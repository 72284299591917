import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { wixCodeReduxContext } from '@wix/wix-code-common-components';
import codeStateReader from '@/infra/redux-state/reducers/codeStateReader';
import fileActions from '@/infra/redux-state/actions/fileActions';
import { CodeEditorContainer } from '../codeEditorContainer/CodeEditorContainer';
import once_ from 'lodash/once';

export default once_(({ core }) => {
  const WixCodeCodeEditor = createReactClass({
    displayName: 'WixCodeCodeEditor',
    propTypes: {
      fileId: PropTypes.string.isRequired,
      onEditorFocus: PropTypes.func,
      onEditorBlur: PropTypes.func,
      onSeeAllShortcuts: PropTypes.func.isRequired,
      getFileContext: PropTypes.func,
    },
    mixins: [core.mixins.editorAPIMixin],
    getCodeEditorContainerProps() {
      const props = {
        fileId: this.props.fileId,
        onEditorFocus: this.props.onEditorFocus,
        onEditorBlur: this.props.onEditorBlur,
        onSeeAllShortcuts: this.props.onSeeAllShortcuts,
        readOnly: this.props.readOnly,
        getFileContext: this.props.getFileContext,
        Footer: this.props.Footer,
      };
      return props;
    },
    render() {
      return <CodeEditorContainer {...this.getCodeEditorContainerProps()} />;
    },
  });

  const mapStateToProps = (state, ownProps) => ({
    contentChangedReported: codeStateReader.getFileContentChangedBiReported(
      state,
      ownProps.fileId,
    ),
  });

  const mapDispatchToProps = (dispatch) => {
    const reportContentChangedBi = (fileId) =>
      dispatch(fileActions.reportContentChangedBi(fileId));

    return {
      reportContentChangedBi,
    };
  };

  return connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
    context: wixCodeReduxContext,
  })(WixCodeCodeEditor);
});
