export const SET_SYNCING = 'SET_SYNCING';
export const SET_SYNC_SUCCESS = 'SET_SYNC_SUCCESS';

export const CONNECTED = 'CONNECTED';
export const RECONNECTED = 'RECONNECTED';
export const DISCONNECTED = 'DISCONNECTED';

export const syncActionCreators = {
  setSyncing: (isSyncing: boolean) => ({
    type: SET_SYNCING,
    isSyncing,
  }),
  setSyncSuccess: (isSyncSuccess: boolean) => ({
    type: SET_SYNC_SUCCESS,
    isSyncSuccess,
  }),
};

export const connectionActionCreators = {
  connected: () => ({
    type: CONNECTED,
  }),
  disconnected: () => ({
    type: DISCONNECTED,
  }),
  reconnected: () => ({
    type: RECONNECTED,
  }),
};
