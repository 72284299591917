import { LocalEditorPublicAPI } from '@wix/wix-code-plugin-contracts';
import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { ExternalLocalEditorAPIKey } from './externalApiKeys';

export type ExternalLocalEditorAPI = {
  contributeOnCLIServiceDisconnect: LocalEditorPublicAPI['contributeOnCLIServiceDisconnect'];
};

export const ExternalLocalEditorEntryPoint: EntryPoint = {
  name: 'External LocalEditor',
  getDependencyAPIs() {
    return [APIKeys.LocalEditorPublicAPI];
  },
  declareAPIs() {
    return [ExternalLocalEditorAPIKey];
  },
  attach(shell: Shell) {
    shell.contributeAPI(ExternalLocalEditorAPIKey, () => {
      const { contributeOnCLIServiceDisconnect } = shell.getAPI(
        APIKeys.LocalEditorPublicAPI,
      );
      return {
        contributeOnCLIServiceDisconnect,
      };
    });
  },
};

export {
  SetFileStructureType,
  FileStructureTypeEnum,
} from '@wix/wix-code-plugin-contracts';
