// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AfqbWm{white-space:pre-line}.uyBTQ_{width:510px}.Jp00EN{padding:24px 24px 0 24px}.EwWhnq{display:flex;align-items:center;justify-content:center;padding-left:5px;padding-right:5px;width:145px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-content": "AfqbWm",
	"modalContent": "AfqbWm",
	"modal-width": "uyBTQ_",
	"modalWidth": "uyBTQ_",
	"content": "Jp00EN",
	"preloaderButton": "EwWhnq"
};
export default ___CSS_LOADER_EXPORT___;
