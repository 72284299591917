import React from 'react';
import GoogleCloudIcon from '../../../assets/icons/googleCloudIcon.svg';
import AzureIcon from '../../../assets/icons/azureIcon.svg';
import AWSIcon from '../../../assets/icons/AWSIcon.svg';
import CustonDbIcon from '../../../assets/icons/customDbIcon.svg';
import { Providers } from '../constants';
import styles from './SelectProvider.scss';
import { Text } from '@wix/wix-base-ui';

type Provider = {
  value: string;
  image?: React.ReactElement;
  title: string | React.ReactElement;
  text: string;
};

export const createProviders: (
  translate: (key: string) => string,
) => Provider[] = (translate) => [
  {
    value: Providers.GOOGLE_OPTION_VALUE,
    image: <GoogleCloudIcon className={styles.imgWrapper} />,
    title: (
      <Text weight="bold">{translate(Providers.GOOGLE_OPTION_VALUE)}</Text>
    ),
    text: translate(
      'External_Databases_Modal_Select_Provider_Google_Cloud_Description',
    ),
  },
  {
    value: Providers.AZURE_OPTION_VALUE,
    image: <AzureIcon className={styles.imgWrapper} />,
    title: <Text weight="bold">{translate(Providers.AZURE_OPTION_VALUE)}</Text>,
    text: translate(
      'External_Databases_Modal_Select_Provider_Azure_Description',
    ),
  },
  {
    value: Providers.AWS_OPTION_VALUE,
    image: <AWSIcon className={styles.imgWrapper} />,
    title: <Text weight="bold">{translate(Providers.AWS_OPTION_VALUE)}</Text>,
    text: translate('External_Databases_Modal_Select_Provider_AWS_Description'),
  },
  {
    value: Providers.CUSTOM_OPTION_VALUE,
    image: <CustonDbIcon className={styles.imgWrapper} />,
    title: (
      <Text weight="bold">{translate(Providers.CUSTOM_OPTION_VALUE)}</Text>
    ),
    text: translate(
      'External_Databases_Modal_Select_Provider_Custom_Description',
    ),
  },
];
