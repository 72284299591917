// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".x2QjUr{background:#292c33;border-radius:8px;width:520px;display:flex;flex-direction:row;justify-content:space-between}.oIpmEu{display:flex;flex-direction:column;padding:17px;text-align:left}.b14oPy{color:#949599;margin-top:8px;margin-right:8px}.EMsySA{padding:12px 24px;max-width:200px;display:flex;flex-direction:column}.fYAHU5{color:#949599;margin-top:8px;margin-right:8px;cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"terminalContainer": "x2QjUr",
	"terminalContent": "oIpmEu",
	"confirmCopyIcon": "b14oPy",
	"confirmPopoverContent": "EMsySA",
	"copyIcon": "fYAHU5"
};
export default ___CSS_LOADER_EXPORT___;
