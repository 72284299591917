import { experimentUtils } from '@wix/wix-code-common';
import {
  PackagesAndAppsAPI,
  EditorAPI,
  FilesTreeAPI,
  PredefinedSidebarTabs,
} from '@wix/wix-code-plugin-contracts';
import { createPackagesAPIService } from '@/toExtract/packages/packagesServiceAPIFacade';

type PackagesAndAppsAPIParams = {
  editorAPI: EditorAPI;
  selectFile: FilesTreeAPI['selectFile'];
  selectTab: FilesTreeAPI['selectTab'];
  expandParents: FilesTreeAPI['expandParents'];
};

export const packagesAndAppsAPI = ({
  editorAPI,
  selectFile,
  selectTab,
  expandParents,
}: PackagesAndAppsAPIParams): PackagesAndAppsAPI => {
  const packagesAPIService = createPackagesAPIService(editorAPI);
  const getAppImportName = (appDefId: string): any => {
    const appData = editorAPI.platform.getAppDataByAppDefId(appDefId);
    const appComponent = appData.components.find(
      (component) => component.type === 'CODE_PACKAGE',
    );
    return appComponent?.data.importName;
  };

  const openReadme = (appDefId: string) => {
    const appFolderPath = getAppImportName(appDefId);
    const { treePane } = editorAPI.developerMode.ui;
    if (!treePane.isOpen()) {
      treePane.toggle();
    }
    selectTab(PredefinedSidebarTabs.PACKAGES);
    expandParents(appFolderPath);
    selectFile(`${appFolderPath}/README.md`);
  };

  const openFile = (filePath: string) => {
    selectFile(filePath);
  };
  const isVeloDependenciesInstalled = async () => {
    try {
      if (experimentUtils.isAnyNpmFirstPhase()) {
        const dependencies = await packagesAPIService.listWixDependencies();
        if (dependencies.length > 0) {
          return true;
        }
        return false;
      }
      const codeReusePackages =
        await editorAPI.wixCode.codePackages.getCodeReusePkgs();
      return Object.keys(codeReusePackages?.wixCodeDependencies).length !== 0;
    } catch (error) {
      throw new Error(`Failed to list npm dependencies`);
    }
  };

  return {
    openReadme,
    openFile,
    isVeloDependenciesInstalled,
  };
};
