import React, { useState, useEffect, useCallback, useContext } from 'react';
import { PackagesModalContext } from '../../../../packagesModalContext';
import { ToggleSwitch, InfoIcon, TextLabel } from '@wix/wix-base-ui';
import { getLargesetMinor } from '../../../../utils';
import s from './PackagesListItem.scss';
import dataHooks from '../../../../dataHooks';
import { PackageItemContext } from '../PackageItemContext';
import { PackagesBiSender } from '../../../../packagesBiSender';

interface PackageAutoUpdateToggleProps {
  selectedVersion: string;
}

export const PackageAutoUpdateToggle: React.FC<
  PackageAutoUpdateToggleProps
> = ({ selectedVersion }) => {
  const { packagesService, t, sendBi } = useContext(PackagesModalContext);
  const { installedPkg, pkgName, installationStatus } =
    useContext(PackageItemContext);
  const packagesBiSender = PackagesBiSender(sendBi);
  const version = selectedVersion ?? installedPkg.version;

  const [largestMinor, setLargestMinor] = useState<undefined | string>(
    undefined,
  );
  const updateLargestMinor = useCallback(async () => {
    const publicVersions = (
      await packagesService.getCodeReusePkgVersions(pkgName)
    ).publicVersions;
    setLargestMinor(getLargesetMinor(version, publicVersions));
  }, [packagesService, version, pkgName]);

  useEffect(() => {
    updateLargestMinor();
  }, [updateLargestMinor]);

  const installing =
    installationStatus === 'INSTALLING' || installationStatus === 'UPDATING';

  const isCarrot = installedPkg?.version.startsWith('^');

  const onChange = (isAutoUpdate: AnyFixMe) => {
    const cleanVersion = installedPkg.version.startsWith('^')
      ? largestMinor
      : installedPkg.version;
    const versionToInstall = isAutoUpdate ? `^${cleanVersion}` : cleanVersion;
    packagesBiSender.sendClickedInstallVersionPkgBi(
      versionToInstall!,
      installedPkg,
    );
    packagesService.changeCodeReusePkgVersion(pkgName, versionToInstall);
  };

  return (
    <div className={s.toggleLine} data-hook={dataHooks.AUTO_UPDATE_TOGGLE}>
      <div className={s.autoUpdateToggle}>
        <TextLabel
          type="T02"
          shouldTranslate={false}
          value={t('WixCode_PackageManager_Update_Text_Auto_Update')}
          enableEllipsis={false}
        />
        <InfoIcon
          className={s.autoUpdateToggle}
          text={t('WixCode_PackageManager_Update_Text_Auto_Update_tooltip')}
          linkText={t(
            'WixCode_PackageManager_Update_Text_Auto_Update_tooltip_link',
          )}
          onLinkClick={() => {
            window.open(
              t(
                'WixCode_PackageManager_Update_Text_Auto_Update_tooltip_link_url',
              ),
            );
          }}
        />
      </div>
      <ToggleSwitch
        animated
        onChange={onChange}
        disabled={installing || !largestMinor}
        value={isCarrot}
        labelAfterSymbol={true}
        dataHook={dataHooks.AUTO_UPDATE_SWITCH}
        className={s.toggleSwitch}
        size="MEDIUM"
      />
    </div>
  );
};
