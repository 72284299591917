export const captureError = (e: unknown) => {
  if (window.wixCodeSentry) {
    window.wixCodeSentry.captureError(e);
  }
};

// todo: remove old one
export function withErrorHandling(fn: Function) {
  return (...args: any) => {
    try {
      return fn(...args);
    } catch (e) {
      captureError(e);
    }
  };
}
