import { FilesReducerState } from '@wix/wix-code-plugin-contracts';
import { CssEditingSectionReduxProps } from '../components/Sidebar/SectionContent/SectionContent';
import { constants } from '../constants';

export const mapCodeStateToProps =
  (getFile: (state: { files: FilesReducerState }, fileId: string) => any) =>
  (state: any): CssEditingSectionReduxProps => {
    return {
      isCssFileExists: getFile(state, constants.global_css_path),
    };
  };
