import {
  leftTreeClickOnASection,
  leftTreeClickAddNewQuickLink,
  leftTreeClickInMenuSettingOptionItem,
} from '@wix/bi-logger-platform/v2';
import {
  NotificationBanner,
  TreeSectionDivider,
  Text,
  TextButton,
  Preloader,
  Composites,
} from '@wix/wix-base-ui';
import React, { ReactElement, useContext, useState } from 'react';
import { dataHooks } from '../../dataHooks';
import {
  CollectionsTreeNode,
  NodeData,
  SchemaActions,
} from '../CollectionsTreeNode/CollectionsTreeNode';
import { ParagraphEmptyState } from '../EmptyState/ParagraphEmptyState';
import { TabContext } from '../tabContext';
import { bi } from '../../bi';
import { EmptyNamespace } from '../EmptyNamespace/EmptyNamespace';
import { NamespaceWithStatus } from '../CollectionsTree/CollectionsTree';
import { ErrorNamespace } from '../ErrorNamespace/ErrorNamespace';
import {
  Edit,
  ExternalLink,
  UnlinkProperties,
} from '@wix/wix-ui-icons-common/classic-editor';
import { useTranslation } from '@wix/wix-i18n-config';
import { ResourceStatus, type Resource } from '../types';
import { PendingExternalDb } from '../PendingExternalDb/PendingExternalDb';
import { CloudDbListItem } from '../CloudDbListItem';

export interface CollectionsTreeRootProps {
  collections: NodeData[];
  contextMenu?: ReactElement;
  loading: boolean;
  schemaActions: SchemaActions;
  label: string;
  onFolderToggle: ({
    namespace,
    isExpandedFolder,
  }: {
    namespace: string;
    isExpandedFolder: boolean;
  }) => void;
  onContextMenuActionSelected: ({
    action,
    nodeData,
    biItemType,
  }: {
    action: string;
    nodeData: NodeData;
    biItemType: string;
  }) => void;
  onCreateCollection: () => void;
  onCollectionSelected: (collectionId: string) => void;
  emptyStateDescription?: string;
  emptyStateCTAText?: string;
  expandedSections: Number[];
  biSubSectionName: string;
  dataHook: string;
  setExpandedSections: React.Dispatch<React.SetStateAction<Number[]>>;
  id: number;
  allNamespaces?: Resource<NamespaceWithStatus>;
  onEditExternalCollections?: (name: string) => void;
  onRemoveExternalCollections?: (name: string) => void;
  shouldShowFormsTooltip?: boolean;
  setShouldShowFormsTooltip?: React.Dispatch<React.SetStateAction<boolean>>;
  shouldShowFormsBanner?: boolean;
  onFormsBannerClick?: () => void;
}

export const CollectionsTreeRoot: React.FC<CollectionsTreeRootProps> = ({
  collections,
  contextMenu,
  loading,
  schemaActions,
  onCreateCollection,
  onCollectionSelected,
  onFolderToggle,
  onContextMenuActionSelected,
  label,
  emptyStateDescription,
  emptyStateCTAText,
  expandedSections,
  setExpandedSections,
  biSubSectionName,
  dataHook,
  id,
  allNamespaces,
  onEditExternalCollections,
  onRemoveExternalCollections,
  shouldShowFormsTooltip,
  setShouldShowFormsTooltip,
  shouldShowFormsBanner,
  onFormsBannerClick,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const { editorAPI, legacyEditorDependencies, biLoggerAPI } =
    useContext(TabContext);

  const [t] = useTranslation();

  const onQuickAddItemClick = (sub_section: string) => () => {
    biLoggerAPI.report(
      leftTreeClickAddNewQuickLink({
        section_name: bi.sectionNames.DATABASE,
        sub_section,
      }),
    );
    onCreateCollection();
  };

  const renderSingleCollectionChild = (child: NodeData) => (
    <li
      key={
        child.isFolder
          ? `folder-${child.namespace}`
          : `schema-${child.schema.id}`
      }
    >
      <CollectionsTreeNode
        editorAPI={editorAPI}
        loading={loading}
        onCreateCollection={onCreateCollection}
        onCollectionSelected={onCollectionSelected}
        onFolderToggle={onFolderToggle}
        onContextMenuActionSelected={onContextMenuActionSelected}
        schemaActions={schemaActions}
        isTopLevelTreeRoot={false}
        depth={1}
        nodeData={child}
        util={legacyEditorDependencies.util}
        biSectionName={biSubSectionName}
        shouldShowFormsTooltip={shouldShowFormsTooltip}
        setShouldShowFormsTooltip={setShouldShowFormsTooltip}
      />
    </li>
  );

  const renderAllCollectionsOldFlow = () => {
    if (collections.length === 0) {
      return (
        <ParagraphEmptyState
          ctaText={emptyStateCTAText}
          dataHook={dataHooks.EMPTY_STATE}
          onCtaClick={onQuickAddItemClick(biSubSectionName)}
          description={emptyStateDescription}
        />
      );
    }
    return collections.map((child) => renderSingleCollectionChild(child));
  };

  const renderAllNamespaces = () => {
    return allNamespaces!.data!.map((namespaceObject: NamespaceWithStatus) => {
      if (namespaceObject.isError) {
        return (
          <li key={`folder-${namespaceObject.namespace}`}>
            <ErrorNamespace
              namespace={namespaceObject.namespace}
              externalCollectionsSections={getExternalCollectionsSections(
                namespaceObject.namespace,
              )}
            />
          </li>
        );
      } else if (namespaceObject.isEmpty) {
        return (
          <li key={`folder-${namespaceObject.namespace}`}>
            <EmptyNamespace
              namespace={namespaceObject.namespace}
              externalCollectionsSections={getExternalCollectionsSections(
                namespaceObject.namespace,
              )}
            />
          </li>
        );
      } else if (namespaceObject.isPending) {
        return (
          <li key={`folder-${namespaceObject.namespace}`}>
            <PendingExternalDb
              namespace={namespaceObject.namespace}
              creationDate={namespaceObject.creationTime!}
            />
          </li>
        );
      } else if (namespaceObject.isCloudDb) {
        return (
          <li key={`folder-${namespaceObject.namespace}`}>
            <CloudDbListItem
              db={namespaceObject}
              collectionsRoot={
                collections.find(
                  (collection) =>
                    collection.namespace === namespaceObject.namespace,
                )!
              }
              onCollectionSelected={onCollectionSelected}
              onContextMenuActionSelected={onContextMenuActionSelected}
              schemaActions={schemaActions}
            />
          </li>
        );
      } else {
        const correspondingCollectionChild = collections.find(
          (child) => child.namespace === namespaceObject.namespace,
        ) as NodeData;
        if (!correspondingCollectionChild) {
          return null;
        }
        return renderSingleCollectionChild(correspondingCollectionChild);
      }
    });
  };

  const renderEmptyState = () => (
    <ParagraphEmptyState
      ctaText={emptyStateCTAText}
      dataHook={dataHooks.EMPTY_STATE}
      onCtaClick={onQuickAddItemClick(biSubSectionName)}
      description={emptyStateDescription}
    />
  );

  const renderPerRootType = () => {
    if (!isExpanded) {
      return null;
    }
    if (allNamespaces) {
      switch (allNamespaces.status) {
        case ResourceStatus.SUCCESS:
          if (allNamespaces.data!.length === 0) {
            return renderEmptyState();
          }
          return renderAllNamespaces();
        case ResourceStatus.FAILURE:
          return renderAllCollectionsOldFlow();
        case ResourceStatus.LOADING:
          return (
            <Composites.Preloader height={50}>
              <Preloader className="tiny" />
            </Composites.Preloader>
          );
      }
    } else {
      if (collections.length === 0) {
        if (shouldShowFormsBanner) {
          return null;
        }
        return renderEmptyState();
      }
      return renderAllCollectionsOldFlow();
    }
  };

  const getExternalCollectionsSections = (namespace: string) => [
    [
      {
        onClick: () => onEditExternalCollections!(namespace),
        icon: Edit,
        label: t(
          'WixCode_TreeView_ExternalDatabases_Connection_ContextMenu_Edit',
        ),
        automationId: 'action-edit-external-collection',
        id: 'edit-external-collection',
      },
      {
        onClick: () => onRemoveExternalCollections!(namespace),
        icon: UnlinkProperties,
        label: t(
          'WixCode_TreeView_ExternalDatabases_Connection_ContextMenu_Disconnect',
        ),
        automationId: 'action-remove-external-collection',
        id: 'remove-external-collection',
      },
    ],
    [
      {
        onClick: () => {
          biLoggerAPI.report(
            leftTreeClickInMenuSettingOptionItem({
              item_name: namespace,
              item_type: bi.itemTypes.EXTERNAL_COLLECTION,
              menu_entry_name: bi.menuItems.externalCollectionLearnMore,
            }),
          );
          window.open(
            t('WixCode_TreeView_ExternalDatabases_LearnMore_Link', '_blank'),
          );
        },
        icon: ExternalLink,
        label: t('WixCode_TreeView_ExternalDatabases_LearnMore_Text'),
        id: 'learn-more-external-collections',
      },
    ],
  ];

  return (
    <div
      data-hook={dataHooks.TREE_ROOT}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div data-hook={dataHook}>
        <TreeSectionDivider
          dataHook={dataHooks.SECTION_DIVIDER}
          alwaysShowSuffix={isHovered}
          label={label}
          collapsed={!isExpanded}
          onClick={() => {
            isExpanded
              ? setExpandedSections(
                  expandedSections?.filter((sectionId) => sectionId !== id),
                )
              : setExpandedSections(expandedSections?.concat([id]));
            setIsExpanded(!isExpanded);
            biLoggerAPI.report(
              leftTreeClickOnASection({
                section_name: bi.sectionNames.DATABASE,
                sub_section: biSubSectionName,
                action: isExpanded ? bi.actions.CLOSE : bi.actions.OPEN,
              }),
            );
          }}
          suffix={contextMenu}
          size="small"
          shouldTranslate={false}
          hideTopBorder={
            id === 0 ? true : expandedSections?.includes(id - 1) ? false : true
          }
        />
      </div>
      {renderPerRootType()}
      {shouldShowFormsBanner && (
        <NotificationBanner
          skin="standard"
          text={
            <div>
              <Text size="small" weight="bold">
                {t('WixCode_TreeView_Databases_FormsMoveNotice_Header')}
              </Text>
              <br />
              <Text size="small" enableEllipsis={false}>
                {t('WixCode_TreeView_Databases_FormsMoveNotice_Body')}
              </Text>
              <br />
              <TextButton
                size="small"
                onClick={onFormsBannerClick}
                dataHook={dataHooks.FORMS_BANNER_CTA}
              >
                {t('WixCode_TreeView_Databases_FormsMoveNotice_CTA')}
              </TextButton>
            </div>
          }
          shouldTranslate={false}
          dataHook={dataHooks.FORMS_BANNER}
          multiline
          type="floating"
        />
      )}
    </div>
  );
};
