import React from 'react';
import { Heading, RichText, Spacer } from '@wix/wix-base-ui';
import dataHooks from '../../dataHooks';
import CenterLoaderSpinner from '../CenteralLoaderSpinner/CenteralLoaderSpinner';
import styles from './InstallApp.scss';

const InstallApp: React.FC<{
  translate: Function;
  showCentralLoaderSpinner?: boolean;
}> = ({ translate: t, showCentralLoaderSpinner = false }) => {
  return (
    <div
      className={styles.container}
      data-hook={dataHooks.connectModal.installApp.container}
    >
      <Heading
        appearance="h3"
        dataHook={dataHooks.connectModal.installApp.header}
      >
        {t('githubIntegration.connect_modal.install_app.header')}
      </Heading>
      <Spacer type="Spacer04" />
      {showCentralLoaderSpinner ? (
        <CenterLoaderSpinner
          texts={[
            t('githubIntegration.connect_modal.install_app.loader.text1'),
            t('githubIntegration.connect_modal.install_app.loader.text2'),
            t('githubIntegration.connect_modal.install_app.loader.text3'),
            t('githubIntegration.connect_modal.install_app.loader.text4'),
          ]}
        />
      ) : (
        <RichText
          className={styles.installAppText}
          type="T05"
          dataHook={dataHooks.connectModal.installApp.descriptionContainer}
        >
          <span data-hook={dataHooks.connectModal.installApp.description}>
            {t('githubIntegration.connect_modal.install_app.description')}{' '}
          </span>
          <a
            href={t(
              'githubIntegration.connect_modal.install_app.learn_more_link',
            )}
            target="_blank"
            rel="noreferrer"
            data-hook={dataHooks.connectModal.installApp.learn_more}
          >
            {t('githubIntegration.connect_modal.install_app.learn_more')}
          </a>
        </RichText>
      )}
    </div>
  );
};

export default InstallApp;
