import * as React from 'react';
import { CODE_EDITOR_MODE } from '@wix/wix-code-plugin-contracts';
import { DefaultFileTemplate } from '@/fileSystem/fileTemplates/defaultTemplatesInterface';
import { PopoversApi } from '@/codeEditor/popovers/api';

export type LeftPaneContextProps = {
  selectedIdeTabId: string;
  translate: (key: string, params?: object) => string;
  getEditorAPI: () => any;
  experiment: any;
  constants: any;
  validate: {
    notEmptyString: (value: string) => boolean;
    pageNameValidator: (fileName: string) => boolean;
  };
  popoversApi: PopoversApi;
  onFileSelected: (id: string) => void;
  onFileDelete: (id: string) => void;
  onOpenWebmodulePermissions: (name: string, id: string) => void;
  defaultFileTemplate: DefaultFileTemplate;
  // SOS FIX - EDITORX LOAD IDE IN FULL SCREEN.
  codeEditorMode: CODE_EDITOR_MODE;
};

export const LeftPaneContext = React.createContext<LeftPaneContextProps>(
  null as any,
);
