// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cBFePz{display:flex;align-items:center}.CynHeQ{margin:0px 0px 1px 8px}.mWQuVN path{fill:currentColor !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "cBFePz",
	"label": "CynHeQ",
	"currentColor": "mWQuVN"
};
export default ___CSS_LOADER_EXPORT___;
