import { PanelHeader } from '@wix/wix-base-ui';
import { PanelsAPI } from '@wix/wix-code-plugin-contracts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { panelHeaderData } from './createSearchPanel';

type SearchPanelHeaderProps = { closePanel: () => void; panelsAPI: PanelsAPI };

export const SearchPanelHeader: React.FC<SearchPanelHeaderProps> = ({
  closePanel,
  panelsAPI,
}) => {
  const [t] = useTranslation();
  const searchHelpCenterId = '53d9d504-075a-4602-8148-5ea9a91dbc3d';

  const onOpenHelp = () => {
    panelsAPI.openHelpCenter(searchHelpCenterId);
  };

  return (
    <PanelHeader
      dataHook="search-panel-tab-panel-header"
      onHelp={onOpenHelp}
      onClose={closePanel}
      className="dragger dark"
      defaultCursor
    >
      {t(panelHeaderData.label)}
    </PanelHeader>
  );
};
