import _ from 'lodash';
import { ActionType } from './types';
import { consts } from '@wix/wix-code-consts';
import codeStateReader from '../reducers/codeStateReader';
import { packageViewActions } from '@/toExtract/packages/packagesView/packagesViewReducer';
import {
  InstalledPkgs,
  WixCodePackageCodeReuse,
  WixCodePackageJSON,
} from '@/toExtract/packages/packagesModalContext';
import { treeSectionActions } from '@/sidebar/filesTree/filesTree/treeSectionReducer';
import {
  SetCodeReusePackage,
  SetNpmPackage,
} from '../reducers/packageJsonReducer';
import {
  createPackagesAPIService,
  Dependency,
  PackagesAPIService,
} from '@/toExtract/packages/packagesServiceAPIFacade';
import {
  EditorAPI,
  ThunkExtraArg,
  PackageJsonActions,
} from '@wix/wix-code-plugin-contracts';
import { experimentUtils } from '@wix/wix-code-common';

const getDependencies = (
  dependencies: WixCodePackageJSON | Dependency[],
  wixCodeDependencies: WixCodePackageJSON | Dependency[],
  isAnyNpmFirstPhase = false,
  gridAppId = '',
): InstalledPkgs => {
  const { npm, codeReuse } = isAnyNpmFirstPhase
    ? {
        npm:
          (dependencies as Dependency[]).map((d: Dependency) => ({
            name: d.packageName!,
            version: d.installedVersion!,
          })) || [],
        codeReuse:
          (wixCodeDependencies as Dependency[]).map((d: Dependency) => ({
            backEndPackage: d.wixOptions!.npmBackEndPackageName!,
            frontEndPackage: d.wixOptions!.npmFrontEndPackageName!,
            gridAppId,
            id: d.wixOptions!.packageId!,
            version: d.installedVersion!,
            name: d.packageName!,
          })) || [],
      }
    : {
        codeReuse: Object.entries(
          (wixCodeDependencies as WixCodePackageJSON).wixCodeDependencies,
        ).map(([name, pkg]) => ({
          name,
          ...pkg,
        })),

        npm: Object.entries(
          (dependencies as WixCodePackageJSON).dependencies,
        ).map(([name, version]) => ({
          name,
          version,
        })),
      };

  return {
    npm,
    codeReuse,
  };
};
const getDependenciesApi = (
  isAnyNpmFirstPhase: boolean,
  packagesService: PackagesAPIService,
  editorAPI: EditorAPI,
) => {
  return isAnyNpmFirstPhase
    ? {
        getNpmDependecies: packagesService.listNpmDependencies,
        getCodeReuseDependecies: packagesService.listWixDependencies,
      }
    : {
        getNpmDependecies: editorAPI.wixCode.codePackages.getNpmPkgs,
        getCodeReuseDependecies:
          editorAPI.wixCode.codePackages.getCodeReusePkgs,
      };
};
const load = () =>
  async function (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: AnyFixMe,
  ) {
    const pkgJsonId = consts.WIX_CODE_PACKAGE_JSON_FILE_NAME;
    dispatch(
      treeSectionActions.setTreeSectionLoading({ sectionName: pkgJsonId }),
    );
    try {
      const isAnyNpmFirstPhase = experimentUtils.isAnyNpmFirstPhase();
      const packagesService = createPackagesAPIService(editorAPI);
      const { getNpmDependecies, getCodeReuseDependecies } = getDependenciesApi(
        isAnyNpmFirstPhase,
        packagesService,
        editorAPI,
      );
      const [updatedNpmPkgs, updatedCodeReusePkgs] = await Promise.all([
        getNpmDependecies(),
        getCodeReuseDependecies(),
      ]);
      const existingNpmPkgs = codeStateReader.getNpmPackages(getState());
      const existingCodeReusePkgs = codeStateReader.getCodeReusePkgs(
        getState(),
      );
      const { npm, codeReuse } = getDependencies(
        updatedNpmPkgs,
        updatedCodeReusePkgs,
        isAnyNpmFirstPhase,
        packagesService.gridAppId,
      );

      if (!_.isEqual(codeReuse, existingCodeReusePkgs)) {
        dispatch(setCodeReusePkgs(codeReuse));
        dispatch(packageViewActions.setInstalledCodeReusePkgs(codeReuse));
      }
      if (!_.isEqual(npm, existingNpmPkgs)) {
        dispatch(setNpmPackages(npm));
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch(
        treeSectionActions.setTreeSectionLoaded({ sectionName: pkgJsonId }),
      );
    }
  };

function expandRootNodeModules() {
  return function (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) {
    const pkgJsonId = consts.WIX_CODE_PACKAGE_JSON_FILE_NAME;
    const traceEnd = editorAPI.documentServices.wixCode.log.trace({
      action: 'expand',
      message: { fileId: pkgJsonId },
    });
    return load()(dispatch, getState, { editorAPI })
      .then(() => {
        dispatch(
          treeSectionActions.expandTreeSection({ sectionName: pkgJsonId }),
        );
        traceEnd();
        return Promise.resolve();
      })
      .catch((e) => {
        traceEnd({
          message: e,
          level: editorAPI.wixCode.log.levels.ERROR,
        });
        throw e;
      });
  };
}

function setNpmPackages(children: SetNpmPackage['children']): SetNpmPackage {
  return {
    type: ActionType.SET_NPM_PACKAGES,
    children,
  };
}

function setCodeReusePkgs(
  children: WixCodePackageCodeReuse[],
): SetCodeReusePackage {
  return {
    type: ActionType.SET_CODE_REUSE_PKGS,
    children,
  };
}
export const packageJsonActions: PackageJsonActions = {
  load,
  setNpmPackages,
  expandRootNodeModules,
};
