import * as React from 'react';
import {
  NpmPackageInfo,
  PackagesModalContext,
} from '../../../../../../../packagesModalContext';
import PackagesListItem from '../../../../packagesListItem/PackagesListItem';
import dataHooks from '../../../../../../../dataHooks';
import { MODAL_TABS } from '../../../../../../packagesModalConsts';
import { PackageItemContextProvider } from '../../../../PackageItemContextProvider';

interface PackageListViewProps {
  modules: NpmPackageInfo[];
  searchKeyword?: string;
}

const PackageListView: React.FunctionComponent<PackageListViewProps> = ({
  modules,
  searchKeyword,
}) => {
  const { selectedPackage, selectPackage, setSelectedNpmPkgData } =
    React.useContext(PackagesModalContext);
  const selectNpmPkg = (npmModule: NpmPackageInfo) => {
    setSelectedNpmPkgData(npmModule);
    selectPackage(npmModule.name, MODAL_TABS.NPM_PACKAGES);
  };

  return (
    <div data-hook={dataHooks.NPM_PKGS_LIST}>
      {modules
        .filter(({ _version }) => !!_version)
        .map((npmModule) => {
          const { _version: latest, name } = npmModule;
          return (
            <PackageItemContextProvider
              pkgName={name}
              key={`${name}@${latest.version}`}
            >
              <PackagesListItem
                onClick={() => selectNpmPkg(npmModule)}
                isSelected={name === selectedPackage}
                searchKeyword={searchKeyword}
              />
            </PackageItemContextProvider>
          );
        })}
    </div>
  );
};

export default PackageListView;
