import * as React from 'react';
import { useContext } from 'react';
import s from './PackagesListItem.scss';
import dataHooks from '../../../../dataHooks';
import cx from 'classnames';
import { NpmPackageListItemDetails } from './NpmPackageListItemDetails';
import { CodeReusePackageListItemDetails } from './CodeReusePackageListItemDetails';

import {
  PackagesListItemControls,
  PackagesListItemControlsProps,
} from './PackagesListItemControls';
import { useHover } from '@/utils/useHover';
import { CodeReusePkgVersion } from '../../../../codeReuseServerAPI';
import { PackageItemContext } from '../PackageItemContext';
import { PackageAutoUpdateToggle } from './PackageAutoUpdateToggle';
import { getLargesetMinor, isSameMajor } from '../../../../utils';
import { VersionActionButton } from './pkgActionButtons/VersionActionButton';
import { VersionListItemDetails } from './VersionListItemDetails';

interface PackagesListItemProps {
  onClick: (pkgName: string) => void;
  isSelected: boolean;
  publicVersions?: CodeReusePkgVersion[];
  versionInfo?: {
    version: string;
    releaseNotes?: string;
    publishedDate?: string;
    isLatest: boolean;
    allVersions?: CodeReusePkgVersion[];
  };
  searchKeyword?: string;
}

const PackagesListItem: React.FC<PackagesListItemProps> = (props) => {
  const { hoverRef, isHovered } = useHover();
  const { versionInfo, onClick, isSelected, publicVersions, searchKeyword } =
    props;
  const { pkgName, isCodeReusePackage, installedPkg } =
    useContext(PackageItemContext);

  const controlProps: PackagesListItemControlsProps = {
    isHovered,
    isSelected,
  };
  const hasInstalledPkg = !!installedPkg;
  const isInstalledLargestMinor = () => {
    const minorVersion = versionInfo?.version;
    return (
      minorVersion &&
      publicVersions &&
      hasInstalledPkg &&
      getLargesetMinor(minorVersion, publicVersions) === minorVersion &&
      isSameMajor(installedPkg.version, minorVersion)
    );
  };

  return (
    <div
      ref={hoverRef}
      data-hook={dataHooks.PKG_LIST_ITEM}
      data-selected={isSelected}
      className={cx(s.packageItem, {
        [s.selected]: isSelected,
      })}
      onClick={() => onClick(pkgName)}
    >
      <div className={s.row}>
        {versionInfo ? (
          <VersionListItemDetails versionInfo={versionInfo} />
        ) : isCodeReusePackage ? (
          <CodeReusePackageListItemDetails />
        ) : (
          <NpmPackageListItemDetails searchKeyword={searchKeyword} />
        )}
        {versionInfo ? (
          <VersionActionButton
            version={versionInfo.version}
            allVersions={versionInfo.allVersions}
          />
        ) : (
          <PackagesListItemControls {...controlProps} />
        )}
      </div>
      {isCodeReusePackage && isInstalledLargestMinor() && (
        <PackageAutoUpdateToggle selectedVersion={versionInfo!.version} />
      )}
    </div>
  );
};

export default PackagesListItem;
