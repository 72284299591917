import { CustomModal, Text } from '@wix/wix-base-ui';
import React from 'react';
import ErrorIllustration from '../../assets/illustrations/error.svg';
import { dataHooks } from '../dataHooks';
import s from './GenerateCodeError.scss';

export interface GenerateCodeErrorProps {
  closePanel: () => void;
  onBackClick: () => void;
  primaryText: string;
  secondaryText?: string;
}

export const GenerateCodeError: React.FC<GenerateCodeErrorProps> = ({
  closePanel,
  onBackClick,
  primaryText,
  secondaryText,
}) => {
  return (
    <CustomModal
      onCloseButtonClick={closePanel}
      title="AI Code Assistant"
      secondaryButtonText="Back"
      secondaryButtonOnClick={onBackClick}
      onHelpButtonClick={() => {}}
      dataHook={dataHooks.ai_panel.error_modal.modal}
    >
      <div className={s.main}>
        <ErrorIllustration />
        <Text
          size="large"
          weight="bold"
          dataHook={dataHooks.ai_panel.error_modal.primaryText}
        >
          {primaryText}
        </Text>
        <Text
          size="large"
          skin="secondary"
          dataHook={dataHooks.ai_panel.error_modal.secondaryText}
        >
          {secondaryText}
        </Text>
      </div>
    </CustomModal>
  );
};
