import { PageEditorReducerState } from './pageEditorReducer';

function getCurrentTab(state: { pageEditor: PageEditorReducerState }) {
  return state.pageEditor.currentTab;
}

function getRenamingPageId(state: { pageEditor: PageEditorReducerState }) {
  return state.pageEditor.renaming;
}

export default {
  getCurrentTab,
  getRenamingPageId,
};
