import React, { useContext } from 'react';
import _ from 'lodash';
import { TreeSectionDivider } from '@wix/wix-base-ui';
import { monitoring } from '@wix/wix-code-common';
import { leftTreeClickOnASection } from '@wix/bi-logger-platform/v2';
import dataHooks from '../dataHooks';
import { PageTreeContext } from '../pagesTree/PageTreeContext';
import { RouterPagesGroup } from './RouterPagesGroup';
import { PagesTabContext } from '../pagesTree/PagesTabContext';

interface RouterPagesTreeProps {
  dataSource: any;
}

const { withErrorHandling } = monitoring;

export const RouterPagesTree = ({ dataSource }: RouterPagesTreeProps) => {
  const { isCollapsed, setCollapsed } = useContext(PageTreeContext);
  const {
    wixCodeEditorAdapterAPI: { legacyDependencies },
  } = useContext(PagesTabContext);
  const { bi } = useContext(PagesTabContext);
  const getId = () => 'page_' + _.get(dataSource, 'id');
  const [collapsed, setCollapsedState] = React.useState(isCollapsed(getId()));
  const t = legacyDependencies.util.translate;

  const onNodeClick = () =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickOnASection({
          section_name: 'router_pages',
          action: collapsed ? 'open' : 'close',
        }),
      );
      // >>>>> TODO how to manage collapsed state?
      setCollapsed(getId(), !collapsed);
      setCollapsedState(!collapsed);
      return;
    })();

  return (
    <>
      <div data-aid="router-pages-section">
        <TreeSectionDivider
          dataHook={dataHooks.SECTION_DIVIDER}
          label={t(dataSource.label)}
          collapsed={collapsed}
          hideBottomBorder={collapsed}
          onClick={onNodeClick}
          shouldTranslate={false}
          size="small"
        />
      </div>
      {!collapsed && (
        <ul
          data-aid="router-pages-items"
          key="subtree"
          className="section-container"
        >
          {dataSource.items &&
            dataSource.items.map((child: AnyFixMe) => (
              <li key={child.id}>
                <RouterPagesGroup
                  dataSource={child}
                  isTopLevelTreeRoot={false}
                />
              </li>
            ))}
        </ul>
      )}
    </>
  );
};
