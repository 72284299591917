import React from 'react';
import { EntryPoint, APIKeys, Shell } from '@wix/wix-code-repluggable';
import { createPackagesTab } from './createPackagesTab';
import { ContributedSectionsSlot } from './contributedSections';
import {
  PredefinedSidebarTabs,
  TabProps,
} from '@wix/wix-code-plugin-contracts';
import { NpmPkgTree } from '../pkgTree/npmPkgTree/NpmPkgTree';
import { VeloPkgsTree } from '../pkgTree/veloPkgsTree/VeloPkgsTree';
import { PrivateAppsTree } from '../pkgTree/privateAppsTree/PrivateAppsTree';
import { CodePackagesTree } from '../pkgTree/CodePackagesTree/CodePackagesTree';
import { createBlocksFilesTab } from './createBlocksFilesTab';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { createPackagesTabAPI } from '../packagesTabAPI';
import { createPackagesTabWrapper } from './packagesTabWrapper';

export const PackagesTabEntryPoint: EntryPoint = {
  name: 'Packages Tab Entry Point',
  getDependencyAPIs() {
    return [
      APIKeys.SidebarAPI,
      APIKeys.ExperimentsAPI,
      APIKeys.EditorContextAPI,
      WixCodeEditorAdapterAPI,
      APIKeys.MenuAPI,
      APIKeys.MenuPanelAPI,
      APIKeys.WixCodeStoreAPI,
      APIKeys.ReadOnlyAPI,
      APIKeys.WixCodeDuplexerAPI,
      APIKeys.PanelsAPI,
      APIKeys.DuplexerAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.PackagesAndAppsAPI,
    ];
  },
  declareAPIs() {
    return [APIKeys.PackagesTabAPI];
  },
  attach(shell: Shell) {
    shell.contributeAPI(APIKeys.PackagesTabAPI, () => {
      return createPackagesTabAPI(shell);
    });
  },
  extend(shell: Shell) {
    const sidebarAPI = shell.getAPI(APIKeys.SidebarAPI);
    const experimentsAPI = shell.getAPI(APIKeys.ExperimentsAPI);
    const packagesTabAPI = shell.getAPI(APIKeys.PackagesTabAPI);
    const sectionsSlot = shell.getSlot(ContributedSectionsSlot);
    const editorContextAPI = shell.getAPI(APIKeys.EditorContextAPI);
    const wixCodeEditorAdapterAPI = shell.getAPI(WixCodeEditorAdapterAPI);
    const menuAPI = shell.getAPI(APIKeys.MenuAPI);
    const menuPanelAPI = shell.getAPI(APIKeys.MenuPanelAPI);
    const wixCodeStoreAPI = shell.getAPI(APIKeys.WixCodeStoreAPI);
    const readOnlyAPI = shell.getAPI(APIKeys.ReadOnlyAPI);
    const wixCodeDuplexerAPI = shell.getAPI(APIKeys.WixCodeDuplexerAPI);
    const panelsAPI = shell.getAPI(APIKeys.PanelsAPI);
    const duplexerAPI = shell.getAPI(APIKeys.DuplexerAPI);
    const packagesAndAppsAPI = shell.getAPI(APIKeys.PackagesAndAppsAPI);

    const isBlocksCombinedMode = experimentsAPI.isOpen('se_privateAppsPanel');
    const isResponsiveEditorMainBar = experimentsAPI.isOpen(
      'specs.responsive-editor.mainBar',
    );
    const isInStudio = editorContextAPI.getEditorType() === 'Studio';

    const isVeloPhase0 = isResponsiveEditorMainBar && isInStudio;
    packagesTabAPI.contributeSection(
      {
        id: 'npm_packages',
        Component: () => <NpmPkgTree />,
      },
      () => true,
    );
    packagesTabAPI.contributeSection(
      {
        id: 'velo_packages',
        Component: () => <VeloPkgsTree />,
      },
      () => isBlocksCombinedMode,
    );
    packagesTabAPI.contributeSection(
      {
        id: 'private_apps',
        Component: () => <PrivateAppsTree />,
      },
      () => isBlocksCombinedMode,
    );
    packagesTabAPI.contributeSection(
      {
        id: 'code_packages',
        Component: () => <CodePackagesTree />,
      },
      () => !isBlocksCombinedMode,
    );
    const packagesTab = createPackagesTab(
      isBlocksCombinedMode,
      isVeloPhase0,
      sectionsSlot,
      {
        legacyDependenciesAPI: wixCodeEditorAdapterAPI.legacyDependencies,
        wixCodeStoreAPI,
        readOnlyAPI,
        wixCodeDuplexerAPI,
        duplexerAPI,
        panelsAPI,
        packagesAndAppsAPI,
      },
    );
    sidebarAPI.contributeTab(() => packagesTab);

    menuPanelAPI.contributeTab({
      id: 'packages',
      TabContent: ({ closePanel }) => {
        const TabContent = packagesTab.creator();
        return createPackagesTabWrapper({
          closePanel,
          children: TabContent,
          apis: {
            panelsAPI,
            legacyDependenciesAPI: wixCodeEditorAdapterAPI.legacyDependencies,
          },
        });
      },
    });

    menuAPI.registerMenuItemListener('packages', () => {
      menuPanelAPI.selectTab('packages');
    });

    // TODO: move this from packagesTabEntryPoint and contribute it from elsewhere
    sidebarAPI.contributeTab(
      (tabProps?: TabProps[PredefinedSidebarTabs.BLOCKS_CODE]) =>
        createBlocksFilesTab(
          {
            legacyDependenciesAPI: wixCodeEditorAdapterAPI.legacyDependencies,
            wixCodeStoreAPI,
            readOnlyAPI,
            wixCodeDuplexerAPI,
            duplexerAPI,
            panelsAPI,
            packagesAndAppsAPI,
          },
          editorContextAPI.isBlocks(),
          tabProps,
        ),
    );
  },
};
