import { EditorAPI, ExperimentsAPI } from '@wix/wix-code-plugin-contracts';
import {
  createSyncSiteHandlerHelpers,
  SyncSiteHandlers,
} from './syncSiteHandlerHelpers';

const createRegisterSyncSiteHandlers = ({
  syncSiteHandler,
  syncPageTypesHandler,
  syncPageFilesHandler,
}: SyncSiteHandlers) => {
  return (experimentsAPI: ExperimentsAPI, editorAPI: EditorAPI) => {
    const {
      onChangedEvents,
      isSyncSiteEvent,
      registerManualSaveListener,
      registerToSyncSiteOnManualSave,
    } = createSyncSiteHandlerHelpers({
      editorAPI,
      experimentsAPI,
      syncSiteHandler,
      syncPageFilesHandler,
      syncPageTypesHandler,
    });

    registerToSyncSiteOnManualSave();

    editorAPI.dsActions.registerToSiteChanged((changedEvents?: string[]) => {
      const syncSiteEvents = changedEvents?.filter(isSyncSiteEvent);
      if (!syncSiteEvents?.length) {
        return;
      }

      registerManualSaveListener(syncSiteEvents);
      const isAutosaveOn = editorAPI.dsRead.canAutosave();
      if (isAutosaveOn) {
        onChangedEvents(syncSiteEvents);
      }
    });
  };
};

export { createRegisterSyncSiteHandlers };
