// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ge1QR7{flex:1;padding-right:50px;overflow:hidden}.ge1QR7 mark{background-color:#d6e6fe}.ge1QR7 .DNGuyT{display:flex;flex-direction:row;align-items:baseline}.ge1QR7 .DNGuyT .WrAG1i{color:#3b4057;width:auto}.ge1QR7 .DNGuyT .yZOxqj,.ge1QR7 .DNGuyT .WTaUVL{color:#3b4057}.ge1QR7 .DNGuyT .yZOxqj{margin-left:15px}.ge1QR7 .DNGuyT .lKUTPp{margin-left:10px}.ge1QR7 .DNGuyT .WTaUVL{margin-left:10px}.ge1QR7 .tY8kfn{margin-bottom:10px}.ge1QR7 .nBelj9{margin-top:5px;color:#3b4057}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"package-data": "ge1QR7",
	"packageData": "ge1QR7",
	"first-row": "DNGuyT",
	"firstRow": "DNGuyT",
	"title": "WrAG1i",
	"version": "yZOxqj",
	"downloadCount": "WTaUVL",
	"license": "lKUTPp",
	"importName": "tY8kfn",
	"description": "nBelj9"
};
export default ___CSS_LOADER_EXPORT___;
