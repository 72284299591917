import {
  SlotKey,
  SlotRenderer,
  ExtensionSlot,
} from '@wix/wix-code-repluggable';
import React from 'react';

type SidebarSlotContribution = (props: {}) => React.ReactNode;
export type SidebarSlot = ExtensionSlot<SidebarSlotContribution>;

export const CodeEditorSidebarSlot: SlotKey<SidebarSlotContribution> = {
  name: 'Code Editor Sidebar Slot',
};

interface CodeEditorSidebarProps {
  sidebarSlot: ExtensionSlot<SidebarSlotContribution>;
}
export const CodeEditorSidebar = ({ sidebarSlot }: CodeEditorSidebarProps) => (
  <SlotRenderer slot={sidebarSlot} />
);
