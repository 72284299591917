import _ from 'lodash';
import { Tooltip } from '@wix/wix-base-ui';
import React, { useState, useRef, useEffect, FunctionComponent } from 'react';
import classNames from 'classnames';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import scrollIntoView from 'scroll-into-view-if-needed';
import CloseTab from './closeTab.svg';

interface TabItemProps {
  alwaysShowCloseButton?: boolean;
  hasCloseButton?: boolean;
  iconSymbol: React.ReactNode;
  isUnpinned: boolean;
  label: string;
  onClose?: (tabId: string) => void;
  onTabClick?: (tabId: string) => void;
  onTabPin?: (tabId: string) => void;
  selected: boolean;
  suffix?: React.ReactNode;
  tabId: string;
  tooltip?: React.ReactNode;
}

export const TabItem: FunctionComponent<TabItemProps> = ({
  alwaysShowCloseButton = false,
  hasCloseButton = true,
  iconSymbol,
  isUnpinned,
  label,
  onClose = _.noop,
  onTabClick = _.noop,
  onTabPin = _.noop,
  selected,
  suffix,
  tabId,
  tooltip,
}) => {
  const tabRef = useRef(null);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (selected) {
      try {
        scrollIntoView(tabRef.current!, { scrollMode: 'if-needed' });
      } catch (e) {
        // TODO how to handle this?
      }
    }
  }, [selected]);

  const suppressEvent = (event: AnyFixMe, cb: AnyFixMe) => {
    event.stopPropagation();
    return cb();
  };

  return (
    <div
      ref={tabRef}
      className={classNames('tab-item', {
        'tab--selected': selected,
        'tab--hovered': hovered,
        'tab--unpinned': isUnpinned,
      })}
      data-tabid={tabId}
      data-aid="tab-item"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onDoubleClick={(event) => suppressEvent(event, () => onTabPin(tabId))}
      onClick={(event) => suppressEvent(event, () => onTabClick(tabId))}
    >
      <div className="tab-item-content">
        {iconSymbol}

        <span
          className={classNames('tab-caption', {
            'tab--unpinned': isUnpinned,
          })}
        >
          {label}
        </span>
        {suffix}
        <span
          data-aid="icon-container"
          className={classNames('icon-section', {
            visible: hovered || alwaysShowCloseButton,
          })}
        >
          {tooltip && (
            // TODO use InfoIcon instead
            <Tooltip
              key="infoIcon"
              openDelay={200}
              marginTop={6}
              shouldTranslate={false}
              interactive={false}
              content={tooltip}
              maxWidth={400}
            >
              <span data-aid="page-tab-info-icon" className="info-icon">
                <Symbol name="infoIcon"></Symbol>
              </span>
            </Tooltip>
          )}
          {hasCloseButton && (
            <CloseTab
              data-aid="close-tab-item"
              name="close-tab"
              onClick={(event: AnyFixMe) =>
                suppressEvent(event, () => onClose(tabId))
              }
              className="tab-close"
            />
          )}
        </span>
      </div>
    </div>
  );
};
