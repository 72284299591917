import React, { useContext } from 'react';
import { PagesTabContext } from './PagesTabContext';
import { TreeListItem, InfoIcon } from '@wix/wix-base-ui';
import {
  leftTreeClickOnAnItem,
  ideTabAction,
} from '@wix/bi-logger-platform/v2';
import dataHooks from '../dataHooks';
import { Master as MasterIcon } from '@wix/wix-ui-icons-common/classic-editor';
import { utilsCreator, getMasterPageFileId } from '@wix/wix-code-common';
import { consts } from '@wix/wix-code-consts';

interface MasterPageProps {
  selected: boolean;
}

const MasterPage: React.FC<MasterPageProps> = ({ selected }) => {
  const {
    editorAPI,
    legacyDependenciesAPI,
    bi,
    wixCodeStoreAPI,
    devContextAPI,
  } = useContext(PagesTabContext);
  const { experiment, platform, util } = legacyDependenciesAPI;
  const utils = utilsCreator({ experiment, platform, util });
  const masterPageFileId = getMasterPageFileId();

  const onMasterPageClick = () => {
    bi.report(
      leftTreeClickOnAnItem({
        item_name: consts.SITE_JS_PAGE_ID,
        item_type: 'file',
      }),
    );

    editorAPI.wixCode.fileSystem.loadPageCode(
      consts.SITE_JS_PAGE_ID,
      utils.getDefaultPageContent(true),
    );

    devContextAPI.setMasterPageContext();
    editorAPI.developerMode.ui.idePane.unMinimize();
  };

  const onMasterPageDoubleClick = (): void => {
    bi.report(
      ideTabAction({
        origin: 'left_tree_double_click',
        action: 'pinned',
        file_name: masterPageFileId,
      }),
    );
    wixCodeStoreAPI.treeActions.nodeDoubleClick(masterPageFileId);
  };

  return (
    <TreeListItem
      dataHook={dataHooks.TREE_NODE}
      label={util.translate('WixCode_TreeView_Site_MasterPage_Title')}
      depth={0}
      icon={<MasterIcon data-hook={dataHooks.MASTER_PAGE_ICON} />}
      size="small"
      suffix={
        <InfoIcon
          dataHook={dataHooks.MASTER_PAGE_INFO_ICON}
          text={util.translate('WixCode_TreeView_Site_MasterPage_Tooltip')}
        />
      }
      onClick={onMasterPageClick}
      onDoubleClick={onMasterPageDoubleClick}
      selected={selected}
    />
  );
};
export default MasterPage;
