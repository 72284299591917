import React from 'react';
import { TabItem } from '@/commonComponents/IdeTabsContainer/TabItem';
import { TextLabel } from '@wix/wix-base-ui';
import { fileIconCreator } from '@/utils/icons';
import bi from '@/legacy/bi/bi';
import { ideTabPin, ideTabSelect } from '@/legacy/bi/biEvents';
import ideTabsActions from '../../actions/ideTabsActions';
import { IdeCodeTabSuffix } from './IdeCodeTabSuffix';
import {
  useDispatch,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import { useCodeEditorTabContext } from '../context/codeEditorTabContext';
import { CodeTab as CodeTabInternal } from '@wix/wix-code-plugin-contracts';
import { useTranslate } from '@/infra/i18n/useTranslate';

export type CodeTab = CodeTabInternal;

interface IdeCodeTabsProps {
  codeTabs: CodeTab[];
  tabCount: number;
  selectedTabId: string;
  sendBi: (arg: any) => void;
  selectTab: (arg: { tabId: string; isPageTab: boolean }) => void;
  chooseTabAfterClose: (tabClosing: string, biItemName: string) => void;
}

export const IdeCodeTabs: React.FC<IdeCodeTabsProps> = ({
  codeTabs,
  tabCount,
  selectedTabId,
  sendBi,
  selectTab,
  chooseTabAfterClose,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const closeCodeTab = (tabId: AnyFixMe) =>
    dispatch(ideTabsActions.closeTab(tabId));
  const pinTab = (tabId: AnyFixMe) => dispatch(ideTabsActions.pinTab(tabId));

  const { editorAPI } = useEditorLegacyAPIs();
  const FileIcon = fileIconCreator(editorAPI);

  const onTabClick = ({ tabId, isPageTab }: AnyFixMe, biParams: AnyFixMe) => {
    sendBi({
      def: bi.events.IDE_TAB_ACTION,
      params: ideTabSelect({ origin: 'ide_tab_click', tabId, ...biParams }),
    });

    selectTab({ tabId, isPageTab });
    editorAPI.developerMode.ui.idePane.unMinimize();
  };

  const onTabPin = ({ tabId, isPageTab }: AnyFixMe, biParams: AnyFixMe) => {
    sendBi({
      def: bi.events.IDE_TAB_ACTION,
      params: ideTabPin({
        origin: 'double-click',
        tabId,
        ...biParams,
      }),
    });

    if (!isPageTab) {
      pinTab({ tabId });
    }
  };

  const onTabClose = (tabId: AnyFixMe) => {
    chooseTabAfterClose(tabId, tabId);
    closeCodeTab({ tabId });
  };

  const { fileNameTransformer } = useCodeEditorTabContext();
  const generateTabName = ({
    displayName = '',
    tabId,
    isPageTab,
  }: {
    displayName?: string;
    tabId: string;
    isPageTab: boolean;
  }) => {
    const originalTabTitle = displayName || tabId.split('/').pop()!;
    return fileNameTransformer({
      originalTabTitle,
      displayName,
      tabId,
      isPage: isPageTab,
    });
  };

  return (
    <>
      {codeTabs.map(({ displayName, tabId, isPageTab, unpinned }, tabIndex) => {
        const wrapBiParams = (cb: AnyFixMe) => () =>
          cb({ tabId, isPageTab }, { tabIndex, tabCount });

        const isSelected = selectedTabId === tabId;

        const tabName = generateTabName({
          displayName,
          tabId,
          isPageTab: !!isPageTab,
        });

        return (
          <TabItem
            tabId={tabId}
            label={tabName}
            selected={isSelected}
            alwaysShowCloseButton={isSelected}
            key={tabId || displayName}
            onTabClick={wrapBiParams(onTabClick)}
            onTabPin={wrapBiParams(onTabPin)}
            onClose={() => onTabClose(tabId)}
            hasCloseButton={!isPageTab}
            isUnpinned={!!unpinned}
            suffix={<IdeCodeTabSuffix tabId={tabId} />}
            tooltip={
              isPageTab && (
                <div className="wix-code-tabs-container-tooltip-container">
                  <TextLabel
                    type="T03"
                    value={t('WixCode_IdeTabs_PageTab_TooltipHeader')}
                  />
                  <TextLabel
                    enableEllipsis={false}
                    type="T02"
                    value={t('WixCode_IdeTabs_PageTab_TooltipDescription')}
                  />
                </div>
              )
            }
            iconSymbol={<FileIcon path={tabId} className="file-icon" />}
          />
        );
      })}
    </>
  );
};
