// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".q2GWk4{display:flex;height:100%;width:100%}.q2GWk4 .J0LGFT{display:flex;flex-direction:column;width:100%;overflow:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": "q2GWk4",
	"content": "J0LGFT"
};
export default ___CSS_LOADER_EXPORT___;
