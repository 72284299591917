import { isCssFile, isMasterPageFile } from '@wix/wix-code-common';
import { DeveloperModeContext } from './index';

export const createSelectors = (getState: () => DeveloperModeContext) => {
  return {
    isPage: () => getState()?.type === 'PAGE',
    isMasterPage: () => isMasterPageFile(getState()?.data.id),
    isCssFile: () => isCssFile(getState()?.data.id),
  };
};
