import once_ from 'lodash/once';
import tbfActions from '../actions/tbfActions';
import {
  utilsCreator as wixCodeUtilsCreator,
  experimentUtils,
} from '@wix/wix-code-common';
import devContextUtilsCreator from '@/utils/devContext';
import { captureError } from '@/infra/monitoring';
import { isTbfSupportHttpFunctionsActive } from '@/infra/experiments/legacyExperiments';
import { convertModelIdToPath, extractFileTypeFromFileName } from '../utils';
import * as codeEditor from '@wix/wix-code-code-editor';
import bi from '../bi';

const { codeExportsService } = codeEditor;

const TBF_MODELS_NAMESPACE = 'TBF_MODELS_NAMESPACE';

export default once_(({ experiment, platform, util, constants }) => {
  let hasSentValidFunctionInfoBi = false;

  const wixCodeUtils = wixCodeUtilsCreator({ experiment, platform, util });
  const { setTbfContext } = devContextUtilsCreator({
    constants,
  });

  const constructFunctionSignature = (name: AnyFixMe, paramNames: AnyFixMe) =>
    `${name}(${paramNames.join(', ')})`;

  const getExportedArrowAndRegularFunctions = (code: AnyFixMe) => {
    try {
      return codeExportsService.listExportedFunctions(code);
    } catch (error) {
      captureError(error, {
        tags: { listExportedFunctions: true },
        data: { code },
        level: 'warning',
      });
      return [];
    }
  };

  const getExportedWebMethods = (code: AnyFixMe) => {
    try {
      return codeExportsService.listExportedWebMethods(code);
    } catch (error) {
      captureError(error, {
        tags: { listExportedWebMethods: true },
        data: { code },
        level: 'warning',
      });
      return [];
    }
  };

  return function ({ editorAPI, store }: AnyFixMe) {
    const isTbfSupportedForFile = (fileId: string) =>
      (wixCodeUtils.isBackendFile(editorAPI, fileId) && isJswFile(fileId)) ||
      isWebJsFile(fileId);

    const isJswFile = (fileId: string) => /\.jsw?$/.test(fileId);

    const isWebJsFile = (fileId: string) => /\.(web.js)$/.test(fileId);

    const registerTbfHandler = () => {
      editorAPI.wixCode.registerToCodeEditorLoaded(() => {
        const codeEditorApi = editorAPI.wixCode.codeEditorApi.getApi();
        const decorationsProvider =
          codeEditorApi.registerDecorationsProvider ||
          codeEditorApi.provideDecorations;

        decorationsProvider(async ({ modelId, getModelValue }: AnyFixMe) => {
          const fileId = convertModelIdToPath(modelId);
          if (isTbfSupportedForFile(fileId)) {
            const code = getModelValue();
            const exportedFunctionsInfo = [];
            if (isWebJsFile(fileId) && experimentUtils.isWebJsEditorSupport()) {
              exportedFunctionsInfo.push(...getExportedWebMethods(code));
            } else if (isJswFile(fileId)) {
              exportedFunctionsInfo.push(
                ...getExportedArrowAndRegularFunctions(code),
              );
            }

            const shouldSendFirstTimeValidFunctionsOnIdeBiEvent =
              exportedFunctionsInfo.length > 0 && !hasSentValidFunctionInfoBi;

            if (shouldSendFirstTimeValidFunctionsOnIdeBiEvent) {
              editorAPI.bi.event(
                bi.events.TBF_VALID_EXPORTED_FUNCTION,
                bi.eventBuilders.validFunctionInIde({
                  fileName: fileId,
                  fileType: extractFileTypeFromFileName(fileId),
                  functionName: exportedFunctionsInfo[0].name,
                }),
              );
              hasSentValidFunctionInfoBi = true;
            }

            if (exportedFunctionsInfo.length > 0) {
              editorAPI.bi.event(
                bi.events.TBF_RUN_BUTTON_EXPOSURE,
                bi.eventBuilders.playButtonExposure(fileId),
              );
            }

            const decorations = exportedFunctionsInfo.map(
              (functionInfo: AnyFixMe) => {
                const line = functionInfo.location[0];
                return {
                  range: [line, 1, line, 1],
                  type: codeEditor.decorationTypes.LEFT_MARGIN,
                  className: 'tbf-trigger-func-btn-facelift',
                  events: [
                    {
                      type: 'click',
                      callback: async (event: AnyFixMe) => {
                        if (event.event.leftButton) {
                          editorAPI.bi.event(
                            bi.events.TBF_OPEN_TBF_TAB,
                            bi.eventBuilders.openTbfPanel({
                              parametersCount: functionInfo.paramNames.length,
                              fileName: fileId,
                              origin: 'play_icon',
                              functionName: functionInfo.name,
                            }),
                          );
                          setTbfContext(editorAPI, {
                            id: `${fileId}/${functionInfo.name}`,
                            fileId,
                            functionName: functionInfo.name,
                            functionSignature: constructFunctionSignature(
                              functionInfo.name,
                              functionInfo.paramNames,
                            ),
                            paramNames: functionInfo.paramNames,
                            lineNumber: line,
                          });
                        }
                      },
                    },
                  ],
                };
              },
            );
            return { decorations };
          } else {
            return { decorations: [] };
          }
        });
      });
    };

    const loadTbfContent = ({
      id,
      paramNames,
      functionSignature,
      lineNumber,
      functionName,
      fileId,
    }: AnyFixMe) => {
      store.dispatch(
        tbfActions.loadTbfModel({
          isHttpFunctionExperimentOn:
            isTbfSupportHttpFunctionsActive(experiment),
          paramNames,
          functionName,
          fileId,
        }),
      );
    };

    const api = {
      registerTbfHandler,
      loadTbfContent,
      models: codeEditor.createNamespacedModelsApi(TBF_MODELS_NAMESPACE),
    };

    return api;
  };
});
