import React from 'react';
import { TabItem } from '@/commonComponents/IdeTabsContainer/TabItem';
import ideTabsActions from '../../actions/ideTabsActions';
import devContextUtilsCreator from '@/utils/devContext';
import TbfTabIcon from '@/assets/icons/ideTabs/tbfTabIcon.svg';
import {
  useDispatch,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import { TriggerBEFunctionTab as TriggerBEFunctionTabInternal } from '@wix/wix-code-plugin-contracts';

export type TriggerBEFunctionTab = TriggerBEFunctionTabInternal;
interface IdeTriggerBEFunctionTabsProps {
  tbfTabs: TriggerBEFunctionTab[];
  selectedTabId: string;
  chooseTabAfterClose: (tabClosing: string, biItemName: string) => void;
}

export const IdeTriggerBEFunctionTabs: React.FC<
  IdeTriggerBEFunctionTabsProps
> = ({ tbfTabs, selectedTabId, chooseTabAfterClose }) => {
  const {
    editorAPI,
    legacyDependenciesAPI: { constants },
  } = useEditorLegacyAPIs();
  const { setTbfContext } = devContextUtilsCreator({ constants });

  const dispatch = useDispatch();
  const closeTbfTab = (tabId: AnyFixMe) =>
    dispatch(ideTabsActions.closeTbfTab({ tabId }));

  const tbfTabClose = ({ tabId }: AnyFixMe) => {
    closeTbfTab(tabId);
    chooseTabAfterClose(tabId, tabId);
  };

  const tbfTabClick = ({
    tabId,
    fileId,
    functionName,
    functionSignature,
    paramNames,
    lineNumber,
  }: AnyFixMe) => {
    setTbfContext(editorAPI, {
      id: tabId,
      fileId,
      functionName,
      functionSignature,
      paramNames,
      lineNumber,
    });
  };

  return (
    <>
      {tbfTabs.map((tbfTab) => {
        const isSelected = selectedTabId === tbfTab.tabId;
        return (
          <TabItem
            tabId={tbfTab.tabId}
            label={tbfTab.functionName}
            selected={isSelected}
            alwaysShowCloseButton={isSelected}
            onTabPin={() => {}}
            onTabClick={() => tbfTabClick(tbfTab)}
            onClose={() => tbfTabClose(tbfTab)}
            iconSymbol={<TbfTabIcon />}
            isUnpinned={false}
          />
        );
      })}
    </>
  );
};
