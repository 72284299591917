// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BdxWWt{display:flex;justify-content:flex-end;align-items:center;gap:12px}.MolIB3{min-height:350px;max-width:500px;display:flex;justify-content:center}.uteL7e{display:flex;justify-content:center;align-items:center;height:200px}.E1Sh9H{margin-top:15px}.TB9ZRV{margin-top:25px}.IsbE81{display:flex;justify-content:flex-start;align-items:center}.wE_EL6{margin-left:5px}.pAqg0c{display:flex;flex-direction:column;align-items:center;padding:20px;justify-content:space-between;max-width:500px;text-align:center;color:#136cff}.nQ6jQ_{width:540px;padding:12px 0px 18px 0px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalFooter": "BdxWWt",
	"modalContainer": "MolIB3",
	"loaderContainer": "uteL7e",
	"marginTop": "E1Sh9H",
	"largeMarginTop": "TB9ZRV",
	"titleContainer": "IsbE81",
	"marginLeft": "wE_EL6",
	"promotionalModalContainer": "pAqg0c",
	"mainModalContainer": "nQ6jQ_"
};
export default ___CSS_LOADER_EXPORT___;
