import _ from 'lodash';
import { ActionType } from '../actions/types';
import {
  FileDescriptor as FileDescriptorInternal,
  PendingRename as PendingRenameInternal,
  Dirs as DirsInternal,
  FileSystemReducerState as FileSystemReducerStateInternal,
  SetFile as SetFileInternal,
  RemoveFile as RemoveFileInternal,
  FileSystemReducerAction as FileSystemReducerActionInternal,
  SetDirs as SetDirsInternal,
  SetPendingDelete as SetPendingDeleteInternal,
  MoveFolder as MoveFolderInternal,
  SetPendingRename as SetPendingRenameInternal,
  RemoveChild as RemoveChildInternal,
  AddChild as AddChildInternal,
  SetLoaded as SetLoadedInternal,
  SetChildren as SetChildrenInternal,
  SetLoading as SetLoadingInternal,
  SetExpandedMany as SetExpandedManyInternal,
  SetExpanded as SetExpandedInternal,
  FileStateRecord as FileStateRecordInternal,
} from '@wix/wix-code-plugin-contracts';

export type FileDescriptor = FileDescriptorInternal;
export type PendingRename = PendingRenameInternal;
export type Dirs = DirsInternal;
export type FileSystemReducerState = FileSystemReducerStateInternal;
export type SetFile = SetFileInternal;
export type RemoveFile = RemoveFileInternal;
export type FileSystemReducerAction = FileSystemReducerActionInternal;
export type SetDirs = SetDirsInternal;
export type SetPendingDelete = SetPendingDeleteInternal;
export type MoveFolder = MoveFolderInternal;
export type SetPendingRename = SetPendingRenameInternal;
export type RemoveChild = RemoveChildInternal;
export type AddChild = AddChildInternal;
export type SetLoaded = SetLoadedInternal;
export type SetChildren = SetChildrenInternal;
export type SetLoading = SetLoadingInternal;
export type SetExpandedMany = SetExpandedManyInternal;
export type SetExpanded = SetExpandedInternal;
export type FileStateRecord = FileStateRecordInternal;

const initialState: FileSystemReducerState = {};

function isPageCode(fileSystemRecord: Partial<FileStateRecord>) {
  return !('id' in fileSystemRecord && 'descriptor' in fileSystemRecord);
}

const setChildren = (
  state: FileSystemReducerState,
  action: Omit<SetChildren, 'type'>,
) => {
  const childIds: string[] = [];
  _.forEach(action.children, (child) => {
    const itemFromState = state[child.location];
    const item = itemFromState || fileToTreeItem(child);
    state[item.id] = item;
    childIds.push(item.id);
  });
  if (childIds.length) {
    state[action.fileId].childIds = new Set(childIds);
  }
  state[action.fileId].loaded = true;
};

const addChild = (state: FileSystemReducerState, action: AddChild) => {
  const child = fileToTreeItem(action.child);
  if (action.pendingCreation) {
    child.pendingCreation = true;
  }

  return {
    ...state,
    [child.id]: child,
    [action.parentFolderId]: {
      ...state[action.parentFolderId],
      childIds: new Set([...state[action.parentFolderId].childIds, child.id]),
    },
  };
};

const removeChild = (state: FileSystemReducerState, action: RemoveChild) => {
  const { childId, fileId } = action;
  state[fileId].childIds.delete(childId);
};

const moveFolder = (state: FileSystemReducerState, action: MoveFolder) => {
  const newState: FileSystemReducerState = {};
  const keys = Object.keys(state);

  keys.forEach((fileId: string) => {
    const newKey = fileId.startsWith(action.oldLocation)
      ? fileId.replace(action.oldLocation, action.newLocation)
      : fileId;
    newState[newKey] = state[fileId];
  });

  for (const fileId in newState) {
    const entry = newState[fileId];
    if (isPageCode(entry)) {
      continue;
    }

    const newDescriptor = { ...entry.descriptor };
    if (newDescriptor.location.startsWith(action.oldLocation)) {
      newDescriptor.location = newDescriptor.location.replace(
        action.oldLocation,
        action.newLocation,
      );
    }
    if (newDescriptor.location === action.newLocation) {
      const newPath = action.newLocation.split('/');
      newDescriptor.name = newPath[newPath.length - 2];
    }
    newState[fileId].id = decodeURIComponent(fileId);
    newState[fileId].descriptor = newDescriptor;
    newState[fileId].pendingRename = null;
    newState[fileId].childIds = new Set(
      [...entry.childIds].map((child: string) =>
        child.startsWith(action.oldLocation)
          ? child.replace(action.oldLocation, action.newLocation)
          : child,
      ),
    );
  }
  return newState;
};

const setDirs = (state: FileSystemReducerState, action: SetDirs) => {
  _.forEach(action.dirs, ({ file, children }) => {
    const item = fileToTreeItem(file);
    const existing = state[item.id];
    state[item.id] = _.defaults(existing, item);
    setChildren(state, {
      fileId: file.location,
      children,
    });
  });
};

const FileNodeRecord = {
  // eslint-disable-line new-cap
  id: null,
  descriptor: null,
  pendingRename: null,
  pendingDelete: false,
  pendingCreation: false,
  expanded: false,
  loading: false,
  loaded: false,
  childIds: new Set(),
};

function fileToTreeItem(file: FileDescriptor) {
  return {
    ...FileNodeRecord,
    id: decodeURIComponent(file.location),
    descriptor: file,
  };
}

const fileSystemReducer = (
  state = initialState,
  action: FileSystemReducerAction,
) => {
  switch (action.type) {
    case ActionType.SET_FILE:
      const item = fileToTreeItem(action.file);
      return {
        ...state,
        [item.id]: item,
      };
    case ActionType.REMOVE_FILE:
      const { [action.fileId]: fileIdToRemove, ...rest } = state;
      return { ...rest };
    case ActionType.SET_EXPANDED:
      return {
        ...state,
        [action.fileId]: {
          ...state[action.fileId],
          expanded: action.expanded,
        },
      };
    case ActionType.SET_EXPANDED_MANY:
      _.forEach(action.fileIds, (fileId) => {
        state[fileId] = { ...state[fileId], expanded: action.expanded };
      });
      return { ...state };
    case ActionType.SET_LOADING:
      return {
        ...state,
        [action.fileId]: {
          ...state[action.fileId],
          loading: action.loading,
        },
      };
    case ActionType.SET_CHILDREN:
      setChildren(state, action);
      return { ...state };
    case ActionType.SET_LOADED:
      return {
        ...state,
        [action.fileId]: {
          ...state[action.fileId],
          loaded: action.loaded,
        },
      };
    case ActionType.ADD_CHILD:
      return addChild(state, action);
    case ActionType.REMOVE_CHILD:
      removeChild(state, action);
      return { ...state };
    case ActionType.SET_PENDING_RENAME:
      return {
        ...state,
        [action.fileId]: {
          ...state[action.fileId],
          pendingRename: action.pendingRename,
        },
      };
    case ActionType.MOVE_FOLDER:
      return { ...moveFolder(state, action) };
    case ActionType.SET_PENDING_DELETE:
      return {
        ...state,
        [action.fileId]: {
          ...state[action.fileId],
          pendingDelete: action.pendingDelete,
        },
      };
    case ActionType.SET_DIRS:
      setDirs(state, action);
      return state;

    case ActionType.SET_FILES_LOADED:
      const newFiles = action.filesWithModels.reduce(
        (result: FileSystemReducerState, { descriptor }) => {
          const treeItem = fileToTreeItem(descriptor);
          result[treeItem.id] = treeItem;
          return result;
        },
        {},
      );
      return {
        ...state,
        ...newFiles,
      };
    default:
      return state;
  }
};

export default fileSystemReducer;
