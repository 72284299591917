import { OnboardingState } from '../types/GithubTypes';
import { AnyAction } from 'redux';
import {
  LOADING_ONBOARDING_STATUS,
  ON_BOARDING_STATUS_RECEIVED,
  ON_BOARDING_ERROR,
  USER_ACCEPTED_GITHUB_TERMS,
  NEW_COMMITS_RECEIVED,
} from './actions';
import { GithubOnboardingState } from './index';

const initialOnboardingState: GithubOnboardingState = {
  onboardingState: OnboardingState.GITHUB_DISABLED,
  onboardingError: null,
  isLoadingOnboardingStatus: false,
};

export const onboardingStatusReducer = (
  state = initialOnboardingState,
  action: AnyAction,
): GithubOnboardingState => {
  switch (action.type) {
    case ON_BOARDING_STATUS_RECEIVED: {
      const newState = handleOnBoardingStatusReceived(action);
      const shouldClearState =
        newState.onboardingState === OnboardingState.GITHUB_DISABLED;
      if (shouldClearState) {
        return initialOnboardingState;
      }
      return {
        ...state,
        ...handleOnBoardingStatusReceived(action),
      };
    }
    case ON_BOARDING_ERROR: {
      return {
        ...state,
        onboardingError: action.errorType,
      };
    }
    case NEW_COMMITS_RECEIVED: {
      return {
        ...state,
        commits: action.commits,
      };
    }
    case LOADING_ONBOARDING_STATUS: {
      return {
        ...state,
        isLoadingOnboardingStatus: action.value,
      };
    }
    default:
      return state;
  }
};

function handleOnBoardingStatusReceived(action: AnyAction) {
  const {
    onboardingState,
    gitHubAuthorized,
    gitHubRepositoryCreated,
    gitHubEnabled,
  } = action.value;
  switch (onboardingState) {
    case OnboardingState.GITHUB_DISABLED:
      return {
        onboardingState,
      };
    case OnboardingState.GITHUB_AUTHORIZED:
      return {
        onboardingState,
        githubUser: gitHubAuthorized.user,
      };
    case OnboardingState.GITHUB_REPOSITORY_CREATED:
      return {
        onboardingState,
        githubRepository: gitHubRepositoryCreated.repository,
        isVeloAppInstalled: gitHubRepositoryCreated.gitHubAppHasPermission,
      };
    case OnboardingState.GITHUB_ENABLED:
      return {
        onboardingState,
        githubRepository: gitHubEnabled.repository,
        commitHistoryUrl: gitHubEnabled.commitHistoryUrl,
        commits: gitHubEnabled.commits,
      };
    default: {
      console.warn('Unknown github onboarding state');
      return initialOnboardingState;
    }
  }
}

export const acceptedGithubTermsReducer = (
  state: boolean = false,
  action: AnyAction,
) => {
  switch (action.type) {
    case USER_ACCEPTED_GITHUB_TERMS:
      return true;
    default:
      return state;
  }
};
