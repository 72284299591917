import { getFileIdFromPageId } from '@wix/wix-code-common';
import {
  FileDescriptor,
  PageEditorReducerState,
  CodeStateReader,
} from '@wix/wix-code-plugin-contracts';
import models from '@/legacy/core/services/modelsApi';
import { InstalledNpmPkg } from '@/toExtract/packages/packagesModalContext';
import { consts } from '@wix/wix-code-consts';
import { FilesSelectionReducerState } from './filesSelectionReducer';
import { FilesReducerState } from './filesReducer';
import { packageJsonReducerState } from './packageJsonReducer';
import { FileSystemReducerState } from './fileSystemReducer';
import { FilesListenersState } from './filesListenersReducer';

function getFileSelection(
  state: { filesSelection: FilesSelectionReducerState },
  fileId: string,
) {
  return state.filesSelection[fileId];
}

function getFile(state: { files: FilesReducerState }, fileId: string) {
  return state.files[fileId];
}

function getFileModelId(state: { files: FilesReducerState }, fileId: string) {
  return state.files[fileId]?.modelId;
}

function getFileDescriptorFromModelId(
  state: { files: FilesReducerState },
  modelId: string,
) {
  const fileId = Object.keys(state.files).find(
    (key: string) => state.files[key].modelId === modelId,
  );
  if (fileId) {
    const foundFile = state.files[fileId];
    if (foundFile) {
      return foundFile.descriptor;
    }
  }
}

function getFilesByPrefix(state: { files: FilesReducerState }, prefix: string) {
  const allFiles = Object.keys(state.files)
    .filter((key) => key.startsWith(prefix))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: state.files[key] });
    }, {});
  return allFiles;
}

function getPageFileContent(
  state: { files: FilesReducerState },
  pageId: string,
) {
  const fileId = getFileIdFromPageId(pageId);
  const modelId = getFileModelId(state, fileId);
  if (!modelId) {
    throw new Error(`Missing modelId for pageId: ${pageId}`);
  }
  const model = models.get({ modelId });
  return model.getValue();
}

function getNpmPackages(state: {
  packageJson: packageJsonReducerState;
}): InstalledNpmPkg[] {
  return state.packageJson.npmPackages;
}

function getCodeReusePkgs(state: { packageJson: packageJsonReducerState }) {
  return state.packageJson.codeReusePkgs;
}

function getFiles(state: { files: FilesReducerState }) {
  return state.files;
}

const getFileListeners = (
  state: { filesListeners: FilesListenersState },
  fileId: string,
) => {
  return state.filesListeners[fileId];
};

function getFileSystemEntry(
  state: { fileSystemStructure: FileSystemReducerState },
  fileId: AnyFixMe,
) {
  return state.fileSystemStructure[fileId];
}

function getFileSystemParentOf(
  state: { fileSystemStructure: FileSystemReducerState },
  fileId: string,
) {
  let parent = null as any;
  const fileSysytemStructure = Object.values(state.fileSystemStructure);
  fileSysytemStructure.forEach(function (entry: AnyFixMe) {
    const childIds = entry.childIds;
    if (childIds && childIds.has(fileId)) {
      parent = entry;
      return false;
    }
  });
  return parent;
}

function getParentIds(
  state: { fileSystemStructure: FileSystemReducerState },
  fileId: AnyFixMe,
): AnyFixMe {
  const parent = getFileSystemParentOf(state, fileId);
  return parent ? [parent.id, ...getParentIds(state, parent.id)] : [];
}

function getFileContentChangedBiReported(
  state: { files: FilesReducerState },
  fileId: AnyFixMe,
) {
  return state.files[fileId]?.contentChangedBiReported;
}

function getAllFilesLoaded(state: {
  fileSystemStructure: FileSystemReducerState;
}) {
  return state.fileSystemStructure.loaded;
}

function isPageCollapsed(
  state: { pageEditor: PageEditorReducerState },
  itemId: AnyFixMe,
) {
  return state.pageEditor.collapsedItems.includes(itemId);
}

const getChildrenFileDescriptors = (
  state: { fileSystemStructure: FileSystemReducerState },
  parentId: string,
) => {
  const parentEntry = getFileSystemEntry(state, parentId);
  let children = [...parentEntry.childIds];
  children = children.filter(
    (child: string) =>
      child !== `backend/${consts.WIX_CODE_PACKAGE_JSON_FILE_NAME}`,
  );
  const childrenDescriptors = children.map((childPath: string) => {
    const childEntry = getFileSystemEntry(state, childPath);
    const descriptor = childEntry.descriptor;
    return descriptor;
  });
  return childrenDescriptors;
};

const getDirs = (state: {
  fileSystemStructure: FileSystemReducerState;
}): FileDescriptor[] => {
  return Object.values(state.fileSystemStructure)
    .filter(({ descriptor }) => {
      return !!descriptor?.directory;
    })
    .map(({ descriptor }) => descriptor);
};

const codeStateReader: CodeStateReader = {
  getFileSystemEntry,
  getFileSystemParentOf,
  getFile,
  getFiles,
  getFileSelection,
  getFileListeners,
  getPageFileContent, // Unofficial, but de-facto API used by AppBuilder - don't delete without investigating
  getNpmPackages,
  getCodeReusePkgs,
  getFileContentChangedBiReported,
  getFileModelId,
  getFileDescriptorFromModelId,
  getAllFilesLoaded,
  isPageCollapsed,
  getParentIds,
  getFilesByPrefix,
  getChildrenFileDescriptors,
  getDirs,
};

export default codeStateReader;
