import { ActionType } from '@/infra/redux-state/actions/types';
import {
  Results,
  SearchInAllFilesDone,
  SearchInAllFilesQueryChanged,
} from './searchReducer';

function searchQueryChanged({
  query,
}: {
  query: string;
}): SearchInAllFilesQueryChanged {
  return {
    type: ActionType.SEARCH_IN_ALL_FILES_QUERY_CHANGED,
    query,
  };
}

function searchDone({ results }: { results: Results[] }): SearchInAllFilesDone {
  return {
    type: ActionType.SEARCH_IN_ALL_FILES_DONE,
    results,
  };
}

export default {
  searchQueryChanged,
  searchDone,
};
