import React from 'react';
import { PredefinedSidebarTabs } from '@wix/wix-code-plugin-contracts';
import FilesIcon from '../icons/site_code.svg';
import { ClassicFileTab } from '../filesTree/ClassicFileTab';

export const panelHeaderData = {
  infoLinkText: 'Left_Tree_Components_Tooltip_PublicAndBackend_Link_Text',
  infoLinkUrl: 'Left_Tree_Components_Tooltip_PublicAndBackend_Link_URL',
  infoText: 'Left_Tree_Components_Tooltip_PublicAndBackend',
  label: 'Left_Tree_Components_Title_PublicAndBackend',
};

export const createSiteCodeTab = () => ({
  shouldShowTab: () => true,
  id: PredefinedSidebarTabs.SITE_CODE,
  dataHook: `${PredefinedSidebarTabs.SITE_CODE}_tab`,
  symbol: <FilesIcon />,
  creator: () => <ClassicFileTab />,
  ...panelHeaderData,
});
