const dataHooks = {
  terminal: {
    copyCodeButton: 'terminal-copy-code-button',
    confirmCircle: 'terminal-confirmCircle',
    tooltip: 'terminal-tooltip',
    command: 'terminal-command',
    container: 'terminal-container',
  },
};

export default dataHooks;
