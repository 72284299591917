import {
  AvailableCodeReusePkg,
  InstalledCodeReusePkg,
  PackageInstallationStatus,
} from '../packagesModalContext';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

export interface PackagesViewState {
  packageJsonModifierCounter: number;
  installedCodeReusePkgs: {
    byId: Record<string, InstalledCodeReusePkg>;
  };
  availableCodeReusePkgs: {
    byId: Record<string, AvailableCodeReusePkg>;
  };
  codeReusePkgsByOthersMetadata: {
    byName: Record<string, AvailableCodeReusePkg>;
  };
}

const initialState: PackagesViewState = {
  packageJsonModifierCounter: 0,
  installedCodeReusePkgs: {
    byId: {},
  },
  availableCodeReusePkgs: {
    byId: {},
  },
  codeReusePkgsByOthersMetadata: {
    byName: {},
  },
};

const slice = createSlice({
  name: 'packagesView',
  initialState,
  reducers: {
    packageJsonModified(draft) {
      draft.packageJsonModifierCounter++;
    },

    setInstalledCodeReusePkgs(
      draft,
      action: PayloadAction<InstalledCodeReusePkg[]>,
    ) {
      const codeReusePkgs = action.payload;
      draft.installedCodeReusePkgs.byId = _.keyBy(codeReusePkgs, (p) => p.id);
    },

    addCodeReusePkg(draft, action: PayloadAction<InstalledCodeReusePkg>) {
      const codeReusePkg = action.payload;
      draft.installedCodeReusePkgs.byId[codeReusePkg.id] = codeReusePkg;
    },

    updateInstalledCodeReusePkg(
      draft,
      action: PayloadAction<{
        oldPkg: InstalledCodeReusePkg;
        newPkg: InstalledCodeReusePkg;
      }>,
    ) {
      const { oldPkg, newPkg } = action.payload;
      _.unset(draft.installedCodeReusePkgs.byId, oldPkg.id);
      draft.installedCodeReusePkgs.byId[newPkg.id] = newPkg;
    },

    removeCodeReusePkg(draft, action: PayloadAction<InstalledCodeReusePkg>) {
      const pkg = action.payload;
      _.unset(draft.installedCodeReusePkgs.byId, pkg.id);

      const available = draft.availableCodeReusePkgs.byId[pkg.id];
      available && (available.status = 'NOT_INSTALLED');
    },
    setAvailableCodeReusePkgs(
      draft,
      action: PayloadAction<{
        userPkgs: AvailableCodeReusePkg[];
        pkgsByOthersMetadata: AvailableCodeReusePkg[];
      }>,
    ) {
      const { userPkgs, pkgsByOthersMetadata } = action.payload;
      draft.availableCodeReusePkgs.byId = _.keyBy(userPkgs, (p) => p.id);
      draft.codeReusePkgsByOthersMetadata.byName = _.keyBy(
        pkgsByOthersMetadata,
        (p) => p.name,
      );
    },

    setAvailableCodeReusePkgStatus(
      draft,
      action: PayloadAction<{
        pkg: AvailableCodeReusePkg;
        status: PackageInstallationStatus;
      }>,
    ) {
      const { pkg, status } = action.payload;
      draft.availableCodeReusePkgs.byId[pkg.id] = { ...pkg, status };
    },
  },
});
export default slice.reducer;

export const packageViewActions = slice.actions;
