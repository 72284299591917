import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { createFilesViewAPI } from './filesViewAPI';

export const FilesViewEntryPoint: EntryPoint = {
  name: 'files view API',
  getDependencyAPIs() {
    return [WixCodeEditorAdapterAPI];
  },
  declareAPIs() {
    return [APIKeys.FilesViewAPI];
  },
  attach(shell: Shell) {
    shell.contributeAPI(APIKeys.FilesViewAPI, () =>
      createFilesViewAPI(shell, shell.getAPI(WixCodeEditorAdapterAPI)),
    );
  },
  extend(_shell: Shell) {},
};
