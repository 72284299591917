const FORMAT_CODE_BUTTON_TOOLTIP = 'format-code-button-tooltip';
const FORMAT_CODE_BUTTON = 'format-code-button';
const RUN_CODE_BUTTON = 'run-code-button';
const RUN_CODE_BUTTON_TOOLTIP = 'run-code-button-tooltip';
export const DATA_HOOKS = {
  FORMAT_CODE_BUTTON_TOOLTIP,
  FORMAT_CODE_BUTTON,
  RUN_CODE_BUTTON,
  RUN_CODE_BUTTON_TOOLTIP,
};
