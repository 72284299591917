import {
  SlotKey,
  SlotRenderer,
  ExtensionSlot,
} from '@wix/wix-code-repluggable';
import React from 'react';

type FooterSlotContribution = (props: {}) => React.ReactNode;
export type FooterSlot = ExtensionSlot<FooterSlotContribution>;

export const CodeEditorFooterSlot: SlotKey<FooterSlotContribution> = {
  name: 'Code Editor Footer Slot',
};

interface CodeEditorFooterProps {
  footerSlot: ExtensionSlot<FooterSlotContribution>;
}
export const CodeEditorFooter = ({ footerSlot }: CodeEditorFooterProps) => (
  <SlotRenderer slot={footerSlot} />
);
