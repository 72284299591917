import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import {
  FilesViewAPI,
  FileNameTransformer,
  FileNameTransformerParams,
} from '@wix/wix-code-plugin-contracts';
import { Shell, SlotKey } from '@wix/wix-code-repluggable';

export const fileNameTransformerSlot: SlotKey<FileNameTransformer> = {
  name: 'File Name Transformer Slot',
};

export const createFilesViewAPI = (
  shell: Shell,
  _wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI,
): FilesViewAPI => {
  const transformerSlot = shell.declareSlot(fileNameTransformerSlot);
  return {
    contributeFileNameTransformer: (
      contributingShell: Shell,
      transformer: FileNameTransformer,
      condition?: () => boolean,
    ) => {
      transformerSlot.contribute(contributingShell, transformer, condition);
    },
    fileNameTransformer: (
      fileNameTransformerParams: FileNameTransformerParams,
    ) => {
      const transformerSlotItem = transformerSlot.getSingleItem();
      return transformerSlotItem
        ? transformerSlotItem.contribution(fileNameTransformerParams)
        : fileNameTransformerParams.originalTabTitle;
    },
  };
};
