import React, { useContext } from 'react';
import cx from 'classnames';
import s from './PkgActionButton.scss';
import { Button, Tooltip, RichText } from '@wix/wix-base-ui';
import { PackagesModalContext } from '../../../../../packagesModalContext';
import dataHooks from '../../../../../dataHooks';
import biEvents from '@/legacy/bi/events';
import {
  getVersionWithoutPatch,
  isInstalledTestVersion,
} from '../../../../../utils';
import { PackageItemContext } from '../../PackageItemContext';

type UpdateButtonProps = { disabled?: boolean };

export const UpdateButton: React.FC<UpdateButtonProps> = ({ disabled }) => {
  const { t, sendBi, packagesService } = React.useContext(PackagesModalContext);

  const { isCodeReusePackage, installedPkg, availablePkg, pkgName } =
    useContext(PackageItemContext);

  const BI_ITEM_TYPE = isCodeReusePackage ? 'CODE_REUSE' : 'NPM';

  const updatePkgWithBi = () => {
    sendBi(biEvents.PKG_QUICK_ACTION_CLICKED, {
      item_name: pkgName,
      item_version: availablePkg.version,
      item_type: BI_ITEM_TYPE,
      category: 'update_version',
    });
    packagesService.updatePkg(pkgName);
  };

  const upgradableVersion = isCodeReusePackage
    ? getVersionWithoutPatch(availablePkg.version)
    : availablePkg.upgradableVersion;

  const upgradeKey = isInstalledTestVersion(installedPkg)
    ? 'Package_Manager_Section_Manage_Installed_Switch_To_Button'
    : 'Package_Manager_Section_Manage_Installed_UpdateAvailable_Button';

  return (
    <Tooltip
      shouldTranslate={false}
      content={
        <RichText>
          {t('Package_Manager_Section_Manage_Installed_UpdateAvailable_Text')}
        </RichText>
      }
      translate={false}
    >
      <Button
        className={cx(['btn-sm', 'btn-inverted', s.updateButton])}
        onClick={updatePkgWithBi}
        dataHook={dataHooks.PKG_ACTION_BUTTON}
        disabled={disabled}
      >
        {t(upgradeKey, { versionNumber: upgradableVersion })}
      </Button>
    </Tooltip>
  );
};
