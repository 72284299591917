const SYNC_SITE_DELAY_CALL_MS = 2000;
const SYNC_SITE_DEBOUNCE_MS = 2000;
const WIX_IDE_DOMAIN = 'ide.wix-code.com';
const SYNC_SITE_OLD_HOSTNAME =
  'editor.wix-code.com/notify/_supervisor/syncSite' as const;
const SYNC_SITE_HOSTNAME =
  `${WIX_IDE_DOMAIN}/notify/_supervisor/syncSite` as const;
const VSCODE_URL = `https://${WIX_IDE_DOMAIN}` as const;

export {
  VSCODE_URL,
  SYNC_SITE_DEBOUNCE_MS,
  SYNC_SITE_DELAY_CALL_MS,
  SYNC_SITE_HOSTNAME,
  SYNC_SITE_OLD_HOSTNAME,
};
