// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".n64wIq{display:flex}.tSlMhr{margin-right:2px}.ZT2qRf{display:flex;justify-content:center;align-items:center;height:250px}.apArU8{margin-top:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerContainer": "n64wIq",
	"marginRight": "tSlMhr",
	"loaderContainer": "ZT2qRf",
	"marginTop": "apArU8"
};
export default ___CSS_LOADER_EXPORT___;
