// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bqCmt7{margin-left:24px}.bF0kC7{display:flex;flex-direction:column;margin-left:24px;margin-right:24px}.z652nd{color:#868aa5 !important}.yEvmlS *{font-size:13px;line-height:13px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commits-history-link": "bqCmt7",
	"commitsHistoryLink": "bqCmt7",
	"commit-container": "bF0kC7",
	"commitContainer": "bF0kC7",
	"commit-texts-container": "z652nd",
	"commitTextsContainer": "z652nd",
	"link-texts-wrapper": "yEvmlS",
	"linkTextsWrapper": "yEvmlS"
};
export default ___CSS_LOADER_EXPORT___;
