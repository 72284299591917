import { WIX_CODE, DATA_BINDING } from '@wix/app-definition-ids';
import { ContentManagerOptions } from '@wix/wix-code-plugin-contracts';
import { APIKeys, Shell } from '@wix/wix-code-repluggable';

interface CreatePlatformAppsApiParams {
  shell: Shell;
}

export const createPlatformAppsAPI = ({
  shell,
}: CreatePlatformAppsApiParams) => {
  const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
  const notifyAppByAppDefId = (appDefId: string, event: any) => {
    const appData = editorAPI.dsRead.platform.getAppDataByAppDefId(appDefId);
    editorAPI.dsActions.platform.notifyApplication(
      appData.applicationId,
      event,
    );
  };

  return {
    notifyDataBinding: (event: any) => notifyAppByAppDefId(DATA_BINDING, event),
    notifyWixCode: (event: any) => notifyAppByAppDefId(WIX_CODE, event),
    refreshSchemasCache: async () => {
      const privateDataBindingAPI =
        await editorAPI.dsRead.platform.getAppPrivateApi(DATA_BINDING);
      await privateDataBindingAPI.refreshSchemasCache();
    },
    listSchemas: async () => {
      const privateDataBindingAPI =
        await editorAPI.dsRead.platform.getAppPrivateApi(DATA_BINDING);
      return privateDataBindingAPI.listSchemas();
    },
    openContentManager: async (options: ContentManagerOptions) => {
      const privateDataBindingAPI =
        await editorAPI.dsRead.platform.getAppPrivateApi(DATA_BINDING);
      return privateDataBindingAPI.openContentManager(options);
    },
  };
};
