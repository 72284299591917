// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WFL92f{display:flex;flex-direction:column;margin-right:30px;margin-left:30px;height:100%}.WFL92f .control-button{flex-shrink:0;width:100%}.N64o33{display:flex;flex-grow:1;align-items:flex-end}.TzWtUV{list-style-type:disc}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": "WFL92f",
	"gap": "N64o33",
	"list-items": "TzWtUV",
	"listItems": "TzWtUV"
};
export default ___CSS_LOADER_EXPORT___;
