import React, { PropsWithChildren } from 'react';
import { platformApps } from '@wix/wix-code-common';
import _ from 'lodash';
import storeManager from '@/infra/redux-state/store/storeManager';
import codeStateReader from '@/infra/redux-state/reducers/codeStateReader';
import bi from '@/legacy/bi/bi';
import devContextUtilsCreator from '@/utils/devContext';
import { popoversApiCreator } from '@/codeEditor/popovers/api';
import { LeftPaneContext } from '../LeftPaneContext';
import { openDeleteFileSystemItemWarningPanel } from '@/legacy/core/components/deleteFileSystemItemWarningPanel/DeleteFileSystemItemWarningPanel';

import { setCodeFilesTab, PredefinedSidebarTabs } from '../tabs';
import { DefaultFileTemplate } from '@/fileSystem/fileTemplates/defaultTemplatesInterface';
// SOS FIX - EDITORX LOAD IDE IN FULL SCREEN.
import { CODE_EDITOR_MODE, EditorAPI } from '@wix/wix-code-plugin-contracts';
import {
  useEditorLegacyAPIs,
  connectToStores,
} from '@wix/wix-code-common-components';

interface FilesTabProps extends FilesTabInnerProps {
  codeFilesTab: PredefinedSidebarTabs;
}

interface FilesTabInnerProps {
  defaultFileTemplate: DefaultFileTemplate;
  codeEditorMode?: CODE_EDITOR_MODE;
}

interface FilesTabEditorReduxStateProps {
  editorAPI: EditorAPI;
  selectedIdeTabId: string;
}

type FilesTabCompProps = FilesTabEditorReduxStateProps & FilesTabInnerProps;

const FilesTabComp: React.FC<FilesTabCompProps> = ({
  editorAPI,
  selectedIdeTabId,
  defaultFileTemplate,
  codeEditorMode = CODE_EDITOR_MODE.CODE_AND_STAGE,
  children,
}: PropsWithChildren<FilesTabCompProps>) => {
  const {
    legacyDependenciesAPI: { constants, experiment, util, stateManagement },
  } = useEditorLegacyAPIs();
  const { setFileContext } = devContextUtilsCreator({ constants });
  const popoversApi = popoversApiCreator({ stateManagement });
  const store = storeManager.getStore();

  const focusOnLoad = (fileId: AnyFixMe) =>
    editorAPI.wixCode.codeEditorApi.performActionOnMount(
      fileId,
      (api: AnyFixMe) => api && api.focus(),
    );

  const onFileSelected = (fileId: AnyFixMe) => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_ITEM, {
      item_name: fileId,
      item_type: 'file',
    });
    editorAPI.panelManager.closeAllPanels();
    setFileContext(editorAPI, fileId);

    editorAPI.store.dispatch(
      stateManagement.editorPlugins.wixCodeIDE.actions.showIDEWithDsRead({
        dsRead: editorAPI.dsRead,
      }),
    );
    editorAPI.developerMode.ui.idePane.unMinimize();
    editorAPI.selection.deselectComponents();
    focusOnLoad(fileId);
  };

  const onOpenWebmodulePermissions = (fileName: AnyFixMe, filePath: AnyFixMe) =>
    platformApps.notifyWixCode(editorAPI, {
      eventType: 'openWebmodulePermissionsClicked',
      eventPayload: {
        fileName,
        filePath,
        origin: 'ideTree',
      },
    });

  const onFileDelete = (fileId: AnyFixMe) => {
    const ROUTERS_JS_FILE_LOCATION = 'backend/routers.js';
    const entry = codeStateReader.getFileSystemEntry(store.getState(), fileId);
    const fileDescriptor = entry.descriptor;
    if (fileId === ROUTERS_JS_FILE_LOCATION) {
      platformApps.notifyWixCode(editorAPI, {
        eventType: 'deleteRoutersJs',
        eventPayload: {
          origin: 'ideTree',
        },
      });
    } else {
      openDeleteFileSystemItemWarningPanel(editorAPI, {
        panelName: 'deleteFileSystemItemWarningPanel',
        fileName: fileDescriptor.name,
        fileType: fileDescriptor.directory ? 'Folder' : 'File',
        onConfirm: _.partial(onFileDeleteConfirm, fileDescriptor),
        onCancel: () =>
          editorAPI.panelManager.closePanelByName(
            'deleteFileSystemItemWarningPanel',
          ),
      });
    }
  };
  const onFileDeleteConfirm = (fileDescriptor: AnyFixMe) => {
    return editorAPI.wixCode.fileSystem
      .deleteItem(fileDescriptor)
      .catch(function onDeleteError(error: AnyFixMe) {
        editorAPI.panelManager.openPanel(
          'wixCode.panels.fileSystemOperationErrorPanel',
          { error },
        );
      });
  };

  return (
    <LeftPaneContext.Provider
      value={{
        selectedIdeTabId,
        getEditorAPI: () => editorAPI,
        experiment,
        translate: util.translate,
        validate: util.validate,
        constants,
        popoversApi,
        onFileSelected,
        onFileDelete,
        onOpenWebmodulePermissions,
        defaultFileTemplate,
        codeEditorMode,
      }}
    >
      {children}
    </LeftPaneContext.Provider>
  );
};

const mapEditorStateToProps = ({
  editorAPI,
}: AnyFixMe): FilesTabEditorReduxStateProps => {
  const devContext = editorAPI.developerMode.getContext();

  return {
    editorAPI,
    selectedIdeTabId: devContext.data.id,
  };
};

let ConnectedComponent: React.FC<PropsWithChildren<FilesTabInnerProps>> =
  null as any;
export const FilesTab: React.FC<FilesTabProps> = ({
  codeFilesTab,
  defaultFileTemplate,
  children,
  codeEditorMode,
}: PropsWithChildren<FilesTabProps>) => {
  const {
    legacyDependenciesAPI: { util },
  } = useEditorLegacyAPIs();
  if (ConnectedComponent === null) {
    setCodeFilesTab(codeFilesTab);
    ConnectedComponent = connectToStores({
      util,
      mapEditorStateToProps,
      comp: FilesTabComp,
    });
  }
  return (
    <ConnectedComponent
      codeEditorMode={codeEditorMode}
      children={children}
      defaultFileTemplate={defaultFileTemplate}
    />
  );
};
