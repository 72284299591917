import { useTranslation } from '@wix/wix-i18n-config';
import React, { useContext } from 'react';
import { BI } from './constants';
import { MessageModal } from '@wix/wix-base-ui';
import { ExternalDbsModalContext } from './ExternalDbsModalContext';
import { panelHeaderClose } from '@wix/bi-logger-editor/v2';
import { connectExternalDbPanelAction } from '@wix/bi-logger-platform/v2';
import SuccessImage from '../../assets/icons/success.svg';
import { dataHooks } from './dataHooks';

export const SuccessNoCollectionsModal: React.FC = () => {
  const [t] = useTranslation();
  const { panelsAPI, biLoggerAPI } = useContext(ExternalDbsModalContext);
  return (
    <MessageModal
      dataHook={dataHooks.externalDbPanels.messageModal.container}
      onCloseButtonClick={() => {
        biLoggerAPI.report(
          panelHeaderClose({
            panel_name: BI.panels.successNoCollections,
          }),
        );
        panelsAPI.closePanel();
      }}
      title={t('WixCode_Modal_ExternalDatabaseConnection_NoCollections_Title')}
      content={t(
        'WixCode_Modal_ExternalDatabaseConnection_NoCollections_Description',
      )}
      illustration={<SuccessImage />}
      primaryButtonText={t(
        'WixCode_Modal_ExternalDatabaseConnection_NoCollections_PrimaryCTA',
      )}
      primaryButtonOnClick={async () => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: BI.panels.successNoCollections,
            action_type: BI.actions.click,
            button_name: BI.buttons.primaryCTA,
          }),
        );
        panelsAPI.closePanel();
      }}
    />
  );
};
