(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("react"), require("prop-types"), require("reactDOM"), require("santa-editor-symbols"), require("baseUILib"), require("codeEditor/codeEditor"), require("coreUtilsLib"), require("create-react-class"), require("santa-editor-utils"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash", "react", "prop-types", "reactDOM", "santa-editor-symbols", "baseUILib", "codeEditor/codeEditor", "coreUtilsLib", "create-react-class", "santa-editor-utils"], factory);
	else if(typeof exports === 'object')
		exports["wixCodeClassicEditor"] = factory(require("lodash"), require("react"), require("prop-types"), require("reactDOM"), require("santa-editor-symbols"), require("baseUILib"), require("codeEditor/codeEditor"), require("coreUtilsLib"), require("create-react-class"), require("santa-editor-utils"));
	else
		root["wixCodeClassicEditor"] = factory(root["lodash"], root["react"], root["prop-types"], root["reactDOM"], root["santa-editor-symbols"], root["baseUILib"], root["codeEditor/codeEditor"], root["coreUtilsLib"], root["create-react-class"], root["santa-editor-utils"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__50467__, __WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__95099__, __WEBPACK_EXTERNAL_MODULE__61598__, __WEBPACK_EXTERNAL_MODULE__58528__, __WEBPACK_EXTERNAL_MODULE__68809__, __WEBPACK_EXTERNAL_MODULE__75147__, __WEBPACK_EXTERNAL_MODULE__74112__, __WEBPACK_EXTERNAL_MODULE__84171__, __WEBPACK_EXTERNAL_MODULE__79378__) {
return 