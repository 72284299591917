import * as React from 'react';
import dataHooks from '../../../dataHooks';
import {
  CodeReusePkgTreeShouldAlwaysShowSuffixParams,
  CodeReusePkgSuffix,
} from './treeSuffix/CodeReusePkgSuffix';
import { VELO_TREE_QUICK_ACTIONS } from './treeSuffix/CodeReusePkgSuffixContextMenu';
import { FileSubTreeDataSource } from '../../../filesTree/FileSubTree';
import { codeReuseFsSetExpanded } from '@/toExtract/packages/codeReuseFs/codeReuseFsActions';
import { connect } from 'react-redux';
import { CodeReusePkgFirstLevelTree } from './CodeReusePkgFirstLevelTree';

import bi from '@/legacy/bi/bi';
import { CodeReusePkgTreeContext } from './CodeReusePkgTreeContext';
import { PrivateAppsContext } from '../../PrivateAppsProvider';
import { PkgTreeContext } from '../PkgTreeContext';
import { FileTreeListItem } from '../FileTreeListItem';
import {
  wixCodeReduxContext,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import { experimentUtils } from '@wix/wix-code-common';

interface CodeReuseSubTreeReduxDispatchProps {
  setExpandedFile: (params: {
    name: string;
    fileId: string;
    expanded: boolean;
  }) => void;
}

export interface CodeReuseSubTreeProps {
  pkgName: string;
  dataSource: FileSubTreeDataSource;
  depth: number;
  isRoot: boolean;
  labelOverride?: string;
  onFileSelected: (id: string) => void;
  nodeDoubleClick: (fileId: string) => any;
  shouldAlwaysShowSuffix: (
    params: CodeReusePkgTreeShouldAlwaysShowSuffixParams,
  ) => boolean;
  menuActionHandler: (action: VELO_TREE_QUICK_ACTIONS) => void;
  dataHook?: string;
}

type CodeReuseSubTreeCompProps = CodeReuseSubTreeProps &
  CodeReuseSubTreeReduxDispatchProps;

const CodeReuseSubTreeComp: React.FC<CodeReuseSubTreeCompProps> = (props) => {
  const {
    pkgName,
    dataHook,
    depth,
    shouldAlwaysShowSuffix,
    labelOverride,
    isRoot,
    dataSource,
    menuActionHandler,
    onFileSelected,
    nodeDoubleClick,
    setExpandedFile,
  } = props;
  const { expanded, childItems, isFolder, id } = dataSource;
  const { editorAPI } = useEditorLegacyAPIs();
  const [isTreeHovered, setTreeHovered] = React.useState(false);
  const [isContextMenuOpen, setContextMenuOpen] = React.useState(false);
  const { packagesService, isPrivateScope, isByOthers, pkg } = React.useContext(
    CodeReusePkgTreeContext,
  );
  const { isBlocksCombinedMode } = React.useContext(PkgTreeContext);
  const { installedApps } = React.useContext(PrivateAppsContext);
  const isVeloLite = experimentUtils.isLiteEditor();

  const expand = (fileId: string) => {
    if (isBlocksCombinedMode && (isPrivateScope || isByOthers)) {
      const appInfo = installedApps.find((app) =>
        app.components?.find(
          (comp) =>
            comp.type === 'CODE_PACKAGE' &&
            (comp?.data as any)?.importName === pkgName,
        ),
      );
      if (!appInfo) {
        return;
      }
      packagesService.loadApp(appInfo);
    }
    setExpandedFile({ name: pkgName, fileId, expanded: true });
  };

  const collapse = (fileId: string) => {
    setExpandedFile({ name: pkgName, fileId, expanded: false });
  };

  const onNodeClick = () => {
    if (isFolder) {
      sendFolderClickBi();
      if (expanded) {
        collapse(id);
      } else {
        expand(id);
      }
    } else {
      onFileSelected(id);
    }
  };

  const sendFolderClickBi = () => {
    if (isRoot) {
      editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_SECTION, {
        section_name: 'packages',
        sub_section: pkgName,
        action: expanded ? 'close' : 'open',
      });
    } else {
      editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_ITEM, {
        item_name: id,
        item_type: 'folder',
      });
    }
  };

  const actionSelectedHandler = (action: VELO_TREE_QUICK_ACTIONS) => {
    setContextMenuOpen(false);
    menuActionHandler(action);
  };

  const genChildProps = (child: AnyFixMe): CodeReuseSubTreeProps => {
    // values here are taken out or props again to create rest - we know they already exist.
    // eslint-disable-next-line no-shadow
    const {
      depth: latestDepth,
      labelOverride: labelOverrideLatest,
      dataHook: dataHookLatest,
      ...rest
    } = props;
    return {
      ...rest,
      isRoot: false,
      depth: latestDepth + 1,
      dataSource: child,
    };
  };

  const alwaysShowSuffix = shouldAlwaysShowSuffix({
    isRoot,
    isTreeHovered,
    isContextMenuOpen,
  });

  const suffix = (
    <CodeReusePkgSuffix
      isContextMenuOpen={isContextMenuOpen}
      handleContextMenuToggle={setContextMenuOpen}
      actionSelectedHandler={actionSelectedHandler}
      dataSource={dataSource}
      isRoot={isRoot}
      isTreeHovered={isTreeHovered}
      canEdit={pkg.canEdit}
    />
  );

  const rootDataHook = dataHook
    ? dataHook
    : isRoot
    ? dataHooks.TREE_ROOT
    : undefined;

  return (
    <div
      data-hook={rootDataHook}
      onMouseEnter={() => setTreeHovered(true)}
      onMouseLeave={() => setTreeHovered(false)}
    >
      <FileTreeListItem
        dataSource={dataSource}
        depth={depth}
        alwaysShowSuffix={alwaysShowSuffix}
        suffix={suffix}
        labelOverride={labelOverride}
        onNodeClick={onNodeClick}
        nodeDoubleClick={nodeDoubleClick}
        isContextMenuOpen={isContextMenuOpen}
      />

      {expanded && isRoot && !isVeloLite && (
        <CodeReusePkgFirstLevelTree {...props} />
      )}

      {expanded && !isRoot && !isVeloLite && (
        <ul data-aid="folder-items" key="subtree">
          {childItems.map((child) => (
            <li key={'child-' + child.id}>
              <CodeReuseSubTree {...genChildProps(child)} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const mapDispatchToProps: CodeReuseSubTreeReduxDispatchProps = {
  setExpandedFile: codeReuseFsSetExpanded,
};

export const CodeReuseSubTree: React.FC<CodeReuseSubTreeProps> = connect(
  null,
  mapDispatchToProps,
  null,
  { context: wixCodeReduxContext },
)(CodeReuseSubTreeComp);
