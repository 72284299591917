import * as React from 'react';
import { connect } from 'react-redux';
import { Preloader, TreeSectionDivider } from '@wix/wix-base-ui';
import bi from '@/legacy/bi/bi';
import codeStateReader from '@/infra/redux-state/reducers/codeStateReader';
import { NpmPkgItem } from './NpmPkgItem';
import dataHooks from '../../../dataHooks';
import {
  isSectionExpanded,
  isSectionLoaded,
} from '../../../filesTree/filesTree/treeSectionSelectors';
import {
  treeSectionActions,
  STATIC_SECTIONS,
  TreeSectionSetExpandedParams,
} from '../../../filesTree/filesTree/treeSectionReducer';
import { nodeDoubleClick } from '@/infra/redux-state/actions/fileTreeActions';
import {
  ReadOnlyModeContext,
  TreeContextMenu,
  useTranslation,
  useEditorLegacyAPIs,
  wixCodeReduxContext,
} from '@wix/wix-code-common-components';
import { FileTreeParagraphEmptyState } from '../../../filesTree/FileTreeParagraphEmptyState';
import { useContext } from 'react';
import { consts } from '@wix/wix-code-consts';
import { PkgTreeContext } from '../PkgTreeContext';
import { PACKAGE_VIEW_QUICK_ACTIONS } from '../pkgTreeQuickActions';
import { bindActionCreators } from 'redux';
import { InstalledNpmPkg } from '@/toExtract/packages/packagesModalContext';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AppState } from '@/infra/redux-state/reducers/rootReducer';
import { AppMarket } from '@wix/wix-ui-icons-common/classic-editor';
import { CODE_EDITOR_MODE } from '@wix/wix-code-plugin-contracts';

interface NpmPkgTreeProps {
  codeEditorMode?: CODE_EDITOR_MODE;
}

interface NpmPkgTreeReduxProps {
  dependencies: InstalledNpmPkg[];
  loading: boolean;
  expanded: boolean;
}

interface NpmPkgTreeReduxDispatchProps {
  collapse: ActionCreatorWithPayload<TreeSectionSetExpandedParams, string>;
  expand: ActionCreatorWithPayload<TreeSectionSetExpandedParams, string>;
  doubleClick: (id: string) => void;
}

type NpmPkgTreeCompProps = NpmPkgTreeProps &
  NpmPkgTreeReduxProps &
  NpmPkgTreeReduxDispatchProps;

const NpmPkgTreeComp: React.FC<NpmPkgTreeCompProps> = ({
  loading,
  collapse,
  expand,
  expanded,
  dependencies,
  doubleClick,
  codeEditorMode = CODE_EDITOR_MODE.CODE_AND_STAGE,
}) => {
  const { editorAPI } = useEditorLegacyAPIs();
  const [t] = useTranslation();
  const { readOnlyMode } = useContext(ReadOnlyModeContext);
  const { actionHandler, isBlocksCombinedMode } = useContext(PkgTreeContext);
  const [isContextMenuOpen, setContextMenuOpen] = React.useState(false);
  const [isTreeHovered, setTreeHovered] = React.useState(false);

  const onOpenNpmPackageManager = () => {
    actionHandler(PACKAGE_VIEW_QUICK_ACTIONS.NEW_NPM_MODULE);
  };

  const onContextMenuClick = (event: React.MouseEvent) => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_PLUS, {
      section_name: 'npm',
    });
    event.stopPropagation();
  };

  const handleContextMenuToggle = (isOpen: boolean) => {
    setContextMenuOpen(isOpen);
  };

  const onClickOpenMenu = () => {
    setTreeHovered(false);
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_PLUS_ITEM, {
      section_name: 'npm',
      menu_entry_name: 'install',
    });
    onOpenNpmPackageManager();
  };

  const contextMenuSections = {
    sections: [
      [
        {
          onClick: onClickOpenMenu,
          icon: isBlocksCombinedMode ? AppMarket : 'add_plus_icon_reg',
          label: t(
            isBlocksCombinedMode
              ? t('Left_Tree_Blocks_Npm_ContextMenu_Install')
              : t('Left_Tree_Components_Category_CodeFiles_Packages_Menu_npm'),
          ),

          automationId: 'action-add-npm-module',
        },
      ],
    ],
  };
  const suffix = loading ? (
    <div className="preloader-container-hack">
      <Preloader className="tiny" />
    </div>
  ) : (
    <div onClick={onContextMenuClick}>
      <TreeContextMenu
        menuClassName="context-menu wix-code-file-tree-dd"
        handleContextMenuToggle={handleContextMenuToggle}
        contextMenuStructure={contextMenuSections}
        contextMenuButton="corvid_tree__context_menu_add"
        isContextMenuOpen={isContextMenuOpen}
        tooltipContent={t('Velo_Concurrent_Editing_Sidebar_ReadOnly_Text')}
        readonly={!!readOnlyMode.sidePanel?.packages}
      />
    </div>
  );

  const alwaysShowSuffix = isContextMenuOpen || isTreeHovered;

  const installFirstModule = () => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_ADD_QUICK_LINK, {
      section_name: 'npm',
    });

    onOpenNpmPackageManager();
  };

  const onNodeClick = () => {
    const pkgJsonId = consts.WIX_CODE_PACKAGE_JSON_FILE_NAME;
    if (loading) {
      return;
    }

    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_SECTION, {
      section_name: 'npm',
      action: expanded ? 'close' : 'open',
    });

    if (expanded) {
      collapse({ sectionName: pkgJsonId });
    } else {
      expand({ sectionName: pkgJsonId });
    }
  };

  const emptyState = (
    <FileTreeParagraphEmptyState
      dataHook={dataHooks.EMPTY_STATE}
      description={t('Left_Tree_Components_Title_Packages_EmptyState')}
      ctaText={t('Left_Tree_Components_Title_Packages_EmptyState_Button')}
      onCtaClick={installFirstModule}
      readonly={!!readOnlyMode.sidePanel?.packages}
    />
  );

  return (
    <div
      data-hook={dataHooks.NPM_PKGS_ROOT}
      onMouseEnter={() => setTreeHovered(true)}
      onMouseLeave={() => setTreeHovered(false)}
    >
      <div data-aid={dataHooks.NPM_PKGS_SECTION}>
        <TreeSectionDivider
          dataHook={dataHooks.SECTION_DIVIDER}
          label={t('Left_Tree_Components_Category_CodeFiles_Packages')}
          collapsed={!expanded}
          onClick={onNodeClick}
          alwaysShowSuffix={alwaysShowSuffix}
          suffix={suffix}
          hideTopBorder={true}
          hideBottomBorder={!expanded}
          size="small"
          shouldTranslate={false}
        />
      </div>
      {expanded && (
        <ul data-hook={dataHooks.NPM_SECTION} className="subtree" key="subtree">
          {dependencies.length === 0
            ? emptyState
            : dependencies.map(({ name, version }) => (
                <li
                  data-hook={dataHooks.NPM_PKG_ID(name)}
                  key={`npm-pkg-item-${name}`}
                >
                  <NpmPkgItem
                    packageName={name}
                    packageVersion={version}
                    nodeDoubleClick={doubleClick}
                    codeEditorMode={codeEditorMode}
                  />
                </li>
              ))}
        </ul>
      )}
    </div>
  );
};

function mapStateToProps(state: AppState) {
  const pkgJsonId = consts.WIX_CODE_PACKAGE_JSON_FILE_NAME;
  const dependencies = codeStateReader.getNpmPackages(state);
  const npmReadmeLoaded = isSectionLoaded(state, STATIC_SECTIONS.NPM_README);
  const expanded = isSectionExpanded(state, pkgJsonId);

  return {
    dependencies,
    expanded,
    loading: !npmReadmeLoaded,
  };
}

const mapDispatchToProps = (
  dispatch: AnyFixMe,
): NpmPkgTreeReduxDispatchProps => {
  return bindActionCreators(
    {
      expand: treeSectionActions.expandTreeSection,
      collapse: treeSectionActions.collapseTreeSection,
      doubleClick: nodeDoubleClick,
    },
    dispatch,
  );
};

export const NpmPkgTree: React.FC<NpmPkgTreeProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { context: wixCodeReduxContext },
)(NpmPkgTreeComp);
