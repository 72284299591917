import React from 'react';
import classNames from 'classnames';
import './console.global.scss';
import { WIX_CODE_SITE_MONITORING } from '@wix/app-definition-ids';
import consoleActionsCreator from '@/infra/redux-state/actions/consoleActions';
import consoleStateReader from '@/infra/redux-state/reducers/consoleStateReader';
import consoleMessageCreator from './message/consoleMessage';
import ConsoleTopBar from './topBar/ConsoleTopBar';
import { openToolDashboard } from '@/utils/openToolDashboard';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';

import bi from '../../../bi/bi';
import { ConsoleEmptyState } from './ConsoleEmptyState/ConsoleEmptyState';
import { CustomScroll } from '@wix/wix-base-ui';
import {
  useEditorLegacyAPIs,
  connectToStores,
} from '@wix/wix-code-common-components';

type AnyFixMe = any;

type ConsoleProps = {
  className: string;
  id: string;
  overrideButtonClickMap: Record<string, () => void>;
};

type CodeDispatchProps = {
  clear: () => void;
  openLiveLogs: () => void;
};

type EditorStateProps = {
  containerState: AnyFixMe;
};

type CodeStateProps = {
  visibleLevels: AnyFixMe;
  messages: AnyFixMe[];
};

type ConsoleCompProps = ConsoleProps &
  CodeDispatchProps &
  EditorStateProps &
  CodeStateProps;

const ConsoleComp: React.FC<ConsoleCompProps> = ({
  visibleLevels,
  clear,
  messages,
  className,
  id,
  containerState,
  overrideButtonClickMap,
  openLiveLogs,
}) => {
  const {
    legacyDependenciesAPI: { util, baseUI },
  } = useEditorLegacyAPIs();
  const customScroll = React.createRef<typeof CustomScroll>();

  const isLevelVisible = (level: AnyFixMe) => {
    return visibleLevels[level];
  };

  const hasMessages = () => {
    return getMessages().length > 0;
  };

  const getMessages = () => {
    return messages.filter((msg: AnyFixMe) => isLevelVisible(msg.logLevel));
  };

  const onDeleteMessages = () => {
    clear();
  };

  const ConsoleMessage = consoleMessageCreator({ baseUI, util });
  return (
    <div
      className={classNames(className, {
        'wix-code-console': true,
      })}
      id={id}
    >
      <ConsoleTopBar
        containerState={containerState}
        overrideButtonClickMap={overrideButtonClickMap}
        onDeleteMessages={onDeleteMessages}
        openLiveLogs={openLiveLogs}
      />
      <div className="console">
        {!hasMessages() && <ConsoleEmptyState />}
        {hasMessages() && (
          <CustomScroll
            key="custom-scroll"
            ref={customScroll}
            heightRelativeToParent="100%"
            addScrolledClass={false}
            keepAtBottom
          >
            <div className="message-container">
              {getMessages().map(
                (message: AnyFixMe, messageIndex: AnyFixMe) => (
                  <ConsoleMessage message={message} key={messageIndex} />
                ),
              )}
            </div>
          </CustomScroll>
        )}
      </div>
    </div>
  );
};

let ConnectedComponent = null as any;
const ConsoleConnected: React.FC<ConsoleProps> = (origProps) => {
  const {
    legacyDependenciesAPI: { stateManagement, util },
  } = useEditorLegacyAPIs();
  const consoleActions = consoleActionsCreator({ stateManagement });
  const mapStateToProps = (state: AnyFixMe) => {
    return {
      visibleLevels: consoleStateReader.getVisibleLevels(state),
      messages: consoleStateReader.getMessages(state),
    };
  };

  const mapEditorStateToProps = () => {
    return ({ editorAPI }: { editorAPI: EditorAPI }) => {
      return {
        containerState: editorAPI.developerMode.ui.consolePane.getState(),
      };
    };
  };

  const mapCodeDispatchToProps: Record<string, Function> = {
    clear: consoleActions.clear,
    openLiveLogs:
      () =>
      (_dispatch: AnyFixMe, _getState: AnyFixMe, editorAPI: EditorAPI) => {
        const item_name = 'live_site_logs';
        editorAPI.bi.event(bi.events.IDE_TOOLBAR_BUTTON_CLICK, {
          item_name,
        });
        openToolDashboard({
          editorAPI,
          util,
          appDefId: WIX_CODE_SITE_MONITORING,
        });
      },
  };

  if (ConnectedComponent === null) {
    ConnectedComponent = connectToStores({
      util,
      mapEditorStateToProps,
      mapCodeStateToProps: mapStateToProps,
      mapCodeDispatchToProps,
      comp: ConsoleComp,
      forwardRef: true,
    });
  }

  return <ConnectedComponent {...origProps} />;
};

const Console = ConsoleConnected;
export default Console;
