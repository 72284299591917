import coreUtilsLib from '@wix/santa-core-utils';
import {
  EditorAPI,
  FileDescriptor,
  FileActions,
} from '@wix/wix-code-plugin-contracts';
import { ChangeContent } from '@/codeEditor/tabs/reducers/ideTabsReducer';
import bi from '@/legacy/bi/bi';
import { consts } from '@wix/wix-code-consts';
import codeStateReader from '../reducers/codeStateReader';
import {
  LoadFileBegin,
  LoadFileCheckState,
  LoadFileEnd,
  LoadFileFailure,
  SetContentChangeBiReported,
  SetFilesLoaded,
} from '../reducers/filesReducer';
import { SetSelection } from '../reducers/filesSelectionReducer';
import { SetLoaded } from '../reducers/fileSystemReducer';
import { Dispatch, ThunkExtraArg } from '@wix/wix-code-common-components';
import { ActionType } from './types';

function _getFileId(context: AnyFixMe) {
  return context.data.id;
}

function _shouldLoadFile(file: AnyFixMe) {
  return (
    !file ||
    file.state === consts.FILE_STATES.VIRTUAL ||
    file.state === consts.FILE_STATES.LOAD_FAILED
  );
}

const overwrittenLanguageByFilePath = (filePath: AnyFixMe) => {
  if (filePath === 'backend/jobs.config') {
    return 'json';
  }
  return undefined; // will be chosen by the file's extension
};

function createFileModel(
  editorAPI: EditorAPI,
  filePath: AnyFixMe,
  fileContent: AnyFixMe,
) {
  return editorAPI.wixCode.models.create({
    path: filePath,
    value: fileContent,
    language: overwrittenLanguageByFilePath(filePath),
  });
}

function wrapLoadFile(
  loadFileFunction: any,
  errorHandler = (e: any) => Promise.reject(e),
) {
  return function (fileId: any) {
    return function (
      dispatch: any,
      getState: any,
      { editorAPI }: ThunkExtraArg,
    ) {
      const traceEnd = editorAPI.dsActions.wixCode.log.trace({
        action: 'loadFile',
        message: { fileId },
      });

      if (!fileId) {
        const error = new Error('fileId must be defined');
        traceEnd({
          message: error,
          level: editorAPI.wixCode.log.levels.ERROR,
        });

        throw error;
      }

      const file = codeStateReader.getFile(getState(), fileId);
      // we assume that we don't have concurrent sessions
      // so we use the cached version of the code instead of reloading it
      // if we do decide to reload, we need to consider that the current
      // [potentially unsaved] content will be overridden
      if (!_shouldLoadFile(file)) {
        traceEnd();
        const modelId = file.modelId;
        const content = modelId
          ? editorAPI.wixCode.models.get({ modelId }).getValue()
          : '';
        return Promise.resolve(content);
      }

      dispatch(beginLoadFile(fileId));

      setTimeout(function () {
        dispatch(loadFileCheckState(fileId));
      }, 1000);

      const descriptor = editorAPI.wixCode.fileSystem.getVirtualDescriptor(
        fileId,
        false,
      );
      return loadFileFunction(editorAPI, descriptor)
        .then(dispatchLoadedFile)
        .catch((e: AnyFixMe) =>
          errorHandler(e).then(dispatchLoadedFile).catch(handleFileLoadFailure),
        );

      function dispatchLoadedFile(fileContent: any) {
        const { modelId, onChange } = createFileModel(
          editorAPI,
          fileId,
          fileContent,
        );
        onChange((content: any) => {
          dispatch(changeContentAction(fileId));
          dispatch(reportContentChangedBi(fileId));
          return dispatch(changeContent(fileId, content));
        });
        dispatch(endLoadFile(fileId, descriptor, modelId));
        traceEnd();

        return fileContent;
      }

      function handleFileLoadFailure(serverResponse: any) {
        dispatch(failLoadFile(fileId));
        traceEnd({
          message: serverResponse,
          level: editorAPI.wixCode.log.levels.ERROR,
        });
        coreUtilsLib.log.error(serverResponse);

        return Promise.reject(serverResponse);
      }
    };
  };
}

function loadFileImplementation(editorAPI: EditorAPI, descriptor: AnyFixMe) {
  return editorAPI.wixCode.fileSystem.readFile(descriptor);
}

function loadFile(fileId: AnyFixMe) {
  return wrapLoadFile(loadFileImplementation)(fileId);
}

function load({ context }: AnyFixMe) {
  const fileId = _getFileId(context);

  return loadFile(fileId);
}

function setFilesContent(files: AnyFixMe) {
  return function (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) {
    const filesWithModels = files.map(
      ({ path: fileId, content: fileContent }: AnyFixMe) => {
        const descriptor = editorAPI.wixCode.fileSystem.getVirtualDescriptor(
          fileId,
          false,
        );

        const file = codeStateReader.getFile(getState(), fileId);
        if (_shouldLoadFile(file)) {
          const { modelId, onChange } = createFileModel(
            editorAPI,
            fileId,
            fileContent,
          );
          onChange((content: AnyFixMe) => {
            dispatch(changeContentAction(fileId));
            dispatch(reportContentChangedBi(fileId));
            return dispatch(changeContent(fileId, content));
          });

          return { fileId, descriptor, modelId };
        }

        editorAPI.wixCode.models.update({
          modelId: file.modelId,
          value: fileContent,
        });

        return { fileId, descriptor, modelId: file.modelId };
      },
    );
    dispatch(setFilesLoaded(filesWithModels));
  };
}

function beginLoadFile(fileId: string): LoadFileBegin {
  return {
    type: ActionType.LOAD_FILE_BEGIN,
    fileId,
  };
}

function loadFileCheckState(fileId: string): LoadFileCheckState {
  return {
    type: ActionType.LOAD_FILE_CHECK_STATE,
    fileId,
  };
}

function endLoadFile(
  fileId: string,
  descriptor: FileDescriptor,
  modelId: string,
): LoadFileEnd {
  return {
    type: ActionType.LOAD_FILE_END,
    fileId,
    descriptor,
    modelId,
  };
}

function failLoadFile(fileId: string): LoadFileFailure {
  return {
    type: ActionType.LOAD_FILE_FAILURE,
    fileId,
  };
}

function setFilesLoaded(
  filesWithModels: SetFilesLoaded['filesWithModels'],
): SetFilesLoaded {
  return {
    type: ActionType.SET_FILES_LOADED,
    filesWithModels,
  };
}

function cacheContent(fileDescriptor: AnyFixMe, content: AnyFixMe) {
  return function cacheContentThunk(
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) {
    const fileId = fileDescriptor.location;
    const file = codeStateReader.getFile(getState(), fileId);

    if (!file) {
      const { modelId, onChange } = createFileModel(editorAPI, fileId, content);
      onChange((value: AnyFixMe) => {
        dispatch(changeContentAction(fileId));
        dispatch(reportContentChangedBi(fileId));
        dispatch(changeContent(fileId, value));
      });
      dispatch(beginLoadFile(fileId));
      dispatch(endLoadFile(fileId, fileDescriptor, modelId));
    } else {
      const modelId = codeStateReader.getFileModelId(getState(), fileId);
      editorAPI.wixCode.models.update({ modelId, value: content });
    }
  };
}

function changeContent(fileId: AnyFixMe, content: AnyFixMe) {
  return function changeContentThunk(
    dispatch: Dispatch,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) {
    const fileDescriptor =
      editorAPI.dsActions.wixCode.fileSystem.getVirtualDescriptor(
        fileId,
        false,
      );
    const promise = editorAPI.dsActions.wixCode.fileSystem.writeFile(
      fileDescriptor,
      content,
    );
    promise
      .then(function (resultFileDescriptor: AnyFixMe) {
        dispatch(setLoaded(fileId, false));
        return resultFileDescriptor;
      })
      .then(function (resultFileDescriptor: AnyFixMe) {
        const fileListeners = codeStateReader.getFileListeners(
          getState(),
          fileId,
        );
        if (fileListeners) {
          fileListeners.forEach((listener: Function) => listener());
        }
        return resultFileDescriptor;
      })
      .catch(function () {
        // writeFile might fail if it needs to clone first and the clone fails.
        // This failure is reported from the clone method, so we don't need to report
        // it again here (also we don't want to trace every content change)
      });
    dispatch(cacheContent(fileDescriptor, content));
    return promise;
  };
}

function changeContentAction(fileId: ChangeContent['fileId']): ChangeContent {
  return {
    type: ActionType.CHANGE_CONTENT,
    fileId,
  };
}

function setLoaded(
  fileId: SetLoaded['fileId'],
  loaded: SetLoaded['loaded'],
): SetLoaded {
  return {
    type: ActionType.SET_LOADED,
    fileId,
    loaded,
  };
}

function setSelection(
  fileId: SetSelection['fileId'],
  selection: SetSelection['selection'],
): SetSelection {
  return {
    type: ActionType.SET_SELECTION,
    fileId,
    selection,
  };
}

function reportContentChangedBi(fileId: AnyFixMe) {
  return function contentChangedBiThunk(
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) {
    if (!codeStateReader.getFileContentChangedBiReported(getState(), fileId)) {
      editorAPI.bi.event(bi.events.IDE_CODE_CHANGE, {
        file_name: fileId,
      });
      dispatch(setContentChangedBiReported(fileId));
    }
  };
}

function setContentChangedBiReported(
  fileId: SetContentChangeBiReported['fileId'],
): SetContentChangeBiReported {
  return {
    type: ActionType.SET_CONTENT_CHANGED_BI_REPORTED,
    fileId,
  };
}

function resetContentChangedBiReports() {
  return {
    type: ActionType.RESET_CONTENT_CHANGED_BI_REPORTS,
  };
}

function addFileListener(fileId: string, listener: Function) {
  return {
    fileId,
    listener,
    type: ActionType.ADD_FILE_LISTENER,
  };
}

function removeFileListener(fileId: string, listener: Function) {
  return {
    fileId,
    listener,
    type: ActionType.REMOVE_FILE_LISTENER,
  };
}

const fileActions: FileActions = {
  endLoadFile,
  load,
  loadFile,
  wrapLoadFile,
  changeContent,
  setSelection,
  setLoaded,
  setFilesContent,
  cacheContent,

  // bi
  reportContentChangedBi,
  resetContentChangedBiReports,

  // file listeners
  addFileListener,
  removeFileListener,
};

export default fileActions;
