import { ContextMenu, ContextMenuAction } from '@wix/wix-base-ui';
import React from 'react';
import { ContextMenuItem } from '../ContextMenuItem/ContextMenuItem';
import AddIcon from '../../assets/icons/contextMenuAddIcon.svg';
import { dataHooks } from '../../consts/dataHooks';
import { useTranslation } from '@wix/wix-i18n-config';
import { SpiContext } from '../../SpiContext';
import { StopPropagation } from '../StopPropagation';
import { spiLeftTreeActions } from '../SpiLeftTreeSection/spiLeftTreeActions';
import { FileTreeCustomAction } from '@wix/wix-code-plugin-contracts';
import { clickOnAddSPIEvent } from '../../utils/reportEvents';
import { DisabledTooltipSymbol } from '@wix/wix-code-common-components';
import { Experiments } from '@wix/wix-code-common';

export const SectionDividerSuffix: React.FC = () => {
  const [t] = useTranslation();
  const { spiService, experimentsAPI, biLoggerAPI } =
    React.useContext(SpiContext);

  const actions = spiLeftTreeActions(
    t,
    spiService,
    experimentsAPI,
    biLoggerAPI,
  );
  const rootActions = actions.customRootActions();

  const onAddSpi = () => biLoggerAPI.report(clickOnAddSPIEvent());
  const { readOnlyAPI, wixCodeEditorAdapterAPI } = React.useContext(SpiContext);
  const { translate } = wixCodeEditorAdapterAPI.legacyDependencies.util;
  const { sidePanel } = readOnlyAPI.getReadOnlyMode();
  const shouldDisableTooltip = experimentsAPI.isOpen(Experiments.VeloInStudio)
    ? sidePanel?.packages
    : sidePanel?.publicAndBackend;
  return (
    <>
      {shouldDisableTooltip ? (
        <DisabledTooltipSymbol
          iconName="corvid_tree__context_menu_add"
          key="corvid_tree__context_menu_add"
          tooltipContent={translate(
            'Velo_Concurrent_Editing_Sidebar_ReadOnly_Text',
          )}
        />
      ) : (
        <StopPropagation>
          <ContextMenu
            customButton={<AddIcon />}
            dataHook={dataHooks.CONTEXT_MENU_ICON}
            onToggle={onAddSpi}
          >
            {rootActions.map((action: FileTreeCustomAction) => (
              <ContextMenuAction onClick={action.onClick}>
                <ContextMenuItem label={action.label} icon={action.icon} />
              </ContextMenuAction>
            ))}
          </ContextMenu>
        </StopPropagation>
      )}
    </>
  );
};
