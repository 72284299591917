import { APIKeys, EntryPoint, Shell } from '@wix/wix-code-repluggable';
import { vscodeButtonCreator } from './components/VSCodeButton/VSCodeButton';
import { experimentUtils, translations } from '@wix/wix-code-common';
import { externalCodeEditorBannerCreator } from './components/CodeTabsVSCodeExternalHeader/ExternalCodeEditorBanner';

export const VSCodeButtonContributionEntryPoint: EntryPoint = {
  name: 'VS Code Button Contribution Entry Point',
  getDependencyAPIs() {
    return [
      APIKeys.CodeEditorAPI,
      APIKeys.LegacyEditorDependencies,
      APIKeys.ExperimentsAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.LocalEditorPublicAPI,
      APIKeys.GithubPublicAPI,
      APIKeys.EditorContextAPI,
      APIKeys.VSCodePublicAPI,
      APIKeys.MenuAPI,
    ];
  },
  declareAPIs() {
    return [];
  },
  attach(_shell: Shell) {},
  async extend(shell: Shell) {
    const experimentsAPI = shell.getAPI(APIKeys.ExperimentsAPI);
    const editorContextAPI = shell.getAPI(APIKeys.EditorContextAPI);
    if (
      !experimentsAPI.isOpen(experimentUtils.Experiments.VeloIDE) ||
      editorContextAPI.getEditorType() !== 'Studio'
    ) {
      return;
    }
    const legacyDependenciesAPI = shell.getAPI(
      APIKeys.LegacyEditorDependencies,
    );
    const i18nManager = translations.i18nManagerCreator({
      languageCode: legacyDependenciesAPI.util.editorModel.languageCode,
      asyncMessagesLoader: (locale: string) =>
        import(`./assets/locale/messages_${locale}.json`),
    });
    const initializedI18n = await i18nManager.initI18n();

    const codeEditorAPI = shell.getAPI(APIKeys.CodeEditorAPI);
    const githubAPI = shell.getAPI(APIKeys.GithubPublicAPI);
    const localEditorAPI = shell.getAPI(APIKeys.LocalEditorPublicAPI);

    const vscodePublicAPI = shell.getAPI(APIKeys.VSCodePublicAPI);
    const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
    const menuAPI = shell.getAPI(APIKeys.MenuAPI);

    const shouldShowVsCodeButton = () => {
      const isConnectedToGithub =
        !githubAPI.isLoadingOnboardingStatus() && githubAPI.isGithubEnabled();
      if (isConnectedToGithub || localEditorAPI.isInLocalEditor()) {
        return false;
      }
      return true;
    };
    codeEditorAPI.contributeTopBarItem(
      shell,
      vscodeButtonCreator(vscodePublicAPI, initializedI18n, biLoggerAPI),
      () => shouldShowVsCodeButton(),
    );
    codeEditorAPI.contributeExternalIDEBanner(
      shell,
      externalCodeEditorBannerCreator({
        vsCodeApi: vscodePublicAPI,
        i18nInstance: initializedI18n,
        biLoggerAPI,
        getSelectedMenuItem: menuAPI.getSelectedMenuItem,
      }),
      () => shouldShowVsCodeButton(),
    );
  },
  detach(_shell: Shell) {},
};
