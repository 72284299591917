export interface SyncState {
  isSyncing: boolean;
  isSyncSuccess: boolean;
}

export const initialSyncState: SyncState = {
  isSyncing: false,
  isSyncSuccess: false,
};

export interface ConnectionState {
  connected: boolean;
}

export const initialConnectionState: ConnectionState = {
  connected: false,
};

export interface LocalEditorState {
  syncState: SyncState;
  connectionState: ConnectionState;
}

export * from './actions';
export * from './reducers';
export * from './selectors';
