import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import { TextLabel, TextInput, Tooltip } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import noop_ from 'lodash/noop';
import { TFunction } from '@wix/wix-i18n-config';
import { Event } from './types';

type EventControlProps = Pick<
  Event,
  | 'eventLabel'
  | 'eventType'
  | 'handlerInputState'
  | 'handlerName'
  | 'onHandlerNameClicked'
  | 'onCancelEditHandlerName'
  | 'onAddEventHandlerClicked'
  | 'onErrorTooltipClosed'
  | 'onHandlerNameChanged'
  | 'onHandlerNameChanged'
  | 'onRemoveHandlerClicked'
  | 'asyncHandlerNameValidator'
> & {
  disabled: boolean;
  t: TFunction;
  stretchHandlerName: boolean;
};

const EventControl = ({
  eventLabel,
  eventType,
  handlerInputState,
  handlerName,
  onHandlerNameClicked,
  onCancelEditHandlerName,
  onAddEventHandlerClicked,
  onHandlerNameChanged,
  onRemoveHandlerClicked,
  asyncHandlerNameValidator,
  disabled = false,
  t,
  stretchHandlerName,
}: EventControlProps) => {
  const [isValid, setIsValid] = useState(true);
  const handlerExists = !!handlerName;
  const deleteTooltipText = disabled
    ? 'Velo_Concurrent_Editing_CodePanel_ReadOnly_Text'
    : 'WixCode_PropertiesAndEventsPanel_EventHandlers_Remove_Label';
  return (
    <div
      className="event-control"
      data-aid="event-control"
      data-type={eventType}
    >
      <span
        onClick={handlerExists || disabled ? noop_ : onAddEventHandlerClicked}
      >
        <TextLabel
          automationId="event-name"
          className={classNames({
            'event-name': true,
            disabled,
            clickable: !disabled && !handlerExists,
          })}
          type="T04"
          value={eventLabel}
        />
      </span>

      {handlerInputState === 'exist' && (
        <Fragment>
          <span
            data-aid="handler-name"
            className={`handler-name ${
              stretchHandlerName ? 'stretch-handler-name' : ''
            }`}
            onClick={onHandlerNameClicked}
          >
            <TextLabel
              type="T04"
              value={handlerName}
              ellipsisProps={{ disabled: true }}
            />
          </span>
          {!disabled && (
            <Tooltip
              automationId="remove-event-handler-tooltip"
              content={t(deleteTooltipText)}
            >
              <Symbol
                name="remove_event_handler"
                className="handler-action remove-event-handler"
                onClick={onRemoveHandlerClicked}
              />
            </Tooltip>
          )}
        </Fragment>
      )}

      {handlerInputState === 'editing' && (
        <Fragment>
          <TextInput
            automationId="handler-input"
            data-hook="handler-input"
            className="handler-input"
            focus={true}
            blurOnEscKey={false}
            blurOnEnterKey={false}
            value={handlerName}
            onKeyDown={(event: any) => {
              if (event.key === 'Enter') {
                onHandlerNameChanged(event.target.value);
              } else if (event.key === 'Escape') {
                onCancelEditHandlerName();
              }
            }}
            onBlur={(event: any) => {
              onHandlerNameChanged(event.target.value);
            }}
            onValidationStatus={(validationStatus) => {
              setIsValid(validationStatus);
            }}
            asyncValidator={asyncHandlerNameValidator}
            allowInvalidChange={false}
            validateInitialValue={false}
            validateOnValuePropChange={false}
            validateOnBlur={true}
          />
          <Tooltip
            automationId="add-event-handler-tooltip"
            content={t(
              'WixCode_PropertiesAndEventsPanel_EventHandlers_Add_Label',
            )}
          >
            <Symbol
              name="add_event_handler"
              className={
                'handler-action add-event-handler' +
                (isValid ? '' : ' disabled')
              }
            />
          </Tooltip>
        </Fragment>
      )}
    </div>
  );
};

export default EventControl;
