/* eslint react/prop-types:2 */
import _ from 'lodash';
import once_ from 'lodash/once';

export default once_(({ stateManagement, util }) => {
  const { getPreviewPosition, getStageLayout } =
    stateManagement.editorPlugins.domMeasurements.selectors;
  const { isAppWidget, getNickname } =
    stateManagement.editorPlugins.components.selectors;
  const { getSingleSelectedComponent } =
    stateManagement.editorPlugins.selection.selectors;

  const mapStateToProps = ({ editorAPI }) => {
    const { store, dsRead } = editorAPI;
    const editorState = store.getState();
    const editedComponent = getSingleSelectedComponent(editorState);
    const mainEditedComponent =
      util.controlsUtils.getFirstControllableComponent(
        editorAPI,
        editedComponent,
      );

    const widgetRoot =
      mainEditedComponent &&
      isAppWidget(mainEditedComponent, dsRead) &&
      _.head(dsRead.components.getChildren(mainEditedComponent));

    const mapped = {
      editedComponent: mainEditedComponent,
      previewTop: getPreviewPosition(editorState).top,
      isReadOnly: false,
      isRightPanelOpen:
        stateManagement.editorPlugins.panels.selectors.isDockedSectionOpen(
          editorState,
        ),
      stageLayout: getStageLayout(editorState),
    };

    if (widgetRoot) {
      _.assign(mapped, {
        secondaryComponent: widgetRoot,
        componentNickname: getNickname(mainEditedComponent, dsRead),
        secondaryComponentNickname: getNickname(widgetRoot, dsRead),
      });
    }

    return mapped;
  };

  const onClose = (dispatch, getState, { editorAPI }) => {
    editorAPI.developerToolBar.toggle();
  };

  const wixCodePropertiesPanelMobileFirstTimeShowed = (dispatch, getState) =>
    stateManagement.editorPlugins.userPreferences.selectors.getGlobalUserPreferences(
      'wixCodePropertiesPanelMobileFirstTimeShowed',
    )(getState());

  const mapDispatchToProps = (dispatch) => ({
    onClose() {
      dispatch(onClose);
    },
    setWixCodePropertiesPanelMobileFirstTimeShowed(firstTimeShowed) {
      dispatch(
        stateManagement.editorPlugins.userPreferences.actions.setGlobalUserPreferences(
          'wixCodePropertiesPanelMobileFirstTimeShowed',
          firstTimeShowed,
        ),
      ).catch(_.noop);
    },
    wixCodePropertiesPanelMobileFirstTimeShowed() {
      return dispatch(wixCodePropertiesPanelMobileFirstTimeShowed);
    },
    openHelpCenter(helpId, props, biHelpParams) {
      dispatch(
        stateManagement.editorPlugins.panels.actions.openHelpCenter(
          helpId,
          props,
          biHelpParams,
        ),
      );
    },
  });

  return {
    mapStateToProps,
    mapDispatchToProps,
  };
});
