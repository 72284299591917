import React, { Fragment, useContext } from 'react';
import _ from 'lodash';
import baseUILib from '@wix/wix-base-ui';
import { monitoring } from '@wix/wix-code-common';
import { leftTreeClickOnASection } from '@wix/bi-logger-platform/v2';
import { RouterPagesGroup } from './RouterPagesGroup';
import { PagesTabContext } from './PagesTabContext';
import dataHooks from '../dataHooks';
import { PageTreeContext } from './PageTreeContext';
import { RouterPageData } from './types';

interface RouterPagesTreeProps {
  items: Item[];
  label: string;
}

type Item = {
  id: string;
  title: string;
  pagesData: RouterPageData[];
  prefix: string;
  applicationId: number;
  isSpecial: boolean;
  biCategory: string;
  routerType: string;
  actions: {
    title: string;
    symbolName: string;
    event: string;
    type?: string;
  }[];
};

const { withErrorHandling } = monitoring;

export const RouterPagesTree = ({ items, label }: RouterPagesTreeProps) => {
  const { isCollapsed, setCollapsed } = useContext(PageTreeContext);
  const { bi } = useContext(PagesTabContext);
  const id = 'page___ROUTER_PAGES__';
  const [collapsed, setCollapsedState] = React.useState(isCollapsed(id));

  const onNodeClick = () =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickOnASection({
          section_name: 'router_pages',
          action: collapsed ? 'open' : 'close',
        }),
      );
      setCollapsed(id, !collapsed);
      setCollapsedState(!collapsed);
      return;
    })();

  return (
    <Fragment>
      <div data-aid="router-pages-section">
        <baseUILib.TreeSectionDivider
          dataHook={dataHooks.SECTION_DIVIDER}
          label={label}
          collapsed={collapsed}
          hideBottomBorder={collapsed}
          onClick={onNodeClick}
          size="small"
        />
      </div>
      {!collapsed && (
        <ul
          data-aid="router-pages-items"
          key="subtree"
          className="section-container"
        >
          {items &&
            items.map((child: Item) => (
              <li key={child.id}>
                <RouterPagesGroup
                  dataSource={child}
                  isTopLevelTreeRoot={false}
                />
              </li>
            ))}
        </ul>
      )}
    </Fragment>
  );
};
