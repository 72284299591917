import React from 'react';
import { Button, Tooltip, Text, TextButton } from '@wix/wix-base-ui';
import styles from './DisabledPublishButton.scss';
import dataHooks from '../dataHooks';
import { useTranslation } from '@wix/wix-i18n-config';
import { TranslationProvider } from '../../i18n';
import classNames from 'classnames';

export const DisabledPublishButton: React.FC = () => {
  const [t] = useTranslation();
  const toolTipContent = () => {
    return (
      <div className={styles.tooltipContainer}>
        <Text
          size="medium"
          weight="bold"
          enableEllipsis={false}
          shouldTranslate={false}
        >
          {t('localEditor.publish.tooltip.title')}
        </Text>
        <Text
          size="medium"
          weight="thin"
          enableEllipsis={false}
          skin="secondary"
          shouldTranslate={false}
        >
          {t('localEditor.publish.tooltip.description')}
        </Text>
        <span className={styles.terminalCommand}>
          {t('localEditor.publish.tooltip.wix_cli_command')}
        </span>
        <TextButton
          size="medium"
          weight="bold"
          shouldTranslate={false}
          onClick={() => {
            window.open(t('localEditor.publish.tooltip.learn_more.url'));
          }}
        >
          {t('localEditor.publish.tooltip.learn_more.text')}
        </TextButton>
      </div>
    );
  };

  return (
    <div className={styles.badgeContainer}>
      <Tooltip
        content={toolTipContent()}
        interactive={true}
        shouldTranslate={false}
      >
        <Button
          disabled
          className={classNames('btn-md', styles.button)}
          dataHook={dataHooks.topBar.disabledPublishButton.button}
        >
          {t('localEditor.publish.button.label')}
        </Button>
      </Tooltip>
    </div>
  );
};
const createDisabledPublishButton = () => () =>
  (
    <TranslationProvider>
      <DisabledPublishButton />
    </TranslationProvider>
  );
export default createDisabledPublishButton;
