import * as React from 'react';
import dataHooks from '../../dataHooks';
import bi from '@/legacy/bi/bi';
import { FileTreeParagraphEmptyState } from '../FileTreeParagraphEmptyState';
import {
  ReadOnlyModeContext,
  useTranslation,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import { LeftPaneContext } from '@/sidebar/LeftPaneContext';
import { experimentUtils } from '@wix/wix-code-common';

interface FilesTreeEmptyStateProps {
  resource: string;
  onQuickAddItemClick: () => any;
  translations?: { [key: string]: string };
}

export const FilesTreeEmptyState: React.FC<FilesTreeEmptyStateProps> = ({
  resource,
  onQuickAddItemClick,
  translations,
}) => {
  const { editorAPI } = useEditorLegacyAPIs();
  const { readOnlyMode } = React.useContext(ReadOnlyModeContext);

  // TODO: fix this hacky fallback!
  const [translateViaI18n] = useTranslation();
  const { translate: translateViaContext } = React.useContext(LeftPaneContext);
  const translate = translateViaContext || translateViaI18n;

  const description =
    resource === 'ConfigFile'
      ? translations?.CONFIG_EMPTY_STATE_STATE ||
        translate('Left_Tree_Components_Title_ConfigFile_EmptyState')
      : translate(`Left_Tree_Components_Title_${resource}_EmptyState`);

  const articleURL =
    resource === 'Public'
      ? translations?.PUBLIC_EMPTY_STATE_LEARN_MORE_LINK ||
        translate(`Left_Tree_Components_Title_Public_EmptyState_Link_URL`)
      : resource === 'Backend'
      ? translations?.BACKEND_EMPTY_STATE_LEARN_MORE_LINK ||
        (experimentUtils.isWebJsEditorSupport()
          ? translate(
              'Left_Tree_Components_Title_Backend_WebJs_EmptyState_Link_URL',
            )
          : translate(`Left_Tree_Components_Title_Backend_EmptyState_Link_URL`))
      : resource === 'ConfigFile'
      ? translations?.CONFIG_EMPTY_STATE_LEARN_MORE_LINK ||
        translate(`Left_Tree_Components_Title_ConfigFile_EmptyState_Link_URL`)
      : translate(`Left_Tree_Components_Title_${resource}_EmptyState_Link_URL`);

  const learnMoreText =
    resource === 'Public'
      ? translations?.PUBLIC_EMPTY_STATE_LEARN_MORE ||
        translate(`Left_Tree_Components_Title_Public_EmptyState_Link`)
      : resource === 'Backend'
      ? translations?.BACKEND_EMPTY_STATE_LEARN_MORE ||
        translate(`Left_Tree_Components_Title_Backend_EmptyState_Link`)
      : resource === 'ConfigFile'
      ? translations?.CONFIG_EMPTY_STATE_LEARN_MORE ||
        translate(`Left_Tree_Components_Title_ConfigFile_EmptyState_Link`)
      : translate(`Left_Tree_Components_Title_${resource}_EmptyState_Link`);

  const onLinkClick = () => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_ITEM, {
      item_name: `${resource.toLowerCase()} learn more`,
      item_type: 'resource',
    });
    window.open(articleURL, '_blank');
  };

  const emptyStateCtaText = translate(
    `Left_Tree_Components_Title_${resource}_EmptyState_Button`,
  );

  return (
    <FileTreeParagraphEmptyState
      dataHook={dataHooks.EMPTY_STATE}
      description={description}
      ctaText={emptyStateCtaText}
      linkText={learnMoreText}
      onLinkClick={onLinkClick}
      onCtaClick={onQuickAddItemClick}
      readonly={!!readOnlyMode.sidePanel?.publicAndBackend}
    />
  );
};
