import React from 'react';
import {
  Button,
  Text,
  PanelHeader,
  PromotionalList,
  Heading,
  TextButton,
} from '@wix/wix-base-ui';
import { useTranslation, useBiLogger } from '@wix/wix-code-common-components';
import s from './panelEmptyState.scss';
import provisionEmptyStateImage from '../../assets/provisionImage.png';
import { veloLiteIntroduction } from '@wix/bi-logger-platform/v2';
import dataHooks from '../dataHooks';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import { helpIds } from '../utils';

interface PanelEmptyStateProps {
  ctaClick: Function;
  closePanel: () => void;
  panelManager: EditorAPI['panelManager'];
}

export const PanelEmptyState: React.FC<PanelEmptyStateProps> = ({
  ctaClick,
  closePanel,
  panelManager,
}) => {
  const [t] = useTranslation();
  const biLoggerAPI = useBiLogger();

  const emptyStateActionBI = (item_name: string) => {
    biLoggerAPI.report(
      veloLiteIntroduction({
        action: 'click',
        item_name,
      }),
    );
  };
  const ctaClickHandler = () => {
    ctaClick();
    emptyStateActionBI('start_coding');
  };

  const learnMoreClickHandler = () => {
    window.open(t('lite_panel.empty_state.learn_more.link'), '_blank');
    emptyStateActionBI('learn_more');
  };

  const onOpenHelp = () => {
    panelManager.openHelpCenter(helpIds.emptyStateHelpCenter);
  };

  return (
    <>
      <PanelHeader
        onHelp={onOpenHelp}
        onClose={closePanel}
        className="dragger dark"
        defaultCursor
        dataHook={dataHooks.empty_state.header}
      >
        {t('lite_panel.empty_state.panel_header')}
      </PanelHeader>
      <div className={s.content}>
        <div className={s.illustration}>
          <img src={provisionEmptyStateImage} alt="" />
        </div>
        <div className={s.title}>
          <Heading
            appearance="h4"
            shouldTranslate={false}
            dataHook={dataHooks.empty_state.title}
          >
            {t('lite_panel.empty_state.title')}
          </Heading>
        </div>
        <Text
          size="large"
          skin="secondary"
          enableEllipsis={false}
          shouldTranslate={false}
          dataHook={dataHooks.empty_state.subtitle}
        >
          {t('lite_panel.empty_state.subtitle')}
        </Text>
        <div className={s.descriptionList}>
          <PromotionalList
            dataHook={dataHooks.empty_state.features_list}
            shouldTranslate={false}
            items={[
              t('lite_panel.empty_state.list_item_1'),
              t('lite_panel.empty_state.list_item_2'),
              t('lite_panel.empty_state.list_item_3'),
              t('lite_panel.empty_state.list_item_4'),
              t('lite_panel.empty_state.list_item_5'),
            ]}
          />
        </div>
        <div className={s.cta}>
          <Button
            onClick={ctaClickHandler}
            dataHook={dataHooks.empty_state.cta}
          >
            {t('lite_panel.empty_state.cta')}
          </Button>
        </div>
        <TextButton
          size="medium"
          shouldTranslate={false}
          onClick={learnMoreClickHandler}
          dataHook={dataHooks.empty_state.link}
        >
          {t('lite_panel.empty_state.learn_more.text')}
        </TextButton>
      </div>
    </>
  );
};
