// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SDuJal{overflow:visible;width:100%;box-sizing:border-box;background-color:#f7f8f8}.f3m0MX{width:100%;height:100%;display:flex;flex:1}.f3m0MX .esGV1U{display:flex}.KaZEdg{height:109px;display:flex;width:100%;border-bottom:1px solid #dfe5eb}.mau2K_{border-right:1px solid #dfe5eb}.CovQOw{position:absolute;right:20px}.q542DB,.mau2K_{height:109px}.q542DB,.mau2K_{padding:12px 20px;flex:1;position:relative;box-sizing:border-box}.mau2K_ .M2IvDX{margin-bottom:6px}.pYUZvc{color:#3b4057;margin-bottom:5px}.QaOHdK{color:#3b4057;margin:0 5px 5px 0}.jv0pDe{display:inline-flex;font-family:\"Menlo\",\"Monaco\",\"Vera Mono\",monospace;font-size:12px;line-height:1.2;color:#116dff;cursor:pointer;max-width:300px;max-height:15px;text-overflow:ellipsis;overflow:hidden}.jv0pDe:hover{text-decoration:underline}.kTnl6s{display:flex;position:absolute;bottom:10px}.xay2kn a{line-height:1.5;color:#116dff;font-family:HelveticaNeueW01-45Ligh,HelveticaNeueW02-45Ligh,HelveticaNeueW10-45Ligh,HelveticaNeueW31,Helvetica Neue,Helvetica,Arial,メイリオ,meiryo,ヒラギノ角ゴ pro w3,hiragino kaku gothic pro,sans-serif;font-family:var(--wix-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);font-weight:var(--wix-font-weight-regular);font-size:12px;-webkit-font-smoothing:antialiased;font-size:14px;text-decoration:none}.xay2kn a:hover{text-decoration:underline}.l8jcnw{display:flex;flex:1;position:relative;border-left:1px solid #dfe5eb}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "SDuJal",
	"page-content-section": "f3m0MX",
	"pageContentSection": "f3m0MX",
	"page-content-code-code-holder": "esGV1U",
	"pageContentCodeCodeHolder": "esGV1U",
	"page-header-section": "KaZEdg",
	"pageHeaderSection": "KaZEdg",
	"page-left-pane": "mau2K_",
	"pageLeftPane": "mau2K_",
	"btn-run-function": "CovQOw",
	"btnRunFunction": "CovQOw",
	"page-right-pane": "q542DB",
	"pageRightPane": "q542DB",
	"page-description": "M2IvDX",
	"pageDescription": "M2IvDX",
	"page-header": "pYUZvc",
	"pageHeader": "pYUZvc",
	"page-description-text": "QaOHdK",
	"pageDescriptionText": "QaOHdK",
	"page-func-signature": "jv0pDe",
	"pageFuncSignature": "jv0pDe",
	"function-signature-container": "kTnl6s",
	"functionSignatureContainer": "kTnl6s",
	"page-learn-more": "xay2kn",
	"pageLearnMore": "xay2kn",
	"console-wrapper": "l8jcnw",
	"consoleWrapper": "l8jcnw"
};
export default ___CSS_LOADER_EXPORT___;
