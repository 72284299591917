import { useEditorLegacyAPIs } from '@wix/wix-code-common-components';

export enum DateFormat {
  Short,
  Long,
}

export const getFormattedDate = (
  date: string,
  locale: string,
  format: DateFormat,
) => {
  const options: Intl.DateTimeFormatOptions =
    locale === 'es'
      ? {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        }
      : locale === 'vi'
      ? {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        }
      : {
          day: 'numeric',
          month: format === DateFormat.Long ? 'long' : 'short',
          year: 'numeric',
        };
  return new Date(date).toLocaleString(locale, options);
};

export const useEditorLanguageCode = (): string => {
  const {
    legacyDependenciesAPI: { util },
  } = useEditorLegacyAPIs();
  const lang = util.editorModel?.languageCode?.toLowerCase() || 'en';
  return lang;
};
