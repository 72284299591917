import { ActionType } from '@/infra/redux-state/actions/types';

function addMessage({ tbfId, message }: AnyFixMe) {
  return {
    type: ActionType.TBF_CONSOLE_ADD_MESSAGE,
    tbfId,
    message,
  };
}

function addMessages({ tbfId, messages }: AnyFixMe) {
  return {
    type: ActionType.TBF_CONSOLE_ADD_MESSAGES,
    tbfId,
    messages,
  };
}

function clear({ tbfId }: AnyFixMe) {
  return function (dispatch: AnyFixMe) {
    dispatch({
      type: ActionType.TBF_CONSOLE_CLEAR,
      tbfId,
    });
  };
}

export default {
  addMessage,
  addMessages,
  clear,
};
