import React from 'react';
import {
  Divider,
  InfoIcon,
  MultiSelect,
  MultiSelectProps,
  TextLabel,
} from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import { CompRef } from '@wix/document-services-types';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import { propertiesPanelEnterCustomClass } from '@wix/bi-logger-code/v2';
import { useAPIs, useCssClasses } from '../../../../createCssClasses';
import { useBiLogger } from '../../../../biLoggerContext';
import s from './customClasses.scss';
import styles from '../panelContent.scss';

type CustomClassesProps = {
  compRef: CompRef;
};

type CustomClass = {
  id: string;
  label: string;
  size: 'tiny';
};

type InputValue = {
  value: string;
  status?: MultiSelectProps['status'];
  statusMessage?: MultiSelectProps['statusMessage'];
};

function getCustomClassTags(
  editorAPI: EditorAPI,
  compRef: CompRef,
): CustomClass[] {
  const { classnames } =
    editorAPI.components.features.get(compRef, 'classnames') ?? {};
  return classnames?.length
    ? classnames.map((customClassName: string) => ({
        id: customClassName,
        label: customClassName,
        size: 'tiny',
      }))
    : [];
}

export const CustomClasses = ({ compRef }: CustomClassesProps) => {
  const { editorAPI, internalAPI } = useAPIs();
  const [t] = useTranslation();
  const [inputValue, setInputValue] = React.useState<InputValue>({
    value: '',
  });
  const [tags, setTags] = React.useState(
    getCustomClassTags(editorAPI, compRef),
  );
  const { elementTypeName, componentType } = useCssClasses(compRef);
  const allCustomClasses = internalAPI.getAllCustomClasses();
  const biLoggerAPI = useBiLogger();
  const validCssClass = new RegExp(/^[a-zA-Z_-][a-zA-Z0-9_-]*$/);

  const customClassesOptions = React.useMemo(
    () =>
      allCustomClasses.map((className) => ({
        id: className,
        value: className,
      })),
    [allCustomClasses],
  );

  const setNewTags = (newTags: CustomClass[]) => {
    setTags(newTags);
    editorAPI.components.features.update(compRef, 'classnames', {
      type: 'Classnames',
      classnames: newTags.map((tag) => tag.label),
    });
  };

  const sendCustomClassesActionsBi = (tagName: string, action_type: string) => {
    biLoggerAPI.report(
      propertiesPanelEnterCustomClass({
        custom_class: tagName,
        component_id: compRef.id,
        action_type,
        component_type: componentType,
        element_type: elementTypeName,
      }),
    );
  };

  const handleOnSelect: MultiSelectProps['onSelect'] = (tag) => {
    const tagName = { id: tag.id, label: tag.id, size: 'tiny' } as CustomClass;
    const newTags = [...tags, tagName];
    setNewTags(newTags);
    sendCustomClassesActionsBi(tagName.id, 'Select');
  };

  const handleManualInput: MultiSelectProps['onManuallyInput'] = (tag) => {
    const tagName = tag[0];
    const newTags = [
      ...tags,
      { id: tagName, label: tagName, size: 'tiny' } as CustomClass,
    ];
    setNewTags(newTags);
    internalAPI.addCustomClass(tagName);
    sendCustomClassesActionsBi(tagName, 'Add');
  };

  const handleOnRemoveTag: MultiSelectProps['onRemoveTag'] = (tagId) => {
    const newTags = tags.filter((currTag) => currTag.id !== tagId);
    setNewTags(newTags);
    sendCustomClassesActionsBi(tagId, 'Delete');
  };

  const handleOnChange: MultiSelectProps['onChange'] = (e) => {
    if (e.target.value === '' || validCssClass.test(e.target.value)) {
      setInputValue({
        value: e.target.value,
      });
    } else {
      setInputValue({
        value: e.target.value,
        status: 'error',
        statusMessage: t(
          'WixCode_CssClassesPanel_CustomClasses_GeneralError_InvalidClass',
        ),
      });
    }
  };

  const predicate: MultiSelectProps['predicate'] = ({ value }) =>
    String(value).toLowerCase().indexOf(inputValue.value.toLowerCase()) !== -1;

  React.useEffect(() => {
    setTags(getCustomClassTags(editorAPI, compRef));
  }, [compRef, editorAPI]);

  return (
    <>
      <div className={s.classesLabelContainer}>
        <TextLabel
          className={s.classesLabel}
          type="T04"
          value={t('WixCode_CssClassesPanel_CustomClasses_Label')}
        />
        <InfoIcon text={t('WixCode_CssClassesPanel_CustomClasses_Tooltip')} />
      </div>
      <MultiSelect
        size="small"
        tags={tags}
        options={customClassesOptions}
        status={inputValue.status}
        statusMessage={inputValue.statusMessage}
        predicate={predicate}
        onChange={handleOnChange}
        onSelect={handleOnSelect}
        onRemoveTag={handleOnRemoveTag}
        onManuallyInput={handleManualInput}
        placeholder={t('WixCode_CssClassesPanel_CustomClasses_Placeholder')}
      />

      <Divider className={styles.divider} long />
    </>
  );
};
