import React from 'react';
import {
  EditorAPI,
  ExperimentsAPI,
  LegacyEditorDependencies,
  PanelsAPI,
  PlatformAppsAPI,
  PredefinedSidebarTabs,
  ReadOnlyAPI,
  SiteAPI,
  TabProps,
  UserPreferencesAPI,
  WixCodeAppAPI,
  FilesTreeAPI,
} from '@wix/wix-code-plugin-contracts';
import { BiLoggerAPI } from '@wix/wix-code-plugin-contracts/src';

export type APIs = {
  panelsAPI: PanelsAPI;
  editorAPI: EditorAPI;
  legacyEditorDependencies: LegacyEditorDependencies;
  platformAppsAPI: PlatformAppsAPI;
  siteAPI: SiteAPI;
  experimentsAPI: ExperimentsAPI;
  biLoggerAPI: BiLoggerAPI;
  wixCodeAppAPI: WixCodeAppAPI;
  readOnlyAPI: ReadOnlyAPI;
  userPreferencesAPI: UserPreferencesAPI;
  filesTreeAPI: FilesTreeAPI;
};

export const TabContext: React.Context<
  APIs & TabProps[PredefinedSidebarTabs.COLLECTIONS]
> = React.createContext(null as any);
