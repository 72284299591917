import {
  MessagePanelProps,
  openMessagePanel,
} from '@/commonComponents/panels/messagePanel/MessagePanel';
import TestVersion from '@/assets/icons/modals/testVersion.svg';
import React from 'react';
import { RichText } from '@wix/wix-base-ui';
import { isOnEditorX } from '@/utils/editorContext';
import { Trans } from '@/infra/i18n/Trans';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';

export const openInstallTestVersionPanel = ({
  editorAPI,
  translate,
  installFunc,
}: {
  editorAPI: EditorAPI;
  translate: (key: string, options?: string) => string;
  installFunc: AnyFixMe;
}) => {
  const InstallTestVersionPanel = () => (
    <RichText>
      <Trans
        i18nKey="Panels_InstallTestVersion_Details"
        components={{
          learnMore: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              target="_blank"
              rel="noreferrer"
              href={translate('Panels_InstallTestVersion_Link')}
            />
          ),
        }}
      />
    </RichText>
  );

  const props: MessagePanelProps = {
    panelName: 'InstallTestVerstion',
    title: translate('Panels_InstallTestVersion_Title'),
    illustration: <TestVersion />,
    confirmLabel: translate('Panels_InstallTestVersion_Install'),
    onConfirm: installFunc,
    cancelLabel: translate('Panels_InstallTestVersion_Cancel'),
    onCancel: () =>
      editorAPI.panelManager.closePanelByName('InstallTestVerstion'),
    theme: 'standard',
    content: <InstallTestVersionPanel />,
  };

  // on editorX we don't keep the packages modal open, because it hides the message panel and we don't have a solution for it yet
  const leavePanelsOpen = isOnEditorX() ? false : true;

  openMessagePanel({
    editorAPI,
    props,
    leavePanelsOpen,
  });
};
