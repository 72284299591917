import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';

import { createEditorContextAPI } from './EditorContextAPI';

export const EditorContextEntryPoint: EntryPoint = {
  name: 'Editor Context entry point',
  declareAPIs() {
    return [APIKeys.EditorContextAPI];
  },
  attach(shell: Shell) {
    shell.contributeAPI(APIKeys.EditorContextAPI, () =>
      createEditorContextAPI(),
    );
  },
};
