import React from 'react';
import { CustomModal as BaseUICustomModal } from '@wix/wix-base-ui';

interface CustomModalProps {
  className?: string;
  dataHook: string;
  primaryButtonText?: string;
  primaryButtonOnClick?: () => void;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: () => void;
  sideActions?: React.ReactElement;
  title?: string;
  subtitle?: string;
  footnote?: React.ReactElement;
  removeContentPadding?: boolean;
  onClose: () => void;
  onHelp?: () => void;
}

export const CustomModal: React.FC<CustomModalProps> = ({
  children,
  onClose,
  onHelp,
  ...rest
}) => (
  <BaseUICustomModal
    {...{
      shouldTranslate: false,
      onCloseButtonClick: onClose,
      onHelpButtonClick: onHelp,
      ...rest,
    }}
  >
    {children}
  </BaseUICustomModal>
);
