import _ from 'lodash';
import wixCodeStackParser from '@wix/wix-code-stack-parser';
import consoleConstants from '../components/console/constants';
import { consts as codeCoreConstants } from '@wix/wix-code-consts';
import { utilsCreator as wixCodeUtilsCreator } from '@wix/wix-code-common';
import sourceMapFetcher from './sourceMapFetcher';
import once_ from 'lodash/once';
import { captureError } from '@/infra/monitoring';

export default once_(({ experiment, platform, util }) => {
  const resultTypes = consoleConstants.ORIGIN_CALCULATION_RESULT;
  const wixCodeUtils = wixCodeUtilsCreator({ experiment, platform, util });
  const { fetchSourceMap } = sourceMapFetcher();

  const CalculationResult = {
    noUserCodeFrame: () => ({ type: resultTypes.NO_USER_CODE_FRAME }),
    originNotFound: (userCodeFrame) => ({
      type: resultTypes.ORIGIN_NOT_FOUND,
      userCodeFrame,
    }),
    originFound: (origin) => ({ type: resultTypes.ORIGIN_FOUND, origin }),
    serverFailedCreatingSourceMap: () => ({
      type: resultTypes.SERVER_FAILED_CREATING_SOURCE_MAP,
    }),
    error: () => ({ type: resultTypes.ERROR }),
  };

  function getBackendFileName({ editorAPI, source }) {
    const backendFolder =
      editorAPI.wixCode.fileSystem.getRoots().backend.location;

    return source.substring(backendFolder.length);
  }

  function getPublicFileName({ editorAPI, source }) {
    const publicFolder =
      editorAPI.wixCode.fileSystem.getRoots().public.location;

    return source.substring(publicFolder.length);
  }

  function getFileInfo({ editorAPI, source }) {
    const roots = editorAPI.wixCode.fileSystem.getRoots();
    const pagesFolder = roots.pages.location;
    const backendFolder = roots.backend.location;
    const publicFolder = roots.public.location;

    if (_.startsWith(source, pagesFolder)) {
      const pageId = wixCodeUtils.getPageIdFromFileId(source);

      if (pageId === codeCoreConstants.SITE_JS_PAGE_ID) {
        return {
          type: consoleConstants.ORIGIN_TYPES.SITE_CODE,
          displayName: codeCoreConstants.SITE_JS,
        };
      }

      return {
        type: consoleConstants.ORIGIN_TYPES.PAGE_CODE,
        displayName: editorAPI.pages.getPageTitle(pageId),
      };
    }

    if (_.startsWith(source, backendFolder)) {
      return {
        type: consoleConstants.ORIGIN_TYPES.BACKEND_FILE,
        displayName: getBackendFileName({ editorAPI, source }),
      };
    }

    if (_.startsWith(source, publicFolder)) {
      return {
        type: consoleConstants.ORIGIN_TYPES.PUBLIC_FILE,
        displayName: getPublicFileName({ editorAPI, source }),
      };
    }

    throw new Error(`Unknown file origin: ${source}`);
  }

  function tryFindOriginalPosition({
    editorAPI,
    // eslint-disable-next-line no-shadow
    wixCodeStackParser,
    userCodeFrame,
    sourceMap,
  }) {
    const originalPosition = wixCodeStackParser.getOriginalPosition(
      userCodeFrame,
      sourceMap,
    );

    if (!originalPosition) {
      return null;
    }

    const { source, line, column } = originalPosition;
    const { type, displayName } = getFileInfo({ editorAPI, source });

    return {
      type,
      sourceFileFullPath: source,
      sourceFileDisplayName: displayName,
      line,
      column,
    };
  }

  function calculateOrigin({ editorAPI, messageStackTrace }) {
    return Promise.resolve()
      .then(() => {
        const frames = wixCodeStackParser.parse(messageStackTrace);
        const userCodeFrame = _.find(frames, (frame) =>
          editorAPI.wixCode.userScripts.isUserCodeUrl(frame.fileName),
        );

        if (!userCodeFrame) {
          return Promise.resolve(CalculationResult.noUserCodeFrame());
        }

        const sourceMapUrl =
          editorAPI.dsRead.wixCode.userScripts.getSourceMapUrl(
            userCodeFrame.fileName,
          );

        return fetchSourceMap(sourceMapUrl).then((sourceMap) => {
          // When the user has syntax error in his code, the server fails to create a source map and returns 'console.error(something)' instead
          if (_.startsWith(sourceMap, 'console.error')) {
            return CalculationResult.serverFailedCreatingSourceMap();
          }

          try {
            const origin = tryFindOriginalPosition({
              editorAPI,
              wixCodeStackParser,
              userCodeFrame,
              sourceMap,
            });
            if (origin) {
              return CalculationResult.originFound(origin);
            }

            return CalculationResult.originNotFound(userCodeFrame.toString());
          } catch (e) {
            if (e instanceof SyntaxError) {
              throw new Error(
                `Failed to get source map. Got instead: ${sourceMap}`,
              );
            }

            throw e;
          }
        });
      })
      .catch((e) => {
        captureError(e, {
          calculateMessageOrigin: true,
          messageStackTrace,
        });
        return CalculationResult.error();
      });
  }

  return {
    calculate: calculateOrigin,
    getFileInfo,
  };
});
