import { CollectionsEvent, Interceptor } from '@wix/wix-code-plugin-contracts';
import { APIKeys, Shell } from '@wix/wix-code-repluggable';
import { ExternalCollectionsAPIKey } from './externalApiKeys';

export interface ExternalCollectionsAPI {
  addInterceptor: (
    eventName: CollectionsEvent,
    interceptor: Interceptor,
  ) => void;
}

export const ExternalCollectionsEntryPoint = {
  name: 'External Collections Entry Point',
  getDependencyAPIs() {
    return [APIKeys.CollectionsAPI];
  },
  declareAPIs() {
    return [ExternalCollectionsAPIKey];
  },
  attach(shell: Shell) {
    shell.contributeAPI<ExternalCollectionsAPI>(
      ExternalCollectionsAPIKey,
      (): ExternalCollectionsAPI => {
        const collectionsAPI = shell.getAPI(APIKeys.CollectionsAPI);
        return {
          addInterceptor: collectionsAPI.addInterceptor,
        };
      },
    );
  },
};
