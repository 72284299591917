import { Duplexer, InstanceUpdater } from '@wix/duplexer-js';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';

const SOCKET_URL = 'duplexer.wix.com';

let duplexer: Duplexer | undefined;

export const connect = ({
  editorAPI,
  appDefId,
}: {
  editorAPI: EditorAPI;
  appDefId: string;
}) => {
  const instanceUpdater: InstanceUpdater = {
    getInstance() {
      const { instance } = editorAPI.wixCode.getClientSpec();
      return instance;
    },
  };
  if (!duplexer) {
    duplexer = new Duplexer(SOCKET_URL, { instanceUpdater });
  }
  return duplexer.connect({ appDefId });
};

export const subscribeToUserChannelEvent = ({
  eventName,
  eventHandler,
  connection,
}: {
  eventName: string;
  eventHandler: (payload: any) => void;
  connection: ReturnType<Duplexer['connect']>;
}) => {
  let userChannel: ReturnType<(typeof connection)['subscribeToUserChannel']>;
  connection.on('@duplexer:connected', () => {
    if (!userChannel) {
      userChannel = connection.subscribeToUserChannel();
    }
    userChannel.on(eventName, eventHandler);
  });
};
