import type { ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit';
import { FileValidationRule, SectionContribution } from '.';

export enum STATIC_SECTIONS {
  PKG_TREE = 'PKG_ROOT',
  NPM_PKGS = 'NPM_PKGS',
  VELO_PKGS = 'VELO_PKGS',
  NPM_README = 'NPM_README',
  PRIVATE_APP_PKGS = 'PRIVATE_APP_PKGS',
}

export interface SectionStatuses {
  [sectionName: string]: { loaded: boolean; expanded: boolean } | undefined;
}
export interface TreeSectionReducerState {
  singelFileSections: string[];
  contributedSections: SectionContribution[];
  contributedValidationRules: FileValidationRule[];
  statuses: SectionStatuses;
}
export const initialState: TreeSectionReducerState = {
  singelFileSections: [],
  contributedSections: [],
  contributedValidationRules: [],
  statuses: {},
};

export interface TreeSectionSetExpandedParams {
  sectionName: string;
}

export interface TreeSectionSetLoadedParams {
  sectionName: string;
}

export interface TreeContributeSectionParams {
  section: SectionContribution;
}

export interface TreeContributeFileValidationRuleParams {
  rule: FileValidationRule;
}

export interface TreeSectionActions {
  registerSingleFileSection: (fileName: string) => PayloadAction<string>;
  expandTreeSection: ActionCreatorWithPayload<
    TreeSectionSetExpandedParams,
    string
  >;
  collapseTreeSection: ActionCreatorWithPayload<
    TreeSectionSetExpandedParams,
    string
  >;
  setTreeSectionLoaded: (
    params: TreeSectionSetLoadedParams,
  ) => PayloadAction<TreeSectionSetLoadedParams>;
  setTreeSectionLoading: (
    params: TreeSectionSetLoadedParams,
  ) => PayloadAction<TreeSectionSetLoadedParams>;
  contributeTreeSection: (
    params: TreeContributeSectionParams,
  ) => PayloadAction<TreeContributeSectionParams>;
  contributeFileValidationRule: (
    params: TreeContributeFileValidationRuleParams,
  ) => PayloadAction<TreeContributeFileValidationRuleParams>;
}
