import React, { useContext, useEffect, useState } from 'react';
import { DATA_BINDING } from '@wix/app-definition-ids';
import {
  NodeData,
  SchemaActions,
} from '../CollectionsTreeNode/CollectionsTreeNode';
import {
  isDbDriverSchema,
  isExternalSchema,
  isUserSchema,
} from '../schemaHelpers';
import {
  COLLECTIONS_QUICK_ACTIONS,
  FORMS_BANNER_USER_PREFERENCES_KEY,
  QUICK_ACTIONS,
} from '../../constants';
import {
  CollectionsAction,
  DevModeContext,
  ReadOnlyAPI,
} from '@wix/wix-code-plugin-contracts';
import { bi } from '../../bi';

import get_ from 'lodash/get';
import sortBy_ from 'lodash/sortBy';
import orderBy_ from 'lodash/orderBy';
import groupBy_ from 'lodash/groupBy';
import once_ from 'lodash/once';
import { ChildItems, NamespaceExpandedMap, Schema } from '../../internal-types';
import { useTranslation } from '@wix/wix-i18n-config';
import { TreeContextMenu } from '../TreeContextMenu/TreeContextMenu';
import {
  DataTable,
  DataTableCloud,
} from '@wix/wix-ui-icons-common/classic-editor';
import cx from 'classnames';
import { CollectionsTreeRoot } from '../CollectionsTreeRoot/CollectionsTreeRoot';
import { TabContext } from '../tabContext';
import { experimentUtils } from '@wix/wix-code-common';
import {
  leftTreeClickOnPlusIcon,
  leftTreeClickInMenuSettingOptionItem,
  leftTreeClickOnAnItem,
  leftTreeClickInMenuPlusOptionSection,
  leftTreeCollectionsTabClick,
  leftTreeFormsCollectionsMoveAlert,
} from '@wix/bi-logger-platform/v2';
import { dataHooks } from '../../dataHooks';
import {
  cmOpeningFromSiteStructure,
  mFormCollectionsMovementInfoInteraction,
} from '@wix/bi-logger-platform-cm/v2';
import { scrollToFormsNamespace } from '../../utils';
import { useModifySchemas } from './modifyAllSchemas';
import { useAllNamespaces } from '../customHooks/useAllNamespaces';
import { WixStorageStrategy, DataCapsule } from '@wix/data-capsule';
import { HttpClient } from '@wix/http-client';

export const NAMESPACE_ERROR = '__error__';

export const FORMS_APP_ID = '14ce1214-b278-a7e4-1373-00cebd1bef7c';

export const FORMS_NAMESPACE = 'Forms';

export interface NamespaceData {
  namespace: string;
  configuration: string;
  endpoint: string;
  connectionStatus: {
    successful: boolean;
    causeOfFailure: string;
  };
}

export interface CollectionTreeProps {
  loading: boolean;
  schemas: Schema[];
  schemaActions: SchemaActions;
  labelOverride: string;
  devModeContext: DevModeContext;
  onCreateCollection: () => void;
  onAddExternalCollections: () => void;
  onEditExternalCollections: (name: string) => void;
  onRemoveExternalCollections: (name: string) => void;
}

export type NamespaceWithStatus = {
  namespace: string;
  isEmpty?: boolean;
  isError?: boolean;
  isPending?: boolean;
  isCloudDb?: boolean;
  creationTime?: Date;
  id?: string;
};

export type NamespacesWithStatus = NamespaceWithStatus[];

export default once_(
  ({
    constants,
    util,
    stateManagement,
    editorAPI,
    readOnlyAPI,
  }: {
    constants: any;
    util: any;
    stateManagement: any;
    editorAPI: any;
    readOnlyAPI: ReadOnlyAPI;
  }) => {
    const { getAppStore } = stateManagement.editorPlugins.appsStore.selectors;

    const CollectionsTree: React.FC<CollectionTreeProps> = (props) => {
      const [prevSelectedSchemaId, setPrevSelectedSchemaId] = useState<string>(
        getSelectedSchemaId(props.devModeContext),
      );
      const {
        hiddenActions,
        biLoggerAPI,
        panelsAPI,
        platformAppsAPI,
        wixCodeAppAPI,
        siteAPI,
      } = useContext(TabContext);

      const allSchemas = useModifySchemas(props.schemas);
      const [formsBannerSeen, setFormsBannerSeen] = useState<boolean>(true);
      const httpClient = new HttpClient({
        getAppToken: wixCodeAppAPI.getSignedInstance,
      });
      const capsule = new DataCapsule({
        strategy: new WixStorageStrategy({ httpClient }),
        namespace: 'wixCode.formsMigration',
        scope: siteAPI.getUserId(),
      });

      useEffect(() => {
        capsule
          .getItem(FORMS_BANNER_USER_PREFERENCES_KEY)
          .then((item: boolean) => {
            setFormsBannerSeen(item);
          })
          .catch(() => {
            setFormsBannerSeen(false);
          });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      const allNamespaces = useAllNamespaces(props.schemas, wixCodeAppAPI);

      const [shouldShowFormsTooltip, setShouldShowFormsTooltip] =
        useState<boolean>(false);

      const [t] = useTranslation();
      const [expandedSections, setExpandedSections] = useState<Number[]>([
        0, 1,
      ]);

      const [namespaceExpandedMap, setNamespaceExpandedMap] =
        useState<NamespaceExpandedMap>({});

      useEffect(() => {
        biLoggerAPI.report(
          leftTreeCollectionsTabClick({
            hasCollections:
              props.schemas &&
              props.schemas.filter(
                (schema: Schema) =>
                  schema.namespace !== 'Billing' &&
                  schema.namespace !== 'Media',
              ).length > 0,
          }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useEffect(() => {
        const selectedSchemaId = getSelectedSchemaId(props.devModeContext);
        if (selectedSchemaId !== prevSelectedSchemaId) {
          const selectedSchema = allSchemas?.find(
            ({ id }: { id: string }) => id === selectedSchemaId,
          );
          const namespace = get_(selectedSchema, 'namespace');
          setPrevSelectedSchemaId(selectedSchemaId);
          if (namespace) {
            const updatedNamespaceExpandedMap = {
              ...namespaceExpandedMap,
              [namespace]: true,
            };
            setNamespaceExpandedMap(updatedNamespaceExpandedMap);
          }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      const onFolderToggle = ({
        namespace,
        isExpandedFolder,
      }: {
        namespace: string;
        isExpandedFolder: boolean;
      }) => {
        setNamespaceExpandedMap({
          ...namespaceExpandedMap,
          [namespace]: !isExpandedFolder,
        });
      };

      const onContextMenuActionSelected = ({
        action,
        nodeData,
        biItemType,
      }: {
        action: string;
        nodeData: NodeData;
        biItemType: string;
      }) => {
        const { name, isFolder, schema } = nodeData;
        const {
          onCreateCollection,
          onAddExternalCollections,
          onEditExternalCollections,
          onRemoveExternalCollections,
        } = props;
        biLoggerAPI.report(
          leftTreeClickInMenuSettingOptionItem({
            item_name: isFolder ? name : schema.id,
            item_type: biItemType,
            menu_entry_name: action,
          }),
        );

        switch (action) {
          case QUICK_ACTIONS.NEW_COLLECTION:
            return onCreateCollection();
          case QUICK_ACTIONS.ADD_EXTERNAL_COLLECTIONS:
            return onAddExternalCollections();
          case QUICK_ACTIONS.EDIT_EXTERNAL_COLLECTIONS:
            return onEditExternalCollections(name);
          case QUICK_ACTIONS.REMOVE_EXTERNAL_COLLECTIONS:
            return onRemoveExternalCollections(name);
          default: {
            const { applicationId } =
              editorAPI.dsRead.platform.getAppDataByAppDefId(DATA_BINDING);

            editorAPI.dsActions.platform.notifyApplication(applicationId, {
              eventType: action,
              eventPayload: {
                collectionId: schema.id,
                origin: bi.origins.IDE_TREE,
              },
            });
          }
        }
      };

      const {
        labelOverride,
        devModeContext,
        onCreateCollection,
        onAddExternalCollections,
        loading,
        schemaActions,
      } = props;

      const onCollectionSelected = (collectionId: string) => {
        biLoggerAPI.report(
          leftTreeClickOnAnItem({
            item_name: collectionId,
            item_type: bi.itemTypes.COLLECTION,
          }),
        );
        biLoggerAPI.report(
          cmOpeningFromSiteStructure({
            origin: bi.origins.IDE_TREE,
            collection_name: collectionId,
          }),
        );

        panelsAPI.closeAllPanels();

        platformAppsAPI.notifyDataBinding({
          eventType: 'treeItemSelected',
          eventPayload: {
            itemName: collectionId,
            instance: editorAPI.wixCode.getClientSpec().instance,
            origin: 'ideTree',
          },
        });
      };

      const nodeData: Partial<NodeData> = computeTreeData({
        schemas: allSchemas,
        rootName: labelOverride,
        selectedSchemaId: getSelectedSchemaId(devModeContext),
        isNamespaceExpanded: (namespace: string) =>
          Boolean(namespaceExpandedMap[namespace]),
      });

      const isBlocksAppCollectionFolder = (collection: NodeData) => {
        return collection.childItems[0].schema.storage === 'staticAppSchema';
      };

      const appCollections = nodeData.childItems?.filter(
        (collection) =>
          (collection.isFolder &&
            !collection.isExternalFolder &&
            !isBlocksAppCollectionFolder(collection)) ||
          collection.schema?.storage === 'driver',
      );

      const myCollectionsStorages = ['docstore', 'staticAppSchema'];

      const myCollections =
        nodeData.childItems?.filter(
          (collection) =>
            myCollectionsStorages.includes(collection.schema?.storage) ||
            (collection.isFolder && isBlocksAppCollectionFolder(collection)),
        ) || [];

      const externalCollections =
        nodeData.childItems?.filter((collection) => {
          return collection.isExternalFolder;
        }) || [];

      const onContextMenuClick = (event: any, sub_section: string) => {
        event.stopPropagation();
        biLoggerAPI.report(
          leftTreeClickOnPlusIcon({
            section_name: bi.sectionNames.DATABASE,
            sub_section,
          }),
        );
      };

      const sendRootBi = (action: string) => {
        biLoggerAPI.report(
          leftTreeClickInMenuPlusOptionSection({
            section_name: bi.sectionNames.DATABASE,
            menu_entry_name: action,
          }),
        );
      };

      const myCollectionsContextMenu = (
        <div
          className="stop-propagation-bg"
          key="contextMenuContainer"
          onClick={(event) =>
            onContextMenuClick(event, bi.subSections.MY_COLLECTIONS)
          }
        >
          <TreeContextMenu
            contextMenuStructure={{
              sections: [
                [
                  {
                    onClick: () => {
                      onContextMenuActionSelected({
                        action: QUICK_ACTIONS.NEW_COLLECTION,
                        nodeData: nodeData as NodeData,
                        biItemType: bi.itemTypes.COLLECTION,
                      });
                      sendRootBi(QUICK_ACTIONS.NEW_COLLECTION);
                    },
                    icon: DataTable,
                    label: t('WixCode_TreeView_Data_NewCollection'),
                    automationId: dataHooks.NEW_COLLECTION_AUTOMATION_ID,
                  },
                ],
              ],
            }}
            contextMenuButton="corvid_tree__context_menu_add"
            menuClassName="context-menu-icon"
            className={cx('wix-code-file-tree-dd')}
          />
        </div>
      );

      const externalCollectionsContextMenu = (
        <div
          className="stop-propagation-bg"
          key="contextMenuContainer"
          onClick={(event) =>
            onContextMenuClick(event, bi.subSections.EXTERNAL_DATABASES)
          }
        >
          <TreeContextMenu
            contextMenuStructure={{
              sections: [
                [
                  {
                    onClick: () => {
                      onContextMenuActionSelected({
                        action: QUICK_ACTIONS.ADD_EXTERNAL_COLLECTIONS,
                        nodeData: nodeData as NodeData,
                        biItemType: bi.itemTypes.EXTERNAL_COLLECTION,
                      });
                      sendRootBi(QUICK_ACTIONS.ADD_EXTERNAL_COLLECTIONS);
                    },
                    icon: DataTableCloud,
                    label: t('WixCode_TreeView_Data_Add_External_Database'),
                    automationId: dataHooks.EXTERNAL_COLLECTION_AUTOMATION_ID,
                  },
                ],
              ],
            }}
            contextMenuButton="corvid_tree__context_menu_add"
            menuClassName="context-menu-icon"
            className={cx('wix-code-file-tree-dd')}
          />
        </div>
      );

      const showExternalCollections = !hiddenActions?.includes(
        CollectionsAction.ADD_EXTERNAL_COLLECTION,
      );

      const onFormsBannerClick = async () => {
        setFormsBannerSeen(true);
        biLoggerAPI.report(
          leftTreeFormsCollectionsMoveAlert({
            action: bi.actions.CLICK,
            item_name: 'take_me_to_forms',
          }),
        );
        document.getElementById(FORMS_NAMESPACE)?.click();
        setTimeout(scrollToFormsNamespace, 50);
        try {
          await capsule.setItem(FORMS_BANNER_USER_PREFERENCES_KEY, true);
        } catch (e) {}
        setTimeout(() => setShouldShowFormsTooltip(true), 200);
      };

      const shouldShowMoveFormsBanner =
        experimentUtils.isMoveFormsToNewNamespace() &&
        !formsBannerSeen &&
        !!nodeData.childItems?.find(
          (folder) => folder.namespace === FORMS_NAMESPACE,
        );

      useEffect(() => {
        if (shouldShowMoveFormsBanner) {
          biLoggerAPI.report(
            mFormCollectionsMovementInfoInteraction({
              environment: bi.origins.LEFT_TREE,
              action: bi.actions.SHOW,
            }),
          );
          biLoggerAPI.report(
            leftTreeFormsCollectionsMoveAlert({
              action: bi.actions.SHOW,
            }),
          );
        }
      }, [biLoggerAPI, shouldShowMoveFormsBanner]);

      return (
        <div data-hook={dataHooks.COLLECTIONS_TREE}>
          <CollectionsTreeRoot
            collections={myCollections}
            contextMenu={myCollectionsContextMenu}
            label={t('WixCode_TreeView_WixCollections')}
            loading={loading}
            onCollectionSelected={onCollectionSelected}
            onContextMenuActionSelected={onContextMenuActionSelected}
            onCreateCollection={onCreateCollection}
            onFolderToggle={onFolderToggle}
            schemaActions={schemaActions}
            emptyStateCTAText={t('WixCode_TreeView_AddCollectionToDirectory')}
            emptyStateDescription={t(
              'WixCode_TreeView_MyCollections_EmptyState',
            )}
            expandedSections={expandedSections}
            setExpandedSections={setExpandedSections}
            biSubSectionName={bi.subSections.MY_COLLECTIONS}
            dataHook={dataHooks.MY_COLLECTIONS_TREE_ROOT}
            id={0}
            shouldShowFormsBanner={shouldShowMoveFormsBanner}
            onFormsBannerClick={onFormsBannerClick}
          />
          {showExternalCollections && (
            <CollectionsTreeRoot
              collections={externalCollections}
              contextMenu={externalCollectionsContextMenu}
              label={t('WixCode_TreeView_ExternalDatabases')}
              loading={loading}
              onCollectionSelected={onCollectionSelected}
              onContextMenuActionSelected={onContextMenuActionSelected}
              onCreateCollection={onAddExternalCollections}
              onFolderToggle={onFolderToggle}
              schemaActions={schemaActions}
              emptyStateCTAText={t(
                'WixCode_TreeView_ExternalCollections_EmptyState_CTAText',
              )}
              emptyStateDescription={t(
                'WixCode_TreeView_ExternalCollections_EmptyState',
              )}
              expandedSections={expandedSections}
              setExpandedSections={setExpandedSections}
              biSubSectionName={bi.subSections.EXTERNAL_DATABASES}
              dataHook={dataHooks.EXTERNAL_DATABASES_TREE_ROOT}
              id={1}
              allNamespaces={allNamespaces}
              onEditExternalCollections={props.onEditExternalCollections}
              onRemoveExternalCollections={props.onRemoveExternalCollections}
            />
          )}
          {appCollections && appCollections.length > 0 && (
            <CollectionsTreeRoot
              collections={appCollections}
              label={t('WixCode_TreeView_AppCollections')}
              loading={loading}
              onCollectionSelected={onCollectionSelected}
              onContextMenuActionSelected={onContextMenuActionSelected}
              onCreateCollection={onCreateCollection}
              onFolderToggle={onFolderToggle}
              schemaActions={schemaActions}
              expandedSections={expandedSections}
              setExpandedSections={setExpandedSections}
              biSubSectionName={bi.subSections.WIX_APP_DATABASES}
              dataHook={dataHooks.WIX_APP_COLLECTIONS_TREE_ROOT}
              id={2}
              shouldShowFormsTooltip={shouldShowFormsTooltip}
              setShouldShowFormsTooltip={setShouldShowFormsTooltip}
            />
          )}
        </div>
      );
    };

    const getSelectedSchemaId = (devModeContext: DevModeContext) =>
      devModeContext.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.COLLECTION
        ? devModeContext.data.id
        : null;

    const createSchemaNodeData = ({
      schema,
      selectedSchemaId,
    }: {
      schema: Schema;
      selectedSchemaId: string;
    }) => ({
      isFolder: false,
      name: schema.displayName,
      isSelectedSchema: schema.id === selectedSchemaId,
      schema,
    });

    const createFolderNodeData = ({
      name,
      namespace = null,
      isExternalFolder = false,
      isExpandedFolder,
      childItems,
    }: {
      name: string;
      namespace?: string | null;
      isExternalFolder?: boolean;
      isExpandedFolder?: boolean;
      childItems: ChildItems;
    }): Partial<NodeData> => ({
      isFolder: true,
      name,
      namespace,
      isExternalFolder,
      isExpandedFolder,
      childItems,
    });

    const schemasToChildItems = ({
      schemas,
      selectedSchemaId,
    }: {
      schemas: Schema[];
      selectedSchemaId: string;
    }) =>
      sortBy_(schemas, 'displayName').map((schema) =>
        createSchemaNodeData({ schema, selectedSchemaId }),
      );

    const getChildItems = ({
      schemas,
      selectedSchemaId,
      isNamespaceExpanded,
    }: {
      schemas: Schema[];
      selectedSchemaId: string;
      isNamespaceExpanded: (namespace: string) => boolean;
    }) => {
      const getFolderName = (schema: Schema) =>
        schema.displayNamespace || schema.namespace;

      if (util.appStudioUtils.isAppStudio()) {
        return schemasToChildItems({ schemas, selectedSchemaId });
      }

      const groupsByNamespace = Object.values(
        groupBy_(
          schemas?.filter((schema: Schema) => !isUserSchema(schema)),
          'namespace',
        ),
      );

      const sortedGroupsByNamespace = orderBy_(
        groupsByNamespace,
        [isExternalSchema, isDbDriverSchema, getFolderName].map(
          (sortFn) => (schemasGroup) => sortFn(schemasGroup[0]),
        ),
        ['desc', 'desc', 'asc'],
      );

      const folders = sortedGroupsByNamespace.map((schemasGroup) => {
        const firstSchema = schemasGroup[0];

        return createFolderNodeData({
          name: getFolderName(firstSchema),
          namespace: firstSchema.namespace,
          isExternalFolder: isExternalSchema(firstSchema),
          isExpandedFolder: isNamespaceExpanded(firstSchema.namespace),
          childItems: schemasToChildItems({
            schemas: schemasGroup,
            selectedSchemaId,
          }),
        });
      });

      const rootSchemas = schemas?.filter(isUserSchema);

      return [
        ...folders,
        ...schemasToChildItems({ schemas: rootSchemas, selectedSchemaId }),
      ];
    };

    const computeTreeData = ({
      schemas,
      rootName,
      selectedSchemaId,
      isNamespaceExpanded,
    }: {
      schemas: Schema[];
      rootName: string;
      selectedSchemaId: string;
      isNamespaceExpanded: (namespace: string) => boolean;
    }): Partial<NodeData> =>
      createFolderNodeData({
        name: rootName,
        childItems: getChildItems({
          schemas,
          selectedSchemaId,
          isNamespaceExpanded,
        }),
      });

    const mapStateToProps = ({
      state,
      dsRead,
    }: {
      state: any;
      dsRead: any;
    }) => {
      const dataBindingStore = getAppStore(
        state,
        dsRead.platform.editorApps.DATA_BINDING.appDefId,
      );

      const schemas = get_(dataBindingStore, 'schemas', []);

      const actions = get_(dataBindingStore, 'actions', {});

      const actionsBySchema = get_(dataBindingStore, 'actionsBySchema', []);

      const schemaActions = Object.keys(actionsBySchema).reduce(
        (updatedSchemaActions: SchemaActions, schemaId: string) => {
          updatedSchemaActions[schemaId] = actionsBySchema[schemaId].map(
            (action: string) => {
              if (action === COLLECTIONS_QUICK_ACTIONS.ADD_REMOVE_HOOKS) {
                if (readOnlyAPI.getReadOnlyMode().fileSystem) {
                  const returnAction = { ...actions[action], disabled: true };
                  return returnAction;
                }
              }
              return actions[action];
            },
          );
          return updatedSchemaActions;
        },
        {},
      );

      return {
        loading: !dataBindingStore,
        schemas,
        schemaActions,
      };
    };

    return util.hoc.connect(
      util.hoc.STORES.STATE_AND_DS,
      mapStateToProps,
    )(CollectionsTree);
  },
);
