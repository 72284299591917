import * as React from 'react';
import s from './markdownViewerLazy.scss';
import { Preloader } from '@wix/wix-base-ui';
import dataHooks from './dataHooks';

const MarkdownViewer = React.lazy(() => import('./markdownViewer'));

const LazyPreloader = (
  <div className={s.preloader} data-hook={dataHooks.MARKDOWN_LOADER}>
    <Preloader size="large" />
  </div>
);

export const MarkdownViewerLazy = ({ children }: AnyFixMe) => (
  <React.Suspense fallback={LazyPreloader}>
    <MarkdownViewer>{children}</MarkdownViewer>
  </React.Suspense>
);
