const translations: { [key: string]: string } = {
  dbl: 'double',
};

export const camelCaseToLowerCaseWithSpaces = (input: string) => {
  const result = input.replace(/([a-z])([A-Z])/g, '$1 $2');
  return result.toLowerCase();
};

export const formatEventName = (eventName: string) => {
  const description = camelCaseToLowerCaseWithSpaces(eventName);
  return description.replace(
    /\b\w+\b/g,
    (match) => translations[match] || match,
  );
};
