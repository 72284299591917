import React from 'react';
import {
  BiLoggerAPI,
  LegacyEditorDependencies,
  WixCodeStoreAPI,
} from '@wix/wix-code-plugin-contracts';
import { Provider } from 'react-redux';
import { EditorLegacyAPIsContextProvider } from './EditorLegacyApiContext';
import { BiLoggerContext } from './BILogger/biLogger';
import { wixCodeReduxContext } from './store/wixCodeReduxContext';

type MenuTabProviderAPIs = {
  wixCodeStoreAPI: WixCodeStoreAPI;
  legacyDependenciesAPI: LegacyEditorDependencies;
  biLoggerAPI: BiLoggerAPI;
};

type MenuTabProviderProps = MenuTabProviderAPIs & { children: React.ReactNode };

export const MenuTabProvider: React.FC<MenuTabProviderProps> = ({
  wixCodeStoreAPI,
  legacyDependenciesAPI,
  biLoggerAPI,
  children,
}) => {
  return (
    <Provider store={wixCodeStoreAPI.getStore()} context={wixCodeReduxContext}>
      <EditorLegacyAPIsContextProvider
        legacyDependencies={legacyDependenciesAPI}
      >
        <BiLoggerContext.Provider value={biLoggerAPI}>
          {children}
        </BiLoggerContext.Provider>
      </EditorLegacyAPIsContextProvider>
    </Provider>
  );
};
