import {
  ClassicEditorAPI,
  LegacyEditorDependencies,
  SiteAPI,
} from '@wix/wix-code-plugin-contracts';

interface SiteApiParams {
  classicEditorAPI: ClassicEditorAPI;
  legacyEditorDependencies: LegacyEditorDependencies;
}

export const createSiteAPI = ({
  classicEditorAPI,
  legacyEditorDependencies,
}: SiteApiParams): SiteAPI => {
  const { editorAPI } = classicEditorAPI;
  const { generalInfo, viewMode } = editorAPI.dsRead;

  return {
    getSiteName: () => editorAPI.siteName.get(),
    getMetaSiteId: () => generalInfo.getMetaSiteId(),
    getUserId: () => generalInfo.getUserId(),
    getViewMode: () => viewMode.get(),
    getUserLanguage: () =>
      legacyEditorDependencies.util.editorModel.languageCode,
    getBlocksAppDefId: () => window.appStudioModel?.devSiteAppDefId,
  };
};
