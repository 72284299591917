const interactions = {
  provision: 'provision',
  preloadSiteCodeFiles: 'preload-site-code-files',
  ideTreeMount: 'ide-tree-mount',
  openEmbeddedTab: 'open-embedded-tab',
  fetchWixModules: 'fetch-wix-modules',
  fetchComponentsData: 'fetch-components-data',
  fetchFullWixCodeTypes: 'fetch-full-wix-code-types',
  renderCodeEditor: 'render-code-editor',
  renderSidebar: 'render-sidebar',
  renderConsole: 'render-console',
  load: 'load',
};

export { interactions };
