import { SlotKey } from '@wix/wix-code-repluggable';
import { ExternalCollectionsAPI } from './ExternalCollectionsEntryPoint';
import { ExternalFilesTreeAPI } from './ExternalFilesTreeEntryPoint';
import { ExternalPackagesAndAppsAPI } from './ExternalPackagesAndAppsEntryPoint';
import { ExternalGithubAPI } from './ExternalGithubEntryPoint';
import { ExternalLocalEditorAPI } from './ExternalLocalEditorEntryPoint';


export const ExternalFilesTreeAPIKey: SlotKey<ExternalFilesTreeAPI> = {
  name: 'ExternalFilesTreeAPI',
  public: true,
};

export const ExternalCollectionsAPIKey: SlotKey<ExternalCollectionsAPI> = {
  name: 'ExternalCollectionsAPI',
  public: true,
};

export const ExternalPackagesAndAppsAPIKey: SlotKey<ExternalPackagesAndAppsAPI> =
  {
    name: 'ExternalPackagesAndAppsAPI',
    public: true,
  };

  export const ExternalGithubAPIKey: SlotKey<ExternalGithubAPI> = {
    name: 'ExternalGithubAPI',
    public: true,
  };

  export const ExternalLocalEditorAPIKey: SlotKey<ExternalLocalEditorAPI> = {
    name: 'ExternalLocalEditorAPI',
    public: true,
  };
