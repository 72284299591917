// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Y35CHE{background-color:#f7f8f8;box-sizing:border-box;width:100%}.Y35CHE .zbUDMn{display:none}.Y35CHE:hover .zbUDMn{display:block}.ffpkvV{display:none;position:absolute;top:2px;left:3px}.ot90XT{display:block;position:absolute;top:5px;left:6px}.zbUDMn{width:26px;height:26px;box-sizing:border-box;border:solid 1px #d9e1e8;position:absolute;right:0px;top:-1px;cursor:pointer;z-index:1;padding-left:5px;padding-top:4px}.qdI602{position:absolute;top:0px;left:0;right:0px;height:calc(100% - 110px)}.lcQgTh{padding-top:9px}.V4_m4r{display:flex;flex-direction:column;align-items:center;justify-content:center;height:100%}.V4_m4r>.o3xeQD{color:#3b4057;font-size:24px;font-weight:500;line-height:24px;display:flex;margin-top:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wix-code-test-function-console": "Y35CHE",
	"wixCodeTestFunctionConsole": "Y35CHE",
	"tbf-console-clear-button": "zbUDMn",
	"tbfConsoleClearButton": "zbUDMn",
	"tbf-console-clear-button-hover-symbol": "ffpkvV",
	"tbfConsoleClearButtonHoverSymbol": "ffpkvV",
	"tbf-console-clear-button-normal-symbol": "ot90XT",
	"tbfConsoleClearButtonNormalSymbol": "ot90XT",
	"test-function-console": "qdI602",
	"testFunctionConsole": "qdI602",
	"message-container": "lcQgTh",
	"messageContainer": "lcQgTh",
	"no-logs-to-show": "V4_m4r",
	"noLogsToShow": "V4_m4r",
	"title": "o3xeQD"
};
export default ___CSS_LOADER_EXPORT___;
