// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Qxaqao{flex-grow:1}.PV6gSA .xLVDk9{display:flex;align-items:center;flex-wrap:wrap;white-space:pre}.PV6gSA .LBG4fA{color:#868aa5 !important}.PV6gSA .UHgdQE{display:flex}.PV6gSA .X2sAsl{color:#116dff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel-content": "Qxaqao",
	"panelContent": "Qxaqao",
	"api-reference-section": "PV6gSA",
	"apiReferenceSection": "PV6gSA",
	"section": "xLVDk9",
	"section-label": "LBG4fA",
	"sectionLabel": "LBG4fA",
	"link-container": "UHgdQE",
	"linkContainer": "UHgdQE",
	"link": "X2sAsl"
};
export default ___CSS_LOADER_EXPORT___;
