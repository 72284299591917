import * as React from 'react';
import { PackagesModalContext } from '../../../packagesModalContext';
import { MarkdownViewerLazy } from '@/codeEditor/components/markdown/markdownViewerLazy';
import { CodeReusePkgVersion } from '../../../codeReuseServerAPI';

interface PackageReleaseNotesProps {
  versions: CodeReusePkgVersion[];
}

// NOTE - This const is two new lines - the way md renders one new line.
const NEW_LINE_IN_MARKDOWN = `

`;

const formatReleaseNote = (version: CodeReusePkgVersion) =>
  version.releaseNotes
    ? `${NEW_LINE_IN_MARKDOWN} ${version.version} : ${version.releaseNotes}`
    : '';

export const PackageReleaseNotes: React.FC<PackageReleaseNotesProps> = ({
  versions,
}) => {
  const { t } = React.useContext(PackagesModalContext);
  const notes = versions.reduce(
    (acc, note) => acc.concat(formatReleaseNote(note)),
    '',
  );

  const releseNotesFormatted = notes
    ? `## ${t('WixCode_PackageManager_Release_Notes')} ${notes}`
    : '';

  return releseNotesFormatted ? (
    <MarkdownViewerLazy>{releseNotesFormatted}</MarkdownViewerLazy>
  ) : null;
};
