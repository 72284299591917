import { Illustration, MessageModal } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import React from 'react';
import { globalCssFileDelete } from '@wix/bi-logger-platform/v2';
import DestructiveIllustration from '../../../assets/images/DestructiveIllustration.svg';
import { useBiLogger } from '../../../biLoggerContext';
import { constants } from '../../../constants';
import dataHooks from '../../dataHooks';

interface DeleteModalProps {
  onDelete: () => void;
  closeModal: () => void;
}

export const DeleteModal = ({ onDelete, closeModal }: DeleteModalProps) => {
  const [t] = useTranslation();
  const biLoggerAPI = useBiLogger();
  const primaryButtonOnClick = () => {
    onDelete();
    closeModal();
    biLoggerAPI.report(
      globalCssFileDelete({
        item_name: constants.global_css_name,
        item_type: 'file',
      }),
    );
  };

  return (
    <MessageModal
      dataHook={dataHooks.fileDeleteModal}
      theme="destructive"
      title={t('WixCode_Delete_GlobalCss_Modal_Title')}
      content={t('WixCode_Delete_GlobalCss_Modal_Body')}
      illustration={
        <Illustration>
          <DestructiveIllustration />
        </Illustration>
      }
      primaryButtonText={t('WixCode_Delete_GlobalCss_Modal_DeleteButton')}
      primaryButtonOnClick={primaryButtonOnClick}
      secondaryButtonText={t('WixCode_Delete_GlobalCss_Modal_CancelButton')}
      secondaryButtonOnClick={closeModal}
    />
  );
};
