import React from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { MessageModal } from '@wix/wix-base-ui';
import dataHooks from '../dataHooks';

const GithubNotConnectableModal: React.FC<{
  closeModal: Function;
}> = ({ closeModal }) => {
  const [t] = useTranslation();
  return (
    <MessageModal
      title={t('githubIntegration.velo_packages_modal.title')}
      content={t('githubIntegration.velo_packages_modal.description')}
      primaryButtonText={t('githubIntegration.velo_packages_modal.primary_cta')}
      onCloseButtonClick={() => closeModal()}
      primaryButtonOnClick={() => closeModal()}
      dataHook={dataHooks.GithubNotConnectableModal.container}
    />
  );
};

export default GithubNotConnectableModal;
