import React, { useState } from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { MessageModal, Checkbox, Text, Spacer } from '@wix/wix-base-ui';
import { TranslationProvider } from '../../i18n';
import { MODALS_ACTIONS } from '../../utils/biConsts';
import { LocalEditorBIEvents } from '../../utils/biEvents';
import dataHooks from '../dataHooks';

const SyncChangesModal: React.FC<{
  closeModal: () => void;
  primaryButtonOnClick: (shouldNotShowModalAgain: boolean) => () => void;
  biEvents: LocalEditorBIEvents;
}> = ({ closeModal, primaryButtonOnClick, biEvents }) => {
  const [t] = useTranslation();
  const [isDontShowModalAgain, setIsDontShowModalAgain] = useState(false);

  const reportPopUpBiEvent = (action: string) => {
    biEvents.syncChangesPopUpEvent({
      action,
      dontShowAgain: isDontShowModalAgain,
    });
  };

  return (
    <MessageModal
      onCloseButtonClick={() => {
        reportPopUpBiEvent(MODALS_ACTIONS.X);
        closeModal();
      }}
      title={t('localEditor.sync_editor_changes_modal.title')}
      content={
        <div>
          <Text enableEllipsis={false}>
            {t('localEditor.sync_editor_changes_modal.new_revision')}
          </Text>
          <Spacer type="Spacer05" />
          <Text enableEllipsis={false}>
            {t('localEditor.sync_editor_changes_modal.note')}
          </Text>
        </div>
      }
      primaryButtonText={t('localEditor.sync_editor_changes_modal.continue')}
      primaryButtonOnClick={() => {
        reportPopUpBiEvent(MODALS_ACTIONS.CONTINUE);
        primaryButtonOnClick(isDontShowModalAgain)();
      }}
      secondaryButtonText={t('localEditor.sync_editor_changes_modal.cancel')}
      secondaryButtonOnClick={() => {
        reportPopUpBiEvent(MODALS_ACTIONS.CANCEL);
        closeModal();
      }}
      sideActions={
        <Checkbox
          label={t('localEditor.sync_editor_changes_modal.checkbox_label')}
          labelAfterSymbol={true}
          value={isDontShowModalAgain}
          onChange={() => {
            setIsDontShowModalAgain(!isDontShowModalAgain);
          }}
          dataHook={dataHooks.syncChangesModal.checkbox}
        />
      }
    />
  );
};

const createSyncChangesModal = (
  closeModal: () => void,
  primaryButtonOnClick: (shouldNotShowModalAgain: boolean) => () => void,
  biEvents: LocalEditorBIEvents,
) => (
  <TranslationProvider>
    <SyncChangesModal
      closeModal={closeModal}
      primaryButtonOnClick={primaryButtonOnClick}
      biEvents={biEvents}
    />
  </TranslationProvider>
);

export default createSyncChangesModal;
