import React from 'react';
import { InstalledCodeReusePkg } from '../PackagesAPI';
import type DS from '@wix/document-services-types';

interface PackagesTabSectionProps {
  isLast: boolean;
}

export type PackagesTabSectionIds =
  | 'npm_packages'
  | 'velo_packages'
  | 'private_apps'
  | 'code_packages'
  | 'spi';

export type ContributedSection = {
  id: PackagesTabSectionIds;
  Component: React.ComponentType<PackagesTabSectionProps>;
};

export interface PackagesTabAPI {
  contributeSection: (
    section: ContributedSection,
    condition?: () => boolean,
  ) => void;
}

export const sectionsOrder: PackagesTabSectionIds[] = [
  'npm_packages',
  'velo_packages',
  'private_apps',
  'code_packages',
  'spi',
];
export interface PrivateAppData extends AppData {
  codeComponent: InstalledCodeReusePkg;
}

export interface AppData extends DS.AppData {
  latestVersion: string;
  installStatus: InstallStatus;
}
export enum InstallStatus {
  Installed,
  Available,
  Versioned,
}
