import UserFeedbackOpener from '@wix/user-feedback-opener';

const getQuestion = (translate, triggerEvent) => {
  return {
    questionId: 'corvid-feedback',
    categoryId: 'corvid',
    triggerEvent,
    thankYouPage: {
      title: translate('Corvid_Feedback_Form_Thank_You_Page_Title'),
      subTitle: translate('Corvid_Feedback_Form_Thank_You_Page_Subtitle'),
    },
    feedbackModel: {
      feedbackType: 'question',
      question: {
        title: translate('Corvid_Feedback_Form_Main_Title'),
        subTitle: translate('Corvid_Feedback_Form_Main_Subtitle'),
      },
      answer: {
        firstLevelType: 'firstlevel-multichoice',
        options: [
          {
            label: translate('Corvid_Feedback_Form_Main_Option1'),
            choice: 'tell',
            children: {
              type: 'free-text',
              title: translate('Corvid_Feedback_Form_Option1_Title'),
              choice: 'feedback',
              submitButtonLabel: translate(
                'Corvid_Feedback_Form_Send_Form_Button',
              ),
              placeholder: translate('Corvid_Feedback_Form_Option1_Subtitle'),
            },
          },
          {
            label: translate('Corvid_Feedback_Form_Main_Option2'),
            choice: 'tell',
            children: {
              type: 'free-text',
              title: translate('Corvid_Feedback_Form_Option2_Title'),
              choice: 'went_wrong',
              submitButtonLabel: translate(
                'Corvid_Feedback_Form_Send_Form_Button',
              ),
              placeholder: translate('Corvid_Feedback_Form_Option2_Subtitle'),
            },
          },
        ],
      },
    },
  };
};

export default (editorAPI, { initiator, origin }) => {
  const opener = new UserFeedbackOpener({
    initiator,
    siteToken: editorAPI.generalInfo.getSiteToken(),
    metasiteId: editorAPI.generalInfo.getMetaSiteId(),
    origin,
  });
  return {
    preload: () => opener.preload(),
    showQuestion: (translate, triggerEvent) =>
      opener.showQuestion(getQuestion(translate, triggerEvent)),
    destroy: () => opener.destroy(),
  };
};
