import * as React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import _ from 'lodash';
import {
  getMasterPageFileId,
  getPageIdFromFileId,
  experimentUtils,
} from '@wix/wix-code-common';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import s from './fileIcon.scss';
import cx from 'classnames';
import { JS, JSW, JSX, TS, CSS } from '@wix/wix-ui-icons-common/classic-editor';

interface FileIconProps {
  editorAPI: EditorAPI;
  path: string;
  className: string;
  fallback?: string;
}

const IconWrapper = ({ Icon, iconName }: { Icon: any; iconName: string }) => (
  <span
    className={s.iconWrapper}
    data-aid={`${iconName}-icon`}
    data-hook="file-icon"
  >
    <Icon className={cx(s.currentColor, [s[iconName]])} />
  </span>
);

const extensionToIconName = (iconName: string) => {
  switch (iconName) {
    case 'js':
      return <IconWrapper Icon={JS} iconName={iconName} />;
    case 'jsx':
      return <IconWrapper Icon={JSX} iconName={iconName} />;
    case 'ts':
      return <IconWrapper Icon={TS} iconName={iconName} />;
    case 'jsw':
      return <IconWrapper Icon={JSW} iconName={iconName} />;
    case 'css':
      return <IconWrapper Icon={CSS} iconName={iconName} />;
    case 'json':
      return 'tab_json';
    case 'config':
      return 'tab_config';
    default:
      return '';
  }
};

const getIconName = (editorAPI: EditorAPI, path: AnyFixMe) => {
  if (path === getMasterPageFileId()) {
    return 'tab_js';
  }
  if (_.startsWith(path, 'public/pages')) {
    const pageId = getPageIdFromFileId(path);

    if (
      isRouterOrDynamicPage(editorAPI.pages.dynamicPages.getMenuItems(), pageId)
    ) {
      return 'page_dynamic_icon';
    }
    if (isPopupPage(editorAPI, pageId)) {
      return 'popup';
    }

    return 'page';
  }

  let fileExtension: string = getFileExtension(path);
  if (!experimentUtils.isTypescriptSupport() && fileExtension === 'ts') {
    fileExtension = '';
  }

  return extensionToIconName(fileExtension);
};
const getFileExtension = (path: AnyFixMe) => path.split('.').pop();

const isPopupPage = (editorAPI: EditorAPI, pageId: AnyFixMe) => {
  const popupMenuItems = editorAPI.pages.popupPages.getDataList();
  return popupMenuItems.some(({ id }: AnyFixMe) => pageId === id);
};

const isRouterOrDynamicPage = (items: AnyFixMe, pageId: AnyFixMe) => {
  const pageItemsByRouterType = (menuItems: AnyFixMe, type: AnyFixMe) =>
    _(menuItems).filter({ routerType: type }).flatMap('items').value();

  const dynamicPagesItems = pageItemsByRouterType(items, 'Data');
  const customRoutersItems = pageItemsByRouterType(items, 'Custom Router');

  return (
    dynamicPagesItems.some(({ id }) => id === pageId) ||
    customRoutersItems.some(
      ({ id, pageData }) => id === pageId && pageData.tpaApplicationId !== 0,
    )
  );
};

export const FileIcon: React.FC<FileIconProps> = ({
  editorAPI,
  path,
  fallback = 'tab_code_file',
  ...rest
}) => {
  const icon = getIconName(editorAPI, path) || fallback;
  return typeof icon === 'string' ? (
    <span data-aid={`${icon}-icon`} data-hook="file-icon">
      <Symbol name={icon} {...rest} />
    </span>
  ) : (
    icon
  );
};
