import { DeveloperModeContext } from '.';

export const SET_DEVELOPER_MODE_CONTEXT = 'SET_DEVELOPER_MODE_CONTEXT';

export type DeveloperModeContextChange = {
  newContext: DeveloperModeContext;
  type: typeof SET_DEVELOPER_MODE_CONTEXT;
};

export type DeveloperModeContextActions = DeveloperModeContextChange;

export const actions = {
  changeDevContext: (newContext: DeveloperModeContext) => ({
    type: SET_DEVELOPER_MODE_CONTEXT,
    newContext,
  }),
};
