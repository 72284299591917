import { createContext } from 'react';
import {
  PackagesAndAppsAPIProps,
  SidePaneMapDispatchToProps,
  SidePaneStateProps,
} from './SidePane';

export type SidePaneContextProps = SidePaneStateProps &
  SidePaneMapDispatchToProps &
  PackagesAndAppsAPIProps;

export const SidePaneContext = createContext<SidePaneContextProps>({
  showNewFileInput: () => {},
  setNewFileRenameInput: () => {},
  newChild: null,
  packagesAndAppsAPI: {
    openFile: () => {},
    openReadme: () => {},
    isVeloDependenciesInstalled: () => new Promise(() => {}),
  },
});
