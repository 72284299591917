import React from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { RichText } from '@wix/wix-base-ui';
import s from './AIModalFooter.scss';
import { dataHooks } from '../dataHooks';

export const AIModalFooter = () => {
  const [t] = useTranslation();
  return (
    <RichText
      className={s.footer}
      type="T04"
      dataHook={dataHooks.ai_panel.legal_statement.container}
    >
      <span data-hook={dataHooks.ai_panel.legal_statement.part_one}>
        {t('aiCodeAssistant.AddCodeModal.Disclaimer.Text1')}
      </span>{' '}
      <a
        href={t('aiCodeAssistant.AddCodeModal.Disclaimer.TermsOfUse.Link')}
        target="_blank"
        rel="noreferrer"
        data-hook={dataHooks.ai_panel.legal_statement.terms_of_use}
      >
        {t('aiCodeAssistant.AddCodeModal.Disclaimer.TermsOfUse.Text')}
      </a>{' '}
      <br></br>
      <span data-hook={dataHooks.ai_panel.legal_statement.part_two}>
        {t('aiCodeAssistant.AddCodeModal.Disclaimer.Text2')}
      </span>
    </RichText>
  );
};
