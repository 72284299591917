import _ from 'lodash';
import { IdeTabsStateReader } from '@wix/wix-code-plugin-contracts';
import { IdeTabsReducerState } from '../reducers/ideTabsReducer';

function getOpenedTabs(state: { ideTabs: IdeTabsReducerState }) {
  return state.ideTabs.pinnedFileTabs;
}

function getEmbeddedTabs(state: { ideTabs: IdeTabsReducerState }) {
  return state.ideTabs.embeddedTabs;
}

function getUnpinnedTabId(state: { ideTabs: IdeTabsReducerState }) {
  return state.ideTabs.unpinnedTabId;
}

function getTbfTabs(state: { ideTabs: IdeTabsReducerState }) {
  return state.ideTabs.tbfTabs;
}
function getTabScroll(state: { ideTabs: IdeTabsReducerState }, tabId: string) {
  return _.get(state.ideTabs.scrollState, [tabId]);
}

const ideTabsStateReader: IdeTabsStateReader = {
  getOpenedTabs,
  getEmbeddedTabs,
  getUnpinnedTabId,
  getTbfTabs,
  getTabScroll,
};

export default ideTabsStateReader;
