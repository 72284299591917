import { EmbeddedTab } from '@/codeEditor/tabs/components/ideTabsContainer/IdeEmbeddedTabs';
import { ActionType } from '../redux-state/actions/types';

export type DealerReducerState = {
  loaded: boolean;
  [RealEstateId: string]: any;
};

type DealerResponseDone = {
  realEstateId: string;
  payload: EmbeddedTab;
  type: ActionType.DEALER_RESPONSE_DONE;
};

type DealerReducerAction = DealerResponseDone;

const initialState: DealerReducerState = { loaded: false };

const dealerReducer = (state = initialState, action: DealerReducerAction) => {
  switch (action.type) {
    case ActionType.DEALER_RESPONSE_DONE:
      return {
        ...state,
        loaded: true,
        [action.realEstateId]: action.payload,
      };

    default:
      return state;
  }
};

export default dealerReducer;
