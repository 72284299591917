import { ActionType } from '@/infra/redux-state/actions/types';

export type TbfConsolelReducerState = Record<string, any>;

type AddConsoleMessage = {
  tbfId: number;
  message: string;
  messages: string[];
  type: ActionType.TBF_CONSOLE_ADD_MESSAGE;
};

type AddConsoleMessages = {
  tbfId: number;
  messages: string[];
  type: ActionType.TBF_CONSOLE_ADD_MESSAGES;
};

type ClearConsole = {
  tbfId: number;
  type: ActionType.TBF_CONSOLE_CLEAR;
};

type TbfConsolelReducerAction =
  | AddConsoleMessage
  | AddConsoleMessages
  | ClearConsole;

const initialState: TbfConsolelReducerState = {};

const tbfConsoleReducer = (
  state = initialState,
  action: TbfConsolelReducerAction,
) => {
  switch (action.type) {
    case ActionType.TBF_CONSOLE_ADD_MESSAGE:
      return {
        ...state,
        [action.tbfId]: state[action.tbfId]
          ? [...state[action.tbfId], action.message]
          : [action.message],
      };
    case ActionType.TBF_CONSOLE_ADD_MESSAGES:
      return {
        ...state,
        [action.tbfId]: state[action.tbfId]
          ? [...state[action.tbfId], ...action.messages]
          : [...action.messages],
      };
    case ActionType.TBF_CONSOLE_CLEAR:
      const { [action.tbfId]: tbfToRemove, ...rest } = state;
      return {
        ...rest,
      };

    default:
      return state;
  }
};

export default tbfConsoleReducer;
