import * as React from 'react';
import { FileSubTreeDataSource } from '../../../filesTree/FileSubTree';
import { CodeReusePkgShouldAlwaysShowSuffix } from './treeSuffix/CodeReusePkgSuffix';
import { VELO_TREE_QUICK_ACTIONS } from './treeSuffix/CodeReusePkgSuffixContextMenu';
import { CodeReusePkgTreeContext } from './CodeReusePkgTreeContext';
import bi from '@/legacy/bi/bi';
import dataHooks from '../../../dataHooks';
import { getCodeReuseFs } from '@/toExtract/packages/codeReuseFs/codeReuseFsSelectors';
import { connect } from 'react-redux';
import { CodeReuseSubTree } from './CodeReuseSubTree';
import { useContext } from 'react';
import {
  getVersionDisplayText,
  isReusePackageByVelo,
} from '@/toExtract/packages/utils';
import { MODAL_TABS } from '@/toExtract/packages/packagesModal/packagesModalConsts';
import { codeReusePkgsSelectors } from '@/toExtract/packages/packagesView/packagesViewSelectors';
import { AppState } from '@/infra/redux-state/reducers/rootReducer';
import { PrivateAppsContext } from '../../PrivateAppsProvider';
import { AvailableCodeReusePkg } from '@/toExtract/packages/packagesModalContext';
import { PkgTreeContext } from '../PkgTreeContext';
import {
  useInternalAPIs,
  useSelector,
} from '../../packagesTabEntryPoint/contexts';
import { experimentUtils } from '@wix/wix-code-common';
import {
  wixCodeReduxContext,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';

interface CodeReusePkgTreeRootReduxProps {
  dataSource: FileSubTreeDataSource;
}
interface CodeReusePkgTreeRootProps {
  name: string;
  onNodeDoubleClick: (filePath: string) => void;
}

type CodeReusePkgTreeRootCompProps = CodeReusePkgTreeRootProps &
  CodeReusePkgTreeRootReduxProps;
const CodeReusePkgTreeRootComp: React.FC<CodeReusePkgTreeRootCompProps> = ({
  dataSource,
  onNodeDoubleClick,
  name,
}) => {
  const { editorAPI, legacyDependenciesAPI } = useEditorLegacyAPIs();
  const { wixCodeStoreAPI, packagesAndAppsAPI } = useInternalAPIs();
  const {
    util: { translate },
  } = legacyDependenciesAPI;
  const { isBlocksCombinedMode } = useContext(PkgTreeContext);
  const {
    pkg,
    packagesService,
    latestVersion,
    manageInstalledModules,
    isPrivateScope,
    isByOthers,
  } = React.useContext(CodeReusePkgTreeContext);
  const { installedApps } = useContext(PrivateAppsContext);
  const { uninstallPkg, viewReadme, handleUninstallBlockApp } = packagesService;
  const { dispatch } = wixCodeStoreAPI;
  const isVeloLite = experimentUtils.isLiteEditor();
  const myPackages = useSelector(codeReusePkgsSelectors.available.myPackages);
  const menuActionHandler = (action: VELO_TREE_QUICK_ACTIONS) => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_SETTINGS_ITEM, {
      item_name: name,
      item_type: 'internal_packages',
      menu_entry_name: action,
    });

    const uninstall = () => {
      if (isBlocksCombinedMode && (isPrivateScope || isByOthers)) {
        const appData = installedApps.find(
          (app) => pkg.id === app.appDefinitionId,
        );
        if (appData) {
          handleUninstallBlockApp(appData);
        }

        return;
      }

      uninstallPkg(name);
    };

    const selectedTab = isReusePackageByVelo(name)
      ? MODAL_TABS.BUILT_BY_VELO
      : MODAL_TABS.MY_PACKAGES;

    const viewPackageDetails = (shouldSeeAllVersions: boolean = false) => {
      dispatch(wixCodeStoreAPI.packagesActions.selectTab(selectedTab, name));
      editorAPI.panelManager.openPanel('wixCode.panels.PackagesModal', {
        panelName: 'wixCode.panels.PackagesModal',
        packagesService,
        showCodeReuse: true,
      });
      if (shouldSeeAllVersions) {
        dispatch(wixCodeStoreAPI.packagesActions.clickSeeAllVersions());
      }
    };

    const editPackage = () => {
      if (isBlocksCombinedMode) {
        const myPkg = installedApps.find(
          (myPackage: any) => myPackage.codeComponent.name === pkg.name,
        )!;
        if (myPkg.codeComponent.id) {
          packagesService.editCodePkg(myPkg.codeComponent.id);
        }
      } else {
        const myPkg = myPackages.find(
          (myPackage) => myPackage.name === pkg.name,
        )!;
        packagesService.editCodePkg(myPkg.id);
      }
    };
    const openAppDetails = () => {
      editorAPI.panelManager.openPanel('tpa.compPanels.appMarketPanel', {
        urlParams: {},
        selectedSection: {
          id: 'private-apps',
          props: { initialAppDefId: pkg.id },
        },
      });
    };

    switch (action) {
      case 'UNINSTALL':
        uninstall();
        break;
      case 'UPDATE':
        manageInstalledModules();
        break;
      case 'README':
        if (experimentUtils.isLiteEditor()) {
          viewPackageDetails();
        } else {
          viewReadme(name);
        }
        break;
      case 'VERSIONS':
        viewPackageDetails(true);
        break;
      case 'EDIT':
      case 'EDIT_IN_BLOCKS':
        editPackage();
        break;
      case 'APP_DETAILS':
        openAppDetails();
        break;
      default:
        console.log('TBD', action);
    }
  };

  const onPackageClick = (filePath: string) => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_ITEM, {
      item_name: filePath,
      item_type: 'corvid_package',
    });
    packagesAndAppsAPI.openFile(filePath);
  };

  const onPackageDoubleClick = (filePath: AnyFixMe) => {
    editorAPI.bi.event(bi.events.IDE_TAB_ACTION, {
      origin: 'left_tree_double_click',
      file_name: filePath,
      action: 'pin',
    });

    return onNodeDoubleClick(filePath);
  };

  const pkgLabel = name.substring(name.indexOf('/') + 1);
  const displayVersion = getVersionDisplayText(pkg, translate);
  const { version } = pkg;

  return (
    <div data-hook={dataHooks.FOLDER_ROOT}>
      <CodeReuseSubTree
        pkgName={name}
        depth={isVeloLite ? 0 : 1}
        dataHook={dataHooks.CODE_REUSE_TREE_ROOT(pkgLabel)}
        isRoot={true}
        labelOverride={`${pkgLabel} (${displayVersion})`}
        dataSource={dataSource}
        onFileSelected={onPackageClick}
        nodeDoubleClick={onPackageDoubleClick}
        shouldAlwaysShowSuffix={CodeReusePkgShouldAlwaysShowSuffix(
          latestVersion as AvailableCodeReusePkg,
          version,
          editorAPI,
        )}
        menuActionHandler={menuActionHandler}
      />
    </div>
  );
};

function mapStateToProps(
  state: AppState,
  { name }: { name: string },
): CodeReusePkgTreeRootReduxProps {
  const dataSource = getCodeReuseFs(state, name);
  return {
    dataSource,
  };
}

export const CodeReusePkgTreeRoot: React.FC<CodeReusePkgTreeRootProps> =
  connect(mapStateToProps, null, null, {
    context: wixCodeReduxContext,
  })(CodeReusePkgTreeRootComp);
