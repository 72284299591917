import { DisabledTooltipSymbolProps } from '@wix/wix-code-plugin-contracts';
import React from 'react';
import TooltipSymbol from '../tooltipSymbol/TooltipSymbol';
import dataHooks from './dataHooks';
import s from './TreeContextMenu.scss';

export const DisabledTooltipSymbol = ({
  iconName,
  tooltipContent,
}: DisabledTooltipSymbolProps) => {
  return (
    <TooltipSymbol
      automationId={dataHooks.FILE_CONTEXT_DISABLED_TOOLTIP}
      tooltipContent={tooltipContent}
      className={s.disabled}
      key={iconName}
      symbolName={iconName}
    />
  );
};
