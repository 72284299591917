import React from 'react';
import cx from 'classnames';
import dataHooks from '../../../../../dataHooks';
import { Button, Preloader } from '@wix/wix-base-ui';
import s from './PkgActionButton.scss';

export const PkgPreloaderButton: React.FC = () => (
  <Button
    dataHook={dataHooks.PKG_ACTION_LOADER}
    className={cx([
      'btn-inverted',
      'btn-sm',
      'preloader-button',
      s.preloaderButton,
    ])}
    disabled={true}
  >
    <Preloader className={s.tinyPreloader} />
  </Button>
);
