import React from 'react';
import {
  Composites,
  RadioButtonsWithIllustration,
  Text,
} from '@wix/wix-base-ui';
import styles from './SelectProvider.scss';
import { dataHooks } from '../dataHooks';
import { createProviders } from './Providers';
import type { Providers } from '../constants';
import { useTranslation } from '@wix/wix-i18n-config';

type SelectProviderProps = {
  selectedProvider: string;
  onProviderChange: (provider: Providers) => void;
};

export const SelectProvider: React.FC<SelectProviderProps> = ({
  selectedProvider,
  onProviderChange,
}) => {
  const [t] = useTranslation();
  const providers = createProviders(t);
  return (
    <div
      data-hook={dataHooks.externalDbPanels.selectProvider.container}
      className={styles.container}
    >
      <Text
        dataHook={dataHooks.externalDbPanels.selectProvider.title}
        enableEllipsis={false}
        shouldTranslate={false}
      >
        {t('External_Databases_Modal_Select_Provider_Title')}
      </Text>
      <Composites.RadioButtonsWithIllustration
        className={styles.providersContainer}
      >
        <RadioButtonsWithIllustration
          dataHook={dataHooks.externalDbPanels.selectProvider.optionsContainer}
          value={selectedProvider}
          onChange={onProviderChange}
          options={providers}
        />
      </Composites.RadioButtonsWithIllustration>
    </div>
  );
};
