import s from './tbfPage.scss';
import './tbfPage.global.scss';
import React from 'react';
import { connect } from 'react-redux';
import createReactClass from 'create-react-class';
import once_ from 'lodash/once';
import _ from 'lodash';
import { wixCodeReduxContext } from '@wix/wix-code-common-components';
import tbfCodeEditorContainerCreator from '../tbfCodeEditorContainer/tbfCodeEditorContainer';
import codePropTypesCreator from '@/legacy/core/components/propTypes';
import tbfConsoleCreator from '../tbfConsole/tbfConsole';
import tbfConsoleActions from '../../actions/tbfConsoleActions';
import fileActions from '@/infra/redux-state/actions/fileActions';
import { getTbfModelIdName } from '../../utils';
import { TbfPageLeftPane } from './tbfPageLeftPane';
import { TbfPageRightPane } from './tbfPageRightPane';

export default once_(
  ({ baseUI, util, core, constants, experiment, stateManagement }) => {
    const CodePropTypes = codePropTypesCreator({ constants });

    const TbfCodeEditorContainer = tbfCodeEditorContainerCreator({
      experiment,
      core,
      util,
      stateManagement,
    });

    const TestBackendFunctionConsole = tbfConsoleCreator({
      baseUI,
      constants,
      core,
      stateManagement,
      util,
    });

    const TbfPageClass = createReactClass({
      displayName: 'TbfPage',
      propTypes: { context: CodePropTypes.context.isRequired },
      mixins: [core.mixins.editorAPIMixin],
      getModelId() {
        return getTbfModelIdName(
          this.props.context.data.fileId,
          this.props.context.data.functionName,
        );
      },
      getTbfEditorContainerProps() {
        const { fileId, functionName, paramNames } = this.props.context.data;
        return {
          modelId: this.getModelId(),
          fileId,
          functionName,
          paramNames,
          sendBi: this.props.sendBi,
        };
      },

      navigateToFunctionOrigin() {
        const { fileId, lineNumber } = this.props.context.data;
        this.props.editorAPI.wixCode.fileTree.navigateToFile(
          this.props.editorAPI,
          fileId,
          {
            position: { line: lineNumber, column: 1 },
          },
        );
      },

      render() {
        const modelId = this.getModelId();
        const value = this.props.tbfModels.get({ modelId });
        return value ? (
          <div data-aid="tbf-page" className={s.page}>
            <div className={s.pageHeaderSection}>
              <TbfPageLeftPane
                data={this.props.context.data}
                resetContentChangedBiReports={
                  this.props.resetContentChangedBiReports
                }
                navigateToFunctionOrigin={this.navigateToFunctionOrigin}
                functionSignature={this.props.context.data.functionSignature}
                sendBi={this.props.sendBi}
                modelId={modelId}
                sendTbfConsoleMessage={this.props.sendTbfConsoleMessage}
                tbfModels={this.props.tbfModels}
              />
              <TbfPageRightPane />
            </div>
            <div className={s.pageContentSection}>
              <TbfCodeEditorContainer {...this.getTbfEditorContainerProps()} />
              <div className={s.consoleWrapper}>
                <TestBackendFunctionConsole
                  tbfId={this.props.context.data.id}
                  context={this.props.context}
                  baseUI={baseUI}
                  sendBi={this.props.sendBi}
                />
              </div>
            </div>
          </div>
        ) : null;
      },
    });

    const mapDispatchToProps = (dispatch: AnyFixMe, ownProps: AnyFixMe) => {
      return {
        sendTbfConsoleMessage: (message: AnyFixMe) =>
          dispatch(
            tbfConsoleActions.addMessage({
              tbfId: ownProps.context.data.id,
              message,
            }),
          ),
        resetContentChangedBiReports: () =>
          dispatch(fileActions.resetContentChangedBiReports()),
      };
    };
    const mapEditorApiToProps = ({ editorAPI }: AnyFixMe) => ({
      tbfModels: editorAPI.wixCode.tbfService.models,
      sendBi: (...args: AnyFixMe[]) => editorAPI.bi.event(...args),
      editorAPI,
    });
    const {
      connect: editorConnect,
      STORES: { EDITOR_API },
    } = util.hoc;

    const withEditorAPI = (component: AnyFixMe) => {
      const connected = editorConnect(
        EDITOR_API,
        mapEditorApiToProps,
      )(component);
      connected.pure = component;
      return connected;
    };

    return _.flowRight(
      connect(null, mapDispatchToProps, null, {
        forwardRef: true,
        context: wixCodeReduxContext,
      }),
      withEditorAPI,
    )(TbfPageClass);
  },
);
