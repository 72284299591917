import React from 'react';
import { TreeListItem } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import dataHooks from '../dataHooks';
import { StaticPage } from './StaticPage';

interface StaticPageFolderProps {
  dataSource: any;
  isLinkItem: boolean;
}

export const StaticPageFolder = ({
  dataSource,
  isLinkItem,
}: StaticPageFolderProps) => (
  <>
    <TreeListItem
      dataHook={dataHooks.TREE_NODE}
      label={dataSource.label}
      shouldTranslate={false}
      depth={0}
      icon={
        <Symbol
          key="icon_menu_header"
          name={isLinkItem ? 'pageItemLinkIcon' : 'pageItemDropdownIcon'}
        />
      }
      onClick={() => {
        /* TODO */
      }}
      size="small"
    />

    <ul data-aid="static-pages-folder-items" key="subtree">
      {dataSource.items &&
        dataSource.items.map((child: AnyFixMe) => (
          <li key={child.id}>
            <StaticPage dataSource={child} depth={1} />
          </li>
        ))}
    </ul>
  </>
);

// todo - areilw, research renderWhenMutatedByPropTypes. It was removed from this file by me.
