import './codeEditor.global.scss';
import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as codeEditor from '@wix/wix-code-code-editor';
import { fileSystem as fileSystemCommon } from '@wix/wix-code-common';
import IdeShortcutsPopoverCreator from '../../popovers/components/IDEShortcutsPopover/ideShortcutsPopover';
import { popoversApiCreator } from '../../popovers/api';
import once_ from 'lodash/once';
import { injectWixCodeCodeEditorStyles } from '@/utils/injectWixCodeCodeEditorStyles';
import { LegacyEditorDependencies } from '@wix/wix-code-plugin-contracts/dist/types/LegacyEditorDependencies';
import { initEditorShortcuts } from './codeEditorInitShortcuts';
import {
  connectToStores,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';

export default once_(
  ({ core, util, stateManagement }: Partial<LegacyEditorDependencies>) => {
    const IdeShortcutsPopover = IdeShortcutsPopoverCreator({ util });
    const popoversApi = popoversApiCreator({ stateManagement });
    injectWixCodeCodeEditorStyles({ util });

    const CodeEditorClass = createReactClass({
      displayName: 'CodeEditor',
      propTypes: {
        fileId: PropTypes.string.isRequired,
        modelId: PropTypes.string.isRequired,
        onFindReplaceToggle: PropTypes.func.isRequired,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        selection: PropTypes.object,
        onSelection: PropTypes.func,
        isApricotActive: PropTypes.bool,
        allowShortcutsPopover: PropTypes.bool,
        theme: PropTypes.string,
        getFileContext: PropTypes.func,
      },
      getDefaultProps() {
        return { allowShortcutsPopover: true };
      },
      mixins: core && [core.mixins.editorAPIMixin],

      getInitialState() {
        return {
          wasIdeClicked: false,
          showShortcutsPopoverIfTriggered:
            !popoversApi.isIdeShortcutsPopoverDismissed(this.getEditorAPI()),
        };
      },

      componentDidMount() {
        const codeEditorApi = this.getEditorAPI().wixCode.codeEditorApi;
        codeEditorApi.setFileId(this.props.fileId);
      },

      componentDidUpdate() {
        const codeEditorApi = this.getEditorAPI().wixCode.codeEditorApi;
        codeEditorApi.setFileId(this.props.fileId);
      },

      componentWillUnmount: function componentWillUnmount() {
        const codeEditorApi = this.getEditorAPI().wixCode.codeEditorApi;
        codeEditorApi.dispose();
      },

      async selectComponentByNickname(nickname: AnyFixMe) {
        if (!nickname) {
          return;
        }

        const wixEditorAPI = this.getEditorAPI();
        const compRef = wixEditorAPI.deprecatedOldBadPerformanceApis.components
          .getAllComponents(wixEditorAPI.pages.getCurrentUrlPageId())
          .find((c: AnyFixMe) =>
            nickname.includes(wixEditorAPI.components.code.getNickname(c)),
          );
        if (compRef) {
          await wixEditorAPI.selection.selectComponentByCompRef([compRef]);
        }
      },

      getCodeEditorProps() {
        const props = {
          modelId: this.props.modelId,
          hideErrors:
            // backend/jobs.config is a json file with comments, that are not currently allowed
            fileSystemCommon.isScheduledJobsFile(this.props.fileId) ||
            // custom-elements javascript files uses DOM api which aren't currently loaded in to the editor
            fileSystemCommon.isCustomElementsFile(this.props.fileId) ||
            this.props.readOnly,
          readOnly: this.props.readOnly,
          mediaManager: this.getEditorAPI().wixCode.media,
          onFindReplaceToggle: this.props.onFindReplaceToggle,
          onFindInAllFiles: this.props.onFindInAllFiles,
          onSeeAllShortcuts: this.props.onSeeAllShortcuts,
          baseUrl:
            util?.serviceTopology.scriptsLocationMap['wix-code-code-editor'],
          selection: this.props.selection,
          experiments: _.get(util?.editorModel, 'runningExperiments'),
          onFocus: this.props.onFocus,
          onBlur: this.props.onBlur,
          onSelection: (newSelection: AnyFixMe) =>
            this.props.onSelection && this.props.onSelection(newSelection),
          isApricotActive: true,
          onMount: (api: AnyFixMe) => {
            const editorAPI = this.getEditorAPI();
            const codeEditorApi = editorAPI.wixCode.codeEditorApi;
            codeEditorApi.setApi(api);
            editorAPI.wixCode.onCodeEditorLoaded();
            initEditorShortcuts({ api, editorAPI });
          },
          theme: this.props.theme,
          selectComponentByNickname: this.selectComponentByNickname,
          deselectComponents: this.getEditorAPI().selection.deselectComponents,
          getFileContext: this.props.getFileContext,
        };
        return props;
      },

      onClick() {
        if (!this.state.wasIdeClicked) {
          this.setState({ wasIdeClicked: true });
        }
      },

      closeIdeShortcutsPopover() {
        popoversApi.setIdeShortcutsPopoverDismissed(this.getEditorAPI());
        this.setState({ showShortcutsPopoverIfTriggered: false });
      },

      shouldDisplayShortcutsPopover() {
        return (
          this.props.allowShortcutsPopover &&
          this.state.wasIdeClicked &&
          this.state.showShortcutsPopoverIfTriggered
        );
      },

      render() {
        const { Footer } = this.props;

        return (
          <div
            className="wix-code-code-editor"
            ref={(el) => {
              this.rootElement = el;
            }}
            onClick={this.onClick}
          >
            <codeEditor.CodeEditor
              data-hook="wix-code-code-editor"
              {...this.getCodeEditorProps()}
            />
            {this.shouldDisplayShortcutsPopover() && (
              <IdeShortcutsPopover
                key="ideShortcutsPopover"
                onCloseButtonClick={this.closeIdeShortcutsPopover}
                sendBiFunc={this.getEditorAPI().bi.event}
              />
            )}
            {Footer && <Footer />}
          </div>
        );
      },
    });

    const mapCodeStateToProps = (state: AnyFixMe) => {
      const theme = state.ide?.theme;

      return {
        theme,
      };
    };

    let ConnectedComponent: AnyFixMe = null;
    const CodeEditorHot = (origProps: AnyFixMe) => {
      const {
        legacyDependenciesAPI: { util: utilFromContext },
      } = useEditorLegacyAPIs();
      if (ConnectedComponent === null) {
        ConnectedComponent = connectToStores({
          util: utilFromContext,
          mapCodeStateToProps,
          comp: CodeEditorClass,
        });
      }
      return <ConnectedComponent {...origProps} />;
    };

    return CodeEditorHot;
  },
);
