// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XsODHZ{width:50%}.XsODHZ .composite-text-input-labeled{padding:0}.XsODHZ .composite-with-info-icon > .has-tooltip.info-icon-tooltip{right:0px;top:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gitRepositoryName": "XsODHZ"
};
export default ___CSS_LOADER_EXPORT___;
