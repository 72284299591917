import React, { useContext } from 'react';
import { TabContext } from './tabContext';
import { DeleteCloudDbModal } from './DeleteCloudDbModal';
import {
  DataTableCloud,
  ExternalLink,
  Delete,
} from '@wix/wix-ui-icons-common/classic-editor';
import { PredefinedSidebarTabs } from '@wix/wix-code-plugin-contracts';
import { Divider } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import { TreeContextMenu } from './TreeContextMenu/TreeContextMenu';
import { NamespaceWithStatus } from './CollectionsTree/CollectionsTree';
import {
  leftTreeClickOnMenuSettingIconItem,
  leftTreeClickInMenuSettingOptionItem,
  panelOpened,
} from '@wix/bi-logger-platform/v2';
import { BI } from './externalDbs/constants';

interface CloudDbContextMenuProps {
  db: NamespaceWithStatus;
}

export const CloudDbContextMenu: React.FC<CloudDbContextMenuProps> = ({
  db,
}) => {
  const {
    panelsAPI,
    wixCodeAppAPI,
    editorAPI,
    filesTreeAPI,
    platformAppsAPI,
    legacyEditorDependencies,
    biLoggerAPI,
  } = useContext(TabContext);
  const [t] = useTranslation();
  return (
    <div
      className="stop-propagation-bg"
      key="contextMenuContainer"
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
        biLoggerAPI.report(
          leftTreeClickOnMenuSettingIconItem({
            item_name: db.namespace,
            item_type: 'managed_adapter',
          }),
        );
      }}
    >
      <TreeContextMenu
        contextMenuStructure={{
          sections: [
            [
              {
                icon: DataTableCloud,
                label: t(
                  'WixCode_TreeView_ExternalDatabases.Created.ContextMenu.CreateCollection',
                ),
                onClick: () => {
                  biLoggerAPI.report(
                    leftTreeClickInMenuSettingOptionItem({
                      item_name: db.namespace,
                      item_type: 'google_database',
                      menu_entry_name: BI.contextMenuActions.newCollection,
                    }),
                  );
                  platformAppsAPI.notifyDataBinding({
                    eventType:
                      legacyEditorDependencies.platform.constants.EVENTS
                        .createCollectionButtonClicked,
                    eventPayload: {
                      origin: 'ideTree',
                      clickedInSiteStructure: true,
                      namespace: db.namespace,
                    },
                  });
                },
              },
              {
                icon: ExternalLink,
                label: t(
                  'WixCode_TreeView_ExternalDatabases.Created.ContextMenu.Metrics',
                ),
                onClick: () => {
                  // waiting for product/ui
                  biLoggerAPI.report(
                    leftTreeClickInMenuSettingOptionItem({
                      item_name: db.namespace,
                      item_type: 'google_database',
                      menu_entry_name: BI.contextMenuActions.metrics,
                    }),
                  );
                  editorAPI.showUserActionNotification({
                    message: 'test',
                    title: 'test',
                    type: '',
                    link: {
                      caption: 'test',
                      onNotificationLinkClick: () => {
                        if (!editorAPI.developerMode.isEnabled()) {
                          editorAPI.developerMode.toggle();
                        }
                        filesTreeAPI.selectTab(
                          PredefinedSidebarTabs.COLLECTIONS,
                        );
                      },
                    },
                    shouldTranslate: false,
                  });
                },
              },
              {
                icon: Delete,
                label: t(
                  'WixCode_TreeView_ExternalDatabases.Created.ContextMenu.DeleteDatabase',
                ),
                onClick: () => {
                  biLoggerAPI.report(
                    panelOpened({
                      panel_name: BI.panels.deleteCloudDb,
                    }),
                  );
                  panelsAPI.openPanel(
                    <DeleteCloudDbModal
                      db={db}
                      panelsAPI={panelsAPI}
                      wixCodeAppAPI={wixCodeAppAPI}
                      t={t}
                      biLoggerAPI={biLoggerAPI}
                      platformAppsAPI={platformAppsAPI}
                    />,
                    true,
                  );
                },
              },
            ],
            [
              {
                icon: ExternalLink,
                label: t(
                  'WixCode_TreeView_ExternalDatabases.Created.ContextMenu.LearnMore.Text',
                ),
                onClick: () => {
                  biLoggerAPI.report(
                    leftTreeClickInMenuSettingOptionItem({
                      item_name: db.namespace,
                      item_type: 'google_database',
                      menu_entry_name: BI.contextMenuActions.newCollection,
                    }),
                  );
                  window.open(
                    t(
                      'WixCode_TreeView_ExternalDatabases.Created.ContextMenu.LearnMore.URL',
                    ),
                    '_blank',
                  );
                },
              },
            ],
          ],
          separator: <Divider long={true} />,
        }}
        contextMenuButton="corvid_tree__context_menu_more"
        menuClassName="context-menu-icon"
        className="wix-code-file-tree-dd"
      />
    </div>
  );
};
