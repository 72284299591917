import React from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { Text, TextButton } from '@wix/wix-base-ui';
import s from '../SpiWizard.scss';
import { SpiProviderType } from '../../../SpiTypes';

export const WizardStep3Footnote: React.FC<{ spiType: SpiProviderType }> = ({
  spiType,
}) => {
  const [t] = useTranslation();

  return (
    <div className={s.step3Footnote}>
      <Text size="small" skin="secondary" shouldTranslate={false}>
        {t('Wizard_Phase_3_Link_Text1')}
      </Text>
      <div className={s.step3Link}>
        <TextButton
          size="small"
          onClick={() =>
            window.open(t(spiType.translationKeys.wizardPhase3LearnMoreUrl))
          }
          shouldTranslate={false}
        >
          {t(spiType.translationKeys.wizardPhase3LearnMoreText)}
        </TextButton>
      </div>
    </div>
  );
};
