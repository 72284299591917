import React from 'react';
import { InstructionsModal } from '@wix/wix-code-common-components';
import { GithubRepository } from '../../types/GithubTypes';
import { useTranslation } from '@wix/wix-i18n-config';
import { useBiLogger } from '../../context/biLoggerContext';
import { createSuccessfulConnectionEvent } from '../../utils/biEvents';
import { MODALS_ACTIONS } from '../../utils/biConsts';
import dataHooks from '../dataHooks';

const LocalDevSetupModal: React.FC<{
  closeModal: Function;
  githubRepository: GithubRepository;
}> = ({ closeModal, githubRepository }) => {
  const [t] = useTranslation();
  const bi = useBiLogger();
  const successfulEvents = createSuccessfulConnectionEvent(
    githubRepository.repositoryName,
  );
  const reportBiEvent = (action: string) => {
    bi.report(
      successfulEvents.successfulConnectionAction({
        action,
      }),
    );
  };
  const instructions = [
    {
      scope: `${t(
        'githubIntegration.connected_successfully_modal.step_label_prefix',
      )} 1`,
      instructions: t(
        'githubIntegration.connected_successfully_modal.step1_instructions',
      ),
      terminalCommands: [`npm install -g @wix/cli`],
    },
    {
      scope: `${t(
        'githubIntegration.connected_successfully_modal.step_label_prefix',
      )} 2`,
      instructions: t(
        'githubIntegration.connected_successfully_modal.step2_instructions',
      ),
      terminalCommands: [
        `git clone ${githubRepository.sshUrl}`,
        `cd ${githubRepository.repositoryName}`,
        `npm install`,
      ],
    },
    {
      scope: `${t(
        'githubIntegration.connected_successfully_modal.step_label_prefix',
      )} 3`,
      instructions: t(
        'githubIntegration.connected_successfully_modal.step3_instructions',
      ),
      terminalCommands: [`wix dev`],
    },
  ];
  return (
    <InstructionsModal
      dataHook={dataHooks.localDevSetupModal.container}
      copyCodeText={t(
        'githubIntegration.connected_successfully_modal.after_copy_tooltip',
      )}
      closeModal={closeModal}
      modalTitle={t('githubIntegration.connected_successfully_modal.title')}
      modalSubtitle={t(
        'githubIntegration.connected_successfully_modal.subtitle',
      )}
      onCloseButtonClick={() => {
        closeModal();
        reportBiEvent(MODALS_ACTIONS.X);
      }}
      onHelpButtonClick={() => {
        reportBiEvent(MODALS_ACTIONS.HELP);
        window.open(
          t('githubIntegration.connected_successfully_modal.learn_more_link'),
        );
      }}
      footerConfirmButton={{
        onClick: () => {
          reportBiEvent(MODALS_ACTIONS.CONFIRM);
          closeModal();
        },
        text: t(
          'githubIntegration.connected_successfully_modal.confirm_button',
        ),
      }}
      footerSecondaryTextButton={{
        onClick: () => {
          reportBiEvent(MODALS_ACTIONS.REPOSITORY);
        },
        text: t('githubIntegration.connected_successfully_modal.repository'),
        href: `${githubRepository.htmlUrl}`,
      }}
      instructions={instructions}
    />
  );
};

export default LocalDevSetupModal;
