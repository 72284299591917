// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c9ZHqc{width:8px;height:16px;margin-left:-8px}.c83YwJ{background-color:rgba(0,0,0,0)}.GHcH2f{width:130px}.vdgiKs{margin-right:24px;width:auto}.ACgYP4{white-space:nowrap;display:flex;justify-content:flex-end}.action-button,.preloader-button{min-width:75px}.classic-facelift-skin .control-button.btn-inverted[disabled]{background-color:rgba(0,0,0,0);border-color:#bcbcbc;color:#bcbcbc}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tiny-preloader": "c9ZHqc",
	"tinyPreloader": "c9ZHqc",
	"preloader-button": "c83YwJ",
	"preloaderButton": "c83YwJ",
	"update-button": "GHcH2f",
	"updateButton": "GHcH2f",
	"version-button-container": "vdgiKs",
	"versionButtonContainer": "vdgiKs",
	"text-label-container": "ACgYP4",
	"textLabelContainer": "ACgYP4"
};
export default ___CSS_LOADER_EXPORT___;
