import { Divider, InfoIcon, TreeListItem } from '@wix/wix-base-ui';
import React, { useState } from 'react';
import { TreeContextMenu } from '../TreeContextMenu/TreeContextMenu';
import { useTranslation } from '@wix/wix-i18n-config';
import { dataHooks } from '../../dataHooks';
import { MenuItemSection } from '../CollectionsTreeNode/CollectionsTreeNode';

export interface EmptyNamespaceProps {
  namespace: string;
  externalCollectionsSections: MenuItemSection[];
}

export const EmptyNamespace: React.FC<EmptyNamespaceProps> = ({
  namespace,
  externalCollectionsSections,
}) => {
  const [collapsed, setCollapsed] = useState<string>('collapsed');

  const [t] = useTranslation();

  const onContextMenuClick = (event: any) => {
    event.stopPropagation();
  };

  const contextMenu = (
    <div
      className="stop-propagation-bg"
      key="contextMenuContainer"
      onClick={onContextMenuClick}
    >
      <TreeContextMenu
        contextMenuStructure={{
          sections: [...externalCollectionsSections],
          separator: <Divider long={true} />,
        }}
        contextMenuButton="corvid_tree__context_menu_more"
        menuClassName="context-menu-icon"
        className="wix-code-file-tree-dd"
      />
    </div>
  );

  return (
    <div>
      <TreeListItem
        dataHook={dataHooks.TREE_NODE}
        label={namespace}
        shouldTranslate={false}
        depth={1}
        size="small"
        collapseState={collapsed}
        suffix={contextMenu}
        onClick={() => {
          setCollapsed(collapsed === 'collapsed' ? 'expanded' : 'collapsed');
        }}
      />
      {collapsed === 'expanded' && (
        <div>
          <TreeListItem
            dataHook={dataHooks.TREE_NODE}
            label={t(
              'WixCode_TreeView_ExternalDatabases_Connection_NoCollections_Text',
            )}
            size="small"
            depth={2}
            disabled={true}
            shouldTranslate={false}
            alwaysShowSuffix={true}
            suffix={
              <InfoIcon
                shouldTranslate={false}
                text={t(
                  'WixCode_TreeView_ExternalDatabases_Connection_NoCollections_Tooltip',
                )}
              />
            }
          />
        </div>
      )}
    </div>
  );
};
