import React, { useContext } from 'react';
import { PackagesModalContext } from '../../../../packagesModalContext';
import PackagesListItem from '../packagesListItem/PackagesListItem';
import { InstalledPackagesListItem } from '../installedPackagesList/InstalledPackagesListItem/InstalledPackagesListItem';
import { PackageItemContext } from '../PackageItemContext';

export const CodeReusePkgListItem: React.FC = () => {
  const { selectPackage, selectedPackage, currentTab } =
    useContext(PackagesModalContext);

  const { pkgName, installedPkg } = useContext(PackageItemContext);
  const isInstalled = !!installedPkg;

  return isInstalled ? (
    <InstalledPackagesListItem
      key={`installed-code-reuse-pkg-${pkgName}`}
      isSelected={pkgName === selectedPackage}
      onClick={() => selectPackage(pkgName, currentTab)}
    />
  ) : (
    <PackagesListItem
      isSelected={pkgName === selectedPackage}
      onClick={() => selectPackage(pkgName, currentTab)}
    />
  );
};
