import { MessageModal, TextButton, Text } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import React, { useContext } from 'react';
import s from './PostPublishModal.scss';

import { SpiContext } from '../../SpiContext';
import { postPublishModalTypes } from './postPublishModalTypes';
import { spiServerTypes } from '../../SpiService';
import { SpiConnection } from '../../ServerAPI';
import { dataHooks } from '../../consts/dataHooks';
import _ from 'lodash';

export interface PostPublishModalProps extends JSX.IntrinsicAttributes {
  spiStatusUpdates: SpiConnection[];
}

export const PostPublishModal: React.FC<PostPublishModalProps> = ({
  spiStatusUpdates,
}) => {
  const [t] = useTranslation();
  const { panelsAPI, siteAPI } = useContext(SpiContext);

  const typesArray = spiStatusUpdates.map((spi) => spi.spiType);

  const uniqueTypes = _.uniq(typesArray);

  const spiType =
    uniqueTypes.length === 1
      ? _.findKey(spiServerTypes, (item) => item === typesArray[0]!)
      : null;

  const modalType =
    spiStatusUpdates.length > 1
      ? spiType
        ? postPublishModalTypes.multipleNew
        : postPublishModalTypes.multipleNewDifferentTypes
      : postPublishModalTypes.singleNew;

  return (
    <MessageModal
      dataHook={dataHooks.POST_PUBLISH_MODAL}
      onCloseButtonClick={() => {
        panelsAPI.closePanel();
      }}
      title={t(modalType.title, {
        spiType,
      })}
      content={
        <div>
          <Text skin="standard" shouldTranslate={false} enableEllipsis={false}>
            {t(modalType.text, {
              spiName: spiStatusUpdates[0].spiName,
              spiType,
            })}
          </Text>
          <div className={s.container}>
            {modalType.preLinkText ? (
              <>
                <Text skin="standard" shouldTranslate={false}>
                  {t(modalType.preLinkText)}
                </Text>
                <div className={s.marginLeft} />
              </>
            ) : null}
            <TextButton
              shouldTranslate={false}
              onClick={() =>
                window.open(
                  t('Post_Publish_Modal_Test_Integration_Link_Url'),
                  '_blank',
                )
              }
            >
              {t(modalType.linkText)}
            </TextButton>
          </div>
        </div>
      }
      illustration={<modalType.icon />}
      primaryButtonText={t(modalType.confimButtonText, {
        spiType,
      })}
      primaryButtonOnClick={() => {
        window.open(
          `https://manage.wix.com/dashboard/${siteAPI.getMetaSiteId()}/store/shipping-and-tax/list`,
          '_blank',
        );
      }}
      secondaryButtonText={t('Post_Publish_Modal_Dismiss')}
      secondaryButtonOnClick={() => {
        panelsAPI.closePanel();
      }}
    />
  );
};
