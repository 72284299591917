// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZObrfp{display:flex}.XtsJRA{display:flex;align-items:center}.aQPshK{margin-left:8px}.W0RR77{background-color:rgba(0,0,0,0) !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icons-container": "ZObrfp",
	"iconsContainer": "ZObrfp",
	"left-part-container": "XtsJRA",
	"leftPartContainer": "XtsJRA",
	"info-icon": "aQPshK",
	"infoIcon": "aQPshK",
	"no-background": "W0RR77",
	"noBackground": "W0RR77"
};
export default ___CSS_LOADER_EXPORT___;
