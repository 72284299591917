import { isEqual } from 'lodash';
import { EditorAPI, ExperimentsAPI } from '@wix/wix-code-plugin-contracts';
import {
  syncSiteEventsMap,
  syncSiteEventsList,
  SyncSiteEvents,
  ComponentEvents,
  componentEventsList,
  pageEventsList,
  PageEvents,
} from './syncSiteEventTypes';
import { experimentUtils } from '@wix/wix-code-common';

export type SyncSiteHandlers = {
  syncSiteHandler: () => void;
  syncPageTypesHandler: (pageId: string) => void;
  syncPageFilesHandler: () => void;
};

type SyncSiteHandlerHelpersParams = {
  editorAPI: EditorAPI;
  experimentsAPI: ExperimentsAPI;
} & SyncSiteHandlers;

const createSyncSiteHandlerHelpers = ({
  editorAPI,
  experimentsAPI,
  syncSiteHandler,
  syncPageFilesHandler,
  syncPageTypesHandler,
}: SyncSiteHandlerHelpersParams) => {
  const _getSiteElementsMap = () => {
    const sitePages = editorAPI.pages.getPageIdList();
    const elementsMap =
      editorAPI.wixCode.codeIntelligence.getNicknameToTypeMap(sitePages);

    return elementsMap;
  };

  const _getSitePages = () => {
    const sitePages = editorAPI.pages.getPageIdList();
    return sitePages.map((id) => ({
      id,
      title: editorAPI.pages.getPageTitle(id),
    }));
  };

  let _elementsMap = _getSiteElementsMap();
  let _pages = _getSitePages();

  const _handleEdixTransactionEvent = () => {
    const updatedElementsMap = _getSiteElementsMap();
    if (!isEqual(_elementsMap, updatedElementsMap)) {
      syncSiteHandler();
      _elementsMap = updatedElementsMap;
    }
  };

  const partiallySyncSite = () => {
    const updatedElementsMap = _getSiteElementsMap();
    const focusedPageId = editorAPI.pages.getFocusedPageId();
    if (
      !isEqual(_elementsMap[focusedPageId], updatedElementsMap[focusedPageId])
    ) {
      syncPageTypesHandler(focusedPageId);
      _elementsMap = updatedElementsMap;
    }

    const updatedPages = _getSitePages();
    if (!isEqual(_pages, updatedPages)) {
      syncPageFilesHandler();
      _pages = updatedPages;
    }
  };

  const registerToSyncSiteOnManualSave = () => {
    // save seperate map for manual saves
    editorAPI.savePublish.setWaitForSaveDoneCallback(() => {
      if (
        experimentsAPI.isOpen(experimentUtils.Experiments.VSCodePartialSyncSite)
      ) {
        partiallySyncSite();
      } else {
        syncSiteHandler();
      }
    });
  };

  const registerManualSaveListener = (changedEvents: SyncSiteEvents[]) => {
    // we register this callback for each manual save event that happens
    // because when using `setWaitForSaveDoneCallback` you can only register one callback per one save
    if (changedEvents.includes(syncSiteEventsMap.SAVE)) {
      registerToSyncSiteOnManualSave();
    }
  };

  const onChangedEvents = (changedEvents: SyncSiteEvents[]) => {
    if (
      experimentsAPI.isOpen(experimentUtils.Experiments.VSCodePartialSyncSite)
    ) {
      partiallySyncSite();
      return;
    }

    if (
      changedEvents.some((eventName) =>
        componentEventsList.includes(eventName as ComponentEvents),
      )
    ) {
      if (changedEvents.includes(syncSiteEventsMap.TRANSACTION)) {
        _handleEdixTransactionEvent();
      } else {
        syncSiteHandler();
      }
    } else if (
      changedEvents.some((eventName) =>
        pageEventsList.includes(eventName as PageEvents),
      )
    ) {
      syncSiteHandler();
    }
  };

  const isSyncSiteEvent = (eventName: string): eventName is SyncSiteEvents =>
    syncSiteEventsList.includes(eventName as SyncSiteEvents);

  return {
    isSyncSiteEvent,
    onChangedEvents,
    registerManualSaveListener,
    registerToSyncSiteOnManualSave,
  };
};

export { createSyncSiteHandlerHelpers };
