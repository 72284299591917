import { ActionType } from './types';
import { WIX_CODE } from '@wix/app-definition-ids';
import { platformApps } from '@wix/wix-code-common';

import once_ from 'lodash/once';
import {
  ConsoleAddMessage,
  ConsoleAddMessageOrigin,
  ConsoleAddMessages,
  ConsoleRemoveMessage,
  ConsoleRemoveMessages,
  ConsoleSetLevelVisibilty,
  Message,
  Origin,
} from '../reducers/consoleReducer';

export default once_(({ stateManagement }) => {
  const setLevelVisibility = (
    level: ConsoleSetLevelVisibilty['level'],
    visible: boolean,
  ): ConsoleSetLevelVisibilty => ({
    type: ActionType.CONSOLE_SET_LEVEL_VISIBILITY,
    level,
    visible,
  });

  function addMessage(message: Message): ConsoleAddMessage {
    return {
      type: ActionType.CONSOLE_ADD_MESSAGE,
      message,
    };
  }

  function removeMessage(messageId: number): ConsoleRemoveMessage {
    return {
      type: ActionType.CONSOLE_REMOVE_MESSAGE,
      messageId,
    };
  }

  function addMessages(messages: Message[]): ConsoleAddMessages {
    return {
      type: ActionType.CONSOLE_ADD_MESSAGES,
      messages,
    };
  }

  function addMessageOrigin(
    messageId: number,
    origin: Origin,
  ): ConsoleAddMessageOrigin {
    return {
      type: ActionType.CONSOLE_ADD_MESSAGE_ORIGIN,
      messageId,
      origin,
    };
  }

  function clearDisplayedMessages(): ConsoleRemoveMessages {
    return {
      type: ActionType.CONSOLE_CLEAR_DISPLAYED_MESSAGES,
    };
  }

  function clear() {
    return function (
      dispatch: AnyFixMe,
      getState: AnyFixMe,
      editorAPI: AnyFixMe,
    ) {
      dispatch(clearDisplayedMessages());
      // @ts-expect-error - before adding the types only eventType was sent
      platformApps.notifyWixCode(editorAPI, {
        eventType: 'clearConsoleMessages',
      });
      const appState = {
        consoleMessages: [],
      };
      editorAPI.store.dispatch(
        stateManagement.editorPlugins.appsStore.actions.updateAppsStore(
          WIX_CODE,
          appState,
        ),
      );
    };
  }

  return {
    setLevelVisibility,
    addMessage,
    addMessages,
    addMessageOrigin,
    removeMessage,
    clear,
    clearDisplayedMessages,
  };
});
