import { experimentUtils } from '@wix/wix-code-common';
import _ from 'lodash';
import { DefaultFileTemplate } from '@/fileSystem/fileTemplates/defaultTemplatesInterface';
import { consts } from '@wix/wix-code-consts';

interface DefaultFileTemplateService {
  getInitialFileContent(filePath: string): string;
  hasWebModuleExtension(name: string): boolean;
}
const HTTP_FUNCTIONS_FILE_PATH = `backend/${consts.HTTP_FUNCTIONS_FILE_NAME}`;
const DATA_HOOKS_FILE_PATH = `backend/${consts.DATA_HOOK_FILE_NAME}`;
const EVENTS_FILE_PATH = `backend/${consts.EVENTS_FILE_NAME}`;
const JOBS_CONFIG_FILE_PATH = `backend/${consts.JOBS_SCHEDULER_FILE_NAME}`;

export default function (
  templates: DefaultFileTemplate,
): DefaultFileTemplateService {
  const getInitialFileContent = (filePath: string) => {
    if (hasWebModuleExtension(filePath)) {
      const templateContent = templates.WEB_MODULE_CONTENT_TBF;

      return _.template(templateContent)({
        moduleName: getFilePathWithoutExtension(filePath),
      });
    } else if (isWebJsFile(filePath)) {
      return templates.WEB_JS_FILE_CONTENT;
    } else if (isComputedFunctionsFile(filePath)) {
      return experimentUtils.isEnableCreationOfHttpFunctionsFileInBlocks()
        ? templates.COMPUTED_FUNCTIONS_FILE_CONTENT
        : templates.OLD_COMPUTED_FUNCTIONS_FILE_CONTENT;
    } else if (isJobsConfigFile(filePath)) {
      return templates.JOBS_CONFIG_FILE_CONTENT;
    } else if (isPublicJsFile(filePath)) {
      return _.template(templates.PUBLIC_JS_FILE_CONTENT)({
        moduleName: getFilePathWithoutExtension(filePath),
      });
    } else if (isDataHooksFile(filePath)) {
      return templates.DATA_HOOKS_FILE_CONTENT;
    } else if (isEventsFile(filePath)) {
      return templates.EVENTS_FILE_CONTENT;
    } else if (
      experimentUtils.isTypescriptSupport() &&
      isPublicTsFile(filePath)
    ) {
      return _.template(templates.PUBLIC_TS_FILE_CONTENT)({
        moduleName: getFilePathWithoutExtension(filePath),
      });
    } else if (
      experimentUtils.isTypescriptSupport() &&
      isBackendTsFile(filePath)
    ) {
      return _.template(templates.BACKEND_TS_FILE_CONTENT)({
        moduleName: getFilePathWithoutExtension(filePath),
      });
    } else if (isExternalComponentFile(filePath)) {
      return templates.EXTERNAL_COMPONENT_FILE_CONTENT;
    }

    return '';
  };
  const isComputedFunctionsFile = (path: AnyFixMe) => {
    return _.isEqual(path, HTTP_FUNCTIONS_FILE_PATH);
  };
  const isDataHooksFile = (path: AnyFixMe) => {
    return _.isEqual(path, DATA_HOOKS_FILE_PATH);
  };
  const isEventsFile = (path: AnyFixMe) => {
    return _.isEqual(path, EVENTS_FILE_PATH);
  };
  const isJobsConfigFile = (path: AnyFixMe) => {
    return _.isEqual(path, JOBS_CONFIG_FILE_PATH);
  };
  const isPublicJsFile = (path: AnyFixMe) => {
    return _.startsWith(path, 'public/') && _.endsWith(path, '.js');
  };
  const isExternalComponentFile = (path: AnyFixMe) => {
    return (
      _.startsWith(path, 'components/') &&
      (_.endsWith(path, '.js') || _.endsWith(path, '.jsx'))
    );
  };
  const getFilePathWithoutExtension = (filePath: AnyFixMe) => {
    const lastFolderIndex = filePath.lastIndexOf('/');
    if (filePath.lastIndexOf('.') > lastFolderIndex) {
      return filePath.substring(0, filePath.lastIndexOf('.'));
    }
    return filePath;
  };
  const hasWebModuleExtension = (name: string): boolean => {
    return name.endsWith('.jsw');
  };
  const isPublicTsFile = (path: AnyFixMe) => {
    return _.startsWith(path, 'public/') && _.endsWith(path, '.ts');
  };
  const isBackendTsFile = (path: AnyFixMe) => {
    return _.startsWith(path, 'backend/') && _.endsWith(path, '.ts');
  };
  const isWebJsFile = (path: string) => {
    return _.startsWith(path, 'backend/') && _.endsWith(path, 'web.js');
  };
  return {
    getInitialFileContent,
    hasWebModuleExtension,
  };
}
