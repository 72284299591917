import React, { useContext } from 'react';
import { Button } from '@wix/wix-base-ui';
import { PackagesModalContext } from '../../../../../packagesModalContext';
import dataHooks from '../../../../../dataHooks';
import { PackageItemContext } from '../../PackageItemContext';
import { PackagesBiSender } from '../../../../../packagesBiSender';

type ViewPkgButtonProps = {
  disabled?: boolean;
};

export const ViewPkgButton: React.FC<ViewPkgButtonProps> = ({ disabled }) => {
  const { t, sendBi, packagesService, currentTab } =
    React.useContext(PackagesModalContext);
  const packagesBiSender = PackagesBiSender(sendBi);

  const { availablePkg, pkgName } = useContext(PackageItemContext);

  const viewPkg = () => {
    packagesBiSender.sendQuickActionClickedViewPkgBi(availablePkg, currentTab);
    packagesService.viewPkg(pkgName);
  };

  return (
    <Button
      dataHook={dataHooks.PKG_ACTION_BUTTON}
      className="btn-sm btn-inverted action-button"
      disabled={disabled}
      onClick={(e: Event) => {
        e.stopPropagation();
        return viewPkg();
      }}
    >
      {t('Package_Manager_View_Button')}
    </Button>
  );
};
