import { ActionType } from './ActionTypes';

export type SelectTab = {
  type: ActionType.SELECT_TAB;
  selectedTab: string;
};
export type ContributedTab = {
  type: ActionType.CONTRIBUTE_TAB;
  contributedTab: string;
};
export type TabsActions = SelectTab | ContributedTab;

export const setSelectedTab = (selectedTab: string): SelectTab => ({
  type: ActionType.SELECT_TAB,
  selectedTab,
});

export const setContributedTabs = (contributedTab: string): ContributedTab => ({
  type: ActionType.CONTRIBUTE_TAB,
  contributedTab,
});
