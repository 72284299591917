import coreUtilsLib from '@wix/santa-core-utils';
import _ from 'lodash';
import fileSystemActions from '@/infra/redux-state/actions/fileSystemActions';
import reactBootstrapperCreator from './reactBootstrapper';
import ideContainerStateService from './ideContainerStateService';
import consoleContainerStateService from './consoleContainerStateService';
import removeStaticCodeBehaviors from '@/wixCodeInit/editorAPIHooks/removeStaticCodeBehaviors';
import fileActions from '@/infra/redux-state/actions/fileActions';
import preloadSiteCodeFilesCreator from './preloadSiteCodeFilesTree';
import cacheNotificationCreator from './cacheNotificationCreator';
import testSiteNotificationCreator from './testSiteNotificationCreator';
import { BI as codeCodeBI } from '@wix/wix-code-code-editor';
import devContextUtilsCreator from '@/utils/devContext';
import { initSentry } from '@/infra/monitoring/sentryClient';
import { dealerService } from '@/infra/dealer/dealerService';
import { dealerUserPreferences } from '@/infra/dealer/dealerUserPreference';
import { RealEstateIds } from '@/infra/dealer/config';
import { dealerResponseDone } from '@/infra/dealer/dealerActions';
import { initI18Next } from '@/infra/i18n/i18nService';
import { componentsDataService } from '@wix/wix-code-properties-panel';

import once_ from 'lodash/once';
import {
  clientExperimentsService as ClientExperimentService,
  experimentUtils,
  fedops,
} from '@wix/wix-code-common';
import { repluggableEntryPoints } from '@/wixCodeInit/repluggableEntryPoints';
import { createEditorRepluggableEntryPoints } from '@/wixCodeInit/editor-packages';
import { ListOffersBulkResponse } from '@wix/ambassador-dealer-offers-serving-service/types';
import { Store } from '@wix/wix-code-common-components';
import {
  EditorAPI,
  LegacyEditorDependencies,
} from '@wix/wix-code-plugin-contracts';
import { AppHost } from '@wix/wix-code-repluggable';
import { UserCodeDevAnalyzerFacade } from '@/utils/UserCodeDevAnalyzerFacade';

const FIRED_PROMISES = {
  COMPONENTS_DATA_SERVICE: 'componentsDataService',
  PRELOAD_SITE_CODE_FILES: 'preloadSiteCodeFiles',
};
export interface WixCodeLoaderService {
  load(
    editorAPI: EditorAPI,
    config: AnyFixMe,
    repluggableHost: AppHost,
  ): Promise<{
    store: Store;
    editorAPI: EditorAPI;
    firedPromises: { [promiseName: string]: Promise<void> };
  }>;
}

export const wixCodeLoaderServiceCreator = once_(
  (legacyEditorDeps: LegacyEditorDependencies): WixCodeLoaderService => {
    const { constants, experiment, platform, stateManagement, util } =
      legacyEditorDeps;

    const preloadSiteCodeFiles = preloadSiteCodeFilesCreator({
      experiment,
      platform,
      util,
      constants,
    });
    const { setEmbeddedTabContext } = devContextUtilsCreator({
      constants,
    });

    function _initializeServices(editorAPI: EditorAPI): Promise<void> {
      ideContainerStateService.initialize(editorAPI.developerMode.ui);
      return componentsDataService.initialize();
    }

    function _initializeRepluggable({
      editorAPI,
      repluggableHost,
    }: {
      editorAPI: EditorAPI;
      repluggableHost: AppHost;
    }) {
      const editorEntryPoints = createEditorRepluggableEntryPoints(
        editorAPI,
        legacyEditorDeps,
      );
      repluggableHost.addShells([
        ...repluggableEntryPoints,
        ...editorEntryPoints,
      ]);
    }

    function _intializeState(store: Store, editorAPI: EditorAPI) {
      const roots = editorAPI.wixCode.fileSystem.getRoots();
      Object.values(roots).forEach((root) => {
        store.dispatch(fileSystemActions.setFile(root));
      });
    }

    function intializeBi(editorAPI: EditorAPI) {
      const builder = window.loggerModel?.fedOpsAppName;
      if (builder) {
        const { bi } = editorAPI;
        const origBi = bi.event;
        const biWithImplicitFields = (event: AnyFixMe, options: AnyFixMe) => {
          if (event.src === 79 || event.src === 39) {
            options.builderType = builder;
            options.app_id =
              window.appStudioModel?.devSiteAppDefId ||
              editorAPI.dsRead.appStudio?.getDevSiteAppDefId();
          }
          origBi(event, options);
        };
        bi.event = biWithImplicitFields;
      }
    }

    function _provision(editorAPI: EditorAPI) {
      return new Promise<void>(function (resolve, reject) {
        if (editorAPI.wixCode.isProvisioned()) {
          resolve();
        } else {
          fedops.getLogger().interactionStarted(fedops.interactions.provision);
          editorAPI.wixCode.provision({
            onSuccess: () => {
              fedops
                .getLogger()
                .interactionEnded(fedops.interactions.provision);
              resolve();
            },
            onError: reject,
          });
        }
      });
    }

    function _setCodeCodeBiContext(editorAPI: EditorAPI) {
      const msid = editorAPI.dsRead.generalInfo.getMetaSiteId();
      const uuid = editorAPI.dsRead.generalInfo.getUserId();
      const viewMode = editorAPI.dsRead.viewMode.get();

      codeCodeBI.setBiContext({
        msid,
        uuid,
        viewMode,
        getBlocksAppId: () => editorAPI.dsRead.appStudio?.getDevSiteAppDefId(),
        builderType: window.loggerModel?.fedOpsAppName,
      } as AnyFixMe);
    }

    const fetchDealerAssets = (
      editorAPI: EditorAPI,
      store: Store,
      config: AnyFixMe,
    ) => {
      if (_.get(config, 'fetchDealerAssets.disabled')) {
        return;
      }

      const { isDealerOfferHidden } = dealerUserPreferences({
        stateManagement,
      });
      const handleError = window.wixCodeSentry
        ? window.wixCodeSentry.captureError
        : console.warn; // eslint-disable-line no-console

      const processRealEstateResult = ({
        realEstates,
      }: ListOffersBulkResponse) => {
        _.toPairs(realEstates).forEach(([realEstateId, { offers }]) => {
          if (offers && offers.length > 0) {
            const visibleOffers = offers.filter(
              ({ offerGuid }) => !isDealerOfferHidden(editorAPI, offerGuid!),
            );

            if (visibleOffers.length > 0) {
              const [{ asset, offerGuid }] = visibleOffers;
              const payload = JSON.parse(asset!.payloadJson!);

              store.dispatch(
                // TODO this happens only if offer should be displayed, else it creates an embeddedTab that is not visible
                // We need to think how to untangle this: https://github.com/wix-private/wix-code-devex/blob/CRVD-2790-duplicate/packages/wix-code-classic-editor/src/codeEditor/tabs/reducers/ideTabsReducer.js#L112-L115
                dealerResponseDone(realEstateId, { ...payload, offerGuid }),
              );

              if (
                realEstateId === RealEstateIds.PUSH_NOTIFICATION_REAL_ESTATE_ID
              ) {
                setEmbeddedTabContext(editorAPI, {
                  url: payload.url,
                });
              }
            }
          }
        });
      };

      try {
        dealerService
          .listDealersOffers()
          .then(processRealEstateResult)
          .catch(handleError);
      } catch (ex) {
        handleError(ex);
      }
    };
    const unminimizeIdeOnLoadIfNeeded = (
      editorAPI: EditorAPI,
      config: AnyFixMe,
    ) => {
      if (!config.skipUnMinimizeIdeOnLoad) {
        editorAPI.developerMode.ui.idePane.unMinimize();
      }
    };
    const isInlocalEditor = () => {
      return !!util.url.parseUrlParams(window.location).localPort;
    };

    async function _preloadSiteCodeFiles(
      editorAPI: EditorAPI,
      store: Store,
      config: AnyFixMe,
    ) {
      if (_.get(config, 'preloadSiteCodeFiles.disabled')) {
        return;
      }
      await preloadSiteCodeFiles(editorAPI, store);
    }

    function setupSentry() {
      const sentryClient = initSentry({
        dsn: 'https://01c57454650c43e8ba90efab22b46c2a@sentry.wixpress.com/186',
      });
      window.wixCodeSentry = {
        captureError: (error, options) => {
          sentryClient.captureException(error, options);
        },
      };
    }

    async function load(
      editorAPI: EditorAPI,
      config = {},
      repluggableHost: AppHost,
    ) {
      setupSentry();

      const clientExperimentsService =
        await ClientExperimentService.initClientExperimentService();
      await initI18Next({
        util,
        editorAPI,
        experimentsService: clientExperimentsService,
      });

      if (
        experimentUtils.isDevModeLoader() &&
        !editorAPI.documentServices.wixCode.isProvisioned()
      ) {
        editorAPI.panelManager.openPanel('wixCode.panels.devModeLoaderPanel', {
          appName: 'DeveloperMode_EnableDevMode_Loader_Title',
          isDone: false,
        });
      }

      const reactBootstrapper = reactBootstrapperCreator({
        stateManagement,
        util,
        experiment,
      });
      const store = reactBootstrapper.initialize(editorAPI);
      const userCodeDevAnalyzerFacade = new UserCodeDevAnalyzerFacade(
        editorAPI,
      );

      const cacheNotificationService = cacheNotificationCreator({
        userCodeDevAnalyzerFacade,
        experiment,
        editorAPI,
        stateManagement,
        util,
      });
      const testSiteNotificationService = testSiteNotificationCreator({
        editorAPI,
        stateManagement,
        util,
      });

      editorAPI.registerSerializeComponentPlugin(
        '*',
        removeStaticCodeBehaviors,
      );

      _setCodeCodeBiContext(editorAPI);

      editorAPI.savePublish.registerFirstSaveCallback(() =>
        _setCodeCodeBiContext(editorAPI),
      );

      try {
        await _provision(editorAPI);
      } catch (error) {
        coreUtilsLib.log.error(error);
        throw error;
      }

      const firedPromises = {
        [FIRED_PROMISES.COMPONENTS_DATA_SERVICE]:
          _initializeServices(editorAPI),
        [FIRED_PROMISES.PRELOAD_SITE_CODE_FILES]: isInlocalEditor()
          ? Promise.resolve()
          : _preloadSiteCodeFiles(editorAPI, store, config),
      };
      _initializeRepluggable({ editorAPI, repluggableHost });
      _intializeState(store, editorAPI);
      intializeBi(editorAPI);
      editorAPI.preview.registerToGoToPreview(() => {
        userCodeDevAnalyzerFacade.calculateAndReportEligibilityRequest();
        if (experimentUtils.isTestSiteEntryPoint()) {
          testSiteNotificationService.addMessageToPreviewConsole();
        }
        cacheNotificationService.addMessageToPreviewConsole();
        store.dispatch(fileActions.resetContentChangedBiReports());
      });

      editorAPI.wixCode.console.registerToMessages();
      editorAPI.wixCode.tbfService.registerTbfHandler();

      unminimizeIdeOnLoadIfNeeded(editorAPI, config);
      fetchDealerAssets(editorAPI, store, config);

      consoleContainerStateService.initialize(editorAPI.developerMode.ui);

      return {
        store,
        editorAPI,
        firedPromises,
      };
    }

    return { load };
  },
);
