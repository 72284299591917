import { APIKeys, EntryPoint, Shell } from '@wix/wix-code-repluggable';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import {
  createLocalEditorPrivateAPI,
  LocalEditorPrivateAPIKey,
} from './LocalEditorPrivateAPI';
import { createLocalEditorPublicAPI } from './LocalEditorPublicAPI';
import createLiveEditorBadge from './components/LiveEditorBadge/LiveEditorBadge';
import { initi18n } from './i18n';
import createSyncLocallyButton from './components/SyncLocallyButton/SyncLocallyButton';
import {
  connectionStateReducer,
  LocalEditorState,
  syncStateReducer,
} from './state';
import React from 'react';
import { createCodeEditorFooter } from './components/CodeEditorFooter/CodeEditorFooter';
import { contributeIntroductionTour } from './tours/introductionTour';
import contributeSyncLocallyTour from './tours/syncLocallyTour';
import createDisabledPublishButton from './components/DisabledPublishButton/DisabledPublishButton';
import { platformApps } from '@wix/wix-code-common';

export const LocalEditorEntryPoint: EntryPoint = {
  name: 'Local Editor',
  getDependencyAPIs() {
    return [
      WixCodeEditorAdapterAPI,
      APIKeys.EditorContextAPI,
      APIKeys.UserPreferencesAPI,
      APIKeys.ClassicEditorAPI,
      APIKeys.FileSystemAPI,
      APIKeys.ReadOnlyAPI,
      APIKeys.CodeEditorAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.FilesViewAPI,
      APIKeys.ExperimentsAPI,
    ];
  },
  attach(shell: Shell) {
    shell.contributeState<LocalEditorState>(() => ({
      syncState: syncStateReducer,
      connectionState: connectionStateReducer,
    }));
    shell.contributeAPI(LocalEditorPrivateAPIKey, () =>
      createLocalEditorPrivateAPI(shell),
    );
    shell.contributeAPI(APIKeys.LocalEditorPublicAPI, () =>
      createLocalEditorPublicAPI(shell),
    );
  },
  async extend(shell: Shell) {
    const wixCodeEditorAdapterAPI = shell.getAPI(WixCodeEditorAdapterAPI);
    const localEditorPrivateAPI = shell.getAPI(LocalEditorPrivateAPIKey);
    const localEditorPublicAPI = shell.getAPI(APIKeys.LocalEditorPublicAPI);
    const readOnlyAPI = shell.getAPI(APIKeys.ReadOnlyAPI);
    const codeEditorAPI = shell.getAPI(APIKeys.CodeEditorAPI);
    const filesViewAPI = shell.getAPI(APIKeys.FilesViewAPI);
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
    const classicEditorAPI = shell.getAPI(APIKeys.ClassicEditorAPI);

    if (!localEditorPublicAPI.isInLocalEditor()) {
      return;
    }

    const SyncButton = createSyncLocallyButton(shell, localEditorPrivateAPI);

    const i18nInstance = await initi18n(
      wixCodeEditorAdapterAPI.legacyDependencies.util.editorModel.languageCode,
    );

    await localEditorPrivateAPI.initConnectionWithCLI();
    await contributeLocalEditorFeatures(
      wixCodeEditorAdapterAPI.notifyWixCodeLiveEditorLoaded,
    );
    localEditorPrivateAPI.subscribeToFileSystemChanges(i18nInstance);

    filesViewAPI.contributeFileNameTransformer(
      shell,
      ({ originalTabTitle, displayName, tabId, isPage, suffix }) => {
        if (!isPage) {
          return originalTabTitle;
        }
        const pageGuid = tabId.split('/').pop();
        const fileName = `${displayName}.${pageGuid}`;
        return suffix ? `${fileName}${suffix}` : fileName;
      },
      () => true,
    );

    platformApps.notifyWixCode(editorAPI, {
      eventType: 'setRoutersNotWritable',
      eventPayload: {},
    });
    async function contributeLocalEditorFeatures(
      runAfterContributions: () => void,
    ) {
      setDocumentTitle();
      setLocalReadOnly();

      const CodeEditorFooter = createCodeEditorFooter(wixCodeEditorAdapterAPI);
      codeEditorAPI.contributeFooter(shell, CodeEditorFooter);

      const liveEditorBadge = createLiveEditorBadge();
      const disabledPublishButton = createDisabledPublishButton();

      wixCodeEditorAdapterAPI.contributeTopBarItem(
        liveEditorBadge,
        'LOGO',
        ['main', 'preview'],
        localEditorPublicAPI.isInLocalEditor,
      );

      wixCodeEditorAdapterAPI.disableEditorFeatures([
        'PUBLISH',
        'PUBLISH',
        'SITE_HISTORY',
        'DUPLICATE',
        'CREATE_TEST_SITE',
        'FEEDBACK',
        'RELEASE_MANAGER',
        'SAVE_INDICATION',
      ]);

      wixCodeEditorAdapterAPI.contributeTopBarItem(
        (props) => <SyncButton {...props} />,
        'Publish',
        ['main', 'preview'],
        localEditorPublicAPI.isInLocalEditor,
      );

      wixCodeEditorAdapterAPI.contributeTopBarItem(
        disabledPublishButton,
        'Publish',
        ['main', 'preview'],
        localEditorPublicAPI.isInLocalEditor,
      );

      contributeIntroductionTour(
        (key: string) => i18nInstance.t(key),
        wixCodeEditorAdapterAPI,
      );
      contributeSyncLocallyTour(
        (key: string) => i18nInstance.t(key),
        wixCodeEditorAdapterAPI,
        classicEditorAPI,
      );

      wixCodeEditorAdapterAPI.contributeBiParams(shell, { isLiveEditor: true });
      wixCodeEditorAdapterAPI.contributePageCodeDuplicateCondition(
        shell,
        () => false,
      );

      runAfterContributions();
    }

    function setLocalReadOnly() {
      readOnlyAPI.setReadOnly('LOCAL_EDITOR', {
        codeIde: true,
        externalIde: true,
        fileSystem: true,
        propertiesPanel: {
          eventsSection: true,
          nicknamesSection: false,
          propsSection: false,
        },
        sidePanel: {
          pages: false,
          publicAndBackend: true,
          github: false,
          packages: true,
        },
        cssEditing: {
          addGlobalCss: true,
          semanticClasses: true,
        },
      });
    }

    function setDocumentTitle() {
      document.title = i18nInstance.t('localEditor.tabTitle', {
        siteName: editorAPI.siteName.get(),
      });
    }
  },
  declareAPIs() {
    return [LocalEditorPrivateAPIKey, APIKeys.LocalEditorPublicAPI];
  },
};
