import {
  DevContextAPI,
  ExperimentsAPI,
  LegacyEditorDependencies,
  MenuItemEntry,
  MenuItemId,
  MenuPanelTab,
} from '@wix/wix-code-plugin-contracts';
import { ExtensionSlot, ScopedStore, SlotKey } from '@wix/wix-code-repluggable';
import {
  State,
  getRegisteredMenuClickHandlers,
  setSelectedMenuItem,
} from '../../state';
import { getEntries } from '../menu/entries';

export interface LitePanelPrivateAPI {
  getMenuItems: () => MenuItemEntry[];
  getCodeMenuItem: () => MenuItemId;
  getTabContribution: (
    slot: ExtensionSlot<MenuPanelTab>,
    selectedTab: string,
  ) => MenuPanelTab | undefined;
  setMenuItem: (menuItemId: MenuItemId) => void;
}

export const LitePanelPrivateAPIKey: SlotKey<LitePanelPrivateAPI> = {
  name: 'LitePanelPrivateAPI',
  public: false,
};

export const createLitePanelPrivateAPI = (
  store: ScopedStore<State>,
  devContextAPI: DevContextAPI,
  legacyDependenciesAPI: LegacyEditorDependencies,
  experimentsAPI: ExperimentsAPI,
): LitePanelPrivateAPI => {
  const getLocalPort = () =>
    legacyDependenciesAPI.util.url.parseUrlParams(window.location).localPort;

  const getMenuItems = () => {
    const isInLocalEditor = !!getLocalPort();

    // TODO: get rid of this filtering (see commit) and move to new API as described in VELO-7072
    return getEntries().filter((item) => {
      if (item.value === 'github' && isInLocalEditor) {
        return false;
      } else if (
        item.value === 'css' &&
        !experimentsAPI.isOpen('specs.wixcode.CSSEditingSupport')
      ) {
        return false;
      }
      return true;
    });
  };

  const getCodeMenuItem = () => {
    if (devContextAPI.isPage()) {
      return 'page_code';
    } else if (devContextAPI.isMasterPage()) {
      return 'global_code';
    }
    return 'css';
  };

  const getTabContribution = (
    slot: ExtensionSlot<MenuPanelTab>,
    selectedTab: string,
  ) => {
    const TabContribution = slot
      .getItems()
      .map(({ contribution }) => contribution)
      .find((contribution) => contribution.id === selectedTab);
    return TabContribution;
  };

  const setMenuItem = (menuItemId: MenuItemId) => {
    const menuClickHandlers = getRegisteredMenuClickHandlers(store.getState());
    const registeredClickHandler = menuClickHandlers.find(
      (handler) => handler.id === menuItemId,
    );
    if (
      getMenuItems().find(({ value }) => value === menuItemId)?.type === 'item'
    ) {
      store.dispatch(setSelectedMenuItem(menuItemId));
    }
    registeredClickHandler?.callback();
  };

  return {
    getMenuItems,
    getCodeMenuItem,
    getTabContribution,
    setMenuItem,
  };
};
