// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Z9VhdR{display:flex}.F_hclN{margin-right:6px}.NlnVcs{margin-left:4px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"context-menu": "Z9VhdR",
	"contextMenu": "Z9VhdR",
	"info-icon": "F_hclN",
	"infoIcon": "F_hclN",
	"delete-text": "NlnVcs",
	"deleteText": "NlnVcs"
};
export default ___CSS_LOADER_EXPORT___;
