import React from 'react';
import cx from 'classnames';
import './pageElementsPanel.global.scss';
import { usePageElementsPanel } from '../../SidebarContexts';
import { Tabs } from '../tabs/Tabs';
import { TabsSlot } from '../../contributedTabs';
import styles from './PageElementsPanel.scss';
import { experimentUtils } from '@wix/wix-code-common';

type PropertiesPanelProps = {
  tabsSlot: TabsSlot;
  contributedTabs: string[];
  selectedTab: string;
  selectTab: (selectedTab: string) => void;
};

export const PropertiesPanel = ({
  tabsSlot,
  contributedTabs,
  selectedTab,
  selectTab,
}: PropertiesPanelProps) => {
  const { wixCodeStore } = usePageElementsPanel();
  const theme = wixCodeStore.getStore().getState().ide?.theme;
  const slotTabs = tabsSlot.getItems().map((item) => item.contribution);
  const isVeloLite = experimentUtils.isLiteEditor();
  return (
    <div
      className={cx(
        'page-elements-panel-container',
        theme,
        !isVeloLite && 'border-right',
      )}
      data-aid="page-elements-panel-container"
    >
      {contributedTabs.length > 1 && (
        <Tabs selectedTab={selectedTab} selectTab={selectTab} tabs={slotTabs} />
      )}
      <div className={styles.panelContent}>
        {slotTabs
          .filter((item) => item.id === selectedTab)
          .map(({ TabContent, id }) => (
            <TabContent key={id} />
          ))}
      </div>
    </div>
  );
};
