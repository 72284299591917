import React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { Tooltip } from '@wix/wix-base-ui';

export interface TooltipSymbolProps {
  automationId: string;
  className: string;
  tooltipContent: string | React.Component;
  key: string;
  symbolName: string;
}

const TooltipSymbol: React.FC<TooltipSymbolProps> = ({
  automationId,
  className,
  tooltipContent,
  symbolName,
}) => {
  return (
    <Tooltip
      automationId={automationId}
      content={tooltipContent}
      shouldTranslate={false}
    >
      <Symbol className={className} key={symbolName} name={symbolName} />
    </Tooltip>
  );
};

export default TooltipSymbol;
