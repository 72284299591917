import React, { useContext } from 'react';
import s from './InputField.scss';
import { CharLimit, InfoIcon, TextLabel } from '@wix/wix-base-ui';
import styles from './styles.scss';
import { useTranslation } from '@wix/wix-i18n-config';
import { ExternalDbsModalContext } from '../ExternalDbsModalContext';
import { connectExternalDbPanelAction } from '@wix/bi-logger-platform/v2';
import { BI } from '../constants';

interface InputFieldDescriptionProps {
  automationId?: { label: string };
  labelText: string;
  maxLength?: number;
  inputValue: string;
  textInputDatahook: string;
  infoIconDatahook: string;
  infoIconText: string;
  linkText: string;
  onLinkClick?: () => void;
  infoIconBIName: string;
}

export const InputFieldDescription: React.FC<InputFieldDescriptionProps> = ({
  automationId,
  labelText,
  maxLength,
  inputValue,
  textInputDatahook,
  infoIconDatahook,
  infoIconText,
  linkText,
  onLinkClick,
  infoIconBIName,
}) => {
  const [t] = useTranslation();
  const { biLoggerAPI } = useContext(ExternalDbsModalContext);
  return (
    <div className={styles.inputLabel}>
      <TextLabel
        automationId={automationId?.label}
        value={t(labelText)}
        shouldTranslate={false}
      />
      <div className={s.container}>
        {maxLength && (
          <div className={s.charLimitContainer}>
            <CharLimit
              minThreshold={0}
              maxThreshold={maxLength + 1}
              value={inputValue.length}
              maxValue={maxLength}
              dataHook={`${textInputDatahook}-char-limit`}
            />
          </div>
        )}
        <InfoIcon
          shouldTranslate={false}
          dataHook={infoIconDatahook}
          text={t(infoIconText)}
          linkText={t(linkText)}
          onLinkClick={onLinkClick}
          onTooltipOpen={() => {
            biLoggerAPI.report(
              connectExternalDbPanelAction({
                input_name: BI.panels.connectAdapter,
                action_type: BI.actions.hover,
                button_name: infoIconBIName,
              }),
            );
          }}
        />
      </div>
    </div>
  );
};
