import { EntryPoint } from '@wix/wix-code-repluggable';
import {
  EditorAPI,
  LegacyEditorDependencies,
} from '@wix/wix-code-plugin-contracts';
import { createClassicEditorEntryPoint } from './ClassicEditorAPI/ClassicEditorEntryPoint';
import { createLegacyEditorDependenciesEntryPoint } from './LegacyEditorDependencies/LegacyEditorDependenciesEntryPoint';

export const createEditorRepluggableEntryPoints = (
  editorAPI: EditorAPI,
  legacyDeps: LegacyEditorDependencies,
): EntryPoint[] => {
  return [
    createClassicEditorEntryPoint(editorAPI),
    createLegacyEditorDependenciesEntryPoint(legacyDeps),
  ];
};
