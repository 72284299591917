import React from 'react';
import { Provider } from 'react-redux';
import { LegacyEditorDependencies } from '@wix/wix-code-plugin-contracts';
import storeManager from '../redux-state/store/storeManager';
import {
  DoNotTranslateBaseUiComponents,
  EditorLegacyAPIsContextProvider,
  wixCodeReduxContext,
} from '@wix/wix-code-common-components';

interface WixCodeContextProviderProps {
  legacyDependencies: LegacyEditorDependencies;
}

export const WixCodeContextProvider: React.FC<WixCodeContextProviderProps> = ({
  legacyDependencies,
  children,
}) => {
  return (
    <Provider store={storeManager.getStore()} context={wixCodeReduxContext}>
      <DoNotTranslateBaseUiComponents>
        <EditorLegacyAPIsContextProvider
          legacyDependencies={legacyDependencies}
        >
          {children}
        </EditorLegacyAPIsContextProvider>
      </DoNotTranslateBaseUiComponents>
    </Provider>
  );
};
