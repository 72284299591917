import React from 'react';
import type { CompRef } from '@wix/document-services-types';
import { PanelContent } from './PanelContent/PanelContent';
import { NoCssFileState } from './NoCssFileState/NoCssFileState';
import { CssClassesContext, useCssClasses } from '../../createCssClasses';
import { ElementNotSelectedState } from './ElementNotSelectedState/ElementNotSelectedState';
import s from './CssClassesPanel.scss';
import { CssEditingInternalAPI } from '../../cssEditingInternalAPI';
import dataHooks from '../dataHooks';

export type CssClassesPanelProps = {
  createCssFileAndOpen: CssEditingInternalAPI['createCssFileAndOpen'];
  compRef: CompRef;
  isCssFileExists: boolean;
};

export const CssClassesPanel = ({
  createCssFileAndOpen,
  compRef,
  isCssFileExists,
}: CssClassesPanelProps) => {
  const cssClasses = useCssClasses(compRef);

  return (
    <CssClassesContext.Provider value={cssClasses}>
      <div className={s.panelContainer} data-hook={dataHooks.cssClasses}>
        {!isCssFileExists ? (
          <NoCssFileState onAddGlobalCss={createCssFileAndOpen} />
        ) : !compRef ? (
          <ElementNotSelectedState />
        ) : (
          <PanelContent compRef={compRef} />
        )}
      </div>
    </CssClassesContext.Provider>
  );
};
