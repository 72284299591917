import React from 'react';
import { ActionItem, TreeSectionDivider } from '@wix/wix-base-ui';
import { useBiLogger } from '@wix/wix-code-common-components';
import { leftTreeClickOnASection } from '@wix/bi-logger-platform/v2';
import dataHooks from '../../dataHooks';

type Resource = {
  label: string;
  description: string;
  onClick: () => void;
};

export type ResourceSectionProps = {
  label: string;
  resources: Resource[];
};

export const ResourceSection: React.FC<ResourceSectionProps> = ({
  label,
  resources,
}) => {
  const [expanded, setExpanded] = React.useState(true);
  const biLoggerAPI = useBiLogger();

  return (
    <>
      <TreeSectionDivider
        label={label}
        collapsed={!expanded}
        onClick={() => {
          setExpanded((prevState) => !prevState);
          biLoggerAPI.report(
            leftTreeClickOnASection({
              action: expanded ? 'close' : 'open',
              section_name: label,
            }),
          );
        }}
        alwaysShowSuffix={false}
        hideTopBorder={true}
        size="small"
        shouldTranslate={false}
        dataHook={dataHooks.resources.resources_section.sectionHeader}
      />
      {expanded &&
        resources.map((resource) => (
          <ActionItem
            title={resource.label}
            size="auto"
            description={resource.description}
            shouldTranslate={false}
            onClick={resource.onClick}
            dataHook={dataHooks.resources.resources_section.item(
              resource.label,
            )}
          />
        ))}
    </>
  );
};
