import * as React from 'react';
import s from './InstalledPackagesList.scss';
import * as _ from 'lodash';
import {
  PackagesModalContext,
  PkgInfo,
} from '../../../../packagesModalContext';
import dataHooks from '../../../../dataHooks';
import { InstalledPkgsEmptyState } from './InstalledPkgsEmptyState';
import { InstalledPkgsListNpmSection } from './listSections/InstalledPkgsListNpmSection';
import { InstalledPkgsListBuiltByVeloSection } from './listSections/InstalledPkgsListBuiltByVeloSection';
import { InstalledPkgsListBuilyByOthersSection } from './listSections/InstalledPkgsListBuilyByOthersSection';
import { InstalledPkgsListMyPkgsSection } from './listSections/InstalledPkgsListMyPkgsSection';
import { codeReusePkgsSelectors } from '../../../../packagesView/packagesViewSelectors';
import { PackageVersionPicker } from '../packageVersions/PackageVersionPicker';
import { getIsVersionPickerShowing } from '../../../packagesModalSelectors';
import { useSelector } from '@wix/wix-code-common-components';

export const InstalledPackagesList: React.FC = () => {
  const {
    selectTab,
    installedPkgs,
    selectedPackage,
    packagesService,
    isBlocksCombinedMode,
  } = React.useContext(PackagesModalContext);
  const showVersionPicker = useSelector(getIsVersionPickerShowing);
  const showCodeReuseSection = !_.isEmpty(installedPkgs.codeReuse);
  const showNpmSection = !_.isEmpty(installedPkgs.npm);
  const isEmptyState = !showCodeReuseSection && !showNpmSection;
  const builtByVelo = useSelector(codeReusePkgsSelectors.installed.byVelo);
  const builtByOthers = useSelector(codeReusePkgsSelectors.installed.byOthers);
  const myPackages = useSelector(codeReusePkgsSelectors.installed.myPackages);

  let pkg = packagesService.getInstalledPkg(selectedPackage) as PkgInfo;
  if (!pkg) {
    pkg = packagesService.getAvailablePkg(selectedPackage) as PkgInfo;
  }

  return (
    <div className={s.scroller} data-hook={dataHooks.INSTALLED_PKGS_CONTAINER}>
      {isEmptyState ? (
        <InstalledPkgsEmptyState
          dataHook={dataHooks.INSTALLED_PKGS_EMPTY_STATE}
          onLinkClick={selectTab}
        />
      ) : showVersionPicker ? (
        <PackageVersionPicker />
      ) : (
        <div>
          {isBlocksCombinedMode ? (
            <>
              {!_.isEmpty(installedPkgs.npm) && <InstalledPkgsListNpmSection />}

              {!_.isEmpty(builtByVelo) && (
                <InstalledPkgsListBuiltByVeloSection />
              )}
            </>
          ) : (
            <>
              {!_.isEmpty(builtByVelo) && (
                <InstalledPkgsListBuiltByVeloSection />
              )}
              {!_.isEmpty(builtByOthers) && (
                <InstalledPkgsListBuilyByOthersSection />
              )}
              {!_.isEmpty(myPackages) && <InstalledPkgsListMyPkgsSection />}
              {!_.isEmpty(installedPkgs.npm) && <InstalledPkgsListNpmSection />}
            </>
          )}
        </div>
      )}
    </div>
  );
};
