import s from './overlayPanel.scss';
import React, { FC } from 'react';
import { WixCodeTheme } from '../../../constants/themes';
import cx from 'classnames';
import { AppState } from '@/infra/redux-state/reducers/rootReducer';
import {
  useEditorLegacyAPIs,
  connectToStores,
} from '@wix/wix-code-common-components';

interface FailOverlayPanelProps {
  theme: WixCodeTheme;
}

const FailOverlayPanel: React.FC<FailOverlayPanelProps> = ({ theme }) => {
  const {
    legacyDependenciesAPI: { util },
  } = useEditorLegacyAPIs();
  return (
    <div className={cx(s.wixCodePageEditorOverlay, s[theme])}>
      <div className={s.messageContainer}>
        <p className={s.lead}>
          {util.translate('WixCode_FailToLoadFile_Lead')}
        </p>
        <p className={s.sub}>{util.translate('WixCode_FailToLoadFile_Sub')}</p>
      </div>
    </div>
  );
};

const mapCodeStateToProps = (state: AppState) => {
  const theme = state.ide.theme;

  return {
    theme,
  };
};

let ConnectedComponent: FC | null = null;
const FailOverlayPanelHot: React.FC<Omit<FailOverlayPanelProps, 'theme'>> = (
  origProps,
) => {
  const {
    legacyDependenciesAPI: { util },
  } = useEditorLegacyAPIs();
  if (ConnectedComponent === null) {
    ConnectedComponent = connectToStores({
      util,
      mapCodeStateToProps,
      comp: FailOverlayPanel,
    });
  }
  return <ConnectedComponent {...origProps} />;
};

export default FailOverlayPanelHot;
