const dataHooks = {
  sectionRoot: 'tab-section-root',
  sectionHeader: 'css_section_header',
  emptyState: {
    cta: 'cta-button',
    ctaWrapper: 'empty-state-cta-wrapper',
    badge: 'empty-state-badge',
  },
  cssClassesEmptyState: {
    elementNotSelected: 'element-not-selected',
    noCssFile: 'no-css-file',
    noCssFileCta: 'no-css-file-cta',
  },
  treeRoot: 'corvid-site-structure__tree-root',
  treeNode: 'corvid-site-structure-tree-node',
  contextMenuIcon: 'context-menu-icon',
  delete: 'delete',
  fileDeleteModal: 'file-delete-modal',
  semanticClassCta: 'semantic-class-cta',
  cssClasses: 'css-classes',
  cssReference: 'css-reference',
  cssReferenceCta: 'css-reference-cta',
  openGlobalCss: 'open-global-css',
};

export default dataHooks;
