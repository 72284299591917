import { CustomModal, Preloader } from '@wix/wix-base-ui';
import React, { useState } from 'react';
import { SpiFolder, SpiService } from '../../../SpiService';
import { SpiProviderType } from '../../../SpiTypes';
import { useOnMount } from '../../../utils';
import s from '../SpiWizard.scss';
import { WizardStep1 } from '../WizardStep1';
import { WizardStep2 } from '../WizardStep2';
import { WizardStep3 } from '../WizardStep3/WizardStep3';
import { i18n as i18nType, I18nextProvider } from '@wix/wix-i18n-config';
import {
  BiLoggerAPI,
  PanelsAPI,
  UserPreferencesAPI,
} from '@wix/wix-code-plugin-contracts';

type WizardStep = 0 | 1 | 2;

type SpiWizardProps = {
  spiType: SpiProviderType;
  onClose: () => void;
  onHelp: () => void;
  createSpi: (spiName: string, spiType: SpiProviderType) => Promise<void>;
  existingSpis: SpiFolder[];
  initialStep?: WizardStep;
  onStepChange: (step: WizardStep) => void;
  biLoggerAPI: BiLoggerAPI;
};

export type SpiWizardContainerProps = {
  i18n: i18nType;
  panelsAPI: PanelsAPI;
  userPreferencesAPI: UserPreferencesAPI;
  spiService: SpiService;
  spiType: SpiProviderType;
  biLoggerAPI: BiLoggerAPI;
};

export const SpiWizard: React.FC<SpiWizardProps> = ({
  spiType,
  onClose,
  onHelp,
  createSpi,
  existingSpis,
  initialStep = 0,
  onStepChange,
  biLoggerAPI,
}) => {
  const [currentStep, setWizardStep] = useState(initialStep);

  const setStep = (nextStep: WizardStep) => {
    setWizardStep(nextStep);
    onStepChange(nextStep);
  };

  const steps = [
    <WizardStep1
      spiType={spiType}
      biLoggerAPI={biLoggerAPI}
      onConfirm={() => setStep(1)}
      {...{ onClose, onHelp }}
    />,
    <WizardStep2
      spiType={spiType}
      onConfirm={() => setStep(2)}
      biLoggerAPI={biLoggerAPI}
      {...{ onClose, onHelp }}
    />,
    <WizardStep3
      spiType={spiType}
      spisList={existingSpis}
      createSpi={createSpi}
      biLoggerAPI={biLoggerAPI}
      {...{ onClose, onHelp }}
    />,
  ];

  return steps[currentStep];
};

export const SpiWizardContainer: React.FC<SpiWizardContainerProps> = ({
  i18n,
  panelsAPI,
  userPreferencesAPI,
  spiService,
  spiType,
  biLoggerAPI,
}) => {
  const [spisList, setSpisList] = useState<SpiFolder[] | undefined>(undefined);

  useOnMount(() => {
    const getSpisList = async () => {
      const spiList = await spiService.getSpisList(spiType.folderPath);
      setSpisList(spiList);
    };
    getSpisList();
  });

  return spisList ? (
    <I18nextProvider i18n={i18n}>
      <SpiWizard
        spiType={spiType}
        onClose={panelsAPI.closePanel}
        onHelp={() => {
          panelsAPI.openHelpCenter('780f2319-d481-4df0-a3b0-3024274bfb5e');
        }}
        createSpi={spiService.createSpi}
        existingSpis={spisList}
        initialStep={
          userPreferencesAPI.getSitePreference<number>(
            spiType.userPreferencesKey,
          ) as WizardStep
        }
        onStepChange={(newStep) =>
          userPreferencesAPI.setSitePreference(
            spiType.userPreferencesKey,
            newStep,
          )
        }
        biLoggerAPI={biLoggerAPI}
      />
    </I18nextProvider>
  ) : (
    <CustomModal onCloseButtonClick={() => {}}>
      <div className={s.preloader}>
        <Preloader />
      </div>
    </CustomModal>
  );
};
