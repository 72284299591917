import React from 'react';
import { HorizontalStepper } from '@wix/wix-base-ui/next';
import { OnboardingState } from '../../types/GithubTypes';
import dataHooks from '../dataHooks';
import { Step } from '../ConnectInPhasesModal/ConnectInPhasesModal';

const Steps: React.FC<{
  steps: Step[];
  translate: Function;
  onboardingState: OnboardingState;
  currentStepIndex: number;
  isError: boolean;
}> = ({ steps, translate: t, onboardingState, currentStepIndex, isError }) => {
  const getStatus = (
    index: number,
  ): 'completed' | 'disabled' | 'standard' | 'error' => {
    if (index < currentStepIndex) {
      return 'completed';
    } else if (index === currentStepIndex) {
      if (isError) {
        return 'error';
      }
      return 'standard';
    }
    return 'disabled';
  };

  const stepperSteps = steps.map((step, index) => ({
    id: step.onboardingState,
    label: t(step.value),
    status: getStatus(index),
  }));
  return (
    <HorizontalStepper
      dataHook={dataHooks.connectModal.steps.container}
      steps={stepperSteps}
      activeStepId={onboardingState}
      layout="compact"
    />
  );
};

export default Steps;
