import React from 'react';
import cx from 'classnames';
import { propertiesPanelClickOnTab } from '@wix/bi-logger-platform/v2';
import '../pageElementsPanel/pageElementsPanel.global.scss';
import { PropertiesPanelTab, tabsOrder } from '@wix/wix-code-plugin-contracts';
import { Text } from '@wix/wix-base-ui';
import { usePageElementsPanel } from '../../SidebarContexts';
import { useBiLogger } from '@wix/wix-code-common-components';

type TabsProps = {
  selectedTab: string;
  tabs: PropertiesPanelTab[];
  selectTab: (selectedTab: string) => void;
};
export const Tabs = ({ tabs, selectedTab, selectTab }: TabsProps) => {
  const biLoggerAPI = useBiLogger();
  const { editorAPI } = usePageElementsPanel();

  const onTabClick = (id: string, label: string) => {
    selectTab(id);
    biLoggerAPI.report(
      propertiesPanelClickOnTab({
        tab: label,
        path: editorAPI.developerMode.getContext().data.id,
      }),
    );
  };

  return (
    <div className="properties-panel-tabs-container">
      {tabs
        .sort(
          (tabA, tabB) =>
            tabsOrder.indexOf(tabA.id) - tabsOrder.indexOf(tabB.id),
        )
        .map(({ id, label }) => (
          <div
            key={id}
            className="properties-panel-tab"
            onClick={() => onTabClick(id, label)}
          >
            <span
              className={cx('properties-panel-tab-label', {
                'properties-panel-selected-tab': selectedTab !== id,
              })}
            >
              <Text size="tiny" weight="normal" skin="secondary">
                {label}
              </Text>
            </span>
          </div>
        ))}
    </div>
  );
};
