import React, { ReactElement, useEffect } from 'react';

import ReactDOM from 'react-dom';

interface OuterClickProps {
  onOuterClick(event: MouseEvent): void;
  children: ReactElement;
}

export const OuterClick: React.FC<OuterClickProps> = ({
  onOuterClick,
  children,
}) => {
  useEffect(() => {
    window.document.addEventListener('mousedown', handleClickOutside, true);

    return () =>
      window.document.removeEventListener(
        'mousedown',
        handleClickOutside,
        true,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    const domNode = ReactDOM.findDOMNode(this);
    if (domNode && !domNode.contains(event.target as Node)) {
      onOuterClick(event);
    }
  };

  return React.Children.only(children);
};
