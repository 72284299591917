import React, { useEffect, useState } from 'react';
import {
  i18n,
  I18nextProvider,
  initI18n,
  AsyncMessagesLoader,
} from '@wix/wix-i18n-config';

export interface i18nManager {
  initI18n: () => Promise<i18n>;
  TranslationProvider: (children: React.ReactNode) => JSX.Element | null;
}

export const i18nManagerCreator: ({
  languageCode,
  asyncMessagesLoader,
}: {
  languageCode: string;
  asyncMessagesLoader: AsyncMessagesLoader;
}) => i18nManager = ({ languageCode, asyncMessagesLoader }) => {
  let initializedI18n: i18n;

  const _initI18n = async () => {
    if (initializedI18n) {
      return initializedI18n;
    }

    initializedI18n = initI18n({
      locale: languageCode,
      disableAutoInit: true,
      asyncMessagesLoader,
    });

    await initializedI18n.init();

    return initializedI18n;
  };

  return {
    initI18n: _initI18n,
    TranslationProvider: (children: React.ReactNode) => {
      const [i18nInstance, setI18nInstance] = useState<null | i18n>(null);

      useEffect(() => {
        if (initializedI18n) {
          setI18nInstance(initializedI18n);
        } else {
          _initI18n().then(() => setI18nInstance(initializedI18n));
        }
      }, []);

      return i18nInstance ? (
        <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>
      ) : null;
    },
  };
};
