import { SET_DEVELOPER_MODE_CONTEXT } from './actions';
import { DeveloperModeContextChange, DeveloperModeContext } from './index';

const initialState = null;

export const developerModeContextReducer = (
  state: DeveloperModeContext = initialState,
  action: DeveloperModeContextChange,
): DeveloperModeContext => {
  switch (action.type) {
    case SET_DEVELOPER_MODE_CONTEXT: {
      return action.newContext;
    }
    default: {
      return state;
    }
  }
};
