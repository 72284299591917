import { ContextualDivider, NotificationBanner } from '@wix/wix-base-ui';
import React, { useState } from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { CustomModal } from '../../CustomModal/CustomModal';
import {
  SpiNameValidator,
  spiNameValidator,
} from '../SpiNameValidator/SpiNameValidator';
import { WizardStep3Footnote } from './WizardStep3Footnote';
import { dataHooks } from '../../../consts/dataHooks';
import { SpiFolder } from '../../../SpiService';
import { WizardStep3NamePicker } from './WizardStep3NamePicker';
import { WizardStep3DynamicFileNames } from './WizardStep3DynamicFileNames';
import { WizardStep3ActionButtons } from './WizardStep3ActionButtons';
import s from '../SpiWizard.scss';
import { SpiProviderType } from '../../../SpiTypes';
import {
  cancelSpiEvent,
  ideFileFolderCreatedHelper,
  customIntegrationQuickActionClickedEvent,
  BiCategory,
} from '../../../utils/reportEvents';
import { BiLoggerAPI } from '@wix/wix-code-plugin-contracts';

export const WizardStep3: React.FC<{
  spisList: SpiFolder[];
  spiType: SpiProviderType;
  createSpi: (name: string, type: SpiProviderType) => Promise<void>;
  onClose: () => void;
  onHelp: () => void;
  biLoggerAPI: BiLoggerAPI;
}> = ({ spisList, spiType, createSpi, onClose, onHelp, biLoggerAPI }) => {
  const [t] = useTranslation();
  const [spiName, setSpiName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isNameValid, setIsValid] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);

  const onChange = async (input: string) => {
    const spiValidator: SpiNameValidator = spiNameValidator(spisList);
    setSpiName(input);
    const { isValid, errors } = spiValidator.isValid(input);
    if (!isValid) {
      setErrorMessage(t(errors[0]));
      setIsValid(false);
      return;
    }
    setErrorMessage('');
    setIsValid(true);
  };

  const onCancelSpi = () => {
    onClose();
    biLoggerAPI.report(cancelSpiEvent({ spiType: spiType.type }));
  };

  const onAddSpi = async () => {
    try {
      setShowLoader(true);
      await createSpi(spiName, spiType);
      biLoggerAPI.report(
        customIntegrationQuickActionClickedEvent(
          BiCategory.ADD_EDIT_CODE,
          spiType.type,
        ),
      );
      biLoggerAPI.report(
        ideFileFolderCreatedHelper({
          path: spiType.folderPath,
          type: 'folder',
        }),
      );
      biLoggerAPI.report(
        ideFileFolderCreatedHelper({
          path: `${spiType.folderPath}${spiName}`,
          type: 'folder',
        }),
      );
      biLoggerAPI.report(
        ideFileFolderCreatedHelper({
          path: `${spiType.folderPath}${spiName}/${spiName}.js`,
          type: 'js',
        }),
      );
      biLoggerAPI.report(
        ideFileFolderCreatedHelper({
          path: `${spiType.folderPath}${spiName}/${spiName}-config.js`,
          type: 'js',
        }),
      );
    } catch (e) {
      setShowErrorBanner(true);
      setShowLoader(false);
    }
  };

  return (
    <CustomModal
      dataHook={dataHooks.SPI_WIZARD_STEP_3}
      title={t(spiType.translationKeys.wizardPhase3Heading)}
      footnote={<WizardStep3Footnote spiType={spiType} />}
      className={s.step3Container}
      removeContentPadding={true}
      {...{ onClose, onHelp }}
    >
      {showErrorBanner && (
        <div className={s.errorBannerContainer}>
          <NotificationBanner
            skin="alert"
            text={t('Wizard_Phase_3_Spi_Creation_Failed')}
            shouldTranslate={false}
          />
        </div>
      )}
      <WizardStep3NamePicker
        spiName={spiName}
        errorMessage={errorMessage}
        onChange={onChange}
        isValid={isNameValid}
      />
      <ContextualDivider
        value={t('Wizard_Phase_3_Divider')}
        shouldTranslate={false}
      />
      <WizardStep3DynamicFileNames spiName={spiName} spiType={spiType} />
      <WizardStep3ActionButtons
        isConfirmDisabled={!isNameValid || spiName.length === 0 || showLoader}
        onConfirm={onAddSpi}
        showLoader={showLoader}
        onClose={onCancelSpi}
      />
    </CustomModal>
  );
};
