import React from 'react';
import { Badge, TreeSectionDivider } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import OverviewSection from './OverviewSection/OverviewSection';
import { GitHubCommit, GithubRepository } from '../../../types/GithubTypes';
import { GithubModalsAPI } from '../../../githubModalsAPI';
import CommitsSection from './CommitsSection/CommitsSection';
import style from './GithubConnected.scss';
import dataHooks from '../../dataHooks';

export const GithubConnected: React.FC<{
  githubRepository?: GithubRepository;
  githubModalsAPI: GithubModalsAPI;
  commitHistoryUrl?: string;
  commits?: GitHubCommit[];
}> = ({ githubRepository, githubModalsAPI, commitHistoryUrl, commits }) => {
  const [t] = useTranslation();
  const [isOverviewCollapsed, setIsOverviewCollapsed] = React.useState(false);
  const [isCommitsCollapsed, setIsCommitsCollapsed] = React.useState(false);

  return (
    <div
      data-hook={dataHooks.connectedGithubTab.container}
      className={style.githubConnectedContainer}
    >
      <Badge
        className={style.codeOnlyBadge}
        skin="attention"
        value={t('githubIntegration.github_connected.overview_section.badge')}
        shouldTranslate={false}
      />
      <TreeSectionDivider
        shouldTranslate={false}
        label={t('githubIntegration.github_connected.overview_section.title')}
        collapsed={isOverviewCollapsed}
        onClick={() => setIsOverviewCollapsed(!isOverviewCollapsed)}
      />
      {!isOverviewCollapsed && (
        <OverviewSection
          githubRepository={githubRepository}
          githubModalsAPI={githubModalsAPI}
        />
      )}
      <TreeSectionDivider
        shouldTranslate={false}
        label={t('githubIntegration.github_connected.commits_section.title')}
        collapsed={isCommitsCollapsed}
        onClick={() => setIsCommitsCollapsed(!isCommitsCollapsed)}
      />
      {!isCommitsCollapsed && (
        <CommitsSection
          commitHistoryUrl={commitHistoryUrl}
          commits={commits || []}
        />
      )}
    </div>
  );
};

export default GithubConnected;
