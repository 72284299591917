import React from 'react';
import { FileTreeRoot } from './FileTreeRoot';
import {
  ReadOnlyModeContext,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import { useTranslate } from '@/infra/i18n/useTranslate';

export const PublicFileTree: React.FC<{
  translations?: { [key: string]: string };
}> = ({ translations }) => {
  const { editorAPI } = useEditorLegacyAPIs();
  const { t } = useTranslate();
  const { readOnlyMode } = React.useContext(ReadOnlyModeContext);
  const publicId = editorAPI.wixCode.fileSystem.getRoots().public.location;

  return (
    <FileTreeRoot
      id={publicId}
      labelOverride={t('Left_Tree_Components_Category_CodeFiles_Public')}
      isBackend={false}
      translations={translations}
      readOnlyMode={!!readOnlyMode.sidePanel?.publicAndBackend}
    />
  );
};
