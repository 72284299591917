import React from 'react';

export default (Component: React.ComponentType<any>) => {
  class WixCodeErrorBoundary extends React.Component<any> {
    componentDidCatch(error: Error, { componentStack }: React.ErrorInfo): void {
      if (window.wixCodeSentry) {
        let boundaryProps;
        try {
          boundaryProps = JSON.stringify(this.props, null, 2);
        } catch (e) {
          // No props for you :(
        }
        window.wixCodeSentry.captureError(error, {
          componentStack,
          boundaryProps,
        });
      }
    }
    render() {
      return <Component {...this.props} />;
    }
  }
  return WixCodeErrorBoundary;
};
