import {
  ModalRenderer,
  WixCodeEditorAdapterAPI,
} from '@wix/wix-code-editor-adapter';
import { Shell } from '@wix/wix-code-repluggable';
import React from 'react';
import { DeleteModal } from './DeleteModal/DeleteModal';
import { I18nextProvider, i18n } from '@wix/wix-i18n-config';
import { CssEditingInternalAPIKey } from '../../cssEditingInternalAPI';
import { BiLoggerContext } from '../../biLoggerContext';
import { BiLoggerAPI } from '@wix/wix-code-plugin-contracts';

export interface ModalsService {
  openDeleteModal: () => void;
}

interface ModalsCreatorParams {
  shell: Shell;
  initializedI18n: i18n;
  biLoggerAPI: BiLoggerAPI;
}
interface OpenModalParams {
  modalRenderer: ModalRenderer;
  onClose?: () => void;
  closeOnClickOutside?: boolean;
}

export const createModalsService = ({
  shell,
  initializedI18n,
  biLoggerAPI,
}: ModalsCreatorParams): ModalsService => {
  const modals = createModals();

  const internalAPI = shell.getAPI(CssEditingInternalAPIKey);
  const wixCodeEditorAdapterAPI = shell.getAPI(WixCodeEditorAdapterAPI);

  function openModal({
    modalRenderer,
    onClose,
    closeOnClickOutside,
  }: OpenModalParams) {
    wixCodeEditorAdapterAPI.modalAPI.showModal(modalRenderer, {
      backdrop: true,
      closeOnClickOutside: closeOnClickOutside ?? true,
      ...(onClose ? { onClose } : {}),
    });
  }

  function createModals() {
    return {
      DeleteModal: withContext(DeleteModal),
    };
  }

  function withContext<P extends object>(
    Modal: React.ComponentType<P>,
  ): React.ComponentType<P> {
    return (props) => {
      return (
        <I18nextProvider i18n={initializedI18n}>
          <BiLoggerContext.Provider value={biLoggerAPI}>
            <Modal {...props} />
          </BiLoggerContext.Provider>
        </I18nextProvider>
      );
    };
  }

  const openDeleteModal = () => {
    openModal({
      modalRenderer: (closeModal) => (
        <modals.DeleteModal
          closeModal={closeModal}
          onDelete={internalAPI.deleteFile}
        />
      ),
    });
  };

  return {
    openDeleteModal,
  };
};
