import { debounce } from 'lodash';
import { APIKeys, Shell, SlotKey } from '@wix/wix-code-repluggable';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import createLocalEditorService, {
  LocalEditorService,
} from './services/localEditorService';
import createShowErrorModal, {
  ShowErrorModal,
} from './components/ErrorModal/ErrorModal';
import createSyncChangesModal from './components/SyncChangesModal/SyncChangesModal';
import { createSelectors, LocalEditorState, syncActionCreators } from './state';
import {
  CreateNewRevisionError,
  SyncDevEditorChangesError,
} from './services/CliErrors';
import {
  createLocalEditorBIEvents,
  LocalEditorBIEvents,
} from './utils/biEvents';
import { i18n } from '@wix/wix-i18n-config';

const SHOW_SYNC_CHANGES_MODAL = 'SHOW_SYNC_CHANGES_MODAL';

export interface LocalEditorPrivateAPI {
  initConnectionWithCLI: () => Promise<void>;
  syncChanges: () => Promise<void>;
  setDontShowSyncChangesModal: () => void;
  shouldShowSyncChangesModal: () => boolean;
  showSyncChangesModalIfNeeded: () => Promise<void>;
  showErrorModal: ShowErrorModal;
  getRevision: () => string;
  isSyncing: () => boolean;
  isSyncSuccess: () => boolean;
  subscribeToFileSystemChanges: (i18nInstance: i18n) => void;
  contributeOnCLIServiceDisconnect: (
    shell: Shell,
    contribution: () => void,
  ) => () => void;
}

export const LocalEditorPrivateAPIKey: SlotKey<LocalEditorPrivateAPI> = {
  name: 'LocalEditorPrivateAPI',
  public: false,
};

export const createLocalEditorPrivateAPI = (
  shell: Shell,
): LocalEditorPrivateAPI => {
  const wixCodeEditorAdapterAPI = shell.getAPI(WixCodeEditorAdapterAPI);
  const userPreferencesAPI = shell.getAPI(APIKeys.UserPreferencesAPI);
  const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
  const showErrorModal = createShowErrorModal(wixCodeEditorAdapterAPI);
  const classicEditorAPI = shell.getAPI(APIKeys.ClassicEditorAPI);
  const fileSystemAPI = shell.getAPI(APIKeys.FileSystemAPI);
  const localEditorStore = shell.getStore<LocalEditorState>();
  const localEditorSelectors = createSelectors(localEditorStore.getState);
  const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
  const experimentsAPI = shell.getAPI(APIKeys.ExperimentsAPI);

  const getRevision = () => `${editorAPI.dsRead.generalInfo.getRevision()}`;

  const getLocalPort = (): number | undefined =>
    wixCodeEditorAdapterAPI.legacyDependencies.util.url.parseUrlParams(
      window.location,
    ).localPort;

  const biEvents: LocalEditorBIEvents = createLocalEditorBIEvents({
    biLoggerAPI,
    getRevision,
  });

  function redirectToErrorPage() {
    window.location.assign(
      'https://www.editorx.com/editor-x/wix-cli-terminated',
    );
  }

  function onCLIServiceDisconnected() {
    const contributions = onCLIServiceDisconnectSlot.getItems();
    if (contributions.length === 0) {
      redirectToErrorPage();
      return;
    }
    for (const onCLIServiceDisconnectedContribution of contributions) {
      onCLIServiceDisconnectedContribution.contribution();
    }
  }

  const localEditorService: LocalEditorService = createLocalEditorService(
    editorAPI,
    experimentsAPI,
    getLocalPort(),
    showErrorModal,
    fileSystemAPI,
    shell.getStore(),
    onCLIServiceDisconnected,
  );

  const setDontShowSyncChangesModal = () =>
    userPreferencesAPI.setSitePreference(SHOW_SYNC_CHANGES_MODAL, 'false');

  const shouldShowSyncChangesModal = () => {
    const sitePreference = userPreferencesAPI.getSitePreference(
      SHOW_SYNC_CHANGES_MODAL,
    );
    const result = sitePreference !== 'false';
    return result;
  };

  const syncChanges = async () => {
    localEditorStore.dispatch(syncActionCreators.setSyncing(true));
    try {
      await localEditorService.syncChanges();
      localEditorStore.dispatch(syncActionCreators.setSyncSuccess(true));
      biEvents.syncChangesSuccessfulEvent({ success: true });
      setTimeout(() => {
        localEditorStore.dispatch(syncActionCreators.setSyncSuccess(false));
      }, 2000);
    } catch (error) {
      if (
        error instanceof SyncDevEditorChangesError ||
        error instanceof CreateNewRevisionError
      ) {
        biEvents.syncChangesSuccessfulEvent({ success: false });
        showErrorModal({
          titleKey: error.titleKey,
          contentKey: error.contentKey,
          primaryButtonOnClick: syncChanges,
          biEvents,
        });
      }
    }
    localEditorStore.dispatch(syncActionCreators.setSyncing(false));
  };

  const onCLIServiceDisconnectSlotKey: SlotKey<() => void> = {
    name: 'onCLIServiceDisconnectSlot',
  };
  const onCLIServiceDisconnectSlot = shell.declareSlot(
    onCLIServiceDisconnectSlotKey,
  );

  return {
    initConnectionWithCLI: async () => {
      await localEditorService.initConnectionWithCLI();
    },
    syncChanges,
    getRevision,
    setDontShowSyncChangesModal,
    shouldShowSyncChangesModal,
    showSyncChangesModalIfNeeded: async () => {
      if (shouldShowSyncChangesModal()) {
        wixCodeEditorAdapterAPI.modalAPI.showModal(
          (closeModal) =>
            createSyncChangesModal(
              closeModal,
              (shouldNotShowModalAgain: boolean) => () => {
                if (shouldNotShowModalAgain) {
                  setDontShowSyncChangesModal();
                }
                syncChanges();
                closeModal();
              },
              biEvents,
            ),
          {
            backdrop: true,
            closeOnClickOutside: true,
          },
        );
      } else {
        await syncChanges();
      }
    },
    showErrorModal,
    isSyncing: () => localEditorSelectors.isSyncing(),
    isSyncSuccess: () => localEditorSelectors.isSyncSuccess(),
    subscribeToFileSystemChanges: (i18nInstance: i18n) => {
      const reactToCodeChangedInEditor = debounce(onCodeChangedInEditor, 7000, {
        leading: true,
        trailing: false,
      });

      const reactToCodeChangedInPreview = debounce(
        onCodeChangedInPreview,
        2000,
        {
          leading: false,
          trailing: true,
        },
      );

      classicEditorAPI.editorAPI.wixCode.fileSystem.subscribeToConcurrentChange(
        () => {
          const isInPreviewMode = editorAPI.preview.isInPreviewMode();
          biEvents.codeChangesSyncToLiveEditorAction({ isInPreviewMode });

          if (isInPreviewMode) {
            return reactToCodeChangedInPreview();
          }
          return reactToCodeChangedInEditor();
        },
      );

      async function onCodeChangedInEditor() {
        wixCodeEditorAdapterAPI.showSnackbar(
          i18nInstance.t('localEditor.liveEditor.codeUpdatedSnackbar'),
        );
      }
      function onCodeChangedInPreview() {
        wixCodeEditorAdapterAPI.showSnackbar(
          i18nInstance.t('localEditor.liveEditor.preview.codeUpdatedSnackbar'),
        );
        editorAPI.pages.refresh();
      }
    },
    contributeOnCLIServiceDisconnect: (contributingShell, contribution) => {
      onCLIServiceDisconnectSlot.contribute(contributingShell, contribution);
      return () => {
        onCLIServiceDisconnectSlot.discardBy(
          (item) => item.contribution === contribution,
        );
      };
    },
  };
};
