import * as React from 'react';
import { Button, TextLabel, Text } from '@wix/wix-base-ui';
import { MarkdownViewerLazy } from './markdownViewerLazy';
import { useEffect, useState } from 'react';
import s from './SplitMarkdownViewer.scss';
import dataHooks from './dataHooks';
import classNames from 'classnames';
import { useEditorLegacyAPIs } from '@wix/wix-code-common-components';
import { useTranslate } from '@/infra/i18n/useTranslate';

interface SplitMarkdownViewerProps {
  fileId: string;
  Editor: any;
  editorProps: any;
}

export const SplitMarkdownViewer: React.FC<SplitMarkdownViewerProps> = ({
  fileId,
  Editor,
  editorProps,
}) => {
  const { editorAPI } = useEditorLegacyAPIs();
  const { t } = useTranslate();
  const [content, setContent] = useState(null);
  const [isPreview, setIsPreview] = useState(false);

  const buttonLabel = isPreview
    ? t('Wix_Blocks_ReadmeFile_CodePanel_EditButton')
    : t('Wix_Blocks_ReadmeFile_CodePanel_PreviewButton');

  useEffect(() => {
    const getContent = async () => {
      const updatedContent =
        editorAPI.wixCode.models.get({ modelId: fileId }).getValue() || ` `;
      setContent(updatedContent);
    };
    getContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreview, fileId]);

  useEffect(() => {
    setIsPreview(false);
  }, [fileId]);

  return (
    <div className={s.readmeContainer} data-hook={dataHooks.MARKDOWN_CONTENT}>
      <div className={s.readmeExplanationHeader}>
        <div className={s.readmeLabelsContainer}>
          <div className={s.readmeHeaderContainer}>
            <TextLabel
              value={t('Wix_Blocks_ReadmeFile_CodePanel_Title')}
              type="T06"
            />
          </div>
          <Text skin="secondary">Wix_Blocks_ReadmeFile_CodePanel_Subtitle</Text>
        </div>
        <Button
          className={classNames('btn-inverted', s.readmePreviewBtn)}
          dataHook={dataHooks.README_PREVIEW_BUTTON}
          onClick={() => setIsPreview(!isPreview)}
        >
          {buttonLabel}
        </Button>
      </div>

      {isPreview ? (
        <div
          className="markdown-wrapper"
          data-hook={dataHooks.README_PREVIEW_CONTAINER}
        >
          <div className="markdown-inner-wrapper">
            <MarkdownViewerLazy>{content}</MarkdownViewerLazy>
          </div>
        </div>
      ) : (
        <Editor key="FileCodeEditor" {...editorProps} />
      )}
    </div>
  );
};
