import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import createElementorySupport from '@wix/wix-elementory-globals';
import { RequestOptions } from '../../extractHttpFunctionsRequestOptions';

export interface RunFunctionResult {
  isError: boolean;
  payload: any;
  runtime: number;
}

const BE_FUNCTIONS_ENDPOINT = 'dev.wix-code.com';

const isJsFile = (fileId: AnyFixMe) => fileId.endsWith('.js');

const buildPath = (fileId: AnyFixMe, functionName: AnyFixMe) =>
  (isJsFile(fileId)
    ? fileId.replace(/^backend\//, '')
    : '_webMethods/' + fileId) + `/${functionName}.ajax`;

const getElementorySupportArguments = (
  editorAPI: EditorAPI,
  fileId: AnyFixMe,
) => {
  const { instance } = editorAPI.wixCode.getClientSpec();
  const { gridAppId } = editorAPI.wixCode.fileSystem.getViewerInfo();

  const baseUrl = `//${gridAppId}.${BE_FUNCTIONS_ENDPOINT}/${
    isJsFile(fileId) ? '_rpc/' : ''
  }`;

  const queryParameters = `?gridAppId=${gridAppId}&instance=${instance}&viewMode=preview`;

  return [baseUrl, queryParameters];
};

const extractHeaders = (headers: Headers) => {
  const result: Record<string, string> = {};

  headers.forEach((value, key) => {
    result[key] = value;
  });

  return result;
};

const extractBody = async (response: Response) => {
  const body = await response.text();

  try {
    return JSON.parse(body);
  } catch {
    return body;
  }
};

export const runBackendFunction = async (
  editorAPI: EditorAPI,
  { fileId, functionName, params }: AnyFixMe,
): Promise<RunFunctionResult> => {
  const startTime = performance.now();

  const elementorySupport = createElementorySupport(
    ...getElementorySupportArguments(editorAPI, fileId),
  );
  const path = buildPath(fileId, functionName);
  try {
    const payload = await elementorySupport.getJSON(path, params);
    const runtime = Math.floor(performance.now() - startTime);
    return { isError: false, payload, runtime };
  } catch (error) {
    const runtime = Math.floor(performance.now() - startTime);
    return { isError: true, payload: error, runtime };
  }
};

export const runHttpFunction = async ({
  path,
  method,
  headers,
  body,
}: RequestOptions): Promise<RunFunctionResult> => {
  const startTime = performance.now();

  try {
    const response = await fetch(path, {
      method,
      headers,
      body,
    });
    const runtime = Math.floor(performance.now() - startTime);

    const payload = {
      status: response.status,
      headers: extractHeaders(response.headers),
      body: await extractBody(response),
    };

    return { isError: false, payload, runtime };
  } catch (error) {
    const runtime = Math.floor(performance.now() - startTime);
    return { isError: true, payload: error, runtime };
  }
};
