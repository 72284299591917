import React from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { CompRef } from '@wix/document-services-types';
import { Divider, InfoIcon, TextLabel } from '@wix/wix-base-ui';
import { propertiesPanelActionClick } from '@wix/bi-logger-code/v2';
import { SemanticClass } from './SemanticClass';
import { useBiLogger } from '../../../../biLoggerContext';
import { useAPIs, useCssClasses } from '../../../../createCssClasses';
import s from './semanticClasses.scss';
import styles from '../panelContent.scss';

type SemanticClassesSectionProps = {
  compRef: CompRef;
};

export const SemanticClassesSection: React.FC<SemanticClassesSectionProps> = ({
  compRef,
}) => {
  const { internalAPI } = useAPIs();
  const [t] = useTranslation();
  const biLoggerAPI = useBiLogger();
  const { elementTypeName, componentType, semanticClasses } =
    useCssClasses(compRef);

  const classContent = t(
    'WixCode_CodePanel_Editor_GlobalCss_DefaultText_ButtonClassExample',
  );

  const onSelectClassHandler = (
    className: string,
    semanticClassName: string,
  ) => {
    internalAPI.generateCssClassCode(className, classContent);
    biLoggerAPI.report(
      propertiesPanelActionClick({
        cta: semanticClassName,
        action_type: 'semantic class',
        component_id: compRef.id,
        element_type: elementTypeName,
        component_type: componentType,
      }),
    );
  };

  return semanticClasses.length > 0 ? (
    <>
      <div className={s.semanticClassNamesContainer} data-aid="class-binding">
        <span className={s.sectionLabelContainer}>
          <TextLabel
            className={s.sectionLabel}
            type="T04"
            value={t('WixCode_CssClassesPanel_ComponentClasses_Title', {
              element_name: elementTypeName,
            })}
          />
          <InfoIcon
            text={t('WixCode_CssClassesPanel_ComponentClasses_Tooltip', {
              element_name: elementTypeName,
            })}
          />
        </span>
        {semanticClasses?.map((semanticClassName) => (
          <SemanticClass
            key={semanticClassName}
            semanticClassName={`.${semanticClassName}`}
            onSelectClass={(className) =>
              onSelectClassHandler(className, semanticClassName)
            }
          />
        ))}
      </div>
      <Divider className={styles.divider} long />
    </>
  ) : null;
};
