import { propertiesPanelTabIds } from '@wix/wix-code-plugin-contracts';
import { TabsState } from '.';
import { TabsActions } from './actions';
import { ActionType } from './ActionTypes';

const initialState: TabsState = {
  selectedTab: propertiesPanelTabIds.propertiesAndEvents,
  contributedTabs: [],
};

export const tabsReducer = (
  state: TabsState = initialState,
  action: TabsActions,
) => {
  switch (action.type) {
    case ActionType.SELECT_TAB:
      return {
        ...state,
        selectedTab: action.selectedTab,
      };
    case ActionType.CONTRIBUTE_TAB:
      return {
        ...state,
        contributedTabs: [...state.contributedTabs, action.contributedTab],
      };
    default:
      return state;
  }
};
