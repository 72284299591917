export const dataHooks = {
  COLLECTIONS_TAB_ICON: 'collections-tab-icon',
  DB_TAB_ICON: 'databases-tab-icon',
  EMPTY_STATE: 'corvid-site-structure__empty-state',
  LOADER: 'corvid-site-structure__loader',
  SECTION_DIVIDER: 'corvid-site-structure__section-divider',
  TREE_ROOT: 'corvid-site-structure__tree-root',
  TREE_NODE: 'corvid-site-structure__tree-node',
  FILE_CONTEXT_DISABLED_TOOLTIP: 'file-context-disabled-tooltip',
  TAB_HEADER: 'tab-header',
  LEFT_PANE_ROOT: 'corvid-site-structure__root',
  EMPTY_STATE_BUTTON: 'cta-button',
  SCHEMA_FIELD: 'schema-field',
  SCHEMA_FIELD_NAME: 'schema-field-name',
  SCHEMA_FIELD_TYPE: 'schema-field-type',
  EXTERNAL_COLLECTIONS_SECTION: 'external-collections-section',
  EXTERNAL_DATABASES_TREE_ROOT: 'external-databases-tree-root',
  MY_COLLECTIONS_TREE_ROOT: 'my-collections-tree-root',
  WIX_APP_COLLECTIONS_TREE_ROOT: 'wix-app-collections-tree-root',
  CONTEXT_MENU_ICON: 'context-menu-icon',
  NEW_COLLECTION_AUTOMATION_ID: 'action-new-collection',
  EXTERNAL_COLLECTION_AUTOMATION_ID: 'action-add-external-collection',
  CONTEXT_MENU_CUSTOM_BUTTON: 'context-menu-custom-button',
  CONTEXT_MENU_TREE: 'context-menu-tree',
  CONTEXT_MENU_MY_COLLECTION_DIVIDER: 'context-menu-my-collections-divider',
  CONTEXT_MENU_EXTERNAL_COLLECTION_DIVIDER:
    'context-menu-external-collections-divider',
  COPY_BUTTON: 'copy-button',
  TAB_CONTENT: 'tab-content',
  FORMS_BANNER: 'forms-banner',
  FORMS_BANNER_CTA: 'forms-banner-cta',
  COLLECTIONS_TREE: 'collections-tree',
  FORMS_POPVER: 'forms-popover',
  FORMS_POPOVER_CTA: 'forms-popover-cta',
  PENDING_DB: 'pending-db',
  READY_CLOUD_DB: 'ready-cloud-db',
};

export const byHook = (str: string) => `[data-hook=${str}]`;
