import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { wixCodeReduxContext } from '@wix/wix-code-common-components';
import { LeftPaneContext } from '../../../LeftPaneContext';
import { TreeSectionDivider, InfoIcon, Preloader } from '@wix/wix-base-ui';
import dataHooks from '../../../dataHooks';
import {
  treeSectionActions,
  TreeSectionSetExpandedParams,
} from '../treeSectionReducer';
import bi from '@/legacy/bi/bi';

interface SingleFileDividerProps {
  alwaysShowSuffix: boolean;
  id: string;
  expanded: boolean;
  loaded: boolean;
  path: string;
  translations?: { [key: string]: string };
}
interface SingleFileDividerReduxDispatchProps {
  expand: (sectionName: TreeSectionSetExpandedParams) => void;
  collapse: (sectionName: TreeSectionSetExpandedParams) => void;
}

type SingleFileDividerCompProps = SingleFileDividerProps &
  SingleFileDividerReduxDispatchProps;

const SingleFileDividerComp: React.FC<SingleFileDividerCompProps> = ({
  alwaysShowSuffix,
  expand,
  collapse,
  id,
  expanded,
  loaded,
  path,
  translations,
}) => {
  const { translate, getEditorAPI } = React.useContext(LeftPaneContext);
  const toggle = () =>
    expanded ? collapse({ sectionName: path }) : expand({ sectionName: path });

  const tooltipContent =
    id === 'ConfigFile'
      ? translations?.CONFIG_TOOLTIP_CONTENT ||
        translate('Left_Tree_ConfigFile_Section_Info_Text')
      : id === 'ReadmeFile'
      ? translations?.README_TOOLTIP_CONTENT ||
        translate('Left_Tree_ReadmeFile_Section_Info_Text')
      : translate(`Left_Tree_${id}_Section_Info_Text`);

  const linkUrl =
    id === 'ConfigFile'
      ? translations?.CONFIG_TOOLTIP_LEARN_MORE_LINK ||
        translate('Left_Tree_ConfigFile_Section_Info_Link_URL')
      : id === 'ReadmeFile'
      ? translations?.README_TOOLTIP_LEARN_MORE_LINK ||
        translate('Left_Tree_ReadmeFile_Section_Info_Link_URL')
      : translate(`Left_Tree_${id}_Section_Info_Link_URL`);

  const learnMoreText =
    id === 'ConfigFile'
      ? translations?.CONFIG_TOOLTIP_LEARN_MORE ||
        translate('Left_Tree_ConfigFile_Section_Info_Link_Text')
      : id === 'ReadmeFile'
      ? translations?.README_TOOLTIP_LEARN_MORE ||
        translate('Left_Tree_ReadmeFile_Section_Info_Link_Text')
      : translate(`Left_Tree_${id}_Section_Info_Link_Text`);

  const onLinkClick = (event: AnyFixMe) => {
    event.stopPropagation();
    const editorAPI = getEditorAPI();
    editorAPI.bi.event(bi.events.CODE_REUSE_ADD_DEPENDENCY_CLICK, {
      related_link: linkUrl,
      link_name: 'learn_more',
      link_type: 'resource',
      origin: id,
    });
    window.open(linkUrl, '_blank');
  };

  const suffix = loaded ? (
    <InfoIcon
      dataHook="tab-header-info-icon"
      text={tooltipContent}
      linkText={learnMoreText}
      onLinkClick={onLinkClick}
    />
  ) : (
    <div className="preloader-container-hack">
      <Preloader dataHook={dataHooks.LOADER} className="tiny" />
    </div>
  );

  return (
    <TreeSectionDivider
      dataHook={dataHooks.SECTION_DIVIDER}
      alwaysShowSuffix={alwaysShowSuffix}
      label={`Left_Tree_Components_Category_CodeFiles_${id}`}
      collapsed={!expanded}
      onClick={toggle}
      suffix={suffix}
      size="small"
    />
  );
};

const mapDispatchToProps = (
  dispatch: AnyFixMe,
): SingleFileDividerReduxDispatchProps =>
  bindActionCreators(
    {
      expand: treeSectionActions.expandTreeSection,
      collapse: treeSectionActions.collapseTreeSection,
    },
    dispatch,
  );

export const SingleFileDivider: React.FC<SingleFileDividerProps> = connect(
  null,
  mapDispatchToProps,
  null,
  {
    forwardRef: true,
    context: wixCodeReduxContext,
  },
)(SingleFileDividerComp);
