import { WixDataConnectorSettings } from '@wix/wix-data-connector-settings-client';
import { HttpClient } from '@wix/http-client';
import {
  createGoogleSqlDatabaseDeployment,
  deleteGoogleSqlDatabaseDeployment,
  getGoogleSqlDatabaseDeployment,
} from '@wix/ambassador-kore-provisioning-v1-googlesqldb/http';
import { DBVersion } from '@wix/ambassador-kore-provisioning-v1-googlesqldb/types';
import { listDatabaseDeployment } from '@wix/ambassador-kore-provision-v1-database-deployment/http';
import { NamespaceWithStatus } from './components/CollectionsTree/CollectionsTree';

export const getConnectorsService = (instance: () => string) => {
  const httpClient = new HttpClient({ getAppToken: instance });
  const wixDataConnectorSettingsClient = new WixDataConnectorSettings(
    httpClient,
    instance(),
  );

  return wixDataConnectorSettingsClient;
};

export const createExternalDb = async (
  namespace: string,
  dbType: DBVersion,
  instance: () => string,
) => {
  const httpClient = new HttpClient({ getAppToken: instance });
  await httpClient.request(
    createGoogleSqlDatabaseDeployment({
      namespace,
      googleSqlDeploymentParams: {
        dbVersion: dbType,
      },
    }),
  );
};

export const getCloudDbs = async (instance: () => string) => {
  const httpClient = new HttpClient({ getAppToken: instance });
  const res = await httpClient.request(listDatabaseDeployment({}));

  return res.data.databaseDeployments || [];
};

export const deleteCloudDb = async (
  db: NamespaceWithStatus,
  instance: () => string,
) => {
  const httpClient = new HttpClient({ getAppToken: instance });
  const { data } = await httpClient.request(
    getGoogleSqlDatabaseDeployment({ databaseDeploymentId: db.id! }),
  );
  await httpClient.request(
    deleteGoogleSqlDatabaseDeployment({
      databaseDeploymentId: db.id!,
      revision:
        data?.googleSqlDatabaseDeployment?.databaseDeployment?.revision!,
    }),
  );
};
