import React from 'react';
import { HelpTab } from './helpTab/HelpTab';
import HelpIcon from './icons/help.svg';
import {
  PredefinedSidebarTabs,
  SideTabContribution,
  TabProps,
} from '@wix/wix-code-plugin-contracts';
import { BlocksHelpTab } from './helpTab/BlocksHelpTab';
import { experimentUtils } from '@wix/wix-code-common';
import { Help } from '@wix/wix-ui-icons-common/classic-editor/system';

export const genDetails = (tab: string, id: PredefinedSidebarTabs) => ({
  shouldShowTab: () => true,
  id,
  dataHook: `${id}_tab`,
});

type SideTabsMap = {
  [key: string]: TabPropsToContribution;
};

type valueOf<T> = T[keyof T];
type TabPropsToContribution = (
  props?: valueOf<TabProps>,
) => SideTabContribution;

const sideTabContributionMap: SideTabsMap = {};

export const addSideTabContribution = (tab: TabPropsToContribution) => {
  sideTabContributionMap[tab().id] = tab;
};

const TabTypes = (): Readonly<SideTabsMap> => ({
  [PredefinedSidebarTabs.HELP]: () => ({
    label: 'Left_Tree_Components_Title_Help',
    infoText: 'Left_Tree_Components_Tooltip_Help',
    symbol: experimentUtils.isMoveHelpTabToBottomOfSidebar() ? (
      <Help />
    ) : (
      <HelpIcon />
    ),
    id: PredefinedSidebarTabs.HELP,
    dataHook: `${PredefinedSidebarTabs.HELP}_tab`,
    creator: () => <HelpTab />,
    shouldShowTab: () => true,
    isBottomTab: experimentUtils.isMoveHelpTabToBottomOfSidebar(),
  }),
  [PredefinedSidebarTabs.BLOCKS_HELP]: (
    tabProps?: TabProps[PredefinedSidebarTabs.BLOCKS_HELP],
  ) => ({
    label: 'Left_Tree_Components_Title_Blocks_Help',
    infoText: 'Left_Tree_Components_Tooltip_Help',
    symbol: <HelpIcon />,
    id: PredefinedSidebarTabs.BLOCKS_HELP,
    dataHook: `${PredefinedSidebarTabs.BLOCKS_HELP}_tab`,
    creator: () => <BlocksHelpTab tabProps={tabProps} />,
    shouldShowTab: () => true,
  }),
});
export const getSideTabsDefinitions = () => ({
  ...TabTypes(),
  ...sideTabContributionMap,
});

export const generateAppTab = (
  ideTreePackagesViewDefinition: AnyFixMe,
  isBlocksCombinedMode = false,
): SideTabContribution => {
  if (!ideTreePackagesViewDefinition || isBlocksCombinedMode) {
    return {
      label: '',
      symbol: <></>,
      id: PredefinedSidebarTabs.APP_BUILDER,
      dataHook: `${PredefinedSidebarTabs.APP_BUILDER}_tab`,
      creator: () => <></>,
      shouldShowTab: () => false,
    };
  }
  const {
    compType: AppBuilderComp,
    appBuilderProps,
    tabDetails = {},
    shouldShowTab,
  } = ideTreePackagesViewDefinition;

  const { label, symbol } = tabDetails;

  return {
    label,
    symbol,
    id: PredefinedSidebarTabs.APP_BUILDER,
    dataHook: `${PredefinedSidebarTabs.APP_BUILDER}_tab`,
    creator: () => <AppBuilderComp {...appBuilderProps} />,
    shouldShowTab,
  };
};
