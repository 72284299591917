import React from 'react';
import { Package } from '@wix/wix-ui-icons-common/classic-editor';
import { PredefinedSidebarTabs } from '@wix/wix-code-plugin-contracts';
import { PkgTreeRoot } from '../PkgTree';
import { SectionsSlot } from './contributedSections';
import { ProvidersWrapper, ProvidersWrapperAPIs } from './contexts';

const getTabText = (isBlocksCombinedMode: boolean, isVeloPhase0: boolean) => {
  if (isBlocksCombinedMode || isVeloPhase0) {
    return {
      label: 'Left_Tree_Components_Title_Apps_And_Packages',
      infoText: 'Left_Tree_Components_Title_Apps_And_Packages_Tooltip',
      infoLinkText: 'Left_Tree_Components_Title_Apps_And_Packages_Link_Text',
      infoLinkUrl: 'Left_Tree_Components_Title_Apps_And_Packages_Link_URL',
    };
  } else {
    return {
      label: 'Left_Tree_Components_Title_Packages',
      infoText: 'Left_Tree_Components_Title_Packages_Tooltip',
      infoLinkText: 'Left_Tree_Components_Title_Packages_Link_Text',
      infoLinkUrl: 'Left_Tree_Components_Title_Packages_Link_URL',
    };
  }
};

export const createPackagesTab = (
  isBlocksCombinedMode: boolean,
  isVeloPhase0: boolean,
  sectionsSlot: SectionsSlot,
  apis: ProvidersWrapperAPIs,
) => ({
  shouldShowTab: () => true,
  id: PredefinedSidebarTabs.PACKAGES,
  dataHook: `${PredefinedSidebarTabs.PACKAGES}_tab`,
  symbol: <Package />,
  creator: () => (
    <ProvidersWrapper {...apis}>
      <PkgTreeRoot slot={sectionsSlot} />
    </ProvidersWrapper>
  ),
  ...getTabText(isBlocksCombinedMode, isVeloPhase0),
});
