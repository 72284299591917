import React from 'react';
import { i18n, I18nextProvider } from '@wix/wix-i18n-config';
import { utilsCreator as wixCodeUtilsCreator } from '@wix/wix-code-common';

import { APIKeys, connectWithShell, Shell } from '@wix/wix-code-repluggable';
import {
  BiLoggerAPI,
  DevContextAPI,
  EditorAPI,
  LegacyEditorDependencies,
  WixCodeStoreAPI,
} from '@wix/wix-code-plugin-contracts';
import {
  CssClassesPanel,
  CssClassesPanelProps,
} from './components/CssClassesPanel/CssClassesPanel';
import {
  CssEditingInternalAPI,
  CssEditingInternalAPIKey,
} from './cssEditingInternalAPI';
import type { CompRef } from '@wix/document-services-types';
import { BiLoggerContext } from './biLoggerContext';
import { mapCodeStateToProps } from './utils/mapCodeStateToProps';

interface ContextAPIs {
  internalAPI: CssEditingInternalAPI;
  biLoggerAPI: BiLoggerAPI;
  legacyDependenciesAPI: LegacyEditorDependencies;
  editorAPI: EditorAPI;
}

type ConnectCssClassesProps = {
  shell: Shell;
  i18nInstance: i18n;
};
type ConnectCssClassesWithWixCodeStoreProps = {
  i18nInstance: i18n;
  wixCodeStoreAPI: WixCodeStoreAPI;
  internalAPI: CssEditingInternalAPI;
  legacyDependenciesAPI: LegacyEditorDependencies;
};
type ConnectCssClassesWithShellProps = {
  editorAPI: EditorAPI;
  devContextAPI: DevContextAPI;
  legacyDependencies: LegacyEditorDependencies;
};

type CssClassesProps = {
  compRef: CompRef;
};

type APIs = {
  editorAPI: EditorAPI;
  internalAPI: CssEditingInternalAPI;
  legacyDependenciesAPI: LegacyEditorDependencies;
};

type CssClasses = {
  semanticClasses: string[];
  cssReference: string | undefined;
  elementTypeName: string;
  componentType: string;
};

export const APIsContext = React.createContext<APIs>(null as any);

export const useAPIs = () => {
  return React.useContext(APIsContext);
};

export const CssClassesContext = React.createContext<CssClasses>(null as any);

export const useCssClasses = (compRef: CompRef) => {
  const getComponentType = (uiType: string) => {
    const parts = uiType.split('.');
    return parts.length > 0 ? parts[parts.length - 1] : '';
  };

  const { editorAPI, legacyDependenciesAPI, internalAPI } = useAPIs();
  const { platform, util } = legacyDependenciesAPI;
  const wixCodeUtils = wixCodeUtilsCreator({ platform, util });

  const sdkType = compRef
    ? wixCodeUtils.getSdkTypeOrControllerType(editorAPI, compRef)
    : null;

  const elementTypeName = sdkType?.replace('$w.', '');
  const uiType = compRef ? editorAPI.components.getType(compRef) : '';
  const componentType = compRef ? getComponentType(uiType) : '';
  const semanticClasses = internalAPI.getComponentsSemanticClasses(
    sdkType,
    componentType,
  );
  const cssReference = internalAPI.getComponentCssReference(sdkType);
  return {
    cssReference,
    componentType,
    semanticClasses,
    elementTypeName,
  };
};

const mapEditorStateToProps =
  ({ legacyDependencies }: { legacyDependencies: LegacyEditorDependencies }) =>
  ({ editorAPI }: { editorAPI: EditorAPI }): CssClassesProps => {
    const { util, stateManagement } = legacyDependencies;
    const { getSingleSelectedComponent } =
      stateManagement.editorPlugins.selection.selectors;
    const editorState = editorAPI.store.getState();
    const selectedComponent = getSingleSelectedComponent(editorState);
    const compRef: CompRef = util.controlsUtils.getFirstControllableComponent(
      editorAPI,
      selectedComponent,
    );
    return {
      compRef,
    };
  };

const ConnectCssClassesWithWixCodeStore = ({
  legacyDependenciesAPI,
  wixCodeStoreAPI: {
    connectToStores,
    selectors: {
      fileSystem: { getFile },
    },
  },
}: ConnectCssClassesWithWixCodeStoreProps) => {
  const { util } = legacyDependenciesAPI;
  let ConnectedComponent = null as any;
  return (origProps: any) => {
    if (ConnectedComponent === null) {
      ConnectedComponent = connectToStores<CssClassesPanelProps>({
        util,
        mapEditorStateToProps: mapEditorStateToProps({
          legacyDependencies: legacyDependenciesAPI,
        }),
        mapCodeStateToProps: mapCodeStateToProps(getFile),
        comp: CssClassesPanel,
      });
    }
    return <ConnectedComponent {...origProps} />;
  };
};

const ConnectCssClassesWithShell = ({ shell }: { shell: Shell }) =>
  connectWithShell<ConnectCssClassesWithShellProps>(
    (_boundShell: Shell) => {
      return {
        legacyDependencies: _boundShell.getAPI(
          APIKeys.LegacyEditorDependencies,
        ),
        editorAPI: _boundShell.getAPI(APIKeys.ClassicEditorAPI).editorAPI,
        devContextAPI: _boundShell.getAPI(APIKeys.DevContextAPI),
      };
    },
    (_boundShell: Shell) => {
      const cssAPI = _boundShell.getAPI(CssEditingInternalAPIKey);
      return {
        createCssFileAndOpen: cssAPI.createCssFileAndOpen,
      };
    },
    shell,
    {
      renderOutsideProvider: true,
      allowOutOfEntryPoint: true,
    },
  );

const ConnectCssClasses = ({ shell, i18nInstance }: ConnectCssClassesProps) => {
  const internalAPI = shell.getAPI(CssEditingInternalAPIKey);
  const wixCodeStoreAPI = shell.getAPI(APIKeys.WixCodeStoreAPI);
  const legacyDependenciesAPI = shell.getAPI(APIKeys.LegacyEditorDependencies);
  const ConnectedCssClassesWithShellComp = ConnectCssClassesWithShell({
    shell,
  });
  const ConnectedCssClassesWithWixCodeStore = ConnectCssClassesWithWixCodeStore(
    { legacyDependenciesAPI, wixCodeStoreAPI, internalAPI, i18nInstance },
  );
  return ConnectedCssClassesWithShellComp(ConnectedCssClassesWithWixCodeStore);
};

export const createCssClasses =
  (
    shell: Shell,
    i18nInstance: i18n,
    { internalAPI, biLoggerAPI, legacyDependenciesAPI, editorAPI }: ContextAPIs,
  ) =>
  () => {
    const ConnectedCssClasses = ConnectCssClasses({
      shell,
      i18nInstance,
    });

    return (
      <APIsContext.Provider
        value={{ legacyDependenciesAPI, editorAPI, internalAPI }}
      >
        <I18nextProvider i18n={i18nInstance}>
          <BiLoggerContext.Provider value={biLoggerAPI}>
            <ConnectedCssClasses />
          </BiLoggerContext.Provider>
        </I18nextProvider>
      </APIsContext.Provider>
    );
  };
