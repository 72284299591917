import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { createHost, EntryPoint, Shell } from '@wix/wix-code-repluggable';
import initLegacy from '../initLegacy';
import { createWixCodeEditorAdapterEntryPoint } from './wixCodeEditorAdapterEntryPoint';
import { createExternalHostContributionEntryPoint } from '../externalHostContribution/externalHostContributionEntryPoint';
import {
  ExternalCollectionsAPIKey,
  ExternalFilesTreeAPIKey,
  ExternalPackagesAndAppsAPIKey,
  ExternalGithubAPIKey,
  ExternalLocalEditorAPIKey,
} from '@wix/wix-code-external-apis';
import { LitePanelEntryPoint } from '@wix/wix-code-entry-point';

export const entryPoint: EntryPoint = {
  name: 'wix-code editor entry point',
  getDependencyAPIs() {
    return [WixCodeEditorAdapterAPI];
  },
  declareAPIs() {
    return [
      ExternalFilesTreeAPIKey,
      ExternalCollectionsAPIKey,
      ExternalPackagesAndAppsAPIKey,
      ExternalGithubAPIKey,
      ExternalLocalEditorAPIKey,
    ];
  },
  extend(shellInHostingEditor: Shell) {
    const wixCodeEditorAdapterAPI = shellInHostingEditor.getAPI(
      WixCodeEditorAdapterAPI,
    );

    const wixCodeEditorAdapterEntryPointForWixCode =
      createWixCodeEditorAdapterEntryPoint({
        shellInHostingEditor,
        wixCodeEditorAdapterAPI,
      });

    const externalHostContributionEntryPoint =
      createExternalHostContributionEntryPoint(shellInHostingEditor);

    const wixCodeRepluggableHost = createHost([
      wixCodeEditorAdapterEntryPointForWixCode,
      externalHostContributionEntryPoint,
    ]);

    const { components } = initLegacy(
      wixCodeEditorAdapterAPI.legacyDependencies,
      wixCodeRepluggableHost,
    );

    wixCodeEditorAdapterAPI.contributeLoader(
      shellInHostingEditor,
      components.wixCodeLoader,
    );

    wixCodeRepluggableHost.addShells([LitePanelEntryPoint]);
  },
};
