import * as React from 'react';
import { CodeReusePkgTreeContext } from '../CodeReusePkgTreeContext';
import { InstalledCodeReusePkg } from '@/toExtract/packages/packagesModalContext';
import { UpdatePimple } from './UpdatePimple';
import { WasAutoUpdatedPimple } from './WasAutoUpdatedPimple';

interface CodeReuseSuffixPimpleProps {
  isRoot: boolean;
  pkgName: string;
  canUpdate: boolean;
}

export const CodeReuseSuffixPimple: React.FC<CodeReuseSuffixPimpleProps> = ({
  pkgName,
  isRoot,
  canUpdate,
}) => {
  const { packagesService } = React.useContext(CodeReusePkgTreeContext);

  const installed = packagesService.getInstalledPkg(pkgName);

  const fromAutoUpdate = (installed as InstalledCodeReusePkg)?.fromAutoUpdate;

  return !isRoot ? null : canUpdate ? (
    <UpdatePimple pkgName={pkgName} />
  ) : fromAutoUpdate ? (
    <WasAutoUpdatedPimple />
  ) : null;
};
