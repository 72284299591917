import { Shell, SlotKey } from 'repluggable';
import type { ComponentType, ReactNode, ReactElement } from 'react';
import {
  LegacyEditorDependencies,
  EditorAPI,
} from '@wix/wix-code-plugin-contracts';
import type { PageRef } from '@wix/document-services-types';

export type EditorFeature =
  | 'DEV_MODE'
  | 'PUBLISH'
  | 'SITE_HISTORY'
  | 'DUPLICATE'
  | 'CREATE_TEST_SITE'
  | 'FEEDBACK'
  | 'RELEASE_MANAGER'
  | 'VIEW_CODE_PROPERTIES'
  | 'VIEW_HIDDEN_ELEMENTS'
  | 'SAVE_INDICATION'
  | 'PREVIEW';

export type EditorFeatureDisabler = {
  name: EditorFeature;
  condition: () => { disabled: boolean };
};
export type ConditionalContributionFunction = (
  originalCondition: () => boolean,
) => boolean;
export type TopBarSection = 'LOGO' | 'Publish';
export type TopBarType = 'main' | 'secondary' | 'preview';
export type TourKeyName = 'LOCAL_EDITOR_INTRO_TOUR' | (string & {});

export type TourContribution = {
  tour: Tour;
};
export type Tour = { tourKeyName: TourKeyName; actions: TourAction[] };

export type TourAction = {
  title: string;
  content: string;
  mediaSrc?: string;
  primaryButton: {
    label: string;
    onClick?: Function;
  };
  secondaryButton?: {
    label: string;
    onClick?: Function;
    goBack?: boolean;
  };
  learnMore?: {
    label: string;
    articleSrc: string;
  };
  selectorToAttach: string;
  onEnter?: () => Promise<void>;
  onExit?: () => Promise<void>;
};

export type ModalRenderer = (closeModal: () => void) => ReactNode;

export type ShowModalFunction = (
  modalRenderer: ModalRenderer,
  options: {
    backdrop: boolean;
    closeOnClickOutside: boolean;
    onClose?: () => void;
  },
) => void;

export type TourOpener = (options?: {
  showOnlyOnce?: boolean;
}) => void | Promise<void>;

type shouldPublish = boolean;
export type BeforePublishContribution = () => Promise<shouldPublish>;
export type BIParams = { [key: string]: any };
export type EditorContext = {
  msid: string;
};

export type EditorReadOnlyComponent = (props: {
  className?: string;
}) => ReactElement;

export interface WixCodeEditorAdapterAPI {
  legacyDependencies: LegacyEditorDependencies;
  editorAPI: EditorAPI;

  getEditorContext: () => EditorContext;
  contributeCodeEditor(
    shell: Shell,
    component: ComponentType<any>,
    condition: ConditionalContributionFunction,
  ): void;

  contributeConsole(
    shell: Shell,
    component: ComponentType<any>,
    condition: ConditionalContributionFunction,
  ): void;

  contributeFileTree(
    shell: Shell,
    component: ComponentType<any>,
    condition: ConditionalContributionFunction,
  ): void;

  contributeLoader(shell: Shell, component: ComponentType<any>): void;

  temporaryConditionalContributions: {
    contributeConcurrentReadOnlyPanel: (
      condition: ConditionalContributionFunction,
      getVSCodeOnlineTabOpener: () => () => void,
      contributeExternalComponent?: (
        baseReadOnlyComponent: EditorReadOnlyComponent,
      ) => void,
    ) => void;
  };
  modalAPI: {
    showModal: ShowModalFunction;
  };

  showSnackbar: (message: string) => void;

  notifyChange(): void;

  contributeChangesSyncIndication(
    shell: Shell,
    component: ComponentType<any>,
  ): void;

  addDemoAvatarsIfNeeded(): void;

  getDemoAvatarsSelector(): string;

  removeDemoAvatars(): void;

  contributeNicknamesEditableCondition: (
    condition: ConditionalContributionFunction,
  ) => void;

  disableEditorFeatures(
    editorFeatures: EditorFeature[],
    options?: { tooltip?: string },
  ): void;

  contributeTopBarItem(
    item: ComponentType<any>,
    section: TopBarSection,
    topBarType: TopBarType[],
    condition: () => boolean,
  ): void;

  contributeEditorFeaturesDisablers(
    editorFeaturesDisablers: EditorFeatureDisabler[],
  ): void;

  contributeTour(tourContribution: TourContribution): TourOpener;

  notifyWixCodeLiveEditorLoaded: () => void;

  isAnyTourRunning: () => boolean;

  contributePageCodeDuplicateCondition: (
    shell: Shell,
    condition: ConditionalContributionFunction,
  ) => void;

  contributeBiParams: (shell: Shell, params: BIParams) => void;

  contributeBeforePublish: (
    shell: Shell,
    contribution: BeforePublishContribution,
  ) => void;

  contributeLitePanel: (shell: Shell, component: ComponentType<any>) => void;
  isReplacerPage: (pageRef: PageRef) => boolean;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WixCodeEditorAdapterAPI: SlotKey<WixCodeEditorAdapterAPI> = {
  name: 'wix-code editor adapter',
  layer: 'EXTERNAL',
  public: true,
};
