import React from 'react';
import bi from '@/legacy/bi/bi';
import { TopBar, TopBarProps } from '../topBar/TopBar';

import { useEditorLegacyAPIs } from '@wix/wix-code-common-components';

type WixCodeTopBarProps = Omit<
  TopBarProps,
  'onOpenHelp' | 'onToolbarAction' | 'onToggleSize' | 'automationId'
>;

export const WixCodeTopBar: React.FC<WixCodeTopBarProps> = (props) => {
  const { editorAPI } = useEditorLegacyAPIs();
  const onOpenHelp = () => {
    editorAPI.panelManager.openHelpCenter(
      '8afcfaee-a357-425b-87f0-07c1a404b84d',
      {},
      {
        origin: 'wixCodeHelpTooltip',
        panel_name: 'wixCodeHelpTooltip',
      },
    );
  };
  const onToolbarAction = (action: AnyFixMe) => {
    editorAPI.bi.event(bi.events.IDE_TOOLBAR_BUTTON_CLICK, {
      item_name: action,
    });
  };
  const onToggleSize = (toggleState: AnyFixMe) => {
    editorAPI.bi.event(bi.events.IDE_TOOLBAR_TOGGLE, {
      toggle_name: toggleState,
    });
  };

  return (
    <TopBar
      onToolbarAction={onToolbarAction}
      onToggleSize={onToggleSize}
      onOpenHelp={onOpenHelp}
      automationId="wix-code-topbar"
      {...props}
    />
  );
};
