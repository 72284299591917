import React from 'react';
import { TextButton, TextLabel, Divider } from '@wix/wix-base-ui';
import { ideLinkToElementsApiReferenceClicked } from '@wix/bi-logger-platform/v2';
import '../pageElementsPanel.global.scss';
import { TFunction } from '@wix/wix-i18n-config';
import { ExternalLinkSmall } from 'wix-ui-icons-common';
import s from '../PageElementsPanel.scss';
import { useBiLogger } from '@wix/wix-code-common-components';

interface ElementApiReferenceSecionProps {
  t: TFunction;
  elementTypeName: string;
  apiReferenceUrl: string;
  selectedFileIdForBI: string;
}

const ElementApiReferenceSection: React.FC<ElementApiReferenceSecionProps> = ({
  t,
  elementTypeName,
  apiReferenceUrl,
  selectedFileIdForBI,
}) => {
  const biLogger = useBiLogger();

  return (
    <div className={s.apiReferenceSection}>
      <div className={s.section} data-aid="api-reference-container">
        <TextLabel
          enableEllipsis={false}
          shouldTranslate={false}
          className={s.sectionLabel}
          type="T04"
          value={t('WixCode_PropertiesAndEventsPanel_ApiReference_Title') + ' '}
        />
        <span className={s.linkContainer}>
          <TextButton
            dataHook="api-reference-link"
            size="tiny"
            weight="normal"
            onClick={() => {
              window.open(apiReferenceUrl, '_blank');
              biLogger.report(
                ideLinkToElementsApiReferenceClicked({
                  api_name: elementTypeName,
                  path: selectedFileIdForBI,
                  refrence_url: apiReferenceUrl,
                }),
              );
            }}
          >
            {t('WixCode_PropertiesAndEventsPanel_ApiReference_Element', {
              element_name: elementTypeName,
            })}
          </TextButton>
          <ExternalLinkSmall className={s.link} />
        </span>
      </div>
      <Divider className="divider" long />
    </div>
  );
};

export default ElementApiReferenceSection;
