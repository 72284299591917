import {
  ReadOnlyMode,
  ReadOnlyContributor,
} from '@wix/wix-code-plugin-contracts';

export type ReadOnlyContributions = {
  [contributor in ReadOnlyContributor]?: Partial<ReadOnlyMode>;
};

export interface ReadOnlyState {
  readOnlyContributions: ReadOnlyContributions;
}

export interface WixCodeReadOnlyState {
  readOnlyState: ReadOnlyState;
}

export const initialState: ReadOnlyState = {
  readOnlyContributions: {},
};

export * from './actions';
export * from './reducers';
export * from './selectors';
