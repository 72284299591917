import React, { Fragment, useContext } from 'react';
import _ from 'lodash';
import { TreeSectionDivider } from '@wix/wix-base-ui';
import { monitoring } from '@wix/wix-code-common';
import { leftTreeClickOnASection } from '@wix/bi-logger-platform/v2';
import { DynamicPagesGroup } from './DynamicPagesGroup';
import { PagesTabContext } from './PagesTabContext';
import dataHooks from '../dataHooks';
import { PageTreeContext } from './PageTreeContext';
import { DynamicPageItemType } from './types';

interface DynamicPagesTreeProps {
  data: DynamicPageItemType[];
  label: string;
}

const { withErrorHandling } = monitoring;

export const DynamicPagesTree = ({ data, label }: DynamicPagesTreeProps) => {
  const { isCollapsed, setCollapsed } = useContext(PageTreeContext);
  const { bi } = useContext(PagesTabContext);

  const getId = () => 'page___DYNAMIC_PAGES__';
  const [collapsed, setCollapsedState] = React.useState(isCollapsed(getId()));

  const onNodeClick = () =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickOnASection({
          section_name: 'dynamic_pages',
          action: collapsed ? 'open' : 'close',
        }),
      );
      setCollapsed(getId(), !collapsed);
      setCollapsedState(!collapsed);
    })();

  return (
    <Fragment>
      <div data-aid="dynamic-pages-section">
        <TreeSectionDivider
          dataHook={dataHooks.SECTION_DIVIDER}
          label={label}
          collapsed={collapsed}
          hideBottomBorder={collapsed}
          onClick={onNodeClick}
          size="small"
        />
      </div>
      {!collapsed && (
        <ul
          data-aid="dynamic-pages-items"
          key="subtree"
          className="section-container"
        >
          {data.map((child: DynamicPageItemType) => (
            <li key={child.id}>
              <DynamicPagesGroup data={child} />
            </li>
          ))}
        </ul>
      )}
    </Fragment>
  );
};
