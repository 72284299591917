import React, { useContext } from 'react';
import cx from 'classnames';
import s from './PkgControlsContextMenu.scss';
import { ContextMenu, ContextMenuAction } from '@wix/wix-base-ui';
import {
  NpmPackageInfo,
  PackagesModalContext,
} from '../../../../packagesModalContext';
import {
  getUpgradableVersion,
  isInstallationInProgress,
  isInstalledReusePackageByOthers,
  isReusePackage,
} from '../../../../utils';
import { packageStatus } from '../npmPackagesList/consts';
import dataHooks from '../../../../dataHooks';
import { MODAL_TABS } from '../../../packagesModalConsts';
import { codeReusePkgsSelectors } from '../../../../packagesView/packagesViewSelectors';
import EditIcon from '@/assets/icons/contextMenu/edit.svg';
import PackageIcon from '@/assets/icons/contextMenu/package.svg';
import DeleteIcon from '@/assets/icons/contextMenu/delete.svg';
import GoToIcon from '@/assets/icons/contextMenu/goTo.svg';
import UploadIcon from '@/assets/icons/contextMenu/upload.svg';
import VersionsIcon from '@/assets/icons/contextMenu/versions.svg';
import {
  ContextMenuItem,
  useDispatch,
  useSelector,
} from '@wix/wix-code-common-components';
import {
  CODE_REUSE_BI_ITEM_TYPE,
  NPM_BI_ITEM_TYPE,
  PackagesBiSender,
} from '../../../../packagesBiSender';
import { clickSeeAllVersions } from '../../../packagesModalActions';
import { PackageItemContext } from '../PackageItemContext';
import { experimentUtils } from '@wix/wix-code-common';

interface PkgControlsContextMenuProps {
  onToggle: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
}

interface PkgContextMenuAction {
  name: string;
  title: string;
  onClick: React.MouseEventHandler;
  symbol: React.FunctionComponent;
  disabled?: boolean;
}

export const PkgControlsContextMenu: React.FC<PkgControlsContextMenuProps> = ({
  onToggle,
  show,
}) => {
  const { packagesService, t, sendBi, currentTab, selectTab, availablePkgs } =
    useContext(PackagesModalContext);
  const dispatch = useDispatch();
  const myPackages = useSelector(codeReusePkgsSelectors.available.myPackages);

  const { availablePkg, pkgName, installedPkg, isCodeReusePackage } =
    useContext(PackageItemContext);

  const isInstalledCodeReusePkg = installedPkg && isCodeReusePackage;

  const availableCodeReusePkg = isCodeReusePackage ? availablePkg : undefined;
  const installationInProgress =
    experimentUtils.isAnyNpmFirstPhase() &&
    (isInstallationInProgress() ||
      availablePkgs.codeReuse.some((pkg) => pkg.status === 'INSTALLING'));

  const packagesBiSender = PackagesBiSender(sendBi);
  const updatePkgWithBi = () => {
    packagesBiSender.sendQuickActionUpdatePkgBi(availablePkg);
    packagesService.updatePkg(pkgName);
  };

  const editCodePkgWithBi = (pkgId: AnyFixMe) => {
    packagesBiSender.sendQuickActionEditCodePkgBi(availablePkg);
    packagesService.editCodePkg(pkgId);
  };

  const uninstallPkgWithBi = (itemType: AnyFixMe) => {
    packagesBiSender.sendQuickActionUninstallPkgBi(availablePkg, itemType);
    packagesService.uninstallPkg(pkgName, true);
  };

  const readMoreWithBi = () => {
    const documentLink = `https://www.npmjs.com/package/${encodeURIComponent(
      pkgName,
    )}`;
    packagesBiSender.sendQuickActionReadMoreBi(availablePkg);
    window.open(documentLink, '_blank');
  };

  const openRequestLatestVersionWithBi = () => {
    packagesBiSender.sendQuickActionRequestLatestVersionBi(availablePkg);
    openRequestLatestVersion();
  };

  const otherPackages = useSelector(codeReusePkgsSelectors.installed.byOthers);
  const isBuiltByOthers = otherPackages.find(
    (builtByOthersPkg) => pkgName === builtByOthersPkg.name,
  );

  const installedCodeReusePackagesContextMenuActions =
    (): PkgContextMenuAction[] => [
      ...editPackageMenuAction(),
      ...updatePackageMenuAction(),
      ...seeAllVersionsAction(),
      {
        name: 'uninstall',
        title: t('Package_Manager_Installed_Menu_Uninstall'),
        onClick: () => uninstallPkgWithBi(CODE_REUSE_BI_ITEM_TYPE),
        symbol: DeleteIcon,
        disabled: installationInProgress,
      },
    ];

  const seeAllVersionsAction = (): PkgContextMenuAction[] => {
    return !isBuiltByOthers
      ? [
          {
            name: 'versions',
            title: t('Package_Manager_Installed_Menu_AllVersions'),
            onClick: () => dispatch(clickSeeAllVersions()),
            symbol: VersionsIcon,
          },
        ]
      : [];
  };

  const editPackageMenuAction = () => {
    const myPkg = myPackages.find((myPackage) => myPackage.name === pkgName);
    return myPkg
      ? [
          {
            name: 'edit',
            title: t('Packages_Modal_Context_Menu_Edit_Package'),
            onClick: () => editCodePkgWithBi(myPkg.id),
            symbol: EditIcon,
          },
        ]
      : [];
  };

  const updatePackageMenuAction = () => {
    const upgradableVersion = getUpgradableVersion(availableCodeReusePkg);

    return upgradableVersion && !isInstalledReusePackageByOthers(pkgName)
      ? [
          {
            name: 'upgrade',
            title: t('Package_Manager_Context_Menu_Update_To_Version', {
              versionNumber: upgradableVersion,
            }),
            onClick: updatePkgWithBi,
            symbol: UploadIcon,
            disabled: installationInProgress,
          },
        ]
      : [];
  };

  const availableCodeReusePackagesContextMenuActions =
    (): PkgContextMenuAction[] => [
      ...editPackageMenuAction(),
      ...updatePackageMenuAction(),
      ...seeAllVersionsAction(),
    ];

  const canRequestModule = () => {
    const {
      _version: { status },
    } = availablePkg as NpmPackageInfo;
    return status === packageStatus.NOT_AVAILABLE;
  };

  const openRequestLatestVersion = () => {
    if (currentTab !== MODAL_TABS.NPM_PACKAGES) {
      selectTab(MODAL_TABS.NPM_PACKAGES);
    }
    const { name, requestLatestVersion } = availablePkg as NpmPackageInfo;
    packagesService.openSubmitRequest({
      name,
      version: requestLatestVersion,
      versions: packagesService.getVersionsForRequestPackage(
        availablePkg as NpmPackageInfo,
      ),
      disableVersionInput: true,
    });
  };

  const npmPackagesContextMenuActions = (): PkgContextMenuAction[] => {
    const npmPackage = availablePkg as NpmPackageInfo;
    const { requestLatestVersion, installedVersion } = npmPackage;
    const isInstalled = !!installedVersion;

    const actions: PkgContextMenuAction[] = [
      {
        name: 'read-more',
        title: t('Package_Manager_Popup_Context_Menu_Documentation_Link'),
        onClick: () => readMoreWithBi(),
        symbol: GoToIcon,
      },
    ];
    if (experimentUtils.isAnyNpmNewExperience()) {
      actions.push({
        name: 'versions',
        title: t('Package_Manager_Installed_Menu_ChooseVersion'),
        onClick: () => {
          packagesBiSender.sendQuickActionChooseVersionPkgBi(availablePkg);
          return dispatch(clickSeeAllVersions());
        },
        symbol: VersionsIcon,
      });
    }
    if (requestLatestVersion) {
      actions.push({
        name: 'request-latest-version',
        title: t('Package_Manager_Popup_Context_Menu_Request_Latest'),
        onClick: openRequestLatestVersionWithBi,
        symbol: PackageIcon,
      });
    }
    if (isInstalled) {
      actions.push({
        name: 'uninstall',
        title: t(
          'Package_Manager_Popup_Installed_Tab_Context_Menu_Uninstall_Link',
        ),
        onClick: () => uninstallPkgWithBi(NPM_BI_ITEM_TYPE),
        symbol: DeleteIcon,
        disabled: installationInProgress,
      });
    }
    if (canRequestModule() && !experimentUtils.isNpmPkgManagerImprovements()) {
      actions.push({
        name: 'request-package',
        title: t('Package_Manager_Popup_Available_Tab_Request_Button'),
        onClick: () => packagesService.requestPackageWithBi(npmPackage),
        symbol: PackageIcon,
      });
    }

    return actions;
  };

  const getContextMenuActions = (): PkgContextMenuAction[] => {
    if (isReusePackage(pkgName)) {
      if (isInstalledCodeReusePkg) {
        return installedCodeReusePackagesContextMenuActions();
      } else {
        return availableCodeReusePackagesContextMenuActions();
      }
    } else {
      return npmPackagesContextMenuActions();
    }
  };

  const contextMenuActions = getContextMenuActions();
  return (
    <ContextMenu
      className={cx('flat', s.contextMenu, {
        [s.invisible]: !show || contextMenuActions.length === 0,
      })}
      dataHook={dataHooks.CONTEXT_MENU_ICON}
      direction="BOTTOM"
      alignment="RIGHT"
      onToggle={onToggle}
      closeOnClick={true}
    >
      {contextMenuActions.map(({ name, title, onClick, symbol, disabled }) => (
        <ContextMenuAction key={name} onClick={onClick} disabled={disabled}>
          <ContextMenuItem icon={symbol} label={title} />
        </ContextMenuAction>
      ))}
    </ContextMenu>
  );
};
