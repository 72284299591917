import { componentsDataServiceCreator } from '@wix/wix-code-editor-types';
import {
  EditorAPI,
  EditorComponentsAPI,
  LegacyEditorDependencies,
} from '@wix/wix-code-plugin-contracts';
import { formatEventName } from './utils';

interface EditorComponentCreator {
  editorAPI: EditorAPI;
  legacyDependencies: LegacyEditorDependencies;
}

export const createEditorComponentsAPI = async ({
  editorAPI,
  legacyDependencies,
}: EditorComponentCreator): Promise<EditorComponentsAPI> => {
  const componentsDataService = componentsDataServiceCreator();
  const { stateManagement, util, platform } = legacyDependencies;
  const { store } = editorAPI;

  const getControllerType = (compPointer: any) => {
    const compData = editorAPI.components.data.get(compPointer);
    return compData?.controllerType;
  };

  const getSdkTypeOrControllerType = (compPointer: any) => {
    const compType = editorAPI.components.getType(compPointer);
    const sdkType = editorAPI.wixCode.getCompSdkType(compPointer);
    let result = sdkType;

    if (platform.constants.CONTROLLER_COMPONENT_TYPES.includes(compType)) {
      result = getControllerType(compPointer) || sdkType;
    }

    return result;
  };

  await componentsDataService.init();

  return {
    getSelectedComponentData: () => {
      const editorState = store.getState();
      const { getSingleSelectedComponent } =
        stateManagement.editorPlugins.selection.selectors;
      const selectedComponent = getSingleSelectedComponent(editorState);

      const compRef = (util.controlsUtils as any).getFirstControllableComponent(
        editorAPI,
        selectedComponent,
      );

      const compNickname = compRef
        ? (editorAPI as EditorAPI).components.code.getNickname(compRef)
        : null;
      const sdkType = getSdkTypeOrControllerType(selectedComponent);

      if (!compNickname) {
        return null;
      }

      return {
        id: compNickname,
        events:
          componentsDataService
            .getComponentEventHandlers(sdkType)
            ?.map(({ name }) => ({
              name,
              description: formatEventName(name),
            })) || [],
      };
    },
  };
};
