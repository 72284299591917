import React from 'react';
import _ from 'lodash';
import bi from '../../../../bi/bi';
import sizingButtonsConstants, { SizingButtonAction } from './constants';
import ToolbarButton from '../toolbarButton';
import { useEditorLegacyAPIs } from '@wix/wix-code-common-components';
import { IdeContainerStateService } from '../../../services/ideContainerStateService';
import { ConsoleContainerStateService } from '../../../services/consoleContainerStateService';

type SizingButtonsProps = {
  containerStateService:
    | IdeContainerStateService
    | ConsoleContainerStateService;
  containerState: string;
  showMaximize?: boolean;
  overrideButtonClickMap?: Record<string, () => void>;
  showMinimize?: boolean;
  onAction?: () => void;
};

const SizingButtons: React.FC<SizingButtonsProps> = ({
  overrideButtonClickMap,
  containerStateService,
  onAction = _.noop,
  showMinimize = true,
  containerState,
  showMaximize = true,
}) => {
  const {
    editorAPI,
    legacyDependenciesAPI: { baseUI, constants },
  } = useEditorLegacyAPIs();

  const TOOLTIP_DATA = {
    MAXIMIZE: {
      presenter: baseUI.popoverTemplates.titleBodyAndLinkTooltip,
      id: 'wixCodeMaximizeTooltip',
      props: {
        text: 'WixCode_CodePanel_TopBar_Maximize_Tooltip',
      },
    },
    REVERT_MAXIMIZE: {
      presenter: baseUI.popoverTemplates.titleBodyAndLinkTooltip,
      id: 'wixCodeRevertMaximizeTooltip',
      props: {
        text: 'WixCode_CodePanel_TopBar_Restore_Tooltip',
      },
    },
    MINIMIZE: {
      presenter: baseUI.popoverTemplates.titleBodyAndLinkTooltip,
      id: 'wixCodeMinimizeTooltip',
      props: {
        text: 'WixCode_CodePanel_TopBar_Minimize_Tooltip',
      },
    },
    REVERT_MINIMIZE: {
      presenter: baseUI.popoverTemplates.titleBodyAndLinkTooltip,
      id: 'wixCodeRevertMinimizeTooltip',
      props: {
        text: 'WixCode_CodePanel_TopBar_Open_Tooltip',
      },
    },
  };

  const { actions } = sizingButtonsConstants;
  const containerStateButtonClicked = (actionId: SizingButtonAction) => {
    editorAPI.bi.event(bi.events.IDE_TOOLBAR_BUTTON_CLICK, {
      item_name: sizingButtonsConstants.actionToBIName[actionId],
    });

    if (overrideButtonClickMap && _.get(overrideButtonClickMap, actionId)) {
      overrideButtonClickMap[actionId]();
    }

    switch (actionId) {
      case actions.MINIMIZE:
        containerStateService.minimize();
        break;
      case actions.REVERT_MINIMIZE:
        containerStateService.revertMinimize();
        break;
      case actions.MAXIMIZE:
        (containerStateService as IdeContainerStateService).maximize();
        break;
      case actions.REVERT_MAXIMIZE:
        (containerStateService as IdeContainerStateService).revertMaximize();
        break;
      default:
        console.warn(`Unknown action in SizingButtons: ${actionId}`);
    }

    onAction();
  };

  const shouldShowToolbarButton = (actionId: string) => {
    const containerStates = constants.DEVELOPER_MODE.CONTAINER_STATES;

    switch (actionId) {
      case actions.MINIMIZE:
        return (
          showMinimize &&
          (containerState === containerStates.MAXIMIZED ||
            containerState === containerStates.CUSTOM)
        );
      case actions.REVERT_MINIMIZE:
        return showMinimize && containerState === containerStates.MINIMIZED;
      case actions.MAXIMIZE:
        return (
          showMaximize &&
          (containerState === containerStates.MINIMIZED ||
            containerState === containerStates.CUSTOM)
        );
      case actions.REVERT_MAXIMIZE:
        return showMaximize && containerState === containerStates.MAXIMIZED;
      default:
        console.warn(`Unknown container state in SizingButtons: ${actionId}`);
    }
  };

  const RevertMaximizeButton = () => {
    return (
      <ToolbarButton
        id={actions.REVERT_MAXIMIZE}
        key="toolbar-revert-maximize"
        onClick={() => containerStateButtonClicked(actions.REVERT_MAXIMIZE)}
        symbolName="revert_maximize_ide"
        tooltipData={TOOLTIP_DATA.REVERT_MAXIMIZE}
      />
    );
  };

  const MaximizeButton = () => {
    return (
      <ToolbarButton
        id={actions.MAXIMIZE}
        key="toolbar-maximize"
        onClick={() => containerStateButtonClicked(actions.MAXIMIZE)}
        symbolName="maximize_ide"
        tooltipData={TOOLTIP_DATA.MAXIMIZE}
      />
    );
  };

  const RevertMinimizeButton = () => {
    return (
      <ToolbarButton
        id={actions.REVERT_MINIMIZE}
        key="toolbar-revert-minimize"
        onClick={() => containerStateButtonClicked(actions.REVERT_MINIMIZE)}
        symbolName="revert_minimize_ide"
        tooltipData={TOOLTIP_DATA.REVERT_MINIMIZE}
      />
    );
  };

  const MinimizeButton = () => {
    return (
      <ToolbarButton
        id={actions.MINIMIZE}
        key="toolbar-minimize"
        onClick={() => containerStateButtonClicked(actions.MINIMIZE)}
        symbolName="minimize_ide"
        tooltipData={TOOLTIP_DATA.MINIMIZE}
      />
    );
  };

  return (
    <div className="wix-code-sizing-button-container">
      {shouldShowToolbarButton(actions.REVERT_MAXIMIZE) && (
        <RevertMaximizeButton />
      )}
      {shouldShowToolbarButton(actions.MAXIMIZE) && <MaximizeButton />}
      {shouldShowToolbarButton(actions.REVERT_MINIMIZE) && (
        <RevertMinimizeButton />
      )}
      {shouldShowToolbarButton(actions.MINIMIZE) && <MinimizeButton />}
    </div>
  );
};

export default SizingButtons;
