import { EditorAPI } from '@wix/wix-code-plugin-contracts';

interface PlatformPkgsInstallationService {
  installCodeReuse: (params: installCodeReuseParams) => Promise<void>;
  unInstallCodeReuse: (appData: any) => Promise<any>;
}

interface PlatformPkgsInstallationServiceInit {
  editorAPI: EditorAPI;
  stateManagement: any;
}

interface installCodeReuseParams {
  id: string;
  version: string;
}

export const platformPkgsInstallationService: (
  params: PlatformPkgsInstallationServiceInit,
) => PlatformPkgsInstallationService = ({ editorAPI, stateManagement }) => {
  const hasBeenInstalledBefore = (appData: any) =>
    appData && appData.permissions.revoked === false;

  const installCodeReuse = async ({ id, version }: installCodeReuseParams) => {
    const appData = editorAPI.platform.getAppDataByAppDefId(id);
    const shoulUpdate = hasBeenInstalledBefore(appData);

    if (shoulUpdate) {
      return editorAPI.store.dispatch(
        stateManagement.editorPlugins.platform.actions.updateApp({
          applicationId: appData.applicationId,
          appVersion: version,
        }),
      );
    } else {
      return new Promise((resolve, reject) => {
        const options = {
          appVersion: version,
          callback: resolve,
        };
        const callbacks = {
          onAppInstallationComplete: resolve,
          onError: reject,
        };
        editorAPI.store.dispatch(
          stateManagement.editorPlugins.platform.actions.installApp(
            id,
            options,
            callbacks,
          ),
        );
      });
    }
  };

  const unInstallCodeReuse = (appData: any) => {
    return new Promise((resolve) => {
      const { instanceId } = editorAPI.wixCode.getClientSpec();
      const onSuccess = resolve;
      const onFailure = (err: any) => {
        throw new Error(err);
      };

      editorAPI.store.dispatch(
        stateManagement.editorPlugins.platform.actions.uninstallApp(
          {
            applicationId: appData.applicationId,
            appDefinitionId: appData.appDefinitionId,
            instanceId,
          },
          false,
          { origin, onSuccess, onFailure },
        ),
      );
    });
  };

  return {
    installCodeReuse,
    unInstallCodeReuse,
  };
};
