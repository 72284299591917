import {
  ConditionalContributionFunction,
  WixCodeEditorAdapterAPI,
} from '@wix/wix-code-editor-adapter';
import {
  EditorAPI,
  LegacyEditorDependencies,
} from '@wix/wix-code-plugin-contracts';
import { EntryPoint, Shell } from '@wix/wix-code-repluggable';
import React from 'react';
import createSuspendedComp from './createSuspendedComp';

export default function createLegacyEditorAdapter(
  legacyEditorDeps: LegacyEditorDependencies,
  editorAPI: EditorAPI,
) {
  const [SuspendedDevConsole, contributeDevConsole] = createSuspendedComp();
  const [SuspendedIde, contributeIde] = createSuspendedComp();
  const [SuspendedSidePane, contributeSidePane] = createSuspendedComp();

  const editorAdapterApi: WixCodeEditorAdapterAPI = {
    editorAPI,
    legacyDependencies: legacyEditorDeps,
    contributeConsole: (shell: Shell, devConsole: React.ComponentType) =>
      contributeDevConsole(devConsole),
    contributeCodeEditor: (shell: Shell, ide: React.ComponentType) =>
      contributeIde(ide),
    contributeFileTree: (shell: Shell, sidePane: React.ComponentType) =>
      contributeSidePane(sidePane),
    contributeLoader: () => {},
    temporaryConditionalContributions: {
      contributeConcurrentReadOnlyPanel: () => {},
    },
    modalAPI: {
      showModal: () => {},
    },
    showSnackbar: () => {},
    notifyChange: () => {},
    contributeChangesSyncIndication: (
      shell: any,
      changesSyncIndication: React.ComponentType,
    ) => {},
    disableEditorFeatures: (editorFeatures, options) => {},
    contributeTopBarItem: (item, section, tobBarType, condition) => {},
    contributeTour: (tour) => () => {},
    addDemoAvatarsIfNeeded: () => {},
    getDemoAvatarsSelector: () => '',
    removeDemoAvatars: () => {},
    contributeNicknamesEditableCondition: (
      condition: ConditionalContributionFunction,
    ) => {},
    notifyWixCodeLiveEditorLoaded: () => {},
    isAnyTourRunning: () => false,
    contributeBiParams: () => {},
    contributePageCodeDuplicateCondition: () => {},
    contributeBeforePublish: () => {},
    contributeEditorFeaturesDisablers: () => {},
    contributeLitePanel: () => {},
    isReplacerPage: () => false,
    getEditorContext: () => ({ msid: '' }),
  };

  const LegacyEditorAdapterEntryPoint: EntryPoint = {
    name: 'Legacy Editor Adapter',
    layer: 'EXTERNAL',
    declareAPIs() {
      return [WixCodeEditorAdapterAPI];
    },
    attach(shell: Shell) {
      shell.contributeAPI(WixCodeEditorAdapterAPI, () => editorAdapterApi);
    },
  };

  const legacyApi = {
    components: {
      console: SuspendedDevConsole,
      WixCodeIDE: SuspendedIde,
      Tree: SuspendedSidePane,
    },
  };

  return {
    entryPoint: LegacyEditorAdapterEntryPoint,
    legacyApi,
  };
}
