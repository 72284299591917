import {
  ExternalCollectionsAPIKey,
  ExternalFilesTreeAPI,
  ExternalFilesTreeAPIKey,
  ExternalCollectionsAPI,
  ExternalPackagesAndAppsAPIKey,
  ExternalPackagesAndAppsAPI,
  ExternalGithubAPIKey,
  ExternalGithubAPI,
  ExternalLocalEditorAPIKey,
  ExternalLocalEditorAPI,
} from '@wix/wix-code-external-apis';

import { EntryPoint, Shell } from '@wix/wix-code-repluggable';

export const createExternalHostContributionEntryPoint = (
  shellInHostingEditor: Shell,
): EntryPoint => ({
  name: 'External Host Contribution Entry Point',
  getDependencyAPIs() {
    return [
      ExternalFilesTreeAPIKey,
      ExternalCollectionsAPIKey,
      ExternalPackagesAndAppsAPIKey,
      ExternalGithubAPIKey,
      ExternalLocalEditorAPIKey,
    ];
  },
  attach(wixCodeShell: Shell) {
    shellInHostingEditor.contributeAPI<ExternalFilesTreeAPI>(
      ExternalFilesTreeAPIKey,
      () => {
        const externalFilesTreeAPI = wixCodeShell.getAPI<ExternalFilesTreeAPI>(
          ExternalFilesTreeAPIKey,
        );
        return externalFilesTreeAPI;
      },
    );
    shellInHostingEditor.contributeAPI<ExternalCollectionsAPI>(
      ExternalCollectionsAPIKey,
      () => {
        const externalCollectionAPI = wixCodeShell.getAPI(
          ExternalCollectionsAPIKey,
        );
        return externalCollectionAPI;
      },
    );
    shellInHostingEditor.contributeAPI<ExternalPackagesAndAppsAPI>(
      ExternalPackagesAndAppsAPIKey,
      () => {
        const externalPackagesAndAppsAPI = wixCodeShell.getAPI(
          ExternalPackagesAndAppsAPIKey,
        );
        return externalPackagesAndAppsAPI;
      },
    );
    shellInHostingEditor.contributeAPI<ExternalGithubAPI>(
      ExternalGithubAPIKey,
      () => {
        const externalGithubAPI = wixCodeShell.getAPI(ExternalGithubAPIKey);
        return externalGithubAPI;
      },
    );
    shellInHostingEditor.contributeAPI<ExternalLocalEditorAPI>(
      ExternalLocalEditorAPIKey,
      () => wixCodeShell.getAPI(ExternalLocalEditorAPIKey),
    );
  },
});
