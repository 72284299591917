import React, { useContext, useState } from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { dataHooks } from '../../consts/dataHooks';
import { ParagraphEmptyState } from './ParagraphEmptyState';
import { SpiContext } from '../../SpiContext';
import { TreeSectionDivider } from '@wix/wix-base-ui';
import { SectionDividerSuffix } from '../SectionDividerSuffix/SectionDividerSuffix';
import { learnMoreEvent } from '../../utils/reportEvents';

export const EmptyStateWrapper: React.FC = () => {
  const [t] = useTranslation();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const { biLoggerAPI } = useContext(SpiContext);

  const onLinkClick = () => {
    const link = t('Left_Tree_Section_Empty_State_LinkUrl');
    biLoggerAPI.report(
      learnMoreEvent({ origin: 'left_tree', related_link: link }),
    );
    window.open(link);
  };

  return (
    <div
      onMouseEnter={() => {
        setShowContextMenu(true);
      }}
      onMouseLeave={() => {
        setShowContextMenu(false);
      }}
      data-hook={dataHooks.LEFT_TREE_SPI_SECTION_EMPTY_STATE}
    >
      <TreeSectionDivider
        dataHook={dataHooks.SECTION_DIVIDER}
        alwaysShowSuffix={showContextMenu}
        shouldTranslate={false}
        label={t('Left_Tree_Section_Label')}
        collapsed={collapsed}
        onClick={() => setCollapsed(!collapsed)}
        suffix={<SectionDividerSuffix />}
        size="small"
      />
      {!collapsed && (
        <ParagraphEmptyState
          description={t('Left_Tree_Section_Empty_State_Description')}
          linkText={t('Left_Tree_Section_Empty_State_LinkText')}
          onLinkClick={onLinkClick}
          dataHook={dataHooks.EMPTY_STATE}
        />
      )}
    </div>
  );
};
