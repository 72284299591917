import { InstalledCodeReusePkg } from '@/toExtract/packages/packagesModalContext';
import { getPkgScope } from '@/toExtract/packages/utils';

interface installedAppsByScopeInterface {
  myApps: InstalledCodeReusePkg[];
  byOthers: InstalledCodeReusePkg[];
}
const getInstalledApps = (
  installedApps: InstalledCodeReusePkg[],
  scope: string,
): installedAppsByScopeInterface => {
  const installedAppsByScope: installedAppsByScopeInterface = {
    myApps: [],
    byOthers: [],
  };
  installedApps.forEach((app) => {
    const appScope = getPkgScope(app.name);
    if (scope === appScope) {
      installedAppsByScope.myApps.push(app);
    } else {
      installedAppsByScope.byOthers.push(app);
    }
  });
  return installedAppsByScope;
};

export { getInstalledApps };
