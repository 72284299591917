// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rPgVmJ{color:#25a55a;display:flex}.dA4gkz{margin-right:9px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successIconColor": "rPgVmJ",
	"preloaderContainer": "dA4gkz"
};
export default ___CSS_LOADER_EXPORT___;
