import * as React from 'react';
import {
  PackageInstallationStatus,
  PackagesModalContext,
} from '../../../packagesModalContext';
import { PackagesService } from '../../../packagesService';
import { isPackageUpdating, isReusePackage } from '../../../utils';
import {
  PackageItemContext,
  PackageItemContextProps,
} from './PackageItemContext';

const createPackageItemContextValue = ({
  pkgName,
  packagesService,
}: {
  pkgName: string;
  packagesService: PackagesService;
}): PackageItemContextProps => {
  const availablePkg = packagesService.getAvailablePkg(pkgName);
  const installedPkg = packagesService.getInstalledPkg(pkgName);
  let installationStatus: PackageInstallationStatus | undefined;
  if (installedPkg && installedPkg.status) {
    installationStatus = installedPkg.status;
  } else if (availablePkg && isPackageUpdating(pkgName)) {
    installationStatus = 'UPDATING';
  } else {
    installationStatus = availablePkg && availablePkg.status;
  }

  return {
    pkgName,
    isCodeReusePackage: isReusePackage(pkgName),
    availablePkg,
    installedPkg,
    installationStatus,
  };
};

export const PackageItemContextProvider: React.FC<{
  pkgName: string;
}> = ({ pkgName, children }) => {
  const { packagesService } = React.useContext(PackagesModalContext);

  return (
    <PackageItemContext.Provider
      value={createPackageItemContextValue({ pkgName, packagesService })}
    >
      {children}
    </PackageItemContext.Provider>
  );
};
