import React, { useContext } from 'react';
import { Tooltip, RichText } from '@wix/wix-base-ui';
import TestVersionIcon from '@/assets/icons/leftTree/testTube.svg';
import s from '../codeReusePkgTree.scss';
import dataHooks from '../../../../dataHooks';
import { useTranslation } from '@wix/wix-code-common-components';
import { PkgTreeContext } from '../../PkgTreeContext';

export const TestVersionSymbol: React.FC = () => {
  const [t] = useTranslation();
  const { isBlocksCombinedMode } = useContext(PkgTreeContext);

  return (
    <span data-hook={dataHooks.TEST_VERSION_SYMBOL}>
      <Tooltip
        marginTop={4}
        interactive={true}
        content={
          <RichText>
            {t(
              isBlocksCombinedMode
                ? 'Left_Tree_Components_Test_Version_Tooltip_Apps'
                : 'Left_Tree_Components_Test_Version_Tooltip',
            )}
            <a
              target="_blank"
              className={s.readOnlyLink}
              href={t('Left_Tree_Components_Test_Version_Learn_More_Url')}
            >
              {t('Left_Tree_Components_Test_Version_Learn_More')}
            </a>
          </RichText>
        }
      >
        <TestVersionIcon className={s.readOnly} />
      </Tooltip>
    </span>
  );
};
