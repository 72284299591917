import * as React from 'react';
import { PackagesModalTabId } from '../../packagesModalConsts';
import { PackagesModalMenuV2 } from './PackagesModalMenuV2';

export interface ModalTab {
  value: PackagesModalTabId;
  label: string;
  dataHook?: string;
}

export const PackagesModalMenu: React.FC = () => <PackagesModalMenuV2 />;
