import {
  FilesTreeAPI,
  WixCodeStoreAPI,
  FileValidationRule,
  SectionContribution,
  EditorAPI,
  NewChild,
  PredefinedSidebarTabs,
} from '@wix/wix-code-plugin-contracts';
import { ScopedStore } from '@wix/wix-code-repluggable';
import fileSystemActions from '@/infra/redux-state/actions/fileSystemActions';
import { setNewFileRenameInput } from '@/infra/redux-state/actions/fileTreeActions';
import leftPaneActions from '@/infra/redux-state/actions/leftPaneActions';
import { FilesTreeState } from '@/infra/redux-state/reducers/fileTreeReducer';
import { getNewFileInput } from '@/infra/redux-state/selectors/fileTreeSelector';
import { FileTreeRoot } from './FileTreeRoot';
import { treeSectionActions } from './treeSectionReducer';

interface CreateFilesTreeApiParams {
  wixCodeStoreAPI: WixCodeStoreAPI;
  editorAPI: EditorAPI;
  stateManagement: any;
  filesTreeStore: ScopedStore<FilesTreeState>;
}
export const createFilesTreeAPI = ({
  wixCodeStoreAPI,
  stateManagement,
  editorAPI,
  filesTreeStore,
}: CreateFilesTreeApiParams): FilesTreeAPI => {
  const { dispatch } = wixCodeStoreAPI;
  return {
    contributeSection(section: SectionContribution) {
      dispatch(treeSectionActions.contributeTreeSection({ section }));
    },
    contributeFileValidationRule(rule: FileValidationRule) {
      dispatch(treeSectionActions.contributeFileValidationRule({ rule }));
    },
    FileTreeRoot,
    expandSection(sectionName: string) {
      dispatch(fileSystemActions.expand(sectionName) as any);
    },
    expandParents(filePath: string) {
      dispatch(fileSystemActions.expandParents(filePath) as any);
    },
    selectFile(filePath, keepSelectedComponentFocused = false) {
      editorAPI.panelManager.closeAllPanels();
      const fileContext = editorAPI.developerMode.createContext('FILE', {
        id: filePath,
      });
      editorAPI.developerMode.setContext(fileContext);
      editorAPI.store.dispatch(
        stateManagement.editorPlugins.wixCodeIDE.actions.showIDEWithDsRead({
          dsRead: editorAPI.dsRead,
        }),
      );
      editorAPI.developerMode.ui.idePane.unMinimize();
      if (!keepSelectedComponentFocused) {
        editorAPI.selection.deselectComponents();
      }

      editorAPI.wixCode.codeEditorApi.performActionOnMount(
        filePath,
        (api: any) => api && api.focus(),
      );
    },
    setNewFileRenameInput(newChild: NewChild) {
      filesTreeStore.dispatch(setNewFileRenameInput(newChild));
    },
    selectTab(tabName: PredefinedSidebarTabs) {
      dispatch(leftPaneActions.selectLeftPaneTab(tabName));
    },
    async showNewFileInput(
      initialName: string,
      suffix: string,
      isFolder: boolean,
      folderPath: string,
    ) {
      suffix = suffix ? `.${suffix}` : '';
      dispatch(fileSystemActions.expand(folderPath) as any);
      const childItems = await dispatch(
        fileSystemActions.getChildren(folderPath),
      );
      const childNames = childItems.filter((item: any) =>
        item.name.includes(initialName),
      );
      const finalName = `${initialName}${
        childNames.length ? '-' + childNames.length : ''
      }${suffix}`;
      filesTreeStore.dispatch(
        setNewFileRenameInput({
          initialName: finalName,
          isFolder,
          parentFolderPath: folderPath,
        }),
      );
    },
    getNewFileRenameInput() {
      return getNewFileInput(filesTreeStore.getState());
    },
  };
};
