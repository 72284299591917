import React from 'react';
import {
  SlotKey,
  ExtensionSlot,
  SlotRenderer,
} from '@wix/wix-code-repluggable';

export type ExternalIDEBannerSlot = ExtensionSlot<() => React.ReactNode>;
export const ExternalIDEBannerRendererSlot: SlotKey<() => React.ReactNode> = {
  name: 'External IDE Banner Slot',
};

interface ExternalIDEBannerRendererProps {
  externalIDEBannerSlot: ExternalIDEBannerSlot;
}

export const ExternalIDEBannerRenderer = ({
  externalIDEBannerSlot,
}: ExternalIDEBannerRendererProps) => (
  <SlotRenderer slot={externalIDEBannerSlot}></SlotRenderer>
);
