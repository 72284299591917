import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { EntryPoint, Shell } from '@wix/wix-code-repluggable';
import { createDevConsole } from './consoleContainer';
import { fedops } from '@wix/wix-code-common';

export const DevConsoleEntryPoint: EntryPoint = {
  name: 'Dev Console',
  getDependencyAPIs() {
    return [WixCodeEditorAdapterAPI];
  },
  attach(shell: Shell) {
    fedops.getLogger().interactionStarted(fedops.interactions.renderConsole);
  },
  extend(shell: Shell) {
    const wixCodeEditorAdapter = shell.getAPI(WixCodeEditorAdapterAPI);
    const DevConsole = createDevConsole({
      legacyEditorDeps: wixCodeEditorAdapter.legacyDependencies,
    });
    wixCodeEditorAdapter.contributeConsole(shell, DevConsole, () => true);
    fedops.getLogger().interactionEnded(fedops.interactions.renderConsole);
  },
};
