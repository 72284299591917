import * as React from 'react';
import {
  PackageInstallationStatus,
  PkgInfo,
} from '../../../packagesModalContext';

export type PackageItemContextProps = {
  isCodeReusePackage: boolean;
  availablePkg: PkgInfo;
  installedPkg: PkgInfo;
  pkgName: string;
  installationStatus?: PackageInstallationStatus;
};

export const PackageItemContext = React.createContext<PackageItemContextProps>(
  null as any,
);
