import React from 'react';
import { TextButton } from '@wix/wix-base-ui';
import s from './semanticClasses.scss';
import dataHooks from '../../../dataHooks';
import { ReadOnlyModeContext } from '@wix/wix-code-common-components';

type SemanticClassesProps = {
  semanticClassName: string;
  onSelectClass: (className: string) => void;
};

export const SemanticClass = ({
  semanticClassName,
  onSelectClass,
}: SemanticClassesProps) => {
  const { readOnlyMode } = React.useContext(ReadOnlyModeContext);

  return (
    <div className={s.classControl}>
      <TextButton
        dataHook={dataHooks.semanticClassCta}
        disabled={readOnlyMode.cssEditing.semanticClasses}
        size="tiny"
        onClick={() => onSelectClass(semanticClassName)}
      >
        {semanticClassName}
      </TextButton>
    </div>
  );
};
