import { FileNameTransformer } from '@wix/wix-code-plugin-contracts';
import { createContext, useContext } from 'react';

type CodeEditorTabData = {
  fileNameTransformer: FileNameTransformer;
};

export const CodeEditorTabContext = createContext<CodeEditorTabData>(
  null as any,
);

export const useCodeEditorTabContext = () => {
  return useContext(CodeEditorTabContext);
};
