// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".w42c6w{display:flex}.KlPU9z,.Nux4fl{color:#de9c03}.Nux4fl{color:#dfe6ef}.zLjlIP{color:#3178c6}.z8n3sS{color:#06a2ad}.MMfKb1{color:#0049db}.N7eU_C path{fill:currentColor !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon-wrapper": "w42c6w",
	"iconWrapper": "w42c6w",
	"js": "KlPU9z",
	"jsx": "Nux4fl",
	"ts": "zLjlIP",
	"jsw": "z8n3sS",
	"css": "MMfKb1",
	"currentColor": "N7eU_C"
};
export default ___CSS_LOADER_EXPORT___;
