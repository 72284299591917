import './ideShortcutsPopover.global.scss';
import baseUILib from '@wix/wix-base-ui';
import React from 'react';
import PropTypes from 'prop-types';

import once_ from 'lodash/once';
import bi from '@/legacy/bi/bi';

export default once_(({ util }) => {
  class IdeShortcutsPopover extends React.Component {
    constructor(props) {
      super(props);
      this.onGotItButtonClick = this.onGotItButtonClick.bind(this);
    }

    componentDidMount() {
      this.props.sendBiFunc(bi.events.IDE_POPUP_SHOWN, {
        origin: null,
        popup_name: 'ide_shortcuts_popover',
      });
    }

    onGotItButtonClick() {
      this.props.sendBiFunc(bi.events.IDE_POPUP_CLOSED, {
        origin: 'got_it',
        popup_name: 'ide_shortcuts_popover',
      });
      this.props.onCloseButtonClick();
    }

    render() {
      return (
        <baseUILib.Bubble
          className="wix-code-ide-shortcuts-bubble"
          key="IDEShortcutsPopover"
          shouldTranslate={false}
          contentStyle={{ boxShadow: '0 0 11px 0 rgba(0, 0, 0, 0.21)' }}
        >
          <div className="wix-code-ide-shortcuts-container">
            <baseUILib.TextLabel
              type="T06"
              value={util.translate(
                'WixCode_IdeTabs_Shortcuts_Notification_Title',
              )}
              enableEllipsis={false}
            />
            <baseUILib.TextLabel
              enableEllipsis={false}
              type="T02"
              value={util.translate(
                'WixCode_IdeTabs_Shortcuts_Notification_Description',
              )}
            />
            <baseUILib.Button
              className="btn-confirm-secondary btn-sm wix-code-ide-shortcuts-button"
              onClick={this.onGotItButtonClick}
            >
              {util.translate('WixCode_IdeTabs_Shortcuts_Notification_Button')}
            </baseUILib.Button>
          </div>
        </baseUILib.Bubble>
      );
    }
  }

  IdeShortcutsPopover.propTypes = {
    onCloseButtonClick: PropTypes.func.isRequired,
    sendBiFunc: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  return IdeShortcutsPopover;
});
