import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { translations } from '@wix/wix-code-common';
import { createSidebarSection } from './createSidebarSection';
import { CssEditingAction, cssEditingReducer, CssEditingStore } from './state';
import { constants } from './constants';
import {
  createCssEditingInternalAPI,
  CssEditingInternalAPIKey,
} from './cssEditingInternalAPI';
import { createCssClasses } from './createCssClasses';
import { createModalsService } from './components/Modals/modalsService';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { propertiesPanelTabIds } from '@wix/wix-code-plugin-contracts';
import getCompletionItemProvider from './getCompletionItemProvider';
import getCustomCodeValidator from './getCustomCodeValidator';

export const CssEditingEntryPoint: EntryPoint = {
  name: 'css editing entry point',
  getDependencyAPIs() {
    return [
      APIKeys.PagesTreeAPI,
      APIKeys.SidebarAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.LegacyEditorDependencies,
      APIKeys.FileSystemAPI,
      APIKeys.FilesTreeAPI,
      APIKeys.WixCodeStoreAPI,
      APIKeys.PropertiesPanelAPI,
      APIKeys.ClassicEditorAPI,
      APIKeys.DevContextAPI,
      APIKeys.WixCodeDuplexerAPI,
      APIKeys.UserPreferencesAPI,
      APIKeys.BiLoggerAPI,
      WixCodeEditorAdapterAPI,
      APIKeys.CodeEditorAPI,
      APIKeys.ExperimentsAPI,
      APIKeys.EditorContextAPI,
      APIKeys.MenuAPI,
      APIKeys.MenuPanelAPI,
    ];
  },
  declareAPIs() {
    return [CssEditingInternalAPIKey];
  },
  attach(shell: Shell) {
    shell.contributeState<CssEditingStore, CssEditingAction>(() => ({
      cssEditing: cssEditingReducer,
    }));

    shell.contributeAPI(CssEditingInternalAPIKey, () => {
      const fileSystemAPI = shell.getAPI(APIKeys.FileSystemAPI);
      const filesTreeAPI = shell.getAPI(APIKeys.FilesTreeAPI);
      const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
      const wixCodeDuplexerAPI = shell.getAPI(APIKeys.WixCodeDuplexerAPI);
      const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
      const userPreferencesAPI = shell.getAPI(APIKeys.UserPreferencesAPI);
      const codeEditorAPI = shell.getAPI(APIKeys.CodeEditorAPI);
      const store = shell.getStore<CssEditingStore>();
      return createCssEditingInternalAPI({
        store,
        fileSystemAPI,
        filesTreeAPI,
        editorAPI,
        wixCodeDuplexerAPI,
        biLoggerAPI,
        userPreferencesAPI,
        codeEditorAPI,
      });
    });
  },
  async extend(shell: Shell) {
    const editorContextAPI = shell.getAPI(APIKeys.EditorContextAPI);
    const experimentsAPI = shell.getAPI(APIKeys.ExperimentsAPI);
    const shouldContributeCssEditing = () =>
      experimentsAPI.isOpen('specs.wixcode.CSSEditingSupport') &&
      !editorContextAPI.isBlocks();

    if (!shouldContributeCssEditing()) {
      return;
    }

    const pagesTreeAPI = shell.getAPI(APIKeys.PagesTreeAPI);
    const codeEditorAPI = shell.getAPI(APIKeys.CodeEditorAPI);
    const { editorAPI, observeEditorStore } = shell.getAPI(
      APIKeys.ClassicEditorAPI,
    );
    const legacyDependenciesAPI = shell.getAPI(
      APIKeys.LegacyEditorDependencies,
    );
    const internalAPI = shell.getAPI(CssEditingInternalAPIKey);
    const propertiesPanelAPI = shell.getAPI(APIKeys.PropertiesPanelAPI);
    const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
    const menuAPI = shell.getAPI(APIKeys.MenuAPI);
    const menuPanelAPI = shell.getAPI(APIKeys.MenuPanelAPI);
    const fileSystemAPI = shell.getAPI(APIKeys.FileSystemAPI);

    internalAPI.initState();
    internalAPI.updateViewerOnCssChanges();
    observeEditorStore(
      () => editorAPI.developerMode.getContext(),
      (context) => {
        if (context.data.id === constants.global_css_path) {
          propertiesPanelAPI.selectTab(propertiesPanelTabIds.cssClasses);
        }
      },
    );

    const i18nManager = translations.i18nManagerCreator({
      languageCode: legacyDependenciesAPI.util.editorModel.languageCode,
      asyncMessagesLoader: (locale: string) =>
        import(`./assets/locale/messages_${locale}.json`),
    });

    const initializedI18n = await i18nManager.initI18n();

    const modalsService = createModalsService({
      shell,
      initializedI18n,
      biLoggerAPI,
    });

    pagesTreeAPI.contributeSection({
      id: constants.section_id,
      section: createSidebarSection(shell, initializedI18n, modalsService, {
        internalAPI,
        biLoggerAPI,
      }),
    });

    const CssClassesPanel = createCssClasses(shell, initializedI18n, {
      internalAPI,
      biLoggerAPI,
      legacyDependenciesAPI,
      editorAPI,
    });

    propertiesPanelAPI.contributeTab({
      label: initializedI18n.t('WixCode_CssClassesPanel_Title'),
      id: propertiesPanelTabIds.cssClasses,
      TabContent: CssClassesPanel,
    });

    const semanticClassNames =
      await codeEditorAPI.getComponentsSemanticClassNames();
    if (semanticClassNames) {
      const provider = getCompletionItemProvider(semanticClassNames);

      codeEditorAPI.registerCompletionItemProvider(constants.css, provider);
    }
    const stylable =
      editorAPI.components.stylable.getEditorInstance().stylableDriver.stylable;
    const codeValidator = getCustomCodeValidator(stylable);
    codeEditorAPI.registerCodeValidator(constants.css, codeValidator);

    menuAPI.registerMenuItemListener('css', async () => {
      menuPanelAPI.selectTab('code_editor');
      if (await fileSystemAPI.isExists(constants.global_css_path, false)) {
        internalAPI.selectFile();
      } else {
        internalAPI.createCssFileAndOpen(
          `${initializedI18n.t(
            'WixCode_CodePanel_Editor_GlobalCss_DefaultText_CssReference',
          )} ${initializedI18n.t(
            'WixCode_CodePanel_Editor_GlobalCss_DefaultText_CssIntro',
          )}`,
        );
      }
    });
  },
};
