import { EditorAPI } from '@wix/wix-code-plugin-contracts';

export default ({ stateManagement }: { stateManagement: any }) => {
  const setSitePreference = <T = unknown>(
    editorAPI: EditorAPI,
    key: string,
    value: T,
  ) =>
    editorAPI.store.dispatch(
      stateManagement.editorPlugins.userPreferences.actions.setSiteUserPreferences(
        key,
        value,
      ),
    );

  const getSitePreference = <T = unknown>(
    editorAPI: EditorAPI,
    key: string,
  ): T | undefined =>
    stateManagement.editorPlugins.userPreferences.selectors.getSiteUserPreferences(
      key,
    )(editorAPI.store.getState());

  const setGlobalPreference = <T = unknown>(
    editorAPI: EditorAPI,
    key: string,
    value: T,
  ) =>
    editorAPI.store.dispatch(
      stateManagement.editorPlugins.userPreferences.actions.setGlobalUserPreferences(
        key,
        value,
      ),
    );

  const getGlobalPreference = <T = unknown>(
    editorAPI: EditorAPI,
    key: string,
  ): T | undefined =>
    stateManagement.editorPlugins.userPreferences.selectors.getGlobalUserPreferences(
      key,
    )(editorAPI.store.getState());

  return {
    getGlobalPreference,
    setGlobalPreference,
    getSitePreference,
    setSitePreference,
  };
};
