import { APIKeys, EntryPoint, Shell } from '@wix/wix-code-repluggable';

import { createDevContextAPI } from './DevContextAPI';
import {
  DeveloperModeContextActions,
  DeveloperModeContextStore,
  actions,
  developerModeContextReducer,
} from './state';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';

export const DevContextEntryPoint: EntryPoint = {
  name: 'Dev Context Entry Point',
  getDependencyAPIs() {
    return [WixCodeEditorAdapterAPI, APIKeys.ClassicEditorAPI];
  },
  declareAPIs() {
    return [APIKeys.DevContextAPI];
  },
  attach(shell: Shell) {
    shell.contributeState<
      DeveloperModeContextStore,
      DeveloperModeContextActions
    >(() => ({
      developerModeContext: developerModeContextReducer,
    }));
    const { legacyDependencies } = shell.getAPI(WixCodeEditorAdapterAPI);
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);

    shell.contributeAPI(APIKeys.DevContextAPI, () => {
      const store = shell.getStore<DeveloperModeContextStore>();
      return createDevContextAPI(legacyDependencies, editorAPI, store);
    });
  },
  extend(shell: Shell) {
    const { observeEditorStore, editorAPI } = shell.getAPI(
      APIKeys.ClassicEditorAPI,
    );
    const store = shell.getStore<DeveloperModeContextStore>();
    observeEditorStore(
      () => editorAPI.developerMode.getContext()?.data.id,
      () => {
        const currentContext = editorAPI.developerMode.getContext();
        store.dispatch(actions.changeDevContext(currentContext));
      },
    );
  },
};
