// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K3x1qA{display:flex;align-items:center;justify-content:center;flex-grow:1}.XdEZoc{display:flex;justify-content:center;align-items:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "K3x1qA",
	"loader": "XdEZoc"
};
export default ___CSS_LOADER_EXPORT___;
