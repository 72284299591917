import {
  MonacoEditorTextModel,
  MonacoLanguagesCompletionItemProvider,
  MonacoPosition,
  MonacoRange,
  MonacoLanguagesCompletionItem,
} from "@wix/wix-code-code-editor";

const getCompletionItemProvider = (
  suggestionsData: string[],
): MonacoLanguagesCompletionItemProvider => ({
  provideCompletionItems: (
    model: MonacoEditorTextModel,
    position: MonacoPosition,
  ) => {
    if (isInsideCssClass(model, position)) {
      return { suggestions: [] };
    }
    const word = model.getWordUntilPosition(position);
    const range = {
      startLineNumber: position.lineNumber,
      endLineNumber: position.lineNumber,
      startColumn: word.startColumn,
      endColumn: word.endColumn,
    };
    return {
      suggestions: getSuggestions(range, suggestionsData),
    };
  },
});

export const isInsideCssClass = (
  model: MonacoEditorTextModel,
  position: MonacoPosition,
) => {
  const textUntilPosition = model.getValueInRange({
    startLineNumber: 1,
    startColumn: 1,
    endLineNumber: position.lineNumber,
    endColumn: position.column,
  });
  const lastOpeningBraceIndex = textUntilPosition.lastIndexOf("{");
  const lastClosingBraceIndex = textUntilPosition.lastIndexOf("}");
  return lastOpeningBraceIndex > lastClosingBraceIndex;
};

const getSuggestions = (
  range: MonacoRange,
  suggestionsData: string[],
): MonacoLanguagesCompletionItem[] => {
  const uniqueSuggestions = [...new Set(suggestionsData)];
  return uniqueSuggestions.map((suggestion) => ({
    label: `.${suggestion}`,
    kind: 1,
    documentation: "",
    insertText: `.${suggestion} {\n\t$0\n}`,
    insertTextRules: 4,
    range,
  }));
};

export default getCompletionItemProvider;
