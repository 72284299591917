import { Checkbox, InfoIcon, MessageModal, Preloader } from '@wix/wix-base-ui';
import React from 'react';
import GenericTrash from '../assets/icons/generic_trash.svg';
import { deleteCloudDb } from '../WixDataServerAPI';
import { NamespaceWithStatus } from './CollectionsTree/CollectionsTree';
import {
  BiLoggerAPI,
  PanelsAPI,
  PlatformAppsAPI,
  WixCodeAppAPI,
} from '@wix/wix-code-plugin-contracts';
import { connectExternalDbPanelAction } from '@wix/bi-logger-platform/v2';
import { BI } from './externalDbs/constants';

interface DeleteCloudDbModalProps {
  db: NamespaceWithStatus;
  panelsAPI: PanelsAPI;
  wixCodeAppAPI: WixCodeAppAPI;
  t: (key: string) => string;
  biLoggerAPI: BiLoggerAPI;
  platformAppsAPI: PlatformAppsAPI;
}

export const DeleteCloudDbModal: React.FC<DeleteCloudDbModalProps> = ({
  db,
  panelsAPI,
  wixCodeAppAPI,
  t,
  biLoggerAPI,
  platformAppsAPI,
}) => {
  const [checkboxChecked, setCheckboxChecked] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <MessageModal
      theme="destructive"
      title={t('External_Databases.Modal.DeleteDatabase.Title')}
      content={t('External_Databases.Modal.DeleteDatabase.Description')}
      onCloseButtonClick={() => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: BI.panels.deleteCloudDb,
            action_type: BI.actions.click,
            button_name: BI.buttons.x,
          }),
        );
        panelsAPI.closePanel();
      }}
      onHelpButtonClick={() => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: BI.panels.deleteCloudDb,
            action_type: BI.actions.click,
            button_name: BI.buttons.help,
          }),
        );
        window.open('https://example.com', '_blank');
      }}
      illustration={<GenericTrash />}
      sideActions={
        <div style={{ display: 'flex' }}>
          <Checkbox
            label={t('External_Databases.Modal.DeleteDatabase.Checkbox.Text')}
            labelAfterSymbol={true}
            value={checkboxChecked}
            onChange={(isChecked: boolean) => {
              setCheckboxChecked(isChecked);
            }}
          />
          {showError && (
            <InfoIcon
              text={t(
                'External_Databases.Modal.DeleteDatabase.Checkbox.ErrorText',
              )}
              error
            />
          )}
        </div>
      }
      primaryButtonText={
        isLoading
          ? ' '
          : t('External_Databases.Modal.DeleteDatabase.PrimaryCTA')
      }
      primaryButtonOnClick={async () => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: BI.panels.deleteCloudDb,
            action_type: BI.actions.click,
            button_name: BI.buttons.delete,
          }),
        );
        try {
          if (checkboxChecked) {
            setIsLoading(true);
            await deleteCloudDb(db, wixCodeAppAPI.getSignedInstance);
            platformAppsAPI.refreshSchemasCache();
            panelsAPI.closePanel();
          } else {
            setShowError(true);
          }
        } catch (e) {}
      }}
      secondaryButtonText={t(
        'External_Databases.Modal.DeleteDatabase.SecondaryCTA',
      )}
      secondaryButtonOnClick={() => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: BI.panels.deleteCloudDb,
            action_type: BI.actions.click,
            button_name: BI.buttons.cancel,
          }),
        );
        panelsAPI.closePanel();
      }}
      primaryButtonProps={
        isLoading
          ? {
              suffixIcon: (
                <div style={{ marginRight: '9px', padding: '0px 18px' }}>
                  <Preloader className="tiny light" />
                </div>
              ),
            }
          : undefined
      }
    />
  );
};
