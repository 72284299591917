import React, { useState } from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import js from 'react-syntax-highlighter/dist/cjs/languages/hljs/javascript';
import { github } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  ConfirmCircle,
  Duplicate,
} from '@wix/wix-ui-icons-common/classic-editor';

import { CustomModal, Preloader } from '@wix/wix-base-ui';
import s from './GenerateCodeResult.scss';
import { dataHooks } from '../dataHooks';
import './GithubMarkdownStyle.global.scss';

SyntaxHighlighter.registerLanguage('javascript', js);

type Markdown = string;
export interface GenerateCodeResultProps {
  closePanel: () => void;
  generatedResult: Markdown;
  onBackClick: () => void;
  onCodeInsertClick: () => void;
  setGeneratedCodeBlock: (codeBlock: string) => void;
  isGeneratingResult: boolean;
}

const GenerateCodeResult = ({
  closePanel,
  generatedResult,
  onBackClick,
  onCodeInsertClick,
  setGeneratedCodeBlock,
  isGeneratingResult,
}: GenerateCodeResultProps) => {
  const [t] = useTranslation();
  return (
    <CustomModal
      onCloseButtonClick={closePanel}
      title={t('aiCodeAssistant.AddCodeModal.Title')}
      primaryButtonText={
        isGeneratingResult
          ? ' '
          : t('aiCodeAssistant.QueryResultModal.PrimaryCta')
      }
      primaryButtonOnClick={() => {
        if (isGeneratingResult) {
          return;
        }
        onCodeInsertClick();
      }}
      primaryButtonProps={
        isGeneratingResult
          ? {
              className: cx(s.cta, s.disabled),
              prefixIcon: <Preloader className="tiny light" />,
            }
          : {}
      }
      secondaryButtonText={t('aiCodeAssistant.QueryResultModal.SecondaryCta')}
      secondaryButtonOnClick={onBackClick}
      onHelpButtonClick={() =>
        window.open(t('aiCodeAssistant.AddCodeModal.LearnMore.Link'), '_blank')
      }
      dataHook={dataHooks.ai_panel.modal}
    >
      <div
        className={`${s.main} markdown-body`}
        data-hook={dataHooks.ai_panel.markdown_container}
        onCopy={(e) => e.stopPropagation()}
      >
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          children={generatedResult}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '');
              const shouldRenderCodeBlockWithCopy = !inline && match;
              if (shouldRenderCodeBlockWithCopy) {
                const codeBlock = String(children).replace(/\n$/, '');
                setGeneratedCodeBlock(codeBlock);
                return (
                  <CodeBlockWithCopy code={codeBlock} language={match[1]} />
                );
              } else {
                return (
                  <code {...props} className={className}>
                    {children}
                  </code>
                );
              }
            },
          }}
        />
      </div>
    </CustomModal>
  );
};

export default GenerateCodeResult;

const CodeBlockWithCopy = ({
  code,
  language = 'javascript',
}: {
  code: string;
  language?: string;
}) => {
  const [copied, setCopied] = useState(false);
  return (
    <>
      <div className={s.copyContainer}>
        {copied ? (
          <ConfirmCircle />
        ) : (
          <CopyToClipboard
            text={code}
            onCopy={() => {
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 2000);
            }}
          >
            <Duplicate className={s.copyButton} />
          </CopyToClipboard>
        )}
      </div>

      <SyntaxHighlighter
        children={code}
        style={github}
        language={language}
        PreTag="div"
      />
    </>
  );
};
