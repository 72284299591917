import _ from 'lodash';
import { WIX_CODE } from '@wix/app-definition-ids';
import consoleActionsCreator from '@/infra/redux-state/actions/consoleActions';
import tbfConsoleActions from '@/toExtract/backendDebugging/actions/tbfConsoleActions';
import consoleContainerStateService from './consoleContainerStateService';
import consoleConstants from '../components/console/constants';
import messageOriginCalculatorCreator from './messageOriginCalculator';
import devContextUtilsCreator from '@/utils/devContext';
import once_ from 'lodash/once';

export default once_(
  ({
    constants,
    experiment,
    platform,
    stateManagement,
    util,
    wixCodeWsListener,
  }) => {
    const { isTbfContext } = devContextUtilsCreator({
      constants,
    });

    const consoleActions = consoleActionsCreator({ stateManagement });
    const messageOriginCalculator = messageOriginCalculatorCreator({
      experiment,
      platform,
      util,
    });

    const cannotBeObject = (str) =>
      !(_.head(str) === '{' && _.last(str) === '}');
    const cannotBeArray = (str) =>
      !(_.head(str) === '[' && _.last(str) === ']');

    function castJson(str) {
      if (cannotBeArray(str) && cannotBeObject(str)) {
        return str;
      }

      try {
        return JSON.parse(str);
      } catch (e) {
        return str;
      }
    }

    return function (store, editorAPI) {
      let nextMessageId = 0;

      function handleOnMessage(msg) {
        let messageData;

        try {
          // error handling for good JSON
          if (typeof msg.data === 'string') {
            messageData = JSON.parse(msg.data);
          }
        } catch (ee) {
          return;
        }

        if (
          messageData &&
          messageData.intent === consoleConstants.WIX_CODE_MESSAGE_INTENT &&
          messageData.type === consoleConstants.WIX_CODE_MESSAGE_TYPE
        ) {
          return handleClientConsoleMessage(messageData.data);
        }
      }

      function calculateMessageOrigin(message) {
        return messageOriginCalculator
          .calculate({ editorAPI, messageStackTrace: message.stack })
          .then((result) => {
            if (
              result.type ===
              consoleConstants.ORIGIN_CALCULATION_RESULT.ORIGIN_FOUND
            ) {
              store.dispatch(
                consoleActions.addMessageOrigin(message.id, result.origin),
              );
            }

            return result;
          });
      }

      function castMessageArgsToJson(message) {
        return _.assign({}, message, {
          args: message.args.map((a) => castJson(a)),
        });
      }

      function addMessageId(message) {
        return _.assign({}, message, { id: nextMessageId++ });
      }

      function enhanceConsoleMessage(message) {
        return _.flow(addMessageId, castMessageArgsToJson)(message);
      }

      function handleClientConsoleMessage(originalMessage) {
        const message = enhanceConsoleMessage(originalMessage);
        toggleConsole(message);
        store.dispatch(consoleActions.addMessage(message));

        return calculateMessageOrigin(message);
      }

      function backendSeverityToClientLogLevel(severity) {
        return consoleConstants.BACKEND_SEVERITY_TO_CLIENT_LOG_LEVEL[severity];
      }

      function transformServerFormatToClientFormat({
        severity,
        args,
        record,
        filename: source,
        lineNumber,
        columnNumber,
      }) {
        const message = {
          logLevel: backendSeverityToClientLogLevel(severity),
          args: args || [record],
        };

        if (source) {
          const { type, displayName } = messageOriginCalculator.getFileInfo({
            editorAPI,
            source,
          });
          message.origin = {
            type,
            sourceFileFullPath: source,
            sourceFileDisplayName: displayName,
            line: lineNumber,
            column: columnNumber,
          };
        }

        return message;
      }

      function shouldMaximizeConsoleByLevel(message) {
        return (
          message.logLevel === consoleConstants.LOG_LEVEL.LOG ||
          message.logLevel === consoleConstants.LOG_LEVEL.WARNING ||
          message.logLevel === consoleConstants.LOG_LEVEL.ERROR
        );
      }

      function containerIsMinimized(containerState) {
        return (
          containerState === constants.DEVELOPER_MODE.CONTAINER_STATES.MINIMIZED
        );
      }

      function toggleConsole(message) {
        const containerState =
          editorAPI.developerMode.ui.consolePane.getState();
        if (
          shouldMaximizeConsoleByLevel(message) &&
          containerIsMinimized(containerState) &&
          !consoleContainerStateService.minimizedByUser()
        ) {
          consoleContainerStateService.revertMinimize();
        }
      }

      function registerToMessagesFromPlatformApp() {
        let lastProcessedIndex = -1;

        editorAPI.store.subscribe(() => {
          const editorAppMessages =
            stateManagement.editorPlugins.appsStore.selectors.getAppStore(
              editorAPI.store.getState(),
              WIX_CODE,
            )?.consoleMessages || [];

          if (editorAppMessages.length === 0) {
            store.dispatch(consoleActions.clearDisplayedMessages());
          }

          const newMessages = editorAppMessages.slice(lastProcessedIndex + 1);
          newMessages.forEach((message) =>
            handleClientConsoleMessage(JSON.parse(message)),
          );

          lastProcessedIndex = editorAppMessages.length - 1;
        });
      }

      function registerToMessages() {
        registerToClientLogs();
        registerToBackendLogs();
      }

      function registerToClientLogs() {
        window.addEventListener('message', handleOnMessage, false);
        registerToMessagesFromPlatformApp();
      }

      function registerToBackendLogs() {
        const { instance, instanceId } = editorAPI.wixCode.getClientSpec();
        wixCodeWsListener.registerToBackendLogs({
          instance,
          instanceId,
          socketServerUrl: 'duplexer.wix.com',
          wixCodeIdeServerUrl: util.serviceTopology.wixCodeIdeServerUrl,
          onLogsReceived: (logs) => {
            // TODO: Remove JSON.parse once server is compatible
            const parsedLogs = _.isString(logs) ? JSON.parse(logs) : logs;
            editorAPI.wixCode.log.trace({
              action: 'backendLogsReceived',
              message: `received ${parsedLogs.length} logs`,
            })();
            const consoleMessages = parsedLogs
              .map(transformServerFormatToClientFormat)
              .map(enhanceConsoleMessage);
            consoleMessages.forEach((message) => toggleConsole(message));
            store.dispatch(consoleActions.addMessages(consoleMessages));
            // TODO: Extract the duplexer out of console API creator and handle this on a tbfConsoleApiCreator
            if (isTbfContext(editorAPI)) {
              const tbfId = editorAPI.developerMode.getContext().data.id;
              store.dispatch(
                tbfConsoleActions.addMessages({
                  tbfId,
                  messages: consoleMessages,
                }),
              );
            }
          },
          onConnectionStatusChanged: _.noop,
        });
      }

      function addSingleMessage(originalMessage) {
        const message = addMessageId(originalMessage);
        store.dispatch(consoleActions.addMessage(message));
      }

      function removeMessage(messageId) {
        store.dispatch(consoleActions.removeMessage(messageId));
      }

      function clear() {
        store.dispatch(consoleActions.clear());
      }

      return {
        handleOnMessage,
        registerToMessages,
        addSingleMessage,
        removeMessage,
        clear,
      };
    };
  },
);
