import { LegacyEditorDependencies } from '@wix/wix-code-plugin-contracts';
import { APIKeys, EntryPoint, Shell } from '@wix/wix-code-repluggable';

export const createLegacyEditorDependenciesEntryPoint = (
  legacyDeps: LegacyEditorDependencies,
): EntryPoint => ({
  name: 'Legacy Editor Dependencies',
  declareAPIs() {
    return [APIKeys.LegacyEditorDependencies];
  },
  attach(shell: Shell) {
    shell.contributeAPI(APIKeys.LegacyEditorDependencies, () => legacyDeps);
  },
});
