import React from 'react';
import _ from 'lodash';
import { DATA_BINDING } from '@wix/app-definition-ids';
import { utilsCreator, getMasterPageFileId } from '@wix/wix-code-common';
import { leftTreeClickOnAnItem } from '@wix/bi-logger-platform/v2';
import { StaticPagesTree } from './StaticPagesTree';
import { DynamicPagesTree } from './DynamicPagesTree';
import { PagesTabContext } from '../pagesTree/PagesTabContext';
import { PageTreeContext } from '../pagesTree/PageTreeContext';
import { RouterPagesTree } from './RouterPagesTree';
import { RouterPagesGroup } from './RouterPagesGroup';
import { LightboxTree } from './LightboxTree';
import {
  BiLoggerAPI,
  DevContextAPI,
  EditorAPI,
  FilesViewAPI,
  LegacyEditorDependencies,
  WixCodeStoreAPI,
} from '@wix/wix-code-plugin-contracts';
import { Divider } from '@wix/wix-base-ui';
import dataHooks from '../dataHooks';
import MasterPage from '../pagesTree/MasterPage';
import { ContributedSection } from '../state';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';

interface PagesTabCompEditorReduxProps {
  editorAPI: EditorAPI;
  selectedPageId: string;
  dataSources: any;
}

interface PagesTabReduxProps {
  pageIdPendingRename: string;
}

type PagesTabCompProps = PagesTabReduxProps &
  PagesTabCompEditorReduxProps & {
    legacyDependenciesAPI: LegacyEditorDependencies;
    wixCodeStoreAPI: WixCodeStoreAPI;
    biLoggerAPI: BiLoggerAPI;
    devContextAPI: DevContextAPI;
    filesViewAPI: FilesViewAPI;
    wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI;
    contributedSections: ContributedSection[];
    selectedIdeTabId: string;
  };
const PagesTabComp: React.FC<PagesTabCompProps> = ({
  editorAPI,
  selectedPageId,
  dataSources,
  selectedIdeTabId,
  pageIdPendingRename,
  legacyDependenciesAPI,
  wixCodeStoreAPI,
  biLoggerAPI,
  devContextAPI,
  filesViewAPI,
  contributedSections,
  wixCodeEditorAdapterAPI,
}: PagesTabCompProps) => {
  const { constants, experiment, platform, util } = legacyDependenciesAPI;

  const {
    getStore,
    selectors: {
      page: { isPageCollapsed: isPageCollapsedRaw, pageActionsCreator },
    },
  } = wixCodeStoreAPI;
  const pageActions = pageActionsCreator({ experiment, platform, util });
  const utils = utilsCreator({ experiment, platform, util });
  const store = getStore();

  const refreshAppAfterDataChange = () => {
    const context = editorAPI.developerMode.getContext();
    if (
      experiment.isOpen('sv_livePreview') &&
      context.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.COLLECTION
    ) {
      editorAPI.dsActions.platform.livePreview.refresh({
        apps: [DATA_BINDING],
        source: 'AFTER_DB_CHANGE',
      });
    }
  };

  const setPageCollapsed = (id: AnyFixMe, collapsed: AnyFixMe) => {
    store.dispatch(pageActions.setCollapsed([id], collapsed));
  };

  const focusOnLoad = (fileId: AnyFixMe) => {
    editorAPI.wixCode.codeEditorApi.performActionOnMount(
      fileId,
      (api: AnyFixMe) => api && api.focus(),
    );
  };

  const isPageCollapsed = (id: AnyFixMe) => {
    return isPageCollapsedRaw(store.getState(), id);
  };

  const onPageSelected = (pageData: AnyFixMe, type: AnyFixMe) => {
    biLoggerAPI.report(
      leftTreeClickOnAnItem({
        item_name: pageData.id,
        item_type: type.isDynamicPage ? 'dynamic_page' : 'page',
      }),
    );
    refreshAppAfterDataChange();
    editorAPI.panelManager.closeAllPanelsOfType(
      constants.PANEL_TYPES.FULL_STAGE,
    );

    focusOnLoad(utils.getFileIdFromPageId(pageData.id));
    editorAPI.pages.navigateTo(pageData.id);
  };

  const setPendingRename = (pageId: AnyFixMe, isPendingRename = false) => {
    store.dispatch(pageActions.setRenaming(pageId, isPendingRename));
  };

  const ds = dataSources;
  const masterPageFileId = getMasterPageFileId();

  return (
    <PagesTabContext.Provider
      value={{
        legacyDependenciesAPI,
        wixCodeStoreAPI,
        editorAPI,
        bi: biLoggerAPI,
        devContextAPI,
        filesViewAPI,
        wixCodeEditorAdapterAPI,
      }}
    >
      <PageTreeContext.Provider
        value={{
          selectedPageId,
          selectedIdeTabId,
          onPageSelected,
          setPendingRename,
          pageIdPendingRename,
          isCollapsed: isPageCollapsed,
          setCollapsed: setPageCollapsed,
        }}
      >
        <StaticPagesTree dataSource={ds.static} />
        <div data-hook={dataHooks.MASTER_PAGE_CONTAINER}>
          <Divider dataHook={dataHooks.MASTER_PAGE_DIVIDER} />
          <MasterPage selected={selectedIdeTabId === masterPageFileId} />
        </div>
        {contributedSections.map(({ section: Section }: any) => (
          <Section />
        ))}
        {ds.dynamicPages.items.length > 0 && (
          <DynamicPagesTree dataSource={ds.dynamicPages} />
        )}
        {ds.router.items.length > 0 && (
          <RouterPagesTree dataSource={ds.router} />
        )}
        {ds.appPages.map((appPage: AnyFixMe) => (
          <RouterPagesGroup
            key={appPage.id}
            dataSource={appPage}
            isTopLevelTreeRoot={true}
          />
        ))}
        {ds.popups.items.length > 0 && <LightboxTree dataSource={ds.popups} />}
      </PageTreeContext.Provider>
    </PagesTabContext.Provider>
  );
};

const getDataSources = (editorAPI: EditorAPI) => {
  const dynamicPagesRoot = {
    id: '__DYNAMIC_PAGES__',
    isRoot: true,
    label: 'Left_Tree_Components_Category_DynamicPages',
    type: 'page',
    items: null,
  };

  const routerPagesRoot = {
    id: '__ROUTER_PAGES__',
    isRoot: true,
    label: 'Left_Tree_Components_Category_RouterPages',
    type: 'page',
    items: null,
  };

  const popupsRoot = {
    id: '__POPUPS__',
    isRoot: true,
    label: 'Left_Tree_Components_Category_Lightboxes',
    items: null as any,
  };

  function toMenuItem(popupData: AnyFixMe) {
    return {
      id: popupData.id,
      label: popupData.title,
      pageData: {
        id: popupData.id,
      },
      type: {
        isPage: false,
        isLink: false,
        isPopup: true,
      },
    };
  }

  const menuItems = editorAPI.menus.getMenuItemsForIdeTree();
  const dynamicPagesMenuItems = editorAPI.pages.dynamicPages.getMenuItems();
  const popupMenuItems = editorAPI.pages.popupPages
    .getDataList()
    .map(toMenuItem);

  dynamicPagesRoot.items = dynamicPagesMenuItems.filter(
    (mi: AnyFixMe) => mi.routerType === 'Data',
  ); // TODO how to do this properly? santa-editor constants
  routerPagesRoot.items = dynamicPagesMenuItems.filter((mi: AnyFixMe) => {
    const tpaIds = mi.items.map((item: AnyFixMe) =>
      _.get(item, 'pageData.tpaApplicationId'),
    );
    const nonZeroTpaApplicationId = tpaIds.some(Boolean);

    return mi.routerType === 'Custom Router' && !nonZeroTpaApplicationId; // TODO how to do this properly?
  });

  const appPages = dynamicPagesMenuItems.filter((mi: AnyFixMe) => {
    const tpaIds = mi.items.map((item: AnyFixMe) =>
      _.get(item, 'pageData.tpaApplicationId'),
    );
    const nonZeroTpaApplicationId = tpaIds.some(Boolean);

    return mi.routerType === 'Custom Router' && nonZeroTpaApplicationId; // TODO how to do this properly?
  });
  popupsRoot.items = popupMenuItems;

  return {
    static: { items: menuItems },
    dynamicPages: dynamicPagesRoot,
    router: routerPagesRoot,
    appPages,
    popups: popupsRoot,
  };
};

const mapEditorStateToProps = ({
  editorAPI,
}: AnyFixMe): PagesTabCompEditorReduxProps => {
  const selectedPageId = editorAPI.pages.getFocusedPageId();
  const dataSources = getDataSources(editorAPI);

  return {
    editorAPI,
    selectedPageId,
    dataSources,
  };
};

let ConnectedComponent = null as any;
export const PagesTab = (origProps: AnyFixMe) => {
  // todo: restore the "AppState" type (instead of the "any") that currently exists in classic-editor package
  const mapCodeStateToProps = (state: any): PagesTabReduxProps => {
    const pageIdPendingRename = getRenamingPageId(state);
    return {
      pageIdPendingRename,
    };
  };

  const legacyDependenciesAPI: LegacyEditorDependencies =
    origProps.legacyDependenciesAPI;
  const wixCodeStoreAPI: WixCodeStoreAPI = origProps.wixCodeStoreAPI;
  const {
    connectToStores,
    selectors: {
      page: { getRenamingPageId },
    },
  } = wixCodeStoreAPI;

  const { util } = legacyDependenciesAPI;
  if (ConnectedComponent === null) {
    ConnectedComponent = connectToStores({
      util,
      mapEditorStateToProps,
      mapCodeStateToProps,
      comp: PagesTabComp,
    });
  }
  return <ConnectedComponent {...origProps} />;
};
