import { ActionType } from '@/infra/redux-state/actions/types';
import { MODAL_TABS, PackagesModalTabId } from './packagesModalConsts';

export type PackagedModalReducerState = {
  currentTab: PackagesModalTabId;
  selectedPackageForTab: Record<string, any>;
  justInstalledPkgs: string[];
  justRequestedPkgs: string[];
  selectedVersion: string | null;
  showVersionPicker: boolean;
  veloPkgsSearchKeyword: string;
};

const initialState: PackagedModalReducerState = {
  currentTab: MODAL_TABS.BUILT_BY_VELO,
  selectedPackageForTab: {},
  justInstalledPkgs: [],
  justRequestedPkgs: [],
  selectedVersion: null,
  showVersionPicker: false,
  veloPkgsSearchKeyword: '',
};

type PackagesModalSelectTab = {
  tab: string;
  type: ActionType.PACKAGES_MODAL_SELECT_TAB;
};

export type PackagesModalSelectPackage = {
  tab: string;
  packageName: string;
  type: ActionType.PACKAGES_MODAL_SELECT_PACKAGE;
};

export type PackagesModalAddJustInstalledPkg = {
  name: string;
  type: ActionType.PACKAGES_MODAL_ADD_JUST_INSTALLED_PKG;
};

export type PackagesModalSelectVersion = {
  version: string;
  type: ActionType.PACKAGES_MODAL_SELECT_VERSION;
};

export type PackagesModalSearchVeloPkg = {
  keyword: string;
  type: ActionType.PACKAGES_MODAL_SEARCH_VELO_PKG;
};
export type PackagesModalClearInstalledPkgs = {
  type: ActionType.PACKAGES_MODAL_CLEAR_INSTALLED_PKGS;
};
export type PackagesModalOpenVersionPicker = {
  type: ActionType.PACKAGES_MODAL_OPEN_VERSION_PICKER;
};
export type PackagesModalCloseVersionPicker = {
  type: ActionType.PACKAGES_MODAL_CLOSE_VERSION_PICKER;
};
export type PackagesModalAddJustRequestedPkg = {
  name: string;
  type: ActionType.PACKAGES_MODAL_ADD_JUST_REQUESTED_PKG;
};

type PackagesModalReducerAction =
  | PackagesModalSelectTab
  | PackagesModalSelectPackage
  | PackagesModalAddJustInstalledPkg
  | PackagesModalSelectVersion
  | PackagesModalSearchVeloPkg
  | PackagesModalClearInstalledPkgs
  | PackagesModalOpenVersionPicker
  | PackagesModalCloseVersionPicker
  | PackagesModalAddJustRequestedPkg;

const packagesModalReducer = (
  state = initialState,
  action: PackagesModalReducerAction,
) => {
  switch (action.type) {
    case ActionType.PACKAGES_MODAL_SELECT_TAB:
      return {
        ...state,
        currentTab: action.tab,
        showVersionPicker: false,
        selectedVersion: null,
      };
    case ActionType.PACKAGES_MODAL_SELECT_PACKAGE:
      return {
        ...state,
        selectedPackageForTab: {
          ...state.selectedPackageForTab,
          [action.tab]: action.packageName,
        },
      };
    case ActionType.PACKAGES_MODAL_ADD_JUST_INSTALLED_PKG:
      return {
        ...state,
        justInstalledPkgs: [...state.justInstalledPkgs, action.name],
      };
    case ActionType.PACKAGES_MODAL_CLEAR_INSTALLED_PKGS:
      return {
        ...state,
        justInstalledPkgs: [],
      };
    case ActionType.PACKAGES_MODAL_ADD_JUST_REQUESTED_PKG:
      return {
        ...state,
        justRequestedPkgs: [...state.justRequestedPkgs, action.name],
      };
    case ActionType.PACKAGES_MODAL_SELECT_VERSION:
      return {
        ...state,
        selectedVersion: action.version,
      };
    case ActionType.PACKAGES_MODAL_SEARCH_VELO_PKG:
      return {
        ...state,
        veloPkgsSearchKeyword: action.keyword,
      };
    case ActionType.PACKAGES_MODAL_OPEN_VERSION_PICKER:
      return {
        ...state,
        showVersionPicker: true,
      };
    case ActionType.PACKAGES_MODAL_CLOSE_VERSION_PICKER:
      return {
        ...state,
        showVersionPicker: false,
        selectedVersion: null,
      };
    default:
      return state;
  }
};

export default packagesModalReducer;
