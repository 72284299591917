import './resultGroup.global.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import ResultRow from '../resultRow/resultRow';

const ResultGroup = ({
  title,
  subTitle,
  icon,
  matches,
  selectedResult,
  onResultClick,
  onResultDoubleClick,
}) => {
  return (
    <li className="result-group" data-aid="result-group">
      <div className="result-group-header">
        <span className="result-group-icon">{icon}</span>
        <span data-aid="result-group-title">{title}</span>
        <span
          data-aid="result-group-subtitle"
          className="result-group-subtitle"
        >
          {subTitle}
        </span>
      </div>
      <ul>
        {matches.map((result, index) => (
          <ResultRow
            key={index}
            result={result}
            isSelected={selectedResult === result}
            onResultClick={onResultClick}
            onResultDoubleClick={() => onResultDoubleClick(result)}
          />
        ))}
      </ul>
    </li>
  );
};

ResultGroup.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  icon: PropTypes.element,
  matches: PropTypes.array,
  selectedResult: PropTypes.object,
  onResultClick: PropTypes.func,
  onResultDoubleClick: PropTypes.func,
};

ResultGroup.defaultProps = {
  onResultClick: noop,
  onResultDoubleClick: noop,
};

export default ResultGroup;
