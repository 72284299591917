// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JwyfPT{display:flex;flex-direction:column;align-items:center;height:100%;justify-content:center}.aHfyi2{text-align:center;max-width:200px}._XddwZ{margin:10px 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty-state-wrapper": "JwyfPT",
	"emptyStateWrapper": "JwyfPT",
	"centered-text": "aHfyi2",
	"centeredText": "aHfyi2",
	"text": "_XddwZ"
};
export default ___CSS_LOADER_EXPORT___;
