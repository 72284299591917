import * as _ from 'lodash';
import bi from '@/legacy/bi/bi';
import {
  InstalledNpmPkg,
  InstalledPkgs,
  PkgDependency,
  NpmPackageInfo,
  PkgInfo,
  PkgAvailableVersionType,
  AvailableCodeReusePkg,
  TEST_VERSION_NAME,
  InstalledCodeReusePkg,
  NpmPackageVersion,
} from './packagesModalContext';
import { createWatcherService } from './packagesWatcherService';
import {
  fetchCodeReuseFiles,
  fetchCodeReuseReadme,
  CodeReuseFiles,
  fetchCodeReusePkgVersions,
  CodeReusePkgVersions,
  fetchAvailableCodeReusePkgs,
} from './codeReuseServerAPI';
import { consts } from '@wix/wix-code-consts';
import { packageViewActions } from './packagesView/packagesViewReducer';
import {
  getIndexSearchModules,
  getDefaultNpmPackages,
  getDefaultNpmPackagesDescriptors,
  getNpmModules,
} from './packagesModal/packagesModalContent/pkgLists/npmPackagesList/selectors/modulesSelectors';
import {
  isReusePackage,
  getNpmReadmePath,
  preambleMarkdown,
  getPkgScope,
  isSameVersion,
  isReusePackageByVelo,
} from './utils';
import {
  getSelectedPackageForTab,
  getCurrentTab,
} from './packagesModal/packagesModalSelectors';
import {
  selectPackage,
  addJustInstalledPkg,
  selectPackageVersion,
} from './packagesModal/packagesModalActions';
import {
  codeReuseFsAddRoot,
  codeReuseFsRemoveRoot,
} from './codeReuseFs/codeReuseFsActions';
import { fetchFiles, toCodeReuseTree } from './codeReuseFs/codeReuseFsServices';

import modelsAPI from './pkgsModelsAPI';
import { fetchReadme } from './packagesModal/packagesModalContent/pkgLists/npmPackagesList/readmeClient';
import {
  experimentUtils,
  DELETE_FILE_TYPE,
  openDeleteFileSystemItemWarningPanel,
  devContextUtilsCreator,
} from '@wix/wix-code-common';
import {
  installAndSave,
  installStart,
  installDone,
  openSubmitRequest,
  getCachedNpmPackageData,
  install,
  installVersionStart,
} from './packagesModal/packagesModalContent/pkgLists/npmPackagesList/actions/modulesActions';
import { definitionsApi } from '@wix/wix-code-code-editor';
import { codeReusePkgsSelectors } from './packagesView/packagesViewSelectors';
import { PackagesBiSender } from './packagesBiSender';
import { openInstallTestVersionPanel } from './InstallTestVersionPanel';
import { packagesCacheFactory } from './packagesCacheService';
import { platformPkgsInstallationService } from './platformPkgsInstallationService';
import { packageStatus } from './packagesModal/packagesModalContent/pkgLists/npmPackagesList/consts';
import { createPackagesAPIService } from './packagesServiceAPIFacade';
import {
  PrivateAppData,
  STATIC_SECTIONS,
  wixCodeLifecycleDuplexer,
} from '@wix/wix-code-plugin-contracts';
import { openErrorModal } from './errorModal/errorModal';

export interface PackagesService {
  getAvailablePkg: (name: string) => PkgInfo;
  getDependency: (name: string) => PkgDependency;
  getPackageReadme: (name: string, version?: string | null) => Promise<string>;
  getInstalled: (state: any) => InstalledPkgs;
  getInstalledVeloPkgs: (state: any) => InstalledCodeReusePkg[];
  installPkg: (name: string, version?: string) => Promise<void>;
  uninstallPkg: (name: string, hideWarningPanelOnEditorX?: boolean) => void;
  updatePkg: (name: string) => void;
  preloadCodeReuseFiles: () => Promise<void>;
  viewPkg: (name: string) => void;
  viewReadme: (name: string, pinTab?: boolean) => void;
  getPkgFileContent: (fileId: string) => string | null;
  openSubmitRequest: (params: AnyFixMe) => void;
  refreshAvailableCodeReusePkgs: () => Promise<void>;
  refreshPackage: (pkgName: string) => Promise<void>;
  editCodePkg: (appDefId: string) => void;
  getCodeReusePkgVersions: (name: string) => Promise<CodeReusePkgVersions>;
  isPackageVersionInstalled: (name: string, version: string) => boolean;
  getInstalledPkg: (name: string) => PkgInfo;
  changeCodeReusePkgVersion: (name: string, version?: string) => void;
  installCodeReusePkgVersion: (info: {
    pkgInstalling: AvailableCodeReusePkg;
    versionOverride?: string;
  }) => void;
  getMyScopeName: () => string;
  getCodeReusePkgInfo: (name: string) => PkgInfo | undefined;
  registerToCodeReuseEvents: () => void;
  requestPackage: (availablePkg: NpmPackageInfo) => void;
  requestPackageWithBi: (availablePkg: NpmPackageInfo) => void;
  getVersionsForRequestPackage: (availablePkg: NpmPackageInfo) => {
    available: string[];
    nonAvailable: string[];
  };
  loadApp: (appInfo: any) => void;
  handleUninstallBlockApp: (
    appData: PrivateAppData,
    hideWarningPanelOnEditorX?: boolean,
  ) => void;
}

export default function ({
  experiment,
  constants,
  editorAPI,
  translate,
  stateManagement,
  packagesAPI,
  wixCodeStoreAPI,
  duplexerAPI,
  panelsAPI,
}: AnyFixMe): PackagesService {
  const { setPageContext, isFileContext, setFileContext, extractFileId } =
    devContextUtilsCreator({
      constants,
    });
  const packagesBiSender = PackagesBiSender(editorAPI.bi.event);
  const platformInstaller = platformPkgsInstallationService({
    editorAPI,
    stateManagement,
  });
  const {
    codeStateReader,
    fileSystemActions,
    dispatch,
    getStore,
    ideTabsStateReader,
    fileActions,
    ideTabsActions,
    packageJsonActions,
    treeSectionActions,
  } = wixCodeStoreAPI;
  const VELO_PACKAGE_REGISTRY_APP_DEF_ID =
    '18230e81-ca47-4358-aff2-d72028596658';
  const EVENT_NAME = 'code-reuse-package-updates';
  const { load } = packageJsonActions;
  const packagesAPIService = createPackagesAPIService(editorAPI);
  const getVersionsForRequestPackage = (availablePkg: NpmPackageInfo) => {
    const npmModule = availablePkg;
    const nonAvailable = npmModule._orderedLabeledVersions
      .filter(
        (version: NpmPackageVersion) =>
          version.versionInfo.status !== packageStatus.AVAILABLE,
      )
      .map((version: NpmPackageVersion) => version.value);
    const available = npmModule._orderedLabeledVersions
      .filter(
        (version: NpmPackageVersion) =>
          version.versionInfo.status === packageStatus.AVAILABLE,
      )
      .map((version: NpmPackageVersion) => version.value);
    return {
      nonAvailable,
      available,
    };
  };
  const requestPackageWithBi = (availablePkg: NpmPackageInfo) => {
    packagesBiSender.sendQuickActionRequestNpmPackageBi(availablePkg);
    requestPackage(availablePkg);
  };

  const requestPackage = (availablePkg: NpmPackageInfo) => {
    const { name, _version } = availablePkg;
    const version = _version.version;
    dispatch(
      openSubmitRequest({
        name,
        version,
        versions: getVersionsForRequestPackage(availablePkg),
      }),
    );
  };

  function getInstalledNpmPkgs(state: AnyFixMe): InstalledNpmPkg[] {
    return codeStateReader.getNpmPackages(state);
  }
  function getInstalledVeloPkgs(state: AnyFixMe): InstalledCodeReusePkg[] {
    return codeReusePkgsSelectors.installed.byVelo(state);
  }

  function getInstalled(state: AnyFixMe): InstalledPkgs {
    return {
      npm: getInstalledNpmPkgs(state),
      codeReuse: codeReusePkgsSelectors.installed.all(state),
    };
  }

  function loadCodeReuseFiles({ name, files }: AnyFixMe) {
    files.forEach(({ path, content }: AnyFixMe) => {
      const fileId = `${name}/${path}`;
      if (path.endsWith('d.ts')) {
        addDefinitionFile({ fileId, content });
      }
      cacheFile(fileId, content);
    });
  }

  function cacheFile(fileId: AnyFixMe, content: AnyFixMe) {
    const { modelId } = modelsAPI.create({ path: fileId, value: content });
    const descriptor = editorAPI.wixCode.fileSystem.getVirtualDescriptor(
      fileId,
      false,
    );

    dispatch(fileActions.endLoadFile(fileId, descriptor, modelId));
  }

  function addDefinitionFile({ fileId, content }: AnyFixMe) {
    try {
      definitionsApi.add({ fileName: fileId, content });
    } catch {
      console.error(
        `Could not add definition file ${fileId}. codeEditorApi has been reset.`,
      );
    }
  }

  function isFileIdEqualToContext(fileId: AnyFixMe) {
    if (!isFileContext(editorAPI)) {
      return false;
    }

    return editorAPI.developerMode.getContext().data.id === fileId;
  }

  function removeCodeReuseFiles(name: AnyFixMe) {
    const state = getStore().getState();
    const files = codeStateReader.getFilesByPrefix(state, name);
    closeCodeReuseTabs(name, state);
    _.forEach(files, ({ modelId }, fileId) => {
      closeTab(name, fileId, state);
      if (isFileIdEqualToContext(fileId)) {
        setPageContext(editorAPI);
      }

      if (fileId.endsWith('d.ts')) {
        removeDefinitionFile({ fileName: fileId });
      }
      modelsAPI.remove({ modelId });
      dispatch(fileSystemActions.removeFile(fileId) as any);
    });
  }

  function closeCodeReuseTabs(name: AnyFixMe, state: AnyFixMe) {
    const configFile = `backend/${consts.CONFIG_FOLDER_NAME}/${name}-config.json`;
    closeTab(name, configFile, state);
  }

  function closeTab(name: AnyFixMe, fileName: AnyFixMe, state: AnyFixMe) {
    closeUnpinnedTabIfNeeded(name, state, fileName);
    setDefaultContextIfNeeded(name, fileName);

    dispatch(ideTabsActions.closeTab({ tabId: fileName }));
  }

  function closeUnpinnedTabIfNeeded(
    name: AnyFixMe,
    state: AnyFixMe,
    fileName: AnyFixMe,
  ) {
    const unpinnedTab = ideTabsStateReader.getUnpinnedTabId(state);

    if (
      unpinnedTab &&
      (unpinnedTab.indexOf(name) === 0 || unpinnedTab === fileName)
    ) {
      dispatch(ideTabsActions.openOrChangeUnpinnedTab({ tabId: null }));
    }
  }

  function setDefaultContextIfNeeded(name: AnyFixMe, fileName: AnyFixMe) {
    const devModeContext = editorAPI.developerMode.getContext();
    const selectedIdeTabId = extractFileId(devModeContext);
    if (
      selectedIdeTabId &&
      (selectedIdeTabId.indexOf(name) === 0 || selectedIdeTabId === fileName)
    ) {
      setPageContext(editorAPI);
    }
  }

  function removeDefinitionFile({ fileName }: AnyFixMe) {
    try {
      definitionsApi.remove({ fileName });
    } catch {
      console.error(
        `Could not remove definition file ${fileName}. codeEditorApi has been reset.`,
      );
    }
  }

  const getMyScopeName = () =>
    '@' + editorAPI.dsRead.generalInfo.getUserInfo().name;

  const isMyScope = (name: string) => getMyScopeName() === getPkgScope(name);

  const loadApp = async (appInfo: any) => {
    const codeComponent = appInfo.components?.find(
      (comp: any) => comp.type === 'CODE_PACKAGE',
    );
    const name = codeComponent.data?.importName;

    // TODO: determine if file already loaded
    if (getPkgFileContent(`${name}/README.md`)) {
      return;
    }
    dispatch(
      codeReuseFsAddRoot({
        name,
        files: {
          id: name,
          name,
          pendingRename: '',
          isFolder: true,
          loading: false,
          expanded: false,
          pendingCreation: false,
          pendingDelete: false,
          childItems: [
            {
              id: `${name}/README.md`,
              name: 'README.md',
              pendingRename: '',
              isFolder: false,
              childItems: [],
              loading: false,
              expanded: false,
              pendingCreation: false,
              pendingDelete: false,
            },
          ],
        },
      }),
    );
    const files = await fetchCodeReuseFiles(
      { id: appInfo.appDefinitionId } as any,
      editorAPI,
      translate,
    );
    const readme = files.find((file) => file.path === 'README.md');
    cacheFile(`${name}/${readme?.path}`, readme?.content);
  };

  async function preloadCodeReuseFiles() {
    const state = getStore().getState();

    const installedNpmPkgs = getInstalledNpmPkgs(state);
    const installedCodeReusePkgs = codeReusePkgsSelectors.installed.all(state);

    const myScopeName = getMyScopeName();
    const [codeReusePkgs] = await Promise.all([
      fetchFiles(installedCodeReusePkgs, editorAPI, translate),
      loadAvailablecodeReusePackages(),
      cacheAllNpmReadme(installedNpmPkgs),
    ]);

    for (const pkg of codeReusePkgs) {
      const { name, files } = pkg;
      loadCodeReuseFiles({ name, files });
      addCodeReuseFilesToFS(name, files);
      const scope = getPkgScope(name);
      dispatch(treeSectionActions.setTreeSectionLoaded({ sectionName: scope }));
    }
    // We set this section to loaded to inform us that load packages is over
    dispatch(
      treeSectionActions.setTreeSectionLoaded({
        sectionName: consts.VELO_PKG_SCOPE,
      }),
    );
    dispatch(
      treeSectionActions.setTreeSectionLoaded({ sectionName: myScopeName }),
    );
    if (experiment.isOpen('se_privateAppsPanel')) {
      createWatcherService({
        editorAPI,
        initialPackages: installedCodeReusePkgs,
        packagesAPIService,
      }).subscribe(async ({ added, removed }) => {
        for (const { name } of removed) {
          removeCodeReuseFiles(name);
        }
        const newPackagesWithFiles = await fetchFiles(
          added,
          editorAPI,
          translate,
        );
        for (const { name, files } of newPackagesWithFiles) {
          loadCodeReuseFiles({ name, files });
          addCodeReuseFilesToFS(name, files);
        }
      });
    }
  }

  function registerToCodeReuseEvents() {
    if (experimentUtils.isShowTestVersion()) {
      duplexerAPI.subscribeToUserChannel(
        {
          appDefId: VELO_PACKAGE_REGISTRY_APP_DEF_ID,
          eventName: EVENT_NAME,
        },
        (payload: any) => refreshPackage(payload.packageCodeName),
      );
    }
  }

  function addCodeReuseFilesToFS(name: AnyFixMe, files: CodeReuseFiles[]) {
    const state = getStore().getState();
    const available = codeReusePkgsSelectors.available.all(state);

    const pkgIsPublic = available.some((pkg) => pkg.name === name);
    const readmeFile = files.find((file) => file.path === 'README.md');
    const filesToLoad = pkgIsPublic ? files : readmeFile ? [readmeFile] : [];

    dispatch(
      codeReuseFsAddRoot({
        name,
        files: toCodeReuseTree(name, filesToLoad, translate),
      }),
    );
  }

  async function refreshPackage(pkgName: string) {
    const currentInstalled = getInstalledCodeReusePkg(pkgName);

    if (currentInstalled?.version !== TEST_VERSION_NAME) {
      return;
    }

    const state = getStore().getState();
    await refreshAvailableCodeReusePkgs();
    const available = getAvailableCodeReusePkg({ name: pkgName, state });
    const versions = await getCodeReusePkgVersions(pkgName);

    if (available?.testVersion !== versions.testVersion?.version) {
      await internalInstallCodeReusePkgByVersion({
        name: pkgName,
        version: TEST_VERSION_NAME,
        fromAutoUpdate: true,
      });
    }
  }

  async function refreshAvailableCodeReusePkgs() {
    packagesCacheFactory().clear();
    await loadAvailablecodeReusePackages();
  }

  async function loadAvailablecodeReusePackages() {
    const { userPkgs, pkgsByOthersMetadata } =
      await fetchAvailableCodeReusePkgs(editorAPI);
    dispatch(
      packageViewActions.setAvailableCodeReusePkgs({
        userPkgs,
        pkgsByOthersMetadata,
      }),
    );
  }

  async function cacheAllNpmReadme(npmPkgs: InstalledNpmPkg[]) {
    await Promise.all(
      npmPkgs.map(async (npm) => {
        await cacheNpmReadme(npm.name);
      }),
    );
    dispatch(
      treeSectionActions.setTreeSectionLoaded({
        sectionName: STATIC_SECTIONS.NPM_README,
      }),
    );
  }

  async function cacheNpmReadme(name: string) {
    const fileId = getNpmReadmePath(name);
    if (!codeStateReader.getFileModelId(getStore().getState(), fileId)) {
      const content = await getPackageReadme(name);
      cacheFile(fileId, content);
    }
  }

  async function removeNpmReadmeFromCache(name: string) {
    const modelId = codeStateReader.getFileModelId(getStore().getState(), name);
    if (modelId) {
      return modelsAPI.remove({ modelId });
    }
  }

  // async function getInitialJsonPackage(): Promise<string> {

  function getPkgFileContent(fileId: string): string | null {
    const state = getStore().getState();
    const modelId = codeStateReader.getFileModelId(state, fileId);

    if (!modelId) {
      return null;
    }

    const file =
      modelsAPI.get({ modelId }) || editorAPI.wixCode.models.get({ modelId });
    return file.getValue();
  }
  const handleNewReadme = async (name: string) => {
    dispatch(
      treeSectionActions.setTreeSectionLoading({
        sectionName: STATIC_SECTIONS.NPM_README,
      }),
    );
    await cacheNpmReadme(name);

    dispatch(
      treeSectionActions.setTreeSectionLoaded({
        sectionName: STATIC_SECTIONS.NPM_README,
      }),
    );
  };

  const isLatestVersion = (
    version: string | undefined,
    packageInfo: NpmPackageInfo,
  ) => {
    return !version ? true : version === packageInfo._tags.latest;
  };

  async function installNpmPackage(name: string, version?: string) {
    const pkg = getAvailableNpmPkg(name);
    if (experimentUtils.isAnyNpmFirstPhase()) {
      try {
        const pkgVersion = version || pkg._version.version;
        version
          ? dispatch(installVersionStart(name, version))
          : dispatch(installStart(name));
        const jobId = await packagesAPIService.installNpmPackage(
          name,
          pkgVersion,
        );
        if (jobId) {
          try {
            packagesAPI.internalSubscribeToDependenciesChanged(
              name,
              jobId,
              async (
                payload?: wixCodeLifecycleDuplexer.DependenciesChangedEvent,
              ) => {
                if (!payload || payload.success) {
                  dispatch(install(name, pkgVersion));
                  await dispatch(load());
                  await handleNewReadme(name);
                } else {
                  throw new Error('Installation Failed');
                }
              },
            );
          } catch (error: any) {
            throw error;
          }
        }
      } catch (error) {
        editorAPI.bi.event(bi.events.PKG_INSTALL_FAILED, {
          item_name: name,
          item_type: 'NPM',
          is_latest_version: isLatestVersion(version, pkg),
        });
        dispatch(installDone(name));
        openErrorModal(panelsAPI, translate);
      }
    } else {
      await dispatch(installAndSave(name, pkg._version.version));
      await dispatch(load());
      handleNewReadme(name);
    }
  }

  async function uninstallNpmPackage(name: string) {
    dispatch(installStart(name));
    packagesBiSender.sendClickedUninstallNpmPkgBi(name);
    if (experimentUtils.isAnyNpmFirstPhase()) {
      try {
        const jobId = await packagesAPIService.uninstallNpmPackage(name);
        if (jobId) {
          packagesAPI.internalSubscribeToDependenciesChanged(
            name,
            jobId,
            async (
              payload?: wixCodeLifecycleDuplexer.DependenciesChangedEvent,
            ) => {
              if (!payload || payload.success) {
                dispatch(packageViewActions.packageJsonModified());
                await dispatch(load());
                removeNpmReadmeFromCache(name);
                closeTab(name, getNpmReadmePath(name), getStore().getState());
                dispatch(installDone(name));
              } else {
                throw new Error(`Error uninstalling ${name}. Please try again`);
              }
            },
          );
        }
      } catch (error) {
        dispatch(installDone(name));
        openErrorModal(panelsAPI, translate);
      }
    } else {
      return editorAPI.wixCode.codePackages
        .uninstallNpmPkg(name)
        .then(async () => {
          dispatch(packageViewActions.packageJsonModified());
          await dispatch(load());
          removeNpmReadmeFromCache(name);
          closeTab(name, getNpmReadmePath(name), getStore().getState());
          dispatch(installDone(name));
        })
        .catch((e: AnyFixMe) => {
          editorAPI.panelManager.openPanel(
            'wixCode.panels.fileSystemOperationErrorPanel',
            { error: `Error uninstalling ${name}. Please try again.` },
          );
          console.error({ e });
        });
    }
  }

  function updateNpmPackage(name: string) {
    return installNpmPackage(name);
  }

  async function installCodeReusePkgVersion({
    pkgInstalling,
    versionOverride,
  }: {
    pkgInstalling: AvailableCodeReusePkg;
    versionOverride?: string;
  }) {
    const { availableVersionType } = pkgInstalling;
    if (
      versionOverride === TEST_VERSION_NAME ||
      availableVersionType === PkgAvailableVersionType.TEST_ONLY
    ) {
      const installFunc = () =>
        installCodeReusePkgVersionInternal({
          pkgInstalling,
          versionOverride: TEST_VERSION_NAME,
        });
      openInstallTestVersionPanel({ editorAPI, translate, installFunc });
    } else {
      installCodeReusePkgVersionInternal({ pkgInstalling, versionOverride });
    }
  }

  async function installCodeReusePkgVersionInternal({
    pkgInstalling,
    versionOverride,
  }: {
    pkgInstalling: AvailableCodeReusePkg;
    versionOverride?: string;
  }) {
    const pkgScope = getPkgScope(pkgInstalling.name);
    const isTestVersion = versionOverride === TEST_VERSION_NAME;
    pkgInstalling = {
      ...pkgInstalling,
      version: versionOverride || pkgInstalling.version,
    };

    // We always keep the available package with the latest version
    if (isTestVersion || !versionOverride) {
      dispatch(
        packageViewActions.setAvailableCodeReusePkgStatus({
          pkg: pkgInstalling,
          status: 'INSTALLING',
        }),
      );
    } else {
      dispatch(
        packageViewActions.addCodeReusePkg({
          ...pkgInstalling,
          status: 'INSTALLING',
        }),
      );
    }

    try {
      const version = versionOverride || pkgInstalling.version;
      const { id } = pkgInstalling;
      await callInstallCodeReuseViaApi({ id, version });
      if (
        experimentUtils.isInstallCodeReuseViaPlatform() &&
        !isReusePackageByVelo(pkgInstalling.name)
      ) {
        await platformInstaller.installCodeReuse({
          id,
          version,
        });
      }
    } catch (err) {
      const error = `Error installing ${pkgInstalling.name}: ${err}`;
      console.error(error);
      editorAPI.panelManager.openPanel(
        'wixCode.panels.fileSystemOperationErrorPanel',
        { error },
      );
      if (isTestVersion || !versionOverride) {
        dispatch(
          packageViewActions.setAvailableCodeReusePkgStatus({
            pkg: pkgInstalling,
            status: 'NOT_INSTALLED',
          }),
        );
      } else {
        dispatch(packageViewActions.removeCodeReusePkg(pkgInstalling));
      }
      return;
    }
    if (isTestVersion || !versionOverride) {
      dispatch(packageViewActions.addCodeReusePkg(pkgInstalling));
    }
    dispatch(packageViewActions.packageJsonModified());
    try {
      await editorAPI.wixCode.fileSystem.flush();
      const files = await fetchCodeReuseFiles(
        pkgInstalling,
        editorAPI,
        translate,
      );

      loadCodeReuseFiles({ files, name: pkgInstalling.name });
      addCodeReuseFilesToFS(pkgInstalling.name, files);

      dispatch(
        treeSectionActions.setTreeSectionLoaded({ sectionName: pkgScope }),
      );
      await handleConfigFileInstall(pkgInstalling.name, files);
      viewReadme(pkgInstalling.name, false);
    } catch (error) {
      editorAPI.panelManager.openPanel(
        'wixCode.panels.fileSystemOperationErrorPanel',
        { error: `Error installing ${pkgInstalling.name}. Please try again.` },
      );
      console.error({ error });
    }
    if (isTestVersion || !versionOverride) {
      dispatch(
        packageViewActions.setAvailableCodeReusePkgStatus({
          pkg: pkgInstalling,
          status: 'INSTALLED',
        }),
      );
    } else {
      dispatch(
        packageViewActions.updateInstalledCodeReusePkg({
          oldPkg: pkgInstalling,
          newPkg: { ...pkgInstalling, status: 'INSTALLED' },
        }),
      );
    }
    dispatch(addJustInstalledPkg(pkgInstalling.name));
  }

  async function callInstallCodeReuseViaApi({
    id,
    version,
  }: {
    id: string;
    version: string;
  }) {
    if (experimentUtils.isAnyNpmFirstPhase()) {
      return packagesAPIService.installVeloPackage(id, version);
    }
    return editorAPI.wixCode.codePackages.installCodeReusePkg(id, version);
  }

  async function installCodeReusePkgByName(name: string) {
    const pkgInstalling = getAvailableCodeReusePkg({
      name,
      state: getStore().getState(),
    })!;
    installCodeReusePkgVersion({ pkgInstalling });
  }

  async function handleConfigFileInstall(
    name: string,
    files: CodeReuseFiles[],
  ) {
    const configSrc = files.find((file) => file.path === 'backend/config.json');
    if (configSrc) {
      const [pkgScope, pkgName] = name.split('/');

      const foldersToRefresh = [
        `backend/`,
        `backend/${consts.CONFIG_FOLDER_NAME}`,
        `backend/${consts.CONFIG_FOLDER_NAME}/${pkgScope}`,
      ];
      await Promise.all(
        foldersToRefresh.map(async (folder) =>
          editorAPI.wixCode.fileSystem.refreshFolder(folder),
        ),
      );
      await editorAPI.wixCode.fileSystem.refreshFile(
        `backend/${consts.CONFIG_FOLDER_NAME}/${pkgScope}/${pkgName}-config.json`,
      );
    }
  }

  function openFile(file: string, pinTab: boolean) {
    setFileContext(editorAPI, file);
    editorAPI.developerMode.ui.idePane.unMinimize();
    if (pinTab) {
      dispatch(ideTabsActions.pinTab({ tabId: file }));
    }
  }

  async function uninstallBlockApp(appData: PrivateAppData) {
    if (!appData || !appData.appDefinitionName || !appData.appDefinitionId) {
      return;
    }
    const { name, id, version } = _.get(appData, 'codeComponent');
    packagesBiSender.sendClickedUninstallPrivateAppBi({
      name,
      id,
      version,
      type: isMyScope(name) ? 'my_packages' : 'built_by_others',
    });

    try {
      await callUnInstallCodeReuseViaApi(id);
      if (
        appData &&
        experimentUtils.isInstallCodeReuseViaPlatform() &&
        !isReusePackageByVelo(name)
      ) {
        await platformInstaller.unInstallCodeReuse(appData);
      }
      removeCodeReuseFiles(name);
    } catch (e) {
      editorAPI.panelManager.openPanel(
        'wixCode.panels.fileSystemOperationErrorPanel',
        { error: `Error uninstalling ${name}. Please try again.` },
      );
      console.error({ e });
    }
  }

  async function handleUninstallBlockApp(
    appData: PrivateAppData,
    hideWarningPanelOnEditorX = false,
  ) {
    const onConfirm = async () => {
      uninstallBlockApp(appData);
    };
    const { name } = _.get(appData, 'codeComponent');

    const type = 'Private';
    checkOpenDeleteApp(name, hideWarningPanelOnEditorX, onConfirm, type);
  }

  async function uninstallCodeReusePkg(name: string) {
    const pckToRemove = codeReusePkgsSelectors.installed
      .all(getStore().getState())
      .find((pkg) => pkg.name === name)!; /* strictNullChecks */

    packagesBiSender.sendClickedUninstallCodePkgBi(
      getInstalledCodeReusePkg(name) as InstalledCodeReusePkg,
    );

    dispatch(
      packageViewActions.updateInstalledCodeReusePkg({
        oldPkg: pckToRemove,
        newPkg: { ...pckToRemove, status: 'UPDATING' },
      }),
    );
    const appData = editorAPI.platform.getAppDataByAppDefId(pckToRemove.id);

    try {
      await callUnInstallCodeReuseViaApi(pckToRemove.id);
      if (
        appData &&
        experimentUtils.isInstallCodeReuseViaPlatform() &&
        !isReusePackageByVelo(pckToRemove.name)
      ) {
        await platformInstaller.unInstallCodeReuse(appData);
      }
      removeCodeReuseFiles(pckToRemove.name);
      dispatch(packageViewActions.removeCodeReusePkg(pckToRemove));
      dispatch(codeReuseFsRemoveRoot(pckToRemove.name));
      dispatch(packageViewActions.packageJsonModified());
    } catch (e) {
      editorAPI.panelManager.openPanel(
        'wixCode.panels.fileSystemOperationErrorPanel',
        { error: `Error uninstalling ${name}. Please try again.` },
      );
      console.error({ e });
    }
  }

  async function callUnInstallCodeReuseViaApi(id: string) {
    if (experimentUtils.isAnyNpmFirstPhase()) {
      return packagesAPIService.uninstallVeloPackage(id);
    }
    return editorAPI.wixCode.codePackages.uninstallCodeReusePkg(id);
  }

  async function changeCodeReusePkgVersion(name: string, version?: string) {
    const installFunc = () =>
      internalInstallCodeReusePkgByVersion({ name, version });
    if (version === TEST_VERSION_NAME) {
      openInstallTestVersionPanel({ editorAPI, translate, installFunc });
    } else {
      installFunc();
    }
  }

  async function internalInstallCodeReusePkgByVersion({
    name,
    version,
    fromAutoUpdate = false,
  }: {
    name: string;
    version?: string;
    fromAutoUpdate?: boolean;
  }) {
    const state = getStore().getState();
    const oldPkg = codeReusePkgsSelectors.installed
      .all(state)
      .find((pkg) => pkg.name === name)!;

    let pkgInstalling = codeReusePkgsSelectors.available
      .all(state)
      .find((pkg) => pkg.name === name)!;

    if (version) {
      pkgInstalling = { ...pkgInstalling, version, status: 'INSTALLING' };
      dispatch(
        packageViewActions.updateInstalledCodeReusePkg({
          oldPkg,
          newPkg: pkgInstalling,
        }),
      );
    } else {
      dispatch(
        packageViewActions.setAvailableCodeReusePkgStatus({
          pkg: pkgInstalling,
          status: 'UPDATING',
        }),
      );
    }
    await callInstallCodeReuseViaApi({
      id: pkgInstalling.id,
      version: pkgInstalling.version,
    });
    if (
      experimentUtils.isInstallCodeReuseViaPlatform() &&
      !isReusePackageByVelo(pkgInstalling.name)
    ) {
      await platformInstaller.installCodeReuse({
        id: pkgInstalling.id,
        version: pkgInstalling.version,
      });
    }
    dispatch(packageViewActions.packageJsonModified());
    await editorAPI.wixCode.fileSystem.flush();
    const files = await fetchCodeReuseFiles(
      pkgInstalling,
      editorAPI,
      translate,
    );
    if (version) {
      pkgInstalling = { ...pkgInstalling, status: 'INSTALLED' };
    } else {
      dispatch(
        packageViewActions.setAvailableCodeReusePkgStatus({
          pkg: pkgInstalling,
          status: 'UPDATED',
        }),
      );
    }
    dispatch(
      packageViewActions.updateInstalledCodeReusePkg({
        oldPkg,
        newPkg: { ...pkgInstalling, fromAutoUpdate },
      }),
    );
    dispatch(codeReuseFsRemoveRoot(oldPkg.id));
    removeCodeReuseFiles(pkgInstalling.name);
    loadCodeReuseFiles({ name: pkgInstalling.name, files });
    await handleConfigFileInstall(pkgInstalling.name, files);
    addCodeReuseFilesToFS(pkgInstalling.name, files);
    dispatch(addJustInstalledPkg(pkgInstalling.name));
    if (!pkgInstalling.version.startsWith('^')) {
      dispatch(selectPackageVersion(pkgInstalling.version));
    }
  }

  function getCodeReusePkgInfo(name: string): PkgInfo | undefined {
    const state = getStore().getState();
    return (
      getAvailableCodeReusePkg({ name, state }) ||
      codeReusePkgsSelectors.byOthersMetadata(state).byName[name] ||
      getInstalledCodeReusePkg(name) ||
      undefined
    );
  }

  function getInstalledPkg(name: string) {
    return isReusePackage(name)
      ? getInstalledCodeReusePkg(name)! /* strictNullChecks */
      : getInstalledNpmPkg(name);
  }

  function getInstalledNpmPkg(name: string): InstalledNpmPkg {
    const state = getStore().getState();
    const installed = getInstalledNpmPkgs(state).find(
      (pkg) => pkg.name === name,
    );
    return installed as PkgInfo;
  }

  function getInstalledCodeReusePkg(name: string) {
    const state = getStore().getState();
    const installed = codeReusePkgsSelectors.installed
      .all(state)
      .find((pkg) => pkg.name === name);
    return installed as PkgInfo;
  }

  function isPackageVersionInstalled(name: string, version: string) {
    const state = getStore().getState();
    const installedPkg = codeReusePkgsSelectors.installed
      .all(state)
      .some((pkg) => pkg.name === name && isSameVersion(pkg.version, version));
    return installedPkg ? true : false;
  }

  function getAvailableCodeReusePkg({ name, state }: AnyFixMe) {
    return codeReusePkgsSelectors.available
      .all(state)
      .find((pkg) => pkg.name === name);
  }

  function getAvailableNpmPkg(name: string): NpmPackageInfo {
    const state = getStore().getState();
    const defaultPackages = getDefaultNpmPackages(state);

    const packageFromDefaults = defaultPackages?.find(
      (pkg) => pkg.name === name,
    );
    if (packageFromDefaults) {
      return packageFromDefaults;
    }

    const cachedResult = getCachedNpmPackageData(name);
    if (cachedResult) {
      return cachedResult;
    }

    const searchResult = getIndexSearchModules(state);
    return searchResult.find((pkg: AnyFixMe) => pkg.name === name)!;
  }

  function getAvailablePkg(name: string): PkgInfo {
    return isReusePackage(name)
      ? getCodeReusePkgInfo(name)! /* strictNullChecks */
      : getAvailableNpmPkg(name);
  }

  async function getCodeReuseReadme(
    name: string,
    version?: string | null,
  ): Promise<string> {
    const state = getStore().getState();
    const installedPkgs = codeReusePkgsSelectors.installed.all(state);
    const installedPkg = installedPkgs.find((pkg) => pkg.name === name);
    const available = codeReusePkgsSelectors.available
      .all(state)
      .find((pkg) => pkg.name === name)!; /* strictNullChecks */
    const shouldUseLocal =
      !!installedPkg && (!version || installedPkg.version === version);

    if (shouldUseLocal) {
      return getPkgFileContent(
        `${name}/${consts.README_FILE_NAME}`,
      )!; /* strictNullChecks */
    } else {
      return fetchCodeReuseReadme(
        { id: available.id, version: version || available.version },
        editorAPI,
      );
    }
  }

  async function getCodeReusePkgVersions(
    name: string,
  ): Promise<CodeReusePkgVersions> {
    return packagesCacheFactory().getCodeReusePkgVersions(
      name,
      fethcCodeReusePkgVersions,
    );
  }

  async function fethcCodeReusePkgVersions(
    name: string,
  ): Promise<CodeReusePkgVersions> {
    const state = getStore().getState();
    const available = codeReusePkgsSelectors.available
      .all(state)
      .find((pkg) => pkg.name === name);
    if (!available) {
      return { publicVersions: [] };
    }
    return fetchCodeReusePkgVersions(available, editorAPI);
  }

  function getNpmPackageReadmePreamble(name: string): string {
    const state = getStore().getState();
    const packagesDescriptors = getDefaultNpmPackagesDescriptors(state);

    const descriptor = packagesDescriptors?.[name];
    if (descriptor) {
      return preambleMarkdown({ ...descriptor, translate, name });
    }
    return '';
  }
  const getReadme = (npmModules: AnyFixMe, packageName: string) => {
    const npmPackages =
      npmModules.searchKeyword === ''
        ? npmModules.defaultNpmPackages.pkgInfo
        : npmModules.indexSearchPackages;
    const npmPackageDetails = npmPackages.find(
      (pkg: NpmPackageInfo) => pkg.name === packageName,
    );
    return npmPackageDetails?.readme;
  };

  async function getNpmPackageReadme(name: string): Promise<string> {
    const state = getStore().getState();
    const npmModulesSelector = getNpmModules(state);

    const preamble = getNpmPackageReadmePreamble(name);
    const readme = npmModulesSelector.defaultNpmPackages
      ? getReadme(npmModulesSelector, name)
      : await fetchReadme(name, editorAPI);

    return preamble + readme;
  }

  async function getPackageReadme(
    name: string,
    version?: string | null,
  ): Promise<string> {
    if (isReusePackage(name)) {
      return getCodeReuseReadme(name, version);
    } else {
      try {
        return getNpmPackageReadme(name);
      } catch {
        return '';
      }
    }
  }

  function getDependency(name: string): PkgDependency {
    const state = getStore().getState();
    return isReusePackage(name)
      ? codeReusePkgsSelectors.installed
          .all(state)
          .find((pkg) => pkg.name === name)! /* strictNullChecks */
      : getInstalledNpmPkgs(state).find(
          (pkg) => pkg.name === name,
        )!; /* strictNullChecks */
  }

  async function installPkg(name: string, version?: string) {
    return isReusePackage(name)
      ? installCodeReusePkgByName(name)
      : installNpmPackage(name, version);
  }
  function checkOpenDeleteApp(
    name: string,
    hideWarningPanelOnEditorX = false,
    onConfirm: () => void,
    type: DELETE_FILE_TYPE,
  ) {
    const isOnEditorX = () => window?.commonConfig?.brand === 'editorx';
    const state = getStore().getState();
    const tab = getCurrentTab(state);
    if (getSelectedPackageForTab(state, tab) === name) {
      selectPackage('', tab);
    }
    if (hideWarningPanelOnEditorX && isOnEditorX()) {
      onConfirm();
    } else {
      openDeleteFileSystemItemWarningPanel(
        editorAPI,
        {
          panelName: 'deleteFileSystemItemWarningPanel',
          fileName: name,
          fileType: type,
          onConfirm,
          onCancel: () =>
            editorAPI.panelManager.closePanelByName(
              'deleteFileSystemItemWarningPanel',
            ),
        },
        true,
      );
    }
  }
  function uninstallPkg(name: string, hideWarningPanelOnEditorX = false) {
    const onConfirm = () => {
      isReusePackage(name)
        ? uninstallCodeReusePkg(name)
        : uninstallNpmPackage(name);
    };
    const type = isReusePackage(name) ? 'Velo' : 'Npm';
    checkOpenDeleteApp(name, hideWarningPanelOnEditorX, onConfirm, type);
  }

  async function updatePkg(name: string) {
    isReusePackage(name)
      ? changeCodeReusePkgVersion(name)
      : updateNpmPackage(name);
  }

  function viewPkg(name: string) {
    editorAPI.panelManager.closePanelByName('wixCode.panels.PackagesModal');
    viewReadme(name);
  }

  function openSubmitRequestDialog(params: AnyFixMe) {
    dispatch(() => {});
    dispatch(openSubmitRequest(params));
  }

  function viewReadme(name: string, pinTab: boolean = true) {
    const file = isReusePackage(name)
      ? `${name}/README.md`
      : getNpmReadmePath(name);

    dispatch(
      stateManagement.editorPlugins.wixCodeIDE.actions.showIDEWithDsRead({
        dsRead: editorAPI.dsRead,
      }),
    );

    openFile(file, pinTab);

    editorAPI.developerMode.ui.idePane.unMinimize();
    dispatch(fileSystemActions.expandParents(file) as any);
  }

  function editCodePkg(appDefId: string) {
    const isOnEditorX = () => window?.commonConfig?.brand === 'editorx';
    const isStudio = () => (window as any)?.commonConfig?.host === 'STUDIO';
    const editorName = isStudio()
      ? '_wixStudio'
      : isOnEditorX()
      ? '_editorx'
      : '_wixEditor';
    window.open(
      `/_api/wix-blocks-service/web/v1/apps/${appDefId}/open?http_referrer=LeftTree${editorName}`,
    );
  }

  return {
    getAvailablePkg,
    getDependency,
    getPackageReadme,
    getInstalled,
    installPkg,
    uninstallPkg,
    updatePkg,
    viewPkg,
    preloadCodeReuseFiles,
    getPkgFileContent,
    viewReadme,
    openSubmitRequest: openSubmitRequestDialog,
    refreshAvailableCodeReusePkgs,
    refreshPackage,
    editCodePkg,
    getCodeReusePkgVersions,
    isPackageVersionInstalled,
    getInstalledPkg,
    changeCodeReusePkgVersion,
    installCodeReusePkgVersion,
    getMyScopeName,
    getCodeReusePkgInfo,
    registerToCodeReuseEvents,
    requestPackageWithBi,
    requestPackage,
    getVersionsForRequestPackage,
    getInstalledVeloPkgs,
    loadApp,
    handleUninstallBlockApp,
  };
}
