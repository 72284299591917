import * as _ from 'lodash';
import { getDashboardApps } from '@wix/app-market-api-js';
import storeManager from '@/infra/redux-state/store/storeManager';
import {
  getAppData,
  isAppDataLoaded,
  isAppDataLoading,
} from './appData/appDataSelectors';
import {
  setAppsData,
  setAppsDataLoading,
  setAppsDataLoaded,
} from './appData/appDataActions';
import { codeReusePkgsSelectors } from './packagesView/packagesViewSelectors';

export interface AppData {
  appDefinitionId: string;
  name: string;
  hasDashboard: boolean;
  teaser: string;
}

export interface AppDataService {
  loadAppsData: () => Promise<void>;
  getAppDataById: (appDefinitionId: string) => AppData;
}

const APP_DATA_FETCH_THROTTLE = 5000;

function getAvailableAppIds(): string[] {
  const store = storeManager.getStore();
  const state = store.getState();
  const availableCodeReusePkgs = codeReusePkgsSelectors.available.all(state);
  return _.uniq(
    _.flatMap(availableCodeReusePkgs, (pkg) => pkg.appDependencies || []),
  );
}

export function appDataServiceCreator(): AppDataService {
  async function loadAppsData(): Promise<void> {
    const store = storeManager.getStore();
    const state = store.getState();
    if (!isAppDataLoaded(state) && !isAppDataLoading(state)) {
      store.dispatch(setAppsDataLoading(true));
      try {
        store.dispatch(
          setAppsData(await getDashboardApps(getAvailableAppIds())),
        );
        store.dispatch(setAppsDataLoaded(true));
        store.dispatch(setAppsDataLoading(false));
      } catch (err) {
        console.error(err);
        setTimeout(
          () => store.dispatch(setAppsDataLoading(false)),
          APP_DATA_FETCH_THROTTLE,
        );
      }
    }
  }

  function getAppDataById(appDefinitionId: string): AppData {
    const store = storeManager.getStore();
    const state = store.getState();
    return getAppData(state, appDefinitionId);
  }

  return {
    loadAppsData,
    getAppDataById,
  };
}
