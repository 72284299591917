// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gmxwE2{color:#868aa5}.AA_W9h{border-top:1px solid #dfe5eb;border-bottom:1px solid #dfe5eb}.birdO3{padding:28px 24px;display:flex;flex-direction:column}.Sp6rHN{width:200px}.jfZpf5{height:100%;overflow:scroll}.wnQgHu{margin-left:12px}.fn7U30{margin-left:5px}.Sx7AZg{margin:0 auto 15px 10px}.Usn18j{margin-left:10px;width:auto}.RfWYqF{position:absolute;bottom:16px;right:22px}.XigzHq{margin-top:12px;margin-bottom:15px}.Jm54XC{max-width:370px !important}.B5s2Z_{text-align:center}.A7piQp{height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"packageReview": "gmxwE2",
	"titleContainer": "AA_W9h",
	"descriptionContainer": "birdO3",
	"versionInputContainer": "Sp6rHN",
	"requestPackageContainer": "jfZpf5",
	"versionLabel": "wnQgHu",
	"versionTooltip": "fn7U30",
	"submitButton": "Sx7AZg",
	"requestSubmissionInfo": "Usn18j",
	"versionValidationErrorSvg": "RfWYqF",
	"submitedTitle": "XigzHq",
	"submitedText": "Jm54XC",
	"submitedMessage": "B5s2Z_",
	"submitSuccessPanel": "A7piQp"
};
export default ___CSS_LOADER_EXPORT___;
