import './whatsNew.global.scss';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import getFeedbackOpenerCreator from '@/sidebar/helpTab/feedback';
import { Preloader } from '@wix/wix-base-ui';
import { captureError } from '@/infra/monitoring';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import { fedops } from '@wix/wix-code-common';
import { useEditorLegacyAPIs } from '@wix/wix-code-common-components';

interface WhatsNewProps {
  editorAPI: EditorAPI;
  siteUrl: string;
  translate: any;
  unselectEmbeddedTab: () => void;
}

function appendLanguageQueryParamToUrl(baseUrl: string, util: any): string {
  const lang = util.editorModel?.languageCode?.toLowerCase() || 'en';
  try {
    const urlObj = new URL(baseUrl);
    urlObj.searchParams.append('lang', lang);
    return urlObj.toString();
  } catch (e) {
    captureError(e);
    return baseUrl;
  }
}

export const WhatsNew: React.FC<WhatsNewProps> = ({
  editorAPI,
  siteUrl,
  translate,
  unselectEmbeddedTab,
}) => {
  const [loaded, setLoaded] = useState(false);
  const {
    legacyDependenciesAPI: { util },
  } = useEditorLegacyAPIs();
  const url = appendLanguageQueryParamToUrl(siteUrl, util);

  useEffect(() => {
    fedops.getLogger().interactionStarted(fedops.interactions.openEmbeddedTab, {
      customParams: { url },
    });

    const getFeedbackOpener = getFeedbackOpenerCreator(editorAPI, {
      initiator: 'whats-new',
      origin: 'whats-new',
    });

    const handler = ({ data }: any) => {
      if (_.has(data, 'whatIsNew') || _.has(data, 'embeddedTabMessage')) {
        const { value, type } = data.whatIsNew || data.embeddedTabMessage;
        switch (type) {
          case 'page-loaded': {
            fedops
              .getLogger()
              .interactionEnded(fedops.interactions.openEmbeddedTab, {
                customParams: { url },
              });
            break;
          }
          case 'bi': {
            const { src, evid, params, endpoint } = value;
            editorAPI.bi.event(
              { src, evid, endpoint },
              { src, evid, ...params },
            );
            break;
          }
          case 'open-feedback-form': {
            getFeedbackOpener.preload();
            getFeedbackOpener.showQuestion(translate, 'whats-new');
            break;
          }
          case 'unselect-tab': {
            unselectEmbeddedTab();
            break;
          }
          default: {
            console.warn(`Unknown message type from EmbeddedTab: ${type}`);
          }
        }
      }
    };
    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
      getFeedbackOpener.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!loaded && (
        <div className="loading-overlay">
          <Preloader size="medium" />
        </div>
      )}
      <iframe
        data-hook="whats-new-ide-tab-iframe"
        title="What's New?"
        style={{ width: '100%' }}
        src={url}
        onLoad={() => setLoaded(true)}
      />
    </>
  );
};
