import React from 'react';
import { RichText } from '@wix/wix-base-ui';
import { TFunction } from '@wix/wix-i18n-config';

type PageElementsNoEditStateProps = {
  t: TFunction;
};

const PageElementsNoEditState = ({ t }: PageElementsNoEditStateProps) => (
  <div data-aid="no-edit-state" className="empty-state">
    <div style={{ marginTop: '12px', width: '100%' }} />
    <RichText automationId="description" className="text-container">
      {t('WixCode_PropertiesPanel_empty_state_no_edit')}
    </RichText>
  </div>
);

export default PageElementsNoEditState;
