// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".G6xmvx{height:100%;display:flex;flex-direction:column}.mlD9JZ{display:flex;width:100%;gap:16px}.xIje2s{width:50%;padding:0;margin-left:-23px;margin-top:-16px;width:283px}.VUkG_Y{width:50%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "G6xmvx",
	"repositoryInfoContainer": "mlD9JZ",
	"gitAccountDropdown": "xIje2s",
	"gitRepositoryName": "VUkG_Y"
};
export default ___CSS_LOADER_EXPORT___;
