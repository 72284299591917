// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oiJxjx{color:#2d3034}.oiJxjx svg path{fill:#2d3034}.oiJxjx.control-button{background-color:rgba(0,0,0,0)}.oiJxjx.control-button .button-content{color:#2d3034}.oiJxjx.control-button{border-left:1px solid #dfe5eb;border-radius:0;height:100%;padding:0 8px}.oiJxjx.control-button:active{background-color:rgba(0,0,0,0)}.oiJxjx.control-button:focus{background-color:rgba(0,0,0,0)}.oiJxjx:hover svg path{fill:#116dff}.oiJxjx:hover.control-button{background-color:rgba(0,0,0,0)}.oiJxjx:hover.control-button .button-content{color:#116dff}.oiJxjx:disabled svg path{fill:#acafc4}.oiJxjx:disabled.control-button{background-color:rgba(0,0,0,0)}.oiJxjx:disabled.control-button .button-content{color:#acafc4}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"format-button": "oiJxjx",
	"formatButton": "oiJxjx"
};
export default ___CSS_LOADER_EXPORT___;
