import * as React from 'react';
import cx from 'classnames';
import { TextLabel, TextButton } from '@wix/wix-base-ui';
import s from './ParagraphEmptyState.scss';

export interface ParagraphEmptyStateProps {
  ctaText?: string;
  dataHook: string;
  description?: string;
  linkText?: string;
  onLinkClick?: () => void;
  onCtaClick?: () => void;
  disabled?: boolean;
}

export const ParagraphEmptyState: React.FC<ParagraphEmptyStateProps> = ({
  ctaText,
  dataHook,
  description,
  linkText,
  onLinkClick,
  onCtaClick,
  disabled,
}) => {
  return (
    <div className={s.container} data-hook={dataHook}>
      <div className={s.textWrapper}>
        {description ? (
          <>
            <TextLabel
              className={s.description}
              dataHook="description"
              enableEllipsis={false}
              type="T20"
              shouldTranslate={false}
              value={description}
            />
            &nbsp;
          </>
        ) : null}
        {linkText && onLinkClick ? (
          <TextButton
            className={s.linkButton}
            onClick={() => onLinkClick()}
            underline="always"
            dataHook="link-button"
            shouldTranslate={false}
          >
            {linkText}
          </TextButton>
        ) : null}
      </div>
      {ctaText && onCtaClick ? (
        <TextButton
          className={cx(s.ctaButton, s.cta)}
          onClick={() => onCtaClick()}
          dataHook="cta-button"
          disabled={disabled}
          shouldTranslate={false}
        >
          {ctaText}
        </TextButton>
      ) : null}
    </div>
  );
};
