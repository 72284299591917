import React from 'react';
import { FileTreeRoot } from './FileTreeRoot';
import { FILE_TREE_QUICK_ACTIONS } from './FilesTreeContextMenu';
import {
  ReadOnlyModeContext,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import { useTranslate } from '@/infra/i18n/useTranslate';

interface BackendFileTreeProps {
  hiddenActions?: FILE_TREE_QUICK_ACTIONS[];
  hiddenFiles?: string[];
  isLast: boolean;
  translations?: { [key: string]: string };
}

export const BackendFileTree: React.FC<BackendFileTreeProps> = ({
  hiddenActions,
  hiddenFiles,
  translations,
}) => {
  const { editorAPI } = useEditorLegacyAPIs();
  const { t } = useTranslate();
  const { readOnlyMode } = React.useContext(ReadOnlyModeContext);
  const backEndId = editorAPI.wixCode.fileSystem.getRoots().backend.location;
  const excludedFiles = [
    'events.js',
    'jobs.config',
    'data.js',
    'http-functions.js',
  ];

  return (
    <FileTreeRoot
      id={backEndId}
      labelOverride={t('Left_Tree_Components_Category_CodeFiles_Backend')}
      isBackend={true}
      hiddenActions={hiddenActions}
      hiddenFiles={hiddenFiles}
      excludedFiles={excludedFiles}
      translations={translations}
      readOnlyMode={!!readOnlyMode.sidePanel?.publicAndBackend}
    />
  );
};
