const actions = {
  MINIMIZE: 'minimize.container',
  REVERT_MINIMIZE: 'revert.minimize.container',
  MAXIMIZE: 'maximize.container',
  REVERT_MAXIMIZE: 'revert.maximize.container',
} as const;

type KeysOfActions = keyof typeof actions;
export type SizingButtonAction = (typeof actions)[KeysOfActions];

const actionToBIName: Record<string, string> = {
  [actions.MINIMIZE]: 'minimize',
  [actions.REVERT_MINIMIZE]: 'open',
  [actions.MAXIMIZE]: 'maximize',
  [actions.REVERT_MAXIMIZE]: 'restore',
};

export default {
  actions,
  actionToBIName,
};
