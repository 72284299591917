import { CollectionsEvent, Interceptor } from '@wix/wix-code-plugin-contracts';

export const actionTypes = {
  ADD_INTERCEPTOR: 'add-interceptor',
};

export const addInterceptor = (
  eventName: CollectionsEvent,
  interceptor: Interceptor,
) => ({
  type: actionTypes.ADD_INTERCEPTOR,
  payload: { eventName, interceptor },
});
