import React from 'react';
import {
  ParagraphEmptyState,
  ParagraphEmptyStateProps,
} from '@/commonComponents/emptyState/ParagraphEmptyState';

interface FileTreeParagraphEmptyStateProps
  extends Omit<ParagraphEmptyStateProps, 'disabled'> {
  readonly: boolean;
}

export const FileTreeParagraphEmptyState: React.FC<
  FileTreeParagraphEmptyStateProps
> = (props) => {
  return <ParagraphEmptyState disabled={props.readonly} {...props} />;
};
