import { GithubIntegrationEntryPoint } from '@wix/wix-code-github-integration';
import { SpiPluginEntryPoint } from '@wix/wix-code-package-spi';
import { FilesViewEntryPoint } from '@wix/wix-code-files-view';
import { SiteEntryPoint } from '@/toExtract/site/SiteEntryPoint';
import { ExperimentsEntryPoint } from '@/infra/experiments/ExperimentsEntryPoint';
import { SidebarEntryPoint } from '@/sidebar/SidebarEntryPoint';
import { WixCodeStoreEntryPoint } from '@/infra/redux-state/store/WixCodeStoreEntryPoint';
import { WixCodeAppEntryPoint } from '@/toExtract/wixCodeApp/WixCodeAppEntryPoint';
import { PanelsEntryPoint } from '@/toExtract/panels/PanelsEntryPoint';
import { UserPreferencesEntryPoint } from './editor-packages/UserPreferencesAPI/UserPreferencesEntryPoint';
import { FilesTreeEntryPoint } from '@/sidebar/filesTree/filesTree/FilesTreeEntryPoint';
import { DuplexerEntryPoint } from '@/toExtract/duplexer/DuplexerEntryPoint';
import { WixCodeDuplexerEntryPoint } from '@/toExtract/duplexer/WixCodeDuplexerEntryPoint';
import { DevConsoleEntryPoint } from '@/legacy/core/components/console/DevConsoleEntryPoint';
import { CodeEditorEntryPoint } from '@/codeEditor/CodeEditorEntryPoint';
import { FileSystemEntryPoint } from '@/fileSystem/FileSystemEntryPoint';
import { ServiceTopologyEntryPoint } from '@/infra/ServiceTopologyEntryPoint';
import { ConcurrentEditingEntryPoint } from '@wix/wix-code-concurrent-editing';
import { PagesTabEntryPoint } from '@wix/wix-code-pages-tab';
import { ReadOnlyEntryPoint } from '@wix/wix-code-readonly-mode';
import { BiLoggerEntryPoint } from '@/infra/biLogger/BiLoggerEntryPoint';
import { PlatformAppsEntryPoint } from '@/toExtract/platformApps/PlatformAppsEntryPoint';
import { EmbeddedTabsEntryPoint } from '@/toExtract/embeddedTabs/embeddedTabsEntryPoint';
import { CollectionsEntryPoint } from '@wix/wix-code-collections-plugin';
import { WixCodeEditorApiEntryPoint } from './wixCodeEditorAPI/WixCodeEditorApiEntryPoint';
import { LocalEditorEntryPoint } from '@wix/wix-code-local-editor';
import {
  VSCodeEntryPoint,
  VSCodeButtonContributionEntryPoint,
} from '@wix/wix-code-vscode-online';

import {
  ExternalCollectionsEntryPoint,
  ExternalFilesTreeEntryPoint,
  ExternalPackagesAndAppsEntryPoint,
  ExternalGithubEntryPoint,
  ExternalLocalEditorEntryPoint,
} from '@wix/wix-code-external-apis';
import { BackendDebuggingEntryPoint } from '@/toExtract/backendDebugging/backendDebuggingEntryPoint';
import { PackagesEntryPoint } from '@/toExtract/packages/packagesEntryPoint';
import { PackagesAndAppsEntryPoint } from '@/leftPane/PackagesAndAppsAPI/PackagesAndAppsEntryPoint';
import { EditorContextEntryPoint } from '@/toExtract/editor-context/EditorContextEntryPoint';
import { DevContextEntryPoint } from './editor-packages/DevContextAPI/DevContextEntryPoint';
import { CssEditingEntryPoint } from '@wix/wix-code-css-editing';
import { ExternalComponentsEntryPoint } from '@wix/wix-code-external-components';
import { PropertiesPanelEntryPoint } from '@wix/wix-code-properties-panel';
import { PackagesTabEntryPoint } from '@/sidebar/packagesTab/packagesTabEntryPoint/packagesTabEntryPoint';
import { DevModeOnEntryPoint } from '@wix/wix-code-entry-point';
import { AiAssistanceEntryPoint } from '@wix/wix-code-ai-assistance';
import { EditorComponentsEntryPoint } from '@wix/wix-code-editor-components';
import { SiteCodeTabEntryPoint } from '@/sidebar/siteCode/siteCodeTabEntryPoint';
import { SearchPanelEntryPoint } from '@/sidebar/searchPanel/searchPanelEntryPoint';
import { DeveloperToolsTabEntryPoint } from '@/sidebar/toolsTab/developerToolsTabEntryPoint';
import { UserNotificationsEntryPoint } from '@/user-notifications';

export const repluggableEntryPoints = [
  WixCodeAppEntryPoint,
  SiteEntryPoint,
  ExperimentsEntryPoint,
  WixCodeStoreEntryPoint,
  SidebarEntryPoint,
  PanelsEntryPoint,
  GithubIntegrationEntryPoint,
  SpiPluginEntryPoint,
  FilesViewEntryPoint,
  UserPreferencesEntryPoint,
  FilesTreeEntryPoint,
  DuplexerEntryPoint,
  WixCodeDuplexerEntryPoint,
  DevConsoleEntryPoint,
  CodeEditorEntryPoint,
  FileSystemEntryPoint,
  ServiceTopologyEntryPoint,
  ConcurrentEditingEntryPoint,
  ReadOnlyEntryPoint,
  BiLoggerEntryPoint,
  PlatformAppsEntryPoint,
  CollectionsEntryPoint,
  WixCodeEditorApiEntryPoint,
  ExternalFilesTreeEntryPoint,
  ExternalCollectionsEntryPoint,
  LocalEditorEntryPoint,
  EmbeddedTabsEntryPoint,
  BackendDebuggingEntryPoint,
  PackagesEntryPoint,
  PackagesAndAppsEntryPoint,
  ExternalPackagesAndAppsEntryPoint,
  DevContextEntryPoint,
  PagesTabEntryPoint,
  VSCodeEntryPoint,
  VSCodeButtonContributionEntryPoint,
  EditorContextEntryPoint,
  CssEditingEntryPoint,
  ExternalComponentsEntryPoint,
  PropertiesPanelEntryPoint,
  PackagesTabEntryPoint,
  DevModeOnEntryPoint,
  AiAssistanceEntryPoint,
  EditorComponentsEntryPoint,
  ExternalGithubEntryPoint,
  ExternalLocalEditorEntryPoint,
  SiteCodeTabEntryPoint,
  SearchPanelEntryPoint,
  DeveloperToolsTabEntryPoint,
  UserNotificationsEntryPoint,
];
