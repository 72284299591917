import * as React from 'react';
import { RichText, TextLabel } from '@wix/wix-base-ui';
import s from './CodeReusePkgsList.scss';
import { PackagesModalContext } from '../../../../packagesModalContext';
import biEvents from '@/legacy/bi/events';

export const RequestPackage: React.FC = () => {
  const { sendBi, currentTab, t } = React.useContext(PackagesModalContext);

  const sendRequestURLBi = () => {
    sendBi(biEvents.PKG_QUICK_ACTION_CLICKED, {
      item_name: 'request_new_package',
      item_type: 'internal_packages',
      category: 'request_new_package',
      Menu_entry_name: currentTab,
    });
  };

  return (
    <div className={s.requestPackageWrapper}>
      <div className={s.requestPackage}>
        <TextLabel type="T07" value={t('Package_Manager_Request_Text')} />
        <RichText type="T10">
          <a
            target="_blank"
            rel="noreferrer"
            href={t('Package_Manager_Request_URL')}
            onClick={sendRequestURLBi}
          >
            {t('Package_Manager_Request_Package_Link_Text')}
          </a>
        </RichText>
      </div>
    </div>
  );
};
