import { Action, TabsState } from '..';
import { ActionType } from '../actionTypes';

const initialState: TabsState = {
  selectedTab: 'code_editor',
};

// Will be removed once liteEditor experiment will set to false
export const createTabsReducer = (isVeloInStudio: boolean = true) => {
  if (isVeloInStudio) {
    initialState.selectedTab = 'pages';
  }
  return tabsReducer;
};

const tabsReducer = (state: TabsState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SELECT_TAB:
      return {
        ...state,
        selectedTab: action.selectedTab,
      };

    default:
      return state;
  }
};
