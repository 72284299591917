import type { ReactElement, ReactNode } from 'react';
import { AnyAction } from 'redux';
import type { Shell } from 'repluggable';
import { ActionType, RealEstateIds } from '../sharedTypes';

export type TopBarItem = () => ReactNode;

type MarkerData = {
  severity: number;
  message: string;
  startLineNumber: number;
  startColumn: number;
  endLineNumber: number;
  endColumn: number;
};

export interface CodeEditorAPI {
  contributeFooter: (
    shell: Shell,
    footerComp: () => ReactElement<any>,
    condition?: () => boolean,
  ) => void;
  contributeTopBarItem: (
    shell: Shell,
    topBarItem: TopBarItem,
    condition?: () => boolean,
  ) => void;
  contributeExternalIDEBanner: (
    shell: Shell,
    externalIDEBanner: () => ReactNode,
    condition?: () => boolean,
  ) => void;
  codeIntelligence: {
    getContentAssistForPage: (pageId: string) => {
      elementsMap: {
        [nickname: string]: string;
      };
    };
  };
  contributeTabRenderer: (
    shell: Shell,
    tabRenderer: (props: { codeEditorProps: any }) => ReactNode,
    condition?: () => boolean,
  ) => void;
  contributeSidebar: (
    shell: Shell,
    sidebarComp: () => ReactNode,
    condition?: () => boolean,
  ) => void;
  registerCompletionItemProvider: (languageId: string, provider: any) => any;
  getComponentsSemanticClassNames: () => Promise<string[] | undefined>;
  getComponentsSemanticClasses: (
    sdkType: string,
    componentType: string,
  ) => string[];
  getComponentCssReference: (sdkType: string) => string | undefined;
  registerCodeValidator: (
    languageId: string,
    validator: (modelContent: string, path: string) => MarkerData[],
  ) => void;
}

export enum CODE_EDITOR_MODE {
  CODE_AND_STAGE = 'CODE_AND_STAGE',
  CODE_ONLY = 'CODE_ONLY',
}

export interface TriggerBEFunctionTab {
  tabId: string;
  fileId: string;
  functionName: string;
  functionSignature: string;
  paramNames: string;
  lineNumber: number;
}

export interface CodeTab {
  displayName?: string;
  tabId: string;
  isPageTab?: boolean;
  unpinned?: boolean;
}

export interface EmbeddedTab {
  url: string;
  title: string;
  offerGuid: string;
  realEstateId: RealEstateIds;
  biItemName: string;
  tabTitle: string;
}

export type IdeTabsReducerState = {
  pinnedFileTabs: CodeTab[];
  embeddedTabs: EmbeddedTab[];
  tbfTabs: TriggerBEFunctionTab[];
  unpinnedTabId: string | null;
  scrollState: Record<string, number>;
};

export type TabsPinTab = {
  tabId: string;
  type: ActionType.TABS_PIN_TAB;
};
export type NodeDoubleClick = {
  fileId: string;
  type: ActionType.FILE_TREE_NODE_DOUBLE_CLICK;
};
export type TabsCloseTab = {
  tabId: string;
  type: ActionType.TABS_CLOSE_TAB;
};
export type ChangeContent = {
  fileId: string;
  type: ActionType.CHANGE_CONTENT;
};
type RemoveInstalledCodeReusePkg = {
  codeReusePkgData: { name: string };
  type: ActionType.REMOVE_INSTALLED_CODE_REUSE_PKG;
};
type RemoveFile = {
  fileId: string;
  type: ActionType.REMOVE_FILE;
};
type RemoveFileUnder = {
  fileId: string;
  type: ActionType.REMOVE_FILES_UNDER;
};
export type TabsCloseEmbededTab = {
  tabId: string;
  type: ActionType.TABS_CLOSE_EMBEDDED_TAB;
};
type HelpLinkClick = {
  id: string;
  linkData: {
    url: string;
    tabTitle: string;
    offerGuid: string;
    biFileName: string;
  };
  type: ActionType.HELP_LINK_CLICK;
};
export type TabsAddOrChangeTbfTab = {
  tabId: string;
  fileId: string;
  lineNumber: number;
  paramNames: string[];
  functionName: string;
  functionSignature: { [argName: string]: string };
  type: ActionType.TABS_ADD_OR_CHANGES_TBF_TAB;
};
export type TabsCloseTbfTab = {
  tabId: string;
  type: ActionType.TABS_CLOSE_TBF_TAB;
};
type DealerResponseDone = {
  realEstateId: string;
  payload: {
    url: string;
    tabTitle: string;
    offerGuid: string;
    biFileName: string;
  };
  type: ActionType.DEALER_RESPONSE_DONE;
};
export type TabsOpenOrChangeUnpinnedTab = {
  tabId: string | null;
  type: ActionType.TABS_OPEN_OR_CHANGE_UNPINNED_TAB;
};
export type TabsStoreScrollState = {
  tabId: string;
  scroll: number;
  type: ActionType.TABS_STORE_SCROLL_STATE;
};
type MoveFolder = {
  oldLocation: string;
  newLocation: string;
  type: ActionType.MOVE_FOLDER;
};

export type IdeTabsReducerAction =
  | TabsPinTab
  | NodeDoubleClick
  | TabsCloseTab
  | ChangeContent
  | RemoveInstalledCodeReusePkg
  | RemoveFile
  | RemoveFileUnder
  | TabsCloseEmbededTab
  | HelpLinkClick
  | TabsAddOrChangeTbfTab
  | TabsCloseTbfTab
  | DealerResponseDone
  | TabsOpenOrChangeUnpinnedTab
  | TabsStoreScrollState
  | MoveFolder;

export type AddEmbeddedTabsArgs = {
  realEstateId: string;
} & HelpLinkClick['linkData'];

export interface IdeTabsStateReader {
  getOpenedTabs(state: { ideTabs: IdeTabsReducerState }): any;
  getEmbeddedTabs(state: { ideTabs: IdeTabsReducerState }): any;
  getUnpinnedTabId(state: { ideTabs: IdeTabsReducerState }): any;
  getTbfTabs(state: { ideTabs: IdeTabsReducerState }): any;
  getTabScroll(state: { ideTabs: IdeTabsReducerState }, tabId: string): any;
}

export interface IdeTabsActions {
  pinTab({ tabId }: { tabId: string }): any;
  closeTab({ tabId }: { tabId: string }): AnyAction;
  closeEmbeddedTab({ tabId }: { tabId: string }): AnyAction;
  closeTbfTab({ tabId }: { tabId: string }): AnyAction;
  openOrChangeUnpinnedTab({ tabId }: { tabId: string | null }): AnyAction;
  openOrAddTbfTab({
    tabId,
    fileId,
    functionName,
    functionSignature,
    paramNames,
    lineNumber,
  }: Omit<TabsAddOrChangeTbfTab, 'type'>): AnyAction;
  storeTabScrollState({
    tabId,
    scroll,
  }: Omit<TabsStoreScrollState, 'type'>): AnyAction;
}
