import React from 'react';
import {
  MenuTabProvider,
  TranslationProvider,
} from '@wix/wix-code-common-components';
import {
  BiLoggerAPI,
  LegacyEditorDependencies,
  PanelsAPI,
  WixCodeStoreAPI,
} from '@wix/wix-code-plugin-contracts';
import s from './searchPanel.scss';
import { SearchPanelHeader } from './SearchPanelHeader';

type SearchPanelMenuTabProps = {
  closePanel: () => void;
  apis: {
    wixCodeStoreAPI: WixCodeStoreAPI;
    legacyDependenciesAPI: LegacyEditorDependencies;
    biLoggerAPI: BiLoggerAPI;
    panelsAPI: PanelsAPI;
  };
};

export const createSearchPanelMenuTab: React.FC<SearchPanelMenuTabProps> = ({
  closePanel,
  children,
  apis,
}) => {
  const { panelsAPI, ...restAPIs } = apis;
  return (
    <MenuTabProvider {...restAPIs}>
      <TranslationProvider
        options={{
          locale: apis.legacyDependenciesAPI.util.editorModel.languageCode,
          asyncMessagesLoader: (locale: string) =>
            import(`../../assets/locale/messages_${locale}.json`),
        }}
      >
        <SearchPanelHeader closePanel={closePanel} panelsAPI={panelsAPI} />
        <div className={s.searchPanel}>{children}</div>
      </TranslationProvider>
    </MenuTabProvider>
  );
};
