import React, { useContext } from 'react';
import dataHooks from '@/toExtract/packages/dataHooks';
import {
  AccordionSection,
  Divider,
  Spacer,
  Text,
  RichText,
} from '@wix/wix-base-ui';
import s from './PackagesWarning.scss';
import { PackagesModalContext } from '@/toExtract/packages/packagesModalContext';

export const PackagesWarning = () => {
  const { t } = useContext(PackagesModalContext);
  return (
    <>
      <div
        className={s.packageWarningWrapper}
        data-hook={dataHooks.PKG_MANAGER_README_ANY_NPM_WARNING}
      >
        <div className={s.warningContainer}>
          <Text weight="thin" size="small" enableEllipsis={false}>
            {t('Package_Manager_ReadMe_Any_NPM_Warning')}
          </Text>
        </div>
        <Divider long />
        <AccordionSection
          label={
            <RichText type="T02">
              {t('Package_Manager_ReadMe_Any_NPM_Safe_Package_Indication')}
            </RichText>
          }
          removeTopDivider
          removeBottomDivider
        >
          <RichText>
            {t(
              'Package_Manager_ReadMe_Any_NPM_Safe_Package_Indication_Description',
            )}
          </RichText>
          <Spacer type="Spacer01" />
          <RichText type="T02">
            <ul className={s.listItems}>
              <li className={s.listItems}>
                {t(
                  'Package_Manager_ReadMe_Any_NPM_Safe_Package_Indication_Bullet_1',
                )}
              </li>
              <li className={s.listItems}>
                {t(
                  'Package_Manager_ReadMe_Any_NPM_Safe_Package_Indication_Bullet_2',
                )}
              </li>
              <li className={s.listItems}>
                {t(
                  'Package_Manager_ReadMe_Any_NPM_Safe_Package_Indication_Bullet_3',
                )}
              </li>
              <li className={s.listItems}>
                {t(
                  'Package_Manager_ReadMe_Any_NPM_Safe_Package_Indication_Bullet_4',
                )}
              </li>
              <li className={s.listItems}>
                {t(
                  'Package_Manager_ReadMe_Any_NPM_Safe_Package_Indication_Bullet_5',
                )}
              </li>
            </ul>
          </RichText>
        </AccordionSection>
      </div>
      <Spacer type="Spacer06" />
    </>
  );
};
