import React from 'react';
import PropTypes from 'prop-types';

export class DoNotTranslateBaseUiComponents extends React.Component {
  getChildContext() {
    return {
      ...this.context,
      translateFromContext: (alreadyTranslatedString: string) => {
        return alreadyTranslatedString;
      },
    };
  }

  render() {
    return this.props.children;
  }

  static childContextTypes = {
    translateFromContext: PropTypes.func,
  };

  static contextTypes = {};
}
