// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DF3KXt{display:flex;align-items:center;background-color:#f7f8f8;padding:12px 16px 12px 16px}.dyddAT{border:1px solid #e4e4e4;border-radius:4px;display:flex;flex-direction:column}.iz__F2{list-style-type:disc}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning-container": "DF3KXt",
	"warningContainer": "DF3KXt",
	"package-warning-wrapper": "dyddAT",
	"packageWarningWrapper": "dyddAT",
	"list-items": "iz__F2",
	"listItems": "iz__F2"
};
export default ___CSS_LOADER_EXPORT___;
