import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import {
  getTbfModelIdName,
  getTbfModelPath,
  constructTbfContent,
} from '../utils';

function loadTbfModel({
  isHttpFunctionExperimentOn,
  paramNames,
  functionName,
  fileId,
}: AnyFixMe) {
  return function (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    editorAPI: EditorAPI,
  ) {
    const modelId = getTbfModelIdName(fileId, functionName);
    const modelExists = editorAPI.wixCode.tbfService.models.get({ modelId });
    if (!modelExists) {
      return editorAPI.wixCode.tbfService.models.create({
        path: getTbfModelPath(fileId, functionName),
        value: constructTbfContent({
          isHttpFunctionExperimentOn,
          fileId,
          functionName,
          paramNames,
        }),
        language: 'json',
      });
    }
  };
}

function resetTbfModel({
  isHttpFunctionExperimentOn,
  paramNames,
  functionName,
  fileId,
}: AnyFixMe) {
  return function (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    editorAPI: EditorAPI,
  ) {
    editorAPI.wixCode.tbfService.models.update({
      modelId: getTbfModelIdName(fileId, functionName),
      value: constructTbfContent({
        isHttpFunctionExperimentOn,
        fileId,
        functionName,
        paramNames,
      }),
    });
  };
}

export default {
  loadTbfModel,
  resetTbfModel,
};
