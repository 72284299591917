import React, { useContext } from 'react';
import { PackagesModalContext } from '../../../../../packagesModalContext';
import s from './PkgActionButton.scss';
import {
  getVersionInstallationType,
  isVersionInstallationInProgress,
  VERSION_INSTALLATION_TYPE,
} from '../../../../../utils';
import { CodeReusePkgVersion } from '../../../../../codeReuseServerAPI';
import { PackageItemContext } from '../../PackageItemContext';
import { PkgPreloaderButton } from './PkgPreloaderButton';
import { ViewPkgButton } from './ViewPkgButton';
import { InstallPkgButton } from './InstallPkgButton';
import { DisabledInstallPkgButton } from './DisabledInstallPkgButton';
import { OtherVersionInstalledButton } from './OtherVersionInstalledButton';
import { InstalledLabel } from './InstalledLabel';

interface VersionActionButtonProps {
  version: string;
  allVersions?: CodeReusePkgVersion[];
}

export const VersionActionButton: React.FC<VersionActionButtonProps> = ({
  version,
  allVersions,
}) => {
  const { justInstalledPkgs } = React.useContext(PackagesModalContext);

  const { installedPkg, pkgName, installationStatus, isCodeReusePackage } =
    useContext(PackageItemContext);

  const instalationType = getVersionInstallationType({
    version,
    installedPkg,
    allVersions,
  });

  const installedExactTypes = [
    VERSION_INSTALLATION_TYPE.VERSION_INSTALLED_BY_NUMBER,
    VERSION_INSTALLATION_TYPE.VERSION_INSTALLED_AS_TEST,
    VERSION_INSTALLATION_TYPE.LARGEST_MINOR_IN_AUTO_UPDATE,
  ];

  const isVersionInstalled = installedExactTypes.includes(instalationType);

  const isSameMajorInstalled = [
    ...installedExactTypes,
    VERSION_INSTALLATION_TYPE.NOT_LARGEST_MINOR_IN_AUTO_UPDATE,
  ].includes(instalationType);

  const isVersionJustInstalled =
    justInstalledPkgs.includes(pkgName) &&
    installationStatus === 'INSTALLED' &&
    isVersionInstalled;

  const isInstalling = ['INSTALLING', 'UPDATING'].includes(installationStatus!);
  const isInstallingVersion =
    isInstalling && isCodeReusePackage
      ? isVersionInstalled
      : isVersionInstallationInProgress(version);
  const canInstallVersion = !isVersionInstalled && !isSameMajorInstalled;

  return (
    <div className={s.versionButtonContainer}>
      {isInstallingVersion ? (
        <PkgPreloaderButton />
      ) : isInstalling ? (
        <DisabledInstallPkgButton />
      ) : isVersionJustInstalled ? (
        <ViewPkgButton />
      ) : canInstallVersion ? (
        <InstallPkgButton versionOverride={version} />
      ) : isVersionInstalled ? (
        <InstalledLabel />
      ) : isSameMajorInstalled ? (
        <OtherVersionInstalledButton />
      ) : null}
    </div>
  );
};
