import { SpiConnection } from '../ServerAPI';
import { DuplexerAPI } from '@wix/wix-code-plugin-contracts';

export const spiPublishStatusUpdate = {
  appDefId: 'f5cd23d8-e18b-4663-ad94-91c4c60e9972',
  eventName: 'spi-publish-status',
};

export type DuplexerStatusResponse = {
  statuses: SpiConnection[];
};

export const subscribeToSpiPublishEvent = (
  duplexerAPI: DuplexerAPI,
  onSpiPublish: (payload: DuplexerStatusResponse) => void,
) => {
  duplexerAPI.subscribeToUserChannel(spiPublishStatusUpdate, onSpiPublish);
};
