import _ from 'lodash';

const TABLE_OBJECT = 'Table Obj';
const TABLE_ARRAY_PRIMITIVE = 'Table Prim[]';
const TABLE_ARRAY_OBJECT = 'Table Obj[]';

function TableView(view, matrix) {
  return { view, matrix };
}

export function toTableView(val) {
  if (_.isArray(val)) {
    return arrayToArrayOfMatricesBySubArrayType(val);
  } else {
    return objectToMatrix(val);
  }
}

function objectToMatrix(obj) {
  return [TableView(TABLE_OBJECT, Object.entries(obj))];
}

function arrayToArrayOfMatricesBySubArrayType(arr) {
  if (arr.length === 0) {
    return [];
  }
  const matrices = [];
  let lastIsObj = _.isObject(arr[0]);

  const transformSlice = (subArr, indexOffset) =>
    lastIsObj
      ? objectArrToMatrix(subArr, indexOffset)
      : primitiveArrToMatrix(subArr, indexOffset);

  let subArray = [];
  let lastIndex = 0;
  for (let i = 0; i < arr.length; i++) {
    if (_.isObject(arr[i]) === lastIsObj) {
      subArray.push(arr[i]);
    } else {
      matrices.push(transformSlice(subArray, lastIndex));
      lastIndex = i;
      subArray = [arr[i]];
      lastIsObj = _.isObject(arr[i]);
    }
  }
  matrices.push(transformSlice(subArray, lastIndex));
  return matrices;
}

function primitiveArrToMatrix(arr, indexOffset) {
  return TableView(
    TABLE_ARRAY_PRIMITIVE,
    arr.map((val, index) => [index + indexOffset, val]),
  );
}

function objectArrToMatrix(arr, indexOffset) {
  const fieldNames = extractAllFieldNames(arr);
  const matrix = [['#'].concat(fieldNames)];
  return TableView(
    TABLE_ARRAY_OBJECT,
    matrix.concat(
      arr.map((obj, index) =>
        [index + indexOffset].concat(
          fieldNames.map((field) => (field in obj ? obj[field] : '')),
        ),
      ),
    ),
  );
}

function extractAllFieldNames(arr) {
  const fieldNamesMap = {};
  arr.forEach((object) => {
    const objectFields = Object.keys(object);
    objectFields.forEach((field) => {
      fieldNamesMap[field] = true;
    });
  });
  return Object.keys(fieldNamesMap);
}

export function prepareTableForCopy(value) {
  return toTableView(value)
    .map((view) => {
      return view.matrix
        .map((line) => {
          return line
            .map((_value) =>
              !_.isObject(_value)
                ? formatPrimitiveForTable(_value)
                : JSON.stringify(_value),
            )
            .join('\t');
        })
        .join('\n');
    })
    .join('\n');
}

export function formatPrimitiveForTable(value) {
  return value === null
    ? 'null'
    : value === undefined
    ? 'undefined'
    : value === false
    ? 'false'
    : value === true
    ? 'true'
    : value;
}

export function stringifyPrimitive(value) {
  return value === undefined ? 'undefined' : JSON.stringify(value);
}

export function isLabelCell(view, row, column) {
  return column === 0 || (view.view === TABLE_ARRAY_OBJECT && row === 0);
}
