import { ActionType } from '@/infra/redux-state/actions/types';
import { ThunkAction } from '@wix/wix-code-common-components';
import {
  PackagesModalAddJustInstalledPkg,
  PackagesModalClearInstalledPkgs,
  PackagesModalCloseVersionPicker,
  PackagesModalOpenVersionPicker,
  PackagesModalSearchVeloPkg,
  PackagesModalSelectPackage,
  PackagesModalSelectVersion,
  PackagesModalAddJustRequestedPkg,
} from './packagesModalReducer';
import { getCurrentTab } from './packagesModalSelectors';

export function selectTab(tab: string, packageName?: string): ThunkAction {
  return function (dispatch) {
    dispatch(clearJustInstalledPkgs());
    dispatch({
      type: ActionType.PACKAGES_MODAL_SELECT_TAB,
      tab,
    });
    if (packageName) {
      return dispatch({
        type: ActionType.PACKAGES_MODAL_SELECT_PACKAGE,
        packageName,
        tab,
      });
    }
  };
}

export function addJustInstalledPkg(
  name: string,
): PackagesModalAddJustInstalledPkg {
  return {
    type: ActionType.PACKAGES_MODAL_ADD_JUST_INSTALLED_PKG,
    name,
  };
}

export function clearJustInstalledPkgs(): PackagesModalClearInstalledPkgs {
  return {
    type: ActionType.PACKAGES_MODAL_CLEAR_INSTALLED_PKGS,
  };
}

export function addJustRequestedPkg(
  name: string,
): PackagesModalAddJustRequestedPkg {
  return {
    type: ActionType.PACKAGES_MODAL_ADD_JUST_REQUESTED_PKG,
    name,
  };
}

export function selectPackageVersion(
  version: string,
): PackagesModalSelectVersion {
  return {
    type: ActionType.PACKAGES_MODAL_SELECT_VERSION,
    version,
  };
}

export function clickSeeAllVersions(): PackagesModalOpenVersionPicker {
  return {
    type: ActionType.PACKAGES_MODAL_OPEN_VERSION_PICKER,
  };
}

export function clickVersionPickerBackButton(): PackagesModalCloseVersionPicker {
  return {
    type: ActionType.PACKAGES_MODAL_CLOSE_VERSION_PICKER,
  };
}

export function searchVeloPackage(keyword: string): PackagesModalSearchVeloPkg {
  return {
    type: ActionType.PACKAGES_MODAL_SEARCH_VELO_PKG,
    keyword,
  };
}

export function selectPackage(packageName: string, tab: string): ThunkAction {
  return function (dispatch, getState): PackagesModalSelectPackage {
    if (getCurrentTab(getState()) !== tab) {
      dispatch(selectTab(tab));
    }
    return dispatch({
      type: ActionType.PACKAGES_MODAL_SELECT_PACKAGE,
      packageName,
      tab,
    });
  };
}
