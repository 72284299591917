import React, { Fragment, useContext } from 'react';
import _ from 'lodash';
import { TreeSectionDivider } from '@wix/wix-base-ui';
import { monitoring, utilsCreator } from '@wix/wix-code-common';
import { leftTreeClickOnASection } from '@wix/bi-logger-platform/v2';
import { LightboxPage } from './LightboxPage';
import { PagesTabContext } from './PagesTabContext';
import dataHooks from '../dataHooks';
import { PageTreeContext } from './PageTreeContext';

type Item = {
  id: string;
  title: string;
  biCategory: string;
};

interface LightboxTreeProps {
  items: Item[];
  label: string;
}

const { withErrorHandling } = monitoring;
export const LightboxTree = ({ items, label }: LightboxTreeProps) => {
  const { bi, legacyDependenciesAPI } = useContext(PagesTabContext);
  const { isCollapsed, setCollapsed, selectedIdeTabId, onPageSelected } =
    useContext(PageTreeContext);
  const { experiment, platform, util } = legacyDependenciesAPI;
  const utils = utilsCreator({ experiment, platform, util });

  const id = 'page___POPUPS__';
  const [collapsed, setCollapsedState] = React.useState(isCollapsed(id));

  const onNodeClick = () =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickOnASection({
          section_name: 'lightboxes',
          action: collapsed ? 'open' : 'close',
        }),
      );
      setCollapsed(id, !collapsed);
      setCollapsedState(!collapsed);
    })();

  return (
    <Fragment>
      <div data-aid="lightboxes-section">
        <TreeSectionDivider
          dataHook={dataHooks.SECTION_DIVIDER}
          label={label}
          collapsed={collapsed}
          hideBottomBorder={collapsed}
          onClick={onNodeClick}
          size="small"
        />
      </div>
      {!collapsed && (
        <ul
          data-aid="lightboxes-items"
          key="subtree"
          className="section-container"
        >
          {items.map((child: Item) => (
            <li key={child.id}>
              <LightboxPage
                selectedIdeTabId={selectedIdeTabId}
                data={{ id: child.id, title: child.title }}
                onPageSelected={onPageSelected}
                getFileIdFromPageId={utils.getFileIdFromPageId}
              />
            </li>
          ))}
        </ul>
      )}
    </Fragment>
  );
};
