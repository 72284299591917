import * as React from 'react';
import classNames from 'classnames';
import { Tooltip, TextButton } from '@wix/wix-base-ui';
import dataHooks from '@/commonComponents/dataHooks';
import s from './ToolbarTextButton.scss';

interface ToolbarButtonProps {
  onClick: any;
  prefixIcon?: any;
  tooltipContent: string;
  disabled?: boolean;
  text?: string;
  className?: string;
  id?: string;
  children?: React.ReactNode;
  shouldShowUnderline?: boolean;
}

export const ToolbarTextButton: React.FC<ToolbarButtonProps> = ({
  onClick,
  prefixIcon,
  disabled,
  tooltipContent,
  className,
  id,
  children,
  shouldShowUnderline = true,
}) => {
  return (
    <div data-aid={id}>
      <Tooltip content={tooltipContent} disabled={disabled}>
        <div
          className={classNames(className, {
            [s.wixCodeIdeToolbarTextButton]: true,
          })}
        >
          <TextButton
            dataHook={dataHooks.TOOLBAR_TEXT_BUTTON}
            prefixIcon={prefixIcon}
            onClick={onClick}
            size="small"
            disabled={disabled}
            underline={shouldShowUnderline ? 'onMouseHover' : 'none'}
          >
            {children}
          </TextButton>
        </div>
      </Tooltip>
    </div>
  );
};
