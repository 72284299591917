import { Action, DevModeState } from '..';
import { ActionType } from '../actionTypes';

const initialState: DevModeState = {
  loading: true,
  readOnly: false,
};

export const devModeReducer = (
  state = initialState,
  action: Action,
): DevModeState => {
  switch (action.type) {
    case ActionType.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ActionType.SET_READ_ONLY:
      return {
        ...state,
        readOnly: action.readOnly,
      };
    default:
      return state;
  }
};
