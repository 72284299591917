import { WIX_CODE, DATA_BINDING } from '@wix/app-definition-ids';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';

type notifyApplicationEvent = Parameters<
  EditorAPI['dsActions']['platform']['notifyApplication']
>[1];
export function notifyDataBinding(
  editorAPI: EditorAPI,
  event: notifyApplicationEvent,
) {
  const appData = editorAPI.dsRead.platform.getAppDataByAppDefId(DATA_BINDING);
  editorAPI.dsActions.platform.notifyApplication(appData.applicationId, event);
}

export function notifyWixCode(
  editorAPI: EditorAPI,
  event: notifyApplicationEvent,
) {
  let notified = false;
  const appData = editorAPI.dsRead.platform.getAppDataByAppDefId(WIX_CODE);

  editorAPI.dsActions.platform.registerToManifestAdded(() => {
    if (editorAPI.documentServices.platform.isAppActive(WIX_CODE) && !notified) {
      editorAPI.dsActions.platform.notifyApplication(appData.applicationId, event);
      notified = true;
    }
  });
}
