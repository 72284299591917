import React from 'react';
import { i18n, I18nextProvider } from '@wix/wix-i18n-config';
import { connectWithShell, Shell } from 'repluggable';
import {
  SectionContent,
  CssEditingSectionStateProps,
  CssEditingSectionDispatchProps,
  CssEditingSectionEditorProps,
} from './components/Sidebar/SectionContent/SectionContent';
import { CssEditingInternalAPI } from './cssEditingInternalAPI';
import { CssEditingStore } from './state';
import {
  BiLoggerAPI,
  EditorAPI,
  LegacyEditorDependencies,
  WixCodeStoreAPI,
} from '@wix/wix-code-plugin-contracts';
import { constants as cssConstants } from './constants';
import { APIKeys } from '@wix/wix-code-repluggable';
import { ModalsService } from './components/Modals/modalsService';
import { BiLoggerContext } from './biLoggerContext';
import { mapCodeStateToProps } from './utils/mapCodeStateToProps';

interface ContextAPIs {
  internalAPI: CssEditingInternalAPI;
  biLoggerAPI: BiLoggerAPI;
}
interface ConnectSectionWithWixCodeStoreProps {
  legacyDependenciesAPI: LegacyEditorDependencies;
  wixCodeStoreAPI: WixCodeStoreAPI;
}
interface ConnectSectionWithShellProps {
  shell: Shell;
  internalAPI: CssEditingInternalAPI;
  modalsAPI: ModalsService;
}

const mapEditorStateToProps =
  (constants: LegacyEditorDependencies['constants']) =>
  ({ editorAPI }: { editorAPI: EditorAPI }): CssEditingSectionEditorProps => {
    const devContext = editorAPI.developerMode.getContext();
    return {
      editorAPI,
      isFileSelected:
        devContext.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.FILE &&
        devContext.data.id === cssConstants.global_css_path,
    };
  };

const ConnectSectionWithWixCodeStore = ({
  legacyDependenciesAPI: { util, constants },
  wixCodeStoreAPI: {
    connectToStores,
    selectors: {
      fileSystem: { getFile },
    },
  },
}: ConnectSectionWithWixCodeStoreProps) => {
  let ConnectedComponent = null as any;
  return (origProps: any) => {
    if (ConnectedComponent === null) {
      ConnectedComponent = connectToStores({
        util,
        mapEditorStateToProps: mapEditorStateToProps(constants),
        mapCodeStateToProps: mapCodeStateToProps(getFile),
        comp: SectionContent,
      });
    }
    return <ConnectedComponent {...origProps} />;
  };
};

const ConnectSectionWithShell = ({
  shell,
  internalAPI,
  modalsAPI,
}: ConnectSectionWithShellProps) =>
  connectWithShell<
    CssEditingStore,
    {},
    CssEditingSectionStateProps,
    CssEditingSectionDispatchProps
  >(
    () => {
      const userPreferencesAPI = shell.getAPI(APIKeys.UserPreferencesAPI);
      const globalCssInstallationTime: number | undefined =
        userPreferencesAPI.getGlobalPreference(cssConstants.firstTimeBadgeKey);
      return {
        shouldShowBadge: !globalCssInstallationTime,
        expanded: internalAPI.getExpanded(),
      };
    },
    () => ({
      createCssFileAndOpen: internalAPI.createCssFileAndOpen,
      selectFile: internalAPI.selectFile,
      onDeleteClick: modalsAPI.openDeleteModal,
      setIsExpanded: internalAPI.setIsExpanded,
    }),
    shell,
    {
      renderOutsideProvider: true,
      allowOutOfEntryPoint: true,
    },
  );

const ConnectSectionContent = ({
  shell,
  internalAPI,
  legacyDependenciesAPI,
  wixCodeStoreAPI,
  modalsAPI,
}: ConnectSectionWithWixCodeStoreProps & ConnectSectionWithShellProps) => {
  const ConnectedSectionWithShellComp = ConnectSectionWithShell({
    shell,
    internalAPI,
    modalsAPI,
  });
  const ConnectedSectionWithStoreComp = ConnectSectionWithWixCodeStore({
    legacyDependenciesAPI,
    wixCodeStoreAPI,
  });
  return ConnectedSectionWithShellComp(ConnectedSectionWithStoreComp);
};

export const createSidebarSection =
  (
    shell: Shell,
    i18nInstance: i18n,
    modalsAPI: ModalsService,
    { internalAPI, biLoggerAPI }: ContextAPIs,
  ): React.FC =>
  () => {
    const ConnectedSectionContent = ConnectSectionContent({
      shell,
      internalAPI,
      modalsAPI,
      legacyDependenciesAPI: shell.getAPI(APIKeys.LegacyEditorDependencies),
      wixCodeStoreAPI: shell.getAPI(APIKeys.WixCodeStoreAPI),
    });
    return (
      <I18nextProvider i18n={i18nInstance}>
        <BiLoggerContext.Provider value={biLoggerAPI}>
          <ConnectedSectionContent />
        </BiLoggerContext.Provider>
      </I18nextProvider>
    );
  };
