import { ExtensionSlot, SlotKey } from '@wix/wix-code-repluggable';

export type PanelExtensionSlot = ExtensionSlot<PanelContribution>;

export const LitePanelSlot: SlotKey<PanelContribution> = {
  name: 'Lite Panel Slot',
};

export type PanelContribution = {
  panel: React.ComponentType<any>;
};
