import React from 'react';
import { PackagesModalContext } from '../../../../packagesModalContext';
import { RequestPackage } from './RequestPackage/RequestPackage';
import Preloader from '../../../../preloader/preloader';
import NpmPackageListContainer from './NpmPackageListContainer/NpmPackageListContainer';
import { getIsVersionPickerShowing } from '../../../packagesModalSelectors';
import { PackageVersionPicker } from '../packageVersions/PackageVersionPicker';
import { useSelector } from '@wix/wix-code-common-components';

export const NpmPackagesList: React.FC = () => {
  const { isNpmPackagesFetchingDone, showSubmitRequest } =
    React.useContext(PackagesModalContext);
  const showVersionPicker = useSelector(getIsVersionPickerShowing);
  return (
    <>
      {isNpmPackagesFetchingDone ? (
        showSubmitRequest ? (
          <RequestPackage />
        ) : showVersionPicker ? (
          <PackageVersionPicker />
        ) : (
          <NpmPackageListContainer />
        )
      ) : (
        <Preloader />
      )}
    </>
  );
};
