// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oWc1yY{cursor:default}.oWc1yY path{fill:#bcbcbc}.oWc1yY circle{stroke:#bcbcbc}.g7p5o0{max-width:500px !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disabled": "oWc1yY",
	"contextMenuAction": "g7p5o0"
};
export default ___CSS_LOADER_EXPORT___;
