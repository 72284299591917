import {
  PackagesAndAppsAPI,
  LegacyEditorDependencies,
  ReadOnlyAPI,
  BiLoggerAPI,
  CONCURRENT_EDITING_READ_ONLY_MODE,
  EditorContextAPI,
} from '@wix/wix-code-plugin-contracts';
import React, { useEffect } from 'react';
import { WixCodeContextProvider } from '@/infra/wixCodeContext/WixCodeContextProvider';
import {
  ReadOnlyModeProps,
  SidePaneComp,
  SidePaneMapDispatchToProps,
  SidePaneProps,
  SidePaneStateProps,
} from './SidePane';
import { APIKeys, Shell } from '@wix/wix-code-repluggable';
import { FilesTreeState } from '@/infra/redux-state/reducers/fileTreeReducer';
import { connectWithShell } from 'repluggable';
import { ReadOnlyModeContext } from '@wix/wix-code-common-components';
import { BiLoggerContext } from '@/infra/wixCodeContext/BiLoggerAPIContext';

export const createLeftPane = (
  legacyEditorDeps: LegacyEditorDependencies,
  readOnlyAPI: ReadOnlyAPI,
  shell: Shell,
  packagesAndAppsAPI: PackagesAndAppsAPI,
  biLoggerAPI: BiLoggerAPI,
  editorContextAPI: EditorContextAPI,
) => {
  const SidePane = (props: any) => {
    const { readOnly, readOnlyMode } = props;
    useEffect(() => {
      if (readOnly) {
        readOnlyAPI.setReadOnly(
          'CONCURRENT_EDITING',
          CONCURRENT_EDITING_READ_ONLY_MODE,
        );
      } else {
        readOnlyAPI.removeReadOnlyContribution('CONCURRENT_EDITING');
      }
    }, [readOnly]);
    return (
      <WixCodeContextProvider legacyDependencies={legacyEditorDeps}>
        <ReadOnlyModeContext.Provider value={{ readOnlyMode }}>
          <BiLoggerContext.Provider value={biLoggerAPI}>
            <SidePaneComp
              {...props}
              ideTreePackagesViewDefinition={
                legacyEditorDeps.ideTreePackagesViewDefinition
              }
              packagesAndAppsAPI={packagesAndAppsAPI}
              editorContextAPI={editorContextAPI}
            />
          </BiLoggerContext.Provider>
        </ReadOnlyModeContext.Provider>
      </WixCodeContextProvider>
    );
  };

  const ConnectedSidePane = connectWithShell<
    FilesTreeState,
    SidePaneProps,
    SidePaneStateProps & ReadOnlyModeProps,
    SidePaneMapDispatchToProps
  >(
    (boundedShell: Shell) => {
      return {
        newChild: boundedShell
          .getAPI(APIKeys.FilesTreeAPI)
          .getNewFileRenameInput(),
        readOnlyMode: boundedShell
          .getAPI(APIKeys.ReadOnlyAPI)
          .getReadOnlyMode(),
      };
    },
    (boundedShell: Shell) => {
      return {
        showNewFileInput: boundedShell.getAPI(APIKeys.FilesTreeAPI)
          .showNewFileInput,
        setNewFileRenameInput: boundedShell.getAPI(APIKeys.FilesTreeAPI)
          .setNewFileRenameInput,
      };
    },
    shell,
    {
      renderOutsideProvider: true,
    },
  )(SidePane);

  return ConnectedSidePane;
};
