import { create, FedopsLogger } from '@wix/fedops-logger';

const appName = 'wix-code-classic-editor';

let fedopsLogger: FedopsLogger;

const getLogger = () => {
  if (!fedopsLogger) {
    fedopsLogger = create(appName);
  }

  return fedopsLogger;
};

export { getLogger };
