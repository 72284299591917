import * as React from 'react';
import { LeftPaneContext } from '../../../LeftPaneContext';
import {
  ReadOnlyModeContext,
  TreeContextMenu,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import { openDeleteFileSystemItemWarningPanel } from '@/legacy/core/components/deleteFileSystemItemWarningPanel/DeleteFileSystemItemWarningPanel';
import { Preloader } from '@wix/wix-base-ui';
import dataHooks from '../../../dataHooks';
import { TFunc } from '@/infra/i18n/i18nService';

interface SingleFileNodeMenuProps {
  file: any;
  isContextMenuOpen: boolean;
  setContextMenuOpen: (isOpen: boolean) => void;
}

export const SingleFileNodeMenu: React.FC<SingleFileNodeMenuProps> = ({
  isContextMenuOpen,
  setContextMenuOpen,
  file,
}) => {
  const { readOnlyMode } = React.useContext(ReadOnlyModeContext);
  const { getEditorAPI } = React.useContext(LeftPaneContext);
  const {
    legacyDependenciesAPI: { util },
  } = useEditorLegacyAPIs();
  const t: TFunc = util.translate;
  const editorAPI = getEditorAPI();
  const fileDescriptor = file.descriptor;
  const pendingDelete = file.pendingDelete;

  const onContextMenuClick = (event: React.MouseEvent) =>
    event.stopPropagation();

  const onFileDeleteConfirm = () => {
    return editorAPI.wixCode.fileSystem
      .deleteItem(fileDescriptor)
      .catch((error: AnyFixMe) => {
        editorAPI.panelManager.openPanel(
          'wixCode.panels.fileSystemOperationErrorPanel',
          { error },
        );
      });
  };

  const contextMenuSections = {
    sections: [
      [
        {
          onClick: () => {
            openDeleteFileSystemItemWarningPanel(editorAPI, {
              panelName: 'deleteFileSystemItemWarningPanel',
              fileName: fileDescriptor.name,
              fileType: 'File',
              onConfirm: onFileDeleteConfirm,
              onCancel: () =>
                editorAPI.panelManager.closePanelByName(
                  'deleteFileSystemItemWarningPanel',
                ),
            });
          },
          icon: 'corvid_npm__trash',
          label: t('WixCode_TreeView_FileTree_QuickActions_Delete'),
          automationId: 'action-remove-single',
        },
      ],
    ],
  };

  return pendingDelete ? (
    <div className="preloader-container-hack">
      <Preloader dataHook={dataHooks.LOADER} className="tiny" />
    </div>
  ) : (
    <div onClick={onContextMenuClick}>
      <TreeContextMenu
        menuClassName="context-menu wix-code-file-tree-dd"
        handleContextMenuToggle={setContextMenuOpen}
        contextMenuStructure={contextMenuSections}
        contextMenuButton="corvid_tree__context_menu_more"
        isContextMenuOpen={isContextMenuOpen}
        tooltipContent={util.translate(
          'Velo_Concurrent_Editing_Sidebar_ReadOnly_Text',
        )}
        readonly={!!readOnlyMode.sidePanel?.publicAndBackend}
      />
    </div>
  );
};
