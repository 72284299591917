import React from 'react';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { ideSnackBarMessage, wixIdeButton } from '@wix/bi-logger-platform/v2';
import { experimentUtils } from '@wix/wix-code-common';

import s from './readOnlyPanel.scss';

export const ConcurrentEditingEntryPoint: EntryPoint = {
  name: 'concurrent editing entry point',
  getDependencyAPIs() {
    return [
      WixCodeEditorAdapterAPI,
      APIKeys.CodeEditorAPI,
      APIKeys.ReadOnlyAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.VSCodePublicAPI,
      APIKeys.GithubPublicAPI,
      APIKeys.LocalEditorPublicAPI,
    ];
  },
  declareAPIs() {
    return [];
  },
  extend(shell: Shell) {
    const wixCodeEditorAdapterAPI = shell.getAPI(WixCodeEditorAdapterAPI);
    const codeEditorAPI = shell.getAPI(APIKeys.CodeEditorAPI);
    const readOnlyAPI = shell.getAPI(APIKeys.ReadOnlyAPI);
    const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
    const vscodePublicAPI = shell.getAPI(APIKeys.VSCodePublicAPI);

    const { isLoadingOnboardingStatus, isGithubEnabled } = shell.getAPI(
      APIKeys.GithubPublicAPI,
    );
    const { isInLocalEditor } = shell.getAPI(APIKeys.LocalEditorPublicAPI);

    contributeNicknamesEditableCondition();
    contributeWixCodeReadOnlyCondition();
    const externalClickHandler = (buttonLabel: string) => {
      biLoggerAPI.report(
        ideSnackBarMessage({
          action: 'click',
          input_name: buttonLabel,
        }),
      );
      biLoggerAPI.report(
        wixIdeButton({
          action: 'click',
          input_name: buttonLabel,
          origin: 'ide_snack_bar',
        }),
      );
    };
    function contributeWixCodeReadOnlyCondition() {
      wixCodeEditorAdapterAPI.temporaryConditionalContributions.contributeConcurrentReadOnlyPanel(
        (originalCondition) =>
          isReadOnlyExperienceRelevant() === false
            ? false
            : originalCondition(),
        () => vscodePublicAPI.openVSCodeOnlineTab,
        experimentUtils.isLiteEditor() ? getComponentOverride() : undefined,
      );
    }

    function getComponentOverride() {
      return (ReadOnlyPanelComponent: React.FC<any>) => {
        codeEditorAPI.contributeFooter(
          shell,
          () => (
            <ReadOnlyPanelComponent
              className={s.footer}
              externalClickHandler={externalClickHandler}
            />
          ),
          isReadOnlyExperienceRelevant,
        );
      };
    }

    function contributeNicknamesEditableCondition() {
      const shouldAllowEditingNicknames = () =>
        isInLocalEditor() || isGithubEnabled();

      wixCodeEditorAdapterAPI.contributeNicknamesEditableCondition(
        (originalCondition) =>
          shouldAllowEditingNicknames() || originalCondition(),
      );
    }

    function isReadOnlyExperienceRelevant() {
      return (
        !isLoadingOnboardingStatus() &&
        !isGithubEnabled() &&
        !isInLocalEditor() &&
        readOnlyAPI.getReadOnlyMode().codeIde
      );
    }
  },
};
