import React from 'react';
import { RichText } from '@wix/wix-base-ui';
import styles from './EmptyStateBase.scss';

interface EmptyStateBaseProps {
  text: string;
  illustration: React.ReactNode;
  cta: React.ReactNode;
}

export const EmptyStateBase = ({
  text,
  illustration,
  cta,
}: EmptyStateBaseProps) => {
  return (
    <div className={styles.emptyStateWrapper}>
      {illustration}
      <RichText className={styles.centeredText}>
        <p className={styles.text}>{text}</p>
        {cta}
      </RichText>
    </div>
  );
};
