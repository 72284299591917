import React from 'react';
import { Heading, RichText, Spacer } from '@wix/wix-base-ui';
import dataHooks from '../../dataHooks';
import CenterLoaderSpinner from '../CenteralLoaderSpinner/CenteralLoaderSpinner';
import styles from './SignInInfo.scss';
import { BiLoggerAPI } from '@wix/wix-code-plugin-contracts';
import { signInAction } from '../../../utils/biEvents';
import { MODALS_ACTIONS } from '../../../utils/biConsts';

const SignInInfo: React.FC<{
  translate: Function;
  showCentralLoaderSpinner?: boolean;
  biLoggerAPI: BiLoggerAPI;
}> = ({ translate: t, showCentralLoaderSpinner = false, biLoggerAPI: bi }) => {
  const learnMoreLink = t(
    'githubIntegration.connect_modal.sign_in.learn_more_link',
  );
  return (
    <div
      className={styles.container}
      data-hook={dataHooks.connectModal.signIn.container}
    >
      <Heading appearance="h3" dataHook={dataHooks.connectModal.signIn.header}>
        {t('githubIntegration.connect_modal.sign_in.header')}
      </Heading>
      <Spacer type="Spacer04" />
      {showCentralLoaderSpinner ? (
        <CenterLoaderSpinner
          texts={[
            t('githubIntegration.connect_modal.sign_in.loader.text1'),
            t('githubIntegration.connect_modal.sign_in.loader.text2'),
          ]}
        />
      ) : (
        <RichText
          className={styles.signInText}
          type="T05"
          dataHook={dataHooks.connectModal.signIn.descriptionContainer}
        >
          <span data-hook={dataHooks.connectModal.signIn.description}>
            {t('githubIntegration.connect_modal.sign_in.description')}{' '}
          </span>
          <a
            href={learnMoreLink}
            target="_blank"
            rel="noreferrer"
            data-hook={dataHooks.connectModal.signIn.learn_more}
            onClick={() => {
              bi.report(
                signInAction({
                  action: MODALS_ACTIONS.LEARN_MORE,
                  referralUrl: learnMoreLink,
                }),
              );
            }}
          >
            {t('githubIntegration.connect_modal.sign_in.learn_more')}
          </a>
        </RichText>
      )}
    </div>
  );
};

export default SignInInfo;
