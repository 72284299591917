import { CodeReusePkgVersions } from './codeReuseServerAPI';

interface PackagesCacheService {
  getCodeReusePkgVersions: (
    name: string,
    versionsFetcher: (name: string) => Promise<CodeReusePkgVersions>,
  ) => Promise<CodeReusePkgVersions>;
  clear: () => void;
}

const packagesCacheService = (): PackagesCacheService => {
  let codeReuseVersionCache: {
    [name: string]: CodeReusePkgVersions;
  } = {};

  const findInCache = (name: string) => codeReuseVersionCache[name];
  const addToCache = (name: string, versions: CodeReusePkgVersions) =>
    (codeReuseVersionCache[name] = versions);

  const getCodeReusePkgVersions = async (
    name: AnyFixMe,
    versionsFetcher: AnyFixMe,
  ) => {
    let pkgVersions = findInCache(name);
    if (!pkgVersions) {
      pkgVersions = await versionsFetcher(name);
      addToCache(name, pkgVersions);
    }
    return pkgVersions;
  };

  return {
    getCodeReusePkgVersions,
    clear: () => (codeReuseVersionCache = {}),
  };
};

let packagesCacheInstanse: PackagesCacheService | null = null;

export const packagesCacheFactory: () => PackagesCacheService = () => {
  packagesCacheInstanse = packagesCacheInstanse || packagesCacheService();
  return packagesCacheInstanse;
};
