// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NKHxrE{display:flex;flex-direction:column;padding-top:16px;padding-bottom:16px}.l89cfU{display:flex;flex-direction:row}.IBskyJ{max-width:160px}.XctOLb{display:flex;flex-direction:row;align-items:center}.FeCUCd{color:#595d70}.nUSSd_{margin-right:11.5px}.cHP6Jd{visibility:visible;margin-left:auto}.BkUosT{visibility:hidden;margin-left:auto}.jG3wAx{margin-left:24px;margin-right:16px}.Kb5GPQ .OFvYNY{color:#868aa5}.Kb5GPQ .VjE8E2 *{font-size:13px;line-height:13px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "NKHxrE",
	"repo-container": "l89cfU",
	"repoContainer": "l89cfU",
	"repo-button": "IBskyJ",
	"repoButton": "IBskyJ",
	"branch-container": "XctOLb",
	"branchContainer": "XctOLb",
	"description": "FeCUCd",
	"context-menu-icon": "nUSSd_",
	"contextMenuIcon": "nUSSd_",
	"context-menu-shown": "cHP6Jd",
	"contextMenuShown": "cHP6Jd",
	"context-menu-hidden": "BkUosT",
	"contextMenuHidden": "BkUosT",
	"overview-container": "jG3wAx",
	"overviewContainer": "jG3wAx",
	"text-wrapper": "Kb5GPQ",
	"textWrapper": "Kb5GPQ",
	"overview-texts-container": "OFvYNY",
	"overviewTextsContainer": "OFvYNY",
	"learn-more-link": "VjE8E2",
	"learnMoreLink": "VjE8E2"
};
export default ___CSS_LOADER_EXPORT___;
