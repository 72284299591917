import {
  fedops,
  getMasterPageFileId,
  utilsCreator as wixCodeUtilsCreator,
} from '@wix/wix-code-common';
import fileSystemActions from '@/infra/redux-state/actions/fileSystemActions';
import { Store } from '@wix/wix-code-common-components';
import { consts } from '@wix/wix-code-consts';

import once_ from 'lodash/once';
import _ from 'lodash';
import {
  EditorAPI,
  FileDescriptor,
  LegacyEditorDependencies,
} from '@wix/wix-code-plugin-contracts';
import { captureError } from '@/infra/monitoring';

export default once_(
  ({
    experiment,
    platform,
    util,
    constants,
  }: Pick<
    LegacyEditorDependencies,
    'experiment' | 'platform' | 'util' | 'constants'
  >) => {
    const wixCodeUtils = wixCodeUtilsCreator({ experiment, platform, util });
    async function _preloadSiteCodeFilesTree(
      editorAPI: EditorAPI,
      store: Store,
    ) {
      const fs = editorAPI.wixCode.fileSystem;
      function getFiles() {
        return _.values(_.omit(fs.getRoots(), 'schemas', 'pages'));
      }

      function isOrphanFile(fileId: string) {
        const pageId = wixCodeUtils.getPageIdFromFileId(fileId);
        const validPageIds = editorAPI.pages.getPageIdList();
        const validLightBoxIds = editorAPI.pages.popupPages
          .getDataList()
          .map((p) => p.id);
        const validIds = [
          consts.SITE_JS_PAGE_ID,
          ...validPageIds,
          ...validLightBoxIds,
        ];
        return !validIds.includes(pageId);
      }

      function shouldReadFile(fileId: string) {
        if (wixCodeUtils.isBackendFile(editorAPI, fileId)) {
          const fileName = fileId.substring(fileId.lastIndexOf('/') + 1);
          if (
            [
              consts.WIX_CODE_PACKAGE_JSON_FILE_NAME,
              consts.AUTHORIZATION_CONFIG_FILE_NAME,
            ].includes(fileName as any)
          ) {
            return false;
          }
        }

        if (wixCodeUtils.isPageFile(editorAPI, fileId)) {
          return !isOrphanFile(fileId);
        }
        return true;
      }

      function listFiles(files: any) {
        const getFile = (file: FileDescriptor) => {
          if (file.directory) {
            return fs.getChildren(file).then((children) =>
              listFiles(children).then(
                ({ filesWithContent = [], dirs = [] }) => ({
                  filesWithContent,
                  dirs: [...dirs, { file, children }],
                }),
              ),
            );
          }
          if (shouldReadFile(file.location)) {
            return fs.readFile(file).then((content: any) => ({
              filesWithContent: [{ path: file.location, content }],
              dirs: [],
            }));
          }
          return Promise.resolve({ filesWithContent: [], dirs: [] });
        };
        return Promise.all(files.map(getFile)).then((results) =>
          results.reduce(
            (acc, result) => ({
              filesWithContent: [
                ...acc.filesWithContent,
                ...result.filesWithContent,
              ],
              dirs: [...acc.dirs, ...result.dirs],
            }),
            { filesWithContent: [], dirs: [] },
          ),
        );
      }

      async function load() {
        await editorAPI.wixCode.fileSystem.loadAllFiles();
        const { filesWithContent, dirs } = await listFiles(getFiles());
        store.dispatch(fileSystemActions.setFiles(filesWithContent));
        editorAPI.wixCode.fileSystem.cacheNonExistingPublicPages();
        store.dispatch(fileSystemActions.setDirs(dirs));
      }

      async function expandCodeFilesTreeSections() {
        store.dispatch(
          fileSystemActions.setExpanded(getMasterPageFileId(), true) as any,
        );

        const roots = editorAPI.wixCode.fileSystem.getRoots();
        store.dispatch(
          fileSystemActions.setExpanded(roots.public.location, true),
        );
        store.dispatch(
          fileSystemActions.setExpanded(roots.backend.location, true),
        );
      }
      try {
        fedops
          .getLogger()
          .interactionStarted(fedops.interactions.preloadSiteCodeFiles);

        await load();
        await expandCodeFilesTreeSections();

        fedops
          .getLogger()
          .interactionEnded(fedops.interactions.preloadSiteCodeFiles);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.debug("failed to preload site's code files: ", e);
        captureError(e);
      }
    }
    return _preloadSiteCodeFilesTree;
  },
);
