import _ from 'lodash';

function isCodeBehavior(behaviorDef) {
  return (
    behaviorDef.behavior &&
    behaviorDef.behavior.type === 'widget' &&
    behaviorDef.behavior.name === 'runCode'
  );
}

function getFilteredBehaviors(stringifiedBehaviors) {
  const behaviors = JSON.parse(stringifiedBehaviors);
  const filteredBehaviors = _.reject(behaviors, isCodeBehavior);
  return JSON.stringify(filteredBehaviors);
}

function removeCodeRelatedBehaviors(editorAPI, serializedComp) {
  if (!_.has(serializedComp, 'behaviors')) {
    return serializedComp;
  }
  if (_.isPlainObject(serializedComp.behaviors)) {
    const stringifiedBehaviors = _.get(serializedComp, 'behaviors.items', []);
    _.set(
      serializedComp,
      'behaviors.items',
      getFilteredBehaviors(stringifiedBehaviors),
    );
  }
  return serializedComp;
}

export default removeCodeRelatedBehaviors;
