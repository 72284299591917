import './deprecatedAppBuilderTree.global.scss';
import React, { Fragment } from 'react';
import { WIX_CODE } from '@wix/app-definition-ids';
import { Provider } from 'react-redux';
import { wixCodeReduxContext } from '@wix/wix-code-common-components';
import { platformApps } from '@wix/wix-code-common';

import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import storeManager from '@/infra/redux-state/store/storeManager';
import codePropTypesCreator from '../propTypes';
import bi from '../../../bi/bi';
import collectionsTreeCreator from '../collectionsTree/collectionsTree';
import errorBoundaryComponent from '../wixCodeErrorBoundaryComponent';
import leftPaneStateReader from '@/infra/redux-state/reducers/leftPaneStateReader';
import wixCodeExposureCreator from '../wixCodeExposure/wixCodeExposure';
import deprecatedAppBuilderTreeConstants from './constants';
import searchPanelContainerCreator from '@/sidebar/searchPanel';
import { TranslateBaseUiIfNeeded } from '@/infra/i18n/TranslateBaseUiIfNeeded';

import once_ from 'lodash/once';

const _deprecatedAppBuilderTree = once_(
  ({
    baseUI,
    constants,
    core,
    experiment,
    platform,
    stateManagement,
    util,
    packagesViewDefinition,
  }) => {
    const CollectionsTree = collectionsTreeCreator({
      baseUI,
      constants,
      core,
      experiment,
      platform,
      util,
      stateManagement,
    });
    const SearchPanelContainer = searchPanelContainerCreator({
      core,
      experiment,
      platform,
      util,
    });
    const WixCodeExposure = wixCodeExposureCreator({
      stateManagement,
      util,
    });
    const CodePropTypes = codePropTypesCreator({ constants });

    const deprecatedAppBuilderTree = createReactClass({
      displayName: 'DeprecatedAppBuilderTree',
      propTypes: {
        context: CodePropTypes.context.isRequired,
        performingMouseMoveAction: PropTypes.bool,
        overriddenItems: PropTypes.array,
        pagesLastUpdateTime: PropTypes.number,
      },
      mixins: [core.mixins.editorAPIMixin],
      getInitialState() {
        return {
          pagesLastUpdateTime: this.props.pagesLastUpdateTime,
          viewMode: deprecatedAppBuilderTreeConstants.VIEW_MODE.SITE_STRUCTURE,
        };
      },
      UNSAFE_componentWillMount: function componentWillMount() {
        this.store = storeManager.getStore();
      },
      componentDidMount() {
        this.subscribeToLeftPaneViewMode();
        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.CODE_TOP_BAR_TOGGLE,
        );
      },
      shouldComponentUpdate() {
        return !this.props.performingMouseMoveAction;
      },
      componentWillUnmount() {
        this.unsubscribeFromWixCodeStore();
        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.CODE_TOP_BAR_TOGGLE,
        );
      },
      subscribeToLeftPaneViewMode() {
        this.unsubscribeFromWixCodeStore = this.store.subscribe(() => {
          this.setState({
            viewMode: leftPaneStateReader.getLeftPaneViewMode(
              this.store.getState(),
            ),
          });
        });
      },
      getIdeTreeComponents() {
        const LEFTTREE = constants.ROOT_COMPS.LEFTTREE;
        const ideTreeDefinitions = [
          {
            key: LEFTTREE.COLLECTIONS,
            compType: CollectionsTree,
            props: {
              devModeContext: this.props.context,
              labelOverride: util.translate('WixCode_TreeView_Data_Label'),
              onCollectionSelected: this.onCollectionSelected,
              onCreateCollection: this.onCreateCollection,
              onAddExternalCollections: this.onAddExternalCollections,
              onEditExternalCollections: this.onEditExternalCollections,
              onRemoveExternalCollections: this.onRemoveExternalCollections,
            },
          },
        ].concat(packagesViewDefinition || []);

        // Without using original compType object reference component may unmount unexpectedly for each render
        const useOriginalCompTypeByRef = (definition) => {
          const originalDefinition = _.find(ideTreeDefinitions, {
            key: definition.key,
          });
          if (originalDefinition) {
            return _.defaults(
              { compType: originalDefinition.compType },
              definition,
            );
          }
          return definition;
        };

        return _(ideTreeDefinitions)
          .thru((definitions) =>
            util.overrideUtils.applyOverrides(
              definitions,
              this.props.overriddenItems,
            ),
          )
          .reject({ shouldShow: false })
          .map((definition) => useOriginalCompTypeByRef(definition))
          .value();
      },
      onCollectionSelected(collectionId) {
        const editorAPI = this.getEditorAPI();
        editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_ITEM, {
          item_name: collectionId,
          item_type: 'collection',
        });
        editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_COLLECTION, {
          origin: 'ideTree',
          collection_name: collectionId,
        });

        editorAPI.panelManager.closeAllPanels();

        platformApps.notifyDataBinding(editorAPI, {
          eventType: 'treeItemSelected',
          eventPayload: {
            itemName: collectionId,
            instance: editorAPI.wixCode.getClientSpec().instance,
            origin: 'ideTree',
          },
        });
      },
      sendClickInSiteStructurePlatformEvent(eventType, payload = {}) {
        const editorAPI = this.getEditorAPI();
        platformApps.notifyDataBinding(editorAPI, {
          eventType,
          eventPayload: {
            origin: 'ideTree',
            clickedInSiteStructure: true,
            ...payload,
          },
        });
      },
      onCreateCollection() {
        if (!this.isAppNamespaceDefined()) {
          this.openAppSettingsPanelAtNamespaceTab();
          this.whenAppSettingsPanelIsClosed(() => {
            if (this.isAppNamespaceDefined()) {
              this.notifyDataBindingPlatformAppToOpenCreateCollectionPanel();
            }
          });
        } else {
          this.notifyDataBindingPlatformAppToOpenCreateCollectionPanel();
        }
      },
      isAppNamespaceDefined() {
        const appNamespace = this.getAppNamespace();
        return !_.isUndefined(appNamespace);
      },
      getAppNamespace() {
        const editorAPI = this.getEditorAPI();
        const state = editorAPI.store.getState();
        const appNamespace =
          stateManagement.editorPlugins.applicationStudio.selectors.getNamespace(
            state,
          );
        return appNamespace;
      },
      openAppSettingsPanelAtNamespaceTab() {
        const editorAPI = this.getEditorAPI();
        editorAPI.store.dispatch(
          stateManagement.editorPlugins.panels.actions.updateOrOpenPanel(
            constants.APP_STUDIO.SETTINGS_PANEL.NAME,
            {
              initialTab: constants.APP_STUDIO.SETTINGS_PANEL.OPTIONS.NAMESPACE,
            },
            /* leavePanelsOpen */ true,
          ),
        );
      },
      whenAppSettingsPanelIsClosed(callback) {
        const editorAPI = this.getEditorAPI();
        const unsubscribe = editorAPI.store.subscribe(() => {
          if (!this.isAppSettingsPanelOpen()) {
            unsubscribe();
            callback();
          }
        });
      },
      isAppSettingsPanelOpen() {
        const editorAPI = this.getEditorAPI();
        const state = editorAPI.store.getState();
        const openPanels =
          stateManagement.editorPlugins.panels.selectors.selectOpenPanels(
            state,
          );
        return openPanels.some(
          ({ name }) => name === constants.APP_STUDIO.SETTINGS_PANEL.NAME,
        );
      },
      notifyDataBindingPlatformAppToOpenCreateCollectionPanel() {
        this.sendClickInSiteStructurePlatformEvent(
          platform.constants.EVENTS.createCollectionButtonClicked,
        );
      },
      onAddExternalCollections() {
        this.sendClickInSiteStructurePlatformEvent(
          platform.constants.EVENTS.addExternalDbDriverClicked,
        );
      },
      onEditExternalCollections(namespace) {
        this.sendClickInSiteStructurePlatformEvent(
          platform.constants.EVENTS.editExternalDbDriverClicked,
          { namespace },
        );
      },
      onRemoveExternalCollections(namespace) {
        this.sendClickInSiteStructurePlatformEvent(
          platform.constants.EVENTS.removeExternalDbDriverClicked,
          { namespace },
        );
      },

      render() {
        return (
          <Provider store={this.store} context={wixCodeReduxContext}>
            <TranslateBaseUiIfNeeded tFunc={util.translate}>
              <div
                className="wix-code-site-structure"
                data-aid="wix-code-ide-tree"
              >
                <div className="wix-code-ide-tree">
                  <div className="wix-code-ide-tree-parts">
                    {this.state.viewMode ===
                      deprecatedAppBuilderTreeConstants.VIEW_MODE
                        .SITE_STRUCTURE &&
                      this.getIdeTreeComponents().map(
                        (compDef, compDefIndex) => (
                          <Fragment key={`compDef-${compDefIndex}`}>
                            {compDef.moduleName && (
                              <util.lazyComponent
                                moduleName={compDef.moduleName}
                                {...compDef.props}
                                key={compDef.key}
                              />
                            )}

                            {!compDef.moduleName && (
                              <compDef.compType
                                {...compDef.props}
                                key={compDef.key}
                              />
                            )}
                          </Fragment>
                        ),
                      )}
                    {this.state.viewMode ===
                      deprecatedAppBuilderTreeConstants.VIEW_MODE.SEARCH && (
                      <SearchPanelContainer />
                    )}
                  </div>
                </div>
                <WixCodeExposure key="wix-code-exposure" />
              </div>
            </TranslateBaseUiIfNeeded>
          </Provider>
        );
      },
    });

    const mapStateToProps = ({ state }, props) => {
      // pagesLastUpdateTime is used here to force an UI update from the wix-code app.
      const { pagesLastUpdateTime } =
        stateManagement.editorPlugins.appsStore.selectors.getAppStore(
          state,
          WIX_CODE,
        ) || {};

      return {
        overriddenItems:
          props.overriddenItems ||
          stateManagement.editorPlugins.leftTree.selectors.getOverriddenItems(
            state,
          ),
        pagesLastUpdateTime,
      };
    };

    const Connected = util.hoc.connect(
      util.hoc.STORES.STATE_ONLY,
      mapStateToProps,
      undefined,
      undefined,
      undefined,
      errorBoundaryComponent,
    )(deprecatedAppBuilderTree);
    Connected.pure = deprecatedAppBuilderTree;
    return Connected;
  },
);

export default _deprecatedAppBuilderTree;
