export class InitDevEditorError extends Error {
  titleKey;
  contentKey;
  constructor() {
    super();
    this.titleKey = 'localEditor.error_modal.error_connect_to_cli.title';
    this.contentKey = 'localEditor.error_modal.error_connect_to_cli.content';
  }
}
export class SyncDevEditorChangesError extends Error {
  titleKey;
  contentKey;
  constructor() {
    super();
    this.titleKey =
      'localEditor.error_modal.error_sync_changes_with_editor.title';
    this.contentKey =
      'localEditor.error_modal.error_sync_changes_with_editor.content';
  }
}

export class CreateNewRevisionError extends Error {
  titleKey;
  contentKey;
  constructor() {
    super();
    this.titleKey = 'localEditor.error_modal.error_create_revision.title';
    this.contentKey = 'localEditor.error_modal.error_create_revision.content';
  }
}
