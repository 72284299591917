import {
  ModalRenderer,
  WixCodeEditorAdapterAPI,
} from '@wix/wix-code-editor-adapter';
import {
  BiLoggerAPI,
  ClassicEditorAPI,
  ExperimentsAPI,
  PackagesAndAppsAPI,
} from '@wix/wix-code-plugin-contracts';
import { Shell, SlotKey } from '@wix/wix-code-repluggable';
import React from 'react';
import BeforeConnectModal from './components/BeforeConnectModal/BeforeConnectModal';
import DisconnectModal from './components/DisconnectModal/DisconnectModal';
import createConnectInPhasesModal from './components/ConnectInPhasesModal/ConnectInPhasesModal';
import InstructionsModal from './components/LocalDevSetupModal/LocalDevSetupModal';
import { GithubPrivateAPI } from './githubPrivateAPI';
import { TranslationProvider } from './i18n';
import { BiLoggerContext } from './context/biLoggerContext';
import GithubNotConnectable from './components/GithubNotConnectableModal/GithubNotConnectableModal';
import { GithubConnectionIssuesModal } from './components/GithubConnectionIssuesModal/GithubConnectionIssuesModal';
import { ON_BOARDING_SUPPORTED_ERRORS } from './constants';

export interface GithubModalsAPI {
  showInstructionsModal: () => void;
  openConnectModal: () => void;
  openDisconnectModal: () => void;
  openConnectionIssuesModal: (errorType: ON_BOARDING_SUPPORTED_ERRORS) => void;
}

export const GithubModalsAPIKey: SlotKey<GithubModalsAPI> = {
  name: 'GithubModalsAPI',
  public: false,
};

interface GithubModalsCreatorParams {
  shell: Shell;
  wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI;
  githubAPI: GithubPrivateAPI;
  classicEditorAPI: ClassicEditorAPI;
  biLoggerAPI: BiLoggerAPI;
  packagesAndAppsAPI: PackagesAndAppsAPI;
  experimentsAPI: ExperimentsAPI;
}

export const createGithubModalsAPI = ({
  shell,
  wixCodeEditorAdapterAPI,
  githubAPI,
  classicEditorAPI,
  biLoggerAPI,
  packagesAndAppsAPI,
  experimentsAPI,
}: GithubModalsCreatorParams): GithubModalsAPI => {
  const modals = createModals();
  const showInstructionsModal = () => {
    const repo = githubAPI.getGithubRepository();
    if (!repo) {
      throw new Error(
        'Cannot open success modal because githubRepository is undefined',
      );
    }

    openModal({
      modalRenderer: (closeModal) => (
        <modals.InstructionsModal
          closeModal={closeModal}
          githubRepository={repo}
        />
      ),
    });
  };

  const siteHasVeloPackages = async () => {
    return packagesAndAppsAPI.isVeloDependenciesInstalled();
  };

  const openGithubNotConnectableModal = () => {
    openModal({
      modalRenderer: (closeModal) => (
        <modals.GithubNotConnectableModal closeModal={closeModal} />
      ),
    });
  };

  const openConnectModal = async () => {
    if (
      !experimentsAPI.isOpen('specs.wixCode.allowGithubWithPackages') &&
      (await siteHasVeloPackages())
    ) {
      openGithubNotConnectableModal();
      return;
    } else if (!githubAPI.didUserAcceptGithubTerms()) {
      openGithubTermsModal();
      return;
    }

    classicEditorAPI.editorAPI.saveManager.saveInBackground(
      () => {},
      () => {},
    );

    openModal({
      modalRenderer: (closeModal) => (
        <modals.ConnectInPhasesModal
          githubAPI={githubAPI}
          getSiteName={classicEditorAPI.editorAPI.siteName.get}
          closeModal={closeModal}
          onPhasesCompleted={showInstructionsModal}
          classicEditorAPI={classicEditorAPI}
        />
      ),
    });
  };

  const openGithubTermsModal = () => {
    openModal({
      modalRenderer: (closeModal) => (
        <modals.BeforeConnectModal
          closeModal={closeModal}
          onContinue={() => {
            githubAPI.acceptGithubTerms();
            closeModal();
            openConnectModal();
          }}
        />
      ),
    });
  };

  const openDisconnectModal = () => {
    openModal({
      modalRenderer: (closeModal) => (
        <modals.DisconnectModal closeModal={closeModal} githubAPI={githubAPI} />
      ),
    });
  };

  const openConnectionIssuesModal = (
    errorType: ON_BOARDING_SUPPORTED_ERRORS,
  ) => {
    openModal({
      modalRenderer: (closeModal) => (
        <modals.GithubConnectionIssuesModal
          closeModal={closeModal}
          githubAPI={githubAPI}
          errorType={errorType}
        />
      ),
      closeOnClickOutside: false,
    });
  };

  return {
    openConnectModal,
    showInstructionsModal,
    openDisconnectModal,
    openConnectionIssuesModal,
  };

  interface OpenModalParams {
    modalRenderer: ModalRenderer;
    onClose?: () => void;
    closeOnClickOutside?: boolean;
  }

  function openModal({
    modalRenderer,
    onClose,
    closeOnClickOutside,
  }: OpenModalParams) {
    wixCodeEditorAdapterAPI.modalAPI.showModal(modalRenderer, {
      backdrop: true,
      closeOnClickOutside: closeOnClickOutside ?? true,
      ...(onClose ? { onClose } : {}),
    });
  }

  function createModals() {
    const ConnectInPhasesModal = createConnectInPhasesModal(shell, githubAPI);
    return {
      InstructionsModal: withContext(InstructionsModal),
      BeforeConnectModal: withContext(BeforeConnectModal),
      ConnectInPhasesModal: withContext(ConnectInPhasesModal),
      DisconnectModal: withContext(DisconnectModal),
      GithubNotConnectableModal: withContext(GithubNotConnectable),
      GithubConnectionIssuesModal: withContext(GithubConnectionIssuesModal),
    };
  }

  function withContext<P extends object>(
    Comp: React.ComponentType<P>,
  ): React.ComponentType<P> {
    return (props) => {
      return (
        <TranslationProvider>
          <BiLoggerContext.Provider value={biLoggerAPI}>
            <Comp {...props} />
          </BiLoggerContext.Provider>
        </TranslationProvider>
      );
    };
  }
};
