import React from 'react';
import { TextInput, Text } from '@wix/wix-base-ui';
import styles from './styles.scss';
import classNames from 'classnames';

import { PasswordInput } from './PasswordInput';

import { InputFieldDescription } from './InputFieldDescription';

type InputFieldProps = {
  didUserStartTyping: boolean;
  infoIconBIName: string;
  infoIconDatahook: string;
  textInputDatahook: string;
  className?: string[];
  labelText: string;
  placeHolder?: string;
  inputValue: string;
  onChange: (value: string) => void;
  isValid: boolean;
  invalidMessage: string;
  isDisabled?: boolean;
  automationId?: { label: string; input: string };
  infoIconText?: string;
  linkText?: string;
  onLinkClick?: () => void;
  type?: string;
  maxLength?: number;
  hideInput?: boolean;
  inputFieldPrefix?: string;
};

export const InputField: React.FC<InputFieldProps> = ({
  infoIconBIName,
  infoIconDatahook,
  textInputDatahook,
  didUserStartTyping,
  labelText = '',
  inputValue = '',
  onChange,
  isValid,
  invalidMessage,
  isDisabled = false,
  placeHolder = '',
  automationId,
  infoIconText = '',
  linkText = '',
  onLinkClick,
  className = [],
  maxLength,
  hideInput,
  inputFieldPrefix,
}) => {
  return (
    <div className={classNames(styles.inputWithLabelContainer, ...className)}>
      <InputFieldDescription
        automationId={automationId}
        labelText={labelText}
        maxLength={maxLength}
        inputValue={inputValue}
        textInputDatahook={textInputDatahook}
        infoIconDatahook={infoIconDatahook}
        infoIconText={infoIconText}
        linkText={linkText}
        onLinkClick={onLinkClick}
        infoIconBIName={infoIconBIName}
      />
      {hideInput ? (
        <PasswordInput
          didUserStartTyping={didUserStartTyping}
          inputValue={inputValue}
          placeholder={placeHolder}
          automationId={automationId}
          onChange={onChange}
          isValid={didUserStartTyping ? isValid : true}
          invalidMessage={invalidMessage}
          isDisabled={isDisabled}
          textInputDatahook={textInputDatahook}
        />
      ) : inputFieldPrefix ? (
        <div className={styles.inputWithPrefixContainer}>
          <div className={styles.prefixContainer}>
            <Text weight="bold">{inputFieldPrefix}</Text>
          </div>
          <TextInput
            dataHook={textInputDatahook}
            hideSuccessIndication
            placeholder={placeHolder}
            automationId={automationId?.input}
            value={inputValue}
            onChange={onChange}
            isValid={didUserStartTyping ? isValid : true}
            invalidMessage={invalidMessage}
            disabled={isDisabled}
            shouldTranslate={false}
            className={styles.textInput}
          />
        </div>
      ) : (
        <TextInput
          dataHook={textInputDatahook}
          hideSuccessIndication
          placeholder={placeHolder}
          automationId={automationId?.input}
          value={inputValue}
          onChange={onChange}
          isValid={didUserStartTyping ? isValid : true}
          invalidMessage={invalidMessage}
          disabled={isDisabled}
          shouldTranslate={false}
        />
      )}
    </div>
  );
};
