import Experiments, { ExperimentsBag } from '@wix/wix-experiments';
import { captureError } from '../monitoring';

const WIX_CODE_EXPERIMENTS_SCOPE = 'wix-code';

export interface ClientExperimentService {
  isOpen(spec: string): boolean;
  getRunningExperiments(): ExperimentsBag;
}

let _service: ClientExperimentService | null = null;
export const clientExperiments = (): ClientExperimentService => {
  if (_service) {
    return _service;
  }
  throw Error('ClientExperimentService is not yet initialized');
};

export const initClientExperimentService = async (
  options: {
    _staticExperiments?: ExperimentsBag /* for testing */;
  } = {},
): Promise<ClientExperimentService> => {
  const { _staticExperiments } = options;
  if (_service && !_staticExperiments) {
    return _service;
  }
  const experiments = new Experiments({
    onError: (e: Error) => {
      captureError(e);
      console.error(e);
    },
  });

  if (_staticExperiments) {
    experiments.add(_staticExperiments);
  } else {
    await experiments.load(WIX_CODE_EXPERIMENTS_SCOPE);
  }

  _service = createService(experiments);
  return _service;
};

const createService = (experiments: Experiments): ClientExperimentService => ({
  isOpen: (spec: string) => ['new', 'true'].includes(experiments.get(spec)),
  getRunningExperiments: () => experiments.all(),
});
