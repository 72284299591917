import NewIntegrationIcon from '../../assets/icons/postPublishModalNew.svg';
import UpdatedIntegrationIcon from '../../assets/icons/postPublishModalUpdated.svg';
import NewAndUpdatedIcon from '../../assets/icons/postPublishModalNewAndUpdated.svg';
import ErrorIcon from '../../assets/icons/postPublishModalError.svg';
import React from 'react';

export interface postPublishModalType {
  [key: string]: {
    title: string;
    text: string;
    linkText: string;
    confimButtonText: string;
    preLinkText?: string;
    icon: React.FC;
  };
}

export const postPublishModalTypes: postPublishModalType = {
  singleNew: {
    title: 'Post_Publish_Modal_New_Integration',
    text: 'Post_Publish_Modal_New_Integration_Message1',
    linkText: 'Post_Publish_Modal_Test_Integration_Link_Text',
    confimButtonText: 'Post_Publish_Modal_Go_To_Dashboard',
    preLinkText: 'Post_Publish_Modal_New_Integration_Message2',
    icon: NewIntegrationIcon,
  },
  singleUpdated: {
    title: 'Post_Publish_Modal_Updated_Integration',
    text: 'Post_Publish_Modal_Updated_Integration_Message',
    linkText: 'Post_Publish_Modal_Test_Integration_Link_Text',
    confimButtonText: 'Post_Publish_Modal_Go_To_Dashboard',
    preLinkText: 'Post_Publish_Modal_New_Integration_Message2',
    icon: UpdatedIntegrationIcon,
  },
  multipleNew: {
    title: 'Post_Publish_Modal_New_Integrations',
    text: 'Post_Publish_Modal_New_Integrations_Message1',
    linkText: 'Post_Publish_Modal_Test_Integrations_Link_Text',
    confimButtonText: 'Post_Publish_Modal_Go_To_Dashboard',
    preLinkText: 'Post_Publish_Modal_New_Integration_Message2',
    icon: NewIntegrationIcon,
  },
  multipleNewDifferentTypes: {
    title: 'Post_Publish_Modal_New_Integrations_Different_Types',
    text: 'Post_Publish_Modal_New_Integrations_Message1_Different_Types',
    linkText: 'Post_Publish_Modal_Test_Integrations_Link_Text',
    confimButtonText: 'Post_Publish_Modal_Go_To_Dashboard',
    preLinkText: 'Post_Publish_Modal_New_Integration_Message2',
    icon: NewIntegrationIcon,
  },
  multipleUpdated: {
    title: 'Post_Publish_Modal_Updated_Integrations',
    text: 'Post_Publish_Modal_Updated_Integrations_Message',
    linkText: 'Post_Publish_Modal_Test_Integrations_Link_Text',
    confimButtonText: 'Post_Publish_Modal_Go_To_Dashboard',
    preLinkText: 'Post_Publish_Modal_New_Integration_Message2',
    icon: UpdatedIntegrationIcon,
  },
  multipleUpdatedDifferentTypes: {
    title: 'Post_Publish_Modal_Updated_Integrations_Different_Types',
    text: 'Post_Publish_Modal_Updated_Integrations_Message_Different_Types',
    linkText: 'Post_Publish_Modal_Test_Integrations_Link_Text',
    confimButtonText: 'Post_Publish_Modal_Go_To_Dashboard',
    preLinkText: 'Post_Publish_Modal_New_Integration_Message2',
    icon: UpdatedIntegrationIcon,
  },
  mixedUpdatedAndNew: {
    title: 'Post_Publish_Modal_New_And_Updated_Integrations',
    text: 'Post_Publish_Modal_New_Integration_Message1',
    linkText: 'Post_Publish_Modal_Test_Integrations_Link_Text',
    confimButtonText: 'Post_Publish_Modal_Go_To_Dashboard',
    preLinkText: 'Post_Publish_Modal_New_Integration_Message2',
    icon: NewAndUpdatedIcon,
  },
  errors: {
    title: 'Post_Publish_Modal_Error',
    text: 'Post_Publish_Modal_Error_Message',
    linkText: 'Post_Publish_Modal_Error_Link_Text',
    confimButtonText: 'Post_Publish_Modal_Go_To_Logs',
    icon: ErrorIcon,
  },
};
