import { OnboardingState } from '../types/GithubTypes';
import { GithubState } from './index';

export const createSelectors = (getState: () => GithubState) => {
  return {
    isEnabled: () =>
      getState().onboarding.onboardingState === OnboardingState.GITHUB_ENABLED,
    onboardingState: () => getState().onboarding.onboardingState,
    onboardingError: () => getState().onboarding.onboardingError,
    githubUser: () => getState().onboarding.githubUser,
    githubRepository: () => getState().onboarding.githubRepository,
    isVeloAlreadyInstall: () => getState().onboarding.isVeloAppInstalled,
    commitHistoryUrl: () => getState().onboarding.commitHistoryUrl,
    commits: () => getState().onboarding.commits,
    isLoadingOnboardingStatus: () =>
      getState().onboarding.isLoadingOnboardingStatus,
    didUserAcceptGithubTerms: () => getState().acceptedGithubTerms,
  };
};
