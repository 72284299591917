import { ActionType } from '../actions/types';
import { NewChild } from '@wix/wix-code-plugin-contracts';

type SetNewFileRenameInput = {
  newChild: NewChild;
  type: ActionType.SET_NEW_FILE_RENAME_INPUT;
};

export type FilesTreeState = { newChild: NewChild | null };

const initialState: FilesTreeState = {
  newChild: null,
};
export type FileTreeReducerAction = SetNewFileRenameInput;

export const fileTreeReducer = (
  state = initialState,
  action: FileTreeReducerAction,
) => {
  switch (action.type) {
    case ActionType.SET_NEW_FILE_RENAME_INPUT:
      return { ...state, newChild: action.newChild };
    default:
      return state;
  }
};
