import * as React from 'react';
import { useContext } from 'react';
import dataHooks from '../../../../../dataHooks';
import s from '../../packagesListItem/PackagesListItem.scss';
import cx from 'classnames';
import { useHover } from '@/utils/useHover';
import {
  PackagesListItemControls,
  PackagesListItemControlsProps,
} from '../../packagesListItem/PackagesListItemControls';
import { NpmPackageListItemDetails } from '../../packagesListItem/NpmPackageListItemDetails';
import { CodeReusePackageListItemDetails } from '../../packagesListItem/CodeReusePackageListItemDetails';
import { PackageItemContext } from '../../PackageItemContext';
import { PackageAutoUpdateToggle } from '../../packagesListItem/PackageAutoUpdateToggle';
import {
  isInstalledReusePackageByOthers,
  isInstalledTestVersion,
} from '../../../../../utils';

interface InstalledPackagesListItemProps {
  onClick: () => void;
  isSelected: boolean;
}

export const InstalledPackagesListItem: React.FC<
  InstalledPackagesListItemProps
> = (props: InstalledPackagesListItemProps) => {
  const { hoverRef, isHovered } = useHover();
  const { onClick, isSelected } = props;
  const { installedPkg, isCodeReusePackage, pkgName } =
    useContext(PackageItemContext);
  const controlProps: PackagesListItemControlsProps = {
    isHovered,
    isSelected,
  };

  const isTestVersion = isInstalledTestVersion(installedPkg);

  return (
    <div
      data-hook={dataHooks.PKG_LIST_ITEM}
      ref={hoverRef}
      className={cx(s.packageItem, {
        [s.selected]: isSelected,
      })}
      onClick={onClick}
    >
      <div className={s.row}>
        {isCodeReusePackage ? (
          <CodeReusePackageListItemDetails />
        ) : (
          <NpmPackageListItemDetails />
        )}
        <PackagesListItemControls {...controlProps} />
      </div>
      {isCodeReusePackage &&
        !isTestVersion &&
        !isInstalledReusePackageByOthers(pkgName) && (
          <PackageAutoUpdateToggle selectedVersion={installedPkg?.version} />
        )}
    </div>
  );
};
