import React from 'react';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { wixCodeReduxContext } from '@wix/wix-code-common-components';
import storeManager from '@/infra/redux-state/store/storeManager';
import Console from './console';
import { WixCodeContextProvider } from '@/infra/wixCodeContext/WixCodeContextProvider';
import { LegacyEditorDependencies } from '@wix/wix-code-plugin-contracts';

export const createDevConsole = ({
  legacyEditorDeps,
}: {
  legacyEditorDeps: LegacyEditorDependencies;
}) => {
  const DevConsole = (props: {
    id: string;
    className: string;
    overrideButtonClickMap: Record<string, () => void>;
  }) => (
    <WixCodeContextProvider legacyDependencies={legacyEditorDeps}>
      <ReduxStoreProvider
        store={storeManager.getStore()}
        context={wixCodeReduxContext}
      >
        <Console
          id={props.id}
          className={props.className}
          overrideButtonClickMap={props.overrideButtonClickMap}
        />
      </ReduxStoreProvider>
    </WixCodeContextProvider>
  );

  return DevConsole;
};
