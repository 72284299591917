import * as React from 'react';
import dataHooks from '../../dataHooks';
import { Preloader, InfoIcon } from '@wix/wix-base-ui';
import {
  FilesTreeContextMenu,
  FILE_TREE_QUICK_ACTIONS,
} from './FilesTreeContextMenu';
import cx from 'classnames';
import { FileSubTreeDataSource } from '../FileSubTree';
import {
  FileTreeCustomAction,
  FileTreeCustomFolderAction,
  HiddenFileAction,
} from '@wix/wix-code-plugin-contracts';
import { experimentUtils } from '@wix/wix-code-common';
import { useTranslate } from '@/infra/i18n/useTranslate';

interface FilesTreeShouldAlwaysShowSuffixParams {
  experiment: any;
  isRoot: boolean;
  isTreeHovered: boolean;
  hasPendingOperation: boolean;
  isContextMenuOpen: boolean;
}

export const filesTreeShouldAlwaysShowSuffix = ({
  experiment,
  isRoot,
  isTreeHovered,
  hasPendingOperation,
  isContextMenuOpen,
}: FilesTreeShouldAlwaysShowSuffixParams): boolean => {
  const shouldShowSuffixOnRoot = isRoot && isTreeHovered;
  return shouldShowSuffixOnRoot || hasPendingOperation || isContextMenuOpen;
};

interface FilesTreeSuffixProps {
  hasPendingOperation: boolean;
  onContextMenuClick: (event: React.MouseEvent) => any;
  isContextMenuOpen: boolean;
  handleContextMenuToggle: (isOpen: boolean) => any;
  actionSelectedHandler: (action: FILE_TREE_QUICK_ACTIONS) => any;
  isTopLevelTreeRoot: boolean;
  isBackend: boolean;
  isExternalComponents: boolean;
  dataSource: FileSubTreeDataSource;
  isRoot: boolean;
  isBackendRoutersJs: () => boolean;
  isWebModule: () => boolean;
  hiddenActions?: FILE_TREE_QUICK_ACTIONS[];
  hiddenRootActions?: FILE_TREE_QUICK_ACTIONS[];
  customRootActions?: FileTreeCustomAction[];
  hiddenFolderActions?: FILE_TREE_QUICK_ACTIONS[];
  customFolderActions?: FileTreeCustomFolderAction[];
  hiddenFileActionsByPredicate?: HiddenFileAction[];
  isExcludedFile?: boolean;
  readOnlyMode: boolean;
}

export const FilesTreeSuffix: React.FC<FilesTreeSuffixProps> = ({
  hasPendingOperation,
  onContextMenuClick,
  isContextMenuOpen,
  handleContextMenuToggle,
  actionSelectedHandler,
  isTopLevelTreeRoot,
  isBackend,
  isExternalComponents,
  dataSource,
  isRoot,
  isBackendRoutersJs,
  isWebModule,
  hiddenActions = [],
  hiddenRootActions = [],
  customRootActions,
  hiddenFolderActions = [],
  customFolderActions,
  hiddenFileActionsByPredicate,
  isExcludedFile = false,
  readOnlyMode,
}) => {
  const { t } = useTranslate();
  const { isFolder } = dataSource;

  const getParentFolderName = () => {
    const arr = dataSource.id.split('/');
    if (arr.length >= 2) {
      return arr[arr.length - 2];
    }
    return '';
  };

  const filterHiddenItems = (
    items: FILE_TREE_QUICK_ACTIONS[],
  ): FILE_TREE_QUICK_ACTIONS[] =>
    items.filter((item) => {
      if (isRoot) {
        return (
          !hiddenRootActions.includes(item) && !hiddenActions.includes(item)
        );
      }
      if (isFolder) {
        return (
          !hiddenFolderActions.includes(item) && !hiddenActions.includes(item)
        );
      }
      if (hiddenFileActionsByPredicate) {
        const actionToHide = hiddenFileActionsByPredicate.find((hiddenAction) =>
          hiddenAction.actionsToHide.includes(item),
        );
        if (
          actionToHide &&
          actionToHide.predicate(dataSource.name, getParentFolderName())
        ) {
          return false;
        }
      }
      return !hiddenActions.includes(item);
    });

  const canBeRenamed = () => !isBackendRoutersJs();

  const generateFolderMenuItems = (): FILE_TREE_QUICK_ACTIONS[] => {
    const menuItems: FILE_TREE_QUICK_ACTIONS[] = [];

    if (isBackend) {
      menuItems.push(
        experimentUtils.isWebJsEditorSupport()
          ? 'NEW_WEB_JS_FILE'
          : 'NEW_JSW_FILE',
      );
    }

    if (isExternalComponents) {
      menuItems.push('NEW_JSX_FILE');
    }

    const isTypescriptSupport = experimentUtils.isTypescriptSupport();
    if (isTypescriptSupport) {
      menuItems.push('NEW_TS_FILE');
    }

    return menuItems.concat('NEW_JS_FILE', 'NEW_FILE', 'NEW_FOLDER');
  };

  const folderMenuItems: FILE_TREE_QUICK_ACTIONS[] = generateFolderMenuItems();

  const nonRootMenuItems: FILE_TREE_QUICK_ACTIONS[] = [
    ...((!isExcludedFile && canBeRenamed()
      ? ['RENAME']
      : []) as FILE_TREE_QUICK_ACTIONS[]),
    'DELETE',
    ...((isWebModule()
      ? ['OPEN_WEBMODULE_PERMISSIONS']
      : []) as FILE_TREE_QUICK_ACTIONS[]),
  ];

  const backendMenuItems: FILE_TREE_QUICK_ACTIONS[] = [
    'JOBS_SCHEDULER',
    'HTTP_FUNCTIONS',
    'DATA_HOOKS',
    'EVENTS',
  ];

  let contextMenuItems: FILE_TREE_QUICK_ACTIONS[][] = [
    ...(isFolder ? [filterHiddenItems(folderMenuItems)] : []),
    ...(!isRoot ? [filterHiddenItems(nonRootMenuItems)] : []),
    ...(isRoot && isBackend ? [filterHiddenItems(backendMenuItems)] : []),
  ];

  contextMenuItems = contextMenuItems.filter((item) => item.length > 0);
  customFolderActions = customFolderActions?.filter((customAction) =>
    customAction.predicate(dataSource.id),
  );

  return hasPendingOperation ? (
    <div className="preloader-container-hack">
      <Preloader dataHook={dataHooks.LOADER} className="tiny" />
    </div>
  ) : contextMenuItems.length > 0 ||
    (isRoot && customRootActions) ||
    (isFolder && customFolderActions && customFolderActions.length > 0) ? (
    <FilesTreeContextMenu
      onContextMenuClick={onContextMenuClick}
      menuClassName={cx('wix-code-file-tree-dd', {
        highlight: isContextMenuOpen,
      })}
      isContextMenuOpen={isContextMenuOpen}
      handleContextMenuToggle={handleContextMenuToggle}
      contextMenuItems={contextMenuItems}
      actionSelectedHandler={actionSelectedHandler}
      contextMenuButton={
        isTopLevelTreeRoot
          ? 'corvid_tree__context_menu_add'
          : 'corvid_tree__context_menu_more'
      }
      customActions={
        isRoot
          ? customRootActions
          : isFolder && customFolderActions && customFolderActions.length > 0
          ? customFolderActions?.map((folderAction) => ({
              ...folderAction,
              onClick: folderAction.onClickCreator(dataSource.id),
            }))
          : undefined
      }
      readOnlyMode={readOnlyMode}
    />
  ) : experimentUtils.isLiteEditor() ? (
    <InfoIcon
      shouldTranslate={false}
      text={t('Left_Tree_Extension_Code_Files_Actions_WixIDE')}
    />
  ) : null;
};
