import { Dispatch } from 'redux';
import {
  PagesTreeReducerAction,
  pagesTreeActions,
  ContributedSection,
} from './state';

interface PagesTreeAPICreatorProps {
  dispatch: Dispatch<PagesTreeReducerAction>;
}

export const createPagesTreeAPI = ({ dispatch }: PagesTreeAPICreatorProps) => {
  return {
    contributeSection: (section: ContributedSection) =>
      dispatch(pagesTreeActions.contributeSection(section)),
  };
};
