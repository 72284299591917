import { CssEditingAction, CssEditingState } from '.';
import { ActionType } from './actionTypes';

const initialState: CssEditingState = {
  allCustomClasses: [],
  isExpanded: true,
};

export const cssEditingReducer = (
  state = initialState,
  action: CssEditingAction,
): CssEditingState => {
  switch (action.type) {
    case ActionType.ADD_CUSTOM_CLASSES:
      return {
        ...state,
        allCustomClasses: Array.from(
          new Set([...state.allCustomClasses, ...action.customClasses]),
        ),
      };
    case ActionType.SET_IS_EXPANDED:
      return {
        ...state,
        isExpanded: action.isExpanded,
      };
    default:
      return state;
  }
};
