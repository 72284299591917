import LRU from 'lru-cache';

const CACHE_MAX_ENTRIES = 5;

export default () => {
  const cache = new LRU(CACHE_MAX_ENTRIES);

  function fetchSourceMap(sourceMapUrl) {
    if (cache.has(sourceMapUrl)) {
      return cache.get(sourceMapUrl);
    }

    const result = window.fetch(sourceMapUrl).then((response) => {
      if (response.ok) {
        return response.text();
      }

      cache.del(sourceMapUrl);

      return Promise.reject(new Error(response.statusText));
    });

    cache.set(sourceMapUrl, result);

    return result;
  }

  return {
    fetchSourceMap,
  };
};
