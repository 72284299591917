import {
  ReadOnlyMode,
  ReadOnlyContributor,
} from '@wix/wix-code-plugin-contracts';

export const SET_READ_ONLY = 'SET_READ_ONLY';
export const REMOVE_READ_ONLY_CONTRIBUTION = 'REMOVE_READ_ONLY_CONTRIBUTION';

export const ReadOnlyActionCreators = {
  setReadOnly: (
    contributor: ReadOnlyContributor,
    state: Partial<ReadOnlyMode>,
  ) => ({
    type: SET_READ_ONLY,
    contributor,
    state,
  }),
  removeReadOnlyContribution: (contributor: ReadOnlyContributor) => ({
    type: REMOVE_READ_ONLY_CONTRIBUTION,
    contributor,
  }),
};
