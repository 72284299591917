import * as React from 'react';
import { FileTreeNode } from '../../FileTreeNode';
import { FileSubTreeDataSource } from '../../FileSubTree';
import { SingleFileNodeMenu } from './SingleFileNodeMenu';

interface SingleFileNodeProps {
  path: string;
  fileIsMandatory: boolean;
  file: any;
  onFileSelected: any;
  nodeDblClick: (fileId: string) => any;
}

export const SingleFileNode: React.FC<SingleFileNodeProps> = ({
  file,
  nodeDblClick,
  fileIsMandatory,
  path,
  onFileSelected,
}) => {
  const [isContextMenuOpen, setContextMenuOpen] = React.useState(false);

  const pendingDelete = file.pendingDelete;

  const dataSource: FileSubTreeDataSource = {
    id: path,
    name: file.descriptor.name,
    pendingRename: file.pendingRename,
    pendingDelete: file.pendingDelete,
    pendingCreation: file.pendingCreation,
    isFolder: false,
    childItems: [],
    expanded: false,
    loading: file.loading,
  };
  const suffix = fileIsMandatory ? null : (
    <SingleFileNodeMenu
      file={file}
      isContextMenuOpen={isContextMenuOpen}
      setContextMenuOpen={setContextMenuOpen}
    />
  );
  return (
    <FileTreeNode
      dataSource={dataSource}
      depth={0}
      alwaysShowSuffix={isContextMenuOpen || pendingDelete}
      suffix={suffix}
      onNodeClick={onFileSelected}
      nodeDoubleClick={nodeDblClick}
      isContextMenuOpen={false}
    />
  );
};
