// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iq4cmW{max-height:120px}.oeOB6K{display:flex;justify-content:flex-end}.oeOB6K button+span{margin-left:12px}.jIu6So:first-of-type{padding-top:18px}.jIu6So:last-of-type{padding-bottom:25px}.jIu6So{padding-top:16px}.L4H8Hy{max-height:129px}.XxZb9E{cursor:pointer}.JU6Huc{max-height:38px}.e012Iu{text-align:center}.Asvj0k{display:flex;justify-content:space-between;margin-bottom:8px}.NOxA5o{display:flex;align-items:center;width:100%}.MdMGuX{width:100%}.wXsZGT{margin-right:5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"configuration-schema-input": "iq4cmW",
	"configurationSchemaInput": "iq4cmW",
	"footer-actions": "oeOB6K",
	"footerActions": "oeOB6K",
	"input-with-label-container": "jIu6So",
	"inputWithLabelContainer": "jIu6So",
	"configuration-schema-input-container": "L4H8Hy",
	"configurationSchemaInputContainer": "L4H8Hy",
	"help-link": "XxZb9E",
	"helpLink": "XxZb9E",
	"input": "JU6Huc",
	"footnote-text": "e012Iu",
	"footnoteText": "e012Iu",
	"input-label": "Asvj0k",
	"inputLabel": "Asvj0k",
	"inputWithPrefixContainer": "NOxA5o",
	"textInput": "MdMGuX",
	"prefixContainer": "wXsZGT"
};
export default ___CSS_LOADER_EXPORT___;
