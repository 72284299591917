import { createHost } from '@wix/wix-code-repluggable';
import {
  EditorAPI,
  LegacyEditorDependencies,
} from '@wix/wix-code-plugin-contracts';
import { LitePanelEntryPoint } from '@wix/wix-code-entry-point';
import initLegacy from '@/wixCodeInit/initLegacy';
import { entryPoint as repluggableEntrypoint } from '@/wixCodeInit/wixCodeEntryPoint';
import createLegacyEditorAdapter from '@/wixCodeInit/legacyEditorAdapter/createLegacyEditorAdapter';
import _ from 'lodash';

function legacy(
  legacyDependencies: LegacyEditorDependencies,
  editorAPI: EditorAPI = {} as EditorAPI,
) {
  const repluggableHost = createHost();
  const legacyRepluggableAdapter = createLegacyEditorAdapter(
    legacyDependencies,
    editorAPI,
  );
  repluggableHost.addShells([
    legacyRepluggableAdapter.entryPoint,
    LitePanelEntryPoint,
  ]);
  const legacyApi = initLegacy(legacyDependencies, repluggableHost);
  return _.merge(legacyApi, legacyRepluggableAdapter.legacyApi);
}

export { legacy, repluggableEntrypoint };
