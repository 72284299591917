import * as React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { CodeReusePkgTreeContext } from '../CodeReusePkgTreeContext';
import { Tooltip, RichText, Preloader } from '@wix/wix-base-ui';
import Warning from '@/assets/icons/leftTree/warning.svg';
import s from '../codeReusePkgTree.scss';
import dataHooks from '../../../../dataHooks';
import { AvailableCodeReusePkg } from '@/toExtract/packages/packagesModalContext';
import { isAppDataLoaded } from '@/toExtract/packages/appData/appDataSelectors';
import bi from '@/legacy/bi/bi';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import { AppState } from '@/infra/redux-state/reducers/rootReducer';
import {
  wixCodeReduxContext,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';

interface InstallDependencyPimpleReduxProps {
  loaded: boolean;
}

function isAppInstalled(
  appDefinitionId: string,
  installedApps: { appDefinitionId: string }[],
): boolean {
  return installedApps.some(
    (appData) => appData.appDefinitionId === appDefinitionId,
  );
}

function getInstalledApps(editorApi: EditorAPI) {
  return editorApi.platform.getInstalledEditorApps();
}

export function getMissingDependencyId(
  editorApi: EditorAPI,
  latestVersion?: AvailableCodeReusePkg,
): string | undefined {
  const installedApps = getInstalledApps(editorApi);
  return (
    latestVersion &&
    latestVersion.appDependencies &&
    latestVersion.appDependencies.find(
      (appDefinitionId) => !isAppInstalled(appDefinitionId, installedApps),
    )
  );
}

const InstallDependencyPimpleComp = ({
  loaded,
}: InstallDependencyPimpleReduxProps) => {
  const { appDataService, latestVersion } = React.useContext(
    CodeReusePkgTreeContext,
  );
  const {
    editorAPI,
    legacyDependenciesAPI: {
      util: { translate },
    },
  } = useEditorLegacyAPIs();

  const appId =
    latestVersion &&
    getMissingDependencyId(editorAPI, latestVersion)!; /* strictNullChecks */
  const visible = !!appId;
  appDataService.loadAppsData();
  const loadAppsDataIfNeeded = () => {
    if (!loaded) {
      appDataService.loadAppsData();
    }
  };
  const { name = 'Application' } =
    visible && loaded ? appDataService.getAppDataById(appId) || {} : {};

  const openDependencyInAppMarket = (event: React.MouseEvent) => {
    editorAPI.bi.event(bi.events.CODE_REUSE_ADD_DEPENDENCY_CLICK, {
      related_link: 'open_app_market',
      link_name: name,
      link_type: 'app_market',
      origin: latestVersion!.name,
    });

    editorAPI.panelManager.openPanel('tpa.compPanels.appMarketPanel', {
      urlParams: { slug: appId },
    });
    event.stopPropagation();
  };

  return (
    <Tooltip
      className={cx({ [s.invisible]: !visible })}
      marginLeft={8}
      marginTop={2}
      interactive={true}
      onOpen={loadAppsDataIfNeeded}
      content={
        loaded ? (
          <RichText>
            {translate('WixCode_PackageManager_Installed_Notification_Text', {
              nameOfApp: name,
            })}
            &nbsp;
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              onClick={openDependencyInAppMarket}
              data-hook={dataHooks.INSTALL_DEPENDENCY_LINK}
            >
              {translate('WixCode_PackageManager_Installed_Notification_Link', {
                nameOfApp: name,
              })}
            </a>
          </RichText>
        ) : (
          <Preloader />
        )
      }
    >
      <div data-hook={dataHooks.INSTALL_DEPENDENCY_PIMPLE}>
        <Warning className={s.readOnly} />
      </div>
    </Tooltip>
  );
};

function mapStateToProps(state: AppState): InstallDependencyPimpleReduxProps {
  const loaded = isAppDataLoaded(state);
  return {
    loaded,
  };
}

export const InstallDependencyPimple: React.FC = connect(
  mapStateToProps,
  null,
  null,
  { context: wixCodeReduxContext },
)(InstallDependencyPimpleComp);
