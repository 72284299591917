// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q4ur_a{display:flex;flex:1;position:relative;height:calc(100% - 110px)}.Q4ur_a .P3BIx3{display:none}.Q4ur_a:hover .P3BIx3{display:block}.Nq2lab{display:none;position:absolute;top:1px;left:2px}.h6twJV{display:block;position:absolute;top:5px;left:6px}.P3BIx3{width:26px;height:26px;box-sizing:border-box;border:solid 1px #d9e1e8;position:absolute;right:8px;top:-1px;cursor:pointer;z-index:1;padding-left:5px;padding-top:4px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tbf-code-editor-container": "Q4ur_a",
	"tbfCodeEditorContainer": "Q4ur_a",
	"tbf-editor-clear-button": "P3BIx3",
	"tbfEditorClearButton": "P3BIx3",
	"tbf-editor-clear-button-hover-symbol": "Nq2lab",
	"tbfEditorClearButtonHoverSymbol": "Nq2lab",
	"tbf-editor-clear-button-normal-symbol": "h6twJV",
	"tbfEditorClearButtonNormalSymbol": "h6twJV"
};
export default ___CSS_LOADER_EXPORT___;
