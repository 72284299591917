import './severityFilter.global.scss';
import React, { useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  wixCodeReduxContext,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import consoleActionsCreator from '@/infra/redux-state/actions/consoleActions';
import consoleStateReader from '@/infra/redux-state/reducers/consoleStateReader';
import { LogLevel } from '../constants';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import baseUILib from '@wix/wix-base-ui';
import { dataHooks } from '../dataHooks';
import wixCodeBI from '../../../../bi/bi';

interface LevelDetails {
  translationKey: string;
  bi_name: string;
}

interface SeverityFilterStateProps {
  visibleLevels: { [key in LogLevel]: boolean };
}

interface SeverityFilterDispatchProps {
  setLevelVisibility: (level: LogLevel, visible: boolean) => void;
}

export default _.once(({ stateManagement, util }) => {
  const consoleActions = consoleActionsCreator({ stateManagement });

  const SeverityFilter: React.FC<
    SeverityFilterStateProps & SeverityFilterDispatchProps
  > = ({ visibleLevels, setLevelVisibility }) => {
    const [shouldShowContextMenu, setShouldShowContextMenu] = useState(false);
    const { editorAPI } = useEditorLegacyAPIs();

    const getLevels = () => {
      const levels = Object.values(LogLevel).filter(
        (level) => level !== LogLevel.TIP,
      );

      return levels;
    };

    const getLevelDetails = (): {
      [key in LogLevel]?: LevelDetails;
    } => {
      return {
        [LogLevel.VERBOSE]: {
          translationKey: 'WixCode_Console_TopBar_Logs_Checkbox_Verbose',
          bi_name: 'verbose',
        },
        [LogLevel.LOG]: {
          translationKey: 'WixCode_Console_TopBar_Logs_Checkbox_Debug',
          bi_name: 'debug',
        },
        [LogLevel.INFO]: {
          translationKey: 'WixCode_Console_TopBar_Logs_Checkbox_Info',
          bi_name: 'info',
        },
        [LogLevel.WARNING]: {
          translationKey: 'WixCode_Console_TopBar_Logs_Checkbox_Warning',
          bi_name: 'warning',
        },
        [LogLevel.ERROR]: {
          translationKey: 'WixCode_Console_TopBar_Logs_Checkbox_Error',
          bi_name: 'error',
        },
      };
    };

    const getTitle = () => {
      const levels = getLevels();
      const onLevels = levels.filter((level: LogLevel) => isLevelOn(level));
      const areAllLevelsOnExceptVerbose = () =>
        !isLevelOn(LogLevel.VERBOSE) && onLevels.length === levels.length - 1;

      if (areAllLevelsOnExceptVerbose()) {
        return util.translate(
          'WixCode_Console_TopBar_Logs_Dropdown_DefaultView_Text',
        );
      }

      switch (onLevels.length) {
        case levels.length:
          return util.translate(
            'WixCode_Console_TopBar_Logs_Dropdown_ShowAll_Text',
          );
        case 0:
          return util.translate(
            'WixCode_Console_TopBar_Logs_Dropdown_HideAll_Text',
          );
        case 1:
          return util.translate(
            'WixCode_Console_TopBar_Logs_Dropdown_OnlyOne_Text',
            {
              Selected_Checkbox: util.translate(getTranslationKey(onLevels[0])),
            },
          );
        default:
          return util.translate(
            'WixCode_Console_TopBar_Logs_Dropdown_CustomView_Text',
          );
      }
    };

    const isLevelOn = (level: LogLevel) => {
      return Boolean(visibleLevels[level]);
    };

    const getTranslationKey = (level: LogLevel) => {
      return getLevelDetails()[level]!.translationKey;
    };

    const toggleContextMenu = () => {
      setShouldShowContextMenu(!shouldShowContextMenu);
    };

    return (
      <div
        className={classNames({
          'wix-code-console-severity-filter': true,
          'menu-open': shouldShowContextMenu,
        })}
        onClick={toggleContextMenu}
        data-hook={dataHooks.CONSOLE_SEVERITY_FILTER}
      >
        <span className="console-severity-filter-title">{getTitle()}</span>
        <Symbol className="icon-symbol" name="arrowDown" />
        <baseUILib.ContextMenu
          alignment="BOTTOM"
          direction="MIDDLE"
          className="console-severity-filter-context-menu"
          isOpen={shouldShowContextMenu}
          onToggle={toggleContextMenu}
        >
          {getLevels().map((level) => (
            <baseUILib.ContextMenuAction
              key={level}
              dataHook={dataHooks.CONSOLE_SEVERITY_FILTER_MENU_ITEM}
            >
              <baseUILib.Checkbox
                automationId={level}
                value={isLevelOn(level)}
                label={util.translate(getTranslationKey(level))}
                labelAfterSymbol={true}
                onChange={(visible: boolean) => {
                  editorAPI.bi.event(
                    wixCodeBI.events.LOG_CONSOLE_SEVERITY_FILTER_CHANGE,
                    {
                      severity_name: getLevelDetails()[level]?.bi_name,
                      action: visible ? 'on' : 'off',
                    },
                  );
                  setLevelVisibility(level, visible);
                }}
                shouldTranslate={false}
                dataHook={dataHooks.CONSOLE_SEVERITY_FILTER_MENU_ITEM_CHECKBOX}
              ></baseUILib.Checkbox>
            </baseUILib.ContextMenuAction>
          ))}
        </baseUILib.ContextMenu>
      </div>
    );
  };

  const mapStateToProps = (state: any): SeverityFilterStateProps => ({
    visibleLevels: consoleStateReader.getVisibleLevels(state),
  });

  const mapDispatchToProps = (dispatch: any): SeverityFilterDispatchProps => ({
    setLevelVisibility: (...args) =>
      dispatch(consoleActions.setLevelVisibility(...args)),
  });

  const Connected = connect(mapStateToProps, mapDispatchToProps, null, {
    context: wixCodeReduxContext,
  })(SeverityFilter);

  return Connected;
});
