const dataHooks = {
  instructionsModal: {
    container: 'instructions-modal-container',
    title: 'instructions-modal-title',
    subtitle: 'instructions-modal-subtitle',
    confirmButton: 'instructions-modal-confirm-button',
    instructions: {
      container: 'instructions-modal-instructions-container',
    },
    footer: {
      container: 'instructions-modal-footer',
      textButtonLink: 'instructions-modal-footer-text-btn-link',
    },
  },
  instructions: {
    container: 'instructions-container',
    instructions: 'instructions-instructions',
    confirmCircle: 'instructions-confirmCircle',
    tooltip: 'instructions-tooltip',
    copyCodeButton: 'instructions-copyCodeButton',
    terminal: 'instructions-terminal',
  },
};

export default dataHooks;
