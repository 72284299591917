import s from './overlayPanel.scss';
import React, { FC } from 'react';
import { WixCodeTheme } from '../../../constants/themes';
import { Preloader } from '@wix/wix-base-ui';
import cx from 'classnames';
import {
  useEditorLegacyAPIs,
  connectToStores,
} from '@wix/wix-code-common-components';

interface LoadingOverlayPanelProps {
  theme: WixCodeTheme;
}

const LoadingOverlayPanel: React.FC<LoadingOverlayPanelProps> = ({ theme }) => {
  return (
    <div className={cx(s.wixCodePageEditorOverlay, s[theme])}>
      <div className={s.messageContainer}>
        <Preloader />
      </div>
    </div>
  );
};

const mapCodeStateToProps = (state: AnyFixMe): { theme: WixCodeTheme } => {
  const theme = state.ide?.theme;

  return {
    theme,
  };
};

let ConnectedComponent: FC | null = null;
const LoadingOverlayPanelHot: React.FC<
  Omit<LoadingOverlayPanelProps, 'theme'>
> = (origProps) => {
  const {
    legacyDependenciesAPI: { util },
  } = useEditorLegacyAPIs();
  if (ConnectedComponent === null) {
    ConnectedComponent = connectToStores({
      util,
      mapCodeStateToProps,
      comp: LoadingOverlayPanel,
    });
  }
  return <ConnectedComponent {...origProps} />;
};

export default LoadingOverlayPanelHot;
