import * as React from 'react';
import * as _ from 'lodash';
import { TextLabel, TextButton } from '@wix/wix-base-ui';
import s from './PackageMarkDownHeader.scss';
import dataHooks from '../../../dataHooks';
import { PackagesModalContext, PkgInfo } from '../../../packagesModalContext';
import { isReusePackageByVelo } from '../../../utils';
import {
  DateFormat,
  getFormattedDate,
  useEditorLanguageCode,
} from '@/utils/dateUtils';
import { useContext } from 'react';
import { PackageItemContext } from '../pkgLists/PackageItemContext';
import { experimentUtils } from '@wix/wix-code-common';
import { PackagesWarning } from './PackagesWarning/PackagesWarning';
import { PackagesBiSender } from '@/toExtract/packages/packagesBiSender';

const getPublishedDate = (pkg: PkgInfo): string =>
  _.get(pkg, 'publishedDate') || _.get(pkg, ['_version', 'time']);

export const PackageMarkdownPkgDetails: React.FC = () => {
  const { t, sendBi, currentTab } = React.useContext(PackagesModalContext);
  const { availablePkg, pkgName, isCodeReusePackage } =
    useContext(PackageItemContext);
  const lang = useEditorLanguageCode();
  const isVeloPackage = isReusePackageByVelo(pkgName);
  const publishedDate = getPublishedDate(availablePkg);
  const formattedPublishedDate = getFormattedDate(
    publishedDate,
    lang,
    DateFormat.Short,
  );
  const documentationLink = `https://www.npmjs.com/package/${encodeURIComponent(
    pkgName,
  )}`;

  const packagesBiSender = PackagesBiSender(sendBi);
  return (
    <div className={s.packageInfoContainer}>
      {isVeloPackage ? (
        <div>
          <TextLabel
            type="T07"
            shouldTranslate={false}
            value={t('Package_Manager_ReadMe_Creator')}
            className={s.packageInfoBold}
          />
          <TextLabel type="T02" shouldTranslate={false} value="Wix" />
          {publishedDate ? (
            <div
              data-hook="readme-package-info"
              className={isVeloPackage ? s.packageInfoLine : ''}
            >
              <TextLabel
                type="T07"
                shouldTranslate={false}
                value={t('Package_Manager_ReadMe_Published')}
                className={s.packageInfoBold}
              />
              <TextLabel
                type="T02"
                shouldTranslate={false}
                value={formattedPublishedDate}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      {!isCodeReusePackage && (
        <>
          {experimentUtils.isAnyNpmNewExperience() && <PackagesWarning />}
          <TextButton
            size="small"
            dataHook={dataHooks.OPEN_DOCS_NPM_LINK}
            className={s.packageInfoLine}
            onClick={() => {
              packagesBiSender.sendQuickActionClickedViewPkgBi(
                availablePkg,
                currentTab,
              );
              window.open(documentationLink, '_blank');
            }}
          >
            {t('Package_Manager_ReadMe_Open_NPM')}
          </TextButton>
        </>
      )}
    </div>
  );
};
