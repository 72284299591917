import './ide.global.scss';
import React from 'react';
import createReactClass from 'create-react-class';
import { Provider } from 'react-redux';
import { wixCodeReduxContext } from '@wix/wix-code-common-components';
import storeManager from '@/infra/redux-state/store/storeManager';

const IDE = createReactClass({
  displayName: 'IDE',
  UNSAFE_componentWillMount: function componentWillMount() {
    this.store = storeManager.getStore();
  },

  render() {
    return (
      <Provider store={this.store} context={wixCodeReduxContext}>
        <div className="wix-code-ide" id={this.props.id}>
          {this.props.children}
        </div>
      </Provider>
    );
  },
});

export default IDE;
