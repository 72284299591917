import * as React from 'react';
import { FileSubTree, FileSubTreeDataSource } from '../FileSubTree';
import { connect } from 'react-redux';
import {
  wixCodeReduxContext,
  useEditorLegacyAPIs,
  Dispatch,
} from '@wix/wix-code-common-components';
import codeStateReader from '@/infra/redux-state/reducers/codeStateReader';
import ideTabsStateReader from '@/codeEditor/tabs/selectors/ideTabsStateReader';
import { bindActionCreators } from 'redux';
import fileSystemActions from '@/infra/redux-state/actions/fileSystemActions';
import { nodeDoubleClick } from '@/infra/redux-state/actions/fileTreeActions';
import { isReservedFileSystemName } from '../fileNameValidator';
import _ from 'lodash';
import { LeftPaneContextProps } from '../../LeftPaneContext';
import { getSingelFileSections } from './treeSectionSelectors';
import { FileTreeRootProps } from '@wix/wix-code-plugin-contracts';
import { AppState } from '@/infra/redux-state/reducers/rootReducer';

interface FileTreeRootReduxStateProps {
  dataSource: FileSubTreeDataSource;
  isReservedFileSystemItem: (parentId: string, name: string) => boolean;
  unpinnedTab: string | null;
}

interface FileTreeRootReduxDispatchProps {
  renameFileOrFolder: (fileId: string, newName: string) => any;
  createFile: (
    parentId: string,
    childName: string,
    initialContent?: string,
  ) => any;
  createFolder: (parentId: string, childName: string) => any;
  expand: (fileId: string) => any;
  collapse: (fileId: string) => any;
  nodeDoubleClick: (fileId: string) => any;
}

type FileTreeRootCompProps = FileTreeRootProps &
  FileTreeRootReduxStateProps &
  FileTreeRootReduxDispatchProps;

const FileTreeRootComp: React.FC<FileTreeRootCompProps> = ({ id, ...rest }) => {
  return (
    <FileSubTree depth={0} isRoot={true} isTopLevelTreeRoot={true} {...rest} />
  );
};

function computeChildren(
  state: AnyFixMe,
  immutableChildIdsSet: AnyFixMe,
  isReservedFileSystemItem: AnyFixMe,
) {
  const childItems: AnyFixMe[] = [...immutableChildIdsSet].map(function (
    childId: AnyFixMe,
  ) {
    return buildSubTree(state, childId, isReservedFileSystemItem);
  });
  return childItems;
}

function filterAndOrderChildren(
  childItems: AnyFixMe,
  subTreeRootId: AnyFixMe,
  isReservedFileSystemItem: AnyFixMe,
) {
  return _(childItems)
    .reject((child) => isReservedFileSystemItem(subTreeRootId, child.name))
    .orderBy(['pendingCreation', 'isFolder', 'name'], ['desc', 'desc', 'asc'])
    .value();
}
function buildSubTree(
  state: AnyFixMe,
  subTreeRootId: AnyFixMe,
  isReservedFileSystemItem: AnyFixMe,
) {
  const entry = codeStateReader.getFileSystemEntry(state, subTreeRootId);
  const tempChildItems = computeChildren(
    state,
    entry.childIds,
    isReservedFileSystemItem,
  );
  return {
    id: subTreeRootId,
    name: entry.descriptor.name,
    pendingRename: entry.pendingRename,
    pendingDelete: entry.pendingDelete,
    pendingCreation: entry.pendingCreation,
    isFolder: entry.descriptor.directory,
    childItems: filterAndOrderChildren(
      tempChildItems,
      subTreeRootId,
      isReservedFileSystemItem,
    ),
    expanded: entry.expanded,
    loading: entry.loading,
  };
}

function mapStateToProps(
  state: AppState,
  ownProps: AnyFixMe,
): FileTreeRootReduxStateProps {
  const unpinnedTab = ideTabsStateReader.getUnpinnedTabId(state);
  const fileSystemRoots = ownProps.getEditorAPI().wixCode.fileSystem.getRoots();
  const publicRootId = fileSystemRoots.public.location;
  const backendRootId = fileSystemRoots.backend.location;
  const hiddenFiles = [
    ...(ownProps.hiddenFiles || []),
    ...getSingelFileSections(state),
    'public/app-metadata.json',
  ];

  const isReservedFile = (parentId: string, name: string) =>
    isReservedFileSystemName({
      parentId,
      name,
      publicRootId,
      backendRootId,
      hiddenFiles,
    });

  return {
    dataSource: buildSubTree(state, ownProps.id, isReservedFile),
    isReservedFileSystemItem: isReservedFile,
    unpinnedTab,
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch,
): FileTreeRootReduxDispatchProps =>
  bindActionCreators(
    {
      renameFileOrFolder: fileSystemActions.renameFileOrFolder,
      createFile: fileSystemActions.createFile,
      createFolder: fileSystemActions.createFolder,
      expand: fileSystemActions.expand,
      collapse: fileSystemActions.collapse,
      nodeDoubleClick,
    },
    dispatch,
  );

export const FileTreeRoot: React.FC<FileTreeRootProps> = (props) => {
  const {
    editorAPI,
    legacyDependenciesAPI: {
      util: { validate },
    },
  } = useEditorLegacyAPIs();
  return (
    <FileTreeRootConnected
      {...props}
      getEditorAPI={() => editorAPI}
      validate={validate}
    />
  );
};

const FileTreeRootConnected: React.FC<
  FileTreeRootProps & Pick<LeftPaneContextProps, 'getEditorAPI' | 'validate'>
> = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
  context: wixCodeReduxContext,
})(FileTreeRootComp);
