import * as React from 'react';
import { CodeReusePkgTreeRoot } from './CodeReusePkgTreeRoot';
import { InstalledCodeReusePkg } from '@/toExtract/packages/packagesModalContext';
import { PackagesService } from '@/toExtract/packages/packagesService';
import { CodeReusePkgTreeContext } from './CodeReusePkgTreeContext';
import { nodeDoubleClick } from '@/infra/redux-state/actions/fileTreeActions';
import { AppDataService } from '@/toExtract/packages/appDataService';
import { PACKAGE_VIEW_QUICK_ACTIONS } from '../pkgTreeQuickActions';
import { codeReusePkgsSelectors } from '@/toExtract/packages/packagesView/packagesViewSelectors';
import { PkgTreeContext } from '../PkgTreeContext';
import {
  useInternalAPIs,
  useSelector,
} from '../../packagesTabEntryPoint/contexts';

interface CodeReuseScopeTreeExpandedProps {
  installedCodeReusePkgs: InstalledCodeReusePkg[];
  packagesService: PackagesService;
  appDataService: AppDataService;
  actionHandler: (
    action: PACKAGE_VIEW_QUICK_ACTIONS,
    packageName?: string,
  ) => void;
  isPrivateScope: boolean;
  isByOthers: boolean;
}

export const CodeReuseScopeTreeExpanded: React.FC<
  CodeReuseScopeTreeExpandedProps
> = ({
  installedCodeReusePkgs,
  packagesService,
  appDataService,
  actionHandler,
  isPrivateScope,
  isByOthers,
}) => {
  const { isBlocksCombinedMode } = React.useContext(PkgTreeContext);
  const { wixCodeStoreAPI } = useInternalAPIs();
  const { dispatch } = wixCodeStoreAPI;
  const dispatchNodeDoubleClick = (fileId: AnyFixMe) =>
    dispatch(nodeDoubleClick(fileId)());
  const availableCodeReusePkgs = useSelector(
    codeReusePkgsSelectors.available.all,
  );

  return (
    <>
      {installedCodeReusePkgs.map((pkg) => {
        const { gridAppId, name } = pkg;
        const availableCodePkg = availableCodeReusePkgs.find(
          (p) => p.name === name,
        )!;
        const latestVersion = availableCodePkg
          ? {
              ...availableCodePkg,
            }
          : null;
        if (latestVersion && isBlocksCombinedMode && pkg.latestVersion) {
          latestVersion.version = pkg.latestVersion;
        }

        return (
          <CodeReusePkgTreeContext.Provider
            key={name}
            value={{
              pkg,
              packagesService,
              appDataService,
              manageInstalledModules: () =>
                actionHandler(
                  PACKAGE_VIEW_QUICK_ACTIONS.MANAGE_INSTALLED_MODULES,
                  name,
                ),
              latestVersion,
              isPrivateScope,
              isByOthers,
            }}
          >
            <CodeReusePkgTreeRoot
              key={gridAppId}
              name={name}
              onNodeDoubleClick={dispatchNodeDoubleClick}
            />
          </CodeReusePkgTreeContext.Provider>
        );
      })}
    </>
  );
};
