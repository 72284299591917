import { AnyAction } from 'redux';
import { ReadOnlyState, REMOVE_READ_ONLY_CONTRIBUTION, initialState } from '.';
import { SET_READ_ONLY } from './actions';
import _ from 'lodash';

export const readOnlyReducer = (
  state: ReadOnlyState = initialState,
  action: AnyAction,
): ReadOnlyState => {
  switch (action.type) {
    case SET_READ_ONLY:
      return {
        ...state,
        readOnlyContributions: _.merge({}, state.readOnlyContributions, {
          [action.contributor]: action.state,
        }),
      };
    case REMOVE_READ_ONLY_CONTRIBUTION:
      return {
        ...state,
        readOnlyContributions: _.omitBy(
          state.readOnlyContributions,
          (_value, key) => key === action.contributor,
        ),
      };
    default:
      return state;
  }
};
