import { ComponentsDataService } from '@wix/wix-code-editor-types';
import loadwixCodeTypesComponentsDataService from './wixCodeTypesLoader';
import { EventHandler } from '@wix/wix-code-common';
import { ManifestsObject } from '@wix/wix-code-plugin-contracts';

let wixCodeComponentsDataService: ComponentsDataService | undefined;
let wixCodeComponentsDataServicePromise: Promise<ComponentsDataService>;

export const componentsDataService = {
  initialize: async (): Promise<void> => {
    if (componentsDataService.getService()) {
      return;
    }

    wixCodeComponentsDataServicePromise =
      loadwixCodeTypesComponentsDataService();
    wixCodeComponentsDataService = await wixCodeComponentsDataServicePromise;
  },
  getService: (): ComponentsDataService | undefined => {
    return wixCodeComponentsDataService;
  },
  getComponentEventHandlers: (
    componentName: string,
  ): EventHandler[] | undefined => {
    return wixCodeComponentsDataService?.getComponentEventHandlers(
      componentName,
    );
  },
  getComponentApiReference: (componentName: string): string | undefined => {
    return componentsDataService
      .getService()
      ?.getComponentApiReference(componentName);
  },
  getComponentsSemanticClassNames: () =>
    componentsDataService.getService()?.getComponentsSemanticClassNames(),

  getComponentCssReference: (sdkType: string) =>
    wixCodeComponentsDataService?.getComponentCssReference(sdkType),

  getComponentsSemanticClasses: (sdkType: string, componentType: string) =>
    componentsDataService
      .getService()
      ?.getComponentsSemanticClasses(sdkType, componentType),

  registerEventHandlersFromManifests: (manifests: ManifestsObject) => {
    Object.keys(manifests).forEach((manifestId: string) => {
      const eventHandlers = manifests[manifestId].eventHandlers ?? {};
      const handlers: EventHandler[] = Object.keys(eventHandlers).map(
        (handlerName: string) => {
          const eventHandler = eventHandlers[handlerName];
          return {
            origin: manifestId,
            name: handlerName,
            description: eventHandler.description,
            type: eventHandler.type,
            kind: 'function',
            handlerArgs: [],
          };
        },
      );

      wixCodeComponentsDataServicePromise?.then(() =>
        componentsDataService
          .getService()
          ?.registerComponentEventHandlers(manifestId, handlers),
      );
    });
  },
};
