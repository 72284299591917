import React from 'react';
import styles from './styles.scss';
import { InputField } from './InputField';
import { dataHooks } from '../dataHooks';
import { BI, adapterInputConstraints } from '../constants';
import { useTranslation } from '@wix/wix-i18n-config';
import { UserTypingInfo } from '../../../internal-types';

export type AdapterDetailsProps = {
  userStartedTypingIndication: UserTypingInfo;
  namespace: string;
  setNamespace: (value: string) => void;
  isNamespaceValid: boolean;
  namespaceValidationError: string;
  isNamespaceDisabled: boolean;
  endpoint: string;
  setEndpoint: (value: string) => void;
  isEndpointValid: boolean;
  endpointValidationError: string;
  configuration: string;
  setConfiguration: (value: string) => void;
  isConfigurationValid: boolean;
  configurationValidationError: string;
};

export const AdapterDetails: React.FC<AdapterDetailsProps> = ({
  userStartedTypingIndication,
  namespace,
  setNamespace,
  isNamespaceValid,
  namespaceValidationError,
  isNamespaceDisabled,
  endpoint,
  setEndpoint,
  isEndpointValid,
  endpointValidationError,
  configuration,
  setConfiguration,
  isConfigurationValid,
  configurationValidationError,
}) => {
  const [t] = useTranslation();

  const onLinkClick = () => {
    window.open(t('External_Databases_Modal_Help_Link'), '_blank');
  };

  return (
    <div data-hook={dataHooks.externalDbPanels.addAdapter.inputFieldsContainer}>
      <InputField
        didUserStartTyping={
          userStartedTypingIndication?.didUserStartTypingNamespace
        }
        infoIconBIName={BI.buttons.createNamespace}
        infoIconDatahook={
          dataHooks.externalDbPanels.addAdapter.namespaceInputFieldInfoIcon
        }
        textInputDatahook={
          dataHooks.externalDbPanels.addAdapter.namespaceInputField
        }
        labelText="External_Databases_Modal_Connect_Adapter_Namespace_Label"
        inputValue={namespace}
        isValid={isNamespaceValid}
        invalidMessage={namespaceValidationError}
        onChange={setNamespace}
        isDisabled={isNamespaceDisabled}
        infoIconText={
          isNamespaceDisabled
            ? 'WixCode_Modal_EditExternalDatabaseConnection_Namespace_Tooltip'
            : 'External_Databases_Modal_Connect_Adapter_Namespace_Info_Tooltip'
        }
        automationId={{
          label: 'collections-list-namespace-label',
          input: 'collections-list-namespace-input',
        }}
        placeHolder={t('External_Collections_Screen_Namespace_Placeholder')}
        maxLength={
          isNamespaceDisabled
            ? undefined
            : adapterInputConstraints.MAX_NAMESPACE_LENGTH
        }
      />
      <InputField
        didUserStartTyping={
          userStartedTypingIndication?.didUserStartTypingEndpoint
        }
        infoIconBIName={BI.buttons.addEndpoint}
        infoIconDatahook={
          dataHooks.externalDbPanels.addAdapter.endpointInputFieldInfoIcon
        }
        textInputDatahook={
          dataHooks.externalDbPanels.addAdapter.endpointInputField
        }
        automationId={{
          label: 'endpoint-url-label',
          input: 'endpoint-url-input',
        }}
        labelText="External_Databases_Modal_Connect_Adapter_Entrypoint_Label"
        infoIconText="External_Databases_Modal_Connect_Adapter_Entyrpoint_Info_Tooltip"
        placeHolder={t('External_Collections_Screen_Endpoint_Placeholder')}
        inputValue={endpoint}
        onChange={setEndpoint}
        isValid={isEndpointValid}
        invalidMessage={endpointValidationError}
        maxLength={adapterInputConstraints.MAX_ENDPOINT_LENGTH}
      />
      <InputField
        didUserStartTyping={
          userStartedTypingIndication?.didUserStartTypingSecretkey
        }
        infoIconBIName={BI.buttons.enterSecretKey}
        infoIconDatahook={
          dataHooks.externalDbPanels.addAdapter.secretKeyInputFieldInfoIcon
        }
        textInputDatahook={
          dataHooks.externalDbPanels.addAdapter.secretKeyInputField
        }
        className={[
          'composite-full-height',
          styles.configurationSchemaInputContainer,
        ]}
        labelText="External_Databases_Modal_Connect_Adapter_Configuration_Label"
        automationId={{
          label: 'configuration-schema-label',
          input: 'configuration-schema-input',
        }}
        infoIconText="External_Databases_Modal_Connect_Adapter_Configuration_Info_Tooltip"
        onLinkClick={onLinkClick}
        placeHolder={t(
          'External_Databases_Modal_Connect_Adapter_Configuration_Placeholder',
        )}
        inputValue={configuration}
        onChange={setConfiguration}
        isValid={isConfigurationValid}
        invalidMessage={configurationValidationError}
        type="password"
        hideInput={true}
      />
    </div>
  );
};
