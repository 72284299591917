import React from 'react';
import { PackagesService } from '@/toExtract/packages/packagesService';
import { PACKAGE_VIEW_QUICK_ACTIONS } from './pkgTreeQuickActions';

type PkgTreeContextProps = {
  packagesService: PackagesService;
  actionHandler: (
    action: PACKAGE_VIEW_QUICK_ACTIONS,
    packageName?: string,
  ) => void;
  onOpenRemoveNpmPackage: (packageNameToUninstall: string) => void;
  isBlocksCombinedMode: boolean;
};

export const PkgTreeContext = React.createContext<PkgTreeContextProps>({
  packagesService: null,
  actionHandler: (_action: AnyFixMe) => {},
  onOpenRemoveNpmPackage: (_name: AnyFixMe) => {},
  isBlocksCombinedMode: false,
} as any);
