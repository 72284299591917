import { useState } from 'react';
import { FieldsValidations } from '../../internal-types';

export const useValidation = () => {
  const [fieldsValidations, setFieldsValidations] = useState<FieldsValidations>(
    {
      isNamespaceValid: false,
      isEndpointValid: false,
      isConfigurationValid: false,
    },
  );

  return { fieldsValidations, setFieldsValidations };
};
