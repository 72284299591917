import React from 'react';
import { Spacer, Text } from '@wix/wix-base-ui';
import Terminal from './Terminal/Terminal';
import dataHooks from '../dataHooks';

export type InstructionsProps = {
  title: string;
  instructions: string;
  terminalCommands: string[];
  onCopyCode: () => void;
  copyCodeText: string;
};

const Instructions: React.FC<InstructionsProps> = ({
  title,
  onCopyCode,
  copyCodeText,
  instructions,
  terminalCommands,
}) => {
  return (
    <div data-hook={dataHooks.instructions.container}>
      <Text size="medium" skin="secondary" weight="bold">
        {title}
      </Text>
      <Text
        size="medium"
        weight="thin"
        skin="secondary"
        enableEllipsis={false}
        dataHook={dataHooks.instructions.instructions}
      >
        {instructions}
      </Text>
      <Spacer type="Spacer03" />
      <Terminal
        onCopyCode={onCopyCode}
        copyCodeText={copyCodeText}
        terminalCommands={terminalCommands}
      />
    </div>
  );
};

export default Instructions;
