import './resultRow.global.scss';
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const ResultRow = ({
  result,
  isSelected,
  onResultClick,
  onResultDoubleClick,
}) => {
  return (
    <li
      data-aid="result-hit"
      onClick={() => onResultClick(result)}
      onDoubleClick={onResultDoubleClick}
      className={classNames('result-row', {
        'result-row--selected': isSelected,
      })}
    >
      <span>
        <span data-aid="result-row-prefix">{result.resultTitle.prefix}</span>
        <span data-aid="result-row-match" className="match">
          {result.resultTitle.match}
        </span>
        <span data-aid="result-row-suffix">{result.resultTitle.suffix}</span>
      </span>
    </li>
  );
};

ResultRow.propTypes = {
  result: PropTypes.shape({
    resultTitle: PropTypes.shape({
      prefix: PropTypes.string,
      match: PropTypes.string.isRequired,
      suffix: PropTypes.string,
    }),
  }),
  isSelected: PropTypes.bool,
};

ResultRow.defaultProps = {
  isSelected: false,
};

export default ResultRow;
