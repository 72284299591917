import _ from 'lodash';
import { ActionType } from '@/infra/redux-state/actions/types';
import { getMasterPageFileId } from '@wix/wix-code-common';
import { RealEstateIds } from '@/infra/dealer/config';
import {
  IdeTabsReducerState as IdeTabsReducerStateInternal,
  TabsPinTab as TabsPinTabInternal,
  NodeDoubleClick as NodeDoubleClickInternal,
  TabsCloseTab as TabsCloseTabInternal,
  TabsAddOrChangeTbfTab as TabsAddOrChangeTbfTabInternal,
  TabsCloseTbfTab as TabsCloseTbfTabInternal,
  ChangeContent as ChangeContentInternal,
  TabsCloseEmbededTab as TabsCloseEmbededTabInternal,
  TabsOpenOrChangeUnpinnedTab as TabsOpenOrChangeUnpinnedTabInternal,
  TabsStoreScrollState as TabsStoreScrollStateInternal,
} from '@wix/wix-code-plugin-contracts';

export type IdeTabsReducerState = IdeTabsReducerStateInternal;
export type TabsPinTab = TabsPinTabInternal;
export type NodeDoubleClick = NodeDoubleClickInternal;
export type TabsCloseTab = TabsCloseTabInternal;
export type TabsAddOrChangeTbfTab = TabsAddOrChangeTbfTabInternal;
export type TabsCloseTbfTab = TabsCloseTbfTabInternal;
export type ChangeContent = ChangeContentInternal;
export type TabsCloseEmbededTab = TabsCloseEmbededTabInternal;
export type TabsOpenOrChangeUnpinnedTab = TabsOpenOrChangeUnpinnedTabInternal;
export type TabsStoreScrollState = TabsStoreScrollStateInternal;

type RemoveInstalledCodeReusePkg = {
  codeReusePkgData: { name: string };
  type: ActionType.REMOVE_INSTALLED_CODE_REUSE_PKG;
};
type RemoveFile = {
  fileId: string;
  type: ActionType.REMOVE_FILE;
};
type RemoveFileUnder = {
  fileId: string;
  type: ActionType.REMOVE_FILES_UNDER;
};

type HelpLinkClick = {
  id: string;
  linkData: {
    url: string;
    tabTitle: string;
    offerGuid: string;
    biFileName: string;
  };
  type: ActionType.HELP_LINK_CLICK;
};

type DealerResponseDone = {
  realEstateId: string;
  payload: {
    url: string;
    tabTitle: string;
    offerGuid: string;
    biFileName: string;
  };
  type: ActionType.DEALER_RESPONSE_DONE;
};
type MoveFolder = {
  oldLocation: string;
  newLocation: string;
  type: ActionType.MOVE_FOLDER;
};

type IdeTabsReducerAction =
  | TabsPinTab
  | NodeDoubleClick
  | TabsCloseTab
  | ChangeContent
  | RemoveInstalledCodeReusePkg
  | RemoveFile
  | RemoveFileUnder
  | TabsCloseEmbededTab
  | HelpLinkClick
  | TabsAddOrChangeTbfTab
  | TabsCloseTbfTab
  | DealerResponseDone
  | TabsOpenOrChangeUnpinnedTab
  | TabsStoreScrollState
  | MoveFolder;

type AddEmbeddedTabsArgs = {
  realEstateId: string;
} & HelpLinkClick['linkData'];

const initialState: IdeTabsReducerState = {
  pinnedFileTabs: [],
  embeddedTabs: [],
  tbfTabs: [],
  unpinnedTabId: null,
  scrollState: {},
};

const renameTab = (
  state: IdeTabsReducerState,
  oldTabId: string,
  newTabId: string,
) => {
  return {
    ...state,
    unpinnedTabId:
      state.unpinnedTabId !== oldTabId ? state.unpinnedTabId : newTabId,
    scrollState: _.omit(state.scrollState, [oldTabId]),
    pinnedFileTabs: [
      ...state.pinnedFileTabs.map((tab) =>
        tab.tabId !== oldTabId ? tab : { tabId: newTabId },
      ),
    ],
  };
};

const closeTab = (state: IdeTabsReducerState, tabId: string) => {
  return {
    ...state,
    unpinnedTabId: state.unpinnedTabId !== tabId ? state.unpinnedTabId : null,
    scrollState: _.omit(state.scrollState, [tabId]),
    pinnedFileTabs: state.pinnedFileTabs.filter((tab) => tab.tabId !== tabId),
  };
};

const pinFileTab = (state: IdeTabsReducerState, tabId: string) =>
  isOpened(state, tabId)
    ? { ...state }
    : { ...state, pinnedFileTabs: [...state.pinnedFileTabs, { tabId }] };

const isOpened = (state: IdeTabsReducerState, tabId: string) =>
  state.pinnedFileTabs.find((tab: any) => tab.tabId === tabId);

const addEmbeddedTab = (
  state: IdeTabsReducerState,
  { realEstateId, url, tabTitle, offerGuid, biFileName }: AddEmbeddedTabsArgs,
) =>
  isEmbeddedTabExists(state, url)
    ? { ...state }
    : {
        ...state,
        embeddedTabs: [
          ...state.embeddedTabs,
          {
            realEstateId,
            url,
            title: tabTitle,
            offerGuid,
            biItemName: biFileName,
          },
        ],
      };

const isEmbeddedTabExists = (state: IdeTabsReducerState, url: string) =>
  state.embeddedTabs.find((tab) => {
    return tab.url === url;
  });

const closeEmbeddedTab = (
  state: IdeTabsReducerState,
  tabId: TabsCloseEmbededTab['tabId'],
) => {
  return {
    ...state,
    embeddedTabs: state.embeddedTabs.filter((tab) => tab.url !== tabId),
  };
};
const closeTbfTab = (
  state: IdeTabsReducerState,
  tabId: TabsCloseTbfTab['tabId'],
) => {
  return {
    ...state,
    tbfTabs: state.tbfTabs.filter((tab) => tab.tabId !== tabId),
  };
};

const addOrChangeTbfTab = (
  state: IdeTabsReducerState,
  {
    tabId,
    fileId,
    functionName,
    functionSignature,
    paramNames,
    lineNumber,
  }: Omit<TabsAddOrChangeTbfTab, 'type'>,
) => {
  const tabIndex = state.tbfTabs.findIndex((tab) => tab.tabId === tabId);
  const newTabValues = {
    tabId,
    fileId,
    functionName,
    functionSignature,
    paramNames,
    lineNumber,
  };
  return tabIndex === -1
    ? {
        ...state,
        tbfTabs: [...state.tbfTabs, newTabValues],
      }
    : {
        ...state,
        tbfTabs: state.tbfTabs.map((tab, idx) =>
          tabIndex === idx ? newTabValues : tab,
        ),
      };
};

const moveFolder = (state: IdeTabsReducerState, action: MoveFolder) => {
  let newState = { ...state };
  const upninnedTab = state.unpinnedTabId;
  const pinnedTabs = state.pinnedFileTabs;

  if (upninnedTab?.startsWith(action.oldLocation)) {
    const newUnpinnedTab = upninnedTab.replace(
      action.oldLocation,
      action.newLocation,
    );
    newState = renameTab(state, upninnedTab, newUnpinnedTab);
  }
  pinnedTabs
    .filter((tab) => tab.tabId.startsWith(action.oldLocation))
    .forEach((tab) => {
      const newTabId = tab.tabId.replace(
        action.oldLocation,
        action.newLocation,
      );
      newState = renameTab(newState, tab.tabId, newTabId);
    });
  return { ...newState };
};

const ideTabsReducer = (state = initialState, action: IdeTabsReducerAction) => {
  switch (action.type) {
    case ActionType.TABS_PIN_TAB:
      return pinFileTab(state, action.tabId);
    case ActionType.FILE_TREE_NODE_DOUBLE_CLICK:
      return pinFileTab(state, action.fileId);
    case ActionType.TABS_CLOSE_TAB:
      return closeTab(state, action.tabId);
    case ActionType.CHANGE_CONTENT:
      if (
        !action.fileId.startsWith('public/pages') ||
        action.fileId === getMasterPageFileId()
      ) {
        return pinFileTab(state, action.fileId);
      }
      return { ...state };
    case ActionType.REMOVE_INSTALLED_CODE_REUSE_PKG:
      return {
        ...state,
        pinnedFileTabs: state.pinnedFileTabs.filter(
          (tab) => !_.startsWith(tab.tabId, action.codeReusePkgData.name),
        ),
      };
    case ActionType.REMOVE_FILE:
      return closeTab(state, action.fileId);
    case ActionType.REMOVE_FILES_UNDER:
      return {
        ...state,
        pinnedFileTabs: state.pinnedFileTabs.filter(
          (tab) => !_.startsWith(tab.tabId, action.fileId),
        ),
      };
    case ActionType.TABS_CLOSE_EMBEDDED_TAB:
      return closeEmbeddedTab(state, action.tabId);
    case ActionType.HELP_LINK_CLICK:
      if (action.id === RealEstateIds.HELP_ARTICLE_TAB_REAL_ESTATE_ID) {
        return addEmbeddedTab(state, {
          realEstateId: RealEstateIds.HELP_ARTICLE_TAB_REAL_ESTATE_ID,
          ...action.linkData,
        });
      }
      return { ...state };
    case ActionType.TABS_ADD_OR_CHANGES_TBF_TAB:
      return addOrChangeTbfTab(state, {
        tabId: action.tabId,
        fileId: action.fileId,
        functionName: action.functionName,
        functionSignature: action.functionSignature,
        paramNames: action.paramNames,
        lineNumber: action.lineNumber,
      });
    case ActionType.TABS_CLOSE_TBF_TAB:
      return closeTbfTab(state, action.tabId);
    case ActionType.DEALER_RESPONSE_DONE:
      const { realEstateId, payload } = action;
      if (realEstateId === RealEstateIds.PUSH_NOTIFICATION_REAL_ESTATE_ID) {
        const { url, tabTitle, offerGuid, biFileName } = payload;
        return addEmbeddedTab(state, {
          realEstateId,
          url,
          tabTitle,
          offerGuid,
          biFileName,
        });
      }
      return { ...state };
    case ActionType.TABS_OPEN_OR_CHANGE_UNPINNED_TAB:
      return { ...state, unpinnedTabId: action.tabId };
    case ActionType.TABS_STORE_SCROLL_STATE:
      return {
        ...state,
        scrollState: {
          ...state.scrollState,
          [action.tabId]: action.scroll,
        },
      };
    case ActionType.MOVE_FOLDER:
      return moveFolder(state, action);

    default:
      return { ...state };
  }
};

export default ideTabsReducer;
