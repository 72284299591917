import * as React from 'react';
import { CodeReusePkgTreeConfigFile } from './CodeReusePkgTreeConfigFile';
import { CodeReuseSubTree, CodeReuseSubTreeProps } from './CodeReuseSubTree';
import { consts } from '@wix/wix-code-consts';
import { isReusePackageByVelo } from '@/toExtract/packages/utils';
import { PkgTreeContext } from '../PkgTreeContext';

export const CodeReusePkgFirstLevelTree: React.FC<CodeReuseSubTreeProps> = (
  props,
) => {
  const { isBlocksCombinedMode } = React.useContext(PkgTreeContext);
  const { dataSource, pkgName } = props;
  const { childItems } = dataSource;

  const genChildProps = (child: AnyFixMe): CodeReuseSubTreeProps => {
    // values here are taken out or props again to create rest - we know they already exist.
    // eslint-disable-next-line no-shadow
    const { depth, labelOverride, dataHook, ...rest } = props;
    return {
      ...rest,
      isRoot: false,
      depth: depth + 1,
      dataSource: child,
    };
  };
  const isPrivate = !isReusePackageByVelo(pkgName);
  const readme = childItems.find(
    (item) => item.name === consts.README_FILE_NAME,
  );
  const publicFolder = childItems.find((item) => item.name === 'public');
  const backendFolder = childItems.find((item) => item.name === 'backend');

  return (
    <>
      {readme && <CodeReuseSubTree {...genChildProps(readme)} />}
      <CodeReusePkgTreeConfigFile name={dataSource.name} />
      {isBlocksCombinedMode && isPrivate ? null : (
        <>
          {publicFolder && (
            <CodeReuseSubTree {...genChildProps(publicFolder)} />
          )}
          {backendFolder && (
            <CodeReuseSubTree {...genChildProps(backendFolder)} />
          )}
        </>
      )}
    </>
  );
};
