import React, { useState } from 'react';
import dataHooks from '../dataHooks';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { TreeSectionDivider, TreeListItem } from '@wix/wix-base-ui';
import s from '../LeftPaneWithTabs.module.scss';
import { useTranslate } from '@/infra/i18n/useTranslate';
import { experimentUtils } from '@wix/wix-code-common';
import {
  leftTreeClickOnAnItem,
  leftTreeClickOnASection,
} from '@wix/bi-logger-platform/v2';
import { useBiLogger } from '@/infra/wixCodeContext/BiLoggerAPIContext';

interface LinkItem {
  biItemName: string;
  label: string;
  onClickLinkHandler: () => void;
}

export interface HelpTabLinkProps {
  helpArticle?: {
    biItemName: string;
    linkName: string;
    symbolName: string;
    onClick: () => void;
  };
  links: LinkItem[];
}

export const HelpTabLinks: React.FC<HelpTabLinkProps> = ({
  links,
  helpArticle,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslate();
  const biLogger = useBiLogger();

  const onClickSectionDividerHandler = () => {
    const newState = !collapsed;
    setCollapsed(newState);
    biLogger.report(
      leftTreeClickOnASection({
        section_name: 'links',
        action: newState ? 'close' : 'open',
      }),
    );
  };
  const onClickGettingStartedHandler = () => {
    biLogger.report(
      leftTreeClickOnAnItem({
        item_name: helpArticle?.biItemName || helpArticle?.linkName,
        item_type: 'resource',
      }),
    );
    helpArticle?.onClick();
  };
  return (
    <div data-aid="help-links">
      <div data-aid="help-links-section">
        <TreeSectionDivider
          dataHook={dataHooks.SECTION_DIVIDER}
          collapsed={collapsed}
          hideTopBorder={true}
          hideBottomBorder={collapsed}
          label={t('Left_Tree_Components_Category_HelpLinks')}
          onClick={onClickSectionDividerHandler}
        />
      </div>
      {experimentUtils.isRearrangeHelpTabResources() && helpArticle && (
        <TreeListItem
          className={s.blueListItem}
          dataHook={dataHooks.TREE_NODE}
          shouldTranslate={false}
          label={t('WixCode_GettingStarted_TabTitle')}
          icon={<Symbol name={helpArticle.symbolName} />}
          size="small"
          onClick={onClickGettingStartedHandler}
        />
      )}
      {!collapsed && (
        <div data-aid="help-items" className={s.sectionContainer}>
          {links.map(({ biItemName, onClickLinkHandler, label }) => (
            <React.Fragment key={biItemName}>
              <TreeListItem
                className={s.blueListItem}
                dataHook={dataHooks.TREE_NODE}
                label={label}
                size="small"
                suffix={<Symbol name="link_icon" />}
                onClick={onClickLinkHandler}
              />
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
