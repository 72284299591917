import React, { useContext } from 'react';
import { dataHooks } from '../dataHooks';
import { CustomModal } from '@wix/wix-base-ui';
import styles from '../ExternalDBPanels.scss';
import { ExternalDbsModalContext } from '../ExternalDbsModalContext';

interface CustomModalProps {
  title?: string;
  onCtaClick: () => void;
  onSecondaryButtonClick: () => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onCloseButtonClick: () => void;
  onHelpButtonClick: () => void;
  footerContent?: React.ReactNode;
}

export const CustomisedModal: React.FC<CustomModalProps> = ({
  title,
  children,
  onCtaClick,
  primaryButtonText,
  onCloseButtonClick,
  onHelpButtonClick,
  secondaryButtonText,
  onSecondaryButtonClick,
  footerContent,
}) => {
  const { panelsAPI } = useContext(ExternalDbsModalContext);
  return (
    <CustomModal
      title={title}
      footnote={footerContent}
      dataHook={dataHooks.externalDbPanels.container}
      className={styles.modalContainer}
      onCloseButtonClick={onCloseButtonClick}
      onHelpButtonClick={onHelpButtonClick}
      primaryButtonOnClick={onCtaClick}
      primaryButtonProps={{
        dataHook: dataHooks.externalDbPanels.primaryButton,
      }}
      secondaryButtonOnClick={() => {
        onSecondaryButtonClick();
        panelsAPI.closePanel();
      }}
      secondaryButtonProps={{
        dataHook: dataHooks.externalDbPanels.secondaryButton,
      }}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
    >
      {children}
    </CustomModal>
  );
};
