export type BiSenderFunc = (
  event: BiEventDefinition,
  options: { [key: string]: string },
) => void;

interface BiEventDefinition {
  evid: number;
  src: number;
  endpoint: string;
  fields: { [key: string]: string };
}

export type TopBarEventParams = {
  item_name: string;
  origin: 'IDE_top_bar' | 'keyboard_shortcut';
};

export const BI_EVENTS: { [key: string]: BiEventDefinition } = {
  LEFT_TREE_CHANGE_CURRENT_TAB: {
    evid: 226,
    src: 79,
    endpoint: 'platform',
    fields: {
      origin: 'string',
      input_name: 'string',
      tab_name: 'string',
    },
  },
  LEFT_TREE_CLICK_ON_ITEM: {
    evid: 93,
    src: 79,
    endpoint: 'platform',
    fields: {
      item_name: 'string',
      item_type: 'string',
    },
  },
  // "sub_section" is a confusing name, due to some bi limitations we could not rename it to "group".
  LEFT_TREE_CLICK_ON_SECTION: {
    evid: 94,
    src: 79,
    endpoint: 'platform',
    fields: {
      section_name: 'string',
      action: 'string',
    },
  },
  LEFT_TREE_CLICK_ON_SETTINGS: {
    evid: 95,
    src: 79,
    endpoint: 'platform',
    fields: {
      item_name: 'string',
      item_type: 'string',
    },
  },
  LEFT_TREE_CLICK_ON_PLUS: {
    evid: 96,
    src: 79,
    endpoint: 'platform',
    fields: {
      section_name: 'string',
    },
  },
  LEFT_TREE_CLICK_ON_SETTINGS_ITEM: {
    evid: 97,
    src: 79,
    endpoint: 'platform',
    fields: {
      item_name: 'string',
      item_type: 'string',
      menu_entry_name: 'string',
    },
  },
  LEFT_TREE_CLICK_ON_ADD_QUICK_LINK: {
    evid: 98,
    src: 79,
    endpoint: 'platform',
    fields: {
      section_name: 'string',
    },
  },
  LEFT_TREE_CLICK_ON_PLUS_ITEM: {
    evid: 99,
    src: 79,
    endpoint: 'platform',
    fields: {
      section_name: 'string',
      menu_entry_name: 'string',
    },
  },
  LEFT_TREE_ITEM_CREATED: {
    evid: 100,
    src: 79,
    endpoint: 'platform',
    fields: {
      section_name: 'string',
      origin: 'string',
      path: 'string',
      type: 'string',
    },
  },
  LEFT_TREE_ITEM_RENAMED: {
    evid: 190,
    src: 79,
    endpoint: 'platform',
    fields: {
      section_name: 'string',
      path: 'string',
      type: 'string',
    },
  },
  PROPERTIES_PANEL_NICKNAME_EDITED: {
    endpoint: 'code',
    src: 39,
    evid: 133,
    fields: {
      element_type: 'string',
      element_id: 'string',
      site_id: 'guid',
    },
  },
  PROPERTIES_PANEL_EDITOR_REFERRAL: {
    endpoint: 'code',
    src: 39,
    evid: 134,
    fields: {
      file_name: 'string',
      referral_name: 'string',
    },
  },
  PROPERTIES_PANEL_CLICK_ON_LINK: {
    endpoint: 'code',
    src: 39,
    evid: 138,
    fields: {
      link_text: 'string',
      related_link: 'string',
    },
  },
  PROPERTIES_PANEL_TOGGLE: {
    endpoint: 'code',
    src: 39,
    evid: 141,
    fields: {
      state: 'string',
      site_id: 'guid',
      origin: 'string',
    },
  },
  PROPERTIES_PANEL_NICKNAME_FOCUSED: {
    endpoint: 'code',
    src: 39,
    evid: 142,
    fields: {
      element_type: 'string',
      element_id: 'string',
      site_id: 'guid',
    },
  },
  PROPERTIES_PANEL_ENABILITY_TOGGLE: {
    endpoint: 'code',
    src: 39,
    evid: 145,
    fields: {
      element_type: 'string',
      element_id: 'string',
      site_id: 'guid',
      state: 'string',
    },
  },
  PROPERTIES_PANEL_VISIBILITY_TOGGLE: {
    endpoint: 'code',
    src: 39,
    evid: 146,
    fields: {
      element_type: 'string',
      element_id: 'string',
      is_visible: 'bool',
      site_id: 'guid',
    },
  },
  PROPERTIES_PANEL_COLLAPSIBLITY_TOGGLE: {
    endpoint: 'code',
    src: 39,
    evid: 147,
    fields: {
      element_type: 'string',
      element_id: 'string',
      is_collapsed: 'bool',
      site_id: 'guid',
    },
  },
  PROPERTIES_PANEL_EVENT_HANDLER_ADD_PRESSED: {
    endpoint: 'code',
    src: 39,
    evid: 148,
    fields: {
      default_handler_name: 'string',
      element_id: 'string',
      element_type: 'string',
      event: 'string',
      site_id: 'guid',
    },
  },
  PROPERTIES_PANEL_EVENT_HANDLER_ADDED: {
    endpoint: 'code',
    src: 39,
    evid: 149,
    fields: {
      element_id: 'string',
      element_type: 'string',
      event: 'string',
      handler_name: 'string',
      site_id: 'guid',
    },
  },
  PROPERTIES_PANEL_EVENT_HANDLER_NAVIGATION: {
    endpoint: 'code',
    src: 39,
    evid: 150,
    fields: {
      element_id: 'string',
      element_type: 'string',
      event: 'string',
      handler_name: 'string',
      site_id: 'guid',
    },
  },
  PROPERTIES_PANEL_EVENT_HANDLER_REMOVED: {
    endpoint: 'code',
    src: 39,
    evid: 151,
    fields: {
      element_id: 'string',
      element_type: 'string',
      event: 'string',
      handler_name: 'string',
      site_id: 'guid',
    },
  },
  LEFT_TREE_CLICK_ON_COLLECTION: {
    evid: 102,
    src: 83,
    endpoint: 'platform-cm',
    fields: {
      origin: 'string',
      collection_name: 'string',
    },
  },
  LEFT_TREE_CLICK_ON_PERMISSIONS: {
    evid: 120,
    src: 83,
    endpoint: 'platform-cm',
    fields: {
      origin: 'string',
      collection_name: 'string',
    },
  },
  LOG_CONSOLE_SEVERITY_FILTER_CHANGE: {
    evid: 139,
    src: 79,
    endpoint: 'platform',
    fields: {
      action: 'string',
      severity_name: 'string',
    },
  },
  LOG_CONSOLE_DEEP_LINK_CLICK: {
    evid: 138,
    src: 79,
    endpoint: 'platform',
    fields: {
      section_name: 'string',
      severity_name: 'string',
    },
  },

  SEARCH_ALL_FILES_ENTRY: {
    evid: 179,
    src: 79,
    endpoint: 'platform',
    fields: {
      origin: 'string',
    },
  },
  SEARCH_ALL_FILES_SEARCH: {
    evid: 192,
    src: 79,
    endpoint: 'platform',
    fields: {
      num_of_results: 'number',
      is_case: 'bool',
      is_whole_word: 'bool',
      is_reg_exp: 'bool',
      input_name: 'string',
    },
  },
  SEARCH_ALL_FILES_SELECT_RESULT: {
    evid: 193,
    src: 79,
    endpoint: 'platform',
    fields: {
      file_name: 'string',
    },
  },
  IS_POTENTIAL_FOR_SSR_CACHE_CORVID_PAGE: {
    evid: 204,
    src: 79,
    endpoint: 'platform',
    fields: {
      page_name: 'string',
      pageurl: 'string',
    },
  },
  AUTO_CACHE_ON_AND_MIGHT_HAVE_NEGATIVE_IMPACT: {
    evid: 375,
    src: 79,
    endpoint: 'platform',
    fields: {
      page_name: 'string',
      pageurl: 'string',
    },
  },
  CLICKED_ON_TURNING_ON_CACHING_IDE: {
    evid: 205,
    src: 79,
    endpoint: 'platform',
    fields: {
      page_name: 'string',
      pageurl: 'string',
    },
  },
  CLICKED_ON_LEARN_MORE_REGARDING_CACHING_IDE: {
    evid: 206,
    src: 79,
    endpoint: 'platform',
    fields: {
      page_name: 'string',
      pageurl: 'string',
    },
  },
  CLICKED_ON_DISMISS_CACHING_OPTION_IDE: {
    evid: 207,
    src: 79,
    endpoint: 'platform',
    fields: {
      page_name: 'string',
      pageurl: 'string',
    },
  },
  CLICKED_ON_LEARN_MORE_REGARDING_TEST_SITE: {
    evid: 380,
    src: 79,
    endpoint: 'platform',
    fields: {
      page_name: 'string',
      pageurl: 'string',
    },
  },
  CLICKED_ON_DISMISS_TEST_SITE_LOG: {
    evid: 381,
    src: 79,
    endpoint: 'platform',
    fields: {
      page_name: 'string',
      pageurl: 'string',
    },
  },
  TEST_SITE_MSG_POP_ON_IDE: {
    evid: 379,
    src: 79,
    endpoint: 'platform',
    fields: {
      page_name: 'string',
      pageurl: 'string',
    },
  },
  IDE_CODE_CHANGE: {
    evid: 191,
    src: 79,
    endpoint: 'platform',
    fields: {
      file_name: 'string',
    },
  },
  IDE_TAB_ACTION: {
    evid: 222,
    src: 79,
    endpoint: 'platform',
    fields: {
      origin: 'string',
      file_name: 'string',
      tab_number: 'number',
      num_of_opened_tabs: 'number',
      action: 'string',
    },
  },
  IDE_POPUP_SHOWN: {
    evid: 231,
    src: 79,
    endpoint: 'platform',
    fields: {
      origin: 'string',
      popup_name: 'string',
    },
  },
  IDE_POPUP_CLOSED: {
    evid: 232,
    src: 79,
    endpoint: 'platform',
    fields: {
      origin: 'string',
      popup_name: 'string',
    },
  },
  IDE_TOGGLE_JS_SITE: {
    evid: 112,
    src: 79,
    endpoint: 'platform',
    fields: {
      toggle_name: 'string',
    },
  },
  IDE_TOOLBAR_BUTTON_CLICK: {
    evid: 113,
    src: 79,
    endpoint: 'platform',
    fields: {
      item_name: 'string',
      origin: 'string',
    },
  },
  IDE_TOOLBAR_TOGGLE: {
    evid: 114,
    src: 79,
    endpoint: 'platform',
    fields: {
      toggle_name: 'string',
    },
  },
  IDE_OPEN_MEDIA_MANAGER: {
    evid: 115,
    src: 79,
    endpoint: 'platform',
    fields: {
      type: 'string',
    },
  },
  IDE_MEDIA_ADDED: {
    evid: 116,
    src: 79,
    endpoint: 'platform',
    fields: {
      type: 'string',
    },
  },
  IDE_THEME_SELECT: {
    evid: 113,
    src: 79,
    endpoint: 'platform',
    fields: {
      item_name: 'string',
    },
  },
  IDE_MORE_DROPDOWN_OPEN: {
    evid: 113,
    src: 79,
    endpoint: 'platform',
    fields: {
      item_name: 'string',
    },
  },
  PACKAGE_REQUEST_SUBMITTED: {
    evid: 147,
    src: 79,
    endpoint: 'platform',
    fields: {
      item_name: 'string',
      item_version: 'string',
      reason: 'string',
    },
  },
  PACKAGE_REQUEST_STATUS_TRACK: {
    evid: 167,
    src: 79,
    endpoint: 'platform',
    fields: {
      stage: 'string',
    },
  },
  PKG_INSTALL_FAILED: {
    evid: 413,
    src: 79,
    endpoint: 'platform',
    fields: {
      appId: 'string',
      item_name: 'string',
      item_version: 'string',
      item_type: 'string',
      is_latest_version: 'bool',
    },
  },
  PKG_UNINSTALL_CLICKED: {
    evid: 143,
    src: 79,
    endpoint: 'platform',
    fields: {
      appId: 'string',
      item_name: 'string',
      item_version: 'string',
      item_type: 'string',
    },
  },
  PKG_INSTALL_CLICKED: {
    evid: 144,
    src: 79,
    endpoint: 'platform',
    fields: {
      appId: 'string',
      item_name: 'string',
      item_version: 'string',
      item_type: 'string',
      is_latest_version: 'bool',
    },
  },
  PKG_QUICK_ACTION_CLICKED: {
    evid: 145,
    src: 79,
    endpoint: 'platform',
    fields: {
      item_name: 'string',
      item_type: 'string',
      category: 'string',
      menu_entry_name: 'string',
    },
  },
  NPM_SEARCH_MORE_ACTIONS_CLICKED: {
    evid: 159,
    src: 79,
    endpoint: 'platform',
    fields: {
      item_name: 'string',
      item_type: 'string',
    },
  },
  TBF_OPEN_TBF_TAB: {
    evid: 240,
    src: 79,
    endpoint: 'platform',
    fields: {
      is_default_function: 'bool',
      parameters_count: 'numeric',
      function_name: 'string',
      file_name: 'string',
      origin: 'string',
    },
  },
  TBF_TRIGGER_PANEL_ACTION: {
    evid: 241,
    src: 79,
    endpoint: 'platform',
    fields: {
      is_default_function: 'bool',
      parameters_count: 'numeric',
      function_name: 'string',
      file_name: 'string',
      origin: 'string',
      action: 'string',
    },
  },
  TBF_TRIGGER_BE_FUNCTION: {
    evid: 242,
    src: 79,
    endpoint: 'platform',
    fields: {
      is_default_function: 'bool',
      parameters_count: 'numeric',
      function_name: 'string',
      file_name: 'string',
      origin: 'string',
      is_default_params: 'bool',
    },
  },
  TBF_BE_FUNCTION_FINISHED: {
    evid: 243,
    src: 79,
    endpoint: 'platform',
    fields: {
      is_default_function: 'bool',
      is_default_params: 'bool',
      parameters_count: 'numeric',
      function_name: 'string',
      file_name: 'string',
      exception: 'string',
      function_output: 'string',
      exception_type: 'string',
      file_type: 'string',
      run_time: 'numeric',
    },
  },
  CODE_REUSE_ADD_DEPENDENCY_CLICK: {
    evid: 245,
    src: 79,
    endpoint: 'platform',
    fields: {
      related_link: 'string',
      link_name: 'string',
      link_type: 'string',
      origin: 'string',
    },
  },
  TBF_VALID_EXPORTED_FUNCTION: {
    evid: 247,
    src: 79,
    endpoint: 'platform',
    fields: {
      is_default_function: 'bool',
      function_name: 'string',
      file_name: 'string',
      origin: 'string',
    },
  },
  PREVIEW_CLICK: {
    evid: 250,
    src: 38,
    endpoint: 'editor',
    fields: {
      origin: 'string',
    },
  },
  TBF_RUN_BUTTON_EXPOSURE: {
    evid: 289,
    src: 79,
    endpoint: 'platform',
    fields: {
      file_name: 'string',
    },
  },

  TRANSLATION_KEY_USED: {
    evid: 325,
    src: 79,
    endpoint: 'platform',
    fields: {
      translationKeys: 'string',
    },
  },
};

export default BI_EVENTS;
