import React from 'react';
import { ReadOnlyMode } from '@wix/wix-code-plugin-contracts';

type ReadOnlyModeContextProps = {
  readOnlyMode: ReadOnlyMode;
};

const defaultReadOnlyMode: ReadOnlyMode = {
  propertiesPanel: {
    nicknamesSection: false,
    propsSection: false,
    eventsSection: false,
  },
  codeIde: false,
  fileSystem: false,
  cssEditing: {
    addGlobalCss: false,
    semanticClasses: false,
  },
  externalIde: false,
};

export const ReadOnlyModeContext =
  React.createContext<ReadOnlyModeContextProps>({
    readOnlyMode: defaultReadOnlyMode,
  });
