import { Checkbox, Heading, Text, TextButton, Button } from '@wix/wix-base-ui';
import React, { useState } from 'react';
import s from './SpiWizard.scss';
import { useTranslation } from '@wix/wix-i18n-config';
import { CustomModal } from '../CustomModal/CustomModal';
import cx from 'classnames';
import { dataHooks } from '../../consts/dataHooks';
import { SpiProviderType } from '../../SpiTypes';
import { acceptLegalEvent, cancelLegalEvent } from '../../utils/reportEvents';
import { BiLoggerAPI } from '@wix/wix-code-plugin-contracts';

export const WizardStep2: React.FC<{
  spiType: SpiProviderType;
  onConfirm: () => void;
  onClose: () => void;
  onHelp: () => void;
  biLoggerAPI: BiLoggerAPI;
}> = ({ onConfirm, spiType, onClose, biLoggerAPI }) => {
  const [t] = useTranslation();
  const [isChecked, setIsChecked] = useState(false);

  const acceptLegal = () => {
    onConfirm();
    biLoggerAPI.report(acceptLegalEvent({ spiType: spiType.type }));
  };

  const cancelLegal = () => {
    onClose();
    biLoggerAPI.report(cancelLegalEvent({ spiType: spiType.type }));
  };

  return (
    <CustomModal dataHook={dataHooks.SPI_WIZARD_STEP_2} {...{ onClose }}>
      <div className={cx(s.wizardContainerPhase2, s.wizardContainer)}>
        <Heading appearance="h2" shouldTranslate={false}>
          {t('Wizard_Phase_2_Heading')}
        </Heading>
        <div className={s.smallMargin} />
        <div className={s.maxWidth}>
          <Text skin="secondary" shouldTranslate={false} enableEllipsis={false}>
            {t(spiType.translationKeys.wizardPhase2Subheading)}
          </Text>
        </div>
        <div className={s.footer}>
          <div className={s.termsSection}>
            <Checkbox
              shouldTranslate={false}
              value={isChecked}
              onChange={() => {
                setIsChecked(!isChecked);
              }}
              dataHook={dataHooks.SPI_WIZARD_STEP_2_CHECKBOX}
            />
            <div className={s.termsText}>
              <Text size="small" shouldTranslate={false}>
                {t('Wizard_Phase_2_SideAction_Text')}
              </Text>
            </div>
            <TextButton
              size="small"
              onClick={() =>
                window.open(t('Wizard_Phase_2_SideAction_Link_URL'), '_blank')
              }
              shouldTranslate={false}
            >
              {t('Wizard_Phase_2_SideAction_Link_Text')}
            </TextButton>
          </div>
          <div>
            <Button
              className={cx('btn-md', 'btn-confirm-secondary')}
              onClick={cancelLegal}
              dataHook={dataHooks.SPI_WIZARD_STEP_2_CANCEL_BUTTON}
            >
              {t('Wizard_Phase_2_Cancel')}
            </Button>
            <Button
              className={cx(s.marginLeft, 'btn-md')}
              disabled={!isChecked}
              onClick={acceptLegal}
              dataHook={dataHooks.SPI_WIZARD_STEP_2_ACCEPT_BUTTON}
            >
              {t('Wizard_Phase_2_Accept')}
            </Button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};
