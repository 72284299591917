import { ActionType } from '@/infra/redux-state/actions/types';
import { FileSubTreeDataSource } from '@/sidebar/filesTree/FileSubTree';
import {
  CodeReuseFsAddRootParams,
  CodeReuseFsSetExpandedParams,
} from './codeReuseFsActions';

export type CodeReduseFsReducerState = {
  [name: string]: CodeReuseFsAddRootParams['files'];
};

export type CodeReuseFSAddRoot = {
  pkg: CodeReuseFsAddRootParams;
  type: ActionType.CODE_REUSE_FS_ADD_ROOT;
};
export type CodeReuseFSSetExpanded = {
  toExpand: CodeReuseFsSetExpandedParams;
  type: ActionType.CODE_REUSE_FS_SET_EXPANDED;
};
export type CodeReuseFSRemoveRoot = {
  name: string;
  type: ActionType.CODE_REUSE_FS_REMOVE_ROOT;
};
export type CodeReuseFSExpandParents = {
  fileId: string;
  type: ActionType.CODE_REUSE_FS_EXPAND_PARENTS;
};

type CodeReduseFsReducerAction =
  | CodeReuseFSAddRoot
  | CodeReuseFSSetExpanded
  | CodeReuseFSRemoveRoot
  | CodeReuseFSExpandParents;

const initialState: CodeReduseFsReducerState = {};

const setExpand = (
  tree: FileSubTreeDataSource,
  fileId: string,
  expanded: boolean,
): FileSubTreeDataSource => {
  return {
    ...tree,
    expanded: fileId === tree.id ? expanded : tree.expanded,
    childItems: tree.childItems.map((child) =>
      setExpand(child, fileId, expanded),
    ),
  };
};

const setExpandedRecursive = (
  tree: FileSubTreeDataSource,
  fileId: string,
): FileSubTreeDataSource => {
  return {
    ...tree,
    expanded: fileId.indexOf(tree.id) === 0 ? true : tree.expanded,
    childItems: tree.childItems.map((child) =>
      setExpandedRecursive(child, fileId),
    ),
  };
};

const codeReuseFsReducer = (
  state = initialState,
  action: CodeReduseFsReducerAction,
) => {
  switch (action.type) {
    case ActionType.CODE_REUSE_FS_ADD_ROOT:
      const { pkg } = action;
      return {
        ...state,
        [pkg.name]: pkg.files,
      };
    case ActionType.CODE_REUSE_FS_SET_EXPANDED:
      const {
        toExpand: { name, fileId, expanded },
      } = action;
      return {
        ...state,
        [name]: setExpand(state[name], fileId, expanded),
      };
    case ActionType.CODE_REUSE_FS_REMOVE_ROOT:
      const { [action.name]: fsToRemove, ...rest } = state;
      return { ...rest };
    case ActionType.CODE_REUSE_FS_EXPAND_PARENTS:
      const [pkgScope, pkgName] = action.fileId.split('/');
      return {
        ...state,
        [`${pkgScope}/${pkgName}`]: setExpandedRecursive(
          state[`${pkgScope}/${pkgName}`],
          action.fileId,
        ),
      };
    default:
      return state;
  }
};

export default codeReuseFsReducer;
