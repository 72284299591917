import React, { useContext } from 'react';
import { RichText } from '@wix/wix-base-ui';
import { PackagesModalContext } from '../../../packagesModalContext';
import s from './PackageMarkDown.scss';
import { MODAL_TABS } from '../../packagesModalConsts';
import { PackageItemContext } from '../pkgLists/PackageItemContext';

export const PackageMarkDownFooter: React.FC = () => {
  const { currentTab, t } = useContext(PackagesModalContext);
  const { isCodeReusePackage } = useContext(PackageItemContext);

  const learnMoreReuseUrl =
    'https://support.wix.com/en/article/velo-working-with-velo-packages';

  return currentTab === MODAL_TABS.INSTALLED_PACKAGES ? null : (
    <div className={s.footer}>
      <RichText className={s.linkWrapper}>
        <a
          target="_blank"
          rel="noreferrer"
          href={
            isCodeReusePackage
              ? learnMoreReuseUrl
              : t('Package_Manager_Popup_Search_EmptyState_Link_Url')
          }
          data-aid="readme-more-info-link"
        >
          {t(
            isCodeReusePackage
              ? 'Package_Manager_ReadMe_Help'
              : 'Package_Manager_Popup_Search_EmptyState_Link',
          )}
        </a>
      </RichText>
    </div>
  );
};
