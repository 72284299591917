import React from 'react';
import { PredefinedSidebarTabs } from '@wix/wix-code-plugin-contracts';
import SearchIcon from '../icons/search.svg';
import { SearchPanelContainer } from './SearchPanelContainer';

export const panelHeaderData = {
  label: `Left_Tree_Components_Title_Search`,
  infoLinkText: `Left_Tree_Components_Tooltip_Search_Link_Text`,
  infoLinkUrl: 'Left_Tree_Components_Tooltip_Search_Link_URL',
  infoText: `Left_Tree_Components_Tooltip_Search`,
};

export const createSearchPanel = () => ({
  shouldShowTab: () => true,
  id: PredefinedSidebarTabs.SEARCH,
  dataHook: `${PredefinedSidebarTabs.SEARCH}_tab`,
  symbol: <SearchIcon />,
  creator: () => <SearchPanelContainer />,
  ...panelHeaderData,
});
