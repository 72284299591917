import React from 'react';
import { Preloader, Spacer } from '@wix/wix-base-ui';
import styles from './CenteralLoaderSpinner.scss';
import dataHooks from '../../dataHooks';
import InteractiveText from './InteractiveText/InteractiveText';

const CenterLoaderSpinner: React.FC<{ texts: string[] }> = ({ texts }) => {
  return (
    <div
      data-hook={dataHooks.connectModal.centralLoaderSpinner}
      className={styles.container}
    >
      <div>
        <div className={styles.loader}>
          <Preloader className="large" />
        </div>
        <Spacer type="Spacer05" />
        <InteractiveText texts={texts} />
      </div>
    </div>
  );
};

export default CenterLoaderSpinner;
