import type {
  EditorAPI,
  LegacyEditorDependencies,
} from '@wix/wix-code-plugin-contracts';
import { Message } from '@/infra/redux-state/reducers/consoleReducer';

interface RemoveActionProps {
  text: string;
  callback: (message: Message) => void;
}

interface AddConsoleMessage {
  editorAPI: EditorAPI;
  logLevel: string;
  args: Message['args'];
  action?: RemoveActionProps;
}

interface isNotificationDismissedProps {
  editorAPI: EditorAPI;
  stateManagement: LegacyEditorDependencies['stateManagement'];
  userPreferencesKey: string;
}

interface removeMessageForeverProp {
  editorAPI: EditorAPI;
  stateManagement: LegacyEditorDependencies['stateManagement'];
  userPreferencesKey: string;
  messageId: Message['id'];
}
export interface Page {
  id: string;
  type: string;
}
export function isNotificationDismissed({
  stateManagement,
  editorAPI,
  userPreferencesKey,
}: isNotificationDismissedProps) {
  return stateManagement.editorPlugins.userPreferences.selectors.getSiteUserPreferences(
    userPreferencesKey,
  )(editorAPI.store.getState());
}

export const addMessageToConsole = async ({
  editorAPI,
  logLevel,
  args,
  action,
}: AddConsoleMessage) => {
  editorAPI.wixCode.console.addSingleMessage({
    logLevel,
    args,
    action,
  });
};

export function removeMessageForever({
  editorAPI,
  stateManagement,
  userPreferencesKey,
  messageId,
}: removeMessageForeverProp) {
  editorAPI.wixCode.console.removeMessage(messageId);
  editorAPI.store.dispatch(
    stateManagement.editorPlugins.userPreferences.actions.setSiteUserPreferences(
      userPreferencesKey,
      true,
    ),
  );
}
export function genBiData(editorAPI: EditorAPI, pageId: Page['id']) {
  return {
    page_name: editorAPI.pages.getPageTitle(pageId),
    pageurl: editorAPI.pages.getPageUrl(pageId),
    pageId,
  };
}
