import React from 'react';
import { PanelHeader } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import {
  LegacyEditorDependencies,
  PanelsAPI,
} from '@wix/wix-code-plugin-contracts';
import { TranslationProvider } from '@wix/wix-code-common-components';

type CollectionsMenuTabProps = {
  closePanel: () => void;
  apis: {
    legacyDependenciesAPI: LegacyEditorDependencies;
    panelsAPI: PanelsAPI;
  };
};

type CollectionsTabPanelHeaderProps = {
  closePanel: () => void;
  panelsAPI: PanelsAPI;
};

const CollectionsTabPanelHeader: React.FC<CollectionsTabPanelHeaderProps> = ({
  closePanel,
  panelsAPI,
}) => {
  const [t] = useTranslation();

  const onOpenHelp = () => {
    panelsAPI.openHelpCenter('3c070cf4-973e-411b-afa1-1e9a80fcda23');
  };

  return (
    <PanelHeader
      onHelp={onOpenHelp}
      onClose={closePanel}
      className="dragger dark"
      defaultCursor
    >
      {t('Lite_Panel_Menu_Databases')}
    </PanelHeader>
  );
};

export const createCollectionsMenuTab: React.FC<CollectionsMenuTabProps> = ({
  closePanel,
  children,
  apis,
}) => {
  const { panelsAPI, legacyDependenciesAPI } = apis;

  return (
    <TranslationProvider
      options={{
        locale: legacyDependenciesAPI.util.editorModel.languageCode,
        asyncMessagesLoader: (locale: string) =>
          import(`../../assets/locale/messages_${locale}.json`),
      }}
    >
      <CollectionsTabPanelHeader
        closePanel={closePanel}
        panelsAPI={panelsAPI}
      />
      {children}
    </TranslationProvider>
  );
};
