import * as React from 'react';
import s from './PackagesListItemData.scss';
import dataHooks from '../../../../dataHooks';
import { TextLabel, RichText, TooltipOnEllipsis } from '@wix/wix-base-ui';
import { ReactElement } from 'react';

interface PackagesListItemDetailsDisplayProps {
  packageName: ReactElement;
  packageDescription?: string;
  packageTitle?: string;
  packageLicense?: string;
  displayVersion?: string;
  downloadCount?: Number;
  packageDescriptionElement?: ReactElement;
  publishedDate?: string;
}

export const PackagesListItemDetailsDisplay: React.FC<
  PackagesListItemDetailsDisplayProps
> = ({
  packageName,
  packageDescription,
  packageTitle,
  packageLicense,
  displayVersion,
  downloadCount,
  publishedDate,
}) => {
  return (
    <div className={s.packageData}>
      <div className={s.firstRow}>
        <RichText
          className={s.title}
          dataHook={dataHooks.PKG_TITLE}
          type="T06"
          shouldTranslate={false}
        >
          {packageTitle ? packageTitle : packageName}
        </RichText>
        {displayVersion ? (
          <TextLabel
            dataHook={dataHooks.INSTALLED_PKG_VERSION}
            className={s.version}
            type="T12"
            shouldTranslate={false}
            value={displayVersion}
          />
        ) : null}
        {packageLicense ? (
          <TextLabel
            className={s.license}
            type="T12"
            shouldTranslate={false}
            value={packageLicense}
          />
        ) : null}
        {downloadCount ? (
          <TextLabel
            className={s.downloadCount}
            type="T12"
            shouldTranslate={false}
            value={downloadCount.toString()}
          />
        ) : null}
        {publishedDate ? (
          <TextLabel
            className={s.downloadCount}
            type="T12"
            shouldTranslate={false}
            value={publishedDate}
          />
        ) : null}
      </div>
      {packageTitle ? (
        <RichText
          className={packageTitle ? s.importName : undefined}
          type="T12"
          shouldTranslate={false}
          dataHook={dataHooks.PKG_SUB_TITLE}
        >
          {packageName}
        </RichText>
      ) : null}
      {packageDescription ? (
        <TooltipOnEllipsis>
          <RichText
            className={s.description}
            type="T02"
            shouldTranslate={false}
            dataHook={dataHooks.PACKAGE_DESCRIPTION}
          >
            {packageDescription}
          </RichText>
        </TooltipOnEllipsis>
      ) : null}
    </div>
  );
};
