import React, { Fragment, useContext } from 'react';
import _ from 'lodash';
import { TreeSectionDivider } from '@wix/wix-base-ui';
import { monitoring, utilsCreator } from '@wix/wix-code-common';
import { leftTreeClickOnASection } from '@wix/bi-logger-platform/v2';
import { LightboxPage } from './LightboxPage';
import dataHooks from '../dataHooks';
import { PagesTabContext } from '../pagesTree/PagesTabContext';
import { PageTreeContext } from '../pagesTree/PageTreeContext';

interface LightboxTreeProps {
  dataSource: any;
}

const { withErrorHandling } = monitoring;
export const LightboxTree = ({ dataSource }: LightboxTreeProps) => {
  const { bi, legacyDependenciesAPI } = useContext(PagesTabContext);
  const { isCollapsed, setCollapsed, selectedIdeTabId, onPageSelected } =
    useContext(PageTreeContext);
  const { experiment, platform, util } = legacyDependenciesAPI;
  const utils = utilsCreator({ experiment, platform, util });

  const getId = () => 'page_' + _.get(dataSource, 'id');
  const [collapsed, setCollapsedState] = React.useState(isCollapsed(getId()));

  const onNodeClick = () =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickOnASection({
          section_name: 'lightboxes',
          action: collapsed ? 'open' : 'close',
        }),
      );
      setCollapsed(getId(), !collapsed);
      setCollapsedState(!collapsed);
    })();

  return (
    <Fragment>
      <div data-aid="lightboxes-section">
        <TreeSectionDivider
          dataHook={dataHooks.SECTION_DIVIDER}
          label={dataSource.label}
          collapsed={collapsed}
          hideBottomBorder={collapsed}
          onClick={onNodeClick}
          size="small"
        />
      </div>
      {!collapsed && (
        <ul
          data-aid="lightboxes-items"
          key="subtree"
          className="section-container"
        >
          {dataSource.items &&
            dataSource.items.map((child: AnyFixMe) => (
              <li key={child.id}>
                <LightboxPage
                  selectedIdeTabId={selectedIdeTabId}
                  dataSource={child}
                  onPageSelected={onPageSelected}
                  getFileIdFromPageId={utils.getFileIdFromPageId}
                />
              </li>
            ))}
        </ul>
      )}
    </Fragment>
  );
};
