import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import {
  FORMS_NAMESPACE,
  NamespaceWithStatus,
} from './components/CollectionsTree/CollectionsTree';
import { byHook, dataHooks } from './dataHooks';

export const scrollToFormsNamespace = () => {
  const y = document
    .getElementById(FORMS_NAMESPACE)
    ?.getBoundingClientRect().top;
  document.querySelector(byHook(dataHooks.TAB_CONTENT))?.scroll(0, y! - 200);
};

export const getElementByHook = (element: HTMLElement, hook: string) =>
  element.querySelector(`[data-hook="${hook}"]`);

export const getMyScopeName = (editorAPI: EditorAPI) =>
  '@' + editorAPI.dsRead.generalInfo.getUserInfo().name;

export const sortByNamespace = (
  namespace1: NamespaceWithStatus,
  namespace2: NamespaceWithStatus,
) => namespace1.namespace.localeCompare(namespace2.namespace);
