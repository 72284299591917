import { ClassicEditorAPI, EditorAPI } from '@wix/wix-code-plugin-contracts';

export const createClassicEditorAPI = (
  editorAPI: EditorAPI,
): ClassicEditorAPI => ({
  editorAPI,
  observeEditorStore: (selector, listener) => {
    let currentState: ReturnType<typeof selector>;

    function handleChange() {
      const nextState = selector(editorAPI.store.getState());
      if (nextState !== currentState) {
        const previousState = currentState;
        currentState = nextState;
        listener(nextState, previousState);
      }
    }

    const unsubscribe = editorAPI.store.subscribe(handleChange);
    handleChange();
    return unsubscribe;
  },
});
