import { APIKeys, EntryPoint, Shell } from '@wix/wix-code-repluggable';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import _ from 'lodash';

import wixCodeWsListener from '@wix/wix-code-ws-listener';

import { definitionsApi } from '@wix/wix-code-code-editor';
import consoleApiCreatorFactory from '@/legacy/core/services/consoleApiCreator';
import fileSystemApiCreatorFactory from '@/fileSystem/fileSystemApiCreator';
import mediaManagementServiceCreator from '@/legacy/core/services/mediaManagementServiceCreator';
import ideApiCreatorFactory from '@/legacy/core/services/ideApiCreator';
import collectionsTreeHandlerService from '../editorAPIContributions/collectionsTreeHandlerService';
import eventServiceCreator from '../editorAPIContributions/eventService';
import tbfServiceCreatorFactory from '@/toExtract/backendDebugging/services/tbfServiceCreator';
import codeEditorApiServiceCreator from '../editorAPIContributions/codeEditorApiService';
import uiServiceCreator from '../editorAPIContributions/uiService';
import fileTreeHandlerServiceCreator from '../editorAPIContributions/fileTreeHandlerService';
import models from '@/legacy/core/services/modelsApi';
import storeManager from '@/infra/redux-state/store/storeManager';

export const WixCodeEditorApiEntryPoint: EntryPoint = {
  name: 'wixCode editorAPI wrapper',
  getDependencyAPIs() {
    return [
      WixCodeEditorAdapterAPI,
      APIKeys.ClassicEditorAPI,
      APIKeys.ReadOnlyAPI,
      APIKeys.CodeEditorAPI,
      APIKeys.DevContextAPI,
      APIKeys.ExperimentsAPI,
    ];
  },
  extend(shell: Shell) {
    const readOnlyAPI = shell.getAPI(APIKeys.ReadOnlyAPI);
    const devContextAPI = shell.getAPI(APIKeys.DevContextAPI);
    const codeEditorAPI = shell.getAPI(APIKeys.CodeEditorAPI);
    const { legacyDependencies } = shell.getAPI(WixCodeEditorAdapterAPI);
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
    const experimentsAPI = shell.getAPI(APIKeys.ExperimentsAPI);
    const { constants, experiment, platform, stateManagement, util } =
      legacyDependencies;
    const store = storeManager.getStore();
    const consoleApiCreator = consoleApiCreatorFactory({
      constants,
      experiment,
      platform,
      stateManagement,
      util,
      wixCodeWsListener,
    });
    const fileSystemApiCreator = fileSystemApiCreatorFactory(
      legacyDependencies,
      readOnlyAPI,
      devContextAPI,
      experimentsAPI,
    );
    const ideApiCreator = ideApiCreatorFactory({
      codeIntelligence: codeEditorAPI.codeIntelligence,
    });

    const wixCodeAPI = {
      wixCode: {
        fileSystem: fileSystemApiCreator(store, editorAPI),
        console: consoleApiCreator(store, editorAPI),
        ide: ideApiCreator(store, editorAPI),
        events: eventServiceCreator({ experiment, platform, util }),
        fileTree: fileTreeHandlerServiceCreator({
          constants,
          experiment,
          platform,
          stateManagement,
          util,
        }),
        collectionsTree: collectionsTreeHandlerService,
        ui: uiServiceCreator({ experiment, platform, util }),
        media: mediaManagementServiceCreator(editorAPI),
        codeEditorApi: codeEditorApiServiceCreator(),
        models,
        definitions: definitionsApi,
        tbfService: tbfServiceCreatorFactory({
          experiment,
          platform,
          util,
          constants,
        })({ editorAPI, store }),
      },
    };

    _.merge(editorAPI, wixCodeAPI);
  },
};
