import { SlotKey } from '@wix/wix-code-repluggable';
import { EditorAPI, WixCodeDuplexerAPI } from '@wix/wix-code-plugin-contracts';

import * as constants from './constants';

interface CSSEditingInternalAPIParams {
  editorAPI: EditorAPI;
  wixCodeDuplexerAPI: WixCodeDuplexerAPI;
}

export interface ExternalComponentsInternalAPI {
  updateViewerOnChanges: () => void;
}

export const ExternalComponentsInternalAPIKey: SlotKey<ExternalComponentsInternalAPI> =
  {
    name: 'ExternalComponentsInternalAPI',
    public: false,
  };

export const createExternalComponentsAPI = ({
  editorAPI,
  wixCodeDuplexerAPI,
}: CSSEditingInternalAPIParams): ExternalComponentsInternalAPI => {
  const api: ExternalComponentsInternalAPI = {
    updateViewerOnChanges: () => {
      const refreshFilesOrFolders = (path: string) => {
        editorAPI.wixCode.fileSystem.refreshFilesOrFolders([
          editorAPI.wixCode.fileSystem.getVirtualDescriptor(path, true),
        ]);
      };

      const shouldRefresh = (fileId: string) =>
        fileId.startsWith(constants.COMPONENTS_PATH) ||
        fileId.startsWith(constants.PUBLIC_PATH);

      editorAPI.wixCode.fileSystem.subscribeToFlush((changes) => {
        const toSaveFiles = changes.toSave.filter(({ fileId }) =>
          shouldRefresh(fileId),
        );
        const toDeleteFiles = Object.keys(changes.toDelete).filter((fileId) =>
          shouldRefresh(fileId),
        );

        if (toSaveFiles.length > 0 || toDeleteFiles.length > 0) {
          refreshFilesOrFolders(constants.COMPONENTS_PATH);
        }
      });

      wixCodeDuplexerAPI.subscribeTo.codeChanged((payload) => {
        const files = [
          ...(payload.createdOrUpdatedFiles || []),
          ...(payload.deletedFiles || []),
          ...(payload.deletedDirectories || []),
        ];

        if (files.some((fileId) => shouldRefresh(fileId))) {
          refreshFilesOrFolders(constants.COMPONENTS_PATH);
        }
      });
    },
  };
  return api;
};
