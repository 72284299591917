import coreUtilsLib from '@wix/santa-core-utils';
import { consts } from '@wix/wix-code-consts';
import fileActions from './fileActions';
import { ActionType } from './types';
import { utilsCreator } from '@wix/wix-code-common';
import * as codeEditor from '@wix/wix-code-code-editor';
import once_ from 'lodash/once';

export default once_(({ experiment, platform, util }) => {
  const utils = utilsCreator({ experiment, platform, util });

  function loadById({ pageId, defaultContent }) {
    function loadPageFile(editorAPI) {
      return editorAPI.wixCode.pages.hasCode(pageId)
        ? editorAPI.wixCode.pages.readCode(pageId)
        : Promise.resolve(defaultContent);
    }

    function errorHandler(serverResponse) {
      if (serverResponse.xhr && serverResponse.xhr.status === 404) {
        return Promise.resolve(defaultContent);
      }

      return Promise.reject(serverResponse);
    }

    const fileId = utils.getFileIdFromPageId(pageId);

    return fileActions.wrapLoadFile(loadPageFile, errorHandler)(fileId);
  }

  function load({ context, defaultContent }) {
    return loadById({ pageId: context.data.id, defaultContent });
  }

  function setCurrentTab(currentTab) {
    return {
      type: ActionType.PAGE_EDITOR_SET_CURRENT_TAB,
      currentTab,
    };
  }

  function addExportStatement(currentPageId, showOnAllPages, exportStatement) {
    const pageId = showOnAllPages ? consts.SITE_JS_PAGE_ID : currentPageId;
    const fileId = utils.getFileIdFromPageId(pageId);

    return function (dispatch, getState, editorAPI) {
      return dispatch(fileActions.loadFile(fileId))
        .then(function (
          readFileContent = utils.getDefaultPageContent(
            utils.isMasterPageId(pageId),
          ),
        ) {
          const result = codeEditor.codeExportsService.addExport(
            readFileContent,
            exportStatement,
          );

          const changeContentPromise = dispatch(
            fileActions.changeContent(fileId, result.newCode),
          );
          dispatch(
            setCurrentTab(
              showOnAllPages ? consts.PAGE_TABS.SITE : consts.PAGE_TABS.PAGE,
            ),
          );

          dispatch(
            fileActions.setSelection(fileId, {
              start: result.caretLocation,
              end: result.caretLocation,
            }),
          );

          // Delay the focus to the next tick.
          // This eliminates the possibility of an ongoing
          // input event affecting the code editor text.
          // Example: adding this export statement by pressing "Enter"
          // in the properties panel
          editorAPI.wixCode.codeEditorApi.performActionOnMount(fileId, (api) =>
            setTimeout(() => api && api.focus(), 1),
          );

          return changeContentPromise.then(() => result);
        })
        .catch(function (error) {
          coreUtilsLib.log.error(error);

          return Promise.reject(error);
        });
    };
  }

  function setFocusOnEventHandler(currentPageId, showOnAllPages, functionName) {
    return (dispatch, getState, editorAPI) => {
      const pageId = showOnAllPages ? consts.SITE_JS_PAGE_ID : currentPageId;
      const fileId = utils.getFileIdFromPageId(pageId);

      return dispatch(loadById({ pageId })).then(function (readFileContent) {
        let caretInformation;

        try {
          caretInformation =
            codeEditor.codeExportsService.getCaretInfoForExportStatement(
              readFileContent,
              functionName,
            );
        } catch (e) {
          return Promise.reject(new Error('cannot navigate due to code error'));
        }

        if (caretInformation.found) {
          dispatch(
            setCurrentTab(
              showOnAllPages ? consts.PAGE_TABS.SITE : consts.PAGE_TABS.PAGE,
            ),
          );

          dispatch(
            fileActions.setSelection(fileId, {
              start: caretInformation.caretLocation,
              end: caretInformation.caretLocation,
            }),
          );

          const codeEditorApi = editorAPI.wixCode.codeEditorApi;
          codeEditorApi.performActionOnMount(
            fileId,
            (api) => api && api.focus(),
          );
        }

        return caretInformation.found;
      });
    };
  }

  function setCollapsed(itemIds, collapsed) {
    return {
      type: ActionType.PAGE_EDITOR_SET_COLLAPSED,
      itemIds,
      collapsed,
    };
  }

  function setRenaming(itemId, renaming) {
    return {
      type: ActionType.PAGE_EDITOR_SET_RENAMING,
      itemId,
      renaming,
    };
  }

  return {
    load,
    loadById,
    setCurrentTab,
    addExportStatement,
    setFocusOnEventHandler,
    setCollapsed,
    setRenaming,
  };
});
