import React, { useContext, useState } from 'react';
import { Badge, TreeListItem, TextLabel, TextButton } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import { dataHooks } from '../dataHooks';

import s from './EmptyState/ParagraphEmptyState.scss';
import cx from 'classnames';
import { NamespaceWithStatus } from './CollectionsTree/CollectionsTree';
import { CloudDbContextMenu } from './CloudDbContextMenu';
import {
  CollectionsTreeNode,
  NodeData,
  SchemaActions,
} from './CollectionsTreeNode/CollectionsTreeNode';
import { TabContext } from './tabContext';
import { leftTreeClickAddNewQuickLink } from '@wix/bi-logger-platform/v2';

import { bi } from '../bi';

export interface CloudDbListItemProps {
  db: NamespaceWithStatus;
  collectionsRoot: NodeData | undefined;
  onCollectionSelected: (collectionId: string) => void;
  onContextMenuActionSelected: ({
    action,
    nodeData,
    biItemType,
  }: {
    action: string;
    nodeData: NodeData;
    biItemType: string;
  }) => void;
  schemaActions: SchemaActions;
}

export const CloudDbListItem: React.FC<CloudDbListItemProps> = ({
  db,
  collectionsRoot,
  onCollectionSelected,
  onContextMenuActionSelected,
  schemaActions,
}) => {
  const [collapsed, setCollapsed] = useState<string>('collapsed');

  const { editorAPI, legacyEditorDependencies, platformAppsAPI, biLoggerAPI } =
    useContext(TabContext);

  const [t] = useTranslation();

  const collectionsUnderDb = () => {
    if (!collectionsRoot) {
      return false;
    }
    return !!collectionsRoot?.childItems?.length;
  };

  return (
    <div data-hook={dataHooks.READY_CLOUD_DB}>
      <TreeListItem
        dataHook={dataHooks.TREE_NODE}
        label={db.namespace}
        shouldTranslate={false}
        depth={1}
        size="small"
        collapseState={collapsed}
        indication={{
          node: (
            <Badge
              value={t(
                'WixCode_TreeView_ExternalDatabases.Created.Setup.Badge.Ready',
              )}
              skin="success"
            />
          ),
        }}
        onClick={() => {
          setCollapsed(collapsed === 'collapsed' ? 'expanded' : 'collapsed');
        }}
        suffix={<CloudDbContextMenu db={db} />}
      />
      {collapsed === 'expanded' ? (
        collectionsUnderDb() ? (
          collectionsRoot?.childItems?.map((child) => (
            <li key={`schema-${child.schema.id}`}>
              <CollectionsTreeNode
                editorAPI={editorAPI}
                loading={false}
                onCreateCollection={() => {}}
                onCollectionSelected={onCollectionSelected}
                onFolderToggle={() => {}}
                onContextMenuActionSelected={onContextMenuActionSelected}
                schemaActions={schemaActions}
                isTopLevelTreeRoot={false}
                depth={2}
                nodeData={child}
                util={legacyEditorDependencies.util}
                biSectionName={bi.subSections.EXTERNAL_DATABASES}
              />
            </li>
          ))
        ) : (
          <div className={s.cloudDbContainer}>
            <div className={s.textWrapper}>
              <TextLabel
                className={s.description}
                dataHook="description"
                enableEllipsis={false}
                type="T20"
                shouldTranslate={false}
                value={t(
                  'WixCode_TreeView_ExternalDatabases.Created.EmptyState',
                )}
              />
            </div>
            <TextButton
              className={cx(s.ctaButton, s.cta)}
              onClick={() => {
                biLoggerAPI.report(
                  leftTreeClickAddNewQuickLink({
                    input_name: bi.subSections.EXTERNAL_DATABASES,
                    sub_section: 'googleDatabase',
                  }),
                );
                platformAppsAPI.notifyDataBinding({
                  eventType:
                    legacyEditorDependencies.platform.constants.EVENTS
                      .createCollectionButtonClicked,
                  eventPayload: {
                    origin: 'ideTree',
                    clickedInSiteStructure: true,
                    namespace: db.namespace,
                  },
                });
              }}
              dataHook="cta-button"
              disabled={false}
              shouldTranslate={false}
            >
              {t('WixCode_TreeView_ExternalDatabases.Created.CTA')}
            </TextButton>
          </div>
        )
      ) : null}
    </div>
  );
};
