export const GithubMessageType = {
  SignIn: 'sign_in',
  InstallApp: 'install_app',
};

export const SHOULD_NOT_SHOW_BEFORE_PUBLISH_KEY =
  'GITHUB_SHOULD_NOT_SHOW_BEFORE_PUBLISH_MODAL';
export const GITHUB_REPOSITORY_EXISTS_ERROR = 409;
export const GITHUB_LOGIN_SESSION_EXPIRED_ERROR = 428;

export enum ON_BOARDING_SUPPORTED_ERRORS {
  GITHUB_APP_INSTALLATION_SUSPENDED = 'GITHUB_APP_INSTALLATION_SUSPENDED',
  GITHUB_APP_INSTALLATION_NOT_FOUND = 'GITHUB_APP_INSTALLATION_NOT_FOUND',
  REPOSITORY_NOT_FOUND = 'REPOSITORY_NOT_FOUND',
  GENERIC_ERROR = 'GENERIC_ERROR',
}

export const EXPERIMENTS = {
  GithubConnectionIssuesModal: 'specs.wixCode.GithubConnectionIssuesModal',
};
