export default {
  NPM_PKGS_CONTAINER: 'npm-pkgs-container',
  NPM_PKGS_LIST: 'npm-pkgs-list',
  NPM_INSTALL_BUTTON: 'install-pkg-button',
  NPM_SEARCH_BOX_INPUT: 'npm-pkg-search-box-input',
  CODE_REUSE_PACKAGES_LIST: 'code-reuse-packages-list',
  BUILT_BY_VELO_PACKAGES_LIST: 'built-by-velo-packages-list',
  MY_PACKAGE_PACKAGES_LIST: 'my-packages-packages-list',
  MY_PACKAGE_EMPTY_STATE: 'my-packages-empty-state',
  MY_PACKAGE_EMPTY_LEARN_MORE: 'my-packages-learn-more-link',
  MY_PACKAGE_EMPTY_CREATE_PKG: 'create-a-package-link',
  PKG_LIST_ITEM: 'pkg-list-item',
  PKG_MODAL_CONTAINER: 'pkg-modal-container',
  PKG_MODAL_MENU: 'pkg-modal-menu',
  INSTALLED_PKGS_SECTION_TITLE: 'installed-pkgs-section-title',
  PKG_TITLE: 'pkg-title',
  PKG_SUB_TITLE: 'pkg-sub-title',
  PKG_ACTION_BUTTON: 'pkg-action-button',
  PKG_ACTION_LOADER: 'pkg-action-loader',
  INSTALLED_PKGS_CONTAINER: 'installed-pkgs',
  INSTALLED_NPM_PKGS: 'installed-npm-pkgs',
  INSTALLED_PKGS_LOADER: 'installed-pkgs-loader',
  INSTALLED_CODE_REUSE_PKGS: 'installed-code-reuse-pkgs',
  INSTALLED_LABEL: 'installed-label',
  INSTALLED_PKGS_BUILT_BY_VELO: 'installed-pkgs-built-by-velo',
  INSTALLED_PKGS_BUILT_BY_OTHERS: 'installed-pkgs-built-by-others',
  INSTALLED_PKGS_MY_PKGS: 'installed-pkgs-my-pkgs',
  INSTALLED_PKG_VERSION: 'installed-pkg-version',
  INSTALLED_PKGS_EMPTY_STATE: 'installed-pkgs-empty-state',
  INSTALLED_PKGS_EMPTY_STATE_SEARCH_VELO_LINK:
    'installed-pkgs-empty-state-search-velo-link',
  INSTALLED_PKGS_EMPTY_STATE_SEARCH_NPM_LINK:
    'installed-pkgs-empty-state-npm-link',
  INSTALLED_PKGS_EMPTY_STATE_SUBTITLE: 'installed-pkgs-empty-state-subtitle',
  INSTALLED_PKGS_EMPTY_STATE_SUBTITLE_NO_CODE_REUSE:
    'installed-pkgs-empty-state-subtitle-no-code-reuse',
  PKG_MANAGER_README_PKG_NAME: 'readme-package-name',
  PKG_MANAGER_README_PKG_VERSION: 'readme-package-version',
  PKG_MANAGER_README_PKG_INFO: 'readme-package-info',
  PKG_MANAGER_MANAGE_TAB_SECTION: 'pkg-manager-manage-tab-section',
  PKG_MANAGER_INSTALLED_TAB: 'pkg-manager-installed-tab',
  PKG_MANAGER_VELO_TAB: 'pkg-manager-velo-tab',
  PKG_MANAGER_NPM_TAB: 'pkg-manager-npm-tab',
  PKG_MANAGER_BUILT_BY_VELO_TAB: 'pkg-manager-built-by-velo-tab',
  PKG_MANAGER_MY_PACKAGES_TAB: 'pkg-manager-my-packages-tab',
  PKG_MANAGER_README_ANY_NPM_WARNING: 'readme-any-npm-warning',
  CONTEXT_MENU_ICON: 'context-menu-icon',
  PKG_MODAL_MARKDOWN: 'pkg-modal-markdown',
  MARKDOWN_LOADER: 'pkg-modal-markdown__loader',
  MARKDOWN_CONTENT: 'markdown-content',
  MARKDOWN_WRAPPER: 'pkg-modal-markdown__wrapper',
  OPEN_DOCS_NPM_LINK: 'open-docs-npm-link',
  PKG_MODAL_TAB_SUBTITLE: 'pkg-modal-tab-subtitle',
  VERSION_PICKER: 'version-picker',
  VERSION_BACK_BUTTON: 'version-back-button',
  VERSION_ITEM: 'version-item',
  TEST_VERSION: 'test-version',
  PACKAGE_DESCRIPTION: 'package-description',
  MY_PKGS_CREATE_NEW_PKG: 'my-pkgs-create-new-pkg',
  VELO_SEARCH_BOX_INPUT: 'velo-search-box-input',
  BUILT_BY_VELO_EMPTY_STATE: 'built-by-velo-empty-state',
  AUTO_UPDATE_TOGGLE: 'auto-update-toggle',
  AUTO_UPDATE_SWITCH: 'auto-update-switch',
  VERSION_LOADER: 'version-loader',
};
