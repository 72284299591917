import { ActionType } from '@/infra/redux-state/actions/types';
import { AppData } from '../appDataService';

export type appDataReducerState = {
  data: Record<string, AppData>;
  isLoaded: boolean;
  isLoading: boolean;
};
const initialState: appDataReducerState = {
  data: {},
  isLoaded: false,
  isLoading: false,
};

export type SetAppsData = {
  appsData: AppData[];
  type: ActionType.SET_APPS_DATA;
};
export type SetAppsDataLoaded = {
  isLoaded: boolean;
  type: ActionType.SET_APPS_DATA_LOADED;
};
export type SetAppsDataLoading = {
  isLoading: boolean;
  type: ActionType.SET_APPS_DATA_LOADING;
};
type AppDataReducerAction =
  | SetAppsData
  | SetAppsDataLoaded
  | SetAppsDataLoading;

const appDataReducer = (state = initialState, action: AppDataReducerAction) => {
  switch (action.type) {
    case ActionType.SET_APPS_DATA:
      for (const appData of action.appsData) {
        state.data[appData.appDefinitionId] = appData;
      }
      return { ...state };
    case ActionType.SET_APPS_DATA_LOADED:
      return {
        ...state,
        isLoaded: action.isLoaded,
      };
    case ActionType.SET_APPS_DATA_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export default appDataReducer;
