import { Shell } from '@wix/wix-code-repluggable';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { LocalEditorPublicAPI } from '@wix/wix-code-plugin-contracts';
import { LocalEditorPrivateAPIKey } from './LocalEditorPrivateAPI';

export const createLocalEditorPublicAPI = (
  shell: Shell,
): LocalEditorPublicAPI => {
  const wixCodeEditorAdapterAPI = shell.getAPI(WixCodeEditorAdapterAPI);
  const localEditorPrivateAPI = shell.getAPI(LocalEditorPrivateAPIKey);

  const getLocalPort = (): number | undefined =>
    wixCodeEditorAdapterAPI.legacyDependencies.util.url.parseUrlParams(
      window.location,
    ).localPort;

  return {
    isInLocalEditor: () => {
      return !!getLocalPort();
    },
    contributeOnCLIServiceDisconnect:
      localEditorPrivateAPI.contributeOnCLIServiceDisconnect,
  };
};
