import codeStateReader from '@/infra/redux-state/reducers/codeStateReader';
import { AppState } from '@/infra/redux-state/reducers/rootReducer';
import {
  NpmPackageInfo,
  PackageInstallationStatus,
} from '../../../../../packagesModalContext';
import { NpmModuleReducerState } from '../reducers/modulesReducer';
import { DefaultNpmPackageDescriptor } from '../top10NpmPackages.const';

const getInstalling = (state: { npmModules: NpmModuleReducerState }) =>
  state.npmModules.installingInProgress;
const getSearchKeyword = (state: { npmModules: NpmModuleReducerState }) =>
  state.npmModules.searchKeyword;
const getSelectedTab = (state: { npmModules: NpmModuleReducerState }) =>
  state.npmModules.selectedTab;
const shouldPromptError = (state: { npmModules: NpmModuleReducerState }) =>
  state.npmModules.promptError;
const shouldShowSubmitRequestPanel = (state: {
  npmModules: NpmModuleReducerState;
}) => state.npmModules.promptSubmitRequest;

const getInstalledModulesInfo = (state: AppState) =>
  codeStateReader.getNpmPackages(state);

const getInstalledModulesVersion = (state: AppState) => {
  const installedModulesInfo = getInstalledModulesInfo(state);
  return installedModulesInfo.reduce((acc, pkg) => {
    const { name, version } = pkg;
    acc[name] = version;
    return acc;
  }, {} as AnyFixMe);
};

const addStatus = (state: AppState, pkg: NpmPackageInfo): NpmPackageInfo => {
  const getStatus = (): PackageInstallationStatus => {
    if (getInstalling(state)?.[pkg.name]) {
      return 'INSTALLING';
    }
    if (getInstalledModulesVersion(state)[pkg.name]) {
      return 'INSTALLED';
    }
    return 'NOT_INSTALLED';
  };
  const status = getStatus();
  return { ...pkg, status };
};

const getIndexSearchModules = (state: AnyFixMe): NpmPackageInfo[] => {
  const installedVersions = getInstalledModulesVersion(state);
  return state.npmModules.indexSearchPackages.map((npmPackage: AnyFixMe) =>
    addStatus(state, {
      ...npmPackage,
      installedVersion: installedVersions[npmPackage.name],
    }),
  );
};

const getIndexSearchIsFetching = (state: {
  npmModules: NpmModuleReducerState;
}) => state.npmModules?.indexSearchIsFetching;

const getIsPackagesFetchingDone = (state: {
  npmModules: NpmModuleReducerState;
}) => state.npmModules.fetchPackagesDone;

const getNpmModules = (state: AnyFixMe) => state.npmModules;

const getDefaultNpmPackages = (state: AppState): NpmPackageInfo[] => {
  const { defaultNpmPackages } = state.npmModules as NpmModuleReducerState;
  const installedVersions = getInstalledModulesVersion(state);
  return (
    defaultNpmPackages?.pkgInfo.map((pkg: AnyFixMe) =>
      addStatus(state, {
        ...pkg,
        installedVersion: installedVersions[pkg.name],
      }),
    ) || []
  );
};

const getDefaultNpmPackagesDescriptors = (state: {
  npmModules: NpmModuleReducerState;
}): Record<string, DefaultNpmPackageDescriptor> | undefined =>
  state.npmModules.defaultNpmPackages?.descriptors;

const getSelectedPkgData = (state: { npmModules: NpmModuleReducerState }) =>
  state.npmModules.selectedPkgData;

export {
  getInstalling,
  getSearchKeyword,
  getSelectedTab,
  getInstalledModulesInfo,
  getInstalledModulesVersion,
  shouldPromptError,
  shouldShowSubmitRequestPanel,
  getIndexSearchIsFetching,
  getIndexSearchModules,
  getIsPackagesFetchingDone,
  getDefaultNpmPackages,
  getDefaultNpmPackagesDescriptors,
  getSelectedPkgData,
  getNpmModules,
};
