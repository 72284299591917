import React from 'react';
import { Button, Text } from '@wix/wix-base-ui';
import s from './Banner.scss';
import { dataHooks } from './dataHooks';

export interface BannerProps {
  description: string;
  onClick: () => void;
  buttonName: string;
  suffixIcon?: React.JSX.Element;
}

export const Banner: React.FC<BannerProps> = ({
  description,
  onClick,
  buttonName,
  suffixIcon,
}) => {
  return (
    <div className={s.banner} data-hook="banner">
      <div className={s.description}>
        <Text
          size="small"
          weight="thin"
          shouldTranslate={false}
          dataHook={dataHooks.description}
        >
          {description}
        </Text>
      </div>
      <Button
        onClick={onClick}
        dataHook={dataHooks.button}
        className="btn-sm btn-confirm-secondary"
        suffixIcon={suffixIcon}
      >
        {buttonName}
      </Button>
    </div>
  );
};
