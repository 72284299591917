import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';

import { CustomisedModal } from '../CustomisedModal/CustomisedModal';
import { useTranslation } from '@wix/wix-i18n-config';
import { ExternalDbsModalContext } from '../ExternalDbsModalContext';

import { ConnectorResponse, ModalStage } from '../../../internal-types';
import { CustomPromotionalModal } from '../PromotionalModal';
import { MessageModal, Preloader, Text } from '@wix/wix-base-ui';
import { CreateExtenalDbDetailsModal } from '../CreateExternalDbDetailsModal/CreateExternalDbDetailsModal';
import styles from './CreateExternalDb.scss';
import { createValidations } from '../ConnectAdapter/validation';
import {
  createExternalDb,
  getConnectorsService,
} from '../../../WixDataServerAPI';
import { DBVersion } from '@wix/ambassador-kore-provisioning-v1-googlesqldb/types';
import DbCreationInProgress from '../../../assets/icons/CreateDbInProgress.svg';
import { ConnectionLoader } from '../ConnectionLoader/ConnectionLoader';
import DbCreationError from '../../../assets/icons/ExternalDbCreationError.svg';
import { dataHooks } from '../dataHooks';
import { BI } from '../constants';
import {
  connectExternalDbPanelAction,
  panelOpened,
} from '@wix/bi-logger-platform/v2';

enum CreateExternalDbStage {
  PromotionalModal,
  DetailsModal,
  Loader,
  SuccessModal,
  Error,
}

export const CreateExternalDb: React.FC = () => {
  const [stage, setStage] = useState<CreateExternalDbStage>(
    CreateExternalDbStage.PromotionalModal,
  );

  const { panelsAPI, wixCodeAppAPI, platformAppsAPI, editorAPI, biLoggerAPI } =
    useContext(ExternalDbsModalContext);
  const [t] = useTranslation();
  const [namespace, setNamespace] = useState<string>('');
  const [dbType, setDbType] = useState<DBVersion>(null as any);
  const [namespaceErrorMessage, setNamespaceErrorMessage] =
    useState<string>('');

  const [didUserStartTyping, setDidUserStartTyping] = useState<boolean>(false);
  const [didUserSelectOption, setDidUserSelectOption] =
    useState<boolean>(false);
  const [didUserClickCreate, setDidUserClickCreate] = useState<boolean>(false);

  const connectorsService = useMemo(
    () => getConnectorsService(wixCodeAppAPI.getSignedInstance),
    [wixCodeAppAPI],
  );
  const [namespaces, setNamespaces] = useState<string[]>(null as any);

  useEffect(() => {
    connectorsService
      .getAllConnectors()
      .then((res: ConnectorResponse[]) => {
        setNamespaces(
          res.map((namespaceObj: ConnectorResponse) => namespaceObj.namespace),
        );
      })
      .catch(() => {
        setNamespaces([]);
      });
  }, [connectorsService]);

  useEffect(() => {
    const namespaceError = createValidations()
      .getNamespaceValidationError(namespace, namespaces, t)
      .getOrElse('');
    setNamespaceErrorMessage(namespaceError);
  }, [namespace, namespaces, t]);

  const generateExternalDb = useCallback(async () => {
    try {
      await createExternalDb(
        namespace,
        dbType,
        wixCodeAppAPI.getSignedInstance,
      );
      await platformAppsAPI.refreshSchemasCache();
      setStage(CreateExternalDbStage.SuccessModal);
    } catch (e) {
      setStage(CreateExternalDbStage.Error);
      return;
    }
  }, [dbType, editorAPI, wixCodeAppAPI, namespace, platformAppsAPI]);

  const CreateExternalDbStages = useMemo<{ [key: number]: ModalStage }>(
    () => ({
      [CreateExternalDbStage.PromotionalModal]: {
        content: <CustomPromotionalModal />,
        biName: BI.panels.promotionalModal,
        withModal: true,
        withCtas: true,
        cta: () => setStage(CreateExternalDbStage.DetailsModal),
        onSecondaryButtonClick: () => {},
        hideTitle: true,
        primaryCtaText: t(
          'External_Databases.Modal.CreateNew.Promotional.PrimaryCTA',
        ),
        secondaryCtaText: t(
          'External_Databases.Modal.CreateNew.Promotional.SecondaryCTA',
        ),
      },
      [CreateExternalDbStage.DetailsModal]: {
        content: namespaces ? (
          <CreateExtenalDbDetailsModal
            dbType={dbType}
            setDbType={setDbType}
            didUserStartTyping={didUserStartTyping}
            namespace={namespace}
            namespaceErrorMessage={namespaceErrorMessage}
            setDidUserStartTyping={setDidUserStartTyping}
            setNamespace={setNamespace}
            didUserSelectOption={didUserSelectOption}
            setDidUserSelectOption={setDidUserSelectOption}
            didUserClickCreate={didUserClickCreate}
          />
        ) : (
          <div className={styles.loaderContainer}>
            <Preloader className="large" />
          </div>
        ),
        biName: BI.panels.createManagedDb,
        withModal: true,
        withCtas: !!namespaces,
        cta: async () => {
          setDidUserStartTyping(true);
          setDidUserClickCreate(true);
          if (didUserSelectOption && !namespaceErrorMessage) {
            setStage(CreateExternalDbStage.Loader);
            await generateExternalDb();
          }
        },
        onSecondaryButtonClick: () => {
          panelsAPI.closePanel();
        },
        primaryCtaText: t('External_Databases.Modal.CreateNew.PrimaryCTA'),
        secondaryCtaText: t('External_Databases.Modal.CreateNew.SecondaryCTA'),
      },
      [CreateExternalDbStage.Loader]: {
        content: <ConnectionLoader />,
        withModal: true,
      },
      [CreateExternalDbStage.SuccessModal]: {
        content: (
          <MessageModal
            dataHook={
              dataHooks.externalDbPanels.createDb.dbCreationInProgressModal
            }
            title={t('External_Databases.Modal.CreationConfirmation.Title')}
            primaryButtonText={t(
              'External_Databases.Modal.CreationConfirmation.PrimaryCTA',
            )}
            primaryButtonOnClick={() => {
              biLoggerAPI.report(
                connectExternalDbPanelAction({
                  input_name: BI.panels.dbCreationPending,
                  button_name: BI.buttons.next,
                  action_type: BI.actions.click,
                }),
              );
              panelsAPI.closePanel();
            }}
            onCloseButtonClick={() => {
              biLoggerAPI.report(
                connectExternalDbPanelAction({
                  input_name: BI.panels.dbCreationPending,
                  button_name: BI.buttons.x,
                  action_type: BI.actions.click,
                }),
              );
              panelsAPI.closePanel();
            }}
            content={
              <>
                <div style={{ display: 'flex', marginBottom: '2px' }}>
                  •
                  <div style={{ marginLeft: '10px' }}>
                    <Text weight="thin">
                      {t(
                        'External_Databases.Modal.CreationConfirmation.Bullet1',
                      )}
                    </Text>
                  </div>
                </div>
                <div style={{ display: 'flex', marginBottom: '2px' }}>
                  •
                  <div style={{ marginLeft: '10px' }}>
                    <Text weight="thin">
                      {t(
                        'External_Databases.Modal.CreationConfirmation.Bullet2',
                      )}
                    </Text>
                  </div>
                </div>
                <div style={{ display: 'flex', marginBottom: '2px' }}>
                  •
                  <div style={{ marginLeft: '10px' }}>
                    <Text weight="thin">
                      {t(
                        'External_Databases.Modal.CreationConfirmation.Bullet3',
                      )}
                    </Text>
                  </div>
                </div>
              </>
            }
            illustration={<DbCreationInProgress />}
          />
        ),
        biName: BI.panels.dbCreationPending,
      },
      [CreateExternalDbStage.Error]: {
        content: (
          <MessageModal
            title={t('External_Databases.Modal.CreationFailed.Title')}
            primaryButtonText={t(
              'External_Databases.Modal.CreationFailed.PrimaryCTA',
            )}
            primaryButtonOnClick={() => {
              biLoggerAPI.report(
                connectExternalDbPanelAction({
                  input_name: BI.panels.dbCreationError,
                  button_name: BI.buttons.tryAgain,
                  action_type: BI.actions.click,
                }),
              );
              setStage(CreateExternalDbStage.Loader);
              generateExternalDb();
            }}
            secondaryButtonText={t(
              'External_Databases.Modal.CreationFailed.SecondaryCTA',
            )}
            secondaryButtonOnClick={() => {
              biLoggerAPI.report(
                connectExternalDbPanelAction({
                  input_name: BI.panels.dbCreationError,
                  button_name: BI.buttons.cancel,
                  action_type: BI.actions.click,
                }),
              );
              panelsAPI.closePanel();
            }}
            onCloseButtonClick={() => {
              biLoggerAPI.report(
                connectExternalDbPanelAction({
                  input_name: BI.panels.dbCreationError,
                  button_name: BI.buttons.x,
                  action_type: BI.actions.click,
                }),
              );
              panelsAPI.closePanel();
            }}
            content={t('External_Databases.Modal.CreationFailed.Description')}
            illustration={<DbCreationError />}
            dataHook={dataHooks.externalDbPanels.createDb.errorModal}
          />
        ),
        biName: BI.panels.dbCreationError,
      },
    }),
    [
      dbType,
      didUserClickCreate,
      didUserSelectOption,
      didUserStartTyping,
      namespace,
      namespaceErrorMessage,
      namespaces,
      panelsAPI,
      t,
      biLoggerAPI,
      generateExternalDb,
    ],
  );

  useEffect(() => {
    setTimeout(() => {
      biLoggerAPI.report(
        panelOpened({
          panel_name: CreateExternalDbStages[stage].biName,
        }),
      );
    }, 500);
    // eslint-disable-next-line
  }, [stage, biLoggerAPI]);

  return CreateExternalDbStages[stage].withModal ? (
    <CustomisedModal
      title={
        CreateExternalDbStages[stage].hideTitle
          ? undefined
          : t('External_Databases.Modal.CreateNew.Title')
      }
      onCtaClick={() => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: CreateExternalDbStages[stage].biName,
            button_name: BI.buttons.next,
            action_type: BI.actions.click,
            selected_database_type: dbType,
            databaseName: namespace,
          }),
        );
        CreateExternalDbStages[stage].cta!();
      }}
      primaryButtonText={
        CreateExternalDbStages[stage].withCtas
          ? CreateExternalDbStages[stage].primaryCtaText
          : undefined
      }
      secondaryButtonText={
        CreateExternalDbStages[stage].withCtas
          ? CreateExternalDbStages[stage].secondaryCtaText
          : undefined
      }
      onSecondaryButtonClick={() => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: CreateExternalDbStages[stage].biName,
            button_name: BI.buttons.cancel,
            action_type: BI.actions.click,
          }),
        );
        CreateExternalDbStages[stage].onSecondaryButtonClick &&
          CreateExternalDbStages[stage].onSecondaryButtonClick!();
      }}
      onCloseButtonClick={() => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: CreateExternalDbStages[stage].biName,
            button_name: BI.buttons.x,
            action_type: BI.actions.click,
          }),
        );
        panelsAPI.closePanel();
      }}
      onHelpButtonClick={() => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: CreateExternalDbStages[stage].biName,
            button_name: BI.buttons.help,
            action_type: BI.actions.click,
          }),
        );
        window.open(t('External_Databases_Modal_Help_Link'), '_blank');
      }}
      footerContent={CreateExternalDbStages[stage].footer}
    >
      {CreateExternalDbStages[stage].content}
    </CustomisedModal>
  ) : (
    CreateExternalDbStages[stage].content
  );
};
