import React from 'react';
import { Text } from '@wix/wix-base-ui';
import { dataHooks } from '../../dataHooks';
import s from '../CollectionsTreeNode/CollectionsTreeNode.scss';

export const LabelWithDescription: React.FC<{
  displayName: string;
  id: string;
}> = ({ displayName, id }) => {
  return (
    <div className={s.schemaFieldContainer}>
      <Text
        size="small"
        skin="secondary"
        dataHook={dataHooks.SCHEMA_FIELD_NAME}
        shouldTranslate={false}
      >
        {displayName}
      </Text>
      <div className={s.marginLeft} />
      <div>
        <Text
          size="small"
          skin="placeholder"
          dataHook={dataHooks.SCHEMA_FIELD_TYPE}
          shouldTranslate={false}
        >{`('${id}')`}</Text>
      </div>
    </div>
  );
};
