import { SearchReducerState } from './searchReducer';

function getSearchQuery(state: { search: SearchReducerState }) {
  return state.search.query;
}

function getSearchResults(state: { search: SearchReducerState }) {
  return state.search.results;
}

export default {
  getSearchQuery,
  getSearchResults,
};
