// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CrvS6N{color:#2d3034;padding:0 16px}.CrvS6N svg path{fill:#2d3034}.CrvS6N.control-button{background-color:rgba(0,0,0,0)}.CrvS6N.control-button .button-content{color:#2d3034}.CrvS6N.control-button{border-left:1px solid #dfe5eb;border-radius:0;height:100%}.CrvS6N:hover svg path{fill:#116dff}.CrvS6N:hover.control-button{background-color:rgba(0,0,0,0)}.CrvS6N:hover.control-button .button-content{color:#116dff}.CrvS6N:disabled svg path{fill:#acafc4}.CrvS6N:disabled.control-button{background-color:rgba(0,0,0,0)}.CrvS6N:disabled.control-button .button-content{color:#acafc4}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"run-code-button": "CrvS6N",
	"runCodeButton": "CrvS6N"
};
export default ___CSS_LOADER_EXPORT___;
