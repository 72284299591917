import * as React from 'react';
import { AppDataService } from '@/toExtract/packages/appDataService';
import {
  AvailableCodeReusePkg,
  InstalledCodeReusePkg,
} from '@/toExtract/packages/packagesModalContext';
import { PackagesService } from '@/toExtract/packages/packagesService';

type CodeReusePkgTreeContextProps = {
  pkg: InstalledCodeReusePkg;
  packagesService: PackagesService;
  appDataService: AppDataService;
  manageInstalledModules: () => void;
  latestVersion: AvailableCodeReusePkg | null;
  isPrivateScope: boolean;
  isByOthers: boolean;
};

export const CodeReusePkgTreeContext =
  React.createContext<CodeReusePkgTreeContextProps>(null as any);
