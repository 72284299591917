import React from 'react';
import {
  Composites,
  DropDown,
  DropDownIllustrationOption,
  TextLabel,
} from '@wix/wix-base-ui';
import dataHooks from '../../../../dataHooks';

import styles from './OrganizationName.scss';
import { GithubUser } from '../../../../../types/GithubTypes';

const OrganizationName: React.FC<{
  translate: (key: string) => string;
  userAccount: string;
  onAccountChange: (value: any) => void;
  gitHubUserInfo: GithubUser;
}> = ({ userAccount, onAccountChange, gitHubUserInfo, translate: t }) => {
  return (
    <div
      className={styles.gitAccountDropdown}
      data-hook={dataHooks.connectModal.createRepository.accountName.container}
    >
      <Composites.DropDownLabeled>
        <TextLabel
          type="T02"
          value={t(
            'githubIntegration.connect_modal.create_repository.account_label',
          )}
          dataHook={dataHooks.connectModal.createRepository.accountName.label}
        />
        <DropDown
          value={userAccount}
          onChange={onAccountChange}
          dataHook={
            dataHooks.connectModal.createRepository.accountName.dropDownInput
          }
        >
          <DropDownIllustrationOption
            value={gitHubUserInfo.userLogin}
            label={gitHubUserInfo.userName || gitHubUserInfo.userLogin}
          >
            <img
              className={styles.gitOrgAvatar}
              src={gitHubUserInfo.avatarUrl}
              alt=""
              data-hook={
                dataHooks.connectModal.createRepository.accountName.avatar
              }
            />
          </DropDownIllustrationOption>
          {gitHubUserInfo.organizations.map((org, idx) => (
            <DropDownIllustrationOption
              key={idx}
              value={org.name}
              label={org.name}
            >
              <img className={styles.gitOrgAvatar} src={org.avatarUrl} alt="" />
            </DropDownIllustrationOption>
          ))}
        </DropDown>
      </Composites.DropDownLabeled>
    </div>
  );
};

export default OrganizationName;
