import { BiLoggerAPI } from '@wix/wix-code-plugin-contracts';
import { createContext, useContext } from 'react';
import webBiLogger from '@wix/web-bi-logger';
import { UserContext } from '@wix/web-bi-logger/dist/src/types';

export const BiLoggerContext = createContext<BiLoggerAPI>(null as any);

export const useBiLogger = () => {
  return useContext(BiLoggerContext);
};

export const createBiLoggerAPI = (userContext: any): BiLoggerAPI => {
  const factory = webBiLogger
    .factory()
    .setMuted(false)
    .withUserContext(userContext as UserContext);
  const biLogger = factory.logger();

  return {
    report: (...args) => biLogger.report(...args),
  };
};
