import React from 'react';

const Padder: React.FC<{ size: string; maxHeight?: boolean }> = ({
  children,
  size,
  maxHeight = false,
}) => {
  return (
    <div
      style={{
        paddingRight: size,
        paddingLeft: size,
        height: maxHeight ? '100%' : undefined,
      }}
    >
      {children}
    </div>
  );
};
export default Padder;
