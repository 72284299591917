import React from 'react';
import { Tooltip, RichText } from '@wix/wix-base-ui';
import PrivateReadOnly from '@/assets/icons/leftTree/privateReadOnly.svg';
import s from '../codeReusePkgTree.scss';
import dataHooks from '../../../../dataHooks';
import { useTranslate } from '@/infra/i18n/useTranslate';

interface PrivateReadOnlySymbolPromps {
  isFolder?: boolean;
}

export const PrivateReadOnlySymbol: React.FC<PrivateReadOnlySymbolPromps> = ({
  isFolder = false,
}) => {
  const { t } = useTranslate();

  return (
    <span data-hook={dataHooks.PRIVATE_READ_ONLY}>
      <Tooltip
        marginTop={4}
        interactive={true}
        content={
          <RichText>
            {isFolder
              ? t('Left_Tree_Components_Private_Read_Only_Folder')
              : t('Left_Tree_Components_Private_Read_Only')}
            <a
              target="_blank"
              className={s.readOnlyLink}
              href={t('Left_Tree_Components_Private_Read_Only_Learn_More_Url')}
            >
              {t('Left_Tree_Components_Private_Read_Only_Learn_More')}
            </a>
          </RichText>
        }
      >
        <PrivateReadOnly className={s.readOnly} />
      </Tooltip>
    </span>
  );
};
