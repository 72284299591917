import React from 'react';
import { classicCodeTemplates } from '@/fileSystem/fileTemplates/classicCodeTemplates';
import { FilesTab } from './FilesTab';
import { BackendFileTree } from './filesTree/BackendFileTree';
import { PublicFileTree } from './filesTree/PublicFileTree';
import { getContributedSections } from './filesTree/treeSectionSelectors';
import { PredefinedSidebarTabs } from '@wix/wix-code-plugin-contracts';
import { useSelector } from '@wix/wix-code-common-components';

interface ClassicFileTabProps {}

export const ClassicFileTab: React.FC<ClassicFileTabProps> = () => {
  const contributedSections = useSelector(getContributedSections);
  const contributedSectionsByOrder = contributedSections
    .map((section) => section)
    .sort((a, b) => a.order - b.order)
    .map((section) => section.createSection);

  const fileTreeSections: React.FC<{
    isLast: boolean;
    translations?: { [key: string]: string };
  }>[] = [PublicFileTree, BackendFileTree].concat(contributedSectionsByOrder);
  return (
    <FilesTab
      codeFilesTab={PredefinedSidebarTabs.SITE_CODE}
      defaultFileTemplate={classicCodeTemplates}
    >
      {fileTreeSections.map((section, index) =>
        React.createElement(section, {
          key: index,
          isLast: index === fileTreeSections.length - 1,
        }),
      )}
    </FilesTab>
  );
};
