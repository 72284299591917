import { Tooltip, Text, TextButton } from '@wix/wix-base-ui';
import React, { useContext } from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import s from './TreeNodeTooltip.scss';
import { learnMoreEvent } from '../../utils/reportEvents';
import { SpiContext } from '../../SpiContext';
import { dataHooks } from '../../consts/dataHooks';

interface TreeNodeTooltipProps {
  icon: React.ReactElement;
  text: string;
  ctaText: string;
  ctaLink: string;
  dataHook: string;
  biOrigin: string;
}

export const TreeNodeTooltip: React.FC<TreeNodeTooltipProps> = ({
  icon,
  text,
  ctaText,
  ctaLink,
  dataHook,
  biOrigin,
}) => {
  const { biLoggerAPI } = useContext(SpiContext);
  const [t] = useTranslation();
  return (
    <Tooltip
      dataHook={dataHook}
      shouldTranslate={false}
      content={
        <div className={s.container}>
          <Text
            shouldTranslate={false}
            size="small"
            skin="secondary"
            enableEllipsis={false}
          >
            {t(text)}
          </Text>
          <TextButton
            shouldTranslate={false}
            size="small"
            onClick={() => {
              const link = t(ctaLink);
              biLoggerAPI.report(
                learnMoreEvent({ origin: biOrigin, related_link: link }),
              );
              window.open(link, '_blank');
            }}
            dataHook={dataHooks.TOOLTIP_CTA}
          >
            {t(ctaText)}
          </TextButton>
        </div>
      }
      interactive={true}
    >
      {icon}
    </Tooltip>
  );
};
