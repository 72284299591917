import React from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { ChevronLeft } from '@wix/wix-ui-icons-common/classic-editor';
import { TextButton } from '@wix/wix-base-ui';
import { useAPIs } from '../../../../createCssClasses';
import { useBiLogger } from '../../../../biLoggerContext';
import { propertiesPanelActionClick } from '@wix/bi-logger-code/v2';
import dataHooks from '../../../dataHooks';

export const OpenGlobalCss = () => {
  const { internalAPI } = useAPIs();
  const [t] = useTranslation();
  const biLoggerAPI = useBiLogger();

  return (
    <TextButton
      dataHook={dataHooks.openGlobalCss}
      size="small"
      weight="normal"
      onClick={() => {
        internalAPI.selectFile();
        biLoggerAPI.report(
          propertiesPanelActionClick({
            cta: t('WixCode_CssClassesPanel_OpenCssFileCTA_Text'),
            action_type: t('WixCode_CssClassesPanel_OpenCssFileCTA_Text'),
          }),
        );
      }}
      prefixIcon={<ChevronLeft />}
    >
      {t('WixCode_CssClassesPanel_OpenCssFileCTA_Text')}
    </TextButton>
  );
};
