import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { APIKeys, EntryPoint, Shell } from '@wix/wix-code-repluggable';
import { createSidebarAPI } from './SidebarAPI';
import { createLeftPane } from './sideBarContainer';
import React from 'react';
import { fedops } from '@wix/wix-code-common';

export const SidebarEntryPoint: EntryPoint = {
  name: 'SideBar Entry Point',
  getDependencyAPIs() {
    return [
      APIKeys.WixCodeStoreAPI,
      WixCodeEditorAdapterAPI,
      APIKeys.FilesTreeAPI,
      APIKeys.ReadOnlyAPI,
      APIKeys.PackagesAndAppsAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.EditorContextAPI,
    ];
  },
  declareAPIs() {
    return [APIKeys.SidebarAPI];
  },
  attach(shell: Shell) {
    const wixCodeStoreAPI = shell.getAPI(APIKeys.WixCodeStoreAPI);
    fedops.getLogger().interactionStarted(fedops.interactions.renderSidebar);
    shell.contributeAPI(APIKeys.SidebarAPI, () =>
      createSidebarAPI({ wixCodeStoreAPI }),
    );
  },
  extend(shell: Shell) {
    const wixCodeEditorAdapter = shell.getAPI(WixCodeEditorAdapterAPI);
    const readOnlyAPI = shell.getAPI(APIKeys.ReadOnlyAPI);
    const packagesAndAppsAPI = shell.getAPI(APIKeys.PackagesAndAppsAPI);
    const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
    const editorContextAPI = shell.getAPI(APIKeys.EditorContextAPI);
    const LeftPane = createLeftPane(
      wixCodeEditorAdapter.legacyDependencies,
      readOnlyAPI,
      shell,
      packagesAndAppsAPI,
      biLoggerAPI,
      editorContextAPI,
    );
    wixCodeEditorAdapter.contributeFileTree(
      shell,
      (props) => <LeftPane {...props} />,
      () => true,
    );
    fedops.getLogger().interactionEnded(fedops.interactions.renderSidebar);
  },
};
