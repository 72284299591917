import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { TreeListItem, EditableListItem } from '@wix/wix-base-ui';
import { PagesTabContext } from './PagesTabContext';
import {
  utilsCreator,
  monitoring,
  resolveRouterRef,
} from '@wix/wix-code-common';
import {
  leftTreeClickOnMenuSettingIconItem,
  leftTreeClickInMenuSettingOptionItem,
} from '@wix/bi-logger-platform/v2';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import dataHooks from '../dataHooks';
import SettingsIcon from '../assets/icons/contextMenu/settings.svg';
import { PageTreeContext } from './PageTreeContext';
import {
  ContextMenuStructure,
  ReadOnlyModeContext,
  TreeContextMenu,
} from '@wix/wix-code-common-components';
import { DyanmicPageItemTypeWithPage } from './types';

interface DynamicPageProps {
  dataSource: DyanmicPageItemTypeWithPage;
  depth: number;
}

const { withErrorHandling } = monitoring;
export const DynamicPage = ({ dataSource, depth }: DynamicPageProps) => {
  const {
    selectedPageId,
    selectedIdeTabId,
    onPageSelected,
    setPendingRename,
    pageIdPendingRename,
  } = useContext(PageTreeContext);
  const { readOnlyMode } = useContext(ReadOnlyModeContext);
  const { editorAPI, legacyDependenciesAPI, bi } = useContext(PagesTabContext);
  const [isContextMenuOpen, setContextMenuOpen] = useState(false);
  const [dynamicPageContextMenuItems, setDynamicPageContextMenuItems] =
    useState([] as AnyFixMe[]);
  const [contextMenuSections, setContextMenuSections] =
    useState<ContextMenuStructure>({
      sections: [],
    });
  const stateRef = useRef<any[]>();
  stateRef.current = dynamicPageContextMenuItems;
  const { experiment, platform, util, constants } = legacyDependenciesAPI;
  const utils = utilsCreator({ experiment, platform, util });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => updateContextMenuItems(), []);

  const updateContextMenuItems = () => {
    const contextMenuItems = shouldShowDynamicPageActions()
      ? editorAPI.pages.dynamicPages.getDynamicPageActions(
          dataSource.pageData.id,
          'pageActions',
        )
      : [];

    setDynamicPageContextMenuItems(contextMenuItems);
    setContextMenuSections({
      sections: [
        [
          {
            onClick: () => actionSelectedHandler(ACTIONS.OPEN_SETTINGS),
            icon: SettingsIcon,
            label: util.translate(
              'WixCode_Platform_Page_Actions_Page_Settings',
            ),
            automationId: 'router-context-menu-open-settings',
          },
          ...contextMenuItems.map((item: AnyFixMe, index: number) => ({
            onClick: () => actionSelectedHandler(item.id),
            icon: item.icon || 'file',
            label: item.title,
            automationId: `router-context-item-${item.title}-${index}`,
          })),
        ],
      ],
    });
  };

  const ACTIONS = {
    RENAME_PAGE: 'Pages_Actions_Page_Rename',
    OPEN_SETTINGS: 'Open_Settings_Panel',
  };

  const renamePage = (newValue: AnyFixMe) => {
    editorAPI.pages.data.update(dataSource.pageData.id, {
      title: newValue,
      type: 'Page',
    });
    editorAPI.history.add('Rename Page', {
      pageId: dataSource.pageData.id,
      newValue,
    });
    setPendingRename(dataSource.pageData.id, false);
  };

  const cancelRename = () => {
    setPendingRename(dataSource.pageData.id, false);
  };

  const onNodeClick = () =>
    withErrorHandling(() => {
      onPageSelected(dataSource.pageData.id, dataSource.type);
    })();

  const isSelected = () =>
    selectedIdeTabId === utils.getFileIdFromPageId(dataSource.pageData.id);

  const onContextMenuClick = (event: AnyFixMe) =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickOnMenuSettingIconItem({
          item_name: _.get(dataSource, 'pageData.id'),
          item_type: 'dynamic_page',
        }),
      );
      event.stopPropagation();
    })();

  const shouldShowContextMenu = () => !isMobileViewMode();

  const handleContextMenuToggle = setContextMenuOpen;

  const shouldShowDynamicPageActions = () => !isMobileViewMode();

  const isMobileViewMode = () => {
    const viewMode = editorAPI.dsRead.viewMode;
    return viewMode.get() === viewMode.VIEW_MODES.MOBILE;
  };

  const actionSelectedHandler = (action: AnyFixMe) =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickInMenuSettingOptionItem({
          item_name: _.get(dataSource, 'pageData.id'),
          item_type: 'dynamic_page',
          menu_entry_name: action,
        }),
      );
      const selectedOption = _.find(stateRef.current, {
        id: action,
      });

      if (_.get(selectedOption, 'callback')) {
        return selectedOption.callback();
      }
      switch (action) {
        case ACTIONS.OPEN_SETTINGS: {
          return editorAPI.navigateAndOpenPagesPanel(dataSource.pageData.id, {
            initialSettingsTabType: constants.PAGE_SETTINGS_TABS.PAGE_INFO,
          });
        }
        case ACTIONS.RENAME_PAGE: {
          setPendingRename(dataSource.pageData.id, true);
          return;
        }
        default: {
          const { routerRef, pageRef } = resolveRouterRef(
            editorAPI,
            dataSource,
          );
          const routerData = editorAPI.dsRead.routers.get.byRef(routerRef);
          const payload = {
            routerRef,
            publicUrl: editorAPI.dsRead.generalInfo.getPublicUrl(),
            origin: 'idePagesTree',
          };
          if (pageRef) {
            (payload as any).pageRef = pageRef;
          }
          const applicationId = _.get(
            editorAPI.dsRead.platform.getAppDataByAppDefId(
              routerData.appDefinitionId,
            ),
            'applicationId',
          );
          editorAPI.dsActions.platform.notifyApplication(applicationId, {
            eventType: selectedOption && selectedOption.event,
            eventPayload: payload,
          });
        }
      }
    })();

  const contextMenu = (
    <div
      className="stop-propagation-bg"
      key="contextMenuContainer"
      onClick={onContextMenuClick}
    >
      <TreeContextMenu
        contextMenuStructure={contextMenuSections}
        contextMenuButton="corvid_tree__context_menu_more"
        handleContextMenuToggle={handleContextMenuToggle}
        isContextMenuOpen={isContextMenuOpen}
        menuClassName="context-menu-icon"
        className="wix-code-pages-tree-dd"
        tooltipContent={util.translate(
          'Velo_Concurrent_Editing_Sidebar_ReadOnly_Text',
        )}
        readonly={!!readOnlyMode.sidePanel?.pages}
      />
    </div>
  );

  const pageIsBeingRenamed = pageIdPendingRename === dataSource.pageData.id;

  return (
    <div onMouseEnter={() => updateContextMenuItems()}>
      {pageIsBeingRenamed ? (
        <EditableListItem
          dataHook={dataHooks.TREE_NODE_RENAME}
          depth={depth}
          focus={true}
          getInvalidMessage={util.validate.pageNameValidator}
          initialValue={dataSource.label}
          onConfirm={renamePage}
          onCancel={cancelRename}
          size="small"
          validator={(name: AnyFixMe) =>
            util.validate.pageNameValidator(name) === ''
          }
        />
      ) : (
        <TreeListItem
          dataHook={dataHooks.TREE_NODE}
          label={dataSource.label}
          shouldTranslate={false}
          alwaysShowSuffix={isContextMenuOpen}
          highlight={isContextMenuOpen}
          depth={1}
          icon={
            <Symbol
              className={classNames('corvid-tree-prefix-icon', {
                highlight: selectedPageId === dataSource.pageData.id,
              })}
              key="dynamic_icon_page"
              name="page_dynamic_icon"
            />
          }
          size="small"
          suffix={shouldShowContextMenu() && contextMenu}
          onClick={onNodeClick}
          selected={isSelected()}
        />
      )}
    </div>
  );
};
