import { BadgeIndicator } from '@wix/wix-base-ui';
import style from './GithubTabIcon.scss';
import {
  ExperimentsAPI,
  UserPreferencesAPI,
} from '@wix/wix-code-plugin-contracts';
import React, { useEffect, useState } from 'react';
import GithubIcon from 'wix-ui-icons-common/classic-editor/Github';
import dataHooks from '../dataHooks';

const GithubBadgeDismissedKey = 'wix-code-github-badge-dismissed';
const ExposedToGithubDateKey = 'wix-code-github-exposed-date';

const msInDay = 1000 * 60 * 60 * 24;

function GithubTabIconWithBadge({
  userPreferencesAPI,
}: Pick<GithubTabIconProps, 'userPreferencesAPI'>) {
  const [shouldShowBadge, setShouldShowBadge] = useState(false);

  useEffect(() => {
    const isDismissed = userPreferencesAPI.getGlobalPreference(
      GithubBadgeDismissedKey,
    );
    if (isDismissed) {
      return;
    }

    const originallyExposedToGithubTime: number | undefined =
      userPreferencesAPI.getGlobalPreference(ExposedToGithubDateKey);
    if (!originallyExposedToGithubTime) {
      userPreferencesAPI.setGlobalPreference(
        ExposedToGithubDateKey,
        Date.now(),
      );
      setShouldShowBadge(true);
      return;
    }

    const over30DaysElapsed =
      Date.now() - originallyExposedToGithubTime > msInDay * 30;

    if (!over30DaysElapsed) {
      setShouldShowBadge(true);
      return;
    }
  }, [userPreferencesAPI]);

  return (
    <>
      <GithubIcon
        data-hook={dataHooks.githubTabIcon}
        onClick={() => {
          if (!shouldShowBadge) {
            return;
          }
          userPreferencesAPI.setGlobalPreference(GithubBadgeDismissedKey, true);
          setShouldShowBadge(false);
        }}
      />
      {shouldShowBadge && (
        <BadgeIndicator
          dataHook={dataHooks.githubTabBadge}
          className={style.githubTabIconBadge}
        />
      )}
    </>
  );
}

export default function GithubTabIcon({
  experimentsAPI,
  userPreferencesAPI,
}: GithubTabIconProps) {
  const isGithubExperimentOpen = experimentsAPI.isOpen(
    'specs.wixCode.githubIntegration',
  );
  return !isGithubExperimentOpen ? (
    <GithubIcon />
  ) : (
    <GithubTabIconWithBadge userPreferencesAPI={userPreferencesAPI} />
  );
}

interface GithubTabIconProps {
  userPreferencesAPI: UserPreferencesAPI;
  experimentsAPI: ExperimentsAPI;
}
