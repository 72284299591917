export type Interceptor = () => Promise<void>;

export enum CollectionsEvent {
  CREATE_COLLECTION = 'create_collection',
}

export interface CollectionsAPI {
  addInterceptor: (
    eventName: CollectionsEvent,
    interceptor: Interceptor,
  ) => void;
}
