import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';

import { createPanelsAPI } from './PanelsAPI';

export const PanelsEntryPoint: EntryPoint = {
  name: 'Panels Entry Point',
  getDependencyAPIs() {
    return [APIKeys.ClassicEditorAPI, WixCodeEditorAdapterAPI];
  },
  declareAPIs() {
    return [APIKeys.PanelsAPI];
  },
  attach(shell: Shell) {
    const classicEditorAPI = shell.getAPI(APIKeys.ClassicEditorAPI);
    const wixCodeEditorAdapterAPI = shell.getAPI(WixCodeEditorAdapterAPI);

    shell.contributeAPI(APIKeys.PanelsAPI, () =>
      createPanelsAPI({
        classicEditorAPI,
        legacyDependencies: wixCodeEditorAdapterAPI.legacyDependencies,
      }),
    );
  },
};
