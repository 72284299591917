import { useEffect, useState } from 'react';
import {
  NAMESPACE_ERROR,
  NamespaceData,
  NamespaceWithStatus,
  NamespacesWithStatus,
} from '../CollectionsTree/CollectionsTree';
import { experimentUtils } from '@wix/wix-code-common';
import { Schema } from '../../internal-types';
import { WixCodeAppAPI } from '@wix/wix-code-plugin-contracts';
import { getConnectorsService, getCloudDbs } from '../../WixDataServerAPI';
import { ResourceStatus, Resource } from '../types';
import { State } from '@wix/ambassador-kore-provision-v1-database-deployment/types';
import { sortByNamespace } from '../../utils';

const initialResource = {
  data: undefined,
  status: ResourceStatus.LOADING,
  prev: undefined,
};

export const useAllNamespaces = (
  allSchemas: Schema[],
  wixCodeAppAPI: WixCodeAppAPI,
) => {
  const [allNamespaces, setAllNamespaces] =
    useState<Resource<NamespaceWithStatus>>(initialResource);

  useEffect(() => {
    let isMounted = true;
    const getSignedInstance = wixCodeAppAPI.getSignedInstance;
    getConnectorsService(getSignedInstance)
      .getAllConnectors()
      .then((res: NamespaceData[]) => {
        const allNamespacesResult: NamespacesWithStatus = [];
        res.forEach((ns: NamespaceData) => {
          if (!ns.connectionStatus.successful) {
            allNamespacesResult.push({
              namespace: ns.namespace,
              isError: true,
            });
          } else if (validSchemaForNamespaceExists(allSchemas, ns)) {
            allNamespacesResult.push({
              namespace: ns.namespace,
            });
          } else {
            allNamespacesResult.push({
              namespace: ns.namespace,
              isEmpty: true,
            });
          }
        });
        if (experimentUtils.oneClickDb()) {
          getCloudDbs(getSignedInstance)
            .then((cloudDbs) => {
              cloudDbs.forEach((db) => {
                const existingNamespace = allNamespacesResult.find(
                  (namespace) => namespace.namespace === db.namespace,
                );
                if (
                  existingNamespace &&
                  db.state === State.CREATION_IN_PROGRESS
                ) {
                  existingNamespace.creationTime = db.createdDate;
                  existingNamespace.isPending = true;
                  existingNamespace.isError = false;
                }
                if (existingNamespace && db.state === State.CONNECTED) {
                  existingNamespace.isCloudDb = true;
                  existingNamespace.isEmpty = false;
                  existingNamespace.id = db.id!;
                }
              });
              const data = allNamespacesResult.sort(sortByNamespace);
              if (isMounted) {
                setAllNamespaces((prev) => ({
                  data,
                  status: ResourceStatus.SUCCESS,
                  prev: prev.prev,
                }));
              }
            })
            .catch(() => {
              const data = allNamespacesResult.sort(sortByNamespace);
              if (isMounted) {
                setAllNamespaces((prev) => ({
                  data,
                  status: ResourceStatus.SUCCESS,
                  prev: prev.prev,
                }));
              }
            });
        } else {
          const data = allNamespacesResult.sort(sortByNamespace);
          if (isMounted) {
            setAllNamespaces((prev) => ({
              data,
              status: ResourceStatus.SUCCESS,
              prev: prev.prev,
            }));
          }
        }
      })
      .catch(() => {
        if (isMounted) {
          setAllNamespaces((prev) => ({
            data: undefined,
            status: ResourceStatus.FAILURE,
            prev: prev.prev,
          }));
        }
      });

    return () => {
      isMounted = false;
    };
  }, [allSchemas, wixCodeAppAPI.getSignedInstance]);

  return allNamespaces;
};

const validSchemaForNamespaceExists = (
  allSchemas: Schema[],
  ns: NamespaceData,
) => {
  const schema = allSchemas.find(
    (schemaElem: Schema) =>
      schemaElem.namespace === ns.namespace &&
      !schemaElem.id.includes(NAMESPACE_ERROR),
  );

  return !!schema;
};
