import React from 'react';
import { TextLabel, Button, RichText } from '@wix/wix-base-ui';
import s from './tbfPage.scss';
import dataHooks from '../../dataHooks';
import { isTbfSupportHttpFunctionsActive } from '@/infra/experiments/legacyExperiments';
import { experimentUtils } from '@wix/wix-code-common';
import consoleConstants from '@/legacy/core/components/console/constants';
import {
  isModelContentDivergingFromConstructedJsonParams,
  extractFileTypeFromFileName,
  getTypeFromErrorObject,
  isHttpFunction,
} from '../../utils';
import bi from '../../bi';
import consts from '../../consts';
import { TbfJsonEditorParserError } from '../../errors/tbfJsonEditorParserError';
import { extractHttpFunctionsRequestOptions } from '../../extractHttpFunctionsRequestOptions';
import {
  runBackendFunction,
  runHttpFunction,
  RunFunctionResult,
} from './runBackendFunction';
import cx from 'classnames';
import { BackToFunctionLink } from './BackToFunctionLink';
import { useEditorLegacyAPIs } from '@wix/wix-code-common-components';
import { useTranslate } from '@/infra/i18n/useTranslate';
import { Trans } from '@/infra/i18n/Trans';

interface TbfPageLeftPaneProps {
  resetContentChangedBiReports: () => void;
  navigateToFunctionOrigin: () => void;
  functionSignature: string;
  data: AnyFixMe;
  sendBi: AnyFixMe;
  modelId: AnyFixMe;
  tbfModels: AnyFixMe;
  sendTbfConsoleMessage: AnyFixMe;
}

export const TbfPageLeftPane: React.FC<TbfPageLeftPaneProps> = ({
  navigateToFunctionOrigin,
  functionSignature,
  data,
  resetContentChangedBiReports,
  sendBi,
  modelId,
  tbfModels,
  sendTbfConsoleMessage,
}) => {
  const {
    editorAPI,
    legacyDependenciesAPI: { experiment },
  } = useEditorLegacyAPIs();
  const { t } = useTranslate();

  const getMessageFromErrorObject = (error: AnyFixMe) => {
    return error.message || error.name;
  };

  const paramToString = (param: AnyFixMe) => {
    if (Array.isArray(param)) {
      return `Array(${param.length})`;
    } else if (param === null) {
      return 'null';
    } else if (param === undefined) {
      return 'undefined';
    } else if (typeof param === 'object') {
      return `{...}`;
    } else {
      return `${param}`;
    }
  };

  const paramsToString = (params = []) =>
    `(${params.map(paramToString).join()})`;

  const getStartFunctionMessage = ({
    fileId,
    functionName,
    _arguments,
  }: AnyFixMe) => {
    const hasArguments = _arguments.length > 0;
    return `[${fileId}, ${functionName}] called${
      hasArguments ? ` with ${paramsToString(_arguments)}` : ''
    }`;
  };

  const parseArgumentValuesFromTbfEditor = (runAsHttpFunction: AnyFixMe) => {
    let parsedJson: AnyFixMe;
    try {
      parsedJson = JSON.parse(tbfModels.get({ modelId }).getValue());
    } catch (error) {
      sendTbfConsoleMessage({
        args: [consts.ERROR_PARSING_JSON],
        logLevel: consoleConstants.LOG_LEVEL.ERROR,
      });
      throw new TbfJsonEditorParserError((error as Error).message);
    }

    if (Array.isArray(parsedJson)) {
      return parsedJson;
    } else {
      if (runAsHttpFunction) {
        return [parsedJson?.request];
      }

      return data.paramNames.map((name: AnyFixMe) => parsedJson[name]);
    }
  };

  const extractOptionsAndRunHttpFunction = (
    functionName: string,
    params: unknown[],
    useNewHttpFunctionsDevService: boolean,
  ): Promise<RunFunctionResult> => {
    const { gridAppId } = editorAPI.wixCode.fileSystem.getViewerInfo();
    const { instance } = editorAPI.wixCode.getClientSpec();

    const { options: requestOptions, warnings } =
      extractHttpFunctionsRequestOptions({
        functionName,
        origin: window.location.origin,
        gridAppId,
        instance,
        param: params && params[0],
        useNewHttpFunctionsDevService,
      });

    warnings.forEach((warning) => {
      sendTbfConsoleMessage({
        args: [warning],
        logLevel: consoleConstants.LOG_LEVEL.WARNING,
      });
    });

    return runHttpFunction(requestOptions);
  };

  const runFunction = async () => {
    let _arguments;
    const { fileId, functionName, paramNames } = data;
    const isHttpFunctionExperimentOn =
      isTbfSupportHttpFunctionsActive(experiment);

    resetContentChangedBiReports();
    const origin = 'run_button';
    const event = bi.eventBuilders.triggerBeFunction({
      fileName: fileId,
      functionName,
      origin,
      parametersCount: paramNames.length,
    });
    sendBi(bi.events.TBF_TRIGGER_BE_FUNCTION, event);

    const isDefaultParams = !isModelContentDivergingFromConstructedJsonParams(
      editorAPI,
      {
        isHttpFunctionExperimentOn,
        fileId,
        functionName,
        modelId,
        paramNames: data.paramNames,
      },
    );

    const runAsHttpFunction =
      isHttpFunctionExperimentOn && isHttpFunction(fileId, functionName);
    try {
      _arguments = parseArgumentValuesFromTbfEditor(runAsHttpFunction);
      sendTbfConsoleMessage({
        args: [getStartFunctionMessage({ fileId, functionName, _arguments })],
        logLevel: consoleConstants.LOG_LEVEL.LOG,
      });
    } catch (error) {
      sendBi(
        bi.events.TBF_BE_FUNCTION_FINISHED,
        bi.eventBuilders.beFunctionExecutionError({
          fileName: fileId,
          functionName,
          parametersCount: paramNames.length,
          exceptionType: getTypeFromErrorObject(error),
          fileType: extractFileTypeFromFileName(fileId),
          isDefaultParams,
        }),
      );
      return;
    }
    await editorAPI.wixCode.fileSystem.flush();
    const useNewHttpFunctionsDevService =
      experimentUtils.shouldUseNewHttpFunctionsDevService();
    const { isError, payload, runtime } = runAsHttpFunction
      ? await extractOptionsAndRunHttpFunction(
          functionName,
          _arguments,
          useNewHttpFunctionsDevService,
        )
      : await runBackendFunction(editorAPI, {
          fileId,
          functionName,
          params: _arguments,
        });
    if (isError) {
      sendBi(
        bi.events.TBF_BE_FUNCTION_FINISHED,
        bi.eventBuilders.beFunctionExecutionError({
          fileName: fileId,
          functionName,
          parametersCount: paramNames.length,
          exceptionType: getTypeFromErrorObject(payload),
          fileType: extractFileTypeFromFileName(fileId),
          isDefaultParams,
          runTime: runtime,
        }),
      );
      sendTbfConsoleMessage({
        args: [getMessageFromErrorObject(payload)],
        logLevel: consoleConstants.LOG_LEVEL.ERROR,
        stack: payload.stack,
      });
    } else {
      sendBi(
        bi.events.TBF_BE_FUNCTION_FINISHED,
        bi.eventBuilders.beFunctionExecutionSuccess({
          fileName: fileId,
          functionName,
          parametersCount: paramNames.length,
          runTime: runtime,
          fileType: extractFileTypeFromFileName(fileId),
          isDefaultParams,
        }),
      );
      sendTbfConsoleMessage({
        args: [`[${data.fileId}, ${data.functionName}] returned with`, payload],
        logLevel: consoleConstants.LOG_LEVEL.LOG,
      });
    }
  };

  return (
    <div className={s.pageLeftPane}>
      <div className={s.pageHeader}>
        <TextLabel value={t('Trigger_Function_LeftPanel_Title')} type="T06" />
      </div>
      <div>
        <Button
          className={cx('btn-md', s.btnRunFunction)}
          onClick={runFunction}
          dataHook={dataHooks.TBF_RUN_BUTTON}
        >
          <span>{t('Trigger_Function_LeftPanel_Button')}</span>
        </Button>
      </div>
      <div className={s.pageDescription}>
        <RichText
          type="T12"
          className={`${s.pageDescriptionText} tbf-pane-description`}
        >
          {t('Trigger_Function_LeftPanel_Text')}
        </RichText>
      </div>
      <div className={s.functionSignatureContainer}>
        <div className={s.pageDescription}>
          <RichText
            type="T12"
            className={`${s.pageDescriptionText} tbf-pane-description`}
          >
            <Trans
              i18nKey="Trigger_Function_LeftPanel_BackTo_Function"
              components={{
                nameOfFeature: (
                  <BackToFunctionLink
                    functionSignature={functionSignature}
                    navigateToFunctionOrigin={navigateToFunctionOrigin}
                  />
                ),
              }}
            />
          </RichText>
        </div>
      </div>
    </div>
  );
};
