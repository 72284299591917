import * as React from 'react';
import s from './ListSectionDescription.scss';
import { RichText, TextButton } from '@wix/wix-base-ui';
import { PackagesModalContext } from '../../../../packagesModalContext';
import dataHooks from '../../../../dataHooks';

export const ListSectionDescription: React.FC<{
  titleKey: string;
  learnMoreUrlKey: string;
}> = ({ titleKey, learnMoreUrlKey }) => {
  const { t } = React.useContext(PackagesModalContext);

  return (
    <div
      className={s.sectionTitle}
      data-hook={dataHooks.PKG_MODAL_TAB_SUBTITLE}
    >
      <RichText type="T02" className={s.sectionSubTitle}>
        <span className={s.margin}> {t(titleKey)} </span>
        <TextButton
          className={s.learnMoreLink}
          onClick={() => {
            window.open(t(learnMoreUrlKey), '_blank');
          }}
          size="small"
        >
          {t('Package_Manager_Packages_LearnMore')}
        </TextButton>
      </RichText>
    </div>
  );
};
