import * as React from 'react';
import { BiSenderFunc } from '@/legacy/bi/events';
import { PackagesModalTabId } from './packagesModal/packagesModalConsts';
import { PackagesService } from './packagesService';
import {
  InstalledPkgs as InstalledPkgsInternal,
  AvailablePkgs as AvailablePkgsInternal,
  NpmPackageInfo as NpmPackageInfoInternal,
} from '@wix/wix-code-plugin-contracts';

export type InstalledPkgs = InstalledPkgsInternal;
export type AvailablePkgs = AvailablePkgsInternal;
export type NpmPackageInfo = NpmPackageInfoInternal;

export type {
  PackageInstallationStatus,
  WixCodePackageJSON,
  WixCodePackageCodeReuse,
  PkgInfo,
  PkgDependency,
  NpmPackageVersion,
  PkgVersion,
  AvailableCodeReusePkg,
  InstalledCodeReusePkg,
  InstalledNpmPkg,
} from '@wix/wix-code-plugin-contracts';

export { PkgAvailableVersionType } from '@wix/wix-code-plugin-contracts';

type PackagesModalContextProps = {
  currentTab: PackagesModalTabId;
  availablePkgs: AvailablePkgsInternal;
  installedPkgs: InstalledPkgsInternal;
  packagesService: PackagesService;
  selectTab: React.Dispatch<PackagesModalTabId>;
  selectPackage: (name: string, tab: PackagesModalTabId) => void;
  selectedPackage: string;
  t: (key: string, options?: any) => string;
  sendBi: BiSenderFunc;
  isNpmPackagesFetchingDone: boolean;
  showSubmitRequest: boolean;
  npmInstalledModulesInfo: any;
  addJustInstalledPkg: React.Dispatch<string>;
  clearJustInstalledPkg: React.Dispatch<void>;
  addJustRequestedPkg: React.Dispatch<string>;
  justInstalledPkgs: string[];
  justRequestedPkgs: string[];
  setSelectedNpmPkgData: (pkg: NpmPackageInfoInternal) => void;
  selectedNpmPkgData: NpmPackageInfoInternal | null;
  showCodeReuse: boolean;
  pkgStatus?: string;
  isBlocksCombinedMode: boolean;
};

export const TEST_VERSION_NAME = 'latest';

export const PackagesModalContext =
  React.createContext<PackagesModalContextProps>({} as any);
