import React from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { CompRef } from '@wix/document-services-types';
import { RichText, TextButton } from '@wix/wix-base-ui';
import { useCssClasses } from '../../../createCssClasses';
import s from './UnsupportedElementState.scss';

type UnSupportedElementProps = {
  compRef: CompRef;
};

export const UnsupportedElement = ({ compRef }: UnSupportedElementProps) => {
  const [t] = useTranslation();
  const { componentType } = useCssClasses(compRef);

  return (
    <div className={s.emptyStateContainer}>
      <RichText className={s.text}>
        {t(
          'WixCode_CssClassesPanel_UnsupportedElement_EmptyState_Description',
          { element_name: componentType },
        )}
        <TextButton
          onClick={() => {
            window.open(
              t('WixCode_CssClassesPanel_UnsupportedElement_EmptyState_Link'),
            );
          }}
          shouldTranslate={false}
        >
          {t(
            'WixCode_CssClassesPanel_UnsupportedElement_EmptyState_Link_Label',
          )}
        </TextButton>
      </RichText>
    </div>
  );
};
