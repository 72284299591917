import React from 'react';
import {
  Heading,
  PromotionalList,
  Button,
  Spacer,
  Tooltip,
  TextButton,
} from '@wix/wix-base-ui';
import ConnectToGithubImage from '../../../assets/ConnectToGithub.svg';
import allDataHooks from '../../dataHooks';
import { useTranslation } from '@wix/wix-i18n-config';
import style from './ConnectToGithub.scss';
import { GithubModalsAPI } from '../../../githubModalsAPI';
import { ReadOnlyAPI } from '@wix/wix-code-plugin-contracts';
import { useBiLogger } from '../../../context/biLoggerContext';
import { githubLeftTreeTabAction } from '../../../utils/biEvents';
import { CONNECT_TO_GITHUB_TAB_ACTIONS } from '../../../utils/biConsts';

export const ConnectToGithub: React.FC<{
  githubModalsAPI: GithubModalsAPI;
  readOnlyAPI: ReadOnlyAPI;
  isClassicEditor: boolean;
}> = ({ githubModalsAPI, readOnlyAPI, isClassicEditor }) => {
  const [t] = useTranslation();
  const bi = useBiLogger();
  const { connectToGithub: dataHooks } = allDataHooks;

  const ConnectToGithubButton = () => {
    const shouldDisableConnectButton =
      readOnlyAPI.getReadOnlyMode().sidePanel?.github;
    const ConnectButton = ({ disabled }: { disabled?: boolean }) => (
      <Button
        className="btn-big"
        disabled={disabled}
        onClick={() => {
          bi.report(
            githubLeftTreeTabAction({
              itemName: CONNECT_TO_GITHUB_TAB_ACTIONS.CONNECT_TO_GITHUB,
            }),
          );
          githubModalsAPI.openConnectModal();
        }}
        dataHook={dataHooks.connectToGithubButton}
      >
        {t('githubIntegration.getting_started.cta')}
      </Button>
    );
    if (!shouldDisableConnectButton) {
      return <ConnectButton />;
    }
    return (
      <Tooltip
        shouldTranslate={false}
        content={t(
          'githubIntegration.getting_started.connect_to_github.readonly_tooltip',
        )}
      >
        <ConnectButton disabled />
      </Tooltip>
    );
  };

  const nonWaitingListTitle = isClassicEditor
    ? t('githubIntegration.getting_started.title.beta')
    : t('githubIntegration.getting_started.title');

  return (
    <div className={style.connectToGithub} data-hook={dataHooks.content}>
      <div className={style.gap} />
      <div className={style.illustration}>
        <ConnectToGithubImage />
      </div>
      <Spacer type="Spacer06" />
      <Heading
        appearance="h4"
        dataHook={dataHooks.heading}
        multiline
        shouldTranslate={false}
      >
        {nonWaitingListTitle}
      </Heading>
      <Spacer type="Spacer06" />
      <PromotionalList
        dataHook={dataHooks.githubProsList}
        shouldTranslate={false}
        items={[
          t('githubIntegration.getting_started.connect_to_github_list_item_1'),
          t('githubIntegration.getting_started.connect_to_github_list_item_2'),
          t('githubIntegration.getting_started.connect_to_github_list_item_3'),
        ]}
      />
      <Spacer type="Spacer06" />
      <ConnectToGithubButton />
      <Spacer type="Spacer02" />
      <TextButton
        shouldTranslate={false}
        className="btn-inverted"
        onClick={() => {
          bi.report(
            githubLeftTreeTabAction({
              itemName: CONNECT_TO_GITHUB_TAB_ACTIONS.VIEW_DOCUMENTATION,
            }),
          );
          window.open(t('githubIntegration.getting_started.view_docs_link'));
        }}
        dataHook={dataHooks.viewDocsButton}
      >
        {t('githubIntegration.getting_started.secondary_cta')}
      </TextButton>
      <div className={style.gap}></div>
    </div>
  );
};

export default ConnectToGithub;
