import React from 'react';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { CodeEditorFooterSlot } from './codeEditorFooter';
import { createCodeIde } from './CodeEditorCreator';
import { createCodeIntelligenceService } from './codeIntelligence';
import { CodeEditorTabRendererSlot } from './components/tabRenderer/TabRenderer';
import { MarkdownEntryPoint } from './markdownEntryPoint';
import { loadFileOnContextChange } from './loadFileOnContextChange';
import { fedops } from '@wix/wix-code-common';
import { TopBarItemRendererSlot } from './components/topBar/TopBarItemRenderer';
import { ExternalIDEBannerRendererSlot } from './components/ExternalIDEBanner/ExternalIDEBannerRenderer';
import { CodeEditorSidebarSlot } from './codeEditorSidebar';
import * as codeEditor from '@wix/wix-code-code-editor';
import type { CustomCodeValidator } from '@wix/wix-code-code-editor';
import { componentsDataService } from '@wix/wix-code-properties-panel';
import { createVeloLiteCodeIde } from './VeloLiteCodeEditorCreator';
import devContextUtilsCreator from '@/utils/devContext';
import { APIKeys, EntryPoint, Shell } from '@wix/wix-code-repluggable';
import { TranslationProvider } from '@wix/wix-code-common-components';

export const CodeEditorEntryPoint: EntryPoint = {
  name: 'Code Editor',
  getDependencyAPIs() {
    return [
      WixCodeEditorAdapterAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.ReadOnlyAPI,
      APIKeys.ClassicEditorAPI,
      APIKeys.FilesViewAPI,
      APIKeys.MenuAPI,
      APIKeys.MenuPanelAPI,
      APIKeys.EditorContextAPI,
      APIKeys.FileSystemAPI,
      APIKeys.ExperimentsAPI,
      APIKeys.PanelsAPI,
    ];
  },
  declareAPIs() {
    return [APIKeys.CodeEditorAPI];
  },
  attach(shell: Shell) {
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
    fedops.getLogger().interactionStarted(fedops.interactions.renderCodeEditor);

    const codeIntelligence = createCodeIntelligenceService({ editorAPI });
    const footerSlot = shell.declareSlot(CodeEditorFooterSlot);
    const sidebarSlot = shell.declareSlot(CodeEditorSidebarSlot);
    const tabRendererSlot = shell.declareSlot(CodeEditorTabRendererSlot);
    const topBarItemRendererSlot = shell.declareSlot(TopBarItemRendererSlot);
    const externalIDEBannerRendererSlot = shell.declareSlot(
      ExternalIDEBannerRendererSlot,
    );

    shell.contributeAPI(APIKeys.CodeEditorAPI, () => ({
      contributeFooter: (fromShell, footerContribution, condition) => {
        footerSlot.contribute(fromShell, footerContribution, condition);
      },
      contributeTabRenderer(fromShell, tabRenderer, condition) {
        tabRendererSlot.contribute(fromShell, tabRenderer, condition);
      },
      contributeTopBarItem: (fromShell, topBarItem, condition) => {
        topBarItemRendererSlot.contribute(fromShell, topBarItem, condition);
      },
      contributeExternalIDEBanner: (
        fromShell,
        externalIDEBanner,
        condition,
      ) => {
        externalIDEBannerRendererSlot.contribute(
          fromShell,
          externalIDEBanner,
          condition,
        );
      },
      contributeSidebar: (fromShell, sidebarContribution, condition) => {
        sidebarSlot.contribute(fromShell, sidebarContribution, condition);
      },
      codeIntelligence: {
        getContentAssistForPage: codeIntelligence.getContentAssistForPage,
      },
      registerCompletionItemProvider: (languageId, provider) => {
        codeEditor.registerCompletionItemProvider(languageId, provider);
      },
      getComponentsSemanticClassNames: () =>
        componentsDataService
          .initialize()
          .then(() => componentsDataService.getComponentsSemanticClassNames()),
      getComponentsSemanticClasses: (sdkType, componentType) =>
        componentsDataService.getComponentsSemanticClasses(
          sdkType,
          componentType,
        ),
      getComponentCssReference: (sdkType) =>
        componentsDataService.getComponentCssReference(sdkType),
      registerCodeValidator: (
        languageId: string,
        validator: CustomCodeValidator,
      ) => {
        codeEditor.registerCodeValidator(languageId, validator);
      },
    }));
  },
  extend(shell: Shell) {
    const wixCodeEditorAdapterAPI = shell.getAPI(WixCodeEditorAdapterAPI);
    const classicEditorAPI = shell.getAPI(APIKeys.ClassicEditorAPI);
    const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
    const readOnlyAPI = shell.getAPI(APIKeys.ReadOnlyAPI);
    const filesViewAPI = shell.getAPI(APIKeys.FilesViewAPI);
    const footerSlot = shell.getSlot(CodeEditorFooterSlot);
    const sidebarSlot = shell.getSlot(CodeEditorSidebarSlot);
    const tabRendererSlot = shell.getSlot(CodeEditorTabRendererSlot);
    const topBarItemRendererSlot = shell.getSlot(TopBarItemRendererSlot);
    const externalIDEBannerRendererSlot = shell.getSlot(
      ExternalIDEBannerRendererSlot,
    );
    const codeEditorAPI = shell.getAPI(APIKeys.CodeEditorAPI);
    const editorContextAPI = shell.getAPI(APIKeys.EditorContextAPI);

    const menuAPI = shell.getAPI(APIKeys.MenuAPI);
    const menuPanelAPI = shell.getAPI(APIKeys.MenuPanelAPI);
    const fileSystemAPI = shell.getAPI(APIKeys.FileSystemAPI);
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
    const experimentsAPI = shell.getAPI(APIKeys.ExperimentsAPI);
    const panelsAPI = shell.getAPI(APIKeys.PanelsAPI);
    const { constants, util } = wixCodeEditorAdapterAPI.legacyDependencies;

    const {
      setMasterPageContext,
      setPageContext,
      isMasterPageContext,
      isCssFileContext,
    } = devContextUtilsCreator({
      constants,
    });

    shell.addShells([MarkdownEntryPoint]);
    if (experimentsAPI.isOpen('dm_ideServerNG')) {
      fileSystemAPI.listenToExternalFileSystemChanges();
    }
    const WixCodeIde = createCodeIde(
      shell,
      readOnlyAPI,
      biLoggerAPI,
      wixCodeEditorAdapterAPI,
      footerSlot,
      sidebarSlot,
      tabRendererSlot,
      filesViewAPI,
      topBarItemRendererSlot,
    );
    const VeloLiteWixCodeIde = createVeloLiteCodeIde(
      WixCodeIde,
      shell,
      isMasterPageContext,
      isCssFileContext,
      externalIDEBannerRendererSlot,
      { editorAPI, codeEditorAPI, editorContextAPI, panelsAPI },
    );
    loadFileOnContextChange({ classicEditorAPI, wixCodeEditorAdapterAPI });
    wixCodeEditorAdapterAPI.contributeCodeEditor(
      shell,
      (props: any) => (
        <WixCodeIde
          {...props}
          codeIntelligence={codeEditorAPI.codeIntelligence}
        />
      ),
      () => true,
    );

    menuPanelAPI.contributeTab({
      id: 'code_editor',
      TabContent: ({ readOnly, closePanel }) => (
        <TranslationProvider
          options={{
            locale: util.editorModel.languageCode,
            asyncMessagesLoader: (locale: string) =>
              import(`../assets/locale/messages_${locale}.json`),
          }}
        >
          <VeloLiteWixCodeIde readOnly={readOnly} closePanel={closePanel} />
        </TranslationProvider>
      ),
    });

    menuAPI.registerMenuItemListener('page_code', () => {
      menuPanelAPI.selectTab('code_editor');
      setPageContext(editorAPI);
    });

    menuAPI.registerMenuItemListener('global_code', () => {
      menuPanelAPI.selectTab('code_editor');
      setMasterPageContext(editorAPI);
    });

    fedops.getLogger().interactionEnded(fedops.interactions.renderCodeEditor);
  },
};
