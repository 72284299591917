// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RB_X56{margin-left:12px}.S1TsMT{display:flex;flex-direction:column}.ebQRYM{font-family:Monaco;font-size:14px;line-height:22px;-webkit-user-select:text;-moz-user-select:text;-ms-user-select:text;user-select:text;margin-top:10px;margin-bottom:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "RB_X56",
	"tooltipContainer": "S1TsMT",
	"terminalCommand": "ebQRYM"
};
export default ___CSS_LOADER_EXPORT___;
