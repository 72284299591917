// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D0htK1{color:#116dff;margin-top:3px;cursor:pointer}.D0htK1:hover{color:#5999ff}.PoIoyr{color:#116dff;margin-top:3px;cursor:auto}.grVlSE{color:#3899eb;cursor:pointer}.roBGzl{color:#3899eb;cursor:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyButton": "D0htK1",
	"copyButtonClicked": "PoIoyr",
	"copyButtonStudio": "grVlSE",
	"copyButtonClickedStudio": "roBGzl"
};
export default ___CSS_LOADER_EXPORT___;
