// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HmJuUs{align-items:center;display:flex;flex-direction:column;margin-right:30px;margin-left:30px;height:100%}.HmJuUs .control-button{flex-shrink:0}.O0y3V6{display:flex;flex-grow:1;align-items:flex-end}.oO1sxf{display:flex;justify-content:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"connect-to-github": "HmJuUs",
	"connectToGithub": "HmJuUs",
	"gap": "O0y3V6",
	"illustration": "oO1sxf"
};
export default ___CSS_LOADER_EXPORT___;
