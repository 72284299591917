import { TopBarEventParams } from './events';

const ideTabClose = ({ origin, tabId, tabCount, tabIndex }: AnyFixMe) => ({
  origin,
  file_name: tabId,
  action: 'close',
  tab_number: tabIndex + 1,
  num_of_opened_tabs: tabCount,
});

const ideTabSelect = ({ origin, tabId, tabCount, tabIndex }: AnyFixMe) => ({
  origin,
  file_name: tabId,
  action: 'navigate',
  tab_number: tabIndex + 1,
  num_of_opened_tabs: tabCount,
});

const ideTabPin = ({ origin, tabId, tabCount, tabIndex }: AnyFixMe) => ({
  origin,
  file_name: tabId,
  action: 'pinned',
  tab_number: tabIndex + 1,
  num_of_opened_tabs: tabCount,
});

const ideTabScroll = ({ origin, tabId, tabCount, tabIndex }: AnyFixMe) => ({
  origin,
  file_name: tabId,
  action: 'scroll',
  tab_number: tabIndex + 1,
  num_of_opened_tabs: tabCount,
});

const leftTreeTabPin = (origin: AnyFixMe, fileId: AnyFixMe) => ({
  origin,
  file_name: fileId,
  action: 'pinned',
});

const leftTreeClickTab = (tab: AnyFixMe) => ({
  origin: 'left_tree',
  tab_name: tab,
});

export enum BiThemeEventName {
  DARK_SELECTED = 'dark_selected',
  LIGHT_SELECTED = 'light_selected',
}
const ideThemeSelect = (theme: BiThemeEventName) => ({
  item_name: theme,
});

const ideMoreDropdownOpen = () => ({
  item_name: 'more_actions',
});

const previewClick = () => ({
  origin: 'ide_panel',
});

const formatButtonClick = () => ({
  item_name: 'format',
});

const topBarEvent = ({ item_name, origin }: TopBarEventParams) => ({
  item_name,
  origin,
});

const panelToggledFromCodePanelTopbar_141 = ({ siteId, willOpen }: any) => ({
  site_id: siteId,
  state: willOpen ? 'open' : 'close',
  origin: 'code_panel_toolbar',
});

export {
  ideTabClose,
  ideTabSelect,
  ideTabPin,
  ideThemeSelect,
  leftTreeTabPin,
  ideTabScroll,
  leftTreeClickTab,
  ideMoreDropdownOpen,
  previewClick,
  formatButtonClick,
  topBarEvent,
  panelToggledFromCodePanelTopbar_141,
};
