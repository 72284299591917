import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import { MessageModal } from '@wix/wix-base-ui';
import _ from 'lodash';
import s from './messagePanel.scss';
import { OuterClick } from '../../outerClick/outerClick';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';

export interface MessagePanelProps {
  dataHook?: string;
  panelName: string;
  className?: string;
  panelClassName?: string;
  title: string;
  footnote?: string | React.ReactNode;
  illustration?: string | React.ReactNode;
  confirmLabel: string;
  onConfirm: () => void;
  cancelLabel?: string;
  onCancel: () => void;
  hideCloseButton?: boolean;
  cancelOnEscPress?: boolean;
  cancelOnClickOutside?: boolean;
  confirmOnEnterPress?: boolean;
  theme?: 'standard' | 'destructive' | 'premium';
  content?: React.ReactNode;
}

const PANEL_NAME = 'wixCode.panels.MessagePanel';

export const openMessagePanel = ({
  editorAPI,
  props,
  leavePanelsOpen,
}: {
  editorAPI: EditorAPI;
  props: MessagePanelProps;
  leavePanelsOpen?: boolean;
}) => editorAPI.panelManager.openPanel(PANEL_NAME, props, leavePanelsOpen);

export const MessagePanel: React.FC<MessagePanelProps> = ({
  onCancel,
  onConfirm,
  theme,
  confirmLabel,
  illustration,
  title,
  cancelLabel,
  children,
  className,
  panelClassName,
  footnote,
  dataHook = 'MessagePanelFrame',
  hideCloseButton = false,
  cancelOnClickOutside = true,
  content,
}: PropsWithChildren<MessagePanelProps>) => {
  const handleCancel = () => onCancel();

  const handleConfirm = () => {
    onConfirm();
    onCancel();
  };

  const onCloseButtonClick = hideCloseButton ? _.noop : handleCancel;

  const onOuterClick = () => cancelOnClickOutside && onCloseButtonClick();

  return (
    <div
      className={cx(
        s.panelFrameContainer,
        s.screenCenteredFrame,
        s.darkFrameOverlay,
        className,
      )}
    >
      <OuterClick onOuterClick={onOuterClick}>
        <MessageModal
          className={panelClassName}
          dataHook={dataHook}
          theme={theme}
          title={title}
          content={content || children}
          illustration={illustration}
          primaryButtonText={confirmLabel}
          primaryButtonOnClick={handleConfirm}
          secondaryButtonText={cancelLabel}
          secondaryButtonOnClick={onCloseButtonClick}
          footnote={footnote}
          onCloseButtonClick={onCloseButtonClick}
        />
      </OuterClick>
    </div>
  );
};
