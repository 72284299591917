import { MessageModal, Text } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import React, { useContext } from 'react';
import RemoveDbImage from '../../assets/icons/changeConnection.svg';
import { BI } from './constants';
import { connectExternalDbPanelAction } from '@wix/bi-logger-platform/v2';
import { ExternalDbsModalContext } from './ExternalDbsModalContext';
import { getConnectorsService } from '../../WixDataServerAPI';
import styles from './ExternalDBPanels.scss';
import { dataHooks } from './dataHooks';

export interface RemoveExternalDbProps {
  connectorName: string;
}

export const RemoveExternalDb: React.FC<RemoveExternalDbProps> = ({
  connectorName,
}) => {
  const [t] = useTranslation();
  const { biLoggerAPI, wixCodeAppAPI, platformAppsAPI, panelsAPI } = useContext(
    ExternalDbsModalContext,
  );
  const connectorsService = getConnectorsService(
    wixCodeAppAPI.getSignedInstance,
  );
  return (
    <MessageModal
      dataHook={dataHooks.externalDbPanels.messageModal.container}
      theme="destructive"
      onCloseButtonClick={() => {
        panelsAPI.closePanel();
      }}
      title={t('WixCode_WarningModal_DisconnectExternalDatabase_Title')}
      content={
        <div>
          <Text enableEllipsis={false}>
            {t('WixCode_WarningModal_DisconnectExternalDatabase_Description')}
          </Text>
          <div className={styles.marginTop}></div>
          <Text>{t('Delete_External_Collection_Warning_Paragraph2')}</Text>
        </div>
      }
      illustration={<RemoveDbImage />}
      primaryButtonText={t(
        'WixCode_WarningModal_DisconnectExternalDatabase_PrimaryCTA',
      )}
      primaryButtonOnClick={async () => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            action_type: BI.actions.click,
            button_name: BI.buttons.disconnect,
            input_name: BI.panels.disconnect,
          }),
        );

        await connectorsService.unregisterConnector(connectorName);
        await platformAppsAPI.refreshSchemasCache();
        panelsAPI.closePanel();
      }}
      secondaryButtonText={t(
        'WixCode_WarningModal_DisconnectExternalDatabase_SecondaryCTA',
      )}
      secondaryButtonOnClick={() => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            action_type: BI.actions.click,
            button_name: BI.buttons.cancel,
            input_name: BI.panels.disconnect,
          }),
        );
        panelsAPI.closePanel();
      }}
    />
  );
};
