/* eslint react/prop-types:2 */
import './developerToolBar.global.scss';
import createReactClass from 'create-react-class';
import * as utils from '@wix/santa-editor-utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import developerToolBarMapperCreator from './developerToolBarMapper';
import errorBoundaryComponent from '../wixCodeErrorBoundaryComponent';
import { withErrorHandling } from '@/infra/monitoring';
import baseUILib from '@wix/wix-base-ui';
import developerToolBarContentCreator from './content/developerToolBarContent';

import once_ from 'lodash/once';

const developerToolBar = once_(
  ({
    baseUI,
    constants,
    core,
    experiment,
    helpIds,
    platform,
    stateManagement,
    util,
  }) => {
    const developerToolBarMapper = developerToolBarMapperCreator({
      experiment,
      stateManagement,
      util,
    });
    const DeveloperToolBarContent = developerToolBarContentCreator({
      baseUI,
      constants,
      core,
      experiment,
      helpIds,
      platform,
      stateManagement,
      util,
    });
    const {
      connect,
      STORES: { EDITOR_API },
    } = util.hoc;

    const INITIAL_RIGHT = 42;
    const INITIAL_TOP = 42;

    const TABS = {
      MAIN: 'main',
      SECONDARY: 'secondary',
    };

    const DeveloperToolBar = createReactClass({
      displayName: 'developerToolBar',
      propTypes: {
        editedComponent: PropTypes.object,
        innerComponent: PropTypes.object,
        hidden: PropTypes.bool,
        isCustomPosition: PropTypes.bool.isRequired,
        isReadOnly: PropTypes.bool,
        isRightPanelOpen: PropTypes.bool,
        onClose: PropTypes.func,
        openHelpCenter: PropTypes.func.isRequired,
        previewTop: PropTypes.number,
        registerDragCallbacks: PropTypes.func.isRequired,
        setWixCodePropertiesPanelMobileFirstTimeShowed:
          PropTypes.func.isRequired,
        startDrag: PropTypes.func.isRequired,
        toolsHidden: PropTypes.bool,
        wixCodePropertiesPanelMobileFirstTimeShowed: PropTypes.func.isRequired,
        stageLayout: PropTypes.object,
        secondaryComponent: PropTypes.object,
        componentNickname: PropTypes.string,
        secondaryComponentNickname: PropTypes.string,
        onContextMenu: PropTypes.func,
      },

      getInitialState() {
        this.props.registerDragCallbacks({
          onDragEnd: this.onDragEnd,
        });
        return {
          readOnlyTooltipOpen: false,
          selectedTab: TABS.MAIN,
        };
      },
      componentDidMount() {
        this.openHelpCenter = withErrorHandling(this.openHelpCenter);
        this.updateLastCustomXPosition = withErrorHandling(
          this.updateLastCustomXPosition,
        );
        this.onCloseTooltip = withErrorHandling(this.onCloseTooltip);
      },
      UNSAFE_componentWillReceiveProps(nextProps) {
        if (!_.isEqual(this.props.editedComponent, nextProps.editedComponent)) {
          this.setState({ selectedTab: TABS.MAIN });
        }
      },

      getInitialPosition() {
        return {
          right:
            INITIAL_RIGHT +
            (this.props.isRightPanelOpen ? constants.RIGHT_AREA.WIDTH : 0),
          top: INITIAL_TOP + this.props.previewTop,
        };
      },

      openHelpCenter() {
        const biParams = {
          origin: constants.BI.HELP.ORIGIN.PANEL,
          panel_name: 'wixCode.components.developerToolBar',
        };
        this.props.openHelpCenter(
          '2ec37118-35d1-4068-a491-7640c1481aa1',
          null,
          biParams,
        );
      },

      onDragEnd(position) {
        this.updateLastCustomXPosition(position.left);
      },

      getClasses() {
        return `developer-toolbar ${this.props.hidden ? 'hidden' : ''}`;
      },

      updateLastCustomXPosition(xValue) {
        this.setState({
          lastCustomXPosition: xValue,
        });
      },

      setToolbarRoot(toolbarRoot) {
        this.setState({
          developerToolBarContainer: toolbarRoot,
          readOnlyTooltipOpen: true,
        });
      },

      shouldShowBubble() {
        return this.props.editedComponent && this.shouldShowReadOnlyTooltip();
      },

      shouldShowReadOnlyTooltip() {
        return (
          this.props.isReadOnly &&
          this.state.readOnlyTooltipOpen &&
          !this.props.wixCodePropertiesPanelMobileFirstTimeShowed()
        );
      },

      onCloseTooltip() {
        this.props.setWixCodePropertiesPanelMobileFirstTimeShowed(true);
        this.setState({ readOnlyTooltipOpen: false });
      },

      getTooltipValue() {
        return React.createElement(
          baseUI.popoverTemplates.titleBodyAndLinkTooltip,
          {
            title: 'WixCode_PropertiesPanel_Mobile_FirstTime_Tooltip_Title',
            text: 'WixCode_PropertiesPanel_Mobile_FirstTime_Tooltip_Text',
            onClose: this.onCloseTooltip,
          },
        );
      },

      shouldShowTabs() {
        return !_.isNil(this.props.secondaryComponentNickname);
      },

      getTabs() {
        return [
          {
            label: `#${this.props.componentNickname}`,
            value: TABS.MAIN,
          },
          {
            label: `#${this.props.secondaryComponentNickname}`,
            value: TABS.SECONDARY,
          },
        ];
      },

      selectTab(selectedTab) {
        this.setState({ selectedTab });
      },

      getEditedComponent() {
        return this.state.selectedTab === TABS.SECONDARY
          ? this.props.secondaryComponent
          : this.props.editedComponent;
      },

      renderWithPositioning(renderCallback) {
        return React.createElement(
          core.utils.toolbarPositioning,
          {
            isCustomPosition: this.props.isCustomPosition,
            lastCustomXPosition: this.state.lastCustomXPosition,
            isHidden: this.props.toolsHidden,
            initialPos: this.getInitialPosition(),
            dragRightLimit: INITIAL_RIGHT,
            className: this.getClasses(),
            stageLayout: this.props.stageLayout,
          },
          renderCallback,
        );
      },

      render() {
        const renderWithDragLimits = (dragLimits) => {
          return (
            <div
              onContextMenu={this.props.onContextMenu}
              data-aid="developer-tool-bar-panel"
              ref={this.setToolbarRoot}
            >
              <baseUILib.PanelHeader
                className="header light"
                onMouseDown={(e) => this.props.startDrag(e, dragLimits)}
                onClose={this.props.onClose}
                onHelp={this.openHelpCenter}
              >
                <span
                  className="layout-title"
                  style={{
                    marginTop: '6px',
                    textAlign: 'left',
                    paddingLeft: '12px',
                  }}
                >
                  {util.translate('WixCode_PropertiesPanel_PanelTitle')}
                </span>
              </baseUILib.PanelHeader>

              <baseUILib.Divider long={true} />
              {this.shouldShowTabs() && (
                <baseUILib.HorizontalTabs
                  key="developerToolBarNicknameTabs"
                  arrowed={true}
                  shouldTranslate={false}
                  value={this.state.selectedTab}
                  onChange={this.selectTab}
                  options={this.getTabs()}
                />
              )}

              <DeveloperToolBarContent
                editedComponent={this.getEditedComponent()}
                innerComponent={this.props.innerComponent}
              />

              {this.shouldShowBubble() && (
                <baseUI.bubble
                  key="readOnlyTooltip"
                  ref="readOnlyTooltip"
                  width="222px"
                  value={this.getTooltipValue()}
                  targetNode={this.state.developerToolBarContainer}
                />
              )}
            </div>
          );
        };

        return this.renderWithPositioning(renderWithDragLimits);
      },
    });

    return _.flowRight(
      utils.hoc.draggable,
      connect(
        EDITOR_API,
        developerToolBarMapper.mapStateToProps,
        developerToolBarMapper.mapDispatchToProps,
        undefined,
        undefined,
        errorBoundaryComponent,
      ),
    )(DeveloperToolBar);
  },
);
export default developerToolBar;
