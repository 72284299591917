import React from 'react';
import { PanelHeader } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import { panelHeaderData } from './createSiteCodeTab';
import { PanelsAPI } from '@wix/wix-code-plugin-contracts';
import { helpIds, dataHooks } from './consts';

type SiteCodePanelHeaderProps = {
  closePanel: () => void;
  panelsAPI: PanelsAPI;
};

export const SiteCodePanelHeader: React.FC<SiteCodePanelHeaderProps> = ({
  closePanel,
  panelsAPI,
}) => {
  const [t] = useTranslation();

  const onOpenHelp = () => {
    panelsAPI.openHelpCenter(helpIds.siteCodeHelpCenter);
  };

  return (
    <PanelHeader
      dataHook={dataHooks.panelHeader}
      onHelp={onOpenHelp}
      onClose={closePanel}
      className="dragger dark"
      defaultCursor
    >
      {t(panelHeaderData.label)}
    </PanelHeader>
  );
};
