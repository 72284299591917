/* eslint-disable no-unreachable */
import React, { useState, useEffect } from 'react';
import { TranslationProvider } from '@wix/wix-code-common-components';
import {
  EditorComponentsAPI,
  LegacyEditorDependencies,
  EditorAPI,
} from '@wix/wix-code-plugin-contracts';
import GenerateCodeResult from '../GenerateCodeResult/GenerateCodeResult';
import GenerateCodeForm from '../GenerateCodeForm/GenerateCodeForm';
import aiAssitanceService from '../../services/aiAssitanceService';
import { consumeCompletionsStream } from '../../services/streamUtils';
import { GenerateCodeError } from '../GenerateCodeError/GenerateCodeError';

export interface AiPanelProps {
  legacyDependenciesAPI: LegacyEditorDependencies;
  editorComponentsAPI: EditorComponentsAPI;
  editorAPI: EditorAPI;
  wixCodeSignedInstance: string;
  closePanel: () => void;
  useStream: boolean;
}

type ViewState =
  | 'generateCodeForm'
  | 'generateCodeResult'
  | 'generateCodeError';

const AiPanel: React.FC<AiPanelProps> = ({
  editorComponentsAPI,
  editorAPI,
  closePanel,
  wixCodeSignedInstance,
  useStream = false,
}) => {
  const [selectedEvent, setSelectedEvent] = useState('');
  const [userInput, setUserInput] = useState('');
  const [viewState, setViewState] = useState<ViewState>('generateCodeForm');
  const [generatedResult, setGeneratedResult] = useState('');
  const [generatedCodeBlock, setGeneratedCodeBlock] = useState('');
  const [isGeneratingResult, setIsGeneratingResult] = useState(true);
  const { events, id: componentId } =
    editorComponentsAPI.getSelectedComponentData()!;
  const sortedEvents = events.sort((a, b) =>
    a.description.localeCompare(b.description),
  );

  const codeInsertClickHandler = async () => {
    const generatedCodeWithComment = `\n/***\n* Code added by AI Assistant\n* Prompt: ${userInput}\n***/\n${generatedCodeBlock}`;
    aiAssitanceService.appendCodeToActivePage(
      editorAPI,
      generatedCodeWithComment,
    );
    closePanel();
  };

  useEffect(() => {
    if (!selectedEvent) {
      setSelectedEvent(sortedEvents[0].name);
    }
  }, [sortedEvents, selectedEvent, setSelectedEvent]);

  const onGenerateCodeClick = async () => {
    const prompt = aiAssitanceService.createPrompt(
      userInput,
      selectedEvent,
      componentId,
    );

    if (useStream) {
      let result = '';
      const stream = await aiAssitanceService.generateStream(
        wixCodeSignedInstance,
        [prompt],
        '1',
      );
      consumeCompletionsStream(
        stream,
        (chunk) => {
          result += chunk;
          setGeneratedResult(result);
        },
        () => setIsGeneratingResult(false),
      );
      setViewState('generateCodeResult');
      return;
    }

    try {
      const response = await aiAssitanceService.generate([prompt], '1'); // todo: conversationId is currently hardcoded to '1' without history. Align it with Paulina
      setGeneratedResult(response.data.message);
      setViewState('generateCodeResult');
    } catch (e) {
      setViewState('generateCodeError');
    }
  };

  if (viewState === 'generateCodeError') {
    return (
      <GenerateCodeError
        closePanel={closePanel}
        onBackClick={() => {
          setViewState('generateCodeForm');
        }}
        primaryText="We encountered a problem while trying to generate code"
        secondaryText="An error occurred while connecting to the server. please try again later"
      />
    );
  }

  if (viewState === 'generateCodeForm') {
    return (
      <GenerateCodeForm
        closePanel={closePanel}
        onGenerateCodeClick={onGenerateCodeClick}
        selectedEvent={selectedEvent}
        events={sortedEvents}
        userInput={userInput}
        setUserInput={setUserInput}
        setSelectedEvent={setSelectedEvent}
      />
    );
  }

  return (
    <GenerateCodeResult
      closePanel={closePanel}
      onBackClick={() => setViewState('generateCodeForm')}
      onCodeInsertClick={codeInsertClickHandler}
      generatedResult={generatedResult}
      isGeneratingResult={useStream && isGeneratingResult}
      setGeneratedCodeBlock={setGeneratedCodeBlock}
    />
  );
};

const AiPanelWithTranslations: React.FC<AiPanelProps> = (props) => {
  const { legacyDependenciesAPI } = props;
  return (
    <TranslationProvider
      options={{
        locale: legacyDependenciesAPI.util.editorModel.languageCode,
        asyncMessagesLoader: (locale: string) =>
          import(`../../assets/locale/messages_${locale}.json`),
      }}
    >
      <AiPanel {...props} />
    </TranslationProvider>
  );
};

export default AiPanelWithTranslations;
