import ExtendableError from 'es6-error';

const ERROR_NAME = 'NpmSearchIndexReaderError';

class NpmSearchIndexReaderError extends ExtendableError {
  constructor(originalError, request) {
    super(originalError.message);
    this.name = ERROR_NAME;
    this.originalError = originalError;
    this.request = request;
  }
}

export { NpmSearchIndexReaderError };
