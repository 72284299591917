import {
  componentsDataServiceCreator,
  ComponentsDataService,
} from '@wix/wix-code-editor-types';

const loadWixCodeTypesComponentsDataService =
  async (): Promise<ComponentsDataService> => {
    const wixCodeComponentsDataService = componentsDataServiceCreator();
    await wixCodeComponentsDataService.init();
    return wixCodeComponentsDataService;
  };

export default loadWixCodeTypesComponentsDataService;
