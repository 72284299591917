import * as React from 'react';
import {
  NpmPackageInfo,
  PackagesModalContext,
} from '../../../../packagesModalContext';
import { PackagesListItemDetailsDisplay } from './PackagesListItemDetailsDisplay';
import { useContext } from 'react';
import { PackageItemContext } from '../PackageItemContext';
import { highlightSearchKeyword } from './CodeReusePackageListItemDetails';
import { MODAL_TABS } from '../../../packagesModalConsts';
import { getInstalledNpmPkgByName } from '@/toExtract/packages/utils';

interface NpmPackageListItemDetailsProps {
  searchKeyword?: string;
}
export const NpmPackageListItemDetails: React.FC<
  NpmPackageListItemDetailsProps
> = ({ searchKeyword }) => {
  const { availablePkg } = useContext(PackageItemContext);
  const pkg = availablePkg as NpmPackageInfo;
  const { description, name, version, downloadCount, license } = pkg;
  const packageDescription = description || '';
  const latestVersion = pkg._version?.version || version;
  const installedVersion = getInstalledNpmPkgByName(availablePkg.name)?.version;
  const displayVersion = installedVersion || latestVersion;
  const { currentTab } = useContext(PackagesModalContext);
  const shouldHighlightText =
    searchKeyword && currentTab === MODAL_TABS.NPM_PACKAGES;

  const packageDescriptionElement = description
    ? packageDescription
    : undefined;
  const getPackageImportName = () => {
    if (shouldHighlightText) {
      return highlightSearchKeyword(name, searchKeyword);
    }
    return <span>{name}</span>;
  };
  const packageName = getPackageImportName();

  return (
    <PackagesListItemDetailsDisplay
      packageName={packageName}
      packageDescription={packageDescriptionElement}
      downloadCount={downloadCount}
      displayVersion={`v ${displayVersion}`}
      packageLicense={license}
    />
  );
};
