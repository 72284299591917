import _ from 'lodash';
import bi from '../../bi/bi';

function withOpenMediaManager(
  editorAPI,
  mediaManagerType,
  mediaBiType,
  { origin } = {},
) {
  const mediaServices = editorAPI.mediaServices;
  const mediaManager = mediaServices.mediaManager;
  const category = mediaManager.categories[mediaManagerType];

  editorAPI.bi.event(bi.events.IDE_OPEN_MEDIA_MANAGER, {
    type: mediaBiType,
    origin,
  });

  const openMediaManagerPromise = new Promise(function (resolve) {
    mediaManager.open(category, {
      callback: (items) => {
        resolve(items);
        mediaServices.setFocusToEditor();
      },
      multiSelect: false,
    });
  });

  return openMediaManagerPromise.then(function (payload) {
    if (!payload || !payload.length) {
      return;
    }

    editorAPI.bi.event(bi.events.IDE_MEDIA_ADDED, { type: mediaBiType });

    return payload[0];
  });
}

export default function mediaManagementServiceCreator(editorAPI) {
  return {
    openImageMediaManager: _.partial(
      withOpenMediaManager,
      editorAPI,
      'IMAGE',
      'IMAGE',
    ),
    openVectorMediaManager: _.partial(
      withOpenMediaManager,
      editorAPI,
      'VECTOR_ART',
      'VECTOR',
    ),
    openVideoMediaManager: _.partial(
      withOpenMediaManager,
      editorAPI,
      'VIDEO',
      'VIDEO',
    ),
    openBackgroundMediaManager: _.partial(
      withOpenMediaManager,
      editorAPI,
      'BG_MEDIA',
      'BACKGROUND',
    ),
    // Deprecated - tern IDE
    openMediaManager: _.partial(
      withOpenMediaManager,
      editorAPI,
      'IMAGE',
      'IMAGE',
    ),
    openVectorArtManager: _.partial(
      withOpenMediaManager,
      editorAPI,
      'VECTOR_ART',
      'VECTOR',
    ),
    openBgMediaManager: _.partial(
      withOpenMediaManager,
      editorAPI,
      'BG_MEDIA',
      'DOCUMENT',
    ),
  };
}
