import React from 'react';
import { SidePaneTabs } from './SidePaneTabs';
import { SidePaneContext } from './SidePaneContext';
import { generateAppTab, getSideTabsDefinitions } from './sideTabDefinitions';
import { topTabsOrder } from './tabs';
import dataHooks from './dataHooks';
import { TranslateBaseUiIfNeeded } from '@/infra/i18n/TranslateBaseUiIfNeeded';
import {
  EditorContextAPI,
  NewChild,
  PackagesAndAppsAPI,
  PredefinedSidebarTabs,
  ReadOnlyMode,
  SidebarTabDefinition,
  SideTabContribution,
} from '@wix/wix-code-plugin-contracts';
import { useTranslate } from '@/infra/i18n/useTranslate';
import { sideBarSelectors } from './state/leftPaneSelectors';
import {
  useEditorLegacyAPIs,
  useSelector,
} from '@wix/wix-code-common-components';

export interface SidePaneProps {
  // @deprecated - use sidebarTabs instead of tabNames
  tabNames?: PredefinedSidebarTabs[];
  sidebarTabs?: SidebarTabDefinition[];
  ideTreePackagesViewDefinition?: any;
  iconOverrides: any;
}

export interface SidePaneMapDispatchToProps {
  showNewFileInput: (
    initialName: string,
    suffix: string,
    isFolder: boolean,
    folderPath: string,
  ) => void;
  setNewFileRenameInput: (newChild: NewChild | null) => void;
}

export type SidePaneStateProps = {
  newChild: NewChild | null;
};

export type ReadOnlyModeProps = {
  readOnlyMode: ReadOnlyMode;
};

export type PackagesAndAppsAPIProps = {
  packagesAndAppsAPI: PackagesAndAppsAPI;
};
export type ContextAPIs = {
  editorContextAPI: EditorContextAPI;
} & ReadOnlyModeProps &
  PackagesAndAppsAPIProps;

export const SidePaneComp: React.FC<
  SidePaneProps & SidePaneMapDispatchToProps & SidePaneStateProps & ContextAPIs
> = ({
  readOnlyMode,
  tabNames = topTabsOrder,
  ideTreePackagesViewDefinition,
  sidebarTabs,
  showNewFileInput,
  setNewFileRenameInput,
  newChild,
  packagesAndAppsAPI,
  editorContextAPI,
  ...rest
}) => {
  const { t } = useTranslate();
  const {
    legacyDependenciesAPI: { experiment },
  } = useEditorLegacyAPIs();
  const isBlocksCombinedMode = experiment.isOpen('se_privateAppsPanel');
  const isResponsiveEditorMainBar = experiment.isOpen(
    'specs.responsive-editor.mainBar',
  );
  const isInStudio = editorContextAPI.getEditorType() === 'Studio';
  const isVeloPhase0 = isInStudio && isResponsiveEditorMainBar;
  const tabsData = getSideTabsDefinitions();
  const additionalTabsNames = useSelector(sideBarSelectors.getTabs);

  const sortTabs = (
    tab1: PredefinedSidebarTabs,
    tab2: PredefinedSidebarTabs,
  ) => {
    const idx1 = topTabsOrder.indexOf(tab1);
    const idx2 = topTabsOrder.indexOf(tab2);
    if (idx1 === -1) {
      return 1;
    }
    if (idx2 === -1) {
      return -1;
    }
    return idx1 - idx2;
  };

  const getTabDetails = () =>
    sidebarTabs!
      .map((tab: any) => (tab.tabId ? tabsData[tab.tabId]?.(tab.props) : tab))
      .filter((tab) => tab);

  const getTabDetailsBasedOnDeprecatedTabNames = () =>
    (tabNames as PredefinedSidebarTabs[])
      .concat(
        additionalTabsNames.filter(
          (tab: string) => !tabNames.includes(tab as PredefinedSidebarTabs),
        ) as PredefinedSidebarTabs[],
      )
      .sort(sortTabs)
      .map((name) => {
        switch (name) {
          case PredefinedSidebarTabs.APP_BUILDER:
            return generateAppTab(
              ideTreePackagesViewDefinition,
              isBlocksCombinedMode,
            );
          default:
            return tabsData[name] && tabsData[name]();
        }
      })
      .filter((tab) => !!tab);

  const tabsDetails: SideTabContribution[] = sidebarTabs
    ? getTabDetails()
    : getTabDetailsBasedOnDeprecatedTabNames();

  return (
    <span
      data-hook={dataHooks.SIDE_PANE_CONTAINER}
      data-is-read-only-fs={readOnlyMode.fileSystem}
    >
      <SidePaneContext.Provider
        value={{
          newChild,
          setNewFileRenameInput,
          showNewFileInput,
          packagesAndAppsAPI,
        }}
      >
        <TranslateBaseUiIfNeeded tFunc={t}>
          <SidePaneTabs
            tabsDetails={tabsDetails}
            isVeloPhase0={isVeloPhase0}
            {...rest}
          />
        </TranslateBaseUiIfNeeded>
      </SidePaneContext.Provider>
    </span>
  );
};
