import React from 'react';
import _ from 'lodash';
import { CustomScroll } from '@wix/wix-base-ui';
import EmptyState from './empty-states/panelEmptyState';
import NoEditState from './empty-states/panelNoEditState';
import WidgetAccordions from './config-sections/widgetElementsAccordion';
import createElementConfigContainer from './config-sections/elementConfigContainer';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import { usePageElementsPanel } from '../../SidebarContexts';
import { useTranslation } from '@wix/wix-i18n-config';

type EventsPanelStateProps = {
  editorState: EditorAPI['store']['getState'];
  dsRead: EditorAPI['dsRead'];
};

export type ConnectEventsPanelWithShellProps = {
  selectedFileId: string;
  openMasterPage: () => void;
};

type EventsPanelProps = EventsPanelStateProps &
  ConnectEventsPanelWithShellProps;

const EventsPanel: React.FC<EventsPanelProps> = ({
  editorState,
  dsRead,
  selectedFileId,
  openMasterPage,
}) => {
  const { legacyDependencies, editorAPI } = usePageElementsPanel();
  const { t } = useTranslation();
  const { stateManagement, util } = legacyDependencies;
  const { isAppWidget } = stateManagement.editorPlugins.components.selectors;
  const { getSingleSelectedComponent } =
    stateManagement.editorPlugins.selection.selectors;
  const selectedComponent = getSingleSelectedComponent(editorState);

  const compRef = (util.controlsUtils as any).getFirstControllableComponent(
    editorAPI,
    selectedComponent,
  );

  const compNickname = compRef
    ? (editorAPI as EditorAPI).components.code.getNickname(compRef)
    : null;

  const hasNickname = !_.isNil(compNickname);
  const widgetRootRef =
    compRef && isAppWidget(compRef, dsRead)
      ? _.head(dsRead.components.getChildren(compRef))
      : null;
  const widgetRootNickname = widgetRootRef
    ? (editorAPI as EditorAPI).components.code.getNickname(widgetRootRef)
    : null;
  const hasEditableWidgetRoot = !!widgetRootRef && !_.isNil(widgetRootNickname);

  const ElementConfigContainer = createElementConfigContainer({
    legacyDependencies,
  });
  const openHelpPanel = (helpArticleId: string) =>
    editorAPI.panelManager.openHelpCenter(helpArticleId);

  return (
    <CustomScroll heightRelativeToParent="100%">
      <div className="page-elements-panel" data-aid="page-elements-panel">
        {!compRef && <EmptyState t={t} openHelpPanel={openHelpPanel} />}

        {compRef && !hasNickname && <NoEditState t={t} />}

        {compRef && hasNickname && !hasEditableWidgetRoot && (
          <ElementConfigContainer
            compRef={compRef}
            selectedFileId={selectedFileId}
            openMasterPage={openMasterPage}
          />
        )}

        {compRef && hasNickname && hasEditableWidgetRoot && (
          <WidgetAccordions
            widgetRef={compRef}
            widgetNickname={compNickname}
            widgetRootRef={widgetRootRef}
            widgetRootNickname={widgetRootNickname}
            renderCompContent={(_compRef: any) => (
              <ElementConfigContainer
                compRef={_compRef}
                selectedFileId={selectedFileId}
                openMasterPage={openMasterPage}
              />
            )}
          />
        )}
      </div>
    </CustomScroll>
  );
};

const mapEditorStateToProps = ({ editorAPI }: { editorAPI: EditorAPI }) => ({
  editorState: editorAPI.store.getState(),
  dsRead: editorAPI.dsRead,
});

let ConnectedComponent: any = null;
const ConnectedEventsPanel = (origProps: any) => {
  const { legacyDependencies, wixCodeStore } = usePageElementsPanel();
  const { connectToStores } = wixCodeStore;
  const { util: utilFromContext } = legacyDependencies;
  if (ConnectedComponent === null) {
    ConnectedComponent = connectToStores({
      util: utilFromContext,
      mapEditorStateToProps,
      comp: EventsPanel,
    });
  }
  return <ConnectedComponent {...origProps} />;
};

export default ConnectedEventsPanel;
