import { DefaultFileTemplate } from './defaultTemplatesInterface';

const publicJsFileContent = `// Add public functionality to your package code

// Exported functions can be used in any of your sites after building and installing the package
//
// To use in site, import the exported function in your package code by following this syntax:
// import {<functionName>} from '@<your <username>/<package-name>'; 


// Example:
// ----------
// The following example includes the package public file and the code for using the package in a site's page code

// In this file add the following:

/*
export function add(param1, param2) {
    return param1 + param2;
}
*/

// After bulding and installting the package in a site add the following to your page code:
/*
// import {<functionName>} from '@<your username>/<package-name>'; 

$w.onReady(function () {    
    let sum = add(6,7);
    console.log(sum);
});
*/
  `;

const webModuleContent = `// Package .jsw file


// Add backend functionality to your package code.
// Exported functions can be used in any of your sites after building and installing the package.

export async function multiply(factor1, factor2) {
  return factor1*factor2;
}

// After building and installing the package in a site add the following to your page code:
/*
import {multiply} from '@<your username>/<package-name>-backend';

$w.onReady(function () {
  multiply(4,5).then(product => {
    console.log(product);
    // Logs: 20
  })
  .catch(error => {
    console.log(error);
  });
});
*/
`;

const oldComputedFunctionsFileContent = `/*************************
backend/http-functions.js
 *************************

 In this file you create APIs to expose the functionality of your package as a service. 
 Sites in which that package is installed in can use  the functionality of your package by writing code that calls your packages's APIs, as defined by the functions you create here.
 Using the provided code (below this comment block) as an example, users of your HTTP functions can call your API using the following patterns: 

 Production endpoints:  

• Premium site:
  https://mysite.com/rest-api/v1/functions/packages/<package-name>/multiply?leftOperand=3&rightOperand=4  

• Free sites:
  https://username.wixsite.com/mysite/rest-api/v1/functions/packages/<package-name>/multiply?leftOperand=3&rightOperand=4
 
 About HTTP functions:
 https://support.wix.com/en/article/velo-exposing-a-site-api-with-http-functions

 API Reference: 
 https://www.wix.com/velo/reference/wix-http-functions
 
**********************/

// The following is an example of an HTTP function, which gets the product of 2 operands. Adapt the code below for your specific use case.

import { ok, badRequest } from 'wix-http-functions';

export function get_multiply(request) {

   const response = {
       "headers": {
           "Content-Type": "application/json"
       }
   };

   try {

       const leftOperand = parseInt(request.query["leftOperand"], 10);
       const rightOperand = parseInt(request.query["rightOperand"], 10);

       response.body = {
           "product": leftOperand * rightOperand
       };
       return ok(response);
       
   } catch (err) {
       response.body = {
           "error": err
       };
       return badRequest(response);
   }
}
`;

const computedFunctionsFileContent = `/********************
 backend/http-functions.js


 In this file you create APIs to expose the functionality of your package as a service.  
 You can use it in sites where this package is installed in by calling your packages's APIs, as defined by the functions you create here. 

 Production endpoints: 
 Premium site: https://mysite.com/mysite/_functions/<package-name>-backend/multiply?leftOperand=3&rightOperand=4  
 Free sites: https://username.wixsite.com/mysite/_functions/<package-name>-backend/multiply?leftOperand=3&rightOperand=4  

 Dev endpoints: 
 Premium site:  https://mysite.com/mysite/_functions-dev/<package-name>-backend/multiply?leftOperand=3&rightOperand=4 
 Free sites:  https://username.wixsite.com/mysite/_functions-dev/<package-name>-backend/multiply?leftOperand=3&rightOperand=4    

 About HTTP functions: 
 KB article: https://support.wix.com/en/article/velo-exposing-a-site-api-with-http-functions  
 API Reference: https://www.wix.com/velo/reference/wix-http-functions 
**********************/

// The following is an example of an HTTP function, which gets the product of 2 operands. Adapt the code below for your specific use case.

import { ok, badRequest } from 'wix-http-functions';

export function get_multiply(request) {
   const response = {
       "headers": {
           "Content-Type": "application/json"
       }
   };
   try {
       const leftOperand = parseInt(request.query["leftOperand"], 10);
       const rightOperand = parseInt(request.query["rightOperand"], 10);
       response.body = {
           "product": leftOperand * rightOperand
       };
       return ok(response);
   } catch (err) {
       response.body = {
           "error": err
       };
       return badRequest(response);
   }
}
`;

const jobsConfigFileContent = [
  '// /backend/jobs.config',
  '{',
  '  "jobs": [ // Define up to 20 different jobs',
  '    // Choose one of the options below to define the job execution interval:',
  '    {',
  '      // Option 1 - define execution interval using a cron expression',
  '      "functionLocation": "/module/filename.js(w)", // Relatively to Backend folder, started by slash',
  '      "functionName": "funcName",',
  '      "description": "describe your job", // Optional',
  '      "executionConfig": {',
  '        "cronExpression": "0 8 * * *" // Set intervals of up to 1 hour ',
  '        // Read about Cron expressions: [https://en.wikipedia.org/wiki/Cron#CRON_expression]',
  '      }',
  '    },',
  '    {',
  '      // Option 2 - define execution interval by setting time, day of week and day of month',
  '      "functionLocation": "/module/filename.js(w)", // Relatively to Backend folder, started by slash',
  '      "functionName": "funcName",',
  '      "description": "describe your job", // Optional',
  '      "executionConfig": {',
  '        "time": "08:00", // "hh:mm" 24h format, UTC timezone (e.g. 13:00) ',
  '        // Optional - uncomment section below for more complex intervals',
  '        //"dayOfWeek": "Monday", // Day of week ',
  '        //"dateInMonth": 1 // Number between 1-31 ',
  '      }',
  '    },',
  '    {',
  '    // Add more jobs. Up to 20 jobs supported.',
  '    }',
  '  ]',
  '}\n',
].join('\n');

const webJsFileContent = `/************
.web.js file
************

Backend '.web.js' files contain functions that run on the server side and can be called from page code.

Learn more at https://dev.wix.com/docs/develop-websites/articles/coding-with-wix-studio/wix-studio-about-web-methods

****/

/**** Call the sample multiply function below by pasting the following into your page code:

import { multiply } from 'backend/new-file.web';

$w.onReady(async function () {
   console.log(await multiply(4,5));
});

****/

import { Permissions, webMethod } from "wix-web-module";

export const multiply = webMethod (
  Permissions.Anyone, 
  function (factor1, factor2) { return factor1 * factor2 }
);
`;

const emptyFileContent = '';

export const codeOnlyBlocksTemplates: Omit<
  DefaultFileTemplate,
  'EXTERNAL_COMPONENT_FILE_CONTENT'
> = {
  PUBLIC_JS_FILE_CONTENT: publicJsFileContent,
  WEB_MODULE_CONTENT: webModuleContent,
  WEB_MODULE_CONTENT_TBF: webModuleContent,
  OLD_COMPUTED_FUNCTIONS_FILE_CONTENT: oldComputedFunctionsFileContent,
  COMPUTED_FUNCTIONS_FILE_CONTENT: computedFunctionsFileContent,
  JOBS_CONFIG_FILE_CONTENT: jobsConfigFileContent,
  DATA_HOOKS_FILE_CONTENT: emptyFileContent,
  EVENTS_FILE_CONTENT: emptyFileContent,
  WEB_JS_FILE_CONTENT: webJsFileContent,
};
