import React, { useState } from 'react';
import { IconButton, TextInput } from '@wix/wix-base-ui';
import { dataHooks } from '../dataHooks';
import { Show, Hide } from '@wix/wix-ui-icons-common/classic-editor';
import s from './InputField.scss';
import { useTranslation } from '@wix/wix-i18n-config';

interface PasswordInputProps {
  isValid: boolean;
  placeholder: string;
  automationId?: { label: string; input: string };
  inputValue: string;
  onChange: (value: string) => void;
  didUserStartTyping: boolean;
  textInputDatahook: string;
  invalidMessage: string;
  isDisabled: boolean;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
  isValid,
  placeholder = '',
  automationId,
  inputValue,
  onChange,
  didUserStartTyping,
  textInputDatahook,
  invalidMessage,
  isDisabled,
}) => {
  const [secretKeyShowing, setSecretKeyShowing] = useState<boolean>(false);
  const [t] = useTranslation();
  return (
    <div className={s.inputContainer}>
      {inputValue && (
        <div className={s.showPassword}>
          <IconButton
            skin="light"
            onClick={() => setSecretKeyShowing(!secretKeyShowing)}
            dataHook={
              secretKeyShowing
                ? dataHooks.externalDbPanels.addAdapter.hideConfiguration
                : dataHooks.externalDbPanels.addAdapter.showConfiguration
            }
          >
            {secretKeyShowing ? <Hide /> : <Show />}
          </IconButton>
        </div>
      )}
      <TextInput
        dataHook={textInputDatahook}
        hideSuccessIndication
        placeholder={t(placeholder)}
        automationId={automationId?.input}
        value={inputValue}
        onChange={onChange}
        isValid={didUserStartTyping ? isValid : true}
        invalidMessage={invalidMessage}
        disabled={isDisabled}
        type={secretKeyShowing ? '' : 'password'}
        shouldTranslate={false}
      />
    </div>
  );
};
