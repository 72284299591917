import React, { useContext, useState, useEffect, useMemo } from 'react';
import { SelectProvider } from '../SelectProvider/SelectProvider';
import { BI, Providers } from '../constants';
import { CustomisedModal } from '../CustomisedModal/CustomisedModal';
import { useTranslation } from '@wix/wix-i18n-config';
import { ExternalDbsModalContext } from '../ExternalDbsModalContext';
import { helpClick, panelHeaderClose } from '@wix/bi-logger-editor/v2';
import { ConnectAdapter } from '../ConnectAdapter/ConnectAdapter';

import { connectExternalDbPanelAction } from '@wix/bi-logger-platform/v2';
import { getConnectorsService } from '../../../WixDataServerAPI';
import { ConnectionLoader } from '../ConnectionLoader/ConnectionLoader';
import { Preloader } from '@wix/wix-base-ui';
import { Schema } from '@wix/wix-code-plugin-contracts';
import styles from '../ExternalDBPanels.scss';
import { ConnectorResponse, ModalStage } from '../../../internal-types';
import { useConnectorDetails } from '../useConnectorDetails';
import { useValidation } from '../useValidation';
import { useUserTypingInfo } from '../useUserTypingInfo';
import { SuccessModal } from '../SuccessModal';
import { SuccessNoCollectionsModal } from '../SuccessNoCollectionsModal';
import { ErrorModal } from '../ErrorModal';
import { externalCollectionsAddedSuccessfully } from '@wix/bi-logger-platform-cm/v2';

enum AddExternalDbStage {
  SelectDbProvider,
  AddProvider,
  Loader,
  Success,
  SuccessEmptyState,
  Error,
}

export const AddExternalDb: React.FC = () => {
  const [stage, setStage] = useState<AddExternalDbStage>(
    AddExternalDbStage.SelectDbProvider,
  );
  const [selectedProvider, setSelectedProvider] = useState(
    Providers.GOOGLE_OPTION_VALUE,
  );

  const {
    namespace,
    setNamespace,
    endpoint,
    setEndpoint,
    configuration,
    setConfiguration,
  } = useConnectorDetails();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const { fieldsValidations, setFieldsValidations } = useValidation();

  const { userStartTypingIndicaiton, setUserStartTypingIndicaiton } =
    useUserTypingInfo();

  const [namespaces, setNamespaces] = useState<string[]>(null as any);

  const { biLoggerAPI, panelsAPI, wixCodeAppAPI, platformAppsAPI } = useContext(
    ExternalDbsModalContext,
  );
  const [t] = useTranslation();

  const handleOnProviderChange = (provider: Providers) => {
    setSelectedProvider(provider);
  };
  const connectorsService = useMemo(
    () => getConnectorsService(wixCodeAppAPI.getSignedInstance),
    [wixCodeAppAPI],
  );

  useEffect(() => {
    connectorsService
      .getAllConnectors()
      .then((res: ConnectorResponse[]) => {
        setNamespaces(
          res.map((namespaceObj: ConnectorResponse) => namespaceObj.namespace),
        );
      })
      .catch(() => {
        setNamespaces([]);
      });
  }, [connectorsService]);

  const AddExternalDbStages: { [key: number]: ModalStage } = {
    [AddExternalDbStage.SelectDbProvider]: {
      content: (
        <SelectProvider
          selectedProvider={selectedProvider}
          onProviderChange={handleOnProviderChange}
        />
      ),
      biName: BI.panels.selectProvider,
      cta: () => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: BI.panels.connectAdapter,
            action_type: BI.actions.click,
            button_name: BI.buttons.next,
            selected_provider: t(selectedProvider),
          }),
        );
        setStage(AddExternalDbStage.AddProvider);
      },
      withCtas: true,
      withModal: true,
      onSecondaryButtonClick: () => {
        biLoggerAPI.report(
          panelHeaderClose({
            panel_name: BI.panels.selectProvider,
          }),
        );
      },
    },
    [AddExternalDbStage.AddProvider]: {
      content: namespaces ? (
        <ConnectAdapter
          provider={selectedProvider}
          uniqueNamespaces={namespaces}
          fieldsValidations={fieldsValidations}
          setFieldsValidations={setFieldsValidations}
          setNamespace={setNamespace}
          setEndpoint={setEndpoint}
          setConfiguration={setConfiguration}
          inputFields={{ namespace, endpoint, configuration }}
          userStartTypingIndicaiton={userStartTypingIndicaiton}
          setUserStartTypingIndicaiton={setUserStartTypingIndicaiton}
        />
      ) : (
        <div className={styles.loaderContainer}>
          <Preloader className="large" />
        </div>
      ),
      biName: BI.panels.connectAdapter,
      cta: () => handleOnConnectButtonClick(),
      withCtas: true,
      withModal: true,
      onSecondaryButtonClick: () => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: BI.panels.connectAdapter,
            action_type: BI.actions.click,
            button_name: BI.buttons.back,
            selected_provider: t(selectedProvider),
          }),
        );
      },
    },
    [AddExternalDbStage.Loader]: {
      content: <ConnectionLoader />,
      biName: BI.panels.connectAdapter,
      cta: () => {},
      withModal: true,
    },
    [AddExternalDbStage.SuccessEmptyState]: {
      content: <SuccessNoCollectionsModal />,
    },
    [AddExternalDbStage.Success]: {
      content: (
        <SuccessModal
          title={t('WixCode_SuccessModal_NewExternalDatabaseConnection_Title')}
          description={t(
            'WixCode_SuccessModal_NewExternalDatabaseConnection_Description',
          )}
        />
      ),
    },
    [AddExternalDbStage.Error]: {
      content: (
        <ErrorModal
          errorMessage={errorMessage}
          onCtaClick={() => {
            setStage(AddExternalDbStage.AddProvider);
          }}
        />
      ),
    },
  };

  const handleOnConnectButtonClick = async () => {
    setUserStartTypingIndicaiton({
      didUserStartTypingNamespace: true,
      didUserStartTypingEndpoint: true,
      didUserStartTypingSecretkey: true,
    });
    const areFieldsValid =
      fieldsValidations.isConfigurationValid &&
      fieldsValidations.isEndpointValid &&
      fieldsValidations.isNamespaceValid;
    biLoggerAPI.report(
      connectExternalDbPanelAction({
        input_name: BI.panels.connectAdapter,
        action_type: BI.actions.click,
        button_name: BI.buttons.connect,
        isSuccess: areFieldsValid,
        selected_provider: t(selectedProvider),
      }),
    );
    if (!areFieldsValid) {
      return;
    }
    setStage(AddExternalDbStage.Loader);
    try {
      await connectorsService.registerConnector({
        namespace,
        endpoint,
        configuration: { secretKey: configuration },
      });

      platformAppsAPI.refreshSchemasCache();
      const schemas = await platformAppsAPI.listSchemas();
      const schemasUnderDB = schemas.filter(
        (schema: Schema) => schema.namespace === namespace,
      );
      biLoggerAPI.report(
        externalCollectionsAddedSuccessfully({
          namespace,
          number_of_collections_exposed: schemasUnderDB.length,
        }),
      );
      schemasUnderDB.length > 0
        ? setStage(AddExternalDbStage.Success)
        : setStage(AddExternalDbStage.SuccessEmptyState);
    } catch (error: any) {
      setErrorMessage(error.message);
      setStage(AddExternalDbStage.Error);
    }
  };

  return AddExternalDbStages[stage].withModal ? (
    <CustomisedModal
      title={t('External_Databases_Modal_Title')}
      onCtaClick={AddExternalDbStages[stage].cta!}
      primaryButtonText={
        AddExternalDbStages[stage].withCtas
          ? t('External_Databases_Modal_Select_Provider_CTA')
          : undefined
      }
      secondaryButtonText={
        AddExternalDbStages[stage].withCtas
          ? t('External_Databases_Modal_Select_Provider_Secondary_Button')
          : undefined
      }
      onSecondaryButtonClick={
        AddExternalDbStages[stage].onSecondaryButtonClick || (() => {})
      }
      onCloseButtonClick={() => {
        biLoggerAPI.report(
          panelHeaderClose({
            panel_name: AddExternalDbStages[stage].biName,
          }),
        );
        panelsAPI.closePanel();
      }}
      onHelpButtonClick={() => {
        biLoggerAPI.report(
          helpClick({
            panel_name: AddExternalDbStages[stage].biName,
          }),
        );
        window.open(t('External_Databases_Modal_Help_Link'), '_blank');
      }}
    >
      {AddExternalDbStages[stage].content}
    </CustomisedModal>
  ) : (
    AddExternalDbStages[stage].content
  );
};
