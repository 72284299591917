export const TARGET_ES_LIB = 'ES2020';
export const WEB_WORKER_LIB = 'WebWorker';

export enum FileContext {
  Public = 'public',
  Backend = 'backend',
  Page = 'page',
}

export const DEST_TYPES_DIR_COMMON = 'common';

export type DestTypesDir = FileContext | typeof DEST_TYPES_DIR_COMMON;

export enum RuntimeScope {
  Backend = 'backend',
  Frontend = 'frontend',
}

export enum AutocompleteType {
  Stable = 'stable',
  Beta = 'beta',
}

const DIST_BUILD_FOLDER = 'dist';
export const DEST_JSONS_PATH = `${DIST_BUILD_FOLDER}/jsons`;

export const COMPONENTS_DATA_JSON_FILENAME = 'componentsData.json';
export const MODULE_LIST_FILENAME = 'wixModules.json';
export const MODULE_LIST_BETA_FILENAME = 'wixModules.beta.json';
export const FULL_WIX_CODE_DECLARATION_FILENAME = 'fullWixCodeTypes.json';
export const FULL_WIX_CODE_DECLARATION_BETA_FILENAME =
  'fullWixCodeTypes.beta.json';

export const MODEL_JSON_FILENAME = 'model.json';

export const WIX_CODE_TYPES_MODULE_NAME = 'wix-code-types';

export const WIX_CODE_DOCS_REMOTE = 'https://github.com/wix/wix-code-docs.git';
export default {
  FULL_WIX_CODE_DECLARATION_BETA_FILENAME,
  FULL_WIX_CODE_DECLARATION_FILENAME,
  MODULE_LIST_FILENAME,
  MODULE_LIST_BETA_FILENAME,
  COMPONENTS_DATA_JSON_FILENAME,
  DEST_JSONS_PATH,
  WEB_WORKER_LIB,
  TARGET_ES_LIB,
  WIX_CODE_TYPES_MODULE_NAME,
};
