import React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import once_ from 'lodash/once';
import { MessagePanel } from '@/commonComponents/panels/messagePanel/MessagePanel';
import dataHooks from '@/sidebar/dataHooks';
import s from './deleteFileSystemItemWarningPanel.scss';
import { RichText } from '@wix/wix-base-ui';
import {
  DELETE_FILE_TYPE as DELETE_FILE_TYPE_INTERNAL,
  DeleteFileSystemItemWarningPanelProps as DeleteFileSystemItemWarningPanelPropsInternal,
} from '@wix/wix-code-common';

export type DELETE_FILE_TYPE = DELETE_FILE_TYPE_INTERNAL;
export type DeleteFileSystemItemWarningPanelProps =
  DeleteFileSystemItemWarningPanelPropsInternal;
export { openDeleteFileSystemItemWarningPanel } from '@wix/wix-code-common';

const pkgFileTypes: DELETE_FILE_TYPE_INTERNAL[] = ['Npm', 'Velo', 'Private'];

interface panelProps extends DeleteFileSystemItemWarningPanelProps {
  panelName: string;
}

const translationKeys = (experiment: AnyFixMe, type: AnyFixMe) => {
  const isBlocksCombinedMode = experiment.isOpen('se_privateAppsPanel');
  if (isBlocksCombinedMode && type === 'Private') {
    return {
      confirmLabel: 'Package_Manager_Uninstall_Confirm',
      cancelLabel: 'Package_Manager_Uninstall_Cancel',
      panelTitle: 'Package_Manager_Uninstall_App_Title',
      deleteMessage: 'Package_Manager_Uninstall_App_Text',
    };
  }
  if (pkgFileTypes.includes(type)) {
    return {
      confirmLabel: 'Package_Manager_Uninstall_Confirm',
      cancelLabel: 'Package_Manager_Uninstall_Cancel',
      panelTitle: 'Package_Manager_Uninstall_Title',
      deleteMessage: 'Package_Manager_Uninstall_Text1',
    };
  }

  return {
    confirmLabel: `WixCode_Delete_${type}_DeleteButton`,
    cancelLabel: `WixCode_Delete_${type}_CancelButton`,
    panelTitle: `WixCode_Delete_${type}_Title`,
    deleteMessage: `WixCode_Delete_${type}_Text`,
  };
};

const deleteFileSystemItemWarningPanel = once_(
  ({ experiment, util, panels }) =>
    ({ panelName, onConfirm, onCancel, fileType, fileName }: panelProps) => {
      const keys = translationKeys(experiment, fileType);
      const messageKey = fileType === 'Folder' ? 'nameOfFolder' : 'nameOfFile';
      const message = util.translate(keys.deleteMessage, {
        [messageKey]: fileName,
      });
      return (
        <MessagePanel
          dataHook={dataHooks.FILE_DELETE_MODAL}
          theme="destructive"
          title={util.translate(keys.panelTitle)}
          illustration={<Symbol name="deleteGeneralIcon_NewWorkspace" />}
          confirmLabel={util.translate(keys.confirmLabel)}
          onConfirm={onConfirm}
          cancelLabel={util.translate(keys.cancelLabel)}
          onCancel={onCancel}
          panelName={panelName}
        >
          <RichText className={s.ellipsis} shouldTranslate={false} type="T06">
            {message}
          </RichText>
        </MessagePanel>
      );
    },
);

export default deleteFileSystemItemWarningPanel;
