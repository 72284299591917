import React, { Fragment } from 'react';
import _ from 'lodash';
import { Divider } from '@wix/wix-base-ui';
import ElementNicknameSection from './elementNicknameSection';
import ElementPropsSection from './elementPropsSection';
import * as biEvents from '../bi/biEvents';
import RestrictedGlobalElementInteractionState from '../empty-states/panelRestrictedGlobalElementInteractionState';
import { ReadOnlyModeContext } from '@wix/wix-code-common-components';
import ElementApiReferenceSection from './elementApiReferenceSection';

export const ElementConfigContainerComp = (props: any) => {
  const {
    compRef,
    ElementEventsSection,
    siteId,
    compType,
    sdkType,
    t,
    wixCodeUtils,
  } = props;
  const hasConfigurableProps =
    props.isCompHiddenable ||
    props.isCompCollapsible ||
    props.isCompDisableable;
  const hasBindableEvents = !_.isEmpty(props.eventList);
  const hasApiReference = props.apiReference?.length > 0;
  const compId = compRef.id;
  const isSiteTabSelected =
    wixCodeUtils.getMasterPageFileId() === props.selectedFileId;
  const restrictedGlobalElementInteraction =
    props.isComponentVisibleOnAllPages && !isSiteTabSelected;
  const hasAnInnerStateComponent = !!props.innerStateCompRef;
  const { readOnlyMode } = React.useContext(ReadOnlyModeContext);
  const nicknameSectionsDisabled =
    readOnlyMode.propertiesPanel.nicknamesSection;
  const propsSectionDisabled = readOnlyMode.propertiesPanel.propsSection;
  const eventsSectionDisabled = readOnlyMode.propertiesPanel.eventsSection;
  const elementTypeName = sdkType.replace('$w.', '');
  const shouldDisplayApiReferenceUrl = () =>
    hasApiReference && !restrictedGlobalElementInteraction;

  return (
    <div key={compRef.id} className="page-elements-config-container">
      {shouldDisplayApiReferenceUrl() && (
        <ElementApiReferenceSection
          t={t}
          elementTypeName={elementTypeName}
          apiReferenceUrl={props.apiReference}
          selectedFileIdForBI={props.selectedFileId}
        />
      )}

      <ElementNicknameSection
        automationId="nickname-input-container"
        label={t('WixCode_PropertiesAndEventsPanel_ElementID')}
        compNickname={props.compNickname}
        setNickname={(newNickname: string) => {
          props.setNickname(newNickname);
        }}
        disabled={nicknameSectionsDisabled}
        validateNickname={props.validateNickname}
        siteId={siteId}
        compId={compId}
        compType={compType}
        sendBi={props.sendBi}
      />

      {hasAnInnerStateComponent && (
        <ElementNicknameSection
          key={props.innerStateCompRef.id}
          automationId="state-nickname-input-container"
          label={t('WixCode_PropertiesAndEventsPanel_StateID')}
          compNickname={props.innerStateCompNickname}
          setNickname={(newNickname: string) => {
            props.setInnerStateNickname(newNickname);
          }}
          disabled={nicknameSectionsDisabled}
          validateNickname={props.validateInnerStateNickname}
          siteId={siteId}
          compId={props.innerStateCompRef.id}
          compType={props.innerStateCompType}
          sendBi={props.sendBi}
        />
      )}

      {hasConfigurableProps && !restrictedGlobalElementInteraction && (
        <Fragment>
          <Divider className="divider" long />
          <ElementPropsSection
            t={t}
            disabled={propsSectionDisabled}
            isCompHiddenable={props.isCompHiddenable}
            toggleCompVisibility={props.toggleCompVisibility}
            isCompVisible={props.isCompVisible}
            isCompCollapsible={props.isCompCollapsible}
            toggleCompCollapsiblity={props.toggleCompCollapsiblity}
            isCompCollapsed={props.isCompCollapsed}
            isCompDisableable={props.isCompDisableable}
            toggleCompEnability={props.toggleCompEnability}
            isCompEnabled={props.isCompEnabled}
            sendBi={props.sendBi}
            siteId={props.siteId}
            compId={compRef.id}
            compType={props.compType}
          />
        </Fragment>
      )}

      {!props.isGroup &&
        hasBindableEvents &&
        !restrictedGlobalElementInteraction && (
          <Fragment>
            <Divider className="divider" long />
            <ElementEventsSection
              disabled={eventsSectionDisabled}
              editedComp={compRef}
              eventList={props.eventList}
              savedBehaviors={props.savedBehaviors}
            />
          </Fragment>
        )}
      {restrictedGlobalElementInteraction && (
        <Fragment>
          <Divider className="divider" long />
          <RestrictedGlobalElementInteractionState
            t={t}
            openHelpPanel={(helpArticleId, options) => {
              props.sendBi(
                biEvents.learnMoreLinkClicked({
                  linkText: options?.linkText,
                  linkUrl: helpArticleId,
                }),
              );
              props.openHelpPanel(helpArticleId);
            }}
            openMasterPage={() => {
              props.sendBi(biEvents.openMasterPage());
              props.openMasterPage();
            }}
          />
        </Fragment>
      )}
    </div>
  );
};
