import React, { Fragment, useContext } from 'react';
import _ from 'lodash';
import { TreeSectionDivider } from '@wix/wix-base-ui';
import { monitoring } from '@wix/wix-code-common';
import { leftTreeClickOnASection } from '@wix/bi-logger-platform/v2';
import dataHooks from '../dataHooks';
import { DynamicPagesGroup } from './DynamicPagesGroup';
import { PagesTabContext } from '../pagesTree/PagesTabContext';
import { PageTreeContext } from '../pagesTree/PageTreeContext';

interface DynamicPagesTreeProps {
  dataSource: any;
}

const { withErrorHandling } = monitoring;

export const DynamicPagesTree = ({ dataSource }: DynamicPagesTreeProps) => {
  const { isCollapsed, setCollapsed } = useContext(PageTreeContext);
  const { bi } = useContext(PagesTabContext);

  const getId = () => 'page_' + _.get(dataSource, 'id');
  const [collapsed, setCollapsedState] = React.useState(isCollapsed(getId()));

  const onNodeClick = () =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickOnASection({
          section_name: 'dynamic_pages',
          action: collapsed ? 'open' : 'close',
        }),
      );
      setCollapsed(getId(), !collapsed);
      setCollapsedState(!collapsed);
    })();

  return (
    <Fragment>
      <div data-aid="dynamic-pages-section">
        <TreeSectionDivider
          dataHook={dataHooks.SECTION_DIVIDER}
          label={dataSource.label}
          collapsed={collapsed}
          hideBottomBorder={collapsed}
          onClick={onNodeClick}
          size="small"
        />
      </div>
      {!collapsed && (
        <ul
          data-aid="dynamic-pages-items"
          key="subtree"
          className="section-container"
        >
          {dataSource.items &&
            dataSource.items.map((child: AnyFixMe) => (
              <li key={child.id}>
                <DynamicPagesGroup dataSource={child} />
              </li>
            ))}
        </ul>
      )}
    </Fragment>
  );
};
