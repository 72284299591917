import React from 'react';
import { PanelHeader } from '@wix/wix-base-ui';
import {
  CodeEditorAPI,
  EditorAPI,
  EditorContextAPI,
  PanelsAPI,
} from '@wix/wix-code-plugin-contracts';
import s from './veloLiteCodeEditor.scss';
import { connectWithShell, Shell } from '@wix/wix-code-repluggable';
import {
  ExternalIDEBannerSlot,
  ExternalIDEBannerRenderer,
} from './components/ExternalIDEBanner/ExternalIDEBannerRenderer';
import { useTranslation } from '@wix/wix-code-common-components';

type StateCodeIdeProps = {
  tabName: string;
};
type OwnCodeIdeProps = {
  readOnly: boolean;
  closePanel: () => void;
};

type CodeIdeProps = StateCodeIdeProps & OwnCodeIdeProps;

enum TabType {
  PAGE_CODE = 'lite_panel.menu.page_code',
  GLOBAL_CODE = 'lite_panel.menu.master_page',
  CSS = 'lite_panel.menu.css',
}

type VeloLiteCodeEditorAPIs = {
  codeEditorAPI: CodeEditorAPI;
  editorContextAPI: EditorContextAPI;
  editorAPI: EditorAPI;
  panelsAPI: PanelsAPI;
};
export const createVeloLiteCodeIde = (
  WixCodeIde: React.ComponentType<any>,
  shell: Shell,
  isMasterPageContext: (editorAPI: EditorAPI) => boolean,
  isCssFileContext: (editorAPI: EditorAPI) => boolean,
  externalIDEBannerRendererSlot: ExternalIDEBannerSlot,
  apis: VeloLiteCodeEditorAPIs,
) => {
  const { panelsAPI, codeEditorAPI, editorAPI, editorContextAPI } = apis;
  const CodeIde: React.FC<CodeIdeProps> = ({
    tabName,
    readOnly,
    closePanel,
  }) => {
    const [t] = useTranslation();

    const onOpenHelp = () => {
      switch (tabName) {
        case TabType.PAGE_CODE:
          panelsAPI.openHelpCenter('a47a3111-4d91-498a-b137-e8cda771f6c1');
          return;
        case TabType.GLOBAL_CODE:
          panelsAPI.openHelpCenter('2168fdfc-ab62-4041-9400-bc72eae7def7');
          return;
        case TabType.CSS:
          panelsAPI.openHelpCenter('2aa5cd24-b80c-4581-b55e-a2a3dc48d590');
          return;
        default:
          return;
      }
    };

    return (
      <>
        <PanelHeader
          onHelp={onOpenHelp}
          onClose={closePanel}
          className="dragger dark"
          defaultCursor
        >
          {t(tabName)}
        </PanelHeader>
        {externalIDEBannerRendererSlot && (
          <ExternalIDEBannerRenderer
            externalIDEBannerSlot={externalIDEBannerRendererSlot}
          />
        )}
        <WixCodeIde
          id="wix-code-ide"
          additionalSidePane={undefined}
          readOnly={readOnly}
          key="wix-code-ide"
          codeIntelligence={codeEditorAPI.codeIntelligence}
          editorContextAPI={editorContextAPI}
          className={s.ide}
          position="relative"
        ></WixCodeIde>
      </>
    );
  };

  const getTabHeaderName = (isMasterPage: boolean, isCssFile: boolean) => {
    return isMasterPage
      ? TabType.GLOBAL_CODE
      : isCssFile
      ? TabType.CSS
      : TabType.PAGE_CODE;
  };
  const ConnectedCodeIde = connectWithShell<
    {},
    OwnCodeIdeProps,
    StateCodeIdeProps
  >(
    (_state, _shell, ownProps) => {
      return {
        tabName: getTabHeaderName(
          isMasterPageContext(editorAPI),
          isCssFileContext(editorAPI),
        ),
        ...ownProps,
      };
    },
    () => ({}),
    shell,
  )(CodeIde);

  return ConnectedCodeIde;
};
