const ERROR_PARSING_JSON =
  'Error: Invalid JSON object. Check the JSON object syntax in the left panel and try again.';

const HTTP_FUNCTIONS_REQUEST_OBJECT_WARNINGS = {
  PATH_IS_NOT_ARRAY: 'path must be an array',
  PATH_ITEMS_ARE_NOT_STRINGS: 'path items must be strings',
  QUERY_IS_NOT_OBJECT: 'query must be an object',
  QUERY_VALUES_ARE_NOT_STRINGS: 'query values must be strings',
  METHOD_IS_NOT_STRINGS: 'method must be a string',
  METHOD_IS_INVALID: 'method must be one of the specified HTTP methods',
  HEADERS_IS_NOT_OBJECT: 'headers must be an object',
  HEADERS_VALUES_IS_NOT_STRING: 'header values must be strings',
  BODY_IS_INVALID: 'body must include either json() or text()',
  BODY_JSON_IS_INVALID: 'body.json() must return a valid JSON object',
  BODY_TEXT_IS_INVALID: 'body.text() must return a string',
};

export default {
  ERROR_PARSING_JSON,
  HTTP_FUNCTIONS_REQUEST_OBJECT_WARNINGS,
};
