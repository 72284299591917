import './eventsBinding.global.scss';
import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import bi from '../../../../bi/bi';
import { utilsCreator } from '@wix/wix-code-common';
import eventControlCreator from './eventControl/eventControl';

import once_ from 'lodash/once';

export default once_(
  ({
    baseUI,
    constants,
    core,
    experiment,
    helpIds,
    platform,
    stateManagement,
    util,
  }) => {
    const EventControl = eventControlCreator({ baseUI, constants });
    const utils = utilsCreator({ experiment, platform, util });

    function sendBIEvent(
      editorAPI,
      editedComponent,
      biEventName,
      eventName,
      eventHandlerName,
    ) {
      editorAPI.bi.event(biEventName, {
        site_id: editorAPI.dsRead.generalInfo.getSiteId(),
        element_type: utils.getCompTypeOrControllerType(
          editorAPI,
          editedComponent,
        ),
        event: eventName,
        handler_name: eventHandlerName,
      });
    }

    function getBehaviorDef(editorAPI, editedComponent) {
      const compType = editorAPI.components.getType(editedComponent);
      const name = _.includes(
        platform.constants.CONTROLLER_COMPONENT_TYPES,
        compType,
      )
        ? 'runAppCode'
        : 'runCode';
      return editorAPI.behaviors.getDefinition(name);
    }

    function buildBehaviorObject(editorAPI, editedComponent, handlerName) {
      const behaviorDef = getBehaviorDef(editorAPI, editedComponent);
      behaviorDef.params = {
        callbackId: handlerName,
        compId: editedComponent.id,
      };
      return behaviorDef;
    }

    function getSavedBehaviorByEventType(
      editorAPI,
      editedComponent,
      eventType,
      savedBehaviors,
    ) {
      if (!editedComponent) {
        return null;
      }
      const showOnAllPages =
        editorAPI.components.isShowOnAllPages(editedComponent);

      const behaviorDef = getBehaviorDef(editorAPI, editedComponent);
      const behaviorParams = {
        action: {
          name: eventType,
        },
        behavior: {
          name: behaviorDef.name,
          type: behaviorDef.type,
          targetId: getBehaviorTargetRef(editorAPI, editedComponent).id,
        },
      };
      if (showOnAllPages) {
        delete behaviorParams.behavior.targetId;
      }
      return _.find(savedBehaviors, behaviorParams);
    }

    function getBehaviorTargetRef(editorAPI, editedComponent) {
      return editorAPI.wixCode.getWidgetRef(editedComponent);
    }

    function addBehavior(
      editorAPI,
      editedComponent,
      eventType,
      eventName,
      callback,
    ) {
      const nickName = editorAPI.components.code.getNickname(editedComponent);
      const compType = editorAPI.components.getType(editedComponent);
      const currentPageId = editorAPI.dsRead.pages.getFocusedPageId();
      const showOnAllPages =
        editorAPI.components.isShowOnAllPages(editedComponent);

      editorAPI.wixCode.events
        .getDefaultEventHandlerName(
          currentPageId,
          showOnAllPages,
          eventName,
          compType,
          nickName,
        )
        .then(function (handlerDefaultName) {
          const behaviorTargetRef = getBehaviorTargetRef(
            editorAPI,
            editedComponent,
          );
          const behavior = buildBehaviorObject(
            editorAPI,
            editedComponent,
            handlerDefaultName,
          );
          const action = {
            // should be generated via API as well
            type: 'comp',
            name: eventType,
          };
          editorAPI.behaviors.update(
            editedComponent,
            action,
            behaviorTargetRef,
            behavior,
          );
          callback(handlerDefaultName);
        });
    }

    function getEventHandlerName(behaviorObj) {
      return _.get(behaviorObj, 'behavior.params.callbackId');
    }

    function getInputState(hasBehavior, eventType, currentlyEdited) {
      if (currentlyEdited === eventType) {
        return 'editing';
      }
      return hasBehavior ? 'exist' : 'none';
    }

    function addEventHandler(editedComponent, eventType, eventName) {
      const editorAPI = this.getEditorAPI();
      editorAPI.developerMode.ui.idePane.unMinimize();

      addBehavior(
        editorAPI,
        editedComponent,
        eventType,
        eventName,
        function (handlerDefaultName) {
          this.setState({ currentlyEdited: eventType, handlerDefaultName });
          editorAPI.bi.event(
            bi.events.PROPERTIES_PANEL_EVENT_HANDLER_ADD_PRESSED,
            {
              site_id: editorAPI.dsRead.generalInfo.getSiteId(),
              element_type: utils.getCompTypeOrControllerType(
                editorAPI,
                editedComponent,
              ),
              event: eventName,
              default_handler_name: handlerDefaultName,
            },
          );
        }.bind(this),
      );
    }

    function navigateToMethod(editedComponent, eventType, eventName) {
      const editorAPI = this.getEditorAPI();
      const currentPageId = editorAPI.dsRead.pages.getFocusedPageId();
      const showOnAllPages =
        editorAPI.components.isShowOnAllPages(editedComponent);
      const savedBehaviorByEventType = getSavedBehaviorByEventType(
        editorAPI,
        editedComponent,
        eventType,
        this.props.savedBehaviors,
      );
      const eventHandlerName = getEventHandlerName(savedBehaviorByEventType);
      editorAPI.wixCode.ui
        .setFocusOnEventHandler(currentPageId, showOnAllPages, eventHandlerName)
        .then(
          null,
          handleNavigateToMethodPromiseRejection.bind(this, eventType),
        );

      sendBIEvent(
        editorAPI,
        editedComponent,
        bi.events.PROPERTIES_PANEL_EVENT_HANDLER_NAVIGATION,
        eventName,
        eventHandlerName,
      );
    }

    function handleNavigateToMethodPromiseRejection(eventType) {
      this.setState({ navigationErrorForEventType: eventType });
    }

    function updateHandlerNameAndGenerateCode(
      {
        type: eventType,
        name: eventName,
        handlerArgs,
        description: eventDescription,
      },
      evt,
    ) {
      const editorAPI = this.getEditorAPI();
      const editedComponent = this.props.editedComponent;
      let handlerName = evt.target.value;

      const savedBehaviorObj = getSavedBehaviorByEventType(
        editorAPI,
        editedComponent,
        eventType,
        this.props.savedBehaviors,
      );
      const behaviorTargetRef = getBehaviorTargetRef(
        editorAPI,
        editedComponent,
      );
      const currentPageId = editorAPI.dsRead.pages.getFocusedPageId();
      const showOnAllPages =
        editorAPI.components.isShowOnAllPages(editedComponent);

      const isNameValid =
        editorAPI.wixCode.events.validateEventHandlerName(handlerName);
      if (
        isNameValid !==
          editorAPI.wixCode.events.EVENT_HANDLER_NAME_VALIDATIONS.VALID &&
        handlerName !== ''
      ) {
        handlerName = this.state.handlerDefaultName;
      }

      _.set(savedBehaviorObj, 'behavior.params.callbackId', handlerName);
      editorAPI.behaviors.update(
        editedComponent,
        savedBehaviorObj.action,
        behaviorTargetRef,
        savedBehaviorObj.behavior,
      );

      if (_.isEmpty(handlerName)) {
        editorAPI.behaviors.remove(
          editedComponent,
          savedBehaviorObj.action,
          behaviorTargetRef,
          savedBehaviorObj.behavior,
        );
        this.setState({ currentlyEdited: null, handlerDefaultName: null });
        return;
      }
      showMobileOnlyCodeNotificationIfNeeded(editorAPI);
      editorAPI.wixCode.events.generateEventHandlerCode(
        currentPageId,
        showOnAllPages,
        handlerName,
        handlerArgs,
        eventDescription,
      );
      this.setState({ currentlyEdited: null, handlerDefaultName: null });

      sendBIEvent(
        editorAPI,
        editedComponent,
        bi.events.PROPERTIES_PANEL_EVENT_HANDLER_ADDED,
        eventName,
        getEventHandlerName(savedBehaviorObj),
      );
    }

    function showMobileOnlyCodeNotificationIfNeeded(editorAPI) {
      if (!editorAPI.isMobileEditor()) {
        return;
      }
      const dontShowAgainKey =
        constants.USER_PREFS.MOBILE_EDITOR.WIX_CODE.MOBILE_ONLY_CODE
          .DONT_SHOW_AGAIN;
      const store = editorAPI.store;
      const dispatch = store.dispatch;
      const getSiteUserPreferences = (key) =>
        stateManagement.editorPlugins.userPreferences.selectors.getSiteUserPreferences(
          key,
        )(store.getState());
      const setSiteUserPreferences = (key, value) =>
        dispatch(
          stateManagement.editorPlugins.userPreferences.actions.setSiteUserPreferences(
            key,
            value,
          ),
        );

      if (!getSiteUserPreferences(dontShowAgainKey)) {
        dispatch(
          stateManagement.editorPlugins.notifications.actions.showUserActionNotification(
            {
              message: 'Notifications_Mobile_Code_Text',
              title: 'Notifications_Mobile_Code_Text',
              type: 'info',
              link: {
                caption: 'Notifications_Learn_More_Link',
                onNotificationLinkClick: () =>
                  editorAPI.panelManager.openHelpCenter(
                    helpIds.NOTIFICATIONS.MOBILE_ONLY_CODE,
                  ),
              },
            },
          ),
        );
        setSiteUserPreferences(dontShowAgainKey, true);
      }
    }

    function removeEventHandler(eventType, eventName) {
      const editorAPI = this.getEditorAPI();
      const editedComponent = this.props.editedComponent;
      const savedBehaviorObj = getSavedBehaviorByEventType(
        editorAPI,
        editedComponent,
        eventType,
        this.props.savedBehaviors,
      );

      if (savedBehaviorObj) {
        const behaviorTargetRef = editorAPI.components.get.byId(
          savedBehaviorObj.behavior.targetId,
        );
        editorAPI.behaviors.remove(
          editedComponent,
          savedBehaviorObj.action,
          behaviorTargetRef,
          savedBehaviorObj.behavior,
        );
        this.setState({ currentlyEdited: null, handlerDefaultName: null });

        sendBIEvent(
          editorAPI,
          editedComponent,
          bi.events.PROPERTIES_PANEL_EVENT_HANDLER_REMOVED,
          eventName,
          getEventHandlerName(savedBehaviorObj),
        );
      }
    }

    const EVENT_NAME_VALIDATION_MSGS = {
      INVALID_FN_NAME:
        'WixCode_PropertiesPanel_HandlerName_Validation_InvalidJSFunctionName',
      TOO_LONG: 'WixCode_PropertiesPanel_HandlerName_Validation_TooLong',
    };

    function isEventHandlerNameValid(name) {
      const editorAPI = this.getEditorAPI();
      const validations =
        editorAPI.wixCode.events.EVENT_HANDLER_NAME_VALIDATIONS;

      return (
        editorAPI.wixCode.events.validateEventHandlerName(name) ===
        validations.VALID
      );
    }

    function getEventHandlerNameInvalidMessage(name) {
      const editorAPI = this.getEditorAPI();
      const validationResult =
        editorAPI.wixCode.events.validateEventHandlerName(name);

      if (validationResult === editorAPI.components.code.VALIDATIONS.VALID) {
        return '';
      }

      return util.translate(EVENT_NAME_VALIDATION_MSGS[validationResult]);
    }

    function onErrorTooltipClosed() {
      this.setState({ navigationErrorForEventType: null });
    }
    return createReactClass({
      displayName: 'eventsBinding',
      propTypes: {
        editedComponent: PropTypes.object.isRequired,
        eventList: PropTypes.arrayOf(PropTypes.object).isRequired,
        isReadOnly: PropTypes.bool,
        showOnlySavedEvents: PropTypes.bool,
        disableLinkToCode: PropTypes.bool,
        savedBehaviors: PropTypes.arrayOf(PropTypes.object),
      },
      mixins: [core.mixins.editorAPIMixin],
      getInitialState() {
        return {
          currentlyEdited: null,
          handlerDefaultName: null,
        };
      },

      UNSAFE_componentWillReceiveProps(nextProps) {
        if (_.isEqual(nextProps.editedComponent, this.props.editedComponent)) {
          return;
        }
        this.setState({ currentlyEdited: null, handlerDefaultName: null });
      },

      getEvents() {
        const events = _.map(this.props.eventList, this.getEventProps);
        if (this.props.showOnlySavedEvents) {
          return _.filter(events, 'handlerName');
        }
        return events;
      },

      getEventProps(event) {
        const editorAPI = this.getEditorAPI();
        const editedComponent = this.props.editedComponent;

        const savedBehaviorObj = getSavedBehaviorByEventType(
          editorAPI,
          editedComponent,
          event.type,
          this.props.savedBehaviors,
        );
        const inputState = getInputState(
          !!savedBehaviorObj,
          event.type,
          this.state.currentlyEdited,
        );
        return {
          eventLabel: `${event.name}:`,
          eventType: event.type,
          eventDescription: event.description,
          handlerInputState: inputState,
          handlerName: getEventHandlerName(savedBehaviorObj),
          onAddEventHandlerClicked: addEventHandler.bind(
            this,
            editedComponent,
            event.type,
            event.name,
          ),
          onHandlerNameClicked: this.props.disableLinkToCode
            ? undefined
            : navigateToMethod.bind(
                this,
                editedComponent,
                event.type,
                event.name,
              ),
          onHandlerNameChanged: updateHandlerNameAndGenerateCode.bind(
            this,
            event,
          ),
          onRemoveHandlerClicked: removeEventHandler.bind(
            this,
            event.type,
            event.name,
          ),
          isHandlerNameValid: isEventHandlerNameValid.bind(this),
          getNameInvalidMessage: getEventHandlerNameInvalidMessage.bind(this),
          isReadOnly: this.props.isReadOnly,
          isErrorTooltipActive:
            event.type === this.state.navigationErrorForEventType,
          onErrorTooltipClosed: onErrorTooltipClosed.bind(this),
        };
      },

      render() {
        return (
          <div className="events-binding" data-aid="events-binding">
            <span className="inner form layout-title" key="eventsTab">
              <div className="events-title-wrapper">
                <span className="events-title">
                  {util.translate('WixCode_PropertiesPanel_EventsTab_Title')}
                </span>
              </div>
              {this.getEvents().map((event, eventIndex) => (
                <EventControl
                  {...event}
                  key={eventIndex}
                  ref={`eventControl${eventIndex}`}
                ></EventControl>
              ))}
            </span>
          </div>
        );
      },
    });
  },
);
