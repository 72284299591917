import * as React from 'react';
import { PackagesModalContext } from '../../../../../packagesModalContext';
import dataHooks from '../../../../../dataHooks';
import { InstalledPackagesListSection } from './InstalledPackagesListSection';

export const InstalledPkgsListNpmSection: React.FC = () => {
  const { installedPkgs, isNpmPackagesFetchingDone } =
    React.useContext(PackagesModalContext);

  const sectionTitle = 'Package_Manager_Section_Manage_Installed_npm';

  return (
    <InstalledPackagesListSection
      dataHook={dataHooks.INSTALLED_NPM_PKGS}
      sectionTitle={sectionTitle}
      installedPkgs={installedPkgs.npm}
      isLoading={!isNpmPackagesFetchingDone}
    />
  );
};
