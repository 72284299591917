import {
  TbfBiOpenTbfPanel,
  TbfBiResetParameters,
  TbfBiTriggerBeFunction,
  TbfBiClearConsoleOutput,
  TbfBiBeFunctionExecutionSuccess,
  TbfBiBeFunctionExecutionError,
  TbfValidFunctionInIde,
} from './eventTypes';

const JSW_TEMPLATE_DEFAULT_FUNCTION = 'multiply';

const isDefaultFunctionFromTemplate = (functionName: string) =>
  functionName === JSW_TEMPLATE_DEFAULT_FUNCTION;

const openTbfPanel = ({
  parametersCount,
  functionName,
  fileName,
  origin,
}: TbfBiOpenTbfPanel) => ({
  is_default_function: isDefaultFunctionFromTemplate(functionName),
  parameters_count: parametersCount,
  origin,
  file_name: fileName,
  function_name: functionName,
});

const resetParameters = ({
  parametersCount,
  functionName,
  fileName,
  origin,
}: TbfBiResetParameters) => ({
  is_default_function: isDefaultFunctionFromTemplate(functionName),
  parameters_count: parametersCount,
  file_name: fileName,
  function_name: functionName,
  action: 'reset_parameters',
  origin,
});

const triggerBeFunction = ({
  parametersCount,
  functionName,
  fileName,
  origin,
}: TbfBiTriggerBeFunction) => ({
  is_default_function: isDefaultFunctionFromTemplate(functionName),
  parameters_count: parametersCount,
  file_name: fileName,
  function_name: functionName,
  origin,
});

const clearConsoleOutput = ({
  parametersCount,
  functionName,
  fileName,
  origin,
}: TbfBiClearConsoleOutput) => ({
  is_default_function: isDefaultFunctionFromTemplate(functionName),
  parameters_count: parametersCount,
  file_name: fileName,
  function_name: functionName,
  action: 'clear_output',
  origin,
});

const beFunctionExecutionSuccess = ({
  parametersCount,
  functionName,
  fileName,
  fileType,
  runTime,
  isDefaultParams,
}: TbfBiBeFunctionExecutionSuccess) => ({
  is_default_function: isDefaultFunctionFromTemplate(functionName),
  is_default_params: isDefaultParams,
  parameters_count: parametersCount,
  file_name: fileName,
  function_name: functionName,
  file_type: fileType,
  run_time: runTime,
  exception: false,
});

const beFunctionExecutionError = ({
  parametersCount,
  functionName,
  fileName,
  fileType,
  runTime,
  exceptionType,
  isDefaultParams,
}: TbfBiBeFunctionExecutionError) => ({
  is_default_function: isDefaultFunctionFromTemplate(functionName),
  is_default_params: isDefaultParams,
  parameters_count: parametersCount,
  file_name: fileName,
  function_name: functionName,
  file_type: fileType,
  run_time: runTime,
  exception_type: exceptionType,
  exception: true,
});

const validFunctionInIde = ({
  functionName,
  fileName,
  fileType,
}: TbfValidFunctionInIde) => ({
  is_default_function: isDefaultFunctionFromTemplate(functionName),
  file_name: fileName,
  file_type: fileType,
  function_name: functionName,
});

const playButtonExposure = (fileName: AnyFixMe) => ({
  file_name: fileName,
});

export default {
  openTbfPanel,
  resetParameters,
  triggerBeFunction,
  clearConsoleOutput,
  beFunctionExecutionSuccess,
  beFunctionExecutionError,
  validFunctionInIde,
  playButtonExposure,
};
