import * as React from 'react';
import cx from 'classnames';
import { FileIcon, useEditorLegacyAPIs } from '@wix/wix-code-common-components';
import { TreeListItem } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import dataHooks from '../../dataHooks';
import { FileSubTreeDataSource } from '../../filesTree/FileSubTree';
import bi from '@/legacy/bi/bi';
import { leftTreeTabPin } from '@/legacy/bi/biEvents';
import { NodeIcon } from '@wix/wix-code-plugin-contracts';
import s from './fileTreeListItem.scss';
import { Package } from '@wix/wix-ui-icons-common/classic-editor';
import { experimentUtils } from '@wix/wix-code-common';

interface FileTreeListItemProps {
  dataSource: FileSubTreeDataSource;
  depth: number;
  alwaysShowSuffix: boolean;
  suffix: React.ReactNode;
  labelOverride?: string;
  onNodeClick: (id: string) => void;
  nodeDoubleClick: (fileId: string) => any;
  isContextMenuOpen: boolean;
  customNodeIcon?: NodeIcon;
}

export const FileTreeListItem: React.FC<FileTreeListItemProps> = (props) => {
  const { editorAPI } = useEditorLegacyAPIs();
  const devContext = editorAPI.developerMode.getContext();
  const selectedIdeTabId = devContext.data.id;
  const isVeloLite = experimentUtils.isLiteEditor();

  const {
    depth,
    alwaysShowSuffix,
    suffix,
    labelOverride,
    dataSource,
    onNodeClick,
    nodeDoubleClick,
    isContextMenuOpen,
    customNodeIcon,
  } = props;

  const { name, id, isFolder, expanded } = dataSource;
  const isSelectedTab = selectedIdeTabId === id;

  const onNodeDoubleClick = () => {
    if (!isFolder) {
      editorAPI.bi.event(
        bi.events.IDE_TAB_ACTION,
        leftTreeTabPin('left_tree_double_click', id),
      );
      nodeDoubleClick(id);
    }
  };

  const getTreeListItemExtraProps = () =>
    isVeloLite
      ? { icon: <Package /> }
      : {
          collapseState: expanded ? 'expanded' : 'collapsed',
          icon: (
            <Symbol
              name={`corvid_tree__folder_${expanded ? 'open' : 'closed'}`}
              className="corvid-tree-prefix-icon"
            />
          ),
        };

  return isFolder ? (
    <div
      data-aid="folder-node"
      className={s.fileTreeNodeTooltipContainer}
      data-hook={dataHooks.TREE_NODE_CONTAINER}
    >
      {customNodeIcon ? (
        <div className={s.fileTreeNodeTooltip}>{customNodeIcon.icon}</div>
      ) : null}
      <TreeListItem
        dataHook={dataHooks.TREE_NODE}
        alwaysShowSuffix={alwaysShowSuffix}
        highlight={isContextMenuOpen}
        key={labelOverride || name}
        label={labelOverride || name}
        shouldTranslate={false}
        size="small"
        onClick={onNodeClick}
        suffix={suffix}
        depth={depth}
        {...getTreeListItemExtraProps()}
      />
    </div>
  ) : (
    <>
      {customNodeIcon ? (
        <div className={s.fileTreeNodeTooltip}>{customNodeIcon.icon}</div>
      ) : null}
      <TreeListItem
        dataHook={dataHooks.TREE_NODE}
        label={name}
        shouldTranslate={false}
        alwaysShowSuffix={alwaysShowSuffix}
        highlight={isContextMenuOpen}
        depth={depth}
        icon={
          <FileIcon
            path={name}
            key="icon_file"
            className={cx('corvid-tree-prefix-icon', {
              highlight: isSelectedTab,
            })}
            editorAPI={editorAPI}
          />
        }
        size="small"
        suffix={suffix}
        onClick={onNodeClick}
        onDoubleClick={onNodeDoubleClick}
        selected={isSelectedTab}
      />
    </>
  );
};
