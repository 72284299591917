import React, { FC, useCallback } from 'react';
import ContextMenuItem from '../../contextMenuItem/contextMenuItem';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import {
  RadioButtons,
  ContextMenuAction,
  ContextMenu,
  Divider,
} from '@wix/wix-base-ui';
import { WixCodeTheme } from '@/codeEditor/constants/themes';
import bi from '../../../../bi/bi';
import { ideMoreDropdownOpen } from '../../../../bi/biEvents';
import { useTranslate } from '@/infra/i18n/useTranslate';

type Action = {
  onClick: () => void;
  label: string;
};

type ThemeAction = {
  onClick: () => void;
  label: string;
  value: string;
};

type MoreDropdownProps = {
  actions: Action[];
  themeActions: ThemeAction[];
  selectedTheme: WixCodeTheme;
  sendBi: (event: any) => void;
};

export const MoreDropdown: FC<MoreDropdownProps> = ({
  actions,
  themeActions,
  selectedTheme,
  sendBi,
}) => {
  const { t } = useTranslate();
  const onThemeChange = useCallback(
    (theme: WixCodeTheme) => {
      const themeAction = themeActions.find((action) => action.value === theme);
      return themeAction?.onClick();
    },
    [themeActions],
  );

  const sendOpenMenuBiEvent = useCallback(() => {
    sendBi({
      def: bi.events.IDE_MORE_DROPDOWN_OPEN,
      params: ideMoreDropdownOpen(),
    });
  }, [sendBi]);

  const contextMenuButton = (
    <div className="more-dropdown-button-inner" onClick={sendOpenMenuBiEvent}>
      <Symbol name="show_more" />
    </div>
  );
  return (
    <div className="wix-code-ide-toolbar-more-dropdown">
      <ContextMenu
        className="more-dropdown-button"
        automationId="more-dropdown-button"
        customButton={contextMenuButton}
        optionsContainerClassName="ide-toolbar-dropdown-options"
        alignment="BOTTOM"
        direction="LEFT"
      >
        {actions.map((action) => {
          return (
            <ContextMenuAction
              automationId="more-dropdown-action"
              key={action.label}
              onClick={action.onClick}
            >
              <ContextMenuItem symbolName="show_more" label={action.label} />
            </ContextMenuAction>
          );
        })}
        <>
          <Divider long />
          <ContextMenuAction>
            <ContextMenuItem
              symbolName="show_more"
              label={t('WixCode_MoreDropdown_Theme_Label')}
            />
          </ContextMenuAction>
          <RadioButtons
            value={selectedTheme}
            onChange={onThemeChange}
            options={themeActions}
            className="radio-buttons"
            shouldTranslate={false}
            automationId="theme-radio-buttons"
          />
        </>
      </ContextMenu>
    </div>
  );
};
