import * as React from 'react';
import './multiTabsPopover.global.scss';
import { TextLabel, Tooltip, RichText, Button } from '@wix/wix-base-ui';
import bi from '@/legacy/bi/bi';
import { connect } from 'react-redux';
import {
  wixCodeReduxContext,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import ideTabsStateReader from '../../../tabs/selectors/ideTabsStateReader';
import dataHooks from '@/sidebar/dataHooks';
import { ReactNode } from 'react';
import { popoversApiCreator } from '../../api';
import { AppState } from '@/infra/redux-state/reducers/rootReducer';

interface MultiTabsPopoverReduxProps {
  unpinnedTab: string | null;
}
type MultiTabsPopoverProps = {
  fileId: string;
  children?: ReactNode;
};

type MultiTabsPopoverCompProps = MultiTabsPopoverReduxProps &
  MultiTabsPopoverProps;

const MultiTabsPopoverComp: React.FC<MultiTabsPopoverCompProps> = ({
  fileId,
  unpinnedTab,
  children,
}) => {
  const { editorAPI, legacyDependenciesAPI } = useEditorLegacyAPIs();
  const {
    util: { translate },
    stateManagement,
  } = legacyDependenciesAPI;
  const popoversApi = popoversApiCreator({ stateManagement });

  const [anotherFileWasUnpinned, setAnotherFileWasUnpinned] =
    React.useState(false);

  React.useEffect(() => {
    if (unpinnedTab != null && fileId !== unpinnedTab) {
      setAnotherFileWasUnpinned(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unpinnedTab]);

  const shouldShow =
    fileId === unpinnedTab &&
    anotherFileWasUnpinned &&
    !popoversApi.isMultiTabsPopoverDismissed(editorAPI);

  React.useEffect(() => {
    if (shouldShow) {
      editorAPI.bi.event(bi.events.IDE_POPUP_SHOWN, {
        origin: null,
        popup_name: 'multi_tabs_popover',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShow]);

  const onGotItButtonClick = () => {
    editorAPI.bi.event(bi.events.IDE_POPUP_CLOSED, {
      origin: 'got_it',
      popup_name: 'multi_tabs_popover',
    });

    popoversApi.setMultiTabsPopoverDismissed(editorAPI);
    setAnotherFileWasUnpinned(false);
  };

  return (
    <Tooltip
      interactive={true}
      closeOnMouseLeave={false}
      isOpen={shouldShow}
      disabled={!shouldShow}
      alignment="RIGHT"
      content={
        <div className="wix-code-multi-tabs-popover">
          <TextLabel
            type="T06"
            value={translate('WixCode_IdeTabs_PageTab_Notification_Title')}
            enableEllipsis={false}
          />
          <RichText type="T02">
            {translate('WixCode_IdeTabs_PageTab_Notification_Description')}
          </RichText>

          <Button
            dataHook={dataHooks.MULTI_TABS_POPOVER_BTN}
            className="btn-sm btn-confirm-secondary wix-code-multi-tabs-popover-button"
            onClick={onGotItButtonClick}
          >
            {translate('WixCode_IdeTabs_PageTab_Notification_Button')}
          </Button>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};

function mapStateToProps(state: AppState): MultiTabsPopoverReduxProps {
  const unpinnedTab = ideTabsStateReader.getUnpinnedTabId(state);
  return {
    unpinnedTab,
  };
}

export const MultiTabsPopover = connect(mapStateToProps, null, null, {
  context: wixCodeReduxContext,
})(MultiTabsPopoverComp);
