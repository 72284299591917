import { experimentUtils } from '@wix/wix-code-common';
import { MenuItemEntry } from '@wix/wix-code-plugin-contracts';

export const liteEntries: MenuItemEntry[] = [
  {
    type: 'section',
    value: 'add_code',
    label: 'lite_panel.menu.section_header.add_code',
  },
  { type: 'item', value: 'page_code', label: 'lite_panel.menu.page_code' },
  { type: 'item', value: 'global_code', label: 'lite_panel.menu.master_page' },
  { type: 'item', value: 'css', label: 'lite_panel.menu.css' },
  { type: 'divider', value: 'divider', label: 'divider' },
  {
    type: 'section',
    value: 'integrations',
    label: 'lite_panel.menu.section_header.integrations',
  },
  { type: 'item', value: 'backend', label: 'lite_panel.menu.backend' },
  { type: 'item', value: 'databases', label: 'lite_panel.menu.databases' },
  { type: 'item', value: 'packages', label: 'lite_panel.menu.packages' },
  { type: 'item', value: 'github', label: 'lite_panel.menu.github' },
  { type: 'divider', value: 'divider', label: 'divider' },
  {
    type: 'section',
    value: 'resources',
    label: 'lite_panel.menu.section_header.help',
  },
  {
    type: 'item',
    value: 'help',
    label: 'lite_panel.menu.help',
  },
];

export const entries: MenuItemEntry[] = [
  { type: 'item', value: 'pages', label: 'wix_code.studio.menu.page_code' },
  { type: 'item', value: 'site_code', label: 'wix_code.studio.menu.site_code' },
  { type: 'item', value: 'search', label: 'wix_code.studio.menu.search' },
  { type: 'divider', value: 'divider', label: 'divider' },
  { type: 'item', value: 'github', label: 'wix_code.studio.menu.github' },
  { type: 'item', value: 'databases', label: 'wix_code.studio.menu.databases' },
  { type: 'item', value: 'packages', label: 'wix_code.studio.menu.packages' },
  { type: 'item', value: 'tools', label: 'wix_code.studio.menu.tools' },
  { type: 'divider', value: 'divider', label: 'divider' },
  {
    type: 'item',
    value: 'help',
    label: 'wix_code.studio.menu.help',
  },
];

export const getEntries = () =>
  experimentUtils.isVeloInStudio() ? entries : liteEntries;
