import { wixCodeLifecycleDuplexer } from '@wix/wix-code-plugin-contracts';
import { APIKeys, EntryPoint } from '@wix/wix-code-repluggable';
import type {
  WixCodeDuplexerEventsToListeners,
  wixCodeLifecycleDuplexer as WixCodeLifecycleDuplexer,
} from '@wix/wix-code-plugin-contracts';

export const WixCodeDuplexerEntryPoint: EntryPoint = {
  name: 'Wix Code Duplexer Entry Point',
  getDependencyAPIs() {
    return [APIKeys.DuplexerAPI, APIKeys.ClassicEditorAPI];
  },
  declareAPIs() {
    return [APIKeys.WixCodeDuplexerAPI];
  },
  attach(shell) {
    const duplexerAPI = shell.getAPI(APIKeys.DuplexerAPI);
    const classicEditorAPI = shell.getAPI(APIKeys.ClassicEditorAPI);
    shell.contributeAPI(APIKeys.WixCodeDuplexerAPI, () => {
      const connection = duplexerAPI.connect(wixCodeLifecycleDuplexer.appDefId);
      const subscription = connection.subscribe(
        wixCodeLifecycleDuplexer.channelName,
      );
      return {
        subscribeTo: {
          codeChanged: (listener) =>
            wrapSubscriber(
              wixCodeLifecycleDuplexer.eventNames.CODE_CHANGED,
              (payload: WixCodeLifecycleDuplexer.CodeChangedEvent) => {
                if (
                  payload.sessionId ===
                  classicEditorAPI.editorAPI.documentServices.wixCode.getWixCodeSessionId()
                ) {
                  return;
                }
                listener(payload);
              },
            ),
          githubStatusChanged: (listener) =>
            wrapSubscriber(
              wixCodeLifecycleDuplexer.eventNames.TEAMWORK_GITHUB_INTEGRATION,
              listener,
            ),
          githubCommits: (listener) =>
            wrapSubscriber(
              wixCodeLifecycleDuplexer.eventNames.GITHUB_COMMITS,
              listener,
            ),
          dependenciesChanged: (listener) =>
            wrapSubscriber(
              wixCodeLifecycleDuplexer.eventNames.DEPENDENCIES_CHANGED,
              listener,
            ),
        },
      };

      function wrapSubscriber<
        eventName extends keyof WixCodeDuplexerEventsToListeners,
      >(
        event: eventName,
        listener: WixCodeDuplexerEventsToListeners[eventName],
      ) {
        subscription.on(event, listener);
        return () => {
          subscription.off(event, listener);
        };
      }
    });
  },
};
