const urlMatcher = (domain: string, { allowAnyPort = false } = {}): RegExp => {
  const domainRegex = domain.replace(/\./g, '\\.');
  const portRegex = allowAnyPort ? '(:\\d{1,5})?' : '';
  const trailingSlashRegex = domain.endsWith('/') ? '' : '/';
  return new RegExp(
    `^(http|https)://${domainRegex}${portRegex}${trailingSlashRegex}`,
  );
};

const DOMAINS_WHITELIST = [urlMatcher('localhost', { allowAnyPort: true })];

export const isUrlAllowed = (url: string): boolean => {
  return DOMAINS_WHITELIST.some((matcher) => matcher.test(url));
};
