// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TwETpe{display:flex;margin-top:3px}.sS7fQC:before{content:\"●\";font-size:14px;color:#116dff;margin-right:8px}.Iz79I9{visibility:hidden}.BVvSDx{margin:0 8px 0 0}.I7X5De{margin-left:5px}.PERzZ2{width:26px}.XMWMQe{width:16px}.label-suffix{margin-left:8px;padding:0px 6px;background-color:#ffedc1;border-radius:2px}.label-suffix .control-label-base.t20{color:#2b5672;font-size:10px;line-height:19px;text-transform:uppercase}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"suffix-wrapper": "TwETpe",
	"suffixWrapper": "TwETpe",
	"update": "sS7fQC",
	"invisible": "Iz79I9",
	"read_only": "BVvSDx",
	"readOnly": "BVvSDx",
	"read-only-link": "I7X5De",
	"readOnlyLink": "I7X5De",
	"symbol-placholder": "PERzZ2",
	"symbolPlacholder": "PERzZ2",
	"context-placholder": "XMWMQe",
	"contextPlacholder": "XMWMQe"
};
export default ___CSS_LOADER_EXPORT___;
