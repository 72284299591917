import * as React from 'react';
import {
  ReadOnlyModeContext,
  TreeContextMenu,
  useTranslation,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import s from '../codeReusePkgTree.scss';
import bi from '@/legacy/bi/bi';
import cx from 'classnames';

export type VELO_TREE_QUICK_ACTIONS =
  | 'UPDATE'
  | 'UNINSTALL'
  | 'HELP'
  | 'README'
  | 'VERSIONS'
  | 'EDIT'
  | 'APP_DETAILS'
  | 'EDIT_IN_BLOCKS';

interface CodeReusePkgSuffixContextMenuProps {
  isContextMenuOpen: boolean;
  handleContextMenuToggle: (isOpen: boolean) => void;
  actionSelectedHandler: (action: VELO_TREE_QUICK_ACTIONS) => void;
  pkgName: string;
  isTreeHovered: boolean;
  canUpdate: boolean;
  isRoot: boolean;
  generateRootContextMenuStructure: AnyFixMe;
}

export const CodeReusePkgSuffixContextMenu: React.FC<
  CodeReusePkgSuffixContextMenuProps
> = ({
  handleContextMenuToggle,
  isContextMenuOpen,
  isTreeHovered,
  pkgName,
  isRoot,
  generateRootContextMenuStructure,
}) => {
  const { readOnlyMode } = React.useContext(ReadOnlyModeContext);
  const { editorAPI } = useEditorLegacyAPIs();
  const [t] = useTranslation();
  const isMenuVisible = isTreeHovered || isContextMenuOpen;

  const onContextMenuClick = (event: React.MouseEvent) => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_PLUS, {
      item_name: pkgName,
      item_type: 'internal_packages',
    });
    event.stopPropagation();
  };

  return !isRoot ? (
    <div className={s.contextPlacholder} />
  ) : (
    <div onClick={onContextMenuClick}>
      <TreeContextMenu
        className={cx({ [s.invisible]: !isMenuVisible })}
        menuClassName="context-menu wix-code-file-tree-dd"
        handleContextMenuToggle={handleContextMenuToggle}
        contextMenuStructure={generateRootContextMenuStructure}
        contextMenuButton="corvid_tree__context_menu_more"
        isContextMenuOpen={isContextMenuOpen}
        tooltipContent={t('Velo_Concurrent_Editing_Sidebar_ReadOnly_Text')}
        readonly={!!readOnlyMode.sidePanel?.packages}
      />
    </div>
  );
};
