import React from 'react';
import {
  LegacyEditorDependencies,
  WixCodeStoreAPI,
  EditorAPI,
  BiLoggerAPI,
  DevContextAPI,
  FilesViewAPI,
} from '@wix/wix-code-plugin-contracts';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';

interface PagesTabContextProps {
  legacyDependenciesAPI: LegacyEditorDependencies;
  wixCodeStoreAPI: WixCodeStoreAPI;
  editorAPI: EditorAPI;
  bi: BiLoggerAPI;
  devContextAPI: DevContextAPI;
  filesViewAPI: FilesViewAPI; // todo: rename package to filesViewAPI
  wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI;
}

export const PagesTabContext = React.createContext<PagesTabContextProps>({
  legacyDependenciesAPI: null,
  wixCodeStoreAPI: null,
  editorAPI: null,
  bi: null,
  devContextAPI: null,
  filesViewAPI: null,
  wixCodeEditorAdapterAPI: null,
} as any);
