import React from 'react';
import { Divider, Preloader, TreeSectionDivider } from '@wix/wix-base-ui';
import { consts } from '@wix/wix-code-consts';
import {
  ContextMenuStructure,
  ReadOnlyModeContext,
  TreeContextMenu,
  useTranslation,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import bi from '@/legacy/bi/bi';
import dataHooks from '../../../dataHooks';
import {
  isSectionExpanded,
  isSectionLoaded,
} from '../../../filesTree/filesTree/treeSectionSelectors';
import {
  STATIC_SECTIONS,
  treeSectionActions,
} from '../../../filesTree/filesTree/treeSectionReducer';
import { PkgTreeContext } from '../PkgTreeContext';
import { getPkgScope } from '@/toExtract/packages/utils';
import { PACKAGE_VIEW_QUICK_ACTIONS } from '../pkgTreeQuickActions';
import { CodeReuseScopeTree } from '../codeReusePkgTree/CodeReuseScopeTree';
import { appDataServiceCreator } from '@/toExtract/packages/appDataService';
import { InstalledCodeReusePkg } from '@/toExtract/packages/packagesModalContext';
import { codeReusePkgsSelectors } from '@/toExtract/packages/packagesView/packagesViewSelectors';
import PlusIcon from '@/assets/icons/contextMenu/plus.svg';
import SettingsIcon from '@/assets/icons/contextMenu/settings.svg';
import PackageIcon from '@/assets/icons/contextMenu/package.svg';
import {
  useInternalAPIs,
  useSelector,
} from '../../packagesTabEntryPoint/contexts';

type ScopedPkgs = {
  name: string;
  installedPkgs: InstalledCodeReusePkg[];
};

const convertToScope = (packages: InstalledCodeReusePkg[]): ScopedPkgs[] => {
  const scopeNames = new Set<string>(packages.map((p) => getPkgScope(p.name)));
  return [...scopeNames].map((scope) => ({
    name: scope,
    installedPkgs: packages.filter((p) => getPkgScope(p.name) === scope),
  }));
};

const contextMenuSections: PACKAGE_VIEW_QUICK_ACTIONS[][] = [
  [
    PACKAGE_VIEW_QUICK_ACTIONS.INSTALL_A_PACKAGE,
    PACKAGE_VIEW_QUICK_ACTIONS.MANAGE_INSTALLED,
  ],
  [PACKAGE_VIEW_QUICK_ACTIONS.CREATE_A_PACKAGE],
];
const privateAppsContextMenuSections: PACKAGE_VIEW_QUICK_ACTIONS[][] = [
  [
    PACKAGE_VIEW_QUICK_ACTIONS.SEE_ALL_PRIVATE_APPS,
    PACKAGE_VIEW_QUICK_ACTIONS.CREATE_NEW_APP,
  ],
];
interface ContextMenuItemDescriptor {
  icon: string | React.FunctionComponent;
  labelKey: string;
  automationId: string;
}

const CONTEXT_MENU_ITEMS: {
  [key in PACKAGE_VIEW_QUICK_ACTIONS]?: ContextMenuItemDescriptor;
} = {
  CREATE_A_PACKAGE: {
    icon: PackageIcon,
    labelKey: 'Left_Tree_Components_Category_CodeFiles_Packages_Menu_Create',
    automationId: 'action-create-package',
  },
  INSTALL_A_PACKAGE: {
    icon: PlusIcon,
    labelKey: 'Left_Tree_Components_Category_CodeFiles_Packages_Menu_Install',
    automationId: 'action-install-a-package',
  },
  MANAGE_INSTALLED: {
    icon: SettingsIcon,
    labelKey: 'Left_Tree_Components_Category_CodeFiles_Packages_Menu_Manage',
    automationId: 'action-manage-installed',
  },
};

const PRIVATE_APP_CONTEXT_MENU_ITEMS: {
  [key in PACKAGE_VIEW_QUICK_ACTIONS]?: ContextMenuItemDescriptor;
} = {
  SEE_ALL_PRIVATE_APPS: {
    icon: PackageIcon,
    labelKey: 'Left_Tree_Components_Category_PrivateApps_Packages_See_All_Apps',
    automationId: 'action-see-all-private-apps',
  },
  CREATE_NEW_APP: {
    icon: PlusIcon,
    labelKey:
      'Left_Tree_Components_Category_PrivateApps_Packages_Create_New_App',
    automationId: 'action-create-new-app',
  },
};

export const CodePackagesTree = () => {
  const [isVeloContextMenuOpen, setVeloContextMenuOpen] = React.useState(false);
  const [isPrivateAppContextMenuOpen, setPrivateAppContextMenuOpen] =
    React.useState(false);
  const { editorAPI } = useEditorLegacyAPIs();
  const {
    wixCodeStoreAPI: { dispatch },
  } = useInternalAPIs();
  const [t] = useTranslation();

  const { actionHandler, packagesService } = React.useContext(PkgTreeContext);
  const { readOnlyMode } = React.useContext(ReadOnlyModeContext);

  const npmExpanded = useSelector((state) =>
    isSectionExpanded(state, STATIC_SECTIONS.NPM_PKGS),
  );
  const veloExpanded = useSelector((state) =>
    isSectionExpanded(state, STATIC_SECTIONS.VELO_PKGS),
  );
  const codeReuseLoaded = useSelector((state) =>
    isSectionLoaded(state, consts.VELO_PKG_SCOPE),
  );
  const npmReadmeLoaded = useSelector((state) =>
    isSectionLoaded(state, STATIC_SECTIONS.NPM_README),
  );
  const installedCodeReusePkgs = useSelector(
    codeReusePkgsSelectors.installed.byVelo,
  );

  const myPackages = useSelector(codeReusePkgsSelectors.installed.myPackages);
  const otherPackages = useSelector(codeReusePkgsSelectors.installed.byOthers);

  const myScopes = convertToScope(myPackages);

  const myScopeName = packagesService.getMyScopeName();
  const otherScopes = convertToScope(otherPackages);

  const loaded = codeReuseLoaded && npmReadmeLoaded;

  const appDataService = appDataServiceCreator();

  const onContextMenuClick = (event: React.MouseEvent) => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_SETTINGS, {
      item_name: 'internal_packages',
    });
    event.stopPropagation();
  };

  const contextMenu = (type: string) =>
    loaded ? (
      <div onClick={onContextMenuClick}>
        <TreeContextMenu
          menuClassName="context-menu wix-code-file-tree-dd"
          handleContextMenuToggle={
            type === 'velo'
              ? setVeloContextMenuOpen
              : setPrivateAppContextMenuOpen
          }
          contextMenuStructure={
            type === 'velo' ? packagesMenuStructure : privateAppsMenuStructure
          }
          contextMenuButton="corvid_tree__context_menu_more"
          isContextMenuOpen={
            type === 'velo'
              ? isVeloContextMenuOpen
              : isPrivateAppContextMenuOpen
          }
          tooltipContent={t('Velo_Concurrent_Editing_Sidebar_ReadOnly_Text')}
          readonly={!!readOnlyMode.sidePanel?.packages}
        />
      </div>
    ) : (
      <div
        data-hook={dataHooks.PKG_LOADER}
        className="preloader-container-hack"
      >
        <Preloader className="tiny" />
      </div>
    );

  const packagesMenuStructure: ContextMenuStructure = {
    seperator: <Divider long={true} />,
    sections: contextMenuSections.map((section) =>
      section.map((item) => {
        const itemDesc = CONTEXT_MENU_ITEMS[item];
        if (!itemDesc) {
          throw new Error(
            `Missing context menu action in CONTEXT_MENU_ITEMS: ${item}`,
          );
        }
        return {
          automationId: itemDesc.automationId,
          icon: itemDesc.icon,
          label: t(itemDesc.labelKey),
          onClick: () => menuActionHandler(item),
        };
      }),
    ),
  };
  const privateAppsMenuStructure: ContextMenuStructure = {
    sections: privateAppsContextMenuSections.map((section) =>
      section.map((item) => {
        const itemDesc = PRIVATE_APP_CONTEXT_MENU_ITEMS[item];
        if (!itemDesc) {
          throw new Error(
            `Missing context menu action in PRIVATE_APP_CONTEXT_MENU_ITEMS: ${item}`,
          );
        }
        return {
          automationId: itemDesc.automationId,
          icon: itemDesc.icon,
          label: t(itemDesc.labelKey),
          onClick: () => menuActionHandler(item),
        };
      }),
    ),
  };

  const menuActionHandler = (action: PACKAGE_VIEW_QUICK_ACTIONS) => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_SETTINGS_ITEM, {
      item_name: 'internal_packages',
      menu_entry_name: action,
    });
    return actionHandler(action);
  };

  const dispatchExpandRoot = (sectionName: STATIC_SECTIONS) =>
    dispatch(treeSectionActions.expandTreeSection({ sectionName }));
  const dispatchCollapseRoot = (sectionName: STATIC_SECTIONS) =>
    dispatch(treeSectionActions.collapseTreeSection({ sectionName }));

  const onVeloRootClick = () => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_SECTION, {
      section_name: 'internal_packages',
      action: veloExpanded ? 'close' : 'open',
    });

    veloExpanded
      ? dispatchCollapseRoot(STATIC_SECTIONS.VELO_PKGS)
      : dispatchExpandRoot(STATIC_SECTIONS.VELO_PKGS);
  };

  return (
    <>
      <div data-hook={dataHooks.VELO_PKGS_ROOT}>
        <TreeSectionDivider
          dataHook={dataHooks.SECTION_DIVIDER}
          label={t('Left_Tree_Components_Category_Packages')}
          collapsed={!veloExpanded}
          onClick={onVeloRootClick}
          alwaysShowSuffix={isVeloContextMenuOpen}
          suffix={contextMenu('velo')}
          size="small"
          hideTopBorder={!npmExpanded}
        />
        {veloExpanded && (
          <>
            <CodeReuseScopeTree
              key={consts.VELO_PKG_SCOPE}
              pkgScope={consts.VELO_PKG_SCOPE}
              installedCodeReusePkgs={installedCodeReusePkgs}
              packagesService={packagesService}
              appDataService={appDataService}
              actionHandler={actionHandler}
            />
            {myScopes.length === 0 ? (
              <CodeReuseScopeTree
                key={myScopeName}
                installedCodeReusePkgs={myPackages}
                packagesService={packagesService}
                appDataService={appDataService}
                actionHandler={actionHandler}
                pkgScope={myScopeName}
                isPrivateScope={true}
              />
            ) : (
              myScopes.map((scope) => (
                <CodeReuseScopeTree
                  key={scope.name}
                  installedCodeReusePkgs={scope.installedPkgs}
                  packagesService={packagesService}
                  appDataService={appDataService}
                  actionHandler={actionHandler}
                  pkgScope={scope.name}
                  isPrivateScope={true}
                />
              ))
            )}
            {otherScopes.map((scope) => (
              <CodeReuseScopeTree
                key={scope.name}
                installedCodeReusePkgs={scope.installedPkgs}
                packagesService={packagesService}
                appDataService={appDataService}
                actionHandler={actionHandler}
                pkgScope={scope.name}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};
