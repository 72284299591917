import { SlotKey } from 'repluggable';
import * as Contracts from '@wix/wix-code-plugin-contracts';

export const PanelsAPI: SlotKey<Contracts.PanelsAPI> = {
  name: 'PanelsAPI',
};

export const ClassicEditorAPI: SlotKey<Contracts.ClassicEditorAPI> = {
  name: 'ClassicEditorAPI',
};

export const EditorContextAPI: SlotKey<Contracts.EditorContextAPI> = {
  name: 'EditorContextAPI',
};

export const ExperimentsAPI: SlotKey<Contracts.ExperimentsAPI> = {
  name: 'ExperimentsAPI',
};

export const FilesTreeAPI: SlotKey<Contracts.FilesTreeAPI> = {
  name: 'FilesTreeAPI',
};

export const LegacyEditorDependencies: SlotKey<Contracts.LegacyEditorDependencies> =
  {
    name: 'LegacyEditorDependencies',
  };

export const SidebarAPI: SlotKey<Contracts.SidebarAPI> = {
  name: 'SidebarAPI',
};

export const WixCodeAppAPI: SlotKey<Contracts.WixCodeAppAPI> = {
  name: 'WixCodeAppAPI',
};

export const WixCodeStoreAPI: SlotKey<Contracts.WixCodeStoreAPI> = {
  name: 'WixCodeStoreAPI',
};
export const SiteAPI: SlotKey<Contracts.SiteAPI> = {
  name: 'SiteAPI',
};

export const UserPreferencesAPI: SlotKey<Contracts.UserPreferencesAPI> = {
  name: 'UserPreferencesAPI',
};

export const DuplexerAPI: SlotKey<Contracts.DuplexerAPI> = {
  name: 'DuplexerAPI',
};

export const FileSystemAPI: SlotKey<Contracts.FileSystemAPI> = {
  name: 'FileSystemAPI',
};

export const ServiceTopology: SlotKey<Contracts.ServiceTopology> = {
  name: 'ServiceTopology',
};

export const CodeEditorAPI: SlotKey<Contracts.CodeEditorAPI> = {
  name: 'Code Editor',
};

export const ReadOnlyAPI: SlotKey<Contracts.ReadOnlyAPI> = {
  name: 'Read Only',
};

export const BiLoggerAPI: SlotKey<Contracts.BiLoggerAPI> = {
  name: 'Bi Logger',
};

export const PlatformAppsAPI: SlotKey<Contracts.PlatformAppsAPI> = {
  name: 'PlatformAppsAPI',
};

export const CollectionsAPI: SlotKey<Contracts.CollectionsAPI> = {
  name: 'CollectionsAPI',
};

export const LocalEditorPublicAPI: SlotKey<Contracts.LocalEditorPublicAPI> = {
  name: 'LocalEditorPublicAPI',
};

export const FilesViewAPI: SlotKey<Contracts.FilesViewAPI> = {
  name: 'files view API',
};

export const ConcurrentEditingAPI: SlotKey<Contracts.ConcurrentEditingAPI> = {
  name: 'concurrent editing API',
};

export const DevContextAPI: SlotKey<Contracts.DevContextAPI> = {
  name: 'DevContextAPI',
};

export const WixCodeDuplexerAPI: SlotKey<Contracts.WixCodeDuplexerAPI> = {
  name: 'WixCodeDuplexerAPI',
};

export const PackagesAndAppsAPI: SlotKey<Contracts.PackagesAndAppsAPI> = {
  name: 'PackagesAndAppsAPI',
};

export const VSCodePublicAPI: SlotKey<Contracts.VSCodePublicAPI> = {
  name: 'VSCodePublicAPI',
};

export const PagesTreeAPI: SlotKey<Contracts.PagesTreeAPI> = {
  name: 'PagesTreeAPI',
};
export const PropertiesPanelAPI: SlotKey<Contracts.PropertiesPanelAPI> = {
  name: 'PropertiesPanelAPI',
};

export const GithubPublicAPI: SlotKey<Contracts.GithubPublicAPI> = {
  name: 'GithubPublicAPI',
};

export const AiAssistancePublicAPI: SlotKey<Contracts.AiAssistancePublicAPI> = {
  name: 'AiAssistancePublicAPI',
};

export const PackagesTabAPI: SlotKey<Contracts.PackagesTabAPI> = {
  name: 'PackagesTabAPI',
};

export const MenuAPI: SlotKey<Contracts.MenuAPI> = {
  name: 'MenuAPI',
};

export const MenuPanelAPI: SlotKey<Contracts.MenuPanelAPI> = {
  name: 'MenuPanelAPI',
};

export const LitePanelAPI: SlotKey<Contracts.LitePanelAPI> = {
  name: 'LitePanelAPI',
};

export const EditorComponentsAPI: SlotKey<Contracts.EditorComponentsAPI> = {
  name: 'EditorComponentsAPI',
};
