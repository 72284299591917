export const bi = {
  origins: {
    IDE_TREE: 'ideTree',
    LEFT_TREE: 'left_tree',
  },
  sectionNames: {
    DATABASE: 'database',
    DATABASE_GROUP: 'database_group',
  },
  actions: {
    OPEN: 'open',
    CLOSE: 'close',
    CLICK: 'click',
    HOVER: 'hover',
    SHOW: 'show',
  },
  subSections: {
    MY_COLLECTIONS: 'my_collections',
    EXTERNAL_DATABASES: 'external_databases',
    WIX_APP_DATABASES: 'wix_app_databases',
  },
  itemTypes: {
    EXTERNAL_COLLECTION: 'external_collection',
    COLLECTION: 'collection',
    FIELD: 'field',
  },
  menuItems: {
    externalCollectionLearnMore: 'learn_more_click_external_collection',
    openCollection: 'openCollectionClicked',
  },
};
