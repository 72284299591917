import React from 'react';
import { DisconnectModalBase } from '../DisconnectModal/DisconnectModalBase';
import { useTranslation } from '@wix/wix-i18n-config';
import dataHooks from '../dataHooks';
import { DisconnectModalProps } from '../DisconnectModal/DisconnectModal';
import { ON_BOARDING_SUPPORTED_ERRORS } from '../../constants';

export const RepositoryNotFoundModal = ({
  closeModal,
  githubAPI,
}: DisconnectModalProps) => {
  const [t] = useTranslation();

  return (
    <DisconnectModalBase
      errorType={ON_BOARDING_SUPPORTED_ERRORS.REPOSITORY_NOT_FOUND}
      dataHook={dataHooks.githubConnectionIssuesModal.repositoryNotFoundModal}
      closeModal={closeModal}
      githubAPI={githubAPI}
      title={t('githubIntegration.modal.repo_not_found.title')}
      helpLink={t('githubIntegration.modal.repo_not_found.learn_more.link')}
      content={t('githubIntegration.modal.repo_not_found.description')}
      primaryButtonText={t(
        'githubIntegration.modal.repo_not_found.primary_cta',
      )}
    />
  );
};
