import React from 'react';
import { PanelHeader } from '@wix/wix-base-ui';
import { useBiLogger, useTranslation } from '@wix/wix-code-common-components';
import { ResourceSection } from '../resourcesSection/resourcesSection';
import { leftTreeClickOnAnItem } from '@wix/bi-logger-platform/v2';
import { PanelsAPI } from '@wix/wix-code-plugin-contracts';

type ResourcesTabProps = {
  closePanel: () => void;
  panelsAPI: PanelsAPI;
};

export const ResourcesTab: React.FC<ResourcesTabProps> = ({
  closePanel,
  panelsAPI,
}) => {
  const [t] = useTranslation();
  const biLoggerAPI = useBiLogger();

  const onOpenHelp = () => {
    panelsAPI.openHelpCenter('8e5e8989-ab2b-4d69-8de8-fcb67e6e6d5f');
  };

  const resourceClickBI = (item_name: string) => {
    biLoggerAPI.report(
      leftTreeClickOnAnItem({
        item_type: 'resource',
        item_name,
      }),
    );
  };

  return (
    <div>
      <PanelHeader
        onHelp={onOpenHelp}
        onClose={closePanel}
        className="dragger dark"
        defaultCursor
      >
        {t('lite_panel.menu.help')}
      </PanelHeader>
      <ResourceSection
        label={t('lite_panel.resources_tab.documentation_label')}
        resources={[
          {
            label: t(
              'lite_panel.resources_tab.documentation.api_reference_label',
            ),
            description: t(
              'lite_panel.resources_tab.documentation.api_reference_description',
            ),
            onClick: () => {
              window.open(
                t('lite_panel.resources_tab.documentation.api_reference_link'),
              );
              resourceClickBI('APIReference');
            },
          },
          {
            label: t(
              'lite_panel.resources_tab.documentation.help_center_label',
            ),
            description: t(
              'lite_panel.resources_tab.documentation.help_center_description',
            ),
            onClick: () => {
              window.open(
                t('lite_panel.resources_tab.documentation.help_center_link'),
              );
              resourceClickBI('HelpCenter');
            },
          },
        ]}
      />
      <ResourceSection
        label={t('lite_panel.resources_tab.examples_label')}
        resources={[
          {
            label: t('lite_panel.resources_tab.examples.video_tutorials_label'),
            description: t(
              'lite_panel.resources_tab.examples.video_tutorials_description',
            ),
            onClick: () => {
              window.open(
                t('lite_panel.resources_tab.examples.video_tutorials_link'),
              );
              resourceClickBI('videoTutorials');
            },
          },
          {
            label: t('lite_panel.resources_tab.examples.code_examples_label'),
            description: t(
              'lite_panel.resources_tab.examples.code_examples_description',
            ),
            onClick: () => {
              window.open(
                t('lite_panel.resources_tab.examples.code_examples_link'),
              );
              resourceClickBI('CodeExamples');
            },
          },
        ]}
      />
      <ResourceSection
        label={t('lite_panel.resources_tab.community_label')}
        resources={[
          {
            label: t('lite_panel.resources_tab.community.discord_label'),
            description: t(
              'lite_panel.resources_tab.community.discord_description',
            ),
            onClick: () => {
              window.open(t('lite_panel.resources_tab.community.discord_link'));
              resourceClickBI('Discord');
            },
          },
          {
            label: t('lite_panel.resources_tab.community.forum_label'),
            description: t(
              'lite_panel.resources_tab.community.forum_description',
            ),
            onClick: () => {
              window.open(t('lite_panel.resources_tab.community.forum_link'));
              resourceClickBI('Forum');
            },
          },
          {
            label: t(
              'lite_panel.resources_tab.community.hire_a_developer_label',
            ),
            description: t(
              'lite_panel.resources_tab.community.hire_a_developer_description',
            ),
            onClick: () => {
              window.open(
                t('lite_panel.resources_tab.community.hire_a_developer_link'),
              );
              resourceClickBI('HireDeveloper');
            },
          },
        ]}
      />
    </div>
  );
};
