import React from 'react';
import { Database } from '@wix/wix-ui-icons-common/classic-editor';
import { APIs, TabContext } from '../tabContext';
import {
  CollectionsTreeContainerComp,
  CollectionsTreeStateProps,
} from '../CollectionsTreeContainer/CollectionsTreeContainer';
import { connectWithShell, Shell } from '@wix/wix-code-repluggable';
import { CollectionsEvent, TabProps } from '@wix/wix-code-plugin-contracts';
import { CollectionsStore } from '../../entryPoint';
import { PredefinedSidebarTabs } from '@wix/wix-code-plugin-contracts/src';
import styles from './CollectionsTab.scss';
import { dataHooks } from '../../dataHooks';
import {
  DoNotTranslateBaseUiComponents,
  TranslationProvider,
} from '@wix/wix-code-common-components';

export const sideBarContent = (
  apis: APIs,
  shell: Shell,
  tabProps?: TabProps[PredefinedSidebarTabs.COLLECTIONS],
) => {
  const CollectionsTreeContainer = connectWithShell<
    CollectionsStore,
    TabProps[PredefinedSidebarTabs.COLLECTIONS],
    CollectionsTreeStateProps
  >(
    (_boundedShell: Shell, state: CollectionsStore) => {
      return {
        createCollectionInterceptors:
          state.interceptors[CollectionsEvent.CREATE_COLLECTION] || [],
      };
    },
    () => ({}),
    shell,
    {
      renderOutsideProvider: true,
      allowOutOfEntryPoint: true,
    },
  )(CollectionsTreeContainerComp);
  return {
    symbol: (
      <div className={styles.tabIcon} data-hook={dataHooks.DB_TAB_ICON}>
        <Database />
      </div>
    ),
    creator: () => (
      <TabContext.Provider value={{ ...apis, ...tabProps }}>
        <TranslationProvider
          options={{
            locale: apis.legacyEditorDependencies.util.editorModel.languageCode,
            disableAutoInit: true,
            asyncMessagesLoader: (locale: string) =>
              import(`../../assets/locale/messages_${locale}.json`),
          }}
        >
          <DoNotTranslateBaseUiComponents>
            <CollectionsTreeContainer />
          </DoNotTranslateBaseUiComponents>
        </TranslationProvider>
      </TabContext.Provider>
    ),
  };
};
