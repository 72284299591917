import * as React from 'react';
import { TreeListItem } from '@wix/wix-base-ui';
import dataHooks from '../../../dataHooks';
import { InstalledCodeReusePkg } from '@/toExtract/packages/packagesModalContext';
import { PackagesService } from '@/toExtract/packages/packagesService';
import {
  isSectionExpanded,
  isSectionLoaded,
} from '../../../filesTree/filesTree/treeSectionSelectors';
import _ from 'lodash';
import { AppDataService } from '@/toExtract/packages/appDataService';
import s from './codeReusePkgTree.scss';
import bi from '@/legacy/bi/bi';
import { PACKAGE_VIEW_QUICK_ACTIONS } from '../pkgTreeQuickActions';
import { consts } from '@wix/wix-code-consts';
import { CodeReuseScopeFolderIcon } from './CodeReuseScopeFolderIcon';
import { CodeReuseScopeEmptyState } from './CodeReuseScopeEmptyState';
import { CodeReuseScopeTreeExpanded } from './CodeReuseScopeTreeExpanded';
import { CodeReuseScopeSuffix } from './CodeReuseScopeSuffix';
import { treeSectionActions } from '../../../filesTree/filesTree/treeSectionReducer';
import {
  useInternalAPIs,
  useSelector,
} from '../../packagesTabEntryPoint/contexts';
import { useEditorLegacyAPIs } from '@wix/wix-code-common-components';
import { experimentUtils } from '@wix/wix-code-common';

interface CodeReuseScopeTreeProps {
  installedCodeReusePkgs: InstalledCodeReusePkg[];
  packagesService: PackagesService;
  appDataService: AppDataService;
  actionHandler: (
    action: PACKAGE_VIEW_QUICK_ACTIONS,
    packageName?: string,
  ) => void;
  pkgScope: string;
  isPrivateScope?: boolean;
  isByOthers?: boolean;
}

export const CodeReuseScopeTree: React.FC<CodeReuseScopeTreeProps> = ({
  installedCodeReusePkgs,
  actionHandler,
  packagesService,
  appDataService,
  pkgScope,
  isPrivateScope = false,
  isByOthers = false,
}) => {
  const {
    editorAPI,
    legacyDependenciesAPI: { experiment },
  } = useEditorLegacyAPIs();
  const { wixCodeStoreAPI } = useInternalAPIs();
  const isBlocksCombinedMode = experiment.isOpen('se_privateAppsPanel');
  const isVeloLite = experimentUtils.isLiteEditor();

  const [isContextMenuOpen, setContextMenuOpen] = React.useState(false);
  const [isTreeHovered, setTreeHovered] = React.useState(false);
  const pkgScopeSectionExpanded = useSelector((state) =>
    isSectionExpanded(state, pkgScope),
  );
  const isPrivateAppsSection = isPrivateScope || isByOthers;
  const pkgScopeSectionLoaded = useSelector((state) => {
    if (isBlocksCombinedMode && isPrivateAppsSection) {
      return true;
    }
    return isSectionLoaded(state, pkgScope);
  });
  const { dispatch } = wixCodeStoreAPI;
  const dispatchExpandCodeReuse = () =>
    dispatch(treeSectionActions.expandTreeSection({ sectionName: pkgScope }));
  const collapseExpandCodeReuse = () =>
    dispatch(treeSectionActions.collapseTreeSection({ sectionName: pkgScope }));

  const toggleExpanded = () => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_SECTION, {
      section_name: 'internal_packages',
      action: pkgScopeSectionExpanded ? 'close' : 'open',
    });

    pkgScopeSectionExpanded
      ? collapseExpandCodeReuse()
      : dispatchExpandCodeReuse();
  };

  const shouldShowTree: boolean =
    pkgScopeSectionLoaded &&
    pkgScopeSectionExpanded &&
    !_.isEmpty(installedCodeReusePkgs);

  const shouldShowEmptyState: boolean =
    pkgScopeSectionLoaded &&
    pkgScopeSectionExpanded &&
    _.isEmpty(installedCodeReusePkgs);

  const shouldHaveEmptyPadding: boolean =
    pkgScopeSectionLoaded && _.isEmpty(installedCodeReusePkgs);

  const isVeloScope = pkgScope === consts.VELO_PKG_SCOPE;

  const isNoActionOnScope = !isPrivateScope && !isVeloScope;
  const alwaysShowSuffix =
    !isNoActionOnScope && (isContextMenuOpen || isTreeHovered);
  const nameWithNoAt = pkgScope.substring(1);

  return (
    <div
      data-aid={dataHooks.VELO_SECTION}
      data-hook={dataHooks.CODE_REUSE_TREE_SCOPE_ROOT(nameWithNoAt)}
      onMouseEnter={() => setTreeHovered(true)}
      onMouseLeave={() => setTreeHovered(false)}
    >
      {!isVeloLite && (
        <TreeListItem
          className={shouldHaveEmptyPadding ? s.emptyTree : null}
          dataHook={dataHooks.TREE_NODE}
          label={pkgScope}
          shouldTranslate={false}
          collapseState={pkgScopeSectionExpanded ? 'expanded' : 'collapsed'}
          icon={<CodeReuseScopeFolderIcon opened={pkgScopeSectionExpanded} />}
          size="small"
          onClick={toggleExpanded}
          alwaysShowSuffix={alwaysShowSuffix}
          highlight={isContextMenuOpen}
          suffix={
            <CodeReuseScopeSuffix
              actionHandler={actionHandler}
              pkgScopeSectionLoaded={pkgScopeSectionLoaded}
              isContextMenuOpen={isContextMenuOpen}
              setContextMenuOpen={setContextMenuOpen}
              setTreeHovered={setTreeHovered}
              pkgScope={pkgScope}
              isPrivateScope={isPrivateScope}
            />
          }
        />
      )}
      {shouldShowTree && (
        <CodeReuseScopeTreeExpanded
          installedCodeReusePkgs={installedCodeReusePkgs}
          packagesService={packagesService}
          appDataService={appDataService}
          actionHandler={actionHandler}
          isPrivateScope={isPrivateScope}
          isByOthers={isByOthers}
        />
      )}
      {shouldShowEmptyState && (
        <CodeReuseScopeEmptyState
          isPrivateScope={isPrivateScope}
          actionHandler={actionHandler}
        />
      )}
    </div>
  );
};
