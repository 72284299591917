import React, { useState } from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { dataHooks } from '../../consts/dataHooks';
import { Text, TextButton, TreeSectionDivider } from '@wix/wix-base-ui';
import { SectionDividerSuffix } from '../SectionDividerSuffix/SectionDividerSuffix';
import s from './SpiExternalIdeOnboarding.scss';

interface SpiInstructionsProps {
  amountOfSpis: number;
  openWixIDE: () => void;
}

export const SpiExternalIdeOnboarding: React.FC<SpiInstructionsProps> = ({
  amountOfSpis,
  openWixIDE,
}) => {
  const [t] = useTranslation();
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);

  return (
    <div
      onMouseEnter={() => {
        setShowContextMenu(true);
      }}
      onMouseLeave={() => {
        setShowContextMenu(false);
      }}
      data-hook={dataHooks.LEFT_TREE_SPI_SECTION_EMPTY_STATE}
    >
      <TreeSectionDivider
        dataHook={dataHooks.SECTION_DIVIDER}
        alwaysShowSuffix={showContextMenu}
        shouldTranslate={false}
        label={t('Left_Tree_Section_Label')}
        collapsed={false}
        suffix={<SectionDividerSuffix />}
        size="small"
      />
      <div className={s.container}>
        <div className={s.title}>
          <Text shouldTranslate={false} size="small" skin="secondary">
            {t('SPI_Installed_Indication', { amount: amountOfSpis })}
          </Text>
        </div>
        <Text shouldTranslate={false} size="small" skin="secondary">
          {t('SPI_Installed_Instructions')}
        </Text>
        <div className={s.instructions}>
          <Text shouldTranslate={false} size="small" skin="secondary">
            &#8226;{t('SPI_Installed_Instructions_Step_1')}
            <TextButton
              shouldTranslate={false}
              size="small"
              skin="standard"
              onClick={() => openWixIDE()}
            >
              {t('SPI_Installed_Instructions_Step_1_Link')}
            </TextButton>
          </Text>
          <Text shouldTranslate={false} size="small" skin="secondary">
            &#8226;{t('SPI_Installed_Instructions_Step_2')}
          </Text>
        </div>
      </div>
    </div>
  );
};
