/* eslint react/prop-types:2 */
import React, { Fragment } from 'react';
import './developerToolBarContent.global.scss';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import mapperCreator from './developerToolBarContentMapper';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import eventsBindingCreator from '../eventsBinding/eventsBinding';
import baseUILib from '@wix/wix-base-ui';
import renderWhenMutatedByPropTypes from '@/utils/renderWhenMutatedByPropTypes';

const propTypes = {
  componentName: PropTypes.string,
  editedComponent: PropTypes.object,
  innerComponent: PropTypes.object,
  eventList: PropTypes.arrayOf(PropTypes.object),
  getNameInvalidMessage: PropTypes.func,
  isComponentCollapsed: PropTypes.bool,
  isComponentCollapsible: PropTypes.bool,
  isComponentHiddenable: PropTypes.bool,
  isComponentDisableable: PropTypes.bool,
  isComponentEnabled: PropTypes.bool,
  isComponentVisible: PropTypes.bool,
  isReadOnlyEvents: PropTypes.bool,
  isGroup: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  onNameChange: PropTypes.func,
  onNameFocus: PropTypes.func,
  onInnerNameFocus: PropTypes.func,
  onInnerNameChange: PropTypes.func,
  innerComponentName: PropTypes.string,
  validateInnerName: PropTypes.func,
  disableLinkToCode: PropTypes.bool,
  getInnerNameInvalidMessage: PropTypes.func,
  savedBehaviors: PropTypes.arrayOf(PropTypes.object),
  toggleComponentCollapsiblity: PropTypes.func,
  toggleComponentEnability: PropTypes.func,
  toggleComponentVisibility: PropTypes.func,
  validateName: PropTypes.func,
  wixCodeLoaded: PropTypes.bool.isRequired,
  isSectionProperties: PropTypes.bool.isRequired,
  showOnlySavedEvents: PropTypes.bool,
  shouldShowEvents: PropTypes.bool,
};

const developerToolBarContent = _.once(
  ({
    baseUI,
    constants,
    core,
    experiment,
    helpIds,
    platform,
    stateManagement,
    util,
  }) => {
    const EventsBinding = eventsBindingCreator({
      baseUI,
      constants,
      core,
      experiment,
      helpIds,
      platform,
      stateManagement,
      util,
    });
    const mapper = mapperCreator({
      constants,
      experiment,
      platform,
      stateManagement,
      util,
    });
    const {
      connect,
      STORES: { EDITOR_API },
    } = util.hoc;

    const DeveloperToolBarContent = createReactClass({
      displayName: 'developerToolBarContent',
      propTypes,

      shouldShowDivider() {
        return (
          !experiment.isOpen('se_propertiesAsSection') &&
          !this.props.isSectionProperties
        );
      },

      shouldRenderEventsBindingComp() {
        if (!this.props.wixCodeLoaded) {
          return false;
        }
        if (
          this.props.showOnlySavedEvents &&
          !_.get(this.props.savedBehaviors, 'length', 0)
        ) {
          return false;
        }
        if (!this.props.shouldShowEvents) {
          return false;
        }
        const isEventListEmpty =
          !this.props.eventList || !this.props.eventList.length;
        return !(this.props.isGroup || isEventListEmpty);
      },

      hasNickname() {
        return !_.isNil(this.props.componentName);
      },

      render() {
        return (
          <div
            className="developer-toolbar-content"
            data-aid="developer-toolbar-content"
          >
            {!this.props.editedComponent && (
              <div key="emptyStatePanel" ref="emptyStatePanel">
                <div
                  className="rich-text-paragraph inner form"
                  key="emptyStateText"
                >
                  <p className="empty-state-text">
                    {util.translate(
                      'WixCode_PropertiesPanel_EmptyState_Description',
                    )}
                  </p>
                </div>
                <Symbol
                  className="empty-state-symbol"
                  name="emptyPropertiesPanel"
                />
              </div>
            )}

            {this.props.editedComponent && !this.hasNickname() && (
              <div
                key="noNicknamePanel"
                className="no-nickname-panel"
                data-aid="no-nickname-panel"
              >
                <div
                  className="rich-text-paragraph inner form"
                  key="noNicknameText"
                >
                  <p className="empty-state-text">
                    {util.translate(
                      'WixCode_PropertiesPanel_empty_state_no_edit',
                    )}
                  </p>
                </div>
                <Symbol
                  className="empty-state-symbol"
                  name="collectionsManagerEmptyState"
                />
              </div>
            )}

            {this.hasNickname() && (
              <Fragment>
                {this.props.editedComponent && (
                  <div className="inner form" key="controlsContainer">
                    <div className="nickname-input">
                      <span className="nickname-label">{`${util.translate(
                        'WixCode_PropertiesPanel_ID_Label',
                      )}:`}</span>
                      <baseUI.textInput
                        data-aid="nickname"
                        disabled={this.props.isReadOnly}
                        ref="nameChangeInput"
                        onFocus={this.props.onNameFocus}
                        onChange={this.props.onNameChange}
                        value={this.props.componentName}
                        validator={this.props.validateName}
                        shouldTranslate={false}
                        invalidMessage={this.props.getNameInvalidMessage}
                      ></baseUI.textInput>
                    </div>
                    {this.props.innerComponent && (
                      <div className="nickname-input">
                        <span className="nickname-label">{`${util.translate(
                          'WixCode_PropertiesPanel_ID_State_Label',
                        )}:`}</span>
                        <baseUI.textInput
                          data-aid="inner-nickname"
                          disabled={this.props.isReadOnly}
                          ref="innerNameChangeInput"
                          onFocus={this.props.onInnerNameFocus}
                          onChange={this.props.onInnerNameChange}
                          value={this.props.innerComponentName}
                          shouldTranslate={false}
                          validator={this.props.validateInnerName}
                          invalidMessage={this.props.getInnerNameInvalidMessage}
                        ></baseUI.textInput>
                      </div>
                    )}
                    {this.props.isComponentHiddenable && (
                      <div className="checkBoxContainer" key="visibility">
                        <baseUILib.Checkbox
                          automationId="visibility"
                          dataHook="visibility"
                          label={util.translate(
                            'WixCode_PropertiesPanel_Visibility_Label',
                          )}
                          labelAfterSymbol={true}
                          value={!this.props.isComponentVisible}
                          onChange={this.props.toggleComponentVisibility}
                          disabled={this.props.isReadOnly}
                          ref="visibilityChangeInput"
                        />
                      </div>
                    )}

                    {this.props.isComponentCollapsible && (
                      <div className="checkBoxContainer" key="collapsiblity">
                        <baseUILib.Checkbox
                          automationId="collapsiblity"
                          dataHook="collapsiblity"
                          label={util.translate(
                            'WixCode_PropertiesPanel_Collapse_Label',
                          )}
                          labelAfterSymbol={true}
                          value={this.props.isComponentCollapsed}
                          onChange={this.props.toggleComponentCollapsiblity}
                          disabled={this.props.isReadOnly}
                          ref="collapsiblityChangeInput"
                        />
                      </div>
                    )}
                    {this.props.isComponentDisableable && (
                      <div className="checkBoxContainer" key="enability">
                        <baseUILib.Checkbox
                          automationId="enability"
                          dataHook="enability"
                          label={util.translate(
                            'WixCode_PropertiesPanel_Enabled_Label',
                          )}
                          labelAfterSymbol={true}
                          onChange={this.props.toggleComponentEnability}
                          value={this.props.isComponentEnabled}
                          disabled={this.props.isReadOnly}
                          ref="enabilityChangeInput"
                        />
                      </div>
                    )}
                  </div>
                )}
                {this.shouldRenderEventsBindingComp() && (
                  <EventsBinding
                    key="events"
                    ref="eventsBinding"
                    isReadOnly={this.props.isReadOnlyEvents}
                    showOnlySavedEvents={this.props.showOnlySavedEvents}
                    disableLinkToCode={this.props.disableLinkToCode}
                    eventList={this.props.eventList}
                    editedComponent={this.props.editedComponent}
                    savedBehaviors={this.props.savedBehaviors}
                  ></EventsBinding>
                )}
              </Fragment>
            )}
          </div>
        );
      },
    });

    const Connected = _.flowRight(
      connect(EDITOR_API, mapper.mapStateToProps, mapper.mapDispatchToProps),
    )(renderWhenMutatedByPropTypes(DeveloperToolBarContent, _.keys(propTypes)));

    return Connected;
  },
);
export default developerToolBarContent;
