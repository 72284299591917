import React, { FC, useEffect } from 'react';
import feedbackCreator from './feedback';
import { helpLinkClick } from './helpTabActions';
import dealerStateReader from '@/infra/dealer/dealerStateReader';
import devContextUtilsCreator from '@/utils/devContext';
import { HelpTabRoot } from './HelpTabRoot';
import { dealerService } from '@/infra/dealer/dealerService';
import { RealEstateIds } from '@/infra/dealer/config';
import { useTranslate } from '@/infra/i18n/useTranslate';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import { experimentUtils } from '@wix/wix-code-common';
import { useBiLogger } from '@/infra/wixCodeContext/BiLoggerAPIContext';
import { leftTreeClickOnAnItem } from '@wix/bi-logger-platform/v2';
import {
  useEditorLegacyAPIs,
  connectToStores,
} from '@wix/wix-code-common-components';

interface HelpTabEditorReduxStateProps {
  editorAPI: EditorAPI;
}

interface HelpTabReduxStateProps {
  helpArticleDealerData: any;
}

interface HelpTabReduxReduceProps {
  linkClick: (id: string, linkData: string) => void;
}

type HelpTabCompProps = HelpTabEditorReduxStateProps &
  HelpTabReduxStateProps &
  HelpTabReduxReduceProps;

const HelpTabComp: FC<HelpTabCompProps> = ({
  editorAPI,
  helpArticleDealerData,
  linkClick,
}: HelpTabCompProps) => {
  const links = experimentUtils.isRearrangeHelpTabResources()
    ? [
        {
          biItemName: 'API Reference',
          url: 'WixCode_Exposure_Link_API_Reference',
          label: 'WixCode_TreeView_FileTree_QuickActions_API_Reference',
        },
        {
          biItemName: 'HelpCenter',
          url: 'WixCode_TreeView_FileTree_QuickActions_HelpCenter_URL',
          label: 'WixCode_TreeView_FileTree_QuickActions_HelpCenter',
        },
        {
          biItemName: 'VideoTutorial',
          url: 'WixCode_TreeView_FileTree_QuickActions_VideoTutorial_URL',
          label: 'WixCode_TreeView_FileTree_QuickActions_VideoTutorial',
        },
        {
          biItemName: 'CodeExamples',
          url: 'WixCode_TreeView_FileTree_QuickActions_CodeExamples_URL',
          label: 'WixCode_TreeView_FileTree_QuickActions_CodeExamples',
        },
      ]
    : [
        {
          biItemName: 'API Reference',
          url: 'WixCode_Exposure_Link_API_Reference',
          label: 'WixCode_TreeView_FileTree_QuickActions_API_Reference',
        },
        {
          biItemName: 'Forum',
          url: 'WixCode_Exposure_Link_Forum',
          label: 'WixCode_TreeView_FileTree_QuickActions_Forum',
        },
        {
          biItemName: 'Documentation',
          url: 'WixCode_Exposure_Link_Resources',
          label: 'WixCode_TreeView_FileTree_QuickActions_Documentation',
        },
        {
          biItemName: 'HelpCenter',
          url: 'WixCode_TreeView_FileTree_QuickActions_HelpCenter_URL',
          label: 'WixCode_TreeView_FileTree_QuickActions_HelpCenter',
        },
      ];
  const {
    legacyDependenciesAPI: { constants },
  } = useEditorLegacyAPIs();
  const { setEmbeddedTabContext } = devContextUtilsCreator({
    constants,
  });
  const { t } = useTranslate();
  const biLogger = useBiLogger();

  let feedback = null as any;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    feedback = feedbackCreator(editorAPI, {
      initiator: 'corvid',
      origin: 'corvid',
    });
    feedback.preload();
    helpArticleDealerData &&
      dealerService.reportDealerOfferViewed({
        realEstateId: RealEstateIds.HELP_ARTICLE_TAB_REAL_ESTATE_ID,
        offerId: helpArticleDealerData.offerGuid,
      });

    return function cleanUp() {
      feedback.destroy();
    };
  });

  const giveFeedback = () => {
    biLogger.report(
      leftTreeClickOnAnItem({
        item_name: 'Give Feedback',
        item_type: 'updates',
      }),
    );
    feedback.showQuestion(t, 'left-pane-click');
  };

  const helpArticle = helpArticleDealerData && {
    biItemName: helpArticleDealerData.biItemName,
    linkName: helpArticleDealerData.linkName,
    symbolName: helpArticleDealerData.symbolName || 'corvid_tree__whats_new',
    onClick: () => {
      linkClick(
        RealEstateIds.HELP_ARTICLE_TAB_REAL_ESTATE_ID,
        helpArticleDealerData,
      );
      setEmbeddedTabContext(editorAPI, {
        url: helpArticleDealerData.url,
      });
      dealerService.reportDealerOfferClicked({
        realEstateId: RealEstateIds.HELP_ARTICLE_TAB_REAL_ESTATE_ID,
        offerId: helpArticleDealerData.offerGuid,
      });
    },
  };
  return (
    <HelpTabRoot
      helpArticle={helpArticle}
      links={links.map((l) => ({
        ...l,
        onClickLinkHandler: () => {
          biLogger.report(
            leftTreeClickOnAnItem({
              item_name: l.biItemName,
              item_type: 'resource',
            }),
          );
          window.open(t(l.url), '_blank');
        },
      }))}
      openFeedbackForm={giveFeedback}
    />
  );
};

const mapCodeStateToProps = (state: AnyFixMe): HelpTabReduxStateProps => {
  const helpArticleDealerData = dealerStateReader.getDealerData(
    state,
    RealEstateIds.HELP_ARTICLE_TAB_REAL_ESTATE_ID,
  );

  return {
    helpArticleDealerData,
  };
};

const mapCodeDispatchToProps = (
  dispatch: AnyFixMe,
): HelpTabReduxReduceProps => {
  const linkClick = (id: AnyFixMe, linkData: AnyFixMe) =>
    dispatch(helpLinkClick(id, linkData));

  return {
    linkClick,
  };
};

let ConnectedComponent = null as any;
export const HelpTab = (origProps: AnyFixMe) => {
  const {
    legacyDependenciesAPI: { util },
  } = useEditorLegacyAPIs();
  if (ConnectedComponent === null) {
    ConnectedComponent = connectToStores({
      util,
      mapEditorStateToProps: ({ editorAPI }): HelpTabEditorReduxStateProps => ({
        editorAPI,
      }),
      mapCodeStateToProps,
      mapCodeDispatchToProps,
      comp: HelpTabComp,
    });
  }
  return <ConnectedComponent {...origProps} />;
};
