import * as _ from 'lodash';
import { NpmServiceFacade } from './npmServiceFacade';
import { npmPackageFormaterCreator } from './packageFormatter';

type PackageDetails = {
  description: string;
  id: string;
  license: string;
  name: string;
  tags: string[];
  version: string;
  versions: string[];
  _highlights: { description: string[]; name: string[] };
  _score: number;
  _updated: string;
};
export default ({
  installedVersions,
  limitPerPage: limit = undefined,
  memoize = true,
  editorAPI,
}: AnyFixMe) => {
  const npmPackageFormatter = npmPackageFormaterCreator({
    installedVersions,
  });

  const npmServiceFacade = NpmServiceFacade({
    editorAPI,
  });

  const memoizedIndexSearch: AnyFixMe = {};
  let packagesInfoCache: AnyFixMe = {};

  const formatPkg = (pkg: AnyFixMe) => npmPackageFormatter.format(pkg);

  const isPackageValid = (pkg: AnyFixMe) =>
    _.get(pkg, 'versions', []).length > 0;

  // Wix Search not allways returns the exact match as the first record.
  // This ensures the exact match will go to the top
  const ensureExactMatchAtTheTop = (
    packageDetails: PackageDetails[],
    keyword?: string,
  ) => {
    if (keyword) {
      const exactMatchIndex = packageDetails.findIndex(
        (packageDetailsObj: PackageDetails) =>
          packageDetailsObj.name === keyword,
      );
      const isExactMatchAtTheTop = exactMatchIndex === 0;
      const noExactMatch = exactMatchIndex === -1;
      if (isExactMatchAtTheTop || noExactMatch) {
        return packageDetails;
      }
      const exactMatch = packageDetails.splice(exactMatchIndex, 1);
      packageDetails.unshift(exactMatch[0]);
    }
    return packageDetails;
  };

  const processIndexRequest = (response: AnyFixMe, keyword?: string) => {
    const { documents } = response;
    let packageDetails = documents;
    const { packageDetails: packageDetailsResponse } = response;
    packageDetails = packageDetailsResponse;
    const processedDocuments = ensureExactMatchAtTheTop(packageDetails, keyword)
      .filter(isPackageValid)
      .map(formatPkg);

    return {
      ...response,
      packageDetails: processedDocuments,
    };
  };

  const npmIndexSearch = async ({ keyword, page = 0 }: AnyFixMe) => {
    const response = await npmServiceFacade.search({ keyword, limit: 25 });
    if (response) {
      return processIndexRequest(response.data, keyword);
    }
  };
  const memoizedNpmIndexSearch = async ({ keyword, page = 0 }: AnyFixMe) => {
    const cacheKey = `${keyword}____page____:${page}`;
    memoizedIndexSearch[cacheKey] = memoizedIndexSearch[cacheKey]
      ? memoizedIndexSearch[cacheKey]
      : await npmIndexSearch({ keyword, page });
    return memoizedIndexSearch[cacheKey];
  };

  const fetchAndCachePackages = async (packageNames: AnyFixMe) => {
    if (_.isEmpty(packageNames)) {
      return;
    }
    const response = await npmServiceFacade.get(packageNames);
    if (response) {
      const processedRequest = processIndexRequest(
        response.data,
      ).packageDetails.reduce((acc: AnyFixMe, pkg: AnyFixMe) => {
        acc[pkg.name] = pkg;
        return acc;
      }, {});

      packagesInfoCache = {
        ...packagesInfoCache,
        ...processedRequest,
      };
    }
  };

  const getPackageInfo = (packageName: AnyFixMe) =>
    packagesInfoCache[packageName];

  return {
    npmSearch: memoize ? memoizedNpmIndexSearch : npmIndexSearch,
    fetchAndCachePackages,
    getPackageInfo,
  };
};
