import * as React from 'react';
import { Preloader } from '@wix/wix-base-ui';
import bi from '@/legacy/bi/bi';
import {
  ReadOnlyModeContext,
  TreeContextMenu,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import { PACKAGE_VIEW_QUICK_ACTIONS } from '../pkgTreeQuickActions';
import { consts } from '@wix/wix-code-consts';
import { PkgTreeContext } from '../PkgTreeContext';

interface CodeReuseScopeSuffixProps {
  isContextMenuOpen: boolean;
  pkgScopeSectionLoaded: boolean;
  setContextMenuOpen: (isOpen: boolean) => void;
  setTreeHovered: (isHovered: boolean) => void;
  actionHandler: (
    action: PACKAGE_VIEW_QUICK_ACTIONS,
    packageName?: string,
  ) => void;
  pkgScope: string;
  isPrivateScope: boolean;
}

export const CodeReuseScopeSuffix: React.FC<CodeReuseScopeSuffixProps> = ({
  actionHandler,
  pkgScopeSectionLoaded,
  isContextMenuOpen,
  setContextMenuOpen,
  setTreeHovered,
  pkgScope,
  isPrivateScope,
}) => {
  const { editorAPI, legacyDependenciesAPI } = useEditorLegacyAPIs();
  const { readOnlyMode } = React.useContext(ReadOnlyModeContext);

  const {
    util: { translate },
  } = legacyDependenciesAPI;
  const { isBlocksCombinedMode } = React.useContext(PkgTreeContext);

  const handleContextMenuToggle = (isOpen: boolean) => {
    setContextMenuOpen(isOpen);
  };

  const onContextMenuClick = (event: React.MouseEvent) => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_PLUS, {
      section_name: 'internal_packages',
    });

    event.stopPropagation();
  };

  const getAction = () => {
    if (pkgScope === consts.VELO_PKG_SCOPE) {
      return PACKAGE_VIEW_QUICK_ACTIONS.INSTALL_A_PACKAGE_BY_VELO;
    }
    return PACKAGE_VIEW_QUICK_ACTIONS.INSTALL_MY_PACKAGE;
  };

  const onClickOpenMenu = () => {
    const action = getAction();

    setTreeHovered(false);
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_PLUS_ITEM, {
      section_name: 'internal_packages',
      menu_entry_name: 'install',
    });

    actionHandler(action);
  };

  const isVeloScope = pkgScope === consts.VELO_PKG_SCOPE;
  const labelKey = isVeloScope
    ? 'Left_Tree_Components_Category_CodeFiles_Packages_Menu_Corvid'
    : 'Left_Tree_Components_Category_CodeFiles_Packages_Add_My_Package';
  const contextMenuSections = {
    sections: [
      [
        {
          onClick: onClickOpenMenu,
          icon: 'add_plus_icon_reg',
          label: translate(labelKey),
          automationId: 'action-add-corvid-module',
        },
      ],
    ],
  };

  const isNoActionOnScope = !isPrivateScope && !isVeloScope;

  return isNoActionOnScope ||
    isBlocksCombinedMode ? null : pkgScopeSectionLoaded ? (
    <div onClick={onContextMenuClick}>
      <TreeContextMenu
        menuClassName="context-menu wix-code-file-tree-dd"
        handleContextMenuToggle={handleContextMenuToggle}
        contextMenuStructure={contextMenuSections}
        contextMenuButton="corvid_tree__context_menu_add"
        isContextMenuOpen={isContextMenuOpen}
        tooltipContent={translate(
          'Velo_Concurrent_Editing_Sidebar_ReadOnly_Text',
        )}
        readonly={!!readOnlyMode.sidePanel?.packages}
      />
    </div>
  ) : (
    <div className="preloader-container-hack">
      <Preloader className="tiny" />
    </div>
  );
};
