import { MessageModal } from '@wix/wix-base-ui';
import { PanelsAPI } from '@wix/wix-code-plugin-contracts';
import React from 'react';

type ErrorModalProps = {
  translate: (key: string) => string;
  primaryButtonOnClick: () => void;
  onClose: () => void;
};
export const ErrorModal: React.FC<ErrorModalProps> = ({
  translate: t,
  primaryButtonOnClick,
  onClose,
}) => {
  return (
    <MessageModal
      theme="destructive"
      onCloseButtonClick={onClose}
      title={t('Package_Manager.Modal.GenericError.Title')}
      content={t('Package_Manager.Modal.GenericError.Text')}
      primaryButtonText={t('Package_Manager.Modal.GenericError.PrimaryCta')}
      primaryButtonOnClick={primaryButtonOnClick}
    />
  );
};
export const openErrorModal = (
  panelsAPI: PanelsAPI,
  t: (key: string, options?: any) => string,
) => {
  return panelsAPI.openPanel(
    <ErrorModal
      translate={t}
      primaryButtonOnClick={panelsAPI.closePanel}
      onClose={panelsAPI.closePanel}
    />,
    true,
  );
};
