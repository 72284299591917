import { NotificationBanner, Spacer } from '@wix/wix-base-ui';
import { GithubPrivateAPI } from '../../githubPrivateAPI';
import { OnboardingState } from '../../types/GithubTypes';
import React from 'react';
import dataHooks from '../dataHooks';

export interface ErrorBannerProps {
  githubAPI: GithubPrivateAPI;
  loginSessionWasExpired: boolean;
  resetErrorBanner: () => void;
  t: (key: string) => string;
}

export const ErrorBanner: React.FC<ErrorBannerProps> = ({
  githubAPI,
  loginSessionWasExpired,
  resetErrorBanner,
  t,
}) => {
  const { bannerText, linkComponent } = loginSessionWasExpired
    ? {
        bannerText: t(
          'githubIntegration.connect_modal.create_repository.login_session_expired_error',
        ),
        linkComponent: {
          label: t(
            'githubIntegration.connect_modal.create_repository.login_session_expired_error_link_text',
          ),
          onClick: () => {
            githubAPI.setOnboardingStatus({
              onboardingState: OnboardingState.GITHUB_DISABLED,
            });
            resetErrorBanner();
          },
        },
      }
    : {
        bannerText: t('githubIntegration.connect_modal.error_msg'),
        linkComponent: undefined,
      };

  return (
    <>
      <Spacer type="Spacer06" />
      <NotificationBanner
        skin="alert"
        dataHook={dataHooks.connectModal.notificationBanner}
        text={bannerText}
        shouldTranslate={false}
        multiline={true}
        link={linkComponent}
      />
    </>
  );
};
