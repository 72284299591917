import { platformApps } from '@wix/wix-code-common';
import once_ from 'lodash/once';

export default once_(({ constants }) => {
  function selectTreeItem(editorAPI, collectionId) {
    const context = editorAPI.developerMode.createContext(
      constants.DEVELOPER_MODE.CONTEXT_TYPES.COLLECTION,
      { id: collectionId },
    );
    editorAPI.developerMode.setContext(context);
  }

  function openContentManager(editorAPI, collectionId) {
    platformApps.notifyDataBinding(editorAPI, {
      eventType: 'treeItemSelected',
      eventPayload: {
        itemName: collectionId,
        instance: editorAPI.wixCode.getClientSpec().instance,
        origin: 'ideTree',
      },
    });

    return Promise.resolve();
  }

  function navigateToCollection(editorAPI, collectionId) {
    editorAPI.panelManager.closeAllPanels();
    selectTreeItem(editorAPI, collectionId);
    return openContentManager(editorAPI, collectionId);
  }

  return {
    navigateToCollection,
  };
});
