import _ from 'lodash';
import errors from '@/legacy/core/errors';
import { consts } from '@wix/wix-code-consts';
import * as codeEditor from '@wix/wix-code-code-editor';
import storeManager from '@/infra/redux-state/store/storeManager';
import pageActionsCreator from '@/infra/redux-state/actions/pageActions';
import once_ from 'lodash/once';
import { componentsDataService } from '@wix/wix-code-properties-panel';

export default once_(({ experiment, platform, util }) => {
  const pageActions = pageActionsCreator({ experiment, platform, util });

  const MAX_EVENT_HANDLER_NAME_LENGTH = 128;

  const VALIDATIONS = {
    VALID: 'VALID',
    INVALID_FN_NAME: 'INVALID_FN_NAME',
    TOO_LONG: 'TOO_LONG',
  };

  function eventHandlerFunction(functionName, handlerArgs, comments = '') {
    const args = handlerArgs
      ? handlerArgs.map(({ name }) => name).join(', ')
      : '';
    const compiled = _.template(
      'export function <%= functionName %>(<%= args %>) { \n<%= comments %>// Add your code for this event here: \n }',
    );
    return compiled({ functionName, args, comments });
  }

  function generateEventTypeDocsAnnotation(handlerArgs = [], description) {
    const parameters = handlerArgs.map(({ name, type }) => {
      let typeAnnotation = 'any';
      if (type) {
        typeAnnotation = type.replace('external:', '');
      }
      return `\n*\t @param {${typeAnnotation}} ${name}`;
    });

    const compiled = _.template(
      `/**\n*\t<%= description %><%= parameters %>\n*/`,
    );

    return compiled({ description, parameters });
  }

  function getEvents(sdkOrControllerType) {
    if (!sdkOrControllerType) {
      throw new errors.ArgumentError(
        'componentType',
        'eventService.getEvents',
        sdkOrControllerType,
      );
    }

    return componentsDataService.getComponentEventHandlers(sdkOrControllerType);
  }

  function lowercaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }

  function removeOnPrefix(eventName) {
    return eventName.slice(2);
  }

  function generateEventHandlerCode(
    currentPageId,
    showOnAllPages,
    functionName,
    handlerArgs,
    eventDescription,
    comments,
  ) {
    if (!currentPageId) {
      throw new errors.ArgumentError(
        'currentPageId',
        'eventService.generateEventHandlerCode',
        currentPageId,
      );
    }

    if (showOnAllPages === undefined) {
      throw new errors.ArgumentError(
        'showOnAllPages',
        'eventService.generateEventHandlerCode',
        showOnAllPages,
      );
    }

    if (validateEventHandlerName(functionName) !== VALIDATIONS.VALID) {
      throw new errors.ArgumentError(
        'functionName',
        'eventService.generateEventHandlerCode',
        functionName,
      );
    }

    const store = storeManager.getStore();

    let handlerFunctionString = eventHandlerFunction(
      functionName,
      handlerArgs,
      comments,
    );

    handlerFunctionString = `${generateEventTypeDocsAnnotation(
      handlerArgs,
      eventDescription,
    )}\n${handlerFunctionString}`;

    return store.dispatch(
      pageActions.addExportStatement(
        currentPageId,
        showOnAllPages,
        handlerFunctionString,
      ),
    );
  }

  function findHighestHandlerNamePostFix(names, separator) {
    return _(names)
      .invokeMap(String.prototype.split, separator)
      .map((parts) => _.parseInt(_.last(parts)) || 0)
      .reduce(
        (seed, currentSeed) => (seed > currentSeed ? seed : currentSeed + 1),
        1,
      );
  }

  function getDefaultEventHandlerName(
    currentPageId,
    showOnAllPages,
    eventName,
    componentType,
    componentName,
  ) {
    if (!currentPageId) {
      throw new errors.ArgumentError(
        'currentPageId',
        'eventService.getDefaultEventHandlerName',
        currentPageId,
      );
    }

    if (showOnAllPages === undefined) {
      throw new errors.ArgumentError(
        'showOnAllPages',
        'eventService.getDefaultEventHandlerName',
        showOnAllPages,
      );
    }

    if (!eventName) {
      throw new errors.ArgumentError(
        'eventName',
        'eventService.getDefaultEventHandlerName',
        eventName,
      );
    }

    if (!componentType) {
      throw new errors.ArgumentError(
        'componentType',
        'eventService.getDefaultEventHandlerName',
        componentType,
      );
    }

    if (!componentName) {
      throw new errors.ArgumentError(
        'componentName',
        'eventService.getDefaultEventHandlerName',
        componentName,
      );
    }

    const store = storeManager.getStore();

    const pageId = showOnAllPages ? consts.SITE_JS_PAGE_ID : currentPageId;

    const handlerNameSeparator = '_';
    const eventType = lowercaseFirstLetter(removeOnPrefix(eventName));
    const defaultEventHandlerName =
      componentName + handlerNameSeparator + eventType;

    return store
      .dispatch(pageActions.loadById({ pageId }))
      .then(function (readFileContent) {
        let defaultHandlerExists;
        try {
          defaultHandlerExists =
            codeEditor.codeExportsService.exportStatementExists(
              readFileContent,
              defaultEventHandlerName,
            );
        } catch (e) {
          defaultHandlerExists = false;
        }

        return defaultHandlerExists
          ? nextAvailableHandlerName(readFileContent)
          : defaultEventHandlerName;
      });

    function nextAvailableHandlerName(code) {
      const exportStatements =
        codeEditor.codeExportsService.exportStatementsWithPrefix(
          code,
          defaultEventHandlerName + handlerNameSeparator,
        );
      const highestPostfixInScope = findHighestHandlerNamePostFix(
        exportStatements,
        handlerNameSeparator,
      );

      return (
        defaultEventHandlerName + handlerNameSeparator + highestPostfixInScope
      );
    }
  }

  function setFocusOnEventHandler(currentPageId, showOnAllPages, functionName) {
    if (!currentPageId) {
      throw new errors.ArgumentError(
        'currentPageId',
        'eventService.setFocusOnEventHandler',
        currentPageId,
      );
    }

    if (showOnAllPages === undefined) {
      throw new errors.ArgumentError(
        'showOnAllPages',
        'eventService.setFocusOnEventHandler',
        showOnAllPages,
      );
    }

    if (!functionName) {
      throw new errors.ArgumentError(
        'functionName',
        'eventService.setFocusOnEventHandler',
        functionName,
      );
    }

    const store = storeManager.getStore();

    return store.dispatch(
      pageActions.setFocusOnEventHandler(
        currentPageId,
        showOnAllPages,
        functionName,
      ),
    );
  }

  function validateEventHandlerName(functionName) {
    const validFunctionName =
      /^(?!(?:do|if|in|for|let|new|try|var|case|else|enum|eval|false|null|this|true|void|with|break|catch|class|const|super|throw|while|yield|delete|export|import|public|return|static|switch|typeof|default|extends|finally|package|private|continue|debugger|function|arguments|interface|protected|implements|instanceof)$)[a-zA-Z_$][0-9a-zA-Z_$]*$/;

    if (_.isEmpty(functionName)) {
      return VALIDATIONS.VALID;
    }

    if (functionName.length > MAX_EVENT_HANDLER_NAME_LENGTH) {
      return VALIDATIONS.TOO_LONG;
    }

    if (!validFunctionName.test(functionName)) {
      return VALIDATIONS.INVALID_FN_NAME;
    }

    return VALIDATIONS.VALID;
  }

  return {
    getEvents,
    generateEventHandlerCode,
    getDefaultEventHandlerName,
    setFocusOnEventHandler,
    validateEventHandlerName,
    EVENT_HANDLER_NAME_VALIDATIONS: VALIDATIONS,
  };
});
