// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PIyfcx{padding:12px 24px}.PIyfcx .zN1_VQ .xwzSFA{color:#868aa5;display:inline}.PIyfcx .zN1_VQ .URtmy1 *{color:#000624;font-size:13px;line-height:13px;margin-top:-1px}.PIyfcx .E79v_F{margin-top:12px}.PIyfcx .E79v_F *{font-size:13px !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PIyfcx",
	"text-wrapper": "zN1_VQ",
	"textWrapper": "zN1_VQ",
	"description": "xwzSFA",
	"link-button": "URtmy1",
	"linkButton": "URtmy1",
	"cta-button": "E79v_F",
	"ctaButton": "E79v_F"
};
export default ___CSS_LOADER_EXPORT___;
