import consoleConstants from '../components/console/constants';
import wixCodeBI from '../../bi/bi';
import * as _ from 'lodash';
import { LegacyEditorExperimentsObj } from '@/infra/experiments/legacyExperiments';
import type {
  LegacyEditorDependencies,
  EditorAPI,
} from '@wix/wix-code-plugin-contracts';
import { PageDetails } from '@wix/ambassador-cloud-user-code-dev-analyzer/http';
import { Message } from '@/infra/redux-state/reducers/consoleReducer';
import { UserCodeDevAnalyzerFacade } from '@/utils/UserCodeDevAnalyzerFacade';
import {
  removeMessageForever,
  addMessageToConsole,
  isNotificationDismissed,
  genBiData,
  Page,
} from './commonNotificationsFunctions';

interface CacheNotificationCreatorProps {
  userCodeDevAnalyzerFacade: UserCodeDevAnalyzerFacade;
  experiment: LegacyEditorExperimentsObj;
  editorAPI: EditorAPI;
  stateManagement: LegacyEditorDependencies['stateManagement'];
  util: LegacyEditorDependencies['util'];
}

interface MessageProps {
  pageName: string;
  page: Page;
}

function cacheNotificationCreator({
  userCodeDevAnalyzerFacade,
  editorAPI,
  stateManagement,
  util,
}: CacheNotificationCreatorProps) {
  const ssrNoShowAgainPrefrence = 'SSR_CACHE_NEVER_SHOW_AGAIN_';

  function getPageData(id: Page['id']) {
    return _.find(editorAPI.pages.getPagesData(), { id });
  }

  function shouldShowMessage(page: Page, pageDetails?: PageDetails) {
    if (
      page.type !== 'DESKTOP' ||
      isRouterOrDynamicPage(page.id) ||
      isNotificationDismissed({
        stateManagement,
        editorAPI,
        userPreferencesKey: `${ssrNoShowAgainPrefrence}${page.id}`,
      })
    ) {
      return false;
    }

    const pageData = getPageData(page.id);

    return (
      !isCacheModeSet(pageData) &&
      !isMembersPage(pageData) &&
      !isTPAGeneratedPage(pageData) &&
      !isCacheEligible(pageDetails) &&
      !isCacheDisabledManuallyByUser(page)
    );
  }

  function isMembersPage(pageData: AnyFixMe) {
    return (
      !!pageData.pageSecurity &&
      (pageData.pageSecurity.requireLogin ||
        pageData.pageSecurity.dialogLanguage)
    );
  }

  function isCacheModeSet(pageData: AnyFixMe) {
    return pageData.cacheMaxAgeHours > 0;
  }

  function isTPAGeneratedPage(pageData: AnyFixMe) {
    return !!pageData.tpaApplicationId;
  }

  function isRouterOrDynamicPage(pageId: Page['id']) {
    const pageType = editorAPI.pages.dynamicPages.getRouterPageType(pageId);
    return pageType === 'Router' || pageType === 'Dynamic';
  }

  function isCacheEligible(pageDetails?: PageDetails): boolean | undefined {
    return pageDetails?.isCacheableAccordingToStaticAnalysis;
  }

  const isCacheExperimentOn = (pageDetails?: PageDetails) => {
    return pageDetails?.isCacheable;
  };

  function isCacheDisabledManuallyByUser(page: Page) {
    return getPageData(page.id).cacheMode === 'disabled';
  }

  async function addMessageToPreviewConsole() {
    const page = editorAPI.pages.getFocusedPage();
    const pageDetails = await userCodeDevAnalyzerFacade.getPageDetails();
    if (shouldShowMessage(page, pageDetails)) {
      const pageName = editorAPI.pages.getPageTitle(page.id);
      if (isCacheExperimentOn(pageDetails)) {
        showCacheLogMessage({ pageName, page });
        editorAPI.bi.event(
          wixCodeBI.events.AUTO_CACHE_ON_AND_MIGHT_HAVE_NEGATIVE_IMPACT,
          genBiData(editorAPI, page.id),
        );
      } else {
        showOldCacheLogMessage({ pageName, page });
        editorAPI.bi.event(
          wixCodeBI.events.IS_POTENTIAL_FOR_SSR_CACHE_CORVID_PAGE,
          genBiData(editorAPI, page.id),
        );
      }
    }
  }

  const showCacheLogMessage = ({ pageName, page }: MessageProps) => {
    const args = [
      util.translate('WixCode_CacheNotification_AutoCache_Text', {
        pageName,
      }),
      {
        linkType: 'href',
        url: util.translate(
          'Console_Caching_Message_Manually_Disable_Caching_URL',
        ),
        callback: () =>
          editorAPI.bi.event(
            wixCodeBI.events.CLICKED_ON_LEARN_MORE_REGARDING_CACHING_IDE,
            genBiData(editorAPI, page.id),
          ),
        text: util.translate('WixCode_CacheNotification_ManuallyDisable_Link'),
      },
    ];
    const removeAction = {
      text: util.translate('WixCode_Package_Was_Updated_Dont_Show_again'),
      callback: (message: Message) => {
        removeMessageForever({
          editorAPI,
          stateManagement,
          userPreferencesKey: `${ssrNoShowAgainPrefrence}${page.id}`,
          messageId: message.id,
        });
        editorAPI.bi.event(
          wixCodeBI.events.CLICKED_ON_DISMISS_CACHING_OPTION_IDE,
          genBiData(editorAPI, page.id),
        );
      },
    };
    addMessageToConsole({
      editorAPI,
      logLevel: consoleConstants.LOG_LEVEL.WARNING,
      args,
      action: removeAction,
    });
  };

  const showOldCacheLogMessage = ({ pageName, page }: MessageProps) => {
    const args = [
      util.translate('WixCode_CacheNotificationMessage_Prefix', {
        pageName,
      }),
      util.translate('WixCode_CacheNotificationMessage_Link'),
      util.translate('WixCode_CacheNotificationMessage_Suffix', {
        pageName,
      }),
      {
        linkType: 'href',
        url: util.translate('Console_Caching_Message_Learn_More_URL'),
        callback: () =>
          editorAPI.bi.event(
            wixCodeBI.events.CLICKED_ON_LEARN_MORE_REGARDING_CACHING_IDE,
            genBiData(editorAPI, page.id),
          ),
        text: util.translate('WixCode_CacheNotification_LearnMore'),
      },
    ];
    const removeAction = {
      text: util.translate('WixCode_Package_Was_Updated_Dismiss'),
      callback: (message: Message) => {
        removeMessageForever({
          editorAPI,
          stateManagement,
          userPreferencesKey: `${ssrNoShowAgainPrefrence}${page.id}`,
          messageId: message.id,
        });
        editorAPI.bi.event(
          wixCodeBI.events.CLICKED_ON_DISMISS_CACHING_OPTION_IDE,
          genBiData(editorAPI, page.id),
        );
      },
    };
    addMessageToConsole({
      editorAPI,
      logLevel: consoleConstants.LOG_LEVEL.TIP,
      args,
      action: removeAction,
    });
  };

  return { addMessageToPreviewConsole };
}
export default cacheNotificationCreator;
