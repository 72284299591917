import React from 'react';
import {
  createSelectorHook,
  createDispatchHook,
  ReactReduxContextValue,
} from 'react-redux';
import { Dispatch } from './storeTypes';

export const wixCodeReduxContext = React.createContext<ReactReduxContextValue>(
  null as any,
);

export const useSelector = createSelectorHook(wixCodeReduxContext);
export const useDispatch: () => Dispatch =
  createDispatchHook(wixCodeReduxContext);
