import { MenuItemId } from '@wix/wix-code-plugin-contracts';
import { ActionType } from './actionTypes';

export type SetReadOnly = {
  readOnly: boolean;
  type: ActionType.SET_READ_ONLY;
}

export type SetIsLoading = {
  loading: boolean;
  type: ActionType.SET_LOADING;
};

export type RegisterMenuClickHandler = {
  id: MenuItemId;
  callback: () => void;
  type: ActionType.REGISTER_MENU_CLICK_HANDLER;
};

export type SelectTab = {
  type: ActionType.SELECT_TAB;
  selectedTab: string;
};

export type SelectMenuItem = {
  type: ActionType.SELECT_MENU_ITEM;
  selected: MenuItemId;
};

export type Action =
  | SetIsLoading
  | RegisterMenuClickHandler
  | SelectTab
  | SelectMenuItem
  | SetReadOnly;

export const setIsLoading = (loading: boolean): SetIsLoading => ({
  type: ActionType.SET_LOADING,
  loading,
});

export const registerMenuClickHandler = (
  id: MenuItemId,
  callback: () => void,
): RegisterMenuClickHandler => ({
  type: ActionType.REGISTER_MENU_CLICK_HANDLER,
  id,
  callback,
});

export const setSelectedTab = (selectedTab: string): SelectTab => ({
  type: ActionType.SELECT_TAB,
  selectedTab,
});

export const setSelectedMenuItem = (menuItem: MenuItemId): SelectMenuItem => ({
  type: ActionType.SELECT_MENU_ITEM,
  selected: menuItem,
});

export const setReadOnly = (readOnly: boolean): SetReadOnly => ({
  type: ActionType.SET_READ_ONLY,
  readOnly,
});
