import React from 'react';
import { I18nextProvider, i18n } from '@wix/wix-i18n-config';
import { SpiService } from './SpiService';
import {
  BiLoggerAPI,
  ExperimentsAPI,
  FilesTreeAPI,
  FileSystemAPI,
  PanelsAPI,
  ReadOnlyAPI,
  SiteAPI,
  UserPreferencesAPI,
  WixCodeAppAPI,
} from '@wix/wix-code-plugin-contracts';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';

export type APIs = {
  panelsAPI: PanelsAPI;
  userPreferencesAPI: UserPreferencesAPI;
  filesTreeAPI: FilesTreeAPI;
  wixCodeAppAPI: WixCodeAppAPI;
  experimentsAPI: ExperimentsAPI;
  siteAPI: SiteAPI;
  fileSystemAPI: FileSystemAPI;
  biLoggerAPI: BiLoggerAPI;
  readOnlyAPI: ReadOnlyAPI;
  wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI;
};

export type SpiContextType = APIs & {
  spiService: SpiService;
};

export type SpiContextProviderProps = APIs &
  SpiContextType & {
    i18nInstance: i18n;
  };

export const SpiContext: React.Context<SpiContextType> = React.createContext(
  null as any,
);

export const SpiContextProvider: React.FC<SpiContextProviderProps> = ({
  children,
  i18nInstance,
  ...rest
}) => {
  return (
    <SpiContext.Provider value={rest}>
      <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>
    </SpiContext.Provider>
  );
};
