import React from 'react';
import { FilesTab } from './FilesTab';
import { BackendFileTree } from './filesTree/BackendFileTree';
import { ConfigFileTree } from './filesTree/singleFile/ConfigFileTree';
import { PublicFileTree } from './filesTree/PublicFileTree';
import { ReadmeFileTree } from './filesTree/singleFile/ReadmeFileTree';
import { NpmPkgTree } from '../packagesTab/pkgTree/npmPkgTree/NpmPkgTree';
import { PkgTreeContextWrapper } from '../packagesTab/pkgTree/PkgTreeContextWrapper';
import { codeOnlyBlocksTemplates } from '@/fileSystem/fileTemplates/codeOnlyBlocksTemplates';
// SOS FIX - EDITORX LOAD IDE IN FULL SCREEN.
import { experimentUtils } from '@wix/wix-code-common';
import {
  CODE_EDITOR_MODE,
  PredefinedSidebarTabs,
  TabProps,
} from '@wix/wix-code-plugin-contracts';
import { FILE_TREE_QUICK_ACTIONS } from './filesTree/FilesTreeContextMenu';
import { DefaultFileTemplate } from '@/fileSystem/fileTemplates/defaultTemplatesInterface';

export const BlocksFileTab: React.FC<
  TabProps[PredefinedSidebarTabs.BLOCKS_CODE]
> = ({ translations }) => {
  return (
    <FilesTab
      codeFilesTab={PredefinedSidebarTabs.BLOCKS_CODE}
      defaultFileTemplate={{
        ...(codeOnlyBlocksTemplates as DefaultFileTemplate),
        ...translations,
      }}
      codeEditorMode={CODE_EDITOR_MODE.CODE_ONLY}
    >
      <PublicFileTree translations={translations} />
      <BackendFileTree
        hiddenActions={[
          'OPEN_WEBMODULE_PERMISSIONS',
          'JOBS_SCHEDULER',
          'DATA_HOOKS',
          ...(experimentUtils.isBlocksBackendContextMenu() &&
          experimentUtils.isEnableCreationOfHttpFunctionsFileInBlocks()
            ? ([] as FILE_TREE_QUICK_ACTIONS[])
            : (['HTTP_FUNCTIONS'] as FILE_TREE_QUICK_ACTIONS[])),
        ]}
        hiddenFiles={
          experimentUtils.isEnableCreationOfHttpFunctionsFileInBlocks()
            ? []
            : ['backend/http-functions.js']
        }
        isLast={false}
        translations={translations}
      />
      <ConfigFileTree translations={translations} />
      <PkgTreeContextWrapper showCodeReuse={false}>
        <NpmPkgTree codeEditorMode={CODE_EDITOR_MODE.CODE_ONLY} />
      </PkgTreeContextWrapper>
      <ReadmeFileTree translations={translations} />
    </FilesTab>
  );
};
