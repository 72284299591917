import React from 'react';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { Shell } from '@wix/wix-code-repluggable';
import { defaults } from 'lodash';
import { Experiments } from '@wix/wix-code-common';
import { DoNotTranslateBaseUiComponents } from '@wix/wix-code-common-components';

interface CreateAdapterParams {
  wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI;
  shellInHostingEditor: Shell;
}

export const createWixCodeEditorAdapterEntryPoint = ({
  wixCodeEditorAdapterAPI,
  shellInHostingEditor,
}: CreateAdapterParams) => ({
  name: 'wix-code-editor-adapter-api for wix-code',
  layer: 'EXTERNAL',
  declareAPIs() {
    return [WixCodeEditorAdapterAPI];
  },
  attach(wixCodeShell: Shell) {
    const localEditorMode = () => {
      return !!wixCodeEditorAdapterAPI.legacyDependencies.util.url.parseUrlParams(
        window.location,
      ).localPort;
    };

    const isVeloLite = () => {
      const isExperimentOpen =
        wixCodeEditorAdapterAPI.legacyDependencies.experiment.isOpen;
      const isStudio = (window as any)?.commonConfig?.host === 'STUDIO';
      return (
        isExperimentOpen(Experiments.VeloIDE) &&
        isExperimentOpen(Experiments.LiteEditor) &&
        isStudio
      );
    };

    // we assign default implementation to adapter APIs so we can use them before they implemented by the host
    const adapterApiWithDefaults = defaults<
      WixCodeEditorAdapterAPI,
      Partial<WixCodeEditorAdapterAPI>
    >(
      { ...wixCodeEditorAdapterAPI },
      {
        addDemoAvatarsIfNeeded: () => {},
        getDemoAvatarsSelector: () => '',
        removeDemoAvatars: () => {},
        contributeNicknamesEditableCondition: () => {},
        disableEditorFeatures: () => {},
        showSnackbar: () => {},
        contributeBiParams: () => {},
        contributePageCodeDuplicateCondition: () => {},
        isAnyTourRunning: () => false,
        contributeLitePanel: () => {},
        isReplacerPage: () => false,
      },
    );
    wixCodeShell.contributeAPI<WixCodeEditorAdapterAPI>(
      WixCodeEditorAdapterAPI,
      () => ({
        ...adapterApiWithDefaults,
        contributeConsole(shell, component) {
          return wixCodeEditorAdapterAPI.contributeConsole(
            shellInHostingEditor,
            component,
            (originalCondition) =>
              localEditorMode() ? true : originalCondition(),
          );
        },
        contributeCodeEditor(shell, component) {
          return wixCodeEditorAdapterAPI.contributeCodeEditor(
            shellInHostingEditor,
            component,
            (originalCondition) =>
              localEditorMode() && !isVeloLite() ? true : originalCondition(),
          );
        },
        contributeLoader(shell, component) {
          return wixCodeEditorAdapterAPI.contributeLoader(
            shellInHostingEditor,
            component,
          );
        },
        contributeFileTree(shell, component) {
          return wixCodeEditorAdapterAPI.contributeFileTree(
            shellInHostingEditor,
            component,
            (originalCondition) => originalCondition(),
          );
        },
        contributeChangesSyncIndication: (shell, component) => {
          return wixCodeEditorAdapterAPI.contributeChangesSyncIndication(
            shellInHostingEditor,
            component,
          );
        },
        contributeBeforePublish(shell, contribution) {
          return wixCodeEditorAdapterAPI.contributeBeforePublish(
            shellInHostingEditor,
            contribution,
          );
        },
        modalAPI: {
          showModal: (modalRenderer, options) => {
            const wrappedModalRenderer = (
              closeModal: Parameters<typeof modalRenderer>[0],
            ) => (
              <DoNotTranslateBaseUiComponents>
                {modalRenderer(closeModal)}
              </DoNotTranslateBaseUiComponents>
            );
            return wixCodeEditorAdapterAPI.modalAPI.showModal(
              wrappedModalRenderer,
              options,
            );
          },
        },
      }),
    );
  },
});
