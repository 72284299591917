import { CollectionsEvent, Interceptor } from '@wix/wix-code-plugin-contracts';
import { actionTypes } from './interceptorActions';

export type InterceptorsState = { [event: string]: Interceptor[] };

export type InterceptorsAction = {
  type: typeof actionTypes.ADD_INTERCEPTOR;
  payload: {
    eventName: CollectionsEvent;
    interceptor: Interceptor;
  };
};

export const interceptorsReducer = (
  state: InterceptorsState = {},
  action: InterceptorsAction,
) => {
  switch (action.type) {
    case actionTypes.ADD_INTERCEPTOR:
      if (!state[action.payload.eventName]) {
        state[action.payload.eventName] = [];
      }
      return {
        ...state,
        [action.payload.eventName]: state[action.payload.eventName].concat([
          action.payload.interceptor,
        ]),
      };
    default:
      return state;
  }
};
