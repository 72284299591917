export type IdeContainerStateService = {
  initialize: Function;
  minimize: Function;
  revertMinimize: Function;
  maximize: Function;
  revertMaximize: Function;
  toggleTree: Function;
};

type AnyFixMe = any;

let _developerModeUI: AnyFixMe;

const initialize = (developerModeUI: AnyFixMe) => {
  _developerModeUI = developerModeUI;
};

const minimize = () => {
  _developerModeUI.idePane.minimize();
};

const revertMinimize = () => {
  _developerModeUI.idePane.unMinimize();
};

const maximize = () => {
  _developerModeUI.idePane.maximize();
};

const revertMaximize = () => {
  _developerModeUI.idePane.unMaximize();
};

const toggleTree = (callback: Function) => {
  _developerModeUI.treePane.toggle(callback);
};

export default {
  initialize,
  minimize,
  revertMinimize,
  maximize,
  revertMaximize,
  toggleTree,
};
