import { ScopedStore } from '@wix/wix-code-repluggable';
import * as connectionStatus from './connectionStatus';

export type SpiStoreState = {
  connections: connectionStatus.State;
};

export type SpiStore = ScopedStore<SpiStoreState>;

export const getConnections = (state: SpiStoreState) =>
  connectionStatus.getConnections(state.connections);
