// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cQ7jpa{margin-top:24px}.g1vqu7{margin-top:18px}.Ok08rF{text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cta-button": "cQ7jpa",
	"ctaButton": "cQ7jpa",
	"learn-more-link": "g1vqu7",
	"learnMoreLink": "g1vqu7",
	"subtitle": "Ok08rF"
};
export default ___CSS_LOADER_EXPORT___;
