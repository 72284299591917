// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".R1zYPe{display:flex}._BdZqi{margin-left:5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "R1zYPe",
	"margin-left": "_BdZqi",
	"marginLeft": "_BdZqi"
};
export default ___CSS_LOADER_EXPORT___;
