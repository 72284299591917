import React from 'react';
import { LegacyEditorDependencies } from '@wix/wix-code-plugin-contracts';
import { EditorLegacyAPIsContextProvider } from '@wix/wix-code-common-components';

export interface WixCodeLoaderContextProviderProps {
  legacyDependencies: LegacyEditorDependencies;
}

export const WixCodeLoaderContextProvider: React.FC<
  WixCodeLoaderContextProviderProps
> = ({ legacyDependencies, children }) => {
  return (
    <EditorLegacyAPIsContextProvider legacyDependencies={legacyDependencies}>
      {children}
    </EditorLegacyAPIsContextProvider>
  );
};
