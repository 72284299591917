import React from 'react';
import { GithubModalsAPI } from '../../githubModalsAPI';
import { ReadOnlyAPI } from '@wix/wix-code-plugin-contracts';
import { GithubPrivateAPI } from '../../githubPrivateAPI';
import { GithubState } from '../../state';
import {
  GitHubCommit,
  GithubRepository,
  OnboardingState,
} from '../../types/GithubTypes';
import { connectWithShell, Shell } from '@wix/wix-code-repluggable';
import ConnectToGithub from './ConnectToGithub/ConnectToGithub';
import GithubConnected from './GithubConnected/GithubConnected';
import { Preloader } from '@wix/wix-base-ui';
import style from './GithubTab.scss';
import { ON_BOARDING_SUPPORTED_ERRORS } from '../../constants';
import { GithubErrorTab } from './GithubErrorTab/GithubErrorTab';

export interface GithubTabProps {
  githubModalsAPI: GithubModalsAPI;
  readOnlyAPI: ReadOnlyAPI;
  githubAPI: GithubPrivateAPI;
  isClassicEditor: boolean;
}

interface GithubTabStateProps {
  onboardingState: OnboardingState;
  onboardingError: ON_BOARDING_SUPPORTED_ERRORS | null;
  githubRepository?: GithubRepository;
  commitHistoryUrl?: string;
  commits?: GitHubCommit[];
  isLoadingOnboardingStatus: boolean;
}

export const GithubTab: React.FC<GithubTabProps & GithubTabStateProps> = ({
  githubModalsAPI,
  readOnlyAPI,
  onboardingState,
  onboardingError,
  githubRepository,
  commitHistoryUrl,
  commits,
  isLoadingOnboardingStatus,
  githubAPI,
  isClassicEditor,
}) => {
  return isLoadingOnboardingStatus ? (
    <div className={style.githubTab}>
      <Preloader className="medium preloader" />
    </div>
  ) : onboardingState === OnboardingState.GITHUB_ENABLED ? (
    <GithubConnected
      githubRepository={githubRepository}
      githubModalsAPI={githubModalsAPI}
      commitHistoryUrl={commitHistoryUrl}
      commits={commits}
    />
  ) : onboardingError === ON_BOARDING_SUPPORTED_ERRORS.GENERIC_ERROR ? (
    <GithubErrorTab githubAPI={githubAPI} />
  ) : (
    <ConnectToGithub
      githubModalsAPI={githubModalsAPI}
      readOnlyAPI={readOnlyAPI}

      isClassicEditor={isClassicEditor}
    />
  );
};

const createGithubTab = (
  shell: Shell,
  githubAPI: GithubPrivateAPI,
  closePanel?: () => void,
) => {
  return connectWithShell<GithubState, GithubTabProps, GithubTabStateProps>(
    () => ({
      onboardingState: githubAPI.getOnboardingState(),
      onboardingError: githubAPI.getOnboardingError(),
      githubRepository: githubAPI.getGithubRepository(),
      commitHistoryUrl: githubAPI.getCommitHistoryUrl(),
      commits: githubAPI.getCommits(),
      isLoadingOnboardingStatus: githubAPI.isLoadingOnboardingStatus(),
      closePanel,
    }),
    undefined,
    shell,
    {
      renderOutsideProvider: true,
      allowOutOfEntryPoint: true,
    },
  )(GithubTab);
};

export default createGithubTab;
