import {
  customIntegretionsQuickActionClicked,
  ideFileFolderCreated,
  leftTreeClickOnAPopUpLink,
  leftTreeClickOnPlusIcon,
  leftTreeClickAddNewQuickLink,
  panelOpened,
} from '@wix/bi-logger-platform/v2';
import { spiFolderDisplayName } from '../SpiService';
import { SpiType } from '../SpiTypes';

const RESOURCE = 'resource';

type FileOrFolderCreationHelperParams = {
  path: string;
  type: 'js' | 'folder';
};

type LearnMoreParams = {
  origin: string;
  related_link: string;
};

export const fileOrFolderCreationHelper = ({
  path,
  type,
}: FileOrFolderCreationHelperParams) => {
  return {
    path,
    sectionName: spiFolderDisplayName,
    type,
  };
};

export enum BiCategory {
  ADD_EDIT_CODE = 'add_edit_code',
  CANCEL_EDIT_CODE = 'cancel_edit_code',
  CANCEL_LEGAL = 'cancel_legal',
  ACCEPT_LEGAL = 'accept_legal',
  START_NOW = 'start_now',
}

export const customIntegrationQuickActionClickedEvent = (
  category: BiCategory,
  spiType: SpiType,
) => {
  return customIntegretionsQuickActionClicked({
    category,
    item_name: spiType as string,
    item_type: spiFolderDisplayName,
  });
};

export const startNowEvent = ({ spiType }: { spiType: SpiType }) => {
  return customIntegrationQuickActionClickedEvent(
    BiCategory.START_NOW,
    spiType,
  );
};

export const learnMoreEvent = ({ origin, related_link }: LearnMoreParams) => {
  const LEARN_MORE = 'learn_more';
  return leftTreeClickOnAPopUpLink({
    link_name: LEARN_MORE,
    link_type: RESOURCE,
    origin,
    related_link,
  });
};

export const cancelLegalEvent = ({ spiType }: { spiType: SpiType }) => {
  return customIntegrationQuickActionClickedEvent(
    BiCategory.CANCEL_LEGAL,
    spiType,
  );
};

export const acceptLegalEvent = ({ spiType }: { spiType: SpiType }) => {
  return customIntegrationQuickActionClickedEvent(
    BiCategory.ACCEPT_LEGAL,
    spiType,
  );
};

export const clickOnAddSPIEvent = () => {
  return leftTreeClickOnPlusIcon({
    section_name: spiFolderDisplayName,
  });
};

export const cancelSpiEvent = ({ spiType }: { spiType: SpiType }) => {
  return customIntegrationQuickActionClickedEvent(
    BiCategory.CANCEL_EDIT_CODE,
    spiType,
  );
};

export const ideFileFolderCreatedHelper = (
  params: FileOrFolderCreationHelperParams,
) => {
  return ideFileFolderCreated(fileOrFolderCreationHelper(params));
};

export const addIntegrationEvent = (subSection: SpiType) => {
  return leftTreeClickAddNewQuickLink({
    section_name: spiFolderDisplayName,
    sub_section: subSection as string,
  });
};

export const panelOpenedEvent = () => {
  return panelOpened({ panel_name: 'WixCode.panels.CustomIntegrations' });
};
