import * as React from 'react';
import s from './PackageVersionPicker.scss';
import {
  NpmPackageInfo,
  PackagesModalContext,
  TEST_VERSION_NAME,
} from '../../../../packagesModalContext';
import { CodeReusePkgVersions } from '../../../../codeReuseServerAPI';
import { Preloader, Composites, BackButton } from '@wix/wix-base-ui';
import { useEffect } from 'react';
import dataHooks from '../../../../dataHooks';
import {
  selectPackageVersion,
  clickVersionPickerBackButton,
} from '../../../packagesModalActions';
import { PackageTestVersionPicker } from './PackageTestVersionPicker';
import { PackagePublicVersionsPicker } from './PackagePublicVersionsPicker';
import { PackageItemContextProvider } from '../PackageItemContextProvider';
import { NpmPackageVersionsPicker } from './NpmPackageVersionsPicker';
import { useDispatch } from '@wix/wix-code-common-components';

export const PackageVersionPicker: React.FC = () => {
  const { packagesService, selectedPackage } =
    React.useContext(PackagesModalContext);

  const [versions, setVersions] = React.useState<CodeReusePkgVersions | null>(
    null,
  );

  const pkg = packagesService.getAvailablePkg(selectedPackage);
  const installedPkg = packagesService.getInstalledPkg(selectedPackage);
  const dispatch = useDispatch();

  useEffect(() => {
    const getVersions = async () => {
      const pkgVersions = await packagesService.getCodeReusePkgVersions(
        selectedPackage,
      );
      const { publicVersions } = pkgVersions;
      setVersions(pkgVersions);
      dispatch(
        selectPackageVersion(
          installedPkg?.version ||
            publicVersions[0]?.version ||
            TEST_VERSION_NAME,
        ),
      );
    };

    getVersions();
  }, [dispatch, installedPkg?.version, packagesService, selectedPackage]);

  return (
    <PackageItemContextProvider pkgName={selectedPackage}>
      <div data-hook={dataHooks.VERSION_PICKER} className={s.scroller}>
        <Composites.ButtonLeft>
          <BackButton
            dataHook={dataHooks.VERSION_BACK_BUTTON}
            onClick={() => {
              dispatch(clickVersionPickerBackButton());
            }}
            label={pkg.title || pkg.name}
            shouldTranslate={false}
          />
        </Composites.ButtonLeft>
        {!versions ? (
          <div className={s.loaderContainer}>
            <Preloader dataHook={dataHooks.VERSION_LOADER} />
          </div>
        ) : (
          <>
            {(pkg as NpmPackageInfo)._orderedLabeledVersions && (
              <NpmPackageVersionsPicker
                publicVersions={(pkg as NpmPackageInfo)._orderedLabeledVersions}
              />
            )}
            {versions.testVersion && (
              <PackageTestVersionPicker testVersion={versions.testVersion} />
            )}
            {versions.publicVersions?.length > 0 && (
              <PackagePublicVersionsPicker
                publicVersions={versions.publicVersions}
              />
            )}
          </>
        )}
      </div>
    </PackageItemContextProvider>
  );
};
