import {
  EventHandler,
  ComponentsData,
  SemanticClassNames,
} from '@wix/wix-code-common';
import { fetchComponentsData } from './typesFetcher';

export interface ComponentsDataService {
  init: () => Promise<void>;
  getComponentEventHandlers: (
    componentName: string,
  ) => EventHandler[] | undefined;
  getComponentApiReference: (componentName: string) => string | undefined;
  getComponentCssReference: (componentName: string) => string | undefined;
  getComponentsSemanticClassNames: () => string[] | undefined;
  getComponentsSemanticClasses: (
    sdkType: string,
    componentType: string,
  ) => string[];
  registerComponentEventHandlers: (
    componentName: string,
    handlers: EventHandler[],
  ) => void;
}

const createComponentsDataService = (): ComponentsDataService => {
  let componentData: ComponentsData | undefined;
  let initEnded: boolean = false;

  return {
    init: async () => {
      if (initEnded) {
        return;
      }
      const staticComponentsData = await fetchComponentsData();
      const preInitRegisterdComponentData = componentData ? componentData : {};

      componentData = {
        ...preInitRegisterdComponentData,
        ...staticComponentsData,
      };
      initEnded = true;
    },
    getComponentEventHandlers: (
      componentName: string,
    ): EventHandler[] | undefined => {
      if (!componentData) {
        return [];
      }
      return componentData[componentName]?.events;
    },
    getComponentApiReference: (componentName: string): string | undefined => {
      if (!componentData) {
        return undefined;
      }
      return componentData[componentName]?.apiRefLink;
    },
    getComponentsSemanticClasses: (sdkType, componentType) => {
      if (!componentData) {
        return [];
      }
      return Object.values(
        componentData[sdkType]?.semanticClassNames?.[componentType] || {},
      );
    },
    getComponentsSemanticClassNames: (): string[] | undefined => {
      if (!componentData) {
        return [];
      }
      return Object.values(componentData).reduce((acc, compData) => {
        const data = compData.semanticClassNames;
        const parsedClassNames = parseSemanticClassNames(data);
        acc.push(...parsedClassNames);
        return acc;
      }, [] as string[]);
    },
    getComponentCssReference: (componentName: string): string | undefined => {
      if (!componentData) {
        return undefined;
      }
      return componentData[componentName]?.cssClassRefLink;
    },
    registerComponentEventHandlers: (
      componentName: string,
      handlers: EventHandler[],
    ): void => {
      if (!componentData) {
        componentData = {
          [componentName]: {
            events: handlers,
          },
        };
      } else {
        componentData[componentName] = {
          events: handlers,
        };
      }
    },
  };
};

const parseSemanticClassNames = (semanticClassNames?: SemanticClassNames) => {
  if (!semanticClassNames) {
    return [];
  }
  return Object.values(semanticClassNames).reduce((acc, entries) => {
    acc.push(...Object.values(entries));
    return acc;
  }, [] as string[]);
};

export default createComponentsDataService;
