import _ from 'lodash';
import { WixCodeReadOnlyState } from '.';
import {
  ReadOnlyMode,
  OrderedContributorsByPriority,
  ClassicEditorAPI,
  ReadOnlyContributor,
  READ_ONLY_SIDE_PANEL,
} from '@wix/wix-code-plugin-contracts';

export const defaultReadOnlyMode: ReadOnlyMode = {
  propertiesPanel: {
    nicknamesSection: false,
    propsSection: false,
    eventsSection: false,
  },
  codeIde: false,
  fileSystem: false,
  cssEditing: {
    addGlobalCss: false,
    semanticClasses: false,
  },
  externalIde: false,
};

export const fileSystemReadOnlyMode: ReadOnlyMode = {
  sidePanel: READ_ONLY_SIDE_PANEL,
  propertiesPanel: {
    nicknamesSection: true,
    propsSection: true,
    eventsSection: true,
  },
  codeIde: true,
  fileSystem: true,
  cssEditing: {
    addGlobalCss: true,
    semanticClasses: true,
  },
  externalIde: true,
};

export function createSelectors(
  getState: () => WixCodeReadOnlyState,
  classicEditorAPI: ClassicEditorAPI,
) {
  const resolveWinnerContribution = (): Partial<ReadOnlyMode> => {
    if (classicEditorAPI.editorAPI.wixCode.fileSystem.isReadOnly()) {
      return fileSystemReadOnlyMode;
    } else {
      const contributions = getState().readOnlyState.readOnlyContributions;
      const winner = OrderedContributorsByPriority.find(
        (contributor: ReadOnlyContributor) => !!contributions[contributor],
      );
      return _.get(contributions, winner || '', {});
    }
  };

  return {
    getReadOnlyMode: (): ReadOnlyMode => {
      const winnerContribution = resolveWinnerContribution();
      return _.merge({}, defaultReadOnlyMode, winnerContribution);
    },
  };
}
