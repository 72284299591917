import * as React from 'react';
import cx from 'classnames';
import { withErrorHandling } from '@/infra/monitoring';
import bi from '@/legacy/bi/bi';
import _ from 'lodash';
import {
  EditableListItem,
  TreeSectionDivider,
  Divider,
} from '@wix/wix-base-ui';
import dataHooks from '../dataHooks';
import { FILE_TREE_QUICK_ACTIONS } from './filesTree/FilesTreeContextMenu';
import {
  filesTreeShouldAlwaysShowSuffix,
  FilesTreeSuffix,
} from './filesTree/FilesTreeSuffix';
import { FilesTreeEmptyState } from './filesTree/FilesTreeEmptyState';

import { LeftPaneContext, LeftPaneContextProps } from '../LeftPaneContext';
import { FileTreeNode } from './FileTreeNode';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import defaultFileTemplateServiceFactory from '@/legacy/core/services/defaultFileTemplateService';
import {
  CustomNodeName,
  FileTreeCustomAction,
  FileTreeCustomFolderAction,
  HiddenFileAction,
  NodeIcon,
  TreeNodeType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  EditorAPI,
} from '@wix/wix-code-plugin-contracts';
import s from './FileTreeNode.scss';
import { consts } from '@wix/wix-code-consts';
import { SidePaneContext, SidePaneContextProps } from '../SidePaneContext';
import { experimentUtils } from '@wix/wix-code-common';

export interface FileSubTreeDataSource {
  id: string;
  name: string;
  pendingRename: string;
  isFolder: boolean;
  childItems: FileSubTreeDataSource[];
  loading: boolean;
  expanded: boolean;
  pendingCreation: boolean;
  pendingDelete: boolean;
}

interface FileSubTreeProps {
  dataSource: FileSubTreeDataSource;
  depth?: number;
  isRoot?: boolean;
  isBackend?: boolean;
  isExternalComponents?: boolean;
  labelOverride?: string;
  isReservedFileSystemItem?: (parentId: string, name: string) => boolean;
  isReservedChildNameInParent?: (childName: string) => boolean;
  isDuplicateChildNameInParent?: (childName: string) => boolean;
  isTopLevelTreeRoot?: boolean;
  renameFileOrFolder: (fileId: string, newName: string) => any;
  createFile: (
    parentId: string,
    childName: string,
    initialContent?: string,
  ) => any;
  createFolder: (parentId: string, childName: string) => any;
  expand: (fileId: string) => any;
  collapse: (fileId: string) => any;
  nodeDoubleClick: (fileId: string) => any;
  menuActionHandler?: (
    action: FILE_TREE_QUICK_ACTIONS,
    dataSource: FileSubTreeDataSource,
  ) => void;
  dataHook?: string;
  hiddenActions?: FILE_TREE_QUICK_ACTIONS[];
  hiddenRootActions?: FILE_TREE_QUICK_ACTIONS[];
  customRootActions?: FileTreeCustomAction[];
  hiddenFolderActions?: FILE_TREE_QUICK_ACTIONS[];
  customFolderActions?: FileTreeCustomFolderAction[];
  hiddenFileActionsByPredicate?: HiddenFileAction[];
  isLast?: boolean;
  sectionDataHook?: string;
  customNodeIcons?: NodeIcon[];
  customNodeNames?: CustomNodeName[];
  excludedFiles?: string[];
  isExcludedFile?: boolean;
  translations?: { [key: string]: string };
  customBiReportSectionName?: string;
  readOnlyMode: boolean;
}

interface FileSubTreeState {
  fileIsBeingRenamed: boolean;
  isTreeHovered: boolean;
  isContextMenuOpen: boolean;
}

const ROUTER_FILE_NAME = 'routers.js';

const validFileNameRegex = /^[\w-](?:[\w.-]*[\w-])*$/;

export const FileSubTree: React.FC<FileSubTreeProps> = (props) => (
  <FileSubTreeCls
    {...props}
    {...React.useContext(LeftPaneContext)}
    {...React.useContext(SidePaneContext)}
  />
);

type FileSubTreeClassProps = FileSubTreeProps &
  LeftPaneContextProps &
  SidePaneContextProps;

class FileSubTreeCls extends React.Component<
  FileSubTreeClassProps,
  FileSubTreeState
> {
  static defaultProps = {
    isReservedChildNameInParent: _.constant(false),
    isDuplicateChildNameInParent: _.constant(false),
    isReservedFileSystemItem: _.constant(false),
  };

  constructor(props: FileSubTreeClassProps) {
    super(props);
    this.createFile = this.createFile.bind(this);

    this.state = {
      fileIsBeingRenamed: false,
      isTreeHovered: false,
      isContextMenuOpen: false,
    };
  }
  defaultFileTemplateService = defaultFileTemplateServiceFactory(
    this.props.defaultFileTemplate,
  );

  onNodeDoubleClick = this.props.nodeDoubleClick;

  componentDidMount = () => {
    this.onNodeClick = withErrorHandling(this.onNodeClick);
    this.onNodeDoubleClick = withErrorHandling(this.onNodeDoubleClick);
    this.onContextMenuClick = withErrorHandling(this.onContextMenuClick);
    this.actionSelectedHandler = withErrorHandling(this.actionSelectedHandler);
  };

  showFileSystemErrorPanel = (editorAPI: EditorAPI, error: AnyFixMe) => {
    editorAPI.panelManager.openPanel(
      'wixCode.panels.fileSystemOperationErrorPanel',
      { error },
    );
  };

  getFileExtension = (path: AnyFixMe) => {
    const parts = path.split('.');
    if (
      parts.length === 1 /* no extension */ ||
      (parts[0] === '' && parts.length === 2) /* .special_file */
    ) {
      return '';
    }

    return parts.pop();
  };

  getAutomationId = () => {
    return this.props.isRoot ? 'files-tree' : 'tree-node';
  };

  prepRenameFileOrFolder = (name: AnyFixMe) => {
    this.setState({ fileIsBeingRenamed: true });
  };

  renameFileOrFolder = (newName: AnyFixMe) => {
    const editorAPI = this.props.getEditorAPI();
    this.setState({ fileIsBeingRenamed: false });
    if (newName === this.props.dataSource.name) {
      return;
    }
    return this.props
      .renameFileOrFolder(this.props.dataSource.id, newName)
      .then((path: AnyFixMe) => {
        editorAPI.bi.event(bi.events.LEFT_TREE_ITEM_RENAMED, {
          section_name: this.props.isBackend
            ? 'backend'
            : this.props.isExternalComponents
            ? 'external_components'
            : 'public',
          path,
          type: this.props.dataSource.isFolder ? 'folder' : 'file',
        });
        return path;
      })
      .then((id: string) => {
        if (!this.props.dataSource.isFolder) {
          this.props.onFileSelected(id);
        }
      })
      .catch(_.partial(this.showFileSystemErrorPanel, editorAPI));
  };
  cancelRename = () => {
    this.setState({ fileIsBeingRenamed: false });
  };
  isBackendRoutersJs = () => {
    return (
      !!this.props.isBackend && this.props.dataSource.name === ROUTER_FILE_NAME
    );
  };
  isWebModule = (): boolean => {
    return (
      this.defaultFileTemplateService.hasWebModuleExtension(
        this.props.dataSource.name,
      ) &&
      (this.props.isBackend ?? false)
    );
  };
  handleContextMenuToggle = (isOpen: AnyFixMe) => {
    this.setState({ isContextMenuOpen: isOpen });
  };
  onContextMenuClick = (event: React.MouseEvent) => {
    const { isRoot, getEditorAPI, dataSource } = this.props;
    const { name, id } = dataSource;
    if (isRoot) {
      getEditorAPI().bi.event(bi.events.LEFT_TREE_CLICK_ON_PLUS, {
        section_name: this.props.customBiReportSectionName || name,
      });
    } else {
      getEditorAPI().bi.event(bi.events.LEFT_TREE_CLICK_ON_SETTINGS, {
        item_name: id,
        item_type: this.getItemTypeForBI(),
      });
    }
    event.stopPropagation();
  };
  hasPendingOperation = () => {
    const { pendingRename, loading, pendingCreation, pendingDelete } =
      this.props.dataSource;
    return !!(pendingRename || loading || pendingCreation || pendingDelete);
  };
  getItemTypeForBI = () => {
    if (this.props.dataSource.isFolder) {
      return 'folder';
    }
    return this.isBackendRoutersJs() ? 'router' : 'file';
  };

  actionSelectedHandler = (action: FILE_TREE_QUICK_ACTIONS) => {
    const { menuActionHandler, dataSource } = this.props;
    this.setState({ isContextMenuOpen: false });
    if (menuActionHandler) {
      menuActionHandler(action, dataSource);
    } else {
      this.menuActionHandler(action);
    }
  };

  menuActionHandler = (action: FILE_TREE_QUICK_ACTIONS) => {
    const { isRoot, getEditorAPI, dataSource } = this.props;
    const { id, name } = dataSource;
    if (isRoot) {
      getEditorAPI().bi.event(bi.events.LEFT_TREE_CLICK_ON_PLUS_ITEM, {
        section_name: name,
        menu_entry_name: action,
      });
    } else {
      getEditorAPI().bi.event(bi.events.LEFT_TREE_CLICK_ON_SETTINGS_ITEM, {
        item_name: id,
        item_type: this.getItemTypeForBI(),
        menu_entry_name: action,
      });
    }
    switch (action) {
      case 'NEW_JS_FILE':
        return this.showNewJsFile();
      case 'NEW_TS_FILE':
        return this.showNewTsFile();
      case 'NEW_JSW_FILE':
        return this.showNewJswFile();
      case 'NEW_WEB_JS_FILE':
        return this.showNewWebJsFile();
      case 'NEW_JSX_FILE':
        return this.showNewJsxFile();
      case 'NEW_FILE':
        return this.props.showNewFileInput(
          'new-file',
          '',
          false,
          this.props.dataSource.id,
        );
      case 'NEW_FOLDER':
        return this.props.showNewFileInput(
          'new-folder',
          '',
          true,
          this.props.dataSource.id,
        );
      case 'DELETE':
        return this.props.onFileDelete(id);
      case 'RENAME':
        return this.prepRenameFileOrFolder(name);
      case 'OPEN_WEBMODULE_PERMISSIONS':
        return this.props.onOpenWebmodulePermissions(name, id);
      case 'JOBS_SCHEDULER':
        return this.showNewJobsConfigFile();
      case 'HTTP_FUNCTIONS':
        return this.showNewHttpFunctionsFile();
      case 'DATA_HOOKS':
        return this.showNewDataFile();
      case 'EVENTS':
        return this.showNewEventFile();
      default:
        console.warn(`Unknown action in FileTree: ${action}`);
    }
  };

  onQuickAddItemClick = () => {
    this.props
      .getEditorAPI()
      .bi.event(bi.events.LEFT_TREE_CLICK_ON_ADD_QUICK_LINK, {
        section_name: this.props.dataSource.name,
      });

    if (this.props.isBackend) {
      if (experimentUtils.isWebJsEditorSupport()) {
        this.showNewWebJsFile();
      } else {
        this.showNewJswFile();
      }
    } else if (this.props.isExternalComponents) {
      this.showNewJsxFile();
    } else {
      this.showNewJsFile();
    }
  };
  showNewJsFile = () => {
    return this.props.showNewFileInput(
      'new-file',
      'js',
      false,
      this.props.dataSource.id,
    );
  };
  showNewTsFile = () => {
    return this.props.showNewFileInput(
      'new-file',
      'ts',
      false,
      this.props.dataSource.id,
    );
  };
  showNewJswFile = () => {
    return this.props.showNewFileInput(
      'aModule',
      'jsw',
      false,
      this.props.dataSource.id,
    );
  };
  showNewWebJsFile = () => {
    return this.props.showNewFileInput(
      'new-module',
      'web.js',
      false,
      this.props.dataSource.id,
    );
  };
  showNewJsxFile = () => {
    return this.props.showNewFileInput(
      'new-file',
      'jsx',
      false,
      this.props.dataSource.id,
    );
  };
  showNewJobsConfigFile = () => {
    return this.props.expand(this.props.dataSource.id).then(() => {
      return this.createFile(consts.JOBS_SCHEDULER_FILE_NAME);
    });
  };
  showNewHttpFunctionsFile() {
    return this.props.expand(this.props.dataSource.id).then(() => {
      return this.createFile(consts.HTTP_FUNCTIONS_FILE_NAME);
    });
  }
  showNewDataFile() {
    return this.props.expand(this.props.dataSource.id).then(() => {
      return this.createFile(consts.DATA_HOOK_FILE_NAME);
    });
  }
  showNewEventFile() {
    return this.props.expand(this.props.dataSource.id).then(() => {
      return this.createFile(consts.EVENTS_FILE_NAME);
    });
  }

  onNodeClick = () => {
    if (this.hasPendingOperation()) {
      return;
    }
    if (this.props.dataSource.isFolder) {
      if (this.props.isRoot) {
        this.props
          .getEditorAPI()
          .bi.event(bi.events.LEFT_TREE_CLICK_ON_SECTION, {
            section_name: this.props.dataSource.name,
            action: this.props.dataSource.expanded ? 'close' : 'open',
          });
      } else {
        this.props.getEditorAPI().bi.event(bi.events.LEFT_TREE_CLICK_ON_ITEM, {
          item_name: this.props.dataSource.id,
          item_type: 'folder',
        });
      }
      if (this.props.dataSource.expanded) {
        this.props.collapse(this.props.dataSource.id);
      } else {
        this.props.expand(this.props.dataSource.id);
      }
    } else {
      this.props.onFileSelected(this.props.dataSource.id);
    }
  };
  isSelectable = () => {
    return !this.props.dataSource.isFolder;
  };
  isSelectedTab = () => {
    return this.props.selectedIdeTabId === this.props.dataSource.id;
  };
  onNewChildHide = () => {
    this.props.setNewFileRenameInput(null);
  };

  onNewChildApply = (name: AnyFixMe) => {
    const editorAPI = this.props.getEditorAPI();
    const isFolder = this.props.newChild!.isFolder;

    this.props.setNewFileRenameInput(null);

    if (isFolder) {
      return this.props
        .createFolder(this.props.dataSource.id, name)
        .then((path: AnyFixMe) => {
          editorAPI.bi.event(bi.events.LEFT_TREE_ITEM_CREATED, {
            section_name: this.props.isBackend
              ? 'backend'
              : this.props.isExternalComponents
              ? 'external_components'
              : 'public',
            origin: 'left_tree',
            path,
            type: 'folder',
          });
        })
        .catch(_.partial(this.showFileSystemErrorPanel, editorAPI));
    }
    return this.createFile(name);
  };

  createFile = (name: string) => {
    const editorAPI = this.props.getEditorAPI();
    const filePath = this.props.dataSource.id + name;
    const childPaths: string[] = _.map(this.props.dataSource.childItems, 'id');
    if (childPaths.includes(filePath)) {
      return this.props.onFileSelected(filePath);
    }

    return this.props
      .createFile(
        this.props.dataSource.id,
        name,
        this.defaultFileTemplateService.getInitialFileContent(filePath),
      )
      .then((path: AnyFixMe) => {
        editorAPI.bi.event(bi.events.LEFT_TREE_ITEM_CREATED, {
          section_name: this.props.isBackend
            ? 'backend'
            : this.props.isExternalComponents
            ? 'external_components'
            : 'public',
          path,
          type: this.getFileExtension(path),
          origin: 'left_tree',
        });
        this.props.onFileSelected(path);
      })
      .catch(_.partial(this.showFileSystemErrorPanel, editorAPI));
  };
  isReservedChildName = (name: string): boolean => {
    return this.props.isReservedFileSystemItem!(this.props.dataSource.id, name); // strictNullChecks
  };

  isDuplicateChildName = (name: AnyFixMe) => {
    return _.some(this.props.dataSource.childItems, { name });
  };
  validateLegalFileName = (name: AnyFixMe) => {
    return validFileNameRegex.test(name);
  };
  validateNotDuplicateChildName = (name: AnyFixMe) => {
    if (name === this.props.newChild!.initialName) {
      return true;
    }
    return !this.isDuplicateChildName(name);
  };
  validateNotReservedChildName = (name: AnyFixMe) => {
    return !this.isReservedChildName(name);
  };
  validateNotDuplicateInParent = (name: AnyFixMe) => {
    if (name === this.props.dataSource.name) {
      return true;
    }
    return !this.props.isDuplicateChildNameInParent!(name); // strictNullChecks
  };
  validateNotReservedInParent = (name: AnyFixMe) => {
    return !this.props.isReservedChildNameInParent!(name); // strictNullChecks
  };
  onMouseHover = (isEnter: AnyFixMe) => {
    this.setState({ isTreeHovered: isEnter });
  };
  genChildProps = (child: AnyFixMe): FileSubTreeProps => {
    const {
      isTopLevelTreeRoot,
      depth = 0,
      labelOverride,
      dataHook,
      ...rest
    } = this.props;
    return {
      ...rest,
      isTopLevelTreeRoot: false,
      isRoot: false,
      isReservedChildNameInParent: this.isReservedChildName,
      isDuplicateChildNameInParent: this.isDuplicateChildName,
      depth: isTopLevelTreeRoot ? 0 : depth + 1,
      dataSource: child,
    };
  };
  render() {
    const {
      dataSource,
      isBackend,
      isExternalComponents,
      isRoot = false,
      isTopLevelTreeRoot,
      experiment,
      labelOverride,
      depth = 0,
      validate,
      dataHook,
      hiddenActions,
      isExcludedFile,
      excludedFiles = [],
    } = this.props;
    const { isContextMenuOpen, isTreeHovered, fileIsBeingRenamed } = this.state;

    const { expanded, name, id, childItems, isFolder } = dataSource;
    const onNodeClick = () => {
      if (isFolder) {
        sendFolderClickBi();
        if (expanded) {
          this.props.collapse(id);
        } else {
          this.props.expand(id);
        }
      } else {
        this.props.onFileSelected(id);
      }
    };
    const filesTree: FileSubTreeDataSource[] = isRoot
      ? childItems.filter((child) => !excludedFiles.includes(child.name))
      : childItems;

    const excludedFilesTree: FileSubTreeDataSource[] = childItems.filter(
      (child) => excludedFiles.includes(child.name),
    );
    const sendFolderClickBi = () => {
      if (isRoot) {
        this.props
          .getEditorAPI()
          .bi.event(bi.events.LEFT_TREE_CLICK_ON_SECTION, {
            section_name: name,
            action: expanded ? 'close' : 'open',
          });
      } else {
        this.props.getEditorAPI().bi.event(bi.events.LEFT_TREE_CLICK_ON_ITEM, {
          item_name: id,
          item_type: 'folder',
        });
      }
    };
    const alwaysShowSuffix = filesTreeShouldAlwaysShowSuffix({
      experiment,
      isRoot: Boolean(isRoot),
      isTreeHovered,
      hasPendingOperation: this.hasPendingOperation(),
      isContextMenuOpen,
    });

    const customNodeIcon = this.props.customNodeIcons?.find(
      (nodeIcon) =>
        ((nodeIcon.nodeType === TreeNodeType.ROOT && isRoot) ||
          (nodeIcon.nodeType === TreeNodeType.FOLDER && !isRoot && isFolder) ||
          (nodeIcon.nodeType === TreeNodeType.FILE && !isRoot && !isFolder)) &&
        nodeIcon.predicate(id),
    );

    const customNodeName = this.props.customNodeNames?.find((nodeIcon) =>
      nodeIcon.predicate(id),
    );

    const suffix = (
      <FilesTreeSuffix
        hasPendingOperation={this.hasPendingOperation()}
        onContextMenuClick={this.onContextMenuClick}
        isContextMenuOpen={isContextMenuOpen}
        handleContextMenuToggle={this.handleContextMenuToggle}
        actionSelectedHandler={this.actionSelectedHandler}
        isTopLevelTreeRoot={!!isTopLevelTreeRoot}
        isBackend={!!isBackend}
        isExternalComponents={!!isExternalComponents}
        dataSource={dataSource}
        isRoot={!!isRoot}
        isBackendRoutersJs={this.isBackendRoutersJs}
        isWebModule={this.isWebModule}
        hiddenActions={hiddenActions}
        hiddenRootActions={this.props.hiddenRootActions}
        customRootActions={this.props.customRootActions}
        hiddenFolderActions={this.props.hiddenFolderActions}
        customFolderActions={this.props.customFolderActions}
        hiddenFileActionsByPredicate={this.props.hiddenFileActionsByPredicate}
        isExcludedFile={isExcludedFile}
        readOnlyMode={this.props.readOnlyMode}
      />
    );

    const generateSectionDataAid = () => {
      if (this.props.sectionDataHook) {
        return this.props.sectionDataHook;
      } else if (isBackend) {
        return 'backend-files-section';
      } else if (isExternalComponents) {
        return 'external-components-files-section';
      } else {
        return 'public-files-section';
      }
    };

    const node = isTopLevelTreeRoot ? (
      <div
        data-hook={this.props.sectionDataHook}
        data-aid={generateSectionDataAid()}
      >
        {customNodeIcon ? (
          <div className={s.fileTreeNodeTooltip}>{customNodeIcon.icon}</div>
        ) : null}
        <TreeSectionDivider
          dataHook={dataHooks.SECTION_DIVIDER}
          alwaysShowSuffix={alwaysShowSuffix}
          label={labelOverride!}
          collapsed={!expanded}
          hideBottomBorder={this.props.isLast ? false : !expanded}
          hideTopBorder={id === 'public/'}
          onClick={this.onNodeClick}
          suffix={suffix}
          size="small"
          shouldTranslate={false}
        />
      </div>
    ) : (
      <FileTreeNode
        dataSource={dataSource}
        depth={depth}
        labelOverride={labelOverride || customNodeName?.customName}
        alwaysShowSuffix={alwaysShowSuffix}
        suffix={suffix}
        onNodeClick={onNodeClick}
        nodeDoubleClick={this.onNodeDoubleClick}
        isContextMenuOpen={isContextMenuOpen}
        customNodeIcon={customNodeIcon}
      />
    );

    const validator = (val: AnyFixMe) => {
      const validReserved = fileIsBeingRenamed
        ? this.validateNotReservedInParent
        : this.validateNotReservedChildName;
      const validDuplicate = fileIsBeingRenamed
        ? this.validateNotDuplicateInParent
        : this.validateNotDuplicateChildName;
      return (
        validate.notEmptyString(val) &&
        validReserved(val) &&
        validDuplicate(val) &&
        this.validateLegalFileName(val)
      );
    };

    const getInvalidMessage = (val: AnyFixMe) => {
      const validReserved = fileIsBeingRenamed
        ? this.validateNotReservedInParent
        : this.validateNotReservedChildName;
      const validDuplicate = fileIsBeingRenamed
        ? this.validateNotDuplicateInParent
        : this.validateNotDuplicateChildName;

      if (!validate.notEmptyString(val)) {
        return 'WixCode_FileNameValidationError_Empty';
      } else if (!validReserved(val)) {
        return 'WixCode_FileNameValidationError_Reserved';
      } else if (!validDuplicate(val)) {
        return 'WixCode_FileNameValidationError_Duplicate';
      } else if (!this.validateLegalFileName(val)) {
        return 'WixCode_FileNameValidationError_Invalid';
      } else {
        // eslint-disable-next-line no-console
        console.warn(
          `Trying to get invalid message, but value is valid: ${val}`,
        );
      }
    };

    const initialSelectionEnd = name.includes('.')
      ? name.indexOf('.')
      : name.length;

    const renameInput = (
      <EditableListItem
        dataHook={dataHooks.TREE_NODE_RENAME}
        depth={depth}
        focus={true}
        getInvalidMessage={getInvalidMessage}
        initialSelectionStart={0}
        initialSelectionEnd={initialSelectionEnd}
        initialValue={name}
        onConfirm={this.renameFileOrFolder}
        onCancel={this.cancelRename}
        size="small"
        validator={validator}
      />
    );

    const generateUlDataHook = () => {
      if (!isRoot) {
        return dataHooks.FOLDER_ITEMS(dataSource.name);
      } else if (isBackend) {
        return dataHooks.BACKEND_SECTION;
      } else if (isExternalComponents) {
        return dataHooks.EXTERNAL_COMPONENTS_SECTION;
      } else {
        return dataHooks.PUBLIC_SECTION;
      }
    };
    const ulDataHook = generateUlDataHook();

    const rootDataHook = dataHook
      ? dataHook
      : isRoot
      ? dataHooks.TREE_ROOT
      : undefined;

    const showNewFileInput =
      this.props.newChild &&
      this.props.newChild.parentFolderPath === this.props.dataSource.id;

    const getFileTreeEmptyStateResource = () => {
      if (isBackend) {
        return 'Backend';
      } else if (isExternalComponents) {
        return 'ExternalComponents';
      } else {
        return 'Public';
      }
    };

    return (
      <div
        data-hook={rootDataHook}
        onMouseEnter={() => this.onMouseHover(true)}
        onMouseLeave={() => this.onMouseHover(false)}
      >
        {fileIsBeingRenamed ? renameInput : node}

        {expanded && (
          <ul
            data-hook={ulDataHook}
            key="subtree"
            className={cx({
              'section-container': isTopLevelTreeRoot,
            })}
          >
            {isRoot && !showNewFileInput && !childItems.length ? (
              <FilesTreeEmptyState
                resource={getFileTreeEmptyStateResource()}
                onQuickAddItemClick={this.onQuickAddItemClick}
                translations={this.props.translations}
              />
            ) : null}

            {showNewFileInput && (
              <li key="new-child">
                <EditableListItem
                  dataHook={dataHooks.TREE_NODE_RENAME}
                  depth={isTopLevelTreeRoot ? 0 : (depth ?? 0) + 1}
                  focus={true}
                  getInvalidMessage={getInvalidMessage}
                  initialSelectionStart={0}
                  initialSelectionEnd={this.props.newChild!.initialName.indexOf(
                    '.',
                  )}
                  initialValue={this.props.newChild!.initialName}
                  onConfirm={this.onNewChildApply}
                  onCancel={this.onNewChildHide}
                  size="small"
                  validator={validator}
                />
              </li>
            )}
            <div data-hook={dataHooks.REGULAR_TREE_NODE_CONTAINER}>
              {filesTree.map((child) => (
                <li key={'child-' + child.id}>
                  <FileSubTree {...this.genChildProps(child)} />
                </li>
              ))}
            </div>
            {isRoot && excludedFilesTree.length > 0 && (
              <>
                {filesTree.length > 0 && (
                  <div className={s.fileTreeInSectionDividerContainer}>
                    <Divider />
                  </div>
                )}
                <div data-hook={dataHooks.EXCLUDED_TREE_NODE_CONTAINER}>
                  {excludedFilesTree.map((child) => (
                    <li key={'child-' + child.id}>
                      <FileSubTree
                        isExcludedFile={true}
                        {...this.genChildProps(child)}
                      />
                    </li>
                  ))}
                </div>
              </>
            )}
          </ul>
        )}
      </div>
    );
  }
}
