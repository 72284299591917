export const spiFolderName = '___spi___';

export const spiFolderPath = `backend/${spiFolderName}/`;

export enum SpiType {
  SHIPPING_RATES = 'shipping_rates',
  TAX_CALCULATOR = 'tax_calculator',
  DEFAULT_TAXATION_CATEGORY = 'default_taxation_category',
  CATALOG = 'catalog',
  BOOKINGS_PRICING = 'bookings_pricing',
  PAYMENTS = 'payments',
  FEES = 'fees',
  DISCOUNTS = 'discounts',
  CUSTOM_SCOPE = 'custom_scope',
  COMMUNICATION_CHANNEL = 'communication_channel',
  ITEM_RECOMMENDATIONS = 'item_recommendations',
  COMMENTS_MODERATION = 'comments_moderation',
  BOOKINGS_EXTERNAL_CALENDAR = 'bookings_external_calendar',
  VALIDATIONS = 'validations',
  AUTOMATION_ACTION = 'automation_action',
  PAYMENT_SETTINGS = 'payment_settings',
  CUSTOM_TABLE_RESERVATIONS = 'custom_table_reservations',
}

export type SpiProviderType = {
  type: SpiType;
  folderPath: string;
  translationKeys: {
    wizardPhase1Title: string;
    wizardPhase1Subtitle1: string;
    wizardPhase2Subheading: string;
    wizardPhase3Heading: string;
    wizardPhase3JsFileDescription: string;
    wizardPhase3LearnMoreText: string;
    wizardPhase3LearnMoreUrl: string;
  };
  userPreferencesKey: string;
};

export type SpiProviderTypes = {
  [key: string]: SpiProviderType;
};

export const spiTypes: SpiProviderTypes = {
  payment: {
    type: SpiType.PAYMENTS,
    folderPath: `${spiFolderPath}payment/`,
    translationKeys: {
      wizardPhase1Title: 'Payment_Wizard_Phase_1_Heading',
      wizardPhase1Subtitle1: 'Payment_Wizard_Phase_1_Subheading1',
      wizardPhase2Subheading: 'Payment_Wizard_Phase_2_Subheading',
      wizardPhase3Heading: 'Payment_Wizard_Phase_3_Heading',
      wizardPhase3JsFileDescription: 'Payment_Wizard_Phase_3_JS_Description',
      wizardPhase3LearnMoreText: 'Payment_Wizard_Phase_3_Link_Text2',
      wizardPhase3LearnMoreUrl: 'Payment_Wizard_Phase_3_Link_Url',
    },
    userPreferencesKey: 'wixCode.SpiWizardStageUserConfirmationPayment',
  },
  shipping: {
    type: SpiType.SHIPPING_RATES,
    folderPath: `${spiFolderPath}ecom-shipping-rates/`,
    translationKeys: {
      wizardPhase1Title: 'Shipping_Wizard_Step1_Title',
      wizardPhase1Subtitle1: 'Shipping_Wizard_Step1_Subtitle1',
      wizardPhase2Subheading: 'Shipping_Wizard_Phase_2_Subheading',
      wizardPhase3Heading: 'Shipping_Wizard_Phase_3_Heading',
      wizardPhase3JsFileDescription: 'Shipping_Wizard_Phase_3_JS_Description',
      wizardPhase3LearnMoreText: 'Shipping_Wizard_Phase_3_Link_Text2',
      wizardPhase3LearnMoreUrl: 'Shipping_Wizard_Phase_3_Link_Url',
    },
    userPreferencesKey: 'wixCode.SpiWizardStageUserConfirmationShipping',
  },
  taxCalculator: {
    type: SpiType.TAX_CALCULATOR,
    folderPath: `${spiFolderPath}ecom-tax-calculator/`,
    translationKeys: {
      wizardPhase1Title: 'Tax_Calculator_Wizard_Step1_Title',
      wizardPhase1Subtitle1: 'Tax_Calculator_Wizard_Step1_Subtitle1',
      wizardPhase2Subheading: 'Tax_Calculator_Wizard_Phase_2_Subheading',
      wizardPhase3Heading: 'Tax_Calculator_Wizard_Phase_3_Heading',
      wizardPhase3JsFileDescription:
        'Tax_Calculator_Wizard_Phase_3_JS_Description',
      wizardPhase3LearnMoreText: 'Tax_Calculator_Wizard_Phase_3_Link_Text2',
      wizardPhase3LearnMoreUrl: 'Tax_Calculator_Wizard_Phase_3_Link_Url',
    },
    userPreferencesKey: 'wixCode.SpiWizardStageUserConfirmationTaxCalculator',
  },
  defaultTaxationCategory: {
    type: SpiType.DEFAULT_TAXATION_CATEGORY,
    folderPath: `${spiFolderPath}ecom-default-taxation-category/`,
    translationKeys: {
      wizardPhase1Title: 'Default_Taxation_Category_Wizard_Step1_Title',
      wizardPhase1Subtitle1: 'Default_Taxation_Category_Wizard_Step1_Subtitle1',
      wizardPhase2Subheading: 'Default_Taxation_Category_Wizard_Phase_2_Subheading',
      wizardPhase3Heading: 'Default_Taxation_Category_Wizard_Phase_3_Heading',
      wizardPhase3JsFileDescription:
          'Default_Taxation_Category_Wizard_Phase_3_JS_Description',
      wizardPhase3LearnMoreText: 'Default_Taxation_Category_Wizard_Phase_3_Link_Text2',
      wizardPhase3LearnMoreUrl: 'Default_Taxation_Category_Wizard_Phase_3_Link_Url',
    },
    userPreferencesKey: 'wixCode.SpiWizardStageUserConfirmationDefaultTaxationCategory',
  },
  catalog: {
    type: SpiType.CATALOG,
    folderPath: `${spiFolderPath}ecom-catalog/`,
    translationKeys: {
      wizardPhase1Title: 'Catalog_Wizard_Step1_Title',
      wizardPhase1Subtitle1: 'Catalog_Wizard_Step1_Subtitle1',
      wizardPhase2Subheading: 'Catalog_Wizard_Phase_2_Subheading',
      wizardPhase3Heading: 'Catalog_Wizard_Phase_3_Heading',
      wizardPhase3JsFileDescription: 'Catalog_Wizard_Phase_3_JS_Description',
      wizardPhase3LearnMoreText: 'Catalog_Wizard_Phase_3_Link_Text2',
      wizardPhase3LearnMoreUrl: 'Catalog_Wizard_Phase_3_Link_Url',
    },
    userPreferencesKey: 'wixCode.SpiWizardStageUserConfirmationCatalog',
  },
  fees: {
    type: SpiType.FEES,
    folderPath: `${spiFolderPath}ecom-additional-fees/`,
    translationKeys: {
      wizardPhase1Title: 'Fees_Wizard_Step1_Title',
      wizardPhase1Subtitle1: 'Fees_Wizard_Step1_Subtitle',
      wizardPhase2Subheading: 'Fees_Wizard_Phase_2_Subheading',
      wizardPhase3Heading: 'Fees_Wizard_Step3_Heading',
      wizardPhase3JsFileDescription: 'Fees_Wizard_Phase_3_JS_Description',
      wizardPhase3LearnMoreText: 'Fees_Wizard_Phase_3_Link_Text2',
      wizardPhase3LearnMoreUrl: 'Fees_Wizard_Phase_3_Link_Url',
    },
    userPreferencesKey: 'wixCode.SpiWizardStageUserConfirmationFees',
  },
  discounts: {
    type: SpiType.DISCOUNTS,
    folderPath: `${spiFolderPath}ecom-discounts-trigger/`,
    translationKeys: {
      wizardPhase1Title: 'Discounts_Wizard_Step1_Title',
      wizardPhase1Subtitle1: 'Discounts_Wizard_Step1_Subtitle1',
      wizardPhase2Subheading: 'Discounts_Wizard_Phase_2_Subheading',
      wizardPhase3Heading: 'Discounts_Wizard_Phase_3_Heading',
      wizardPhase3JsFileDescription: 'Discounts_Wizard_Phase_3_JS_Description',
      wizardPhase3LearnMoreText: 'Discounts_Wizard_Phase_3_Link_Text2',
      wizardPhase3LearnMoreUrl: 'Discounts_Wizard_Phase_3_Link_Url',
    },
    userPreferencesKey: 'wixCode.SpiWizardStageUserConfirmationDiscounts',
  },
  bookingsPricing: {
    type: SpiType.BOOKINGS_PRICING,
    folderPath: `${spiFolderPath}bookings-pricing-provider/`,
    translationKeys: {
      wizardPhase1Title: 'SPI_BookingsPricing_Wizard_Step1_Title',
      wizardPhase1Subtitle1: 'SPI_BookingsPricing_Wizard_Step1_Subtitle1',
      wizardPhase2Subheading: 'SPI_BookingsPricing_Wizard_Step2_Subheading',
      wizardPhase3Heading: 'SPI_BookingsPricing_Wizard_Step3_Heading',
      wizardPhase3JsFileDescription:
        'SPI_BookingsPricing_Wizard_Step3_JS_Description',
      wizardPhase3LearnMoreText: 'SPI_BookingsPricing_Wizard_Step3_Link_Text2',
      wizardPhase3LearnMoreUrl: 'SPI_BookingsPricing_Wizard_Step3_Link_Url',
    },
    userPreferencesKey: 'wixCode.SpiWizardStageUserConfirmationBookingsPricing',
  },
  customScope: {
    type: SpiType.CUSTOM_SCOPE,
    folderPath: `${spiFolderPath}ecom-custom-scope/`,
    translationKeys: {
      wizardPhase1Title: 'Discounts_Wizard_Step1_Title',
      wizardPhase1Subtitle1: 'Discounts_Wizard_Step1_Subtitle1',
      wizardPhase2Subheading: 'Discounts_Wizard_Phase_2_Subheading',
      wizardPhase3Heading: 'Discounts_Wizard_Phase_3_Heading',
      wizardPhase3JsFileDescription: 'Discounts_Wizard_Phase_3_JS_Description',
      wizardPhase3LearnMoreText: 'Discounts_Wizard_Phase_3_Link_Text2',
      wizardPhase3LearnMoreUrl: 'Discounts_Wizard_Phase_3_Link_Url',
    },
    userPreferencesKey: 'wixCode.SpiWizardStageUserConfirmationCustomScope',
  },
  communicationChannel: {
    type: SpiType.COMMUNICATION_CHANNEL,
    folderPath: `${spiFolderPath}communication-channel/`,
    translationKeys: {
      wizardPhase1Title: 'Discounts_Wizard_Step1_Title',
      wizardPhase1Subtitle1: 'Discounts_Wizard_Step1_Subtitle1',
      wizardPhase2Subheading: 'Discounts_Wizard_Phase_2_Subheading',
      wizardPhase3Heading: 'Discounts_Wizard_Phase_3_Heading',
      wizardPhase3JsFileDescription: 'Discounts_Wizard_Phase_3_JS_Description',
      wizardPhase3LearnMoreText: 'Discounts_Wizard_Phase_3_Link_Text2',
      wizardPhase3LearnMoreUrl: 'Discounts_Wizard_Phase_3_Link_Url',
    },
    userPreferencesKey:
      'wixCode.SpiWizardStageUserConfirmationCommunicationChannel',
  },
  itemRecommendations: {
    type: SpiType.ITEM_RECOMMENDATIONS,
    folderPath: `${spiFolderPath}ecom-recommendations-provider/`,
    translationKeys: {
      wizardPhase1Title: 'Recommendations_Wizard_Step1_Title',
      wizardPhase1Subtitle1: 'Recommendations_Wizard_Step1_Subtitle1',
      wizardPhase2Subheading: 'Recommendations_Wizard_Phase_2_Subheading',
      wizardPhase3Heading: 'Recommendations_Wizard_Step3_Heading',
      wizardPhase3JsFileDescription:
        'Recommendations_Wizard_Phase_3_JS_Description',
      wizardPhase3LearnMoreText: 'Recommendations_Wizard_Phase_3_Link_Text2',
      wizardPhase3LearnMoreUrl: 'Fees_Wizard_Phase_3_Link_Url',
    },
    userPreferencesKey: 'wixCode.SpiWizardStageUserConfirmationRecommendations',
  },
  commentsModeration: {
    type: SpiType.COMMENTS_MODERATION,
    folderPath: `${spiFolderPath}comments-moderation-provider/`,
    translationKeys: {
      wizardPhase1Title: 'CommentsModeration_Wizard_Step1_Title',
      wizardPhase1Subtitle1: 'CommentsModeration_Wizard_Step1_Subtitle1',
      wizardPhase2Subheading: 'CommentsModeration_Wizard_Phase_2_Subheading',
      wizardPhase3Heading: 'CommentsModeration_Wizard_Step3_Heading',
      wizardPhase3JsFileDescription:
        'CommentsModeration_Wizard_Phase_3_JS_Description',
      wizardPhase3LearnMoreText: 'CommentsModeration_Wizard_Phase_3_Link_Text2',
      wizardPhase3LearnMoreUrl: 'CommentsModeration_Wizard_Phase_3_Link_Url',
    },
    userPreferencesKey: 'wixCode.SpiWizardStageUserConfirmationRecommendations',
  },
  bookingsExternalCalendar: {
    type: SpiType.BOOKINGS_EXTERNAL_CALENDAR,
    folderPath: `${spiFolderPath}bookings-external-calendar-provider/`,
    translationKeys: {
      wizardPhase1Title: 'SPI_BookingsExternalCalendar_Wizard_Step1_Title',
      wizardPhase1Subtitle1:
        'SPI_BookingsExternalCalendar_Wizard_Step1_Subtitle1',
      wizardPhase2Subheading:
        'SPI_BookingsExternalCalendar_Wizard_Step2_Subheading',
      wizardPhase3Heading: 'SPI_BookingsExternalCalendar_Wizard_Step3_Heading',
      wizardPhase3JsFileDescription:
        'SPI_BookingsExternalCalendar_Wizard_Step3_JS_Description',
      wizardPhase3LearnMoreText:
        'SPI_BookingsExternalCalendar_Wizard_Step3_Link_Text2',
      wizardPhase3LearnMoreUrl:
        'SPI_BookingsExternalCalendar_Wizard_Step3_Link_Url',
    },
    userPreferencesKey:
      'wixCode.SpiWizardStageUserConfirmationBookingsExternalCalendar',
  },
  validations: {
    type: SpiType.VALIDATIONS,
    folderPath: `${spiFolderPath}validations-provider/`,
    translationKeys: {
      wizardPhase1Title: 'Validations_Wizard_Step1_Title',
      wizardPhase1Subtitle1: 'Validations_Wizard_Step1_Subtitle1',
      wizardPhase2Subheading: 'Validations_Wizard_Phase_2_Subheading',
      wizardPhase3Heading: 'Validations_Wizard_Phase_3_Heading',
      wizardPhase3JsFileDescription:
        'Validations_Wizard_Phase_3_JS_Description',
      wizardPhase3LearnMoreText: 'Validations_Wizard_Phase_3_Link_Text2',
      wizardPhase3LearnMoreUrl: 'Validations_Wizard_Phase_3_Link_Url',
    },
    userPreferencesKey: 'wixCode.SpiWizardStageUserConfirmationValidations',
  },
  automationAction: {
    type: SpiType.AUTOMATION_ACTION,
    folderPath: `${spiFolderPath}automation-action-provider/`,
    translationKeys: {
      wizardPhase1Title: 'AutomationAction_Wizard_Step1_Title',
      wizardPhase1Subtitle1: 'AutomationAction_Wizard_Step1_Subtitle1',
      wizardPhase2Subheading: 'AutomationAction_Wizard_Phase_2_Subheading',
      wizardPhase3Heading: 'AutomationAction_Wizard_Step3_Heading',
      wizardPhase3JsFileDescription:
        'AutomationActionn_Wizard_Phase_3_JS_Description',
      wizardPhase3LearnMoreText: 'AutomationAction_Wizard_Phase_3_Link_Text2',
      wizardPhase3LearnMoreUrl: 'AutomationAction_Wizard_Phase_3_Link_Url',
    },
    userPreferencesKey:
      'wixCode.SpiWizardStageUserConfirmationAutomationAction',
  },
  paymentSettings: {
    type: SpiType.PAYMENT_SETTINGS,
    folderPath: `${spiFolderPath}payment-settings-provider/`,
    translationKeys: {
      wizardPhase1Title: 'PaymentSettings_Wizard_Step1_Title',
      wizardPhase1Subtitle1: 'PaymentSettings_Wizard_Step1_Subtitle1',
      wizardPhase2Subheading: 'PaymentSettings_Wizard_Phase_2_Subheading',
      wizardPhase3Heading: 'PaymentSettings_Wizard_Phase_3_Heading',
      wizardPhase3JsFileDescription:
        'PaymentSettings_Wizard_Phase_3_JS_Description',
      wizardPhase3LearnMoreText: 'PaymentSettings_Wizard_Phase_3_Link_Text2',
      wizardPhase3LearnMoreUrl: 'PaymentSettings_Wizard_Phase_3_Link_Url',
    },
    userPreferencesKey: 'wixCode.SpiWizardStageUserConfirmationPaymentSettings',
  },
  customTableReservations: {
    type: SpiType.CUSTOM_TABLE_RESERVATIONS,
    folderPath: `${spiFolderPath}custom-table-reservations-provider/`,
    translationKeys: {
      wizardPhase1Title: 'Custom_Table_Reservations_Wizard_Step1_Title',
      wizardPhase1Subtitle1: 'Custom_Table_Reservations_Wizard_Step1_Subtitle1',
      wizardPhase2Subheading: 'Custom_Table_Reservations_Wizard_Phase_2_Subheading',
      wizardPhase3Heading: 'Custom_Table_Reservations_Wizard_Phase_3_Heading',
      wizardPhase3JsFileDescription:
          'Custom_Table_Reservations_Wizard_Phase_3_JS_Description',
      wizardPhase3LearnMoreText: 'Custom_Table_Reservations_Wizard_Phase_3_Link_Text2',
      wizardPhase3LearnMoreUrl: 'Custom_Table_Reservations_Wizard_Phase_3_Link_Url',
    },
    userPreferencesKey: 'wixCode.SpiWizardStageUserConfirmationCustomTableReservations',
  },
};
