import React from 'react';
import {
  SlotKey,
  ExtensionSlot,
  SlotRenderer,
} from '@wix/wix-code-repluggable';
import { TopBarItem } from '@wix/wix-code-plugin-contracts';

export type TopBarItemSlot = ExtensionSlot<TopBarItem>;
export const TopBarItemRendererSlot: SlotKey<TopBarItem> = {
  name: 'Top Bar Item Slot',
};

interface TopBarItemRendererProps {
  topBarItemSlot: TopBarItemSlot;
}

export const TopBarItemRenderer = ({
  topBarItemSlot,
}: TopBarItemRendererProps) => (
  <SlotRenderer
    slot={topBarItemSlot}
    mapFunc={(item) => () => item()}
  ></SlotRenderer>
);
