import { HttpClient } from '@wix/http-client';
import {
  ComponentsData,
  FullWixCodeTypesJSON,
  WixModulesMap,
  fedops,
} from '@wix/wix-code-common';
import { WIX_CODE_TYPES } from '../constants';
import { isUrlAllowed } from './urlOverride';

const SERVER_TIMEOUT_MS = 7 * 1000;
const WIX_CODE_TYPES_URL_OVERRIDE_PARAM = 'wix-code-types-override';

const getOverrideUrl = () => {
  const wixTypesUrlOverride =
    typeof window !== 'undefined' &&
    window?.location &&
    new URL(window.location.href).searchParams.get(
      WIX_CODE_TYPES_URL_OVERRIDE_PARAM,
    );

  return wixTypesUrlOverride && isUrlAllowed(wixTypesUrlOverride)
    ? wixTypesUrlOverride
    : null;
};

const createUrl = (baseUrl: string, fileName: string): string => {
  const overrideUrl = getOverrideUrl() || baseUrl;
  return `${overrideUrl}${fileName}`;
};

const httpClient = new HttpClient();

export const fetchModulesMap = async (
  isBeta: boolean = false,
): Promise<WixModulesMap> => {
  const moduleListHref = createUrl(
    WIX_CODE_TYPES.URL,
    !isBeta
      ? WIX_CODE_TYPES.PATHS.WIX_MODULES
      : WIX_CODE_TYPES.PATHS.WIX_MODULES_BETA,
  );

  const fedopsLogger = fedops.getLogger();
  fedopsLogger.interactionStarted(fedops.interactions.fetchWixModules);

  return httpClient
    .get(moduleListHref, { timeout: SERVER_TIMEOUT_MS })
    .then((source) => {
      fedopsLogger.interactionEnded(fedops.interactions.fetchWixModules);

      return source.data;
    })
    .catch(async () => {
      console.log(`Failed to fetch ${moduleListHref}, returning fallback JSON`);
      return import(
        /* webpackChunkName: "fallbackWixCodeTypesModulesJSON" */ '@wix/wix-code-types/dist/jsons/wixModules.json'
      ).then((source) => source.default);
    });
};

export const fetchComponentsData = async (): Promise<ComponentsData> => {
  const fetchComponentsDataHref = createUrl(
    WIX_CODE_TYPES.URL,
    WIX_CODE_TYPES.PATHS.COMPONENTS_DATA,
  );

  const fedopsLogger = fedops.getLogger();
  fedopsLogger.interactionStarted(fedops.interactions.fetchComponentsData);

  return httpClient
    .get(fetchComponentsDataHref, { timeout: SERVER_TIMEOUT_MS })
    .then((source) => {
      fedopsLogger.interactionEnded(fedops.interactions.fetchComponentsData);

      return source.data;
    })
    .catch(async () => {
      console.log(
        `Failed to fetch ${fetchComponentsDataHref}, returning fallback JSON`,
      );
      return import(
        /* webpackChunkName: "fallbackWixCodeTypesComponentsDataJSON" */ '@wix/wix-code-types/dist/jsons/componentsData.json'
      ).then((source) => source.default);
    });
};

export const fetchFullTypes = async (
  isBeta: boolean = false,
): Promise<FullWixCodeTypesJSON> => {
  const fullTypesHref = createUrl(
    WIX_CODE_TYPES.URL,
    !isBeta
      ? WIX_CODE_TYPES.PATHS.FULL_TYPES
      : WIX_CODE_TYPES.PATHS.FULL_TYPES_BETA,
  );

  const fedopsLogger = fedops.getLogger();
  fedopsLogger.interactionStarted(fedops.interactions.fetchFullWixCodeTypes);

  return httpClient
    .get(fullTypesHref, { timeout: SERVER_TIMEOUT_MS })
    .then((source) => {
      fedopsLogger.interactionEnded(fedops.interactions.fetchFullWixCodeTypes);

      return source.data;
    })
    .catch(async () => {
      console.log(`Failed to fetch ${fullTypesHref}, returning fallback JSON`);
      // literal string is needed here for webpack to pick it up
      return import(
        /* webpackChunkName: "fallbackWixCodeTypesFullTypesJSON" */ '@wix/wix-code-types/dist/jsons/fullWixCodeTypes.json'
      ).then((source) => source.default);
    });
};

export const NPM_PACKAGE_TYPES_BASE_URL = `https://editor.wix.com/_serverless/wix-code-types-bundler-service/npm-package-types`;
const NPM_SERVER_TIMEOUT_MS = 5 * 1000;

const isValidHttpStatus = (status: number) => status >= 200 && status < 400;
export async function getRemoteNpmTypeDeclaration(
  packageName: string,
  packageVersion: string,
) {
  const typesHttpClient = new HttpClient({
    maxRedirects: 2,
    validateStatus: isValidHttpStatus,
    timeout: NPM_SERVER_TIMEOUT_MS,
  });
  const typesURL = `${NPM_PACKAGE_TYPES_BASE_URL}/${packageName}@${packageVersion}`;
  const typeDeclarationResponse = await typesHttpClient.request({
    url: typesURL,
    method: 'GET',
    // The following are so we can disable pre-flight and prevent CORS error in our case.
    xsrfCookieName: 'DISABLE-XSRF-COOKIE', // Not relevant in our case
    withCredentials: false,
    disableWixHeaders: true,
  });

  return typeDeclarationResponse.data;
}

export default {
  fetchFullTypes,
  fetchModulesMap,
  fetchEventHandlersList: fetchComponentsData,
  getRemoteNpmTypeDeclaration,
};
