import React from 'react';
import _ from 'lodash';
import * as biEvents from '../bi/biEvents';
import ElementEventsSectionComp from './ElementEventsSectionComp/ElementEventsSectionComp';

export default _.once(({ legacyDependencies }) => {
  const { constants, helpIds, platform, stateManagement, util } =
    legacyDependencies;
  const t = util.translate;

  function getBehaviorDef(editorAPI, editedComp) {
    const compType = editorAPI.components.getType(editedComp);
    const name = _.includes(
      platform.constants.CONTROLLER_COMPONENT_TYPES,
      compType,
    )
      ? 'runAppCode'
      : 'runCode';
    return editorAPI.behaviors.getDefinition(name);
  }

  function buildBehaviorObject(editorAPI, editedComp, handlerName) {
    const behaviorDef = getBehaviorDef(editorAPI, editedComp);
    behaviorDef.params = {
      callbackId: handlerName,
      compId: editedComp.id,
    };
    return behaviorDef;
  }

  function getSavedBehaviorByEventType(
    editorAPI,
    editedComp,
    eventType,
    savedBehaviors,
  ) {
    if (!editedComp) {
      return null;
    }
    const showOnAllPages = editorAPI.components.isShowOnAllPages(editedComp);

    const behaviorDef = getBehaviorDef(editorAPI, editedComp);
    const behaviorParams = {
      action: {
        name: eventType,
      },
      behavior: {
        name: behaviorDef.name,
        type: behaviorDef.type,
        targetId: getBehaviorTargetRef(editorAPI, editedComp).id,
      },
    };
    if (showOnAllPages) {
      delete behaviorParams.behavior.targetId;
    }
    return _.find(savedBehaviors, behaviorParams);
  }

  function getBehaviorTargetRef(editorAPI, editedComp) {
    return editorAPI.wixCode.getWidgetRef(editedComp);
  }

  function getEventHandlerName(behaviorObj) {
    return _.get(behaviorObj, 'behavior.params.callbackId');
  }

  function getInputState(hasBehavior, eventType, currentlyEdited) {
    if (currentlyEdited === eventType) {
      return 'editing';
    }
    return hasBehavior ? 'exist' : 'none';
  }

  async function addEventHandler(editedComp, eventType, eventName) {
    this.props.sendBi(
      biEvents.eventClicked({
        siteId: this.props.siteId,
        compId: editedComp.id,
        compType: this.props.compType,
        eventName: eventType,
      }),
    );

    const editorAPI = this.props.editorAPI;

    const nickName = editorAPI.components.code.getNickname(editedComp);
    const compType = editorAPI.components.getType(editedComp);
    const currentPageId = editorAPI.dsRead.pages.getFocusedPageId();
    const showOnAllPages = editorAPI.components.isShowOnAllPages(editedComp);

    const handlerDefaultName =
      await editorAPI.wixCode.events.getDefaultEventHandlerName(
        currentPageId,
        showOnAllPages,
        eventName,
        compType,
        nickName,
      );
    this.setState({ currentlyEdited: eventType, handlerDefaultName });
  }

  function navigateToMethod(editedComp, eventType) {
    const editorAPI = this.props.editorAPI;
    const currentPageId = editorAPI.dsRead.pages.getFocusedPageId();
    const showOnAllPages = editorAPI.components.isShowOnAllPages(editedComp);
    const savedBehaviorByEventType = getSavedBehaviorByEventType(
      editorAPI,
      editedComp,
      eventType,
      this.props.savedBehaviors,
    );
    const eventHandlerName = getEventHandlerName(savedBehaviorByEventType);

    this.props.sendBi(
      biEvents.navigateToHandler({
        siteId: this.props.siteId,
        compId: editedComp.id,
        compType: this.props.compType,
        eventName: eventType,
        handlerName: eventHandlerName,
      }),
    );

    editorAPI.wixCode.ui
      .setFocusOnEventHandler(currentPageId, showOnAllPages, eventHandlerName)
      .then(null, handleNavigateToMethodPromiseRejection.bind(this, eventType));
  }

  function handleNavigateToMethodPromiseRejection(eventType) {
    this.setState({ navigationErrorForEventType: eventType });
  }

  function updateHandlerNameAndGenerateCode(
    { type: eventType, handlerArgs, description: eventDescription },
    handlerName,
  ) {
    const editedComp = this.props.editedComp;

    this.props.sendBi(
      biEvents.handlerAdded({
        siteId: this.props.siteId,
        compId: editedComp.id,
        compType: this.props.compType,
        eventName: eventType,
        handlerName,
      }),
    );

    const editorAPI = this.props.editorAPI;
    const isNameValid =
      editorAPI.wixCode.events.validateEventHandlerName(handlerName) ===
      editorAPI.wixCode.events.EVENT_HANDLER_NAME_VALIDATIONS.VALID;

    if (!isNameValid || _.isEmpty(handlerName)) {
      this.setState({ currentlyEdited: null, handlerDefaultName: null });
      return;
    }

    const behaviorTargetRef = getBehaviorTargetRef(editorAPI, editedComp);
    const behavior = buildBehaviorObject(editorAPI, editedComp, handlerName);
    const action = {
      // should be generated via API as well
      type: 'comp',
      name: eventType,
    };
    editorAPI.behaviors.update(editedComp, action, behaviorTargetRef, behavior);

    const currentPageId = editorAPI.dsRead.pages.getFocusedPageId();
    const showOnAllPages = editorAPI.components.isShowOnAllPages(editedComp);

    showMobileOnlyCodeNotificationIfNeeded(editorAPI);
    const comments =
      '// This function was added from the Properties & Events panel. To learn more, visit http://wix.to/UcBnC-4\n';
    editorAPI.wixCode.events.generateEventHandlerCode(
      currentPageId,
      showOnAllPages,
      handlerName,
      handlerArgs,
      eventDescription,
      comments,
    );
    this.setState({ currentlyEdited: null, handlerDefaultName: null });
  }

  function showMobileOnlyCodeNotificationIfNeeded(editorAPI) {
    if (!editorAPI.isMobileEditor()) {
      return;
    }
    const dontShowAgainKey =
      constants.USER_PREFS.MOBILE_EDITOR.WIX_CODE.MOBILE_ONLY_CODE
        .DONT_SHOW_AGAIN;
    const store = editorAPI.store;
    const dispatch = store.dispatch;
    const getSiteUserPreferences = (key) =>
      stateManagement.editorPlugins.userPreferences.selectors.getSiteUserPreferences(
        key,
      )(store.getState());
    const setSiteUserPreferences = (key, value) =>
      dispatch(
        stateManagement.editorPlugins.userPreferences.actions.setSiteUserPreferences(
          key,
          value,
        ),
      );

    if (!getSiteUserPreferences(dontShowAgainKey)) {
      dispatch(
        stateManagement.editorPlugins.notifications.actions.showUserActionNotification(
          {
            message: 'Notifications_Mobile_Code_Text',
            title: 'Notifications_Mobile_Code_Text',
            type: 'info',
            link: {
              caption: 'Notifications_Learn_More_Link',
              onNotificationLinkClick: () =>
                editorAPI.panelManager.openHelpCenter(
                  helpIds.NOTIFICATIONS.MOBILE_ONLY_CODE,
                ),
            },
          },
        ),
      );
      setSiteUserPreferences(dontShowAgainKey, true);
    }
  }

  function removeEventHandler(eventType) {
    const editorAPI = this.props.editorAPI;
    const editedComp = this.props.editedComp;
    const savedBehaviorObj = getSavedBehaviorByEventType(
      editorAPI,
      editedComp,
      eventType,
      this.props.savedBehaviors,
    );
    if (!savedBehaviorObj) {
      return;
    }

    this.props.sendBi(
      biEvents.handlerRemoved({
        siteId: this.props.siteId,
        compId: editedComp.id,
        compType: this.props.compType,
        eventName: eventType,
        handlerName: getEventHandlerName(savedBehaviorObj),
      }),
    );

    const behaviorTargetRef = editorAPI.components.get.byId(
      savedBehaviorObj.behavior.targetId,
    );
    editorAPI.behaviors.remove(
      editedComp,
      savedBehaviorObj.action,
      behaviorTargetRef,
      savedBehaviorObj.behavior,
    );
    this.setState({ currentlyEdited: null, handlerDefaultName: null });
  }

  const EVENT_NAME_VALIDATION_MSGS = {
    INVALID_FN_NAME:
      'WixCode_PropertiesPanel_HandlerName_Validation_InvalidJSFunctionName',
    TOO_LONG: 'WixCode_PropertiesPanel_HandlerName_Validation_TooLong',
  };

  function onErrorTooltipClosed() {
    this.setState({ navigationErrorForEventType: null });
  }

  class ElementEventsSection extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        currentlyEdited: null,
        handlerDefaultName: null,
      };
      this.getEvents = this.getEvents.bind(this);
      this.getEventProps = this.getEventProps.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (_.isEqual(nextProps.editedComp, this.props.editedComp)) {
        return;
      }
      this.setState({ currentlyEdited: null, handlerDefaultName: null });
    }

    getEvents() {
      return _(this.props.eventList)
        .map(this.getEventProps)
        .sortBy(({ eventLabel }) => eventLabel.toLowerCase())
        .value();
    }

    getEventProps(event) {
      const editorAPI = this.props.editorAPI;
      const editedComp = this.props.editedComp;
      const savedBehaviorObj = getSavedBehaviorByEventType(
        editorAPI,
        editedComp,
        event.type,
        this.props.savedBehaviors,
      );
      const inputState = getInputState(
        !!savedBehaviorObj,
        event.type,
        this.state.currentlyEdited,
      );
      const handlerName =
        this.state.currentlyEdited === event.type
          ? this.state.handlerDefaultName
          : getEventHandlerName(savedBehaviorObj);
      const asyncHandlerNameValidator = (_handlerName, onSuccess, onError) => {
        const validationResult =
          editorAPI.wixCode.events.validateEventHandlerName(_handlerName);
        if (
          validationResult ===
          editorAPI.wixCode.events.EVENT_HANDLER_NAME_VALIDATIONS.VALID
        ) {
          onSuccess();
        } else {
          onError(util.translate(EVENT_NAME_VALIDATION_MSGS[validationResult]));
        }
      };

      return {
        eventLabel: `${event.name}( )`,
        eventType: event.type,
        eventDescription: event.description,
        handlerInputState: inputState,
        handlerName,
        onAddEventHandlerClicked: addEventHandler.bind(
          this,
          editedComp,
          event.type,
          event.name,
        ),
        onHandlerNameClicked: navigateToMethod.bind(
          this,
          editedComp,
          event.type,
          event.name,
        ),
        onHandlerNameChanged: updateHandlerNameAndGenerateCode.bind(
          this,
          event,
        ),
        onCancelEditHandlerName: () => {
          this.setState({ currentlyEdited: null, handlerDefaultName: null });
        },
        onRemoveHandlerClicked: removeEventHandler.bind(
          this,
          event.type,
          event.name,
        ),
        asyncHandlerNameValidator,
        isErrorTooltipActive:
          event.type === this.state.navigationErrorForEventType,
        onErrorTooltipClosed: onErrorTooltipClosed.bind(this),
      };
    }

    render() {
      return (
        <ElementEventsSectionComp
          events={this.getEvents()}
          disabled={this.props.disabled}
          t={t}
        />
      );
    }
  }

  const mapEditorApiToProps = ({ editorAPI }, { editedComp }) => ({
    editorAPI,
    sendBi: (event) => editorAPI.bi.event(event.def, event.params),
    siteId: editorAPI.dsRead.generalInfo.getSiteId(),
    compType: editorAPI.components.getType(editedComp),
  });

  const ElementEventsSectionWithEditorAPI = util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapEditorApiToProps,
  )(ElementEventsSection);

  return ElementEventsSectionWithEditorAPI;
});
