import './eventControl.global.scss';
import React from 'react';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

const NAV_ERROR_MSG = 'WixCode_prop_panel_navigate_to_handler_function_error';

import once_ from 'lodash/once';

export default once_(({ baseUI, constants }) =>
  createReactClass({
    displayName: 'eventControl',

    propTypes: {
      eventLabel: PropTypes.string.isRequired,
      eventType: PropTypes.string.isRequired,
      eventDescription: PropTypes.string.isRequired,
      handlerInputState: PropTypes.oneOf(['none', 'editing', 'exist'])
        .isRequired,
      handlerName: PropTypes.string,
      onHandlerNameClicked: PropTypes.func,
      onAddEventHandlerClicked: PropTypes.func.isRequired,
      onHandlerNameChanged: PropTypes.func.isRequired,
      onRemoveHandlerClicked: PropTypes.func.isRequired,
      isHandlerNameValid: PropTypes.func.isRequired,
      getNameInvalidMessage: PropTypes.func.isRequired,
      isReadOnly: PropTypes.bool,
      isErrorTooltipActive: PropTypes.bool,
      onErrorTooltipClosed: PropTypes.func,
    },

    onKeyDown(evt) {
      if (
        evt.key !== 'Enter' ||
        !this.props.isHandlerNameValid(evt.target.value)
      ) {
        return;
      }

      this.props.onHandlerNameChanged(evt);
    },

    getTooltipProps() {
      const commonProps = {
        styleType: 'small',
        closeTriggers: [constants.UI.TOOLTIP.TRIGGERS.MOUSE_LEAVE],
      };
      if (this.props.isErrorTooltipActive) {
        return _.assign(commonProps, {
          value: NAV_ERROR_MSG,
          shouldTranslate: true,
          delay: '0',
          onClose: this.props.onErrorTooltipClosed,
          openTriggers: [constants.UI.TOOLTIP.TRIGGERS.IMMEDIATE],
          alignment: constants.UI.TOOLTIP.ALIGNMENT.TOP,
        });
      }
      return _.assign(commonProps, {
        value: this.props.handlerName,
        shouldTranslate: false,
        delay: '600',
        openTriggers: [constants.UI.TOOLTIP.TRIGGERS.MOUSE_ENTER],
        alignment: constants.UI.TOOLTIP.ALIGNMENT.BOTTOM,
      });
    },

    render() {
      return (
        <div
          className={classNames({
            'component-event': true,
            'add-event-symbol': this.props.handlerInputState === 'none',
            'add-event-label': this.props.handlerInputState !== 'none',
          })}
        >
          <span
            className={classNames({
              label: true,
              'label-switch': true,
              'event-label': true,
              clickable: this.props.onHandlerNameClicked,
            })}
            key="eventLabel"
            type={this.props.eventType}
          >
            {this.props.eventLabel}
          </span>

          <div className="event-handler-wrapper">
            {this.props.handlerInputState === 'none' && (
              <div className="add-event-wrapper" key="showAdd">
                <baseUI.tooltip
                  value={this.props.eventDescription}
                  styleType="small"
                  alignment={constants.UI.TOOLTIP.ALIGNMENT.BOTTOM}
                  ref="eventDescriptionTooltip"
                  shouldTranslate={false}
                >
                  <span>
                    <Symbol
                      name="infoIcon"
                      className="infoIcon"
                      ref="eventDescriptionIcon"
                    />
                  </span>
                </baseUI.tooltip>
                {!this.props.isReadOnly && (
                  <Symbol
                    onClick={this.props.onAddEventHandlerClicked}
                    ref="eventPlusIcon"
                    key="eventPlusIcon"
                    name="addActionCentered"
                    className="plusIcon"
                  />
                )}
              </div>
            )}
            {this.props.handlerInputState === 'editing' && (
              <baseUI.textInput
                data-aid="add-event-handler-input"
                key="showInput"
                ref="eventNameInput"
                shouldTranslate={false}
                focus={true}
                onBlur={this.props.onHandlerNameChanged}
                value={this.props.handlerName}
                validator={this.props.isHandlerNameValid}
                invalidMessage={this.props.getNameInvalidMessage}
                blurOnEnterKey={false}
                onKeyDown={this.onKeyDown}
              />
            )}
            <baseUI.tooltip {...this.getTooltipProps()}>
              <div className="handler-name-wrapper">
                {this.props.handlerInputState === 'exist' && (
                  <span
                    onClick={this.props.onHandlerNameClicked}
                    className={classNames({
                      'handler-name': true,
                      clickable: this.props.onHandlerNameClicked,
                    })}
                    ref="eventLabel"
                    key="showLabel"
                  >
                    {this.props.handlerName}
                  </span>
                )}
              </div>
            </baseUI.tooltip>
          </div>
          {!this.props.isReadOnly &&
            this.props.handlerInputState === 'exist' && (
              <div className="delete-handler-wrapper">
                {!this.props.isReadOnly &&
                  this.props.handlerInputState === 'exist' && (
                    <Symbol
                      key="deleteBtn"
                      name="removeAction"
                      className="delete-handler"
                      ref="deleteIcon"
                      onClick={this.props.onRemoveHandlerClicked}
                    />
                  )}
              </div>
            )}
        </div>
      );
    },
  }),
);
