import { Button, Heading, TextButton, Text } from '@wix/wix-base-ui';
import React, { useEffect } from 'react';
import s from './SpiWizard.scss';
import { useTranslation } from '@wix/wix-i18n-config';
import { CustomModal } from '../CustomModal/CustomModal';
import { dataHooks } from '../../consts/dataHooks';
import { SpiProviderType } from '../../SpiTypes';
import {
  learnMoreEvent,
  panelOpenedEvent,
  startNowEvent,
} from '../../utils/reportEvents';
import { BiLoggerAPI } from '@wix/wix-code-plugin-contracts';

export const WizardStep1: React.FC<{
  spiType: SpiProviderType;
  onConfirm: () => void;
  onClose: () => void;
  onHelp: () => void;
  biLoggerAPI: BiLoggerAPI;
}> = ({ spiType, onConfirm, onClose, onHelp, biLoggerAPI }) => {
  const [t] = useTranslation();

  const onAccept = () => {
    onConfirm();
    biLoggerAPI.report(startNowEvent({ spiType: spiType.type }));
  };

  const learnMore = () => {
    const link = t('Wizard_Phase_1_Learn_More_URL');
    biLoggerAPI.report(
      learnMoreEvent({ origin: 'pop_up', related_link: link }),
    );
    window.open(link, '_blank');
  };

  useEffect(() => {
    biLoggerAPI.report(panelOpenedEvent());
  }, [biLoggerAPI]);

  return (
    <CustomModal
      dataHook={dataHooks.SPI_WIZARD_STEP_1}
      {...{ onClose, onHelp }}
    >
      <div className={s.wizardContainer}>
        <div className={s.wizardContainer}>
          <div className={s.smallMargin} />
          <Heading
            appearance="h2"
            shouldTranslate={false}
            dataHook={dataHooks.SPI_WIZARD_STEP_1_HEADING}
          >
            {t(spiType.translationKeys.wizardPhase1Title)}
          </Heading>
          <div className={s.smallMargin} />
          <div className={s.maxWidth}>
            <Text
              skin="secondary"
              shouldTranslate={false}
              enableEllipsis={false}
            >
              {t(spiType.translationKeys.wizardPhase1Subtitle1)}
            </Text>
          </div>
        </div>
        <div className={s.margin} />
        <div className={s.footerContainer}>
          <Button
            className={s.smallMargin}
            onClick={onAccept}
            dataHook={dataHooks.SPI_WIZARD_STEP_1_START_BUTTON}
          >
            {t('Wizard_Phase_1_Start')}
          </Button>
          <TextButton shouldTranslate={false} onClick={learnMore}>
            {t('Wizard_Phase_1_Learn_More')}
          </TextButton>
        </div>
      </div>
    </CustomModal>
  );
};
