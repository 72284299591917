import * as React from 'react';
import s from './PackageVersionPicker.scss';
import { ListSectionTitle } from '../listSectionTitle/ListSectionTitle';
import { CodeReusePkgVersion } from '../../../../codeReuseServerAPI';
import { selectPackageVersion } from '../../../packagesModalActions';
import { getSelectedVersion } from '../../../packagesModalSelectors';
import PackagesListItem from '../packagesListItem/PackagesListItem';
import { TEST_VERSION_NAME } from '../../../../packagesModalContext';
import dataHooks from '../../../../dataHooks';
import { useDispatch, useSelector } from '@wix/wix-code-common-components';

interface PackageTestVersionPickerProps {
  testVersion: CodeReusePkgVersion;
}
export const PackageTestVersionPicker: React.FC<
  PackageTestVersionPickerProps
> = ({ testVersion }) => {
  const dispatch = useDispatch();
  const selectedPackageVersion = useSelector(getSelectedVersion);

  return (
    <div data-hook={dataHooks.TEST_VERSION}>
      <div className={s.titleContainer}>
        <ListSectionTitle title="Package_Manager_Versions_Test_Version_Title" />
      </div>
      <PackagesListItem
        onClick={() => dispatch(selectPackageVersion(TEST_VERSION_NAME))}
        isSelected={
          testVersion.version === selectedPackageVersion ||
          TEST_VERSION_NAME === selectedPackageVersion
        }
        versionInfo={{
          version: TEST_VERSION_NAME,
          publishedDate: testVersion.createdDate,
          releaseNotes: testVersion.releaseNotes,
          isLatest: false,
        }}
      />
    </div>
  );
};
