import { i18n, initI18n } from '@wix/wix-i18n-config';

export let initializedI18n: i18n;

export const initi18n = async (languageCode: string) => {
  const i18nInstance = initI18n({
    locale: languageCode,
    disableAutoInit: true,
    asyncMessagesLoader: (locale) =>
      import(`./assets/locale/messages_${locale}.json`),
  });

  await i18nInstance.init();
  initializedI18n = i18nInstance;

  return i18nInstance;
};
