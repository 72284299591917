// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".G3Fj42{display:flex;width:30px;height:30px;justify-content:center;align-items:center}.WA4umU{display:flex;padding-left:0}.t4nd2T{height:617px}.DxUg6I{display:flex;justify-content:flex-end;align-items:center;gap:12px}.Ck_cvm{max-width:500px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgWrapper": "G3Fj42",
	"providersContainer": "WA4umU",
	"modal": "t4nd2T",
	"modalFooter": "DxUg6I",
	"container": "Ck_cvm"
};
export default ___CSS_LOADER_EXPORT___;
