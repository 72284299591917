import React from 'react';
import { TabItem } from '@/commonComponents/IdeTabsContainer/TabItem';
import { RealEstateIds } from '@/infra/dealer/config';
import { DealerOfferGUID, dealerService } from '@/infra/dealer/dealerService';
import ideTabsActions from '../../actions/ideTabsActions';
import { dealerUserPreferences } from '@/infra/dealer/dealerUserPreference';
import bi from '@/legacy/bi/bi';
import { ideTabSelect } from '@/legacy/bi/biEvents';
import devContextUtilsCreator from '@/utils/devContext';
import {
  useDispatch,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import { useTranslate } from '@/infra/i18n/useTranslate';
import { EmbeddedTab as EmbeddedTabInternal } from '@wix/wix-code-plugin-contracts';

export type EmbeddedTab = EmbeddedTabInternal;

interface IdeEmbeddedTabsProps {
  embeddedTabs: EmbeddedTab[];
  selectedTabId: string;
  sendBi: (biParams: any) => void;
  tabCount: number;
  chooseTabAfterClose: (tabClosing: string, biItemName: string) => void;
  hiddenDealerOffers?: DealerOfferGUID[];
}

export const IdeEmbeddedTabs: React.FC<IdeEmbeddedTabsProps> = ({
  embeddedTabs,
  selectedTabId,
  sendBi,
  tabCount,
  chooseTabAfterClose,
  hiddenDealerOffers,
}) => {
  const { editorAPI, legacyDependenciesAPI } = useEditorLegacyAPIs();
  const { t } = useTranslate();
  const { constants, stateManagement } = legacyDependenciesAPI;
  const { setEmbeddedTabContext } = devContextUtilsCreator({
    constants,
  });
  const { hideDealerOffer, isDealerOfferHidden } = dealerUserPreferences({
    stateManagement,
  });

  const dispatch = useDispatch();
  const closeEmbeddedTab = (tabId: AnyFixMe) =>
    dispatch(ideTabsActions.closeEmbeddedTab(tabId));

  const embeddedTabClick = (embeddedTab: EmbeddedTab, biParams: AnyFixMe) => {
    const { url, biItemName, offerGuid, realEstateId } = embeddedTab;
    sendBi({
      def: bi.events.IDE_TAB_ACTION,
      params: ideTabSelect({
        origin: 'ide_tab_click',
        tabId: biItemName,
        ...biParams,
      }),
    });
    setEmbeddedTabContext(editorAPI, { url });
    if (realEstateId === RealEstateIds.PUSH_NOTIFICATION_REAL_ESTATE_ID) {
      dealerService.reportDealerOfferViewed({
        realEstateId: RealEstateIds.PUSH_NOTIFICATION_REAL_ESTATE_ID,
        offerId: offerGuid,
      });
    }
  };

  const embeddedTabClose = (embeddedTab: EmbeddedTab) => {
    const { realEstateId, offerGuid, biItemName, url } = embeddedTab;
    if (realEstateId === RealEstateIds.PUSH_NOTIFICATION_REAL_ESTATE_ID) {
      hideDealerOffer(editorAPI, offerGuid);
    }

    dealerService.reportDealerOfferClosed({
      realEstateId,
      offerId: offerGuid,
    });
    closeEmbeddedTab({ tabId: url });
    chooseTabAfterClose(url, biItemName);
  };

  const hiddenByUserTabs = (embeddedTab: EmbeddedTab) =>
    isDealerOfferHidden(editorAPI, embeddedTab.offerGuid);

  const hiddenTabs = (embeddedTab: EmbeddedTab) =>
    hiddenDealerOffers && hiddenDealerOffers.includes(embeddedTab.offerGuid);

  const getTitle = (embeddedTab: EmbeddedTab) =>
    [
      RealEstateIds.HELP_ARTICLE_TAB_REAL_ESTATE_ID,
      RealEstateIds.PUSH_NOTIFICATION_REAL_ESTATE_ID,
    ].includes(embeddedTab.realEstateId)
      ? t('WixCode_GettingStarted_TabTitle')
      : embeddedTab.title;

  return (
    <>
      {embeddedTabs
        .filter((embeddedTab) => !hiddenByUserTabs(embeddedTab))
        .filter((embeddedTab) => !hiddenTabs(embeddedTab))
        .map((embeddedTab) => (
          <TabItem
            tabId={embeddedTab.url}
            label={getTitle(embeddedTab)}
            selected={selectedTabId === embeddedTab.url}
            key={embeddedTab.offerGuid}
            alwaysShowCloseButton={true}
            onTabPin={() => {}}
            onTabClick={() =>
              embeddedTabClick(embeddedTab, {
                tabCount,
                tabIndex: tabCount - 1,
              })
            }
            onClose={() => embeddedTabClose(embeddedTab)}
            iconSymbol={null}
            isUnpinned={false}
          />
        ))}
    </>
  );
};
