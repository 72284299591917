import { WixCodeStoreAPI } from '@wix/wix-code-plugin-contracts';
import ideTabsStateReader from '@/codeEditor/tabs/selectors/ideTabsStateReader';
import fileActions from '@/infra/redux-state/actions/fileActions';
import { packageJsonActions } from '@/infra/redux-state/actions/packageJsonActions';
import ideTabsActions from '@/codeEditor/tabs/actions/ideTabsActions';
import codeStateReader from '../reducers/codeStateReader';
import pageEditorStateReader from '../reducers/pageEditorStateReader';
import pageActionsCreator from '../actions/pageActions';
import storeManager from './storeManager';
import { nodeDoubleClick } from '../actions/fileTreeActions';
import {
  selectTab,
  clickSeeAllVersions,
} from '@/toExtract/packages/packagesModal/packagesModalActions';
import {
  wixCodeReduxContext,
  connectToStores,
} from '@wix/wix-code-common-components';
import fileSystemActions from '../actions/fileSystemActions';
import { treeSectionActions } from '@/sidebar/filesTree/filesTree/treeSectionReducer';

export const createWixCodeStoreAPI = (): WixCodeStoreAPI => {
  return {
    dispatch: (...args: any) =>
      storeManager.getStore().dispatch.apply(null, args),
    getStore: () => storeManager.getStore(),
    connectToStores,
    selectors: {
      fileSystem: {
        getFile: codeStateReader.getFile,
      },
      page: {
        isPageCollapsed: codeStateReader.isPageCollapsed,
        pageActionsCreator,
        getRenamingPageId: pageEditorStateReader.getRenamingPageId,
      },
    },
    treeActions: {
      nodeDoubleClick,
    },
    packagesActions: {
      selectTab,
      clickSeeAllVersions,
    },
    packageJsonActions,
    wixCodeReduxContext,
    ideTabsStateReader,
    codeStateReader,
    fileSystemActions,
    fileActions,
    ideTabsActions,
    treeSectionActions,
  };
};
