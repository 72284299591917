import * as _ from 'lodash';
import { ActionType } from '@/infra/redux-state/actions/types';
import { NpmPackageInfo } from '../../../../../packagesModalContext';
import { packageChangelogAction, packageStatus } from '../consts';
import { defaultNpmPackagesDescriptors } from '../top10NpmPackages.const';

export type NpmModuleReducerState = {
  installingInProgress: Record<string, any>;
  packages: NpmPackage;
  searchKeyword: string;
  selectedTab: 'available';
  promptError: boolean;
  promptSubmitRequest: boolean;
  indexSearchIsFetching: boolean;
  indexSearchErrorFetching: boolean;
  indexSearchKeyword: string | undefined;
  indexSearchPackages: NpmPackageInfo[];
  fetchPackagesDone: boolean;
  selectedPkgData: NpmPackageInfo | null;
  defaultNpmPackages?: DefaultNpmPackages;
};

type Changelog = {
  action: keyof typeof packageStatus;
  status: keyof typeof packageChangelogAction;
  comment?: {
    reson?: string;
    description?: string;
  };
  timestamp: number;
};

export type NpmPackage = {
  [pkgName: string]: {
    name: string;
    versions: {
      [version: string]: {
        changelog: Changelog[];
        comment: {
          reason?: string;
          description?: string;
          made_available_in_version?: string;
        };
        status: keyof typeof packageChangelogAction;
      };
    };
  };
};

export type DefaultNpmPackages = {
  descriptors: typeof defaultNpmPackagesDescriptors;
  pkgInfo: NpmPackageInfo[];
};
export type LoadRemotePackages = {
  packages: NpmPackage | undefined;
  type: ActionType.LOAD_REMOTE_PACKAGES;
};
export type InstallModuleStart = {
  npmModule: { name: string; version?: string };
  type: ActionType.INSTALL_MODULE_START;
};
export type InstallModuleDone = {
  npmModule: { name: string };
  type: ActionType.INSTALL_MODULE_DONE;
};
export type SearchKeywordChanged = {
  keyword: string;
  type: ActionType.SEARCH_KEYWORD_CHANGED;
};
type TabSelected = { tabId: string; type: ActionType.TAB_SELECTED };
export type IndexSearchRequest = {
  indexSearchKeyword: string;
  type: ActionType.INDEX_SEARCH_REQUEST;
};
export type IndexSearchSuccess = {
  indexSearchPackages: NpmPackageInfo[];
  type: ActionType.INDEX_SEARCH_SUCCESS;
};
export type LoadDefaultNpmPackages = {
  defaultNpmPackages: DefaultNpmPackages;
  type: ActionType.LOAD_DEFAULT_NPM_PACKAGES;
};
export type SetSelectedNpmPkgData = {
  selectedPkgData: NpmPackageInfo;
  type: ActionType.SET_SELECTED_NPM_PKG_DATA;
};
export type ErrorInstallingPackages = {
  type: ActionType.ERROR_INSTALLING_PACKAGES;
};
type OpenSubmitNpmRequest = {
  type: ActionType.OPEN_SUBMIT_NPM_REQUEST;
};
type CloseSubmitNpmRequest = {
  type: ActionType.CLOSE_SUBMIT_NPM_REQUEST;
};
export type IndexSearchFailure = {
  type: ActionType.INDEX_SEARCH_FAILURE;
};
export type PackagesInitDone = {
  type: ActionType.PACKAGES_INIT_DONE;
};
export type PackagesInitStart = {
  type: ActionType.PACKAGES_INIT_START;
};

type ModulesReducerAction =
  | LoadRemotePackages
  | InstallModuleStart
  | InstallModuleDone
  | SearchKeywordChanged
  | TabSelected
  | IndexSearchRequest
  | IndexSearchSuccess
  | LoadDefaultNpmPackages
  | SetSelectedNpmPkgData
  | ErrorInstallingPackages
  | OpenSubmitNpmRequest
  | CloseSubmitNpmRequest
  | IndexSearchFailure
  | PackagesInitDone
  | PackagesInitStart;

const initialState: NpmModuleReducerState = {
  installingInProgress: {},
  packages: {},
  searchKeyword: '',
  selectedTab: 'available',
  promptError: false,
  promptSubmitRequest: false,
  indexSearchIsFetching: false,
  indexSearchErrorFetching: false,
  indexSearchKeyword: undefined,
  indexSearchPackages: [],
  fetchPackagesDone: false,
  selectedPkgData: null,
};

const modulesReducer = (state = initialState, action: ModulesReducerAction) => {
  switch (action.type) {
    case ActionType.LOAD_REMOTE_PACKAGES:
      return {
        ...state,
        packages: action.packages || [],
      };
    case ActionType.INSTALL_MODULE_START:
      return {
        ...state,
        installingInProgress: {
          ...state.installingInProgress,
          [action.npmModule.name]: action.npmModule.version
            ? { [action.npmModule.version]: true }
            : true,
        },
      };
    case ActionType.INSTALL_MODULE_DONE:
      return {
        ...state,
        installingInProgress: _.omit(
          state.installingInProgress,
          action.npmModule.name,
        ),
      };
    case ActionType.SEARCH_KEYWORD_CHANGED:
      return {
        ...state,
        searchKeyword: action.keyword,
      };
    case ActionType.TAB_SELECTED:
      return {
        ...state,
        selectedTab: action.tabId,
        promptSubmitRequest: false,
      };
    case ActionType.ERROR_INSTALLING_PACKAGES:
      return {
        ...state,
        promptError: true,
      };
    case ActionType.OPEN_SUBMIT_NPM_REQUEST:
      return {
        ...state,
        promptSubmitRequest: true,
      };
    case ActionType.CLOSE_SUBMIT_NPM_REQUEST:
      return {
        ...state,
        promptSubmitRequest: false,
      };
    case ActionType.INDEX_SEARCH_REQUEST:
      return {
        ...state,
        indexSearchIsFetching: true,
        indexSearchErrorFetching: false,
        indexSearchKeyword: action.indexSearchKeyword,
      };
    case ActionType.INDEX_SEARCH_SUCCESS:
      return {
        ...state,
        indexSearchIsFetching: false,
        indexSearchErrorFetching: false,
        indexSearchPackages: action.indexSearchPackages,
        indexSearchKeyword: undefined,
      };
    case ActionType.INDEX_SEARCH_FAILURE:
      return {
        ...state,
        indexSearchIsFetching: false,
        indexSearchErrorFetching: true,
      };
    case ActionType.PACKAGES_INIT_DONE:
      return {
        ...state,
        fetchPackagesDone: true,
      };
    case ActionType.PACKAGES_INIT_START:
      return {
        ...state,
        fetchPackagesDone: false,
      };
    case ActionType.LOAD_DEFAULT_NPM_PACKAGES:
      return {
        ...state,
        defaultNpmPackages: action.defaultNpmPackages,
      };
    case ActionType.SET_SELECTED_NPM_PKG_DATA:
      return {
        ...state,
        selectedPkgData: action.selectedPkgData,
      };

    default:
      return state;
  }
};

export default modulesReducer;
