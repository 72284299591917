import React from 'react';
import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import AiPanel from './components/AiPanel/AiPanel';
import { Experiments } from '@wix/wix-code-common';

export const AiAssistanceEntryPoint: EntryPoint = {
  name: 'AI Assistance Entry Point',
  getDependencyAPIs() {
    return [
      WixCodeEditorAdapterAPI,
      APIKeys.ExperimentsAPI,
      APIKeys.MenuAPI,
      APIKeys.PanelsAPI,
      APIKeys.EditorComponentsAPI,
      APIKeys.ClassicEditorAPI,
      APIKeys.WixCodeAppAPI,
      APIKeys.LegacyEditorDependencies,
    ];
  },
  declareAPIs() {
    return [APIKeys.AiAssistancePublicAPI];
  },
  attach(shell: Shell) {
    shell.contributeAPI(APIKeys.AiAssistancePublicAPI, () => {
      const editorComponentsAPI = shell.getAPI(APIKeys.EditorComponentsAPI);
      const wixCodeEditorAdapterAPI = shell.getAPI(WixCodeEditorAdapterAPI);
      const classicEditorAPI = shell.getAPI(APIKeys.ClassicEditorAPI);
      const legacyDependenciesAPI = shell.getAPI(
        APIKeys.LegacyEditorDependencies,
      );
      const experimentsAPI = shell.getAPI(APIKeys.ExperimentsAPI);
      const wixCodeAppAPI = shell.getAPI(APIKeys.WixCodeAppAPI);

      const { editorAPI } = classicEditorAPI;
      return {
        openAIPanel: () => {
          wixCodeEditorAdapterAPI.modalAPI.showModal(
            (closeModal) => (
              <AiPanel
                legacyDependenciesAPI={legacyDependenciesAPI}
                editorComponentsAPI={editorComponentsAPI}
                closePanel={closeModal}
                editorAPI={editorAPI}
                wixCodeSignedInstance={wixCodeAppAPI.getSignedInstance()}
                useStream={experimentsAPI.isOpen(
                  Experiments.AiAssistanceUseStream,
                )}
              />
            ),
            { closeOnClickOutside: true, backdrop: true },
          );
        },
      };
    });
  },
};
