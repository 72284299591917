import * as React from 'react';
import { TooltipOnEllipsis } from '@wix/wix-base-ui';
import s from './tbfPage.scss';
import dataHooks from '../../dataHooks';

export const BackToFunctionLink: React.FC<{
  navigateToFunctionOrigin: () => void;
  functionSignature: string;
}> = ({ functionSignature, navigateToFunctionOrigin }) => (
  <span
    className={s.pageFuncSignature}
    data-hook={dataHooks.TBF_FUNCTION_SIGNATURE}
    onClick={navigateToFunctionOrigin}
  >
    <TooltipOnEllipsis>{functionSignature}</TooltipOnEllipsis>
  </span>
);
