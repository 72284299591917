import findReplaceActions from '@/infra/redux-state/actions/findReplaceActions';
import findReplaceStateReader from '@/infra/redux-state/reducers/findReplaceStateReader';
import * as codeEditor from '@wix/wix-code-code-editor';
import fileActions from '@/infra/redux-state/actions/fileActions';
import once_ from 'lodash/once';
import { Store } from '@wix/wix-code-common-components';
import { EditorAPI, CodeEditorAPI } from '@wix/wix-code-plugin-contracts';

export default once_(
  ({
    codeIntelligence,
  }: {
    codeIntelligence: CodeEditorAPI['codeIntelligence'];
  }) => {
    return (store: Store, editorApi: EditorAPI) => ({
      findReplace: {
        setVisible: (visible: boolean) =>
          store.dispatch(findReplaceActions.toggleVisible(visible)),
        isVisible: () => findReplaceStateReader.isVisible(store.getState()),
      },
      getContentAssistForPage: (pageId: string) =>
        codeIntelligence.getContentAssistForPage(pageId),
      setSelection: (fileId: AnyFixMe, selection: AnyFixMe) => {
        store.dispatch(fileActions.setSelection(fileId, selection));
      },
      definitions: codeEditor.definitionsApi,
    });
  },
);
