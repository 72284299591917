import * as React from 'react';
import { CodeReusePkgTreeContext } from '../CodeReusePkgTreeContext';
import s from '../codeReusePkgTree.scss';
import {
  AvailableCodeReusePkg,
  PkgAvailableVersionType,
} from '@/toExtract/packages/packagesModalContext';
import { getMissingDependencyId } from './InstallDependencyPimple';
import { FileSubTreeDataSource } from '../../../../filesTree/FileSubTree';
import { Preloader } from '@wix/wix-base-ui';
import dataHooks from '../../../../dataHooks';
import {
  isInstalledReusePackageByOthers,
  isInstalledTestVersion,
  isReusePackageByVelo,
  isSameVersion,
} from '@/toExtract/packages/utils';
import {
  CodeReusePkgSuffixContextMenu,
  VELO_TREE_QUICK_ACTIONS,
} from './CodeReusePkgSuffixContextMenu';
import { CodeReuseSuffixPimple } from './CodeReuseSuffixPimple';
import { CodeReuseSuffixSymbol } from './CodeReuseSuffixSymbol';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import { generateRootContextMenuStructure } from './utils';
import { PkgTreeContext } from '../../PkgTreeContext';
import { useTranslation } from '@wix/wix-code-common-components';

interface CodeReusePkgSuffixProps {
  isRoot: boolean;
  isContextMenuOpen: boolean;
  handleContextMenuToggle: (isOpen: boolean) => any;
  actionSelectedHandler: (action: VELO_TREE_QUICK_ACTIONS) => any;
  dataSource: FileSubTreeDataSource;
  isTreeHovered: boolean;
  canEdit?: boolean;
}

export interface CodeReusePkgTreeShouldAlwaysShowSuffixParams {
  isRoot: boolean;
  isTreeHovered: boolean;
  isContextMenuOpen: boolean;
}

export const CodeReusePkgShouldAlwaysShowSuffix =
  (
    latestVersion: AvailableCodeReusePkg,
    version: string,
    editorAPI: EditorAPI,
  ) =>
  (params: CodeReusePkgTreeShouldAlwaysShowSuffixParams): boolean => {
    const { isRoot, isTreeHovered, isContextMenuOpen } = params;
    const upToDate = latestVersion && version === latestVersion.version;
    return !(
      upToDate &&
      isRoot &&
      !isTreeHovered &&
      !isContextMenuOpen &&
      !getMissingDependencyId(editorAPI, latestVersion)
    );
  };

export const CodeReusePkgSuffix: React.FC<CodeReusePkgSuffixProps> = ({
  isRoot,
  handleContextMenuToggle,
  isContextMenuOpen,
  isTreeHovered,
  actionSelectedHandler,
  dataSource,
  canEdit,
}) => {
  const { latestVersion, pkg, packagesService } = React.useContext(
    CodeReusePkgTreeContext,
  );
  const { isBlocksCombinedMode } = React.useContext(PkgTreeContext);
  const { isByOthers } = React.useContext(CodeReusePkgTreeContext);
  const [t] = useTranslation();

  const { isFolder, name } = dataSource;
  const installed = isBlocksCombinedMode
    ? pkg
    : packagesService.getInstalledPkg(name);

  const isTestVersion = !!isInstalledTestVersion(installed);

  const canUpdate =
    !!latestVersion &&
    !isSameVersion(pkg.version, latestVersion.version) &&
    !isTestVersion &&
    latestVersion.availableVersionType !== PkgAvailableVersionType.TEST_ONLY;

  const status = latestVersion?.status || installed?.status;
  const isLoading =
    status && ['INSTALLING', 'UPDATING', 'NOT_INSTALLED'].includes(status);

  const builtByOthers = isBlocksCombinedMode
    ? isByOthers
    : isInstalledReusePackageByOthers(name);
  const appMode = isReusePackageByVelo(name)
    ? 'by-velo'
    : builtByOthers
    ? 'by-others'
    : 'private-app';

  const contextMenuStructure = generateRootContextMenuStructure(
    appMode,
    actionSelectedHandler,
    canUpdate,
    t,
    isBlocksCombinedMode,
    canEdit,
  );

  return isLoading ? (
    <div
      className="preloader-container-hack"
      data-hook={dataHooks.CODE_REUSE_LOADER}
    >
      <Preloader className="tiny" />
    </div>
  ) : (
    <div className={s.suffixWrapper}>
      <CodeReuseSuffixPimple
        pkgName={name}
        canUpdate={canUpdate}
        isRoot={isRoot}
      />
      <CodeReuseSuffixSymbol
        isFolder={isFolder}
        isRoot={isRoot}
        pkg={pkg}
        treeNodeLabel={name}
      />
      <CodeReusePkgSuffixContextMenu
        isContextMenuOpen={isContextMenuOpen}
        handleContextMenuToggle={handleContextMenuToggle}
        actionSelectedHandler={actionSelectedHandler}
        pkgName={name}
        canUpdate={canUpdate}
        isTreeHovered={isTreeHovered}
        isRoot={isRoot}
        generateRootContextMenuStructure={contextMenuStructure}
      />
    </div>
  );
};
