// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UxuAau{background-color:#fff;text-align:left;border-top:1px solid #dfe5eb;border-bottom:1px solid #dfe5eb;padding:10px 20px;display:flex;flex-wrap:wrap}.gwjsYV{width:auto;color:#868aa5;margin-right:5px}.YJDTLJ{margin-bottom:3px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-title": "UxuAau",
	"sectionTitle": "UxuAau",
	"section-sub-title": "gwjsYV",
	"sectionSubTitle": "gwjsYV",
	"learn-more-link": "YJDTLJ",
	"learnMoreLink": "YJDTLJ"
};
export default ___CSS_LOADER_EXPORT___;
