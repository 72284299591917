import {
  syncContentChangesPopUpAction,
  unableToSyncContentChangesPopUpAction,
  syncContentChangesSuccessful,
  localCodeChangesSyncToLiveEditor,
} from '@wix/bi-logger-platform/v2';
import { BiLoggerAPI } from '@wix/wix-code-plugin-contracts';
import { EDITOR_VIEW_MODE } from './biConsts';

type SyncChangesEventParams = {
  getRevision: () => string;
  dontShowAgain?: boolean;
  biLoggerAPI: BiLoggerAPI;
};
export type SyncChangesPopUpEventsParams = {
  action: string;
  dontShowAgain?: boolean;
};
type SyncChangesSuccessfulEventParans = {
  success: boolean;
};
type CodeChangesSyncToLiveEditorActionParams = {
  isInPreviewMode: boolean;
};
export type LocalEditorBIEvents = {
  syncChangesPopUpEvent: ({
    action,
    dontShowAgain,
  }: SyncChangesPopUpEventsParams) => void;
  syncChangesFailedPopUpAction: ({
    action,
  }: SyncChangesPopUpEventsParams) => void;
  syncChangesSuccessfulEvent: ({
    success,
  }: SyncChangesSuccessfulEventParans) => void;
  codeChangesSyncToLiveEditorAction: ({
    isInPreviewMode,
  }: CodeChangesSyncToLiveEditorActionParams) => void;
};

export const createLocalEditorBIEvents = ({
  getRevision,
  biLoggerAPI: bi,
}: SyncChangesEventParams) => {
  const getSiteRevisionNumber = () => {
    return Number(getRevision());
  };
  const syncChangesPopUpEvent = ({
    action,
    dontShowAgain,
  }: SyncChangesPopUpEventsParams) => {
    bi.report(
      syncContentChangesPopUpAction({
        siterevision: getSiteRevisionNumber(),
        action,
        dontShowAgain,
      }),
    );
  };
  const syncChangesFailedPopUpAction = ({
    action,
  }: SyncChangesPopUpEventsParams) => {
    bi.report(
      unableToSyncContentChangesPopUpAction({
        siterevision: getSiteRevisionNumber(),
        action,
      }),
    );
  };
  const syncChangesSuccessfulEvent = ({
    success,
  }: SyncChangesSuccessfulEventParans) => {
    bi.report(
      syncContentChangesSuccessful({
        siterevision: getSiteRevisionNumber(),
        success,
      }),
    );
  };
  const codeChangesSyncToLiveEditorAction = ({
    isInPreviewMode,
  }: CodeChangesSyncToLiveEditorActionParams) => {
    const editorMode = isInPreviewMode
      ? EDITOR_VIEW_MODE.PREVIEW
      : EDITOR_VIEW_MODE.EDIT;
    bi.report(
      localCodeChangesSyncToLiveEditor({
        editor_view_mode: editorMode,
        siterevision: getSiteRevisionNumber(),
      }),
    );
  };
  return {
    syncChangesPopUpEvent,
    syncChangesFailedPopUpAction,
    syncChangesSuccessfulEvent,
    codeChangesSyncToLiveEditorAction,
  };
};
