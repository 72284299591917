export default function createCodeEditorApi() {
  let api, mountedFileId;
  const queuedActions = new Map();

  const triggerActions = (fileId) => {
    const actions = queuedActions.get(fileId) || [];
    queuedActions.set(fileId, []);
    actions.forEach((action) => action(api));
  };

  return {
    setApi(newApi) {
      api = newApi;
    },
    setFileId(newFileId) {
      mountedFileId = newFileId;

      triggerActions(mountedFileId);
    },
    dispose() {
      api = null;
      mountedFileId = null;
    },
    getApi() {
      return api;
    },
    performActionOnMount(fileId, action) {
      if (fileId === mountedFileId) {
        return action(api);
      }

      const currentActions = queuedActions.get(fileId) || [];
      queuedActions.set(fileId, currentActions.concat(action));
    },
    clearActions() {
      queuedActions.clear();
    },
  };
}
