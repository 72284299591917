import { captureError } from '@/infra/monitoring';
import requestPackage from './requestPackage';
import biEvents from '@/legacy/bi/events';
import {
  REQUEST_PACKAGE_SUBMITTED,
  REQUEST_PACKAGE_SUBMITTING,
  REQUEST_PACKAGE_SUBMITTING_ERROR,
  REQUEST_PACKAGE_INPUT_CHANGE,
  REQUEST_PACKAGE_SET_VERSION_VALIDATION_ERROR,
  CLOSE_SUBMIT_NPM_REQUEST,
} from '../types';

import {
  getRequestPackageName,
  getRequestPackageVersion,
  getRequestPackageReason,
  getRequestPackageVersions,
  getRequestPackageVersionsValidationError,
} from '../selectors/requestPackageSelectors';

import { requestValidationState } from '../consts';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import { RequestPackageState } from '../reducers/requestPackageReducer';
import { addJustRequestedPkg } from '../../../../packagesModalActions';

const getValidationErrorFromVersion = (
  version: AnyFixMe,
  versions: AnyFixMe,
) => {
  if (!version) {
    return requestValidationState.ERROR_VERSION_IS_EMPTY;
  }
  if (versions.available.some((_v: AnyFixMe) => _v === version)) {
    return requestValidationState.ERROR_VERSION_ALREADY_AVAILABLE;
  }
  if (!versions.nonAvailable.some((_v: AnyFixMe) => _v === version)) {
    return requestValidationState.ERROR_VERSION_DOES_NOT_EXIST;
  }
};

const getVersionValidationError =
  () => async (dispatch: AnyFixMe, getState: AnyFixMe) => {
    const state = getState().npmPackageRequest;
    const version = getRequestPackageVersion(state);
    const versions = getRequestPackageVersions(state);
    const versionValidationError = getValidationErrorFromVersion(
      version,
      versions,
    );

    dispatch({
      type: REQUEST_PACKAGE_SET_VERSION_VALIDATION_ERROR,
      versionValidationError,
    });
  };

const requestPackageInputChanged =
  (data: Partial<Record<keyof RequestPackageState, string>>) =>
  async (dispatch: AnyFixMe, getState: AnyFixMe) => {
    const state = getState().npmPackageRequest;
    dispatch({
      type: REQUEST_PACKAGE_INPUT_CHANGE,
      data,
    });
    const error = getRequestPackageVersionsValidationError(state);
    if (error) {
      dispatch(getVersionValidationError());
    }
  };

const closeSubmitRequest = () => (dispatch: AnyFixMe) => {
  dispatch({
    type: CLOSE_SUBMIT_NPM_REQUEST,
  });
};

const submitRequestPackage =
  () =>
  async (dispatch: AnyFixMe, getState: AnyFixMe, editorAPI: EditorAPI) => {
    const state = getState().npmPackageRequest;
    const version = getRequestPackageVersion(state);
    const versions = getRequestPackageVersions(state);
    const versionValidationError = getValidationErrorFromVersion(
      version,
      versions,
    );

    if (versionValidationError) {
      dispatch(getVersionValidationError());
      return;
    }

    const name = getRequestPackageName(state);
    const reason = getRequestPackageReason(state);

    editorAPI.bi.event(biEvents.PACKAGE_REQUEST_SUBMITTED, {
      item_name: name,
      item_version: version,
      reason,
    });
    dispatch({ type: REQUEST_PACKAGE_SUBMITTING });

    try {
      await requestPackage(
        { instance: editorAPI.wixCode.getClientSpec().instance },
        { name, version, reason },
      );
      dispatch({ type: REQUEST_PACKAGE_SUBMITTED });
      dispatch(addJustRequestedPkg(name));
    } catch (error) {
      dispatch({ type: REQUEST_PACKAGE_SUBMITTING_ERROR });
      captureError(error);
    }
  };

export default {
  submitRequestPackage,
  requestPackageInputChanged,
  closeSubmitRequest,
};
