import React from 'react';
import OrganizationName from './OrganizationName/OrganizationName';
import RepositoryName from './RepositoryName/RepositoryName';
import styles from './RepositoryInfo.scss';
import { GithubUser } from '../../../../types/GithubTypes';

const RepositoryInfo: React.FC<{
  translate: (key: string) => string;
  onAccountChange: (value: any) => void;
  gitHubUserInfo: GithubUser;
  onRepoNameChange: (value: string, isValid: boolean) => void;
  userAccount: string;
  repoName: string;
  doesRepoNameExist: boolean;
}> = ({
  translate: t,
  onAccountChange,
  gitHubUserInfo,
  onRepoNameChange,
  userAccount,
  repoName,
  doesRepoNameExist,
}) => {
  return (
    <div className={styles.repositoryInfoContainer}>
      <OrganizationName
        translate={t}
        userAccount={userAccount}
        onAccountChange={onAccountChange}
        gitHubUserInfo={gitHubUserInfo}
      />
      <RepositoryName
        translate={t}
        repoName={repoName}
        onChange={onRepoNameChange}
        doesRepoNameExist={doesRepoNameExist}
      />
    </div>
  );
};

export default RepositoryInfo;
