// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D2u86Q{display:flex}.Q51Uwi{margin-left:5px}.ooaO0B{display:flex;align-items:center;color:#116dff}.xRp4ez{width:97%}.t98XGC{padding:18px;display:flex;flex-direction:column;width:180px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"schema-field-container": "D2u86Q",
	"schemaFieldContainer": "D2u86Q",
	"margin-left": "Q51Uwi",
	"marginLeft": "Q51Uwi",
	"suffix-container": "ooaO0B",
	"suffixContainer": "ooaO0B",
	"tree-node": "xRp4ez",
	"treeNode": "xRp4ez",
	"popover-content": "t98XGC",
	"popoverContent": "t98XGC"
};
export default ___CSS_LOADER_EXPORT___;
