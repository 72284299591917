import React from 'react';
import { consts } from '@wix/wix-code-consts';
import { fileSystem as fileSystemCommon } from '@wix/wix-code-common';
import { ReadOnlyFileSymbol } from '@/sidebar/packagesTab/pkgTree/codeReusePkgTree/treeSuffix/ReadOnlyFileSymbol';
import { codeReusePkgsSelectors } from '@/toExtract/packages/packagesView/packagesViewSelectors';
import { PrivateReadOnlySymbol } from '@/sidebar/packagesTab/pkgTree/codeReusePkgTree/treeSuffix/PrivateReadOnlySymbol';
import {
  useEditorLegacyAPIs,
  useSelector,
} from '@wix/wix-code-common-components';
import BI_EVENTS from '@/legacy/bi/events';
import { useTranslate } from '@/infra/i18n/useTranslate';

interface IdeCodeTabSuffixProps {
  tabId: string;
}

export const IdeCodeTabSuffix: React.FC<IdeCodeTabSuffixProps> = ({
  tabId,
}) => {
  const { editorAPI } = useEditorLegacyAPIs();
  const { t } = useTranslate();
  const myPackages = useSelector(codeReusePkgsSelectors.installed.myPackages);

  const isReadMe = tabId.endsWith(consts.README_FILE_NAME);
  const isPackage = fileSystemCommon.codePackages.isPackageFile(tabId);

  const hasNoSuffix = !isPackage || isReadMe;

  if (hasNoSuffix) {
    return null;
  }

  const isPrivate = myPackages.some((pkg) => tabId.startsWith(pkg.name));

  const HELP_URL = t('Left_Tree_Components_Readme_Tooltip_LearnMore_URL');

  return isPrivate ? (
    <PrivateReadOnlySymbol />
  ) : (
    <ReadOnlyFileSymbol
      HELP_URL={HELP_URL}
      helpLinkText={t('Left_Tree_Components_Readme_Tooltip_LearnMore')}
      tooltipText={t('Left_Tree_Components_Readme_Tooltip')}
      onClick={() => {
        editorAPI.bi.event(BI_EVENTS.CODE_REUSE_ADD_DEPENDENCY_CLICK, {
          related_link: HELP_URL,
          link_name: 'learn_more',
          link_type: 'resource',
          origin: tabId,
        });
      }}
    />
  );
};
