import {
  cloneAndDevelopClicked,
  githubDisconnectAction,
  gitHubDisconnectStatus,
  gitHubOnboardingAction,
  leftTreeClickInMenuSettingOptionItem,
  leftTreeClickOnAnItem,
  disconnectModalShown as disconnectModalShownEvent,
} from '@wix/bi-logger-platform/v2';
import { GithubPrivateAPI } from '../githubPrivateAPI';
import {
  CONNECT_TO_GITHUB_MODAL_STEPS,
  MODALS_ACTIONS,
  ITEM_TYPE,
} from './biConsts';

type ActionReporterParam = {
  action: string;
  referralUrl?: string;
  sectionName?: string;
};

type DisconnectActionParam = {
  action: string;
  referralUrl?: string;
  sectionName?: string;
  errorType?: string;
};
type DisconnectStatusActionParam = {
  success: boolean;
  errorType?: string;
};
type CloseModalActionParam = {
  step: string;
};
type GithubLeftTreeTabActionParam = {
  itemName: string;
};
type GithubTabContextMenuActionsParam = {
  menuEntryPoint: string;
};
type InstallAppActionsParam = {
  action: string;
  githubAPI: GithubPrivateAPI;
};

export const createSuccessfulConnectionEvent = (repoName: string) => {
  const successfulConnectionAction = ({
    action,
    sectionName,
  }: ActionReporterParam) => {
    return gitHubOnboardingAction({
      step: CONNECT_TO_GITHUB_MODAL_STEPS.SUCCESSFUL_CONNECTION,
      action,
      repoName,
      sectionName,
    });
  };
  return { successfulConnectionAction };
};
export const signInAction = ({ action, referralUrl }: ActionReporterParam) => {
  return gitHubOnboardingAction({
    step: CONNECT_TO_GITHUB_MODAL_STEPS.SIGN_IN,
    action,
    referral_url: referralUrl,
  });
};
export const installAppAction = ({
  action,
  githubAPI,
}: InstallAppActionsParam) => {
  const githubRepository = githubAPI.getGithubRepository();
  const gitUserName =
    githubAPI.getGithubUser()?.userName! ||
    githubRepository?.repositoryOwner.login;
  const repoName = githubRepository?.repositoryName;
  const description = githubRepository?.repositoryDescription!;
  return gitHubOnboardingAction({
    step: CONNECT_TO_GITHUB_MODAL_STEPS.INSTALL,
    action,
    gitUserName,
    repoName,
    description,
  });
};
export const createRepoModalActions = ({ action }: ActionReporterParam) => {
  return gitHubOnboardingAction({
    action,
    step: CONNECT_TO_GITHUB_MODAL_STEPS.CREATE,
  });
};
export const createRepoFailed = () => {
  return gitHubOnboardingAction({
    step: CONNECT_TO_GITHUB_MODAL_STEPS.CREATE_REPO_FAILED,
  });
};

export const cloneAndDevelopeAction = (githubAPI: GithubPrivateAPI) => {
  const githubRepository = githubAPI.getGithubRepository();
  const gitUserName =
    githubAPI.getGithubUser()?.userName! ||
    githubRepository?.repositoryOwner.login;
  const repoName = githubRepository?.repositoryName;
  return cloneAndDevelopClicked({ gitUserName, repoName });
};

export const closeModalAction = ({ step }: CloseModalActionParam) => {
  return gitHubOnboardingAction({ step, action: MODALS_ACTIONS.X });
};

export const createDisconnectFromGithubActions = (
  githubAPI: GithubPrivateAPI,
) => {
  const githubRepository = githubAPI.getGithubRepository();
  const gitUserName =
    githubAPI.getGithubUser()?.userName! ||
    githubRepository?.repositoryOwner.login;
  const repoName = githubRepository?.repositoryName;
  const disconnectFromGithubModalAction = ({
    action,
    errorType,
  }: DisconnectActionParam) => {
    return githubDisconnectAction({ action, gitUserName, repoName, errorType });
  };
  const disconnectStatusAction = ({
    success,
    errorType,
  }: DisconnectStatusActionParam) => {
    return gitHubDisconnectStatus({
      success,
      gitUserName,
      repoName,
      errorType,
    });
  };
  const disconnectModalShown = ({ errorType }: { errorType?: string }) => {
    return disconnectModalShownEvent({ gitUserName, repoName, errorType });
  };
  return {
    disconnectFromGithubModalAction,
    disconnectStatusAction,
    disconnectModalShown,
  };
};

export const githubLeftTreeTabAction = ({
  itemName,
}: GithubLeftTreeTabActionParam) => {
  const itemType = ITEM_TYPE.GITHUB;
  return leftTreeClickOnAnItem({
    item_type: itemType,
    item_name: itemName,
  });
};
export const githubTabContextMenuActions = ({
  menuEntryPoint,
}: GithubTabContextMenuActionsParam) => {
  const itemName = 'github_overview';
  return leftTreeClickInMenuSettingOptionItem({
    item_name: itemName,
    menu_entry_name: menuEntryPoint,
  });
};
export const beforeConnectModalAction = ({ action }: ActionReporterParam) => {
  return gitHubOnboardingAction({
    step: CONNECT_TO_GITHUB_MODAL_STEPS.BEFORE_CONNECTION,
    action,
  });
};
