import React from 'react';
import { Badge, TreeListItem, Text, IconButton } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import { leftTreeClickInMenuPlusOptionSection } from '@wix/bi-logger-platform/v2';
import { Add } from '@wix/wix-ui-icons-common/classic-editor';
import styles from './SectionEmptyState.scss';
import dataHooks from '../../dataHooks';
import { InfoIcon } from '../InfoIcon/InfoIcon';
import { useBiLogger } from '../../../biLoggerContext';
import { constants } from '../../../constants';
import { ReadOnlyModeContext } from '@wix/wix-code-common-components';

interface SectionEmptyStateProps {
  onCtaClick: () => void;
  shouldShowBadge: boolean;
}

export const SectionEmptyState: React.FC<SectionEmptyStateProps> = ({
  onCtaClick,
  shouldShowBadge,
}) => {
  const [t] = useTranslation();
  const biLoggerAPI = useBiLogger();
  const { readOnlyMode } = React.useContext(ReadOnlyModeContext);
  const isAddGlobalCssDisabled = readOnlyMode.cssEditing.addGlobalCss;

  const ctaClickHandler = () => {
    if (!isAddGlobalCssDisabled) {
      onCtaClick();
      biLoggerAPI.report(
        leftTreeClickInMenuPlusOptionSection({
          menu_entry_name: 'NEW_CSS_FILE',
          section_name: constants.styles_folder,
        }),
      );
    }
  };

  return (
    <TreeListItem
      disabled={isAddGlobalCssDisabled}
      dataHook={dataHooks.emptyState.ctaWrapper}
      label={
        <Text
          disabled={isAddGlobalCssDisabled}
          dataHook={dataHooks.emptyState.cta}
          skin="link"
          size="small"
          enableEllipsis={false}
        >
          {t('WixCode_TreeView_CSS_AddGlobalCss_LinkText')}
        </Text>
      }
      icon={
        <IconButton
          disabled={isAddGlobalCssDisabled}
          className={styles.noBackground}
          skin="light"
        >
          <Add />
        </IconButton>
      }
      depth={0}
      alwaysShowSuffix
      size="small"
      onClick={ctaClickHandler}
      suffix={
        <div className={styles.iconsContainer}>
          {shouldShowBadge && (
            <Badge
              size="tiny"
              skin="standard"
              value={t('WixCode_TreeView_CSS_AddGlobalCss_NewBadge')}
              dataHook={dataHooks.emptyState.badge}
            />
          )}
          <InfoIcon className={styles.infoIcon} />
        </div>
      }
    />
  );
};
