import React from 'react';
import GithubTabIcon from '../components/GithubTabIcon/GithubTabIcon';
import createGithubTab from '../components/GithubTab/GithubTab';
import { TranslationProvider } from '../i18n';
import { GithubModalsAPI } from '../githubModalsAPI';
import {
  BiLoggerAPI,
  EditorContextAPI,
  ExperimentsAPI,
  ReadOnlyAPI,
  UserPreferencesAPI,
} from '@wix/wix-code-plugin-contracts';
import { Shell } from '@wix/wix-code-repluggable';
import { GithubPrivateAPI } from '../githubPrivateAPI';
import { BiLoggerContext } from '../context/biLoggerContext';

interface SideBarContentProps {
  shell: Shell;
  githubModalsAPI: GithubModalsAPI;
  readOnlyAPI: ReadOnlyAPI;
  githubAPI: GithubPrivateAPI;
  biLoggerAPI: BiLoggerAPI;
  userPreferencesAPI: UserPreferencesAPI;
  experimentsAPI: ExperimentsAPI;
  editorContextAPI: EditorContextAPI;
}

export const sideBarContent = ({
  shell,
  githubModalsAPI,
  readOnlyAPI,
  githubAPI,
  biLoggerAPI,
  userPreferencesAPI,
  experimentsAPI,
  editorContextAPI,
}: SideBarContentProps) => {
  const GithubTab = createGithubTab(shell, githubAPI);
  return {
    symbol: (
      <GithubTabIcon
        userPreferencesAPI={userPreferencesAPI}
        experimentsAPI={experimentsAPI}
      />
    ),
    creator: () => {
      return (
        <TranslationProvider>
          <BiLoggerContext.Provider value={biLoggerAPI}>
            <GithubTab
              githubModalsAPI={githubModalsAPI}
              readOnlyAPI={readOnlyAPI}
              githubAPI={githubAPI}
              isClassicEditor={editorContextAPI.getEditorType() === 'Classic'}
            />
          </BiLoggerContext.Provider>
        </TranslationProvider>
      );
    },
  };
};
