import * as React from 'react';
import dataHooks from '../../../../../dataHooks';
import { InstalledPackagesListSection } from './InstalledPackagesListSection';
import { codeReusePkgsSelectors } from '../../../../../packagesView/packagesViewSelectors';
import { useSelector } from '@wix/wix-code-common-components';

export const InstalledPkgsListBuilyByOthersSection: React.FC = () => {
  const builtByOthers = useSelector(codeReusePkgsSelectors.installed.byOthers);
  return (
    <InstalledPackagesListSection
      dataHook={dataHooks.INSTALLED_PKGS_BUILT_BY_OTHERS}
      sectionTitle="Package_Manager_Section_Manage_Installed_Built_By_Others"
      installedPkgs={builtByOthers}
    />
  );
};
