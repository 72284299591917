import { RootWithSlice } from '@wix/wix-code-common-components';
import { RequestPackageState } from '../reducers/requestPackageReducer';

export type State = RootWithSlice<'npmPackageRequest', RequestPackageState>;

const getRequestPackageName = (state: RequestPackageState) => state.name;
const getRequestPackageVersion = (state: RequestPackageState) => state.version;
const getRequestPackageInitialVersion = (state: RequestPackageState) =>
  state.initialVersion;
const getRequestPackageLatestVersion = (state: RequestPackageState) =>
  state.latestVersion;
const getRequestPackageVersions = (state: RequestPackageState) =>
  state.versions;
const getRequestPackageReason = (state: RequestPackageState) => state.reason;
const isRequestPackageSubmitted = (state: RequestPackageState) =>
  state.submitted;
const getRequestPackageVersionsValidationError = (state: RequestPackageState) =>
  state.versionValidationError;
const getRequestPackageDisableVersionInput = (state: RequestPackageState) =>
  state.disableVersionInput;
const isRequestPackageSubmitting = (state: RequestPackageState) =>
  state.submitting;

export {
  getRequestPackageName,
  getRequestPackageVersion,
  getRequestPackageInitialVersion,
  getRequestPackageLatestVersion,
  getRequestPackageVersions,
  getRequestPackageReason,
  isRequestPackageSubmitted,
  getRequestPackageVersionsValidationError,
  getRequestPackageDisableVersionInput,
  isRequestPackageSubmitting,
};
