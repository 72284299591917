import React from 'react';
import cx from 'classnames';
import s from './PkgActionButton.scss';
import { Button } from '@wix/wix-base-ui';
import { PackagesModalContext } from '../../../../../packagesModalContext';
import dataHooks from '../../../../../dataHooks';

export const DisabledInstallPkgButton: React.FC = () => {
  const { t } = React.useContext(PackagesModalContext);
  return (
    <Button
      dataHook={dataHooks.PKG_ACTION_BUTTON}
      className={cx(
        'btn-sm',
        'btn-inverted',
        'action-button',
        s.disabledButton,
      )}
      disabled={true}
    >
      {t('Package_Manager_Install_Button')}
    </Button>
  );
};
