import React from 'react';
import { SpiWizardContainer } from './components/SpiWizard/SpiWizard/SpiWizard';
import { initializedI18n } from './i18n';
import { createServerApi, SpiConnection } from './ServerAPI';
import { SpiContextProvider } from './SpiContext';
import {
  spiFolderName,
  spiFolderPath,
  SpiProviderType,
  SpiType,
} from './SpiTypes';
import {
  PostPublishModal,
  PostPublishModalProps,
} from './components/PostPublishModal/PostPublishModal';
import {
  DeleteSpiModal,
  DeleteSpiModalProps,
} from './components/DeleteSpiModal/DeleteSpiModal';
import {
  FileDescriptor,
  FilesTreeAPI,
  ExperimentsAPI,
  WixCodeAppAPI,
  PanelsAPI,
  UserPreferencesAPI,
  SiteAPI,
  FileSystemAPI,
  BiLoggerAPI,
  ReadOnlyAPI,
  ServiceTopology,
  ClassicEditorAPI,
} from '@wix/wix-code-plugin-contracts';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import * as state from './state';
import { deleteConnection, updateConnections } from './state/actions';
import { DuplexerStatusResponse } from './services/duplexerService';
import { experimentUtils } from '@wix/wix-code-common';

export interface SpiFolder {
  name: string;
}

export const spiFolderDisplayName = 'custom_integrations';

export const paymentProvidersPath = `${spiFolderPath}payment/`;

export const isSpiFolder = (fileName: string): boolean =>
  fileName === spiFolderName;

type SpiServerTypes = {
  [key: string]: string;
};

export const spiServerTypes: SpiServerTypes = {
  [SpiType.PAYMENTS]:
    'com.wixpress.appservice.api.ComponentType.PAYMENT_PROVIDER',
  [SpiType.SHIPPING_RATES]:
    'com.wixpress.appservice.api.ComponentType.ECOM_SHIPPING_RATES',
  [SpiType.TAX_CALCULATOR]:
    'com.wixpress.appservice.api.ComponentType.ECOM_TAX_CALCULATOR_SPI',
  [SpiType.DEFAULT_TAXATION_CATEGORY]:
      'com.wixpress.appservice.api.ComponentType.ECOM_DEFAULT_TAXATION_CATEGORY',
  [SpiType.CATALOG]: 'com.wixpress.appservice.api.ComponentType.ECOM_CATALOG',
  [SpiType.FEES]:
    'com.wixpress.appservice.api.ComponentType.ECOM_ADDITIONAL_FEES',
  [SpiType.DISCOUNTS]:
    'com.wixpress.appservice.api.ComponentType.ECOM_DISCOUNTS_TRIGGER',
  [SpiType.BOOKINGS_PRICING]:
    'com.wixpress.appservice.api.ComponentType.BOOKINGS_PRICING_PROVIDER',
  [SpiType.CUSTOM_SCOPE]:
    'com.wixpress.appservice.api.ComponentType.ECOM_CUSTOM_SCOPE',
  [SpiType.COMMUNICATION_CHANNEL]:
    'com.wixpress.appservice.api.ComponentType.COMMUNICATION_CHANNEL',
  [SpiType.ITEM_RECOMMENDATIONS]:
    'com.wixpress.appservice.api.ComponentType.ECOM_RECOMMENDATIONS_PROVIDER',
  [SpiType.COMMENTS_MODERATION]:
    'com.wixpress.appservice.api.ComponentType.COMMENTS_MODERATION_PROVIDER',
  [SpiType.BOOKINGS_EXTERNAL_CALENDAR]:
    'com.wixpress.appservice.api.ComponentType.BOOKINGS_EXTERNAL_CALENDAR_PROVIDER',
  [SpiType.VALIDATIONS]:
    'com.wixpress.appservice.api.ComponentType.ECOM_VALIDATIONS',
  [SpiType.AUTOMATION_ACTION]:
    'com.wixpress.appservice.api.ComponentType.AUTOMATIONS_ACTION_PROVIDER',
  [SpiType.PAYMENT_SETTINGS]:
    'com.wixpress.appservice.api.ComponentType.ECOM_PAYMENT_SETTINGS',
  [SpiType.CUSTOM_TABLE_RESERVATIONS]:
    'com.wixpress.appservice.api.ComponentType.CUSTOM_TABLE_RESERVATIONS_PROVIDER',
};
export interface SpiService {
  createSpi: (spiName: string, spiType: SpiProviderType) => Promise<void>;
  uninstallSpi: (spiName: string) => void;
  getStatuses: () => SpiConnection[];
  getSpisList: (folderTypePath: string) => Promise<SpiFolder[]>;
  openSpiWizard: (spiType: SpiProviderType) => void;
  spiFolderExists: () => boolean;
  openPostPublishModal: (spiStatusUpdates: SpiConnection[]) => void;
  openDeleteSpiModal: (spiPath: string) => void;
  deleteSpi: (spiPath: string) => Promise<void>;
  getSpiFolders: () => FileDescriptor[];
  refreshSpiStatusesFromServer: () => Promise<void>;
  onSpiPublish: (payload: DuplexerStatusResponse) => Promise<void>;
}

export const createSpiService = ({
  APIs,
  store,
  isLiteEditor,
}: {
  APIs: {
    filesTreeAPI: FilesTreeAPI;
    experimentsAPI: ExperimentsAPI;
    wixCodeAppAPI: WixCodeAppAPI;
    panelsAPI: PanelsAPI;
    userPreferencesAPI: UserPreferencesAPI;
    siteAPI: SiteAPI;
    fileSystemAPI: FileSystemAPI;
    serviceTopology: ServiceTopology;
    biLoggerAPI: BiLoggerAPI;
    readOnlyAPI: ReadOnlyAPI;
    wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI;
    classicEditorAPI: ClassicEditorAPI;
  };
  store: state.SpiStore;
  isLiteEditor: boolean;
}): SpiService => {
  const {
    filesTreeAPI,
    panelsAPI,
    userPreferencesAPI,
    wixCodeAppAPI,
    experimentsAPI,
    siteAPI,
    fileSystemAPI,
    serviceTopology,
    biLoggerAPI,
    readOnlyAPI,
    wixCodeEditorAdapterAPI,
    classicEditorAPI,
  } = APIs;

  const serverApi = createServerApi({
    classicEditorAPI,
    getSignedInstance: wixCodeAppAPI.getSignedInstance,
    experimentsAPI,
    serviceTopology,
  });

  const createSpi = async (
    spiName: string,
    spiType: SpiProviderType,
  ): Promise<void> => {
    await wixCodeAppAPI.ensureAppIsWriteable();

    const newSpiPath = await serverApi.createNewSpi({
      spiName,
      spiType: spiServerTypes[spiType.type],
      gridAppId: wixCodeAppAPI.getGridAppId(),
      isStudioEditor: experimentUtils.isStudioEditor(),
    });
    await fileSystemAPI.refreshFolder(newSpiPath);

    if (!isLiteEditor) {
      filesTreeAPI.expandParents(`${newSpiPath}${spiName}-config.js`);
      selectFile(`${newSpiPath}${spiName}-config.js`);
    }
    panelsAPI.closePanel();
    await refreshSpiStatusesFromServer();
  };

  const uninstallSpi = (spiName: string) => {
    throw new Error('Not Implemented' + spiName);
  };

  const refreshSpiStatusesFromServer = async () => {
    const connections = await serverApi.getSpiStatuses();
    store.dispatch(updateConnections(connections));
  };

  const getStatuses = () => {
    return state.getConnections(store.getState());
  };

  const spiFolderExists = () => {
    const backendFolders = fileSystemAPI.getChildren('backend/');
    return backendFolders.some((folder) => folder.name === spiFolderName);
  };

  const getSpiFolders = () => {
    return fileSystemAPI.getChildren(spiFolderPath);
  };

  const spiFolderTypeExists = (folderTypePath: string) => {
    const spiFolders = fileSystemAPI.getChildren(spiFolderPath);
    return spiFolders.some((folder) => folder.location === folderTypePath);
  };

  const getSpisList = async (folderTypePath: string): Promise<SpiFolder[]> => {
    if (spiFolderExists() && spiFolderTypeExists(folderTypePath)) {
      const spisFileDescriptors = fileSystemAPI.getChildren(folderTypePath);
      return spisFileDescriptors.map((file) => ({ name: file.name }));
    } else {
      return [];
    }
  };

  const withSpiContext =
    <GenericProps extends JSX.IntrinsicAttributes>(
      Component: React.FC<GenericProps>,
    ) =>
    (props: GenericProps) =>
      (
        <SpiContextProvider
          i18nInstance={initializedI18n}
          panelsAPI={panelsAPI}
          userPreferencesAPI={userPreferencesAPI}
          filesTreeAPI={filesTreeAPI}
          wixCodeAppAPI={wixCodeAppAPI}
          spiService={service}
          experimentsAPI={experimentsAPI}
          siteAPI={siteAPI}
          biLoggerAPI={biLoggerAPI}
          fileSystemAPI={fileSystemAPI}
          readOnlyAPI={readOnlyAPI}
          wixCodeEditorAdapterAPI={wixCodeEditorAdapterAPI}
        >
          <Component {...props} />
        </SpiContextProvider>
      );

  const isSameSpi = (spi1: SpiConnection, spi2: SpiConnection) => {
    return spi1.spiName === spi2.spiName && spi1.spiType === spi2.spiType;
  };

  const addPublishedSpis = (
    spiStatusUpdate: SpiConnection,
    newlyPublishedSpis: SpiConnection[],
  ) => {
    const oldStatuses = getStatuses();
    const isNewSpi = !oldStatuses.find((status) =>
      isSameSpi(status, spiStatusUpdate),
    );
    if (isNewSpi) {
      newlyPublishedSpis.push(spiStatusUpdate);
      return;
    }
    oldStatuses.forEach((spi) => {
      const isNewlyPublishedSpi =
        isSameSpi(spi, spiStatusUpdate) && !spi.isSpiPublished;
      if (isNewlyPublishedSpi) {
        newlyPublishedSpis.push(spiStatusUpdate);
      }
    });
  };

  const popPostPublishModal = (newlyPublishedSpis: SpiConnection[]) => {
    let modalOpenRetriesLimit = 0;
    const interval = setInterval(() => {
      if (panelsAPI.getOpenPanels().length === 0) {
        clearInterval(interval);
        openPostPublishModal(newlyPublishedSpis);
      }
      modalOpenRetriesLimit++;
      if (modalOpenRetriesLimit > 4) {
        clearInterval(interval);
      }
    }, 2000);
  };

  const onSpiPublish = async (payload: DuplexerStatusResponse) => {
    const newlyPublishedSpis: SpiConnection[] = [];
    const { statuses } = payload;
    statuses
      .filter((status) => status.isSpiPublished)
      .forEach((spiStatusUpdate) =>
        addPublishedSpis(spiStatusUpdate, newlyPublishedSpis),
      );
    if (
      newlyPublishedSpis.length > 0 &&
      experimentsAPI.isOpen('specs.wixCode.SPIPostPublishModal')
    ) {
      popPostPublishModal(newlyPublishedSpis);
    }
    refreshSpiStatusesFromServer();
  };

  const openSpiWizard = (spiType: SpiProviderType) => {
    const SpiWizardContribution = () => (
      <SpiWizardContainer
        i18n={initializedI18n}
        panelsAPI={panelsAPI}
        userPreferencesAPI={userPreferencesAPI}
        spiService={service}
        spiType={spiType}
        biLoggerAPI={biLoggerAPI}
      />
    );
    panelsAPI.openPanel(<SpiWizardContribution />, true);
  };

  const openPostPublishModal = (spiStatusUpdates: SpiConnection[]) => {
    const PostPublishModalContribution =
      withSpiContext<PostPublishModalProps>(PostPublishModal);
    panelsAPI.openPanel(
      <PostPublishModalContribution spiStatusUpdates={spiStatusUpdates} />,
      true,
    );
  };

  const selectFile = (filePath: string) => {
    filesTreeAPI.selectFile(filePath);
  };

  const openDeleteSpiModal = (spiPath: string) => {
    const DeleteSpiModalContribution =
      withSpiContext<DeleteSpiModalProps>(DeleteSpiModal);

    panelsAPI.openPanel(<DeleteSpiModalContribution spiPath={spiPath} />, true);
  };

  const getParent = (path: string) => {
    const parents = path
      .split('/')
      .filter((x) => x)
      .slice(0, -1);
    return parents.join('/') + '/';
  };

  const deleteSpi = async (spiPath: string) => {
    const spiTypeFolderPath = getParent(spiPath);
    panelsAPI.closePanel();
    if ((await getSpisList(spiTypeFolderPath)).length === 1) {
      await fileSystemAPI.deleteFolder(spiTypeFolderPath);
    } else {
      await fileSystemAPI.deleteFolder(spiPath);
    }
    store.dispatch(deleteConnection(spiPath));
  };

  const service: SpiService = {
    createSpi,
    uninstallSpi,
    getSpisList,
    getStatuses,
    spiFolderExists,
    openSpiWizard,
    openPostPublishModal,
    openDeleteSpiModal,
    deleteSpi,
    getSpiFolders,
    refreshSpiStatusesFromServer,
    onSpiPublish,
  };

  return service;
};
