import { captureError } from './index';

/**
 * @deprecated use import { withErrorHandling } from '@wix/wix-code-common instead';
 */
export function withErrorHandling(fn) {
  return (...args) => {
    try {
      return fn(...args);
    } catch (e) {
      captureError(e);
    }
  };
}
