import * as React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import {
  TextLabel,
  TextInput,
  TextInputMultiline,
  BackButton,
  Button,
  RichText,
  Illustration,
  Composites,
  Text,
  TextButton,
} from '@wix/wix-base-ui';

import { Dispatch, wixCodeReduxContext } from '@wix/wix-code-common-components';
import { isValidPackageName } from './utils';
import s from './RequestPackage.scss';
import requestPackageActions from '../actions/requestPackageActions';

import {
  getRequestPackageName,
  getRequestPackageVersion,
  getRequestPackageInitialVersion,
  getRequestPackageReason,
  isRequestPackageSubmitted,
  isRequestPackageSubmitting,
  getRequestPackageVersions,
  getRequestPackageVersionsValidationError,
  getRequestPackageDisableVersionInput,
  State,
} from '../selectors/requestPackageSelectors';
import { getSearchKeyword } from '../selectors/modulesSelectors';
import { requestValidationState } from '../consts';
import { PackagesModalContext } from '../../../../../packagesModalContext';
import { ListSectionTitle } from '../../listSectionTitle/ListSectionTitle';
import dataHooks from '@/sidebar/dataHooks';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import { experimentUtils } from '@wix/wix-code-common';
import { RequestPackageState } from '../reducers/requestPackageReducer';
import { AppState } from '@/infra/redux-state/reducers/rootReducer';

interface RequestPackageDispatchProps {
  requestPackageInputChanged: (
    data: Partial<Record<keyof RequestPackageState, string>>,
  ) => (dispatch: Dispatch, getState: () => State) => Promise<void>;
  submitRequestPackage: () => (
    dispatch: Dispatch,
    getState: () => State,
    editorAPI: EditorAPI,
  ) => Promise<void>;
  closeSubmitRequest: () => (dispatch: Dispatch) => void;
}

const getErrorMessage = (
  errorCode: string,
  t: (key: string, params: { [key: string]: string }) => string,
  version: string,
) => {
  switch (errorCode) {
    case requestValidationState.ERROR_VERSION_IS_EMPTY:
    case requestValidationState.ERROR_VERSION_ALREADY_AVAILABLE:
    case requestValidationState.ERROR_VERSION_DOES_NOT_EXIST:
      return t(
        'Package_Manager_Request_Popup_Version_Validation_Error_Not_Found',
        { version },
      );
    default:
      return;
  }
};

const mapStateToProps = (state: AppState): RequestPackageState => {
  const requestPackageState = state.npmPackageRequest;

  return {
    name: getRequestPackageName(requestPackageState) || getSearchKeyword(state),
    initialVersion: getRequestPackageInitialVersion(requestPackageState),
    version: getRequestPackageVersion(requestPackageState),
    reason: getRequestPackageReason(requestPackageState),
    submitted: isRequestPackageSubmitted(requestPackageState),
    submitting: isRequestPackageSubmitting(requestPackageState),
    versions: getRequestPackageVersions(requestPackageState),
    versionValidationError:
      getRequestPackageVersionsValidationError(requestPackageState),
    disableVersionInput:
      getRequestPackageDisableVersionInput(requestPackageState),
  };
};

const mapDispatchToProps: RequestPackageDispatchProps = {
  requestPackageInputChanged: requestPackageActions.requestPackageInputChanged,
  submitRequestPackage: requestPackageActions.submitRequestPackage,
  closeSubmitRequest: requestPackageActions.closeSubmitRequest,
};

type RequestPackageCompProps = RequestPackageState &
  RequestPackageDispatchProps;

const PackageRequest: React.FC<RequestPackageCompProps> = (props) => {
  const { t } = React.useContext(PackagesModalContext);
  const {
    name,
    version,
    initialVersion,
    closeSubmitRequest,
    requestPackageInputChanged,
    submitRequestPackage,
    versionValidationError,
    disableVersionInput,
    reason,
    submitting,
  } = props;

  React.useEffect(() => {
    if (!version) {
      requestPackageInputChanged({ version: initialVersion });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openKbArticle = () =>
    window.open(
      t('Packages_Modal_Npm_Request_Pkg_Label_Tooltip_Link_Url'),
      '_blank',
    );

  const submitRequestForm = () => {
    return (
      <div
        data-aid="submit-request-form"
        data-hook={dataHooks.REQUEST_NPM_PKG_CONTAINER}
        className={s.requestPackageContainer}
      >
        <Composites.ButtonLeft>
          <BackButton
            label={t('Package_Manager_Request_Popup_Back_Button_Text')}
            onClick={closeSubmitRequest}
          />
        </Composites.ButtonLeft>
        <div className={s.titleContainer}>
          <ListSectionTitle title={t('Package_Manager_Request_Popup_Title')} />
        </div>

        {experimentUtils.isNpmPkgManagerImprovements() && (
          <div className={s.descriptionContainer}>
            <Text skin="secondary" enableEllipsis={false}>
              {t('Package_Manager_Request_Submission_Information')}
            </Text>
            <TextButton onClick={openKbArticle} rel="noreferrer">
              {t(
                `Package_Manager_Popup_Available_Tab_Rejected_Request_Tooltip_Link`,
              )}
            </TextButton>
          </div>
        )}

        <Composites.TextInputLabeled>
          <TextLabel
            value={t('Package_Manager_Request_Popup_Name_Label')}
            type="T02"
          />
          <TextInput
            automationId="package-name-input"
            value={name}
            onChange={(value: string) =>
              requestPackageInputChanged({ name: value })
            }
            placeholder={t(
              'Package_Manager_Request_Popup_Name_Placeholder_Text',
            )}
            isValid={isValidPackageName(name)}
            disabled={true}
          />
        </Composites.TextInputLabeled>

        <Composites.TextInputLabeled>
          <TextLabel
            value={t('Package_Manager_Request_Popup_Version_Label')}
            type="T02"
            className={s.versionLabel}
          />
          <TextInput
            dataHook={dataHooks.REQUEST_NPM_PKG_VERSION_INPUT}
            className={s.versionInputContainer}
            value={version}
            disabled={disableVersionInput}
            placeholder={t(
              'Package_Manager_Request_Popup_Version_Placeholder_Text',
              { version: initialVersion },
            )}
            shouldTranslate={false}
            onChange={(value: string) => {
              requestPackageInputChanged({ version: value });
            }}
            hideSuccessIndication={true}
            isValid={!versionValidationError}
            invalidMessage={
              versionValidationError &&
              getErrorMessage(versionValidationError, t, initialVersion)
            }
          />
        </Composites.TextInputLabeled>
        <Composites.TextInputLabeled>
          <TextLabel
            value={t('Package_Manager_Request_Popup_Why_Label')}
            type="T02"
          />
          <TextInputMultiline
            className={s.requestReason}
            value={reason}
            placeholder={t(
              'Package_Manager_Request_Popup_Why_Placeholder_Text',
            )}
            onChange={(value: string) =>
              requestPackageInputChanged({ reason: value })
            }
            automationId="package-reason-input"
          />
        </Composites.TextInputLabeled>

        <Composites.TextInputLabeled>
          <Button
            className={cx(s.submitButton, 'btn-md')}
            onClick={submitRequestPackage}
            automationId="send-request-button"
            dataHook={dataHooks.REQUEST_NPM_PKG_SUBMIT_BTN}
            disabled={
              submitting ||
              !isValidPackageName(name) ||
              !!versionValidationError
            }
          >
            {t('Package_Manager_Request_Popup_Button')}
          </Button>
          {!experimentUtils.isNpmPkgManagerImprovements() && (
            <RichText className={s.requestSubmissionInfo} type="T02">
              {t('Package_Manager_Request_Submission_Information')}
            </RichText>
          )}
        </Composites.TextInputLabeled>
      </div>
    );
  };

  const submitRequestSuccessPanel = () => {
    return (
      <div
        data-aid="submit-request-success"
        className={s.submitSuccessPanel}
        data-hook={dataHooks.REQUEST_NPM_PKG_SUCCESS_CONTAINER}
      >
        <Illustration>
          <Symbol name="corvid_npm__success_request_package" />
        </Illustration>
        <Composites.RichText className={s.submitedText}>
          <TextLabel
            type="T09"
            value={t('Package_Manager_Request_Popup_Submitted_Title')}
            className={s.submitedTitle}
          />
          <RichText type="T07" className={s.submitedMessage}>
            {t('Package_Manager_Request_Popup_Submitted_Text')}
          </RichText>
        </Composites.RichText>

        <div className={s.submitDoneButton}>
          <Button
            className="btn-md"
            onClick={closeSubmitRequest}
            automationId="done-request-button"
          >
            {t('Package_Manager_Request_Popup_Submitted_Done_Button')}
          </Button>
        </div>
      </div>
    );
  };
  return props.submitted ? submitRequestSuccessPanel() : submitRequestForm();
};

const RequestPackage = connect(mapStateToProps, mapDispatchToProps, null, {
  context: wixCodeReduxContext,
})(PackageRequest as any);

export { RequestPackage };
