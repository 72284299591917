import * as React from 'react';
import s from './PackageVersionPicker.scss';
import itemStyle from '../packagesListItem/PackagesListItem.scss';
import { ListSectionTitle } from '../listSectionTitle/ListSectionTitle';
import dataHooks from '../../../../dataHooks';
import { selectPackageVersion } from '../../../packagesModalActions';
import { getSelectedVersion } from '../../../packagesModalSelectors';
import PackagesListItem from '../packagesListItem/PackagesListItem';
import { NpmPackageVersion } from '@/toExtract/packages/packagesModalContext';
import { useDispatch, useSelector } from '@wix/wix-code-common-components';

interface NpmPackageVersionsPickerProps {
  publicVersions: NpmPackageVersion[];
}
export const NpmPackageVersionsPicker: React.FC<
  NpmPackageVersionsPickerProps
> = ({ publicVersions }) => {
  const dispatch = useDispatch();
  const selectedPackageVersion = useSelector(getSelectedVersion);

  const isSelected = (version: NpmPackageVersion) =>
    version.value === selectedPackageVersion;

  return (
    <>
      <div className={s.titleContainer}>
        <ListSectionTitle title="Package_Manager_Versions_Built_Title" />
      </div>

      {publicVersions.map((version, index) => (
        <div
          data-hook={dataHooks.VERSION_ITEM}
          key={version.value}
          className={itemStyle.versionItem}
        >
          <PackagesListItem
            onClick={() => dispatch(selectPackageVersion(version.value))}
            isSelected={isSelected(version)}
            versionInfo={{
              version: version.value,
              isLatest: index === 0,
            }}
          />
        </div>
      ))}
    </>
  );
};
