import React from 'react';

export const StopPropagation: React.FC = ({ children }) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {children}
    </div>
  );
};
