import React from 'react';
import { APIKeys, EntryPoint } from '@wix/wix-code-repluggable';
import { WhatsNew } from '@/codeEditor/components/whatsNew/WhatsNew';
import devContextUtilsCreator from '@/utils/devContext';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';

export const EmbeddedTabsEntryPoint: EntryPoint = {
  name: 'Embedded Tab Entry Point',
  getDependencyAPIs() {
    return [
      APIKeys.CodeEditorAPI,
      APIKeys.ClassicEditorAPI,
      WixCodeEditorAdapterAPI,
    ];
  },
  extend(shell) {
    const codeEditorAPI = shell.getAPI(APIKeys.CodeEditorAPI);
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
    const { legacyDependencies } = shell.getAPI(WixCodeEditorAdapterAPI);
    const devContextUtils = devContextUtilsCreator(legacyDependencies);
    codeEditorAPI.contributeTabRenderer(
      shell,
      () => {
        const devContext = editorAPI.developerMode.getContext();
        return (
          <WhatsNew
            key="WhatsNewPage"
            editorAPI={editorAPI}
            siteUrl={devContext.data.url}
            translate={legacyDependencies.util.translate}
            unselectEmbeddedTab={() =>
              devContextUtils.setPageContext(editorAPI)
            }
          />
        );
      },
      () => devContextUtils.isEmbeddedTabContext(editorAPI),
    );
  },
};
