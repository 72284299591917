/**
  Redux middleware for batching updates (taken from https://github.com/acdlite/redux-batched-updates)
 */
import ReactDOM from 'react-dom';

export default function batchedUpdatesMiddleware() {
  return function (next) {
    return function (action) {
      return ReactDOM.unstable_batchedUpdates(function () {
        return next(action);
      });
    };
  };
}
