import { ActionType } from '@/infra/redux-state/actions/types';
import { AppData } from '../appDataService';
import {
  SetAppsData,
  SetAppsDataLoaded,
  SetAppsDataLoading,
} from './appDataReducer';

export function setAppsData(appsData: AppData[]): SetAppsData {
  return {
    type: ActionType.SET_APPS_DATA,
    appsData,
  };
}
export function setAppsDataLoaded(isLoaded: boolean): SetAppsDataLoaded {
  return {
    type: ActionType.SET_APPS_DATA_LOADED,
    isLoaded,
  };
}
export function setAppsDataLoading(isLoading: boolean): SetAppsDataLoading {
  return {
    type: ActionType.SET_APPS_DATA_LOADING,
    isLoading,
  };
}
