import { appDataReducerState } from './appDataReducer';

export const getAppData = (
  state: { appData: appDataReducerState },
  appDefinitionId: string,
) => state.appData.data[appDefinitionId];

export const isAppDataLoaded = (state: { appData: appDataReducerState }) =>
  state.appData.isLoaded;

export const isAppDataLoading = (state: { appData: appDataReducerState }) =>
  state.appData.isLoading;
