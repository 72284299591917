import { HttpClient } from '@wix/http-client';
import { debounce } from 'lodash';
import {
  SYNC_SITE_DEBOUNCE_MS,
  SYNC_SITE_DELAY_CALL_MS,
  SYNC_SITE_HOSTNAME,
  SYNC_SITE_OLD_HOSTNAME,
} from '../SyncSiteHandler/consts';

// 304 It's a valid response code, it means there are no pods for this gridAppId.
const NO_ACTIVE_POD_STATUS_CODE = 304;

const getSyncGridAppBaseURL = (gridAppId: string, isNewDomain: boolean) =>
  isNewDomain
    ? `https://${gridAppId}.${SYNC_SITE_HOSTNAME}`
    : `https://${gridAppId}.${SYNC_SITE_OLD_HOSTNAME}`;

const getSyncSiteURL = (gridAppId: string, isNewDomain: boolean) =>
  `${getSyncGridAppBaseURL(gridAppId, isNewDomain)}`;
const getSyncPageURL = (
  gridAppId: string,
  pageId: string,
  isNewDomain: boolean,
) => `${getSyncGridAppBaseURL(gridAppId, isNewDomain)}/page/${pageId}`;
const getSyncPageFilesURL = (gridAppId: string, isNewDomain: boolean) =>
  `${getSyncGridAppBaseURL(gridAppId, isNewDomain)}/pageFiles`;

const syncPageTypesRequestHandler = (
  httpClient: HttpClient,
  gridAppId: string,
  instance: string,
  pageId: string,
  isNewDomain: boolean,
) => {
  // we delay this call because it takes couple of milliseconds for DM services
  // to get the latest updated document
  setTimeout(() => {
    httpClient
      .post(getSyncPageURL(gridAppId, pageId, isNewDomain), { instance })
      .catch((e) => {
        if (e.response.status === NO_ACTIVE_POD_STATUS_CODE) {
          return;
        }
        throw e;
      });
  }, SYNC_SITE_DELAY_CALL_MS);
};

const syncPageFilesRequestHandler = (
  httpClient: HttpClient,
  gridAppId: string,
  instance: string,
  isNewDomain: boolean,
) => {
  // we delay this call because it takes couple of milliseconds for DM services
  // to get the latest updated document
  setTimeout(() => {
    httpClient
      .post(getSyncPageFilesURL(gridAppId, isNewDomain), { instance })
      .catch((e) => {
        if (e.response.status === NO_ACTIVE_POD_STATUS_CODE) {
          return;
        }
        throw e;
      });
  }, SYNC_SITE_DELAY_CALL_MS);
};

const syncSiteRequestHandler = debounce(
  (
    httpClient: HttpClient,
    gridAppId: string,
    instance: string,
    isNewDomain: boolean,
  ) => {
    // we delay this call because it takes couple of milliseconds for DM services
    // to get the latest updated document
    setTimeout(() => {
      httpClient
        .post(getSyncSiteURL(gridAppId, isNewDomain), { instance })
        .catch((e) => {
          if (e.response.status === NO_ACTIVE_POD_STATUS_CODE) {
            return;
          }
          throw e;
        });
    }, SYNC_SITE_DELAY_CALL_MS);
  },
  SYNC_SITE_DEBOUNCE_MS,
  {
    leading: true,
    trailing: true,
  },
);

export {
  syncSiteRequestHandler,
  syncPageTypesRequestHandler,
  syncPageFilesRequestHandler,
};
