// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WhbnJK{flex:0;display:flex;justify-content:flex-start;align-items:center}.WhbnJK .l_xUia path{fill:#3b4057}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls": "WhbnJK",
	"symbol-icon": "l_xUia",
	"symbolIcon": "l_xUia"
};
export default ___CSS_LOADER_EXPORT___;
