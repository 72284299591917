import * as React from 'react';
import { ContextMenu, ContextMenuAction } from '@wix/wix-base-ui';
import { ContextMenuItem, IconColor } from '../contextMenuItem/ContextMenuItem';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import s from './TreeContextMenu.scss';
import { DisabledTooltipSymbol } from './DisabledTooltipSymbol';

export type MenuItemSection = TreeContextMenuItemProps[];

export interface TreeContextMenuItemProps {
  automationId?: string;
  disabled?: boolean;
  icon: string | React.FunctionComponent;
  iconColor?: IconColor;
  label: string;
  onClick: () => void;
}

export interface ContextMenuStructure {
  sections: MenuItemSection[];
  seperator?: React.ReactNode;
}

interface TreeContextMenuProps {
  menuClassName: string;
  handleContextMenuToggle: (isOpen: boolean) => void;
  contextMenuStructure: ContextMenuStructure;
  contextMenuButton: string;
  isContextMenuOpen: boolean;
  className?: string;
  disabled?: boolean;
  tooltipContent: string;
  readonly: boolean;
}

const renderMenuActions = (sections: AnyFixMe, separator: AnyFixMe) => {
  const actions = [] as JSX.Element[];
  sections.forEach((section: AnyFixMe, index: AnyFixMe) => {
    actions.push(
      section.map((item: AnyFixMe) => (
        <ContextMenuAction
          className={s.contextMenuAction}
          automationId={item.automationId}
          dataHook={item.automationId}
          disabled={item.disabled}
          key={`menuItem_${item.automationId}`}
          onClick={item.onClick}
        >
          <ContextMenuItem
            icon={item.icon}
            iconColor={item.iconColor}
            label={item.label}
          />
        </ContextMenuAction>
      )),
    );

    if (separator && index !== sections.length - 1) {
      const separatorWithKey = React.cloneElement(separator, {
        key: `separator_${index}`,
      });
      actions.push(separatorWithKey);
    }
  });
  return actions;
};

export const TreeContextMenu: React.FC<TreeContextMenuProps> = ({
  contextMenuStructure: { sections, seperator },
  contextMenuButton,
  handleContextMenuToggle,
  isContextMenuOpen,
  className,
  tooltipContent,
  readonly,
}) => {
  if (readonly) {
    return (
      <DisabledTooltipSymbol
        iconName={contextMenuButton}
        key={contextMenuButton}
        tooltipContent={tooltipContent}
      />
    );
  }

  const flattened = renderMenuActions(sections, seperator);

  return (
    <ContextMenu
      dataHook="context-menu-icon"
      onToggle={handleContextMenuToggle}
      isOpen={isContextMenuOpen}
      customButton={<Symbol name={contextMenuButton} key={contextMenuButton} />}
      className={className}
    >
      {flattened.map((item) => item)}
    </ContextMenu>
  );
};
