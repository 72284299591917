import { ActionType } from '@/infra/redux-state/actions/types';

export type RequestPackageState = {
  name: string;
  version: string;
  initialVersion: string;
  latestVersion?: string;
  versions: { available: string[]; nonAvailable: string[] };
  reason: string;
  submitted: boolean;
  versionValidationError?: string;
  disableVersionInput: boolean;
  submitting: boolean;
};

export type OpenSubmitNpmRequest = {
  name: string;
  version: string;
  versions: { available: string[]; nonAvailable: string[] };
  disableVersionInput: boolean;
  type: ActionType.OPEN_SUBMIT_NPM_REQUEST;
};
type CloseSubmitNpmRequest = {
  type: ActionType.CLOSE_SUBMIT_NPM_REQUEST;
};
type RequestPackageSubmitted = {
  type: ActionType.REQUEST_PACKAGE_SUBMITTED;
};
type RequestPackageSubmitting = {
  type: ActionType.REQUEST_PACKAGE_SUBMITTING;
};
type RequestPackageSubmittingError = {
  type: ActionType.REQUEST_PACKAGE_SUBMITTING_ERROR;
};
type RequestPackageSetVersionValidationError = {
  versionValidationError: any;
  type: ActionType.REQUEST_PACKAGE_SET_VERSION_VALIDATION_ERROR;
};
type RequestPackageInputChange = {
  data: Partial<Record<keyof RequestPackageState, string>>;
  type: ActionType.REQUEST_PACKAGE_INPUT_CHANGE;
};

type RequestPackageReducerAction =
  | OpenSubmitNpmRequest
  | CloseSubmitNpmRequest
  | RequestPackageSetVersionValidationError
  | RequestPackageInputChange
  | RequestPackageSubmitted
  | RequestPackageSubmitting
  | RequestPackageSubmittingError;

const initialState: RequestPackageState = {
  name: '',
  version: '',
  initialVersion: '',
  reason: '',
  versions: { available: [], nonAvailable: [] },
  latestVersion: '',
  versionValidationError: undefined,
  disableVersionInput: false,
  submitted: false,
  submitting: false,
};
const requestPackageReducer = (
  state = initialState,
  action: RequestPackageReducerAction,
) => {
  switch (action.type) {
    case ActionType.OPEN_SUBMIT_NPM_REQUEST:
      return {
        ...initialState,
        name: action.name,
        initialVersion: action.version,
        versions: action.versions,
        disableVersionInput: action.disableVersionInput,

        version:
          action.version && action.disableVersionInput
            ? action.version
            : undefined,
      };
    case ActionType.CLOSE_SUBMIT_NPM_REQUEST:
      return {
        ...state,
      };
    case ActionType.REQUEST_PACKAGE_SUBMITTED:
      return {
        ...state,
        submitted: true,
        submitting: false,
      };
    case ActionType.REQUEST_PACKAGE_SUBMITTING:
      return {
        ...state,
        submitting: true,
      };
    case ActionType.REQUEST_PACKAGE_SUBMITTING_ERROR:
      return {
        ...state,
        submitting: false,
      };
    case ActionType.REQUEST_PACKAGE_SET_VERSION_VALIDATION_ERROR:
      return {
        ...state,
        versionValidationError: action.versionValidationError,
      };
    case ActionType.REQUEST_PACKAGE_INPUT_CHANGE:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};

export default requestPackageReducer;
