import { ScopedStore } from '@wix/wix-code-repluggable';
import {
  ClassicEditorAPI,
  ReadOnlyAPI,
  ReadOnlyContributor,
  ReadOnlyMode,
} from '@wix/wix-code-plugin-contracts';
import {
  WixCodeReadOnlyState,
  ReadOnlyActionCreators,
  createSelectors,
} from './state';

export const createReadOnlyAPI = (
  store: ScopedStore<WixCodeReadOnlyState>,
  classicEditorAPI: ClassicEditorAPI,
): ReadOnlyAPI => {
  const readOnlyModeSelectors = createSelectors(
    store.getState,
    classicEditorAPI,
  );

  return {
    setReadOnly(
      contributor: ReadOnlyContributor,
      state: Partial<ReadOnlyMode>,
    ): void {
      store.dispatch(ReadOnlyActionCreators.setReadOnly(contributor, state));
    },
    removeReadOnlyContribution(contributor: ReadOnlyContributor): void {
      store.dispatch(
        ReadOnlyActionCreators.removeReadOnlyContribution(contributor),
      );
    },
    getReadOnlyMode(): ReadOnlyMode {
      return readOnlyModeSelectors.getReadOnlyMode();
    },
  };
};
