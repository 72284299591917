import * as React from 'react';
import { FileSubTreeDataSource } from '../../../filesTree/FileSubTree';
import { connect } from 'react-redux';
import codeStateReader from '@/infra/redux-state/reducers/codeStateReader';
import { bindActionCreators } from 'redux';
import { nodeDoubleClick } from '@/infra/redux-state/actions/fileTreeActions';
import { LeftPaneContextProps } from '../../../LeftPaneContext';
import bi from '@/legacy/bi/bi';
import devContextUtilsCreator from '@/utils/devContext';
import { consts } from '@wix/wix-code-consts';
import { AppState } from '@/infra/redux-state/reducers/rootReducer';
import { FileTreeListItem } from '../FileTreeListItem';
import {
  wixCodeReduxContext,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';

interface CodeReusePkgTreeConfigFileReduxStateProps {
  configFile: any;
}

interface CodeReusePkgTreeConfigFileReduxDispatchProps {
  nodeDblClick: (fileId: string) => any;
}

interface CodeReusePkgTreeConfigFileProps {
  name: string;
}

type CodeReusePkgTreeConfigFileCompProps = CodeReusePkgTreeConfigFileProps &
  CodeReusePkgTreeConfigFileReduxStateProps &
  CodeReusePkgTreeConfigFileReduxDispatchProps;

const CodeReusePkgTreeConfigFileComp: React.FC<
  CodeReusePkgTreeConfigFileCompProps
> = ({ configFile, name, nodeDblClick }) => {
  const {
    editorAPI,
    legacyDependenciesAPI: { constants },
  } = useEditorLegacyAPIs();
  const filePath = `backend/${consts.CONFIG_FOLDER_NAME}/${name}-config.json`;
  const { setFileContext } = devContextUtilsCreator({ constants });

  if (!configFile) {
    return null;
  }

  const onFileSelected = () => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_ITEM, {
      item_name: filePath,
      item_type: 'corvid_package',
    });
    editorAPI.panelManager.closeAllPanels();
    setFileContext(editorAPI, filePath);
    editorAPI.developerMode.ui.idePane.unMinimize();
  };

  const dataSource: FileSubTreeDataSource = {
    id: filePath,
    name: configFile.descriptor.name,
    pendingRename: configFile.pendingRename,
    pendingDelete: configFile.pendingDelete,
    pendingCreation: configFile.pendingCreation,
    isFolder: false,
    childItems: [],
    expanded: configFile.expanded,
    loading: configFile.loading,
  };

  return (
    <FileTreeListItem
      dataSource={dataSource}
      depth={2}
      alwaysShowSuffix={false}
      suffix={null}
      onNodeClick={onFileSelected}
      nodeDoubleClick={nodeDblClick}
      isContextMenuOpen={false}
    />
  );
};

const mapStateToProps = (
  state: AppState,
  { name }: CodeReusePkgTreeConfigFileProps,
): CodeReusePkgTreeConfigFileReduxStateProps => {
  const configFile = () =>
    codeStateReader.getFileSystemEntry(
      state,
      `backend/${consts.CONFIG_FOLDER_NAME}/${name}-config.json`,
    );
  return { configFile: configFile() };
};

const mapDispatchToProps = (
  dispatch: AnyFixMe,
): CodeReusePkgTreeConfigFileReduxDispatchProps =>
  bindActionCreators(
    {
      nodeDblClick: nodeDoubleClick,
    },
    dispatch,
  );

export const CodeReusePkgTreeConfigFile: React.FC<
  CodeReusePkgTreeConfigFileProps
> = (props) => {
  const { editorAPI } = useEditorLegacyAPIs();

  return (
    <CodeReusePkgTreeConfigFileConnected
      {...props}
      getEditorAPI={() => editorAPI}
    />
  );
};

const CodeReusePkgTreeConfigFileConnected: React.FC<
  CodeReusePkgTreeConfigFileProps & Pick<LeftPaneContextProps, 'getEditorAPI'>
> = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
  context: wixCodeReduxContext,
})(CodeReusePkgTreeConfigFileComp);
