import React from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import Illustration from '../../../assets/images/PanelEmptyStateIllustration.svg';
import { EmptyStateBase } from '../EmptyStateBase/EmptyStateBase';
import { TextButton } from '@wix/wix-base-ui';
import { ideToolbarAction } from '@wix/bi-logger-platform/v2';
import { useBiLogger } from '../../../biLoggerContext';

export const ElementNotSelectedState = () => {
  const [t] = useTranslation();
  const biLoggerAPI = useBiLogger();

  return (
    <EmptyStateBase
      illustration={<Illustration />}
      text={t('WixCode_CssClassesPanel_EmptyState_Description')}
      cta={
        <TextButton
          onClick={() => {
            window.open(
              'https://support.wix.com/en/article/css-editing',
              '_blank',
            );
            biLoggerAPI.report(
              ideToolbarAction({
                item_name: 'css_learn_more',
                action: 'click',
              }),
            );
          }}
        >
          {t('WixCode_CssClassesPanel_EmptyState_LearnMore_Link')}
        </TextButton>
      }
    />
  );
};
