import { getMasterPageFileId, translations } from '@wix/wix-code-common';
import {
  PropertiesPanelTab,
  propertiesPanelTabIds,
} from '@wix/wix-code-plugin-contracts';
import { APIKeys, EntryPoint, Shell } from '@wix/wix-code-repluggable';
import _ from 'lodash';
import { ContributedTabsSlot } from './contributedTabs';
import { createCodeEditorSidebar } from './createCodeEditorSidebar';
import {
  setContributedTabs,
  setSelectedTab,
  TabsActions,
  tabsReducer,
  TabsStore,
} from './state';

export const PropertiesPanelEntryPoint: EntryPoint = {
  name: 'properties-panel',
  getDependencyAPIs() {
    return [
      APIKeys.WixCodeStoreAPI,
      APIKeys.ClassicEditorAPI,
      APIKeys.CodeEditorAPI,
      APIKeys.LegacyEditorDependencies,
      APIKeys.WixCodeStoreAPI,
      APIKeys.DevContextAPI,
      APIKeys.SiteAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.AiAssistancePublicAPI,
    ];
  },
  declareAPIs() {
    return [APIKeys.PropertiesPanelAPI];
  },
  attach(shell: Shell) {
    const tabsSlot = shell.declareSlot(ContributedTabsSlot);

    shell.contributeState<TabsStore, TabsActions>(() => ({
      tabs: tabsReducer,
    }));

    shell.contributeAPI(APIKeys.PropertiesPanelAPI, () => ({
      contributeTab: (tab: PropertiesPanelTab, condition?: () => boolean) => {
        tabsSlot.contribute(shell, tab, condition);
        shell.getStore().dispatch(setContributedTabs(tab.id));
      },
      selectTab: (tabId: keyof typeof propertiesPanelTabIds) => {
        shell.getStore().dispatch(setSelectedTab(tabId));
      },
    }));
  },
  async extend(shell) {
    const { editorAPI, observeEditorStore } = shell.getAPI(
      APIKeys.ClassicEditorAPI,
    );
    const codeEditorAPI = shell.getAPI(APIKeys.CodeEditorAPI);
    const legacyDependenciesAPI = shell.getAPI(
      APIKeys.LegacyEditorDependencies,
    );
    observeEditorStore(
      () => editorAPI.developerMode.getContext(),
      (context, prevContext) => {
        const store = shell.getStore();
        if (
          (context.type === 'PAGE' ||
            context.data.id === getMasterPageFileId()) &&
          !_.isEqual(context, prevContext)
        ) {
          store.dispatch(
            setSelectedTab(propertiesPanelTabIds.propertiesAndEvents),
          );
        }
      },
    );

    const i18nManager = translations.i18nManagerCreator({
      languageCode: legacyDependenciesAPI.util.editorModel.languageCode,
      asyncMessagesLoader: (locale: string) =>
        import(`./assets/locale/messages_${locale}.json`),
    });

    const i18nInstance = await i18nManager.initI18n();
    const CodeEditorSidebar = createCodeEditorSidebar({
      shell,
      i18nInstance,
    });
    codeEditorAPI.contributeSidebar(shell, CodeEditorSidebar, () =>
      editorAPI.developerToolBar.isEnabled(),
    );
  },
};
