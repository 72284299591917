import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { createPagesTreeAPI } from './pagesTreeAPI';
import {
  PagesTreeReducerAction,
  pagesTreeReducer,
  PagesTabStore,
} from './state';
import { createPagesTab } from './createPagesTab';
import { createPagesMenuTab } from './PagesMenuTab';

export const PagesTabEntryPoint: EntryPoint = {
  name: 'pages sidebar tab entry point',
  getDependencyAPIs() {
    return [
      APIKeys.MenuAPI,
      APIKeys.PanelsAPI,
      APIKeys.SidebarAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.MenuPanelAPI,
      APIKeys.FilesViewAPI,
      APIKeys.DevContextAPI,
      APIKeys.WixCodeStoreAPI,
      APIKeys.ClassicEditorAPI,
      WixCodeEditorAdapterAPI,
    ];
  },
  declareAPIs() {
    return [APIKeys.PagesTreeAPI];
  },
  attach(shell: Shell) {
    shell.contributeState<PagesTabStore, PagesTreeReducerAction>(() => ({
      pagesTree: pagesTreeReducer,
    }));
    const { dispatch } = shell.getStore<PagesTabStore>();
    shell.contributeAPI(APIKeys.PagesTreeAPI, () =>
      createPagesTreeAPI({ dispatch }),
    );
  },
  extend(shell: Shell) {
    const menuAPI = shell.getAPI(APIKeys.MenuAPI);
    const panelsAPI = shell.getAPI(APIKeys.PanelsAPI);
    const sidebarAPI = shell.getAPI(APIKeys.SidebarAPI);
    const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
    const filesViewAPI = shell.getAPI(APIKeys.FilesViewAPI);
    const menuPanelAPI = shell.getAPI(APIKeys.MenuPanelAPI);
    const devContextAPI = shell.getAPI(APIKeys.DevContextAPI);
    const wixCodeStoreAPI = shell.getAPI(APIKeys.WixCodeStoreAPI);
    const wixCodeEditorAdapterAPI = shell.getAPI(WixCodeEditorAdapterAPI);
    const { legacyDependencies } = wixCodeEditorAdapterAPI;

    const pagesTab = createPagesTab({
      shell,
      apis: {
        legacyDependenciesAPI: legacyDependencies,
        wixCodeStoreAPI,
        biLoggerAPI,
        devContextAPI,
        filesViewAPI,
        wixCodeEditorAdapterAPI,
      },
    });
    sidebarAPI.contributeTab(() => pagesTab);

    menuPanelAPI.contributeTab({
      id: 'pages',
      TabContent: ({ closePanel }) => {
        const TabContent = pagesTab.creator();
        return createPagesMenuTab({
          shell,
          closePanel,
          children: TabContent,
          apis: {
            wixCodeStoreAPI,
            legacyDependenciesAPI: legacyDependencies,
            biLoggerAPI,
            panelsAPI,
          },
        });
      },
    });

    menuAPI.registerMenuItemListener('pages', () => {
      menuPanelAPI.selectTab('pages');
    });
  },
};
