import React from 'react';
import { Provider } from 'react-redux';
import storeManager from './storeManager';
import createReactClass from 'create-react-class';
import { wixCodeReduxContext } from '@wix/wix-code-common-components';

export default (WrappedComponent) => {
  return createReactClass({
    UNSAFE_componentWillMount: function componentWillMount() {
      this.store = storeManager.getStore();
    },
    render() {
      return (
        <Provider store={this.store} context={wixCodeReduxContext}>
          <WrappedComponent {...this.props} />
        </Provider>
      );
    },
  });
};
