import './ideTabsContainer.global.scss';
import _ from 'lodash';
import React, { useCallback, useRef, useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import ideTabsStateReader from '../../selectors/ideTabsStateReader';
import codeStateReader from '@/infra/redux-state/reducers/codeStateReader';
import ideTabsActions from '../../actions/ideTabsActions';
import bi from '@/legacy/bi/bi';
import { ideTabClose, ideTabScroll } from '@/legacy/bi/biEvents';
import leftPaneActions from '@/infra/redux-state/actions/leftPaneActions';
import fileSystemActions from '@/infra/redux-state/actions/fileSystemActions';
import {
  getFileIdFromPageId,
  isCssFile,
  getMasterPageFileId,
} from '@wix/wix-code-common';
import devContextUtilsCreator from '@/utils/devContext';
import { CodeTab, IdeCodeTabs } from './IdeCodeTabs';
import { EmbeddedTab, IdeEmbeddedTabs } from './IdeEmbeddedTabs';
import {
  IdeTriggerBEFunctionTabs,
  TriggerBEFunctionTab,
} from './IdeTriggerBEFunctionTabs';
import {
  useEditorLegacyAPIs,
  connectToStores,
} from '@wix/wix-code-common-components';

import { CODE_EDITOR_MODE, EditorAPI } from '@wix/wix-code-plugin-contracts';
import { AppState } from '@/infra/redux-state/reducers/rootReducer';
import { IdeTabsReducerState } from '../../reducers/ideTabsReducer';
import { DealerOfferGUID } from '@/infra/dealer/dealerService';

interface IdeTabsContainerProps {
  codeEditorMode: CODE_EDITOR_MODE;
  hiddenDealerOffers?: DealerOfferGUID[];
}

interface IdeTabsContainerReduxStateProps {
  openedTabs: CodeTab[];
  embeddedTabs: EmbeddedTab[];
  isFileExists: (fileId: string) => boolean;
  unpinnedTab: string | null;
  tbfTabs: TriggerBEFunctionTab[];
}

interface IdeTabsContainerReduxDispatchProps {
  dispatchSelectTab: (tabId: string) => void;
  openOrChangeUnpinnedTab: (arg: { tabId: string | null }) => void;
  openOrAddTbfTab: (tab: TriggerBEFunctionTab) => void;
}

interface IdeTabsContainerEditorReduxStateProps {
  editorAPI: EditorAPI;
  devContext: any;
  fileIdOfFocusedPage: string;
  titleOfFocusedPage: string;
  selectedCompRef: any;
  isMasterPageComponentSelected: boolean;
  selectedTabId: string;
  sendBi: (event: any) => void;
}

type IdeTabsContainerCompProps = IdeTabsContainerReduxStateProps &
  IdeTabsContainerReduxDispatchProps &
  IdeTabsContainerEditorReduxStateProps &
  IdeTabsContainerProps;

const IdeTabsContainerComp: React.FC<IdeTabsContainerCompProps> = ({
  selectedTabId,
  openedTabs,
  sendBi,
  unpinnedTab,
  titleOfFocusedPage,
  isFileExists,
  selectedCompRef,
  devContext,
  isMasterPageComponentSelected,
  fileIdOfFocusedPage,
  openOrChangeUnpinnedTab,
  dispatchSelectTab,
  embeddedTabs,
  tbfTabs,
  openOrAddTbfTab,
  codeEditorMode,
  hiddenDealerOffers,
}) => {
  const { editorAPI, legacyDependenciesAPI } = useEditorLegacyAPIs();
  const { constants, stateManagement } = legacyDependenciesAPI;

  const {
    setPageContext,
    setFileContext,
    isFileContext,
    setEmbeddedTabContext,
    setTbfContext,
    setCssFileContext,
  } = devContextUtilsCreator({
    constants,
  });

  const shouldShowUnpinned = (unpinned: AnyFixMe) => {
    return (
      unpinned &&
      !openedTabs.find(({ tabId }) => tabId === unpinned) &&
      isFileExists(unpinned)
    );
  };

  const getTabs = (): CodeTab[] => {
    const pageTab: CodeTab[] =
      codeEditorMode === CODE_EDITOR_MODE.CODE_ONLY
        ? []
        : [
            {
              displayName: titleOfFocusedPage,
              tabId: fileIdOfFocusedPage,
              isPageTab: true,
            },
          ];
    return pageTab.concat(openedTabs).concat(
      shouldShowUnpinned(unpinnedTab)
        ? {
            tabId: unpinnedTab!,
            unpinned: true,
            isPageTab: false,
          }
        : [],
    );
  };
  const codeTabs = getTabs();
  const tabOrder = codeTabs
    .map((t) => t.tabId)
    .concat(embeddedTabs.map((t) => t.url))
    .concat(tbfTabs.map((t) => t.tabId));
  const tabCount = tabOrder.length;

  const scrollableNodeRef = useRef<EventTarget>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onScroll = useCallback(
    _.debounce(() => {
      const tabIndex = tabOrder.indexOf(selectedTabId);
      sendBi({
        def: bi.events.IDE_TAB_ACTION,
        params: ideTabScroll({
          origin: 'scroll',
          tabId: selectedTabId,
          tabCount,
          tabIndex,
        }),
      });
    }, 500),
    [tabOrder.length, selectedTabId],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => findOrOpenTabFromContext(), []);
  useEffect(() => {
    if (tabCount === 1) {
      editorAPI.store.dispatch(
        stateManagement.editorPlugins.wixCodeIDE.actions.showIDEWithDsRead({
          dsRead: editorAPI.dsRead,
        }),
      );
    }
    if (tabCount === 0) {
      editorAPI.store.dispatch(
        stateManagement.editorPlugins.wixCodeIDE.actions.hideIDE(),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabCount]);

  useEffect(() => {
    const nodeRef = scrollableNodeRef.current!; // strictNullChecks
    nodeRef.addEventListener('scroll', onScroll);
    return () => nodeRef && nodeRef.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  const fileIdOfFocusedPageRef = useRef(fileIdOfFocusedPage);
  const selectedCompRefRef = useRef(selectedCompRef);
  const devContextRef = useRef(devContext);

  const selectTabIfPageChanged = () => {
    const pageHasChanged = !_.isEqual(
      fileIdOfFocusedPageRef.current,
      fileIdOfFocusedPage,
    );
    fileIdOfFocusedPageRef.current = fileIdOfFocusedPage;

    const selectedComponentHasChanged =
      !_.isEqual(selectedCompRef, selectedCompRefRef.current) &&
      !!selectedCompRef;

    selectedCompRefRef.current = selectedCompRef;

    const shouldStayOnMasterPageTab =
      isMasterPageComponentSelected && selectedTabId === getMasterPageFileId();

    const shouldStayOnGlobalCssTab = isCssFile(selectedTabId);

    if (
      pageHasChanged ||
      (selectedComponentHasChanged &&
        !shouldStayOnMasterPageTab &&
        !shouldStayOnGlobalCssTab)
    ) {
      selectTab({
        tabId: fileIdOfFocusedPage,
        isPageTab: true,
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(selectTabIfPageChanged, [
    selectedTabId,
    fileIdOfFocusedPage,
    isMasterPageComponentSelected,
    selectedCompRef,
  ]);

  const openTabIfContextChanged = () => {
    const wasContextChanged = !_.isEqual(devContextRef.current, devContext);
    devContextRef.current = devContext;

    if (wasContextChanged) {
      findOrOpenTabFromContext();
    }

    const isMasterPageSelected = selectedTabId === getMasterPageFileId();
    const wasTransitionedToFileContext =
      wasContextChanged &&
      isFileContext(editorAPI) &&
      !isMasterPageSelected &&
      !isCssFile(selectedTabId);
    if (
      (!isMasterPageComponentSelected && isMasterPageSelected) ||
      wasTransitionedToFileContext
    ) {
      editorAPI.selection.deselectComponents();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(openTabIfContextChanged, [
    selectedTabId,
    devContext,
    isMasterPageComponentSelected,
  ]);

  const removeUnpinnedIfJustPinned = () => {
    if (_.find(openedTabs, { tabId: unpinnedTab })) {
      openOrChangeUnpinnedTab({ tabId: null });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(removeUnpinnedIfJustPinned, [unpinnedTab, openedTabs.length]);

  const findOrOpenTabFromContext = () => {
    if (!openedTabs.find(({ tabId }) => tabId === devContext.data.id)) {
      if (devContext.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.FILE) {
        openOrChangeUnpinnedTab({ tabId: devContext.data.id });
      } else if (
        devContext.type ===
        constants.DEVELOPER_MODE.CONTEXT_TYPES.TRIGGER_BACKEND_FUNCTION
      ) {
        openOrAddTbfTab({
          tabId: devContext.data.id,
          fileId: devContext.data.fileId,
          functionName: devContext.data.functionName,
          functionSignature: devContext.data.functionSignature,
          paramNames: devContext.data.paramNames,
          lineNumber: devContext.data.lineNumber,
        });
      }
    }
  };

  const selectTab = ({ tabId, isPageTab }: AnyFixMe) => {
    if (isPageTab) {
      setPageContext(editorAPI);
    } else if (isCssFile(tabId)) {
      setCssFileContext(editorAPI);
    } else {
      setFileContext(editorAPI, tabId);
    }
    dispatchSelectTab(tabId);
  };

  const chooseTabAfterClose = (tabClosing: string, biItemName: string) => {
    const tabIndex = tabOrder.indexOf(tabClosing);

    sendBi({
      def: bi.events.IDE_TAB_ACTION,
      params: ideTabClose({
        origin: 'x',
        tabId: biItemName,
        tabCount,
        tabIndex,
      }),
    });

    if (unpinnedTab === tabClosing) {
      openOrChangeUnpinnedTab({ tabId: null });
    }

    if (selectedTabId === tabClosing && tabCount !== 1) {
      if (tabCount === tabIndex + 1) {
        selectTabByIndex(tabIndex - 1);
      } else {
        selectTabByIndex(tabIndex + 1);
      }
    }
  };

  const selectTabByIndex = (tabIndex: AnyFixMe) => {
    const tabToSelect = tabOrder[tabIndex];
    const tabInCode = codeTabs.find((t) => t.tabId === tabToSelect);
    const tabInEmbedd = embeddedTabs.find((t) => t.url === tabToSelect);
    const tabInTriggerBE = tbfTabs.find((t) => t.tabId === tabToSelect);

    if (!!tabInCode && tabInCode.isPageTab) {
      setPageContext(editorAPI);
    } else if (tabInEmbedd) {
      setEmbeddedTabContext(editorAPI, { url: tabToSelect });
    } else if (tabInTriggerBE) {
      const { tabId, ...rest } = tabInTriggerBE;
      setTbfContext(editorAPI, { id: tabId, ...rest });
    } else {
      setFileContext(editorAPI, tabToSelect);
    }
    dispatchSelectTab(tabToSelect);
  };

  return (
    <SimpleBar
      scrollableNodeProps={{ ref: scrollableNodeRef }}
      className="wix-code-tabs-container"
      data-aid="wix-code-tabs-container"
    >
      {codeTabs.length !== 0 && (
        <IdeCodeTabs
          codeTabs={codeTabs}
          tabCount={tabCount}
          selectedTabId={selectedTabId}
          sendBi={sendBi}
          selectTab={selectTab}
          chooseTabAfterClose={chooseTabAfterClose}
        />
      )}
      {embeddedTabs.length !== 0 && (
        <IdeEmbeddedTabs
          embeddedTabs={embeddedTabs}
          selectedTabId={selectedTabId}
          sendBi={sendBi}
          tabCount={tabCount}
          chooseTabAfterClose={chooseTabAfterClose}
          hiddenDealerOffers={hiddenDealerOffers}
        />
      )}
      {tbfTabs.length !== 0 && (
        <IdeTriggerBEFunctionTabs
          tbfTabs={tbfTabs}
          selectedTabId={selectedTabId}
          chooseTabAfterClose={chooseTabAfterClose}
        />
      )}
    </SimpleBar>
  );
};

const mapEditorStateToProps =
  ({ stateManagement, util, constants }: AnyFixMe) =>
  ({ editorAPI }: AnyFixMe): IdeTabsContainerEditorReduxStateProps => {
    const mapSelectedTabId = ({ devContext, focusedPageId }: AnyFixMe) => {
      switch (devContext.type) {
        case constants.DEVELOPER_MODE.CONTEXT_TYPES.WHATS_NEW:
          return devContext.data.url;
        case constants.DEVELOPER_MODE.CONTEXT_TYPES.FILE:
          return devContext.data.id;
        case constants.DEVELOPER_MODE.CONTEXT_TYPES.TRIGGER_BACKEND_FUNCTION:
          return devContext.data.id;
        default:
          return getFileIdFromPageId(focusedPageId);
      }
    };
    const { getSingleSelectedComponent } =
      stateManagement.editorPlugins.selection.selectors;
    const { store } = editorAPI;
    const editorState = store.getState();
    const selectedComponent = getSingleSelectedComponent(editorState);
    const selectedCompRef = util.controlsUtils.getFirstControllableComponent(
      editorAPI,
      selectedComponent,
    );
    const isMasterPageComponentSelected =
      editorAPI.components.isShowOnAllPages(selectedCompRef);

    const focusedPageId = editorAPI.pages.getFocusedPageId();
    const devContext = editorAPI.developerMode.getContext();
    const selectedTabId = mapSelectedTabId({ devContext, focusedPageId });
    const sendBi = (event: AnyFixMe) =>
      editorAPI.bi.event(event.def, event.params);

    return {
      editorAPI,
      devContext,
      fileIdOfFocusedPage: getFileIdFromPageId(focusedPageId),
      titleOfFocusedPage: editorAPI.pages.getPageTitle(focusedPageId),
      selectedCompRef,
      isMasterPageComponentSelected,
      selectedTabId,
      sendBi,
    };
  };

const mapCodeStateToProps = (
  state: AppState,
): IdeTabsContainerReduxStateProps => {
  const ideTabs = state.ideTabs as IdeTabsReducerState;
  const openedTabs = ideTabs.pinnedFileTabs;
  const embeddedTabs = ideTabs.embeddedTabs;
  const unpinnedTab = ideTabs.unpinnedTabId;
  const isFileExists = (fileId: AnyFixMe) =>
    !!codeStateReader.getFile(state, fileId);
  const tbfTabs = ideTabsStateReader.getTbfTabs(state);

  return {
    openedTabs,
    embeddedTabs,
    isFileExists,
    unpinnedTab,
    tbfTabs,
  };
};

const mapCodeDispatchToProps = (
  dispatch: AnyFixMe,
): IdeTabsContainerReduxDispatchProps => {
  const dispatchSelectTab = (tabId: AnyFixMe) => {
    dispatch(leftPaneActions.selectLeftPaneTabOf(tabId));
    dispatch(fileSystemActions.expandParents(tabId));
  };
  const openOrChangeUnpinnedTab = (tabId: AnyFixMe) =>
    dispatch(ideTabsActions.openOrChangeUnpinnedTab(tabId));
  const openOrAddTbfTab = ({
    tabId,
    fileId,
    functionName,
    functionSignature,
    paramNames,
    lineNumber,
  }: AnyFixMe) =>
    dispatch(
      ideTabsActions.openOrAddTbfTab({
        tabId,
        fileId,
        functionName,
        functionSignature,
        paramNames,
        lineNumber,
      }),
    );

  return {
    dispatchSelectTab,
    openOrChangeUnpinnedTab,
    openOrAddTbfTab,
  };
};

let ConnectedComponent = null as any;
export const IdeTabsContainer: React.FC<IdeTabsContainerProps> = (
  origProps,
) => {
  const {
    legacyDependenciesAPI: { constants, util, stateManagement },
  } = useEditorLegacyAPIs();
  if (ConnectedComponent === null) {
    ConnectedComponent = connectToStores({
      util,
      mapEditorStateToProps: mapEditorStateToProps({
        stateManagement,
        util,
        constants,
      }),
      mapCodeStateToProps,
      mapCodeDispatchToProps,
      comp: IdeTabsContainerComp,
    });
  }
  return <ConnectedComponent {...origProps} />;
};
