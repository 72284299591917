import npmIndexSearchCreator from '../searchClient';
import { ActionType } from '@/infra/redux-state/actions/types';
import { getSearchKeyword } from '../selectors/modulesSelectors';
import { NpmPackageInfo } from '../../../../../packagesModalContext';
import { defaultNpmPackagesDescriptors } from '../top10NpmPackages.const';
import { addJustInstalledPkg } from '../../../../packagesModalActions';
import { Dispatch, ThunkAction } from '@wix/wix-code-common-components';
import codeStateReader from '@/infra/redux-state/reducers/codeStateReader';
import { packageJsonActions } from '@/infra/redux-state/actions/packageJsonActions';
import { OpenSubmitNpmRequest } from '../reducers/requestPackageReducer';
import {
  DefaultNpmPackages,
  ErrorInstallingPackages,
  IndexSearchFailure,
  IndexSearchRequest,
  IndexSearchSuccess,
  InstallModuleDone,
  InstallModuleStart,
  LoadDefaultNpmPackages,
  PackagesInitDone,
  PackagesInitStart,
  SearchKeywordChanged,
  SetSelectedNpmPkgData,
} from '../reducers/modulesReducer';
import { debounce } from 'lodash';
import { AppState } from '@/infra/redux-state/reducers/rootReducer';

let npmIndexSearchApi: ReturnType<typeof npmIndexSearchCreator>;
const { setNpmPackages } = packageJsonActions;

const getDefaultNpmPackages = async () => {
  // Placeholder until this is served from some api
  const defaultPackagesDescriptors = await Promise.resolve(
    defaultNpmPackagesDescriptors,
  );

  const defaultNpmPackages = Object.keys(defaultPackagesDescriptors);
  const defaultNpmPackagesInfo: NpmPackageInfo[] = await npmIndexSearchApi
    .fetchAndCachePackages(defaultNpmPackages)
    .then(() =>
      defaultNpmPackages
        .map((packageName) => npmIndexSearchApi.getPackageInfo(packageName))
        .filter((v) => !!v),
    );
  return {
    pkgInfo: defaultNpmPackagesInfo,
    descriptors: defaultPackagesDescriptors,
  };
};

const init =
  (): ThunkAction =>
  async (dispatch, getState, { editorAPI }) => {
    await dispatch(packagesInitStart());
    try {
      const dependencies = codeStateReader.getNpmPackages(getState());

      npmIndexSearchApi = npmIndexSearchCreator({
        installedVersions: dependencies,
        editorAPI,
      });

      const defaultNpmPackages: DefaultNpmPackages =
        await getDefaultNpmPackages();
      await npmIndexSearchApi.fetchAndCachePackages(
        dependencies.map((d) => d.name),
      );
      dispatch(loadDefaultNpmPackages(defaultNpmPackages));
    } catch (e) {
      console.error(e);
      // todo, arielw, handle errors
      dispatch(errorInstallingPackages());
    }

    await dispatch(packagesInitDone());
  };

const save =
  (name: string, version: string): ThunkAction =>
  async (dispatch, getState, { editorAPI }) => {
    try {
      await editorAPI.wixCode.codePackages.installNpmPkg(name, version);

      const oldNpmPkgs = codeStateReader.getNpmPackages(getState());
      dispatch(setNpmPackages([...oldNpmPkgs, { name, version }]));
    } catch (e) {
      editorAPI.panelManager.openPanel(
        'wixCode.panels.fileSystemOperationErrorPanel',
        { error: `Error installing ${name}. Please try again.` },
      );
      console.error({ e });
    }
  };

const onTabSelect = (tabId: string) => async (dispatch: AnyFixMe) => {
  dispatch({ type: ActionType.TAB_SELECTED, tabId });
};

const search = (keyword: string): SearchKeywordChanged => ({
  type: ActionType.SEARCH_KEYWORD_CHANGED,
  keyword,
});
const install =
  (name: string, version: string): ThunkAction =>
  async (dispatch, getState) => {
    const existingNpmPkgs = codeStateReader.getNpmPackages(getState());
    const existsPackageIndex = existingNpmPkgs.findIndex(
      (pkg) => pkg.name === name,
    );
    if (existsPackageIndex === -1) {
      dispatch(setNpmPackages([...existingNpmPkgs, { name, version }]));
    } else {
      existingNpmPkgs[existsPackageIndex].version = version;
      dispatch(setNpmPackages([...existingNpmPkgs]));
    }
    dispatch(addJustInstalledPkg(name));
    dispatch(installDone(name));
  };

const installAndSave =
  (name: string, version: string): ThunkAction =>
  async (dispatch: AnyFixMe) => {
    dispatch(installStart(name));
    await new Promise((slowdown) => setTimeout(slowdown, 1000));
    await dispatch(save(name, version));
    dispatch(addJustInstalledPkg(name));
    dispatch(installDone(name));
  };

const installDone = (name: string): InstallModuleDone => ({
  type: ActionType.INSTALL_MODULE_DONE,
  npmModule: { name },
});

const installStart = (name: string): InstallModuleStart => ({
  type: ActionType.INSTALL_MODULE_START,
  npmModule: { name },
});
const installVersionStart = (name: string, version: string) => ({
  type: ActionType.INSTALL_MODULE_START,
  npmModule: { name, version },
});

const openSubmitRequest =
  ({
    name,
    versions,
    version,
    disableVersionInput,
  }: Partial<OpenSubmitNpmRequest>) =>
  async (dispatch: AnyFixMe) => {
    dispatch({
      type: ActionType.OPEN_SUBMIT_NPM_REQUEST,
      name,
      version,
      versions,
      disableVersionInput,
    });
  };

const errorInstallingPackages = (): ErrorInstallingPackages => ({
  type: ActionType.ERROR_INSTALLING_PACKAGES,
});

const indexSearch = (
  indexSearchKeyword: IndexSearchRequest['indexSearchKeyword'],
): IndexSearchRequest => ({
  type: ActionType.INDEX_SEARCH_REQUEST,
  indexSearchKeyword,
});

const indexSearchSuccess = (
  indexSearchPackages: IndexSearchSuccess['indexSearchPackages'],
): IndexSearchSuccess => ({
  type: ActionType.INDEX_SEARCH_SUCCESS,
  indexSearchPackages,
});

const clearIndexSearchPackages = (): IndexSearchSuccess => ({
  type: ActionType.INDEX_SEARCH_SUCCESS,
  indexSearchPackages: [],
});

const indexSearchFailure = (): IndexSearchFailure => ({
  type: ActionType.INDEX_SEARCH_FAILURE,
});

const packagesInitDone = (): PackagesInitDone => ({
  type: ActionType.PACKAGES_INIT_DONE,
});

const packagesInitStart = (): PackagesInitStart => ({
  type: ActionType.PACKAGES_INIT_START,
});

const loadDefaultNpmPackages = (
  defaultNpmPackages: DefaultNpmPackages,
): LoadDefaultNpmPackages => ({
  type: ActionType.LOAD_DEFAULT_NPM_PACKAGES,
  defaultNpmPackages,
});

const performIndexSearch =
  (keyword: string) => async (dispatch: AnyFixMe, getState: () => any) => {
    dispatch(search(keyword));
    dispatch(indexSearch(keyword));
    keyword.length === 0
      ? dispatch(clearIndexSearchPackages())
      : debounceSearch(keyword, dispatch, getState);
  };
const debounceSearch = debounce(
  async (keyword: string, dispatch: Dispatch, getState: () => AppState) => {
    await npmIndexSearchApi
      .npmSearch({ keyword })
      .then((indexSearchPackages) => {
        if (getSearchKeyword(getState()) === keyword) {
          dispatch(clearIndexSearchPackages());
          dispatch(indexSearchSuccess(indexSearchPackages.packageDetails));
        }
      })
      .catch(() => {
        dispatch(indexSearchFailure());
      });
  },
  400,
);
const setSelectedNpmPkgData = (pkg: NpmPackageInfo): SetSelectedNpmPkgData => ({
  type: ActionType.SET_SELECTED_NPM_PKG_DATA,
  selectedPkgData: pkg,
});

const getCachedNpmPackageData = (name: string) =>
  npmIndexSearchApi?.getPackageInfo(name);

export {
  install,
  init,
  onTabSelect,
  installStart,
  installVersionStart,
  installDone,
  search,
  installAndSave,
  openSubmitRequest,
  performIndexSearch,
  setSelectedNpmPkgData,
  getCachedNpmPackageData,
};
