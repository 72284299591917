import React, { useState, useEffect } from 'react';

type ContributedCompListener = (comp: React.ComponentType) => void;

export default function createSuspendedComp() {
  let listener: ContributedCompListener | null = null;
  let ContributedComp: React.ComponentType | null = null;

  function onCompContributed(_listener: ContributedCompListener) {
    listener = _listener;
    if (ContributedComp) {
      listener(ContributedComp);
    }
    return () => {
      listener = null;
    };
  }

  function contributeComp(comp: React.ComponentType) {
    ContributedComp = comp;
    if (listener) {
      listener(ContributedComp);
    }
  }

  const renderCount = 0;
  function SuspendedComp(props: any) {
    const [, setCounter] = useState<number>(renderCount);
    useEffect(() => {
      return onCompContributed(() => setCounter(renderCount + 1));
    }, []);
    return ContributedComp ? <ContributedComp {...props} /> : null;
  }

  return [SuspendedComp, contributeComp];
}
