import * as React from 'react';
import s from './pkgListEmptyStateContainer.scss';

export const PkgListEmptyStateContainer: React.FC<{
  children: React.ReactNode;
  dataHook?: string;
}> = ({ children, dataHook }) => {
  return (
    <div className={s.emptyStateContainer} data-hook={dataHook}>
      {children}
    </div>
  );
};
