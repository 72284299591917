import React, { useContext } from 'react';
import { Button } from '@wix/wix-base-ui';
import {
  AvailableCodeReusePkg,
  PackagesModalContext,
} from '../../../../../packagesModalContext';
import dataHooks from '../../../../../dataHooks';
import { PackageItemContext } from '../../PackageItemContext';
import { PackagesBiSender } from '../../../../../packagesBiSender';

interface InstallPkgButtonProps {
  versionOverride?: string;
}

export const InstallPkgButton: React.FC<InstallPkgButtonProps> = ({
  versionOverride,
}) => {
  const { t, sendBi, packagesService } = React.useContext(PackagesModalContext);
  const packagesBiSender = PackagesBiSender(sendBi);

  const { availablePkg, pkgName, installedPkg, isCodeReusePackage } =
    useContext(PackageItemContext);

  const installPkg = () => {
    if (versionOverride) {
      if (isCodeReusePackage) {
        packagesBiSender.sendClickedInstallVersionPkgBi(
          versionOverride,
          availablePkg,
        );
        installedPkg
          ? packagesService.changeCodeReusePkgVersion(pkgName, versionOverride)
          : packagesService.installCodeReusePkgVersion({
              pkgInstalling: availablePkg as AvailableCodeReusePkg,
              versionOverride,
            });
      } else {
        packagesBiSender.sendClickedInstallVersionPkgBi(
          versionOverride,
          availablePkg,
        );
        packagesService.installPkg(pkgName, versionOverride);
      }
    } else {
      packagesBiSender.sendClickedInstallPkgBi(availablePkg);
      packagesService.installPkg(pkgName);
    }
  };

  return (
    <Button
      dataHook={dataHooks.PKG_ACTION_BUTTON}
      className="btn-sm btn-inverted action-button"
      onClick={(e: Event) => {
        e.stopPropagation();
        return installPkg();
      }}
    >
      {t('Package_Manager_Install_Button')}
    </Button>
  );
};
