import React, { useState } from 'react';
import { Button, Preloader, Spacer, Text, Popover } from '@wix/wix-base-ui';
import ConfirmBold from 'wix-ui-icons-common/classic-editor/ConfirmBold';
import { useTranslation } from '@wix/wix-i18n-config';
import s from './SyncLocallyButton.scss';
import dataHooks from '../dataHooks';
import { TranslationProvider } from '../../i18n';
import { LocalEditorPrivateAPI } from '../../LocalEditorPrivateAPI';
import { Shell } from 'repluggable';
import { connectWithShell } from '@wix/wix-code-repluggable';
import { SyncState } from '../../state';
import classNames from 'classnames';

type SyncLocallyButtonProps = {
  localEditorPrivateAPI: LocalEditorPrivateAPI;
  zIndexTooltip: number;
};

type SyncStateProps = {
  isSyncing: boolean;
  isSyncSuccess: boolean;
};

type SyncLocallyButtonCompProps = SyncLocallyButtonProps & SyncStateProps;

const SyncLocallyButton: React.FC<SyncLocallyButtonCompProps> = ({
  localEditorPrivateAPI,
  isSyncing,
  isSyncSuccess,
  zIndexTooltip,
}) => {
  const [t] = useTranslation();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const defaultZindexTooltip = 1500;
  const TooltipContent = () => (
    <div className={s.tooltipContainer}>
      <Text size="medium" weight="bold" shouldTranslate={false}>
        {t('localEditor.sync_locally_button.tooltip.title')}
      </Text>
      <Spacer type="Spacer01" />
      <Text
        size="small"
        weight="thin"
        shouldTranslate={false}
        enableEllipsis={false}
      >
        {t('localEditor.sync_locally_button.tooltip.text')}
      </Text>
      <Spacer type="Spacer04" />
      <Text
        size="small"
        weight="thin"
        shouldTranslate={false}
        enableEllipsis={false}
      >
        {t('localEditor.sync_locally_button.tooltip.current_revision')}{' '}
        {localEditorPrivateAPI.getRevision()}
      </Text>
    </div>
  );

  const SyncButton = () => (
    <div
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
    >
      <Button
        className={classNames(s.button, 'btn-md')}
        onClick={async () => {
          setIsTooltipVisible(false);
          if (isSyncing || isSyncSuccess) {
            return;
          }
          localEditorPrivateAPI.showSyncChangesModalIfNeeded();
        }}
        dataHook={dataHooks.syncLocallyButton.button}
        shouldTranslate={false}
      >
        {isSyncSuccess && (
          <span className={s.syncSuccess}>
            <ConfirmBold data-hook={dataHooks.syncLocallyButton.confirm} />
          </span>
        )}
        {isSyncing && (
          <span className={s.syncLoad}>
            <Preloader
              className="light tiny"
              dataHook={dataHooks.syncLocallyButton.syncLoader}
            />
          </span>
        )}
        <span
          style={{
            visibility: isSyncSuccess || isSyncing ? 'hidden' : 'visible',
          }}
        >
          {t('localEditor.sync_locally_button.text')}
        </span>
      </Button>
    </div>
  );

  return (
    <Popover
      showArrow={false}
      shown={isTooltipVisible}
      animate={true}
      appendTo="viewport"
      placement="bottom-end"
      dataHook={dataHooks.syncLocallyButton.popover}
      width={290}
      zIndex={zIndexTooltip || defaultZindexTooltip}
      moveBy={{ x: 0, y: 10 }}
    >
      <Popover.Element>
        <SyncButton />
      </Popover.Element>
      <Popover.Content>
        <TooltipContent />
      </Popover.Content>
    </Popover>
  );
};

const createSyncLocallyButton = (
  shell: Shell,
  localEditorPrivateAPI: LocalEditorPrivateAPI,
) => {
  const SyncButton = (props: SyncLocallyButtonCompProps) => (
    <TranslationProvider>
      <SyncLocallyButton
        {...props}
        localEditorPrivateAPI={localEditorPrivateAPI}
      />
    </TranslationProvider>
  );

  const ConnectedSyncButton = connectWithShell<
    SyncState,
    SyncLocallyButtonProps,
    SyncStateProps
  >(
    () => ({
      isSyncing: localEditorPrivateAPI.isSyncing(),
      isSyncSuccess: localEditorPrivateAPI.isSyncSuccess(),
    }),
    () => ({}),
    shell,
    {
      renderOutsideProvider: true,
    },
  )(SyncButton);

  return ConnectedSyncButton;
};

export default createSyncLocallyButton;
