const dataHooks = {
  connectToGithub: {
    githubProsList: 'github-pros-list',
    heading: 'heading',
    connectToGithubButton: 'connect-to-github-button',
    disconnectFromGithubButton: 'disconnect-from-github-button',
    viewDocsButton: 'view-docs-button',
    watchVideoButton: 'watch-video-button',
    content: 'github-tab-content',
    interactiveLoader: 'github-interactive-loader',
  },
  connectedGithubTab: {
    container: 'connected-github-tab',
    menuItems: 'connected-github-tab-menu-items',
    menuItemsActions: {
      viewRepository: 'connected-github-menu-item-view-repository',
      cloneRepository: 'connected-github-menu-item-clone-repository',
      disconnect: 'connected-github-menu-item-disconnect',
    },
  },
  githubErrorTab: {
    container: 'github-error-tab-container',
    title: 'github-error-tab-title',
    content: 'github-error-tab-content',
    cta: 'github-error-tab-cta',
    secondaryCta: 'github-error-tab-secondary-cta',
  },
  sideBar: {
    githubTab: 'github_tab',
  },
  beforeConnectModal: {
    container: 'before_connect_modal',
    contentLine1: 'content-line-1',
    contentLine2: 'content-line-2',
  },
  GithubNotConnectableModal: {
    container: 'github_not_connectable_modal',
    contentLine: 'content-line',
  },
  disconnectModal: {
    container: 'disconnect_modal',
    description: {
      container: 'disconnect_modal-description-container',
      contentLine1: 'disconnect-description-line-1',
      contentLine2: 'disconnect-description-line-2',
      link: 'disconnect-description-link',
    },
    error: 'disconnect-modal-error',
    title: 'disconnect-modal-title',
  },
  githubConnectionIssuesModal: {
    repositoryNotFoundModal: 'repository-not-found-modal',
    githubUninstalledModal: 'github-uninstalled-modal',
    githubSuspendedModal: 'github-suspended-modal',
  },
  connectModal: {
    primaryButton: 'primary_button',
    container: 'connect_modal',
    notificationBanner: 'connect-modal-notification-banner',
    signIn: {
      container: 'connect-modal-sign-in',
      header: 'connect-modal-signIn-header',
      description: 'connect-modal-signIn-description',
      descriptionContainer: 'connect-modal-signIn-description-container',
      learn_more: 'connect-modal-signIn-learn-more',
    },
    createRepository: {
      container: 'connect-modal-create-repository-container',
      titles: {
        mainTitle: 'connect-modal-create-repository-main-title',
        subTitle: 'connect-modal-create-repository-sub-title',
      },
      accountName: {
        container: 'connect-modal-create-repository-account-name-container',
        label: 'connect-modal-create-repository-account-name-label',
        dropDownInput:
          'connect-modal-create-repository-account-name-drop-down-input',
        avatar: 'connect-modal-create-repository-account-name-avatar',
      },
      repoName: {
        container: 'connect-modal-create-repository-repo-name-container',
        label: 'connect-modal-create-repository-repo-name-label',
        input: 'connect-modal-create-repository-repo-name-input',
      },
      description: {
        label: 'connect-modal-create-repository-description-label',
        input: 'connect-modal-create-repository-description-input',
      },
      repositoryInfo: 'connect-modal-create-repository-info',
    },
    installApp: {
      container: 'install-app-container',
      header: 'connect-modal-installApp-header',
      description: 'connect-modal-installApp-description',
      descriptionContainer: 'connect-modal-signIn-installApp-container',
      learn_more: 'connect-modal-installApp-learn-more',
    },
    steps: {
      container: 'connect-modal-steps',
      button: 'connect-modal-steps-button',
    },
    centralLoaderSpinner: 'central-loader-spinner',
  },
  codeEditorFooter: {
    container: 'github-code-editor-footer',
    description: 'github-code-editor-footer-text',
    button: 'github-code-editor-footer-button',
  },
  localDevSetupModal: {
    container: 'local-dev-setup-modal-container',
  },
  githubTabBadge: 'github-tab-badge',
  githubTabIcon: 'github-tab-icon',
};

export default dataHooks;
