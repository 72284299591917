import {
  DropDown,
  DropDownOption,
  InfoIcon,
  TextLabel,
} from '@wix/wix-base-ui';
import React from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import styles from './DetailsModal.scss';
import { InputField } from '../AdapterDetails/InputField';
import { adapterInputConstraints } from '../constants';
import { DbType } from '../../../internal-types';
import { dataHooks } from '../dataHooks';
import { DetailsModalNotificationBanner } from './DetailsModalNotificationBanner';

import { DBVersion } from '@wix/ambassador-kore-provisioning-v1-googlesqldb/types';

interface CreateExtenalDbDetailsModalProps {
  didUserStartTyping: boolean;
  namespace: string;
  namespaceErrorMessage: string;
  setNamespace: any;
  setDidUserStartTyping: any;
  dbType: DBVersion;
  setDbType: any;
  didUserSelectOption: boolean;
  setDidUserSelectOption: any;
  didUserClickCreate: boolean;
}

const dbTypes: DbType[] = [
  {
    displayValue: 'SQL',
    value: DBVersion.MYSQL_8_0,
  },
  {
    displayValue: 'Postgres',
    value: DBVersion.POSTGRES_13,
  },
];

export const CreateExtenalDbDetailsModal: React.FC<
  CreateExtenalDbDetailsModalProps
> = ({
  didUserStartTyping,
  namespace,
  namespaceErrorMessage,
  setDidUserStartTyping,
  setNamespace,
  dbType,
  setDbType,
  didUserSelectOption,
  setDidUserSelectOption,
  didUserClickCreate,
}) => {
  const [t] = useTranslation();

  return (
    <div
      data-hook={dataHooks.externalDbPanels.createDb.enterDetails}
      className={styles.detailsModal}
    >
      <DetailsModalNotificationBanner />
      <div className={styles.detailsModalContainer}>
        <InputField
          className={[styles.textInput]}
          didUserStartTyping={didUserStartTyping}
          infoIconText={t(
            'External_Databases_Modal_Connect_Adapter_Namespace_Info_Tooltip',
          )}
          infoIconBIName=""
          infoIconDatahook=""
          inputValue={namespace}
          invalidMessage={namespaceErrorMessage}
          isValid={!namespaceErrorMessage}
          labelText={t('External_Databases.Modal.CreateNew.NameField.Label')}
          onChange={(value) => {
            setNamespace(value);
            setDidUserStartTyping(true);
          }}
          textInputDatahook={dataHooks.externalDbPanels.createDb.namespaceInput}
          placeHolder={t(
            'External_Databases.Modal.CreateNew.NameField.Placeholder',
          )}
          maxLength={adapterInputConstraints.MAX_NAMESPACE_LENGTH}
        />
        <div className={styles.dropdown}>
          <TextLabel
            value={t('External_Databases.Modal.CreateNew.TypeField.Label')}
          />
          <div className={styles.marginBottom} />
          {didUserClickCreate && !didUserSelectOption && (
            <InfoIcon
              error
              text={t(
                'External_Databases.Modal.CreateNew.TypeField.EmptyFieldError',
              )}
              className={styles.dropdownInfoIcon}
              dataHook={dataHooks.externalDbPanels.createDb.dropdownErrorIcon}
            />
          )}
          <DropDown
            dataHook={dataHooks.externalDbPanels.createDb.dropdown}
            value={dbType}
            onChange={(type: DBVersion) => {
              setDbType(type);
              setDidUserSelectOption(true);
            }}
            children={dbTypes.map((type, index) => (
              <DropDownOption
                value={type.value}
                key={index}
                label={type.displayValue}
              />
            ))}
            placeholder={t(
              'External_Databases.Modal.CreateNew.TypeField.Placeholder',
            )}
          />
        </div>
      </div>
    </div>
  );
};
