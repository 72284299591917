import type { StylableDriver } from '@wix/stylable-panel-drivers';
import type { MonacoModelMarker } from '@wix/wix-code-code-editor';
import { EDITOR_VIEW_ENVIRONMENT_BLACKLIST } from './constants';
import type { ChildNode, Declaration, Rule, Source } from 'postcss';

type Node = {
  selectorAst: { nodes: Node[] };
  nodes: Node[];
  type: string;
};

/**
 * Taken from monaco.MarkerSeverity due to an issue with import
 */
enum MarkerSeverity {
  Hint = 1,
  Info = 2,
  Warning = 4,
  Error = 8,
}

type MarkedNode = { source: Source } & (Rule | Declaration);

const TAG_SELECTOR_MESSAGE = `Tag selectors aren't supported and can break site designs. Use classes instead.`;
const PREVIEW_ONLY_MESSAGE = 'To view this style, go to Preview';

const markerUniqueData: Record<
  MarkedNode['type'],
  Pick<MonacoModelMarker, 'severity' | 'message'>
> = {
  decl: { message: PREVIEW_ONLY_MESSAGE, severity: MarkerSeverity.Info },
  rule: { message: TAG_SELECTOR_MESSAGE, severity: MarkerSeverity.Warning },
};

const getCustomCSSMarkersProvider =
  (stylable: StylableDriver['stylable']) =>
  (modelContent: string, path: string) => {
    const markers: MonacoModelMarker[] = [];
    const { ast } = stylable.fileProcessor.processContent(modelContent, path);
    ast.walk((node) => {
      if (shouldBeMarked(node)) {
        const marker = getMarker(node);
        markers.push(marker);
      }
    });
    return markers;
  };

const shouldBeMarked = (node: ChildNode): node is MarkedNode =>
  !!node.source && (isPreviewOnlyDeclaration(node) || isTagSelectorRule(node));

const getMarker = (node: MarkedNode) => {
  const location = node.source;
  const endColumnAddition = getEndColumnAddition(node);
  const locationData = {
    startLineNumber: location.start?.line || 0,
    endLineNumber: location.start?.line || 0,
    startColumn: location.start?.column || 0,
    endColumn: (location.start?.column || 0) + (endColumnAddition || 0),
  };
  const visualData = markerUniqueData[node.type];
  return {
    ...locationData,
    ...visualData,
  };
};

const getEndColumnAddition = (node: MarkedNode) =>
  node.type === 'rule' ? node.selector.length : node.prop.length;

const isPreviewOnlyDeclaration = (node: ChildNode): node is Declaration =>
  node.type === 'decl' && isPreviewOnlyProperty(node.prop);

const isTagSelectorRule = (node: ChildNode): node is Rule =>
  node.type === 'rule' && hasTypeSelectors(node as unknown as Node);

const isPreviewOnlyProperty = (propName: string) =>
  EDITOR_VIEW_ENVIRONMENT_BLACKLIST.includes(propName);

const hasTypeSelectors = (node: Node) => {
  const selectors = node.selectorAst?.nodes;
  return selectors?.some((selector) => {
    const selectorParts = selector.nodes;
    return selectorParts.some((part) => part.type === 'element');
  });
};

export default getCustomCSSMarkersProvider;
