import { Divider, Tooltip, TreeListItem } from '@wix/wix-base-ui';
import React from 'react';
import { TreeContextMenu } from '../TreeContextMenu/TreeContextMenu';
import { AlertCircleBoldSmall } from '@wix/wix-ui-icons-common/classic-editor';
import { useTranslation } from '@wix/wix-i18n-config';
import { MenuItemSection } from '../CollectionsTreeNode/CollectionsTreeNode';
import { dataHooks } from '../../dataHooks';
import s from './ErrorNamespace.scss';

export interface ErrorNamespaceProps {
  namespace: string;
  externalCollectionsSections: MenuItemSection[];
}

export const ErrorNamespace: React.FC<ErrorNamespaceProps> = ({
  namespace,
  externalCollectionsSections,
}) => {
  const [t] = useTranslation();

  const onContextMenuClick = (event: any) => {
    event.stopPropagation();
  };

  const contextMenu = (
    <div
      className="stop-propagation-bg"
      key="contextMenuContainer"
      onClick={onContextMenuClick}
    >
      <TreeContextMenu
        contextMenuStructure={{
          sections: [...externalCollectionsSections],
          separator: <Divider long={true} />,
        }}
        contextMenuButton="corvid_tree__context_menu_more"
        menuClassName="context-menu-icon"
        className="wix-code-file-tree-dd"
      />
    </div>
  );

  return (
    <TreeListItem
      dataHook={dataHooks.TREE_NODE}
      label={namespace}
      shouldTranslate={false}
      depth={1}
      onClick={() => {}}
      size="small"
      suffix={contextMenu}
      indication={{
        node: (
          <Tooltip
            content={t(
              'WixCode_TreeView_ExternalDatabases_Connection_Error_Tooltip',
            )}
            interactive={true}
          >
            <AlertCircleBoldSmall className={s.errorIconColor} />
          </Tooltip>
        ),
      }}
    />
  );
};
