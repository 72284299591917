import { FileSubTreeDataSource } from '@/sidebar/filesTree/FileSubTree';
import { CodeReduseFsReducerState } from './codeReuseFsReducer';

export const getCodeReuseFs = (
  state: { codeReuseFs: CodeReduseFsReducerState } & AnyFixMe,
  pkgId: string,
): FileSubTreeDataSource => {
  const fs = state.codeReuseFs[pkgId];
  return fs
    ? fs
    : {
        id: pkgId,
        name: pkgId,
        pendingRename: false,
        isFolder: true,
        childItems: [],
        loading: true,
        expanded: false,
        pendingCreation: true,
        pendingDelete: false,
      };
};
