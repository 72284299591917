import _ from 'lodash';
import coreUtilsLib from '@wix/santa-core-utils';
import { ActionType } from './types';
import codeStateReader from '../reducers/codeStateReader';
import fileActions from './fileActions';
import {
  codeReuseFsExpandParents,
  codeReuseFsSetExpanded,
} from '@/toExtract/packages/codeReuseFs/codeReuseFsActions';
import { getSingelFileSections } from '@/sidebar/filesTree/filesTree/treeSectionSelectors';
import { consts } from '@wix/wix-code-consts';
import { getPkgScope } from '@/toExtract/packages/utils';
import {
  treeSectionActions,
  STATIC_SECTIONS,
} from '@/sidebar/filesTree/filesTree/treeSectionReducer';
import { Dispatch, ThunkExtraArg } from '@wix/wix-code-common-components';
import {
  EditorAPI,
  FileDescriptor,
  FileSystemActions,
} from '@wix/wix-code-plugin-contracts';
import { SetFile, Dirs } from '../reducers/fileSystemReducer';
import { fileSystem } from '@wix/wix-code-common';

function setFile(file: SetFile['file']): SetFile {
  return {
    type: ActionType.SET_FILE,
    file,
  };
}

function expand(fileId: string) {
  return function (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) {
    const traceEnd = editorAPI.dsActions.wixCode.log.trace({
      action: 'expand',
      message: { fileId },
    });

    return dispatch(ensureChildrenLoaded(fileId))
      .then(function () {
        dispatch(setExpanded(fileId, true));
        traceEnd();
      })
      .catch(function (e: AnyFixMe) {
        traceEnd({
          message: e,
          level: editorAPI.wixCode.log.levels.ERROR,
        });
        throw e;
      });
  };
}

function expandNpmParents({ dispatch }: AnyFixMe) {
  const pkgJsonId = consts.WIX_CODE_PACKAGE_JSON_FILE_NAME;
  dispatch(
    treeSectionActions.expandTreeSection({
      sectionName: STATIC_SECTIONS.PKG_TREE,
    }),
  );
  dispatch(treeSectionActions.expandTreeSection({ sectionName: pkgJsonId }));
}

function expandCodeReuseParents({ dispatch, fileId }: AnyFixMe) {
  const pkgScope = getPkgScope(fileId);
  dispatch(
    treeSectionActions.expandTreeSection({
      sectionName: STATIC_SECTIONS.PKG_TREE,
    }),
  );
  dispatch(treeSectionActions.expandTreeSection({ sectionName: pkgScope }));
  dispatch(codeReuseFsExpandParents(fileId));
}

function expandCodeReuseConfigParents({ dispatch, fileId }: AnyFixMe) {
  dispatch(
    treeSectionActions.expandTreeSection({
      sectionName: STATIC_SECTIONS.PKG_TREE,
    }),
  );
  const pkgName = fileId.substring(
    `backend/${consts.CONFIG_FOLDER_NAME}/`.length,
    fileId.indexOf('-config.json'),
  );
  const pkgScope = getPkgScope(pkgName);
  dispatch(treeSectionActions.expandTreeSection({ sectionName: pkgScope }));
  dispatch(
    codeReuseFsSetExpanded({
      name: pkgName,
      fileId: pkgName,
      expanded: true,
    }),
  );
}

const getParent = (path: string) => {
  const parents = path
    .split('/')
    .filter((x) => x)
    .slice(0, -1);
  return parents.join('/') + '/';
};

const isRootFolder = (path: string) =>
  path.split('/').filter((x) => x).length === 1;

function refreshFolderWithNotifyCache(path: string) {
  return async function (
    dispatch: Dispatch,
    getState: any,
    { editorAPI }: ThunkExtraArg,
  ) {
    const parentPath = getParent(path);
    const doesPathExistInState = !!codeStateReader.getFileSystemEntry(
      getState(),
      path,
    );
    if (!isRootFolder(path) && !doesPathExistInState) {
      await dispatch(refreshFolderWithNotifyCache(parentPath));
    }
    await editorAPI.wixCode.fileSystem.notifyFileChanges([path]);
    await editorAPI.wixCode.fileSystem.refreshFolder(path);
  };
}

function expandParents(fileId: string) {
  return function (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) {
    const state = getState();
    const traceEnd = editorAPI.dsActions.wixCode.log.trace({
      action: 'expandParents',
      message: { fileId },
    });
    if (fileId.indexOf(`${consts.NPM_VIRTUAL_FOLDER_NAME}/`) === 0) {
      expandNpmParents({ dispatch });
    } else if (fileId.indexOf('@') === 0) {
      expandCodeReuseParents({ dispatch, fileId });
    } else if (fileId.indexOf(`backend/${consts.CONFIG_FOLDER_NAME}/`) === 0) {
      expandCodeReuseConfigParents({ dispatch, fileId });
    } else if (getSingelFileSections(state).includes(fileId)) {
      dispatch(treeSectionActions.expandTreeSection({ sectionName: fileId }));
    } else {
      const parentIds = codeStateReader.getParentIds(state, fileId);
      dispatch(setExpandedMany(parentIds, true));
    }
    traceEnd();
  };
}

function getChildren(fileId: AnyFixMe) {
  return function (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) {
    const traceEnd = editorAPI.dsActions.wixCode.log.trace({
      action: 'getChildren',
      message: { fileId },
    });

    const state = getState();
    const entry = codeStateReader.getFileSystemEntry(state, fileId);

    return editorAPI.wixCode.fileSystem
      .getChildren(entry.descriptor)
      .then(function (childFiles) {
        traceEnd();
        return _.map(childFiles, function (child) {
          return _.pick(child, ['name', 'location']);
        });
      })
      .catch(function (e: AnyFixMe) {
        traceEnd({
          message: e,
          level: editorAPI.wixCode.log.levels.ERROR,
        });
        throw e;
      });
  };
}

function deleteItem(descriptor: AnyFixMe) {
  const fileId = descriptor.location;
  return function (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) {
    dispatch(_setPendingDelete(fileId, true));
    const traceEnd = editorAPI.dsActions.wixCode.log.trace({
      action: 'deleteFile', // TODO: change traced action?
      message: { fileId },
    });

    return editorAPI.dsActions.wixCode.fileSystem
      .deleteItem(descriptor)
      .then(function () {
        dispatch(removeFromStore(descriptor));
        traceEnd();
      })
      .catch(function (e: AnyFixMe) {
        traceEnd({
          message: e,
          level: editorAPI.wixCode.log.levels.ERROR,
        });
        dispatch(_setPendingDelete(fileId, false));
        throw e;
      });
  };
}

const unregisterChildren = (
  folder: FileDescriptor,
  dispatch: Dispatch,
  state: any,
) => {
  const children = codeStateReader.getChildrenFileDescriptors(
    state,
    folder.location,
  );
  children.forEach((child: FileDescriptor) => {
    dispatch(unregisterItem(child.location));
    if (child.directory) {
      unregisterChildren(child, dispatch, state);
    }
  });
};

function removeFromStore(descriptor: FileDescriptor) {
  const fileId = descriptor.location;
  return (dispatch: Dispatch, getState: any) => {
    if (descriptor.directory) {
      unregisterChildren(descriptor, dispatch, getState());
    }
    dispatch(unregisterItem(fileId));
  };
}

function renameFileOrFolder(fileId: string, newName: AnyFixMe) {
  return function (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) {
    const traceEnd = editorAPI.dsActions.wixCode.log.trace({
      action: 'renameFile',
      message: { fileId, newName },
    });

    const state = getState();
    const entry = codeStateReader.getFileSystemEntry(state, fileId);
    const parent = codeStateReader.getFileSystemParentOf(state, fileId);

    dispatch(_setPendingRename(fileId, newName));

    return editorAPI.wixCode.fileSystem
      .move(entry.descriptor, parent.descriptor, newName)
      .then(function (newPath: string) {
        traceEnd();
        return newPath;
      })
      .catch(function (e: AnyFixMe) {
        dispatch(_setPendingRename(fileId, null));
        traceEnd({
          message: e,
          level: editorAPI.wixCode.log.levels.ERROR,
        });
        throw e;
      });
  };
}

function writeEmptyFile(fileDescriptor: AnyFixMe) {
  return function (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) {
    return editorAPI.dsActions.wixCode.fileSystem.writeFile(fileDescriptor);
  };
}

function createFolder(parentId: AnyFixMe, childName: AnyFixMe) {
  return function (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) {
    const traceEnd = editorAPI.dsActions.wixCode.log.trace({
      action: 'createFolder',
      message: { parentId, childName },
    });

    const state = getState();
    const parentEntry = codeStateReader.getFileSystemEntry(state, parentId);

    const virtualChildDescriptor = getVirtualChildDescriptor(
      editorAPI,
      childName,
      parentEntry.descriptor,
      true,
    );
    const childId = virtualChildDescriptor.location;

    dispatch(_addPendingChild(parentId, virtualChildDescriptor));

    return editorAPI.dsActions.wixCode.fileSystem
      .createFolder(childName, parentEntry.descriptor)
      .then(function (newFileDescriptor: AnyFixMe) {
        dispatch(_addChild(parentId, newFileDescriptor));
        traceEnd();
        return newFileDescriptor.location;
      })
      .catch(function (e: AnyFixMe) {
        coreUtilsLib.log.error(e);
        dispatch(unregisterItem(childId));
        traceEnd({
          message: e,
          level: editorAPI.wixCode.log.levels.ERROR,
        });
        throw e;
      });
  };
}

function createFile(
  parentId: AnyFixMe,
  childName: AnyFixMe,
  initialContent: AnyFixMe,
) {
  return function (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) {
    const content = initialContent || '';

    const traceEnd = editorAPI.dsActions.wixCode.log.trace({
      action: 'createFile',
      message: { parentId, childName },
    });

    const state = getState();
    const parentEntry = codeStateReader.getFileSystemEntry(state, parentId);

    const virtualChildDescriptor = getVirtualChildDescriptor(
      editorAPI,
      childName,
      parentEntry.descriptor,
      false,
    );
    const childId = virtualChildDescriptor.location;

    dispatch(_addPendingChild(parentId, virtualChildDescriptor));

    return dispatch(
      fileActions.changeContent(virtualChildDescriptor.location, content),
    )
      .then(function (newFileDescriptor: AnyFixMe) {
        dispatch(_addChild(parentId, newFileDescriptor));
        traceEnd();
        return newFileDescriptor.location;
      })
      .catch(function (e: AnyFixMe) {
        coreUtilsLib.log.error(e);
        dispatch(unregisterItem(childId));
        traceEnd({
          message: e,
          level: editorAPI.wixCode.log.levels.ERROR,
        });
        throw e;
      });
  };
}

function getVirtualChildDescriptor(
  editorAPI: EditorAPI,
  childName: AnyFixMe,
  parentFolderDescriptor: AnyFixMe,
  isFolder: AnyFixMe,
) {
  return editorAPI.wixCode.fileSystem.getVirtualDescriptor(
    parentFolderDescriptor.location + childName,
    isFolder,
  );
}

function setLoading(fileId: AnyFixMe, loading: AnyFixMe) {
  return {
    type: ActionType.SET_LOADING,
    fileId,
    loading,
  };
}

function setChildren(fileId: AnyFixMe, children: AnyFixMe) {
  return {
    type: ActionType.SET_CHILDREN,
    fileId,
    children,
  };
}

function _addChild(parentFolderId: string, child: FileDescriptor) {
  return {
    type: ActionType.ADD_CHILD,
    parentFolderId,
    pendingCreation: false,
    child,
  };
}

function _addPendingChild(parentFolderId: string, child: FileDescriptor) {
  return {
    type: ActionType.ADD_CHILD,
    parentFolderId,
    pendingCreation: true,
    child,
  };
}

function setExpanded(fileId: AnyFixMe, expanded: AnyFixMe) {
  return {
    type: ActionType.SET_EXPANDED,
    fileId,
    expanded,
  };
}

function setExpandedMany(fileIds: AnyFixMe, expanded: AnyFixMe) {
  return {
    type: ActionType.SET_EXPANDED_MANY,
    fileIds,
    expanded,
  };
}

function removeFile(fileId: AnyFixMe) {
  return {
    type: ActionType.REMOVE_FILE,
    fileId,
  };
}

function _removeChild(fileId: AnyFixMe, childId: AnyFixMe) {
  return {
    type: ActionType.REMOVE_CHILD,
    fileId,
    childId,
  };
}

function _setPendingRename(fileId: AnyFixMe, pendingRename: AnyFixMe) {
  return {
    type: ActionType.SET_PENDING_RENAME,
    fileId,
    pendingRename,
  };
}

function _setPendingDelete(fileId: AnyFixMe, pendingDelete: AnyFixMe) {
  return {
    type: ActionType.SET_PENDING_DELETE,
    fileId,
    pendingDelete,
  };
}

function moveFolder(oldLocation: string, newLocation: string) {
  return {
    type: ActionType.MOVE_FOLDER,
    oldLocation,
    newLocation,
  };
}

function collapse(fileId: AnyFixMe) {
  return setExpanded(fileId, false);
}

function ensureChildrenLoaded(fileId: AnyFixMe) {
  return function (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) {
    const state = getState();
    const entry = codeStateReader.getFileSystemEntry(state, fileId);
    if (entry.loaded || entry.loading) {
      return Promise.resolve();
    }

    dispatch(setLoading(fileId, true));

    return editorAPI.wixCode.fileSystem
      .getChildren(entry.descriptor)
      .then(function (childFiles: AnyFixMe) {
        dispatch(setChildren(fileId, childFiles));
        dispatch(setLoading(fileId, false));
      })
      .catch(function (e: AnyFixMe) {
        dispatch(setLoading(fileId, false));
        throw e;
      });
  };
}

function createVirtualFolder(parentId: AnyFixMe, childName: AnyFixMe) {
  return function (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) {
    const childId = `${parentId}${childName}/`;
    const childDescriptor =
      editorAPI.dsActions.wixCode.fileSystem.getVirtualDescriptor(
        childId,
        true,
      );

    dispatch(_addChild(parentId, childDescriptor));
  };
}

function unregisterItem(fileId: AnyFixMe) {
  return function (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) {
    const state = getState();
    const parent = codeStateReader.getFileSystemParentOf(state, fileId);

    const file = codeStateReader.getFile(getState(), fileId);
    if (file) {
      const modelId = codeStateReader.getFileModelId(getState(), fileId);
      editorAPI.wixCode.models.remove({ modelId });
    }

    // If backend folder was never expanded && experiment (se_wixCodeMoveNodeModulesToRoot) is on,
    // then the `wix-code-package.json` file is loaded but it has no parent
    if (parent) {
      dispatch(_removeChild(parent.id, fileId));
    }

    dispatch(removeFile(fileId));
  };
}

function setDirs(dirs: Dirs[]) {
  return (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) => {
    const dirsToSetPaths = _.map(dirs, 'location');
    const rootDirsPaths = _.map(
      editorAPI.wixCode.fileSystem.getRoots(),
      'location',
    );
    const existingDirs = codeStateReader.getDirs(getState());

    const dirsToRemove = existingDirs.filter((existingDir: any) => {
      const existingDirPath = existingDir.location;
      return (
        !dirsToSetPaths.includes(existingDirPath) &&
        !rootDirsPaths.includes(existingDirPath)
      );
    });
    dirsToRemove.forEach((dir: any) => dispatch(removeFromStore(dir)));
    dispatch({
      type: ActionType.SET_DIRS,
      dirs,
    });
  };
}

function setFiles(
  files: {
    path: string;
    content: string;
  }[],
) {
  return (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: ThunkExtraArg,
  ) => {
    const existingFilesModelIds = editorAPI.wixCode.models.getAll();
    const filesToRemove = existingFilesModelIds.filter(
      (existingModelId: any) => {
        return !files.find(
          (file) =>
            file.content ===
            fileSystem.convertModelIdToPath(existingModelId.getValue()),
        );
      },
    );
    filesToRemove.forEach((file: any) => dispatch(removeFromStore(file)));
    dispatch(fileActions.setFilesContent(files));
  };
}

const fileSystemActions: FileSystemActions = {
  setFile,
  setFiles,
  renameFileOrFolder,
  createFile,
  createFolder,
  delete: deleteItem,
  expand,
  collapse,
  getChildren,
  setChildren,
  setExpanded,
  setLoading,
  ensureChildrenLoaded,
  createVirtualFolder,
  unregisterItem,
  writeEmptyFile,
  setDirs,
  expandParents,
  removeFromStore,
  removeFile,
  _addPendingChild,
  _addChild,
  refreshFolderWithNotifyCache,
  moveFolder,
};

export default fileSystemActions;
