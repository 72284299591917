import { ActionType } from './actionTypes';

export type AddCustomClasses = {
  customClasses: string[];
  type: ActionType.ADD_CUSTOM_CLASSES;
};

export type SetIsExpanded = {
  isExpanded: boolean;
  type: ActionType.SET_IS_EXPANDED;
};

export type CssEditingAction = AddCustomClasses | SetIsExpanded;

export const addCustomClasses = (
  customClasses: string[],
): AddCustomClasses => ({
  type: ActionType.ADD_CUSTOM_CLASSES,
  customClasses,
});

export const setIsExpanded = (isExpanded: boolean): SetIsExpanded => ({
  type: ActionType.SET_IS_EXPANDED,
  isExpanded,
});
