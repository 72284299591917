import { APIKeys, EntryPoint, Shell } from '@wix/wix-code-repluggable';
import { experimentUtils } from '@wix/wix-code-common';
import { createVSCodePublicAPI } from './plugins/VSCodePublicAPI/vsCodePublicAPI';

export const VSCodeEntryPoint: EntryPoint = {
  name: 'VS Code Entry Point',
  getDependencyAPIs() {
    return [
      APIKeys.ClassicEditorAPI,
      APIKeys.ExperimentsAPI,
      APIKeys.SiteAPI,
      APIKeys.FileSystemAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.EditorContextAPI,
    ];
  },
  declareAPIs() {
    return [APIKeys.VSCodePublicAPI];
  },
  attach(shell: Shell) {
    shell.contributeAPI(APIKeys.VSCodePublicAPI, () =>
      createVSCodePublicAPI(shell),
    );
  },
  async extend(shell: Shell) {
    const experimentsAPI = shell.getAPI(APIKeys.ExperimentsAPI);
    const editorContextAPI = shell.getAPI(APIKeys.EditorContextAPI);
    if (
      !experimentsAPI.isOpen(experimentUtils.Experiments.VeloIDE) ||
      editorContextAPI.getEditorType() !== 'Studio'
    ) {
      return;
    }
    const vscodePublicAPI = shell.getAPI(APIKeys.VSCodePublicAPI);
    vscodePublicAPI.registerVSCodeSyncer();
  },
  detach(shell: Shell) {
    const vscodePublicAPI = shell.getAPI(APIKeys.VSCodePublicAPI);
    vscodePublicAPI.unregisterVSCodeSyncer();
  },
};
