import {
  AvailableCodeReusePkg,
  PkgAvailableVersionType,
  WixCodePackageCodeReuse,
} from './packagesModalContext';
import { captureError } from '@/infra/monitoring';
import { isReusePackageByVelo } from './utils';
import { experimentUtils } from '@wix/wix-code-common';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';

const CODE_REUSE_PKG_URL = '/wix-code-ide-server/apps/';
const CODE_REUSE_README_URL = '/wix-code-ide-server/packages/';

export interface CodeReuseFiles {
  path: string;
  content: string;
}

export interface CodeReusePkgVersions {
  publicVersions: CodeReusePkgVersion[];
  testVersion?: CodeReusePkgVersion;
}

export interface CodeReusePkgVersion {
  version: string;
  releaseNotes: string;
  displayName: string;
  createdDate: string;
}

interface FetchAvailableCodeReusePackagesResponse {
  userPackages: CodeReusePackage[];
  packagesByOthers: CodeReusePackage[];
}

interface CodeReusePackage {
  name: string;
  id: string;
  oldId: string;
  title: string;
  description: string;
  latestVersion?: Version;
  testVersion?: Version;
  downloadCount?: number;
}

interface Version {
  backEndPackage: string;
  frontEndPackage: string;
  version: string;
  gridAppId: string;
  createdDate: string;
  appDependencies: string[];
  privateRelease?: boolean;
}

export const fetchAvailableCodeReusePkgs = async (
  editorAPI: EditorAPI,
): Promise<{
  userPkgs: AvailableCodeReusePkg[];
  pkgsByOthersMetadata: AvailableCodeReusePkg[];
}> => {
  const fileSystem = editorAPI.wixCode.fileSystem;
  const myGridAppId = fileSystem.getViewerInfo().gridAppId;
  const url = `${CODE_REUSE_PKG_URL}${myGridAppId}/packages`;

  const response = await fetch(url, securityHeader(editorAPI));
  if (!response.ok) {
    parseNetworkError(response);
    return { pkgsByOthersMetadata: [], userPkgs: [] };
  }
  const data: FetchAvailableCodeReusePackagesResponse = await response.json();
  const userPackages = data.userPackages
    .filter((p) => !!(p.latestVersion || p.testVersion))
    .map(transformCodeReusePackage);
  const pkgsByOthersMetadata = data.packagesByOthers.map(
    transformCodeReusePackage,
  );
  return {
    pkgsByOthersMetadata,
    userPkgs: userPackages,
  };
};

export const fetchCodeReuseReadme = async (
  pkg: { id: string; version: string },
  editorAPI: EditorAPI,
): Promise<string> => {
  const readmeUrl = `${CODE_REUSE_README_URL}${pkg.id}/readmeV2?semver=${pkg.version}`;

  const response = await fetch(readmeUrl, securityHeader(editorAPI));

  if (!response.ok) {
    if (response.status === 404) {
      return '';
    }

    return parseNetworkError(response);
  }

  return response.text();
};

export const fetchCodeReusePkgVersions = async (
  srcPkg: WixCodePackageCodeReuse,
  editorAPI: EditorAPI,
): Promise<CodeReusePkgVersions> => {
  const fileSystem = editorAPI.wixCode.fileSystem;
  const myGridAppId = fileSystem.getViewerInfo().gridAppId;
  const { id, frontEndPackage, backEndPackage } = srcPkg;
  let response: Response;
  let errorRes = null as any;

  const versionsUrl = `${CODE_REUSE_PKG_URL}${myGridAppId}/packages/${id}/versions`;

  try {
    response = await fetch(versionsUrl, securityHeader(editorAPI));
    if (!response.ok) {
      errorRes = await parseNetworkError(response);
    }
  } catch (error) {
    errorRes = error;
  }
  if (errorRes) {
    const pkgName = frontEndPackage || backEndPackage;
    console.error(
      `Error fetching files for package ${pkgName}. details: ${errorRes}`,
    );
    return { publicVersions: [] };
  }

  const { versions, testVersion } = await response!.json(); // strictNullChecks
  const latestVersion = testVersion ?? versions[0];
  const isVelo = isReusePackageByVelo(latestVersion.displayName);

  return {
    publicVersions: versions.filter(
      (version: AnyFixMe) =>
        !testVersion || version.version !== testVersion.version,
    ),
    testVersion:
      experimentUtils.isShowTestVersion() && !isVelo
        ? latestVersion
        : undefined,
  };
};

export const fetchCodeReuseFiles = async (
  srcPkg: WixCodePackageCodeReuse,
  editorAPI: EditorAPI,
  translate: AnyFixMe,
): Promise<CodeReuseFiles[]> => {
  const fileSystem = editorAPI.wixCode.fileSystem;
  const myGridAppId = fileSystem.getViewerInfo().gridAppId;
  const { id, frontEndPackage, backEndPackage } = srcPkg;
  let response: Response;
  let errorRes = null as any;

  const filesUrl = `${CODE_REUSE_PKG_URL}${myGridAppId}/packages/${id}/filesV2`;

  try {
    response = await fetch(filesUrl, securityHeader(editorAPI));
    if (!response.ok) {
      errorRes = await parseNetworkError(response);
    }
  } catch (error) {
    errorRes = error;
  }
  if (errorRes) {
    const pkgName = frontEndPackage || backEndPackage;
    console.error(
      `Error fetching files for package ${pkgName}. details: ${errorRes}`,
    );
    return [
      {
        path: translate('Package_Error_File_Name'),
        content: translate('Package_Error_File_Message'),
      },
    ];
  }

  return response!.json(); // strictNullChecks
};

const securityHeader = (editorAPI: EditorAPI): RequestInit => ({
  mode: 'cors',
  credentials: 'include',

  headers: {
    'x-wix-si': editorAPI.wixCode.getClientSpec().instance,
  },
});

const parseNetworkError = async (response: AnyFixMe): Promise<string> => {
  let details;
  try {
    const resObj = (await response.json()) as any;
    details = resObj.Message || resObj.errorDescription;
  } catch {
    details = response.statusText;
  }
  console.error('network error details:', { response, details });
  captureError(details);
  return details;
};

const transformCodeReusePackage = (
  p: CodeReusePackage,
): AvailableCodeReusePkg => {
  const {
    description,
    title,
    downloadCount,
    id,
    oldId,
    latestVersion,
    testVersion,
  } = p;

  const latest = latestVersion || testVersion;
  const availableVersionType = !latestVersion
    ? PkgAvailableVersionType.TEST_ONLY
    : testVersion
    ? PkgAvailableVersionType.BOTH
    : PkgAvailableVersionType.NO_TEST;

  const {
    version,
    backEndPackage,
    frontEndPackage,
    gridAppId,
    createdDate,
    appDependencies,
    privateRelease = false,
  } = latest!;

  return {
    id,
    oldId,
    name: p.name,
    description,
    title,
    downloadCount,
    version,
    testVersion: testVersion?.version,
    publishedDate: createdDate,
    backEndPackage,
    frontEndPackage,
    gridAppId,
    appDependencies,
    privateRelease,
    availableVersionType,
  };
};
