import {
  experimentUtils,
  clientExperimentsService,
} from '@wix/wix-code-common';
import { BI_EVENTS } from '@/legacy/bi/events';
import { isQA } from '@/utils/urlQueryParamUtils';
import _ from 'lodash';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';

interface ReportTranslationKeyUsage {
  reportTranslationKeyUsage(key: string): void;
}

/**
 * Collect BI data on translation key usage. Used to detect unused keys.
 * Should be open only to Wix Employees.
 * Heavily influenced by a similar module in santa-editor https://github.com/wix-private/santa-editor/blob/7edb7375732b1303bc997db600bdead2e96ac0f9/santa-editor/packages/i18n/reportTranslationKeyUsage/reportTranslationKeyUsage.ts#L8
 *
 * to query the data: https://bo.wix.com/quix/#/notebook/a8703682-1dd2-49c3-8bd4-4d678e813596
 */
export const reportTranslationKeyUsageCreator = ({
  editorAPI,
  experimentsService,
}: {
  editorAPI: EditorAPI;
  experimentsService: clientExperimentsService.ClientExperimentService;
}): ReportTranslationKeyUsage => {
  const isEnabled =
    experimentsService.isOpen(
      experimentUtils.Experiments.ReportTranslationKeyUsage,
    ) &&
    !isQA() &&
    'requestIdleCallback' in window;

  const translationKeysToSend = new Set<string>();
  const translationKeysProcessed = new Set<string>();

  const sendBiEvent = (translationKeys: string[]) => {
    editorAPI.bi.event(BI_EVENTS.TRANSLATION_KEY_USED, {
      translationKeys: translationKeys.join(','),
    });
  };

  const scheduleReport = _.debounce(() => {
    window.requestIdleCallback(() => {
      // Slice `maxKeysToSend` items, to be sure bi event is send without `413 Entity Too Large` error (too long URL)
      const maxKeysToSend = 200;
      const keysToSendBatch = Array.from(translationKeysToSend).slice(
        0,
        maxKeysToSend,
      );

      sendBiEvent(keysToSendBatch);

      keysToSendBatch.forEach((key) => {
        translationKeysToSend.delete(key);
      });

      if (translationKeysToSend.size > 0) {
        scheduleReport();
      }
    });
  }, 1000);

  function reportTranslationKeyUsage(translationKey: string): void {
    if (!isEnabled || translationKeysProcessed.has(translationKey)) {
      return;
    }

    translationKeysProcessed.add(translationKey);
    translationKeysToSend.add(translationKey);

    scheduleReport();
  }

  return { reportTranslationKeyUsage };
};
