import React from 'react';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import consoleMessageOriginCreator from './consoleMessageOrigin';
import consoleMessageActionCreator from './consoleMessageAction';
import consoleConstants from '@/legacy/core/components/console/constants';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import JsonVisualizerCreator from '@/legacy/core/components/console/jsonVisualizer/jsonVisualizer';

import once_ from 'lodash/once';

export default once_(({ baseUI, util }) => {
  const ConsoleMessageOrigin = consoleMessageOriginCreator({ baseUI, util });
  const ConsoleMessageAction = consoleMessageActionCreator({ baseUI, util });
  const JsonVisualizer = JsonVisualizerCreator({ util });

  function spacify(arr) {
    return arr.reduce((acc, part) => {
      if (acc.length === 0) {
        return [part];
      }

      const init = _.initial(acc);
      const last = _.last(acc);

      if (_.isObject(last) && _.isObject(part)) {
        return [...init, last, ' ', part];
      }
      if (_.isObject(part)) {
        return [...init, `${last} `, part];
      }
      if (_.isObject(last)) {
        return [...init, last, ` ${part}`];
      }
      return [...init, `${last} ${part}`];
    }, []);
  }

  const ConsoleMessage = createReactClass({
    displayName: 'WixCodeConsoleMessage',

    propTypes: {
      message: PropTypes.object,
    },

    getMessageParts(args) {
      return spacify(args);
    },

    isStringPart(part) {
      return !_.isObject(part) || _.isEmpty(part);
    },

    isJsonVisualationPart(part) {
      return _.isObject(part) && !part.linkType;
    },

    isHrefPart(part) {
      return !_.isEmpty(part) && part.linkType === 'href';
    },

    isCallbackPart(part) {
      return !_.isEmpty(part) && part.linkType === 'callback';
    },

    runCallback(messagePart) {
      messagePart.callback && messagePart.callback(messagePart);
    },

    render() {
      const { message } = this.props;
      const { logLevel, origin, action, args } = message;
      return (
        <div
          className={classNames({
            'wix-code-console-message': true,
            [logLevel.toLowerCase()]: true,
          })}
          data-log-level={logLevel.toLowerCase()}
        >
          <div className="message-and-icon">
            <div className="icon">
              {logLevel === consoleConstants.LOG_LEVEL.ERROR && (
                <Symbol key="errorIcon" name="code-console-error-icon" />
              )}
              {logLevel === consoleConstants.LOG_LEVEL.WARNING && (
                <Symbol key="warningIcon" name="code-console-warning-icon" />
              )}
              {logLevel === consoleConstants.LOG_LEVEL.INFO && (
                <Symbol key="infoIcon" name="code-console-info-icon" />
              )}
            </div>
            <div className="message">
              {this.getMessageParts(args).map((part, partIndex) => (
                <div key={partIndex} className="message-part">
                  {this.isStringPart(part) && (
                    <div className="message-part-content">{String(part)}</div>
                  )}
                  {this.isJsonVisualationPart(part) && (
                    <JsonVisualizer
                      value={part}
                      className="message-part-content"
                    />
                  )}
                  {this.isCallbackPart(part) && (
                    <div
                      key={partIndex}
                      onClick={() => this.runCallback(part)}
                      className="clickable"
                    >
                      {String(part.text)}
                    </div>
                  )}
                  {this.isHrefPart(part) && (
                    <a
                      key={partIndex}
                      href={part.url}
                      target="_blank"
                      rel="noreferrer"
                      className="clickable"
                      onClick={() => this.runCallback(part)}
                    >
                      {String(part.text)}
                    </a>
                  )}
                </div>
              ))}
            </div>
          </div>
          {origin && <ConsoleMessageOrigin message={message} />}
          {action && <ConsoleMessageAction message={message} />}
        </div>
      );
    },
  });

  return ConsoleMessage;
});
