// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S1sxYQ{padding:12px 24px;height:100%}.S1sxYQ .XRyqV1{margin-bottom:10px}.S1sxYQ .UgK1rO{padding-left:5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "S1sxYQ",
	"title": "XRyqV1",
	"instructions": "UgK1rO"
};
export default ___CSS_LOADER_EXPORT___;
