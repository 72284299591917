import { EditorAPI } from '@wix/wix-code-plugin-contracts';

export const openToolDashboard = ({
  editorAPI,
  util,
  appDefId = '',
  path = '',
}: {
  editorAPI: EditorAPI;
  util: any;
  appDefId?: string;
  path?: string;
}) => {
  const pathPrefix = appDefId === '' ? '' : 'app/';
  const BUSINESS_MANAGER_URL = util.serviceTopology.businessManagerUrl;
  const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();

  // url is business-managers API: https://github.com/wix-private/business-manager/blob/master/business-manager-web/docs/endpoints.md#dashboardmsidappappdefidappstate
  window.open(
    `${BUSINESS_MANAGER_URL}/${metaSiteId}/${pathPrefix}${appDefId}${path}?referralInfo=EDITOR`,
  );
};
