import * as React from 'react';
import cx from 'classnames';
import { CorvidSiteStructure } from '@wix/wix-base-ui';
import dataHooks from './dataHooks';
import { PredefinedSidebarTabs } from '@wix/wix-code-plugin-contracts';
import { experimentUtils } from '@wix/wix-code-common';
import { TabDetailsMap } from './SidePaneTabs';

export interface TabDetails {
  content: React.ReactElement;
  value: string;
  dataHook?: string;
  label: string;
  infoLinkText?: string;
  infoLinkUrl?: string;
  infoText?: string;
  symbol: React.ReactElement;
  className?: string;
  shouldShowTab: (editorAPI?: any) => boolean;
}

interface SidebarProps {
  currentTab: PredefinedSidebarTabs;
  leftPaneIsOpen: boolean; // TODO rename
  sendTabChangeBi: (tab: PredefinedSidebarTabs) => void; // TODO rename
  setCurrentTab: (tab: PredefinedSidebarTabs) => void;
  t: (translationKey: string) => string;
  tabDetails: TabDetailsMap;
  toggleLeftTree: () => void;
  topTabs: string[];
  bottomTabs: string[];
  isVeloPhase0: boolean;
}

const wrapTabSymbol = (
  tabDetails: TabDetails,
  currentTab: PredefinedSidebarTabs,
): TabDetails => ({
  ...tabDetails,

  symbol: (
    <div
      className={cx('corvid_tab_icon', {
        selected: tabDetails.value === currentTab,
      })}
    >
      {tabDetails.symbol}
    </div>
  ),
});

export const Sidebar: React.FC<SidebarProps> = ({
  currentTab,
  leftPaneIsOpen,
  sendTabChangeBi,
  setCurrentTab,
  t,
  tabDetails,
  toggleLeftTree,
  topTabs,
  bottomTabs,
  isVeloPhase0,
}) => {
  const { label, infoText, infoLinkText, infoLinkUrl, className } =
    tabDetails[currentTab];
  const topTabDetails: TabDetails[] = topTabs.map((topTab: string) =>
    wrapTabSymbol(tabDetails[topTab], currentTab),
  );
  const bottomTabDetails: TabDetails[] =
    !isVeloPhase0 && experimentUtils.isMoveHelpTabToBottomOfSidebar()
      ? bottomTabs.map((bottomTab: string) =>
          wrapTabSymbol(tabDetails[bottomTab], currentTab),
        )
      : [];
  const onTabChange = (tab: PredefinedSidebarTabs): void => {
    if (!leftPaneIsOpen) {
      sendTabChangeBi(tab);
      toggleLeftTree();
      setCurrentTab(tab);
    } else if (tab === currentTab && !isVeloPhase0) {
      toggleLeftTree();
    } else {
      sendTabChangeBi(tab);
      setCurrentTab(tab);
    }
  };

  return (
    <CorvidSiteStructure
      dataHook={dataHooks.LEFT_PANE_ROOT}
      className={'corvid-site-structure' + (className ? ` ${className}` : '')} // TODO import this from module?
      value={currentTab}
      onChange={onTabChange}
      collapsed={!leftPaneIsOpen}
      topTabs={
        isVeloPhase0 ? [...topTabDetails, ...bottomTabDetails] : topTabDetails
      }
      bottomTabs={bottomTabDetails}
      headerText={label}
      infoText={infoText}
      infoLinkText={infoLinkText}
      onLinkClick={
        () => window.open(t(infoLinkUrl!), '_blank') /* strictNullChecks */
      }
      onBarClick={() => {
        if (!isVeloPhase0) {
          toggleLeftTree();
        }
      }}
      showOnlyLabels={isVeloPhase0}
    >
      {tabDetails[currentTab].content}
    </CorvidSiteStructure>
  );
};
