import React, { ReactElement } from 'react';
import { PredefinedSidebarTabs } from '../SidebarAPI';

export type FileValidationRule = (fileName: string) => boolean;

export type SectionProps = {
  isLast: boolean;
};

export type SectionContribution = {
  createSection: (props: SectionProps) => ReactElement;
  /** a unique identifier */
  id: string;
  order: number;
};

export type FILE_TREE_QUICK_ACTIONS =
  | 'NEW_FOLDER'
  | 'NEW_FILE'
  | 'NEW_JS_FILE'
  | 'NEW_TS_FILE'
  | 'NEW_JSW_FILE'
  | 'NEW_WEB_JS_FILE'
  | 'NEW_JSX_FILE'
  | 'DELETE'
  | 'RENAME'
  | 'OPEN_WEBMODULE_PERMISSIONS'
  | 'JOBS_SCHEDULER'
  | 'HTTP_FUNCTIONS'
  | 'DATA_HOOKS'
  | 'EVENTS';

export interface FileTreeCustomFolderAction {
  disabled?: boolean;
  icon: React.FC;
  label: string;
  onClickCreator: (folderPath: string) => () => void;
  predicate: (folderName: string) => boolean;
}

export interface FileTreeCustomAction {
  disabled?: boolean;
  icon: React.FC;
  label: string;
  onClick: () => void;
}

export interface HiddenFileAction {
  actionsToHide: FILE_TREE_QUICK_ACTIONS[];
  predicate: (...args: any) => boolean;
}

export enum TreeNodeType {
  FILE,
  FOLDER,
  ROOT,
}

export type NodeIcon = {
  nodeType: TreeNodeType;
  icon: ReactElement | null;
  predicate: (nodePath: string) => boolean;
};

export type CustomNodeName = {
  predicate: (nodePath: string) => boolean;
  customName: string;
};

export interface FileTreeRootProps {
  id: string;
  labelOverride: string;
  isBackend: boolean;
  isExternalComponents?: boolean;
  hiddenActions?: FILE_TREE_QUICK_ACTIONS[];
  hiddenFiles?: string[];
  hiddenRootActions?: FILE_TREE_QUICK_ACTIONS[];
  customRootActions?: FileTreeCustomAction[];
  hiddenFolderActions?: FILE_TREE_QUICK_ACTIONS[];
  customFolderActions?: FileTreeCustomFolderAction[];
  hiddenFileActionsByPredicate?: HiddenFileAction[];
  isLast?: boolean;
  sectionDataHook?: string;
  customNodeIcons?: NodeIcon[];
  customNodeNames?: CustomNodeName[];
  excludedFiles?: string[];
  translations?: { [key: string]: string };
  customBiReportSectionName?: string;
  readOnlyMode: boolean;
}

export interface FileSubTreeDataSource {
  id: string;
  name: string;
  pendingRename: string;
  isFolder: boolean;
  childItems: FileSubTreeDataSource[];
  loading: boolean;
  expanded: boolean;
  pendingCreation: boolean;
  pendingDelete: boolean;
}

export type NewChild = {
  initialName: string;
  isFolder: boolean;
  parentFolderPath: string;
};

export interface DisabledTooltipSymbolProps {
  iconName: string;
  tooltipContent: string;
}

export interface FilesTreeAPI {
  contributeSection(sectionContribution: SectionContribution): void;
  contributeFileValidationRule(rule: FileValidationRule): void;
  FileTreeRoot: React.FC<FileTreeRootProps>;
  expandSection(sectionName: string): void;
  expandParents(filePath: string): void;
  selectFile(filePath: string, keepSelectedComponentFocused?: boolean): void;
  showNewFileInput(
    initialName: string,
    suffix: string,
    isFolder: boolean,
    folderPath: string,
  ): void;
  setNewFileRenameInput(newChild: NewChild | null): void;
  getNewFileRenameInput(): NewChild | null;
  selectTab(tabName: PredefinedSidebarTabs): void;
}

export * from './fileTreeReduxTypes';
