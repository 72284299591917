// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YIFZtT{width:520px;height:331px;overflow:hidden}.DP4eer{width:20px;height:20px}.eSEskW{display:flex;justify-content:space-between}.responsive-skin .control-button.btn-md.prefix-icon{padding:0 16px 0 16px}.ZYTPsz{margin-left:auto}.g56A3A{display:flex;align-items:center;justify-content:center;padding-left:5px;padding-right:5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "YIFZtT",
	"loader": "DP4eer",
	"footerContent": "eSEskW",
	"primaryButton": "ZYTPsz",
	"preloaderButton": "g56A3A"
};
export default ___CSS_LOADER_EXPORT___;
