export enum ResourceStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  LOADING = 'LOADING',
}

export type Resource<T> = {
  data: T[] | undefined;
  status: ResourceStatus;
  prev: T[] | undefined;
};
