import React from 'react';
import { Tooltip, RichText } from '@wix/wix-base-ui';
import LockIcon from '@/assets/icons/leftTree/lock.svg';
import s from '../codeReusePkgTree.scss';
import dataHooks from '../../../../dataHooks';

interface ReadOnlyFileSymbolProps {
  HELP_URL: string;
  onClick: () => void;
  tooltipText: string;
  helpLinkText: string;
}

export const ReadOnlyFileSymbol: React.FC<ReadOnlyFileSymbolProps> = ({
  HELP_URL,
  onClick,
  tooltipText,
  helpLinkText,
}) => {
  return (
    <span data-hook={dataHooks.READ_ONLY_TAB}>
      <Tooltip
        marginTop={4}
        interactive={true}
        content={
          <RichText>
            {tooltipText}
            <a
              target="_blank"
              className={s.readOnlyLink}
              href={HELP_URL}
              onClick={onClick}
            >
              {helpLinkText}
            </a>
          </RichText>
        }
      >
        <LockIcon className={s.readOnly} />
      </Tooltip>
    </span>
  );
};
