import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { initi18n } from './i18n';
import { subscribeToSpiPublishEvent } from './services/duplexerService';
import { createSpiService, isSpiFolder } from './SpiService';
import * as connectionStatus from './state/connectionStatus';
import { contributeLeftTreeSection } from './components/SpiLeftTreeSection/leftTreeSectionContribution';
import { experimentUtils } from '@wix/wix-code-common';

type SpiStoreState = {
  connections: connectionStatus.State;
};

export const SpiPluginEntryPoint: EntryPoint = {
  name: 'Spi Plugin',
  getDependencyAPIs() {
    return [
      APIKeys.FilesTreeAPI,
      APIKeys.ExperimentsAPI,
      APIKeys.WixCodeAppAPI,
      APIKeys.PanelsAPI,
      APIKeys.UserPreferencesAPI,
      APIKeys.DuplexerAPI,
      APIKeys.SiteAPI,
      APIKeys.FileSystemAPI,
      APIKeys.ServiceTopology,
      APIKeys.BiLoggerAPI,
      APIKeys.ReadOnlyAPI,
      APIKeys.PackagesTabAPI,
      APIKeys.ClassicEditorAPI,
      APIKeys.VSCodePublicAPI,
      APIKeys.EditorContextAPI,
      WixCodeEditorAdapterAPI,
    ];
  },
  attach(shell: Shell) {
    shell.contributeState<SpiStoreState>(() => ({
      connections: connectionStatus.reducer,
    }));
  },
  async extend(shell: Shell) {
    const experimentsAPI = shell.getAPI(APIKeys.ExperimentsAPI);
    if (experimentsAPI.isOpen('specs.wixCode.SPICutsomIntegration')) {
      const filesTreeAPI = shell.getAPI(APIKeys.FilesTreeAPI);
      const wixCodeAppAPI = shell.getAPI(APIKeys.WixCodeAppAPI);
      const panelsAPI = shell.getAPI(APIKeys.PanelsAPI);
      const userPreferencesAPI = shell.getAPI(APIKeys.UserPreferencesAPI);
      const duplexerAPI = shell.getAPI(APIKeys.DuplexerAPI);
      const siteAPI = shell.getAPI(APIKeys.SiteAPI);
      const fileSystemAPI = shell.getAPI(APIKeys.FileSystemAPI);
      const serviceTopology = shell.getAPI(APIKeys.ServiceTopology);
      const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
      const readOnlyAPI = shell.getAPI(APIKeys.ReadOnlyAPI);
      const packagesTabAPI = shell.getAPI(APIKeys.PackagesTabAPI);
      const wixCodeEditorAdapterAPI = shell.getAPI(WixCodeEditorAdapterAPI);
      const classicEditorAPI = shell.getAPI(APIKeys.ClassicEditorAPI);
      const vsCodePublicAPI = shell.getAPI(APIKeys.VSCodePublicAPI);
      const i18n = await initi18n(siteAPI.getUserLanguage());

      const store = shell.getStore<SpiStoreState>();

      const spiService = createSpiService({
        APIs: {
          experimentsAPI,
          fileSystemAPI,
          filesTreeAPI,
          panelsAPI,
          serviceTopology,
          siteAPI,
          userPreferencesAPI,
          wixCodeAppAPI,
          biLoggerAPI,
          readOnlyAPI,
          wixCodeEditorAdapterAPI,
          classicEditorAPI,
        },
        store,
        isLiteEditor: experimentUtils.isLiteEditor(),
      });
      spiService.refreshSpiStatusesFromServer();
      subscribeToSpiPublishEvent(duplexerAPI, spiService.onSpiPublish);

      filesTreeAPI.contributeFileValidationRule(isSpiFolder);
      contributeLeftTreeSection({
        i18n,
        filesTreeAPI,
        experimentsAPI,
        wixCodeAppAPI,
        panelsAPI,
        userPreferencesAPI,
        duplexerAPI,
        siteAPI,
        fileSystemAPI,
        serviceTopology,
        spiService,
        shell,
        biLoggerAPI,
        readOnlyAPI,
        packagesTabAPI,
        wixCodeEditorAdapterAPI,
        vsCodePublicAPI,
      });
    }
  },
};
