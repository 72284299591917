import { useTranslation } from '@wix/wix-i18n-config';
import React from 'react';
import styles from './ExternalDBPanels.scss';
import WixGoogleConnection from '../../assets/icons/wixGoogleConnection.svg';
import { Heading, PromotionalList, TextButton } from '@wix/wix-base-ui';
import { dataHooks } from './dataHooks';
import { Confirm } from '@wix/wix-ui-icons-common';

export const CustomPromotionalModal: React.FC = () => {
  const [t] = useTranslation();

  return (
    <div
      className={styles.promotionalModalContainer}
      data-hook={dataHooks.externalDbPanels.createDb.promotionalModal}
    >
      <WixGoogleConnection />
      <div className={styles.largeMarginTop} />
      <Heading appearance="h2">
        {t('External_Databases.Modal.CreateNew.Promotional.Title')}
      </Heading>
      <div className={styles.marginTop} />
      <div className={styles.largeMarginTop} />
      <PromotionalList
        symbol={<Confirm />}
        items={[
          t('External_Databases.Modal.CreateNew.Promotional.ListItem1'),
          t('External_Databases.Modal.CreateNew.Promotional.ListItem2'),
          t('External_Databases.Modal.CreateNew.Promotional.ListItem3'),
        ]}
      />
      <TextButton></TextButton>
    </div>
  );
};
