import React, { useEffect, useState } from 'react';
import styles from './Terminal.scss';
import ConfirmCircle from 'wix-ui-icons-common/classic-editor/ConfirmCircle';
import DuplicateBold from 'wix-ui-icons-common/classic-editor/DuplicateBold';
import _ from 'lodash';
import { Popover, Text } from '@wix/wix-base-ui';
import TerminalCommand from './TerminalCommand/TerminalCommand';
import dataHooks from './dataHooks';

type TerminalProps = {
  terminalCommands: string[];
  onCopyCode: () => void;
  copyCodeText: string;
};

export const Terminal: React.FC<TerminalProps> = ({
  onCopyCode,
  terminalCommands,
  copyCodeText,
}) => {
  const [showCopyCode, setShowCopyCode] = useState(false);
  const [showCopyCodeSuccess, setShowCopyCodeSuccess] = useState(false);

  useEffect(() => {
    if (showCopyCodeSuccess) {
      setTimeout(() => {
        setShowCopyCodeSuccess(false);
      }, 2000);
    }
  }, [showCopyCodeSuccess]);

  const CopyCode = () => {
    return (
      <DuplicateBold
        data-hook={dataHooks.terminal.copyCodeButton}
        className={styles.copyIcon}
        onClick={async () => {
          onCopyCode();
          const textToCopy = _.join(terminalCommands, '\n');
          await navigator?.clipboard?.writeText(textToCopy);
          setShowCopyCodeSuccess(true);
        }}
      />
    );
  };

  const CopyCodeSuccess = () => {
    return (
      <Popover showArrow shown animate appendTo="scrollParent" placement="top">
        <Popover.Element>
          <ConfirmCircle
            data-hook={dataHooks.terminal.confirmCircle}
            className={styles.confirmCopyIcon}
          />
        </Popover.Element>
        <Popover.Content>
          <div className={styles.confirmPopoverContent}>
            <Text
              weight="normal"
              dataHook={dataHooks.terminal.tooltip}
              shouldTranslate={false}
            >
              {copyCodeText}
            </Text>
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  return (
    <div
      onMouseOver={() => setShowCopyCode(true)}
      onMouseLeave={() => setShowCopyCode(false)}
      className={styles.terminalContainer}
      data-hook={dataHooks.terminal.container}
    >
      <div className={styles.terminalContent}>
        {terminalCommands.map((command, idx) => (
          <TerminalCommand
            dataHook={dataHooks.terminal.command}
            key={idx}
            command={command}
          />
        ))}
      </div>
      {showCopyCodeSuccess && <CopyCodeSuccess />}
      {showCopyCode && !showCopyCodeSuccess && <CopyCode />}
    </div>
  );
};

export default Terminal;
