import * as React from 'react';
import { Preloader } from '@wix/wix-base-ui';
import { PackagesModalContext } from '../../../packagesModalContext';
import { PackageMarkDownHeaderV2 } from './PackageMarkDownHeaderV2';
import { isReusePackage } from '../../../utils';
import s from './PackageMarkDown.scss';
import { useEffect, useRef, useState } from 'react';
import dataHooks from '../../../dataHooks';
import { MarkdownViewerLazy } from '@/codeEditor/components/markdown/markdownViewerLazy';
import { PackageMarkDownFooter } from './PackageMarkDownFooter';
import { CodeReusePkgVersion } from '../../../codeReuseServerAPI';
import { PackageReleaseNotes } from './PackageReleaseNotes';
import { getSelectedVersion } from '../../packagesModalSelectors';
import { useSelector } from '@wix/wix-code-common-components';

export const PackageMarkDown: React.FC = () => {
  const { selectedPackage, packagesService, isNpmPackagesFetchingDone } =
    React.useContext(PackagesModalContext);
  const [readme, setReadme] = useState<string>('');
  const [versions, setVersions] = useState<CodeReusePkgVersion[]>([]);
  const [readmeLoaded, setReadmeLoaded] = useState<boolean>(false);
  const [notesLoaded, setNotesLoaded] = useState<boolean>(false);
  const prevSelectedPackageRef = useRef<string>();

  const selectedPackageVersion = useSelector(getSelectedVersion);

  const hasReleaseNotes = isReusePackage(selectedPackage);

  const Loader = ({ className }: AnyFixMe) => (
    <div className={className} data-hook={dataHooks.MARKDOWN_LOADER}>
      <Preloader size="large" />
    </div>
  );

  const loadReadme = async () => {
    const readmeText = await packagesService.getPackageReadme(
      selectedPackage,
      selectedPackageVersion,
    );
    if (prevSelectedPackageRef.current === selectedPackage) {
      setReadme(readmeText);
      setReadmeLoaded(true);
    }
  };

  const loadReleaseNotes = async () => {
    if (hasReleaseNotes) {
      const pkgVersions = await packagesService.getCodeReusePkgVersions(
        selectedPackage,
      );
      if (prevSelectedPackageRef.current === selectedPackage) {
        setVersions(pkgVersions.publicVersions);
        setNotesLoaded(true);
      }
    } else {
      setNotesLoaded(true);
    }
  };

  useEffect(() => {
    prevSelectedPackageRef.current = selectedPackage;
    setReadmeLoaded(false);
    setNotesLoaded(false);
    setReadme('');
    setVersions([]);

    loadReadme();
    loadReleaseNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackage, selectedPackageVersion]);

  const showLoader = !readmeLoaded && (!notesLoaded || !hasReleaseNotes);
  const readmeSection = readmeLoaded ? (
    <MarkdownViewerLazy>{readme}</MarkdownViewerLazy>
  ) : (
    <Loader className={s.halfloader} />
  );
  const notesSection = !hasReleaseNotes ? null : notesLoaded ? (
    <PackageReleaseNotes versions={versions} />
  ) : (
    <Loader className={s.halfloader} />
  );
  const isCodeReusePackage = isReusePackage(selectedPackage);

  const doneLoading = isCodeReusePackage || isNpmPackagesFetchingDone;
  return doneLoading ? (
    <>
      <div className={s.main} data-aid="package-readme">
        <PackageMarkDownHeaderV2 allVersions={versions} />
        {showLoader ? (
          <Loader className={s.preloader} />
        ) : (
          <div
            data-aid="readme-package-description"
            className={s.readmeDescription}
          >
            {readmeSection}
            {notesSection}
          </div>
        )}
      </div>
      {isCodeReusePackage ? null : <PackageMarkDownFooter />}
    </>
  ) : (
    <Loader className={s.preloader} />
  );
};
