import React from 'react';
import { CloneCode } from '@wix/wix-ui-icons-common/responsive-editor';
import { Banner, TranslationProvider } from '@wix/wix-code-common-components';
import { useTranslation } from '@wix/wix-i18n-config';
import {
  BiLoggerAPI,
  LegacyEditorDependencies,
} from '@wix/wix-code-plugin-contracts';
import { GithubModalsAPI } from '../../githubModalsAPI';

interface ExternalBannerProps {
  biLoggerAPI: BiLoggerAPI;
  githubModalsAPI: GithubModalsAPI;
}

export const ExternalCodeEditorBanner: React.FC<ExternalBannerProps> = ({
  githubModalsAPI,
}) => {
  const [t] = useTranslation();

  return (
    <Banner
      description={t('lite_panel.ide_entry.description')}
      onClick={() => {
        githubModalsAPI.showInstructionsModal();
      }}
      buttonName={t('lite_panel.ide_entry.cta')}
      suffixIcon={<CloneCode />}
    />
  );
};

export const externalCodeEditorBannerCreator =
  (
    biLoggerAPI: BiLoggerAPI,
    githubModalsAPI: GithubModalsAPI,
    legacyDependenciesAPI: LegacyEditorDependencies
  ) =>
  () =>
    (
      <TranslationProvider
        options={{
          locale: legacyDependenciesAPI.util.editorModel.languageCode,
          asyncMessagesLoader: (locale: string) =>
            import(`../../assets/locale/messages_${locale}.json`),
        }}
      >
        <ExternalCodeEditorBanner
          biLoggerAPI={biLoggerAPI}
          githubModalsAPI={githubModalsAPI}
        />
      </TranslationProvider>
    );
