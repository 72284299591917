import { BI_EVENTS } from './events';

export const apiLinkClicked = ({ linkText, linkUrl }: any) => ({
  def: BI_EVENTS.PROPERTIES_PANEL_CLICK_ON_LINK,
  params: {
    link_text: linkText,
    related_link: linkUrl,
  },
});

export const panelToggledFromCodePanelTopbar = ({ siteId, willOpen }: any) => ({
  def: BI_EVENTS.PROPERTIES_PANEL_TOGGLE,
  params: {
    site_id: siteId,
    state: willOpen ? 'open' : 'close',
    origin: 'code_panel_toolbar',
  },
});

export const nicknameFocused = ({ siteId, compId, compType }: any) => ({
  def: BI_EVENTS.PROPERTIES_PANEL_NICKNAME_FOCUSED,
  params: {
    site_id: siteId,
    element_id: compId,
    element_type: compType,
  },
});

export const nicknameChanged = ({ siteId, compId, compType }: any) => ({
  def: BI_EVENTS.PROPERTIES_PANEL_NICKNAME_EDITED,
  params: {
    site_id: siteId,
    element_id: compId,
    element_type: compType,
  },
});

export const toggleVisibility = ({
  siteId,
  compId,
  compType,
  isVisible,
}: any) => ({
  def: BI_EVENTS.PROPERTIES_PANEL_VISIBILITY_TOGGLE,
  params: {
    site_id: siteId,
    element_id: compId,
    element_type: compType,
    is_visible: !!isVisible,
  },
});

export const toggleCollapsibility = ({
  siteId,
  compId,
  compType,
  isCollapsed,
}: any) => ({
  def: BI_EVENTS.PROPERTIES_PANEL_COLLAPSIBLITY_TOGGLE,
  params: {
    site_id: siteId,
    element_id: compId,
    element_type: compType,
    is_collapsed: !!isCollapsed,
  },
});

export const toggleEnability = ({
  siteId,
  compId,
  compType,
  isEnabled,
}: any) => ({
  def: BI_EVENTS.PROPERTIES_PANEL_ENABILITY_TOGGLE,
  params: {
    site_id: siteId,
    element_id: compId,
    element_type: compType,
    state: isEnabled ? 'enable' : 'disable',
  },
});

export const eventClicked = ({ siteId, compId, compType, eventName }: any) => ({
  def: BI_EVENTS.PROPERTIES_PANEL_EVENT_HANDLER_ADD_PRESSED,
  params: {
    site_id: siteId,
    element_id: compId,
    element_type: compType,
    event: eventName,
  },
});

export const handlerAdded = ({
  siteId,
  compId,
  compType,
  eventName,
  handlerName,
}: any) => ({
  def: BI_EVENTS.PROPERTIES_PANEL_EVENT_HANDLER_ADDED,
  params: {
    site_id: siteId,
    element_id: compId,
    element_type: compType,
    event: eventName,
    handler_name: handlerName,
  },
});

export const navigateToHandler = ({
  siteId,
  compId,
  compType,
  eventName,
  handlerName,
}: any) => ({
  def: BI_EVENTS.PROPERTIES_PANEL_EVENT_HANDLER_NAVIGATION,
  params: {
    site_id: siteId,
    element_id: compId,
    element_type: compType,
    event: eventName,
    handler_name: handlerName,
  },
});

export const handlerRemoved = ({
  siteId,
  compId,
  compType,
  eventName,
  handlerName,
}: any) => ({
  def: BI_EVENTS.PROPERTIES_PANEL_EVENT_HANDLER_REMOVED,
  params: {
    site_id: siteId,
    element_id: compId,
    element_type: compType,
    event: eventName,
    handler_name: handlerName,
  },
});

export const openMasterPage = () => ({
  def: BI_EVENTS.PROPERTIES_PANEL_EDITOR_REFERRAL,
  params: {
    file_name: 'masterPage.js',
    referral_name: 'Open MasterPage.js',
  },
});

export const learnMoreLinkClicked = ({ linkText, linkUrl }: any) => ({
  def: BI_EVENTS.PROPERTIES_PANEL_CLICK_ON_LINK,
  params: {
    link_text: linkText,
    related_link: linkUrl,
  },
});
