import React from 'react';
import {
  EditorAPI,
  LegacyEditorDependencies,
} from '@wix/wix-code-plugin-contracts';

interface ConnectToEditorStoreParams {
  util: LegacyEditorDependencies['util'];
  component: React.FC<any>;
  mapEditorStateToProps: (
    state: any,
  ) => ({ editorAPI }: { editorAPI: EditorAPI }) => any;
}

export const connectToEditorStore = ({
  util,
  component,
  mapEditorStateToProps,
}: ConnectToEditorStoreParams) => {
  const {
    connect,
    STORES: { EDITOR_API },
  } = util.hoc;
  const connected = connect(EDITOR_API, mapEditorStateToProps)(component);
  connected.pure = component;
  return connected;
};

export const helpIds = {
  emptyStateHelpCenter: '9caf7762-a6ea-48c2-859c-26f1e20b6606',
};
