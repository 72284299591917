export type BiSenderFunc = (
  event: BiEventDefinition,
  options: { [key: string]: string },
) => void;

interface BiEventDefinition {
  evid: number;
  src: number;
  endpoint: string;
  fields: { [key: string]: string };
}

export const BI_EVENTS = {
  PROPERTIES_PANEL_CLICK_ON_LINK: {
    endpoint: 'code',
    src: 39,
    evid: 138,
    fields: {
      link_text: 'string',
      related_link: 'string',
    },
  },

  PROPERTIES_PANEL_TOGGLE: {
    endpoint: 'code',
    src: 39,
    evid: 141,
    fields: {
      state: 'string',
      site_id: 'guid',
      origin: 'string',
    },
  },

  PROPERTIES_PANEL_NICKNAME_FOCUSED: {
    endpoint: 'code',
    src: 39,
    evid: 142,
    fields: {
      element_type: 'string',
      element_id: 'string',
      site_id: 'guid',
    },
  },

  PROPERTIES_PANEL_NICKNAME_EDITED: {
    endpoint: 'code',
    src: 39,
    evid: 133,
    fields: {
      element_type: 'string',
      element_id: 'string',
      site_id: 'guid',
    },
  },

  PROPERTIES_PANEL_VISIBILITY_TOGGLE: {
    endpoint: 'code',
    src: 39,
    evid: 146,
    fields: {
      element_type: 'string',
      element_id: 'string',
      is_visible: 'bool',
      site_id: 'guid',
    },
  },

  PROPERTIES_PANEL_COLLAPSIBLITY_TOGGLE: {
    endpoint: 'code',
    src: 39,
    evid: 147,
    fields: {
      element_type: 'string',
      element_id: 'string',
      is_collapsed: 'bool',
      site_id: 'guid',
    },
  },

  PROPERTIES_PANEL_ENABILITY_TOGGLE: {
    endpoint: 'code',
    src: 39,
    evid: 145,
    fields: {
      element_type: 'string',
      element_id: 'string',
      site_id: 'guid',
      state: 'string',
    },
  },

  PROPERTIES_PANEL_EVENT_HANDLER_ADD_PRESSED: {
    endpoint: 'code',
    src: 39,
    evid: 148,
    fields: {
      default_handler_name: 'string',
      element_id: 'string',
      element_type: 'string',
      event: 'string',
      site_id: 'guid',
    },
  },

  PROPERTIES_PANEL_EVENT_HANDLER_ADDED: {
    endpoint: 'code',
    src: 39,
    evid: 149,
    fields: {
      element_id: 'string',
      element_type: 'string',
      event: 'string',
      handler_name: 'string',
      site_id: 'guid',
    },
  },

  PROPERTIES_PANEL_EVENT_HANDLER_NAVIGATION: {
    endpoint: 'code',
    src: 39,
    evid: 150,
    fields: {
      element_id: 'string',
      element_type: 'string',
      event: 'string',
      handler_name: 'string',
      site_id: 'guid',
    },
  },
  PROPERTIES_PANEL_EVENT_HANDLER_REMOVED: {
    endpoint: 'code',
    src: 39,
    evid: 151,
    fields: {
      element_id: 'string',
      element_type: 'string',
      event: 'string',
      handler_name: 'string',
      site_id: 'guid',
    },
  },
  PROPERTIES_PANEL_EDITOR_REFERRAL: {
    endpoint: 'code',
    src: 39,
    evid: 134,
    fields: {
      file_name: 'string',
      referral_name: 'string',
    },
  },
};

export default BI_EVENTS;
