type AnyFixMe = any;

let _developerModeUI: AnyFixMe;

const initialize = (developerModeUI: AnyFixMe) => {
  _developerModeUI = developerModeUI;
  revertMinimize();
};

const minimize = () => {
  _developerModeUI.consolePane.minimize();
};

const revertMinimize = () => {
  _developerModeUI.consolePane.unMinimize();
};

const minimizedByUser = () => {
  return _developerModeUI.consolePane.minimizedByUser();
};

export type ConsoleContainerStateService = {
  initialize: Function;
  minimize: Function;
  revertMinimize: Function;
  minimizedByUser: Function;
};

export default {
  initialize,
  minimize,
  revertMinimize,
  minimizedByUser,
};
