import {
  EventType,
  ReportEventRequest,
} from '@wix/ambassador-dealer-offer-events-service/http';
import { RealEstateIds } from './config';

export interface DealerEventOptions extends ReportEventRequest {
  realEstateId: RealEstateIds;
  offerId: string;
}

export interface DealerEventClickOptions extends DealerEventOptions {
  secondary?: boolean;
}

const dealerEventViewed = (eventOptions: DealerEventOptions) => ({
  event: {
    ...eventOptions,
    type: EventType.VIEW,
  },
});

const dealerEventClosed = (eventOptions: DealerEventOptions) => ({
  event: {
    ...eventOptions,
    type: EventType.CLOSE,
  },
});

const dealerEventClickCTA = (eventOptions: DealerEventClickOptions) => {
  const type = eventOptions.secondary
    ? EventType.SECONDARY_CTA_CLICK
    : EventType.MAIN_CTA_CLICK;

  const clickOptions = eventOptions.secondary
    ? { secondaryCtaClick: {} }
    : { mainCtaClick: {} };

  return {
    event: {
      ...eventOptions,
      type,
      ...clickOptions,
    },
  };
};

export const dealerEventsCreators = {
  dealerEventViewed,
  dealerEventClosed,
  dealerEventClickCTA,
};
