import React from 'react';
import { ActionType } from '@/infra/redux-state/actions/types';

type Matches = {
  range: {
    startLineNumber: number;
    startColumn: number;
    endLineNumber: number;
    endColumn: number;
  };
  matches: string[];
  modelId: string;
  resulutTitle: {
    prefix: string;
    match: string;
    suffix: string;
  };
};

export type Results = {
  [key: string]: {
    fileId: string;
    title: string;
    matches: Matches[];
    subTitle: string;
    icon: React.Component;
  };
};

export type SearchReducerState = {
  query: string;
  results: Results[] | null;
};

export type SearchInAllFilesQueryChanged = {
  query: string;
  type: ActionType.SEARCH_IN_ALL_FILES_QUERY_CHANGED;
};
export type SearchInAllFilesDone = {
  results: Results[];
  type: ActionType.SEARCH_IN_ALL_FILES_DONE;
};
type SearchReducerAction = SearchInAllFilesQueryChanged | SearchInAllFilesDone;

const initialState: SearchReducerState = { results: null, query: '' };

const searchReducer = (state = initialState, action: SearchReducerAction) => {
  switch (action.type) {
    case ActionType.SEARCH_IN_ALL_FILES_QUERY_CHANGED:
      return {
        ...state,
        query: action.query,
      };

    case ActionType.SEARCH_IN_ALL_FILES_DONE:
      return {
        ...state,
        results: action.results,
      };

    default:
      return state;
  }
};

export default searchReducer;
