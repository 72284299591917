// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EhtTwK{padding:12px 24px}.EhtTwK .WNpPY6 .IcWkft{color:#868aa5;display:inline}.EhtTwK .WNpPY6 .ZVSBRB *{color:#000624;font-size:13px;line-height:13px;margin-top:-1px}.EhtTwK .WrSa6p{margin-top:12px}.EhtTwK .WrSa6p *{font-size:13px !important}.K0wRi3{padding:0px 24px 12px 37px}.K0wRi3 .WNpPY6 .IcWkft{color:#868aa5;display:inline}.K0wRi3 .WrSa6p{margin-top:12px}.K0wRi3 .WrSa6p *{font-size:13px !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "EhtTwK",
	"text-wrapper": "WNpPY6",
	"textWrapper": "WNpPY6",
	"description": "IcWkft",
	"link-button": "ZVSBRB",
	"linkButton": "ZVSBRB",
	"cta-button": "WrSa6p",
	"ctaButton": "WrSa6p",
	"cloud-db-container": "K0wRi3",
	"cloudDbContainer": "K0wRi3"
};
export default ___CSS_LOADER_EXPORT___;
