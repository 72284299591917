import React from 'react';
import dataHooks from '../../../../dataHooks';
import s from './CodeReusePkgsList.scss';
import { CodeReusePkgListItem } from './CodeReusePkgListItem';
import { ListSectionTitle } from '../listSectionTitle/ListSectionTitle';
import { codeReusePkgsSelectors } from '../../../../packagesView/packagesViewSelectors';
import _ from 'lodash';
import { MyPackagesEmptyState } from './myPackagesEmptyState/MyPackagesEmptyState';
import { ListSectionDescription } from '../listSectionDescription/ListSectionDescription';
import { PackageVersionPicker } from '../packageVersions/PackageVersionPicker';
import { getIsVersionPickerShowing } from '../../../packagesModalSelectors';
import { TextButton } from '@wix/wix-base-ui';
import PackageIcon from '@/assets/icons/contextMenu/package.svg';

import { PackageItemContextProvider } from '../PackageItemContextProvider';
import {
  useEditorLegacyAPIs,
  useSelector,
} from '@wix/wix-code-common-components';

export const MyPackagesPkgList: React.FC = () => {
  const packages = useSelector(codeReusePkgsSelectors.available.myPackages);
  const {
    editorAPI,
    legacyDependenciesAPI: { stateManagement },
  } = useEditorLegacyAPIs();
  const showVersionPicker = useSelector(getIsVersionPickerShowing);

  const handleCreatePackageClick = () => {
    editorAPI.store.dispatch(
      stateManagement.editorPlugins.panels.actions.updateOrOpenPanel(
        'wixBlocksConsumer.panels.WixBlocksPanel',
        {},
      ),
    );
  };

  return _.isEmpty(packages) ? (
    <MyPackagesEmptyState />
  ) : showVersionPicker ? (
    <PackageVersionPicker />
  ) : (
    <div className={s.myPackagesContainer}>
      <div className={s.myPkgsScroller}>
        <ListSectionTitle title="Package_Manager_My_Packages_Section_Title" />
        <ListSectionDescription
          titleKey="Package_Manager_My_Packages_Description"
          learnMoreUrlKey="Package_Manager_My_Packages_LearnMore_Link"
        />
        <div data-hook={dataHooks.MY_PACKAGE_PACKAGES_LIST}>
          {packages.map((pkg) => (
            <PackageItemContextProvider pkgName={pkg.name} key={pkg.name}>
              <CodeReusePkgListItem />
            </PackageItemContextProvider>
          ))}
        </div>
      </div>
      <div className={s.myPackagesFooter}>
        <TextButton
          prefixIcon={<PackageIcon />}
          onClick={handleCreatePackageClick}
          weight="thin"
          size="small"
          dataHook={dataHooks.MY_PKGS_CREATE_NEW_PKG}
        >
          Package_Manager_My_Packages_Build_New
        </TextButton>
      </div>
    </div>
  );
};
