import { APIKeys, EntryPoint, Shell } from '@wix/wix-code-repluggable';
import { packagesAndAppsAPI } from './PackagesAndAppsAPI';

export const PackagesAndAppsEntryPoint: EntryPoint = {
  name: 'Packages and Apps Tree',
  getDependencyAPIs() {
    return [APIKeys.ClassicEditorAPI, APIKeys.FilesTreeAPI];
  },
  declareAPIs() {
    return [APIKeys.PackagesAndAppsAPI];
  },
  attach(shell: Shell) {
    shell.contributeAPI(APIKeys.PackagesAndAppsAPI, () => {
      const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
      const { selectFile, selectTab, expandParents } = shell.getAPI(
        APIKeys.FilesTreeAPI,
      );
      return packagesAndAppsAPI({
        editorAPI,
        selectFile,
        selectTab,
        expandParents,
      });
    });
  },
};
