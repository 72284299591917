import DeleteIcon from '../../assets/icons/delete.svg';
import { SpiService } from '../../SpiService';
import React from 'react';

import {
  FileTreeCustomAction,
  FILE_TREE_QUICK_ACTIONS,
  HiddenFileAction,
  CustomNodeName,
  ExperimentsAPI,
  FileTreeCustomFolderAction,
  BiLoggerAPI,
} from '@wix/wix-code-plugin-contracts';
import { SpiType, spiTypes } from '../../SpiTypes';
import { addIntegrationEvent } from '../../utils/reportEvents';

export interface SpiLeftTreeActions {
  hiddenRootActions: FILE_TREE_QUICK_ACTIONS[];
  customRootActions: () => FileTreeCustomAction[];
  hiddenFolderActions: FILE_TREE_QUICK_ACTIONS[];
  customFolderActions: FileTreeCustomFolderAction[];
  hiddenFileActionsByPredicate: HiddenFileAction[];
  customNodeNames: CustomNodeName[];
}

export const spiLeftTreeActions = (
  t: (key: string) => string,
  spiService: SpiService,
  experimentsAPI: ExperimentsAPI,
  biLoggerAPI: BiLoggerAPI,
): SpiLeftTreeActions => ({
  hiddenRootActions: ['NEW_TS_FILE', 'NEW_JS_FILE', 'NEW_FILE', 'NEW_FOLDER'],
  customRootActions: () => {
    const rootActions: FileTreeCustomAction[] = [];
    if (experimentsAPI.isOpen('specs.wixCode.ShippingRatesSPI')) {
      rootActions.push({
        label: t('Left_Tree_Section_Add_Shipping_SPI'),
        icon: () => <div />,
        onClick: () => {
          biLoggerAPI.report(addIntegrationEvent(SpiType.SHIPPING_RATES));
          return spiService.openSpiWizard(spiTypes.shipping);
        },
      });
    }
    if (experimentsAPI.isOpen('specs.wixCode.SPIPaymentsIntegration')) {
      rootActions.push({
        label: t('Left_Tree_Section_Add_Payment_SPI'),
        icon: () => <div />,
        onClick: () => {
          biLoggerAPI.report(addIntegrationEvent(SpiType.PAYMENTS));
          spiService.openSpiWizard(spiTypes.payment);
        },
      });
    }
    if (experimentsAPI.isOpen('specs.wixCode.CatalogSPI')) {
      rootActions.push({
        label: t('Left_Tree_Section_Add_Catalog_SPI'),
        icon: () => <div />,
        onClick: () => {
          biLoggerAPI.report(addIntegrationEvent(SpiType.CATALOG));
          spiService.openSpiWizard(spiTypes.catalog);
        },
      });
    }
    if (experimentsAPI.isOpen('specs.wixCode.additionalFeesSPI')) {
      rootActions.push({
        label: t('Left_Tree_Section_Add_Fees_SPI'),
        icon: () => <div />,
        onClick: () => {
          biLoggerAPI.report(addIntegrationEvent(SpiType.FEES));
          spiService.openSpiWizard(spiTypes.fees);
        },
      });
    }
    if (experimentsAPI.isOpen('specs.wixCode.taxCalculatorSPI')) {
      rootActions.push({
        label: t('Left_Tree_Section_Add_Tax_Calculator_SPI'),
        icon: () => <div />,
        onClick: () => {
          biLoggerAPI.report(addIntegrationEvent(SpiType.TAX_CALCULATOR));
          spiService.openSpiWizard(spiTypes.taxCalculator);
        },
      });
    }
    if (experimentsAPI.isOpen('specs.wixCode.defaultTaxationCategorySPI')) {
      rootActions.push({
        label: t('Left_Tree_Section_Add_Default_Taxation_Category'),
        icon: () => <div />,
        onClick: () => {
          biLoggerAPI.report(addIntegrationEvent(SpiType.DEFAULT_TAXATION_CATEGORY));
          spiService.openSpiWizard(spiTypes.defaultTaxationCategory);
        },
      });
    }
    if (experimentsAPI.isOpen('specs.wixCode.discountsTriggerSPI')) {
      rootActions.push({
        label: t('Left_Tree_Section_Add_Discounts_SPI'),
        icon: () => <div />,
        onClick: () => {
          biLoggerAPI.report(addIntegrationEvent(SpiType.DISCOUNTS));
          spiService.openSpiWizard(spiTypes.discounts);
        },
      });
    }
    rootActions.push({
      label: t('Left_Tree_Section_Add_BookingsPricing_SPI'),
      icon: () => <div />,
      onClick: () => {
        biLoggerAPI.report(addIntegrationEvent(SpiType.BOOKINGS_PRICING));
        spiService.openSpiWizard(spiTypes.bookingsPricing);
      },
    });
    if (experimentsAPI.isOpen('specs.wixCode.customScopeSPI')) {
      rootActions.push({
        label: t('Left_Tree_Section_Add_CustomScope_SPI'),
        icon: () => <div />,
        onClick: () => {
          biLoggerAPI.report(addIntegrationEvent(SpiType.CUSTOM_SCOPE));
          spiService.openSpiWizard(spiTypes.customScope);
        },
      });
    }
    if (experimentsAPI.isOpen('specs.wixCode.communicationChannelSPI')) {
      rootActions.push({
        label: t('Left_Tree_Section_Add_CommunicationChannel_SPI'),
        icon: () => <div />,
        onClick: () => {
          biLoggerAPI.report(
            addIntegrationEvent(SpiType.COMMUNICATION_CHANNEL),
          );
          spiService.openSpiWizard(spiTypes.communicationChannel);
        },
      });
    }
    if (experimentsAPI.isOpen('specs.wixCode.itemRecommendations')) {
      rootActions.push({
        label: t('Left_Tree_Section_Add_Recommendations_SPI'),
        icon: () => <div />,
        onClick: () => {
          biLoggerAPI.report(addIntegrationEvent(SpiType.ITEM_RECOMMENDATIONS));
          spiService.openSpiWizard(spiTypes.itemRecommendations);
        },
      });
    }
    if (experimentsAPI.isOpen('specs.wixCode.commentsModerationProvider')) {
      rootActions.push({
        label: t('Left_Tree_Section_Add_Comments_Moderation_Provider_SPI'),
        icon: () => <div />,
        onClick: () => {
          biLoggerAPI.report(addIntegrationEvent(SpiType.COMMENTS_MODERATION));
          spiService.openSpiWizard(spiTypes.commentsModeration);
        },
      });
    }
    if (experimentsAPI.isOpen('specs.wixCode.bookingsExternalCalendarSPI')) {
      rootActions.push({
        label: t('Left_Tree_Section_Add_BookingsExternalCalendar_SPI'),
        icon: () => <div />,
        onClick: () => {
          biLoggerAPI.report(
            addIntegrationEvent(SpiType.BOOKINGS_EXTERNAL_CALENDAR),
          );
          spiService.openSpiWizard(spiTypes.bookingsExternalCalendar);
        },
      });
    }
    if (experimentsAPI.isOpen('specs.wixCode.validationsSPI')) {
      rootActions.push({
        label: t('Left_Tree_Section_Add_Validations_SPI'),
        icon: () => <div />,
        onClick: () => {
          biLoggerAPI.report(addIntegrationEvent(SpiType.VALIDATIONS));
          spiService.openSpiWizard(spiTypes.validations);
        },
      });
    }
    if (experimentsAPI.isOpen('specs.wixCode.automationActionProvider')) {
      rootActions.push({
        label: t('Left_Tree_Section_Add_Automation_Action_Provider_SPI'),
        icon: () => <div />,
        onClick: () => {
          biLoggerAPI.report(addIntegrationEvent(SpiType.AUTOMATION_ACTION));
          spiService.openSpiWizard(spiTypes.automationAction);
        },
      });
    }
    if (experimentsAPI.isOpen('specs.wixCode.paymentSettingsSPI')) {
      rootActions.push({
        label: t('Left_Tree_Section_Add_PaymentSettings_SPI'),
        icon: () => <div />,
        onClick: () => {
          biLoggerAPI.report(addIntegrationEvent(SpiType.PAYMENT_SETTINGS));
          spiService.openSpiWizard(spiTypes.paymentSettings);
        },
      });
    }
    if (experimentsAPI.isOpen('specs.wixCode.customTableReservationsSPI')) {
      rootActions.push({
        label: t('Left_Tree_Section_Add_CustomTableReservations_SPI'),
        icon: () => <div />,
        onClick: () => {
          biLoggerAPI.report(addIntegrationEvent(SpiType.CUSTOM_TABLE_RESERVATIONS));
          spiService.openSpiWizard(spiTypes.customTableReservations);
        },
      });
    }
    return rootActions;
  },
  hiddenFolderActions: [
    'DELETE',
    'NEW_FOLDER',
    'RENAME',
    'NEW_JS_FILE',
    'NEW_FILE',
  ],
  customFolderActions: [
    {
      label: t('Left_Tree_Section_Remove_Spi'),
      icon: DeleteIcon,
      onClickCreator: (folderPath: string) => () =>
        spiService.openDeleteSpiModal(folderPath),
      predicate: (folderPath) =>
        !Object.values(spiTypes)
          .map((type) => type.folderPath)
          .includes(folderPath),
    },
  ],
  hiddenFileActionsByPredicate: [
    {
      actionsToHide: ['RENAME', 'DELETE'],
      predicate: (fileName: string, folderName: string) =>
        fileName === folderName + '.js' ||
        fileName === folderName + '-config.js',
    },
  ],
  customNodeNames: [
    {
      predicate: (nodePath: string) => nodePath === spiTypes.payment.folderPath,
      customName: t('Payment_Folder_Name'),
    },
    {
      predicate: (nodePath: string) =>
        nodePath === spiTypes.shipping.folderPath,
      customName: t('Shipping_Folder_Name'),
    },
  ],
});
