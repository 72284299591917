import ExtendableError from 'es6-error';

const ERROR_NAME = 'NpmRequestError';

class NpmRequestError extends ExtendableError {
  constructor(originalError, request) {
    super(originalError.message);
    this.name = ERROR_NAME;
    this.originalError = originalError;
    this.request = request;
  }
}

export { NpmRequestError };
