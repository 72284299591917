import React from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { InstructionsModal } from '@wix/wix-code-common-components';

export interface FooterProps {
  wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI;
}

export const CliInstructionsModal: React.FC<{ closeModal: () => void }> = ({
  closeModal,
}) => {
  const [t] = useTranslation();
  const instructions = [
    {
      scope: t('localEditor.learn_more_modal.section1.header'),
      instructions: t('localEditor.learn_more_modal.section1.description'),
      terminalCommands: [t('localEditor.learn_more_modal.section1.command')],
    },
    {
      scope: t('localEditor.learn_more_modal.section2.header'),
      instructions: t('localEditor.learn_more_modal.section2.description'),
      terminalCommands: [t('localEditor.learn_more_modal.section2.command')],
    },
    {
      scope: t('localEditor.learn_more_modal.section3.header'),
      instructions: t('localEditor.learn_more_modal.section3.description'),
      terminalCommands: [t('localEditor.learn_more_modal.section3.command')],
    },
  ];

  return (
    <InstructionsModal
      onCloseButtonClick={closeModal}
      closeModal={closeModal}
      copyCodeText={t('localEditor.learn_more_modal.after_copy_tooltip')}
      instructions={instructions}
      onHelpButtonClick={() => {
        window.open(t('localEditor.learn_more_modal.learn_more_link.url'));
      }}
      footerConfirmButton={{
        onClick: () => {
          closeModal();
        },
        text: t('localEditor.learn_more_modal.close_button'),
      }}
      footerSecondaryTextButton={{
        text: t('localEditor.learn_more_modal.learn_more_link.text'),
        href: t('localEditor.learn_more_modal.learn_more_link.url'),
      }}
      modalTitle={t('localEditor.code_editor_footer.learn_more_button')}
      modalSubtitle={t('localEditor.learn_more_modal.title')}
    />
  );
};

export default CliInstructionsModal;
