interface ChatCompletion {
  choices: {
    delta?: {
      content: string;
    };
  }[];
}

const readCompletionsChunk = (lines: string[] = []) => {
  try {
    return lines.reduce((acc: string, line: string) => {
      try {
        if (line.trim() === '') {
          return acc;
        }

        const completions: ChatCompletion = JSON.parse(line);
        return (
          acc +
          completions.choices
            .map((choice) => choice.delta?.content || '')
            .join('')
        );
      } catch {
        return acc;
      }
    }, '');
  } catch (e) {
    console.error('error while parsing stream data: ', e);
    return '';
  }
};

export const consumeCompletionsStream = async (
  stream: ReadableStream<Uint8Array>,
  onChunkReceived: (chunk: string) => void,
  onDone: () => void,
): Promise<void> => {
  const reader = stream.getReader();
  const decoder = new TextDecoder();

  try {
    let { done, value } = await reader.read();

    while (!done) {
      const buffer = decoder.decode(value, { stream: true });
      const lines = buffer.split('\n');
      onChunkReceived(readCompletionsChunk(lines));

      const readResult = await reader.read();
      done = readResult.done;
      value = readResult.value;
    }
  } catch (error) {
    throw error;
  } finally {
    reader.releaseLock();
    onDone();
  }
};
