import React from 'react';
import { Shell } from '@wix/wix-code-repluggable';
import {
  MenuTabProvider,
  DoNotTranslateBaseUiComponents,
} from '@wix/wix-code-common-components';
import {
  BiLoggerAPI,
  LegacyEditorDependencies,
  PanelsAPI,
  WixCodeStoreAPI,
} from '@wix/wix-code-plugin-contracts';
import { DeveloperToolsPanelHeader } from './DeveloperToolsPanelHeader';
import { panelHeaderData } from './createDeveloperToolsTab';

type DeveloperToolsTabWrapperProps = {
  closePanel: () => void;
  shell: Shell;
  apis: {
    wixCodeStoreAPI: WixCodeStoreAPI;
    legacyDependenciesAPI: LegacyEditorDependencies;
    biLoggerAPI: BiLoggerAPI;
    panelsAPI: PanelsAPI;
  };
};

export const createDeveloperToolsTabWrapper: React.FC<
  DeveloperToolsTabWrapperProps
> = ({ closePanel, children, apis }) => {
  const { panelsAPI, ...restAPIs } = apis;
  const panelHeaderLabel = restAPIs.legacyDependenciesAPI.util.translate(
    panelHeaderData.label,
  );

  return (
    <MenuTabProvider {...restAPIs}>
      <DoNotTranslateBaseUiComponents>
        <DeveloperToolsPanelHeader
          closePanel={closePanel}
          panelsAPI={panelsAPI}
          label={panelHeaderLabel}
        />
        {children}
      </DoNotTranslateBaseUiComponents>
    </MenuTabProvider>
  );
};
