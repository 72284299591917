import React from 'react';
import { InfoIcon as BaseUIInfoIcon } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import { useBiLogger } from '../../../biLoggerContext';
import { leftTreeClickOnAnItem } from '@wix/bi-logger-platform/v2';
import { constants } from '../../../constants';

const LearnMoreLink = 'https://support.wix.com/en/article/css-editing';

export const InfoIcon = ({ className }: { className: string }) => {
  const [t] = useTranslation();
  const biLoggerAPI = useBiLogger();

  const onLinkClick: React.MouseEventHandler = (e) => {
    window.open(LearnMoreLink, '_blank');
    e.stopPropagation();
    biLoggerAPI.report(
      leftTreeClickOnAnItem({
        item_name: constants.css,
        item_type: 'info_learn_more',
        origin: 'left_tree',
      }),
    );
  };

  return (
    <BaseUIInfoIcon
      className={className}
      onClick={(e) => e.stopPropagation()}
      text={t('WixCode_CssClassesPanel_AddGlobalCssFile_Description')}
      linkText={t('WixCode_TreeView_CSS_GlobalCss_Tooltip_LearnMore_Link')}
      onLinkClick={onLinkClick}
    />
  );
};
