// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Vfq8Ap{position:absolute;width:calc(100% - (24px * 2));display:flex;justify-content:center;padding:0 24px 20px;bottom:0;overflow-wrap:anywhere}.GkVHzj{max-width:200px;overflow-wrap:break-word}.sxokRn{background-color:#e7f0ff;display:flex;flex-direction:row;justify-content:center;align-items:center;width:100%;gap:8px;padding:8px;border-radius:5px;box-shadow:0 2px 7px 0 rgba(23,25,28,.17);max-width:-moz-fit-content;max-width:fit-content}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification-wrapper": "Vfq8Ap",
	"notificationWrapper": "Vfq8Ap",
	"text": "GkVHzj",
	"notification": "sxokRn"
};
export default ___CSS_LOADER_EXPORT___;
