import { PredefinedSidebarTabs } from '@wix/wix-code-plugin-contracts';

export { PredefinedSidebarTabs };

let codeFilesTab = PredefinedSidebarTabs.SITE_CODE;
export const getCodeFilesTab = () => {
  return codeFilesTab;
};

export const setCodeFilesTab = (tab: PredefinedSidebarTabs) => {
  codeFilesTab = tab;
};

export type TabDefinition = TabDefinitionSymbolName | TabDefinitionSymbolType;

type TabDefinitionBase = {
  label: string;
  infoLinkText?: string;
  infoLinkUrl?: string;
  infoText?: string;
  value: PredefinedSidebarTabs;
};

interface TabDefinitionSymbolName extends TabDefinitionBase {
  symbolName: string;
}

interface TabDefinitionSymbolType extends TabDefinitionBase {
  symbol: any;
}

export const topTabsOrder: PredefinedSidebarTabs[] = [
  PredefinedSidebarTabs.PAGE_CODE,
  PredefinedSidebarTabs.SITE_CODE,
  PredefinedSidebarTabs.SEARCH,
  PredefinedSidebarTabs.GITHUB,
  PredefinedSidebarTabs.PACKAGES,
  PredefinedSidebarTabs.COLLECTIONS,
  PredefinedSidebarTabs.TOOLS,
  PredefinedSidebarTabs.APP_BUILDER,
  PredefinedSidebarTabs.HELP,
];

export const tabs: { [key: string]: PredefinedSidebarTabs } = {
  // TODO remove this object when all usages have been removed
  APP_BUILDER: PredefinedSidebarTabs.APP_BUILDER,
  FILES: PredefinedSidebarTabs.SITE_CODE,
  HELP: PredefinedSidebarTabs.HELP,
  PAGES: PredefinedSidebarTabs.PAGE_CODE,
  SEARCH: PredefinedSidebarTabs.SEARCH,
  TOOLS: PredefinedSidebarTabs.TOOLS,
  PACKAGES: PredefinedSidebarTabs.PACKAGES,
};
