import * as React from 'react';
import {
  PackagesModalContext,
  TEST_VERSION_NAME,
} from '../../../../packagesModalContext';
import { PackagesListItemDetailsDisplay } from './PackagesListItemDetailsDisplay';
import { getVersionWithoutPatch } from '../../../../utils';
import { useContext } from 'react';
import {
  DateFormat,
  getFormattedDate,
  useEditorLanguageCode,
} from '@/utils/dateUtils';
import { PackageItemContext } from '../PackageItemContext';

interface VersionListItemDetailsProps {
  versionInfo: {
    version: string;
    releaseNotes?: string;
    publishedDate?: string;
    isLatest: boolean;
  };
}

export const VersionListItemDetails: React.FC<VersionListItemDetailsProps> = ({
  versionInfo,
}) => {
  const { t } = useContext(PackagesModalContext);
  const { availablePkg, isCodeReusePackage } = useContext(PackageItemContext);
  const { downloadCount } = availablePkg;
  const lang = useEditorLanguageCode();

  const renderPackageDisplayName = () => {
    let versionNumber = versionInfo.version;
    if (isCodeReusePackage) {
      versionNumber =
        versionInfo.version === TEST_VERSION_NAME
          ? t('Package_Manager_test_version')
          : getVersionWithoutPatch(versionInfo.version);
    }
    const versionLatest =
      versionInfo.isLatest && ` ${t('Package_Manager_latest_version')}`;
    return (
      <>
        {versionNumber}
        {versionLatest}
      </>
    );
  };
  const getPackageDescription = () => {
    return versionInfo.releaseNotes || '';
  };
  return (
    <PackagesListItemDetailsDisplay
      packageName={renderPackageDisplayName()}
      packageDescription={getPackageDescription()}
      downloadCount={downloadCount}
      publishedDate={
        versionInfo.publishedDate
          ? getFormattedDate(versionInfo.publishedDate, lang, DateFormat.Short)
          : undefined
      }
    />
  );
};
