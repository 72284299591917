import { APIKeys, Shell } from '@wix/wix-code-repluggable';
import { registerVSCodeSyncSiteHandler } from '../VSCodeSyncSiteHandler/vsCodeSyncSiteHandler';
import {
  FileSystemChangesUnsubscriber,
  subscribeToFileSystemChanges,
} from '../FileSystemChangesListener/fileSystemChangesListener';
import { VSCodePublicAPI } from '@wix/wix-code-plugin-contracts';
import { VSCODE_URL } from '../SyncSiteHandler/consts';

const VSCODE_OLD_URL = 'https://editor.wix.com/vscode/dep';

export const createVSCodePublicAPI = (shell: Shell): VSCodePublicAPI => {
  const fileSystemAPI = shell.getAPI(APIKeys.FileSystemAPI);
  const classicEditorAPI = shell.getAPI(APIKeys.ClassicEditorAPI);
  const experimentsAPI = shell.getAPI(APIKeys.ExperimentsAPI);
  const siteAPI = shell.getAPI(APIKeys.SiteAPI);
  const isNewDomain = experimentsAPI.isOpen(
    'spec.vscode-wrapper.useNewWixIdeDomain',
  );

  let fileSystemChangesUnsubscriber: FileSystemChangesUnsubscriber | undefined;

  const registerVSCodeSyncer = () => {
    registerVSCodeSyncSiteHandler(classicEditorAPI.editorAPI, experimentsAPI);
    fileSystemChangesUnsubscriber = subscribeToFileSystemChanges(
      fileSystemAPI,
      classicEditorAPI,
      experimentsAPI,
    );
  };

  const unregisterVSCodeSyncer = () => {
    fileSystemChangesUnsubscriber?.();
  };

  const getVSCodeUrl = (metaSiteId: string) =>
    isNewDomain
      ? `${VSCODE_URL}/${metaSiteId}`
      : `${VSCODE_OLD_URL}/${metaSiteId}`;

  const openVSCodeOnlineTab = () => {
    const url = getVSCodeUrl(siteAPI.getMetaSiteId());
    window.open(url, url)!.focus();
  };

  const api = {
    registerVSCodeSyncer,
    unregisterVSCodeSyncer,
    openVSCodeOnlineTab,
  };

  return api;
};
