import React from 'react';
import { Trans as OriginalTrans } from 'react-i18next';
import { getI18nInstance } from './i18nService';
import { useTranslate } from './useTranslate';

interface TransProps {
  i18nKey: string;
  components?: { readonly [tagName: string]: React.ReactElement };
  values?: Object;
}

export const Trans: React.FC<TransProps> = ({
  i18nKey,
  components,
  values,
}) => {
  const { t } = useTranslate();
  return (
    <OriginalTrans
      i18nKey={i18nKey}
      t={t}
      components={components}
      i18n={getI18nInstance()}
      values={values}
    ></OriginalTrans>
  );
};
