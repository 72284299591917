// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WgBvN_{margin-left:13px}.WgBvN_._XdDVA{visibility:hidden}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"context-menu": "WgBvN_",
	"contextMenu": "WgBvN_",
	"invisible": "_XdDVA"
};
export default ___CSS_LOADER_EXPORT___;
