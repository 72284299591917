import _ from 'lodash';
import {
  LogLevel,
  LOG_LEVEL,
} from '@/legacy/core/components/console/constants';
import { ActionType } from '../actions/types';

type CallbackArg = {
  text: string;
  callback: (args?: any) => any;
  url?: string;
  linkType?: string;
};

type MessageArgs = (string | CallbackArg)[];

export type Message = {
  id: number;
  logLevel: typeof LOG_LEVEL;
  args: MessageArgs;
  origin?: {
    column: number;
    line: number;
    sourceFileDisplayName: string;
    sourceFileFullPath: string;
    type: string;
  };
  action?: {
    text: string;
    callback: () => any;
  };
  [key: string]: any;
};
export type Origin = {
  column: number;
  line: number;
  sourceFileDisplayName: string;
  sourceFileFullPath: string;
  type: string;
};

export interface ConsoleReaderState {
  visibleLevels: { [key in LogLevel]: boolean };
  messages: Message[];
}

const initialState: ConsoleReaderState = {
  visibleLevels: {
    [LogLevel.LOG]: true,
    [LogLevel.INFO]: true,
    [LogLevel.WARNING]: true,
    [LogLevel.ERROR]: true,
    [LogLevel.VERBOSE]: false,
    [LogLevel.TIP]: true,
  },
  messages: [],
};

export type ConsoleAddMessage = {
  message: Message;
  type: ActionType.CONSOLE_ADD_MESSAGE;
};
export type ConsoleAddMessages = {
  messages: Message[];
  type: ActionType.CONSOLE_ADD_MESSAGES;
};
export type ConsoleRemoveMessage = {
  messageId: number;
  type: ActionType.CONSOLE_REMOVE_MESSAGE;
};
export type ConsoleRemoveMessages = {
  type: ActionType.CONSOLE_CLEAR_DISPLAYED_MESSAGES;
};
export type ConsoleAddMessageOrigin = {
  messageId: number;
  origin: Origin;
  type: ActionType.CONSOLE_ADD_MESSAGE_ORIGIN;
};
export type ConsoleSetLevelVisibilty = {
  visible: boolean;
  level: keyof typeof LOG_LEVEL;
  type: ActionType.CONSOLE_SET_LEVEL_VISIBILITY;
};

type ConsoleReaderAction =
  | ConsoleAddMessage
  | ConsoleRemoveMessage
  | ConsoleAddMessages
  | ConsoleRemoveMessages
  | ConsoleAddMessageOrigin
  | ConsoleSetLevelVisibilty;

const consoleReducer = (state = initialState, action: ConsoleReaderAction) => {
  switch (action.type) {
    case ActionType.CONSOLE_ADD_MESSAGE:
      return { ...state, messages: [...state.messages, action.message] };
    case ActionType.CONSOLE_REMOVE_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter(
          (message) => message.id !== action.messageId,
        ),
      };

    case ActionType.CONSOLE_ADD_MESSAGES:
      return { ...state, messages: state.messages.concat(action.messages) };

    case ActionType.CONSOLE_ADD_MESSAGE_ORIGIN:
      return {
        ...state,
        messages: state.messages.map((message) =>
          message.id === action.messageId
            ? _.assign({}, message, { origin: action.origin })
            : message,
        ),
      };
    case ActionType.CONSOLE_CLEAR_DISPLAYED_MESSAGES:
      return { ...state, messages: [] };

    case ActionType.CONSOLE_SET_LEVEL_VISIBILITY:
      state.visibleLevels[action.level] = action.visible;
      return {
        ...state,
        visibleLevels: {
          ...state.visibleLevels,
          [action.level]: action.visible,
        },
      };

    default:
      return state;
  }
};

export default consoleReducer;
