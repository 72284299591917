import { captureError } from '@/infra/monitoring';
import { NpmRequestError } from '../errors';
import { createNpmPackageRequest } from '@wix/ambassador-velo-npm-v1-npm-package-details/http';
import { CreateNpmPackageRequestRequest } from '@wix/ambassador-velo-npm-v1-npm-package-details/types';
import { HttpClient } from '@wix/http-client';

interface RequestPackageParams {
  name: string;
  version: string;
  reason: string;
}

const requestPackage = async (
  { instance }: AnyFixMe,
  { name, version, reason }: RequestPackageParams,
) => {
  const httpClient = new HttpClient({
    getAppToken: () => instance,
  });
  const payload: CreateNpmPackageRequestRequest = {
    name,
    version,
  };
  try {
    const request = await httpClient.request(createNpmPackageRequest(payload));
    return request;
  } catch (error) {
    captureError(new NpmRequestError(error));
    throw error;
  }
};

export default requestPackage;
