import React from 'react';
import { Badge, Tooltip, Text, TextButton } from '@wix/wix-base-ui';
import styles from './LiveEditorBadge.scss';
import dataHooks from '../dataHooks';
import { useTranslation } from '@wix/wix-i18n-config';
import { TranslationProvider } from '../../i18n';

export const LiveEditorBadge: React.FC = () => {
  const [t] = useTranslation();
  const toolTipContent = () => {
    return (
      <div className={styles.badgeTooltip}>
        <div className={styles.title}>
          <Text
            size="medium"
            weight="bold"
            enableEllipsis={false}
            shouldTranslate={false}
          >
            {t('localEditor.liveEditorBadge.tooltip.title')}
          </Text>
        </div>
        <div className={styles.tooltipContent}>
          <Text
            size="small"
            weight="thin"
            enableEllipsis={false}
            skin="secondary"
            shouldTranslate={false}
          >
            {t('localEditor.liveEditorBadge.tooltip.content')}
          </Text>
          <TextButton
            size="small"
            weight="thin"
            shouldTranslate={false}
            dataHook={dataHooks.topBar.liveEditorBadge.tooltip.learnMoreBtn}
            onClick={() => {
              window.open(
                t('localEditor.liveEditorBadge.tooltip.learnMoreBtn.link'),
              );
            }}
          >
            {t('localEditor.liveEditorBadge.tooltip.learnMoreBtn.title')}
          </TextButton>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.badgeContainer}>
      <Tooltip
        content={toolTipContent()}
        interactive={true}
        shouldTranslate={false}
        dataHook={dataHooks.topBar.liveEditorBadge.tooltip.content}
      >
        <Badge
          skin="standard"
          value={t('localEditor.liveEditorBadge.title')}
          shouldTranslate={false}
          dataHook={dataHooks.topBar.liveEditorBadge.container}
        />
      </Tooltip>
    </div>
  );
};
const createLiveEditorBadge = () => () =>
  (
    <TranslationProvider>
      <LiveEditorBadge />
    </TranslationProvider>
  );
export default createLiveEditorBadge;
