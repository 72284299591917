import React, { useContext } from 'react';
import { Button, Tooltip } from '@wix/wix-base-ui';
import {
  useEditorLegacyAPIs,
  ReadOnlyModeContext,
} from '@wix/wix-code-common-components';
import QuickFormat from 'wix-ui-icons-common/classic-editor/QuickFormat';
import s from './formatButton.scss';
import bi from '../../../../bi/bi';
import { formatButtonClick } from '../../../../bi/biEvents';
import { codeEditorConsts, experimentUtils } from '@wix/wix-code-common';
import { useTranslate } from '@/infra/i18n/useTranslate';
import cx from 'classnames';

export const FormatButton = () => {
  const { editorAPI } = useEditorLegacyAPIs();
  const { readOnlyMode } = useContext(ReadOnlyModeContext);
  const disabled = readOnlyMode.codeIde;
  const codeEditorApi = editorAPI.wixCode.codeEditorApi.getApi();

  const handleFormatButtonClick = () => {
    codeEditorApi.format();
    editorAPI.bi.event(bi.events.IDE_TOOLBAR_BUTTON_CLICK, formatButtonClick());
  };

  const { DATA_HOOKS } = codeEditorConsts;
  const isVeloLite = experimentUtils.isLiteEditor();
  const { t } = useTranslate();

  return (
    <>
      {isVeloLite ? (
        <Button
          onClick={handleFormatButtonClick}
          className={cx(s.formatButton)}
          dataHook={DATA_HOOKS.FORMAT_CODE_BUTTON}
          disabled={disabled}
          prefixIcon={<QuickFormat />}
        >
          {t('lite_panel.code_panel.toolbar.prettify')}
        </Button>
      ) : (
        <Tooltip
          content={
            disabled
              ? t('WixCode_CodePanel_TopBar_QuickFormat_Disabled_Tooltip')
              : t('WixCode_CodePanel_TopBar_QuickFormat')
          }
          marginTop={4}
          maxWidth={200}
          dataHook={DATA_HOOKS.FORMAT_CODE_BUTTON_TOOLTIP}
        >
          <Button
            onClick={handleFormatButtonClick}
            className={s.formatButton}
            dataHook={DATA_HOOKS.FORMAT_CODE_BUTTON}
            disabled={disabled}
          >
            <QuickFormat />
          </Button>
        </Tooltip>
      )}
    </>
  );
};
