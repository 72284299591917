import {
  ContributedSection,
  PackagesTabAPI,
} from '@wix/wix-code-plugin-contracts';
import { Shell } from 'repluggable';
import { ContributedSectionsSlot } from './packagesTabEntryPoint/contributedSections';

export const createPackagesTabAPI = (shell: Shell): PackagesTabAPI => {
  const sectionsSlot = shell.declareSlot(ContributedSectionsSlot);
  const contributeSection = (
    section: ContributedSection,
    condition?: () => boolean,
  ) => {
    sectionsSlot.contribute(shell, section, condition);
  };

  return {
    contributeSection,
  };
};
