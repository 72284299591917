import { MenuState } from '..';
import { Action } from '../actions';
import { ActionType } from '../actionTypes';

const initialState: MenuState = {
  handlers: [],
  selected: 'page_code',
};

// Will be removed once liteEditor experiment will set to false
export const createMenuReducer = (isVeloInStudio: boolean = true) => {
  if (isVeloInStudio) {
    initialState.selected = 'pages';
  }
  return menuReducer;
};

const menuReducer = (state = initialState, action: Action): MenuState => {
  switch (action.type) {
    case ActionType.REGISTER_MENU_CLICK_HANDLER:
      return {
        ...state,
        handlers: state.handlers.concat([
          {
            id: action.id,
            callback: action.callback,
          },
        ]),
      };
    case ActionType.SELECT_MENU_ITEM:
      return {
        ...state,
        selected: action.selected,
      };
    default:
      return state;
  }
};
