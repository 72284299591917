import {
  ClassicEditorAPI,
  WixCodeAppAPI,
} from '@wix/wix-code-plugin-contracts';

interface CreateWixCodeApiParams {
  classicEditorAPI: ClassicEditorAPI;
}
export const createWixCodeAppAPI = ({
  classicEditorAPI,
}: CreateWixCodeApiParams): WixCodeAppAPI => {
  const { editorAPI } = classicEditorAPI;
  return {
    getSignedInstance: () => editorAPI.wixCode.getClientSpec().instance,
    getGridAppId: () => editorAPI.wixCode.getEditedGridApp(),
    ensureAppIsWriteable: () =>
      editorAPI.wixCode.fileSystem.ensureAppIsWriteable(),
  };
};
