// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kohCjU{background-color:#f7f8f8;display:flex;align-items:center;justify-content:center;width:100%}.kohCjU>.eAoe4n{max-width:50%;text-align:center}.kohCjU>.eAoe4n>.j5F3_s{font-family:HelveticaNeueW01-45Ligh,HelveticaNeueW02-45Ligh,HelveticaNeueW10-45Ligh,HelveticaNeueW31,Helvetica Neue,Helvetica,Arial,メイリオ,meiryo,ヒラギノ角ゴ pro w3,hiragino kaku gothic pro,sans-serif;font-family:var(--wix-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);font-weight:var(--wix-font-weight-regular);font-size:20px;font-weight:300;-webkit-font-smoothing:antialiased;color:#3b4057}.kohCjU>.eAoe4n>.gleVuI{font-family:HelveticaNeueW01-45Ligh,HelveticaNeueW02-45Ligh,HelveticaNeueW10-45Ligh,HelveticaNeueW31,Helvetica Neue,Helvetica,Arial,メイリオ,meiryo,ヒラギノ角ゴ pro w3,hiragino kaku gothic pro,sans-serif;font-family:var(--wix-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);font-weight:var(--wix-font-weight-regular);font-size:16px;-webkit-font-smoothing:antialiased;color:#868aa5}.kohCjU>.eAoe4n>.gtcscG{position:inherit;margin:0 auto}.kohCjU.qgdlk9{background-color:#061b29}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wix-code-page-editor-overlay": "kohCjU",
	"wixCodePageEditorOverlay": "kohCjU",
	"message-container": "eAoe4n",
	"messageContainer": "eAoe4n",
	"lead": "j5F3_s",
	"sub": "gleVuI",
	"circle-preloader": "gtcscG",
	"circlePreloader": "gtcscG",
	"dark": "qgdlk9"
};
export default ___CSS_LOADER_EXPORT___;
