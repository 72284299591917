export interface ReadOnlyMode {
  sidePanel?: {
    pages?: boolean;
    publicAndBackend?: boolean;
    packages?: boolean;
    github?: boolean;
  };
  propertiesPanel: {
    nicknamesSection: boolean;
    propsSection: boolean;
    eventsSection: boolean;
  };
  codeIde: boolean;
  fileSystem: boolean;
  cssEditing: {
    semanticClasses: boolean;
    addGlobalCss: boolean;
  };
  externalIde: boolean;
}

export const READ_ONLY_SIDE_PANEL: Required<ReadOnlyMode['sidePanel']> = {
  pages: true,
  github: true,
  publicAndBackend: true,
  packages: true,
};

export const CONCURRENT_EDITING_READ_ONLY_MODE: Partial<ReadOnlyMode> = {
  codeIde: true,
  sidePanel: {
    pages: true,
    github: true,
    publicAndBackend: true,
    packages: false,
  },
  propertiesPanel: {
    eventsSection: true,
    nicknamesSection: false,
    propsSection: false,
  },
  cssEditing: {
    addGlobalCss: true,
    semanticClasses: true,
  },
};

export const LITE_CONCURRENT_EDITING_READ_ONLY_MODE: Partial<ReadOnlyMode> = {
  codeIde: true,
  sidePanel: {
    pages: true,
    github: true,
    publicAndBackend: false,
    packages: false,
  },
  propertiesPanel: {
    eventsSection: true,
    nicknamesSection: false,
    propsSection: false,
  },
  cssEditing: {
    addGlobalCss: true,
    semanticClasses: true,
  },
  externalIde: false,
};

export interface ReadOnlyAPI {
  getReadOnlyMode: () => ReadOnlyMode;
  setReadOnly: (
    contributor: ReadOnlyContributor,
    state: Partial<ReadOnlyMode>,
  ) => void;
  removeReadOnlyContribution: (contributor: ReadOnlyContributor) => void;
}

export type ReadOnlyContributor =
  | 'GITHUB'
  | 'LOCAL_EDITOR'
  | 'CONCURRENT_EDITING'
  | 'VS_CODE';

// The contributor priority defines who wins in case of conflicts
export const OrderedContributorsByPriority: ReadOnlyContributor[] = [
  'CONCURRENT_EDITING',
  'LOCAL_EDITOR',
  'GITHUB',
  'VS_CODE',
];
