import { createBiLoggerAPI } from '@wix/wix-code-common-components';
import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';

export const BiLoggerEntryPoint: EntryPoint = {
  name: 'Bi Logger Entry Point',
  getDependencyAPIs() {
    return [APIKeys.SiteAPI, APIKeys.ClassicEditorAPI];
  },
  declareAPIs() {
    return [APIKeys.BiLoggerAPI];
  },
  attach(shell: Shell) {
    shell.contributeAPI(APIKeys.BiLoggerAPI, () => {
      const siteAPI = shell.getAPI(APIKeys.SiteAPI);
      const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
      return createBiLoggerAPI({
        msid: () => siteAPI.getMetaSiteId(),
        app_id: () =>
          siteAPI.getBlocksAppDefId() ||
          editorAPI.dsRead.appStudio?.getDevSiteAppDefId(),
        builderType: window.loggerModel?.fedOpsAppName,
      });
    });
  },
};
