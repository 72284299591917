import { DuplexerEvent } from '@wix/wix-code-plugin-contracts';
import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import {
  connect,
  subscribeToUserChannelEvent,
} from '@/legacy/core/services/duplexListener';

export const DuplexerEntryPoint: EntryPoint = {
  name: 'Duplexer Entry Point',
  getDependencyAPIs() {
    return [APIKeys.ClassicEditorAPI];
  },
  declareAPIs() {
    return [APIKeys.DuplexerAPI];
  },
  attach(shell: Shell) {
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
    shell.contributeAPI(APIKeys.DuplexerAPI, () => ({
      subscribeToUserChannel: (duplexerEvent: DuplexerEvent, callback) => {
        const connection = connect({
          editorAPI,
          appDefId: duplexerEvent.appDefId,
        });
        subscribeToUserChannelEvent({
          eventName: duplexerEvent.eventName,
          eventHandler: callback,
          connection,
        });
      },
      connect(appDefId) {
        return connect({
          editorAPI,
          appDefId,
        });
      },
    }));
  },
};
