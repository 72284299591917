import React, { ComponentType } from 'react';
import _ from 'lodash';
import { SlotKey, ExtensionSlot } from '@wix/wix-code-repluggable';
import { WixCodeIdeCompProps } from '../wixCodeIde/WixCodeIde';
import fileCodeEditorCreator from '../fileCodeEditor/fileCodeEditorV2';
import { LegacyEditorDependencies } from '@wix/wix-code-plugin-contracts';
import { SidebarSlot } from '../../codeEditorSidebar';

type TabRendererContribution = (props: {
  codeEditorProps: any;
}) => React.ReactNode;
export type TabRendererSlot = ExtensionSlot<TabRendererContribution>;
export const CodeEditorTabRendererSlot: SlotKey<TabRendererContribution> = {
  name: 'Code Editor Tab Renderer Slot',
};

interface TabRendererProps {
  tabRendererSlot: TabRendererSlot;
  codeEditorProps: WixCodeIdeCompProps;
  legacyDependencies: LegacyEditorDependencies;
  sidebarSlot: SidebarSlot;
  Footer?: ComponentType;
}

const createFileCodeEditor = _.once(
  (legacyDependencies: LegacyEditorDependencies, sidebarSlot: SidebarSlot) => {
    return fileCodeEditorCreator({
      ...legacyDependencies,
      sidebarSlot,
    });
  },
);

export const TabRenderer = ({
  tabRendererSlot,
  sidebarSlot,
  codeEditorProps,
  legacyDependencies,
  Footer,
}: TabRendererProps) => {
  const FileCodeEditor = createFileCodeEditor(legacyDependencies, sidebarSlot);
  const compContribution = tabRendererSlot.getSingleItem()?.contribution;
  if (compContribution) {
    return <>{compContribution({ codeEditorProps })}</>;
  }
  return <FileCodeEditor {...codeEditorProps} Footer={Footer} />;
};
