import React, { useEffect, useState } from 'react';
import { i18n, I18nextProvider, initI18n } from '@wix/wix-i18n-config';

let initializedI18n: i18n;

export const initi18n = async (languageCode = 'en') => {
  const i18nInstance = initI18n({
    locale: languageCode,
    disableAutoInit: true,
    asyncMessagesLoader: (locale) =>
      import(`./assets/locale/messages_${locale}.json`),
  });

  await i18nInstance.init();
  initializedI18n = i18nInstance;

  return i18nInstance;
};

export const TranslationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [i18nInstance, setI18nInstance] = useState<null | i18n>(null);
  useEffect(() => {
    if (initializedI18n) {
      setI18nInstance(initializedI18n);
    } else {
      initi18n().then(() => setI18nInstance(initializedI18n));
    }
  }, []);

  return i18nInstance ? (
    <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>
  ) : null;
};
