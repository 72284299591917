import { ActionType } from '../actions/types';
import { WixCodeTheme } from '@/codeEditor/constants/themes';

export type IDEReducerState = {
  theme: typeof WixCodeTheme | null;
};

type IDEReducerAction = {
  type: ActionType.IDE_SET_THEME;
  theme: typeof WixCodeTheme;
};

const initialState: IDEReducerState = {
  theme: null,
};

const ideReducer = (state = initialState, action: IDEReducerAction) => {
  switch (action.type) {
    case ActionType.IDE_SET_THEME:
      return { ...state, theme: action.theme };

    default:
      return state;
  }
};

export default ideReducer;
