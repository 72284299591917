export const MODALS_ACTIONS = {
  CONTINUE: 'continue',
  CANCEL: 'cancel',
  X: 'X',
  LEARN_MORE: 'learn_more',
  TRY_AGAIN: 'try_again',
  HELP: '?',
};
export const EDITOR_VIEW_MODE = {
  PREVIEW: 'preview',
  EDIT: 'edit',
};
