import { AnyAction } from 'redux';
import {
  ConnectionState,
  initialConnectionState,
  initialSyncState,
  SyncState,
} from './index';
import {
  CONNECTED,
  DISCONNECTED,
  RECONNECTED,
  SET_SYNCING,
  SET_SYNC_SUCCESS,
} from './actions';

export const syncStateReducer = (
  state: SyncState = initialSyncState,
  action: AnyAction,
): SyncState => {
  switch (action.type) {
    case SET_SYNCING:
      return {
        ...state,
        isSyncing: action.isSyncing,
      };
    case SET_SYNC_SUCCESS:
      return {
        ...state,
        isSyncSuccess: action.isSyncSuccess,
      };
    default:
      return state;
  }
};

export const connectionStateReducer = (
  state: ConnectionState = initialConnectionState,
  action: AnyAction,
): ConnectionState => {
  switch (action.type) {
    case CONNECTED:
    case RECONNECTED:
      return {
        connected: true,
      };
    case DISCONNECTED:
      return {
        connected: false,
      };
    default:
      return state;
  }
};
