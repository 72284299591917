import { GithubPublicAPI } from '@wix/wix-code-plugin-contracts';
import { GithubPrivateAPI } from './githubPrivateAPI';

export const createGithubPublicAPI = (
  githubPrivateAPI: GithubPrivateAPI,
): GithubPublicAPI => {
  return {
    isGithubEnabled() {
      return githubPrivateAPI.getOnboardingState() === 'GITHUB_ENABLED';
    },
    isLoadingOnboardingStatus() {
      return githubPrivateAPI.isLoadingOnboardingStatus();
    },
    setFileStructureType(type) {
      githubPrivateAPI.setFileStructureType(type)
    },
  };
};
