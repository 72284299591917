export const constants = {
  section_id: 'css_editing_section',
  styles_folder: 'styles',
  global_css_path: 'styles/global.css',
  global_css_name: 'global.css',
  firstTimeBadgeKey: 'wix-code-css-exposed-date',
  css: 'css',
};

// TODO: this list should be exported and reused https://github.com/wix-private/cloud-runtime/blob/master/packages/css/cloud-css-constants/src/blacklistAttributesConstants.ts#LL6C1-L159C3
export const EDITOR_VIEW_ENVIRONMENT_BLACKLIST = [
  "width",
  "min-width",
  "max-width",
  "height",
  "min-height",
  "max-height",
  "display",
  "flex",
  "flex-flow",
  "flex-basis",
  "flex-direction",
  "flex-grow",
  "flex-shrink",
  "flex-wrap",
  "align-self",
  "place-items",
  "align-items",
  "justify-items",
  "place-content",
  "align-content",
  "justify-content",
  "order",
  "grid",
  "grid-area",
  "grid-auto-columns",
  "grid-auto-flow",
  "grid-auto-rows",
  "grid-column",
  "grid-column-end",
  "grid-column-start",
  "grid-row",
  "grid-row-end",
  "grid-row-start",
  "grid-template",
  "grid-template-areas",
  "grid-template-columns",
  "grid-template-rows",
  "margin",
  "margin-top",
  "margin-right",
  "margin-bottom",
  "margin-left",
  "position",
  "z-index",
  "top",
  "right",
  "bottom",
  "left",
  "padding",
  "padding-top",
  "padding-right",
  "padding-bottom",
  "padding-left",
  "border",
  "border-width",
  "border-style",
  "border-top",
  "border-top-width",
  "border-top-style",
  "border-right",
  "border-right-width",
  "border-right-style",
  "border-bottom",
  "border-bottom-width",
  "border-bottom-style",
  "border-left",
  "border-left-width",
  "border-left-style",
  "-webkit-border-image-width",
  "-moz-border-image-width",
  "-o-border-image-width",
  "border-image-width",
  "-webkit-border-image-outset",
  "-moz-border-image-outset",
  "-o-border-image-outset",
  "border-image-outset",
  "outline",
  "outline-width",
  "outline-style",
  "outline-color",
  "outline-offset",
  "container-type",
  "-webkit-animation",
  "-moz-animation",
  "-ms-animation",
  "-o-animation",
  "animation",
  "-webkit-animation-name",
  "-moz-animation-name",
  "-ms-animation-name",
  "-o-animation-name",
  "animation-name",
  "-webkit-animation-duration",
  "-moz-animation-duration",
  "-ms-animation-duration",
  "-o-animation-duration",
  "animation-duration",
  "-webkit-animation-play-state",
  "-moz-animation-play-state",
  "-ms-animation-play-state",
  "-o-animation-play-state",
  "animation-play-state",
  "-webkit-animation-timing-function",
  "-moz-animation-timing-function",
  "-ms-animation-timing-function",
  "-o-animation-timing-function",
  "animation-timing-function",
  "-webkit-animation-delay",
  "-moz-animation-delay",
  "-ms-animation-delay",
  "-o-animation-delay",
  "animation-delay",
  "-webkit-animation-iteration-count",
  "-moz-animation-iteration-count",
  "-ms-animation-iteration-count",
  "-o-animation-iteration-count",
  "animation-iteration-count",
  "-webkit-animation-direction",
  "-moz-animation-direction",
  "-ms-animation-direction",
  "-o-animation-direction",
  "animation-direction",
  "-webkit-animation-fill-mode",
  "-moz-animation-fill-mode",
  "-ms-animation-fill-mode",
  "-o-animation-fill-mode",
  "animation-fill-mode",
  "-webkit-transition",
  "-moz-transition",
  "-ms-transition",
  "-o-transition",
  "transition",
  "-webkit-transition-delay",
  "-moz-transition-delay",
  "-ms-transition-delay",
  "-o-transition-delay",
  "transition-delay",
  "-webkit-transition-timing-function",
  "-moz-transition-timing-function",
  "-ms-transition-timing-function",
  "-o-transition-timing-function",
  "transition-timing-function",
  "-webkit-transition-duration",
  "-moz-transition-duration",
  "-ms-transition-duration",
  "-o-transition-duration",
  "transition-duration",
  "-webkit-transition-property",
  "-moz-transition-property",
  "-ms-transition-property",
  "-o-transition-property",
  "transition-property",
];
