import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TreeSectionReducerState as TreeSectionReducerStateInternal,
  TreeSectionSetExpandedParams as TreeSectionSetExpandedParamsInternal,
  TreeSectionSetLoadedParams,
  TreeContributeSectionParams,
  TreeContributeFileValidationRuleParams,
  TreeSectionActions,
  SectionStatuses as SectionStatusesInternal,
} from '@wix/wix-code-plugin-contracts';

export { STATIC_SECTIONS } from '@wix/wix-code-plugin-contracts';

export type SectionStatuses = SectionStatusesInternal;
export type TreeSectionReducerState = TreeSectionReducerStateInternal;
export type TreeSectionSetExpandedParams = TreeSectionSetExpandedParamsInternal;
const initialState: TreeSectionReducerStateInternal = {
  singelFileSections: [],
  contributedSections: [],
  contributedValidationRules: [],
  statuses: {},
};

export const defaultStatuses = () => ({ loaded: false, expanded: true });

const initStatuses = (sectionName: string, draft: TreeSectionReducerState) => {
  draft.statuses[sectionName] = defaultStatuses();
};

const slice = createSlice({
  name: 'treeSection',
  initialState,
  reducers: {
    registerSingleFileSection(draft, action: PayloadAction<string>) {
      const fileName: string = action.payload;
      const files = draft.singelFileSections;
      draft.singelFileSections = files.includes(fileName)
        ? files
        : [...files, fileName];
    },
    expandTreeSection(
      draft,
      action: PayloadAction<TreeSectionSetExpandedParams>,
    ) {
      const { sectionName } = action.payload;
      if (!draft.statuses[sectionName]) {
        initStatuses(sectionName, draft);
      }
      draft.statuses[sectionName]!.expanded = true;
    },
    collapseTreeSection(
      draft,
      action: PayloadAction<TreeSectionSetExpandedParams>,
    ) {
      const { sectionName } = action.payload;
      if (!draft.statuses[sectionName]) {
        initStatuses(sectionName, draft);
      }
      draft.statuses[sectionName]!.expanded = false;
    },
    setTreeSectionLoaded(
      draft,
      action: PayloadAction<TreeSectionSetLoadedParams>,
    ) {
      const { sectionName } = action.payload;
      if (!draft.statuses[sectionName]) {
        initStatuses(sectionName, draft);
      }
      draft.statuses[sectionName]!.loaded = true;
    },
    setTreeSectionLoading(
      draft,
      action: PayloadAction<TreeSectionSetLoadedParams>,
    ) {
      const { sectionName } = action.payload;
      if (!draft.statuses[sectionName]) {
        initStatuses(sectionName, draft);
      }
      draft.statuses[sectionName]!.loaded = false;
    },
    contributeTreeSection(
      draft,
      action: PayloadAction<TreeContributeSectionParams>,
    ) {
      const { section } = action.payload;
      draft.contributedSections.push(section);
    },
    contributeFileValidationRule(
      draft,
      action: PayloadAction<TreeContributeFileValidationRuleParams>,
    ) {
      const { rule } = action.payload;
      draft.contributedValidationRules.push(rule);
    },
  },
});

export default slice.reducer;
export const treeSectionActions: TreeSectionActions = slice.actions;
