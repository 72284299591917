// import sanitizeHtml from 'sanitize-html';
import * as _ from 'lodash';
import { packageStatus } from './consts.ts';
import { experimentUtils } from '@wix/wix-code-common';

const {
  AVAILABLE,
  APPROVED,
  PENDING,
  PENDING_OPERATOR_APPROVAL,
  REJECTED,
  DELETED,
  UNKNOWN,
  NOT_FOUND,
} = packageStatus;

const sanitizeHtml = (html) => html;

const isStatusAvailable = (status) => [AVAILABLE, APPROVED].includes(status);

const isStatusPending = (status) =>
  [PENDING, PENDING_OPERATOR_APPROVAL].includes(status);

const npmPackageFormaterCreator = ({ installedVersions }) => {
  const format = (pkg) => {
    let tags;
    let versionsMap;
    let installedVersion;
    let newestSupportedVersion;
    let requestLatestVersion;

    const _sanitizedName = sanitizeHtml(pkg.name);
    const _sanitizedDescription = sanitizeHtml(pkg.description);

    const formatedPkg = {
      name: _sanitizedName,
      description: _sanitizedDescription,
      license: pkg.license,
      highlight: {
        name: pkg.highlights.name?.highlightValues,
        description: pkg.highlights.description?.highlightValues,
      },
      readme: pkg.readme,
    };
    const self = this;

    function extractTags() {
      tags = pkg.tags.reduce((acc, _tag) => {
        const [tag, tagValue] = _tag.split('|');
        return { ...acc, [tag]: tagValue };
      }, {});
      formatedPkg._tags = tags;
    }
    function orderedLabeledVersions() {
      const _tags = tags || [];
      const { latest } = _tags;
      const calcStatusScore = (status = '') => {
        const statusScoreMap = {
          [AVAILABLE]: 5,
          [APPROVED]: 5,
          [PENDING]: 4,
          [PENDING_OPERATOR_APPROVAL]: 4,
          [UNKNOWN]: 3,
          [REJECTED]: 2,
          [DELETED]: 2,
          [NOT_FOUND]: 1,
        };

        return statusScoreMap[status] || 0;
      };
      const __orderedLabeledVersions = experimentUtils.isAnyNpmNewExperience()
        ? Object.keys(versionsMap).reverse()
        : Object.keys(versionsMap)
            .sort((a, b) => {
              const statusScore =
                calcStatusScore(versionsMap[a].status) -
                calcStatusScore(versionsMap[b].status);
              return statusScore;
            })
            .reverse();
      const _orderedLabeledVersions = __orderedLabeledVersions.map((key) => {
        // eslint-disable-next-line no-shadow
        const { name, _sanitizedName, _sanitizedDescription } = formatedPkg;

        const versionInfo = {
          ...versionsMap[key],
          name,
          _sanitizedName,
          _sanitizedDescription,
        };
        const isLatest = latest === key;
        const isAvailable = isStatusAvailable(versionInfo.status);
        const additionalLabelInfo = !isAvailable
          ? ''
          : isLatest
          ? ' - latest'
          : ''; // TODO: Localize latest string???
        return {
          value: key,
          label: `${key}${additionalLabelInfo}`,
          versionInfo,
        };
      });
      formatedPkg._orderedLabeledVersions = _orderedLabeledVersions;
      return self;
    }
    function extractVersions() {
      const indexVersions = pkg.versions.reduce((acc, _version) => {
        const { id: version, publishedDate } = _version;
        acc[version] = {
          version,
          description: pkg.description,
          license: pkg.license,
          repository: {
            type: 'git',
          },
          status: _version.wixCodeStatus,
          statusReason: _version?.additionalRequestInfo?.rejectionType,
          statusDescription:
            _version?.additionalRequestInfo?.rejectionDescription,
          publishedDate,
        };

        return acc;
      }, {});

      versionsMap = indexVersions;
      return self;
    }
    function versionToPresent(latest) {
      if (experimentUtils.isAnyNpmNewExperience()) {
        return versionsMap[latest] || versionsMap[versionsMap.length - 1];
      }
      return (
        formatedPkg._orderedLabeledVersions.find(
          (_version) => _version.versionInfo.status === AVAILABLE,
        ) ||
        formatedPkg._orderedLabeledVersions.find(
          (_version) => _version.versionInfo.status === REJECTED,
        ) ||
        formatedPkg._orderedLabeledVersions.find((_version) =>
          isStatusPending(_version.versionInfo.status),
        )
      );
    }
    function extractVersionToPresent() {
      const _tags = formatedPkg._tags || [];
      const { latest } = _tags;
      const version = versionToPresent(latest);
      formatedPkg._version = _.get(version, 'versionInfo', versionsMap[latest]);
    }
    function addInstalledVersion() {
      installedVersion = installedVersions.find(
        (version) => version.name === _sanitizedName,
      )?.version;
      formatedPkg.installedVersion = installedVersion;
    }
    function getUpgradableVersion() {
      if (experimentUtils.isAnyNpmNewExperience()) {
        return formatedPkg._version.version;
      }
      if (installedVersion && installedVersion !== newestSupportedVersion) {
        return newestSupportedVersion;
      }
      return null;
    }
    function addUpgradableVersion() {
      formatedPkg.upgradableVersion = getUpgradableVersion();
    }

    function addRequestLatestVersion() {
      requestLatestVersion =
        newestSupportedVersion && newestSupportedVersion !== tags.latest
          ? tags.latest
          : null;
      formatedPkg.requestLatestVersion = requestLatestVersion;
    }

    function addNewestSupportedVersion() {
      const getNewestSupportedVersion = (versions) => {
        const orderedSupportedVersionsRes = [];
        const mostRecentVersion = versions.reverse();
        for (const version of mostRecentVersion) {
          if (version.wixCodeStatus === 'AVAILABLE') {
            orderedSupportedVersionsRes.push(version.id);
            return orderedSupportedVersionsRes;
          }
        }
        return orderedSupportedVersionsRes;
      };
      const orderedSupportedVersions = getNewestSupportedVersion(pkg.versions);
      newestSupportedVersion = orderedSupportedVersions[0];
    }

    extractTags();
    extractVersions();
    addNewestSupportedVersion();
    orderedLabeledVersions();
    extractVersionToPresent();
    addInstalledVersion();
    addUpgradableVersion();
    if (!experimentUtils.isAnyNpmNewExperience()) {
      addRequestLatestVersion();
    }
    return formatedPkg;
  };

  return {
    format,
  };
};

export { npmPackageFormaterCreator };
