export const dataHooks = {
  LEFT_TREE_SPI_SECTION: 'spi-section',
  SECTION_DIVIDER: 'corvid-site-structure__section-divider',
  CONTEXT_MENU_ICON: 'context-menu-icon',
  EMPTY_STATE: 'corvid-site-structure__empty-state',
  DESCRIPTION: 'description',
  LEARN_MORE_LINK: 'link-button',
  CTA_BUTTON: 'cta-button',
  SPI_WIZARD_STEP_1: 'spi-wizard-step-1',
  SPI_WIZARD_STEP_2: 'spi-wizard-step-2',
  SPI_WIZARD_STEP_3: 'spi-wizard-step-3',
  SPI_WIZARD_STEP_1_START_BUTTON: 'spi-wizard-step-1-start-button',
  SPI_WIZARD_STEP_2_ACCEPT_BUTTON: 'spi-wizard-step-2-accept-button',
  SPI_WIZARD_STEP_2_CANCEL_BUTTON: 'spi-wizard-step-2-cancel-button',
  SPI_WIZARD_STEP_2_CHECKBOX: 'spi-wizard-step-2-checkbox',
  SPI_WIZARD_STEP_3_CREATE_SPI: 'spi-wizard-step-3-create-spi',
  SPI_WIZARD_STEP_3_CANCEL_BUTTON: 'spi-wizard-step-3-cancel-button',
  SPI_WIZARD_STEP_3_TEXT_INPUT: 'spi-wizard-step-3-text-input',
  SPI_WIZARD_STEP_3_CONFIG_FILE: 'spi-wizard-step-3-config-file',
  SPI_WIZARD_STEP_3_JS_FILE: 'spi-wizard-step-3-js-file',
  LEFT_TREE_SPI_SECTION_EMPTY_STATE: 'spi-section-empty-state',
  SPI_STATUS_PUBLISHED: 'spi-status-published',
  SPI_STATUS_CONNECTED: 'spi-status-connected',
  SPI_STATUS_ERROR: 'spi-status-error',
  SPI_WIZARD_STEP_1_HEADING: 'spi-wizard-step-1-heading',
  POST_PUBLISH_MODAL: 'spi-post-publish-modal',
  DELETE_SPI_MODAL: 'delete-spi-modal',
  TOOLTIP_CTA: 'tooltip-cta',
};
