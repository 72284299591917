import { APIKeys, EntryPoint, Shell } from '@wix/wix-code-repluggable';

import initUserPreferencesAPI from '@/utils/userPreference';

export const UserPreferencesEntryPoint: EntryPoint = {
  name: 'User Prefereneces',
  getDependencyAPIs() {
    return [APIKeys.LegacyEditorDependencies, APIKeys.ClassicEditorAPI];
  },
  declareAPIs() {
    return [APIKeys.UserPreferencesAPI];
  },
  attach(shell: Shell) {
    const { stateManagement } = shell.getAPI(APIKeys.LegacyEditorDependencies);
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
    const userPreferencesAPI = initUserPreferencesAPI({
      stateManagement,
    });
    const withEditorAPI = {
      getGlobalPreference: (key: string) =>
        userPreferencesAPI.getGlobalPreference(editorAPI, key),
      setGlobalPreference: <T>(key: string, value: T) =>
        userPreferencesAPI.setGlobalPreference(editorAPI, key, value),
      getSitePreference: (key: string) =>
        userPreferencesAPI.getSitePreference(editorAPI, key),
      setSitePreference: <T>(key: string, value: T) =>
        userPreferencesAPI.setSitePreference(editorAPI, key, value),
    };
    shell.contributeAPI(APIKeys.UserPreferencesAPI, () => withEditorAPI);
  },
};
