import { APIKeys, EntryPoint, Shell } from '@wix/wix-code-repluggable';
import { PredefinedSidebarTabs } from '@wix/wix-code-plugin-contracts';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { sideBarContent } from './contributions/sideBarContent';
import dataHooks from './components/dataHooks';
import {
  acceptedGithubTermsReducer,
  GithubState,
  onboardingStatusReducer,
} from './state';
import createCodeEditorFooter from './components/CodeEditorFooter/CodeEditorFooter';
import { initi18n } from './i18n';
import {
  createGithubPrivateAPI,
  GithubPrivateAPIKey,
} from './githubPrivateAPI';
import { OnboardingState } from './types/GithubTypes';
import { createGithubModalsAPI, GithubModalsAPIKey } from './githubModalsAPI';
import { listenToExternalCompletedOnboardingEvent } from './githubOnboardingCompletedEventAPI';
import { EXPERIMENTS, ON_BOARDING_SUPPORTED_ERRORS } from './constants';
import { createGithubPublicAPI } from './githubPublicAPI';
import { createGithubTabLiteWrapper } from './components/GithubTab/GithubTabLiteWrapper';
import { externalCodeEditorBannerCreator } from './components/CodeTabsExternalHeader/ExternalCodeEditorBanner';

let unsubscribeErrorListener: () => void;
export const entryPoint: EntryPoint = {
  name: 'Github Integration',
  getDependencyAPIs() {
    return [
      APIKeys.EditorContextAPI,
      APIKeys.SidebarAPI,
      APIKeys.CodeEditorAPI,
      APIKeys.WixCodeAppAPI,
      APIKeys.LegacyEditorDependencies,
      APIKeys.ClassicEditorAPI,
      WixCodeEditorAdapterAPI,
      APIKeys.ReadOnlyAPI,
      APIKeys.FileSystemAPI,
      APIKeys.WixCodeDuplexerAPI,
      APIKeys.UserPreferencesAPI,
      APIKeys.LocalEditorPublicAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.ExperimentsAPI,
      APIKeys.FilesViewAPI,
      APIKeys.MenuAPI,
      APIKeys.MenuPanelAPI,
      APIKeys.PackagesAndAppsAPI,
      APIKeys.PanelsAPI,
    ];
  },
  declareAPIs() {
    return [GithubPrivateAPIKey, GithubModalsAPIKey, APIKeys.GithubPublicAPI];
  },
  attach(shell: Shell) {
    shell.contributeState<GithubState>(() => ({
      onboarding: onboardingStatusReducer,
      acceptedGithubTerms: acceptedGithubTermsReducer,
    }));
    shell.contributeAPI(GithubPrivateAPIKey, () => {
      const wixCodeAppAPI = shell.getAPI(APIKeys.WixCodeAppAPI);
      const readOnlyAPI = shell.getAPI(APIKeys.ReadOnlyAPI);
      const fileSystemAPI = shell.getAPI(APIKeys.FileSystemAPI);
      const wixCodeEditorAdapterAPI = shell.getAPI(WixCodeEditorAdapterAPI);
      const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
      const experimentsAPI = shell.getAPI(APIKeys.ExperimentsAPI);
      const githubPrivateAPI = createGithubPrivateAPI(
        shell,
        wixCodeAppAPI,
        readOnlyAPI,
        fileSystemAPI,
        wixCodeEditorAdapterAPI,
        editorAPI,
        experimentsAPI
      );
      return githubPrivateAPI;
    });
    shell.contributeAPI(GithubModalsAPIKey, () => {
      const wixCodeEditorAdapterAPI = shell.getAPI(WixCodeEditorAdapterAPI);
      const classicEditorAPI = shell.getAPI(APIKeys.ClassicEditorAPI);
      const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
      const githubAPI = shell.getAPI(GithubPrivateAPIKey);
      const packagesAndAppsAPI = shell.getAPI(APIKeys.PackagesAndAppsAPI);
      const experimentsAPI = shell.getAPI(APIKeys.ExperimentsAPI);

      return createGithubModalsAPI({
        shell,
        wixCodeEditorAdapterAPI,
        classicEditorAPI,
        githubAPI,
        biLoggerAPI,
        packagesAndAppsAPI,
        experimentsAPI,
      });
    });
    shell.contributeAPI(APIKeys.GithubPublicAPI, () => {
      const githubPrivateAPI = shell.getAPI(GithubPrivateAPIKey);
      return createGithubPublicAPI(githubPrivateAPI);
    });
  },
  async extend(shell: Shell) {
    const experimentsAPI = shell.getAPI(APIKeys.ExperimentsAPI);
    const editorContextAPI = shell.getAPI(APIKeys.EditorContextAPI);

    const editorType = editorContextAPI.getEditorType();
    const isInEditorX = editorType === 'EditorX';
    const isInStudio = editorType === 'Studio';
    const isInClassic = editorType === 'Classic';
    const isInBlocks = editorType === 'Blocks';

    const shouldContributeGithub =
      isInEditorX ||
      isInStudio ||
      isInBlocks ||
      (isInClassic &&
        experimentsAPI.isOpen('specs.wixCode.githubIntegrationInClassic'));

    if (!shouldContributeGithub) {
      return;
    }

    const sidebarAPI = shell.getAPI(APIKeys.SidebarAPI);
    const codeEditorAPI = shell.getAPI(APIKeys.CodeEditorAPI);
    const readOnlyAPI = shell.getAPI(APIKeys.ReadOnlyAPI);
    const legacyDependenciesAPI = shell.getAPI(
      APIKeys.LegacyEditorDependencies
    );
    const githubAPI = shell.getAPI(GithubPrivateAPIKey);
    const githubModalsAPI = shell.getAPI(GithubModalsAPIKey);
    const wixCodeDuplexerAPI = shell.getAPI(APIKeys.WixCodeDuplexerAPI);
    const wixCodeEditorAdapterAPI = shell.getAPI(WixCodeEditorAdapterAPI);
    const localEditorPublicAPI = shell.getAPI(APIKeys.LocalEditorPublicAPI);
    const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
    const userPreferencesAPI = shell.getAPI(APIKeys.UserPreferencesAPI);
    const filesViewAPI = shell.getAPI(APIKeys.FilesViewAPI);
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
    const menuAPI = shell.getAPI(APIKeys.MenuAPI);
    const menuPanelAPI = shell.getAPI(APIKeys.MenuPanelAPI);
    const panelsAPI = shell.getAPI(APIKeys.PanelsAPI);

    const CodeEditorFooter = createCodeEditorFooter(
      githubModalsAPI,
      githubAPI,
      biLoggerAPI
    );

    if (localEditorPublicAPI.isInLocalEditor()) {
      return;
    }

    unsubscribeErrorListener = githubAPI.subscribeToOnboardingError(
      (errorType: ON_BOARDING_SUPPORTED_ERRORS) => {
        if (
          editorAPI.developerMode.isEnabled() &&
          experimentsAPI.isOpen(EXPERIMENTS.GithubConnectionIssuesModal) &&
          errorType !== ON_BOARDING_SUPPORTED_ERRORS.GENERIC_ERROR
        ) {
          githubModalsAPI.openConnectionIssuesModal(errorType);
        }
      }
    );
    githubAPI.syncWithSavedState();

    const isGithubEnabled = () =>
      githubAPI.getOnboardingState() === OnboardingState.GITHUB_ENABLED;

    githubAPI.ensureSyncedWithRemote();

    filesViewAPI.contributeFileNameTransformer(
      shell,
      ({ originalTabTitle, displayName, tabId, isPage, suffix }) => {
        if (!isPage) {
          return originalTabTitle;
        }
        const pageGuid = tabId.split('/').pop();
        const fileName = `${displayName}.${pageGuid}`;
        return suffix ? `${fileName}${suffix}` : fileName;
      },
      isGithubEnabled
    );

    const getSideBarContent = () =>
      sideBarContent({
        shell,
        githubModalsAPI,
        readOnlyAPI,
        githubAPI,
        biLoggerAPI,
        userPreferencesAPI,
        experimentsAPI,
        editorContextAPI,
      });

    const i18nInstance = await initi18n(
      legacyDependenciesAPI.util.editorModel.languageCode
    );

    addFooterContribution();
    contributePageCodeDuplicateCondition();

    listenToExternalCompletedOnboardingEvent({
      i18nInstance,
      wixCodeDuplexerAPI,
      githubAPI,
      wixCodeEditorAdapterAPI,
    });

    subscribeToGithubCommits();

    sidebarAPI.contributeTab(() => ({
      id: PredefinedSidebarTabs.GITHUB,
      label: 'Left_Tree_Components_Title_Github',
      dataHook: dataHooks.sideBar.githubTab,
      infoText: 'Left_Tree_Components_Info_Text_Github',
      infoLinkText: 'Left_Tree_Components_Info_Link_Text_Github',
      infoLinkUrl: 'Left_Tree_Components_Link_Github',
      className: 'github-tab-header',
      shouldShowTab: () => true,
      ...getSideBarContent(),
    }));

    menuPanelAPI.contributeTab({
      id: 'github',
      TabContent: ({ closePanel }) => {
        const TabContent = getSideBarContent().creator();
        return createGithubTabLiteWrapper({
          closePanel,
          panelsAPI,
          children: TabContent,
        });
      },
    });

    menuAPI.registerMenuItemListener('github', () => {
      menuPanelAPI.selectTab('github');
    });
    contributeExternalCodeEditorBannerCondition();
    function addFooterContribution() {
      codeEditorAPI.contributeFooter(
        shell,
        CodeEditorFooter,
        () => githubAPI.getOnboardingState() === OnboardingState.GITHUB_ENABLED
      );
    }

    function contributePageCodeDuplicateCondition() {
      wixCodeEditorAdapterAPI.contributePageCodeDuplicateCondition(
        shell,
        () =>
          !(githubAPI.getOnboardingState() === OnboardingState.GITHUB_ENABLED)
      );
    }
    function contributeExternalCodeEditorBannerCondition() {
      codeEditorAPI.contributeExternalIDEBanner(
        shell,
        externalCodeEditorBannerCreator(
          biLoggerAPI,
          githubModalsAPI,
          legacyDependenciesAPI
        ),
        () => githubAPI.getOnboardingState() === OnboardingState.GITHUB_ENABLED
      );
    }

    function subscribeToGithubCommits() {
      wixCodeDuplexerAPI.subscribeTo.githubCommits((event) => {
        const { commits = [] } = event;
        githubAPI.newCommitsReceived(commits, i18nInstance);
      });
    }
  },
  detach(shell: Shell) {
    const readOnlyAPI = shell.getAPI(APIKeys.ReadOnlyAPI);
    readOnlyAPI.removeReadOnlyContribution('GITHUB');
    unsubscribeErrorListener();
  },
};

export const GithubIntegrationEntryPoint = entryPoint;
