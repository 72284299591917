import React from 'react';
import { TextInput, Composites, TextLabel } from '@wix/wix-base-ui';
import dataHooks from '../../../../dataHooks';
import styles from './RepositoryName.scss';

const RepositoryName: React.FC<{
  translate: (key: string) => string;
  repoName: string;
  onChange: (value: string, isValid: boolean) => void;
  doesRepoNameExist: boolean;
}> = ({ translate: t, repoName, onChange, doesRepoNameExist }) => {
  const isRepoNameEmpty = repoName.trim().length === 0;
  const validationCheck =
    !isRepoNameEmpty && repoName.length < 100 && !doesRepoNameExist;
  const invalidMessage = doesRepoNameExist
    ? t(
        'githubIntegration.connect_modal.create_repository.repository_name_exists_err_msg',
      )
    : isRepoNameEmpty
    ? t(
        'githubIntegration.connect_modal.create_repository.repository_name_err_msg',
      )
    : t(
        'githubIntegration.connect_modal.create_repository.name_too_long_err_msg',
      );
  return (
    <div
      className={styles.gitRepositoryName}
      data-hook={dataHooks.connectModal.createRepository.repoName.container}
    >
      <Composites.TextInputLabeled>
        <TextLabel
          type="T02"
          value={t(
            'githubIntegration.connect_modal.create_repository.repository_name_label',
          )}
          dataHook={dataHooks.connectModal.createRepository.repoName.label}
        />
        <TextInput
          value={repoName}
          onChange={onChange}
          hideSuccessIndication={true}
          isValid={validationCheck}
          invalidMessage={invalidMessage}
          dataHook={dataHooks.connectModal.createRepository.repoName.input}
          shouldTranslate={false}
        />
      </Composites.TextInputLabeled>
    </div>
  );
};

export default RepositoryName;
