import React from 'react';
import LockBold from 'wix-ui-icons-common/classic-editor/LockBold';
import { useTranslation } from '@wix/wix-i18n-config';
import { TranslationProvider } from '../../i18n';
import { GithubModalsAPI } from '../../githubModalsAPI';
import { GithubPrivateAPI } from '../../githubPrivateAPI';
import { cloneAndDevelopeAction } from '../../utils/biEvents';
import { BiLoggerAPI } from '@wix/wix-code-plugin-contracts';
import { CloneCode } from '@wix/wix-ui-icons-common/responsive-editor';
import { FloatingNotification } from '@wix/wix-code-common-components';

export interface FooterProps {
  githubModalsAPI: GithubModalsAPI;
  githubAPI: GithubPrivateAPI;
  biLoggerAPI: BiLoggerAPI;
}

export const Footer: React.FC<FooterProps> = ({
  githubModalsAPI,
  githubAPI,
  biLoggerAPI: bi,
}) => {
  const [t] = useTranslation();

  return (
    <FloatingNotification
      text={t('githubIntegration.code_editor_footer.text', {
        defaultBranch: githubAPI.getGithubRepository()?.defaultBranch,
      })}
      prefixIcon={<LockBold />}
      buttonLabel={t('githubIntegration.code_editor_footer.button_text')}
      buttonType="BUTTON"
      buttonOnClick={() => {
        bi.report(cloneAndDevelopeAction(githubAPI));
        githubModalsAPI.showInstructionsModal();
      }}
      buttonIcon={<CloneCode />}
    />
  );
};

const createCodeEditorFooter =
  (
    githubModalsAPI: GithubModalsAPI,
    githubAPI: GithubPrivateAPI,
    biLoggerAPI: BiLoggerAPI,
  ) =>
  () =>
    (
      <TranslationProvider>
        <Footer
          githubModalsAPI={githubModalsAPI}
          githubAPI={githubAPI}
          biLoggerAPI={biLoggerAPI}
        />
      </TranslationProvider>
    );
export default createCodeEditorFooter;
