import { ConsoleReaderState } from './consoleReducer';

function getVisibleLevels(state: { console: ConsoleReaderState }) {
  return state.console.visibleLevels;
}

function getMessages(state: { console: ConsoleReaderState }) {
  return state.console.messages;
}

export default {
  getVisibleLevels,
  getMessages,
};
