import { FindReplaceToggleVisible } from '../reducers/findReplaceReducer';
import { ActionType } from './types';

function toggleVisible(visible: boolean): FindReplaceToggleVisible {
  return {
    type: ActionType.FIND_REPLACE_TOGGLE_VISIBLE,
    visible,
  };
}

export default {
  toggleVisible,
};
