import React, { Dispatch, useEffect, useState } from 'react';
import _ from 'lodash';
import once_ from 'lodash/once';

import type DS from '@wix/document-services-types';
import { MapStateToProps } from 'react-redux';
import { InstalledCodeReusePkg } from '@/toExtract/packages/packagesModalContext';
import {
  AppData as AppDataInternal,
  InstallStatus as InstallStatusInternal,
  PrivateAppData as PrivateAppDataInternal,
} from '@wix/wix-code-plugin-contracts';

export type AppData = AppDataInternal;
export { InstallStatusInternal as InstallStatus };
export type PrivateAppData = PrivateAppDataInternal;
interface StateProps {
  installedApps: PrivateAppData[];
  isLoading: boolean;
}

interface DispatchProps {
  fetchAvailableApps: () => void;
}

type Props = StateProps & DispatchProps;

enum ListView {
  Apps,
  AppVersions,
}

interface PrivateAppsContextValue {
  installedApps: PrivateAppData[];
  isLoading: boolean;
  selectedListView: ListView;
  setSelectedListView: (selectedListView: ListView) => void;
}

export const PrivateAppsContext = React.createContext<PrivateAppsContextValue>({
  installedApps: [],
  isLoading: false,
  selectedListView: ListView.Apps,
  setSelectedListView: () => ({}),
});

export default once_(({ util, stateManagement }: any) => {
  const PrivateAppsProviderComponent: React.FC<Props> = ({
    children,
    installedApps,
    isLoading,
    fetchAvailableApps,
  }) => {
    const [selectedListView, setSelectedListView] = useState(ListView.Apps);

    useEffect(() => {
      fetchAvailableApps();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <PrivateAppsContext.Provider
        value={{
          installedApps,
          isLoading,
          selectedListView,
          setSelectedListView,
        }}
      >
        {children}
      </PrivateAppsContext.Provider>
    );
  };

  const hasCodeComponent = (app: AppData) => {
    return (
      app.components &&
      app.components.some((x: DS.AppDataComponent) => x.type === 'CODE_PACKAGE')
    );
  };

  const isAppAvailableForUser = (availableApps: AppData[], app: AppData) => {
    return availableApps.some(
      (anApp) => app.appDefinitionId === anApp.appDefinitionId,
    );
  };

  const {
    getIsLoading,
    getAvailableApps,
    getInstalledPackages,
    getLatestVersions,
  } = stateManagement.editorPlugins.platform.selectors;

  const { fetchAvailableApps } = stateManagement.editorPlugins.platform.actions;

  const mapStateToProps: MapStateToProps<StateProps, {}> = ({
    state,
    dsRead,
  }: any): StateProps => {
    const isLoading = getIsLoading(state);
    const availableAppsForUser = getAvailableApps(state);
    const latestVersions = getLatestVersions(state);
    const installedApps = getInstalledPackages(dsRead);
    const getCodeComponent = (app: AppData): InstalledCodeReusePkg => {
      const codeComponent = app.components?.find(
        (component) => component.type === 'CODE_PACKAGE',
      );

      const { data: codeAppData } = codeComponent || ({} as any);

      return {
        frontEndPackage: codeAppData?.importName,
        backEndPackage: `${codeAppData?.importName}-backend`,
        gridAppId: codeAppData?.gridId,
        id: app.appDefinitionId!,
        name: codeAppData?.importName || app.appDefinitionName,
        version: app.appFields?.installedVersion!,
        canEdit: availableAppsForUser
          ? isAppAvailableForUser(availableAppsForUser, app)
          : false,
      };
    };

    return {
      isLoading,
      installedApps: _(installedApps)
        .sortBy(installedApps, 'appDefinitionName')
        .map((app) => ({
          ...app,
          installStatus: InstallStatusInternal.Installed,
          codeComponent: {
            ...getCodeComponent(app),
            latestVersion: latestVersions[app.appDefinitionId],
          },
        }))
        .filter((installedApp) => hasCodeComponent(installedApp))
        .value(),
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    fetchAvailableApps: () => dispatch(fetchAvailableApps()),
  });

  return util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  )(PrivateAppsProviderComponent);
});
