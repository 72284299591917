import React, { FC, useEffect } from 'react';
import {
  WixCodeLoaderService,
  wixCodeLoaderServiceCreator,
} from '../../services/wixCodeLoaderService';
import { consts } from '@wix/wix-code-consts';
import once_ from 'lodash/once';
import noop_ from 'lodash/noop';
import { LegacyEditorDependencies } from '@wix/wix-code-plugin-contracts';
import { experimentUtils, monitoring } from '@wix/wix-code-common';
import { useEditorLegacyAPIs } from '@wix/wix-code-common-components';
import { AppHost } from '@wix/wix-code-repluggable';

type WixCodeLoaderInitProps = {
  repluggableHost: AppHost;
  id: string;
  wixCodeLoaded: boolean;
  config?: Record<string, any>;
};
type WixCodeLoaderProps = {
  wixCodeLoaderService: WixCodeLoaderService;
  fedopsLogger: LegacyEditorDependencies['util']['fedopsLogger'];
  onLoadCompleted?: any;
  [prop: string]: any;
} & WixCodeLoaderInitProps;

type FiredPromises = {
  [promise: string]: Promise<void>;
};

const WixCodeLoader: FC<WixCodeLoaderProps> = (props) => {
  const { editorAPI } = useEditorLegacyAPIs();
  useEffect(() => {
    const onLoadCompletedCallback = props?.onLoadCompleted || noop_;

    if (editorAPI.wixCode.isLoaded()) {
      return;
    }

    props.wixCodeLoaderService
      .load(editorAPI, props.config || {}, props.repluggableHost)
      .then(function ({ firedPromises }: { firedPromises: FiredPromises }) {
        props.fedopsLogger.interactionEnded(
          props.fedopsLogger.INTERACTIONS.CODE_TOP_BAR_FIRST_INSTALL,
        );
        editorAPI.wixCode.setLoaded();
        Promise.all(Object.values(firedPromises)).then(() => {
          onLoadCompletedCallback(firedPromises);
        });
        if (experimentUtils.isDevModeLoader()) {
          editorAPI.panelManager.updatePanelProps(
            'wixCode.panels.devModeLoaderPanel',
            {
              isDone: true,
            },
          );
        }
      })
      .catch((error) => {
        const errorCode =
          error.errorCode || consts.DEVELOPER_MODE_FAIL_PANEL.ERROR_CODE;
        editorAPI.developerMode.toggle();
        if (experimentUtils.isDevModeLoader()) {
          editorAPI.panelManager.closePanelByName(
            'wixCode.panels.devModeLoaderPanel',
          );
        }
        editorAPI.panelManager.openPanel(
          'savePublish.panels.common.failPanel',
          {
            titleKey: 'WixCode_DeveloperModeFail_Title',
            description: 'SAVE_PUBLISH_ERROR_BODY_DESCRIPTION',
            stepsTitle: 'SAVE_PUBLISH_ERROR_BODY_DESCRIPTION_BOLD',
            steps: [
              'SAVE_PUBLISH_ERROR_OPTION_1',
              'WixCode_DeveloperModeFail_Step2_Text',
              'WixCode_DeveloperModeFail_Step3_Text',
            ],
            helpMessage: 'WixCode_DeveloperModeFail_Learn_More_Label',
            helpLinkMessage: 'WixCode_DeveloperModeFail_Learn_More_Link',
            helpLink: consts.DEVELOPER_MODE_FAIL_PANEL.HELP_LINK,
            errorCode,
          },
        );
        monitoring.captureError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

const WixCodeLoaderCreator = once_(
  (legacyEditorDeps: LegacyEditorDependencies) => {
    const wixCodeLoaderService = wixCodeLoaderServiceCreator(legacyEditorDeps);
    const Loader = (props: WixCodeLoaderInitProps) => {
      return (
        <WixCodeLoader
          {...props}
          wixCodeLoaderService={wixCodeLoaderService}
          fedopsLogger={legacyEditorDeps.util.fedopsLogger}
        />
      );
    };
    return Loader;
  },
);

export default WixCodeLoaderCreator;
