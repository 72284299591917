import React, { useEffect, useState } from 'react';
import {
  i18n,
  I18nextProvider,
  initI18n,
  initOptions,
  useTranslation,
} from '@wix/wix-i18n-config';

const initi18n = async (options: initOptions) => {
  const i18nInstance = initI18n(options);

  await i18nInstance.init();

  return i18nInstance;
};

const TranslationProvider = ({
  children,
  options = { locale: 'en' },
}: {
  children: React.ReactNode;
  options: initOptions;
}) => {
  const [i18nInstance, setI18nInstance] = useState<null | i18n>(null);
  useEffect(() => {
    if (!i18nInstance) {
      initi18n(options).then((initializedI18n) =>
        setI18nInstance(initializedI18n),
      );
    }
  }, [i18nInstance, options]);

  return i18nInstance ? (
    <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>
  ) : null;
};

export { useTranslation, TranslationProvider };
