import { MessageModal, Text } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { ExternalDbsModalContext } from './ExternalDbsModalContext';
import styles from './ExternalDBPanels.scss';
import { BI } from './constants';
import RemoveDbImage from '../../assets/icons/changeConnection.svg';
import { connectExternalDbPanelAction } from '@wix/bi-logger-platform/v2';
import { dataHooks } from './dataHooks';

export interface EditExternalDbWarningModalProps {
  onEditConnector: () => void;
  setStage: Dispatch<SetStateAction<number>>;
}

export const EditExternalDbWarningModal: React.FC<
  EditExternalDbWarningModalProps
> = ({ onEditConnector, setStage }) => {
  const [t] = useTranslation();
  const { biLoggerAPI } = useContext(ExternalDbsModalContext);

  return (
    <MessageModal
      dataHook={dataHooks.externalDbPanels.messageModal.container}
      theme="destructive"
      onCloseButtonClick={() => {
        setStage(0);
      }}
      title={t('WixCode_WarningModal_EditExternalDatabaseConnection_Title')}
      content={
        <div>
          <Text enableEllipsis={false}>
            {t('WixCode_Modal_EditExternalDatabaseConnection_Description')}
          </Text>
          <div className={styles.marginTop}></div>
          <Text>
            {t(
              'WixCode_WarningModal_EditExternalDatabaseConnection_Description2',
            )}
          </Text>
        </div>
      }
      illustration={<RemoveDbImage />}
      primaryButtonText={t(
        'WixCode_WarningModal_EditExternalDatabaseConnection_PrimaryCTA',
      )}
      primaryButtonOnClick={async () => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            action_type: BI.actions.click,
            button_name: BI.buttons.primaryCTA,
            input_name: BI.panels.connectionValue,
          }),
        );
        onEditConnector();
      }}
      secondaryButtonText={t(
        'WixCode_WarningModal_EditExternalDatabaseConnection_SecondaryCTA',
      )}
      secondaryButtonOnClick={() => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            action_type: BI.actions.click,
            button_name: BI.buttons.secondaryCTA,
            input_name: BI.panels.connectionValue,
          }),
        );
        setStage(0);
      }}
    />
  );
};
