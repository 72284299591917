import React from 'react';
import s from '../SpiWizard.scss';
import { Button, Preloader } from '@wix/wix-base-ui';
import { dataHooks } from '../../../consts/dataHooks';
import { useTranslation } from '@wix/wix-i18n-config';
import cx from 'classnames';

export const WizardStep3ActionButtons: React.FC<{
  isConfirmDisabled: boolean;
  showLoader: boolean;
  onConfirm: () => void;
  onClose: () => void;
}> = ({ isConfirmDisabled, onConfirm, showLoader, onClose }) => {
  const [t] = useTranslation();

  return (
    <div className={s.actionButtons}>
      <Button
        className={cx('btn-md', 'btn-confirm-secondary')}
        onClick={onClose}
        dataHook={dataHooks.SPI_WIZARD_STEP_3_CANCEL_BUTTON}
      >
        {t('Wizard_Phase_2_Cancel')}
      </Button>
      <Button
        className={cx(s.marginLeft, 'btn-md')}
        disabled={isConfirmDisabled}
        onClick={onConfirm}
        dataHook={dataHooks.SPI_WIZARD_STEP_3_CREATE_SPI}
      >
        {showLoader ? (
          <Preloader className="tiny light" />
        ) : (
          t('Wizard_Phase_3_Add_Spi')
        )}
      </Button>
    </div>
  );
};
