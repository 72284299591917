import {
  ExperimentsAPI,
  LegacyEditorDependencies,
} from '@wix/wix-code-plugin-contracts';

interface CreateExperimentsApiParams {
  legacyEditorDependencies: LegacyEditorDependencies;
}
export const createExperimentsAPI = ({
  legacyEditorDependencies,
}: CreateExperimentsApiParams): ExperimentsAPI => {
  const { experiment } = legacyEditorDependencies;
  return {
    isOpen: (experimentName: string) => experiment.isOpen(experimentName),
  };
};
