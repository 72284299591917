import { ActionType } from '../actions/types';
import { consts } from '@wix/wix-code-consts';
import concat_ from 'lodash/concat';
import without_ from 'lodash/without';
import uniq_ from 'lodash/uniq';

export type PageEditorReducerState = {
  currentTab: string;
  collapsedItems: string[];
  renaming: string;
};

type PageEditorSetCurrentTab = {
  currentTab: typeof consts.PAGE_TABS;
  type: ActionType.PAGE_EDITOR_SET_CURRENT_TAB;
};
type PageEditorSetCollapsed = {
  collapsed: boolean;
  itemIds: string[];
  type: ActionType.PAGE_EDITOR_SET_COLLAPSED;
};
type PageEditorSetRenaming = {
  renaming: boolean;
  itemId: string;
  type: ActionType.PAGE_EDITOR_SET_RENAMING;
};

type PageEditorReducerAction =
  | PageEditorSetCurrentTab
  | PageEditorSetCollapsed
  | PageEditorSetRenaming;

const initialState: Partial<PageEditorReducerState> = {
  currentTab: consts.PAGE_TABS.PAGE,
  collapsedItems: [],
};

const pageEditorReducer = (
  state = initialState,
  action: PageEditorReducerAction,
) => {
  switch (action.type) {
    case ActionType.PAGE_EDITOR_SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.currentTab,
      };
    case ActionType.PAGE_EDITOR_SET_COLLAPSED:
      const collapsedItems = state.collapsedItems;
      const result = action.collapsed
        ? concat_(collapsedItems, action.itemIds)
        : without_(collapsedItems, ...action.itemIds);
      return {
        ...state,
        collapsedItems: uniq_(result),
      };
    case ActionType.PAGE_EDITOR_SET_RENAMING:
      return {
        ...state,
        renaming: action.renaming ? action.itemId : undefined,
      };

    default:
      return state;
  }
};

export default pageEditorReducer;
