import './additionalSidePane.global.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { CustomScroll } from '@wix/wix-base-ui';

const AdditionalSidePane = ({ SidePane }) => {
  return (
    <div
      className="additional-side-pane-container"
      data-aid="additional-side-pane-container"
    >
      <CustomScroll heightRelativeToParent="100%">
        <SidePane />
      </CustomScroll>
    </div>
  );
};

AdditionalSidePane.propTypes = {
  SidePane: PropTypes.elementType,
};

export default AdditionalSidePane;
