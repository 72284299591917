import { connectWithShell, Shell } from '@wix/wix-code-repluggable';
import {
  ReadOnlyAPI,
  BiLoggerAPI,
  ReadOnlyMode,
  FilesViewAPI,
  CONCURRENT_EDITING_READ_ONLY_MODE,
  CODE_EDITOR_MODE,
  CodeEditorAPI,
} from '@wix/wix-code-plugin-contracts';
import { ReadOnlyModeContext } from '@wix/wix-code-common-components';
import { WixCodeIde } from './components/wixCodeIde/WixCodeIde';
import { WixCodeContextProvider } from '@/infra/wixCodeContext/WixCodeContextProvider';
import { BiLoggerContext } from '@/infra/wixCodeContext/BiLoggerAPIContext';
import { CodeEditorFooter, FooterSlot } from './codeEditorFooter';
import React, { useEffect } from 'react';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { TabRendererSlot } from './components/tabRenderer/TabRenderer';
import { CodeEditorTabContext } from './tabs/components/context/codeEditorTabContext';
import { TopBarItemSlot } from './components/topBar/TopBarItemRenderer';
import { SidebarSlot } from './codeEditorSidebar';
import { AdditionalSidePane } from '@/types/internal-types';

interface CodeEditorProps {
  id?: string;
  additionalSidePane?: AdditionalSidePane;
  readOnly?: boolean;
  codeEditorMode?: CODE_EDITOR_MODE;
  codeIntelligence?: CodeEditorAPI['codeIntelligence'];
}
type ReadOnlyModeProps = {
  readOnlyMode: ReadOnlyMode;
};

export const createCodeIde = (
  shell: Shell,
  readOnlyAPI: ReadOnlyAPI,
  biLoggerAPI: BiLoggerAPI,
  wixCodeEditorAdapter: WixCodeEditorAdapterAPI,
  footerSlot: FooterSlot,
  sidebarSlot: SidebarSlot,
  tabRendererSlot: TabRendererSlot,
  filesViewAPI: FilesViewAPI,
  topBarItemRendererSlot: TopBarItemSlot,
) => {
  const CodeIde = (props: any) => {
    const { readOnly, readOnlyMode } = props;
    useEffect(() => {
      if (readOnly) {
        readOnlyAPI.setReadOnly(
          'CONCURRENT_EDITING',
          CONCURRENT_EDITING_READ_ONLY_MODE,
        );
      } else {
        readOnlyAPI.removeReadOnlyContribution('CONCURRENT_EDITING');
      }
    }, [readOnly]);

    return (
      <WixCodeContextProvider
        legacyDependencies={wixCodeEditorAdapter.legacyDependencies}
      >
        <BiLoggerContext.Provider value={biLoggerAPI}>
          <ReadOnlyModeContext.Provider value={{ readOnlyMode }}>
            <CodeEditorTabContext.Provider
              value={{
                fileNameTransformer: filesViewAPI.fileNameTransformer,
              }}
            >
              <WixCodeIde
                {...props}
                tabRendererSlot={tabRendererSlot}
                topBarItemSlot={topBarItemRendererSlot}
                sidebarSlot={sidebarSlot}
                Footer={() => <CodeEditorFooter footerSlot={footerSlot} />}
              />
            </CodeEditorTabContext.Provider>
          </ReadOnlyModeContext.Provider>
        </BiLoggerContext.Provider>
      </WixCodeContextProvider>
    );
  };

  const ConnectedCodeIde = connectWithShell<
    {},
    CodeEditorProps,
    ReadOnlyModeProps
  >(
    () => {
      return {
        readOnlyMode: readOnlyAPI.getReadOnlyMode(), // todo: add it here
      };
    },
    () => ({}),
    shell,
    {
      renderOutsideProvider: true,
    },
  )(CodeIde);

  return ConnectedCodeIde;
};
