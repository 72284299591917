import {
  MenuItemEntry,
  MenuItemId,
  MenuPanelTab,
} from '@wix/wix-code-plugin-contracts';
import React from 'react';
import s from './litePanel.scss';
import { Menu } from '../menu/menu';
import { LitePanelPrivateAPI } from './litePanelPrivateAPI';

export interface LitePanelProps {
  TabContribution: MenuPanelTab | undefined;
  readOnly: boolean;
  closePanel: () => void;
  currentMenuItem: MenuItemId;
  setMenuItem: LitePanelPrivateAPI['setMenuItem'];
  menuItems: MenuItemEntry[];
  toggleDevMode: () => void;
}

export const LitePanel: React.FC<LitePanelProps> = ({
  readOnly,
  closePanel,
  currentMenuItem,
  setMenuItem,
  menuItems,
  TabContribution,
  toggleDevMode,
}) => (
  <div className={s.panel}>
    <Menu
      menuItems={menuItems}
      selected={currentMenuItem}
      setMenuItem={setMenuItem}
      toggleDevMode={toggleDevMode}
    />
    <div className={s.content}>
      {TabContribution && (
        <TabContribution.TabContent
          readOnly={readOnly}
          key={TabContribution.id}
          closePanel={closePanel}
        />
      )}
    </div>
  </div>
);
