import {
  AvailableCodeReusePkg,
  InstalledCodeReusePkg,
  NpmPackageInfo,
  PkgInfo,
} from './packagesModalContext';
import {
  isInstalledReusePackageByOthers,
  isReusePackage,
  isReusePackageByVelo,
} from './utils';
import biEvents, { BiSenderFunc } from '@/legacy/bi/events';
import { PackagesModalTabId } from './packagesModal/packagesModalConsts';

export const NPM_BI_ITEM_TYPE = 'NPM';
export const CODE_REUSE_BI_ITEM_TYPE = 'CODE_REUSE';
export const npmBi = {
  editorSource: 79,
  events: {
    clickOnPackage: 93,
    clickOnNpmSection: 94,
    clickOnInstallPackage: 144,
    packageInstallFailed: 413,
    clickOnChooseVersionActionInPackagesModal: 145,
    clickInstallVersion: 144,
    clickViewVersion: 145,
    clickOnViewPackage: 145,
    clickOpenReadme: 145,
    clickOnChooseVersionActionInLeftTree: 97,
  },
  origins: {
    builtVersions: 'built_versions',
    mainView: 'package_manager_main_view',
  },
  contextMenuActions: {
    chooseVersion: 'choose_a_version',
  },
  itemTypes: {
    npmPackages: 'npm_packages',
  },
  npmSection: 'npm',
  actions: {
    close: 'close',
  },
};

type ItemType = typeof NPM_BI_ITEM_TYPE | typeof CODE_REUSE_BI_ITEM_TYPE;

const getItemType = (pkg: PkgInfo): ItemType =>
  isReusePackage(pkg.name) ? CODE_REUSE_BI_ITEM_TYPE : NPM_BI_ITEM_TYPE;

const getPkgType = (name: string) =>
  isReusePackageByVelo(name)
    ? 'internal_packages'
    : isInstalledReusePackageByOthers(name)
    ? 'built_by_others'
    : isReusePackage(name)
    ? 'my_packages'
    : 'npm_packages';

export const PackagesBiSender = (sendBi: BiSenderFunc) => {
  const sendQuickActionUpdatePkgBi = (pkg: PkgInfo) => {
    sendBi(biEvents.PKG_QUICK_ACTION_CLICKED, {
      item_name: pkg.name,
      item_version: pkg.version,
      item_type: getItemType(pkg),
      category: 'update_version',
    });
  };

  const sendQuickActionChooseVersionPkgBi = (pkg: PkgInfo) => {
    sendBi(biEvents.PKG_QUICK_ACTION_CLICKED, {
      item_name: pkg.name,
      item_version: pkg.version,
      item_type: getItemType(pkg),
      category: 'choose_a_version',
    });
  };

  const sendQuickActionEditCodePkgBi = (pkg: PkgInfo) => {
    sendBi(biEvents.PKG_QUICK_ACTION_CLICKED, {
      item_name: pkg.name,
      item_type: 'my_packages',
      category: 'open_in_blocks',
    });
  };

  const sendQuickActionUninstallPkgBi = (pkg: PkgInfo, itemType: ItemType) => {
    sendBi(biEvents.PKG_QUICK_ACTION_CLICKED, {
      item_name: pkg.name,
      item_type: itemType,
      category: 'uninstall',
    });
  };

  const sendQuickActionReadMoreBi = (pkg: PkgInfo) => {
    sendBi(biEvents.PKG_QUICK_ACTION_CLICKED, {
      item_name: pkg.name,
      item_type: NPM_BI_ITEM_TYPE,
      category: 'Documentation',
    });
  };

  const sendQuickActionRequestLatestVersionBi = (pkg: PkgInfo) => {
    sendBi(biEvents.PKG_QUICK_ACTION_CLICKED, {
      item_name: pkg.name,
      item_type: NPM_BI_ITEM_TYPE,
      category: 'request_latest_version',
    });
  };

  const sendQuickActionRequestNpmPackageBi = (pkg: PkgInfo) => {
    sendBi(biEvents.PKG_QUICK_ACTION_CLICKED, {
      item_name: pkg.name,
      item_type: NPM_BI_ITEM_TYPE,
      category: 'request_package',
    });
  };

  const sendClickedUninstallNpmPkgBi = (name: string) => {
    sendBi(biEvents.PKG_UNINSTALL_CLICKED, {
      item_name: name,
      item_type: getPkgType(name),
    });
  };

  const sendClickedUninstallCodePkgBi = (pkg: InstalledCodeReusePkg) => {
    sendBi(biEvents.PKG_UNINSTALL_CLICKED, {
      item_name: pkg.name,
      item_version: pkg.version,
      appId: pkg.id,
      item_type: getPkgType(pkg.name),
    });
  };

  const sendClickedUninstallPrivateAppBi = ({
    name,
    version,
    id,
    type,
  }: {
    name: string;
    version: string;
    id: string;
    type: string;
  }) => {
    sendBi(biEvents.PKG_UNINSTALL_CLICKED, {
      item_name: name,
      item_version: version,
      appId: id,
      item_type: type,
    });
  };

  const sendClickedInstallPkgBi = (pkg: PkgInfo, versionOverride?: string) => {
    const realPkg = pkg as AvailableCodeReusePkg | NpmPackageInfo;
    const version =
      '_version' in realPkg ? realPkg._version.version : pkg.version;
    sendClickedInstallVersionPkgBi(version, pkg);
  };

  const isLatestVersion = (
    version: string | undefined,
    packageInfo: NpmPackageInfo,
  ) => {
    return !version ? true : version === packageInfo._tags.latest;
  };

  const sendClickedInstallVersionPkgBi = (version: string, pkg: PkgInfo) => {
    const realPkg = pkg as AvailableCodeReusePkg | NpmPackageInfo;
    const pkgId = 'id' in realPkg ? realPkg.id : '';

    let isNpmPackage = false;
    if ('_tags' in realPkg) {
      isNpmPackage = true;
    }

    const data = {
      appId: pkgId,
      item_name: pkg.name,
      item_version: version,
      item_type: getPkgType(pkg.name),
    };

    if (isNpmPackage) {
      (data as any).is_latest_version = isLatestVersion(
        version,
        realPkg as NpmPackageInfo,
      );
    }
    sendBi(biEvents.PKG_INSTALL_CLICKED, data);
  };
  const sendQuickActionClickedViewPkgBi = (
    pkg: PkgInfo,
    currentTab: PackagesModalTabId,
  ) =>
    sendBi(biEvents.PKG_QUICK_ACTION_CLICKED, {
      item_name: pkg.name,
      item_type: getPkgType(pkg.name),
      category: 'view',
      Menu_entry_name: currentTab,
    });

  return {
    sendQuickActionUpdatePkgBi,
    sendQuickActionEditCodePkgBi,
    sendQuickActionUninstallPkgBi,
    sendQuickActionReadMoreBi,
    sendQuickActionRequestLatestVersionBi,
    sendQuickActionRequestNpmPackageBi,
    sendClickedUninstallNpmPkgBi,
    sendClickedUninstallCodePkgBi,
    sendClickedInstallPkgBi,
    sendClickedInstallVersionPkgBi,
    sendQuickActionClickedViewPkgBi,
    sendClickedUninstallPrivateAppBi,
    sendQuickActionChooseVersionPkgBi,
  };
};
