/*
    The purpose of this service is to wrap logical services with UI operations
*/
import eventServiceCreator from './eventService';
import ideContainerStateService from '@/legacy/core/services/ideContainerStateService';

import once_ from 'lodash/once';

export default once_(({ experiment, platform, util }) => {
  const eventService = eventServiceCreator({ experiment, platform, util });
  function setFocusOnEventHandler() {
    ideContainerStateService.revertMinimize();

    return eventService.setFocusOnEventHandler.apply(eventService, arguments);
  }

  return {
    setFocusOnEventHandler,
    toggleTree: ideContainerStateService.toggleTree,
  };
});
