// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gn3Lxq{color:#3b4057}.gn3Lxq path{fill:currentColor}.gn3Lxq._SnoSe{color:#116dff}.MqO3Ll{padding-bottom:6px}.UYUYLl path{fill:#116dff}.UYUYLl div{color:#116dff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"corvid-tree-prefix-icon": "gn3Lxq",
	"corvidTreePrefixIcon": "gn3Lxq",
	"highlight": "_SnoSe",
	"section-container": "MqO3Ll",
	"sectionContainer": "MqO3Ll",
	"blue-list-item": "UYUYLl",
	"blueListItem": "UYUYLl"
};
export default ___CSS_LOADER_EXPORT___;
