import React from 'react';
import { PanelHeader } from '@wix/wix-base-ui';
import { PanelsAPI } from '@wix/wix-code-plugin-contracts';
import { dataHooks, helpIds } from './consts';

type PanelHeaderProps = {
  label: string;
  panelsAPI: PanelsAPI;
  closePanel: () => void;
};

export const DeveloperToolsPanelHeader: React.FC<PanelHeaderProps> = ({
  label,
  panelsAPI,
  closePanel,
}) => {
  const onOpenHelp = () => {
    panelsAPI.openHelpCenter(helpIds.siteCodeHelpCenter);
  };

  return (
    <PanelHeader
      dataHook={dataHooks.panelHeader}
      onHelp={onOpenHelp}
      onClose={closePanel}
      className="dragger dark"
      defaultCursor
    >
      {label}
    </PanelHeader>
  );
};
