import * as React from 'react';
import packagesServiceCreator from '@/toExtract/packages/packagesService';
import { PkgTreeContext } from './PkgTreeContext';
import { PropsWithChildren, useState } from 'react';

import { usePkgTreeQuickActionHandler } from './pkgTreeQuickActions';
import createPrivatePackagesProvider from '../PrivateAppsProvider';
import { useEditorLegacyAPIs } from '@wix/wix-code-common-components';
import { createPackagesAPI } from '@/toExtract/packages/packagesAPI';
import { useInternalAPIs } from '../packagesTabEntryPoint/contexts';

interface PkgTreeContextWrapperProps {
  showCodeReuse: boolean;
}

export const PkgTreeContextWrapper: React.FC<PkgTreeContextWrapperProps> = ({
  showCodeReuse,
  children,
}: PropsWithChildren<PkgTreeContextWrapperProps>) => {
  const {
    editorAPI,
    legacyDependenciesAPI: { experiment, constants, stateManagement, util },
  } = useEditorLegacyAPIs();
  const { wixCodeDuplexerAPI, duplexerAPI, panelsAPI, wixCodeStoreAPI } =
    useInternalAPIs();
  const isBlocksCombinedMode = experiment.isOpen('se_privateAppsPanel');
  const packagesAPI = createPackagesAPI(
    wixCodeDuplexerAPI,
    panelsAPI,
    wixCodeStoreAPI,
    util.translate,
    editorAPI,
  );

  const [packagesService] = useState(() =>
    packagesServiceCreator({
      experiment,
      constants,
      editorAPI,
      translate: util.translate,
      stateManagement,
      isBlocksCombinedMode,
      packagesAPI,
      wixCodeStoreAPI,
      duplexerAPI,
      panelsAPI,
    }),
  );

  const PrivateAppsProvider = createPrivatePackagesProvider({
    util,
    stateManagement,
  });

  const { actionHandler } = usePkgTreeQuickActionHandler({
    packagesService,
    showCodeReuse,
  });

  const onOpenRemoveNpmPackage = (packageNameToUninstall: string) => {
    const { uninstallPkg } = packagesService;
    uninstallPkg(packageNameToUninstall);
  };

  return (
    <PrivateAppsProvider>
      <PkgTreeContext.Provider
        value={{
          packagesService,
          actionHandler,
          onOpenRemoveNpmPackage,
          isBlocksCombinedMode,
        }}
      >
        {children}
      </PkgTreeContext.Provider>
    </PrivateAppsProvider>
  );
};
