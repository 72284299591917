import * as Sentry from '@sentry/browser';
import get_ from 'lodash/get';
import { getAppVersion } from './version';

const environment = process.env.NODE_ENV;
const defaultIntegrationsToExclude = [
  'GlobalHandlers',
  'TryCatch',
  'Breadcrumbs',
  'ReportingObserver',
  'Dedupe',
];

const initSentry = ({ dsn }) => {
  const sentryOptions = {
    dsn,
    includePaths: [/wix-code-classic-editor/, /wixCodeClassicEditor/],
    release: getAppVersion(),
    environment,
    enabled: environment === 'production',
    integrations: Sentry.defaultIntegrations.filter(
      (integration) => !defaultIntegrationsToExclude.includes(integration.name),
    ),
  };

  const client = new Sentry.BrowserClient(sentryOptions);
  const hub = new Sentry.Hub(client);

  const captureException = (err, extra = {}) => {
    hub.run((currentHub) => {
      currentHub.withScope((scope) => {
        if (extra) {
          Object.keys(extra).forEach((key) => scope.setExtra(key, extra[key]));
        }
        return currentHub.captureException(err);
      });
    });
  };

  try {
    // Hack some editor data
    if (window.editorModel) {
      const { metaSiteId, publicUrl, editorVersion, permissionsInfo } =
        window.editorModel;

      hub.configureScope((scope) => {
        scope.setUser({
          id: get_(permissionsInfo, 'loggedInUserId'),
          roles: get_(permissionsInfo, 'loggedInUserRoles'),
        });

        scope.setTags({
          metaSiteId,
          publicUrl,
          editorVersion,
        });
      });
    }
  } catch (ex) {
    captureException(ex, {
      reason: 'Error while setting santa-editor context',
    });
  }

  return {
    captureException,
  };
};

export { initSentry };
