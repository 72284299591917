import * as React from 'react';
import { connect } from 'react-redux';
import codeStateReader from '@/infra/redux-state/reducers/codeStateReader';
import { bindActionCreators } from 'redux';
import {
  wixCodeReduxContext,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import { nodeDoubleClick } from '@/infra/redux-state/actions/fileTreeActions';
import bi from '@/legacy/bi/bi';
import devContextUtilsCreator from '@/utils/devContext';
import dataHooks from '../../../dataHooks';
import { isSectionExpanded } from '../treeSectionSelectors';
import fileSystemActions from '@/infra/redux-state/actions/fileSystemActions';
import { useEffect, useState } from 'react';
import { FilesTreeEmptyState } from '../FilesTreeEmptyState';
import { SingleFileDivider } from './SingleFileDivider';
import { SingleFileNode } from './SingleFileNode';
import { treeSectionActions } from '../treeSectionReducer';
import { AppState } from '@/infra/redux-state/reducers/rootReducer';

interface SingleFileReduxStateProps {
  file: any;
  parentLoaded: boolean;
  expanded: boolean;
}

interface SingleFileReduxDispatchProps {
  nodeDblClick: (fileId: string) => any;
  createFile: (
    parentId: String,
    childName: string,
    initialContent: string,
  ) => void;
  registerSection: (fileName: string) => void;
}

interface SingleFileProps {
  parentId: string;
  fileName: string;
  id: string;
  initialContent: string;
  fileIsMandatory: boolean;
  translations?: { [key: string]: string };
}

type SingleFileCompProps = SingleFileProps &
  SingleFileReduxStateProps &
  SingleFileReduxDispatchProps;

const SingleFileComp: React.FC<SingleFileCompProps> = ({
  file,
  fileName,
  parentId,
  nodeDblClick,
  expanded,
  id,
  createFile,
  initialContent,
  registerSection,
  fileIsMandatory,
  parentLoaded,
  translations,
}) => {
  const {
    editorAPI,
    legacyDependenciesAPI: { stateManagement, constants },
  } = useEditorLegacyAPIs();
  const { setFileContext } = devContextUtilsCreator({ constants });
  const [isTreeHovered, setTreeHovered] = useState(false);

  const path = `${parentId}${fileName}`;
  useEffect(() => {
    registerSection(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFileSelected = () => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_ITEM, {
      item_name: path,
      item_type: 'file',
    });
    editorAPI.panelManager.closeAllPanels();
    setFileContext(editorAPI, path);
    editorAPI.store.dispatch(
      stateManagement.editorPlugins.wixCodeIDE.actions.showIDEWithDsRead({
        dsRead: editorAPI.dsRead,
      }),
    );
    editorAPI.developerMode.ui.idePane.unMinimize();
  };

  const emptyState = (
    <FilesTreeEmptyState
      resource={id}
      onQuickAddItemClick={() => {
        createFile(parentId, fileName, initialContent);
        onFileSelected();
      }}
      translations={translations}
    />
  );

  return (
    <div
      data-hook={dataHooks.FILE_SECTION_ROOT(id)}
      onMouseEnter={() => setTreeHovered(true)}
      onMouseLeave={() => setTreeHovered(false)}
      className="section-container"
    >
      <SingleFileDivider
        alwaysShowSuffix={isTreeHovered || !parentLoaded}
        id={id}
        expanded={expanded}
        loaded={parentLoaded}
        path={path}
        translations={translations}
      />
      {expanded &&
        parentLoaded &&
        (file ? (
          <SingleFileNode
            file={file}
            nodeDblClick={nodeDblClick}
            fileIsMandatory={fileIsMandatory}
            path={path}
            onFileSelected={onFileSelected}
          />
        ) : (
          emptyState
        ))}
    </div>
  );
};

const mapStateToProps = (
  state: AppState,
  { fileName, parentId }: SingleFileProps,
): SingleFileReduxStateProps => {
  const path = `${parentId}${fileName}`;
  const parent = codeStateReader.getFileSystemEntry(state, parentId);
  const parentLoaded = parent?.loaded;
  const file = codeStateReader.getFileSystemEntry(state, path);

  const expanded = isSectionExpanded(state, path);
  return { file, expanded, parentLoaded };
};

const mapDispatchToProps = (dispatch: AnyFixMe): SingleFileReduxDispatchProps =>
  bindActionCreators(
    {
      nodeDblClick: nodeDoubleClick,
      createFile: fileSystemActions.createFile,
      registerSection: treeSectionActions.registerSingleFileSection,
    },
    dispatch,
  );

export const SingleFile: React.FC<SingleFileProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true,
    context: wixCodeReduxContext,
  },
)(SingleFileComp);
