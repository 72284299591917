import React, { useContext, useEffect, useState } from 'react';
import {
  Composites,
  CustomModal,
  Preloader,
  RadioButtonsWithIllustration,
  TextButton,
} from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import { ExternalDbsModalContext } from '../ExternalDbsModalContext';
import styles from '../ExternalDBPanels.scss';
import { AddExternalDb } from '../AddExternalDb/AddExternalDb';
import { CreateExternalDb } from '../CreateExternalDb/CreateExternalDb';
import { dataHooks } from '../dataHooks';
import { getCloudDbs } from '../../../WixDataServerAPI';
import { BI, getExternalDbsMainScreenOptions } from '../constants';
import { State } from '@wix/ambassador-kore-provision-v1-database-deployment/types';
import {
  connectExternalDbPanelAction,
  panelOpened,
} from '@wix/bi-logger-platform/v2';

export enum ExternalDbFlow {
  CreateNewDb = 'CREATE_NEW_DB',
  ConnectExistingDb = 'CONNECT_EXISTING_DB',
}

export const AddExternalDbMainScreen: React.FC = () => {
  const [t] = useTranslation();
  const { panelsAPI, wixCodeAppAPI, biLoggerAPI } = useContext(
    ExternalDbsModalContext,
  );

  const [userConfirmed, setUserConfirmed] = useState<boolean>(false);

  const [isCloudDbLimitReached, setIsCloudDbLimitReached] = useState<boolean>(
    null as any,
  );

  const [selectedOption, setSelectedOption] = useState<ExternalDbFlow>(
    null as any,
  );

  useEffect(() => {
    biLoggerAPI.report(panelOpened({ panel_name: BI.panels.mainModal }));
    const updateStateWithCloudDbs = async () => {
      try {
        const cloudDbs = await getCloudDbs(wixCodeAppAPI.getSignedInstance);
        if (
          cloudDbs.filter(
            (db) =>
              db.state !== State.DELETION_IN_PROGRESS &&
              db.state !== State.DELETED,
          ).length
        ) {
          setIsCloudDbLimitReached(true);
          setSelectedOption(ExternalDbFlow.ConnectExistingDb);
        } else {
          setIsCloudDbLimitReached(false);
          setSelectedOption(ExternalDbFlow.CreateNewDb);
        }
      } catch (e) {
        setIsCloudDbLimitReached(false);
      }
    };
    updateStateWithCloudDbs();
  }, [wixCodeAppAPI, biLoggerAPI]);

  const onSelectFlow = () => {
    setUserConfirmed(true);
  };

  if (userConfirmed) {
    return selectedOption === ExternalDbFlow.CreateNewDb ? (
      <CreateExternalDb />
    ) : (
      <AddExternalDb />
    );
  }

  return (
    <CustomModal
      title={t('External_Databases.Modal.ChooseCreateOrConnect.Title')}
      onCloseButtonClick={() => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: BI.panels.mainModal,
            action_type: BI.actions.click,
            button_name: BI.buttons.x,
          }),
        );
        panelsAPI.closePanel();
      }}
      primaryButtonOnClick={() => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: BI.panels.mainModal,
            action_type: BI.actions.click,
            button_name: BI.buttons.next,
            selected_flow:
              selectedOption === ExternalDbFlow.CreateNewDb
                ? BI.flows.createNew
                : BI.flows.addExisting,
          }),
        );
        onSelectFlow();
      }}
      secondaryButtonOnClick={() => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: BI.panels.mainModal,
            action_type: BI.actions.click,
            button_name: BI.buttons.cancel,
          }),
        );
        panelsAPI.closePanel();
      }}
      primaryButtonTooltipContent={t(
        'External_Databases.Modal.ChooseCreateOrConnect.NoSelectionTooltip',
      )}
      primaryButtonText={t(
        'External_Databases.Modal.ChooseCreateOrConnect.PrimaryCTA',
      )}
      primaryButtonProps={{
        disabled: !selectedOption,
        dataHook: dataHooks.externalDbPanels.primaryButton,
      }}
      secondaryButtonText={t(
        'External_Databases.Modal.ChooseCreateOrConnect.SecondaryCTA',
      )}
      dataHook={dataHooks.externalDbPanels.mainScreen.container}
      sideActions={
        isCloudDbLimitReached === null ? null : (
          <TextButton
            size="small"
            onClick={() =>
              window.open(
                t(
                  'External_Databases.Modal.ChooseCreateOrConnect.LearnMore.Link',
                ),
                '_blank',
              )
            }
          >
            {t('External_Databases.Modal.ChooseCreateOrConnect.LearnMore.Text')}
          </TextButton>
        )
      }
    >
      <Composites.RadioButtonsWithIllustration
        className={styles.mainModalContainer}
      >
        {isCloudDbLimitReached === null ? (
          <div className={styles.loaderContainer}>
            <Preloader className="large" />
          </div>
        ) : (
          <RadioButtonsWithIllustration
            options={getExternalDbsMainScreenOptions(isCloudDbLimitReached, t)}
            value={selectedOption}
            onChange={(value: ExternalDbFlow) => setSelectedOption(value)}
            dataHook={dataHooks.externalDbPanels.mainScreen.flowSelector}
          />
        )}
      </Composites.RadioButtonsWithIllustration>
    </CustomModal>
  );
};
