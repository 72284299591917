import React, { useContext } from 'react';
import s from './PkgControlsSymbol.scss';
import { Tooltip, RichText, Symbol as BaseUiSymbol } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { PackagesModalContext } from '../../../../packagesModalContext';
import { isModulePending } from '../../../../utils';
import { PackageItemContext } from '../PackageItemContext';

export const PkgControlsSymbol: React.FC = () => {
  const { t } = React.useContext(PackagesModalContext);
  const { availablePkg } = useContext(PackageItemContext);

  const requestState = isModulePending(availablePkg) ? 'Pending' : 'Rejected';
  return (
    <Tooltip
      interactive={true}
      automationId={
        isModulePending(availablePkg)
          ? 'npm-item-pending-tooltip'
          : 'npm-item-rejected-tooltip'
      }
      closeOnContentMouseClick={true}
      marginLeft={17}
      marginRight={17}
      content={
        <div className={s.tooltipContent}>
          <RichText
            type="T06"
            automationId="pending-request-tooltip-title"
            className={s.tooltipElement}
          >
            {t(
              `Package_Manager_Popup_Available_Tab_${requestState}_Request_Tooltip_Title`,
            )}
          </RichText>
          <RichText type="T07" className={s.tooltipElement}>
            {t(
              `Package_Manager_Popup_Available_Tab_${requestState}_Request_Tooltip_Description`,
            )}
          </RichText>
          <RichText>
            <a
              data-aid="all-packages-link-tooltip"
              href={t(
                'Package_Manager_Popup_Available_Tab_Rejected_Request_Tooltip_Link_Url',
              )}
              target="_blank"
              rel="noreferrer"
            >
              {t(
                `Package_Manager_Popup_Available_Tab_${requestState}_Request_Tooltip_Link`,
              )}
            </a>
          </RichText>
        </div>
      }
    >
      <BaseUiSymbol>
        {isModulePending(availablePkg) ? (
          <Symbol className={s.symbolIcon} name="corvid_npm__pending" />
        ) : (
          <Symbol className={s.symbolIcon} name="corvid_npm__rejected" />
        )}
      </BaseUiSymbol>
    </Tooltip>
  );
};
