import { GithubPublicAPI } from '@wix/wix-code-plugin-contracts';
import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { ExternalGithubAPIKey } from './externalApiKeys';

export type ExternalGithubAPI = {
  setFileStructureType: GithubPublicAPI['setFileStructureType'];
  isGithubEnabled: GithubPublicAPI['isGithubEnabled'];
};

export const ExternalGithubEntryPoint: EntryPoint = {
  name: 'External Github',
  getDependencyAPIs() {
    return [APIKeys.GithubPublicAPI];
  },
  declareAPIs() {
    return [ExternalGithubAPIKey];
  },
  attach(shell: Shell) {
    shell.contributeAPI(ExternalGithubAPIKey, () => {
      const { setFileStructureType, isGithubEnabled } = shell.getAPI(
        APIKeys.GithubPublicAPI
      );
      return {
        setFileStructureType,
        isGithubEnabled,
      };
    });
  },
};

export {
  SetFileStructureType,
  FileStructureTypeEnum,
} from '@wix/wix-code-plugin-contracts';
