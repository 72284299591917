import * as codeEditor from '@wix/wix-code-code-editor';
import _ from 'lodash';
import errors from '@/legacy/core/errors';
import fileSystemActions from '@/infra/redux-state/actions/fileSystemActions.ts';
import fileActions from '@/infra/redux-state/actions/fileActions';
import codeStateReader from '@/infra/redux-state/reducers/codeStateReader';
import storeManager from '@/infra/redux-state/store/storeManager';
import { utilsCreator as wixCodeUtilsCreator } from '@wix/wix-code-common';
import ideContainerStateService from '@/legacy/core/services/ideContainerStateService';
import { consts as codeCoreConstants } from '@wix/wix-code-consts';
import pageActionsCreator from '@/infra/redux-state/actions/pageActions';
import devContextUtilsCreator from '@/utils/devContext';

import once_ from 'lodash/once';

export default once_(({ constants, experiment, platform, util }) => {
  const pageActions = pageActionsCreator({ experiment, platform, util });
  const wixCodeUtils = wixCodeUtilsCreator({ experiment, platform, util });
  const { setMasterPageContext } = devContextUtilsCreator({
    constants,
  });

  function expandFolderOnTreeNode(folderName) {
    const store = storeManager.getStore();
    return store.dispatch(fileSystemActions.expand(folderName));
  }

  function goToFileLine(filePath, caretOffset) {
    const store = storeManager.getStore();

    return store.dispatch(
      fileActions.setSelection(filePath, {
        start: caretOffset,
        end: caretOffset,
      }),
    );
  }

  function goToFileSelection(filePath, selection) {
    const store = storeManager.getStore();

    return store.dispatch(fileActions.setSelection(filePath, selection));
  }

  function _hasNavigationTargetParam(options) {
    return (
      options.functionName ||
      options.caretLocation ||
      options.position ||
      options.selection
    );
  }

  function navigateToLocation(filePath, options) {
    if (!filePath) {
      throw new errors.ArgumentError(
        'filePath',
        'pageTreeHandlerService.goToFileLine',
        filePath,
      );
    }

    if (!_hasNavigationTargetParam(options)) {
      return;
    }

    const store = storeManager.getStore();

    if (options.selection) {
      return goToFileSelection(filePath, options.selection);
    }

    if (options.caretLocation) {
      return goToFileLine(filePath, options.caretLocation);
    }

    if (options.position) {
      return store.dispatch(fileActions.loadFile(filePath)).then(function () {
        goToFileLine(filePath, options.position);
      });
    }

    return store
      .dispatch(fileActions.loadFile(filePath))
      .then(function (readFileContent) {
        let caretInformation;
        try {
          caretInformation =
            codeEditor.codeExportsService.getCaretInfoForExportStatement(
              readFileContent,
              options.functionName,
            );
        } catch (e) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('cannot navigate due to code error');
        }

        if (caretInformation.found) {
          goToFileLine(filePath, caretInformation.caretLocation);
        }
      });
  }

  function getFolderName(editorAPI, filePath) {
    return _(editorAPI.wixCode.fileSystem.getRoots())
      .map('location')
      .find(function (loc) {
        return _.startsWith(filePath, loc);
      });
  }

  function isPageFile(editorAPI, filePath) {
    const roots = editorAPI.wixCode.fileSystem.getRoots();
    const pagesFolder = roots.pages.location;

    return _.startsWith(filePath, pagesFolder);
  }

  function isMasterPageFile(pageId) {
    return pageId === codeCoreConstants.SITE_JS_PAGE_ID;
  }

  function navigateToPageFile(editorAPI, pageId) {
    return new Promise((resolve) => {
      editorAPI.pages.navigateTo(pageId, resolve);
    }).then(() => {
      ideContainerStateService.revertMinimize();
      const store = storeManager.getStore();
      store.dispatch(
        pageActions.setCurrentTab(codeCoreConstants.PAGE_TABS.PAGE),
      );
    });
  }

  function navigateToMasterPageFile(editorAPI) {
    setMasterPageContext(editorAPI);
    ideContainerStateService.revertMinimize();
    return Promise.resolve();
  }

  function navigateToCodeFile(editorAPI, filePath) {
    const folderName = getFolderName(editorAPI, filePath);
    return expandFolderOnTreeNode(folderName).then(() => {
      const store = storeManager.getStore();

      const entry = codeStateReader.getFileSystemEntry(
        store.getState(),
        filePath,
      );
      if (!entry) {
        throw new errors.FileSystemError(`The file ${filePath} does not exist`);
      }

      const fileDescriptor = entry.descriptor;

      editorAPI.panelManager.closeAllPanels();
      const context = editorAPI.developerMode.createContext(
        constants.DEVELOPER_MODE.CONTEXT_TYPES.FILE,
        _.assign({ id: filePath }, fileDescriptor),
      );
      editorAPI.developerMode.setContext(context);
    });
  }

  function navigateByFileType(editorAPI, filePath) {
    if (isPageFile(editorAPI, filePath)) {
      const pageId = wixCodeUtils.getPageIdFromFileId(filePath);

      if (isMasterPageFile(pageId)) {
        return navigateToMasterPageFile(editorAPI);
      }
      return navigateToPageFile(editorAPI, pageId);
    }

    return navigateToCodeFile(editorAPI, filePath);
  }

  function navigateToFile(editorAPI, filePath, options) {
    if (!filePath) {
      throw new errors.ArgumentError(
        'filePath',
        'pageTreeHandlerService.setFocusOnBackedFileLine',
        filePath,
      );
    }

    return navigateByFileType(editorAPI, filePath)
      .then(() => navigateToLocation(filePath, options || {}))
      .then(() => {
        editorAPI.developerMode.ui.idePane.unMinimize();
        return editorAPI.wixCode.codeEditorApi.performActionOnMount(
          filePath,
          (api) => api && api.focus(),
        );
      });
  }

  return {
    navigateToFile,
    expandFolder: expandFolderOnTreeNode,
  };
});
