import s from './NpmPkgsList.scss';
import React from 'react';
import classNames from 'classnames';
import { Divider, RichText, Search } from '@wix/wix-base-ui';
import PackageListView from './NpmPackageListView/NpmPackageListView';
import {
  NpmPackageInfo,
  PackagesModalContext,
} from '../../../../../../packagesModalContext';
import dataHooks from '../../../../../../dataHooks';
import SearchNotFound from '../../../../../../icons/search_not_found.svg';
import { ListSectionTitle } from '../../../listSectionTitle/ListSectionTitle';

const learnMoreUrl =
  'https://support.wix.com/en/article/corvid-managing-external-code-libraries-with-the-package-manager';

interface NpmPkgsListProps {
  searchKeyword: string;
  onSearch: Function;
  indexSearchIsFetching: boolean;
  defaultNpmPackages: NpmPackageInfo[];
  selectedPackage: string;
}

export const NpmPkgsList: React.FunctionComponent<NpmPkgsListProps> = ({
  searchKeyword,
  onSearch,
  indexSearchIsFetching,
  defaultNpmPackages,
}) => {
  const { availablePkgs, t } = React.useContext(PackagesModalContext);
  return (
    <div className={s.availableTab} data-aid="available-tab-wrapper">
      <Search
        value={searchKeyword}
        placeholder={t('Package_Manager_Popup_Search_Placeholder_Text')}
        onChange={onSearch}
        options={[]}
        onClear={() => onSearch('')}
        className={s.searchInput}
        dataHook={dataHooks.NPM_SEARCH_BOX_INPUT}
      />

      <div className={s.availableModulesListNpmSearch}>
        {availablePkgs.npm.length !== 0 && (
          <PackageListView
            modules={availablePkgs.npm}
            searchKeyword={searchKeyword}
          />
        )}
        {!indexSearchIsFetching &&
          availablePkgs.npm.length === 0 &&
          searchKeyword.length !== 0 && (
            <>
              <Divider long={true} />
              <div className={s.noResultWrapper}>
                <SearchNotFound />
                <RichText type="T09" automationId="no-search-results-header">
                  {t('Package_Manager_Popup_Search_NoResults_Heading')}
                </RichText>
                <RichText
                  type="T07"
                  className={classNames('light', s.emptyResultsSubheader)}
                  automationId="no-search-results-sub-header"
                >
                  {t('Package_Manager_Popup_Search_NoResults_SubHeading', {
                    search_input: searchKeyword,
                  })}
                </RichText>
                <RichText
                  className={s.noResultsTitle}
                  automationId="no-search-results-link"
                >
                  <a
                    data-aid="no-search-results-learn-more-link"
                    target="_blank"
                    rel="noreferrer"
                    href={learnMoreUrl}
                  >
                    {t('Package_Manager_Popup_Search_NoResults_Link')}
                  </a>
                </RichText>
              </div>
            </>
          )}
        {searchKeyword.length === 0 && (
          <>
            <Divider long={true} />
            <ListSectionTitle title="Package_Manager_Popup_Top_NPM_Packages" />
            <Divider long={true} />
            <div>
              <RichText type="T02" className={s.sectionSubTitle}>
                {t('Package_Manager_Popup_Top_NPM_Packages_Text')}
              </RichText>
            </div>
            <div>
              <PackageListView modules={defaultNpmPackages} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
