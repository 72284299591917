import './topBar.global.scss';
import React from 'react';
import ideContainerStateService from '@/legacy/core/services/ideContainerStateService';
import cx from 'classnames';
import SizingButtons from '@/legacy/core/components/toolbarButton/sizingButtons/sizingButtons';
import ToolbarButton from '@/legacy/core/components/toolbarButton/toolbarButton';
import { MoreDropdown } from '@/legacy/core/components/toolbarButton/moreDropDown/moreDropDown';
import { IdeTabsContainer } from '../../tabs/components/ideTabsContainer/IdeTabsContainer';
import devContextUtilsCreator from '@/utils/devContext';

import { AdditionalSidePane } from '@/types/internal-types';
import { WixCodeTheme } from '../../constants/themes';
import wixCodeActions from '@/infra/redux-state/actions/wixCodeActions';
import userPreferenceUtilCreator from '@/utils/userPreference';
import { userPreferencesKeys } from '../../constants/userPreferenceKeys';
import bi from '@/legacy/bi/bi';
import {
  BiThemeEventName,
  ideThemeSelect,
  panelToggledFromCodePanelTopbar_141,
} from '@/legacy/bi/biEvents';
import { RunCodeButton } from '@/legacy/core/components/toolbarButton/runCodeButton/RunCodeButton';
import {
  useEditorLegacyAPIs,
  connectToStores,
} from '@wix/wix-code-common-components';
import { useTranslate } from '@/infra/i18n/useTranslate';
import { FormatButton } from '@/legacy/core/components/toolbarButton/formatButton/formatButton';
import { AppState } from '@/infra/redux-state/reducers/rootReducer';
import { CODE_EDITOR_MODE } from '@wix/wix-code-plugin-contracts';
import { TopBarItemRenderer, TopBarItemSlot } from './TopBarItemRenderer';
import { DealerOfferGUID } from '@/infra/dealer/dealerService';
import { experimentUtils } from '@wix/wix-code-common';

interface TopBarReduxStateProps {
  theme: WixCodeTheme;
}

interface TopBarEditorResuxStateProps {
  containerState: string;
  shouldShowPropertiesPanel: boolean;
  isPageElementsPanelEnabled: boolean;
  openHelpCenter: (articalId: string) => void;
  sendBi: (event: any) => void;
  siteId: string;
  developerToolBarToggle: () => void;
  selectedFileName: string;
}

interface TopBarReduxReduceProps {
  setTheme: (theme: WixCodeTheme) => void;
}

export interface TopBarProps {
  onToggleSize: (size: 'open' | 'close') => void;
  focusIde: () => void;
  onToolbarAction: (action: string) => void;
  onOpenHelp: () => void;
  onSeeAllShortcuts: () => void;
  additionalSidePane: AdditionalSidePane;
  automationId: string;
  showPageElementsToggle: boolean;
  showMaximize: boolean;
  showMinimize: boolean;
  /** @default CODE_EDITOR_MODE.CODE_AND_STAGE */
  codeEditorMode?: CODE_EDITOR_MODE;
  topBarItemSlot?: TopBarItemSlot;
  hiddenDealerOffers?: DealerOfferGUID[];
}

type TopBarCompProps = TopBarProps &
  TopBarEditorResuxStateProps &
  TopBarReduxReduceProps &
  TopBarReduxStateProps;

const TopBarComp: React.FC<TopBarCompProps> = ({
  containerState,
  onToggleSize,
  focusIde,
  onToolbarAction,
  onOpenHelp,
  onSeeAllShortcuts,
  shouldShowPropertiesPanel,
  additionalSidePane,
  automationId,
  isPageElementsPanelEnabled,
  showPageElementsToggle,
  showMaximize,
  openHelpCenter,
  showMinimize,
  sendBi,
  siteId,
  developerToolBarToggle,
  codeEditorMode = CODE_EDITOR_MODE.CODE_AND_STAGE,
  theme,
  setTheme,
  topBarItemSlot,
  hiddenDealerOffers,
  selectedFileName,
}) => {
  const {
    editorAPI,
    legacyDependenciesAPI: { util, baseUI, constants, stateManagement },
  } = useEditorLegacyAPIs();
  const { t } = useTranslate();

  const CONTAINER_STATES = constants.DEVELOPER_MODE.CONTAINER_STATES;

  const onTopBarClick = () => {
    if (containerState === CONTAINER_STATES.MAXIMIZED) {
      return;
    }

    if (containerState === CONTAINER_STATES.MINIMIZED) {
      ideContainerStateService.revertMinimize();
      onToggleSize('open');
      focusIde();
    } else {
      ideContainerStateService.minimize();
      onToggleSize('close');
    }
  };

  const openHelp = () => {
    onOpenHelp();
    onToolbarAction('help');
  };

  const seeAllShortcuts = () => {
    onSeeAllShortcuts();
    onToolbarAction('see_all_shortcuts');
  };

  const togglePageElementsPanel = () => {
    sendBi({
      def: bi.events.PROPERTIES_PANEL_TOGGLE,
      params: panelToggledFromCodePanelTopbar_141({
        siteId,
        willOpen: !isPageElementsPanelEnabled,
      }),
    });
    if (containerState === CONTAINER_STATES.MINIMIZED) {
      if (!isPageElementsPanelEnabled) {
        developerToolBarToggle();
      }
      ideContainerStateService.revertMinimize();
    } else {
      developerToolBarToggle();
    }
  };

  const { setSitePreference } = userPreferenceUtilCreator({
    stateManagement,
  });

  const updateThemePreference = (themeToUpdate: WixCodeTheme) =>
    setSitePreference(
      editorAPI,
      userPreferencesKeys.USER_PREFERENCE_THEME_KEY,
      themeToUpdate,
    );

  const getMoreDropDownActions = () => {
    return [
      {
        label: t('WixCode_CodePanel_TopBar_Help'),
        onClick: openHelp,
      },
      {
        label: t('WixCode_CodePanel_TopBar_Shortcuts'),
        onClick: seeAllShortcuts,
      },
    ];
  };

  const getThemeActions = () => {
    const getThemeAction = (
      selectedTheme: WixCodeTheme,
      label: string,
      biThemeName: BiThemeEventName,
    ) => ({
      label,
      value: selectedTheme,
      onClick: () => {
        setTheme(selectedTheme);
        updateThemePreference(selectedTheme);
        sendBi({
          def: bi.events.IDE_THEME_SELECT,
          params: ideThemeSelect(biThemeName),
        });
      },
    });

    return [
      getThemeAction(
        WixCodeTheme.LIGHT,
        util.translate('WixCode_Theme_Label_Light'),
        BiThemeEventName.LIGHT_SELECTED,
      ),
      getThemeAction(
        WixCodeTheme.DARK,
        util.translate('WixCode_Theme_Label_Dark'),
        BiThemeEventName.DARK_SELECTED,
      ),
    ];
  };

  const additionalSidePaneIsVisible =
    additionalSidePane && additionalSidePane.isVisible;
  const isVeloLite = experimentUtils.isLiteEditor();

  return (
    <div className="wix-code-top-bar" data-aid={automationId}>
      <div
        className={cx('tool-bar', isVeloLite ? 'vertical' : 'horizontal')}
        onClick={() => onTopBarClick()}
      >
        {isVeloLite ? (
          <div className="selected-file-wrapper">
            <div className="selected-file-caption">{selectedFileName}</div>
          </div>
        ) : (
          <IdeTabsContainer
            codeEditorMode={codeEditorMode}
            hiddenDealerOffers={hiddenDealerOffers}
          />
        )}
        <div className="actions-container" onClick={(e) => e.stopPropagation()}>
          {!isVeloLite && additionalSidePane && (
            <ToolbarButton
              id="additional-side-pane-button"
              symbolName={additionalSidePane.buttonIconName}
              className={cx({
                toggled:
                  additionalSidePaneIsVisible &&
                  containerState !== CONTAINER_STATES.MINIMIZED,
                disabled: !shouldShowPropertiesPanel,
              })}
              onClick={() => {
                if (shouldShowPropertiesPanel) {
                  if (containerState === CONTAINER_STATES.MINIMIZED) {
                    ideContainerStateService.revertMinimize();

                    if (!additionalSidePaneIsVisible) {
                      additionalSidePane.onVisibilityChange(true);
                    }
                  } else {
                    additionalSidePane.onVisibilityChange(
                      !additionalSidePaneIsVisible,
                    );
                  }

                  if (isPageElementsPanelEnabled) {
                    togglePageElementsPanel();
                  }
                }
              }}
              tooltipData={{
                presenter: baseUI.popoverTemplates.titleBodyAndLinkTooltip,
                id: 'additionalSidePaneButton',
                interactive: true,
                props: additionalSidePane.buttonTooltipDataProps,
              }}
            />
          )}

          {!isVeloLite && topBarItemSlot && (
            <TopBarItemRenderer topBarItemSlot={topBarItemSlot} />
          )}

          {!isVeloLite && codeEditorMode !== CODE_EDITOR_MODE.CODE_ONLY && (
            <RunCodeButton />
          )}
          <FormatButton />
          {!isVeloLite && showPageElementsToggle && (
            <ToolbarButton
              id="toggle-elements-panel-button"
              symbolName="toggle_page_elements"
              className={cx({
                toggled:
                  !additionalSidePaneIsVisible &&
                  isPageElementsPanelEnabled &&
                  containerState !== CONTAINER_STATES.MINIMIZED,
                disabled: !shouldShowPropertiesPanel,
                'toggle-page-elements-button': true,
              })}
              onClick={() => {
                if (shouldShowPropertiesPanel) {
                  if (additionalSidePaneIsVisible) {
                    additionalSidePane.onVisibilityChange(false);
                    if (!isPageElementsPanelEnabled) {
                      togglePageElementsPanel();
                    }
                  } else {
                    togglePageElementsPanel();
                  }
                }
              }}
              tooltipData={{
                presenter: baseUI.popoverTemplates.titleBodyAndLinkTooltip,
                id: 'wixCodeTogglePageElementsPanelButton',
                interactive: !shouldShowPropertiesPanel,
                props: {
                  text: shouldShowPropertiesPanel
                    ? 'WixCode_PropertiesAndEventsPanel_Title_Label'
                    : 'WixCode_PropertiesAndEventsPanel_Title_Disabled',
                  link: shouldShowPropertiesPanel
                    ? undefined
                    : 'WixCode_PropertiesAndEventsPanel_Title_Disabled_Link',
                  linkAction: () => {
                    if (!shouldShowPropertiesPanel) {
                      openHelpCenter('2ec37118-35d1-4068-a491-7640c1481aa1');
                    }
                  },
                },
              }}
              disabled={false}
            />
          )}

          {!isVeloLite && (
            <>
              <SizingButtons
                containerStateService={ideContainerStateService}
                containerState={containerState}
                showMaximize={showMaximize}
                showMinimize={showMinimize}
                onAction={focusIde}
              />
              <MoreDropdown
                actions={getMoreDropDownActions()}
                themeActions={getThemeActions()}
                selectedTheme={theme}
                sendBi={sendBi}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapEditorStateToProps =
  (constants: AnyFixMe) =>
  ({ editorAPI }: AnyFixMe): TopBarEditorResuxStateProps => {
    const {
      isPageContext,
      isMasterPageContext,
      isCssFileContext,
      getFileNameFromFileId,
    } = devContextUtilsCreator({
      constants,
    });
    const getSelectedFileName = () => {
      const context = editorAPI.developerMode.getContext();
      return isPageContext(editorAPI)
        ? context.data.displayName
        : getFileNameFromFileId(context.data.id);
    };
    return {
      shouldShowPropertiesPanel:
        isPageContext(editorAPI) ||
        isMasterPageContext(editorAPI) ||
        isCssFileContext(editorAPI),
      containerState: editorAPI.developerMode.ui.idePane.getState(),
      developerToolBarToggle: editorAPI.developerToolBar.toggle,
      isPageElementsPanelEnabled: editorAPI.developerToolBar.isEnabled(),
      openHelpCenter: editorAPI.panelManager.openHelpCenter,
      siteId: editorAPI.dsRead.generalInfo.getSiteId(),
      sendBi: (event) => editorAPI.bi.event(event.def, event.params),
      selectedFileName: getSelectedFileName(),
    };
  };

const mapCodeDispatchToProps = (
  dispatch: AnyFixMe,
): TopBarReduxReduceProps => ({
  setTheme: (theme: WixCodeTheme) => dispatch(wixCodeActions.setTheme(theme)),
});

const mapCodeStateToProps = (state: AppState) => {
  const theme = state.ide.theme;

  return {
    theme,
  };
};

let ConnectedComponent = null as any;
const TopBarConnected: React.FC<TopBarProps> = (origProps) => {
  const {
    legacyDependenciesAPI: { util, constants },
  } = useEditorLegacyAPIs();
  if (ConnectedComponent === null) {
    ConnectedComponent = connectToStores({
      util,
      mapEditorStateToProps: mapEditorStateToProps(constants),
      mapCodeDispatchToProps,
      mapCodeStateToProps,
      comp: TopBarComp,
    });
  }
  return <ConnectedComponent {...origProps} />;
};

export const TopBar = TopBarConnected;
