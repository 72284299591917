import bi from '@/legacy/bi/bi';
import { ActionType } from './types';
import ideTreeConstants from '@/legacy/core/components/ideTree/constants';
import { tabs, getCodeFilesTab } from '@/sidebar/tabs';
import {
  isPageFile,
  isCssFile,
  getPageIdFromFileId,
} from '@wix/wix-code-common';

function getLeftPaneTabFor(editorAPI, fileId) {
  function pageToTabMapper(pageId) {
    const pageRef = editorAPI.pages.getReference(pageId);

    switch (editorAPI.dsRead.appStudio?.getEntityByPage?.(pageRef)) {
      case 'PANEL':
      case 'CONFIGURATION':
      case 'WIDGET':
        return 'widgets';
      case 'DASHBOARD':
        return 'dashboardPage';
      default:
        return undefined;
    }
  }

  if (isPageFile(editorAPI, fileId)) {
    if (pageToTabMapper) {
      const pageId = getPageIdFromFileId(fileId);
      const tabFromMapper = pageToTabMapper(pageId);
      if (tabFromMapper) {
        return tabFromMapper;
      }
    }
    return tabs.PAGES;
  }

  if (isCssFile(fileId)) {
    return tabs.PAGES;
  }

  return getCodeFilesTab();
}

export default {
  setLeftPaneViewMode:
    (viewMode, biParams) => (dispatch, _getState, editorAPI) => {
      if (viewMode === ideTreeConstants.VIEW_MODE.SEARCH) {
        editorAPI.bi.event(bi.events.SEARCH_ALL_FILES_ENTRY, biParams);
      }
      dispatch({
        type: ActionType.SET_LEFT_PANE_VIEW_MODE,
        viewMode,
      });
    },

  selectLeftPaneTab: (tabId) => ({
    type: ActionType.SELECT_LEFT_PANE_TAB,
    tabId,
  }),

  selectLeftPaneTabOf: (fileId) => (dispatch, _getState, editorAPI) =>
    dispatch({
      type: ActionType.SUGGEST_LEFT_PANE_TAB,
      tabId: getLeftPaneTabFor(editorAPI, fileId),
    }),
};
