import { MessageModal } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import React, { useContext } from 'react';

import DestructiveModalImage from '../../assets/icons/destructiveModalImage.svg';
import { dataHooks } from '../../consts/dataHooks';
import { SpiContext } from '../../SpiContext';

export interface DeleteSpiModalProps extends JSX.IntrinsicAttributes {
  spiPath: string;
}

export const DeleteSpiModal: React.FC<DeleteSpiModalProps> = ({ spiPath }) => {
  const [t] = useTranslation();
  const { panelsAPI, spiService } = useContext(SpiContext);

  return (
    <MessageModal
      dataHook={dataHooks.DELETE_SPI_MODAL}
      theme="destructive"
      onCloseButtonClick={() => panelsAPI.closePanel()}
      title={t('Delete_Spi_Modal_Title')}
      content={t('Delete_Spi_Modal_Description')}
      illustration={<DestructiveModalImage />}
      primaryButtonText={t('Delete_Spi_Modal_Remove_Button')}
      primaryButtonOnClick={() => spiService.deleteSpi(spiPath)}
      secondaryButtonText={t('Delete_Spi_Modal_Cancel_Button')}
      secondaryButtonOnClick={() => {
        panelsAPI.closePanel();
      }}
    />
  );
};
