import * as React from 'react';
import { Divider } from '@wix/wix-base-ui';
import {
  TreeContextMenu,
  ContextMenuStructure,
  IconColor,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';

import {
  AdminAccess,
  TS,
  JSX,
  JS,
  JSW,
  FileAdd,
  Delete,
  Rename,
  SettingsSmall,
  FolderAdd,
} from '@wix/wix-ui-icons-common/classic-editor';
import { FileTreeCustomAction } from '@wix/wix-code-plugin-contracts';

interface FilesTreeContextMenuProps {
  onContextMenuClick: (event: React.MouseEvent) => any;
  menuClassName: string;
  handleContextMenuToggle: (isOpen: boolean) => void;
  actionSelectedHandler: (action: FILE_TREE_QUICK_ACTIONS) => void;
  contextMenuItems: FILE_TREE_QUICK_ACTIONS[][];
  contextMenuButton: string;
  isContextMenuOpen: boolean;
  customActions?: FileTreeCustomAction[];
  readOnlyMode: boolean;
}

export type FILE_TREE_QUICK_ACTIONS =
  | 'NEW_FOLDER'
  | 'NEW_FILE'
  | 'NEW_JS_FILE'
  | 'NEW_TS_FILE'
  | 'NEW_JSW_FILE'
  | 'NEW_WEB_JS_FILE'
  | 'NEW_JSX_FILE'
  | 'DELETE'
  | 'RENAME'
  | 'OPEN_WEBMODULE_PERMISSIONS'
  | 'JOBS_SCHEDULER'
  | 'HTTP_FUNCTIONS'
  | 'DATA_HOOKS'
  | 'EVENTS';

interface actionAttributs {
  automationId: string;
  icon: React.FunctionComponent;
  iconColor?: IconColor;
  label: string;
}

const createQuickActions: () => {
  [key in FILE_TREE_QUICK_ACTIONS]: actionAttributs;
} = () => ({
  NEW_JSW_FILE: {
    automationId: 'action-new-jsw-file',
    icon: JSW,
    iconColor: 'jsw',
    label: 'WixCode_TreeView_FileTree_QuickActions_NewJswFile',
  },
  NEW_WEB_JS_FILE: {
    automationId: 'action-new-web-js-file',
    icon: JS,
    iconColor: 'js',
    label: 'WixCode_TreeView_FileTree_QuickActions_NewWebJsFile',
  },
  NEW_JS_FILE: {
    automationId: 'action-new-js-file',
    icon: JS,
    iconColor: 'js',
    label: 'WixCode_TreeView_FileTree_QuickActions_NewJsFile',
  },
  NEW_JSX_FILE: {
    automationId: 'action-new-jsx-file',
    icon: JSX,
    iconColor: 'jsx',
    label: 'WixCode_TreeView_FileTree_QuickActions_NewJsxFile',
  },
  NEW_TS_FILE: {
    automationId: 'action-new-ts-file',
    icon: TS,
    iconColor: 'ts',
    label: 'WixCode_TreeView_FileTree_QuickActions_NewTsFile',
  },
  NEW_FILE: {
    automationId: 'action-new-file',
    icon: FileAdd,
    label: 'WixCode_TreeView_FileTree_QuickActions_NewFile',
  },
  NEW_FOLDER: {
    automationId: 'action-new-folder',
    icon: FolderAdd,
    label: 'WixCode_TreeView_FileTree_QuickActions_NewFolder',
  },
  RENAME: {
    automationId: 'action-rename',
    icon: Rename,
    label: 'WixCode_TreeView_FileTree_QuickActions_Rename',
  },
  DELETE: {
    automationId: 'action-delete',
    icon: Delete,
    label: 'WixCode_TreeView_FileTree_QuickActions_Delete',
  },
  OPEN_WEBMODULE_PERMISSIONS: {
    automationId: 'action-permissions',
    icon: AdminAccess,
    label: 'WixCode_TreeView_FileTree_QuickActions_OpenWebmodulePermissions',
  },
  JOBS_SCHEDULER: {
    automationId: 'action-jobs-scheduler',
    icon: SettingsSmall,
    label: `WixCode_TreeView_FileTree_QuickActions_JobScheduler`,
  },
  HTTP_FUNCTIONS: {
    automationId: 'action-expose-site-api',
    icon: JS,
    iconColor: 'js',
    label: 'WixCode_TreeView_FileTree_QuickActions_ExposeHttpFunctions',
  },
  DATA_HOOKS: {
    automationId: 'action-data-hooks',
    icon: JS,
    iconColor: 'js',
    label: 'WixCode_TreeView_FileTree_QuickActions_DataHooks',
  },
  EVENTS: {
    automationId: 'action-site-events',
    icon: JS,
    iconColor: 'js',
    label: 'WixCode_TreeView_FileTree_QuickActions_SiteEvents',
  },
});

export const FilesTreeContextMenu: React.FC<FilesTreeContextMenuProps> = ({
  menuClassName,
  handleContextMenuToggle,
  contextMenuItems,
  onContextMenuClick,
  actionSelectedHandler,
  isContextMenuOpen,
  contextMenuButton,
  customActions,
  readOnlyMode,
}) => {
  const {
    legacyDependenciesAPI: {
      util: { translate },
    },
  } = useEditorLegacyAPIs();
  const quickActions = createQuickActions();
  const contextMenuStructure: ContextMenuStructure = {
    seperator: <Divider long={true} />,
    sections: contextMenuItems.map((section) => [
      ...section.map((item) => ({
        automationId: quickActions[item].automationId,
        icon: quickActions[item].icon,
        iconColor: quickActions[item].iconColor,
        label: translate(quickActions[item].label),
        onClick: () => actionSelectedHandler(item),
      })),
    ]),
  };
  if (customActions) {
    contextMenuStructure.sections.push(customActions);
  }
  return (
    <div
      className="stop-propagation-bg"
      key="contextMenuContainer"
      onClick={onContextMenuClick}
    >
      <TreeContextMenu
        menuClassName={menuClassName}
        handleContextMenuToggle={handleContextMenuToggle}
        contextMenuStructure={contextMenuStructure}
        contextMenuButton={contextMenuButton}
        isContextMenuOpen={isContextMenuOpen}
        tooltipContent={translate(
          'Velo_Concurrent_Editing_Sidebar_ReadOnly_Text',
        )}
        readonly={readOnlyMode}
      />
    </div>
  );
};
