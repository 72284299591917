/* eslint-disable react-hooks/exhaustive-deps */
import React, { ComponentType, useEffect, useRef } from 'react';
import _ from 'lodash';
import { utilsCreator as wixCodeUtilsCreator } from '@wix/wix-code-common';
import storeManager from '@/infra/redux-state/store/storeManager';
import pageEditorStateReader from '@/infra/redux-state/reducers/pageEditorStateReader';
import codeStateReader from '@/infra/redux-state/reducers/codeStateReader';
import ideTabsActions from '../../tabs/actions/ideTabsActions';
import ideTabsStateReader from '../../tabs/selectors/ideTabsStateReader';
import { WixCodeTopBar } from '../wixCodeTopBar/WixCodeTopBar';
import { AdditionalSidePane, Context } from '@/types/internal-types';
import { WixCodeTheme } from '../../constants/themes';
import cx from 'classnames';
import {
  Dispatch,
  useEditorLegacyAPIs,
  connectToStores,
} from '@wix/wix-code-common-components';
import {
  CodeEditorAPI,
  EditorAPI,
  ReadOnlyMode,
  CODE_EDITOR_MODE,
  EditorContextAPI,
} from '@wix/wix-code-plugin-contracts';
import { TabRenderer, TabRendererSlot } from '../tabRenderer/TabRenderer';
import { TopBarItemSlot } from '../topBar/TopBarItemRenderer';
import { DealerOfferGUID } from '@/infra/dealer/dealerService';
import { SidebarSlot } from '../../codeEditorSidebar';

interface WixCodeIdeEditorReduxStateProps {
  context: Context;
  focusIde: (currentContext?: any) => void;
  onSeeAllShortcuts: () => void;
  currentPageId: string;
}

interface WixCodeIdeReduxStateProps {
  dependencies: { [key: string]: string };
  scrollTop: number;
  theme: WixCodeTheme;
}

interface WixCodeIdeReduxReduceProps {
  storeTabScrollState: (arg: { tabId: string; scroll: number }) => void;
}

export interface WixCodeIdeOwnProps {
  additionalSidePane: AdditionalSidePane;
  readOnlyMode: ReadOnlyMode;
  editorContextAPI: EditorContextAPI;
  codeEditorMode?: CODE_EDITOR_MODE;
  fileId: string;
  id: AnyFixMe;
  wixCodeUtils: AnyFixMe;
  Footer: ComponentType;
  codeIntelligence: CodeEditorAPI['codeIntelligence'];
  tabRendererSlot: TabRendererSlot;
  topBarItemSlot: TopBarItemSlot;
  sidebarSlot: SidebarSlot;
  hiddenDealerOffers?: DealerOfferGUID[];
  pageElementsPanelComp: React.FC;
  className?: string;
  position?: 'absolute' | 'relative';
}

export type WixCodeIdeCompProps = WixCodeIdeReduxStateProps &
  WixCodeIdeReduxReduceProps &
  WixCodeIdeEditorReduxStateProps &
  WixCodeIdeOwnProps;

const WixCodeIdeComp: React.FC<WixCodeIdeCompProps> = (props) => {
  const {
    dependencies,
    context,
    additionalSidePane,
    focusIde,
    onSeeAllShortcuts,
    id = 'wix-code-ide',
    codeEditorMode = CODE_EDITOR_MODE.CODE_AND_STAGE,
    topBarItemSlot,
    sidebarSlot,
    hiddenDealerOffers,
    className,
    position = 'absolute',
    Footer,
  } = props;

  const { editorAPI, legacyDependenciesAPI } = useEditorLegacyAPIs();
  const { constants, stateManagement } = legacyDependenciesAPI;

  const contextRef = useRef(context);
  if (!_.isEqual(contextRef.current, context)) {
    contextRef.current = context;
  }

  const dependenciesRef = useRef(dependencies);
  if (!_.isEqual(dependenciesRef.current, dependencies)) {
    dependenciesRef.current = dependencies;
  }

  const additionalSidePaneRef = useRef(additionalSidePane);
  if (!_.isEqual(additionalSidePaneRef.current, additionalSidePane)) {
    additionalSidePaneRef.current = additionalSidePane;
  }

  useEffect(() => {
    focusIde();
  }, []);

  const showTopBarButtons = codeEditorMode === CODE_EDITOR_MODE.CODE_AND_STAGE;

  useEffect(() => {
    if (
      codeEditorMode === CODE_EDITOR_MODE.CODE_ONLY &&
      context?.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE
    ) {
      editorAPI.store.dispatch(
        stateManagement.editorPlugins.wixCodeIDE.actions.hideIDE(),
      );
    }
  }, []);

  return (
    <div
      className="wix-code-ide"
      style={{ position }}
      id={id}
      data-hook="wix-code-ide"
    >
      {!!context && (
        <div key="EditorsContainer" data-aid="wix-code-ide">
          <div className="wix-code-editor-v2">
            <WixCodeTopBar
              focusIde={focusIde}
              showPageElementsToggle={showTopBarButtons}
              onSeeAllShortcuts={onSeeAllShortcuts}
              additionalSidePane={additionalSidePane}
              showMaximize={showTopBarButtons}
              showMinimize={showTopBarButtons}
              codeEditorMode={codeEditorMode}
              topBarItemSlot={topBarItemSlot}
              hiddenDealerOffers={hiddenDealerOffers}
            />
            <div className={cx('editor-body', props.theme, className)}>
              <TabRenderer
                tabRendererSlot={props.tabRendererSlot}
                sidebarSlot={sidebarSlot}
                codeEditorProps={props}
                legacyDependencies={legacyDependenciesAPI}
                Footer={Footer}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const prepareFocusIde =
  (
    editorAPI: EditorAPI,
    store: AnyFixMe,
    constants: AnyFixMe,
    wixCodeUtils: AnyFixMe,
  ) =>
  (currentContext: AnyFixMe) => {
    const focusOnLoad = (fileId: AnyFixMe) => {
      editorAPI.wixCode.codeEditorApi.performActionOnMount(
        fileId,
        (api: AnyFixMe) => {
          if (
            editorAPI.developerMode.ui.idePane.getState() !==
            constants.DEVELOPER_MODE.CONTAINER_STATES.MINIMIZED
          ) {
            api && api.focus();
          }
        },
      );
    };

    const devContext = currentContext || editorAPI.developerMode.getContext();
    if (devContext.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE) {
      if (
        pageEditorStateReader.getRenamingPageId(store.getState()) !==
        devContext.data.id
      ) {
        focusOnLoad(wixCodeUtils.getFileIdFromPageId(devContext.data.id));
      }
    } else if (
      devContext.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.FILE
    ) {
      focusOnLoad(devContext.data.id);
    }
  };

const mapEditorStateToProps =
  (
    constants: AnyFixMe,
    experiment: AnyFixMe,
    platform: AnyFixMe,
    util: AnyFixMe,
  ) =>
  ({ editorAPI }: AnyFixMe): WixCodeIdeEditorReduxStateProps => {
    const wixCodeUtils = wixCodeUtilsCreator({ experiment, platform, util });
    return {
      currentPageId: editorAPI.dsRead.pages.getFocusedPageId(),
      context: editorAPI.developerMode.getContext(),
      focusIde: prepareFocusIde(
        editorAPI,
        storeManager.getStore(),
        constants,
        wixCodeUtils,
      ),
      onSeeAllShortcuts: function onSeeAllShortcuts() {
        editorAPI.panelManager.openHelpCenter(
          '70581cf1-c168-48cf-b4c2-4e4591b40b83',
        );
      },
    };
  };

const mapCodeDispatchToProps = (dispatch: Dispatch) => {
  return {
    storeTabScrollState: (action: { scroll: number; tabId: string }) => {
      dispatch(ideTabsActions.storeTabScrollState(action));
    },
  };
};

const mapCodeStateToProps = (
  state: AnyFixMe,
  ownProps: AnyFixMe,
): WixCodeIdeReduxStateProps => {
  const tabId = _.get(ownProps.context, 'data.id');
  const theme = state.ide?.theme;
  const dependencies = codeStateReader
    .getNpmPackages(state)
    .reduce((acc, val) => {
      acc[val.name] = val.version;
      return acc;
    }, {} as AnyFixMe);
  return {
    dependencies,
    scrollTop: ideTabsStateReader.getTabScroll(state, tabId),
    theme,
  };
};

let ConnectedComponent = null as any;
const WixCodeIdeConnected: React.FC<WixCodeIdeOwnProps> = (origProps) => {
  const {
    legacyDependenciesAPI: { util, constants, experiment, platform },
  } = useEditorLegacyAPIs();
  if (ConnectedComponent === null) {
    ConnectedComponent = React.memo(
      connectToStores({
        util,
        mapCodeStateToProps,
        mapCodeDispatchToProps,
        mapEditorStateToProps: mapEditorStateToProps(
          constants,
          experiment,
          platform,
          util,
        ),
        comp: WixCodeIdeComp,
      }),
    );
  }
  return <ConnectedComponent {...origProps} />;
};

export const WixCodeIde = WixCodeIdeConnected;
