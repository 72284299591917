import React, { useContext } from 'react';
import s from './ListSectionTitle.scss';
import { TextLabel } from '@wix/wix-base-ui';
import { PackagesModalContext } from '../../../../packagesModalContext';
import dataHooks from '../../../../dataHooks';

export const ListSectionTitle: React.FC<{ title: string }> = ({ title }) => {
  const { t } = useContext(PackagesModalContext);
  return (
    <div
      className={s.sectionTitle}
      data-hook={dataHooks.INSTALLED_PKGS_SECTION_TITLE}
    >
      <TextLabel type="T01" value={t(title)} shouldTranslate={false} />
    </div>
  );
};
