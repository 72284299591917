import {
  PredefinedSidebarTabs,
  TabProps,
} from '@wix/wix-code-plugin-contracts';
import React from 'react';
import { useTranslate } from '@/infra/i18n/useTranslate';
import { useBiLogger } from '@/infra/wixCodeContext/BiLoggerAPIContext';
import { HelpTabLinks } from './HelpTabLinks';
import { leftTreeClickOnAnItem } from '@wix/bi-logger-platform/v2';

export interface BlocksHelpTabProps {
  tabProps?: TabProps[PredefinedSidebarTabs.BLOCKS_HELP];
}

export const BlocksHelpTab: React.FC<BlocksHelpTabProps> = ({ tabProps }) => {
  const { t } = useTranslate();

  const links = [
    {
      biItemName: 'API Reference',
      url: t('WixCode_Exposure_Link_API_Reference'),
      label: 'WixCode_TreeView_FileTree_QuickActions_API_Reference',
    },
    {
      biItemName: 'Documentation',
      url:
        tabProps?.translations?.DOCUMENTATION_LINK ||
        t('WixCode_Exposure_Link_Reuse_Resources'),
      label: 'WixCode_TreeView_FileTree_QuickActions_Documentation',
    },
  ];
  const biLogger = useBiLogger();
  return (
    <HelpTabLinks
      links={links.map((l) => ({
        ...l,
        onClickLinkHandler: () => {
          biLogger.report(
            leftTreeClickOnAnItem({
              item_name: l.biItemName,
              item_type: 'resource',
            }),
          );
          window.open(l.url, '_blank');
        },
      }))}
    />
  );
};
