import React from 'react';
import { PanelHeader } from '@wix/wix-base-ui';
import { Shell } from '@wix/wix-code-repluggable';
import {
  MenuTabProvider,
  DoNotTranslateBaseUiComponents,
} from '@wix/wix-code-common-components';
import {
  BiLoggerAPI,
  LegacyEditorDependencies,
  PanelsAPI,
  WixCodeStoreAPI,
} from '@wix/wix-code-plugin-contracts';
import { panelHeaderData } from './createPagesTab';
import { dataHooks, helpIds } from './consts';

type PagesMenuTabProps = {
  closePanel: () => void;
  shell: Shell;
  apis: {
    wixCodeStoreAPI: WixCodeStoreAPI;
    legacyDependenciesAPI: LegacyEditorDependencies;
    biLoggerAPI: BiLoggerAPI;
    panelsAPI: PanelsAPI;
  };
};

type PagesPanelHeaderProps = {
  closePanel: () => void;
  panelsAPI: PanelsAPI;
  label: string;
};

export const PagesPanelHeader: React.FC<PagesPanelHeaderProps> = ({
  closePanel,
  panelsAPI,
  label,
}) => {
  const onOpenHelp = () => {
    panelsAPI.openHelpCenter(helpIds.siteCodeHelpCenter);
  };

  return (
    <PanelHeader
      dataHook={dataHooks.panelHeader}
      onHelp={onOpenHelp}
      onClose={closePanel}
      className="dragger dark"
      defaultCursor
    >
      {label}
    </PanelHeader>
  );
};

export const createPagesMenuTab: React.FC<PagesMenuTabProps> = ({
  closePanel,
  children,
  apis,
}) => {
  const { panelsAPI, ...restApis } = apis;
  const panelHeaderLabel = apis.legacyDependenciesAPI.util.translate(
    panelHeaderData.label,
  );
  return (
    <MenuTabProvider {...restApis}>
      <DoNotTranslateBaseUiComponents>
        <PagesPanelHeader
          closePanel={closePanel}
          panelsAPI={apis.panelsAPI}
          label={panelHeaderLabel}
        />
        {children}
      </DoNotTranslateBaseUiComponents>
    </MenuTabProvider>
  );
};
