import * as _ from 'lodash';
import { TEST_VERSION_NAME } from './packagesModalContext';
import {
  AvailableCodeReusePkg,
  PkgInfo,
  PkgAvailableVersionType,
  InstalledCodeReusePkg,
  NpmPackageInfo,
} from '@wix/wix-code-plugin-contracts';
import { consts } from '@wix/wix-code-consts';
import storeManager from '@/infra/redux-state/store/storeManager';
import { codeReusePkgsSelectors } from './packagesView/packagesViewSelectors';
import { CodeReusePkgVersion } from './codeReuseServerAPI';
import { packageStatus } from './packagesModal/packagesModalContent/pkgLists/npmPackagesList/consts';
import { getInstalling } from './packagesModal/packagesModalContent/pkgLists/npmPackagesList/selectors/modulesSelectors';
import codeStateReader from '@/infra/redux-state/reducers/codeStateReader';

export enum VERSION_INSTALLATION_TYPE {
  NOT_INSTALLED = 'NOT_INSTALLED',
  VERSION_INSTALLED_BY_NUMBER = 'VERSION_INSTALLED_BY_NUMBER',
  VERSION_INSTALLED_AS_TEST = 'VERSION_INSTALLED_AS_TEST',
  DIFFERENT_VERSION_INSTALLED = 'DIFFERENT_VERSION_INSTALLED',
  LARGEST_MINOR_IN_AUTO_UPDATE = 'LARGEST_MINOR_IN_AUTO_UPDATE',
  NOT_LARGEST_MINOR_IN_AUTO_UPDATE = 'NOT_LARGEST_MINOR_IN_AUTO_UPDATE',
}

export const getVersionInstallationType = ({
  version,
  installedPkg,
  allVersions,
}: {
  version: string;
  installedPkg?: PkgInfo;
  allVersions?: CodeReusePkgVersion[];
}): VERSION_INSTALLATION_TYPE => {
  if (!installedPkg) {
    return VERSION_INSTALLATION_TYPE.NOT_INSTALLED;
  } else if (
    isInstalledTestVersion(installedPkg) &&
    version === TEST_VERSION_NAME
  ) {
    return VERSION_INSTALLATION_TYPE.VERSION_INSTALLED_AS_TEST;
  } else if (
    installedPkg.version.startsWith('^') &&
    (installedPkg.version === version ||
      (allVersions &&
        getLargesetMinor(installedPkg.version, allVersions) === version))
  ) {
    return VERSION_INSTALLATION_TYPE.LARGEST_MINOR_IN_AUTO_UPDATE;
  } else if (
    installedPkg.version.startsWith('^') &&
    isSameMajor(version, installedPkg.version)
  ) {
    return VERSION_INSTALLATION_TYPE.NOT_LARGEST_MINOR_IN_AUTO_UPDATE;
  } else if (installedPkg.version === version) {
    return VERSION_INSTALLATION_TYPE.VERSION_INSTALLED_BY_NUMBER;
  } else {
    return VERSION_INSTALLATION_TYPE.DIFFERENT_VERSION_INSTALLED;
  }
};

const isReusePackage = (name: string): boolean => {
  const store = storeManager.getStore();
  const state = store.getState();
  const set = codeReusePkgsSelectors.allNames(state);
  return set.has(name);
};

const isReusePackageByVelo = (name: string): boolean => {
  const store = storeManager.getStore();
  const state = store.getState();
  return codeReusePkgsSelectors.available
    .byVelo(state)
    .some((p) => p.name === name);
};

const getInstalledPackgeByName = (
  name: string,
): InstalledCodeReusePkg | undefined => {
  const store = storeManager.getStore();
  const state = store.getState();
  return codeReusePkgsSelectors.installed
    .all(state)
    .find((installed) => installed.name === name);
};

const isInstalledReusePackageByOthers = (name: string): boolean => {
  const store = storeManager.getStore();
  const state = store.getState();
  const ret = codeReusePkgsSelectors.installed
    .byOthers(state)
    .some((p) => p.name === name);
  return ret;
};

const isPkgFile = (name: string): boolean => _.startsWith(name, '@');
const getNpmReadmePath = (pkgName: string): string =>
  `${consts.NPM_VIRTUAL_FOLDER_NAME}/${pkgName}/${consts.README_FILE_NAME}`;
const isPkgMarkdown = (path: string) =>
  isPkgFile(path) && _.endsWith(path, '.md');
const isMarkdownFile = (path: string) => _.endsWith(path, '.md');
const getPkgScope = (pkgName: string) =>
  pkgName.substring(0, pkgName.indexOf('/'));

const getUpgradableVersion = (
  availablePkg: PkgInfo | undefined,
): string | undefined => {
  if (!availablePkg) {
    return undefined;
  }
  if (isReusePackage(availablePkg.name)) {
    const { availableVersionType, version } =
      availablePkg as AvailableCodeReusePkg;

    if (availableVersionType === PkgAvailableVersionType.TEST_ONLY) {
      return undefined;
    }
    const installedPkg = getInstalledPackgeByName(availablePkg.name);

    if (installedPkg && !isSameVersion(installedPkg.version, version)) {
      return version;
    }
  } else {
    const installedPackage = getInstalledNpmPkgByName(availablePkg.name);

    if (
      availablePkg.upgradableVersion &&
      availablePkg?.upgradableVersion !== installedPackage?.version
    ) {
      return availablePkg.upgradableVersion;
    }
  }
};
const getInstalledNpmPkgByName = (name: string) =>
  codeStateReader
    .getNpmPackages(storeManager.getStore().getState())
    .find((pkg) => pkg.name === name);

const isSameVersion = (installedVersion: string, availableVersion: string) => {
  return (
    installedVersion === availableVersion ||
    (installedVersion.startsWith('^') &&
      isSameMajor(installedVersion, availableVersion))
  );
};

function isAvailableCodeReusePkg(obj: any): obj is AvailableCodeReusePkg {
  return obj.availableVersionType !== undefined;
}

const isInstalledTestVersion = (pkg?: PkgInfo) => {
  return (
    pkg &&
    (pkg?.version === TEST_VERSION_NAME ||
      (isAvailableCodeReusePkg(pkg) && pkg.version === pkg.testVersion))
  );
};

const isSameMajor = (installedVersion: string, availableVersion: string) =>
  installedVersion &&
  availableVersion &&
  getMajorVersion(installedVersion) === getMajorVersion(availableVersion);

const getLargesetMinor = (
  version: string,
  publicVersions: CodeReusePkgVersion[],
): string => {
  const major = getMajorVersion(version);
  const largestMinor = publicVersions.reduce((maxMinor, codeReuseVersion) => {
    const [majorVersion, minor] = codeReuseVersion.version.split('.');
    return major === majorVersion && maxMinor < Number(minor)
      ? Number(minor)
      : maxMinor;
  }, 0);
  return `${major}.${largestMinor}.0`;
};

const getMajorVersion = (version: string) =>
  version.replace(/^\^/, '').split('.')[0];

const preambleMarkdown = ({
  name,
  translate,
  description,
  importSnippet,
  usageDescription,
  functionalitySnippet,
}: any) => `
## How to use '${name}' on your site
${description}

1 - Install the package.

2 - Import the package in your code:
\`\`\`javascript
${importSnippet}
\`\`\`
3 - ${usageDescription}:
\`\`\`javascript
${functionalitySnippet}
\`\`\`

***
${translate('Package_Manager_ReadMe_Install_Text')}
***
`;

const getVersion = (pkg: PkgInfo): string =>
  pkg.version || _.get(pkg, ['_version', 'version']);

const getVersionDisplayText = (
  pkg: PkgInfo,
  t: any,
  versionOverride?: string | null,
): string => {
  const { name } = pkg;
  const version = versionOverride || getVersion(pkg);
  const isCodeReusePackage = isReusePackage(name);
  if (!isCodeReusePackage) {
    return version;
  }
  const { availableVersionType } = pkg as AvailableCodeReusePkg;

  if (
    version === TEST_VERSION_NAME ||
    availableVersionType === PkgAvailableVersionType.TEST_ONLY
  ) {
    return t('Package_Manager_test_version');
  }

  return `v ${getVersionWithoutPatch(version)}`;
};

const getVersionWithoutPatch = (version: string): string => {
  const versionParts = version.split('.');
  return version.startsWith('^')
    ? `${versionParts[0]}.x`
    : `${versionParts.slice(0, 2).join('.')}`;
};

const getNpmPkgStatus = (npmPkg: NpmPackageInfo) => npmPkg?._version?.status;

const isModulePending = (pkg: PkgInfo) =>
  !isReusePackage(pkg.name) &&
  [packageStatus.PENDING, packageStatus.PENDING_OPERATOR_APPROVAL].includes(
    getNpmPkgStatus(pkg as NpmPackageInfo) as any,
  );
const isModuleRejected = (pkg: PkgInfo) =>
  !isReusePackage(pkg.name) &&
  getNpmPkgStatus(pkg as NpmPackageInfo) === packageStatus.REJECTED;
const npmPackagesNameFormat = (name: string, version: string) => {
  return `${name} (v.${version})`;
};
function isInstallationInProgress() {
  const store = storeManager.getStore();
  return Object.values(getInstalling(store.getState())).some(
    (status) => !!status,
  );
}
function isVersionInstallationInProgress(version: string) {
  const store = storeManager.getStore();
  return !!Object.values(getInstalling(store.getState())).find(
    (versions) => versions[version] === true,
  );
}
function isPackageUpdating(name: string) {
  const store = storeManager.getStore();
  return !!Object.keys(getInstalling(store.getState())).find(
    (pkgName: string) => pkgName === name,
  );
}
export {
  isReusePackage,
  isReusePackageByVelo,
  isInstalledReusePackageByOthers,
  getUpgradableVersion,
  isPkgMarkdown,
  isMarkdownFile,
  isPkgFile,
  getPkgScope,
  getNpmReadmePath,
  preambleMarkdown,
  getVersionDisplayText,
  getVersionWithoutPatch,
  isSameVersion,
  isInstalledTestVersion,
  isSameMajor,
  getLargesetMinor,
  isModulePending,
  isModuleRejected,
  npmPackagesNameFormat,
  isInstallationInProgress,
  isPackageUpdating,
  getInstalledNpmPkgByName,
  isVersionInstallationInProgress,
};
