import eventBuilders from './eventBuilders';
import biEvents from '@/legacy/bi/events';

const {
  TBF_OPEN_TBF_TAB,
  TBF_TRIGGER_PANEL_ACTION,
  TBF_TRIGGER_BE_FUNCTION,
  TBF_BE_FUNCTION_FINISHED,
  TBF_VALID_EXPORTED_FUNCTION,
  TBF_RUN_BUTTON_EXPOSURE,
} = biEvents;

export default {
  eventBuilders,
  events: {
    TBF_OPEN_TBF_TAB,
    TBF_TRIGGER_PANEL_ACTION,
    TBF_TRIGGER_BE_FUNCTION,
    TBF_BE_FUNCTION_FINISHED,
    TBF_VALID_EXPORTED_FUNCTION,
    TBF_RUN_BUTTON_EXPOSURE,
  },
};
