import { ActionType } from '../actions/types';
import { FilesSelectionReducerState as FilesSelectionReducerStateInternal } from '@wix/wix-code-plugin-contracts';

export type FilesSelectionReducerState = FilesSelectionReducerStateInternal;
const initialState: FilesSelectionReducerStateInternal = {};

type Selection = { line: number; column: number };

export type SetSelection = {
  fileId: string;
  selection: {
    end: Selection;
    start: Selection;
  };
  type: ActionType.SET_SELECTION;
};
type FilesSelectionReducerAction = SetSelection;

const filesSelectionReducer = (
  state = initialState,
  action: FilesSelectionReducerAction,
) => {
  switch (action.type) {
    case ActionType.SET_SELECTION:
      return {
        ...state,
        [action.fileId]: action.selection,
      };

    default:
      return state;
  }
};

export default filesSelectionReducer;
