// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".atEVa0{display:flex;padding:0 5px;justify-content:center;flex-direction:column;height:100%}.atEVa0 .oQ3uso{max-height:24px;padding:0 6px 0 10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "atEVa0",
	"cta": "oQ3uso"
};
export default ___CSS_LOADER_EXPORT___;
