import React, { Fragment } from 'react';
import { AccordionSection } from '@wix/wix-base-ui';

type WidgetElementsAccordionProps = {
  widgetRef: any;
  widgetNickname: string;
  widgetRootRef: any;
  widgetRootNickname: string;
  renderCompContent: (ref: any) => JSX.Element;
};

type WidgetState = {
  isWidgetOpen: true;
  isRootOpen: true;
};

const WidgetElementsAccordion = ({
  widgetNickname,
  widgetRef,
  renderCompContent,
  widgetRootNickname,
  widgetRootRef,
}: WidgetElementsAccordionProps) => {
  const [widget, setWidget] = React.useState<WidgetState>({
    isWidgetOpen: true,
    isRootOpen: true,
  });
  const toggleState = (toToggle: keyof WidgetState) => {
    setWidget((prevState) => ({
      ...prevState,
      [toToggle]: !prevState[toToggle],
    }));
  };
  return (
    <Fragment>
      <AccordionSection
        automationId="widget-section"
        label={widgetNickname}
        isOpen={widget.isWidgetOpen}
        onToggle={() => toggleState('isWidgetOpen')}
      >
        {renderCompContent(widgetRef)}
      </AccordionSection>
      <AccordionSection
        automationId="widget-root-section"
        label={widgetRootNickname}
        isOpen={widget.isRootOpen}
        onToggle={() => toggleState('isRootOpen')}
      >
        {renderCompContent(widgetRootRef)}
      </AccordionSection>
    </Fragment>
  );
};

export default WidgetElementsAccordion;
