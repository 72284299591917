import './wixCodeExposure.global.scss';
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import wixCodeBI from '../../../bi/bi';
import baseUILib from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import once_ from 'lodash/once';

const links = [
  {
    biItemName: 'Documentation',
    url: 'Wixcode_Exposure_Link_Resources',
    label: 'WixCode_TreeView_FileTree_QuickActions_Documentation',
  },
  {
    biItemName: 'API Reference',
    url: 'Wixcode_Exposure_Link_API_Reference',
    label: 'WixCode_TreeView_FileTree_QuickActions_API_Reference',
  },
  {
    biItemName: 'Forum',
    url: 'Wixcode_Exposure_Link_Forum',
    label: 'WixCode_TreeView_FileTree_QuickActions_Forum',
  },
  {
    biItemName: 'Local_work',
    url: 'WixCode_TreeView_FileTree_QuickActions_Local_Link',
    label: 'WixCode_TreeView_FileTree_QuickActions_Local',
  },
];

export default once_(({ stateManagement, util }) => {
  const WixCodeExposure = createReactClass({
    displayName: 'wixCodeExposure',
    propTypes: {
      biEvent: PropTypes.func,
    },
    getInitialState() {
      return {
        shouldShowContextMenu: false,
      };
    },

    toggleContextMenu() {
      if (this.state.shouldShowContextMenu === false) {
        this.props.biEvent(wixCodeBI.events.LEFT_TREE_CLICK_ON_SECTION, {
          section_name: 'Wix Code Resources',
        });
      }
      this.setState({
        shouldShowContextMenu: !this.state.shouldShowContextMenu,
      });
    },

    navigateToLink(item) {
      this.props.biEvent(wixCodeBI.events.LEFT_TREE_CLICK_ON_ITEM, {
        origin: 'left_tree',
        item_name: item.biItemName,
      });
      window.open(item.url, '_blank');
    },

    render() {
      return (
        <div
          className="wix-code-exposure"
          data-aid="wix-code-exposure"
          onClick={this.toggleContextMenu}
        >
          <div
            className={classNames({
              'exposure-label': true,
              selected: this.state.shouldShowContextMenu,
            })}
          >
            {util.translate('Corvid_TreeView_Resources_Section')}
          </div>
          <baseUILib.ContextMenu
            className="exposure-context-menu"
            isOpen={this.state.shouldShowContextMenu}
            onToggle={this.toggleContextMenu}
          >
            {links.map(({ biItemName, url, label }, i) => (
              <baseUILib.ContextMenuAction
                key={'link-' + i}
                onClick={() =>
                  this.navigateToLink({
                    biItemName,
                    url: util.translate(url),
                  })
                }
              >
                <baseUILib.Symbol>
                  <Symbol name="link_icon" />
                </baseUILib.Symbol>
                {util.translate(label)}
              </baseUILib.ContextMenuAction>
            ))}
          </baseUILib.ContextMenu>
        </div>
      );
    },
  });

  const mapDispatchToProps = (dispatch) => ({
    biEvent: (...args) =>
      dispatch(stateManagement.editorPlugins.bi.actions.event(...args)),
  });

  const Connected = util.hoc.connect(
    [],
    _.stubObject,
    mapDispatchToProps,
  )(WixCodeExposure);

  return Connected;
});
