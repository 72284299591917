import React from 'react';
import _ from 'lodash';
import { CompRef } from '@wix/document-services-types';
import { useAPIs, useCssClasses } from '../../../createCssClasses';
import { CssReference } from './CssReference/CssReference';
import { OpenGlobalCss } from './OpenGlobalCss/OpenGlobalCss';
import { CustomClasses } from './CustomClasses/CustomClasses';
import { SemanticClassesSection } from './SemanticClasses/SemanticClassesSection';
import { UnsupportedElement } from '../UnsupportedElementState/UnsupportedElementState';
import s from './panelContent.scss';

type PanelContentProps = {
  compRef: CompRef;
};

export const PanelContent = ({ compRef }: PanelContentProps) => {
  const { editorAPI, legacyDependenciesAPI } = useAPIs();
  const cssCompRefClasses = useCssClasses(compRef);
  const { isAppWidget } =
    legacyDependenciesAPI.stateManagement.editorPlugins.components.selectors;
  const compRootRef =
    compRef && isAppWidget(compRef, editorAPI.dsRead)
      ? _.head(editorAPI.dsRead.components.getChildren(compRef))
      : null;
  const hasEditableWidgetRoot = !!compRootRef;
  const cssCompRootRefClasses = useCssClasses(compRootRef as CompRef);
  const semanticClasses = !hasEditableWidgetRoot
    ? cssCompRefClasses.semanticClasses
    : cssCompRootRefClasses.semanticClasses;
  const ref = hasEditableWidgetRoot ? (compRootRef as CompRef) : compRef;
  const shouldDisplayCssClassesContent = semanticClasses.length > 0;

  return shouldDisplayCssClassesContent ? (
    <div className={s.panelContent}>
      <CssReference compRef={ref} />
      <CustomClasses compRef={ref} />
      <SemanticClassesSection compRef={ref} />
      <OpenGlobalCss />
    </div>
  ) : (
    <UnsupportedElement compRef={ref} />
  );
};
