import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import get_ from 'lodash/get';

const UNSUPPORTED_PARAMS_CONTENT = `[]`;
const HTTP_FUNCTIONS_FILE_PATH = 'backend/http-functions.js';
const HTTP_FUNCTION_NAME_REGEX = /^(get|post|put|delete|options|use)_.+$/;

const DEFAULT_HTTP_FUNCTIONS_PAYLOAD_QUERY = {
  myKey: 'myValue',
};
const DEFAULT_HTTP_FUNCTIONS_PAYLOAD_PATH = ['myPath1', 'myPath2'];
const DEFAULT_HTTP_FUNCTIONS_PAYLOAD_HEADERS = {
  Accept: '*/*',
};
const DEFAULT_HTTP_FUNCTIONS_PAYLOAD_BODY = {
  json: {
    myKey1: 'myValue1',
    myKey2: 'myValue2',
  },
};

interface HttpFunctionsPayload {
  method?: string;
  body?: { json?: object };
  path?: string[];
  headers: Record<string, string>;
  query?: Record<string, unknown>;
}

const prettyStringify = (obj: AnyFixMe) => JSON.stringify(obj, null, 4);

export const getTbfModelPath = (fileId: AnyFixMe, functionName: AnyFixMe) =>
  `.tbfModels/${fileId}/${functionName}.json`;

export const getTbfModelIdName = (fileId: AnyFixMe, functionName: AnyFixMe) =>
  'file:///' + getTbfModelPath(fileId, functionName);

export const extractFileTypeFromFileName = (fileName: AnyFixMe) =>
  get_(fileName.match(/\.([^.]+)$/), '[1]');

export const convertModelIdToPath = (modelId: AnyFixMe) =>
  modelId.replace('file:///', '');

const constructContentFromJSON = (paramNames: AnyFixMe) => {
  if (paramNames.length === 0) {
    return {};
  }

  return paramNames.reduce((acc: AnyFixMe, name: AnyFixMe) => {
    acc[name] = null;

    return acc;
  }, {});
};

export const constructHttpFunctionPayload = (functionName: AnyFixMe) => {
  const method = functionName.split('_')[0].toUpperCase();

  const request: HttpFunctionsPayload = {
    path: [...DEFAULT_HTTP_FUNCTIONS_PAYLOAD_PATH],
    headers: { ...DEFAULT_HTTP_FUNCTIONS_PAYLOAD_HEADERS },
  };

  if (method === 'GET' || method === 'DELETE') {
    request.query = { ...DEFAULT_HTTP_FUNCTIONS_PAYLOAD_QUERY };
  } else if (method === 'POST' || method === 'PUT') {
    request.headers['Content-Type'] = 'application/json';
    request.body = { ...DEFAULT_HTTP_FUNCTIONS_PAYLOAD_BODY };
  } else if (method === 'USE') {
    request.query = { ...DEFAULT_HTTP_FUNCTIONS_PAYLOAD_QUERY };
    request.method = 'GET';
  }

  return { request };
};

export const constructTbfContent = ({
  isHttpFunctionExperimentOn,
  fileId,
  functionName,
  paramNames = [],
}: AnyFixMe) => {
  // If the params contain any null this means that there are
  // unsupported param types like destructured or spread arguments
  const containsUnsupportedParams =
    paramNames.find((param: AnyFixMe) => param === null) === null;

  if (containsUnsupportedParams) {
    return UNSUPPORTED_PARAMS_CONTENT;
  }

  const result =
    isHttpFunctionExperimentOn && isHttpFunction(fileId, functionName)
      ? constructHttpFunctionPayload(functionName)
      : constructContentFromJSON(paramNames);

  return prettyStringify(result);
};

export const isModelContentDivergingFromConstructedJsonParams = (
  editorAPI: EditorAPI,
  {
    modelId,
    fileId,
    functionName,
    paramNames,
    isHttpFunctionExperimentOn,
  }: AnyFixMe,
) => {
  const modelValue = editorAPI.wixCode.tbfService.models
    .get({ modelId })
    .getValue();
  const constructedJsonParams = constructTbfContent({
    isHttpFunctionExperimentOn,
    fileId,
    functionName,
    paramNames,
  });
  return constructedJsonParams !== modelValue;
};

export const getMessageFromErrorObject = (error: AnyFixMe) =>
  error.message || error.name;
export const getTypeFromErrorObject = (error: AnyFixMe) => error.name;

const isHttpFunctionsFile = (fileId: AnyFixMe) =>
  fileId === HTTP_FUNCTIONS_FILE_PATH;

const isFunctionAnHttpFunction = (functionName: AnyFixMe) =>
  HTTP_FUNCTION_NAME_REGEX.test(functionName);

export const isHttpFunction = (fileId: AnyFixMe, functionName: AnyFixMe) =>
  isHttpFunctionsFile(fileId) && isFunctionAnHttpFunction(functionName);
