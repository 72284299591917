import { connect } from 'react-redux';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import React from 'react';
import { wixCodeReduxContext } from './wixCodeReduxContext';
import errorBoundaryComponent from '../WixCodeErrorBoundary';

type AnyFixMe = any;
type DispatchToPropsObject = Record<string, Function>;
type DispatchToPropsFunction = (dispatch: any) => any;
interface connectToStoresParams {
  util: any;
  mapEditorStateToProps?: ({
    editorAPI,
    state,
  }: {
    editorAPI: EditorAPI;
    state: any;
  }) => any;
  mapCodeStateToProps?: (state: any, ownProps?: any) => any;
  mapCodeDispatchToProps?: DispatchToPropsFunction | DispatchToPropsObject;
  comp: React.FC<any> | React.ComponentClass;
  forwardRef?: boolean;
}

const connectToCodeStore = (
  mapCodeStateToProps: AnyFixMe,
  mapCodeDispatchToProps: AnyFixMe,
  comp: AnyFixMe,
  forwardRef: boolean = false,
) => {
  return mapCodeStateToProps || mapCodeDispatchToProps
    ? connect(mapCodeStateToProps, mapCodeDispatchToProps, null, {
        forwardRef,
        context: wixCodeReduxContext,
      })(comp)
    : comp;
};

const connectToEditorStore = (
  util: AnyFixMe,
  mapEditorStateToProps: AnyFixMe,
  comp: AnyFixMe,
) => {
  const {
    connect: editorConnect,
    STORES: { EDITOR_API },
  } = util.hoc;

  return mapEditorStateToProps
    ? editorConnect(
        EDITOR_API,
        mapEditorStateToProps,
        undefined,
        undefined,
        undefined,
        errorBoundaryComponent,
      )(comp)
    : comp;
};

export function connectToStores<T>({
  util,
  mapEditorStateToProps,
  mapCodeStateToProps,
  mapCodeDispatchToProps,
  comp,
  forwardRef,
}: connectToStoresParams): React.FC<T> {
  return connectToEditorStore(
    util,
    mapEditorStateToProps,
    connectToCodeStore(
      mapCodeStateToProps,
      mapCodeDispatchToProps,
      comp,
      forwardRef,
    ),
  );
}
