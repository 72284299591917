import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { createDeveloperToolsTab } from './createDeveloperToolsTab';
import { createDeveloperToolsTabWrapper } from './createDeveloperToolsTabMenuTab';

export const DeveloperToolsTabEntryPoint: EntryPoint = {
  name: 'Developer Tools tab entry point',
  getDependencyAPIs() {
    return [
      APIKeys.MenuAPI,
      APIKeys.PanelsAPI,
      APIKeys.SidebarAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.MenuPanelAPI,
      APIKeys.WixCodeStoreAPI,
      WixCodeEditorAdapterAPI,
    ];
  },
  extend(shell: Shell) {
    const menuAPI = shell.getAPI(APIKeys.MenuAPI);
    const panelsAPI = shell.getAPI(APIKeys.PanelsAPI);
    const sidebarAPI = shell.getAPI(APIKeys.SidebarAPI);
    const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
    const menuPanelAPI = shell.getAPI(APIKeys.MenuPanelAPI);
    const wixCodeStoreAPI = shell.getAPI(APIKeys.WixCodeStoreAPI);
    const { legacyDependencies } = shell.getAPI(WixCodeEditorAdapterAPI);

    const developerToolsTab = createDeveloperToolsTab();

    sidebarAPI.contributeTab(() => developerToolsTab);

    menuPanelAPI.contributeTab({
      id: 'tools',
      TabContent: ({ closePanel }) => {
        const TabContent = developerToolsTab.creator();
        return createDeveloperToolsTabWrapper({
          shell,
          closePanel,
          children: TabContent,
          apis: {
            wixCodeStoreAPI,
            legacyDependenciesAPI: legacyDependencies,
            biLoggerAPI,
            panelsAPI,
          },
        });
      },
    });

    menuAPI.registerMenuItemListener('tools', () => {
      menuPanelAPI.selectTab('tools');
    });
  },
};
