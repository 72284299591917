import * as React from 'react';
import classNames from 'classnames';
import baseUILib from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import dataHooks from '../dataHooks';
import { monitoring } from '@wix/wix-code-common';
import styles from '../pagesTree/Page.module.scss';
import { DataSource, PageData, PageType } from './types';

interface LightboxPageProps {
  dataSource: DataSource;
  selectedIdeTabId: string;
  getFileIdFromPageId: (pageId: string) => string;
  onPageSelected: (pageData: PageData, type: PageType) => void;
}

const { withErrorHandling } = monitoring;
export const LightboxPage: React.FC<LightboxPageProps> = ({
  dataSource,
  selectedIdeTabId,
  getFileIdFromPageId,
  onPageSelected,
}) => {
  const onClick = () => {
    withErrorHandling(onPageSelected)(dataSource.pageData, dataSource.type);
  };

  const isSelected =
    selectedIdeTabId === getFileIdFromPageId(dataSource.pageData.id);

  return (
    <baseUILib.TreeListItem
      dataHook={dataHooks.TREE_NODE}
      label={dataSource.label}
      shouldTranslate={false}
      depth={0}
      icon={
        <Symbol
          className={classNames(styles.corvidTreePrefixIcon, {
            [styles.highlight]: isSelected,
          })}
          key="icon_popup"
          name="popup"
        />
      }
      size="small"
      onClick={onClick}
      selected={isSelected}
    />
  );
};
