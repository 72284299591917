// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V7CcME{padding:0}.eABLDj{padding-bottom:6px}.HzKOeU{margin-left:17px;margin-top:4px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip-content": "V7CcME",
	"tooltipContent": "V7CcME",
	"tooltip-element": "eABLDj",
	"tooltipElement": "eABLDj",
	"pkgs-symbol": "HzKOeU",
	"pkgsSymbol": "HzKOeU"
};
export default ___CSS_LOADER_EXPORT___;
