import * as codeEditor from '@wix/wix-code-code-editor';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import { HttpClient } from '@wix/http-client';
import {
  GENERATE_ENDPOINT,
  GENERATE_STREAM_ENDPOINT,
  SERVERLESS_BASE_URL,
} from '../constants';

const client = new HttpClient({ baseURL: SERVERLESS_BASE_URL });

const appendCodeToActivePage = async (editorAPI: EditorAPI, code: string) => {
  const fileId = editorAPI.developerMode.getContext().data?.id;
  const fileDescriptor = editorAPI.wixCode.fileSystem.getVirtualDescriptor(
    `public/pages/${fileId}.js`,
  );
  const existingContent: string = await editorAPI.wixCode.fileSystem.readFile(
    fileDescriptor,
  );

  const lastLineOfCode = codeEditor.getCodeLinesCount();

  debugger;
  editorAPI.wixCode.fileSystem.writeFile(
    fileDescriptor,
    `${existingContent}\n${code}`,
  );

  const commentLinesCount = 5;
  const firstLineOfAiAssistantCode = lastLineOfCode + commentLinesCount + 1;
  focusCodeOnLine(
    editorAPI,
    fileDescriptor.location,
    firstLineOfAiAssistantCode,
  );
};

const focusCodeOnLine = (
  editorAPI: EditorAPI,
  fileName: string,
  line: number,
) => {
  const newSelection = {
    start: { line, column: 0 },
    end: { line, column: 0 },
  };
  editorAPI.wixCode.fileSystem.setSelection(fileName, newSelection);
  editorAPI.wixCode.codeEditorApi.getApi().focus();
};

const createRequestOptions = (wixCodeSignedInstance: string): RequestInit => ({
  mode: 'cors',
  credentials: 'include',
  headers: {
    'content-type': 'application/json',
    'x-wix-si': wixCodeSignedInstance,
  },
});

const generate = async (prompt: string[], conversationId: string) =>
  client.post<{ message: string }>(
    GENERATE_ENDPOINT,
    {
      action: 'generate',
      prompt,
      conversationId,
    },
    { withCredentials: true },
  );

export const generateStream = async (
  wixCodeSignedInstance: string,
  prompt: string[],
  conversationId: string,
): Promise<ReadableStream<Uint8Array>> => {
  const response = await fetch(
    `${SERVERLESS_BASE_URL}${GENERATE_STREAM_ENDPOINT}`,
    {
      method: 'POST',
      body: JSON.stringify({
        prompt,
        conversationId,
      }),
      ...createRequestOptions(wixCodeSignedInstance),
      // @ts-expect-error for some reason fetch types doesn't include response-type: 'stream'
      responseType: 'stream',
    },
  );

  return response.body as ReadableStream<Uint8Array>;
};

const fakeGenerate = async (_prompt: string[], _conversationId: string) => {
  const fakeClient = new HttpClient({ baseURL: 'https://mocki.io/v1' });
  return fakeClient.get<{ message: string }>(
    `/e3332312-1584-439d-bcff-0ea54da2b78d`,
  );
};

const createPrompt = (
  userInput: string,
  eventHandler: string,
  componentId: string,
): string => {
  return `Create ${eventHandler} event handler in the style of $w('component-id').onMouseIn for component ${componentId} which does the following: ${userInput}`;
};

export default {
  appendCodeToActivePage,
  generate,
  generateStream,
  fakeGenerate,
  createPrompt,
};
