import { WixCodePackageCodeReuse } from '@/toExtract/packages/packagesModalContext';
import {
  packageJsonReducerState as packageJsonReducerStateInternal,
  SetNpmPackage as SetNpmPackageInternal,
} from '@wix/wix-code-plugin-contracts';

import { ActionType } from '../actions/types';

export type packageJsonReducerState = packageJsonReducerStateInternal;
export type SetNpmPackage = SetNpmPackageInternal;

export type SetCodeReusePackage = {
  children: WixCodePackageCodeReuse[];
  type: ActionType.SET_CODE_REUSE_PKGS;
};
type packageJsonReducerAction = SetNpmPackageInternal | SetCodeReusePackage;

const initialState: packageJsonReducerStateInternal = {
  npmPackages: [],
  codeReusePkgs: [],
};

const packageJsonReducer = (
  state = initialState,
  action: packageJsonReducerAction,
) => {
  switch (action.type) {
    case ActionType.SET_NPM_PACKAGES:
      return {
        ...state,
        npmPackages: action.children,
      };
    case ActionType.SET_CODE_REUSE_PKGS:
      return { ...state, codeReusePkgs: action.children };

    default:
      return state;
  }
};

export default packageJsonReducer;
