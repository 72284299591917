export const getEditorType = () => {
  const type = (window as any)?.commonConfig?.host;
  switch (type) {
    case 'EDITOR_X':
      return 'EditorX';
    case 'BLOCKS':
      return 'Blocks';
    case 'STUDIO':
      return 'Studio';
    default:
      return 'Classic';
  }
};
