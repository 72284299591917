import { uniq } from 'lodash';
import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { fileSystem as fileSystemCommon } from '@wix/wix-code-common';
import {
  wixCodeLifecycleDuplexer,
  isDirectoryOrFile,
} from '@wix/wix-code-plugin-contracts';
import fileSystemActions from '@/infra/redux-state/actions/fileSystemActions';
import codeStateReader from '@/infra/redux-state/reducers/codeStateReader';
import storeManager from '@/infra/redux-state/store/storeManager';
import fileActions from '@/infra/redux-state/actions/fileActions';

export const FileSystemEntryPoint: EntryPoint = {
  name: 'File System Entry Point',
  getDependencyAPIs() {
    return [
      APIKeys.WixCodeStoreAPI,
      APIKeys.ClassicEditorAPI,
      APIKeys.WixCodeAppAPI,
      APIKeys.WixCodeDuplexerAPI,
    ];
  },
  declareAPIs() {
    return [APIKeys.FileSystemAPI];
  },
  attach(shell: Shell) {
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
    const storeAPI = shell.getAPI(APIKeys.WixCodeStoreAPI);
    const wixCodeAppAPI = shell.getAPI(APIKeys.WixCodeAppAPI);
    const wixCodeDuplexerAPI = shell.getAPI(APIKeys.WixCodeDuplexerAPI);

    const handleFileSystemError = (error: Error) => {
      editorAPI.panelManager.openPanel(
        'wixCode.panels.fileSystemOperationErrorPanel',
        { error },
      );
    };
    shell.contributeAPI(APIKeys.FileSystemAPI, () => {
      return {
        refreshFolder: (folderPath: string) =>
          storeAPI
            .dispatch(
              fileSystemActions.refreshFolderWithNotifyCache(folderPath),
            )
            .catch(handleFileSystemError),
        getChildren(folderPath: string) {
          return codeStateReader.getChildrenFileDescriptors(
            storeManager.getStore().getState(),
            folderPath,
          );
        },
        deleteFolder: async (folderPath: string) => {
          const descriptor = editorAPI.wixCode.fileSystem.getVirtualDescriptor(
            folderPath,
            true,
          );
          await editorAPI.wixCode.fileSystem
            .deleteItem(descriptor)
            .catch(handleFileSystemError);
        },
        deleteFile: async (filePath: string) => {
          const descriptor = editorAPI.wixCode.fileSystem.getVirtualDescriptor(
            filePath,
            false,
          );
          await editorAPI.wixCode.fileSystem
            .deleteItem(descriptor)
            .catch((e) => {
              handleFileSystemError(e);
              throw e;
            });
        },
        listenToExternalFileSystemChanges: () => {
          return wixCodeDuplexerAPI.subscribeTo.codeChanged(
            handleCodeChangedEvent,
          );
        },
        writeFile: (path: string, content: string) => {
          const descriptor = editorAPI.wixCode.fileSystem.getVirtualDescriptor(
            path,
            false,
          );
          return editorAPI.wixCode.fileSystem
            .writeFile(descriptor, content)
            .catch(handleFileSystemError);
        },
        isExists: async (
          fileOrDirPath: string,
          isDirectory: isDirectoryOrFile,
        ) => {
          try {
            const descriptor =
              editorAPI.wixCode.fileSystem.getVirtualDescriptor(
                fileOrDirPath,
                isDirectory,
              );
            const content = await editorAPI.wixCode.fileSystem.readFile(
              descriptor,
            );
            if (content === '') {
              return true;
            }
            return !!content;
          } catch (e) {
            return false;
          }
        },
        reloadAllFiles,
        registerToFileContentChanges: (path: string, listener: () => void) => {
          storeAPI.dispatch(fileActions.addFileListener(path, listener));

          return () =>
            storeAPI.dispatch(fileActions.removeFileListener(path, listener));
        },
      };
    });

    async function handleCodeChangedEvent(
      payload: wixCodeLifecycleDuplexer.CodeChangedEvent,
    ) {
      if (!isCodeChangedOnEditedGridApp()) {
        return;
      }

      const {
        createdOrUpdatedFiles = [],
        createdDirectories = [],
        deletedFiles = [],
        deletedDirectories = [],
        overwritten = false,
      } = payload;

      if (overwritten) {
        return reloadAllFiles();
      }

      const pathsToUpdate = getPathsToUpdate();

      if (pathsToUpdate.length > 0) {
        await editorAPI.wixCode.fileSystem.notifyFileChanges(
          pathsToUpdate as any,
        );
      }

      function isCodeChangedOnEditedGridApp() {
        return payload.gridAppId === wixCodeAppAPI.getGridAppId();
      }

      function getPathsToUpdate() {
        const modifiedPathsToUpdate = createdOrUpdatedFiles
          .concat(deletedFiles, deletedDirectories, createdDirectories)
          .reduce((result: string[], path: string) => {
            const parentPath = fileSystemCommon.getParentPath(path);
            result.push(path, getClosestParentThatNeedsRefresh(parentPath));
            return result;
          }, []);

        return uniq(modifiedPathsToUpdate);

        function getClosestParentThatNeedsRefresh(path: string): string {
          const parentPath = fileSystemCommon.getParentPath(path);
          const dirExists = isDirKnown(path);
          if (dirExists) {
            return path;
          }
          return getClosestParentThatNeedsRefresh(parentPath);
        }

        function isDirKnown(path: string) {
          return Boolean(
            codeStateReader
              .getDirs(storeAPI.getStore().getState())
              .find((dir) => dir.location === path),
          );
        }
      }
    }

    async function reloadAllFiles() {
      const roots = editorAPI.wixCode.fileSystem.getRoots();
      const currentPagePath = editorAPI.wixCode.fileSystem.getFileIdFromPageId(
        editorAPI.pages.getFocusedPageId(),
      );
      const paths = Object.values(roots)
        .map((root) => root.location)
        .concat(currentPagePath);
      await editorAPI.wixCode.fileSystem.notifyFileChanges(paths);
    }
  },
};
