import {
  FileValidationRule,
  SectionContribution,
} from '@wix/wix-code-plugin-contracts';
import { defaultStatuses, TreeSectionReducerState } from './treeSectionReducer';

function isSectionExpanded(
  state: { treeSection: TreeSectionReducerState },
  sectionName: string,
): boolean {
  const isExpanded =
    state.treeSection.statuses[sectionName]?.expanded ??
    defaultStatuses().expanded;
  return isExpanded;
}

function isSectionLoaded(
  state: { treeSection: TreeSectionReducerState },
  sectionName: string,
): boolean {
  return !!state.treeSection.statuses[sectionName]?.loaded;
}
function getSingelFileSections(state: {
  treeSection: TreeSectionReducerState;
}): string[] {
  return state.treeSection.singelFileSections;
}

function getContributedSections(state: {
  treeSection: TreeSectionReducerState;
}): SectionContribution[] {
  return state.treeSection.contributedSections;
}

function getContributedFileNameValidationRules(state: {
  treeSection: TreeSectionReducerState;
}): FileValidationRule[] {
  return state.treeSection.contributedValidationRules;
}

export {
  isSectionExpanded,
  isSectionLoaded,
  getSingelFileSections,
  getContributedSections,
  getContributedFileNameValidationRules,
};
