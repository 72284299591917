import dataHooks from '../../dataHooks';
import { FileIcon, ReadOnlyModeContext } from '@wix/wix-code-common-components';
import cx from 'classnames';
import {
  ContextMenu,
  ContextMenuAction,
  Tooltip,
  TreeListItem,
} from '@wix/wix-base-ui';
import React, { useContext } from 'react';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import { useTranslation } from '@wix/wix-i18n-config';
import {
  leftTreeClickOnMenuSettingIconItem,
  leftTreeClickInMenuSettingOptionItem,
  leftTreeClickOnAnItem,
} from '@wix/bi-logger-platform/v2';
import styles from './CssTreeListItem.scss';
import { Delete } from '@wix/wix-ui-icons-common';
import { InfoIcon } from '../InfoIcon/InfoIcon';
import { useBiLogger } from '../../../biLoggerContext';
import { constants } from '../../../constants';

interface CssTreeListItemProps {
  isFileSelected: boolean;
  editorAPI: EditorAPI;
  selectFile: () => void;
  onDeleteClick: () => void;
}

export const CssTreeListItem = ({
  isFileSelected,
  editorAPI,
  selectFile,
  onDeleteClick,
}: CssTreeListItemProps) => {
  const [t] = useTranslation();
  const biLoggerAPI = useBiLogger();
  const { readOnlyMode } = useContext(ReadOnlyModeContext);

  const onDeleteHandler = () => {
    onDeleteClick();
    biLoggerAPI.report(
      leftTreeClickInMenuSettingOptionItem({
        item_name: constants.global_css_name,
        item_type: 'file',
        menu_entry_name: 'DELETE',
      }),
    );
  };

  const onClickHandler = () => {
    selectFile();
    biLoggerAPI.report(
      leftTreeClickOnAnItem({
        item_name: constants.global_css_name,
        item_type: 'file',
        origin: 'left_tree',
      }),
    );
  };

  const onToggleHandler = (isOpen: boolean) =>
    isOpen &&
    biLoggerAPI.report(
      leftTreeClickOnMenuSettingIconItem({
        item_name: constants.global_css_path,
        item_type: 'file',
      }),
    );

  return (
    <TreeListItem
      dataHook={dataHooks.treeNode}
      label={t('WixCode_TreeView_CSS_GlobalCss_Fileame')}
      depth={0}
      selected={isFileSelected}
      icon={
        <FileIcon
          path="css"
          key="icon-file"
          className={cx('corvid-tree-prefix-icon', {
            highlight: isFileSelected,
          })}
          editorAPI={editorAPI}
        />
      }
      size="small"
      onClick={onClickHandler}
      suffix={
        <div className={styles.contextMenu}>
          <InfoIcon className={styles.infoIcon} />
          <Tooltip
            content={t('WixCode_TreeView_CSS_GlobalCss_ReadOnly_Tooltip_Text')}
            disabled={!readOnlyMode.fileSystem}
          >
            <ContextMenu
              stopPropagationOnMenuToggle
              onToggle={onToggleHandler}
              disabled={readOnlyMode.fileSystem}
            >
              <ContextMenuAction onClick={onDeleteHandler}>
                <Delete />
                <span className={styles.deleteText}>
                  {t('WixCode_TreeView_CSS_GlobalCss_ContextMenu_Delete')}
                </span>
              </ContextMenuAction>
            </ContextMenu>
          </Tooltip>
        </div>
      }
    />
  );
};
