import './leftPaneWithTabs.global.scss';
import React from 'react';
import leftPaneActions from '@/infra/redux-state/actions/leftPaneActions';
import leftPaneStateReader from '@/infra/redux-state/reducers/leftPaneStateReader';
import {
  PredefinedSidebarTabs,
  SideTabContribution,
} from '@wix/wix-code-plugin-contracts';
import bi from '@/legacy/bi/bi';
import { leftTreeClickTab } from '@/legacy/bi/biEvents';
import { Sidebar, TabDetails } from './Sidebar';
import {
  useEditorLegacyAPIs,
  connectToStores,
} from '@wix/wix-code-common-components';
import { useTranslate } from '@/infra/i18n/useTranslate';
import { experimentUtils } from '@wix/wix-code-common';

interface SidePaneTabsProps {
  iconOverrides: any;
  tabsDetails: SideTabContribution[];
  isVeloPhase0: boolean;
}

interface SidePaneTabsEditorReduxStateProps {
  leftPaneIsOpen: boolean;
  toggleLeftTree: () => void;
  sendBi: (tab: string) => void;
}

interface SidePaneTabsReduxStateProps {
  currentTab: PredefinedSidebarTabs;
}

interface SidePaneTabsReduxReduceProps {
  setCurrentTab: (tab: string) => void;
}

type SidePaneTabsCompProps = SidePaneTabsEditorReduxStateProps &
  SidePaneTabsProps &
  SidePaneTabsReduxStateProps &
  SidePaneTabsReduxReduceProps;

export type TabDetailsMap = { [key: string]: TabDetails };

const SidePaneTabsComp: React.FC<SidePaneTabsCompProps> = ({
  currentTab,
  leftPaneIsOpen,
  sendBi,
  setCurrentTab,
  toggleLeftTree,
  tabsDetails,
  isVeloPhase0,
}: SidePaneTabsCompProps) => {
  const { t } = useTranslate();
  const { editorAPI } = useEditorLegacyAPIs();
  const filteredTabs = tabsDetails.filter((tab) =>
    tab.shouldShowTab?.(editorAPI),
  );

  const extractDetails = (tabsToChange: SideTabContribution[]): TabDetailsMap =>
    tabsToChange.reduce((accumulator, val) => {
      const { creator, id, ...rest } = val;
      accumulator[id] = { ...rest, content: creator(), value: id };
      return accumulator;
    }, {} as TabDetailsMap);
  const topTabs = filteredTabs
    .filter((tab) => !tab.isBottomTab)
    .map((tab) => tab.id);
  const bottomTabs = experimentUtils.isMoveHelpTabToBottomOfSidebar()
    ? filteredTabs.filter((tab) => tab.isBottomTab).map((tab) => tab.id)
    : [];
  const tabDetails: TabDetailsMap = extractDetails(filteredTabs);

  return (
    <Sidebar
      currentTab={currentTab}
      leftPaneIsOpen={leftPaneIsOpen}
      sendTabChangeBi={sendBi}
      setCurrentTab={setCurrentTab}
      t={t}
      tabDetails={tabDetails}
      toggleLeftTree={toggleLeftTree}
      topTabs={topTabs}
      bottomTabs={bottomTabs}
      isVeloPhase0={isVeloPhase0}
    />
  );
};

const mapCodeStateToProps = (
  state: AnyFixMe,
  { tabsDetails }: SidePaneTabsProps,
) => {
  const selectedTab = leftPaneStateReader.getLeftPaneSelectedTab(state);
  const selectedTabExists = tabsDetails.map((t) => t.id).includes(selectedTab);
  const fallbackTab = tabsDetails[0].id;
  const currentTab = selectedTabExists ? selectedTab : fallbackTab;
  return { currentTab };
};

const mapCodeDispatchToProps = (dispatch: AnyFixMe) => {
  const setCurrentTab = (tabId: AnyFixMe) =>
    dispatch(leftPaneActions.selectLeftPaneTab(tabId));

  return {
    setCurrentTab,
  };
};

const mapEditorStateToProps = ({
  editorAPI,
}: AnyFixMe): SidePaneTabsEditorReduxStateProps => ({
  leftPaneIsOpen: editorAPI.developerMode.ui.treePane.isOpen(),
  toggleLeftTree: editorAPI.developerMode.ui.treePane.toggle,
  sendBi: (tab) =>
    editorAPI.bi.event(
      bi.events.LEFT_TREE_CHANGE_CURRENT_TAB,
      leftTreeClickTab(tab),
    ),
});

let ConnectedComponent = null as any;
export const SidePaneTabs: React.FC<SidePaneTabsProps> = (
  origProps: SidePaneTabsProps,
) => {
  const {
    legacyDependenciesAPI: { util },
  } = useEditorLegacyAPIs();
  if (ConnectedComponent === null) {
    ConnectedComponent = connectToStores<SidePaneTabsProps>({
      util,
      mapEditorStateToProps,
      mapCodeStateToProps,
      mapCodeDispatchToProps,
      comp: SidePaneTabsComp,
    });
  }
  return <ConnectedComponent {...origProps} />;
};
