import React from 'react';
import { TreeSectionDivider } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import { SectionEmptyState } from '../SectionEmptyState/SectionEmptyState';
import dataHooks from '../../dataHooks';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import { CssTreeListItem } from '../CssTreeListItem/CssTreeListItem';
import { constants } from '../../../constants';
import { leftTreeClickOnASection } from '@wix/bi-logger-platform/v2';
import { useBiLogger } from '../../../biLoggerContext';
import { CssEditingInternalAPI } from '../../../cssEditingInternalAPI';

export interface CssEditingSectionStateProps {
  shouldShowBadge: boolean;
  expanded: boolean;
}

export interface CssEditingSectionDispatchProps {
  createCssFileAndOpen: CssEditingInternalAPI['createCssFileAndOpen'];
  selectFile: () => void;
  onDeleteClick: () => void;
  setIsExpanded: (isExpanded: boolean) => void;
}

export interface CssEditingSectionEditorProps {
  isFileSelected: boolean;
  editorAPI: EditorAPI;
}
export interface CssEditingSectionReduxProps {
  isCssFileExists: boolean;
}

export const SectionContent: React.FC<
  CssEditingSectionStateProps &
    CssEditingSectionDispatchProps &
    CssEditingSectionEditorProps &
    CssEditingSectionReduxProps
> = ({
  createCssFileAndOpen,
  selectFile,
  isFileSelected,
  editorAPI,
  shouldShowBadge,
  onDeleteClick,
  setIsExpanded,
  expanded,
  isCssFileExists,
}) => {
  const [t] = useTranslation();
  const biLoggerAPI = useBiLogger();
  const addGlobalCss = async () => {
    await createCssFileAndOpen(
      `${t('WixCode_CodePanel_Editor_GlobalCss_DefaultText_CssReference')} ${t(
        'WixCode_CodePanel_Editor_GlobalCss_DefaultText_CssIntro',
      )}`,
    );
  };

  const sectionClickHandler = () => {
    biLoggerAPI.report(
      leftTreeClickOnASection({
        action: expanded ? 'open' : 'close',
        section_name: constants.css,
      }),
    );
    setIsExpanded(!expanded);
  };

  return (
    <div data-hook={dataHooks.sectionRoot}>
      <TreeSectionDivider
        collapsed={!expanded}
        label={t('WixCode_TreeView_CSS_Section')}
        onClick={sectionClickHandler}
        dataHook={dataHooks.sectionHeader}
      />
      {expanded &&
        (isCssFileExists ? (
          <CssTreeListItem
            isFileSelected={isFileSelected}
            editorAPI={editorAPI}
            selectFile={selectFile}
            onDeleteClick={onDeleteClick}
          />
        ) : (
          <SectionEmptyState
            shouldShowBadge={shouldShowBadge}
            onCtaClick={() => addGlobalCss()}
          />
        ))}
    </div>
  );
};
