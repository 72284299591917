import './jsonVisualizer.global.scss';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import {
  prepareTableForCopy,
  toTableView,
  stringifyPrimitive,
  formatPrimitiveForTable,
  isLabelCell,
} from './jsonVisualizerUtil';
import once_ from 'lodash/once';

const VIEW_JSON = 'json';
const VIEW_TABLE = 'table';

const supportsCopy = !!navigator.clipboard && !!navigator.clipboard.writeText;

export default once_(({ util }) => {
  const TRANSLATED_COPY_JSON = util.translate('WixCode_Console_Copy_JSON');
  const TRANSLATED_COPY_TABLE = util.translate('WixCode_Console_Copy_Table');
  const TRANSLATED_COPIED = util.translate(
    'WixCode_Console_Successfully_Copied',
  );
  const TRANSLATED_TABLE = util.translate('WixCode_Console_Table_View');
  const TRANSLATED_JSON = 'json';

  const JsonTree = createReactClass({
    displayName: 'JsonTree',

    propTypes: {
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.oneOf([null]),
      ]),
      defaultView: PropTypes.oneOf([VIEW_JSON, VIEW_TABLE]),
    },

    getInitialState() {
      return {
        collapsed: true,
        view: this.props.defaultView || VIEW_JSON,
        copying: false,
      };
    },

    toggle(event) {
      // The custom scroll component catches our click as well
      // and sometimes updates the scroll position to the wrong one
      // https://github.com/rommguy/react-custom-scroll/issues/42
      event.stopPropagation();
      this.setState({ collapsed: !this.state.collapsed });
    },

    copy() {
      if (this.state.view === VIEW_JSON) {
        navigator.clipboard.writeText(
          JSON.stringify(this.props.value, undefined, 2),
        );
      } else {
        navigator.clipboard.writeText(prepareTableForCopy(this.props.value));
      }
      this.setState({ copying: true });
      setTimeout(() => {
        this.setState({ copying: false });
      }, 2500);
    },

    changeView(event, view) {
      // The custom scroll component catches our click as well
      // and sometimes updates the scroll position to the wrong one
      // https://github.com/rommguy/react-custom-scroll/issues/42
      event.stopPropagation();
      this.setState({ view });
    },

    renderValueLine() {
      return (
        <div
          onClick={this.toggle}
          className={classNames({
            root: true,
            clickable: _.isObject(this.props.value),
            'non-clickable-label':
              !_.isObject(this.props.value) && this.props.label,
            'non-clickable-no-label':
              !_.isObject(this.props.value) && !this.props.label,
          })}
        >
          <div
            className={classNames({
              arrow: true,
              'arrow-collapsed': this.state.collapsed,
              transparent: !_.isObject(this.props.value),
            })}
          />

          <div className="label">
            {_.isUndefined(this.props.label) ? '' : `${this.props.label}: `}
          </div>

          {_.isArray(this.props.value) && (
            <div key="array-value" className="preview-expandable">
              {this.state.collapsed
                ? '[...]'
                : `Array(${this.props.value.length})`}
            </div>
          )}
          {_.isPlainObject(this.props.value) && (
            <div key="object-value" className="preview-expandable">
              {'{...}'}
            </div>
          )}
          {!_.isObject(this.props.value) && (
            <span key="primitive-value" className="preview-value">
              {stringifyPrimitive(this.props.value)}
            </span>
          )}
        </div>
      );
    },

    renderControls() {
      return (
        <div className="view">
          <span
            className={classNames({
              tab: true,
              selected: this.state.view === VIEW_JSON,
            })}
            onClick={(ev) => this.changeView(ev, VIEW_JSON)}
          >
            {TRANSLATED_JSON}
          </span>
          <span
            className={classNames({
              tab: true,
              selected: this.state.view === VIEW_TABLE,
            })}
            onClick={(ev) => this.changeView(ev, VIEW_TABLE)}
          >
            {TRANSLATED_TABLE}
          </span>
          {supportsCopy && (
            <span className="copy" onClick={(ev) => this.copy(ev)}>
              {this.state.copying
                ? TRANSLATED_COPIED
                : this.state.view === VIEW_JSON
                ? TRANSLATED_COPY_JSON
                : TRANSLATED_COPY_TABLE}
            </span>
          )}
        </div>
      );
    },

    renderJson() {
      return (
        <div key="json-children" className="values">
          {Object.entries(this.props.value).map((pair) => (
            <JsonTree label={pair[0]} value={pair[1]} key={pair[0]} />
          ))}
        </div>
      );
    },

    renderTable() {
      return (
        <div>
          {toTableView(this.props.value).map((view, index) => (
            <table key={`json-table-${index}`}>
              <tbody>
                {view.matrix.map((line, lineIndex) => (
                  <tr key={`line-${lineIndex}`}>
                    {line.map((cell, cellIndex) => (
                      <td key={`cell-${lineIndex}-${cellIndex}`}>
                        {!_.isObject(cell) ? (
                          <span
                            className={classNames({
                              'cell-value': true,
                              'label-cell': isLabelCell(
                                view,
                                lineIndex,
                                cellIndex,
                              ),
                            })}
                          >
                            {formatPrimitiveForTable(cell)}
                          </span>
                        ) : (
                          <JsonTree value={cell} defaultView={VIEW_TABLE} />
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
        </div>
      );
    },

    render() {
      return (
        <div className="wix-code-json-visualizer">
          {this.renderValueLine()}
          {!this.state.collapsed && _.isObject(this.props.value) && (
            <Fragment>
              {this.renderControls()}
              {this.state.view === VIEW_JSON
                ? this.renderJson()
                : this.renderTable()}
            </Fragment>
          )}
        </div>
      );
    },
  });

  return JsonTree;
});
