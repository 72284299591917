import * as React from 'react';
import { TextLabel } from '@wix/wix-base-ui';
import s from './PackageMarkDownHeader.scss';
import { PackagesModalContext } from '../../../packagesModalContext';
import dataHooks from '../../../dataHooks';
import { PkgActionButton } from '../pkgLists/packagesListItem/pkgActionButtons/PkgActionButton';
import { getVersionDisplayText } from '../../../utils';
import { getSelectedVersion } from '../../packagesModalSelectors';
import { useContext } from 'react';
import { PackageItemContext } from '../pkgLists/PackageItemContext';
import { CodeReusePkgVersion } from '../../../codeReuseServerAPI';
import { VersionActionButton } from '../pkgLists/packagesListItem/pkgActionButtons/VersionActionButton';
import { shouldShowSubmitRequestPanel } from '../pkgLists/npmPackagesList/selectors/modulesSelectors';
import { useSelector } from '@wix/wix-code-common-components';

interface PackageMarkdownHeaderFirstRowProps {
  allVersions: CodeReusePkgVersion[];
}
export const PackageMarkdownHeaderFirstRow: React.FC<
  PackageMarkdownHeaderFirstRowProps
> = ({ allVersions }) => {
  const { selectedPackage, packagesService, t, selectedNpmPkgData } =
    React.useContext(PackagesModalContext);

  const npmPackageRequestShowing = useSelector(shouldShowSubmitRequestPanel);

  const { installedPkg, availablePkg, isCodeReusePackage } =
    useContext(PackageItemContext);

  const pkgInfo = packagesService.getCodeReusePkgInfo(selectedPackage);

  const selectedPackageVersion = useSelector(getSelectedVersion);

  const shouldShowLicense = !isCodeReusePackage && !!pkgInfo?.license;

  const packageName = !isCodeReusePackage
    ? selectedPackage
    : pkgInfo?.title || selectedPackage;

  const packageData = installedPkg || availablePkg || selectedNpmPkgData;
  const versionPart = getVersionDisplayText(
    packageData,
    t,
    selectedPackageVersion,
  );

  return (
    <div className={s.title}>
      <div>
        <TextLabel
          type="T09"
          shouldTranslate={false}
          value={packageName}
          dataHook={dataHooks.PKG_MANAGER_README_PKG_NAME}
        />
        <TextLabel
          type="T12"
          shouldTranslate={false}
          value={versionPart}
          className={s.version}
          dataHook={dataHooks.PKG_MANAGER_README_PKG_VERSION}
        />
        {shouldShowLicense && (
          <TextLabel
            type="T12"
            shouldTranslate={false}
            value={pkgInfo!.license!}
            className={s.version}
          />
        )}
      </div>
      <div className={s.action} data-aid="readme-package-button">
        {selectedPackageVersion ? (
          <VersionActionButton
            version={selectedPackageVersion}
            allVersions={allVersions}
          />
        ) : npmPackageRequestShowing ? null : (
          <PkgActionButton />
        )}
      </div>
    </div>
  );
};
