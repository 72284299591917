import React from 'react';
import { Tooltip, RichText } from '@wix/wix-base-ui';
import { PackagesModalContext } from '../../../../../packagesModalContext';
import { DisabledInstallPkgButton } from './DisabledInstallPkgButton';

export const OtherVersionInstalledButton: React.FC = () => {
  const { t } = React.useContext(PackagesModalContext);
  return (
    <Tooltip
      content={
        <RichText>
          {t('WixCode_PackageManager_To_Install_Remove_Auto_Update')}
        </RichText>
      }
      translate={false}
    >
      <DisabledInstallPkgButton />
    </Tooltip>
  );
};
