import { NpmSearchIndexReaderError } from './errors';
import { queryNpmPackageDetails } from '@wix/ambassador-velo-npm-v1-npm-package-details/http';
import {
  QueryNpmPackageDetailsRequest,
  QueryV2,
} from '@wix/ambassador-velo-npm-v1-npm-package-details/types';
import { HttpClient } from '@wix/http-client';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';

interface NpmIndexFacdeParams {
  editorAPI: EditorAPI;
}

interface searchParams {
  keyword: string;
  limit?: number;
}
const NpmServiceFacade = ({ editorAPI }: NpmIndexFacdeParams) => {
  const httpClient = new HttpClient({
    getAppToken: () => editorAPI.wixCode.getClientSpec().instance,
  });

  const anOrFilterClause = (packageNames: string[]) =>
    packageNames.map((name) => ({ name: { $eq: name } }));

  const search = async ({ keyword, limit = 50 }: searchParams) => {
    const query: QueryV2 = {
      filter: { term: { $contains: keyword } },
      paging: { limit, offset: 0 },
    };
    const payload: QueryNpmPackageDetailsRequest = {
      query,
    };
    try {
      const response = await httpClient.request(
        queryNpmPackageDetails(payload),
      );

      return response;
    } catch (error) {
      throw new NpmSearchIndexReaderError(error);
    }
  };

  const get = async (packageNames: string[]) => {
    const query: QueryV2 = {
      filter: { $or: anOrFilterClause(packageNames) },
    };
    const payload: QueryNpmPackageDetailsRequest = {
      query,
    };
    try {
      const response = await httpClient.request(
        queryNpmPackageDetails(payload),
      );

      return response;
    } catch (error) {
      throw new NpmSearchIndexReaderError(error);
    }
  };

  return {
    search,
    get,
  };
};

export { NpmServiceFacade };
