// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rK26Ap{display:flex;justify-content:flex-start;padding:10px}.qcW4Eh{display:flex;flex-direction:column;align-items:flex-start;margin-left:10px}.mNYVOx{margin-top:5px;margin-left:5px;display:flex;flex-direction:column;margin-bottom:3px}.VWyyhK{display:flex;flex-direction:column;align-items:flex-start;width:600px;margin-top:12px}.JthxrU{width:100%}.rKytcY{padding-left:0px !important;width:50%;margin-top:30px;position:relative;display:flex;flex-direction:column}.clOirq{margin-bottom:8px}.ZPtpcu{position:absolute;right:0px;top:6px}.IpxXlx{padding-top:18px;padding-bottom:18px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBannerContainer": "rK26Ap",
	"infoBannerTextContainer": "qcW4Eh",
	"margin": "mNYVOx",
	"detailsModalContainer": "VWyyhK",
	"textInput": "JthxrU",
	"dropdown": "rKytcY",
	"marginBottom": "clOirq",
	"dropdownInfoIcon": "ZPtpcu",
	"detailsModal": "IpxXlx"
};
export default ___CSS_LOADER_EXPORT___;
