import { useEffect, useState } from 'react';
import { Schema } from '../../internal-types';
import { experimentUtils } from '@wix/wix-code-common';
import { FORMS_APP_ID, FORMS_NAMESPACE } from './CollectionsTree';

export const useModifySchemas = (schemas: Schema[]) => {
  const [allSchemas, setAllSchemas] = useState<Schema[]>([]);

  useEffect(() => {
    if (experimentUtils.isMoveFormsToNewNamespace()) {
      const allSchemasWithNewForms = schemas.map((schema: Schema) => {
        return schema.ownerAppId === FORMS_APP_ID
          ? { ...schema, namespace: FORMS_NAMESPACE }
          : schema;
      });
      setAllSchemas(allSchemasWithNewForms);
    } else {
      setAllSchemas(schemas);
    }
  }, [schemas]);

  return allSchemas;
};
