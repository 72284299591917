// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YkiGXd{color:#de9c03}.swlxxC{color:#dfe6ef}.imPYlY{color:#3178c6}.PymFsf{color:#06a2ad}.G2t_5r path{fill:currentColor !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"js": "YkiGXd",
	"jsx": "swlxxC",
	"ts": "imPYlY",
	"jsw": "PymFsf",
	"currentColor": "G2t_5r"
};
export default ___CSS_LOADER_EXPORT___;
