import { PackagesAndAppsAPI } from '@wix/wix-code-plugin-contracts';
import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { ExternalPackagesAndAppsAPIKey } from './externalApiKeys';

export type ExternalPackagesAndAppsAPI = {
  openReadme: PackagesAndAppsAPI['openReadme'];
};

export const ExternalPackagesAndAppsEntryPoint: EntryPoint = {
  name: 'External Packages and Apps Tree',
  getDependencyAPIs() {
    return [APIKeys.PackagesAndAppsAPI];
  },
  declareAPIs() {
    return [ExternalPackagesAndAppsAPIKey];
  },
  attach(shell: Shell) {
    shell.contributeAPI(ExternalPackagesAndAppsAPIKey, () => {
      const { openReadme } = shell.getAPI(APIKeys.PackagesAndAppsAPI);
      return {
        openReadme,
      };
    });
  },
};
