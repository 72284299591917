import s from './NpmPackageListContainer.scss';
import * as React from 'react';
import * as redux from 'redux';
import { connect } from 'react-redux';

import { wixCodeReduxContext } from '@wix/wix-code-common-components';
import {
  NpmPackageInfo,
  PackagesModalContext,
} from '../../../../../packagesModalContext';
import { NpmPkgsList } from './NpmPkgsList/NpmPkgsList';
import {
  getSearchKeyword,
  shouldPromptError,
  shouldShowSubmitRequestPanel,
  getIndexSearchIsFetching,
  getDefaultNpmPackages,
} from '../selectors/modulesSelectors';
import { init, performIndexSearch } from '../actions/modulesActions';
import dataHooks from '../../../../../dataHooks';
import { AppState } from '@/infra/redux-state/reducers/rootReducer';

interface NpmPackageListContainerProps {
  searchKeyword: string;
  hasError: boolean;
  showSubmitRequest: boolean;
  indexSearchIsFetching: boolean;
  defaultNpmPackages: NpmPackageInfo[];
  init: typeof init;
  performIndexSearch: (
    keyword: string,
  ) => (dispatch: any, getState: () => any) => Promise<void>;
}

const NpmPackageListContainer: React.FC<NpmPackageListContainerProps> = ({
  searchKeyword,
  performIndexSearch: search,
  indexSearchIsFetching,
  defaultNpmPackages,
}) => {
  const { selectedPackage } = React.useContext(PackagesModalContext);

  return (
    <div
      className={s.npmPackagesContainer}
      data-hook={dataHooks.NPM_PKGS_CONTAINER}
    >
      <NpmPkgsList
        searchKeyword={searchKeyword}
        onSearch={search}
        indexSearchIsFetching={indexSearchIsFetching}
        defaultNpmPackages={defaultNpmPackages}
        selectedPackage={selectedPackage}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  searchKeyword: getSearchKeyword(state),
  hasError: shouldPromptError(state),
  showSubmitRequest: shouldShowSubmitRequestPanel(state),
  indexSearchIsFetching: getIndexSearchIsFetching(state),
  defaultNpmPackages: getDefaultNpmPackages(state),
});

const mapDispatchToProps = (dispatch: AnyFixMe) =>
  redux.bindActionCreators(
    {
      init,
      performIndexSearch,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps, null, {
  context: wixCodeReduxContext,
})(NpmPackageListContainer);
