import React from 'react';
import { PanelsAPI, UserPreferencesAPI } from '@wix/wix-code-plugin-contracts';
import { MessagePanel } from '@/commonComponents/panels/messagePanel/MessagePanel';
import { LITE_TO_FALLBACK_PREFERENCE_KEY } from '../constants';
import { Text, TextProps } from '@wix/wix-base-ui';
import { TFunc } from '@/infra/i18n/i18nService';
import s from './liteToFallbackPanel.scss';

export interface LiteToFallbackModalProps {
  panelsAPI: PanelsAPI;
  userPreferencesAPI: UserPreferencesAPI;
  t: TFunc;
}

export const LiteToFallbackModal: React.FC<LiteToFallbackModalProps> = ({
  panelsAPI,
  userPreferencesAPI,
  t,
}) => {
  const confirm = () => {
    userPreferencesAPI.setGlobalPreference(
      LITE_TO_FALLBACK_PREFERENCE_KEY,
      true,
    );
    panelsAPI.closePanel();
  };

  const TextWithoutEllipsis = (
    props: Omit<typeof TextProps, 'enableEllipsis'>,
  ) => <Text enableEllipsis={false} {...props} />;

  return (
    <MessagePanel
      title={t('user_notifications.lite_to_full.title')}
      confirmLabel={t('user_notifications.lite_to_full.cta')}
      onConfirm={confirm}
      onCancel={confirm}
      panelClassName={s.content}
      panelName="lite-to-fallback"
      content={
        <>
          <TextWithoutEllipsis>
            {t('user_notifications.lite_to_full.content_heading')}
          </TextWithoutEllipsis>
          <div className={s.itemList}>
            <div>
              <TextWithoutEllipsis>
                &bull; {t('user_notifications.lite_to_full.content_item1')}
              </TextWithoutEllipsis>
            </div>
            <div>
              <TextWithoutEllipsis>
                &bull; {t('user_notifications.lite_to_full.content_item2')}
              </TextWithoutEllipsis>
            </div>
          </div>
          <TextWithoutEllipsis>
            {t('user_notifications.lite_to_full.content_foot')}
          </TextWithoutEllipsis>
        </>
      }
    />
  );
};
