import * as React from 'react';
import s from './packagesModalMenu.scss';
import { TextLabel, VerticalTabs, Divider } from '@wix/wix-base-ui';
import dataHooks from '../../../dataHooks';
import { useContext } from 'react';
import { PackagesModalContext } from '../../../packagesModalContext';
import { MODAL_TABS } from '../../packagesModalConsts';
import { ModalTab } from './PackagesModalMenu';

export const PackagesModalMenuV2: React.FC = () => {
  const { selectTab, currentTab, isBlocksCombinedMode, t } =
    React.useContext(PackagesModalContext);
  const { showCodeReuse } = useContext(PackagesModalContext);

  const builtByVeloTab: ModalTab = {
    value: MODAL_TABS.BUILT_BY_VELO,
    label: isBlocksCombinedMode
      ? t('Package_Manager_Section_Install_Built_By_Velo_Apps')
      : t('Package_Manager_Section_Install_Built_By_Velo'),
    dataHook: dataHooks.PKG_MANAGER_BUILT_BY_VELO_TAB,
  };
  const myPackagesTab: ModalTab = {
    value: MODAL_TABS.MY_PACKAGES,
    label: t('Package_Manager_Section_Install_My_Packages'),
    dataHook: dataHooks.PKG_MANAGER_MY_PACKAGES_TAB,
  };
  const npmPackagesTab: ModalTab = {
    value: MODAL_TABS.NPM_PACKAGES,
    label: t('Package_Manager_Section_Discover_npm'),
    dataHook: dataHooks.PKG_MANAGER_NPM_TAB,
  };
  const installedTab: ModalTab = {
    value: MODAL_TABS.INSTALLED_PACKAGES,
    label: isBlocksCombinedMode
      ? t('Package_Manager_Section_Installed')
      : t('Package_Manager_Section_Manage_Installed'),
    dataHook: dataHooks.PKG_MANAGER_INSTALLED_TAB,
  };
  const veloPackagesTabs = isBlocksCombinedMode
    ? [builtByVeloTab]
    : [builtByVeloTab, myPackagesTab];

  const externalPackagesTabs = [npmPackagesTab];

  const manageTabs = [installedTab];
  const divider = { value: VerticalTabs.DIVIDER_VALUE, label: '' };
  const combinedModeTabs = [
    ...externalPackagesTabs,
    ...(showCodeReuse ? veloPackagesTabs : []),
    divider,
    ...manageTabs,
  ];

  return (
    <div className={s.packagesModalMenu} data-hook={dataHooks.PKG_MODAL_MENU}>
      {isBlocksCombinedMode ? (
        <VerticalTabs
          data-hook={dataHooks.PKG_MANAGER_MANAGE_TAB_SECTION}
          value={currentTab}
          onChange={selectTab}
          options={combinedModeTabs}
          maxWidth={250}
        />
      ) : (
        <>
          {showCodeReuse ? (
            <MenuSection
              dataAid="install-packages"
              title={`${t('Package_Manager_Section_Install_Title_Old')}`}
              tabs={veloPackagesTabs}
            />
          ) : null}

          <MenuSection
            dataAid="external-packages"
            title={`${t('Package_Manager_Section_External_Title')}`}
            tabs={externalPackagesTabs}
          />

          <div className={s.dividerContainer}>
            <Divider long />
          </div>

          <MenuSection
            dataAid="manage-packages"
            title={`${t('Package_Manager_Section_Manage_Title')}`}
            tabs={manageTabs}
          />
        </>
      )}
    </div>
  );
};

type MenuSectionProps = { title: string; dataAid: string; tabs: ModalTab[] };

const MenuSection: React.FC<MenuSectionProps> = (props) => {
  const { selectTab, currentTab } = React.useContext(PackagesModalContext);

  return (
    <div data-aid={props.dataAid}>
      <TextLabel className={s.label} type="T14" value={props.title} />
      <VerticalTabs
        data-hook={dataHooks.PKG_MANAGER_MANAGE_TAB_SECTION}
        className={s.tabs}
        value={currentTab}
        onChange={selectTab}
        options={props.tabs}
        maxWidth={250}
      />
    </div>
  );
};
