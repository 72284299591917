// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I3pfJF{display:flex;flex-direction:row;padding:16px 18px 16px 24px;border-bottom:1px solid #dfe5eb}.I3pfJF .P4h3e2{margin-right:8px;display:flex;align-items:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": "I3pfJF",
	"description": "P4h3e2"
};
export default ___CSS_LOADER_EXPORT___;
