import React, { useEffect } from 'react';
import {
  EditorAPI,
  FileTreeRootProps,
  FilesTreeAPI,
  LegacyEditorDependencies,
  SectionProps,
} from '@wix/wix-code-plugin-contracts';
import {
  ReadOnlyModeContext,
  TranslationProvider,
  useTranslation,
} from '@wix/wix-code-common-components';

type ExternalComponentSectionProps = {
  filesTreeAPI: FilesTreeAPI;
  editorAPI: EditorAPI;
  legacyDependenciesAPI: LegacyEditorDependencies;
};

type ExternalComponentsProps = {
  FileTreeRoot: React.FC<FileTreeRootProps>;
  path: string;
  readOnlyMode: boolean;
};

const createSideBarSection =
  (apis: ExternalComponentSectionProps) => (_props: SectionProps) => {
    return <Section {...apis} />;
  };
export default createSideBarSection;

const Section: React.FC<ExternalComponentSectionProps> = ({
  filesTreeAPI,
  editorAPI,
  legacyDependenciesAPI,
}) => {
  const { readOnlyMode } = React.useContext(ReadOnlyModeContext);
  useEffect(() => filesTreeAPI.expandSection(path), []);

  const path = editorAPI.wixCode.fileSystem.getRoots().components.location;
  const FileTreeRoot = filesTreeAPI.FileTreeRoot;

  return (
    <TranslationProvider
      options={{
        locale: legacyDependenciesAPI.util.editorModel.languageCode,
        disableAutoInit: true,
        asyncMessagesLoader: (locale: string) =>
          import(`../assets/locale/messages_${locale}.json`),
      }}
    >
      <ExternalComponents
        FileTreeRoot={FileTreeRoot}
        path={path}
        readOnlyMode={!!readOnlyMode.sidePanel?.publicAndBackend}
      />
    </TranslationProvider>
  );
};

const ExternalComponents = ({
  FileTreeRoot,
  path,
  readOnlyMode,
}: ExternalComponentsProps) => {
  const [t] = useTranslation();
  return (
    <FileTreeRoot
      id={path}
      labelOverride={t(
        'Left_Tree_Components_Category_CodeFiles_ExternalComponents',
      )}
      isBackend={false}
      isExternalComponents={true}
      readOnlyMode={readOnlyMode}
    />
  );
};
