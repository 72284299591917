import {
  FULL_WIX_CODE_DECLARATION_BETA_FILENAME,
  FULL_WIX_CODE_DECLARATION_FILENAME,
  MODULE_LIST_FILENAME,
  COMPONENTS_DATA_JSON_FILENAME,
  DEST_JSONS_PATH,
  WEB_WORKER_LIB,
  TARGET_ES_LIB,
  MODULE_LIST_BETA_FILENAME,
} from '@wix/wix-code-common';

export const WIX_CODE_TYPES_URL = 'https://unpkg.com/@wix/wix-code-types@~2/';

export const BASE_LIBS = {
  BACKEND: [TARGET_ES_LIB],
  PUBLIC: [TARGET_ES_LIB, WEB_WORKER_LIB],
  PAGE: [TARGET_ES_LIB, WEB_WORKER_LIB],
};

export const WIX_CODE_TYPES = {
  URL: WIX_CODE_TYPES_URL,
  PATHS: {
    WIX_MODULES: `${DEST_JSONS_PATH}/${MODULE_LIST_FILENAME}`,
    WIX_MODULES_BETA: `${DEST_JSONS_PATH}/${MODULE_LIST_BETA_FILENAME}`,
    COMPONENTS_DATA: `${DEST_JSONS_PATH}/${COMPONENTS_DATA_JSON_FILENAME}`,
    FULL_TYPES: `${DEST_JSONS_PATH}/${FULL_WIX_CODE_DECLARATION_FILENAME}`,
    FULL_TYPES_BETA: `${DEST_JSONS_PATH}/${FULL_WIX_CODE_DECLARATION_BETA_FILENAME}`,
  },
};

export const DYNAMIC_TYPES = {
  ELEMENTS_MAP_FILENAME: 'elementsMap.d.ts',
  NPM_DEPENDENCIES_FOLDER: '/dependencies',
};

export default {
  BASE_LIBS,
  DYNAMIC_TYPES,
  WIX_CODE_TYPES,
  WIX_CODE_TYPES_URL,
};
