import React, { useContext } from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { dataHooks } from '../../consts/dataHooks';
import { EmptyStateWrapper } from '../EmptyState/EmptyStateWrapper';
import { SpiContext } from '../../SpiContext';
import { spiFolderDisplayName } from '../../SpiService';
import {
  NodeIcon,
  SectionProps,
  TreeNodeType,
} from '@wix/wix-code-plugin-contracts';
import { spiStatusesTooltips } from './spiStatusesTooltips';
import { spiLeftTreeActions } from './spiLeftTreeActions';
import { SpiConnection } from '../../ServerAPI';
import { useOnMount } from '../../utils';
import { Shell } from '@wix/wix-code-repluggable';
import { connectWithShell } from 'repluggable';
import { SpiStoreState } from '../../state';
import { spiFolderPath } from '../../SpiTypes';
import { SpiExternalIdeOnboarding } from './SpiExternalIdeOnboarding';
import { ReadOnlyModeContext } from '@wix/wix-code-common-components';
import { Experiments } from '@wix/wix-code-common';

enum ValidationState {
  'VALID' = 'VALID',
  'INVALID' = 'INVALID',
}

type SpiLeftTreeSectionStateProps = {
  spiStatuses: SpiConnection[];
  isLiteEditor: boolean;
  openWixIDE: () => void;
};

export const SpiLeftTreeSectionComp: React.FC<
  SpiLeftTreeSectionStateProps & SectionProps
> = ({ isLast, spiStatuses, isLiteEditor, openWixIDE }) => {
  const [t] = useTranslation();
  const { spiService, filesTreeAPI, experimentsAPI, biLoggerAPI } =
    useContext(SpiContext);
  const {
    readOnlyMode: { sidePanel },
  } = useContext(ReadOnlyModeContext);

  useOnMount(() => {
    if (spiService.spiFolderExists()) {
      filesTreeAPI.expandSection(spiFolderPath);
    }
  });

  const FileTreeRoot = filesTreeAPI.FileTreeRoot;

  const finalSpiStatuses: NodeIcon[] = spiStatuses?.map(
    (spiStatus: SpiConnection) => ({
      predicate: (nodePath: string) =>
        nodePath === spiStatus.path!.slice(1) + '/',
      nodeType: TreeNodeType.FOLDER,
      icon:
        spiStatus.configValidityState === ValidationState.INVALID ||
        spiStatus.implementationValidityState === ValidationState.INVALID
          ? spiStatusesTooltips.error
          : spiStatus.isSpiConnected
          ? spiStatusesTooltips.connected
          : spiStatus.isSpiPublished
          ? spiStatusesTooltips.publishedNotConnected
          : null,
    }),
  );

  const leftTreeActions = spiLeftTreeActions(
    t,
    spiService,
    experimentsAPI,
    biLoggerAPI,
  );
  const readOnlyMode = experimentsAPI.isOpen(Experiments.VeloInStudio)
    ? sidePanel?.packages
    : sidePanel?.publicAndBackend;

  return spiService.spiFolderExists() &&
    spiService.getSpiFolders().length > 0 ? (
    isLiteEditor ? (
      <SpiExternalIdeOnboarding
        amountOfSpis={spiService.getSpiFolders().length}
        openWixIDE={openWixIDE}
      />
    ) : (
      <FileTreeRoot
        id={spiFolderPath}
        isBackend={false}
        labelOverride={t('Left_Tree_Section_Label')}
        hiddenRootActions={leftTreeActions.hiddenRootActions}
        customRootActions={leftTreeActions.customRootActions()}
        hiddenFolderActions={leftTreeActions.hiddenFolderActions}
        customFolderActions={leftTreeActions.customFolderActions}
        hiddenFileActionsByPredicate={
          leftTreeActions.hiddenFileActionsByPredicate
        }
        isLast={isLast}
        sectionDataHook={dataHooks.LEFT_TREE_SPI_SECTION}
        customNodeIcons={finalSpiStatuses}
        customBiReportSectionName={spiFolderDisplayName}
        readOnlyMode={!!readOnlyMode}
      />
    )
  ) : (
    <EmptyStateWrapper />
  );
};

export const createSpiLeftTreeSection = (
  boundShell: Shell,
  isLiteEditor: boolean,
  openWixIDE: () => void,
) =>
  connectWithShell<SpiStoreState, SectionProps, SpiLeftTreeSectionStateProps>(
    (_shell: Shell, state: SpiStoreState) => {
      return {
        spiStatuses: state.connections,
        isLiteEditor,
        openWixIDE,
      };
    },
    (_shell: Shell, _dispatch) => ({}),
    boundShell,
    { allowOutOfEntryPoint: true, renderOutsideProvider: true },
  )(SpiLeftTreeSectionComp);
