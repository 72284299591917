import { ActionCreatorWithPayload, bindActionCreators } from '@reduxjs/toolkit';
import { Divider, Preloader, TreeSectionDivider } from '@wix/wix-base-ui';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import bi from '@/legacy/bi/bi';
import dataHooks from '../../../dataHooks';
import {
  ContextMenuStructure,
  ReadOnlyModeContext,
  TreeContextMenu,
  useTranslation,
  wixCodeReduxContext,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import {
  STATIC_SECTIONS,
  treeSectionActions,
  TreeSectionSetExpandedParams,
} from '../../../filesTree/filesTree/treeSectionReducer';
import { PkgTreeContext } from '../PkgTreeContext';
import { PACKAGE_VIEW_QUICK_ACTIONS } from '../pkgTreeQuickActions';
import { CodeReuseScopeTree } from '../codeReusePkgTree/CodeReuseScopeTree';
import { consts } from '@wix/wix-code-consts';
import { codeReusePkgsSelectors } from '@/toExtract/packages/packagesView/packagesViewSelectors';
import { appDataServiceCreator } from '@/toExtract/packages/appDataService';
import { nodeDoubleClick } from '@/infra/redux-state/actions/fileTreeActions';
import {
  isSectionExpanded,
  isSectionLoaded,
} from '../../../filesTree/filesTree/treeSectionSelectors';
import { AppMarket } from '@wix/wix-ui-icons-common/classic-editor';
import { CODE_EDITOR_MODE } from '@wix/wix-code-plugin-contracts';

import {
  useInternalAPIs,
  useSelector,
} from '../../packagesTabEntryPoint/contexts';

interface VeloPkgsTreeProps {
  codeEditorMode?: CODE_EDITOR_MODE;
}

interface VeloPkgsTreeReduxProps {
  loading: boolean;
  expanded: boolean;
}

interface VeloPkgsTreeReduxDispatchProps {
  collapse: ActionCreatorWithPayload<TreeSectionSetExpandedParams, string>;
  expand: ActionCreatorWithPayload<TreeSectionSetExpandedParams, string>;
  doubleClick: (id: string) => void;
}

type VeloPkgsTreeCompProps = VeloPkgsTreeProps &
  VeloPkgsTreeReduxProps &
  VeloPkgsTreeReduxDispatchProps;
interface ContextMenuItemDescriptor {
  icon: string | React.FunctionComponent;
  labelKey: string;
  automationId: string;
}

const CONTEXT_MENU_ITEMS: {
  [key in PACKAGE_VIEW_QUICK_ACTIONS]?: ContextMenuItemDescriptor;
} = {
  INSTALL_A_PACKAGE: {
    icon: AppMarket,
    labelKey: 'Left_Tree_Blocks_Velo_ContextMenu_Install',
    automationId: 'action-see-all-available-apps',
  },
};

const contextMenuSections: PACKAGE_VIEW_QUICK_ACTIONS[][] = [
  [PACKAGE_VIEW_QUICK_ACTIONS.INSTALL_A_PACKAGE],
];
const VeloPkgsTreeComp: React.FC<VeloPkgsTreeCompProps> = ({
  loading,
  expanded,
  codeEditorMode = CODE_EDITOR_MODE.CODE_AND_STAGE,
}) => {
  const { readOnlyMode } = useContext(ReadOnlyModeContext);
  const { editorAPI } = useEditorLegacyAPIs();
  const {
    wixCodeStoreAPI: { dispatch },
  } = useInternalAPIs();

  const { actionHandler, packagesService } = useContext(PkgTreeContext);
  const [isContextMenuOpen, setContextMenuOpen] = React.useState(false);
  const [isTreeHovered] = React.useState(false);
  const [t] = useTranslation();
  const installedCodeReusePkgs = useSelector(
    codeReusePkgsSelectors.installed.byVelo,
  );
  const appDataService = appDataServiceCreator();

  const alwaysShowSuffix = isContextMenuOpen || isTreeHovered;
  const dispatchExpandRoot = (sectionName: STATIC_SECTIONS) =>
    dispatch(treeSectionActions.expandTreeSection({ sectionName }));
  const dispatchCollapseRoot = (sectionName: STATIC_SECTIONS) =>
    dispatch(treeSectionActions.collapseTreeSection({ sectionName }));
  const onVeloRootClick = () => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_SECTION, {
      section_name: 'internal_packages',
      action: expanded ? 'close' : 'open',
    });
    expanded
      ? dispatchCollapseRoot(STATIC_SECTIONS.VELO_PKGS)
      : dispatchExpandRoot(STATIC_SECTIONS.VELO_PKGS);
  };
  const onContextMenuClick = (event: React.MouseEvent) => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_PLUS, {
      section_name: 'velo',
    });

    event.stopPropagation();
  };

  const menuActionHandler = (action: PACKAGE_VIEW_QUICK_ACTIONS) => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_SETTINGS_ITEM, {
      item_name: 'internal_packages',
      menu_entry_name: action,
    });
    return actionHandler(action);
  };
  const handleContextMenuToggle = (isOpen: boolean) => {
    setContextMenuOpen(isOpen);
  };
  const packagesMenuStructure: ContextMenuStructure = {
    seperator: <Divider long={true} />,
    sections: contextMenuSections.map((section) =>
      section.map((item) => {
        const itemDesc = CONTEXT_MENU_ITEMS[item];
        if (!itemDesc) {
          throw new Error(
            `Missing context menu action in CONTEXT_MENU_ITEMS: ${item}`,
          );
        }
        return {
          automationId: itemDesc.automationId,
          icon: itemDesc.icon,
          label: t(itemDesc.labelKey),
          onClick: () => menuActionHandler(item),
        };
      }),
    ),
  };
  const suffix = loading ? (
    <div className="preloader-container-hack">
      <Preloader className="tiny" />
    </div>
  ) : (
    <div onClick={onContextMenuClick}>
      <TreeContextMenu
        menuClassName="context-menu wix-code-file-tree-dd"
        handleContextMenuToggle={handleContextMenuToggle}
        contextMenuStructure={packagesMenuStructure}
        contextMenuButton="corvid_tree__context_menu_add"
        isContextMenuOpen={isContextMenuOpen}
        tooltipContent={t('Velo_Concurrent_Editing_Sidebar_ReadOnly_Text')}
        readonly={!!readOnlyMode.sidePanel?.packages}
      />
    </div>
  );
  return (
    <div data-hook={dataHooks.VELO_PKGS_ROOT}>
      <TreeSectionDivider
        dataHook={dataHooks.SECTION_DIVIDER}
        label={t('Left_Tree_Components_Category_Velo_Packages')}
        collapsed={!expanded}
        onClick={onVeloRootClick}
        alwaysShowSuffix={alwaysShowSuffix}
        suffix={suffix}
        size="small"
        hideBottomBorder={!expanded}
        shouldTranslate={false}
      />
      <>
        {expanded && (
          <CodeReuseScopeTree
            key={consts.VELO_PKG_SCOPE}
            pkgScope={consts.VELO_PKG_SCOPE}
            installedCodeReusePkgs={installedCodeReusePkgs}
            packagesService={packagesService}
            appDataService={appDataService}
            actionHandler={actionHandler}
          />
        )}
      </>
    </div>
  );
};

function mapStateToProps(state: AnyFixMe) {
  const sectionName = STATIC_SECTIONS.VELO_PKGS;
  const veloReadmeLoaded = isSectionLoaded(state, consts.VELO_PKG_SCOPE);
  const expanded = isSectionExpanded(state, sectionName);

  return {
    expanded,
    loading: !veloReadmeLoaded,
  };
}

const mapDispatchToProps = (
  dispatch: AnyFixMe,
): VeloPkgsTreeReduxDispatchProps => {
  return bindActionCreators(
    {
      expand: treeSectionActions.expandTreeSection,
      collapse: treeSectionActions.collapseTreeSection,
      doubleClick: nodeDoubleClick,
    },
    dispatch,
  );
};

export const VeloPkgsTree: React.FC<VeloPkgsTreeProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { context: wixCodeReduxContext },
)(VeloPkgsTreeComp);
