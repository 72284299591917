import * as React from 'react';
import dataHooks from '../../../../../dataHooks';
import { InstalledPackagesListSection } from './InstalledPackagesListSection';
import { codeReusePkgsSelectors } from '../../../../../packagesView/packagesViewSelectors';
import { useSelector } from '@wix/wix-code-common-components';

export const InstalledPkgsListMyPkgsSection: React.FC = () => {
  const myPackages = useSelector(codeReusePkgsSelectors.installed.myPackages);

  return (
    <InstalledPackagesListSection
      dataHook={dataHooks.INSTALLED_PKGS_MY_PKGS}
      sectionTitle="Package_Manager_Section_Manage_Installed_My_Packages"
      installedPkgs={myPackages}
    />
  );
};
