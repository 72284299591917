import React from 'react';
import { Shell, connectWithShell } from '@wix/wix-code-repluggable';
import {
  BiLoggerAPI,
  FilesTreeAPI,
  LegacyEditorDependencies,
  PackagesAndAppsAPI,
  PanelsAPI,
  ReadOnlyAPI,
  WixCodeStoreAPI,
} from '@wix/wix-code-plugin-contracts';
import { FilesTreeState } from '@/infra/redux-state/reducers/fileTreeReducer';
import { SidePaneContext } from '../SidePaneContext';
import {
  MenuTabProvider,
  ReadOnlyModeContext,
  TranslationProvider,
} from '@wix/wix-code-common-components';
import { SiteCodePanelHeader } from './SiteCodePanelHeader';

type SiteCodeAPIs = {
  wixCodeStoreAPI: WixCodeStoreAPI;
  legacyDependenciesAPI: LegacyEditorDependencies;
  biLoggerAPI: BiLoggerAPI;
  packagesAndAppsAPI: PackagesAndAppsAPI;
  filesTreeAPI: FilesTreeAPI;
  panelsAPI: PanelsAPI;
  readOnlyAPI: ReadOnlyAPI;
};

type SiteCodeMenuTabProps = {
  closePanel: () => void;
  shell: Shell;
  apis: SiteCodeAPIs;
};

type SidePaneContextWrapperOwnProps = {
  packagesAndAppsAPI: PackagesAndAppsAPI;
};

type SidePaneContextWrapperDispatchProps = {
  showNewFileInput: FilesTreeAPI['showNewFileInput'];
  setNewFileRenameInput: FilesTreeAPI['setNewFileRenameInput'];
};

type SidePaneContextWrapperProps = FilesTreeState &
  SidePaneContextWrapperDispatchProps &
  SidePaneContextWrapperOwnProps;

const SidePaneContextWrapper: React.FC<SidePaneContextWrapperProps> = ({
  children,
  ...contextProps
}) => (
  <SidePaneContext.Provider value={contextProps}>
    {children}
  </SidePaneContext.Provider>
);

export const createSiteCodeMenuTab: React.FC<SiteCodeMenuTabProps> = ({
  shell,
  closePanel,
  children,
  apis,
}) => {
  const {
    filesTreeAPI,
    packagesAndAppsAPI,
    panelsAPI,
    readOnlyAPI,
    ...restApis
  } = apis;

  const mapStateToProps = () => ({
    newChild: filesTreeAPI.getNewFileRenameInput(),
  });
  const mapDispatchToProps = () => ({
    showNewFileInput: filesTreeAPI.showNewFileInput,
    setNewFileRenameInput: filesTreeAPI.setNewFileRenameInput,
  });

  const ConnectedSidePaneContextWrapper = connectWithShell<
    {},
    SidePaneContextWrapperOwnProps,
    FilesTreeState,
    SidePaneContextWrapperDispatchProps
  >(mapStateToProps, mapDispatchToProps, shell, {
    renderOutsideProvider: true,
    allowOutOfEntryPoint: true,
  })(SidePaneContextWrapper);

  return (
    <MenuTabProvider {...restApis}>
      <ReadOnlyModeContext.Provider
        value={{ readOnlyMode: readOnlyAPI.getReadOnlyMode() }}
      >
        <TranslationProvider
          options={{
            locale: apis.legacyDependenciesAPI.util.editorModel.languageCode,
            asyncMessagesLoader: (locale: string) =>
              import(`../../assets/locale/messages_${locale}.json`),
          }}
        >
          <SiteCodePanelHeader closePanel={closePanel} panelsAPI={panelsAPI} />
          <ConnectedSidePaneContextWrapper
            packagesAndAppsAPI={packagesAndAppsAPI}
          >
            {children}
          </ConnectedSidePaneContextWrapper>
        </TranslationProvider>
      </ReadOnlyModeContext.Provider>
    </MenuTabProvider>
  );
};
