// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tHxo7R{display:flex;flex-direction:column;margin-top:12px}.v4AdJD{margin-top:6px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adapterConfiguration": "tHxo7R",
	"warningContainer": "v4AdJD"
};
export default ___CSS_LOADER_EXPORT___;
