const siteEventsMap = {
  SAVE: 'save',
} as const;

const componentEventsMap = {
  REMOVE: 'components.remove',
  ADD: 'components.add',
  TRANSACTION: 'transaction',
  SET_NICKNAME: 'components.code.setNickname',
} as const;

const pageEventsMap = {
  UPDATE: 'pages.data.update',
  ADD: 'pages.add',
  REMOVE: 'pages.remove',
} as const;

const syncSiteEventsMap = {
  ...siteEventsMap,
  ...componentEventsMap,
  ...pageEventsMap,
} as const;

const componentEventsList = Object.values(componentEventsMap);
const pageEventsList = Object.values(pageEventsMap);
const siteEventsList = Object.values(siteEventsMap);

const syncSiteEventsList = [
  ...pageEventsList,
  ...componentEventsList,
  ...siteEventsList,
];

type ComponentEvents = (typeof componentEventsList)[number];
type PageEvents = (typeof pageEventsList)[number];
type SyncSiteEvents = (typeof syncSiteEventsList)[number];
type SiteEvents = (typeof siteEventsList)[number];

export {
  siteEventsList,
  componentEventsList,
  pageEventsList,
  syncSiteEventsList,
  syncSiteEventsMap,
  SyncSiteEvents,
  ComponentEvents,
  PageEvents,
  SiteEvents,
};
