import {
  AppHostOptions,
  createAppHost,
  EntryPointOrPackage,
  HostLogger,
  ConsoleHostLogger,
} from 'repluggable';

export function createHost(entryPoints: EntryPointOrPackage[] = []) {
  const urlParams = new URLSearchParams(window.location.search);

  const disableMonitoring = !urlParams.has('enableMonitoring');
  const enablePerformance = urlParams.has('enablePerformance');
  const disableMemoization = urlParams.has('disableMemoization');
  const debugMemoization = urlParams.has('debugMemoization');
  const disableLayersValidation = urlParams.has('disableLayersValidation');
  const disableCustomHostLogger = urlParams.has('disableCustomHostLogger');

  const isDebug = ['true', 'all'].includes(urlParams.get('debug') || '');
  const isQA = urlParams.get('isqa') === 'true';

  const isDebugMode = isDebug && !isQA;

  const errorBoundaryParam =
    urlParams.get('stickyErrorBoundary') || `${isDebugMode}`;
  const enableStickyErrorBoundaries =
    errorBoundaryParam.toLowerCase() !== 'false';

  const monitoring = {
    disableMonitoring,
    enablePerformance,
    disableMemoization,
    debugMemoization,
  };

  const logger: HostLogger = isDebugMode
    ? ConsoleHostLogger
    : {
        ...ConsoleHostLogger,
        log: (severity, ...args) => {
          if (severity === 'debug') {
            return;
          }
          return ConsoleHostLogger.log(severity, ...args);
        },
      };

  const options: AppHostOptions = {
    logger: disableCustomHostLogger ? undefined : logger,
    monitoring,
    disableLayersValidation,
    enableStickyErrorBoundaries,
    disableCheckCircularDependencies: !isDebugMode,
    enableReduxDevtoolsExtension: isDebugMode,
    layers: [
      {
        level: 80,
        name: 'EXTERNAL',
      },
    ],
  };

  // repluggable debug hack
  // reference - https://github.com/wix-private/santa-editor/blob/e92105efb3dc7162eed06221e475b12fdee5c15b/santa-editor/packages/rEditor/rootComps/createEditorHost.ts#L235
  const repluggableAppDebug = window?.repluggableAppDebug;
  const host = createAppHost(entryPoints, options);
  window.wixCodeRepluggableAppDebug = window?.repluggableAppDebug;
  window.repluggableAppDebug = repluggableAppDebug;

  return host;
}
