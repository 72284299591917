// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".un4Ige{gap:10px;padding:25px;width:700px;height:500px}.Ho2QRm{padding:0 12px 0px 14px !important}.Ho2QRm.DEo5vj{cursor:not-allowed}.JFDNtW{text-align:right}.B8FFjx{cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "un4Ige",
	"cta": "Ho2QRm",
	"disabled": "DEo5vj",
	"copy-container": "JFDNtW",
	"copyContainer": "JFDNtW",
	"copy-button": "B8FFjx",
	"copyButton": "B8FFjx"
};
export default ___CSS_LOADER_EXPORT___;
