import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { APIKeys, EntryPoint } from '@wix/wix-code-repluggable';
import React from 'react';
import fileCodeEditorCreator from './components/fileCodeEditor/fileCodeEditorV2';
import { SplitMarkdownViewer } from './components/markdown/SplitMarkdownViewer';
import { fileSystem as fileSystemCommon } from '@wix/wix-code-common';

export const MarkdownEntryPoint: EntryPoint = {
  name: 'Markdown Entry Point',
  getDependencyAPIs() {
    return [
      APIKeys.ClassicEditorAPI,
      APIKeys.CodeEditorAPI,
      WixCodeEditorAdapterAPI,
    ];
  },
  extend(shell) {
    const wixCodeEditorAdapterAPI = shell.getAPI(WixCodeEditorAdapterAPI);
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
    const codeEditorAPI = shell.getAPI(APIKeys.CodeEditorAPI);

    const FileCodeEditor = fileCodeEditorCreator(
      wixCodeEditorAdapterAPI.legacyDependencies,
    );

    codeEditorAPI.contributeTabRenderer(shell, MarkdownRenderer, () => {
      const path = getContextId();
      return (
        !fileSystemCommon.codePackages.isPackageMarkdown(path) &&
        fileSystemCommon.isMarkdownFile(path)
      );
    });
    function MarkdownRenderer({ codeEditorProps }: { codeEditorProps: any }) {
      return (
        <SplitMarkdownViewer
          fileId={getContextId()}
          Editor={FileCodeEditor}
          editorProps={codeEditorProps}
        />
      );
    }

    function getContextId() {
      return editorAPI.developerMode.getContext().data?.id;
    }
  },
};
