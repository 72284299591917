export const byHook = (str: string) => `[data-hook=${str}]`;

export default {
  SECTION_DIVIDER: 'corvid-site-structure__section-divider',
  TREE_NODE: 'corvid-site-structure__tree-node',
  TREE_NODE_RENAME: 'corvid-site-structure__rename_input',
  MASTER_PAGE_CONTAINER: 'master-page-container',
  MASTER_PAGE_DIVIDER: 'master-page-divider',
  MASTER_PAGE_ICON: 'master-page-icon',
  MASTER_PAGE_INFO_ICON: 'master-page-info-icon',
  TREE_ROOT: 'corvid-site-structure__tree-root',
};
