import {
  FileSystemAPI,
  ReadOnlyAPI,
  ReadOnlyMode,
  WixCodeAppAPI,
  wixCodeLifecycleDuplexer,
  EditorAPI,
  ExperimentsAPI,
  SetFileStructureType,
  FileStructureType,
} from '@wix/wix-code-plugin-contracts';
import { Shell, SlotKey } from '@wix/wix-code-repluggable';
import {
  GitHubCommit,
  GithubRepository,
  GithubUser,
  OnboardingState,
  RepositoryOwner,
  TeamworkOnboardingStatus,
} from './types/GithubTypes';
import createGithubService from './services/githubService';
import { actions, createSelectors, GithubState } from './state';
import {
  GITHUB_CALLBACK_ORIGIN,
  GITHUB_CALLBACK_SOURCE,
  GITHUB_INSTALL_APP_URL,
  GITHUB_OAUTH_AUTHORIZE_URL,
} from './config';
import { onWindowMessage, openCenteredDialog } from './utils/utils';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { monitoring, platformApps } from '@wix/wix-code-common';
import type { i18n } from '@wix/wix-i18n-config';
import { ON_BOARDING_SUPPORTED_ERRORS } from './constants';
import { StateObserverUnsubscribe } from 'repluggable';

const ABORT_PENDING_GITHUB_MESSAGE_LISTENERS =
  'ABORT_PENDING_GITHUB_MESSAGE_LISTENERS';
const GITHUB_ABORTED = 'GITHUB_ABORTED';

const githubModalWindowSize = {
  height: 1050,
  width: 1000,
};
export interface GithubPrivateAPI {
  setOnboardingStatus: (onboardingStatus: TeamworkOnboardingStatus) => void;
  acceptGithubTerms: () => void;
  didUserAcceptGithubTerms: () => boolean;
  getOnboardingState: () => OnboardingState;
  getOnboardingError: () => ON_BOARDING_SUPPORTED_ERRORS | null;
  getGithubUser: () => GithubUser | undefined;
  getGithubRepository: () => GithubRepository | undefined;
  isVeloAppInstalled: () => boolean | undefined;
  getCommitHistoryUrl: () => string | undefined;
  getCommits: () => GitHubCommit[] | undefined;
  isLoadingOnboardingStatus: () => boolean;
  syncWithSavedState: () => void;
  authorizeGithubUser: (onGithubDialogClose: () => void) => Promise<void>;
  initGitHubRepository: (onGithubDialogClose: () => void) => Promise<void>;
  createGitHubRepository: (
    owner: RepositoryOwner,
    repositoryName: string,
    repositoryDescription?: string | null,
  ) => Promise<void>;
  disconnectGithubRepository: () => Promise<void>;
  newCommitsReceived: (
    commits: wixCodeLifecycleDuplexer.GithubCommitsEvent['commits'],
    i18nInstance: i18n,
  ) => void;
  ensureSyncedWithRemote: () => void;
  subscribeToOnboardingError: (
    callback: (errorType: ON_BOARDING_SUPPORTED_ERRORS) => void,
  ) => StateObserverUnsubscribe;
  setFileStructureType: SetFileStructureType;
}

export const GithubPrivateAPIKey: SlotKey<GithubPrivateAPI> = {
  name: 'GithubPrivateAPI',
  public: false,
};

const githubReadOnlyMode: ReadOnlyMode = {
  codeIde: true,
  sidePanel: {
    publicAndBackend: true,
    packages: true,
  },
  propertiesPanel: {
    eventsSection: true,
    nicknamesSection: false,
    propsSection: false,
  },
  fileSystem: true,
  cssEditing: {
    addGlobalCss: true,
    semanticClasses: true,
  },
  externalIde: true,
};

export const createGithubPrivateAPI: (
  shell: Shell,
  wixCodeAppAPI: WixCodeAppAPI,
  readOnlyAPI: ReadOnlyAPI,
  fileSystemAPI: FileSystemAPI,
  wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI,
  editorAPI: EditorAPI,
  experimentsAPI: ExperimentsAPI,
) => GithubPrivateAPI = (
  shell,
  wixCodeAppAPI,
  readOnlyAPI,
  fileSystemAPI,
  wixCodeEditorAdapterAPI,
  editorAPI,
  experimentsAPI,
) => {
  const githubService = createGithubService(wixCodeAppAPI.getSignedInstance);
  const githubStore = shell.getStore<GithubState>();
  const githubSelectors = createSelectors(githubStore.getState);
  let currentError: ON_BOARDING_SUPPORTED_ERRORS | null = null;
  let fileStructure: FileStructureType;


  async function getDataFromGithub<T extends OAuthPostMessage | AppPostMessage>(
    githubUrl: string,
    onGithubDialogClose: () => void,
  ) {
    const dialog = openCenteredDialog(githubUrl, githubModalWindowSize);
    window.dispatchEvent(new Event(ABORT_PENDING_GITHUB_MESSAGE_LISTENERS));
    const data = await listenForGithubMessage<T>();
    if ('abort' in data && data.abort) {
      return GITHUB_ABORTED;
    }
    dialog.close();
    onGithubDialogClose();
    if ('error' in data && data.error) {
      throw new Error(data.error_description);
    }

    return data;
  }

  const api: GithubPrivateAPI = {
    setOnboardingStatus: (onboardingStatus) => {
      githubStore.dispatch(actions.setOnboardingStatus(onboardingStatus));
      if (
        onboardingStatus.onboardingState === OnboardingState.GITHUB_DISABLED
      ) {
        readOnlyAPI.removeReadOnlyContribution('GITHUB');
      } else if (
        onboardingStatus.onboardingState === OnboardingState.GITHUB_ENABLED
      ) {
        platformApps.notifyWixCode(editorAPI, {
          eventType: 'setRoutersNotWritable',
          eventPayload: {},
        });
        readOnlyAPI.setReadOnly('GITHUB', githubReadOnlyMode);
        if (!experimentsAPI.isOpen('dm_ideServerNG')) {
          fileSystemAPI.listenToExternalFileSystemChanges();
        }
      }
      wixCodeEditorAdapterAPI.notifyChange();
    },
    getOnboardingState: (): OnboardingState => {
      return githubSelectors.onboardingState();
    },
    getOnboardingError: (): ON_BOARDING_SUPPORTED_ERRORS | null => {
      return githubSelectors.onboardingError();
    },
    getGithubUser: () => {
      return githubSelectors.githubUser();
    },
    getGithubRepository: () => {
      return githubSelectors.githubRepository();
    },
    isVeloAppInstalled: () => {
      return githubSelectors.isVeloAlreadyInstall();
    },
    getCommitHistoryUrl: () => {
      return githubSelectors.commitHistoryUrl();
    },
    getCommits: () => {
      return githubSelectors.commits();
    },
    isLoadingOnboardingStatus: () => {
      return githubSelectors.isLoadingOnboardingStatus();
    },
    syncWithSavedState: async () => {
      githubStore.dispatch(actions.loadingOnboardingStatus(true));
      githubStore.dispatch(actions.setOnboardingError(null));
      try {
        const status = await githubService.getOnboardingStatus();
        api.setOnboardingStatus(status);
      } catch (error: any) {
        monitoring.captureError(error);
        const errorType = getErrorType(error);
        githubStore.dispatch(actions.setOnboardingError(errorType));
      }
      githubStore.dispatch(actions.loadingOnboardingStatus(false));
    },
    authorizeGithubUser: async (onGithubDialogClose: () => void) => {
      const data = await getDataFromGithub(
        GITHUB_OAUTH_AUTHORIZE_URL,
        onGithubDialogClose,
      );

      if (data === GITHUB_ABORTED) {
        return;
      }

      const teamworkOnboardingStatus = await githubService.authorizeGithubUser(
        (data as OAuthPostMessage).code,
      );
      api.setOnboardingStatus(teamworkOnboardingStatus);
    },
    initGitHubRepository: async (onGithubDialogClose: () => void) => {
      const repo = githubSelectors.githubRepository();
      let installationId;
      let abort = false;
      if (!repo) {
        throw new Error('cannot init a repository before creating one');
      }
      if (!api.isVeloAppInstalled()) {
        const githubAppAuthUrl = `${GITHUB_INSTALL_APP_URL}/permissions?suggested_target_id=${repo.repositoryOwner.id}&repository_ids[]=${repo.id}`;
        const data = await getDataFromGithub(
          githubAppAuthUrl,
          onGithubDialogClose,
        );

        if (data === GITHUB_ABORTED) {
          abort = true;
        } else {
          installationId = (data as AppPostMessage).installationId;
        }
      }
      if (abort) {
        return;
      }
      const teamworkOnboardingStatus = await githubService.initGitHubRepository(
        installationId, 
        fileStructure,
      );
      api.setOnboardingStatus(teamworkOnboardingStatus);
    },
    createGitHubRepository: async (
      owner,
      repositoryName,
      repositoryDescription,
    ) => {
      const teamworkOnboardingStatus =
        await githubService.createGitHubRepository(
          owner,
          repositoryName,
          repositoryDescription,
        );
      api.setOnboardingStatus(teamworkOnboardingStatus);
    },
    acceptGithubTerms: () => {
      githubStore.dispatch(actions.acceptGithubTerms());
    },
    didUserAcceptGithubTerms: () => githubSelectors.didUserAcceptGithubTerms(),
    newCommitsReceived: (commits, i18nInstance) => {
      githubStore.dispatch(actions.githubCommitsReceived(commits));
      wixCodeEditorAdapterAPI.showSnackbar(
        i18nInstance.t('githubIntegration.new_commits.snackbar', {
          defaultBranch: githubSelectors.githubRepository()?.defaultBranch,
        }),
      );
    },
    disconnectGithubRepository: async () => {
      const teamworkOnboardingStatus =
        await githubService.disconnectGithubRepository();
      api.setOnboardingStatus(teamworkOnboardingStatus);
    },
    ensureSyncedWithRemote: () => {
      const handleError = (error: any) => monitoring.captureError(error);
      githubService.ensureSyncedWithRemote().catch(handleError);
    },
    subscribeToOnboardingError: (
      callback: (errorType: ON_BOARDING_SUPPORTED_ERRORS) => void,
    ) => {
      return githubStore.subscribe(() => {
        const previousError = currentError;
        currentError = githubStore.getState().onboarding.onboardingError;

        if (previousError === null && currentError !== null) {
          callback(currentError);
        }
      });
    },
    setFileStructureType(type) {
      fileStructure = type;
    }
  };
  return api;
};

type OAuthPostMessage = {
  code: string;
};
type AppPostMessage = {
  installationId: string;
};
type GithubError = {
  error: string;
  error_description: string;
};
type GithubAbort = {
  abort: boolean;
};

async function listenForGithubMessage<
  T extends OAuthPostMessage | AppPostMessage,
>(): Promise<T | GithubAbort | GithubError> {
  return new Promise((resolve) => {
    const removeListener = onWindowMessage(async (event) => {
      if (!isGithubMessage(event)) {
        return;
      }
      removeListener();
      resolve(event.data);
    });
    window.addEventListener(ABORT_PENDING_GITHUB_MESSAGE_LISTENERS, () => {
      removeListener();
      resolve({ abort: true });
    });
  });
}

function isGithubMessage(event: MessageEvent) {
  return (
    GITHUB_CALLBACK_ORIGIN.includes(event.origin) &&
    event.data.source === GITHUB_CALLBACK_SOURCE
  );
}

function getErrorType(error: any): ON_BOARDING_SUPPORTED_ERRORS {
  const errorCode = error.response?.data?.details?.applicationError?.code;
  return errorCode in ON_BOARDING_SUPPORTED_ERRORS
    ? errorCode
    : ON_BOARDING_SUPPORTED_ERRORS.GENERIC_ERROR;
}
