import React from 'react';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { useTranslation } from '@wix/wix-i18n-config';
import { TranslationProvider } from '../../i18n';
import { CloneCode } from '@wix/wix-ui-icons-common/responsive-editor';
import CliInstructionsModal from '../CliInstructionsModal/CliInstructionsModal';
import { FloatingNotification } from '@wix/wix-code-common-components';

export interface FooterProps {
  wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI;
}

const modalRenderer = (closeModal: () => void) => (
  <TranslationProvider>
    <CliInstructionsModal closeModal={closeModal} />
  </TranslationProvider>
);

export const Footer: React.FC<FooterProps> = ({ wixCodeEditorAdapterAPI }) => {
  const [t] = useTranslation();

  return (
    <FloatingNotification
      text={t('localEditor.code_editor_footer.text')}
      buttonLabel={t('localEditor.code_editor_footer.learn_more_button')}
      buttonIcon={<CloneCode />}
      buttonOnClick={() => {
        wixCodeEditorAdapterAPI.modalAPI.showModal(modalRenderer, {
          backdrop: true,
          closeOnClickOutside: true,
        });
      }}
      buttonType="BUTTON"
    />
  );
};

export const createCodeEditorFooter =
  (wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI) => () =>
    (
      <TranslationProvider>
        <Footer wixCodeEditorAdapterAPI={wixCodeEditorAdapterAPI} />
      </TranslationProvider>
    );
