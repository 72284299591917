// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tceS2G{font-family:Monaco;font-size:12px;line-height:22px;color:#fff;-webkit-user-select:text;-moz-user-select:text;-ms-user-select:text;user-select:text}.o4gg9T{color:#00c202;-webkit-user-select:text;-moz-user-select:text;-ms-user-select:text;user-select:text}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"terminalCommand": "tceS2G",
	"terminalCommandFirstWord": "o4gg9T"
};
export default ___CSS_LOADER_EXPORT___;
