import React, { useEffect, useState } from 'react';

import { CONFIGURATION_LINK, Providers } from '../constants';
import { createValidations } from './validation';

import { dataHooks } from '../dataHooks';
import { WarningBanner } from './WarningBanner';
import { useTranslation } from '@wix/wix-i18n-config';
import {
  FieldsValidations,
  InputsTypes,
  UserTypingInfo,
} from '../../../internal-types';
import { AdapterDetails } from '../AdapterDetails/AdapterDetails';
import { ConnectAdapterStep1 } from './ConnectAdapterStep1';

export type ConnectAdapterProps = {
  provider?: Providers;
  uniqueNamespaces: any;
  inputFields: InputsTypes;
  setNamespace: (value: string) => void;
  setEndpoint: (value: string) => void;
  setConfiguration: (value: string) => void;
  fieldsValidations: FieldsValidations;
  setFieldsValidations: (
    value: (state: FieldsValidations) => FieldsValidations,
  ) => void;
  isEditFlow?: boolean;
  initialFields?: any;
  userStartTypingIndicaiton: UserTypingInfo;
  setUserStartTypingIndicaiton: React.Dispatch<
    React.SetStateAction<UserTypingInfo>
  >;
};
export const ConnectAdapter: React.FC<ConnectAdapterProps> = ({
  provider,
  uniqueNamespaces,
  inputFields,
  fieldsValidations,
  setNamespace,
  setEndpoint,
  setConfiguration,
  setFieldsValidations,
  isEditFlow,
  initialFields,
  userStartTypingIndicaiton,
  setUserStartTypingIndicaiton,
}) => {
  const [t] = useTranslation();
  const {
    getNamespaceValidationError,
    getEndpointValidationError,
    getConfigurationValidationError,
  } = createValidations();
  const [namespaceValidationError, setNamespaceValidationError] =
    useState<string>(
      t('External_Databases_Modal_Connect_Adapter_Empty_Field_Error'),
    );
  const [endpointValidationError, setEndpointValidationError] =
    useState<string>(
      t('External_Databases_Modal_Connect_Adapter_Empty_Field_Error'),
    );
  const [configurationValidationError, setConfigurationValidationError] =
    useState<string>(
      t('External_Databases_Modal_Connect_Adapter_Empty_Field_Error'),
    );

  useEffect(() => {
    if (initialFields) {
      setNamespace(initialFields.namespace);
      setConfiguration(initialFields.configuration);
      setEndpoint(initialFields.endpoint);
      setFieldsValidations(() => ({
        isNamespaceValid: true,
        isEndpointValid: true,
        isConfigurationValid: true,
      }));
    }
  }, [
    initialFields,
    setNamespace,
    setConfiguration,
    setEndpoint,
    setFieldsValidations,
  ]);

  const configureAdapterOnClick = () => {
    window.open(t(CONFIGURATION_LINK[provider!]));
  };

  const onNamespaceChange = (value: string) => {
    if (!userStartTypingIndicaiton.didUserStartTypingNamespace) {
      setUserStartTypingIndicaiton((prevState: UserTypingInfo) => ({
        ...prevState,
        didUserStartTypingNamespace: true,
      }));
    }
    setNamespace(value);
    const error = getNamespaceValidationError(
      value,
      uniqueNamespaces,
      t,
    ).getOrElse(null);
    setNamespaceValidationError(error);
    if (error) {
      setFieldsValidations((state: FieldsValidations) => ({
        ...state,
        isNamespaceValid: false,
      }));
      return;
    }
    setFieldsValidations((state: FieldsValidations) => ({
      ...state,
      isNamespaceValid: true,
    }));
  };
  const onEndpointChange = (value: string) => {
    if (!userStartTypingIndicaiton.didUserStartTypingEndpoint) {
      setUserStartTypingIndicaiton((prevState) => ({
        ...prevState,
        didUserStartTypingEndpoint: true,
      }));
    }
    setEndpoint(value);
    const error = getEndpointValidationError(value, t).getOrElse(null);
    setEndpointValidationError(error);
    if (error) {
      setFieldsValidations((state: FieldsValidations) => ({
        ...state,
        isEndpointValid: false,
      }));
      return;
    }
    setFieldsValidations((state: FieldsValidations) => ({
      ...state,
      isEndpointValid: true,
    }));
  };
  const onConfigurationChange = (value: string) => {
    if (!userStartTypingIndicaiton.didUserStartTypingSecretkey) {
      setUserStartTypingIndicaiton((prevState) => ({
        ...prevState,
        didUserStartTypingSecretkey: true,
      }));
    }
    setConfiguration(value);
    const error = getConfigurationValidationError(value, t).getOrElse(null);
    setConfigurationValidationError(error);
    if (error) {
      setFieldsValidations((state: FieldsValidations) => ({
        ...state,
        isConfigurationValid: false,
      }));
      return;
    }
    setFieldsValidations((state: FieldsValidations) => ({
      ...state,
      isConfigurationValid: true,
    }));
  };

  return (
    <div data-hook={dataHooks.externalDbPanels.addAdapter.container}>
      {!isEditFlow && (
        <ConnectAdapterStep1
          configureAdapterOnClick={configureAdapterOnClick}
          provider={provider!}
        />
      )}
      <div>
        {isEditFlow && <WarningBanner />}
        <AdapterDetails
          userStartedTypingIndication={userStartTypingIndicaiton}
          setEndpoint={onEndpointChange}
          setNamespace={onNamespaceChange}
          setConfiguration={onConfigurationChange}
          namespaceValidationError={namespaceValidationError}
          configurationValidationError={configurationValidationError}
          endpointValidationError={endpointValidationError}
          isNamespaceValid={fieldsValidations.isNamespaceValid}
          isEndpointValid={fieldsValidations.isEndpointValid}
          isConfigurationValid={fieldsValidations.isConfigurationValid}
          isNamespaceDisabled={!!isEditFlow}
          configuration={inputFields.configuration}
          endpoint={inputFields.endpoint}
          namespace={inputFields.namespace}
        />
      </div>
    </div>
  );
};
