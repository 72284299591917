import React from 'react';
import {
  Button,
  CustomModal,
  Heading,
  Spacer,
  Text,
  TextButton,
} from '@wix/wix-base-ui';
import dataHooks from './dataHooks';
import styles from './InstructionsModal.scss';
import Instructions from './Instructions/Instructions';

type InstructionsProps = {
  scope: string;
  instructions: string;
  terminalCommands: string[];
};

type InstructionsModalProps = {
  closeModal: Function;
  instructions: InstructionsProps[];
  onCloseButtonClick: () => void;
  onHelpButtonClick: () => void;
  footerConfirmButton: { onClick: () => void; text: string };
  footerSecondaryTextButton: {
    onClick?: () => void;
    text: string;
    href: string;
  };
  modalTitle: string;
  modalSubtitle: string;
  dataHook?: string;
  copyCodeText: string; // todo: find a better solution then getting this fixed text again and again
};

export const InstructionsModal: React.FC<InstructionsModalProps> = ({
  closeModal,
  instructions,
  onCloseButtonClick,
  onHelpButtonClick,
  footerConfirmButton,
  footerSecondaryTextButton,
  modalTitle,
  modalSubtitle,
  copyCodeText,
  dataHook,
}) => {
  const ModalFooter = () => {
    return (
      <div className={styles.footerContent} data-hook={dataHook}>
        <TextButton
          as="a"
          href={footerSecondaryTextButton.href}
          target="_blank"
          rel="noreferrer"
          dataHook={dataHooks.instructionsModal.footer.textButtonLink}
          onClick={footerSecondaryTextButton.onClick}
        >
          {footerSecondaryTextButton.text}
        </TextButton>
        <Button
          className="btn-confirm-secondary"
          onClick={() => {
            footerConfirmButton.onClick();
            closeModal();
          }}
          dataHook={dataHooks.instructionsModal.confirmButton}
        >
          {footerConfirmButton.text}
        </Button>
      </div>
    );
  };

  const ModalContent = () => {
    return (
      <div className={styles.contentContainer}>
        <Heading
          appearance="h2"
          multiline={true}
          dataHook={dataHooks.instructionsModal.title}
        >
          {modalTitle}
        </Heading>
        <Spacer type="Spacer04" />
        <Text
          size="medium"
          weight="thin"
          skin="secondary"
          dataHook={dataHooks.instructionsModal.subtitle}
        >
          {modalSubtitle}
        </Text>
        <Spacer type="Spacer04" />
        {instructions.map((instruction, idx) => (
          <>
            <Instructions
              title={instruction.scope}
              onCopyCode={() => {}}
              key={idx}
              instructions={instruction.instructions}
              terminalCommands={instruction.terminalCommands}
              copyCodeText={copyCodeText}
            />
            <Spacer type="Spacer04" />
          </>
        ))}
      </div>
    );
  };

  return (
    <CustomModal
      dataHook={dataHooks.instructionsModal.container}
      onCloseButtonClick={onCloseButtonClick}
      onHelpButtonClick={onHelpButtonClick}
      footerContent={<ModalFooter />}
    >
      <ModalContent />
    </CustomModal>
  );
};

export default InstructionsModal;
