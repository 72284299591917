import React from 'react';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { APIKeys, EntryPoint } from '@wix/wix-code-repluggable';
import devContextUtilsCreator from '@/utils/devContext';
import tbfPageCreator from './components/tbfPage/tbfPage';

export const BackendDebuggingEntryPoint: EntryPoint = {
  name: 'Backend Debugging Entry Point',
  getDependencyAPIs() {
    return [
      WixCodeEditorAdapterAPI,
      APIKeys.ClassicEditorAPI,
      APIKeys.CodeEditorAPI,
    ];
  },
  extend(shell) {
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
    const { legacyDependencies } = shell.getAPI(WixCodeEditorAdapterAPI);
    const codeEditorAPI = shell.getAPI(APIKeys.CodeEditorAPI);
    const devContextUtils = devContextUtilsCreator(legacyDependencies);

    const TbfPage = tbfPageCreator(legacyDependencies);
    codeEditorAPI.contributeTabRenderer(
      shell,
      ({ codeEditorProps }) => <TbfPage {...codeEditorProps} />,
      () => devContextUtils.isTbfContext(editorAPI),
    );
  },
};
