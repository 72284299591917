import s from './contextMenuItem.scss';
import * as React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import cx from 'classnames';

export type IconColor = 'jsw' | 'jsx' | 'js' | 'ts';

interface ContextMenuItemProps {
  icon: string | React.FunctionComponent<{ className: string }>;
  iconColor?: IconColor;
  label: string;
}

export class ContextMenuItem extends React.Component<ContextMenuItemProps> {
  render() {
    const { icon: Icon, iconColor, label } = this.props;

    return (
      <>
        {typeof Icon === 'string' ? (
          <Symbol name={Icon} className={s.currentColor} />
        ) : (
          <Icon
            className={cx(
              'symbol', // className symbol is here to get the default styling from <ContextMenuAction /> of wix-base-ui
              s.currentColor,
              {
                [s.js]: iconColor === 'js',
                [s.jsx]: iconColor === 'jsx',
                [s.jsw]: iconColor === 'jsw',
                [s.ts]: iconColor === 'ts',
              },
            )}
          />
        )}
        <span>{label}</span>
      </>
    );
  }
}
