import React from 'react';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { experimentUtils, fedops } from '@wix/wix-code-common';
import {
  EntryPointState,
  Action,
  devModeReducer,
  setReadOnly,
  getReadOnly,
} from '../state';
import { createPanelSlot } from '../components/panelSlot/createPanelSlot';
import { LitePanelSlot } from '../components/panelSlot/index';

import {
  BiLoggerContext,
  TranslationProvider,
  createBiLoggerAPI,
} from '@wix/wix-code-common-components';
import { PanelEmptyState } from '../components/panelEmptyState/panelEmptyState';

export const LitePanelEntryPoint: EntryPoint = {
  name: 'Wix Code Lite Panel Entry Point',
  getDependencyAPIs() {
    return [WixCodeEditorAdapterAPI];
  },
  declareAPIs() {
    return [APIKeys.LitePanelAPI];
  },
  attach(shell: Shell) {
    fedops.getLogger().interactionStarted(fedops.interactions.load);
    const panelSlot = shell.declareSlot(LitePanelSlot);

    shell.contributeState<EntryPointState, Action>(() => ({
      devMode: devModeReducer,
    }));

    const store = shell.getStore<EntryPointState>();

    shell.contributeAPI(APIKeys.LitePanelAPI, () => ({
      contributePanel: ({ panel }, condition) => {
        panelSlot.contribute(shell, { panel }, condition);
      },
      getReadOnlyState: () => getReadOnly(store.getState().devMode),
    }));
  },
  async extend(shell: Shell) {
    const wixCodeEditorAdapter = shell.getAPI(WixCodeEditorAdapterAPI);
    const litePanelAPI = shell.getAPI(APIKeys.LitePanelAPI);
    const { legacyDependencies, editorAPI } = wixCodeEditorAdapter;
    const store = shell.getStore<EntryPointState>();

    const { experiment } = legacyDependencies;
    const isLiteEditor = experiment.isOpen(
      experimentUtils.Experiments.LiteEditor,
    );
    const isVeloInStudio = experiment.isOpen(
      experimentUtils.Experiments.VeloInStudio,
    );
    const shouldContributeLitePanel = isVeloInStudio || isLiteEditor;
    if (!shouldContributeLitePanel) {
      return;
    }

    const PanelRenderer = createPanelSlot({
      legacyDependencies,
      store,
      shell,
    });

    const biLoggerAPI = createBiLoggerAPI({
      msid: () =>
        wixCodeEditorAdapter.getEditorContext
          ? wixCodeEditorAdapter?.getEditorContext().msid
          : '',
      builderType: window.loggerModel?.fedOpsAppName,
    });

    const locale = legacyDependencies.util.editorModel.languageCode;
    const messages = await import(`../assets/locale/messages_${locale}.json`);

    litePanelAPI.contributePanel(
      {
        panel: ({ toggleDevMode, closePanel, devModeStatus }) => (
          <BiLoggerContext.Provider value={biLoggerAPI}>
            <TranslationProvider
              options={{
                locale: legacyDependencies.util.editorModel.languageCode,
                messages,
              }}
            >
              {devModeStatus === 'OFF' ? (
                <PanelEmptyState
                  closePanel={closePanel}
                  ctaClick={() => {
                    toggleDevMode();
                  }}
                  panelManager={editorAPI.panelManager}
                />
              ) : (
                <></>
              )}
            </TranslationProvider>
          </BiLoggerContext.Provider>
        ),
      },
      () => !wixCodeEditorAdapter.editorAPI.developerMode.isEnabled(),
    );

    wixCodeEditorAdapter.contributeLitePanel(
      shell,
      ({ status: devModeStatus, loading, closePanel, ...rest }: any) => (
        <PanelRenderer
          closePanel={closePanel}
          devModeStatus={devModeStatus}
          setReadOnly={(readOnly: boolean) =>
            store.dispatch(setReadOnly(readOnly))
          }
          loading={loading}
          {...rest}
        />
      ),
    );

    fedops.getLogger().interactionEnded(fedops.interactions.load);
  },
};
