import React, { ReactNode, useEffect, useState } from 'react';
import {
  CustomModal,
  Heading,
  NotificationBanner,
  Preloader,
  RichText,
  Spacer,
} from '@wix/wix-base-ui';
import dataHooks from '../dataHooks';
import styles from './DisconnectModalBase.scss';
import { useTranslation } from '@wix/wix-i18n-config';
import { useBiLogger } from '../../context/biLoggerContext';
import { createDisconnectFromGithubActions } from '../../utils/biEvents';
import { MODALS_ACTIONS } from '../../utils/biConsts';
import { DisconnectModalProps } from './DisconnectModal';
import { ON_BOARDING_SUPPORTED_ERRORS } from '../../constants';

export interface DisconnectModalBaseProps extends DisconnectModalProps {
  title: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: () => void;
  onCloseButtonClick?: () => void;
  helpLink?: string;
  content: ReactNode;
  dataHook: string;
  errorType?: ON_BOARDING_SUPPORTED_ERRORS;
}

export const DisconnectModalBase = ({
  closeModal,
  githubAPI,
  title,
  secondaryButtonText,
  primaryButtonText,
  secondaryButtonOnClick,
  helpLink,
  content,
  onCloseButtonClick,
  dataHook,
  errorType,
}: DisconnectModalBaseProps) => {
  const [t] = useTranslation();
  const [disconnectInProgress, setDisconnectInProgress] = useState(false);
  const [disconnectError, setDisconnectError] = useState(false);
  const bi = useBiLogger();
  const disconnectBiEvents = createDisconnectFromGithubActions(githubAPI);

  useEffect(() => {
    bi.report(
      disconnectBiEvents.disconnectModalShown({
        errorType,
      }),
    );
  }, []);

  const reportBiEvent = (action: string) => {
    bi.report(
      disconnectBiEvents.disconnectFromGithubModalAction({
        action,
        errorType,
      }),
    );
  };

  const disconnect = async () => {
    if (disconnectError) {
      reportBiEvent(MODALS_ACTIONS.TRY_AGAIN);
    } else {
      reportBiEvent(MODALS_ACTIONS.DISCONNECT);
    }
    setDisconnectError(false);
    try {
      setDisconnectInProgress(true);
      await githubAPI.disconnectGithubRepository();
      bi.report(
        disconnectBiEvents.disconnectStatusAction({ success: true, errorType }),
      );
      closeModal();
    } catch {
      bi.report(
        disconnectBiEvents.disconnectStatusAction({
          success: false,
          errorType,
        }),
      );
      setDisconnectError(true);
    } finally {
      setDisconnectInProgress(false);
    }
  };

  const primaryButtonTextOrComponent = disconnectError ? (
    t('githubIntegration.disconnect_modal.try_again')
  ) : disconnectInProgress ? (
    <div className={styles.preloaderButton}>
      <Preloader className="tiny light" />
    </div>
  ) : (
    primaryButtonText
  );

  return (
    <div className={styles.modalWidth}>
      <CustomModal
        removeContentPadding={true}
        dataHook={dataHook}
        onCloseButtonClick={onCloseButtonClick}
        // @ts-expect-error - until we modify base-ui type to support a node
        primaryButtonText={primaryButtonTextOrComponent}
        primaryButtonOnClick={disconnect}
        secondaryButtonText={secondaryButtonText}
        secondaryButtonOnClick={secondaryButtonOnClick}
        onHelpButtonClick={() => {
          reportBiEvent(MODALS_ACTIONS.HELP);
          helpLink && window.open(helpLink, '_blank');
        }}
      >
        <ModalContent
          disconnectError={disconnectError}
          title={title}
          content={content}
        />
      </CustomModal>
    </div>
  );
};

const ModalContent: React.FC<{
  title: string;
  disconnectError: boolean;
  content: ReactNode;
}> = ({ disconnectError, title, content }) => {
  const [t] = useTranslation();

  return (
    <>
      {disconnectError && (
        <>
          <Spacer type="Spacer06" />
          <NotificationBanner
            dataHook={dataHooks.disconnectModal.error}
            skin="alert"
            shouldTranslate={false}
            multiline={true}
            text={t('githubIntegration.disconnect_modal.error')}
          ></NotificationBanner>
        </>
      )}
      <div className={styles.content}>
        <Heading appearance="h4" dataHook={dataHooks.disconnectModal.title}>
          {title}
        </Heading>
        <Spacer type="Spacer02" />
        <RichText
          className={styles.modalContent}
          dataHook={dataHooks.disconnectModal.description.container}
        >
          {content}
        </RichText>
      </div>
    </>
  );
};
