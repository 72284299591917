export const dataHooks = {
  ai_panel: {
    event_label: 'ai_panel.event_label',
    event_dropdown: 'ai_panel.event_dropdown',
    user_input_label: 'ai_panel.user_input_label',
    userInput: 'ai_panel.user_input',
    modal: 'ai_panel.modal',
    generated_result: 'ai_panel.generated_result',
    markdown_container: 'ai_panel.markdown_container',
    legal_statement: {
      container: 'ai_panel.legal_statement.container',
      part_one: 'ai_panel.legal_statement.part_one',
      terms_of_use: 'terms_of_use',
      part_two: 'ai_panel.legal_statement.part_two',
      part_three: 'ai_panel.legal_statement.part_three',
    },
    error_modal: {
      modal: 'ai_panel.error_modal.main',
      primaryText: 'ai_panel.error_modal.primary_text',
      secondaryText: 'ai_panel.error_modal.secondary_text',
    },
  },
};
