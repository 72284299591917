import React from 'react';
import { TextButton, Divider, Tooltip } from '@wix/wix-base-ui';
import { useBiLogger, useTranslation } from '@wix/wix-code-common-components';
import s from './menu.scss';
import { veloLiteIntroduction } from '@wix/bi-logger-platform/v2';
import dataHooks from '../dataHooks';

interface DevModeOffButtonProps {
  toggleDevMode: () => void;
}

export const DevModeOffButton: React.FC<DevModeOffButtonProps> = ({
  toggleDevMode,
}) => {
  const [t] = useTranslation();
  const bi = useBiLogger();
  return (
    <div>
      <Divider long={true} />
      <div>
        <Tooltip
          content={t('lite_panel.menu.turn_off_dev_mode_tooltip')}
          shouldTranslate={false}
        >
          <div className={s.devModeOffButton}>
            <TextButton
              dataHook={dataHooks.dev_mode_off_button}
              onClick={() => {
                toggleDevMode();
                bi.report(veloLiteIntroduction({ item_name: 'dev_mode_off' }));
              }}
              shouldTranslate={false}
            >
              {t('lite_panel.menu.turn_off_dev_mode_cta')}
            </TextButton>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
