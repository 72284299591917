import { useState } from 'react';
import { UserTypingInfo } from '../../internal-types';

export const useUserTypingInfo = () => {
  const [userStartTypingIndicaiton, setUserStartTypingIndicaiton] =
    useState<UserTypingInfo>({
      didUserStartTypingNamespace: false,
      didUserStartTypingEndpoint: false,
      didUserStartTypingSecretkey: false,
    });

  return { userStartTypingIndicaiton, setUserStartTypingIndicaiton };
};
