import React, { useContext } from 'react';
import s from './PackagesListItemControls.scss';
import { PkgActionButton } from './pkgActionButtons/PkgActionButton';
import { PkgControlsSymbol } from './PkgControlsSymbol';
import { PkgControlsContextMenu } from './PkgControlsContextMenu';
import { PackageItemContext } from '../PackageItemContext';
import { experimentUtils } from '@wix/wix-code-common';
import { isModulePending, isModuleRejected } from '../../../../utils';

export interface PackagesListItemControlsProps {
  isHovered: boolean;
  isSelected: boolean;
}

export const PackagesListItemControls: React.FC<
  PackagesListItemControlsProps
> = ({ isHovered, isSelected }) => {
  const [isContextMenuOpen, setContextMenuOpen] = React.useState(false);
  const { availablePkg } = useContext(PackageItemContext);

  const isModulePendingOrRejected =
    isModulePending(availablePkg) || isModuleRejected(availablePkg);
  const showContextMenu = isSelected || isHovered || isContextMenuOpen;

  return (
    <div className={s.controls}>
      <PkgActionButton />
      {isModulePendingOrRejected &&
      !experimentUtils.isNpmPkgManagerImprovements() ? (
        <PkgControlsSymbol />
      ) : (
        <PkgControlsContextMenu
          onToggle={setContextMenuOpen}
          show={showContextMenu}
        />
      )}
    </div>
  );
};
