import { PredefinedSidebarTabs } from '@wix/wix-code-plugin-contracts';
import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { ExternalFilesTreeAPIKey } from './externalApiKeys';

export interface ExternalFilesTreeAPI {
  selectTab(tabName: PredefinedSidebarTabs): void;
  showNewFileInput(
    initialName: string,
    suffix: string,
    isFolder: boolean,
    folderPath: string,
  ): void;
}

export const ExternalFilesTreeEntryPoint: EntryPoint = {
  name: 'External Files Tree',
  getDependencyAPIs() {
    return [APIKeys.FilesTreeAPI];
  },
  declareAPIs() {
    return [ExternalFilesTreeAPIKey];
  },
  attach(shell: Shell) {
    shell.contributeAPI(ExternalFilesTreeAPIKey, () => {
      const filesTreeAPI = shell.getAPI(APIKeys.FilesTreeAPI);
      return {
        selectTab: filesTreeAPI.selectTab,
        showNewFileInput: filesTreeAPI.showNewFileInput,
      };
    });
  },
};
