// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Hs78DJ{width:210px;border-radius:4px;gap:8px}.TPNw5A{margin-bottom:4px}.fNoOCE{margin-inline:8px}.x4hYjJ{display:flex;flex-direction:column}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeTooltip": "Hs78DJ",
	"title": "TPNw5A",
	"badgeContainer": "fNoOCE",
	"tooltipContent": "x4hYjJ"
};
export default ___CSS_LOADER_EXPORT___;
