import * as React from 'react';
import { CodeReusePkgTreeContext } from '../CodeReusePkgTreeContext';
import s from '../codeReusePkgTree.scss';
import { TestVersionSymbol } from './TestVersionSymbol';
import { consts } from '@wix/wix-code-consts';
import { PrivateReadOnlySymbol } from './PrivateReadOnlySymbol';
import { ReadOnlyFileSymbol } from './ReadOnlyFileSymbol';
import {
  getMissingDependencyId,
  InstallDependencyPimple,
} from './InstallDependencyPimple';

import { isInstalledTestVersion } from '@/toExtract/packages/utils';
import { InstalledCodeReusePkg } from '@/toExtract/packages/packagesModalContext';
import { PkgTreeContext } from '../../PkgTreeContext';
import {
  useTranslation,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import BI_EVENTS from '@/legacy/bi/events';

interface CodeReuseSuffixSymbolProps {
  isRoot: boolean;
  isFolder: boolean;
  pkg: InstalledCodeReusePkg;
  treeNodeLabel: string;
}

export const CodeReuseSuffixSymbol: React.FC<CodeReuseSuffixSymbolProps> = ({
  isRoot,
  isFolder,
  pkg,
  treeNodeLabel,
}) => {
  const { isPrivateScope, packagesService } = React.useContext(
    CodeReusePkgTreeContext,
  );
  const { latestVersion } = React.useContext(CodeReusePkgTreeContext);
  const { isBlocksCombinedMode } = React.useContext(PkgTreeContext);
  const { editorAPI } = useEditorLegacyAPIs();
  const [t] = useTranslation();

  const installed = isBlocksCombinedMode
    ? pkg
    : packagesService.getInstalledPkg(treeNodeLabel);

  const isTestVersion = isInstalledTestVersion(installed);
  const isReadme = treeNodeLabel === consts.README_FILE_NAME;
  const appId =
    latestVersion && getMissingDependencyId(editorAPI, latestVersion)!;

  const shouldShowPrivateReadOnlySymbol =
    !isRoot && isPrivateScope && !isReadme;
  const shouldShowReadOnlySymbol = !isRoot && !isFolder && !isReadme;

  const HELP_URL = t('Left_Tree_Components_Readme_Tooltip_LearnMore_URL');

  return isRoot && appId ? (
    <InstallDependencyPimple />
  ) : isTestVersion && isFolder ? (
    <TestVersionSymbol />
  ) : shouldShowPrivateReadOnlySymbol ? (
    <PrivateReadOnlySymbol isFolder={isFolder} />
  ) : shouldShowReadOnlySymbol ? (
    <ReadOnlyFileSymbol
      HELP_URL={HELP_URL}
      helpLinkText={t('Left_Tree_Components_Readme_Tooltip_LearnMore')}
      tooltipText={t('Left_Tree_Components_Readme_Tooltip')}
      onClick={() => {
        editorAPI.bi.event(BI_EVENTS.CODE_REUSE_ADD_DEPENDENCY_CLICK, {
          related_link: HELP_URL,
          link_name: 'learn_more',
          link_type: 'resource',
          origin: pkg.name,
        });
      }}
    />
  ) : (
    <div className={s.symbolPlacholder} />
  );
};
