import React from 'react';
import styles from './TerminalCommand.scss';
import _ from 'lodash';

export type TerminalCommandProps = {
  command: string;
  dataHook: string;
};

export const TerminalCommand: React.FC<TerminalCommandProps> = ({
  command,
  dataHook,
}) => {
  const commands = command.split(' ');
  const firstWord = _.head(commands);
  const restOfCommand = _.tail(commands).join(' ');
  return (
    <div className={styles.terminalCommand} data-hook={dataHook}>
      <span className={styles.terminalCommandFirstWord}>{`${firstWord}`}</span>
      {_.isEmpty(restOfCommand) ? '' : ` ${restOfCommand}`}
    </div>
  );
};

export default TerminalCommand;
