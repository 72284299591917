const dataHooks = {
  topBar: {
    liveEditorBadge: {
      container: 'liveEditor.topBar.liveEditorBadge.container',
      tooltip: {
        content: 'liveEditor.topBar.liveEditorBadge.tooltip.content',
        learnMoreBtn: 'liveEditor.topBar.liveEditorBadge.tooltip.learnMoreBtn',
      },
    },
    disabledPublishButton: {
      button: 'liveEditor.topBar.disabledPublish.button',
    },
  },
  syncLocallyButton: {
    button: 'liveEditor.syncLocallyButton',
    confirm: 'liveEditor.syncLocallyButton.confirm',
    syncLoader: 'liveEditor.syncLocallyButton.syncLoader',
    popover: 'liveEditor.syncLocallyButton.popover',
  },
  codeEditorFooter: {
    container: 'local-code-editor-footer-container',
    button: 'local-code-editor-footer-button',
    description: 'local-code-editor-footer-description',
  },
  syncChangesModal: {
    checkbox: 'liveEditor.syncChangesModal.checkbox',
  },
  errorModal: 'liveEditor.errorModal',
};

export default dataHooks;
