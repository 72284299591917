import React from 'react';
import {
  BiLoggerAPI,
  DevContextAPI,
  FilesViewAPI,
  LegacyEditorDependencies,
  PredefinedSidebarTabs,
  WixCodeStoreAPI,
} from '@wix/wix-code-plugin-contracts';
import { connectWithShell, Shell } from '@wix/wix-code-repluggable';
import { PagesTab as NewPagesTab } from './pagesTree/PagesTab';
import { PagesTab as OldPagesTab } from './oldPagesTree/PagesTab';
import { PagesTabStore } from './state';
import { getContributedSections } from './state/selectors';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { experimentUtils } from '@wix/wix-code-common';
import PagesIcon from './assets/icons/page_code.svg';

interface PagesTabProps {
  legacyDependenciesAPI: LegacyEditorDependencies;
  wixCodeStoreAPI: WixCodeStoreAPI;
  biLoggerAPI: BiLoggerAPI;
  devContextAPI: DevContextAPI;
  filesViewAPI: FilesViewAPI;
  wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI;
}

interface PagesTabCreatorProps {
  shell: Shell;
  apis: PagesTabProps;
}

export const panelHeaderData = {
  infoLinkText: 'Left_Tree_Components_Tooltip_SitePages_Link_Text',
  infoLinkUrl: 'Left_Tree_Components_Tooltip_SitePages_Link_URL',
  infoText: 'Left_Tree_Components_Tooltip_SitePages',
  label: 'Left_Tree_Components_Title_SitePages',
};

export const createPagesTab = ({ shell, apis }: PagesTabCreatorProps) => ({
  id: PredefinedSidebarTabs.PAGE_CODE,
  dataHook: `${PredefinedSidebarTabs.PAGE_CODE}_tab`,
  shouldShowTab: () => true,
  symbol: <PagesIcon />,
  creator: () => createPagesTabComp({ shell, apis }),
  ...panelHeaderData,
});

export const createPagesTabComp = ({ shell, apis }: PagesTabCreatorProps) => {
  const PagesTab = experimentUtils.isNewPagesMenu() ? NewPagesTab : OldPagesTab;
  const ConnectedPagesTab = connectWithShell<PagesTabStore, PagesTabProps, {}>(
    (_boundedShell, state) => ({
      contributedSections: getContributedSections(state),
      selectedIdeTabId: apis.devContextAPI.getSelectedFileId(),
    }),
    undefined,
    shell,
    {
      allowOutOfEntryPoint: true,
      renderOutsideProvider: true,
    },
  )(PagesTab);

  return <ConnectedPagesTab {...apis} />;
};
