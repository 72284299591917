import { Preloader, TreeListItem } from '@wix/wix-base-ui';
import React, { useContext, useEffect, useState } from 'react';
import { dataHooks } from '../../dataHooks';
import styles from './PendingExternalDb.scss';
import { SuccessBoldSmall } from '@wix/wix-ui-icons-common/classic-editor';
import { useTranslation } from '@wix/wix-i18n-config';
import { TabContext } from '../tabContext';

export interface PendingExternalDbIndicationsProps {
  creationDate: Date;
}

export const PendingExternalDbIndications: React.FC<
  PendingExternalDbIndicationsProps
> = ({ creationDate }) => {
  const [t] = useTranslation();
  const { platformAppsAPI } = useContext(TabContext);

  const [creationStage, setCreationStage] = useState<number>(1);

  useEffect(() => {
    const onFirstStage = (diffMins: number) => {
      setTimeout(() => {
        onSecondStage(0);
      }, (5 - diffMins) * 60000);
    };

    const onSecondStage = (diffMins: number) => {
      setCreationStage(2);
      setTimeout(() => {
        onThirdStage(0);
      }, (5 - diffMins) * 60000);
    };

    const onThirdStage = (diffMins: number) => {
      setCreationStage(3);
      setTimeout(() => {
        platformAppsAPI.refreshSchemasCache();
      }, (5 - diffMins) * 60000);
    };

    const now = new Date();
    const diffMs = now.getTime() - creationDate.getTime();
    const diffMins = Math.floor(diffMs / 1000 / 60);
    if (diffMins < 5) {
      onFirstStage(diffMins);
    } else if (diffMins < 10) {
      onSecondStage(diffMins - 5);
    } else {
      onThirdStage(diffMins - 10);
    }
  }, [setCreationStage, creationDate, platformAppsAPI]);
  return (
    <div>
      <TreeListItem
        dataHook={dataHooks.TREE_NODE}
        label={t('WixCode_TreeView_ExternalDatabases.Created.Setup.Stage1')}
        size="small"
        depth={2}
        disabled={true}
        shouldTranslate={false}
        alwaysShowSuffix={true}
        suffix={
          creationStage === 1 ? (
            <div className={styles.preloaderContainer}>
              <Preloader className="tiny" />
            </div>
          ) : (
            <div className={styles.successIconColor}>
              <SuccessBoldSmall />
            </div>
          )
        }
      />
      <TreeListItem
        dataHook={dataHooks.TREE_NODE}
        label={t('WixCode_TreeView_ExternalDatabases.Created.Setup.Stage2')}
        size="small"
        depth={2}
        disabled={true}
        shouldTranslate={false}
        alwaysShowSuffix={true}
        suffix={
          creationStage > 1 ? (
            creationStage === 2 ? (
              <div className={styles.preloaderContainer}>
                <Preloader className="tiny" />
              </div>
            ) : (
              <div className={styles.successIconColor}>
                <SuccessBoldSmall />
              </div>
            )
          ) : null
        }
      />
      <TreeListItem
        dataHook={dataHooks.TREE_NODE}
        label={t('WixCode_TreeView_ExternalDatabases.Created.Setup.Stage3')}
        size="small"
        depth={2}
        disabled={true}
        shouldTranslate={false}
        alwaysShowSuffix={true}
        suffix={
          creationStage === 3 ? (
            <div className={styles.preloaderContainer}>
              <Preloader className="tiny" />
            </div>
          ) : null
        }
      />
    </div>
  );
};
