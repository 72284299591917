import React, { useEffect } from 'react';
import { LegacyEditorDependencies } from '@wix/wix-code-plugin-contracts';
import {
  ScopedStore,
  Shell,
  connectWithShell,
  ExtensionItem,
} from '@wix/wix-code-repluggable';
import { EntryPointState, DevModeState } from '../../state';
import { connectToEditorStore } from '../utils';
import { TranslationProvider } from '@wix/wix-code-common-components';
import { LitePanelSlot, PanelContribution } from '.';
import s from './panelSlot.scss';
import dataHooks from '../dataHooks';
import { Preloader } from '@wix/wix-base-ui';

interface LitePanelCreatorProps {
  legacyDependencies: LegacyEditorDependencies;
  store: ScopedStore<EntryPointState>;
  shell: Shell;
}

interface PanelSlotRendererProps {
  slotItems: ExtensionItem<PanelContribution>[];
  devModeStatus: 'OFF' | 'WRITE' | 'READ_ONLY';
  loading: boolean;
  setReadOnly: (readOnly: boolean) => void;
}

const PanelSlotRenderer = ({
  slotItems,
  devModeStatus,
  setReadOnly,
  loading,
  ...rest
}: PanelSlotRendererProps) => {
  useEffect(() => {
    setReadOnly(devModeStatus === 'READ_ONLY');
  }, [devModeStatus, setReadOnly]);

  const items = slotItems.map(({ contribution }) => contribution.panel);

  return loading ? (
    <div className={s.loader}>
      <Preloader className="large" />
    </div>
  ) : (
    <div className={s.panel} data-hook={dataHooks.code_panel}>
      {items.map((PanelItem, index) => (
        <PanelItem
          key={`slot_panel_item_${index}`}
          devModeStatus={devModeStatus}
          {...rest}
        />
      ))}
    </div>
  );
};

export const createPanelSlot = ({
  legacyDependencies,
  shell,
}: LitePanelCreatorProps) => {
  const { util } = legacyDependencies;

  const ConnectedLitePanel = connectToEditorStore({
    util,
    component: PanelSlotRenderer,
    mapEditorStateToProps:
      () =>
      ({ editorAPI }) => ({
        toggleDevMode: editorAPI.developerMode.toggle,
      }),
  });

  const ConnectedWithShell = connectWithShell<DevModeState>(
    (boundShell) => {
      return {
        slotItems: boundShell.getSlot(LitePanelSlot).getItems(),
      };
    },
    undefined,
    shell,
    {
      renderOutsideProvider: true,
      allowOutOfEntryPoint: true,
    },
  )(ConnectedLitePanel);

  return (props: any) => {
    return (
      <TranslationProvider
        options={{
          locale: util.editorModel.languageCode,
          asyncMessagesLoader: (locale: string) =>
            import(`../../assets/locale/messages_${locale}.json`),
        }}
      >
        <ConnectedWithShell {...props} />
      </TranslationProvider>
    );
  };
};
