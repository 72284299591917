import _ from 'lodash';
import PropTypes from 'prop-types';

import once_ from 'lodash/once';

export default once_(({ constants }) => {
  const containerStates = constants.DEVELOPER_MODE.CONTAINER_STATES;

  return {
    context: PropTypes.shape({
      type: PropTypes.oneOf(_.values(constants.DEVELOPER_MODE.CONTEXT_TYPES))
        .isRequired,
      data: PropTypes.object.isRequired,
    }),
    containerState: PropTypes.oneOf([
      containerStates.MINIMIZED,
      containerStates.MAXIMIZED,
      containerStates.CUSTOM,
    ]),
  };
});
