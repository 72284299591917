import * as React from 'react';
import { PackagesModalContext } from '../../../../packagesModalContext';
import { PackagesListItemDetailsDisplay } from './PackagesListItemDetailsDisplay';
import { getVersionDisplayText } from '../../../../utils';
import { useContext } from 'react';
import { getVeloPkgsSearchKeyword } from '../../../packagesModalSelectors';
import { MODAL_TABS } from '../../../packagesModalConsts';
import { PackageItemContext } from '../PackageItemContext';
import { useSelector } from '@wix/wix-code-common-components';

export const highlightSearchKeyword = (text: string, searchKeyword: string) => {
  const regex = new RegExp(`(${searchKeyword})`, 'gi');
  const arr = text.split(regex);
  return (
    <>
      {arr.map((word, index) =>
        word.toLowerCase() === searchKeyword.toLowerCase() ? (
          <mark key={index}>{word}</mark>
        ) : (
          <span key={index}>{word}</span>
        ),
      )}
    </>
  );
};
export const CodeReusePackageListItemDetails: React.FC = () => {
  const { t, currentTab } = useContext(PackagesModalContext);
  const { availablePkg, installedPkg } = useContext(PackageItemContext);
  const { description, name, downloadCount, title } = availablePkg;
  const searchKeyword = useSelector(getVeloPkgsSearchKeyword);
  const shouldHighlightText =
    searchKeyword && currentTab === MODAL_TABS.BUILT_BY_VELO;

  const getPackageImportName = () => {
    if (shouldHighlightText) {
      return highlightSearchKeyword(name, searchKeyword);
    }
    return <>{name}</>;
  };

  return (
    <PackagesListItemDetailsDisplay
      packageName={getPackageImportName()}
      packageDescription={description}
      downloadCount={downloadCount}
      packageTitle={title}
      displayVersion={getVersionDisplayText(installedPkg || availablePkg, t)}
    />
  );
};
