// TODO - arielw, Nov 25th/
// For now, this file contins only isReservedFileSystemItem.
// but I'm in the process of moving all file name validation
// stuff from FileTreeTS to here for cleaner code
import { consts } from '@wix/wix-code-consts';
import { getStore } from '@/infra/redux-state/store/storeManager';
import { getContributedFileNameValidationRules } from './filesTree/treeSectionSelectors';

function isPagesRoot(
  parentId: AnyFixMe,
  name: AnyFixMe,
  publicRootId: AnyFixMe,
) {
  return parentId === publicRootId && name === consts.PAGES_ROOT;
}

function isAuthorizationConfig(
  parentId: AnyFixMe,
  name: AnyFixMe,
  backendRootId: AnyFixMe,
) {
  return (
    parentId === backendRootId && name === consts.AUTHORIZATION_CONFIG_FILE_NAME
  );
}

function isCodeReuseConfigFolder(
  parentId: AnyFixMe,
  name: AnyFixMe,
  rootId: AnyFixMe,
) {
  return parentId === rootId && name === consts.CONFIG_FOLDER_NAME;
}

function isNpmPackageJsonFile(
  parentId: AnyFixMe,
  name: AnyFixMe,
  backendRootId: AnyFixMe,
) {
  return (
    parentId === backendRootId &&
    name === consts.WIX_CODE_PACKAGE_JSON_FILE_NAME
  );
}

interface IsReservedFileSystemNameParams {
  parentId: string;
  name: string;
  publicRootId: string;
  backendRootId: string;
  hiddenFiles: string[];
}

function isReservedFileSystemName({
  parentId,
  name,
  publicRootId,
  backendRootId,
  hiddenFiles,
}: IsReservedFileSystemNameParams) {
  const state = getStore().getState();
  const contributedRules = getContributedFileNameValidationRules(state);

  const parent = parentId.endsWith('/') ? parentId : `${parentId}/`;
  return (
    isPagesRoot(parent, name, publicRootId) ||
    isAuthorizationConfig(parent, name, backendRootId) ||
    isNpmPackageJsonFile(parent, name, backendRootId) ||
    isCodeReuseConfigFolder(parent, name, publicRootId) ||
    isCodeReuseConfigFolder(parent, name, backendRootId) ||
    hiddenFiles.includes(`${parent}${name}`) ||
    contributedRules.some((rule) => rule(name))
  );
}

export { isReservedFileSystemName };
