import { CloudUserCodeDevAnalyzer } from '@wix/ambassador-cloud-user-code-dev-analyzer/http';
import { PageDetails } from '@wix/ambassador-cloud-user-code-dev-analyzer/types';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';

const CODE_DEV_ANALYZER_BASE_URL = '/_api/cloud-user-code-dev-analyzer';

const userCodeService = CloudUserCodeDevAnalyzer(
  CODE_DEV_ANALYZER_BASE_URL,
).UserCodeDevAnalyzer();

export class UserCodeDevAnalyzerFacade {
  constructor(private editorAPI: EditorAPI) {}

  private _userCodeService() {
    return userCodeService({
      authorization: this.editorAPI.wixCode.getClientSpec().instance,
    });
  }
  calculateAndReportEligibilityRequest(): Promise<{}> {
    const { calculateAndReportEligibility } = this._userCodeService();
    const { gridAppId } = this.editorAPI.wixCode.fileSystem.getViewerInfo();
    const pageId = this.editorAPI.pages.getFocusedPageId();

    return calculateAndReportEligibility({
      gridAppId,
      pageIds: [pageId],
    });
  }

  async getPageDetails(): Promise<PageDetails | undefined> {
    const { getPagesDetails } = this._userCodeService();
    const { gridAppId } = this.editorAPI.wixCode.fileSystem.getViewerInfo();
    const pageId = this.editorAPI.pages.getFocusedPageId();

    const data = await getPagesDetails({
      gridAppId,
      pageIds: [pageId],
    });
    return (data.pagesDetails || []).find((p) => p.pageId === pageId);
  }
}
