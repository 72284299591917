import * as React from 'react';
import { ProviderProps } from 'react';
import {
  EditorAPI,
  LegacyEditorDependencies,
} from '@wix/wix-code-plugin-contracts';
import { connectToStores } from './store/connectToStores';

interface APIsContextType {
  editorAPI: EditorAPI;
  legacyDependenciesAPI: LegacyEditorDependencies;
}
const APIsContext = React.createContext<APIsContextType | undefined>(undefined);

export const useEditorLegacyAPIs = () => {
  const maybeContext = React.useContext(APIsContext);
  if (maybeContext === undefined) {
    throw new Error('useEditorLegacyAPIs must be used within a Provider');
  }
  return maybeContext;
};

let ConnectedComponent = null as any;
export const EditorLegacyAPIsContextProvider: React.FC<{
  legacyDependencies: LegacyEditorDependencies;
}> = ({ children, legacyDependencies }) => {
  if (ConnectedComponent === null) {
    ConnectedComponent = connectToStores({
      util: legacyDependencies.util,
      mapEditorStateToProps: ({ editorAPI }) => {
        const compProps: ProviderProps<APIsContextType> = {
          value: {
            editorAPI,
            legacyDependenciesAPI: legacyDependencies,
          },
        };
        return compProps;
      },
      comp: APIsContext.Provider,
    });
  }
  return <ConnectedComponent>{children}</ConnectedComponent>;
};
