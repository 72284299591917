import './packagesModal.global.scss';
import React, { useEffect } from 'react';
import * as _ from 'lodash';

import withWixCodeStore from '@/infra/redux-state/store/withWixCodeStore';
import * as packagesModalActions from './packagesModalActions';
import {
  getCurrentTab,
  getSelectedPackageForTab,
  getJustInstalledPkgs,
  getJustRequestedPkgs,
} from './packagesModalSelectors';
import {
  getIndexSearchModules,
  getInstalledModulesInfo,
  shouldShowSubmitRequestPanel,
  getIsPackagesFetchingDone,
  getDefaultNpmPackages,
  getSearchKeyword,
  getSelectedPkgData,
} from './packagesModalContent/pkgLists/npmPackagesList/selectors/modulesSelectors';
import {
  init as initNpmPackages,
  performIndexSearch,
  setSelectedNpmPkgData as setSelectedNpmPkgDataAction,
} from './packagesModalContent/pkgLists/npmPackagesList/actions/modulesActions';
import PackagesModalContent from './packagesModalContent/PackagesModalContent';
import {
  InstalledPkgs,
  AvailablePkgs,
  PackagesModalContext,
  NpmPackageInfo,
} from '../packagesModalContext';
import { PackagesService } from '../packagesService';
import { PackagesModalTabId, MODAL_TABS } from './packagesModalConsts';
import { codeReusePkgsSelectors } from '../packagesView/packagesViewSelectors';
import {
  useEditorLegacyAPIs,
  useDispatch,
  connectToStores,
} from '@wix/wix-code-common-components';

export interface PackagesModalProps {
  panelName: string;
  packagesService: PackagesService;
  showCodeReuse: boolean;
}

interface PackagesModalStateProps {
  currentTab: PackagesModalTabId;
  availablePkgs: AvailablePkgs;
  installedPkgs: InstalledPkgs;
  npmInstalledModulesInfo: any[];
  selectedPackage: string;
  isNpmPackagesFetchingDone: boolean;
  showSubmitRequest: boolean;
  justInstalledPkgs: string[];
  justRequestedPkgs: string[];
  selectedNpmPkgData: NpmPackageInfo | null;
  isBlocksCombinedMode: boolean;
}

interface PackagesModalDispatchProps {
  selectTab: React.Dispatch<PackagesModalTabId>;
  selectPackage: (name: string, tab: PackagesModalTabId) => void;
  addJustInstalledPkg: React.Dispatch<string>;
  clearJustInstalledPkg: React.Dispatch<void>;
  addJustRequestedPkg: React.Dispatch<string>;
  setSelectedNpmPkgData: (pkg: NpmPackageInfo) => void;
}

type PackagesModalCompProps = PackagesModalProps &
  PackagesModalStateProps &
  PackagesModalDispatchProps;

const PackagesModalComp: React.FC<PackagesModalCompProps> = ({
  packagesService,
  panelName,
  currentTab,
  installedPkgs,
  availablePkgs,
  selectTab,
  selectPackage,
  selectedPackage,
  isNpmPackagesFetchingDone,
  npmInstalledModulesInfo,
  showSubmitRequest,
  addJustInstalledPkg,
  clearJustInstalledPkg,
  addJustRequestedPkg,
  justInstalledPkgs,
  justRequestedPkgs,
  selectedNpmPkgData,
  setSelectedNpmPkgData,
  showCodeReuse,
  isBlocksCombinedMode,
}) => {
  const {
    editorAPI,
    legacyDependenciesAPI: { panels, util },
  } = useEditorLegacyAPIs();

  useEffect(() => {
    dispatch(initNpmPackages());
    packagesService.refreshAvailableCodeReusePkgs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pkg = packagesService.getAvailablePkg(selectedPackage);
  const pkgStatus = pkg?.status;
  const FocusPanelFrame = panels.frames.focusPanelFrame;
  const helpId = '26a55232-f046-4eac-8334-50895670e040';
  const dispatch = useDispatch();
  const onPackagesModalClose = () => {
    editorAPI.panelManager.closePanelByName('wixCode.panels.PackagesModal');
    dispatch(packagesModalActions.searchVeloPackage(''));
    dispatch(performIndexSearch(''));
  };

  return (
    <FocusPanelFrame
      panelName={panelName}
      helpId={helpId}
      title={util.translate(
        isBlocksCombinedMode
          ? 'Package_Manager_Main_Title'
          : 'Package_Manager_Main_Title_Old',
      )}
      className="packages-modal-panel"
      dataHook="packages-modal-panel"
      onClose={onPackagesModalClose}
    >
      <PackagesModalContext.Provider
        value={{
          packagesService,
          currentTab,
          npmInstalledModulesInfo,
          availablePkgs,
          installedPkgs,
          selectTab,
          selectPackage,
          selectedPackage,
          isNpmPackagesFetchingDone,
          showSubmitRequest,
          t: util.translate,
          sendBi: editorAPI.bi.event,
          addJustInstalledPkg,
          clearJustInstalledPkg,
          addJustRequestedPkg,
          justInstalledPkgs,
          justRequestedPkgs,
          setSelectedNpmPkgData,
          selectedNpmPkgData,
          showCodeReuse,
          pkgStatus,
          isBlocksCombinedMode,
        }}
      >
        <PackagesModalContent />
      </PackagesModalContext.Provider>
    </FocusPanelFrame>
  );
};

const createMapCodeStateToProps = (isBlocksCombinedMode = false) => {
  return (
    state: AnyFixMe,
    { packagesService }: PackagesModalProps,
  ): PackagesModalStateProps => {
    const currentTab = getCurrentTab(state);
    const currentlySelectedPackage = getSelectedPackageForTab(
      state,
      currentTab,
    );
    const justInstalledPkgs = getJustInstalledPkgs(state);
    const justRequestedPkgs = getJustRequestedPkgs(state);
    const installedPkgs = isBlocksCombinedMode
      ? {
          ...packagesService.getInstalled(state),
          codeReuse: packagesService.getInstalledVeloPkgs(state),
        }
      : packagesService.getInstalled(state);

    const availablePkgs: AvailablePkgs = {
      codeReuse: codeReusePkgsSelectors.available.all(state),
      npm: getIndexSearchModules(state),
    };
    const defaultNpmPkgs = getDefaultNpmPackages(state);
    const isNpmEmptyState = _.isEmpty(getSearchKeyword(state));

    const npmInstalledModulesInfo = getInstalledModulesInfo(state);
    const isNpmPackagesFetchingDone = getIsPackagesFetchingDone(state);
    const showSubmitRequest = shouldShowSubmitRequestPanel(state);

    const isValidPkgName = (
      pkgs: { name: string }[],
      pkgName: string,
    ): boolean => !!pkgName && pkgs.some(({ name }) => name === pkgName);

    const selectPackageWithDefault = (
      pkgs: { name: string }[],
      name: string,
    ) => {
      return isValidPkgName(pkgs, name) ? name : _.head(pkgs)?.name;
    };

    const selectedNpmPkgData = getSelectedPkgData(state);

    const selectNpmPkg = (name: AnyFixMe) => {
      if (!!name && !!selectedNpmPkgData && name === selectedNpmPkgData.name) {
        return name;
      }
      if (isNpmEmptyState) {
        return selectPackageWithDefault(defaultNpmPkgs, name);
      }
      if (availablePkgs.npm.length) {
        return selectPackageWithDefault(availablePkgs.npm, name);
      }
      if (selectedNpmPkgData) {
        return selectedPackage.name;
      }
      return selectPackageWithDefault(defaultNpmPkgs, name);
    };

    const selectInstalledPkg = (name: AnyFixMe) => {
      const installedPackages = [
        ...installedPkgs.codeReuse,
        ...installedPkgs.npm,
      ];
      return selectPackageWithDefault(installedPackages, name);
    };

    let selectedPackage: AnyFixMe;
    switch (currentTab) {
      case MODAL_TABS.NPM_PACKAGES:
        selectedPackage = selectNpmPkg(currentlySelectedPackage);
        break;
      case MODAL_TABS.BUILT_BY_VELO:
        selectedPackage = selectPackageWithDefault(
          codeReusePkgsSelectors.available.byVelo(state),
          currentlySelectedPackage,
        );
        break;
      case MODAL_TABS.MY_PACKAGES:
        selectedPackage = selectPackageWithDefault(
          codeReusePkgsSelectors.available.myPackages(state),
          currentlySelectedPackage,
        );
        break;
      case MODAL_TABS.INSTALLED_PACKAGES:
        selectedPackage = selectInstalledPkg(currentlySelectedPackage);
        break;
      default:
        console.warn('unknown tab', currentTab);
    }

    return {
      currentTab,
      availablePkgs,
      npmInstalledModulesInfo,
      installedPkgs,
      isNpmPackagesFetchingDone,
      showSubmitRequest,
      selectedPackage,
      justInstalledPkgs,
      justRequestedPkgs,
      selectedNpmPkgData,
      isBlocksCombinedMode,
    };
  };
};

function mapCodeDispatchToProps(
  dispatch: React.Dispatch<React.ReducerAction<any>>,
): PackagesModalDispatchProps {
  return {
    selectTab: (tab) => dispatch(packagesModalActions.selectTab(tab)),
    selectPackage: (name: string, tab: PackagesModalTabId) =>
      dispatch(packagesModalActions.selectPackage(name, tab)),
    addJustInstalledPkg: (name: string) =>
      dispatch(packagesModalActions.addJustInstalledPkg(name)),
    clearJustInstalledPkg: () =>
      dispatch(packagesModalActions.clearJustInstalledPkgs()),
    addJustRequestedPkg: (name: string) =>
      dispatch(packagesModalActions.addJustRequestedPkg(name)),
    setSelectedNpmPkgData: (pkg: NpmPackageInfo) => {
      dispatch(setSelectedNpmPkgDataAction(pkg));
    },
  };
}

let ConnectedComponent = null as any;
const PackagesModalStoreConnect = (origProps: PackagesModalProps) => {
  const {
    legacyDependenciesAPI: { util, experiment },
  } = useEditorLegacyAPIs();
  const mapCodeStateToProps = createMapCodeStateToProps(
    experiment.isOpen('se_privateAppsPanel'),
  );

  if (ConnectedComponent === null) {
    ConnectedComponent = connectToStores<PackagesModalProps>({
      util,
      mapCodeStateToProps,
      mapCodeDispatchToProps,
      comp: PackagesModalComp,
    });
  }
  return <ConnectedComponent {...origProps} />;
};

const withStore = withWixCodeStore(PackagesModalStoreConnect);

export const PackagesModal = withStore;
