import _ from 'lodash';
import { consts } from '@wix/wix-code-consts';

export function getFileIdFromPageId(pageId) {
  return `public/${consts.PAGES_ROOT}/${pageId}${consts.JS_EXTENSION}`;
}

export function getFileIdFromStylesFolder(fileId) {
  return `styles/${fileId}`;
}

export function getPageIdFromFileId(fileId) {
  return fileId.substring(
    fileId.lastIndexOf('/') + 1,
    fileId.indexOf(consts.JS_EXTENSION),
  );
}

export function getMasterPageFileId() {
  return getFileIdFromPageId(consts.SITE_JS_PAGE_ID);
}

export function isCssFile(filePath) {
  return filePath.startsWith('styles/') && filePath.endsWith('.css');
}

const getPublicFolder = (editorAPI) =>
  editorAPI.wixCode.fileSystem.getRoots().public.location;

const isPublicFile = (editorAPI, path) =>
  _.startsWith(path, getPublicFolder(editorAPI));

const getBackendFolder = (editorAPI) =>
  editorAPI.wixCode.fileSystem.getRoots().backend.location;

const isBackendFile = (editorAPI, path) =>
  _.startsWith(path, getBackendFolder(editorAPI));

export const isPageFile = (editorAPI, path) => {
  const pagesFolder = editorAPI.wixCode.fileSystem.getRoots().pages.location;
  return _.startsWith(path, pagesFolder);
};

const isMasterPageId = (pageId) => {
  return pageId === consts.SITE_JS_PAGE_ID;
};

export const isMasterPageFile = (fileId) => {
  return isMasterPageId(getPageIdFromFileId(fileId));
};

const isReservedBackendFile = (editorAPI, path) => {
  const { WIX_CODE_PACKAGE_JSON_FILE_NAME, AUTHORIZATION_CONFIG_FILE_NAME } =
    consts;
  const backendFolder = getBackendFolder(editorAPI);
  return [WIX_CODE_PACKAGE_JSON_FILE_NAME, AUTHORIZATION_CONFIG_FILE_NAME].some(
    (reservedFileName) => path === backendFolder + reservedFileName,
  );
};

export const utilsCreator = _.once(({ platform, util }) => {
  function getDefaultPageContent(isMasterPage) {
    if (isMasterPage) {
      return [
        util.translate('WixCode_CodePanel_Editor_HelpComment_SiteCode'),
        util.translate('WixCode_Consts_Default_Empty_Master_Page_Content'),
      ].join('\n');
    }

    return [
      util.translate(
        'WixCode_CodePanel_Editor_HelpComment_ApiReference_NewDefaultPageCode',
      ),
      util.translate('WixCode_Consts_Default_Empty_Page_NewDefaultPageCode'),
    ].join('\n');
  }

  function getControllerType(editorAPI, compPointer) {
    const compData = editorAPI.components.data.get(compPointer);
    return _.get(compData, 'controllerType');
  }

  function getCompTypeOrControllerType(editorAPI, compPointer) {
    const compType = editorAPI.components.getType(compPointer);
    let result = compType;

    if (_.includes(platform.constants.CONTROLLER_COMPONENT_TYPES, compType)) {
      result = getControllerType(editorAPI, compPointer) || compType;
    }

    return result;
  }

  function getSdkTypeOrControllerType(editorAPI, compPointer) {
    const compType = editorAPI.components.getType(compPointer);
    const sdkType = editorAPI.wixCode.getCompSdkType(compPointer);
    let result = sdkType;

    if (_.includes(platform.constants.CONTROLLER_COMPONENT_TYPES, compType)) {
      result = getControllerType(editorAPI, compPointer) || sdkType;
    }

    return result;
  }

  function getTPAsImportDefinitions(platformAppConfiguration) {
    if (!platformAppConfiguration || !platformAppConfiguration.api) {
      return null;
    }
    const tpaConfigurationFile = platformAppConfiguration.api;
    const platformAppConfigurationObj = _.assign(
      {},
      _.assign.apply(_, [{}].concat(tpaConfigurationFile)),
    );
    return _.mapKeys(platformAppConfigurationObj, (obj, key) =>
      obj.widgetDisplayName ? obj.widgetDisplayName : key,
    );
  }

  const getBackendUserFolder = (editorAPI, path) =>
    path.substring(
      getBackendFolder(editorAPI).length - 1,
      path.lastIndexOf('/'),
    );

  const getPublicUserFolder = (editorAPI, path) =>
    path.substring(
      getPublicFolder(editorAPI).length - 1,
      path.lastIndexOf('/'),
    );

  return {
    getFileIdFromPageId,
    getPageIdFromFileId,
    getCompTypeOrControllerType,
    getSdkTypeOrControllerType,
    getTPAsImportDefinitions,
    getDefaultPageContent,
    isPageFile,
    isBackendFile,
    isReservedBackendFile,
    isPublicFile,
    isMasterPageId,
    getBackendUserFolder,
    getPublicUserFolder,
    getMasterPageFileId,
  };
});
