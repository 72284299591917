export interface LegacyEditorExperimentsObj {
  isOpen(spec: string): boolean;
  getValue(spec: string): string;
  isMultiValueExperimentOpen(spec: string): boolean;
  getRunningExperiments(): { [spec: string]: string };
}
/**
 * There are two ways we use for conducting experiments:
 * 1. LegacyExperiments - these are experiments that are conducted on the editor's server, and are part of the editorModel (downloaded with the inital HTML source and put on "window.editorModel").
 *    The editor's server conducts all specs in the "santa-editor" scope. Wix code's legacy experiments are therefore always under both scopes [santa-editor,wix-code]
 * 2. client conduction - we use clientExperimentService to make a nework call to petri to conduct all experiments in the "wix-code" scope.
 *
 * We prefer option [2], because it allows us to have control over how the conduction is done. For example, editor server conduction context was bad https://wix.slack.com/archives/C0W6260BW/p1629699593293300
 *
 * Please don't add new experiments to this enum.
 * Once all of these are merged, we can get rid of this file
 */
export enum LegacyExperiments {
  TBF_SUPPORT_HTTP_FUNCTIONS = 'specs.wixCode.tbfSupportHttpFunctions',
  TYPESCRIPT_CODE_EDITOR_IN_APP_BUILDER = 'specs.wixCode.typescriptCodeEditorInAppBuilder',
  CACHE_NOTIFICATIONS_FROM_SERVER = 'specs.wixCode.cacheNotificationFromServer',
}

export const isTbfSupportHttpFunctionsActive = (
  editorExperiments: LegacyEditorExperimentsObj,
) => editorExperiments.isOpen(LegacyExperiments.TBF_SUPPORT_HTTP_FUNCTIONS);

export const isCacheNotificationFromServer = (
  editorExperiments: LegacyEditorExperimentsObj,
) =>
  editorExperiments.isOpen(LegacyExperiments.CACHE_NOTIFICATIONS_FROM_SERVER);
