// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KaFsVh{display:flex;flex-direction:column;align-items:center;justify-content:space-between}.aKyWQQ{align-items:flex-start}.TRBGTc{white-space:pre}.e8Z7HV{height:30px}.M46fHG{margin-bottom:6px}.lkrEkC{margin-bottom:12px}.s16GNY{display:flex;flex-direction:column;align-items:center;justify-content:center}.eQlOMe{margin:0 4px 0 5px}.OtDo8y{display:flex;margin-top:36px;justify-content:space-between;width:100%}.neHHPd{margin-left:12px}.SdoN8N{border-radius:5px;width:225px}.Ghx61w{margin-top:30px;display:flex;justify-content:flex-end;padding:0px 30px}.qo9RK6{margin-top:5px;display:flex}.v9qd5u{line-height:5px;margin-left:4px}.yws3l7{display:flex;justify-content:center}.G4oZD4{margin-left:5px}.r8L5GT{max-width:500px}.phxEtn{display:flex;justify-content:center}.GIP6Rs{display:flex;align-items:center}.OKQI4D{padding:20px 30px}.aaWbMZ{padding:0px}.AZ0hAQ{padding:0 8px 4px}.hLIfj9{margin:0px 30px}.NM1Hp0{display:flex;justify-content:center;max-width:450px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wizard-container": "KaFsVh",
	"wizardContainer": "KaFsVh",
	"wizard-container-step2": "aKyWQQ",
	"wizardContainerStep2": "aKyWQQ",
	"subheader": "TRBGTc",
	"margin": "e8Z7HV",
	"smaller-margin": "M46fHG",
	"smallerMargin": "M46fHG",
	"small-margin": "lkrEkC",
	"smallMargin": "lkrEkC",
	"footer-container": "s16GNY",
	"footerContainer": "s16GNY",
	"terms-text": "eQlOMe",
	"termsText": "eQlOMe",
	"footer": "OtDo8y",
	"margin-left": "neHHPd",
	"marginLeft": "neHHPd",
	"input-container": "SdoN8N",
	"inputContainer": "SdoN8N",
	"action-buttons": "Ghx61w",
	"actionButtons": "Ghx61w",
	"step-3-files-description": "qo9RK6",
	"step3FilesDescription": "qo9RK6",
	"step-3-link": "v9qd5u",
	"step3Link": "v9qd5u",
	"step-3-footnote": "yws3l7",
	"step3Footnote": "yws3l7",
	"small-margin-left": "G4oZD4",
	"smallMarginLeft": "G4oZD4",
	"max-width": "r8L5GT",
	"maxWidth": "r8L5GT",
	"preloader": "phxEtn",
	"termsSection": "GIP6Rs",
	"dynamic-files-container": "OKQI4D",
	"dynamicFilesContainer": "OKQI4D",
	"step-3-container": "aaWbMZ",
	"step3Container": "aaWbMZ",
	"name-picker-container": "AZ0hAQ",
	"namePickerContainer": "AZ0hAQ",
	"error-banner-container": "hLIfj9",
	"errorBannerContainer": "hLIfj9",
	"video-container": "NM1Hp0",
	"videoContainer": "NM1Hp0"
};
export default ___CSS_LOADER_EXPORT___;
