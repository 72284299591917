import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { wixCodeReduxContext } from '@wix/wix-code-common-components';
import SearchBox from '../searchBox/searchBox';
import SearchResults from '../searchResults/searchResults';
import searchStateReader from '../searchStateReader';

export default _.once(({ util }) => {
  const SearchPanel = ({
    onSearch,
    results,
    error,
    onResultClick,
    onResultDoubleClick,
  }) => {
    const [selected, setSelected] = useState();

    const resultClicked = (result) => {
      setSelected(result);
      onResultClick(result);
    };

    const resultDoubleClicked = (result) => {
      setSelected(result);
      onResultDoubleClick(result);
    };

    return (
      <div data-aid="search-panel">
        <SearchBox
          error={error}
          placeholder={util.translate('Corvid_Search_All_Files_Placeholder')}
          autoFocus={true}
          onSearch={onSearch}
        />
        <SearchResults
          error={error}
          results={results}
          selectedResult={selected}
          onResultClick={resultClicked}
          onResultDoubleClick={resultDoubleClicked}
          translate={util.translate}
        />
      </div>
    );
  };

  SearchPanel.propTypes = {
    onSearch: PropTypes.func.isRequired,
    onResultClick: PropTypes.func,
    onResultDoubleClick: PropTypes.func,
    results: PropTypes.object,
  };

  SearchPanel.defaultProps = {
    onResultClick: () => {},
    onResultDoubleClick: () => {},
  };

  SearchPanel.contextTypes = {
    translate: PropTypes.func,
    captureException: PropTypes.func,
  };

  const mapStateToProps = (state) => {
    const query = searchStateReader.getSearchQuery(state);

    return {
      query,
    };
  };

  const Connected = connect(mapStateToProps, null, null, {
    context: wixCodeReduxContext,
  })(SearchPanel);

  return Connected;
});
