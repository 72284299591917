/* eslint-disable no-template-curly-in-string */

const FILE_STATES = {
  IDLE: 'idle',
  VIRTUAL: 'virtual',
  LOADING: 'loading',
  SAVING: 'saving',
  LOAD_FAILED: 'loadFailed',
  LOAD_TAKING_LONGER_THAN_USUAL: 'loadTakingLongerThanUsual',
};

const PAGE_TABS = {
  PAGE: 'page',
  SITE: 'site',
};

const DEVELOPER_MODE_FAIL_PANEL = {
  ERROR_CODE: 13200,
  HELP_LINK: '8045e8e4-6f47-4913-8d74-6239f21fffec',
};

const SITE_JS_PAGE_ID = 'masterPage';

const SITE_JS = 'masterPage.js';
const SITE_JS_CONTEXT = {
  type: 'PAGE',
  data: {
    displayName: SITE_JS,
    id: SITE_JS_PAGE_ID,
  },
};

export default {
  SITE_JS_CONTEXT,
  FILE_STATES,
  PAGES_ROOT: 'pages',
  JS_EXTENSION: '.js',
  SITE_JS_PAGE_ID,
  PAGE_TABS,
  DEVELOPER_MODE_FAIL_PANEL,
  ROUTER_FILE_NAME: 'routers.js',
  AUTHORIZATION_CONFIG_FILE_NAME: 'authorization-config.json',
  CONFIG_FOLDER_NAME: '___config___',
  VELO_PKG_SCOPE: '@velo',
  README_FILE_NAME: 'README.md',
  NPM_VIRTUAL_FOLDER_NAME: '@npm',
  WIX_CODE_PACKAGE_JSON_FILE_NAME: 'wix-code-package.json',
  INSTALLED_TAB: 'installed',
  AVAILABLE_TAB: 'available',
  PREVIEW: 'preview',
  PUBLISH: 'publish',
  KEYBOARD_SHORTCUT: 'keyboard_shortcut',
  EVENTS_FILE_NAME: 'events.js',
  DATA_HOOK_FILE_NAME: 'data.js',
  JOBS_SCHEDULER_FILE_NAME: 'jobs.config',
  HTTP_FUNCTIONS_FILE_NAME: 'http-functions.js',
  FILE_EDITOR_STATUS_SAVING_FILE: 'saving...',
  FILE_EDITOR_STATUS_LOADING_FILE: 'loading...',
  SITE_JS,
  GLOBAL_CSS: 'global.css',
} as const;
