import React from 'react';
import { SingleFile } from './SingleFile';

export const ConfigFileTree: React.FC<{
  translations?: { [key: string]: string };
}> = ({ translations }) => (
  <SingleFile
    id="ConfigFile"
    parentId="backend/"
    fileName="config.json"
    fileIsMandatory={false}
    initialContent="{}"
    translations={translations}
  />
);
