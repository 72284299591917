import { getEditorType } from '../utils/getEditorType';
import { clientExperiments } from './clientExperimentService';

export enum Experiments {
  BlocksCombinedMode = 'se_privateAppsPanel',
  SPICutsomIntegration = 'specs.wixCode.SPICutsomIntegration',
  BlockWixCodeOnDisabledSaveAndAutoSave = 'dm_blockWixCodeOnDisabledSaveAndAutoSave',
  ReportTranslationKeyUsage = 'specs.wixCode.reportTranslationKeyUsage',
  BetaAutocomplete = 'specs.wixCode.AutocompleteBeta',
  SpiPaymentIntegration = 'specs.wixCode.SPIPaymentsIntegration',
  NpmPkgManagerUIImprovements = 'specs.wixCode.NpmPkgManagerUIImprovements',
  enableCreationOfHttpFunctionsFileInBlocks = 'specs.wixCode.enableHttpFunctionsInBlocks',
  ReportPreviewCodeEligibility = 'specs.wixCode.reportPreviewCodeEligibility',
  ShippingSPI = 'specs.wixCode.ShippingRatesSPI',
  blocksBackendContextMenu = 'specs.wixCode.blocksBackendContextMenu',
  npmReplicatorUseNewIndex = 'specs.wixCode.npmReplicatorUseNewIndex',
  DevModeLoader = 'specs.wixCode.devModeLoader',
  TypescriptSupport = 'specs.wixCode.typescriptSupport',
  VeloIDE = 'specs.wixcode.VeloIDE',
  MoveHelpTabToBottomOfSidebar = 'specs.wixCode.moveHelpTabToBottomOfSidebar',
  RearrangeHelpTabResources = 'specs.wixCode.rearrangeHelpTabResources',
  ComponentNicknameDataManipulationAction = 'dm_componentNicknameDataManipulation',
  CssEditing = 'specs.wixcode.CSSEditingSupport',
  TestSiteEntryPoint = 'specs.wixCode.TestSiteEntryPoint',
  VSCodePartialSyncSite = 'specs.wixCode.VSCodePartialSyncSite',
  LiteEditor = 'specs.wixCode.liteEditor',
  BYOC = 'specs.responsive-editor.ReactExternalComponent',
  MoveFormsToNewLocation = 'specs.wixCode.moveFormsToNewNamespace',
  AnyNpmFirstPhase = 'specs.wixCode.anyNpmFirstPhase',
  NewEventsPanel = 'specs.wixCode.newEventsPanel',
  MigrateExtenralDbs = 'wixCode.migrateExternalDbsToVelo',
  AiAssistance = 'specs.wixCode.AiAssistance',
  AiAssistanceUseStream = 'specs.wixCode.AiAssistanceUseStream',
  AnyNpmNewExperience = 'specs.wixCode.anyNpmNewExperience',
  OneClickDb = 'specs.wixCode.oneClickDb',
  WebJsEditorSupport = 'specs.wixCode.webJsEditorSupport',
  NewPagesMenu = 'specs.wixCode.NewPagesMenu',
  VeloInStudio = 'specs.wixCode.veloInStudio',
  NewHttpFunctionsDevService = 'specs.wixcode.NewHttpFunctionsDevService',
  LiteToFallbackNotification = 'specs.wixCode.liteToFallbackNotification',
}

// All new experiments are turned on by default in sled tests. These are relatively old ones that can't easily be opened like that.
export enum OldExperiments {
  InstallCodeReuseViaPlatform = 'specs.wixCode.installCodeReuseViaPlatform',
  HighlightingComponents = 'specs.wixCode.highlightingComponents',
  CodeReuseShowTestVersion = 'specs.wixCode.codeReuseShowTestVersion',
  // Please don't add any new experiments to this enum.
}

export const isExperimentOpen = (experiment: Experiments) =>
  clientExperiments().isOpen(experiment);

export const isBetaAutocomplete = () =>
  clientExperiments().isOpen(Experiments.BetaAutocomplete);

export const isMoveHelpTabToBottomOfSidebar = () =>
  clientExperiments().isOpen(Experiments.MoveHelpTabToBottomOfSidebar);

export const isShowTestVersion = () =>
  clientExperiments().isOpen(OldExperiments.CodeReuseShowTestVersion);

export const isRearrangeHelpTabResources = () =>
  clientExperiments().isOpen(Experiments.RearrangeHelpTabResources);

export const isTypescriptSupport = () =>
  clientExperiments().isOpen(Experiments.TypescriptSupport);

export const isInstallCodeReuseViaPlatform = () =>
  clientExperiments().isOpen(OldExperiments.InstallCodeReuseViaPlatform);

export const isNpmPkgManagerImprovements = () =>
  clientExperiments().isOpen(Experiments.NpmPkgManagerUIImprovements);

export const isEnableCreationOfHttpFunctionsFileInBlocks = () =>
  clientExperiments().isOpen(
    Experiments.enableCreationOfHttpFunctionsFileInBlocks,
  );
export const isNpmReplicatorUseNewIndex = () =>
  clientExperiments().isOpen(Experiments.npmReplicatorUseNewIndex);

export const isBlocksBackendContextMenu = () =>
  clientExperiments().isOpen(Experiments.blocksBackendContextMenu);

export const isDevModeLoader = () =>
  clientExperiments().isOpen(Experiments.DevModeLoader);

export const isTestSiteEntryPoint = () =>
  clientExperiments().isOpen(Experiments.TestSiteEntryPoint);

export const isEnablePartialSyncSite = () =>
  clientExperiments().isOpen(Experiments.VSCodePartialSyncSite);

export const isMoveFormsToNewNamespace = () =>
  clientExperiments().isOpen(Experiments.MoveFormsToNewLocation);

export const isLiteEditor = () => {
  const experiments = clientExperiments();
  return experiments.isOpen(Experiments.LiteEditor) && isStudioEditor();
};

export const isStudioEditor = () =>
  getEditorType() === 'Studio' &&
  clientExperiments().isOpen(Experiments.VeloIDE);

export const isAnyNpmFirstPhase = () =>
  clientExperiments().isOpen(Experiments.AnyNpmFirstPhase);

export const isNewEventsPanel = () =>
  clientExperiments().isOpen(Experiments.NewEventsPanel);

export const isMigrateExternalDbs = () =>
  clientExperiments().isOpen(Experiments.MigrateExtenralDbs);

export const isAiAssistance = () =>
  clientExperiments().isOpen(Experiments.AiAssistance);

export const isAnyNpmNewExperience = () =>
  clientExperiments().isOpen(Experiments.AnyNpmNewExperience);

export const oneClickDb = () =>
  clientExperiments().isOpen(Experiments.OneClickDb);

export const isNewPagesMenu = () =>
  clientExperiments().isOpen(Experiments.NewPagesMenu);

export const isWebJsEditorSupport = () =>
  clientExperiments().isOpen(Experiments.WebJsEditorSupport);

export const isVeloInStudio = () =>
  clientExperiments().isOpen(Experiments.VeloInStudio) &&
  getEditorType() === 'Studio';

export const shouldUseNewHttpFunctionsDevService = () =>
  clientExperiments().isOpen(Experiments.NewHttpFunctionsDevService);
