import { SidebarAPI, WixCodeStoreAPI } from '@wix/wix-code-plugin-contracts';
import { addSideTabContribution } from './sideTabDefinitions';
import { sideBarActions } from './state/leftPaneReducer';

interface CreateSideBarApiParams {
  wixCodeStoreAPI: WixCodeStoreAPI;
}

export const createSidebarAPI = ({
  wixCodeStoreAPI,
}: CreateSideBarApiParams): SidebarAPI => {
  const { dispatch } = wixCodeStoreAPI;
  return {
    contributeTab(tab) {
      addSideTabContribution(tab);
      dispatch(sideBarActions.addSideBarTab(tab().id));
    },
  };
};
