import s from './contextMenuItem.scss';
import * as React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import cx from 'classnames';

export type IconColor = 'jsw' | 'js' | 'jsx';

export interface ContextMenuItemProps {
  icon: string | React.FunctionComponent<{ className: string }>;
  iconColor?: IconColor;
  label: string;
}

export class ContextMenuItem extends React.Component<ContextMenuItemProps> {
  render() {
    const { icon: Icon, iconColor, label } = this.props;

    return (
      <>
        {typeof Icon === 'string' ? (
          <Symbol name={Icon} className={s.currentColor} />
        ) : (
          <Icon
            className={cx('symbol', s.currentColor, {
              [s.js]: iconColor === 'js',
              [s.jsw]: iconColor === 'jsw',
              [s.jsx]: iconColor === 'jsx',
            })}
          />
        )}
        <span>{label}</span>
      </>
    );
  }
}
