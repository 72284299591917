import { HttpClient } from '@wix/http-client';
import {
  createSpiConnection,
  listSpiConnections,
} from '@wix/ambassador-velo-spi-v1-spi-connection/http';
import { SpiConnection } from '@wix/ambassador-velo-spi-v1-spi-connection/types';
import {
  ClassicEditorAPI,
  ExperimentsAPI,
  ServiceTopology,
} from '@wix/wix-code-plugin-contracts';
import { createSpiFile } from '@wix/ambassador-velo-ide-v1-gridapp-filestructure/http';

export { SpiConnection };

export type SpiFolderPath = string;

type SPICreationParams = {
  spiName: string;
  gridAppId: string;
  spiType: string;
  isStudioEditor: boolean;
};

export function createServerApi({
  classicEditorAPI,
  experimentsAPI,
  serviceTopology,
  getSignedInstance,
}: {
  classicEditorAPI: ClassicEditorAPI;
  experimentsAPI: ExperimentsAPI;
  serviceTopology: ServiceTopology;
  getSignedInstance: () => string;
}) {
  const httpClient = new HttpClient({
    getAppToken: getSignedInstance,
  });

  const createNewSpi = async ({
    spiName,
    gridAppId,
    spiType,
    isStudioEditor,
  }: SPICreationParams): Promise<SpiFolderPath> => {
    let path: string;
    if (
      isStudioEditor &&
      experimentsAPI.isOpen('specs.wixcode.UseNewCreateSpiConnectionsFlow')
    ) {
      const response = await httpClient.request(
        createSpiConnection({
          gridAppId,
          spiType,
          spiName,
          sessionId:
            classicEditorAPI.editorAPI.documentServices.wixCode.getWixCodeSessionId(),
        }),
      );

      if (!response.data.spiConnection?.path) {
        throw new Error(
          `Failed to create SPI. Type: ${spiType}, Name: ${spiName}`,
        );
      }
      path = response.data.spiConnection.path;
    } else if (
      isStudioEditor &&
      experimentsAPI.isOpen('specs.wixCode.UseIDEServerNgForSpi')
    ) {
      const response = await httpClient.request(
        createSpiFile({
          gridAppId,
          spiType,
          spiName,
          sessionId:
            classicEditorAPI.editorAPI.documentServices.wixCode.getWixCodeSessionId(),
        }),
      );
      if (!response.data.path) {
        throw new Error(
          `Failed to create SPI. Type: ${spiType}, Name: ${spiName}`,
        );
      }
      path = response.data.path;
    } else {
      // IDE server does not have ambassador packages and cannot currently work with wix's standard headers
      const response = await httpClient.post(
        serviceTopology.wixCodeIdeServerUrl + `/v1/file/spi/${gridAppId}`,
        JSON.stringify({
          spiType,
          spiName,
        }),
        {
          disableWixHeaders: true,
          headers: {
            'x-wix-si': getSignedInstance(),
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        },
      );
      path = response.data;
    }

    return (
      path
        .split('/')
        .filter((x: string) => !!x)
        .join('/') + '/'
    );
  };

  const getSpiStatuses = async (): Promise<Required<SpiConnection>[]> => {
    const response = await httpClient.request(listSpiConnections({}));
    return response.data.spiConnections! as Required<SpiConnection>[]; // TODO: remove once proto props are defined as required
  };

  return {
    createNewSpi,
    getSpiStatuses,
  };
}
