import _ from 'lodash';
import * as React from 'react';
import createReactClass from 'create-react-class';

const getDisplayName = (Component: AnyFixMe) => {
  if (typeof Component === 'string') {
    return Component;
  }

  if (!Component) {
    return undefined;
  }

  return Component.displayName || Component.name || 'Component';
};

const wrapDisplayName = (BaseComponent: AnyFixMe, hocName: AnyFixMe) =>
  `${hocName}(${getDisplayName(BaseComponent)})`;

function renderWhenMutatedByPropTypes(Component: AnyFixMe, propKeys: AnyFixMe) {
  // eslint-disable-next-line react/prefer-es6-class
  const RenderWhenMutatedByProps = createReactClass({
    displayName: wrapDisplayName(Component, 'RenderWhenMutatedByProps'),
    shouldComponentUpdate(nextProps: AnyFixMe) {
      const currentProps = this.props;

      return _.some(propKeys, (key) => {
        const currentValue = currentProps[key];
        const nextValue = nextProps[key];

        if (key === 'children') {
          if (_.isFunction(currentValue)) {
            return true;
          }
          return nextValue !== currentValue;
        }

        if (_.isFunction(currentValue) && _.isFunction(nextValue)) {
          return false;
        }

        return !_.isEqual(currentValue, nextValue);
      });
    },

    render() {
      return React.createElement(Component, this.props);
    },
  });

  return RenderWhenMutatedByProps;
}

export default renderWhenMutatedByPropTypes;
