import React, { useState } from 'react';
import { Text } from '@wix/wix-base-ui';
import useInterval from './useInterval';
import dataHooks from '../../../dataHooks';

const InteractiveText: React.FC<{ texts: string[]; timeDiff?: number }> = ({
  texts = [''],
  timeDiff = 3000,
}) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  useInterval(() => {
    if (currentTextIndex === texts.length - 1) {
      return;
    }
    setCurrentTextIndex((prev) => prev + 1);
  }, timeDiff);

  return (
    <div>
      <Text
        dataHook={dataHooks.connectToGithub.interactiveLoader}
        skin="placeholder"
      >
        {texts[currentTextIndex]}
      </Text>
    </div>
  );
};

export default InteractiveText;
