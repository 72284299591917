import '@/infra/styles/common.global.scss';
import '@/infra/styles/vendors.global.scss';
import '@/infra/styles/wixCode.global.scss';
import { TopBar, TopBarProps } from '@/codeEditor/components/topBar/TopBar';
import {
  CodeEditorContainer,
  CodeEditorContainerProps,
} from '@/codeEditor/components/codeEditorContainer/CodeEditorContainer';
import ide from '@/legacy/core/components/ide/ide';
import deprecatedAppBuilderTreeCreator from '@/legacy/core/components/deprecatedAppBuilderTree/deprecatedAppBuilderTree';
import developerToolBarCreator from '@/legacy/core/components/developerToolBar/developerToolBar';
import developerToolBarContentCreator from '@/legacy/core/components/developerToolBar/content/developerToolBarContent';
import deleteFileSystemItemWarningPanelCreator from '@/legacy/core/components/deleteFileSystemItemWarningPanel/DeleteFileSystemItemWarningPanel';
import fileSystemOperationErrorPanelCreator from '@/legacy/core/components/fileSystemOperationErrorPanel/fileSystemOperationErrorPanel';
import WixCodeLoaderCreator from '@/legacy/core/components/wixCodeLoader/wixCodeLoader';
import storeManager from '@/infra/redux-state/store/storeManager';
import codeStateReader from '@/infra/redux-state/reducers/codeStateReader';
import {
  PackagesModal,
  PackagesModalProps,
} from '@/toExtract/packages/packagesModal/PackagesModal';
import React from 'react';
import { WixCodeContextProvider } from '@/infra/wixCodeContext/WixCodeContextProvider';
import {
  MessagePanel,
  MessagePanelProps,
} from '@/commonComponents/panels/messagePanel/MessagePanel';
import {
  DynamicPanel,
  DynamicPanelProps,
} from '@/commonComponents/panels/DynamicPanel';
import { DYNAMIC_PANEL_NAME } from '@/toExtract/panels/PanelsAPI';
import { LegacyEditorDependencies } from '@wix/wix-code-plugin-contracts';
import { AppHost } from '@wix/wix-code-repluggable';
import { WixCodeLoaderContextProvider } from '@/infra/wixCodeContext/WixCodeLoaderContext';
import DevModeLoader, {
  DevModeLoaderProps,
} from '@/commonComponents/panels/DevModeLoader';

export default function initLegacy(
  legacyEditorDeps: LegacyEditorDependencies,
  repluggableHost: AppHost,
) {
  return {
    components: {
      IDE: ide,
      TopBar: (props: TopBarProps) => (
        <WixCodeContextProvider legacyDependencies={legacyEditorDeps}>
          <TopBar {...props} />
        </WixCodeContextProvider>
      ),
      CodeEditor: (props: CodeEditorContainerProps) => (
        <WixCodeContextProvider legacyDependencies={legacyEditorDeps}>
          <CodeEditorContainer {...props} />
        </WixCodeContextProvider>
      ),
      DeprecatedAppBuilderTree: (props: AnyFixMe) => {
        const DeprecatedAppBuilderTree =
          deprecatedAppBuilderTreeCreator(legacyEditorDeps);
        return (
          <WixCodeContextProvider legacyDependencies={legacyEditorDeps}>
            <DeprecatedAppBuilderTree {...props}></DeprecatedAppBuilderTree>
          </WixCodeContextProvider>
        );
      },

      developerToolBar: (props: AnyFixMe) => {
        const DeveloperToolBar = developerToolBarCreator(legacyEditorDeps);
        return (
          <WixCodeContextProvider legacyDependencies={legacyEditorDeps}>
            <DeveloperToolBar {...props}></DeveloperToolBar>
          </WixCodeContextProvider>
        );
      },

      developerToolBarContent: (props: AnyFixMe) => {
        const DeveloperToolBarContent =
          developerToolBarContentCreator(legacyEditorDeps);
        return (
          <WixCodeContextProvider legacyDependencies={legacyEditorDeps}>
            <DeveloperToolBarContent {...props}></DeveloperToolBarContent>
          </WixCodeContextProvider>
        );
      },
      wixCodeLoader: (props: AnyFixMe) => {
        const WixCodeLoader = WixCodeLoaderCreator(legacyEditorDeps);
        return (
          <WixCodeLoaderContextProvider legacyDependencies={legacyEditorDeps}>
            <WixCodeLoader {...props} repluggableHost={repluggableHost} />
          </WixCodeLoaderContextProvider>
        );
      },
    },
    panels: {
      fileSystemOperationErrorPanel: (props: AnyFixMe) => {
        const FileErrorPanel =
          fileSystemOperationErrorPanelCreator(legacyEditorDeps);
        return (
          <WixCodeContextProvider legacyDependencies={legacyEditorDeps}>
            <FileErrorPanel {...props}></FileErrorPanel>
          </WixCodeContextProvider>
        );
      },

      deleteFileSystemItemWarningPanel: (props: AnyFixMe) => {
        const DeleteWarningPanel =
          deleteFileSystemItemWarningPanelCreator(legacyEditorDeps);
        return (
          <WixCodeContextProvider legacyDependencies={legacyEditorDeps}>
            <DeleteWarningPanel {...props}></DeleteWarningPanel>
          </WixCodeContextProvider>
        );
      },
      PackagesModal: (props: PackagesModalProps) => (
        <WixCodeContextProvider legacyDependencies={legacyEditorDeps}>
          <PackagesModal {...props} />
        </WixCodeContextProvider>
      ),
      MessagePanel: (props: MessagePanelProps) => (
        <WixCodeContextProvider legacyDependencies={legacyEditorDeps}>
          <MessagePanel {...props} />
        </WixCodeContextProvider>
      ),
      devModeLoaderPanel: (props: DevModeLoaderProps) => (
        <WixCodeLoaderContextProvider legacyDependencies={legacyEditorDeps}>
          <DevModeLoader {...props} />
        </WixCodeLoaderContextProvider>
      ),
      [DYNAMIC_PANEL_NAME]: (props: DynamicPanelProps) => {
        return (
          <WixCodeContextProvider legacyDependencies={legacyEditorDeps}>
            <DynamicPanel {...props}>{props.element}</DynamicPanel>
          </WixCodeContextProvider>
        );
      },
    },
    storeManager,
    codeStateReader,
  };
}
