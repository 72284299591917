import React from 'react';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import baseUILib from '@wix/wix-base-ui';
import { withErrorHandling } from '@/infra/monitoring';
import bi from '../../../bi/bi';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import ContextMenuItem from '../contextMenuItem/contextMenuItem';
import {
  isAppBuilderSchema,
  isExternalSchema,
  isUserSchema,
  isCollectionSuitableForDP,
} from './schemaHelpers';
import { QUICK_ACTIONS } from './constants';

import once_ from 'lodash/once';

const nodeDataShape = {
  isFolder: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  namespace: PropTypes.string,
  isRoot: PropTypes.bool,
  isSelectedSchema: PropTypes.bool,
  isExpandedFolder: PropTypes.bool,
  isExternalFolder: PropTypes.bool,
};
nodeDataShape.childItems = PropTypes.arrayOf(PropTypes.shape(nodeDataShape));

export default once_(({ baseUI, core, util }) => {
  const CollectionsTreeNode = createReactClass({
    displayName: 'CollectionsTreeNode',

    propTypes: {
      loading: PropTypes.bool.isRequired,
      nodeData: PropTypes.shape(nodeDataShape).isRequired,
      onCollectionSelected: PropTypes.func.isRequired,
      onCreateCollection: PropTypes.func.isRequired,
      onFolderToggle: PropTypes.func.isRequired,
      onContextMenuActionSelected: PropTypes.func.isRequired,
      schemaActions: PropTypes.object.isRequired,
    },

    mixins: [core.mixins.editorAPIMixin],

    getInitialState() {
      return {
        hasMouseInside: false,
      };
    },

    componentDidMount() {
      this.onNodeClick = withErrorHandling(this.onNodeClick);
      this.onContextMenuClick = withErrorHandling(this.onContextMenuClick);
    },

    onMouseOver() {
      this.ifShouldHaveTooltipThenCancelPendingTooltipShowingAnd(
        ({ showTooltip }) => {
          showTooltip();
        },
      );
    },

    onMouseEnter() {
      this.setHoverState(true);
    },

    onMouseMove() {
      this.setHoverState(true);
    },

    onMouseLeave() {
      this.setHoverState(false);
      this.ifShouldHaveTooltipThenCancelPendingTooltipShowingAnd(
        ({ hideTooltip }) => {
          hideTooltip();
        },
      );
    },

    ifShouldHaveTooltipThenCancelPendingTooltipShowingAnd(handleTooltip) {
      if (this.shouldHaveTooltip()) {
        const { tooltip } = this.refs;
        window.clearTimeout(tooltip.pendingShowDelay);
        handleTooltip(tooltip);
      }
    },

    getTooltipTranslation() {
      if (this.shouldHaveTooltip()) {
        const collectionId = this.props.nodeData.schema.id;
        const tooltipTranslation = util.translate(
          'WixCode_TreeView_Database_Full_Name_Tooltip',
          {
            collectionId,
          },
        );
        return tooltipTranslation;
      }
    },

    shouldHaveTooltip() {
      const {
        nodeData: { schema, isFolder },
      } = this.props;

      if (isFolder) {
        return false;
      }

      return isAppBuilderSchema(schema) || util.appStudioUtils.isAppStudio();
    },

    setHoverState(hasMouseInside) {
      this.setState({ hasMouseInside });
    },

    onContextMenuClick(event) {
      const {
        nodeData: { isRoot, name, isFolder },
      } = this.props;
      if (isRoot) {
        this.getEditorAPI().bi.event(bi.events.LEFT_TREE_CLICK_ON_PLUS, {
          section_name: 'database',
        });
      } else {
        this.getEditorAPI().bi.event(bi.events.LEFT_TREE_CLICK_ON_SETTINGS, {
          item_name: name,
          item_type: isFolder ? 'external_collection' : 'collection',
        });
      }

      event.stopPropagation();
    },
    shouldShowContextMenu() {
      const {
        nodeData: { isRoot, isExternalFolder, isFolder, isSelectedSchema },
        loading,
      } = this.props;

      if (loading) {
        return false;
      }

      const contextMenuIsAlreadyOpen =
        this.refs.contextMenu && this.refs.contextMenu.isExpanded();
      const nodeIsHoveredOrSelected =
        this.state.hasMouseInside || isSelectedSchema;
      const shouldNotShowInAnyWay =
        !contextMenuIsAlreadyOpen && !nodeIsHoveredOrSelected;

      if (shouldNotShowInAnyWay) {
        return false;
      }

      return isExternalFolder || isRoot || !isFolder;
    },
    shouldShowAddExternalCollectionsItem() {
      return this.props.nodeData.isRoot && !util.appStudioUtils.isAppStudio();
    },
    shouldShowAddDynamicPageItem() {
      const {
        nodeData: { isFolder, schema },
      } = this.props;
      return (
        !isFolder &&
        isCollectionSuitableForDP(schema) &&
        !util.appStudioUtils.isAppStudio()
      );
    },
    shouldShowEditCollectionItem() {
      const {
        nodeData: { schema },
      } = this.props;
      return isUserSchema(schema) || util.appStudioUtils.isAppStudio();
    },
    onQuickAddItemClick() {
      this.getEditorAPI().bi.event(
        bi.events.LEFT_TREE_CLICK_ON_ADD_QUICK_LINK,
        {
          section_name: 'database',
        },
      );

      this.props.onCreateCollection();
    },
    onNodeClick() {
      const {
        nodeData: { isFolder, isRoot, schema, isExpandedFolder },
        loading,
        onCollectionSelected,
      } = this.props;

      if (loading) {
        return;
      }

      if (!isFolder) {
        onCollectionSelected(schema.id);
        return;
      }

      if (isRoot) {
        this.getEditorAPI().bi.event(bi.events.LEFT_TREE_CLICK_ON_SECTION, {
          section_name: 'database',
          action: isExpandedFolder ? 'close' : 'open',
        });
      }

      this.props.onFolderToggle(this.props.nodeData);
    },
    getIconName() {
      const {
        nodeData: { isFolder, schema, isExpandedFolder },
      } = this.props;

      if (isFolder) {
        return isExpandedFolder ? 'root_folder_open' : 'root_folder_closed';
      }

      if (isExternalSchema(schema)) {
        return 'external_collection';
      }

      return isUserSchema(schema) ? 'collection' : 'db_driver_collection';
    },
    render() {
      const {
        nodeData: {
          isRoot,
          isFolder,
          name,
          isExternalFolder,
          childItems,
          isSelectedSchema,
          isExpandedFolder,
          schema,
        },
        loading,
        onContextMenuActionSelected,
        schemaActions,
      } = this.props;
      return (
        <div
          data-aid={isRoot ? 'collections-tree' : 'tree-node'}
          data-tree-node-name={name}
          className={classNames({
            'wix-code-file-tree': true,
            root: isFolder,
          })}
        >
          <baseUI.tooltip
            ref="tooltip"
            disabled={!this.shouldHaveTooltip()}
            openTriggers={[]}
            closeTriggers={[]}
            shouldTranslate={false}
            value={this.getTooltipTranslation()}
          >
            <div
              className={classNames({
                'node-content': true,
                pending: loading,
                selectable: !isFolder,
                selected: isSelectedSchema,
              })}
              data-aid="content"
              onMouseOver={this.onMouseOver}
              onMouseEnter={this.onMouseEnter}
              onMouseMove={this.onMouseMove}
              onMouseLeave={this.onMouseLeave}
              onClick={this.onNodeClick}
            >
              {loading ? (
                <span className="icon" key="preloader">
                  <baseUI.preloader />
                </span>
              ) : (
                <span className="icon" key="icon" data-aid="icon-wrapper">
                  <Symbol className="icon-symbol" name={this.getIconName()} />
                </span>
              )}

              <span data-aid="label" className="label" key="label">
                {name}
              </span>

              {isFolder && !isRoot && !isExternalFolder && (
                <baseUI.infoIcon
                  automationId="info-icon"
                  hidden={!this.state.hasMouseInside}
                  interactiveTooltip={false}
                  key="infoIcon"
                  shouldTranslate={false}
                  text={util.translate('WixCode_TreeView_WixApp_Tooltip_Text', {
                    WixApp_name: name,
                  })}
                />
              )}

              {this.shouldShowContextMenu() && (
                <baseUI.dropDown.contextMenu
                  data-aid="context-menu-icon"
                  className="wix-code-file-tree-dd"
                  key="contextMenu"
                  ref="contextMenu"
                  icon={isRoot ? 'root_folder_menu' : 'file_menu'}
                  onMouseDown={this.onContextMenuMouseDown}
                  onClick={this.onContextMenuClick}
                  onChange={(action) =>
                    onContextMenuActionSelected({
                      action,
                      nodeData: this.props.nodeData,
                    })
                  }
                >
                  {isRoot && (
                    <baseUI.dropDown.option
                      value={QUICK_ACTIONS.NEW_COLLECTION}
                      key="action-new"
                    >
                      <ContextMenuItem
                        symbolName="collection"
                        label={util.translate(
                          'WixCode_TreeView_Data_NewCollection',
                        )}
                      />
                    </baseUI.dropDown.option>
                  )}

                  {this.shouldShowAddExternalCollectionsItem() && (
                    <baseUI.dropDown.option
                      value={QUICK_ACTIONS.ADD_EXTERNAL_COLLECTIONS}
                      key="action-add-external"
                    >
                      <ContextMenuItem
                        symbolName="external_collection"
                        label={util.translate(
                          'WixCode_TreeView_Data_Add_External_Collections',
                        )}
                      />
                    </baseUI.dropDown.option>
                  )}

                  {isExternalFolder && (
                    <baseUI.dropDown.option
                      value={QUICK_ACTIONS.EDIT_EXTERNAL_COLLECTIONS}
                      key="action-edit-external"
                    >
                      <ContextMenuItem
                        symbolName="edit_icon"
                        label={util.translate(
                          'WixCode_TreeView_FileTree_QuickActions_Edit',
                        )}
                      />
                    </baseUI.dropDown.option>
                  )}

                  {isExternalFolder && (
                    <baseUI.dropDown.option
                      value={QUICK_ACTIONS.REMOVE_EXTERNAL_COLLECTIONS}
                      key="action-remove-external"
                    >
                      <ContextMenuItem
                        symbolName="delete_icon"
                        label={util.translate(
                          'WixCode_TreeView_FileTree_QuickActions_Delete',
                        )}
                      />
                    </baseUI.dropDown.option>
                  )}

                  {!isFolder &&
                    schemaActions[schema.id].map(
                      (contextMenuAction, contextMenuActionIndex) => (
                        <baseUI.dropDown.option
                          key={'context-menu-action' + contextMenuActionIndex}
                          value={contextMenuAction.event}
                        >
                          <ContextMenuItem
                            symbolName={contextMenuAction.icon}
                            label={contextMenuAction.title}
                          />
                        </baseUI.dropDown.option>
                      ),
                    )}
                </baseUI.dropDown.contextMenu>
              )}
            </div>
          </baseUI.tooltip>

          {isExpandedFolder && (
            <ul data-aid="sub-items" className="subtree" key="subtree">
              {isRoot && !childItems.length && (
                <li className="empty-dir-message" key="empty-dir-message">
                  <baseUILib.Button
                    className="btn-text link"
                    onClick={this.onQuickAddItemClick}
                  >
                    {util.translate(
                      'WixCode_TreeView_AddCollectionToDirectory',
                    )}
                  </baseUILib.Button>
                </li>
              )}

              {childItems.map((child) => (
                <li
                  key={
                    child.isFolder
                      ? `folder-${child.namespace}`
                      : `schema-${child.schema.id}`
                  }
                >
                  <CollectionsTreeNode {...this.props} nodeData={child} />
                </li>
              ))}
            </ul>
          )}
        </div>
      );
    },
  });

  return CollectionsTreeNode;
});
