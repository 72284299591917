import { Interceptor } from '@wix/wix-code-plugin-contracts';
import React, { useContext } from 'react';
import createCollectionsTree from '../CollectionsTree/CollectionsTree';
import { TabContext } from '../tabContext';
import { experimentUtils } from '@wix/wix-code-common';
import { AddExternalDb } from '../externalDbs/AddExternalDb/AddExternalDb';
import { ExternalDbsModalContextProvider } from '../externalDbs/ExternalDbsModalContext';
import { EditExternalDb } from '../externalDbs/EditExternalDb/EditExternalDb';
import { RemoveExternalDb } from '../externalDbs/RemoveExternalDb';
import { AddExternalDbMainScreen } from '../externalDbs/AddExternalDbMainScreen/AddExternalDbMainScreen';

export interface CollectionsTreeStateProps {
  createCollectionInterceptors: Interceptor[];
}

export const CollectionsTreeContainerComp: React.FC<
  CollectionsTreeStateProps
> = ({ createCollectionInterceptors }) => {
  const {
    editorAPI,
    legacyEditorDependencies,
    platformAppsAPI,
    readOnlyAPI,
    biLoggerAPI,
    panelsAPI,
    wixCodeAppAPI,
  } = useContext(TabContext);
  const { platform, util, stateManagement, constants } =
    legacyEditorDependencies;
  const devModeContext = editorAPI.developerMode.getContext();

  const sendClickInSiteStructurePlatformEvent = (
    eventType: string,
    payload = {},
  ) => {
    platformAppsAPI.notifyDataBinding({
      eventType,
      eventPayload: {
        origin: 'ideTree',
        clickedInSiteStructure: true,
        ...payload,
      },
    });
  };

  const onCreateCollection = () => {
    Promise.all(
      createCollectionInterceptors.map((interceptor: Interceptor) =>
        interceptor(),
      ),
    )
      .then(() => {
        sendClickInSiteStructurePlatformEvent(
          platform.constants.EVENTS.createCollectionButtonClicked,
        );
      })
      .catch(() => {});
  };

  const onAddExternalCollections = () => {
    const AddExternalDbModal = () => (
      <ExternalDbsModalContextProvider
        panelsAPI={panelsAPI}
        platformAppsAPI={platformAppsAPI}
        biLoggerAPI={biLoggerAPI}
        wixCodeAppAPI={wixCodeAppAPI}
        languageCode={legacyEditorDependencies.util.editorModel.languageCode}
        editorAPI={editorAPI}
      >
        {experimentUtils.oneClickDb() ? (
          <AddExternalDbMainScreen />
        ) : (
          <AddExternalDb />
        )}
      </ExternalDbsModalContextProvider>
    );
    experimentUtils.isMigrateExternalDbs()
      ? panelsAPI.openPanel(<AddExternalDbModal />, true)
      : sendClickInSiteStructurePlatformEvent(
          platform.constants.EVENTS.addExternalDbDriverClicked,
        );
  };

  const onEditExternalCollections = (namespace: string) => {
    const EditExternalDbModal = () => (
      <ExternalDbsModalContextProvider
        panelsAPI={panelsAPI}
        platformAppsAPI={platformAppsAPI}
        biLoggerAPI={biLoggerAPI}
        wixCodeAppAPI={wixCodeAppAPI}
        languageCode={legacyEditorDependencies.util.editorModel.languageCode}
        editorAPI={editorAPI}
      >
        <EditExternalDb connectorName={namespace} />
      </ExternalDbsModalContextProvider>
    );

    experimentUtils.isMigrateExternalDbs()
      ? panelsAPI.openPanel(<EditExternalDbModal />, true)
      : sendClickInSiteStructurePlatformEvent(
          platform.constants.EVENTS.editExternalDbDriverClicked,
          { namespace },
        );
  };

  const onRemoveExternalCollections = (namespace: string) => {
    const DisconnectExternalDbModal = () => (
      <ExternalDbsModalContextProvider
        panelsAPI={panelsAPI}
        platformAppsAPI={platformAppsAPI}
        biLoggerAPI={biLoggerAPI}
        wixCodeAppAPI={wixCodeAppAPI}
        languageCode={legacyEditorDependencies.util.editorModel.languageCode}
        editorAPI={editorAPI}
      >
        <RemoveExternalDb connectorName={namespace} />
      </ExternalDbsModalContextProvider>
    );
    experimentUtils.isMigrateExternalDbs()
      ? panelsAPI.openPanel(<DisconnectExternalDbModal />, true)
      : sendClickInSiteStructurePlatformEvent(
          platform.constants.EVENTS.removeExternalDbDriverClicked,
          { namespace },
        );
  };

  const CollectionsTree = createCollectionsTree({
    util,
    stateManagement,
    constants,
    editorAPI,
    readOnlyAPI,
  });

  return (
    <>
      <CollectionsTree
        labelOverride="Left_Tree_Components_Category_Databases_SiteCollections"
        onCreateCollection={onCreateCollection}
        onAddExternalCollections={onAddExternalCollections}
        onEditExternalCollections={onEditExternalCollections}
        onRemoveExternalCollections={onRemoveExternalCollections}
        devModeContext={devModeContext}
      />
    </>
  );
};
