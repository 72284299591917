export const getDynamicRoutersData = (
  editorAPI: EditorAPI,
  dataSources: any,
) => {
  const dynamicRouters = dataSources.routers.filter(
    (router: any) => router.routerType === 'Data',
  );
  if (dynamicRouters.length > 0) {
    return dynamicRouters.map((dynamicRouter: any) => ({
      id: dynamicRouter.id,
      title: dynamicRouter.title,
      prefix: dynamicRouter.prefix,
      items: dynamicRouter.pagesData,
    }));
  } else {
    return editorAPI.pages.dynamicPages
      .getMenuItems()
      .filter((dynamicPage: any) => dynamicPage.routerType === 'Data')
      .map((dynamicPage: any) => ({
        ...dynamicPage,
        title: dynamicPage.label,
      }));
  }
};

import { EditorAPI } from '@wix/wix-code-plugin-contracts';

export const getSiteMenuData = (pagesStructure: any) => {
  const appPagesFlat = pagesStructure.appPages.flatMap(
    (appPage: any) => appPage.pagesData,
  );
  const sitePagesMenu = pagesStructure.menus.find(
    (m: any) => m.id === 'CUSTOM_MAIN_MENU',
  );
  const sitePagesMenuItems = sitePagesMenu.menuItems
    .filter((menuItem: any) => menuItem.type.isPage)
    .map((menuItem: any) => ({
      id: menuItem.pageData.id,
      title: menuItem.label,
    }))
    .filter(
      (menuItem: any) =>
        !appPagesFlat.some((appPage: any) => appPage.id === menuItem.id),
    );

  return sitePagesMenuItems;
};

export const getRoutersData = (dataSources: any) => {
  return dataSources.routers.filter(
    (router: any) => router.routerType !== 'Data',
  );
};

export const getAppPagesData = (pagesStructure: any) => {
  return pagesStructure.appPages.filter(
    (appPage: any) => appPage.id !== 'dataBinding',
  );
};

export const getDataSources = (editorAPI: EditorAPI) => {
  const data = (editorAPI.pages as any).getFullPagesStructure();
  return data;
};
