import React, { useContext } from 'react';
import { PackagesModalContext } from '../../../../../packagesModalContext';
import dataHooks from '../../../../../dataHooks';
import { Text } from '@wix/wix-base-ui';
import s from './PkgActionButton.scss';

export const InstalledLabel: React.FC = () => {
  const { t } = useContext(PackagesModalContext);

  return (
    <div className={s.installedLabel}>
      <Text
        type="T02"
        automationId="installed-label"
        className="strong"
        dataHook={dataHooks.INSTALLED_LABEL}
        shouldTranslate={false}
        size="small"
      >
        {t('Package_Manager_Installed_Button')}
      </Text>
    </div>
  );
};
