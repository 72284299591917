import consoleConstants from '../components/console/constants';
import wixCodeBI from '../../bi/bi';
import type {
  LegacyEditorDependencies,
  EditorAPI,
} from '@wix/wix-code-plugin-contracts';
import { Message } from '@/infra/redux-state/reducers/consoleReducer';
import {
  removeMessageForever,
  addMessageToConsole,
  isNotificationDismissed,
  genBiData,
} from './commonNotificationsFunctions';

interface TestSiteNotificationCreatorProps {
  editorAPI: EditorAPI;
  stateManagement: LegacyEditorDependencies['stateManagement'];
  util: LegacyEditorDependencies['util'];
}

function testSiteNotificationCreator({
  editorAPI,
  stateManagement,
  util,
}: TestSiteNotificationCreatorProps) {
  const testSiteLogDismiss = 'TEST_SITE_LOG_DISMISS';

  function addMessageToPreviewConsole() {
    const page = editorAPI.pages.getFocusedPage();
    if (
      !isNotificationDismissed({
        stateManagement,
        editorAPI,
        userPreferencesKey: testSiteLogDismiss,
      })
    ) {
      const args = [
        util.translate('WixCode_DevConsole_PreviewLimitationsMessage_Text'),
        {
          linkType: 'href',
          url: util.translate(
            'WixCode_DevConsole_PreviewLimitationsMessage_URL',
          ),
          callback: () =>
            editorAPI.bi.event(
              wixCodeBI.events.CLICKED_ON_LEARN_MORE_REGARDING_TEST_SITE,
              genBiData(editorAPI, page.id),
            ),
          text: util.translate(
            'WixCode_DevConsole_PreviewLimitationsMessage_LearnMore_Text',
          ),
        },
      ];
      const removeAction = {
        text: util.translate(
          'WixCode_DevConsole_PreviewLimitationsMessage_Dismiss',
        ),
        callback: (message: Message) => {
          removeMessageForever({
            editorAPI,
            stateManagement,
            userPreferencesKey: testSiteLogDismiss,
            messageId: message.id,
          });
          editorAPI.bi.event(
            wixCodeBI.events.CLICKED_ON_DISMISS_TEST_SITE_LOG,
            genBiData(editorAPI, page.id),
          );
        },
      };
      addMessageToConsole({
        editorAPI,
        logLevel: consoleConstants.LOG_LEVEL.TIP,
        args,
        action: removeAction,
      });
      editorAPI.bi.event(
        wixCodeBI.events.TEST_SITE_MSG_POP_ON_IDE,
        genBiData(editorAPI, page.id),
      );
    }
  }

  return {
    addMessageToPreviewConsole,
  };
}
export default testSiteNotificationCreator;
