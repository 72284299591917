import React from 'react';
import {
  EditorAPI,
  LegacyEditorDependencies,
  LitePanelAPI,
} from '@wix/wix-code-plugin-contracts';
import {
  ScopedStore,
  Shell,
  connectWithShell,
  APIKeys,
} from '@wix/wix-code-repluggable';
import {
  State,
  getRegisteredMenuClickHandlers,
  getSelectedTab,
} from '../../state';
import { LitePanel, LitePanelProps } from './litePanel';
import {
  BiLoggerContext,
  TranslationProvider,
} from '@wix/wix-code-common-components';
import { ContributedMenuPanelTabsSlot } from '../../slots/menuPanelTab';
import { LitePanelPrivateAPI } from './litePanelPrivateAPI';

interface LitePanelCreatorProps {
  legacyDependencies: LegacyEditorDependencies;
  store: ScopedStore<State>;
  shell: Shell;
  litePanelAPI: LitePanelAPI;
  editorAPI: EditorAPI;
  litePanelPrivateAPI: LitePanelPrivateAPI;
}

export const createLitePanel = async ({
  legacyDependencies,
  shell,
  store,
  litePanelAPI,
  editorAPI,
  litePanelPrivateAPI,
}: LitePanelCreatorProps) => {
  const { util } = legacyDependencies;

  const ConnectedWithShell = connectWithShell<State, LitePanelProps>(
    () => {
      const state = store.getState();

      return {
        menuClickHandlers: getRegisteredMenuClickHandlers(state),
        currentMenuItem: state.menu.selected,
        menuItems: litePanelPrivateAPI.getMenuItems(),
        readOnly: litePanelAPI.getReadOnlyState(),
        TabContribution: litePanelPrivateAPI.getTabContribution(
          shell.getSlot(ContributedMenuPanelTabsSlot),
          getSelectedTab(state),
        ),
        setMenuItem: litePanelPrivateAPI.setMenuItem,
      };
    },
    undefined,
    shell,
    {
      renderOutsideProvider: true,
      allowOutOfEntryPoint: true,
    },
  )((props) => {
    const toggleDevMode = () => editorAPI.developerMode.toggle();

    return <LitePanel {...props} toggleDevMode={toggleDevMode} />;
  });

  const locale = util.editorModel.languageCode;
  const messages = await import(`../../assets/locale/messages_${locale}.json`);
  const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);

  return ({ devModeStatus, closePanel, ...rest }: any) => (
    <TranslationProvider
      options={{
        locale: util.editorModel.languageCode,
        messages,
      }}
    >
      <BiLoggerContext.Provider value={biLoggerAPI}>
        <ConnectedWithShell
          readOnly={devModeStatus !== 'WRITE'}
          closePanel={closePanel}
          {...rest}
        />
      </BiLoggerContext.Provider>
    </TranslationProvider>
  );
};
