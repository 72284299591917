import React, { useState } from 'react';
import { TextLabel, TextInput } from '@wix/wix-base-ui';
import * as biEvents from '../bi/biEvents';

type ElementNicknameSectionProps = {
  automationId: string;
  label: string;
  compNickname: string;
  disabled: boolean;
  setNickname: (nickname: string) => void;
  validateNickname: any;
  siteId: string;
  compId: string;
  compType: string;
  sendBi: (event: Record<string, any>) => void;
};

const ElementNicknameSection = ({
  automationId,
  label,
  compNickname,
  disabled,
  setNickname,
  validateNickname,
  siteId,
  compId,
  compType,
  sendBi,
}: ElementNicknameSectionProps) => {
  const [nicknameInputValue, setNicknameInputValue] = useState(compNickname);
  const { valid: isValid, message: invalidMessage = '' } =
    validateNickname(nicknameInputValue);
  return (
    <div
      data-aid={automationId}
      data-is-read-only={(!!disabled).toString()}
      className="nickname-input-container"
    >
      <TextLabel
        automationId="nickname-label"
        className="label"
        type="T04"
        value={label}
      />
      <TextInput
        disabled={disabled}
        className="nickname-input"
        automationId="nickname-input"
        onFocus={() => {
          sendBi(biEvents.nicknameFocused({ siteId, compId, compType }));
        }}
        invalidMessage={invalidMessage}
        isValid={isValid}
        onChange={(candidate) => {
          setNicknameInputValue(candidate);
        }}
        onBlur={() => {
          if (
            nicknameInputValue !== compNickname &&
            validateNickname(nicknameInputValue).valid
          ) {
            sendBi(biEvents.nicknameChanged({ siteId, compId, compType }));
            setNickname(nicknameInputValue);
          } else {
            setNicknameInputValue(compNickname);
          }
        }}
        value={nicknameInputValue}
      />

      {/* <Tooltip
                      content={util.translate(
                        "WixCode_PropertiesAndEventsPanel_API_Label",
                        {
                          apiName: this.props.sdkType.replace(/^\$w\./, "")
                        }
                      )}
                    >
                      <a
                        href={`https://www.wix.com/corvid/reference/${this.props.sdkType}.html`}
                        target="_blank"
                      >
                        <TextLabel
                          automationId="api-link"
                          className="api-link"
                          type="T04"
                          value={t("WixCode_PropertiesAndEventsPanel_API_Text")}
                        />
                      </a>
                    </Tooltip> */}
    </div>
  );
};

export default ElementNicknameSection;
