import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { MenuItemId, MenuPanelTab } from '@wix/wix-code-plugin-contracts';
import { experimentUtils, fedops } from '@wix/wix-code-common';
import { createLitePanel } from '../components';
import {
  State,
  Action,
  registerMenuClickHandler,
  setSelectedTab,
  createTabsReducer,
  createMenuReducer,
} from '../state';
import { ResourcesEntryPoint } from './resourcesEntryPoint';
import { BackendEntryPoint } from './backendEntryPoint';
import { ContributedMenuPanelTabsSlot } from '../slots/menuPanelTab';
import { leftTreeClickOnATab } from '@wix/bi-logger-platform/v2';
import {
  LitePanelPrivateAPIKey,
  createLitePanelPrivateAPI,
} from '../components/litePanel/litePanelPrivateAPI';

export const DevModeOnEntryPoint: EntryPoint = {
  name: 'Wix Code Dev Mode On Entry Point',
  getDependencyAPIs() {
    return [
      WixCodeEditorAdapterAPI,
      APIKeys.LitePanelAPI,
      APIKeys.ExperimentsAPI,
      APIKeys.ClassicEditorAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.DevContextAPI,
    ];
  },
  declareAPIs() {
    return [APIKeys.MenuAPI, APIKeys.MenuPanelAPI, LitePanelPrivateAPIKey];
  },
  attach(shell: Shell) {
    fedops.getLogger().interactionStarted(fedops.interactions.load);
    const experimentsAPI = shell.getAPI(APIKeys.ExperimentsAPI);
    const tabsSlot = shell.declareSlot(ContributedMenuPanelTabsSlot);

    shell.contributeState<State, Action>(() => {
      const isVeloInStudio = experimentsAPI.isOpen(
        experimentUtils.Experiments.VeloInStudio,
      );
      const menuReducer = createMenuReducer(isVeloInStudio);
      const tabsReducer = createTabsReducer(isVeloInStudio);
      return {
        menu: menuReducer,
        tabs: tabsReducer,
      };
    });

    shell.contributeAPI(APIKeys.MenuAPI, () => {
      const { dispatch } = shell.getStore();
      const biLogger = shell.getAPI(APIKeys.BiLoggerAPI);

      return {
        registerMenuItemListener: (id: MenuItemId, callback: () => void) => {
          const callbackEnhancer = () => {
            callback();
            biLogger.report(leftTreeClickOnATab({ tab_name: id }));
          };
          dispatch(registerMenuClickHandler(id, callbackEnhancer));
        },
        getSelectedMenuItem: () =>
          shell.getStore<State>().getState().menu.selected,
      };
    });

    shell.contributeAPI(APIKeys.MenuPanelAPI, () => ({
      contributeTab: (tab: MenuPanelTab, condition?: () => boolean) => {
        tabsSlot.contribute(shell, tab, condition);
      },
      selectTab: (tabId: string) => {
        shell.getStore().dispatch(setSelectedTab(tabId));
      },
    }));

    shell.contributeAPI(LitePanelPrivateAPIKey, () => {
      const store = shell.getStore<State>();
      const devContextAPI = shell.getAPI(APIKeys.DevContextAPI);
      const { legacyDependencies } = shell.getAPI(WixCodeEditorAdapterAPI);

      return createLitePanelPrivateAPI(
        store,
        devContextAPI,
        legacyDependencies,
        experimentsAPI,
      );
    });
  },
  async extend(shell: Shell) {
    const wixCodeEditorAdapter = shell.getAPI(WixCodeEditorAdapterAPI);
    const devContextAPI = shell.getAPI(APIKeys.DevContextAPI);
    const litePanelAPI = shell.getAPI(APIKeys.LitePanelAPI);
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
    const litePanelPrivateAPI = shell.getAPI(LitePanelPrivateAPIKey);
    const { legacyDependencies } = wixCodeEditorAdapter;
    const isLiteEditor = experimentUtils.isLiteEditor();
    const isVeloInStudio = experimentUtils.isVeloInStudio();
    const shouldContributeDevModeOnEntryPoint = isLiteEditor || isVeloInStudio;

    if (!shouldContributeDevModeOnEntryPoint) {
      return;
    }

    const store = shell.getStore<State>();
    litePanelAPI.contributePanel(
      {
        panel: await createLitePanel({
          legacyDependencies,
          shell,
          store,
          litePanelAPI,
          editorAPI,
          litePanelPrivateAPI,
        }),
      },
      () => editorAPI.developerMode.isEnabled(),
    );

    // Will be removed once lite editor experiment will set to false
    if (!isVeloInStudio) {
      devContextAPI.observeFileChange(() => {
        const { setMenuItem, getCodeMenuItem } = litePanelPrivateAPI;
        setMenuItem(getCodeMenuItem());
      });
    }

    shell.addShells([ResourcesEntryPoint, BackendEntryPoint]);
    fedops.getLogger().interactionEnded(fedops.interactions.load);
  },
};
