import { FileSubTreeDataSource } from '@/sidebar/filesTree/FileSubTree';
import { ActionType } from '@/infra/redux-state/actions/types';
import {
  CodeReuseFSAddRoot,
  CodeReuseFSExpandParents,
  CodeReuseFSRemoveRoot,
  CodeReuseFSSetExpanded,
} from './codeReuseFsReducer';

export interface CodeReuseFsAddRootParams {
  name: string;
  files: FileSubTreeDataSource;
}

export interface CodeReuseFsSetExpandedParams {
  name: string;
  fileId: string;
  expanded: boolean;
}

export const codeReuseFsAddRoot = (
  pkg: CodeReuseFsAddRootParams,
): CodeReuseFSAddRoot => ({
  type: ActionType.CODE_REUSE_FS_ADD_ROOT,
  pkg,
});

export const codeReuseFsSetExpanded = (
  toExpand: CodeReuseFsSetExpandedParams,
): CodeReuseFSSetExpanded => ({
  type: ActionType.CODE_REUSE_FS_SET_EXPANDED,
  toExpand,
});

export const codeReuseFsRemoveRoot = (name: string): CodeReuseFSRemoveRoot => ({
  type: ActionType.CODE_REUSE_FS_REMOVE_ROOT,
  name,
});

export const codeReuseFsExpandParents = (
  fileId: string,
): CodeReuseFSExpandParents => ({
  type: ActionType.CODE_REUSE_FS_EXPAND_PARENTS,
  fileId,
});
