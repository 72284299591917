// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BmDJbv{padding:10px 24px}.eJoHRp{display:flex;flex-direction:column;justify-content:space-between;border-right:1px solid #e5e5e5}.idx6TU{display:flex;justify-content:center;padding:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-header": "BmDJbv",
	"sectionHeader": "BmDJbv",
	"side-menu": "eJoHRp",
	"sideMenu": "eJoHRp",
	"dev-mode-off-button": "idx6TU",
	"devModeOffButton": "idx6TU"
};
export default ___CSS_LOADER_EXPORT___;
