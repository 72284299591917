import { PackagesModalTabId } from './packagesModalConsts';
import { PackagedModalReducerState } from './packagesModalReducer';

function getCurrentTab(state: {
  packagesModal: PackagedModalReducerState;
}): PackagesModalTabId {
  return state.packagesModal.currentTab;
}

function getSelectedPackageForTab(
  state: {
    packagesModal: PackagedModalReducerState;
  },
  tab: string,
): string {
  return state.packagesModal.selectedPackageForTab[tab];
}

function getJustInstalledPkgs(state: {
  packagesModal: PackagedModalReducerState;
}): string[] {
  return state.packagesModal.justInstalledPkgs;
}

function getJustRequestedPkgs(state: {
  packagesModal: PackagedModalReducerState;
}): string[] {
  return state.packagesModal.justRequestedPkgs;
}

function getSelectedVersion(state: {
  packagesModal: PackagedModalReducerState;
}): string | null {
  return state.packagesModal.selectedVersion;
}

function getIsVersionPickerShowing(state: {
  packagesModal: PackagedModalReducerState;
}): boolean {
  return state.packagesModal.showVersionPicker;
}

function getVeloPkgsSearchKeyword(state: {
  packagesModal: PackagedModalReducerState;
}): string {
  return state.packagesModal.veloPkgsSearchKeyword;
}

export {
  getIsVersionPickerShowing,
  getSelectedVersion,
  getCurrentTab,
  getSelectedPackageForTab,
  getJustInstalledPkgs,
  getJustRequestedPkgs,
  getVeloPkgsSearchKeyword,
};
