import * as _ from 'lodash';
import {
  AvailableCodeReusePkg,
  InstalledCodeReusePkg,
} from '../packagesModalContext';
import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '@/infra/redux-state/reducers/rootReducer';

type NewState = Record<string, any>;

const available_all = createSelector(
  (state: NewState) => state.packagesView.availableCodeReusePkgs,
  ({ byId }): AvailableCodeReusePkg[] => {
    const pkgs = _.values(byId);
    return _.sortBy(pkgs, (pkg) => pkg.name);
  },
);

const available_byVelo = createSelector(
  available_all,
  (pkgs: AvailableCodeReusePkg[]) => pkgs.filter((pkg) => !pkg.privateRelease), // will stop being correct if we allow users to create public packages
);

const available_myPackages = createSelector(
  available_all,
  (pkgs: AvailableCodeReusePkg[]) => pkgs.filter((pkg) => pkg.privateRelease), // will stop being correct if we allow users to create public packages
);

const installed_all = createSelector(
  (state: AnyFixMe) => state.packagesView.installedCodeReusePkgs,
  ({ byId }): InstalledCodeReusePkg[] => {
    const pkgs = _.values(byId);
    return _.sortBy(pkgs, (pkg) => pkg.name);
  },
);

const installed_byVelo = createSelector(
  available_byVelo,
  installed_all,
  (allByVelo, allInstalled): InstalledCodeReusePkg[] => {
    const isByVelo = (pkg1: InstalledCodeReusePkg) =>
      allByVelo.some((pkg2) => isSamePkg(pkg2, pkg1));
    return allInstalled.filter(isByVelo);
  },
);

const installed_myPackages = createSelector(
  available_myPackages,
  installed_all,
  (allMy, AllInstalled): InstalledCodeReusePkg[] => {
    const isMyPackage = (pkg: AnyFixMe) =>
      allMy.some((_pkg) => isSamePkg(_pkg, pkg));
    return AllInstalled.filter(isMyPackage);
  },
);

const installed_byOthers = createSelector(
  available_all,
  installed_all,
  (allAvailable, allInstalled): InstalledCodeReusePkg[] => {
    // not loaded yet
    if (allAvailable.length === 0) {
      return [];
    }
    const isNotAvailable = (pkg: InstalledCodeReusePkg) =>
      !allAvailable.some((_pkg) => isSamePkg(_pkg, pkg));
    return allInstalled.filter(isNotAvailable);
  },
);

const isSamePkg = (
  available: AvailableCodeReusePkg,
  installed: InstalledCodeReusePkg,
) => [available.id, available.oldId].includes(installed.id);

const allIds = createSelector(
  (state: NewState) => state.packagesView.availableCodeReusePkgs.byId,
  (state: NewState) => state.packagesView.installedCodeReusePkgs.byId,
  (available, installed): Set<string> => {
    const ids = [..._.keys(available), ..._.keys(installed)];
    return new Set(ids);
  },
);

const allNames = createSelector(
  (state: AppState) => state.packagesView.availableCodeReusePkgs.byId,
  (state: AppState) => state.packagesView.installedCodeReusePkgs.byId,
  (available, installed): Set<string> => {
    const all = [..._.values(available), ..._.values(installed)];
    const names = _.flatten(
      all.map((p) => [p.name, p.backEndPackage, p.frontEndPackage]),
    );
    return new Set(names);
  },
);

const byOthersMetadata = (state: AnyFixMe) =>
  state.packagesView.codeReusePkgsByOthersMetadata;

export const codeReusePkgsSelectors = {
  available: {
    all: available_all,
    byVelo: available_byVelo,
    myPackages: available_myPackages,
  },
  installed: {
    all: installed_all,
    byVelo: installed_byVelo,
    myPackages: installed_myPackages,
    byOthers: installed_byOthers,
  },
  byOthersMetadata,
  allIds,
  allNames,
};
