import React from 'react';
import createReactClass from 'create-react-class';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import once_ from 'lodash/once';
import baseUILib from '@wix/wix-base-ui';

const fileSystemOperationErrorPanel = once_(({ panels: Panels, util }) =>
  createReactClass({
    displayName: 'fileSystemOperationErrorPanel',

    render() {
      const MessagePanelFrame = Panels.frames.messagePanelFrame;
      // const { error } = this.props; // TBD - show error details to user
      return (
        <MessagePanelFrame
          panelName={this.props.panelName}
          panelTitle={util.translate(
            'WixCode_FileSystemOperationError_PanelTitle',
          )}
          confirmLabel={util.translate(
            'WixCode_FileSystemOperationError_Confirm',
          )}
        >
          <baseUILib.Composites.RichTextWithIllustration className="wix-code-fs-operation-error">
            <baseUILib.Illustration>
              <Symbol name="plaster" />
            </baseUILib.Illustration>
            <baseUILib.Composites.RichText>
              <baseUILib.RichText type="T01" className="message">
                {util.translate('WixCode_FileSystemOperationError_Message')}
              </baseUILib.RichText>
            </baseUILib.Composites.RichText>
          </baseUILib.Composites.RichTextWithIllustration>
        </MessagePanelFrame>
      );
    },
  }),
);

export default fileSystemOperationErrorPanel;
