import Text from '../../assets/icons/ic-type-text.svg';
import Image from '../../assets/icons/ic-type-image.svg';
import Boolean from '../../assets/icons/ic-type-boolean.svg';
import Url from '../../assets/icons/ic-type-url.svg';
import Calendar from '../../assets/icons/ic-type-calendar.svg';
import Time from '../../assets/icons/ic-type-time.svg';
import Document from '../../assets/icons/ic-type-document.svg';
import Address from '../../assets/icons/ic-type-address.svg';
import Array from '../../assets/icons/ic-type-array.svg';
import Audio from '../../assets/icons/ic-type-audio.svg';
import Color from '../../assets/icons/ic-type-color.svg';
import ComposedStatus from '../../assets/icons/ic-type-composed-status.svg';
import DocumentArray from '../../assets/icons/ic-type-document-array.svg';
import MediaGallery from '../../assets/icons/ic-type-media-gallery.svg';
import Object from '../../assets/icons/ic-type-object.svg';
import Reference from '../../assets/icons/ic-type-reference.svg';
import Video from '../../assets/icons/ic-type-video.svg';
import Number from '../../assets/icons/ic-type-number.svg';
import RichContent from '../../assets/icons/ic-type-rich-content.svg';
import RichText from '../../assets/icons/ic-type-richtext.svg';
import MultiReference from '../../assets/icons/ic-type-reference-multi.svg';

export const Icons = {
  Text,
  Image,
  Boolean,
  Url,
  Calendar,
  Document,
  Address,
  Array,
  Audio,
  Color,
  ComposedStatus,
  DocumentArray,
  RichContent,
  MediaGallery,
  Object,
  Reference,
  Video,
  Number,
  Time,
  RichText,
  MultiReference,
};
