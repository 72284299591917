import React from 'react';
import {
  Text,
  PromotionalList,
  Button,
  PanelHeader,
  Heading,
  TextButton,
} from '@wix/wix-base-ui';
import s from './backendTab.scss';
import { useTranslation, useBiLogger } from '@wix/wix-code-common-components';
import dataHooks from '../dataHooks';
import { PanelsAPI, VSCodePublicAPI } from '@wix/wix-code-plugin-contracts';
import backendTabEmptyStateImage from '../../assets/backend.png';
import {
  leftTreeClickOnAnItem,
  wixIdeButton,
} from '@wix/bi-logger-platform/v2';

interface BackendCodeTabProps {
  readOnly: boolean;
  vscodePublicAPI: VSCodePublicAPI;
  panelsAPI: PanelsAPI;
  closePanel: () => void;
}

export const BackendCodeTab: React.FC<BackendCodeTabProps> = ({
  readOnly,
  vscodePublicAPI,
  panelsAPI,
  closePanel,
}) => {
  const [t] = useTranslation();
  const biLoggerAPI = useBiLogger();

  const onOpenHelp = () => {
    panelsAPI.openHelpCenter('5d930dcd-e33f-4302-bb7e-fb72f106b3cb');
  };
  const onClickHandler = () => {
    vscodePublicAPI.openVSCodeOnlineTab();
    biLoggerAPI.report(leftTreeClickOnAnItem({ item_name: 'open_wix_ide' }));
    biLoggerAPI.report(
      wixIdeButton({
        action: 'click',
        input_name: 'open_wix_ide',
        origin: 'backend_tab',
      }),
    );
  };
  return (
    <>
      <PanelHeader
        onHelp={onOpenHelp}
        onClose={closePanel}
        className="dragger dark"
        defaultCursor
        dataHook={dataHooks.backend_tab.panel_header}
      >
        {t('lite_panel.backend_tab.panel_header')}
      </PanelHeader>
      <div className={s.root}>
        <div className={s.contentWrapper}>
          <img src={backendTabEmptyStateImage} alt="" />
          <div className={s.title}>
            <Heading
              appearance="h4"
              shouldTranslate={false}
              dataHook={dataHooks.backend_tab.title}
            >
              {t('lite_panel.backend_tab.title')}
            </Heading>
          </div>
          <div className={s.subtitle}>
            <Text
              size="large"
              skin="secondary"
              shouldTranslate={false}
              dataHook={dataHooks.backend_tab.subtitle}
              enableEllipsis={false}
            >
              {t('lite_panel.backend_tab.subtitle')}
            </Text>
          </div>
          <div className={s.descriptionList}>
            <PromotionalList
              dataHook={dataHooks.backend_tab.features_list}
              shouldTranslate={false}
              items={[
                t('lite_panel.backend_tab.list_item_1'),
                t('lite_panel.backend_tab.list_item_2'),
                t('lite_panel.backend_tab.list_item_3'),
              ]}
            />
          </div>
          <div className={s.mainCta}>
            <Button
              shouldTranslate={false}
              dataHook={dataHooks.backend_tab.cta}
              disabled={readOnly}
              onClick={onClickHandler}
            >
              {t('lite_panel.backend_tab.cta')}
            </Button>
          </div>
          <TextButton
            size="medium"
            shouldTranslate={false}
            dataHook={dataHooks.backend_tab.link}
            onClick={() => {
              window.open(t('lite_panel.backend_tab.help', '_blank'));
              biLoggerAPI.report(
                leftTreeClickOnAnItem({ item_name: 'wix_ide_learn_more' }),
              );
            }}
          >
            {t('lite_panel.backend_tab.learn_more')}
          </TextButton>
        </div>
      </div>
    </>
  );
};
