// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".edWQtP{padding:12px 24px}.edWQtP ._I7Sof .RRtdDy{color:#868aa5;display:inline}.edWQtP ._I7Sof .SxQV3L *{color:#000624;font-size:13px;line-height:13px;margin-top:-1px}.edWQtP .KId9RN{margin-top:12px}.edWQtP .KId9RN *{font-size:13px !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "edWQtP",
	"text-wrapper": "_I7Sof",
	"textWrapper": "_I7Sof",
	"description": "RRtdDy",
	"link-button": "SxQV3L",
	"linkButton": "SxQV3L",
	"cta-button": "KId9RN",
	"ctaButton": "KId9RN"
};
export default ___CSS_LOADER_EXPORT___;
