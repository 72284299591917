import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';

export const ServiceTopologyEntryPoint: EntryPoint = {
  name: 'Service Topology',
  getDependencyAPIs() {
    return [APIKeys.LegacyEditorDependencies];
  },
  declareAPIs() {
    return [APIKeys.ServiceTopology];
  },
  attach(shell: Shell) {
    const legacyEditorDependencies = shell.getAPI(
      APIKeys.LegacyEditorDependencies,
    );
    shell.contributeAPI(APIKeys.ServiceTopology, () => ({
      wixCodeIdeServerUrl:
        legacyEditorDependencies.util.serviceTopology.wixCodeIdeServerUrl,
    }));
  },
};
