type WindowSize = { width: number; height: number };

const computeCenterLocation = (windowSize: WindowSize) => {
  const { width, height } = windowSize;
  return {
    left:
      (window.screen as any).availLeft +
      window.screen.availWidth / 2 -
      width / 2,
    top:
      (window.screen as any).availTop +
      window.screen.availHeight / 2 -
      height / 2,
  };
};

export const openCenteredDialog = (url: string, windowSize: WindowSize) => {
  const { left, top } = computeCenterLocation(windowSize);
  const openedDialog = window.open(
    url,
    'github',
    `menubar=no,width=${windowSize.width},height=${windowSize.height},left=${left},top=${top}`,
  ) as Window;

  return openedDialog;
};

export const onWindowMessage = (cb: (event: MessageEvent) => void) => {
  window.addEventListener('message', cb, false);

  return () => window.removeEventListener('message', cb, false);
};
