import { HttpClient } from '@wix/http-client';
import { EditorAPI, ExperimentsAPI } from '@wix/wix-code-plugin-contracts';
import { createRegisterSyncSiteHandlers } from '../SyncSiteHandler/createRegisterSyncSiteHandlers';
import {
  syncPageTypesRequestHandler,
  syncPageFilesRequestHandler,
  syncSiteRequestHandler,
} from './syncSiteRequestHandler';

const registerVSCodeSyncSiteHandler = (
  editorAPI: EditorAPI,
  experimentsAPI: ExperimentsAPI,
) => {
  const _httpClient = new HttpClient();
  const { gridAppId: _gridAppId } =
    editorAPI.wixCode.fileSystem.getViewerInfo();
  const wixCodeSignedInstance = editorAPI.wixCode.getClientSpec().instance;
  const isNewDomain = experimentsAPI.isOpen(
    'spec.vscode-wrapper.useNewWixIdeDomain'
  );

  const registerSyncSiteHandlers = createRegisterSyncSiteHandlers({
    syncSiteHandler: () =>
      syncSiteRequestHandler(
        _httpClient,
        _gridAppId,
        wixCodeSignedInstance,
        isNewDomain,
      ),
    syncPageTypesHandler: (pageId: string) =>
      syncPageTypesRequestHandler(
        _httpClient,
        _gridAppId,
        wixCodeSignedInstance,
        pageId,
        isNewDomain,
      ),
    syncPageFilesHandler: () =>
      syncPageFilesRequestHandler(
        _httpClient,
        _gridAppId,
        wixCodeSignedInstance,
        isNewDomain,
      ),
  });
  registerSyncSiteHandlers(experimentsAPI, editorAPI);
};

export { registerVSCodeSyncSiteHandler };
