import * as React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

interface CodeReuseScopeFolderIconProps {
  opened: boolean;
}

export const CodeReuseScopeFolderIcon: React.FC<
  CodeReuseScopeFolderIconProps
> = ({ opened }) => (
  <Symbol
    name={`corvid_tree__folder_${opened ? 'closed' : 'open'}`}
    className="corvid-tree-prefix-icon"
  />
);
