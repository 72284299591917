import React from 'react';
import { Button, Heading, Spacer, RichText } from '@wix/wix-base-ui';
import classNames from 'classnames';
import styles from './GithubErrorTab.scss';
import { useTranslation } from '@wix/wix-i18n-config';
import { GithubPrivateAPI } from '../../../githubPrivateAPI';
import dataHooks from '../../dataHooks';

type GithubErrorTabProps = {
  githubAPI: GithubPrivateAPI;
};

export const GithubErrorTab: React.FC<GithubErrorTabProps> = ({
  githubAPI,
}) => {
  const [t] = useTranslation();
  return (
    <div
      className={styles.tabContainer}
      data-hook={dataHooks.githubErrorTab.container}
    >
      <div className={styles.gap}></div>
      <Heading
        appearance="h4"
        multiline={true}
        dataHook={dataHooks.githubErrorTab.title}
      >
        {t('githubIntegration.github_unknown_error.title')}
      </Heading>
      <Spacer type="Spacer04"></Spacer>
      <RichText type="T02" dataHook={dataHooks.githubErrorTab.content}>
        {t('githubIntegration.github_unknown_error.description')}
        <ul>
          <li className={styles.listItems}>
            {t('githubIntegration.github_unknown_error.bullet1')}
          </li>
          <li className={styles.listItems}>
            {t('githubIntegration.github_unknown_error.bullet2')}
          </li>
        </ul>
      </RichText>
      <Spacer type="Spacer06"></Spacer>
      <Button
        className="btn-big"
        dataHook={dataHooks.githubErrorTab.cta}
        onClick={() => {
          githubAPI.syncWithSavedState();
        }}
      >
        {t('githubIntegration.github_unknown_error.primary_cta')}
      </Button>
      <Spacer type="Spacer02"></Spacer>
      <Button
        className={classNames('btn-big', 'btn-inverted')}
        dataHook={dataHooks.githubErrorTab.secondaryCta}
        onClick={() => {
          window.open(
            t('githubIntegration.github_unknown_error.secondary_cta.link'),
          );
        }}
      >
        {t('githubIntegration.github_unknown_error.secondary_cta')}
      </Button>
      <div className={styles.gap}></div>
    </div>
  );
};
