import './searchResults.global.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { flatten, noop } from 'lodash';

import ResultGroup from '../resultGroup/resultGroup';

const SearchResults = ({
  error,
  selectedResult,
  results,
  onResultClick,
  onResultDoubleClick,
  translate,
}) => {
  const renderResultsSummary = (_results) => {
    if (error) {
      return <div>{error}</div>;
    }
    if (!_results) {
      return <div>{translate('Corvid_Search_All_Files_Empty_State')}</div>;
    } else {
      const { matches, files } = calcSummary(_results);
      if (matches === 0) {
        return <div>{translate('Corvid_Search_All_Files_No_Results')}</div>;
      }
      return (
        <div className="search-results--stats">
          {translate('Corvid_Search_All_Files_Results_Found_Multiple', {
            matches,
            files,
          })}
        </div>
      );
    }
  };

  const calcSummary = (_results) => {
    const keys = Object.keys(_results);
    return {
      files: keys.length,
      matches: flatten(keys.map((file) => _results[file].matches)).length,
    };
  };

  return (
    <div data-aid="search-results">
      <div data-aid="search-results-stats" className="search-results--stats">
        {renderResultsSummary(results)}
      </div>
      <ul style={{ listStyle: 'none' }}>
        {results &&
          Object.values(results).map(
            ({ title, subTitle, icon, fileId, matches }) => (
              <ResultGroup
                icon={icon}
                key={fileId}
                title={title}
                subTitle={subTitle}
                matches={matches}
                selectedResult={selectedResult}
                onResultClick={onResultClick}
                onResultDoubleClick={onResultDoubleClick}
              />
            ),
          )}
      </ul>
    </div>
  );
};

SearchResults.propTypes = {
  results: PropTypes.object,
  onResultClick: PropTypes.func,
  onResultDoubleClick: PropTypes.func,
};

SearchResults.defaultProps = {
  onResultClick: noop,
  onResultDoubleClick: noop,
};

export default SearchResults;
