import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SideBarState = string[];

const initialState: string[] = [];

const slice = createSlice({
  name: 'sideBar',
  initialState,
  reducers: {
    addSideBarTab(draft, action: PayloadAction<string>) {
      const tab = action.payload;
      draft.push(tab);
    },
  },
});

export default slice.reducer;
export const sideBarActions = slice.actions;
