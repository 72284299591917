import { useTranslation } from '@wix/wix-i18n-config';
import React, { useContext } from 'react';
import { BI } from './constants';
import { MessageModal, Text } from '@wix/wix-base-ui';
import { ExternalDbsModalContext } from './ExternalDbsModalContext';
import { panelHeaderClose } from '@wix/bi-logger-editor/v2';
import { connectExternalDbPanelAction } from '@wix/bi-logger-platform/v2';
import styles from './ExternalDBPanels.scss';
import ErrorImage from '../../assets/icons/underConstruction.svg';
import { dataHooks } from './dataHooks';

interface ErrorModalProps {
  errorMessage: string;
  onCtaClick: () => void;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({
  errorMessage,
  onCtaClick,
}) => {
  const [t] = useTranslation();
  const { panelsAPI, biLoggerAPI } = useContext(ExternalDbsModalContext);
  return (
    <MessageModal
      dataHook={dataHooks.externalDbPanels.messageModal.container}
      onCloseButtonClick={() => {
        biLoggerAPI.report(
          panelHeaderClose({
            panel_name: BI.panels.errorPanel,
          }),
        );
        panelsAPI.closePanel();
      }}
      title={t('WixCode_ErrorModal_ExternalDatabaseConnection_Title')}
      content={
        <>
          <Text enableEllipsis={false}>
            {t('WixCode_ErrorModal_ExternalDatabaseConnection_Description')}
          </Text>
          <div className={styles.marginTop} />
          <Text skin="alert" shouldTranslate={false} enableEllipsis={false}>
            {errorMessage}
          </Text>
        </>
      }
      illustration={<ErrorImage />}
      primaryButtonText={t(
        'WixCode_ErrorModal_ExternalDatabaseConnection_PrimaryCTA',
      )}
      primaryButtonOnClick={async () => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: BI.panels.errorPanel,
            button_name: BI.buttons.editSettings,
            action_type: BI.actions.click,
          }),
        );
        onCtaClick();
      }}
    />
  );
};
