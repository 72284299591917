import './codeEditorContainer.global.scss';
import React, { ComponentType, useEffect } from 'react';
import codeStateReader from '@/infra/redux-state/reducers/codeStateReader';
import leftPaneActions from '@/infra/redux-state/actions/leftPaneActions';
import wixCodeActions from '@/infra/redux-state/actions/wixCodeActions';
import FailOverlayPanel from './overlayPanels/failOverlayPanel';
import LoadingOverlayPanel from './overlayPanels/loadingOverlayPanel';
import codeEditorCreator from '../codeEditor/codeEditor';
import { consts } from '@wix/wix-code-consts';
import { tabs as leftPaneTabs } from '@/sidebar/tabs';
import { WixCodeTheme } from '../../constants/themes';
import userPreferenceUtilCreator from '@/utils/userPreference';
import { userPreferencesKeys } from '../../constants/userPreferenceKeys';
import {
  useEditorLegacyAPIs,
  connectToStores,
} from '@wix/wix-code-common-components';
import { useBiLogger } from '@/infra/wixCodeContext/BiLoggerAPIContext';
import { ideSearchUiButtonClick } from '@wix/bi-logger-platform/v2';
import { experimentUtils } from '@wix/wix-code-common';

interface CodeEditorContainerReduxStateProps {
  file: any;
  selection: any;
  theme: WixCodeTheme;
}

interface CodeEditorContainerReduxReduceProps {
  openFindInAllFiles: any;
  setTheme: (theme: WixCodeTheme) => void;
}

export interface CodeEditorContainerProps {
  fileId: string;
  onEditorFocus: any;
  onEditorBlur: any;
  onSeeAllShortcuts: any;
  readOnly: boolean;
  getFileContext?: any;
  Footer: ComponentType;
}

type CodeEditorContainerCompProps = CodeEditorContainerReduxStateProps &
  CodeEditorContainerReduxReduceProps &
  CodeEditorContainerProps;

const CodeEditorContainerComp: React.FC<CodeEditorContainerCompProps> = ({
  file,
  openFindInAllFiles,
  selection,
  onEditorFocus,
  onEditorBlur,
  onSeeAllShortcuts,
  readOnly,
  getFileContext,
  setTheme,
  Footer,
}) => {
  const {
    legacyDependenciesAPI: { experiment, util, core, stateManagement },
  } = useEditorLegacyAPIs();
  const { editorAPI } = useEditorLegacyAPIs();
  const biLogger = useBiLogger();

  const CodeEditor = codeEditorCreator({
    experiment,
    core,
    util,
    stateManagement,
  });

  const onSelection = (newSelection: AnyFixMe) =>
    editorAPI.wixCode.fileSystem.setSelection(
      file.descriptor.location,
      newSelection,
    );

  const onFindReplaceToggle = ({ isOpen }: AnyFixMe) =>
    editorAPI.wixCode.ide.findReplace.setVisible(isOpen);

  const onFindInAllFiles = () => {
    biLogger.report(
      ideSearchUiButtonClick({
        origin: 'keyboard',
      }),
    );
    return openFindInAllFiles();
  };

  const getCodeEditorProps = () => {
    const props = {
      key: 'codeEditor',
      // fileId: file.get('descriptor').get('location'),
      fileId: file.descriptor.location,
      // modelId: file.get('modelId'),
      modelId: file.modelId,
      selection,
      onFocus: onEditorFocus,
      onBlur: onEditorBlur,
      onSelection,
      onFindReplaceToggle,
      onFindInAllFiles,
      onSeeAllShortcuts,
      readOnly,
      getFileContext,
      Footer,
    };
    return props;
  };

  useEffect(() => {
    const { setSitePreference, getSitePreference } = userPreferenceUtilCreator({
      stateManagement,
    });

    let themePreference = getSitePreference<WixCodeTheme>(
      editorAPI,
      userPreferencesKeys.USER_PREFERENCE_THEME_KEY,
    );
    if (!themePreference) {
      setSitePreference(
        editorAPI,
        userPreferencesKeys.USER_PREFERENCE_THEME_KEY,
        WixCodeTheme.LIGHT,
      );
      themePreference = WixCodeTheme.LIGHT;
    }
    if (experimentUtils.isLiteEditor()) {
      themePreference = WixCodeTheme.STUDIO;
    }

    setTheme(themePreference);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="wix-code-code-editor-container"
        data-aid="wix-code-code-editor-container"
      >
        {/* {file && file.get('state') === consts.FILE_STATES.IDLE && ( */}
        {file && file.state === consts.FILE_STATES.IDLE && (
          <CodeEditor {...getCodeEditorProps()} />
        )}

        {/* {file && file.get('state') === consts.FILE_STATES.LOAD_FAILED && ( */}
        {file && file.state === consts.FILE_STATES.LOAD_FAILED && (
          <FailOverlayPanel key="failOverlayPanel" />
        )}
        {(!file ||
          ![consts.FILE_STATES.IDLE, consts.FILE_STATES.LOAD_FAILED].includes(
            // file.get('state'),
            file.state,
          )) && <LoadingOverlayPanel key="loadingOverlayPanel" />}
      </div>
    </>
  );
};

const mapCodeStateToProps = (
  state: AnyFixMe,
  ownProps: CodeEditorContainerProps,
): CodeEditorContainerReduxStateProps => {
  const file = codeStateReader.getFile(state, ownProps.fileId);
  const selection = codeStateReader.getFileSelection(state, ownProps.fileId);
  const theme = state.ide?.theme;

  return {
    file,
    selection,
    theme,
  };
};

const mapCodeDispatchToProps = (
  dispatch: AnyFixMe,
): CodeEditorContainerReduxReduceProps => ({
  openFindInAllFiles: () =>
    dispatch(leftPaneActions.selectLeftPaneTab(leftPaneTabs.SEARCH)),

  setTheme: (theme: WixCodeTheme) => dispatch(wixCodeActions.setTheme(theme)),
});

let ConnectedComponent = null as any;
const CodeEditorContainerHot: React.FC<CodeEditorContainerProps> = (
  origProps,
) => {
  const {
    legacyDependenciesAPI: { util },
  } = useEditorLegacyAPIs();
  if (ConnectedComponent === null) {
    ConnectedComponent = connectToStores({
      util,
      mapCodeStateToProps,
      mapCodeDispatchToProps,
      comp: CodeEditorContainerComp,
    });
  }
  return <ConnectedComponent {...origProps} />;
};

export const CodeEditorContainer = CodeEditorContainerHot;
