import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import { NpmServiceFacade } from './npmServiceFacade';

const fetchReadme = async (
  name: string,
  editorAPI: EditorAPI,
): Promise<string | null | undefined> => {
  const npmServiceFacade = NpmServiceFacade({
    editorAPI,
  });
  const response = await npmServiceFacade.get([name]);
  return response.data.packageDetails?.[0].readme;
};

export { fetchReadme };
