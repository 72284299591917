import { ActionCreatorWithPayload, bindActionCreators } from '@reduxjs/toolkit';
import {
  Badge,
  Preloader,
  Tooltip,
  TreeSectionDivider,
} from '@wix/wix-base-ui';
import { AppMarket } from '@wix/wix-ui-icons-common/classic-editor';
import PlusIcon from '@/assets/icons/contextMenu/plus.svg';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import s from './privateAppsTree.scss';
import bi from '@/legacy/bi/bi';
import dataHooks from '../../../dataHooks';
import {
  ContextMenuStructure,
  ReadOnlyModeContext,
  TreeContextMenu,
  useTranslation,
  wixCodeReduxContext,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import {
  STATIC_SECTIONS,
  treeSectionActions,
  TreeSectionSetExpandedParams,
} from '../../../filesTree/filesTree/treeSectionReducer';
import { PkgTreeContext } from '../PkgTreeContext';
import { PACKAGE_VIEW_QUICK_ACTIONS } from '../pkgTreeQuickActions';
import { CodeReuseScopeTree } from '../codeReusePkgTree/CodeReuseScopeTree';
import { consts } from '@wix/wix-code-consts';
import { appDataServiceCreator } from '@/toExtract/packages/appDataService';
import { nodeDoubleClick } from '@/infra/redux-state/actions/fileTreeActions';
import {
  isSectionExpanded,
  isSectionLoaded,
} from '../../../filesTree/filesTree/treeSectionSelectors';
import { InstalledCodeReusePkg } from '@/toExtract/packages/packagesModalContext';
import { getPkgScope } from '@/toExtract/packages/utils';
import { PrivateAppsContext } from '../../PrivateAppsProvider';
import { getInstalledApps } from './utils';
import { AppsTooltipContent } from '../AppsTooltipContent/AppsTooltipContent';
import { CODE_EDITOR_MODE } from '@wix/wix-code-plugin-contracts';
import { useInternalAPIs } from '../../packagesTabEntryPoint/contexts';

type ScopedPkgs = {
  name: string;
  installedPkgs: InstalledCodeReusePkg[];
};
interface PrivateAppsTreeProps {
  codeEditorMode?: CODE_EDITOR_MODE;
}

interface PrivateAppsTreeReduxProps {
  loading: boolean;
  expanded: boolean;
}

interface PrivateAppsTreeReduxDispatchProps {
  collapse: ActionCreatorWithPayload<TreeSectionSetExpandedParams, string>;
  expand: ActionCreatorWithPayload<TreeSectionSetExpandedParams, string>;
  doubleClick: (id: string) => void;
}

type PrivateAppsTreeCompProps = PrivateAppsTreeProps &
  PrivateAppsTreeReduxProps &
  PrivateAppsTreeReduxDispatchProps;
interface ContextMenuItemDescriptor {
  icon: string | React.FunctionComponent;
  labelKey: string;
  automationId: string;
}

const CONTEXT_MENU_ITEMS: {
  [key in PACKAGE_VIEW_QUICK_ACTIONS]?: ContextMenuItemDescriptor;
} = {
  SEE_ALL_PRIVATE_APPS: {
    icon: AppMarket,
    labelKey: 'Left_Tree_Blocks_Private_ContextMenu_Install',
    automationId: 'action-see-all-available-apps',
  },
  CREATE_NEW_APP: {
    icon: PlusIcon,
    labelKey: 'Left_Tree_Blocks_Private_ContextMenu_Create',
    automationId: 'action-create-new-app',
  },
};

const privateAppsContextMenuSections: PACKAGE_VIEW_QUICK_ACTIONS[][] = [
  [
    PACKAGE_VIEW_QUICK_ACTIONS.SEE_ALL_PRIVATE_APPS,
    PACKAGE_VIEW_QUICK_ACTIONS.CREATE_NEW_APP,
  ],
];
const convertToScope = (packages: InstalledCodeReusePkg[]): ScopedPkgs[] => {
  const scopeNames = new Set<string>(packages.map((p) => getPkgScope(p.name)));
  return [...scopeNames].map((scope) => ({
    name: scope,
    installedPkgs: packages.filter((p) => getPkgScope(p.name) === scope),
  }));
};
const PrivateAppsTreeComp: React.FC<PrivateAppsTreeCompProps> = ({
  loading,
  expanded,
  codeEditorMode = CODE_EDITOR_MODE.CODE_AND_STAGE,
}) => {
  const { editorAPI } = useEditorLegacyAPIs();
  const {
    wixCodeStoreAPI: { dispatch },
  } = useInternalAPIs();
  const { readOnlyMode } = useContext(ReadOnlyModeContext);
  const { installedApps } = useContext(PrivateAppsContext);
  const [t] = useTranslation();

  const { actionHandler, packagesService } = useContext(PkgTreeContext);
  const [isContextMenuOpen, setContextMenuOpen] = React.useState(false);
  const myScopeName = packagesService.getMyScopeName();
  const installedCodeApps: InstalledCodeReusePkg[] = [];
  installedApps.forEach((app) => installedCodeApps.push(app.codeComponent));
  const installedAppsByScope = getInstalledApps(installedCodeApps, myScopeName);
  const myInstalledApps = installedAppsByScope.myApps;
  const byOtherApps = installedAppsByScope.byOthers;

  const myScopes = convertToScope(myInstalledApps);

  const otherScopes = convertToScope(byOtherApps);
  const appDataService = appDataServiceCreator();

  const dispatchExpandRoot = (sectionName: STATIC_SECTIONS) =>
    dispatch(treeSectionActions.expandTreeSection({ sectionName }));
  const dispatchCollapseRoot = (sectionName: STATIC_SECTIONS) =>
    dispatch(treeSectionActions.collapseTreeSection({ sectionName }));
  const onPrivateAppRootClick = () => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_SECTION, {
      section_name: 'internal_packages',
      action: expanded ? 'close' : 'open',
    });
    expanded
      ? dispatchCollapseRoot(STATIC_SECTIONS.PRIVATE_APP_PKGS)
      : dispatchExpandRoot(STATIC_SECTIONS.PRIVATE_APP_PKGS);
  };

  const onContextMenuClick = (event: React.MouseEvent) => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_PLUS, {
      section_name: 'private-apps',
    });

    event.stopPropagation();
  };

  const menuActionHandler = (action: PACKAGE_VIEW_QUICK_ACTIONS) => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_SETTINGS_ITEM, {
      item_name: 'internal_packages',
      menu_entry_name: action,
    });
    return actionHandler(action);
  };
  const handleContextMenuToggle = (isOpen: boolean) => {
    setContextMenuOpen(isOpen);
  };
  const privateAppsMenuStructure: ContextMenuStructure = {
    sections: privateAppsContextMenuSections.map((section) =>
      section.map((item) => {
        const itemDesc = CONTEXT_MENU_ITEMS[item];
        if (!itemDesc) {
          throw new Error(
            `Missing context menu action in PRIVATE_APP_CONTEXT_MENU_ITEMS: ${item}`,
          );
        }
        return {
          automationId: itemDesc.automationId,
          icon: itemDesc.icon,
          label: t(itemDesc.labelKey),
          onClick: () => menuActionHandler(item),
        };
      }),
    ),
  };
  const suffix = loading ? (
    <div className="preloader-container-hack">
      <Preloader className="tiny" />
    </div>
  ) : (
    <div onClick={onContextMenuClick} className={s.treeSectionSuffix}>
      <TreeContextMenu
        menuClassName="context-menu wix-code-file-tree-dd"
        handleContextMenuToggle={handleContextMenuToggle}
        contextMenuStructure={privateAppsMenuStructure}
        contextMenuButton="corvid_tree__context_menu_add"
        isContextMenuOpen={isContextMenuOpen}
        tooltipContent={t('Velo_Concurrent_Editing_Sidebar_ReadOnly_Text')}
        readonly={!!readOnlyMode.sidePanel?.packages}
      />
    </div>
  );

  return (
    <div data-hook={dataHooks.PRIVATE_PKGS_ROOT}>
      <div className={s.treeSectionWrapper}>
        <div className={s.tooltipContainer}>
          <Tooltip
            dataHook={dataHooks.SECTION_DIVIDER_BADGE}
            interactive={true}
            content={
              <AppsTooltipContent
                text={t('Left_Tree_Blocks_Private_Badge_Tooltip')}
                link={t('Left_Tree_Blocks_Private_Badge_Learn_More_Link')}
                learnMore={t('Left_Tree_Blocks_Private_Badge_Learn_More')}
              />
            }
            closeOnMouseClick={true}
          >
            <Badge skin="attention" value="New" />
          </Tooltip>
        </div>
        <TreeSectionDivider
          dataHook={dataHooks.SECTION_DIVIDER}
          label={t('Left_Tree_Components_Category_Private_Apps')}
          collapsed={!expanded}
          onClick={onPrivateAppRootClick}
          alwaysShowSuffix={isContextMenuOpen}
          suffix={suffix}
          size="small"
          shouldTranslate={false}
        />
      </div>
      <>
        {expanded && (
          <>
            {myScopes.length === 0 ? (
              <CodeReuseScopeTree
                key={myScopeName}
                installedCodeReusePkgs={myInstalledApps}
                packagesService={packagesService}
                appDataService={appDataService}
                actionHandler={actionHandler}
                pkgScope={myScopeName}
                isPrivateScope={true}
              />
            ) : (
              myScopes.map((scope) => (
                <CodeReuseScopeTree
                  key={scope.name}
                  installedCodeReusePkgs={scope.installedPkgs}
                  packagesService={packagesService}
                  appDataService={appDataService}
                  actionHandler={actionHandler}
                  pkgScope={scope.name}
                  isPrivateScope={true}
                  isByOthers={false}
                />
              ))
            )}
            {otherScopes.map((scope) => (
              <CodeReuseScopeTree
                key={scope.name}
                installedCodeReusePkgs={scope.installedPkgs}
                packagesService={packagesService}
                appDataService={appDataService}
                actionHandler={actionHandler}
                pkgScope={scope.name}
                isByOthers={true}
              />
            ))}
          </>
        )}
      </>
    </div>
  );
};

function mapStateToProps(state: AnyFixMe) {
  const sectionName = STATIC_SECTIONS.PRIVATE_APP_PKGS;
  const veloReadmeLoaded = isSectionLoaded(state, consts.VELO_PKG_SCOPE);
  const expanded = isSectionExpanded(state, sectionName);

  return {
    expanded,
    loading: !veloReadmeLoaded,
  };
}

const mapDispatchToProps = (
  dispatch: AnyFixMe,
): PrivateAppsTreeReduxDispatchProps => {
  return bindActionCreators(
    {
      expand: treeSectionActions.expandTreeSection,
      collapse: treeSectionActions.collapseTreeSection,
      doubleClick: nodeDoubleClick,
    },
    dispatch,
  );
};

export const PrivateAppsTree: React.FC<PrivateAppsTreeProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { context: wixCodeReduxContext },
)(PrivateAppsTreeComp);
