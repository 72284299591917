// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aYe1s2{display:flex;align-items:center;flex-wrap:wrap;white-space:pre}.Pw8oxE{color:#868aa5 !important}.K68S6C{display:flex}.PujszK{color:#116dff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "aYe1s2",
	"section-label": "Pw8oxE",
	"sectionLabel": "Pw8oxE",
	"link-container": "K68S6C",
	"linkContainer": "K68S6C",
	"link": "PujszK"
};
export default ___CSS_LOADER_EXPORT___;
