import React from 'react';
import consoleContainerStateService from '../../../services/consoleContainerStateService';
import SizingButtons from '../../toolbarButton/sizingButtons/sizingButtons';
import ToolbarButton from '../../toolbarButton/toolbarButton';
import severityFilterCreator from '../severityFilter/SeverityFilter';
import { ToolbarTextButton } from './toolbarButton/ToolbarTextButton';
import ExternalLink from 'wix-ui-icons-common/classic-editor/ExtrnalLink';
import s from './consoleTopBar.scss';
import { InfoIcon } from '@wix/wix-base-ui';
import cx from 'classnames';
import { useEditorLegacyAPIs } from '@wix/wix-code-common-components';

type ConsoleTopBarProps = {
  overrideButtonClickMap?: Record<string, () => void>;
  containerState: string;
  onDeleteMessages: () => void;
  openLiveLogs: () => void;
  focusIde?: () => void;
};

const ConsoleTopBar: React.FC<ConsoleTopBarProps> = ({
  overrideButtonClickMap,
  containerState,
  onDeleteMessages,
  openLiveLogs,
  focusIde,
}) => {
  const {
    legacyDependenciesAPI: { baseUI, constants, stateManagement, util },
  } = useEditorLegacyAPIs();
  const SeverityFilter = severityFilterCreator({ stateManagement, util });

  const TOOLTIP_DATA = {
    DELETE_MESSAGES: {
      presenter: baseUI.popoverTemplates.titleBodyAndLinkTooltip,
      id: 'wixCodeDeleteMessagesTooltip',
      props: {
        text: 'WixCode_Console_Topbar_Delete_Messages_Tooltip_Title',
      },
    },
  };

  const onTopBarClick = () => {
    const containerStates = constants.DEVELOPER_MODE.CONTAINER_STATES;

    if (containerState === containerStates.MAXIMIZED) {
      return;
    }

    if (containerState === containerStates.MINIMIZED) {
      consoleContainerStateService.revertMinimize();
    } else {
      consoleContainerStateService.minimize();
    }
  };

  return (
    <div className="wix-code-top-bar wix-code-top-bar-deprecated">
      <div className="tool-bar" onClick={() => onTopBarClick()}>
        <div className={s.consoleTitle}>
          <span className={cx('text', s.smallMarginRight)}>
            {util.translate('WixCode_Console_Title')}
          </span>
          <InfoIcon
            shouldTranslate={false}
            text={util.translate('DevConsole_Title_Tooltip')}
            linkText={util.translate('DevConsole_Title_Tooltip_Link')}
            onLinkClick={() =>
              window.open(util.translate('DevConsole_Title_Tooltip_Url'))
            }
          />
        </div>
        <div
          className={s.consoleActionsContainer}
          onClick={(e) => e.stopPropagation()}
        >
          <ToolbarTextButton
            id="wix-code-view-logs-button"
            onClick={openLiveLogs}
            className={s.viewLogsToolbarButton}
            prefixIcon={<ExternalLink />}
            tooltipContent={util.translate(
              'WixCode_CodePanel_TopBar_ViewLogs_Tooltip_Text',
            )}
            shouldShowUnderline={false}
          >
            {util.translate('WixCode_CodePanel_TopBar_ViewLogs_Title')}
          </ToolbarTextButton>

          <SeverityFilter key="filter-severity" />
          <ToolbarButton
            id="deleteMessages"
            onClick={() => onDeleteMessages()}
            symbolName="code-console-delete-messages"
            tooltipData={TOOLTIP_DATA.DELETE_MESSAGES}
          />
          <SizingButtons
            containerStateService={consoleContainerStateService}
            containerState={containerState}
            showMaximize={false}
            overrideButtonClickMap={overrideButtonClickMap}
            showMinimize={true}
            onAction={focusIde}
          />
        </div>
      </div>
    </div>
  );
};

export default ConsoleTopBar;
