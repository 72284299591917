import React from 'react';
import dataHooks from '../dataHooks';
import { useTranslation, TFunction } from '@wix/wix-i18n-config';
import { githubDisconnectAction } from '@wix/bi-logger-platform/v2';
import { useBiLogger } from '../../context/biLoggerContext';
import { GithubPrivateAPI } from '../../githubPrivateAPI';
import { MODALS_ACTIONS } from '../../utils/biConsts';
import { DisconnectModalBase } from './DisconnectModalBase';
import { createDisconnectFromGithubActions } from '../../utils/biEvents';
import { BiLoggerAPI } from '@wix/wix-code-plugin-contracts';

export interface DisconnectModalProps {
  closeModal: Function;
  githubAPI: GithubPrivateAPI;
}

const DisconnectModal: React.FC<DisconnectModalProps> = ({
  closeModal,
  githubAPI,
}) => {
  const [t] = useTranslation();
  const bi = useBiLogger();
  const disconnectBiEvents = createDisconnectFromGithubActions(githubAPI);

  const reportBiEvent = (action: string) => {
    bi.report(
      disconnectBiEvents.disconnectFromGithubModalAction({
        action,
      }),
    );
  };

  return (
    <DisconnectModalBase
      dataHook={dataHooks.disconnectModal.container}
      title={t('githubIntegration.disconnect_modal.title')}
      githubAPI={githubAPI}
      closeModal={closeModal}
      primaryButtonText={t(
        'githubIntegration.disconnect_modal.continue_button',
      )}
      onCloseButtonClick={() => {
        reportBiEvent(MODALS_ACTIONS.X);
        closeModal();
      }}
      secondaryButtonOnClick={() => {
        reportBiEvent(MODALS_ACTIONS.CANCEL);
        closeModal();
      }}
      secondaryButtonText={t(
        'githubIntegration.disconnect_modal.cancel_button',
      )}
      content={<DisconnectModalContent t={t} bi={bi} />}
    />
  );
};

const DisconnectModalContent = ({
  t,
  bi,
}: {
  t: TFunction;
  bi: BiLoggerAPI;
}) => {
  return (
    <>
      <div data-hook={dataHooks.disconnectModal.description.contentLine1}>
        {t('githubIntegration.disconnect_modal.content_line_1')}
      </div>
      <div>
        <span data-hook={dataHooks.disconnectModal.description.contentLine2}>
          {t('githubIntegration.disconnect_modal.content_line_2')}
        </span>{' '}
        <a
          data-hook={dataHooks.disconnectModal.description.link}
          href={t('githubIntegration.disconnect_modal.learn_more_link')}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            bi.report(
              githubDisconnectAction({ action: MODALS_ACTIONS.LEARN_MORE }),
            );
          }}
        >
          {t('githubIntegration.disconnect_modal.learn_more')}
        </a>
      </div>
    </>
  );
};

export default DisconnectModal;
