import React from 'react';
import { APIKeys, EntryPoint, Shell } from '@wix/wix-code-repluggable';
import { Experiments } from '@wix/wix-code-common';
import { LiteToFallbackModal } from '../components/LiteToFallbackPanel';
import { LITE_TO_FALLBACK_PREFERENCE_KEY } from '../constants';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';

export const UserNotificationsEntryPoint: EntryPoint = {
  name: 'User Notifications Entry Point',
  getDependencyAPIs: () => [
    WixCodeEditorAdapterAPI,
    APIKeys.ExperimentsAPI,
    APIKeys.PanelsAPI,
    APIKeys.UserPreferencesAPI,
  ],
  attach: (shell: Shell) => {
    const panelsAPI = shell.getAPI(APIKeys.PanelsAPI);
    const experimentsAPI = shell.getAPI(APIKeys.ExperimentsAPI);
    const userPreferencesAPI = shell.getAPI(APIKeys.UserPreferencesAPI);
    const { legacyDependencies } = shell.getAPI(WixCodeEditorAdapterAPI);

    const hasUserAlreadyConfirmed = userPreferencesAPI.getGlobalPreference(
      LITE_TO_FALLBACK_PREFERENCE_KEY,
    );

    const shouldShowLiteToFallbackModal =
      !hasUserAlreadyConfirmed &&
      experimentsAPI.isOpen(Experiments.LiteToFallbackNotification);

    if (shouldShowLiteToFallbackModal) {
      panelsAPI.openPanel(
        <LiteToFallbackModal
          panelsAPI={panelsAPI}
          userPreferencesAPI={userPreferencesAPI}
          t={legacyDependencies.util.translate}
        />,
      );
    }
  },
};
