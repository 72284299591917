import { createHttpClient } from '@wix/http-client';
// This is the only place in the code that should use the original types and transform them - so ignoring the lint rule is needed here
// eslint-disable-next-line no-restricted-imports
import {
  authorizeGitHubUser as createAuthorizeGithubUserRequest,
  initGitHubRepository as createInitGitHubRepositoryRequest,
  getTeamworkOnboardingStatus,
  createGitHubRepository as createGitHubRepositoryRequest,
  disconnectGitHubRepository as createDisconnectGitHubRepositoryRequest,
} from '@wix/ambassador-velo-teamwork-v1-teamwork-onboarding-status/http';
import { ensureSyncedWithRemote as createEnsureSyncedWithRemoteRequest } from '@wix/ambassador-velo-apps-v1-app/http';
import {
  OnboardingState,
  TeamworkOnboardingStatus,
  RepositoryOwner,
} from '../types/GithubTypes';
import { FileStructureType } from '@wix/wix-code-plugin-contracts';

const defaultTeamworkOnboardingStatus: TeamworkOnboardingStatus = {
  onboardingState: OnboardingState.GITHUB_DISABLED,
};

const createGithubService = (getSignedInstance: () => string) => {
  const httpClient = createHttpClient({ getAppToken: getSignedInstance });

  const authorizeGithubUser = async (code: string) => {
    const { data } = await httpClient.request(
      createAuthorizeGithubUserRequest({ authCode: code }),
    );
    return (data.teamworkOnboardingStatus ||
      defaultTeamworkOnboardingStatus) as TeamworkOnboardingStatus;
  };

  const getOnboardingStatus = async () => {
    const { data } = await httpClient.request(getTeamworkOnboardingStatus({}));
    return (data.teamworkOnboardingStatus ||
      defaultTeamworkOnboardingStatus) as TeamworkOnboardingStatus;
  };

  const initGitHubRepository = async (
    veloAppInstallationId?: string,
    label?: FileStructureType
  ) => {
    const { data } = await httpClient.request(
      createInitGitHubRepositoryRequest({ veloAppInstallationId, label }),
    );
    return (data.teamworkOnboardingStatus ||
      defaultTeamworkOnboardingStatus) as TeamworkOnboardingStatus;
  };

  const createGitHubRepository = async (
    owner: RepositoryOwner,
    repositoryName: string,
    repositoryDescription?: string | null,
  ) => {
    const { data } = await httpClient.request(
      createGitHubRepositoryRequest({
        owner,
        repositoryName,
        repositoryDescription,
      }),
    );
    return (data.teamworkOnboardingStatus ||
      defaultTeamworkOnboardingStatus) as TeamworkOnboardingStatus;
  };

  const disconnectGithubRepository = async () => {
    const { data } = await httpClient.request(
      createDisconnectGitHubRepositoryRequest({}),
    );
    return (data.teamworkOnboardingStatus ||
      defaultTeamworkOnboardingStatus) as TeamworkOnboardingStatus;
  };

  const ensureSyncedWithRemote = async () => {
    return httpClient.request(createEnsureSyncedWithRemoteRequest({}));
  };

  return {
    authorizeGithubUser,
    getOnboardingStatus,
    initGitHubRepository,
    createGitHubRepository,
    disconnectGithubRepository,
    ensureSyncedWithRemote,
  };
};

export default createGithubService;
export type GithubService = ReturnType<typeof createGithubService>;
