import { DealerOffersServingService } from '@wix/ambassador-dealer-offers-serving-service/http';
import { DealerOfferEventsService } from '@wix/ambassador-dealer-offer-events-service/http';
import {
  DEALER_REAL_ESTATE_IDS,
  DEALER_SERVICE_BASE_URL,
  DEALER_EVENTS_BASE_URL,
} from './config';
import {
  dealerEventsCreators,
  DealerEventOptions,
  DealerEventClickOptions,
} from './dealerEvents';

export type DealerOfferGUID = string;

const dealerOffersService = DealerOffersServingService(
  DEALER_SERVICE_BASE_URL,
).DealerOffersServing();

const dealerEventsService = DealerOfferEventsService(
  DEALER_EVENTS_BASE_URL,
).DealerOfferEventsService();

export const dealerService = {
  listDealersOffers: () =>
    dealerOffersService().listOffersBulk({
      realEstateIds: DEALER_REAL_ESTATE_IDS,
    }),
  reportDealerOfferViewed: (eventOptions: DealerEventOptions) => {
    return dealerEventsService().reportEvent(
      dealerEventsCreators.dealerEventViewed(eventOptions),
    );
  },
  reportDealerOfferClosed: (eventOptions: DealerEventOptions) => {
    return dealerEventsService().reportEvent(
      dealerEventsCreators.dealerEventClosed(eventOptions),
    );
  },
  reportDealerOfferClicked: (eventOptions: DealerEventClickOptions) => {
    return dealerEventsService().reportEvent(
      dealerEventsCreators.dealerEventClickCTA(eventOptions),
    );
  },
};
