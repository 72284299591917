import * as React from 'react';
import { Tooltip, RichText } from '@wix/wix-base-ui';
import s from '../codeReusePkgTree.scss';
import dataHooks from '../../../../dataHooks';

interface SuffixPimpleProps {
  tooltipText: string;
  ctaAction: (event: React.MouseEvent) => void;
  ctaText: string;
}

export const SuffixPimple: React.FC<SuffixPimpleProps> = ({
  tooltipText,
  ctaAction,
  ctaText,
}) => {
  return (
    <Tooltip
      marginLeft={8}
      marginTop={2}
      interactive={true}
      closeOnContentMouseClick={true}
      content={
        <RichText>
          {tooltipText}
          &nbsp;
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={ctaAction}>{ctaText}</a>
        </RichText>
      }
    >
      <span data-hook={dataHooks.UPDATE_PIMPLE} className={s.update} />
    </Tooltip>
  );
};
