import {
  ClassicEditorAPI,
  ExperimentsAPI,
  FileSystemAPI,
} from '@wix/wix-code-plugin-contracts';
import { debounce } from 'lodash';
import { UPDATE_PREVIEW_CODE_DEBOUNCE_MS } from './consts';

export type FileSystemChangesUnsubscriber = () => void;

const subscribeToFileSystemChanges: (
  fileSystemAPI: FileSystemAPI,
  classicEditorAPI: ClassicEditorAPI,
  experimentsAPI: ExperimentsAPI,
) => FileSystemChangesUnsubscriber = (
  fileSystemAPI: FileSystemAPI,
  classicEditorAPI: ClassicEditorAPI,
  experimentsAPI: ExperimentsAPI,
) => {
  const fsChangesListenerUnsubscriber = !experimentsAPI.isOpen('dm_ideServerNG')
    ? fileSystemAPI.listenToExternalFileSystemChanges()
    : undefined;

  const onCodeChangedInPreview = debounce(
    () => {
      classicEditorAPI.editorAPI.pages.refresh();
    },
    UPDATE_PREVIEW_CODE_DEBOUNCE_MS,
    {
      leading: true,
      trailing: true,
    },
  );

  const onConcurrentChange = () => {
    const isInPreviewMode =
      classicEditorAPI.editorAPI.preview.isInPreviewMode();

    if (isInPreviewMode) {
      onCodeChangedInPreview();
    }
  };

  const concurrentChangesListenerUnsubscriber =
    classicEditorAPI.editorAPI.wixCode.fileSystem.subscribeToConcurrentChange(
      onConcurrentChange,
    );

  return () => {
    fsChangesListenerUnsubscriber && fsChangesListenerUnsubscriber();
    concurrentChangesListenerUnsubscriber &&
      concurrentChangesListenerUnsubscriber();
  };
};

export { subscribeToFileSystemChanges };
