import * as React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { TextLabel, Illustration, TextButton } from '@wix/wix-base-ui';
import { MODAL_TABS } from '../../../packagesModalConsts';
import { PkgListEmptyStateContainer } from '../pkgListEmptyStateContainer/PkgListEmptyStateContainer';
import dataHooks from '../../../../dataHooks';
import { PackagesModalContext } from '../../../../packagesModalContext';

interface InstalledPkgsEmptyStateProps {
  dataHook: string;
  onLinkClick: Function;
}

export const InstalledPkgsEmptyState: React.FC<
  InstalledPkgsEmptyStateProps
> = ({ dataHook, onLinkClick }) => {
  const { showCodeReuse, isBlocksCombinedMode, t } =
    React.useContext(PackagesModalContext);

  return (
    <PkgListEmptyStateContainer dataHook={dataHook}>
      <Illustration>
        <Symbol name="corvid_npm__installed_empty_state" />
      </Illustration>
      <TextLabel
        dataHook="title"
        type="T16"
        value={
          isBlocksCombinedMode
            ? t('Package_Manager_Installed_Apps_And_Packages_EmptyState_Title')
            : t('Package_Manager_Installed_EmptyState_Title')
        }
      />
      {showCodeReuse ? (
        <TextLabel
          dataHook={dataHooks.INSTALLED_PKGS_EMPTY_STATE_SUBTITLE}
          type="T07"
          value={
            isBlocksCombinedMode
              ? t(
                  'Package_Manager_Installed_Apps_And_Packages_EmptyState_SubTitle',
                )
              : t('Package_Manager_Installed_EmptyState_SubTitle')
          }
        />
      ) : (
        <TextLabel
          dataHook={dataHooks.INSTALLED_PKGS_EMPTY_STATE_SUBTITLE_NO_CODE_REUSE}
          type="T07"
          value={t(
            'Package_Manager_Installed_EmptyState_SubTitle_No_Code_Reuse',
          )}
        />
      )}
      {showCodeReuse && (
        <TextButton
          onClick={() => onLinkClick(MODAL_TABS.BUILT_BY_VELO)}
          dataHook={dataHooks.INSTALLED_PKGS_EMPTY_STATE_SEARCH_VELO_LINK}
        >
          {isBlocksCombinedMode
            ? t(
                'Package_Manager_Installed_Apps_And_Packages_EmptyState_Velo_Pkgs',
              )
            : t('Package_Manager_Installed_EmptyState_Velo_Pkgs')}
        </TextButton>
      )}
      <TextButton
        onClick={() => onLinkClick(MODAL_TABS.NPM_PACKAGES)}
        dataHook={dataHooks.INSTALLED_PKGS_EMPTY_STATE_SEARCH_NPM_LINK}
      >
        {t('Package_Manager_Installed_EmptyState_npm_Pkgs')}
      </TextButton>
    </PkgListEmptyStateContainer>
  );
};
