export const MODALS_ACTIONS = {
  CONTINUE: 'continue',
  CANCEL: 'cancel',
  X: 'X',
  SIGN_IN: 'sign_in',
  LEARN_MORE: 'learn_more',
  CREATE: 'create',
  INSTALL: 'install',
  CONFIRM: 'confirm',
  COPY_CODE: 'copy_code',
  REPOSITORY: 'repository',
  DISCONNECT: 'disconnect',
  HELP: '?',
  TRY_AGAIN: 'try_again',
  REFRESH_EDITOR: 'refresh_editor',
};
export const CONNECT_TO_GITHUB_MODAL_STEPS = {
  BEFORE_CONNECTION: 'before_connecting',
  SIGN_IN: 'sign_in',
  CREATE: 'create_repository',
  INSTALL: 'install_app',
  SUCCESSFUL_CONNECTION: 'successful_connection',
  CREATE_REPO_FAILED: 'create_repo_failed',
};
export const GITHUB_TAB_ACTIONS = {
  REPOSITORY: 'repositroy',
  VIEW_COMMIT_HISTORY: 'view_commit_history',
  COMMIT_ID: 'commit_id',
  LEARN_MORE: 'learn_more',
  OVERVIEW: 'github_overview',
};
export const GITHUB_OVERVIEW_CONTEXTMENU = {
  VIEW: 'view_github_repository',
  CLONE: 'clone_and_develope',
  DISCONNECT: 'disconnect',
};
export const CONNECT_TO_GITHUB_TAB_ACTIONS = {
  CONNECT_TO_GITHUB: 'connect_to_github',
  VIEW_DOCUMENTATION: 'view_documentation',
};
export const ITEM_TYPE = {
  GITHUB: 'github',
};
export const evids = {
  leftTreeClickOnAnItem: 93,
  leftTreeClickOnMenuOrSettingIcon: 94,
  leftTreeClickInMenuSettingOptionItem: 97,
  githubOnboardingAction: 350,
  githubOnboardingSuccessStatus: 351,
  githubDisconnectAction: 352,
  gitHubDisconnectStatus: 353,
  cloneAndDevelopClicked: 354,
};
export const biSrc = 79;
