import * as React from 'react';
import s from './PackageVersionPicker.scss';
import itemStyle from '../packagesListItem/PackagesListItem.scss';
import { ListSectionTitle } from '../listSectionTitle/ListSectionTitle';
import { CodeReusePkgVersion } from '../../../../codeReuseServerAPI';
import dataHooks from '../../../../dataHooks';
import { selectPackageVersion } from '../../../packagesModalActions';
import { getSelectedVersion } from '../../../packagesModalSelectors';
import PackagesListItem from '../packagesListItem/PackagesListItem';
import { getLargesetMinor } from '../../../../utils';
import { useDispatch, useSelector } from '@wix/wix-code-common-components';

interface PackagePublicVersionsPickerProps {
  publicVersions: CodeReusePkgVersion[];
}
export const PackagePublicVersionsPicker: React.FC<
  PackagePublicVersionsPickerProps
> = ({ publicVersions }) => {
  const dispatch = useDispatch();
  const selectedPackageVersion = useSelector(getSelectedVersion);

  const actualSelectedVersion = selectedPackageVersion?.startsWith('^')
    ? getLargesetMinor(selectedPackageVersion, publicVersions)
    : selectedPackageVersion;

  const isSelected = (version: CodeReusePkgVersion) =>
    version.version === actualSelectedVersion;

  return (
    <>
      <div className={s.titleContainer}>
        <ListSectionTitle title="Package_Manager_Versions_Built_Title" />
      </div>

      {publicVersions.map((version, index) => (
        <div
          data-hook={dataHooks.VERSION_ITEM}
          key={version.version}
          className={itemStyle.versionItem}
        >
          <PackagesListItem
            onClick={() => dispatch(selectPackageVersion(version.version))}
            isSelected={isSelected(version)}
            publicVersions={publicVersions}
            versionInfo={{
              version: version.version,
              publishedDate: version.createdDate,
              releaseNotes: version.releaseNotes,
              isLatest: index === 0,
              allVersions: publicVersions,
            }}
          />
        </div>
      ))}
    </>
  );
};
