import { DoNotTranslateBaseUiComponents } from '@wix/wix-code-common-components';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { ClassicEditorAPI, PanelsAPI } from '@wix/wix-code-plugin-contracts';
import React, { ReactElement } from 'react';

export const DYNAMIC_PANEL_NAME = 'DynamicPanel';
export const DYNAMIC_PANEL_FULL_PATH = `wixCode.panels.${DYNAMIC_PANEL_NAME}`;

const WixCodePanelPath = 'wixCode.panels.WixCodePanel';
interface CreatePanelsApiParams {
  classicEditorAPI: ClassicEditorAPI;
  legacyDependencies: WixCodeEditorAdapterAPI['legacyDependencies'];
}

export const createPanelsAPI = ({
  classicEditorAPI,
  legacyDependencies,
}: CreatePanelsApiParams): PanelsAPI => {
  const { editorAPI } = classicEditorAPI;
  const FocusPanelFrame = legacyDependencies.panels.frames.focusPanelFrame;

  return {
    openPanel: (
      element: ReactElement,
      withFocusPanelFrame?: boolean,
      keepPanelsOpen?: boolean,
    ) => {
      const wrappedElementWithNoTranslate = (
        <DoNotTranslateBaseUiComponents>
          {element}
        </DoNotTranslateBaseUiComponents>
      );
      const Component = () => {
        if (!withFocusPanelFrame) {
          return wrappedElementWithNoTranslate;
        }
        return (
          <FocusPanelFrame
            shouldHideHeader={true}
            onClose={() => {
              editorAPI.panelManager.closePanelByName(WixCodePanelPath);
              return true;
            }}
            shouldNotCloseOnBlur={keepPanelsOpen}
          >
            {wrappedElementWithNoTranslate}
          </FocusPanelFrame>
        );
      };
      editorAPI.panelManager.openPanel(
        WixCodePanelPath,
        {
          Component,
        },
        keepPanelsOpen,
      );
    },
    closePanel: () => editorAPI.panelManager.closePanelByName(WixCodePanelPath),
    getOpenPanels: () => editorAPI.panelManager.getOpenPanels(),
    openHelpCenter: (helpId: string) =>
      editorAPI.panelManager.openHelpCenter(helpId),
    closeAllPanels: () => editorAPI.panelManager.closeAllPanels(),
  };
};
