import React, { ElementType } from 'react';
import classNames from 'classnames';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { useEditorLegacyAPIs } from '@wix/wix-code-common-components';

type ToolbarButtonProps = {
  onClick: React.MouseEventHandler;
  symbolName: string;
  tooltipData: {
    presenter: ElementType<any>;
    props: {
      text: string;
      link?: string;
      linkAction?: () => void;
      shouldTranslate?: boolean;
    };
    delay?: string;
    interactive?: boolean;
    id: string;
  };
  disabled?: boolean;
  id: string;
  className?: string;
};

const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  onClick,
  symbolName,
  tooltipData,
  disabled,
  id,
  className,
}) => {
  const {
    legacyDependenciesAPI: { baseUI },
  } = useEditorLegacyAPIs();
  const getTooltipProps = () => {
    const value = React.createElement(tooltipData.presenter, tooltipData.props);
    return {
      value,
      delay: tooltipData.delay,
      disabled,
      interactive: tooltipData.interactive,
    };
  };

  return (
    <baseUI.tooltip {...getTooltipProps()}>
      <div
        data-aid={id}
        className={classNames(className, {
          'wix-code-ide-toolbar-button': true,
          disabled,
        })}
        role="button"
        onClick={onClick}
      >
        <Symbol name={symbolName} />
      </div>
    </baseUI.tooltip>
  );
};

export default ToolbarButton;
