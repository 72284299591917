import React, { useState, useEffect, useContext } from 'react';
import cx from 'classnames';

import {
  DataTableLinked,
  DataTable,
  DataTableCloud,
  Edit,
  ExternalLink,
  UnlinkProperties,
  Page,
} from '@wix/wix-ui-icons-common/classic-editor';

import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import {
  isAppBuilderSchema,
  isCollectionSuitableForDP,
  isExternalSchema,
  isSingleItemSchema,
  isUserSchema,
} from '../schemaHelpers';
import { SchemaFieldsIcons } from '../icons/icons';
import { IconColor } from '../ContextMenuItem/ContextMenuItem';
import { TreeContextMenu } from '../TreeContextMenu/TreeContextMenu';
import {
  TreeListItem,
  Tooltip,
  InfoIcon,
  Preloader,
  Divider,
  Text,
  Popover,
  TextButton,
} from '@wix/wix-base-ui';
import { Schema } from '../../internal-types';
import { useTranslation } from '@wix/wix-i18n-config';
import { bi } from '../../bi';
import { dataHooks } from '../../dataHooks';
import { TabContext } from '../tabContext';
import s from './CollectionsTreeNode.scss';
import { QUICK_ACTIONS } from '../../constants';
import {
  leftTreeClickInMenuSettingOptionItem,
  leftTreeClickOnASection,
  leftTreeClickOnMenuSettingIconItem,
  leftTreeFormsCollectionsMoveAlert,
} from '@wix/bi-logger-platform/v2';
import { CopyButton } from '../CopyButton/CopyButton';
import { LabelWithDescription } from '../LabelWithDescription/LabelWithDescription';
import { FORMS_NAMESPACE } from '../CollectionsTree/CollectionsTree';

export interface TreeContextMenuItemProps {
  automationId?: string;
  disabled?: boolean;
  icon: React.FunctionComponent;
  iconColor?: IconColor;
  label: string;
  onClick: () => void;
  id?: string;
}

export type NodeData = {
  isFolder: boolean;
  schema: Schema;
  name: string;
  namespace: string | null;
  isSelectedSchema: boolean;
  isExpandedFolder: boolean;
  isExternalFolder: boolean;
  childItems: NodeData[];
};

export type ContextMenuAction = {
  title: string;
  event: string;
  icon: React.FC;
  disabled?: boolean;
};

export type MenuItemSection = TreeContextMenuItemProps[];

export interface ContextMenuStructure {
  sections: MenuItemSection[];
  seperator?: React.ReactElement;
}

export type SchemaActions = {
  [collectionId: string]: ContextMenuAction[];
};

type CollectionsTreeNodeProps = {
  isTopLevelTreeRoot: boolean;
  loading: boolean;
  nodeData: NodeData;
  onCollectionSelected: Function;
  onCreateCollection: Function;
  onFolderToggle: Function;
  onContextMenuActionSelected: ({
    action,
    nodeData,
    biItemType,
  }: {
    action: string;
    nodeData: NodeData;
    biItemType: string;
  }) => void;
  schemaActions: SchemaActions;
  depth?: number;
  editorAPI: EditorAPI;
  util: any;
  biSectionName: string;
  shouldShowFormsTooltip?: boolean;
  setShouldShowFormsTooltip?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CollectionsTreeNode: React.FC<CollectionsTreeNodeProps> = ({
  editorAPI,
  loading,
  nodeData,
  onCreateCollection,
  onCollectionSelected,
  onFolderToggle,
  onContextMenuActionSelected,
  schemaActions,
  isTopLevelTreeRoot,
  depth,
  util,
  biSectionName,
  shouldShowFormsTooltip,
  setShouldShowFormsTooltip,
}) => {
  const {
    schema,
    isFolder,
    name,
    isSelectedSchema,
    isExpandedFolder,
    isExternalFolder,
    childItems,
  } = nodeData || {};
  const { biLoggerAPI } = useContext(TabContext);
  const [isTreeHovered, setTreeHovered] = useState(false);
  const [contextMenuSections, setContextMenuSections] =
    useState<ContextMenuStructure>({
      sections: [],
    });
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const [t] = useTranslation();

  const onContextMenuClick = (event: any) => {
    event.stopPropagation();
    biLoggerAPI.report(
      leftTreeClickOnMenuSettingIconItem({
        item_name: name,
        item_type: isFolder
          ? bi.itemTypes.EXTERNAL_COLLECTION
          : bi.itemTypes.COLLECTION,
      }),
    );
  };

  const getTooltipTranslation = (): string => {
    if (shouldHaveTooltip()) {
      const collectionId = schema.id;
      return t('WixCode_TreeView_Database_Full_Name_Tooltip', {
        collectionId,
      });
    }
    return '';
  };

  const shouldHaveTooltip = () => {
    if (isFolder) {
      return false;
    }

    return isAppBuilderSchema(schema);
  };

  const onMouseHover = (isEnter: boolean) => setTreeHovered(isEnter);

  const onNodeClick = () => {
    if (loading) {
      return;
    }

    if (!isFolder) {
      setIsExpanded(!isExpanded);
      return;
    }
    biLoggerAPI.report(
      leftTreeClickOnASection({
        section_name: bi.sectionNames.DATABASE_GROUP,
        sub_section: name,
        action: isExpandedFolder ? bi.actions.CLOSE : bi.actions.OPEN,
      }),
    );
    onFolderToggle(nodeData);
  };

  const getIcon = () => {
    const { schema: nodeSchema } = nodeData;

    if (isSingleItemSchema(nodeSchema)) {
      return <Page />;
    } else if (isExternalSchema(nodeSchema)) {
      return <DataTableCloud />;
    } else if (isUserSchema(nodeSchema)) {
      return <DataTable />;
    } else {
      return <DataTableLinked />;
    }
  };

  const actionSelected = (action: string) => {
    onContextMenuActionSelected({
      action,
      nodeData,
      biItemType: isExternalFolder
        ? bi.itemTypes.EXTERNAL_COLLECTION
        : bi.itemTypes.COLLECTION,
    });
  };

  const showEditCollectionItem = schema && isUserSchema(schema);
  const showAddDynamicPageItem = !isFolder && isCollectionSuitableForDP(schema);

  const externalCollectionsSections: MenuItemSection[] = [
    [
      {
        onClick: () => actionSelected(QUICK_ACTIONS.EDIT_EXTERNAL_COLLECTIONS),
        icon: Edit,
        label: t(
          'WixCode_TreeView_ExternalDatabases_Connection_ContextMenu_Edit',
        ),
        automationId: 'action-edit-external-collection',
        id: 'edit-external-collection',
      },
      {
        onClick: () =>
          actionSelected(QUICK_ACTIONS.REMOVE_EXTERNAL_COLLECTIONS),
        icon: UnlinkProperties,
        label: t(
          'WixCode_TreeView_ExternalDatabases_Connection_ContextMenu_Disconnect',
        ),
        automationId: 'action-remove-external-collection',
        id: 'remove-external-collection',
      },
    ],
  ];

  externalCollectionsSections.push([
    {
      onClick: () => {
        biLoggerAPI.report(
          leftTreeClickInMenuSettingOptionItem({
            item_name: name,
            item_type: bi.itemTypes.EXTERNAL_COLLECTION,
            menu_entry_name: bi.menuItems.externalCollectionLearnMore,
          }),
        );
        window.open(
          t('WixCode_TreeView_ExternalDatabases_LearnMore_Link', '_blank'),
        );
      },
      icon: ExternalLink,
      label: t('WixCode_TreeView_ExternalDatabases_LearnMore_Text'),
      id: 'learn-more-external-collections',
    },
  ]);

  useEffect(() => {
    if (isExternalFolder) {
      setContextMenuSections({ sections: externalCollectionsSections });
    }
    if (!isFolder) {
      const finalSections = [];
      finalSections.push({
        onClick: () => {
          biLoggerAPI.report(
            leftTreeClickInMenuSettingOptionItem({
              item_type: bi.itemTypes.COLLECTION,
              item_name: name,
              menu_entry_name: bi.menuItems.openCollection,
            }),
          );
          onCollectionSelected(schema.id);
        },
        icon: DataTable,
        label: t('WixCode_TreeView_EditCollection'),
        automationId: 'edit-collection',
        id: 'edit-collection',
      });
      const actions = schemaActions[schema.id] || [];
      finalSections.push(
        ...actions.map((action: ContextMenuAction) => ({
          automationId: action.event,
          onClick: () => actionSelected(action.event),
          icon: action.icon,
          label: action.title,
          id: action.title,
          disabled: action.disabled,
        })),
      );
      setContextMenuSections({
        sections: [finalSections],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isExternalFolder,
    isFolder,
    showEditCollectionItem,
    showAddDynamicPageItem,
    schemaActions,
  ]);

  const contextMenu = (
    <div
      className="stop-propagation-bg"
      key="contextMenuContainer"
      onClick={onContextMenuClick}
    >
      <TreeContextMenu
        contextMenuStructure={{
          ...contextMenuSections,
          separator: <Divider long={true} />,
        }}
        contextMenuButton="corvid_tree__context_menu_more"
        menuClassName="context-menu-icon"
        className={cx('wix-code-file-tree-dd')}
      />
    </div>
  );

  const alwaysShowSuffix = isTreeHovered || loading;
  const loader = (
    <div className="preloader-container-hack">
      <Preloader dataHook={dataHooks.LOADER} className="tiny" />
    </div>
  );

  const userCreatedFields =
    schema &&
    Object.entries(schema.fields).filter(
      (field: any) => !field[1].systemField && !field[1].isDeleted,
    );

  const nodeSuffix = loading ? (
    loader
  ) : (
    <div className={s.suffixContainer}>
      <CopyButton
        description={t('WixCode_TreeView_Database_CopyCollectionId_Tooltip')}
        textToCopy={schema?.id}
        displayName={name}
        sectionName={biSectionName}
        itemType={bi.itemTypes.COLLECTION}
      />
      {contextMenu}
    </div>
  );

  const formsFolderWithPopover = (
    <Popover
      showArrow
      shown={shouldShowFormsTooltip || false}
      appendTo="window"
      placement="right"
      zIndex={10000}
      className={s.treeNode}
      dataHook={dataHooks.FORMS_POPVER}
    >
      <Popover.Element>
        <div id={FORMS_NAMESPACE} onClick={onNodeClick}>
          <TreeListItem
            className={s.treeNode}
            dataHook={dataHooks.TREE_NODE}
            label={name}
            onClick={() => {}}
            shouldTranslate={false}
            selected={shouldShowFormsTooltip}
            alwaysShowSuffix={alwaysShowSuffix}
            collapseState={isExpandedFolder ? 'expanded' : 'collapsed'}
            size="small"
            suffix={
              loading ? (
                loader
              ) : isExternalFolder ? (
                contextMenu
              ) : (
                <InfoIcon
                  shouldTranslate={false}
                  text={t('WixCode_TreeView_WixApp_Tooltip_Text', {
                    WixApp_name: name,
                  })}
                />
              )
            }
            depth={depth}
          />
        </div>
      </Popover.Element>
      <Popover.Content>
        <div className={s.popoverContent}>
          <Text
            enableEllipsis={false}
            size="large"
            weight="bold"
            skin="secondary"
          >
            {t('WixCode_TreeView_AppCollections_FormsMoveTooltip_Title')}
          </Text>
          <div style={{ marginBottom: '6px' }} />
          <Text
            enableEllipsis={false}
            size="small"
            skin="secondary"
            weight="thin"
          >
            {t('WixCode_TreeView_AppCollections_FormsMoveTooltip_Body')}
          </Text>
          <div style={{ marginBottom: '6px' }} />
          <TextButton
            dataHook={dataHooks.FORMS_POPOVER_CTA}
            size="small"
            onClick={() => {
              biLoggerAPI.report(
                leftTreeFormsCollectionsMoveAlert({
                  action: bi.actions.CLICK,
                  item_name: 'got_it',
                }),
              );
              setShouldShowFormsTooltip && setShouldShowFormsTooltip(false);
            }}
          >
            {t('WixCode_TreeView_AppCollections_FormsMoveTooltip_CTA')}
          </TextButton>
        </div>
      </Popover.Content>
    </Popover>
  );

  const node =
    name === FORMS_NAMESPACE ? (
      formsFolderWithPopover
    ) : isFolder ? (
      <TreeListItem
        dataHook={dataHooks.TREE_NODE}
        label={name}
        shouldTranslate={false}
        alwaysShowSuffix={alwaysShowSuffix}
        collapseState={isExpandedFolder ? 'expanded' : 'collapsed'}
        size="small"
        onClick={onNodeClick}
        suffix={
          loading ? (
            loader
          ) : isExternalFolder ? (
            contextMenu
          ) : (
            <InfoIcon
              shouldTranslate={false}
              text={t('WixCode_TreeView_WixApp_Tooltip_Text', {
                WixApp_name: name,
              })}
            />
          )
        }
        depth={depth}
      />
    ) : (
      <Tooltip
        disabled={!shouldHaveTooltip()}
        shouldTranslate={false}
        content={getTooltipTranslation()}
      >
        <div>
          <TreeListItem
            dataHook={dataHooks.TREE_NODE}
            label={
              <Tooltip
                maxWidth={500}
                content={
                  <LabelWithDescription displayName={name} id={schema.id} />
                }
              >
                <LabelWithDescription displayName={name} id={schema.id} />
              </Tooltip>
            }
            shouldTranslate={false}
            alwaysShowSuffix={alwaysShowSuffix}
            depth={depth}
            icon={getIcon()}
            size="small"
            suffix={nodeSuffix}
            onClick={onNodeClick}
            selected={isSelectedSchema}
            collapseState={
              userCreatedFields.length
                ? isExpanded
                  ? 'expanded'
                  : 'collapsed'
                : 'none'
            }
          />
        </div>
      </Tooltip>
    );

  const hasChildrenToRender = childItems && childItems.length > 0;

  return (
    <div
      data-hook={dataHooks.TREE_ROOT}
      onMouseEnter={() => onMouseHover(true)}
      onMouseLeave={() => onMouseHover(false)}
    >
      {node}
      {isExpandedFolder && (
        <ul
          data-aid="database-items"
          key="subtree"
          className={cx({
            'section-container': isTopLevelTreeRoot,
          })}
        >
          {hasChildrenToRender &&
            childItems.map((child) => (
              <li
                key={
                  child.isFolder
                    ? `folder-${child.namespace}`
                    : `schema-${child.schema.id}`
                }
              >
                <CollectionsTreeNode
                  editorAPI={editorAPI}
                  loading={loading}
                  onCreateCollection={onCreateCollection}
                  onCollectionSelected={onCollectionSelected}
                  onFolderToggle={onFolderToggle}
                  onContextMenuActionSelected={onContextMenuActionSelected}
                  schemaActions={schemaActions}
                  isTopLevelTreeRoot={false}
                  depth={isTopLevelTreeRoot ? 0 : depth! + 1}
                  nodeData={child}
                  util={util}
                  biSectionName={biSectionName}
                />
              </li>
            ))}
        </ul>
      )}
      {userCreatedFields &&
        isExpanded &&
        userCreatedFields.map((field) => (
          <TreeListItem
            label={
              <Tooltip
                maxWidth={500}
                content={
                  <LabelWithDescription
                    displayName={field[1].displayName}
                    id={field[0]}
                  />
                }
              >
                <LabelWithDescription
                  displayName={field[1].displayName}
                  id={field[0]}
                />
              </Tooltip>
            }
            depth={depth! + 1}
            size="small"
            icon={
              <Tooltip
                content={t('WixCode_TreeView_Database_FieldType_Tooltip', {
                  fieldType: field[1].type,
                })}
              >
                {SchemaFieldsIcons[field[1].type]}
              </Tooltip>
            }
            dataHook={dataHooks.SCHEMA_FIELD}
            disabled={true}
            suffix={
              <CopyButton
                description={t('WixCode_TreeView_Database_CopyFieldId_Tooltip')}
                textToCopy={field[0]}
                displayName={name}
                sectionName={biSectionName}
                itemType={bi.itemTypes.FIELD}
              />
            }
          />
        ))}
    </div>
  );
};
