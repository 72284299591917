import React from 'react';
import {
  EditorAPI,
  PanelsAPI,
  WixCodeAppAPI,
} from '@wix/wix-code-plugin-contracts';
import {
  BiLoggerAPI,
  PlatformAppsAPI,
} from '@wix/wix-code-plugin-contracts/src';
import { TranslationProvider } from '@wix/wix-code-common-components';

export type ExternalDbsModalContextType = {
  panelsAPI: PanelsAPI;
  biLoggerAPI: BiLoggerAPI;
  wixCodeAppAPI: WixCodeAppAPI;
  platformAppsAPI: PlatformAppsAPI;
  editorAPI: EditorAPI;
};

export const ExternalDbsModalContext: React.Context<ExternalDbsModalContextType> =
  React.createContext(null as any);

export const ExternalDbsModalContextProvider: React.FC<
  ExternalDbsModalContextType & { languageCode: string }
> = (props) => {
  return (
    <ExternalDbsModalContext.Provider value={{ ...props }}>
      <TranslationProvider
        options={{
          locale: props.languageCode,
          disableAutoInit: true,
          asyncMessagesLoader: (locale: string) =>
            import(`../../assets/locale/messages_${locale}.json`),
        }}
      >
        {props.children}
      </TranslationProvider>
    </ExternalDbsModalContext.Provider>
  );
};
