import React from 'react';
import {
  PredefinedSidebarTabs,
  TabProps,
} from '@wix/wix-code-plugin-contracts';
import FilesIcon from '../../icons/site_code.svg';
import { ProvidersWrapper, ProvidersWrapperAPIs } from './contexts';
import { genDetails } from '../../sideTabDefinitions';
import { BlocksFileTab } from '../../filesTree/BlocksFileTab';

export const createBlocksFilesTab = (
  apis: ProvidersWrapperAPIs,
  shouldShowTab: boolean,
  tabProps?: TabProps[PredefinedSidebarTabs.BLOCKS_CODE],
) => ({
  ...genDetails('PublicAndBackend', PredefinedSidebarTabs.BLOCKS_CODE),
  label: `Left_Tree_Components_Title_PublicAndBackend`,
  infoLinkText:
    tabProps?.translations?.HEADER_TOOLTIP_CONTENT_LEARN_MORE ||
    `Left_Tree_Components_Tooltip_PublicAndBackend_Link_Text`,
  infoLinkUrl:
    tabProps?.translations?.HEADER_TOOLTIP_CONTENT_LEARN_MORE_LINK ||
    'Left_Tree_Components_Tooltip_PublicAndBackendBlocks_Link_URL',
  infoText:
    tabProps?.translations?.HEADER_TOOLTIP_CONTENT ||
    `Left_Tree_Components_Title_PublicAndBackend_Tooltip`,
  symbol: <FilesIcon />,
  shouldShowTab: () => shouldShowTab,
  creator: () => (
    <ProvidersWrapper {...apis}>
      <BlocksFileTab {...tabProps} />
    </ProvidersWrapper>
  ),
});
