import { DealerReducerState } from './dealerReducer';

function getDealerData(
  state: { dealer: DealerReducerState },
  realEstateId: string,
) {
  return state.dealer[realEstateId];
}

export default {
  getDealerData,
};
