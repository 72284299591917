import { TbfConsolelReducerState } from '../reducers/tbfConsoleReducer';

function getMessages(
  state: { tbfConsole: TbfConsolelReducerState },
  id: string,
) {
  return state.tbfConsole[id] || [];
}

function hasMessages(
  state: { tbfConsole: TbfConsolelReducerState },
  id: string,
) {
  return !!state.tbfConsole[id];
}

export default {
  getMessages,
  hasMessages,
};
