// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RNDM_l{height:36px;margin-left:12px;position:relative}.RNDM_l .glewr3 *{fill:#fff}.mHoXN5{margin:0;position:absolute;top:50%;transform:translateY(-50%)}.glewr3{margin:0;position:absolute;top:80%;transform:translateY(-70%)}.WCofYD{padding:16px;display:flex;flex-direction:column}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "RNDM_l",
	"syncSuccess": "glewr3",
	"syncLoad": "mHoXN5",
	"tooltipContainer": "WCofYD"
};
export default ___CSS_LOADER_EXPORT___;
