import _ from 'lodash';
import { isReservedFileSystemName } from '@/sidebar/filesTree/fileNameValidator';
import { FileSubTreeDataSource } from '@/sidebar/filesTree/FileSubTree';
import { CodeReuseFiles, fetchCodeReuseFiles } from '../codeReuseServerAPI';
import { InstalledCodeReusePkg } from '../packagesModalContext';
import { consts } from '@wix/wix-code-consts';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';

const VISABLE_ROOTS = (translate: AnyFixMe) => [
  'public',
  'backend',
  translate('Package_Error_File_Name'),
  consts.README_FILE_NAME,
];
const HIDDEN_FILE_FULL_PATH = [
  '/backend/config.json',
  '/public/app-metadata.json',
];
const HIDDEN_FILE_NAMES = [
  'index.d.ts',
  'index.jsw',
  'package.json',
  'velo.json',
];

interface FileStructure {
  name: string;
  path: string;
  content?: string;
  children?: FileStructure[];
}

interface FetchResult {
  id: string;
  name: string;
  files: CodeReuseFiles[];
}

export const fetchFiles = async (
  pkgs: InstalledCodeReusePkg[],
  editorAPI: EditorAPI,
  translate: any,
): Promise<FetchResult[]> =>
  Promise.all(
    pkgs.map(async (pkg) => ({
      id: pkg.id,
      name: pkg.name,
      files: await fetchCodeReuseFiles(pkg, editorAPI, translate),
    })),
  );

export const toCodeReuseTree = (
  pkgName: string,
  files: CodeReuseFiles[],
  translate: (key: string) => string,
): FileSubTreeDataSource => {
  const contentTree = toTreeStructure(pkgName, files);
  const childItems = filterAndOrderChildren(
    contentTree.children?.map((child) => toFileSubTree(child, pkgName))!,
    contentTree.path,
    filterChildrenOnRoot(translate),
  );
  return toFileSubTree(contentTree, pkgName, childItems);
};

const toTreeStructure = (
  pkgName: string,
  files: CodeReuseFiles[],
): FileStructure => {
  const result = files.reduce((r, file) => {
    const names = file.path.split('/');
    const content = file.content;
    let path = '';

    // eslint-disable-next-line
    names.reduce((q: AnyFixMe, name: AnyFixMe) => {
      path += `/${name}`;
      if (name.indexOf('.') > 0) {
        q.push({ name, content, path });
      } else {
        let temp = q.find((o: AnyFixMe) => o.name === name);
        if (!temp) {
          q.push((temp = { name, children: [], path }));
        }
        return temp.children;
      }
    }, r);
    return r;
  }, [] as FileStructure[]);

  return { name: pkgName, path: '', children: result };
};

const filterChildrenOnSubTrees = (
  parentId: string,
  child: FileSubTreeDataSource,
) => {
  const isEmptyFolder = child.isFolder && child.childItems.length === 0;
  return (
    isEmptyFolder ||
    isReservedFileSystemName({
      parentId,
      name: child.name,
      publicRootId: '/public',
      backendRootId: '/backend',
      hiddenFiles: HIDDEN_FILE_FULL_PATH,
    }) ||
    isReservedCodeReuseFile(child.name)
  );
};

const isReservedCodeReuseFile = (childName: string) =>
  HIDDEN_FILE_NAMES.includes(childName);

const filterAndOrderChildren = (
  childItems: FileSubTreeDataSource[],
  subTreeRootId: string,
  filterChildren: (parentId: string, child: FileSubTreeDataSource) => boolean,
): FileSubTreeDataSource[] => {
  return _(childItems)
    .reject((child) => filterChildren(subTreeRootId, child))
    .orderBy(['isFolder', 'name'], ['desc', 'asc'])
    .value();
};

const filterChildrenOnRoot =
  (translate: AnyFixMe) =>
  (_parentId: string, child: FileSubTreeDataSource) => {
    const isEmptyFolder = child.isFolder && child.childItems.length === 0;
    return isEmptyFolder || !VISABLE_ROOTS(translate).includes(child.name);
  };

const toFileSubTree = (
  contentTree: FileStructure,
  packageName: string,
  children?: FileSubTreeDataSource[],
): FileSubTreeDataSource => {
  const childItems =
    children ||
    filterAndOrderChildren(
      contentTree.children?.map((child) =>
        toFileSubTree(child, packageName),
      )! /* strictNullChecks */,
      contentTree.path,
      filterChildrenOnSubTrees,
    );

  return {
    id: `${packageName}${contentTree.path}`,
    name: contentTree.name,
    pendingRename: '',
    isFolder: !contentTree.content && contentTree.name.indexOf('.') === -1,
    childItems,
    loading: false,
    expanded: false,
    pendingCreation: false,
    pendingDelete: false,
  };
};
