/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { RichText } from '@wix/wix-base-ui';

import Illustration from './emptyStateIllustration.svg';
import { TFunction } from '@wix/wix-i18n-config';
import dataHooks from './dataHooks';
import { ideToolbarAction } from '@wix/bi-logger-platform/v2';
import { useBiLogger } from '@wix/wix-code-common-components';

type PageElementsEmptyStateProps = {
  openHelpPanel: (id: string, options?: Record<string, any>) => void;
  t: TFunction;
};

const PageElementsEmptyState = ({
  openHelpPanel,
  t,
}: PageElementsEmptyStateProps) => {
  const biLoggerAPI = useBiLogger();

  return (
    <div
      data-aid="empty-state"
      data-hook={dataHooks.container}
      className="empty-state"
    >
      <Illustration />
      <RichText className="text-container">
        <p data-aid="description">
          {t('WixCode_PropertiesAndEventsPanel_EmptyState_Body')}
        </p>
        <a
          data-aid="help-link"
          onClick={() => {
            openHelpPanel('2ec37118-35d1-4068-a491-7640c1481aa1');
            biLoggerAPI.report(
              ideToolbarAction({ item_name: 'properties_events_learn_more' }),
            );
          }}
        >
          {t('WixCode_PropertiesAndEventsPanel_EmptyState_Link_Text')}
        </a>
      </RichText>
    </div>
  );
};

export default PageElementsEmptyState;
