import { SpiConnection } from '../ServerAPI';
import { types } from './actions';

export type State = SpiConnection[];

export const reducer = (state: State = [], action: any) => {
  switch (action.type) {
    case types.UPDATE_SPI_CONNECTIONS:
      return action.payload;
    case types.DELETE_SPI_CONNECTION:
      return state.filter(
        (status) => status.path!.slice(1) + '/' !== action.payload,
      );
    default:
      return state;
  }
};

export const getConnections = (state: State) => state;
