import React from 'react';
import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { ResourcesTab } from '../components/resources/resourcesTab/ResourcesTab';
import { BiLoggerContext } from '@wix/wix-code-common-components';

export const ResourcesEntryPoint: EntryPoint = {
  name: 'Knowledge Base Resources Entry Point',
  getDependencyAPIs() {
    return [
      APIKeys.MenuAPI,
      APIKeys.MenuPanelAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.PanelsAPI,
    ];
  },
  extend(shell: Shell) {
    const menuAPI = shell.getAPI(APIKeys.MenuAPI);
    const menuPanelAPI = shell.getAPI(APIKeys.MenuPanelAPI);
    const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
    const panelsAPI = shell.getAPI(APIKeys.PanelsAPI);

    menuPanelAPI.contributeTab({
      id: 'help',
      TabContent: ({ closePanel }) => (
        <BiLoggerContext.Provider value={biLoggerAPI}>
          <ResourcesTab closePanel={closePanel} panelsAPI={panelsAPI} />
        </BiLoggerContext.Provider>
      ),
    });

    menuAPI.registerMenuItemListener('help', () => {
      menuPanelAPI.selectTab('help');
    });
  },
};
