import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';

import { createExperimentsAPI } from './ExperimentsAPI';

export const ExperimentsEntryPoint: EntryPoint = {
  name: 'Experiments Entry Point',
  getDependencyAPIs() {
    return [APIKeys.LegacyEditorDependencies];
  },
  declareAPIs() {
    return [APIKeys.ExperimentsAPI];
  },
  attach(shell: Shell) {
    const legacyEditorDependencies = shell.getAPI(
      APIKeys.LegacyEditorDependencies,
    );
    shell.contributeAPI(APIKeys.ExperimentsAPI, () =>
      createExperimentsAPI({
        legacyEditorDependencies,
      }),
    );
  },
};
