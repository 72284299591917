import { EditorAPI } from '@wix/wix-code-plugin-contracts';

export type DELETE_FILE_TYPE = 'File' | 'Folder' | 'Npm' | 'Velo' | 'Private';

export interface DeleteFileSystemItemWarningPanelProps {
  panelName: string;
  onConfirm: () => void;
  onCancel: () => void;
  fileType: DELETE_FILE_TYPE;
  fileName: string;
}

export const openDeleteFileSystemItemWarningPanel = (
  editorAPI: EditorAPI,
  props: DeleteFileSystemItemWarningPanelProps,
  leavePanelsOpen?: boolean,
) =>
  editorAPI.panelManager.openPanel(
    'wixCode.panels.deleteFileSystemItemWarningPanel',
    props,
    leavePanelsOpen,
  );
