import React, { useContext } from 'react';
import {
  NpmPackageInfo,
  PackagesModalContext,
} from '../../../../../packagesModalContext';
import { TextButton, Tooltip, Text } from '@wix/wix-base-ui';
import dataHooks from '@/sidebar/dataHooks';

interface RequestNpmPkgLabelProps {
  availablePkg: NpmPackageInfo;
  disabled?: boolean;
}

export const RequestNpmPkgLabel: React.FC<RequestNpmPkgLabelProps> = ({
  availablePkg,
  disabled,
}) => {
  const { t, packagesService } = useContext(PackagesModalContext);
  const openKbArticle = () =>
    window.open(
      t('Packages_Modal_Npm_Request_Pkg_Label_Tooltip_Link_Url'),
      '_blank',
    );

  return (
    <Tooltip
      interactive={true}
      content={
        <div>
          <Text enableEllipsis={false} size="small">
            {t('Packages_Modal_Npm_Request_Pkg_Label_Tooltip')}
          </Text>
          <TextButton size="small" onClick={openKbArticle}>
            {t('Packages_Modal_Npm_Request_Pkg_Label_Tooltip_Link_Text')}
          </TextButton>
        </div>
      }
      maxWidth={400}
    >
      <TextButton
        dataHook={dataHooks.REQUEST_NPM_PKG_LABEL}
        onClick={() => packagesService.requestPackageWithBi(availablePkg)}
        size="small"
        disabled={disabled}
      >
        {t('Packages_Modal_Npm_Request_Pkg_Label')}
      </TextButton>
    </Tooltip>
  );
};
