// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tZWfYg{height:100%;overflow-y:auto}.v1LMkH{border-top:1px solid #dfe5eb;border-bottom:1px solid #dfe5eb}.CoVywu{position:absolute;right:20px;bottom:20px}.ASrnS1{display:flex;justify-content:center;align-items:center;margin-top:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scroller": "tZWfYg",
	"title-container": "v1LMkH",
	"titleContainer": "v1LMkH",
	"info-icon": "CoVywu",
	"infoIcon": "CoVywu",
	"loader-container": "ASrnS1",
	"loaderContainer": "ASrnS1"
};
export default ___CSS_LOADER_EXPORT___;
