import React, { useEffect, useRef } from 'react';
import { APIKeys, EntryPoint } from '@wix/wix-code-repluggable';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import packagesServiceCreator from './packagesService';
import {
  experimentUtils,
  fileSystem as fileSystemCommon,
  monitoring,
} from '@wix/wix-code-common';
import dataHooks from './dataHooks';
import { MarkdownViewerLazy } from '@/codeEditor/components/markdown/markdownViewerLazy';
import { useMarkdownSavingScroll } from '@/codeEditor/components/markdown/useMarkdownSavingScroll';
import ideTabsActions from '@/codeEditor/tabs/actions/ideTabsActions';
import storeManager from '@/infra/redux-state/store/storeManager';
import ideTabsStateReader from '@/codeEditor/tabs/selectors/ideTabsStateReader';
import { createPackagesAPI } from './packagesAPI';

export const PackagesEntryPoint: EntryPoint = {
  name: 'Packages Entry Point',
  getDependencyAPIs() {
    return [
      APIKeys.CodeEditorAPI,
      WixCodeEditorAdapterAPI,
      APIKeys.ClassicEditorAPI,
      APIKeys.WixCodeStoreAPI,
      APIKeys.WixCodeDuplexerAPI,
      APIKeys.ExperimentsAPI,
      APIKeys.WixCodeStoreAPI,
      APIKeys.DuplexerAPI,
      APIKeys.PanelsAPI,
    ];
  },
  async extend(shell) {
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
    const codeEditorAPI = shell.getAPI(APIKeys.CodeEditorAPI);
    const wixCodeDuplexerAPI = shell.getAPI(APIKeys.WixCodeDuplexerAPI);
    const { legacyDependencies } = shell.getAPI(WixCodeEditorAdapterAPI);
    const duplexerAPI = shell.getAPI(APIKeys.DuplexerAPI);
    const panelsAPI = shell.getAPI(APIKeys.PanelsAPI);
    const wixCodeStoreAPI = shell.getAPI(APIKeys.WixCodeStoreAPI);

    const packagesAPI = createPackagesAPI(
      wixCodeDuplexerAPI,
      panelsAPI,
      wixCodeStoreAPI,
      legacyDependencies.util.translate,
      editorAPI,
    );
    if (experimentUtils.isAnyNpmFirstPhase()) {
      packagesAPI.subscribeToDependenciesChanged();
    }

    const packagesService = packagesServiceCreator({
      experiment: legacyDependencies.experiment,
      constants: legacyDependencies.constants,
      stateManagement: legacyDependencies.stateManagement,
      translate: legacyDependencies.util.translate,
      editorAPI,
      packagesAPI,
      wixCodeStoreAPI,
      duplexerAPI,
      panelsAPI,
    });

    const { dispatch, packageJsonActions } = wixCodeStoreAPI;
    await dispatch(packageJsonActions.expandRootNodeModules());
    try {
      await packagesService.preloadCodeReuseFiles();
      packagesService.registerToCodeReuseEvents();
    } catch (error) {
      monitoring.captureError(error);
    }

    if (experimentUtils.isAnyNpmFirstPhase()) {
      packagesAPI.subscribeToDependenciesChanged();
    }

    codeEditorAPI.contributeTabRenderer(shell, PackageMarkdown, () =>
      fileSystemCommon.codePackages.isPackageMarkdown(getContextId()),
    );

    function PackageMarkdown({ codeEditorProps }: { codeEditorProps: any }) {
      const fileContent = packagesService.getPkgFileContent(getContextId());

      const markdownRef = useRef<HTMLDivElement>(null);
      const { onMarkdownScroll, setMarkdownScroll } = useMarkdownSavingScroll({
        markdownRef,
        storeTabScrollState,
        focusIde: codeEditorProps.focusIde,
        context: codeEditorProps.context,
        scrollTop: ideTabsStateReader.getTabScroll(
          storeManager.getStore().getState(),
          getContextId(),
        ),
      });

      useEffect(() => {
        setMarkdownScroll();
      }, [
        codeEditorProps.additionalSidePane,
        codeEditorProps.context,
        codeEditorProps.readOnlyMode,
        codeEditorProps.dependencies,
        setMarkdownScroll,
      ]);
      return (
        <div
          className="markdown-wrapper"
          data-hook={dataHooks.MARKDOWN_WRAPPER}
          ref={markdownRef}
          onScroll={onMarkdownScroll}
        >
          <div
            className="markdown-inner-wrapper"
            data-hook={dataHooks.MARKDOWN_CONTENT}
          >
            <MarkdownViewerLazy>{fileContent}</MarkdownViewerLazy>
          </div>
        </div>
      );
    }

    function storeTabScrollState(action: { scroll: number; tabId: string }) {
      dispatch(ideTabsActions.storeTabScrollState(action));
    }

    function getContextId() {
      return editorAPI.developerMode.getContext().data?.id;
    }
  },
};
