import React, { useContext } from 'react';
import _ from 'lodash';
import { platformApps, monitoring } from '@wix/wix-code-common';
import {
  leftTreeClickInMenuPlusOptionSection,
  leftTreeClickOnASection,
  leftTreeClickOnPlusIcon,
} from '@wix/bi-logger-platform/v2';
import { TreeSectionDivider } from '@wix/wix-base-ui';
import AddPageIcon from '../assets/icons/contextMenu/addPage.svg';
import RouterIcon from '../assets/icons/contextMenu/router.svg';
import { StaticPage } from './StaticPage';
import {
  ReadOnlyModeContext,
  TreeContextMenu,
} from '@wix/wix-code-common-components';
import { PagesTabContext } from './PagesTabContext';
import dataHooks from '../dataHooks';
import { PageTreeContext } from './PageTreeContext';

type Item = {
  id: string;
  title: string;
};

interface StaticPagesTreeProps {
  title: string;
  items: Item[];
  showGroupOptions?: boolean;
}

const { withErrorHandling } = monitoring;
export const StaticPagesTree = ({
  title,
  items,
  showGroupOptions,
}: StaticPagesTreeProps) => {
  const { setPendingRename } = useContext(PageTreeContext);
  const { readOnlyMode } = useContext(ReadOnlyModeContext);
  const {
    editorAPI,
    bi,
    legacyDependenciesAPI: { util },
  } = useContext(PagesTabContext);
  const [collapsed, setCollapsedState] = React.useState(false);
  const [isContextMenuOpen, setContextMenuOpen] = React.useState(false);
  const [isTreeHovered, setTreeHovered] = React.useState(false);

  const ACTIONS = {
    ADD_CUSTOM_ROUTER: 'addCustomRouter',
    ADD_PAGE: 'addPageClicked',
  };

  const onNodeClick = () =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickOnASection({
          section_name: 'pages',
          action: collapsed ? 'open' : 'close',
        }),
      );
      setCollapsedState(!collapsed);
    })();

  const handleContextMenuToggle = setContextMenuOpen;

  const onContextMenuClick = (event: AnyFixMe) =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickOnPlusIcon({
          section_name: 'pages',
        }),
      );

      event.stopPropagation();
    })();

  const shouldShowContextMenu = () => !isMobileViewMode() && showGroupOptions;

  const isMobileViewMode = () => {
    const viewMode = editorAPI.dsRead.viewMode;
    return viewMode.get() === viewMode.VIEW_MODES.MOBILE;
  };

  const actionSelectedHandler = (action: AnyFixMe) =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickInMenuPlusOptionSection({
          section_name: 'pages',
          menu_entry_name: action,
        }),
      );
      switch (action) {
        case ACTIONS.ADD_CUSTOM_ROUTER: {
          platformApps.notifyWixCode(editorAPI, {
            eventType: 'addDynamicPageClicked',
            eventPayload: {
              publicUrl: editorAPI.dsRead.generalInfo.getPublicUrl(),
              origin: 'idePagesTree',
            },
          });
          return;
        }
        case ACTIONS.ADD_PAGE: {
          const NEW_PAGE_TITLE_ID = util.translate(
            'WixCode_Pages_Menu_Add_New_Page',
          );
          const newPage = editorAPI.pages.add(NEW_PAGE_TITLE_ID);
          if (newPage) {
            setCollapsedState(false);
            editorAPI.history.add('adding new page', {
              actionType: 'addPage',
              nextPage: newPage.id,
            });
            setPendingRename(newPage.id, true);
            editorAPI.pages.navigateTo(newPage.id);
          }
          return;
        }
        default: {
          // eslint-disable-next-line no-console
          console.warn(
            `Unknown ContextMenuAction selected on StaticPagesRoot: ${action}`,
          );
        }
      }
    })();

  const onMouseHover = setTreeHovered;

  const addPageDisabled = !editorAPI.pages.canAddStaticPage();

  const alwaysShowSuffix = isContextMenuOpen || isTreeHovered;

  const contextMenuSections = {
    sections: [
      [
        {
          onClick: () => actionSelectedHandler(ACTIONS.ADD_PAGE),
          icon: AddPageIcon,
          label: util.translate('WixCode_NewPages_Panel_AddPage_Button'),
          automationId: 'action-add-new-page',
          disabled: addPageDisabled,
        },
        {
          onClick: () => actionSelectedHandler(ACTIONS.ADD_CUSTOM_ROUTER),
          icon: RouterIcon,
          label: util.translate(
            'WixCode_PLATFORM_ROUTING_SITEMENU_PAGES_ADD_ROUTER',
          ),
          automationId: 'action-new-custom-routing-url',
        },
      ],
    ],
  };

  const contextMenu = (
    <div
      className="stop-propagation-bg"
      key="contextMenuContainer"
      onClick={onContextMenuClick}
    >
      <TreeContextMenu
        contextMenuStructure={contextMenuSections}
        contextMenuButton="corvid_tree__context_menu_add"
        handleContextMenuToggle={handleContextMenuToggle}
        isContextMenuOpen={isContextMenuOpen}
        menuClassName="context-menu-icon"
        className="stop-propagation-bg"
        tooltipContent={util.translate(
          'Velo_Concurrent_Editing_Sidebar_ReadOnly_Text',
        )}
        readonly={!!readOnlyMode.sidePanel?.pages}
      />
    </div>
  );
  return (
    <div
      data-hook={dataHooks.TREE_ROOT}
      onMouseEnter={() => onMouseHover(true)}
      onMouseLeave={() => onMouseHover(false)}
    >
      <div data-aid="pages-section">
        <TreeSectionDivider
          dataHook={dataHooks.SECTION_DIVIDER}
          alwaysShowSuffix={alwaysShowSuffix}
          label={title}
          collapsed={collapsed}
          hideTopBorder={true}
          hideBottomBorder={collapsed}
          onClick={onNodeClick}
          suffix={shouldShowContextMenu() && contextMenu}
          size="small"
        />
      </div>

      {!collapsed && (
        <ul data-aid="pages-items" key="subtree" className="section-container">
          {items.map((child: Item) => (
            <li key={child.id}>
              <StaticPage id={child.id} title={child.title} depth={0} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
