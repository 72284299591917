import coreUtilsLib from '@wix/santa-core-utils';
import normalizeUrl from 'normalize-url';

const isValidURL = (url: string) => {
  try {
    new URL(url);

    return true;
  } catch {
    return false;
  }
};

// currently code-code doesn't include its css/styles in its bundle
// we use this function so we can load its css files dynamically..
// https://jira.wixpress.com/browse/VELO-5449
export const injectWixCodeCodeEditorStyles = ({ util }: { util: any }) => {
  let codeCodeCSSScriptsURL = `${util.serviceTopology.scriptsLocationMap['wix-code-code-editor']}/static/css/codeEditor.css`;
  if (isValidURL(codeCodeCSSScriptsURL)) {
    codeCodeCSSScriptsURL = normalizeUrl(codeCodeCSSScriptsURL);
  }

  coreUtilsLib.cssUtils.addStylesheetOfUrl(codeCodeCSSScriptsURL);
};
