import React, { createContext, useContext } from 'react';
import {
  LegacyEditorDependencies,
  WixCodeStoreAPI,
  ReadOnlyAPI,
  WixCodeDuplexerAPI,
  DuplexerAPI,
  PanelsAPI,
  PackagesAndAppsAPI,
} from '@wix/wix-code-plugin-contracts';
import { Provider, createSelectorHook } from 'react-redux';
import {
  EditorLegacyAPIsContextProvider,
  ReadOnlyModeContext,
  TranslationProvider,
  wixCodeReduxContext,
} from '@wix/wix-code-common-components';

export type APIs = {
  legacyDependenciesAPI: LegacyEditorDependencies;
};

export type InternalAPIs = {
  wixCodeStoreAPI: WixCodeStoreAPI;
  readOnlyAPI: ReadOnlyAPI;
  wixCodeDuplexerAPI: WixCodeDuplexerAPI;
  duplexerAPI: DuplexerAPI;
  panelsAPI: PanelsAPI;
  packagesAndAppsAPI: PackagesAndAppsAPI;
};

export type ProvidersWrapperAPIs = APIs & InternalAPIs;

type ProvidersWrapperProps = ProvidersWrapperAPIs;

export const InternalAPIsContext = createContext<InternalAPIs>(null as any);

export const useInternalAPIs = () => {
  return useContext(InternalAPIsContext);
};

export const useSelector = createSelectorHook(wixCodeReduxContext);

export const ProvidersWrapper: React.FC<ProvidersWrapperProps> = ({
  wixCodeStoreAPI,
  legacyDependenciesAPI,
  readOnlyAPI,
  children,
  wixCodeDuplexerAPI,
  duplexerAPI,
  panelsAPI,
  packagesAndAppsAPI,
}) => {
  const readOnlyMode = readOnlyAPI.getReadOnlyMode();
  return (
    <Provider
      store={wixCodeStoreAPI.getStore()}
      context={wixCodeStoreAPI.wixCodeReduxContext}
    >
      <ReadOnlyModeContext.Provider value={{ readOnlyMode }}>
        <EditorLegacyAPIsContextProvider
          legacyDependencies={legacyDependenciesAPI}
        >
          <InternalAPIsContext.Provider
            value={{
              wixCodeStoreAPI,
              readOnlyAPI,
              wixCodeDuplexerAPI,
              duplexerAPI,
              panelsAPI,
              packagesAndAppsAPI,
            }}
          >
            <TranslationProvider
              options={{
                locale: legacyDependenciesAPI.util.editorModel.languageCode,
                disableAutoInit: true,
                asyncMessagesLoader: (locale: string) =>
                  import(`../../../assets/locale/messages_${locale}.json`),
              }}
            >
              {children}
            </TranslationProvider>
          </InternalAPIsContext.Provider>
        </EditorLegacyAPIsContextProvider>
      </ReadOnlyModeContext.Provider>
    </Provider>
  );
};
