export enum LogLevel {
  VERBOSE = 'VERBOSE',
  LOG = 'LOG',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  TIP = 'TIP',
}

export const LOG_LEVEL = {
  VERBOSE: 'VERBOSE',
  LOG: 'LOG',
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  TIP: 'TIP',
};

const BACKEND_SEVERITY_TO_CLIENT_LOG_LEVEL = {
  error: LOG_LEVEL.ERROR,
  warn: LOG_LEVEL.WARNING,
  info: LOG_LEVEL.INFO,
  log: LOG_LEVEL.LOG,
  verbose: LOG_LEVEL.VERBOSE,
};

export default {
  LOG_LEVEL,
  BACKEND_SEVERITY_TO_CLIENT_LOG_LEVEL,
  WIX_CODE_MESSAGE_INTENT: 'WIX_CODE',
  WIX_CODE_MESSAGE_TYPE: 'console',
  ORIGIN_TYPES: {
    PAGE_CODE: 'PageCode',
    SITE_CODE: 'SiteCode',
    PUBLIC_FILE: 'PublicFile',
    BACKEND_FILE: 'BackendFile',
  },
  ORIGIN_CALCULATION_RESULT: {
    NO_USER_CODE_FRAME: 'NO_USER_CODE_FRAME',
    ORIGIN_NOT_FOUND: 'ORIGIN_NOT_FOUND',
    ORIGIN_FOUND: 'ORIGIN_FOUND',
    SERVER_FAILED_CREATING_SOURCE_MAP: 'SERVER_FAILED_CREATING_SOURCE_MAP',
    ERROR: 'ERROR',
  },
};
