import { ActionType } from '../actions/types';
import constants from '@/legacy/core/components/ideTree/constants';
import { tabs } from '@/sidebar/tabs';

type LeftPaneReducerState = {
  viewMode: any;
  selectedTab: any;
};

type SetViewMode = {
  viewMode: typeof constants.VIEW_MODE;
  type: ActionType.SET_LEFT_PANE_VIEW_MODE;
};
type SelectTab = {
  tabId: string;
  type: ActionType.SELECT_LEFT_PANE_TAB;
};
type SuggestTab = {
  tabId: string;
  type: ActionType.SUGGEST_LEFT_PANE_TAB;
};
type LeftPaneReducerAction = SetViewMode | SelectTab | SuggestTab;

const initialState: LeftPaneReducerState = {
  viewMode: constants.VIEW_MODE.SITE_STRUCTURE,
  selectedTab: null,
};

const leftPaneReducer = (
  state = initialState,
  action: LeftPaneReducerAction,
) => {
  switch (action.type) {
    case ActionType.SET_LEFT_PANE_VIEW_MODE:
      return {
        ...state,
        viewMode: action.viewMode,
      };
    case ActionType.SELECT_LEFT_PANE_TAB:
      return {
        ...state,
        selectedTab: action.tabId,
      };
    case ActionType.SUGGEST_LEFT_PANE_TAB:
      if (state.selectedTab === tabs.SEARCH) {
        return { ...state };
      }
      return {
        ...state,
        selectedTab: action.tabId,
      };
    default:
      return state;
  }
};

export default leftPaneReducer;
