import React from 'react';
import _ from 'lodash';
import { utilsCreator as wixCodeUtilsCreator } from '@wix/wix-code-common';
import createElementEventsSection from './elementEventsSection';
import { ElementConfigContainerComp } from './ElementConfigContainerComp';
import { componentsDataService } from '../service/componentsDataService';
import {
  EditorAPI,
  LegacyEditorDependencies,
} from '@wix/wix-code-plugin-contracts';
import type { CompRef } from '@wix/document-services-types';

type ElementConfigContainerProps = {
  legacyDependencies: LegacyEditorDependencies;
};

export default _.once(({ legacyDependencies }: ElementConfigContainerProps) => {
  const { experiment, platform, stateManagement, util } = legacyDependencies;
  const {
    connect: editorConnect,
    STORES: { EDITOR_API },
  } = util.hoc;

  const t = util.translate;

  const wixCodeUtils = wixCodeUtilsCreator({ experiment, platform, util });

  const ElementEventsSection = createElementEventsSection({
    legacyDependencies,
  });

  const { findCurrentSlide } =
    stateManagement.editorPlugins.boxSlideShow.selectors;

  const VALIDATION_MSGS: any = {
    ALREADY_EXISTS: 'WixCode_PropertiesPanel_ID_Validation_NotUnique',
    TOO_SHORT: 'WixCode_PropertiesPanel_ID_Validation_Empty',
    TOO_LONG: 'WixCode_PropertiesPanel_ID_Validation_TooLong',
    INVALID_NAME: 'WixCode_PropertiesPanel_ID_Validation_InvalidName',
  };

  const getInnerStateComponent = (editorAPI: EditorAPI, outerCompRef: any) => {
    if (!outerCompRef) {
      return null;
    }
    if (editorAPI.components.is.exposesSlideAsInnerComponent(outerCompRef)) {
      return findCurrentSlide(editorAPI.dsRead, outerCompRef);
    }

    return null;
  };

  const nicknameValidator = (
    editorAPI: EditorAPI,
    component: any,
    newName: string,
  ) => {
    const codeAPI = editorAPI.components.code;

    const validationResult = codeAPI.validateNickname(component, newName);
    if (validationResult === codeAPI.VALIDATIONS.VALID) {
      return { valid: true };
    } else {
      return {
        valid: false,
        message: util.translate(VALIDATION_MSGS[validationResult]),
      };
    }
  };

  const ElementConfigContainer = (props: any) => (
    <ElementConfigContainerComp
      wixCodeUtils={wixCodeUtils}
      t={t}
      ElementEventsSection={ElementEventsSection}
      experiment={experiment}
      {...props}
    />
  );

  const mapStateToProps = (
    { editorAPI }: { editorAPI: EditorAPI },
    {
      compRef,
      selectedFileId,
      openMasterPage,
    }: { compRef: CompRef; selectedFileId: string; openMasterPage: () => void },
  ) => {
    const compType = compRef
      ? wixCodeUtils.getCompTypeOrControllerType(editorAPI, compRef)
      : null;

    const sdkType = compRef
      ? wixCodeUtils.getSdkTypeOrControllerType(editorAPI, compRef)
      : null;

    const innerStateCompRef = getInnerStateComponent(editorAPI, compRef);
    const componentProperties = editorAPI.components.properties.get(compRef);
    const isCompVisible = !_.get(componentProperties, 'isHidden');
    const isCompHiddenable = compRef
      ? editorAPI.components.is.hiddenable(compRef)
      : false;
    const isCompCollapsible = compRef
      ? editorAPI.components.is.collapsible(compRef)
      : false;
    const isCompCollapsed = Boolean(_.get(componentProperties, 'isCollapsed'));
    const isCompEnabled = !_.get(componentProperties, 'isDisabled');
    const isCompDisableable = compRef
      ? editorAPI.components.is.disableable(compRef)
      : false;
    const savedBehaviors = compRef ? editorAPI.behaviors.get(compRef) : null;

    const isComponentVisibleOnAllPages =
      editorAPI.components.isShowOnAllPages(compRef);

    const pageRef = editorAPI.dsRead.components.getPage(compRef);
    const isBlocksDashboard =
      editorAPI.dsRead.appStudio?.dashboardPages?.isDashboardPage?.(pageRef);

    const eventList =
      sdkType && !isBlocksDashboard
        ? editorAPI.wixCode.events.getEvents(sdkType)
        : null;

    const apiReference = sdkType
      ? componentsDataService.getComponentApiReference(sdkType)
      : null;
    const codeAPI = editorAPI.components.code;

    return {
      // selected component
      compRef,
      compType,
      sdkType,
      compNickname: editorAPI.components.code.getNickname(compRef),
      setNickname: (newName: string) => codeAPI.setNickname(compRef, newName),
      validateNickname: (newName: string) =>
        nicknameValidator(editorAPI, compRef, newName),
      isGroup: editorAPI.components.is.group(compRef),

      // inner state component (multistate box)
      innerStateCompRef,
      innerStateCompType: innerStateCompRef
        ? wixCodeUtils.getCompTypeOrControllerType(editorAPI, innerStateCompRef)
        : null,
      innerStateSdkType: innerStateCompRef
        ? wixCodeUtils.getSdkTypeOrControllerType(editorAPI, innerStateCompRef)
        : null,
      innerStateCompNickname:
        innerStateCompRef &&
        editorAPI.components.code.getNickname(innerStateCompRef),
      setInnerStateNickname: (newName: string) =>
        codeAPI.setNickname(innerStateCompRef, newName),

      validateInnerStateNickname: (newName: string) =>
        nicknameValidator(editorAPI, innerStateCompRef, newName),

      // component properties
      isCompCollapsed,
      isCompCollapsible,
      toggleCompCollapsiblity: () => {
        editorAPI.components.properties.update(compRef, {
          isCollapsed: !isCompCollapsed,
        });
      },

      isCompEnabled,
      isCompDisableable,
      toggleCompEnability: () => {
        editorAPI.components.properties.update(compRef, {
          isDisabled: isCompEnabled,
        });
      },

      isCompVisible,
      isCompHiddenable,
      isComponentVisibleOnAllPages,
      toggleCompVisibility: () => {
        editorAPI.components.properties.update(compRef, {
          isHidden: isCompVisible,
        });
      },
      openHelpPanel: (helpArticleId: any, options?: Record<string, any>) =>
        editorAPI.panelManager.openHelpCenter(helpArticleId, options),

      // events
      eventList,
      savedBehaviors,

      apiReference,

      // bi
      sendBi: (event: any) => editorAPI.bi.event(event.def, event.params),
      siteId: editorAPI.dsRead.generalInfo.getSiteId(),

      selectedFileId,
      openMasterPage,
    };
  };

  const withEditorAPI = (component: any) => {
    const connected = (editorConnect as any)(
      EDITOR_API,
      mapStateToProps,
    )(component);
    connected.pure = component;
    return connected;
  };

  return withEditorAPI(ElementConfigContainer);
});
