// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c5r2_h{background-color:#f7f8f8;height:44px;line-height:44px;text-align:center;color:#3b4057;vertical-align:middle}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-title": "c5r2_h",
	"sectionTitle": "c5r2_h"
};
export default ___CSS_LOADER_EXPORT___;
