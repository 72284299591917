import React, { useContext, useState } from 'react';
import bi from '@/legacy/bi/bi';
import PackageIcon from '@/assets/icons/leftTree/npm.svg';
import baseUILib from '@wix/wix-base-ui';
import dataHooks from '../../../dataHooks';
import devContextUtilsCreator from '@/utils/devContext';
import {
  getNpmReadmePath,
  isInstallationInProgress,
  npmPackagesNameFormat,
} from '@/toExtract/packages/utils';
import {
  ReadOnlyModeContext,
  TreeContextMenu,
  useTranslation,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';
import { PkgTreeContext } from '../PkgTreeContext';
import { CODE_EDITOR_MODE } from '@wix/wix-code-plugin-contracts';
import { ExternalLink, Delete } from '@wix/wix-ui-icons-common/classic-editor';
import { experimentUtils } from '@wix/wix-code-common';

enum QUICK_ACTIONS {
  UNINSTALL = 'UNINSTALL',
}

interface NpmPkgItemProps {
  packageName: string;
  packageVersion: string;
  nodeDoubleClick: (id: string) => void;
  codeEditorMode: CODE_EDITOR_MODE;
}

export const NpmPkgItem: React.FC<NpmPkgItemProps> = ({
  packageName,
  nodeDoubleClick,
  codeEditorMode,
  packageVersion,
}) => {
  const [t] = useTranslation();
  const { onOpenRemoveNpmPackage } = useContext(PkgTreeContext);
  const {
    editorAPI,
    legacyDependenciesAPI: { stateManagement, constants },
  } = useEditorLegacyAPIs();
  const { readOnlyMode } = useContext(ReadOnlyModeContext);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const selectedIdeTabId = editorAPI.developerMode.getContext()?.data.id;
  const { setFileContext } = devContextUtilsCreator({ constants });

  const onContextMenuClick = (event: AnyFixMe) => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_SETTINGS, {
      item_name: packageName, // todo -packageName? originaly this is id - butid was not sent in props!!
      item_type: 'NPM',
    });
    event.stopPropagation();
  };

  const uninstallPackage = () => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_SETTINGS_ITEM, {
      item_name: packageName,
      item_type: 'NPM',
      menu_entry_name: QUICK_ACTIONS.UNINSTALL,
    });
    return onOpenRemoveNpmPackage(packageName);
  };

  const readMore = () => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_SETTINGS_ITEM, {
      item_name: packageName,
      item_type: 'NPM',
      menu_entry_name: 'see_documentation',
    });
    window.open(
      `https://www.npmjs.com/package/${encodeURIComponent(packageName)}`,
      '_blank',
    );
  };

  const contextMenuSections = {
    sections: [
      [
        {
          onClick: () => readMore(),
          icon: ExternalLink,
          label: t('Left_Tree_Components_Readme_Documentation'),
          automationId: 'action-go-to-npm-module',
        },
        {
          onClick: () => uninstallPackage(),
          icon: Delete,
          label: t(
            'WixCode_TreeView_FileTree_QuickActions_NPMPackages_Uninstall',
          ),
          automationId: 'action-uninstall-npm-module',
          disabled:
            experimentUtils.isAnyNpmFirstPhase() && isInstallationInProgress(),
        },
      ],
    ],
  };

  const contextMenu = (
    <div onClick={onContextMenuClick}>
      <TreeContextMenu
        menuClassName="context-menu wix-code-file-tree-dd"
        handleContextMenuToggle={setIsContextMenuOpen}
        contextMenuStructure={contextMenuSections}
        contextMenuButton="corvid_tree__context_menu_more"
        isContextMenuOpen={isContextMenuOpen}
        tooltipContent={t('Velo_Concurrent_Editing_Sidebar_ReadOnly_Text')}
        readonly={!!readOnlyMode.sidePanel?.packages}
      />
    </div>
  );

  const readmePath = getNpmReadmePath(packageName);

  const clickPkg = () => {
    editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_ITEM, {
      item_name: packageName,
      item_type: 'NPM',
    });
    editorAPI.developerMode.ui.idePane.unMinimize();

    editorAPI.store.dispatch(
      stateManagement.editorPlugins.wixCodeIDE.actions.showIDEWithDsRead({
        dsRead: editorAPI.dsRead,
      }),
    );
    setFileContext(editorAPI, readmePath);
  };
  const packageNameAndVersion = npmPackagesNameFormat(
    packageName,
    packageVersion,
  );

  return (
    <div data-hook={dataHooks.REGULAR_TREE_NODE_CONTAINER}>
      <baseUILib.TreeListItem
        dataHook={dataHooks.TREE_NODE}
        label={packageNameAndVersion}
        shouldTranslate={false}
        alwaysShowSuffix={isContextMenuOpen}
        highlight={isContextMenuOpen}
        icon={<PackageIcon />}
        size="small"
        suffix={contextMenu}
        selected={selectedIdeTabId === readmePath}
        onClick={clickPkg}
        onDoubleClick={() => nodeDoubleClick && nodeDoubleClick(readmePath)}
      />
    </div>
  );
};
