import { ActionType } from '../sharedTypes';
import { ThunkExtraArg } from '../WixCodeStoreAPI';

export type packageJsonReducerState = {
  npmPackages: PkgDependency[];
  codeReusePkgs: WixCodePackageCodeReuse[];
};
type Pkg = {
  name: string;
  version: string;
};
export type SetNpmPackage = {
  children: Pkg[];
  type: ActionType.SET_NPM_PACKAGES;
};
export type PackageJsonActions = {
  load: () => (
    dispatch: any,
    getState: any,
    { editorAPI }: ThunkExtraArg,
  ) => Promise<void>;
  setNpmPackages: (children: SetNpmPackage['children']) => SetNpmPackage;
  expandRootNodeModules: () => (
    dispatch: any,
    getState: any,
    { editorAPI }: ThunkExtraArg,
  ) => Promise<any>;
};

export interface WixCodePackageCodeReuse {
  backEndPackage: string;
  frontEndPackage: string;
  gridAppId: string;
  id: string;
  version: string;
  appDependencies?: string[];
  canEdit?: boolean;
}

interface npmVersion {
  version: string;
  description: string;
  license: string;
  repository: { [key: string]: string };
  status: string;
  time: string;
  publishedDate: string;
}

export interface PkgInfo extends PkgDependency {
  description?: string;
  license?: string;
  upgradableVersion?: string;
  downloadCount?: number;
  title?: string;
  installedVersion?: string;
}

export interface NpmPackageVersion {
  label: string;
  value: string;
  versionInfo: {
    description: string;
    homepage: string;
    license: string;
    repository: {
      type: string;
      url: string;
    };
    status: string;
    statusDescription: any;
    statusReason: any;
    tarball: any;
    time: string;
    version: string;
    _sanitizedDescription: any;
    _sanitizedName: any;
    publishedDate: string;
  };
}

export interface NpmPackageInfo extends PkgInfo {
  highlight?: {
    name: string;
    description: string;
  };
  versions: { [key: string]: any };
  requestLatestVersion?: string;
  _version: npmVersion;
  _tags: { latest: string };
  description: string;
  installedVersion: string;
  license: string;
  name: string;
  upgradableVersion: any;
  _moduleMetadata: {
    name: string;
    versions: {
      [versionName: string]: PkgVersion;
    };
  };
  _orderedLabeledVersions: NpmPackageVersion[];
}

export type PkgVersion = {
  status: PackageChangelogAction;
  changelog: {
    status: string;
    timestemp: number;
  };
  comment?: {
    made_available_in_version?: string;
    [key: string]: any;
  };
};

export type PackageStatus =
  | 'AVAILABLE'
  | 'APPROVED'
  | 'PENDING'
  | 'PENDING_OPERATOR_APPROVAL'
  | 'NOT_AVAILABLE'
  | 'REJECTED'
  | 'DELETED'
  | 'UNKNOWN'
  | 'NOT_FOUND';

export type PackageChangelogAction = 'MODIFIED' | 'CREATED' | 'AVAILABLE';

export type RequestValidationState =
  | 'ERROR_VERSION_ALREADY_AVAILABLE'
  | 'ERROR_VERSION_DOES_NOT_EXIST'
  | 'ERROR_VERSION_IS_EMPTY'
  | 'VALID';

export interface AvailablePkgs {
  npm: NpmPackageInfo[];
  codeReuse: AvailableCodeReusePkg[];
}

export enum PkgAvailableVersionType {
  NO_TEST = 'NO_TEST',
  TEST_ONLY = 'TEST_ONLY',
  BOTH = 'BOTH',
}

export interface AvailableCodeReusePkg
  extends WixCodePackageCodeReuse,
    PkgInfo {
  title: string;
  publishedDate?: string;
  appDependencies?: string[];
  privateRelease: boolean;
  availableVersionType: PkgAvailableVersionType;
  oldId?: string;
  testVersion?: string;
}

export interface WixCodePackageJSON {
  dependencies: { [key: string]: string };
  wixCodeDependencies: { [key: string]: WixCodePackageCodeReuse };
}

export interface InstalledCodeReusePkg extends CodeReusePkg {
  fromAutoUpdate?: boolean;
  latestVersion?: string;
}
type CodeReusePkg = WixCodePackageCodeReuse & PkgDependency;

export interface InstalledPkgs {
  npm: InstalledNpmPkg[];
  codeReuse: InstalledCodeReusePkg[];
}

export type InstalledNpmPkg = PkgDependency;

export interface PkgDependency {
  name: string;
  version: string;
  status?: PackageInstallationStatus;
}

export type PackageInstallationStatus =
  | 'NOT_INSTALLED'
  | 'INSTALLING'
  | 'INSTALLED'
  | 'UPDATING'
  | 'UPDATED'
  | 'VERSION_UPDATING';
