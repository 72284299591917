import React, { Fragment, useContext } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { utilsCreator, platformApps, monitoring } from '@wix/wix-code-common';
import {
  leftTreeClickOnMenuSettingIconItem,
  leftTreeClickInMenuSettingOptionItem,
} from '@wix/bi-logger-platform/v2';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { Divider, EditableListItem, TreeListItem } from '@wix/wix-base-ui';
import SettingsIcon from '../assets/icons/contextMenu/settings.svg';
import PagesIcon from '../assets/icons/contextMenu/pages.svg';
import RenameIcon from '../assets/icons/contextMenu/rename.svg';
import { PagesTabContext } from './PagesTabContext';
import dataHooks from '../dataHooks';
import { PageTreeContext } from './PageTreeContext';
import {
  MenuItemSection,
  ReadOnlyModeContext,
  TreeContextMenu,
} from '@wix/wix-code-common-components';

import styles from './Page.module.scss';

const { withErrorHandling } = monitoring;
interface StaticPageProps {
  id: string;
  title: string;
  depth: number;
}

export const StaticPage = ({ id, title, depth }: StaticPageProps) => {
  const {
    setPendingRename,
    onPageSelected,
    selectedIdeTabId,
    pageIdPendingRename,
    selectedPageId,
  } = useContext(PageTreeContext);
  const { editorAPI, legacyDependenciesAPI, bi, filesViewAPI } =
    useContext(PagesTabContext);
  const { readOnlyMode } = useContext(ReadOnlyModeContext);
  const { constants, experiment, platform, util } = legacyDependenciesAPI;

  const utils = utilsCreator({ experiment, platform, util });
  const [isContextMenuOpen, setContextMenuOpen] = React.useState(false);
  const [contextMenuSections, setContextMenuSections] = React.useState({
    sections: [] as any[],
    separator: {},
  });

  const isLinkablePage = editorAPI.dsRead.routers.pages.isConnectablePage(id);
  React.useEffect(() => {
    const pageSection: MenuItemSection = !isLinkablePage
      ? []
      : [
          {
            onClick: () => actionSelectedHandler(ACTIONS.SET_AS_DYNAMIC_PAGE),
            icon: PagesIcon,
            label: util.translate(
              'WixCode_Platform_Page_Actions_Set_Dynamic_Page',
            ),
            automationId: ACTIONS.SET_AS_DYNAMIC_PAGE,
          },
        ];
    pageSection.push({
      onClick: () => actionSelectedHandler(ACTIONS.RENAME_PAGE),
      icon: RenameIcon,
      label: util.translate('WixCode_Pages_Actions_Page_Rename'),
      automationId: ACTIONS.RENAME_PAGE,
    });
    setContextMenuSections({
      sections: [
        [
          {
            onClick: () => actionSelectedHandler(ACTIONS.OPEN_SETTINGS),
            icon: SettingsIcon,
            label: util.translate(
              'WixCode_Platform_Page_Actions_Page_Settings',
            ),
            automationId: ACTIONS.OPEN_SETTINGS,
          },
        ],
        pageSection,
      ],
      separator: <Divider long={true} />,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLinkablePage]);

  const ACTIONS = {
    SET_AS_DYNAMIC_PAGE: 'setAsDynamicPage',
    RENAME_PAGE: 'Pages_Actions_Page_Rename',
    OPEN_SETTINGS: 'Open_Settings_Panel',
  };

  const renamePage = (newValue: AnyFixMe) => {
    editorAPI.pages.data.update(id, {
      title: newValue,
      type: 'Page',
    });
    editorAPI.history.add('Rename Page', {
      pageId: id,
      newValue,
    });
    setPendingRename(id, false);
  };

  const cancelRename = () => {
    setPendingRename(id, false);
  };

  const onNodeClick = () =>
    withErrorHandling(() => {
      onPageSelected(id);
    })();

  const isSelected = () => selectedIdeTabId === utils.getFileIdFromPageId(id);

  const onContextMenuClick = (event: AnyFixMe) =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickOnMenuSettingIconItem({
          item_name: id,
          item_type: 'page',
        }),
      );

      event.stopPropagation();
    })();

  const shouldShowContextMenu = () => !isMobileViewMode();

  const isMobileViewMode = () => {
    const viewMode = editorAPI.dsRead.viewMode;
    return viewMode.get() === viewMode.VIEW_MODES.MOBILE;
  };

  const actionSelectedHandler = (action: AnyFixMe) =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickInMenuSettingOptionItem({
          item_name: id,
          item_type: 'page',
          menu_entry_name: action,
        }),
      );

      switch (action) {
        case ACTIONS.OPEN_SETTINGS: {
          return editorAPI.navigateAndOpenPagesPanel(id, {
            initialSettingsTabType: constants.PAGE_SETTINGS_TABS.PAGE_INFO,
          });
        }
        case ACTIONS.SET_AS_DYNAMIC_PAGE: {
          const pageId = id;
          editorAPI.pages.data.update(pageId, {
            mobileHidePage: null,
            hidePage: true,
          });
          platformApps.notifyDataBinding(editorAPI, {
            eventType: 'addDynamicPageClicked',
            eventPayload: {
              pageRef: editorAPI.dsRead.pages.getReference(pageId),
              origin: 'idePagesTree',
            },
          });
          return;
        }
        case ACTIONS.RENAME_PAGE: {
          setPendingRename(id, true);
          return;
        }
        default:
          // eslint-disable-next-line no-console
          console.warn(
            `Unknown ContextMenuAction "${action}" selected on StaticPage with id ${id}`,
          );
      }
    })();

  const contextMenu = (
    <div
      className="stop-propagation-bg"
      key="contextMenuContainer"
      onClick={onContextMenuClick}
    >
      <TreeContextMenu
        contextMenuStructure={contextMenuSections}
        contextMenuButton="corvid_tree__context_menu_more"
        handleContextMenuToggle={setContextMenuOpen}
        isContextMenuOpen={isContextMenuOpen}
        menuClassName="context-menu-icon"
        className="wix-code-pages-tree-dd"
        tooltipContent={util.translate(
          'Velo_Concurrent_Editing_Sidebar_ReadOnly_Text',
        )}
        readonly={!!readOnlyMode.sidePanel?.pages}
      />
    </div>
  );

  const pageIsBeingRenamed = pageIdPendingRename === id;

  const generateFileName = () => {
    return filesViewAPI.fileNameTransformer({
      originalTabTitle: title,
      displayName: title,
      tabId: id,
      isPage: true,
      suffix: '.js',
    });
  };
  return (
    <Fragment>
      {pageIsBeingRenamed ? (
        <EditableListItem
          dataHook={dataHooks.TREE_NODE_RENAME}
          depth={depth}
          focus={true}
          getInvalidMessage={util.validate.pageNameValidator}
          initialValue={title}
          onConfirm={renamePage}
          onCancel={cancelRename}
          size="small"
          validator={(name: AnyFixMe) =>
            util.validate.pageNameValidator(name) === ''
          }
        />
      ) : (
        <TreeListItem
          dataHook={dataHooks.TREE_NODE}
          label={generateFileName()}
          shouldTranslate={false}
          alwaysShowSuffix={isContextMenuOpen}
          highlight={isContextMenuOpen}
          depth={depth}
          icon={
            <Symbol
              className={classNames(styles.corvidTreePrefixIcon, {
                [styles.highlight]: selectedPageId === id,
              })}
              key="icon_page"
              name="page"
            />
          }
          size="small"
          suffix={shouldShowContextMenu() && contextMenu}
          onClick={onNodeClick}
          selected={isSelected()}
        />
      )}
    </Fragment>
  );
};
