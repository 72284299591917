import {
  DevContextAPI,
  LegacyEditorDependencies,
  EditorAPI,
} from '@wix/wix-code-plugin-contracts';
import createDevContextUtils from '@/utils/devContext';
import { ScopedStore } from '@wix/wix-code-repluggable';
import { DeveloperModeContextStore, createSelectors } from './state';

export const createDevContextAPI = (
  legacyEditorDependencies: LegacyEditorDependencies,
  editorAPI: EditorAPI,
  store: ScopedStore<DeveloperModeContextStore>,
): DevContextAPI => {
  const developerContextUtils = createDevContextUtils(legacyEditorDependencies);
  const getDeveloperModeContext = () => store.getState().developerModeContext;
  const developerModeContextSelectors = createSelectors(
    getDeveloperModeContext,
  );
  const getSelectedFileId = () =>
    developerContextUtils.extractFileId(getDeveloperModeContext());

  return {
    ...developerModeContextSelectors,
    getSelectedFileId,
    setMasterPageContext: () => {
      developerContextUtils.setMasterPageContext(editorAPI);
    },
    observeFileChange: (cb) => {
      let selectedFileId: ReturnType<typeof getSelectedFileId>;

      function handleChange() {
        const nextSelectedFileId = getSelectedFileId();
        if (nextSelectedFileId !== selectedFileId) {
          selectedFileId = nextSelectedFileId;
          cb();
        }
      }

      const unsubscribe = store.subscribe(handleChange);
      handleChange();
      return unsubscribe;
    },
  };
};
