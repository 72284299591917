import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';

import { createSiteAPI } from './SiteAPI';

export const SiteEntryPoint: EntryPoint = {
  name: 'Site entry point',
  getDependencyAPIs() {
    return [APIKeys.ClassicEditorAPI, APIKeys.LegacyEditorDependencies];
  },
  declareAPIs() {
    return [APIKeys.SiteAPI];
  },
  attach(shell: Shell) {
    shell.contributeAPI(APIKeys.SiteAPI, () => {
      const classicEditorAPI = shell.getAPI(APIKeys.ClassicEditorAPI);
      const legacyEditorDependencies = shell.getAPI(
        APIKeys.LegacyEditorDependencies,
      );
      return createSiteAPI({ classicEditorAPI, legacyEditorDependencies });
    });
  },
};
