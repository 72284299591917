import React, { useContext } from 'react';
import { PackagesModalContext } from '../../../../../packagesModalContext';
import { Tooltip, Text, RichText, TextButton } from '@wix/wix-base-ui';
import dataHooks from '@/sidebar/dataHooks';
import s from './PkgActionButton.scss';

interface NpmPkgLabelProps {
  tooltipLink: string;
  tooltipText: string;
  tooltipLinkText: string;
  labelText: string;
  dataHook: string;
}

export const NpmPkgLabel: React.FC<NpmPkgLabelProps> = ({
  tooltipLink,
  dataHook,
  labelText,
  tooltipLinkText,
  tooltipText,
}) => {
  const { t } = useContext(PackagesModalContext);

  const tooltipCTA = () => window.open(t(tooltipLink), '_blank');

  return (
    <Tooltip
      maxWidth={400}
      interactive={true}
      content={
        <div>
          <Text size="small">{t(tooltipText)}</Text>
          <RichText>
            <TextButton
              size="small"
              onClick={tooltipCTA}
              rel="noreferrer"
              dataHook={dataHooks.TOOLTIP_CTA}
            >
              {t(tooltipLinkText)}
            </TextButton>
          </RichText>
        </div>
      }
    >
      <div className={s.textLabelContainer}>
        <Text
          dataHook={dataHook}
          type="T02"
          shouldTranslate={false}
          size="small"
          skin="secondary"
          enableEllipsis={false}
        >
          {t(labelText)}
        </Text>
      </div>
    </Tooltip>
  );
};
