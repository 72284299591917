import React, { useContext, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { TreeListItem, TreeSectionDivider } from '@wix/wix-base-ui';
import { monitoring, resolveRouterRef } from '@wix/wix-code-common';
import {
  leftTreeClickInMenuSettingOptionItem,
  leftTreeClickOnASection,
  leftTreeClickOnMenuSettingIconItem,
} from '@wix/bi-logger-platform/v2';
import { RouterPage } from './RouterPage';
import {
  ContextMenuStructure,
  ReadOnlyModeContext,
  TreeContextMenu,
} from '@wix/wix-code-common-components';
import { PagesTabContext } from './PagesTabContext';
import dataHooks from '../dataHooks';
import { PageTreeContext } from './PageTreeContext';
import { RouterPageData } from './types';

interface RouterPagesGroupProps {
  dataSource: RouterType;
  isTopLevelTreeRoot: boolean;
}

type ContextMenuAction = {
  id: string;
  title: string;
  icon: string;
};

type ActionType = {
  title: string;
  symbolName: string;
  event: string;
  type?: string;
};

type RouterType = {
  id: string;
  title: string;
  pagesData: RouterPageData[];
  prefix: string;
  applicationId: number;
  isSpecial: boolean;
  biCategory: string;
  routerType: string;
  actions: ActionType[];
};

const { withErrorHandling } = monitoring;

export const RouterPagesGroup = ({
  dataSource,
  isTopLevelTreeRoot,
}: RouterPagesGroupProps) => {
  const { readOnlyMode } = useContext(ReadOnlyModeContext);
  const { isCollapsed, setCollapsed } = useContext(PageTreeContext);
  const {
    editorAPI,
    bi,
    legacyDependenciesAPI: { util },
  } = useContext(PagesTabContext);

  const getId = () => 'page_' + _.get(dataSource, 'id');

  const [isContextMenuOpen, setContextMenuOpen] = useState(false);
  const [routerContextMenuItems, setRouterContextMenuItems] = useState<
    ContextMenuAction[]
  >([]);
  const [collapsed, setCollapsedState] = React.useState(isCollapsed(getId()));
  const [contextMenuSections, setContextMenuSections] =
    useState<ContextMenuStructure>({
      sections: [],
    });
  const stateRef = useRef<any[]>();
  stateRef.current = routerContextMenuItems;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => updateContextMenuItems(), []);

  const updateContextMenuItems = () => {
    const contextMenuItems = shouldUpdateContextMenuItems()
      ? editorAPI.pages.dynamicPages.getRouterActionByPrefix(dataSource.prefix)
      : [];

    setRouterContextMenuItems(contextMenuItems);
    setContextMenuSections({
      sections: [
        contextMenuItems.map((item: AnyFixMe, index: number) => ({
          onClick: () => actionSelectedHandler(item.id),
          icon: item.icon || 'file',
          label: item.title,
          automationId: `router-context-item-${item.title}-${index}`,
        })),
      ],
    });
  };

  const getAppData = () => {
    const { routerRef } = resolveRouterRef(editorAPI, dataSource);
    const routerData = editorAPI.dsRead.routers.get.byRef(routerRef);
    return editorAPI.dsRead.platform.getAppDataByAppDefId(
      routerData.appDefinitionId,
    );
  };

  const onNodeClick = () =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickOnASection({
          section_name: 'router_pages_group',
          sub_section: dataSource.title,
          action: collapsed ? 'open' : 'close',
        }),
      );
      setCollapsed(getId(), !collapsed);
      setCollapsedState(!collapsed);
    })();

  const handleContextMenuToggle = setContextMenuOpen;

  const onContextMenuClick = (event: AnyFixMe) =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickOnMenuSettingIconItem({
          item_name: _.get(dataSource, 'pageData.id'),
          item_type: 'page',
        }),
      );

      event.stopPropagation();
    })();

  const shouldUpdateContextMenuItems = () => !isMobileViewMode();

  const shouldShowContextMenu = () => {
    if (stateRef.current!.length === 0) {
      return false;
    }
    return shouldUpdateContextMenuItems();
  };

  const isMobileViewMode = () => {
    const viewMode = editorAPI.dsRead.viewMode;
    return viewMode.get() === viewMode.VIEW_MODES.MOBILE;
  };

  const actionSelectedHandler = (action: AnyFixMe) =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickInMenuSettingOptionItem({
          item_name: _.get(dataSource, 'pageData.id'),
          item_type: 'page',
          menu_entry_name: action,
        }),
      );

      const selectedOption = _.find(stateRef.current, {
        id: action,
      });

      if (_.get(selectedOption, 'callback')) {
        return selectedOption.callback();
      }

      const { routerRef, pageRef } = resolveRouterRef(editorAPI, dataSource);
      const routerData = editorAPI.dsRead.routers.get.byRef(routerRef);
      const payload = {
        routerRef,
        publicUrl: editorAPI.dsRead.generalInfo.getPublicUrl(),
        origin: 'idePagesTree',
      };
      if (pageRef) {
        (payload as any).pageRef = pageRef;
      }
      const applicationId = _.get(
        editorAPI.dsRead.platform.getAppDataByAppDefId(
          routerData.appDefinitionId,
        ),
        'applicationId',
      );
      editorAPI.dsActions.platform.notifyApplication(applicationId, {
        eventType: selectedOption && selectedOption.event,
        eventPayload: payload,
      });
    })();

  const applicationName = _.get(getAppData(), 'appDefinitionName');

  const contextMenu = (
    <div
      className="stop-propagation-bg"
      key="contextMenuContainer"
      onClick={onContextMenuClick}
    >
      <TreeContextMenu
        contextMenuStructure={contextMenuSections}
        contextMenuButton={
          isTopLevelTreeRoot
            ? 'corvid_tree__context_menu_add'
            : 'corvid_tree__context_menu_more'
        }
        handleContextMenuToggle={handleContextMenuToggle}
        isContextMenuOpen={isContextMenuOpen}
        menuClassName="context-menu-icon"
        className="wix-code-pages-tree-dd"
        tooltipContent={util.translate(
          'Velo_Concurrent_Editing_Sidebar_ReadOnly_Text',
        )}
        readonly={!!readOnlyMode.sidePanel?.pages}
      />
    </div>
  );

  const groupNode = isTopLevelTreeRoot ? (
    <div data-aid={'app-section-' + applicationName}>
      <TreeSectionDivider
        dataHook={dataHooks.SECTION_DIVIDER}
        label={dataSource.title}
        shouldTranslate={false}
        collapsed={collapsed}
        hideBottomBorder={collapsed}
        alwaysShowSuffix={isContextMenuOpen}
        onClick={onNodeClick}
        size="small"
        suffix={shouldShowContextMenu() && contextMenu}
      />
    </div>
  ) : (
    <TreeListItem
      dataHook={dataHooks.TREE_NODE}
      label={dataSource.title}
      shouldTranslate={false}
      collapseState={collapsed ? 'collapsed' : 'expanded'}
      size="small"
      alwaysShowSuffix={isContextMenuOpen}
      highlight={isContextMenuOpen}
      onClick={onNodeClick}
      suffix={shouldShowContextMenu() && contextMenu}
    />
  );

  return (
    <div onMouseEnter={() => updateContextMenuItems()}>
      {groupNode}
      {!collapsed && (
        <ul
          className={cx('subtree', {
            'section-container': isTopLevelTreeRoot,
          })}
          data-aid={'app-items-' + applicationName}
          key="subtree"
        >
          {dataSource.pagesData &&
            dataSource.pagesData.map((child: RouterPageData) => (
              <li key={child.id}>
                <RouterPage
                  dataSource={child}
                  depth={isTopLevelTreeRoot ? 0 : 1}
                />
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
