import React, { useContext, useEffect, useMemo, useState } from 'react';
import { BI } from '../constants';
import { CustomisedModal } from '../CustomisedModal/CustomisedModal';
import { useTranslation } from '@wix/wix-i18n-config';
import { ExternalDbsModalContext } from '../ExternalDbsModalContext';
import { helpClick, panelHeaderClose } from '@wix/bi-logger-editor/v2';
import { ConnectAdapter } from '../ConnectAdapter/ConnectAdapter';

import { connectExternalDbPanelAction } from '@wix/bi-logger-platform/v2';
import { getConnectorsService } from '../../../WixDataServerAPI';
import { ConnectionLoader } from '../ConnectionLoader/ConnectionLoader';
import { Preloader } from '@wix/wix-base-ui';
import { Schema } from '@wix/wix-code-plugin-contracts';
import styles from '../ExternalDBPanels.scss';
import { EditExternalDbWarningModal } from '../EditExternalDbWarningModal';
import {
  Connector,
  ConnectorResponse,
  ModalStage,
} from '../../../internal-types';
import { useConnectorDetails } from '../useConnectorDetails';
import { useValidation } from '../useValidation';
import { useUserTypingInfo } from '../useUserTypingInfo';
import { SuccessModal } from '../SuccessModal';
import { SuccessNoCollectionsModal } from '../SuccessNoCollectionsModal';
import { ErrorModal } from '../ErrorModal';

export interface EditExternalDbProps {
  connectorName: string;
}

enum EditExternalDbStage {
  EditConnector,
  WarningModal,
  Loader,
  Success,
  SuccessEmptyState,
  Error,
}

export const EditExternalDb: React.FC<EditExternalDbProps> = ({
  connectorName,
}) => {
  const [stage, setStage] = useState<EditExternalDbStage>(
    EditExternalDbStage.EditConnector,
  );

  const {
    namespace,
    setNamespace,
    endpoint,
    setEndpoint,
    configuration,
    setConfiguration,
  } = useConnectorDetails();

  const { fieldsValidations, setFieldsValidations } = useValidation();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { userStartTypingIndicaiton, setUserStartTypingIndicaiton } =
    useUserTypingInfo();

  const { biLoggerAPI, panelsAPI, wixCodeAppAPI, platformAppsAPI } = useContext(
    ExternalDbsModalContext,
  );

  const [connector, setConnector] = useState<Connector>(null as any);
  const [t] = useTranslation();

  const connectorsService = useMemo(
    () => getConnectorsService(wixCodeAppAPI.getSignedInstance),
    [wixCodeAppAPI],
  );

  useEffect(() => {
    connectorsService
      .getConnector(connectorName)
      .then((res: ConnectorResponse) =>
        setConnector({ ...res, configuration: res.configuration.secretKey }),
      )
      .catch(() => {});
  }, [connectorName, connectorsService]);

  const editConnector = async () => {
    setStage(EditExternalDbStage.Loader);
    const timeout = setTimeout(onConnectionError, 65000);
    try {
      await connectorsService.modifyConnector({
        namespace,
        endpoint,
        configuration: { secretKey: configuration },
      });
      clearTimeout(timeout);
      platformAppsAPI.refreshSchemasCache();
      const schemas = await platformAppsAPI.listSchemas();
      const schemasUnderDB = schemas.filter(
        (schema: Schema) => schema.namespace === namespace,
      );
      schemasUnderDB.length > 0
        ? setStage(EditExternalDbStage.Success)
        : setStage(EditExternalDbStage.SuccessEmptyState);
    } catch (error: any) {
      clearTimeout(timeout);
      setErrorMessage(error.message);
      setStage(EditExternalDbStage.Error);
    }
  };

  const onConnectionError = () => {
    setErrorMessage('The server is currently busy. Please try again shortly');
    setStage(EditExternalDbStage.Error);
  };

  const EditExternalDbStages: { [key: number]: ModalStage } = {
    [EditExternalDbStage.EditConnector]: {
      content: connector ? (
        <ConnectAdapter
          uniqueNamespaces={[]}
          fieldsValidations={fieldsValidations}
          setFieldsValidations={setFieldsValidations}
          setNamespace={setNamespace}
          setEndpoint={setEndpoint}
          setConfiguration={setConfiguration}
          inputFields={{ namespace, endpoint, configuration }}
          isEditFlow={true}
          initialFields={
            userStartTypingIndicaiton.didUserStartTypingEndpoint ||
            userStartTypingIndicaiton.didUserStartTypingSecretkey
              ? null
              : connector
          }
          userStartTypingIndicaiton={userStartTypingIndicaiton}
          setUserStartTypingIndicaiton={setUserStartTypingIndicaiton}
        />
      ) : (
        <div className={styles.loaderContainer}>
          <Preloader className="large" />
        </div>
      ),
      biName: BI.panels.editAdapter,
      cta: () => handleConfirmEditConnector(),
      withModal: true,
      withCtas: true,
      onSecondaryButtonClick: () => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: BI.panels.editAdapter,
            action_type: BI.actions.click,
            button_name: BI.buttons.primaryCTA,
          }),
        );
      },
    },
    [EditExternalDbStage.WarningModal]: {
      content: (
        <EditExternalDbWarningModal
          setStage={setStage}
          onEditConnector={editConnector}
        />
      ),
      biName: BI.panels.editAdapter,
    },
    [EditExternalDbStage.Loader]: {
      content: <ConnectionLoader />,
      biName: BI.panels.editAdapter,
      withModal: true,
    },
    [EditExternalDbStage.SuccessEmptyState]: {
      content: <SuccessNoCollectionsModal />,
    },
    [EditExternalDbStage.Success]: {
      content: (
        <SuccessModal
          title={t(
            'WixCode_Modal_ExternalDatabaseConnection_ChangedConnection_Title',
          )}
          description={t(
            'WixCode_Modal_ExternalDatabaseConnection_ChangedConnection_Description',
          )}
        />
      ),
    },
    [EditExternalDbStage.Error]: {
      content: (
        <ErrorModal
          errorMessage={errorMessage}
          onCtaClick={() => {
            setStage(EditExternalDbStage.EditConnector);
          }}
        />
      ),
    },
  };

  const handleConfirmEditConnector = async () => {
    const areFieldsValid =
      fieldsValidations.isEndpointValid &&
      fieldsValidations.isConfigurationValid;

    biLoggerAPI.report(
      connectExternalDbPanelAction({
        input_name: BI.panels.editAdapter,
        action_type: BI.actions.click,
        button_name: BI.buttons.connect,
        isSuccess: areFieldsValid,
      }),
    );
    if (!areFieldsValid) {
      return;
    }
    setStage(EditExternalDbStage.WarningModal);
  };

  return EditExternalDbStages[stage].withModal ? (
    <CustomisedModal
      title={t('WixCode_Modal_EditExternalDatabaseConnection_Title')}
      onSecondaryButtonClick={
        EditExternalDbStages[stage].onSecondaryButtonClick || (() => {})
      }
      onCtaClick={EditExternalDbStages[stage].cta!}
      primaryButtonText={
        EditExternalDbStages[stage].withCtas
          ? t('External_Databases_Modal_Connect_Adapter_CTA')
          : undefined
      }
      secondaryButtonText={
        EditExternalDbStages[stage].withCtas
          ? t(
              'WixCode_WarningModal_EditExternalDatabaseConnection_SecondaryCTA',
            )
          : undefined
      }
      onCloseButtonClick={() => {
        biLoggerAPI.report(
          panelHeaderClose({
            panel_name: EditExternalDbStages[stage].biName,
          }),
        );
        panelsAPI.closePanel();
      }}
      onHelpButtonClick={() => {
        biLoggerAPI.report(
          helpClick({
            panel_name: EditExternalDbStages[stage].biName,
          }),
        );
        window.open(t('External_Databases_Modal_Help_Link'), '_blank');
      }}
    >
      {EditExternalDbStages[stage].content}
    </CustomisedModal>
  ) : (
    EditExternalDbStages[stage].content
  );
};
