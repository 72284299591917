export const byHook = (str: string) => `[data-hook=${str}]`;

export default {
  CONTEXT_MENU_ICON: 'context-menu-icon',
  EMPTY_STATE: 'corvid-site-structure__empty-state',
  EMPTY_STATE_BUTTON: 'cta-button',
  LEFT_PANE_ROOT: 'corvid-site-structure__root',
  LOADER: 'corvid-site-structure__loader',
  PKG_LOADER: 'corvid-site-structure__pkg_loader',
  FOLDER_ITEMS: (folderName: string) => `folder-items-${folderName}`,
  PUBLIC_SECTION: 'public-files-items',
  BACKEND_SECTION: 'backend-files-items',
  EXTERNAL_COMPONENTS_SECTION: 'external-components-files-items',
  CODE_REUSE_LOADER: 'corvid-site-structure__code_reuse_loader',
  SECTION_DIVIDER: 'corvid-site-structure__section-divider',
  NPM_SECTION: 'npm-section',
  VELO_SECTION: 'velo-section',
  PRIVATE_SECTION: 'private-section',
  FOLDER_ROOT: 'corvid-site-structure__code-reuse-root',
  FILE_SECTION_ROOT: (id: string) =>
    `corvid-site-structure__section-${id}-root`,
  TREE_ROOT: 'corvid-site-structure__tree-root',
  TREE_NODE: 'corvid-site-structure__tree-node',
  TREE_NODE_RENAME: 'corvid-site-structure__rename_input',
  CODE_REUSE_TREE_SCOPE_ROOT: (scope: string) =>
    `wix-code-packages-scope-${scope}`,
  CODE_REUSE_TREE_ROOT: (pkgName: string) =>
    `corvid-site-structure__code-reuse-root-${pkgName}`,
  EMPTY_SPACE: 'empty-space',
  TAB_CONTAINER: 'tab-container',
  LARGE_ACTION_ITEM: 'large-action-item',
  NPM_PKGS_SECTION: 'packages-section',
  VELO_PKGS_SECTION: 'velo-packages-section',
  PRIVATE_PKGS_SECTION: 'private-packages-section',
  NPM_PKGS_ROOT: 'npm-pkgs-root',
  VELO_PKGS_ROOT: 'velo-pkgs-root',
  PRIVATE_PKGS_ROOT: 'private-pkgs-root',
  NPM_PKG_ID: (pkgName: string) => `npm-pkg-id-${pkgName}`,
  UPDATE_PIMPLE: 'update-pimple',
  INSTALL_DEPENDENCY_PIMPLE: 'install-depandency-pimple',
  INSTALL_DEPENDENCY_LINK: 'install-depandency-link',
  PROD_TOOLS_SECTION: 'production-tools-section',
  VELO_TOOLS_LOGS_SECTION: 'velo-tools-logs-section',
  VELO_TOOLS_MONITORING_SECTION: 'velo-tools-monitoring-section',
  VELO_TOOLS_SECURITY_SECTION: 'velo-tools-security-section',
  VELO_TOOLS_AUTOMATION_SECTION: 'velo-tools-automation-section',
  VELO_TOOLS_PERFORMANCE_SECTION: 'velo-tools-performance-section',
  MULTI_TABS_POPOVER_BTN: 'multi_tabs_popuver_btn',
  FILE_CONTEXT_DISABLED_TOOLTIP: 'file-context-disabled-tooltip',
  READ_ONLY_TAB: 'read_only_tab',
  FILE_DELETE_MODAL: 'file-delete-modal',
  PRIVATE_READ_ONLY: 'private_read_only',
  TEST_VERSION_SYMBOL: 'test_version_symbol',
  SIDE_PANE_CONTAINER: 'side-pane-container',
  TREE_NODE_CONTAINER: 'tree-node-container',
  REQUEST_NPM_PKG_LABEL: 'request-npm-pkg-label',
  NOT_SUPPORTED_NPM_PKG_LABEL: 'not-supported-npm-pkg-label',
  PENDING_NPM_PKG_LABEL: 'pending-npm-pkg-label',
  REQUEST_NPM_PKG_VERSION_INPUT: 'request-npm-pkg-version-input',
  REQUEST_NPM_PKG_CONTAINER: 'request-npm-pkg-container',
  REQUEST_NPM_PKG_SUBMIT_BTN: 'request-npm-pkg-submit-btn',
  REQUEST_NPM_PKG_SUCCESS_CONTAINER: 'request-npm-pkg-success-container',
  TOOLTIP_CTA: 'tooltip-cta',
  MASTER_PAGE_CONTAINER: 'master-page-container',
  MASTER_PAGE_DIVIDER: 'master-page-divider',
  MASTER_PAGE_ICON: 'master-page-icon',
  MASTER_PAGE_INFO_ICON: 'master-page-info-icon',
  EXCLUDED_TREE_NODE_CONTAINER: 'excluded-tree-node-container',
  REGULAR_TREE_NODE_CONTAINER: 'regular-tree-node-container',
  SECTION_DIVIDER_BADGE: 'section-divider-badge',
};
