import './contextMenuItem.global.scss';
import * as React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import cx from 'classnames';

export interface ContextMenuItemProps {
  symbolName: string;
  label: string;
  automationId?: string;
  className?: string;
}

export class ContextMenuItem extends React.Component<ContextMenuItemProps> {
  render() {
    const { symbolName, label, automationId, className } = this.props;
    return (
      <div
        className={cx('wix-code-context-menu-item', className)}
        data-aid={automationId}
      >
        <div className="icon">
          <Symbol name={symbolName} />
        </div>
        <span className="label">{label}</span>
      </div>
    );
  }
}
