import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import { APIKeys, EntryPoint, Shell } from '@wix/wix-code-repluggable';

import { createClassicEditorAPI } from './ClassicEditorAPI';

export const createClassicEditorEntryPoint = (
  editorAPI: EditorAPI,
): EntryPoint => ({
  name: 'Classic Editor Entry Point',
  declareAPIs() {
    return [APIKeys.ClassicEditorAPI];
  },
  attach(shell: Shell) {
    shell.contributeAPI(APIKeys.ClassicEditorAPI, () =>
      createClassicEditorAPI(editorAPI),
    );
  },
});
