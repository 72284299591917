/* eslint-disable prettier/prettier */
import { DefaultFileTemplate } from './defaultTemplatesInterface';

const externalComponentFileContent = `import * as React from 'react';

export default function MyComponent({
  title='This is a sample React component'
}) {
  const styles = {
    main: {
      padding: "24px",
      fontFamily: "Wix Madefor Text",
      textAlign: "center",
      background: "#FFFFFF"
    },
    title: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "18px",
      lineHeight: "24px",
      textAlign: "center",
      marginTop: "15px",
      marginBottom: "5px",
    },
    subtitle: {
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0px",
      color: '#595D70'
    },
  };
  
  return (
    <div style={styles.main}>
      <img src='http://wix.to/6gubljp' width='120px' height='100px' />
      <h4 style={styles.title}>{title}</h4>
      <p style={styles.subtitle}>Write your own from scratch or import an external React component. Make sure to install any NPM packages your component requires.</p>
    </div>
  );
}`

const publicJsFileContent = `// Filename: \${moduleName}.js

// Code written in public files is shared by your site's
// Backend, page code, and site code environments.

// Use public files to hold utility functions that can
// be called from multiple locations in your site's code.
export function add(param1, param2) {
	return param1 + param2;
}

// The following code demonstrates how to call the add
// function from your site's page code or site code.
/*
import {add} from '\${moduleName}.js'
$w.onReady(function () {
    let sum = add(6,7);
    console.log(sum);
});
*/`;

const webModuleContent = `/*********
 .jsw file
 **********ֿ

 Backend .jsw files contain functions that run on the server side but can be called from page code and frontend files.
 Use backend functions to keep code private and hidden from a user's browser. More info:

 https://support.wix.com/en/article/velo-web-modules-calling-backend-code-from-the-frontend

**********/

/*** Call the sample multiply function below by copying the following into your page code:

 import { multiply } from 'backend/multiplication';

 $w.onReady(function () {
     multiply(4, 5).then(product => {
         console.log(product);
     })
     .catch(error => {
         console.log(error);
     });
 });

***/

// Sample backend code in a multiplication.jsw file:

export function multiply(factor1, factor2) {
  return factor1 * factor2;
}`;

const webModuleContentTbf = `/*********
 .jsw file
 *********

 Backend .jsw files contain functions that run on the server side but can be called from page code and frontend files.
 Use backend functions to keep code private and hidden from a user's browser. More info:

 https://support.wix.com/en/article/velo-web-modules-calling-backend-code-from-the-frontend

**********/

/*** Call the sample multiply function below by copying the following into your page code:

 import { multiply } from 'backend/multiplication';

 $w.onReady(function () {
     multiply(4, 5).then(product => {
         console.log(product);
     })
     .catch(error => {
         console.log(error);
     });
 });

***/

// Sample backend code in a multiplication.jsw file:

export function multiply(factor1, factor2) {
   return factor1 * factor2;
}`;

const computedFunctionsFileContent = `/*************************
backend/http-functions.js
*************************

'backend/http-functions.js' is a reserved Velo file that lets you expose APIs that respond to fetch requests from external services.

In this file you create APIs to expose the functionality of your site as a service. That is, other people can use 
the functionality of your site by writing code that calls your site's APIs, as defined by the functions you create here.

Using the provided code (below this comment block) as an example, users of your HTTP functions can call your API using the following patterns: 

Production endpoints:

 • Premium site:
   https://mysite.com/_functions/multiply?leftOperand=3&rightOperand=4
 • Free sites:
   https://username.wixsite.com/mysite/_functions/multiply?leftOperand=3&rightOperand=4

Test endpoints:
 • Premium sites:
   https://mysite.com/_functions-dev/multiply?leftOperand=3&rightOperand=4
 • Free sites:
   https://username.wixsite.com/mysite/_functions-dev/multiply?leftOperand=3&rightOperand=4

---
About HTTP functions: 
https://support.wix.com/en/article/velo-exposing-a-site-api-with-http-functions

API Reference:
https://www.wix.com/velo/reference/wix-http-functions

**********************/

// The following is an example of an HTTP function, which gets the product of 2 operands. Adapt the code below for your specific use case.

import { ok, badRequest } from 'wix-http-functions';

export function get_multiply(request) {

   const response = {
       "headers": {
           "Content-Type": "application/json"
       }
   };

   try {

       const leftOperand = parseInt(request.query["leftOperand"], 10);
       const rightOperand = parseInt(request.query["rightOperand"], 10);

       response.body = {
           "product": leftOperand * rightOperand
       };
       return ok(response);
       
   } catch (err) {
       response.body = {
           "error": err
       };
       return badRequest(response);
   }
}
`;

const jobsConfigFileContent = `/*******************
 backend/jobs.config
 *******************

 'backend/jobs.config' is a Velo configuration file that lets you schedule code to run repeatedly at specified intervals. 

 Using scheduled jobs you can run backend code without any user interaction. For example, you could generate a weekly 
 report or run nightly data cleansing.

 You schedule code to run by creating a job.
 Each job defines which function to run and when to run it.

 ---
 More about Scheduled Jobs: 
 https://support.wix.com/en/article/velo-scheduling-recurring-jobs
 
 Online tool for building Velo cron expressions
 https://wix.to/NDAQn6c

*******************/

// The following code is an example of a job. Adapt the code for your specific use case.
{
 "jobs": [{
         // Option 1: Define execution interval using a cron expression
         "functionLocation": "/module/filename.js(w)", // Relatively to Backend folder, started by slash
         "functionName": "funcName",
         "description": "describe your job", // Optional
         "executionConfig": {
             "cronExpression": "45 10 * * *" // Set a recurrence of no less than 1 hour
             // Read about Cron expressions: [https://en.wikipedia.org/wiki/Cron#CRON_expression]
         }
     },
     {
         // Option 2: Ddefine execution interval by setting time, day of week, and day of month
         "functionLocation": "/module/filename.js(w)", // Relative to Backend folder, started by slash
         "functionName": "funcName",
         "description": "describe your job", // Optional
         "executionConfig": {
             "time": "10:23" // "hh:mm" 24h format, UTC timezone (e.g. 13:00)
             // Optional: Uncomment section below for more complex intervals
             // "dayOfWeek": "Monday", // Day of week
             // "dateInMonth": 1 // Number between 1-31
         }
     },
     {
         // Add more jobs. Up to 20 jobs supported.
     }
 ]
}
`;

const eventsFileContent = `/*****************
 backend/events.js
 *****************

 'backend/events.js' is a reserved Velo file that enables you to handle backend events.

 Many of the Velo backend modules, like 'wix-stores-backend' or 'wix-media-backend', include events that are triggered when 
 specific actions occur on your site. You can write code that runs when these actions occur.

 For example, you can write code that sends a custom email to a customer when they pay for a store order.

 Example: Use the function below to capture the event of a file being uploaded to the Media Manager:

   export function wixMediaManager_onFileUploaded(event) {
       console.log('The file "' + event.fileInfo.fileName + '" was uploaded to the Media Manager');
   }

 ---
 More about Velo Backend Events: 
 https://support.wix.com/en/article/velo-backend-events

*******************/
`;

const dataHooksFileContent = `/***************
 backend/data.js
 ***************

 'backend/data.js' is a reserved Velo file that enables you to create data hooks.

 A data hook is an event handler (function) that runs code before or after interactions with your site's database collections. 
 For example, you may want to intercept an item before it is added to your collection to tweak the data or to perform a final validation.

 Syntax for functions:

  export function [collection name]_[action](item, context){}

 Example: 

  export function myCollection_beforeInsert(item, context){}

 ---
 More about Data Hooks: 
 https://support.wix.com/en/article/velo-about-data-hooks

 Using Data Hooks: 
 https://support.wix.com/en/article/velo-using-data-hooks

 API Reference: 
 https://www.wix.com/velo/reference/wix-data/hooks

***************/
`;
const publicTsFileContent = `// TypeScript v4.7.4
// Learn more about TypeScript: https://www.typescriptlang.org/

// Code written in public files is shared by your site's
// Backend, page code, and site code environments.

// Use public files to hold utility functions that can
// be called from multiple locations in your site's code.
export function add(param1:  number, param2: number) 
{    return param1 + param2;
}

// The following code demonstrates how to call the add
// function from your site's page code or site code.
/*
import {add} from 'public/new-file.ts'
$w.onReady(function () {
    let sum = add(6,7);
    console.log(sum);

});
*/`
const backendTsFileContent = `// TypeScript v4.7.4
// Learn more about TypeScript: https://www.typescriptlang.org/
`

const webJsFileContent = `/************
.web.js file
************

Backend '.web.js' files contain functions that run on the server side and can be called from page code.

Learn more at https://dev.wix.com/docs/develop-websites/articles/coding-with-wix-studio/wix-studio-about-web-methods

****/

/**** Call the sample multiply function below by pasting the following into your page code:

import { multiply } from 'backend/new-file.web';

$w.onReady(async function () {
   console.log(await multiply(4,5));
});

****/

import { Permissions, webMethod } from "wix-web-module";

export const multiply = webMethod (
  Permissions.Anyone, 
  function (factor1, factor2) { return factor1 * factor2 }
);
`;

export const classicCodeTemplates: DefaultFileTemplate = {
  PUBLIC_JS_FILE_CONTENT: publicJsFileContent,
  EXTERNAL_COMPONENT_FILE_CONTENT: externalComponentFileContent,
  WEB_MODULE_CONTENT: webModuleContent,
  WEB_MODULE_CONTENT_TBF: webModuleContentTbf,
  OLD_COMPUTED_FUNCTIONS_FILE_CONTENT: computedFunctionsFileContent,
  COMPUTED_FUNCTIONS_FILE_CONTENT: computedFunctionsFileContent,
  JOBS_CONFIG_FILE_CONTENT: jobsConfigFileContent,
  DATA_HOOKS_FILE_CONTENT: dataHooksFileContent,
  EVENTS_FILE_CONTENT: eventsFileContent,
  PUBLIC_TS_FILE_CONTENT: publicTsFileContent,
  BACKEND_TS_FILE_CONTENT: backendTsFileContent,
  WEB_JS_FILE_CONTENT: webJsFileContent,
};
