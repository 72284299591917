import React from 'react';
import { TextInput, Composites, TextLabel } from '@wix/wix-base-ui';
import dataHooks from '../../../dataHooks';
import styles from './RepositoryDescription.scss';

const RepositoryDescription: React.FC<{
  translate: (key: string) => string;
  onChange: (value: string, isValid: boolean) => void;
  siteName: string;
  repoDescription: string;
}> = ({ translate: t, onChange, siteName, repoDescription }) => {
  return (
    <div className={styles.description}>
      <Composites.TextInputLabeled>
        <TextLabel
          type="T02"
          value={t(
            'githubIntegration.connect_modal.create_repository.repository_description',
          )}
          dataHook={dataHooks.connectModal.createRepository.description.label}
        />
        <TextInput
          placeholder={`${t(
            'githubIntegration.connect_modal.create_repository.repository_description_placeholder',
          )} ${siteName}`}
          value={repoDescription}
          onChange={onChange}
          hideSuccessIndication={true}
          dataHook={dataHooks.connectModal.createRepository.description.input}
          isValid={repoDescription.length < 100}
          invalidMessage={t(
            'githubIntegration.connect_modal.create_repository.description_too_long_err_msg',
          )}
        />
      </Composites.TextInputLabeled>
    </div>
  );
};

export default RepositoryDescription;
