// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hOvwj6{color:#3b4057}.hOvwj6 path{fill:currentColor}.hOvwj6.Y5ZwGD{color:#116dff}.QZbU9Q{padding-bottom:6px}.ZFhxHL path{fill:#116dff}.ZFhxHL div{color:#116dff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"corvid-tree-prefix-icon": "hOvwj6",
	"corvidTreePrefixIcon": "hOvwj6",
	"highlight": "Y5ZwGD",
	"section-container": "QZbU9Q",
	"sectionContainer": "QZbU9Q",
	"blue-list-item": "ZFhxHL",
	"blueListItem": "ZFhxHL"
};
export default ___CSS_LOADER_EXPORT___;
