import { ActionType } from '../actions/types';

type findReplaceReducerState = {
  visible: boolean;
};

export type FindReplaceToggleVisible = {
  type: ActionType.FIND_REPLACE_TOGGLE_VISIBLE;
  visible: boolean;
};
type findReplaceReducerAction = FindReplaceToggleVisible;

const initialState: findReplaceReducerState = { visible: false };

const findReplaceReducer = (
  state = initialState,
  action: findReplaceReducerAction,
) => {
  switch (action.type) {
    case ActionType.FIND_REPLACE_TOGGLE_VISIBLE:
      return { ...state, visible: action.visible };
    default:
      return state;
  }
};

export default findReplaceReducer;
