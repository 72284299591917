export const dataHooks = {
  externalDbPanels: {
    container: 'external-db-panels-container',
    primaryButton: 'external-db-panels-select-provider-primary-button',
    secondaryButton: 'external-db-panels-select-provider-secondary-button',
    selectProvider: {
      container: 'external-db-panels-select-provider-container',
      optionsContainer: 'external-db-panels-select-provider-options-container',
      title: 'external-db-panels-select-provider-title',
    },
    addAdapter: {
      container: 'external-db-panels-add-adapter-container',
      adapterConfigDocsButton: 'external-db-panels-add-adapter-docs-button',
      inputFieldsContainer: 'external-db-panels-add-adapter-inputs-container',
      namespaceInputFieldInfoIcon:
        'external-db-panels-add-adapter-namespace-info-icon',
      endpointInputFieldInfoIcon:
        'external-db-panels-add-adapter-endpoint-info-icon',
      secretKeyInputFieldInfoIcon:
        'external-db-panels-add-adapter-secret-key-info-icon',
      namespaceInputField:
        'external-db-panels-add-adapter-namespace-input-field',
      endpointInputField: 'external-db-panels-add-adapter-endpoint-input-field',
      secretKeyInputField:
        'external-db-panels-add-adapter-secret-key-input-field',
      firstStepLabel: 'external-db-panels-first-step-label',
      secondStepLabel: 'external-db-panels-second-step-label',
      showConfiguration: 'external-db-panels-show-config',
      hideConfiguration: 'external-db-panels-hide-config',
    },
    connectingLoader: {
      container: 'external-db-panels-loader-container',
      loadingMsg: 'external-db-panels-loading-msg',
    },
    editProvider: {
      warningBanner: 'external-db-panels-edit-provider-warning-banner',
      warningModal: 'platform-error-panel',
    },
    confirmationPanel: {
      container: 'external-db-panels-confirmation-modal-container',
    },
    errorPanel: {
      container: 'external-db-panels-error-modal-container',
    },
    messageModal: {
      container: 'external-db-panels-message-modal',
    },
    mainScreen: {
      container: 'external-db-panels-main-screen',
      flowSelector: 'external-db-panels-flow-selector',
    },
    createDb: {
      promotionalModal: 'external-db-panels-promotional-modal',
      enterDetails: 'external-db-panels-promotional-modal',
      namespaceInput: 'external-db-panels-create-db-namespace-input',
      dropdown: 'external-db-panels-create-db-dropdown-input',
      dropdownErrorIcon: 'external-db-panels-dropwdown-error-icon',
      errorModal: 'external-db-panels-creation-error',
      dbCreationInProgressModal: 'external-db-panels-db-creation-in-progress',
    },
  },
};
