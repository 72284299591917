import * as React from 'react';
import dataHooks from '../../../../../dataHooks';
import { InstalledPackagesListSection } from './InstalledPackagesListSection';
import { codeReusePkgsSelectors } from '../../../../../packagesView/packagesViewSelectors';
import { PackagesModalContext } from '../../../../../packagesModalContext';
import { useSelector } from '@wix/wix-code-common-components';

export const InstalledPkgsListBuiltByVeloSection: React.FC = () => {
  const builtByVelo = useSelector(codeReusePkgsSelectors.installed.byVelo);
  const { isBlocksCombinedMode } = React.useContext(PackagesModalContext);

  const sectionTitle = isBlocksCombinedMode
    ? 'Package_Manager_Section_Manage_Installed_Built_By_Velo_Apps'
    : 'Package_Manager_Section_Manage_Installed_Built_By_Velo';
  return (
    <InstalledPackagesListSection
      dataHook={dataHooks.INSTALLED_PKGS_BUILT_BY_VELO}
      sectionTitle={sectionTitle}
      installedPkgs={builtByVelo}
    />
  );
};
