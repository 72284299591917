import {
  addNpmDependency,
  listDependencies,
  removeNpmDependency,
  addVeloDependency,
  removeVeloDependency,
} from '@wix/ambassador-velo-apps-v2-dependency/http';
import {
  AddNpmDependencyRequest,
  DependencyFilter,
  CodePackageType,
} from '@wix/ambassador-velo-apps-v2-dependency/types';

import { HttpClient } from '@wix/http-client';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';

export interface WixCodePackageData {
  packageId?: string;
  npmFrontEndPackageName?: string | null;
  npmBackEndPackageName?: string | null;
  privateRelease?: boolean | null;
}
export interface Dependency {
  wixOptions?: WixCodePackageData;
  packageName?: string;
  packageType?: CodePackageType;
  installedVersion?: string;
  resolvedVersion?: string | null;
}

export const createPackagesAPIService = (editorAPI: EditorAPI) => {
  const gridAppId = editorAPI.wixCode.fileSystem.getViewerInfo().gridAppId;
  const httpClient = new HttpClient({
    getAppToken: () => editorAPI.wixCode.getClientSpec().instance,
  });
  const installNpmPackage = async (name: string, version: string) => {
    const addNpmDependencyRequest: AddNpmDependencyRequest = {
      consumerGridAppId: gridAppId,
      npmPackageInfo: {
        name,
        version,
      },
    };
    try {
      const { data } = await httpClient.request(
        addNpmDependency(addNpmDependencyRequest),
      );
      return data.jobId;
    } catch (e) {
      throw new Error(`Failed to install npm package ${name}@${version}`);
    }
  };
  const listNpmDependencies = async () => {
    try {
      const { data } = await httpClient.request(
        listDependencies({
          consumerGridAppId: gridAppId,
          dependencyFilter: DependencyFilter.NPM,
        }),
      );
      return data.dependencies || [];
    } catch (error) {
      throw new Error(`Failed to list npm dependencies`);
    }
  };
  const listWixDependencies = async () => {
    try {
      const { data } = await httpClient.request(
        listDependencies({
          consumerGridAppId: gridAppId,
          dependencyFilter: DependencyFilter.WIX,
        }),
      );
      return data.dependencies || [];
    } catch (error) {
      throw new Error(`Failed to list Wix dependencies`);
    }
  };
  const uninstallNpmPackage = async (name: string) => {
    try {
      const { data } = await httpClient.request(
        removeNpmDependency({
          consumerGridAppId: gridAppId,
          packageName: name,
        }),
      );
      return data.jobId;
    } catch (e) {
      throw new Error(`Failed to uninstall npm package ${name}`);
    }
  };
  const installVeloPackage = async (appDefId: string, version: string) => {
    try {
      await httpClient.request(
        addVeloDependency({ consumerGridAppId: gridAppId, appDefId, version }),
      );
    } catch (e) {
      throw new Error(`Failed to install velo package `);
    }
  };
  const uninstallVeloPackage = async (appDefId: string) => {
    try {
      await httpClient.request(
        removeVeloDependency({ consumerGridAppId: gridAppId, appDefId }),
      );
    } catch (e) {
      throw new Error(`Failed to uninstall velo package `);
    }
  };

  return {
    installNpmPackage,
    listNpmDependencies,
    listWixDependencies,
    uninstallNpmPackage,
    gridAppId,
    installVeloPackage,
    uninstallVeloPackage,
  };
};
export type PackagesAPIService = ReturnType<typeof createPackagesAPIService>;
