import { WIX_CODE_DUPLEXER } from '@wix/app-definition-ids';
import type { requests } from '../generated/client/proto-generated';

export const appDefId = WIX_CODE_DUPLEXER;
export const channelName = `wix-code-lifecycle-events`;
interface WixCodeDuplexerEventNames {
  CODE_CHANGED: 'CODE_CHANGED';
  TEAMWORK_GITHUB_INTEGRATION: 'TEAMWORK_GITHUB_INTEGRATION';
  GITHUB_COMMITS: 'GITHUB_COMMITS';
  DEPENDENCIES_CHANGED: 'DEPENDENCIES_CHANGED';
}
export const eventNames: WixCodeDuplexerEventNames = {
  CODE_CHANGED: `CODE_CHANGED`,
  TEAMWORK_GITHUB_INTEGRATION: `TEAMWORK_GITHUB_INTEGRATION`,
  GITHUB_COMMITS: `GITHUB_COMMITS`,
  DEPENDENCIES_CHANGED: `DEPENDENCIES_CHANGED`,
};

export type CodeChangedEvent = requests.wix.velo.duplexer.v1.CodeChanged;
export type TeamworkGithubIntegrationEvent =
  requests.wix.velo.duplexer.v1.VeloGitHubIntegration;
export type GithubCommitsEvent = requests.wix.velo.duplexer.v1.GitHubCommits;
export type DependenciesChangedEvent =
  requests.wix.velo.duplexer.v1.DependenciesChanged;
