import { APIKeys, EntryPoint, Shell } from '@wix/wix-code-repluggable';
import { WixCodeReadOnlyState, readOnlyReducer } from './state';
import { createReadOnlyAPI } from './ReadOnlyAPICreator';

export const ReadOnlyEntryPoint: EntryPoint = {
  name: 'Read Only',

  getDependencyAPIs() {
    return [APIKeys.ClassicEditorAPI];
  },

  declareAPIs() {
    return [APIKeys.ReadOnlyAPI];
  },

  attach(shell: Shell) {
    shell.contributeState<WixCodeReadOnlyState>(() => ({
      readOnlyState: readOnlyReducer,
    }));

    shell.contributeAPI(APIKeys.ReadOnlyAPI, () =>
      createReadOnlyAPI(
        shell.getStore<WixCodeReadOnlyState>(),
        shell.getAPI(APIKeys.ClassicEditorAPI),
      ),
    );
  },
};
