import {
  PackageStatus,
  PackageChangelogAction,
  RequestValidationState,
} from '@wix/wix-code-plugin-contracts';

export const packageStatus: Record<string, PackageStatus> = {
  AVAILABLE: 'AVAILABLE',
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  PENDING_OPERATOR_APPROVAL: 'PENDING_OPERATOR_APPROVAL',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  REJECTED: 'REJECTED',
  DELETED: 'DELETED',
  UNKNOWN: 'UNKNOWN',
  NOT_FOUND: 'NOT_FOUND',
};

export const packageChangelogAction: Record<string, PackageChangelogAction> = {
  MODIFIED: 'MODIFIED',
  CREATED: 'CREATED',
  AVAILABLE: 'AVAILABLE',
};

export const requestValidationState: Record<string, RequestValidationState> = {
  ERROR_VERSION_ALREADY_AVAILABLE: 'ERROR_VERSION_ALREADY_AVAILABLE',
  ERROR_VERSION_DOES_NOT_EXIST: 'ERROR_VERSION_DOES_NOT_EXIST',
  ERROR_VERSION_IS_EMPTY: 'ERROR_VERSION_IS_EMPTY',
  VALID: 'VALID',
};
