import React from 'react';
import s from '../SpiWizard.scss';
import { Text } from '@wix/wix-base-ui';
import { dataHooks } from '../../../consts/dataHooks';
import { useTranslation } from 'react-i18next';
import { SpiProviderType } from '../../../SpiTypes';

export const WizardStep3DynamicFileNames: React.FC<{
  spiName: string;
  spiType: SpiProviderType;
}> = ({ spiName, spiType }) => {
  const [t] = useTranslation();
  return (
    <div className={s.dynamicFilesContainer}>
      <Text size="medium" skin="secondary" shouldTranslate={false}>
        {t('Wizard_Phase_3_Files_Description')}
      </Text>
      <div className={s.smallMargin} />
      <div className={s.step3FilesDescription}>
        <Text
          size="small"
          shouldTranslate={false}
          dataHook={dataHooks.SPI_WIZARD_STEP_3_CONFIG_FILE}
        >
          {`• ${spiName}-config.js`}
        </Text>
        <div className={s.smallMarginLeft} />
        <Text size="small" skin="secondary" shouldTranslate={false}>
          {t('Wizard_Phase_3_Config_Description')}
        </Text>
      </div>
      <div className={s.step3FilesDescription}>
        <Text
          size="small"
          shouldTranslate={false}
          dataHook={dataHooks.SPI_WIZARD_STEP_3_JS_FILE}
        >
          {`• ${spiName}.js`}
        </Text>
        <div className={s.smallMarginLeft} />
        <Text size="small" skin="secondary" shouldTranslate={false}>
          {t(spiType.translationKeys.wizardPhase3JsFileDescription)}
        </Text>
      </div>
    </div>
  );
};
