// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".w7TQGQ{padding:20px;display:flex;justify-content:space-between;border-bottom:1px solid;border-color:#dfe5eb;align-items:center}.lHPLDQ{display:flex;flex-direction:column}.nbFZ7G{display:flex;flex-direction:column;width:100%;overflow:hidden;background-color:#f7f8f8}.W83KLM{margin-bottom:5px}.Jov3at{width:85px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"readme-explanation-header": "w7TQGQ",
	"readmeExplanationHeader": "w7TQGQ",
	"readme-labels-container": "lHPLDQ",
	"readmeLabelsContainer": "lHPLDQ",
	"readme-container": "nbFZ7G",
	"readmeContainer": "nbFZ7G",
	"readme-header-container": "W83KLM",
	"readmeHeaderContainer": "W83KLM",
	"readme-preview-btn": "Jov3at",
	"readmePreviewBtn": "Jov3at"
};
export default ___CSS_LOADER_EXPORT___;
