// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/@wix/yoshi-style-dependencies/css-loader.js??ruleSet[1].rules[3].rules[1].oneOf[0]!../../../../../../node_modules/@wix/yoshi-style-dependencies/postcss-loader.js??ruleSet[1].rules[3].rules[2]!../../../../../../node_modules/@wix/yoshi-style-dependencies/resolve-url-loader.js!../../../../../../node_modules/@wix/yoshi-style-dependencies/sass-loader.js??ruleSet[1].rules[3].rules[4]!../../../../../../node_modules/github-markdown-css/github-markdown-light.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".markdown-body pre{overflow:unset}.markdown-body code{-webkit-user-select:text;-moz-user-select:text;-ms-user-select:text;user-select:text}.markdown-body code *{-webkit-user-select:text;-moz-user-select:text;-ms-user-select:text;user-select:text}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
