import * as React from 'react';
import dataHooks from '../../../../dataHooks';
import s from './CodeReusePkgsList.scss';
import { RequestPackage } from './RequestPackage';
import { CodeReusePkgListItem } from './CodeReusePkgListItem';
import { ListSectionTitle } from '../listSectionTitle/ListSectionTitle';
import { codeReusePkgsSelectors } from '../../../../packagesView/packagesViewSelectors';
import { ListSectionDescription } from '../listSectionDescription/ListSectionDescription';
import { PackageVersionPicker } from '../packageVersions/PackageVersionPicker';
import {
  getIsVersionPickerShowing,
  getVeloPkgsSearchKeyword,
} from '../../../packagesModalSelectors';
import { AvailableCodeReusePkg } from '../../../../packagesModalContext';
import { BuiltByVeloListEmptyState } from './BuiltByVeloListEmptyState';
import { BuiltByVeloSearchBar } from './BuiltByVeloSearchBar';
import { PackageItemContextProvider } from '../PackageItemContextProvider';
import { PkgTreeContext } from '@/sidebar/packagesTab/pkgTree/PkgTreeContext';
import { useSelector } from '@wix/wix-code-common-components';

export const BuiltByVeloPkgList: React.FC = () => {
  const { isBlocksCombinedMode } = React.useContext(PkgTreeContext);
  const packages = useSelector(codeReusePkgsSelectors.available.byVelo);
  const showVersionPicker = useSelector(getIsVersionPickerShowing);
  const searchKeyword = useSelector(getVeloPkgsSearchKeyword);

  const filterPackage = (pkg: AvailableCodeReusePkg) => {
    const lowerCaseSearchKeyword = searchKeyword.toLowerCase();
    return (
      pkg.name.toLowerCase().includes(lowerCaseSearchKeyword) ||
      pkg.title.toLowerCase().includes(lowerCaseSearchKeyword) ||
      pkg.description?.toLowerCase().includes(lowerCaseSearchKeyword)
    );
  };

  const pkgsToShow = packages.filter(filterPackage);

  return showVersionPicker ? (
    <PackageVersionPicker />
  ) : (
    <div
      className={s.scroller}
      data-hook={dataHooks.BUILT_BY_VELO_PACKAGES_LIST}
    >
      <ListSectionTitle
        title={
          isBlocksCombinedMode
            ? 'Package_Manager_Built_By_Velo_Apps_Section_Title'
            : 'Package_Manager_Built_By_Velo_Section_Title'
        }
      />
      <ListSectionDescription
        titleKey={
          isBlocksCombinedMode
            ? 'Package_Manager_Velo_Packages_Subtitle_Of_Blocks'
            : 'Package_Manager_Velo_Packages_Subtitle'
        }
        learnMoreUrlKey="Package_Manager_Velo_Packages_LearnMore_Link"
      />
      <BuiltByVeloSearchBar />
      {pkgsToShow.length > 0 ? (
        <>
          {pkgsToShow.map((pkg) => (
            <PackageItemContextProvider pkgName={pkg.name} key={pkg.id}>
              <CodeReusePkgListItem />
            </PackageItemContextProvider>
          ))}
          <RequestPackage />
        </>
      ) : (
        <BuiltByVeloListEmptyState searchKeyword={searchKeyword} />
      )}
    </div>
  );
};
