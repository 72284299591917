import * as React from 'react';
import dataHooks from '../../dataHooks';
import { PackagesModalContext } from '../../packagesModalContext';
import { MODAL_TABS } from '../packagesModalConsts';
import { PackageMarkDown } from './packageMarkDown/PackageMarkDown';
import s from './packagesModalContent.scss';
import { PackagesModalMenu } from './packagesModalMenu/PackagesModalMenu';
import { BuiltByVeloPkgList } from './pkgLists/codeReusePkgsList/BuiltByVeloPkgList';
import { MyPackagesPkgList } from './pkgLists/codeReusePkgsList/MyPackagesPkgList';
import { InstalledPackagesList } from './pkgLists/installedPackagesList/InstalledPackagesList';
import { NpmPackagesList } from './pkgLists/npmPackagesList/NpmPackagesList';
import { PackageItemContextProvider } from './pkgLists/PackageItemContextProvider';

const PackagesModalContent: React.FC = () => {
  const { currentTab, selectedPackage } =
    React.useContext(PackagesModalContext);

  return (
    <div
      className={s.packagesModalContent}
      data-hook={dataHooks.PKG_MODAL_CONTAINER}
    >
      <PackagesModalMenu />
      <div>
        {currentTab === MODAL_TABS.NPM_PACKAGES && <NpmPackagesList />}
        {currentTab === MODAL_TABS.INSTALLED_PACKAGES && (
          <InstalledPackagesList />
        )}
        {currentTab === MODAL_TABS.BUILT_BY_VELO && <BuiltByVeloPkgList />}
        {currentTab === MODAL_TABS.MY_PACKAGES && <MyPackagesPkgList />}
      </div>
      {selectedPackage && (
        <div className={s.markdown} data-hook={dataHooks.PKG_MODAL_MARKDOWN}>
          <PackageItemContextProvider pkgName={selectedPackage}>
            <PackageMarkDown />
          </PackageItemContextProvider>
        </div>
      )}
    </div>
  );
};

export default PackagesModalContent;
