import { createSpiLeftTreeSection } from './SpiLeftTreeSection';
import React from 'react';
import {
  FilesTreeAPI,
  ExperimentsAPI,
  WixCodeAppAPI,
  PanelsAPI,
  UserPreferencesAPI,
  DuplexerAPI,
  SiteAPI,
  FileSystemAPI,
  ServiceTopology,
  SectionProps,
  BiLoggerAPI,
  ReadOnlyAPI,
  PackagesTabAPI,
  VSCodePublicAPI,
} from '@wix/wix-code-plugin-contracts';
import { experimentUtils } from '@wix/wix-code-common';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { i18n as i18nType } from '@wix/wix-i18n-config';
import { isSpiFolder, SpiService } from '../../SpiService';
import { SpiContextProvider } from '../../SpiContext';
import { Shell } from '@wix/wix-code-repluggable';

export interface contributeLeftTreeSectionProps {
  i18n: i18nType;
  filesTreeAPI: FilesTreeAPI;
  experimentsAPI: ExperimentsAPI;
  wixCodeAppAPI: WixCodeAppAPI;
  panelsAPI: PanelsAPI;
  userPreferencesAPI: UserPreferencesAPI;
  duplexerAPI: DuplexerAPI;
  siteAPI: SiteAPI;
  fileSystemAPI: FileSystemAPI;
  serviceTopology: ServiceTopology;
  spiService: SpiService;
  shell: Shell;
  biLoggerAPI: BiLoggerAPI;
  readOnlyAPI: ReadOnlyAPI;
  packagesTabAPI: PackagesTabAPI;
  wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI;
  vsCodePublicAPI: VSCodePublicAPI;
}

export const contributeLeftTreeSection = ({
  i18n,
  filesTreeAPI,
  experimentsAPI,
  wixCodeAppAPI,
  panelsAPI,
  userPreferencesAPI,
  duplexerAPI,
  siteAPI,
  fileSystemAPI,
  serviceTopology,
  spiService,
  shell,
  biLoggerAPI,
  readOnlyAPI,
  packagesTabAPI,
  wixCodeEditorAdapterAPI,
  vsCodePublicAPI,
}: contributeLeftTreeSectionProps) => {
  const isLiteEditor = experimentUtils.isLiteEditor();
  filesTreeAPI.contributeFileValidationRule(isSpiFolder);
  const SpiLeftTreeSection = createSpiLeftTreeSection(
    shell,
    isLiteEditor,
    vsCodePublicAPI.openVSCodeOnlineTab,
  );
  const SPISection = (props: SectionProps) => (
    <SpiContextProvider
      i18nInstance={i18n}
      {...{
        spiService,
        filesTreeAPI,
        experimentsAPI,
        wixCodeAppAPI,
        panelsAPI,
        userPreferencesAPI,
        duplexerAPI,
        siteAPI,
        fileSystemAPI,
        serviceTopology,
        biLoggerAPI,
        readOnlyAPI,
        wixCodeEditorAdapterAPI,
      }}
    >
      <SpiLeftTreeSection {...props} />
    </SpiContextProvider>
  );
  if (isLiteEditor) {
    packagesTabAPI.contributeSection({
      id: 'spi',
      Component: SPISection,
    });
  } else {
    filesTreeAPI.contributeSection({
      id: 'SpiSection',
      order: 1,
      createSection: SPISection,
    });
  }
};
