import React from 'react';
import { PanelHeader } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import { PanelsAPI } from '@wix/wix-code-plugin-contracts';

interface GithubTabLiteWrapperProps extends React.PropsWithChildren<any> {
  closePanel?: () => void;
  panelsAPI: PanelsAPI;
}
export const GithubTabLiteWrapper = ({
  children,
  closePanel,
  panelsAPI,
}: GithubTabLiteWrapperProps) => {
  const [t] = useTranslation();

  const onOpenHelp = () => {
    panelsAPI.openHelpCenter('0b8ddada-12c4-4fe8-b6fb-c90cf2b73a2d');
  };

  return (
    <>
      <PanelHeader
        onHelp={onOpenHelp}
        onClose={closePanel}
        className="dragger dark"
        defaultCursor
      >
        {t('lite_panel.menu.github')}
      </PanelHeader>
      {children}
    </>
  );
};

export const createGithubTabLiteWrapper = ({
  closePanel,
  panelsAPI,
  children,
}: GithubTabLiteWrapperProps) => (
  <GithubTabLiteWrapper closePanel={closePanel} panelsAPI={panelsAPI}>
    {children}
  </GithubTabLiteWrapper>
);
