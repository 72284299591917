import React from 'react';
import { Text, TextButton } from '@wix/wix-base-ui';
import s from './appsTooltipContent.scss';

type TooltipContentProps = {
  text: string;
  link: string;
  learnMore: string;
};

export const AppsTooltipContent: React.FC<TooltipContentProps> = ({
  text,
  link,
  learnMore,
}) => {
  return (
    <div className={s.tooltipContainer}>
      <Text size="small" weight="thin" enableEllipsis={false}>
        {text}
      </Text>
      <TextButton size="small" weight="bold" onClick={() => window.open(link)}>
        {learnMore}
      </TextButton>
    </div>
  );
};
