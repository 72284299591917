import React, { useContext } from 'react';
import * as _ from 'lodash';
import {
  NpmPackageInfo,
  PackagesModalContext,
} from '../../../../../packagesModalContext';
import { packageStatus } from '../../npmPackagesList/consts';
import { PackageItemContext } from '../../PackageItemContext';
import {
  getUpgradableVersion,
  isInstalledReusePackageByOthers,
  isInstalledTestVersion,
  isModulePending,
  isModuleRejected,
  isSameVersion,
  isInstallationInProgress,
} from '../../../../../utils';
import { UpdateButton } from './UpdateButton';
import { PkgPreloaderButton } from './PkgPreloaderButton';
import { ViewPkgButton } from './ViewPkgButton';
import { InstallPkgButton } from './InstallPkgButton';
import { InstalledLabel } from './InstalledLabel';
import { RequestNpmPkgLabel } from './RequestNpmPkgLabel';
import { experimentUtils } from '@wix/wix-code-common';
import { NpmPkgLabel } from './NpmPackageLabel';
import dataHooks from '@/sidebar/dataHooks';
import { DisabledInstallPkgButton } from './DisabledInstallPkgButton';

export const PkgActionButton: React.FC = () => {
  const { justInstalledPkgs, justRequestedPkgs, availablePkgs, t } =
    React.useContext(PackagesModalContext);

  const {
    installedPkg,
    availablePkg,
    pkgName,
    installationStatus,
    isCodeReusePackage,
  } = useContext(PackageItemContext);

  const isInstalled = installedPkg || installationStatus === 'INSTALLED';
  const isJustInstalled = justInstalledPkgs.includes(pkgName) && isInstalled;
  const isJustRequested = justRequestedPkgs.includes(pkgName);
  const isPackageUpgradable = () => {
    if (isCodeReusePackage) {
      return (
        !!installedPkg &&
        !isSameVersion(installedPkg?.version, availablePkg.version) &&
        !isInstalledTestVersion(installedPkg) &&
        !isInstalledReusePackageByOthers(pkgName)
      );
    }
    return isInstalled && getUpgradableVersion(availablePkg);
  };

  const shouldShowUpdateButton = isPackageUpgradable();

  const isNpmPkgAvailable = (): boolean => {
    const status = _.get(availablePkg, ['_version', 'status']);
    return [packageStatus.AVAILABLE, packageStatus.APPROVED].includes(status);
  };
  const installationInProgress =
    experimentUtils.isAnyNpmFirstPhase() &&
    (isInstallationInProgress() ||
      availablePkgs.codeReuse.some((pkg) => pkg.status === 'INSTALLING'));
  const isLoader = ['UPDATING', 'INSTALLING'].includes(installationStatus!);

  const canInstall =
    isCodeReusePackage || experimentUtils.isAnyNpmNewExperience()
      ? !isInstalled
      : !isInstalled && isNpmPkgAvailable();

  const showRequestNpmPkgLabel =
    availablePkg &&
    !isCodeReusePackage &&
    experimentUtils.isNpmPkgManagerImprovements();
  const renderActionButton = () => {
    if (isLoader) {
      return <PkgPreloaderButton />;
    }
    if (isJustInstalled) {
      return <ViewPkgButton disabled={installationInProgress} />;
    }
    if (canInstall) {
      if (installationInProgress) {
        return <DisabledInstallPkgButton />;
      }
      return <InstallPkgButton />;
    }
    if (shouldShowUpdateButton) {
      return <UpdateButton disabled={installationInProgress} />;
    }
    if (isInstalled) {
      return <InstalledLabel />;
    }
    if (showRequestNpmPkgLabel) {
      if (isModulePending(availablePkg) || isJustRequested) {
        return (
          <NpmPkgLabel
            dataHook={dataHooks.PENDING_NPM_PKG_LABEL}
            labelText={t('Packages_Modal_Npm_Pending_Pkg_Label')}
            tooltipLink={t(
              'Package_Manager_Popup_Available_Tab_Rejected_Request_Tooltip_Link_Url',
            )}
            tooltipLinkText={t(
              'Package_Manager_Popup_Available_Tab_Pending_Request_Tooltip_Link',
            )}
            tooltipText={t(
              'Package_Manager_Popup_Available_Tab_Pending_Request_Tooltip_Description',
            )}
          />
        );
      }
      if (isModuleRejected(availablePkg)) {
        return (
          <NpmPkgLabel
            labelText={t('Packages_Modal_Npm_Rejected_Pkg_Label')}
            tooltipLink={t(
              'Packages_Modal_Npm_Request_Pkg_Label_Tooltip_Link_Url',
            )}
            tooltipLinkText={t(
              'Package_Manager_Popup_Available_Tab_Rejected_Request_Tooltip_Link',
            )}
            tooltipText={t(
              'Package_Manager_Popup_Available_Tab_Rejected_Request_Tooltip_Description',
            )}
            dataHook={dataHooks.NOT_SUPPORTED_NPM_PKG_LABEL}
          />
        );
      }
      return (
        <RequestNpmPkgLabel
          availablePkg={availablePkg as NpmPackageInfo}
          disabled={installationInProgress}
        />
      );
    }

    return null;
  };

  return <>{renderActionButton()}</>;
};
