// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c4C36N .P6SMQe{display:flex;align-items:center;margin-bottom:6px}.c4C36N .aSTnD4{margin-right:8px;color:#868aa5}.c4C36N .cw_4KW{display:flex;align-items:center;margin-bottom:5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"semantic-class-names-container": "c4C36N",
	"semanticClassNamesContainer": "c4C36N",
	"section-label-container": "P6SMQe",
	"sectionLabelContainer": "P6SMQe",
	"section-label": "aSTnD4",
	"sectionLabel": "aSTnD4",
	"class-control": "cw_4KW",
	"classControl": "cw_4KW"
};
export default ___CSS_LOADER_EXPORT___;
