// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I5ufJZ{width:50%}.I5ufJZ .composite-dropdown-labeled{padding:0}.I5ufJZ .composite-dropdown-labeled .control-label-base{font-size:14px;line-height:20px}.I5ufJZ .composite-dropdown-labeled .has-tooltip.info-icon-tooltip{right:0;top:0}.p4_Z99{width:24px;height:24px;border-radius:50%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gitAccountDropdown": "I5ufJZ",
	"gitOrgAvatar": "p4_Z99"
};
export default ___CSS_LOADER_EXPORT___;
