import React from 'react';
import { TextLabel, RichText } from '@wix/wix-base-ui';
import s from './tbfPage.scss';
import { useTranslate } from '@/infra/i18n/useTranslate';

export const TbfPageRightPane: React.FC = () => {
  const { t } = useTranslate();

  return (
    <div className={s.pageRightPane}>
      <div className={`${s.pageHeader} tbf-pane-header`}>
        <TextLabel value={t('Trigger_Function_RightPanel_Title')} type="T06" />
      </div>
      <div className={s.pageDescription}>
        <RichText type="T12" className={s.pageDescriptionText}>
          {t('Trigger_Function_RightPanel_Text')}
        </RichText>
        <RichText type="T12" className={s.pageDescriptionText}>
          {t('Trigger_Function_RightPanel_Secondary_Text')}
          &nbsp;
          <a
            href={t('Trigger_Function_RightPanel_LinkURL')}
            target="_blank"
            rel="noreferrer"
          >
            {t('Trigger_Function_RightPanel_LinkText')}
          </a>
        </RichText>
      </div>
    </div>
  );
};
