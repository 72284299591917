import React from 'react';
import cx from 'classnames';
import { Button, Tooltip } from '@wix/wix-base-ui';
import { ExternalLink } from 'wix-ui-icons-common';
import topBarStyles from '@wix/wix-code-common/src/style/CodeEditorTopBarButton.scss';
import { dataHooks } from '../dataHooks';
import { i18n, I18nextProvider, useTranslation } from '@wix/wix-i18n-config';
import { VSCodePublicAPI, BiLoggerAPI } from '@wix/wix-code-plugin-contracts';
import { CODE_PANEL_ITEMS, codePanelEvent } from '../../bi';
import { wixIdeButton } from '@wix/bi-logger-platform/v2';
import s from './VSCodeButton.scss';

interface ButtonProps {
  vsCodeApi: VSCodePublicAPI;
  biLoggerAPI: BiLoggerAPI;
}

export const VSCodeButtonComp: React.FC<ButtonProps> = ({
  vsCodeApi,
  biLoggerAPI,
}) => {
  const [t] = useTranslation();
  const onClickHandler = () => {
    vsCodeApi.openVSCodeOnlineTab();
    biLoggerAPI.report(codePanelEvent(CODE_PANEL_ITEMS.VELO_PRO));
    biLoggerAPI.report(
      wixIdeButton({
        action: 'click',
        input_name: 'code_in_wix_ide',
        origin: 'ide_tool_bar',
      }),
    );
  };
  return (
    <Tooltip
      content={t('VSCode.tooltip')}
      marginTop={4}
      maxWidth={200}
      dataHook={dataHooks.tooltip}
    >
      <div className={s.container}>
        <Button
          onClick={onClickHandler}
          dataHook={dataHooks.codeEditorVSCodeButton}
          className={cx(topBarStyles.Button, s.cta, 'btn-confirm-secondary')}
        >
          {t('VSCode.button')}
          <ExternalLink />
        </Button>
      </div>
    </Tooltip>
  );
};

export const vscodeButtonCreator =
  (vsCodeApi: VSCodePublicAPI, i18nInstance: i18n, biLoggerAPI: BiLoggerAPI) =>
  () =>
    (
      <I18nextProvider i18n={i18nInstance}>
        <VSCodeButtonComp vsCodeApi={vsCodeApi} biLoggerAPI={biLoggerAPI} />
      </I18nextProvider>
    );
