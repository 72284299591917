import * as React from 'react';
import dataHooks from '../../../../dataHooks';
import s from './CodeReusePkgsList.scss';

import { getVeloPkgsSearchKeyword } from '../../../packagesModalSelectors';
import { Search } from '@wix/wix-base-ui';
import { searchVeloPackage } from '../../../packagesModalActions';
import { PkgTreeContext } from '@/sidebar/packagesTab/pkgTree/PkgTreeContext';
import { useTranslate } from '@/infra/i18n/useTranslate';
import { useDispatch, useSelector } from '@wix/wix-code-common-components';

export const BuiltByVeloSearchBar: React.FC = () => {
  const searchKeyword = useSelector(getVeloPkgsSearchKeyword);
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const { isBlocksCombinedMode } = React.useContext(PkgTreeContext);

  return (
    <Search
      value={searchKeyword}
      placeholder={
        isBlocksCombinedMode
          ? t('WixCode_Packages_Modal_Velo_Apps_Search_Placeholder')
          : t('WixCode_Packages_Modal_Velo_Search_Placeholder')
      }
      onChange={(value: AnyFixMe) => {
        dispatch(searchVeloPackage(value));
      }}
      options={[]}
      onClear={() => {
        dispatch(searchVeloPackage(''));
      }}
      className={s.searchInput}
      dataHook={dataHooks.VELO_SEARCH_BOX_INPUT}
    />
  );
};
