/**
 * Bootstraps WixCode's redux and top level react components.
 */
import _ from 'lodash';
import storeManager from '@/infra/redux-state/store/storeManager';
import { rootReducer } from '@/infra/redux-state/reducers/rootReducer';

import once_ from 'lodash/once';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';

export default once_(({ util, experiment }) => {
  function initialize(editorAPI: EditorAPI) {
    const query = util.url.parseUrlParams(window.location);
    const isDebug = query.debug === 'all' || _.includes(query.debug, 'wixCode');

    return storeManager.initialize(rootReducer, isDebug, editorAPI, experiment);
  }

  return {
    initialize,
  };
});
