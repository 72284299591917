import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import {
  i18n as I18n,
  initI18n,
  initOptions as I18nInitOptions,
} from '@wix/wix-i18n-config';
import { clientExperimentsService } from '@wix/wix-code-common';
import { reportTranslationKeyUsageCreator } from './reportTranslationKeyUsage';

let _i18nInstance: I18n | null = null;

export const getI18nInstance = () => {
  if (_i18nInstance) {
    return _i18nInstance;
  }
  throw Error('i18n is not yet initialized');
};

export const initI18Next = async ({
  util,
  editorAPI,
  experimentsService,
  staticMessages,
}: {
  util: any;
  editorAPI: EditorAPI;
  experimentsService: clientExperimentsService.ClientExperimentService;
  staticMessages?: Record<string, string> /* used for testing */;
}): Promise<void> => {
  if (_i18nInstance && !staticMessages) {
    return;
  }

  const options: I18nInitOptions = {
    locale: util.editorModel.languageCode,
    disableAutoInit: true,
  };

  if (staticMessages) {
    options.messages = staticMessages;
  } else {
    options.asyncMessagesLoader = (locale) =>
      import(`../../assets/locale/messages_${locale}.json`);
  }

  _i18nInstance = initI18n(options);
  patchUtilTranslateFunc(util, _i18nInstance, experimentsService, editorAPI);

  await _i18nInstance.init();
};

function patchUtilTranslateFunc(
  util: any,
  i18nInstance: I18n,
  experimentsService: clientExperimentsService.ClientExperimentService,
  editorAPI: EditorAPI,
) {
  const translate: TFunc = (key, options) => i18nInstance.t(key, options);

  const { reportTranslationKeyUsage } = reportTranslationKeyUsageCreator({
    editorAPI,
    experimentsService,
  });

  const patchedTranslateFunc: TFunc = (key, options) => {
    reportTranslationKeyUsage(key);

    return translate(key, options);
  };
  util.translate = patchedTranslateFunc;
}

export type TFunc = (key: string, options?: any) => string;
