// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Qi6W0W{padding:13px 13px 13px 20px;border-bottom:1px solid #dfe5eb}.Qi6W0W *{cursor:pointer}.Qi6W0W.VaVwRo{background-color:#e7f0ff}.Qi6W0W .yPKdV9{display:flex;align-items:center}.SWrc64{border:solid #dfe5eb;border-width:1px 0 0;padding-top:16px;margin-top:24px;display:flex;gap:8px;justify-content:space-between}.SUuvxQ{display:flex;align-items:flex-start;justify-content:space-between;gap:8px}.FRIjTX{align-items:initial;justify-content:initial}.X1AjnN{padding-right:35px;padding-left:15px}.XHZOxk .X1AjnN{padding-right:22px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"package-item": "Qi6W0W",
	"packageItem": "Qi6W0W",
	"selected": "VaVwRo",
	"row": "yPKdV9",
	"toggle-line": "SWrc64",
	"toggleLine": "SWrc64",
	"auto-update-toggle": "SUuvxQ",
	"autoUpdateToggle": "SUuvxQ",
	"info-icon": "FRIjTX",
	"infoIcon": "FRIjTX",
	"toggle-switch": "X1AjnN",
	"toggleSwitch": "X1AjnN",
	"version-item": "XHZOxk",
	"versionItem": "XHZOxk"
};
export default ___CSS_LOADER_EXPORT___;
