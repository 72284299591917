import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import {
  ClassicEditorAPI,
  DevModeContext,
} from '@wix/wix-code-plugin-contracts';
import {
  fileSystem as fileSystemCommon,
  utilsCreator,
} from '@wix/wix-code-common';
import { withErrorHandling } from '@/infra/monitoring';
import devContextUtilsCreator from '@/utils/devContext';

import { consts } from '@wix/wix-code-consts';

export const loadFileOnContextChange = ({
  classicEditorAPI,
  wixCodeEditorAdapterAPI,
}: {
  classicEditorAPI: ClassicEditorAPI;
  wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI;
}) => {
  const { editorAPI, observeEditorStore } = classicEditorAPI;
  const { isMasterPageContext } = devContextUtilsCreator(
    wixCodeEditorAdapterAPI.legacyDependencies,
  );
  const wixCodeUtils = utilsCreator(wixCodeEditorAdapterAPI.legacyDependencies);
  const { constants } = wixCodeEditorAdapterAPI.legacyDependencies;

  let firstTime = true;
  observeEditorStore(
    () => editorAPI.developerMode.getContext().data?.id,
    () => {
      if (firstTime) {
        firstTime = false;
        return;
      }
      return loadContent(editorAPI.developerMode.getContext());
    },
  );

  function loadContent(newContext: DevModeContext) {
    return withErrorHandling(() => {
      const fileSystemApi = editorAPI.wixCode.fileSystem;
      if (isMasterPageContext(editorAPI)) {
        fileSystemApi.loadPageCode(
          consts.SITE_JS_PAGE_ID,
          wixCodeUtils.getDefaultPageContent(true),
        );
      } else if (
        newContext.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE
      ) {
        fileSystemApi.loadPageCode(
          newContext.data.id,
          wixCodeUtils.getDefaultPageContent(
            wixCodeUtils.isMasterPageId(newContext.data.id),
          ),
        );
      } else if (
        newContext.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.FILE &&
        !fileSystemCommon.codePackages.isPackageFile(newContext.data.id)
      ) {
        fileSystemApi.loadFile(newContext.data.id);
      } else if (
        newContext.type ===
        constants.DEVELOPER_MODE.CONTEXT_TYPES.TRIGGER_BACKEND_FUNCTION
      ) {
        editorAPI.wixCode.tbfService.loadTbfContent(newContext.data);
      }
    })();
  }
};
