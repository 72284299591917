// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gP4meH{height:100%;display:flex;flex-direction:row}.gP4meH>div{flex:1;overflow:hidden}.gP4meH>:first-child{flex:0;overflow:initial}.gP4meH .u2Pm_E{border-left:1px solid #dfe5eb;display:flex;flex-direction:column}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"packages-modal-content": "gP4meH",
	"packagesModalContent": "gP4meH",
	"markdown": "u2Pm_E"
};
export default ___CSS_LOADER_EXPORT___;
