import React, { useContext } from 'react';
import { BI, CONFIGURATION_LINK, Providers } from '../constants';
import { Heading, Spacer, Text, Button, Divider } from '@wix/wix-base-ui';
import { dataHooks } from '../dataHooks';
import { useTranslation } from '@wix/wix-i18n-config';
import { ExternalDbsModalContext } from '../ExternalDbsModalContext';
import styles from './ConnectAdapter.scss';
import { ExternalLinkSmall } from '@wix/wix-base-ui/icons';
import { connectExternalDbPanelAction } from '@wix/bi-logger-platform/v2';

interface ConnectAdapterStep1Props {
  configureAdapterOnClick: () => void;
  provider: Providers;
}

export const ConnectAdapterStep1: React.FC<ConnectAdapterStep1Props> = ({
  configureAdapterOnClick,
  provider,
}) => {
  const [t] = useTranslation();
  const { biLoggerAPI } = useContext(ExternalDbsModalContext);
  return (
    <React.Fragment>
      <div className={styles.adapterConfiguration}>
        <Heading
          appearance="h3"
          dataHook={dataHooks.externalDbPanels.addAdapter.firstStepLabel}
          shouldTranslate={false}
        >
          {t('External_Databases_Modal_Connect_Adapter_Step_1_Title')}
        </Heading>
        <Spacer type="Spacer04" />
        <Text weight="thin" size="small" shouldTranslate={false}>
          {t('External_Databases_Modal_Connect_Adapter_Step_1_Subtitle')}
        </Text>
      </div>
      <Spacer type="Spacer04" />
      <Button
        dataHook={dataHooks.externalDbPanels.addAdapter.adapterConfigDocsButton}
        className="btn-confirm-secondary"
        prefixIcon={<ExternalLinkSmall />}
        onClick={() => {
          biLoggerAPI.report(
            connectExternalDbPanelAction({
              input_name: BI.panels.connectAdapter,
              action_type: BI.actions.click,
              button_name: BI.buttons.configureAdapter,
              selected_provider: t(provider!),
              target_url: t(CONFIGURATION_LINK[provider!]),
            }),
          );
          try {
            configureAdapterOnClick();
          } catch (e: any) {}
        }}
      >
        {t('External_Databases_Modal_Connect_Adapter_Set_Up_Button', {
          provider: t(provider!),
        })}
      </Button>
      <Spacer type="Spacer06" />
      <Divider long />
      <Spacer type="Spacer06" />
      <Heading
        appearance="h3"
        dataHook={dataHooks.externalDbPanels.addAdapter.secondStepLabel}
        shouldTranslate={false}
      >
        {t('External_Databases_Modal_Connect_Adapter_Step_2_Title')}
      </Heading>
    </React.Fragment>
  );
};
