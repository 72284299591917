import {
  EditorAPI,
  ExperimentsAPI,
  FileSystemAPI,
} from '@wix/wix-code-plugin-contracts';
import { ScopedStore } from '@wix/wix-code-repluggable';
import { ShowErrorModal } from '../components/ErrorModal/ErrorModal';
import {
  connectionActionCreators,
  createSelectors,
  LocalEditorState,
} from '../state';
import { CreateNewRevisionError, InitDevEditorError } from './CliErrors';
import createVeloCliService from './veloCliService';

const createLocalEditorService = (
  editorAPI: EditorAPI,
  experimentsAPI: ExperimentsAPI,
  localPort: number | undefined,
  showErrorModal: ShowErrorModal,
  fileSystemAPI: FileSystemAPI,
  localEditorStore: ScopedStore<LocalEditorState>,
  onCliServiceDisconnect: () => void,
) => {
  const selectors = createSelectors(localEditorStore.getState);

  const veloCliService = createVeloCliService(localPort);
  registerCliEvents();

  async function initConnectionWithCLI() {
    try {
      const localEditorGridAppId =
        await veloCliService.connectDevEditorClient();
      localEditorStore.dispatch(connectionActionCreators.connected());
      setEditorToWorkAgainstLocalEditorGridApp(localEditorGridAppId);
      await fileSystemAPI.reloadAllFiles();
      if (!experimentsAPI.isOpen('dm_ideServerNG')) {
        fileSystemAPI.listenToExternalFileSystemChanges();
      }
    } catch (error) {
      if (error instanceof InitDevEditorError) {
        showErrorModal({
          titleKey: error.titleKey,
          contentKey: error.contentKey,
          primaryButtonOnClick: initConnectionWithCLI,
        });
      }
    }
  }

  return {
    initConnectionWithCLI,
    syncChanges: async () => {
      const revision = await saveNewRevision();
      await veloCliService.syncChanges(revision);
    },
  };

  function setEditorToWorkAgainstLocalEditorGridApp(gridAppId: string) {
    editorAPI.wixCode.setIsolatedGridApp(gridAppId);
  }

  function saveNewRevision(): Promise<string> {
    return new Promise((resolve, reject) => {
      editorAPI.saveManager.saveInBackground(
        () => resolve(`${editorAPI.dsRead.generalInfo.getRevision()}`),
        (_e) => reject(new CreateNewRevisionError()),
        'Velo CLI Service',
        {
          isPublish: false,
          isSilent: true,
          preventSiteSavedPanel: true,
        },
      );
    });
  }

  function registerCliEvents() {
    veloCliService.on('createRevision', async () => {
      const revision = await saveNewRevision();
      return { revision };
    });
    veloCliService.on('disconnected', () => {
      localEditorStore.dispatch(connectionActionCreators.disconnected());
      setTimeout(() => {
        if (!selectors.isConnected()) {
          onCliServiceDisconnect();
        }
      }, 2000);
    });
    veloCliService.on('reconnected', () => {
      localEditorStore.dispatch(connectionActionCreators.reconnected());
    });
    veloCliService.on('disconnectedUnrecoverable', () => {
      onCliServiceDisconnect();
    });
  }
};

export default createLocalEditorService;
export type LocalEditorService = ReturnType<typeof createLocalEditorService>;
