import * as React from 'react';
import { TreeSectionDivider, IconButton, Text } from '@wix/wix-base-ui';
import s from './ToolsTree.scss';
import ChevronRight from 'wix-ui-icons-common/classic-editor/ChevronRight';

interface ToolItemProps {
  id: string;
  title: string;
  description: string;
  onClick: (id: string) => void;
  dataHook?: string;
  icon?: React.ReactElement;
}

const ToolItem: React.FC<ToolItemProps> = ({
  id,
  title,
  description,
  onClick,
  dataHook,
  icon = <ChevronRight />,
}) => {
  return (
    <div
      className={s.toolItem}
      onClick={() => onClick(id)}
      data-hook={dataHook}
    >
      <div className={s.textWrapper}>
        <Text dataHook={`${dataHook}-title`} size="small">
          {title}
        </Text>
        <Text
          dataHook={`${dataHook}-description`}
          size="tiny"
          skin="secondary"
          enableEllipsis={false}
        >
          {description}
        </Text>
      </div>
      <div className={s.ctaWrapper}>
        <IconButton priority="inverted">{icon}</IconButton>
      </div>
    </div>
  );
};

interface ToolsTreeProps {
  dataHook: string;
  onSectionClick: (collapsed: boolean) => void;
  toolSectionDataHook: string;
  toolSectionLabel: string;
  tools: ToolItemProps[];
}

export const ToolsTree: React.FC<ToolsTreeProps> = ({
  dataHook,
  onSectionClick,
  toolSectionDataHook,
  toolSectionLabel,
  tools,
}) => {
  const [collapsed, setCollapsed] = React.useState(false);

  return (
    <div data-hook={dataHook}>
      <TreeSectionDivider
        dataHook={toolSectionDataHook}
        collapsed={collapsed}
        hideTopBorder={true}
        label={toolSectionLabel}
        onClick={() => {
          const newState = !collapsed;
          setCollapsed(newState);
          onSectionClick(newState);
        }}
      />
      {!collapsed && tools.map((tool) => <ToolItem key={tool.id} {...tool} />)}
    </div>
  );
};
