import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';

import { createWixCodeAppAPI } from './WixCodeAppAPI';

export const WixCodeAppEntryPoint: EntryPoint = {
  name: 'WixCode App Entry Point',
  getDependencyAPIs() {
    return [APIKeys.ClassicEditorAPI];
  },
  declareAPIs() {
    return [APIKeys.WixCodeAppAPI];
  },
  attach(shell: Shell) {
    const classicEditorAPI = shell.getAPI(APIKeys.ClassicEditorAPI);
    shell.contributeAPI(APIKeys.WixCodeAppAPI, () =>
      createWixCodeAppAPI({ classicEditorAPI }),
    );
  },
};
