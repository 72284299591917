// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AjhQUA{position:relative}.kQmp8z{position:absolute;bottom:2px;right:45px;z-index:1}.sTizyR{padding:4px 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file-tree-node-tooltip-container": "AjhQUA",
	"fileTreeNodeTooltipContainer": "AjhQUA",
	"file-tree-node-tooltip": "kQmp8z",
	"fileTreeNodeTooltip": "kQmp8z",
	"file-tree-in-section-divider-container": "sTizyR",
	"fileTreeInSectionDividerContainer": "sTizyR"
};
export default ___CSS_LOADER_EXPORT___;
