import { NotificationBanner, Text, TextButton } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import React from 'react';
import GoogleIcon from '../../../assets/icons/googleCloudIcon.svg';
import styles from './DetailsModal.scss';

export const DetailsModalNotificationBanner: React.FC = () => {
  const [t] = useTranslation();
  return (
    <NotificationBanner
      type="floating"
      text={
        <div className={styles.infoBannerContainer}>
          <GoogleIcon />
          <div className={styles.infoBannerTextContainer}>
            <Text weight="bold" size="small">
              {t('External_Databases.Modal.CreateNew.SpecsBanner.Header')}
            </Text>
            <div className={styles.margin}>
              <Text size="small" weight="thin">
                {t('External_Databases.Modal.CreateNew.SpecsBanner.Text1')}
              </Text>
              <Text size="small" weight="thin">
                {t('External_Databases.Modal.CreateNew.SpecsBanner.Text2')}
              </Text>
              <Text size="small" weight="thin">
                {t('External_Databases.Modal.CreateNew.SpecsBanner.Text3')}
              </Text>
            </div>
            <TextButton
              size="small"
              onClick={() =>
                window.open(
                  t(
                    'External_Databases.Modal.CreateNew.SpecsBanner.LearnMore.Link',
                  ),
                  '_blank',
                )
              }
            >
              {t(
                'External_Databases.Modal.CreateNew.SpecsBanner.LearnMore.Text',
              )}
            </TextButton>
          </div>
        </div>
      }
    />
  );
};
