import { union, find, keys, findIndex } from 'lodash';
import { ScopedStore, SlotKey } from '@wix/wix-code-repluggable';
import {
  BiLoggerAPI,
  CodeEditorAPI,
  EditorAPI,
  FilesTreeAPI,
  FileSystemAPI,
  UserPreferencesAPI,
  WixCodeDuplexerAPI,
  CodeSavedChanges,
  wixCodeLifecycleDuplexer,
} from '@wix/wix-code-plugin-contracts';
import { ideFileFolderCreated } from '@wix/bi-logger-platform/v2';

import { addCustomClasses, CssEditingStore, setIsExpanded } from './state';
import { getAllCustomClasses, getIsExpanded } from './state/selectors';
import { constants } from './constants';

interface CSSEditingInternalAPIParams {
  store: ScopedStore<CssEditingStore>;
  fileSystemAPI: FileSystemAPI;
  filesTreeAPI: FilesTreeAPI;
  editorAPI: EditorAPI;
  wixCodeDuplexerAPI: WixCodeDuplexerAPI;
  biLoggerAPI: BiLoggerAPI;
  userPreferencesAPI: UserPreferencesAPI;
  codeEditorAPI: CodeEditorAPI;
}

export type TFunc = (key: string, options?: any) => string;

export interface CssEditingInternalAPI {
  setIsExpanded: (isExpanded: boolean) => void;
  getExpanded: () => boolean;
  getAllCustomClasses: () => string[];
  addCustomClass: (customClass: string) => void;
  initState: () => Promise<void>;
  createCssFileAndOpen: (template: string) => Promise<void>;
  selectFile: () => void;
  updateViewerOnCssChanges: () => void;
  generateCssClassCode: (className: string, classContent: string) => void;
  deleteFile: () => Promise<void>;
  getComponentsSemanticClasses: (
    sdkType: string,
    componentType: string,
  ) => string[];
  getComponentCssReference: (sdkType: string) => string | undefined;
}

export const CssEditingInternalAPIKey: SlotKey<CssEditingInternalAPI> = {
  name: 'CssEditingInternalAPI',
  public: false,
};

const createAllCustomClasses = (editorAPI: EditorAPI): string[] => {
  return editorAPI.components
    .getAllComponentsFromFull()
    .flatMap(
      (compRef) =>
        editorAPI.components.features.get(compRef, 'classnames')?.classnames,
    )
    .filter(Boolean);
};

export const createCssEditingInternalAPI = ({
  store,
  fileSystemAPI,
  filesTreeAPI,
  editorAPI,
  wixCodeDuplexerAPI,
  biLoggerAPI,
  userPreferencesAPI,
  codeEditorAPI,
}: CSSEditingInternalAPIParams): CssEditingInternalAPI => {
  const api: CssEditingInternalAPI = {
    setIsExpanded: (isExpanded) => store.dispatch(setIsExpanded(isExpanded)),
    getExpanded: () => getIsExpanded(store.getState()),
    getAllCustomClasses: () => getAllCustomClasses(store.getState()),
    addCustomClass: (customClass: string) => {
      store.dispatch(addCustomClasses([customClass]));
    },
    createCssFileAndOpen: async (template) => {
      await fileSystemAPI.writeFile(constants.global_css_path, template);

      api.selectFile();
      userPreferencesAPI.setGlobalPreference(
        constants.firstTimeBadgeKey,
        Date.now(),
      );
      await fileSystemAPI.refreshFolder(constants.global_css_path);

      biLoggerAPI.report(
        ideFileFolderCreated({
          origin: 'left_tree',
          path: constants.global_css_path,
        }),
      );
    },
    initState: async () => {
      const allCustomClasses = createAllCustomClasses(editorAPI);
      store.dispatch(addCustomClasses(allCustomClasses));
    },
    generateCssClassCode: async (className, classContent = '') => {
      const fileId = constants.global_css_path;
      const fileDescriptor =
        editorAPI.wixCode.fileSystem.getVirtualDescriptor(fileId);
      const fileContent = await editorAPI.wixCode.fileSystem.readFile(
        fileDescriptor,
      );
      const cssClassContent = `\n ${className} ${classContent}\n`;
      const content = fileContent.concat(cssClassContent);

      editorAPI.wixCode.fileSystem.changeContent(fileId, content);
      api.selectFile();
    },
    selectFile: () => {
      filesTreeAPI.selectFile(constants.global_css_path, true);
    },
    updateViewerOnCssChanges: () => {
      editorAPI.wixCode.fileSystem.subscribeToFlush(
        (changes: CodeSavedChanges) => {
          if (
            find(
              changes.toSave,
              ({ fileId }) => fileId === constants.global_css_path,
            ) ||
            findIndex(
              keys(changes.toDelete),
              (fileId) =>
                fileId === constants.global_css_path ||
                fileId === constants.styles_folder,
            ) !== -1
          ) {
            editorAPI.customCSS.refresh();
          }
        },
      );

      wixCodeDuplexerAPI.subscribeTo.codeChanged(
        (payload: wixCodeLifecycleDuplexer.CodeChangedEvent) => {
          const files = union(
            payload.createdOrUpdatedFiles,
            payload.deletedFiles,
            payload.deletedDirectories,
          );
          if (
            find(
              files,
              (path) =>
                path === constants.global_css_path ||
                path === constants.styles_folder,
            )
          ) {
            editorAPI.customCSS.refresh();
          }
        },
      );
      wixCodeDuplexerAPI.subscribeTo.githubCommits(() => {
        editorAPI.customCSS.refresh();
      });
    },
    deleteFile: async () => {
      try {
        await fileSystemAPI.deleteFile(constants.global_css_path);
      } catch (e) {
        console.error(e);
        // log error
      }
    },
    getComponentsSemanticClasses: (sdkType, componentType) => {
      return codeEditorAPI.getComponentsSemanticClasses(sdkType, componentType);
    },
    getComponentCssReference: (sdkType) => {
      return codeEditorAPI.getComponentCssReference(sdkType);
    },
  };
  return api;
};
