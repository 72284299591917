import { wixCodeLifecycleDuplexer } from '@wix/wix-code-plugin-contracts';
import { TeamworkOnboardingStatus } from '../types/GithubTypes';
import { ON_BOARDING_SUPPORTED_ERRORS } from '../constants';

export const ON_BOARDING_STATUS_RECEIVED = 'ON_BOARDING_STATUS_RECEIVED';
export const ON_BOARDING_ERROR = 'ON_BOARDING_ERROR';
export const USER_ACCEPTED_GITHUB_TERMS = 'USER_ACCEPTED_GITHUB_TERMS';
export const NEW_COMMITS_RECEIVED = 'NEW_COMMITS_RECEIVED';
export const LOADING_ONBOARDING_STATUS = 'LOADING_ONBOARDING_STATUS';

export const actions = {
  setOnboardingStatus: (value: TeamworkOnboardingStatus) => ({
    type: ON_BOARDING_STATUS_RECEIVED,
    value,
  }),
  setOnboardingError: (errorType: ON_BOARDING_SUPPORTED_ERRORS | null) => ({
    type: ON_BOARDING_ERROR,
    errorType,
  }),
  acceptGithubTerms: () => ({ type: USER_ACCEPTED_GITHUB_TERMS }),
  githubCommitsReceived: (
    commits: wixCodeLifecycleDuplexer.GithubCommitsEvent['commits'],
  ) => ({
    type: NEW_COMMITS_RECEIVED,
    commits,
  }),
  loadingOnboardingStatus: (value: boolean) => ({
    type: LOADING_ONBOARDING_STATUS,
    value,
  }),
};
