import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { createPlatformAppsAPI } from './PlatformAppsAPI';

export const PlatformAppsEntryPoint: EntryPoint = {
  name: 'Platform Apps Entry Point',
  getDependencyAPIs() {
    return [APIKeys.ClassicEditorAPI];
  },
  declareAPIs() {
    return [APIKeys.PlatformAppsAPI];
  },
  attach(shell: Shell) {
    shell.contributeAPI(APIKeys.PlatformAppsAPI, () =>
      createPlatformAppsAPI({ shell }),
    );
  },
};
