import React from 'react';
import { Divider, Tab, Text, VerticalTabs } from '@wix/wix-base-ui';
import { MenuItemId, MenuItemEntry } from '@wix/wix-code-plugin-contracts';
import { useTranslation } from '@wix/wix-code-common-components';
import dataHooks from '../dataHooks';
import s from './menu.scss';
import { DevModeOffButton } from './devModeOffButton';

interface MenuProps {
  menuItems: MenuItemEntry[];
  selected: MenuItemId;
  setMenuItem: (itemId: MenuItemId) => void;
  toggleDevMode: () => void;
}

export const Menu: React.FC<MenuProps> = ({
  menuItems,
  selected,
  setMenuItem,
  toggleDevMode,
}) => {
  const [t] = useTranslation();

  const translatedMenuItems = menuItems.map(({ type, label, value }) => {
    return { label: t(label), value, type };
  });

  const generateMenuItem = ({ label, value, type }: MenuItemEntry) => {
    if (type === 'section') {
      return (
        <div className={s.sectionHeader} key={value}>
          <Text
            skin="placeholder"
            weight="bold"
            size="small"
            shouldTranslate={false}
          >
            {label}
          </Text>
        </div>
      );
    } else if (type === 'divider') {
      return <Divider key={value} />;
    }

    return (
      <Tab key={value} value={value} label={label} shouldTranslate={false} />
    );
  };

  return (
    <div className={s.sideMenu}>
      <div>
        <VerticalTabs
          dataHook={dataHooks.menu}
          value={selected}
          onChange={setMenuItem}
          shouldTranslate={false}
          maxWidth={300}
        >
          {translatedMenuItems.map(generateMenuItem)}
        </VerticalTabs>
      </div>
      <DevModeOffButton toggleDevMode={toggleDevMode} />
    </div>
  );
};
