import {
  TourContribution,
  WixCodeEditorAdapterAPI,
  Tour,
} from '@wix/wix-code-editor-adapter';
import dataHooks from '../components/dataHooks';
import {
  introductionTourFirstStepMediaSrc,
  introductionTourSecondStepMediaSrc,
} from './constants';

export const contributeIntroductionTour = (
  t: (key: string) => string,
  wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI,
) => {
  const createLiveEditorIntroductionTour = (): TourContribution => {
    const tour: Tour = {
      tourKeyName: 'LOCAL_EDITOR_INTRO_TOUR',
      actions: [
        {
          title: t('localEditor.introductionTour.firstAction.title'),
          content: t('localEditor.introductionTour.firstAction.content'),
          mediaSrc: introductionTourFirstStepMediaSrc,
          primaryButton: {
            label: t(
              'localEditor.introductionTour.firstAction.primaryButton.label',
            ),
          },
          learnMore: {
            label: t(
              'localEditor.introductionTour.secondAction.learnMoreButton.label',
            ),
            articleSrc: t(
              'localEditor.introductionTour.secondAction.learnMoreButton.articleSrc',
            ),
          },
          selectorToAttach: `[data-hook='${dataHooks.topBar.liveEditorBadge.container}']`,
        },
        {
          title: t('localEditor.introductionTour.secondAction.title'),
          content: t('localEditor.introductionTour.secondAction.content'),
          mediaSrc: introductionTourSecondStepMediaSrc,
          primaryButton: {
            label: t(
              'localEditor.introductionTour.secondAction.primaryButton.label',
            ),
          },
          secondaryButton: {
            label: t(
              'localEditor.introductionTour.secondAction.secondaryButton.label',
            ),
            goBack: true,
          },
          selectorToAttach: wixCodeEditorAdapterAPI.getDemoAvatarsSelector(),
          onEnter: async () => wixCodeEditorAdapterAPI.addDemoAvatarsIfNeeded(),
          onExit: async () => wixCodeEditorAdapterAPI.removeDemoAvatars(),
        },
      ],
    };
    return { tour };
  };
  const liveEditorIntroductionTour = createLiveEditorIntroductionTour();
  const introductionTourOpener = wixCodeEditorAdapterAPI.contributeTour(
    liveEditorIntroductionTour,
  );
  if (!wixCodeEditorAdapterAPI.isAnyTourRunning()) {
    introductionTourOpener({ showOnlyOnce: true });
  }
};
