import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import { Reducer } from 'redux';
import { LegacyEditorExperimentsObj } from '../../experiments/legacyExperiments';
import { AppState } from '../reducers/rootReducer';
import createstore from './createStore';
import { Store } from '@wix/wix-code-common-components';

let store: Store;

function initialize(
  reducer: Reducer<AppState>,
  isDebug: AnyFixMe,
  editorAPI: EditorAPI,
  experiment: LegacyEditorExperimentsObj,
) {
  store = createstore(reducer, isDebug, editorAPI, experiment);

  return getStore();
}

export function getStore() {
  return store;
}

export default {
  initialize,
  getStore,
};
