import './tbfConsole.global.scss';
import React from 'react';
import classNames from 'classnames';
import s from './tbfConsole.scss';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { wixCodeReduxContext } from '@wix/wix-code-common-components';
import tbfConsoleActions from '../../actions/tbfConsoleActions';
import tbfConsoleStateReader from '../../selectors/tbfConsoleStateReader';
import consoleMessageCreator from './message/consoleMessage';
import baseUILib from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import dataHooks from '../../dataHooks';
import bi from '../../bi';

import once_ from 'lodash/once';
import { AppState } from '@/infra/redux-state/reducers/rootReducer';

export default once_(({ baseUI, util }) => {
  const t = util.translate;
  const ConsoleMessage = consoleMessageCreator({ baseUI, util });

  const TestBackendFunctionConsole = createReactClass({
    displayName: 'WixCodeTestBackendFunctionConsole',
    propTypes: {
      tbfId: PropTypes.string.isRequired,
      sendBi: PropTypes.func.isRequired,
    },
    getDefaultMessage() {
      return {
        logLevel: 'INFO',
        args: [t('Trigger_Function_RightPanel_Empty')],
      };
    },
    getMessages() {
      return this.props.messages.filter((msg: AnyFixMe) =>
        this.isMessageLevelVisible(msg),
      );
    },
    isMessageLevelVisible(msg: AnyFixMe) {
      return !!msg.logLevel;
    },
    render() {
      return (
        <div
          className={classNames(this.props.className, {
            [s.wixCodeTestFunctionConsole]: true,
            'wix-code-test-func-console-global': true,
          })}
          id={this.props.tbfId}
          data-hook={dataHooks.TBF_CONSOLE_PANE}
        >
          <baseUILib.Tooltip
            key="clearConsoleOutputTooltip"
            direction="TOP"
            alignment="LEFT"
            marginLeft={8}
            marginTop={2}
            content={
              <baseUILib.TextLabel
                type="T04"
                value={t('Trigger_Function_RightPanel_Clear')}
              />
            }
          >
            <div
              onClick={this.props.clear}
              className={s.tbfConsoleClearButton}
              data-hook={dataHooks.TBF_CONSOLE_CLEAR_BUTTON}
            >
              <Symbol
                name="tbf_trash"
                className={s.tbfConsoleClearButtonNormalSymbol}
              />
              <Symbol
                name="tbf_reset_white"
                className={s.tbfConsoleClearButtonHoverSymbol}
              />
            </div>
          </baseUILib.Tooltip>

          <div
            className={s.testFunctionConsole}
            data-hook={dataHooks.TBF_CONSOLE}
          >
            <baseUILib.CustomScroll
              key="custom-scroll"
              ref="customScroll"
              heightRelativeToParent="100%"
              addScrolledClass={false}
              keepAtBottom
            >
              <div className={s.messageContainer}>
                <ConsoleMessage
                  message={this.getDefaultMessage()}
                  key="defaultMessage"
                  originDeeplinkTooltip={util.translate(
                    'Trigger_Function_RightPanel_ViewCode',
                  )}
                />
                {this.props.hasMessages &&
                  this.getMessages().map(
                    (message: AnyFixMe, messageIndex: AnyFixMe) => (
                      <ConsoleMessage
                        message={message}
                        key={messageIndex}
                        isTbfConsole={true}
                      />
                    ),
                  )}
              </div>
            </baseUILib.CustomScroll>
          </div>
        </div>
      );
    },
  });

  const mapStateToProps = (state: AppState, ownProps: AnyFixMe) => ({
    messages: tbfConsoleStateReader.getMessages(state, ownProps.tbfId),
    hasMessages: tbfConsoleStateReader.hasMessages(state, ownProps.tbfId),
  });

  const mapDispatchToProps = (dispatch: AnyFixMe, ownProps: AnyFixMe) => ({
    clear: () => {
      dispatch(tbfConsoleActions.clear({ tbfId: ownProps.tbfId }));
      ownProps.sendBi(
        bi.events.TBF_TRIGGER_PANEL_ACTION,
        bi.eventBuilders.clearConsoleOutput({
          parametersCount: ownProps.context.data.paramNames.length,
          fileName: ownProps.context.data.fileId,
          functionName: ownProps.context.data.functionName,
          origin: 'button',
        }),
      );
    },
  });

  return connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
    context: wixCodeReduxContext,
  })(TestBackendFunctionConsole);
});
