import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { createSearchPanel } from './createSearchPanel';
import { createSearchPanelMenuTab } from './SearchPanelMenuTab';

export const SearchPanelEntryPoint: EntryPoint = {
  name: 'Search Panel Entry Point',
  getDependencyAPIs() {
    return [
      APIKeys.SidebarAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.WixCodeStoreAPI,
      WixCodeEditorAdapterAPI,
      APIKeys.MenuAPI,
      APIKeys.MenuPanelAPI,
      APIKeys.PanelsAPI,
    ];
  },
  async extend(shell: Shell) {
    const sidebarAPI = shell.getAPI(APIKeys.SidebarAPI);
    const menuAPI = shell.getAPI(APIKeys.MenuAPI);
    const menuPanelAPI = shell.getAPI(APIKeys.MenuPanelAPI);
    const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
    const { legacyDependencies } = shell.getAPI(WixCodeEditorAdapterAPI);
    const wixCodeStoreAPI = shell.getAPI(APIKeys.WixCodeStoreAPI);
    const panelsAPI = shell.getAPI(APIKeys.PanelsAPI);

    const searchPanel = createSearchPanel();

    sidebarAPI.contributeTab(() => searchPanel);

    menuPanelAPI.contributeTab({
      id: 'search',
      TabContent: ({ closePanel }) => {
        const TabContent = searchPanel.creator();
        return createSearchPanelMenuTab({
          closePanel,
          children: TabContent,
          apis: {
            wixCodeStoreAPI,
            biLoggerAPI,
            legacyDependenciesAPI: legacyDependencies,
            panelsAPI,
          },
        });
      },
    });

    menuAPI.registerMenuItemListener('search', () => {
      menuPanelAPI.selectTab('search');
    });
  },
};
