import {
  Badge,
  Tooltip,
  TreeListItem,
  Text,
  TextButton,
} from '@wix/wix-base-ui';
import React, { useState } from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { dataHooks } from '../../dataHooks';
import { PendingExternalDbIndications } from './PendingExternalDbIndications';
import { leftTreeClouddbCreationLabel } from '@wix/bi-logger-platform/v2';
import { BI } from '../externalDbs/constants';

export interface PendingExternalDbProps {
  namespace: string;
  creationDate: Date;
}

export const PendingExternalDb: React.FC<PendingExternalDbProps> = ({
  namespace,
  creationDate,
}) => {
  const [collapsed, setCollapsed] = useState<string>('collapsed');

  const [t] = useTranslation();

  return (
    <div data-hook={dataHooks.PENDING_DB}>
      <TreeListItem
        dataHook={dataHooks.TREE_NODE}
        label={namespace}
        shouldTranslate={false}
        depth={1}
        size="small"
        collapseState={collapsed}
        indication={{
          node: (
            <Tooltip
              interactive={true}
              content={
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                  }}
                >
                  <Text size="small" weight="thin" enableEllipsis={false}>
                    {t(
                      'WixCode_TreeView_ExternalDatabases.Created.Setup.Badge.Tooltip.Text',
                    )}
                  </Text>
                  <TextButton
                    onMouseEnter={() => {
                      leftTreeClouddbCreationLabel({
                        namespace,
                        action: BI.actions.hover,
                        item_name: BI.badges.inProgress,
                      });
                    }}
                    size="small"
                    onClick={() => {
                      leftTreeClouddbCreationLabel({
                        namespace,
                        action: BI.actions.click,
                        item_name: BI.badges.learnMore,
                      });
                      window.open(
                        t(
                          'WixCode_TreeView_ExternalDatabases.Created.Setup.Badge.Tooltip.LearnMore.Link',
                        ),
                        '_blank',
                      );
                    }}
                  >
                    {t(
                      'WixCode_TreeView_ExternalDatabases.Created.Setup.Badge.Tooltip.LearnMore.Text',
                    )}
                  </TextButton>
                </div>
              }
            >
              <Badge
                value={t(
                  'WixCode_TreeView_ExternalDatabases.Created.Setup.Badge.InProgress',
                )}
                skin="general"
              />
            </Tooltip>
          ),
        }}
        onClick={() => {
          setCollapsed(collapsed === 'collapsed' ? 'expanded' : 'collapsed');
        }}
      />
      {collapsed === 'expanded' && (
        <PendingExternalDbIndications creationDate={creationDate} />
      )}
    </div>
  );
};
