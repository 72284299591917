import { createDevEditorClient } from '@wix/editor-velo-cli-comm-api';
import { InitDevEditorError, SyncDevEditorChangesError } from './CliErrors';

const createVeloCliService = (serverPort: number | undefined) => {
  const devEditorClient = createDevEditorClient();

  const connectDevEditorClient = async () => {
    if (serverPort) {
      try {
        const { gridAppId } = await devEditorClient.connect(serverPort, {
          timeoutMs: 5000,
        });
        return gridAppId;
      } catch (error) {
        throw new InitDevEditorError();
      }
    } else {
      return '';
    }
  };

  const wrapSyncDevEditorChangesWithError = async (revision: string) => {
    try {
      await devEditorClient.syncDevEditorChanges(revision);
    } catch (error) {
      throw new SyncDevEditorChangesError();
    }
  };

  const syncChanges = async (revision: string) => {
    if (serverPort) {
      await wrapSyncDevEditorChangesWithError(revision);
    }
  };

  return {
    connectDevEditorClient,
    syncChanges,
    on: devEditorClient.on,
  };
};

export default createVeloCliService;
export type VeloCliService = ReturnType<typeof createVeloCliService>;
