import React from 'react';
import {
  DevContextAPI,
  EditorAPI,
  LegacyEditorDependencies,
  WixCodeStoreAPI,
  AiAssistancePublicAPI,
} from '@wix/wix-code-plugin-contracts';

export const PageElementsPanelContext = React.createContext<{
  legacyDependencies: LegacyEditorDependencies;
  editorAPI: EditorAPI;
  wixCodeStore: WixCodeStoreAPI;
  devContextAPI: DevContextAPI;
  aiAssistancePublicAPI: AiAssistancePublicAPI;
}>(null as any);

export const usePageElementsPanel = () =>
  React.useContext(PageElementsPanelContext);
