import { Schema } from '../internal-types';

export const isExternalSchema = (schema: Schema) =>
  schema.storage === 'external';
export const isDbDriverSchema = (schema: Schema) => schema.storage === 'driver';
export const isAppBuilderSchema = (schema: Schema) => schema.storage === 'app';
export const isUserSchema = (schema: Schema) => !schema.namespace;

const checkFieldToHaveSufficientQueryOperators = (queryOperators: string[]) =>
  !queryOperators || queryOperators.includes('eq');

const fieldSuitableForDPUrl = (field: any) => {
  const allowedTypes = ['text', 'number'];
  const isFieldOfAllowedType = allowedTypes.includes(field.type);
  const isFieldOfSufficientQueryOperators =
    checkFieldToHaveSufficientQueryOperators(field.queryOperators);

  return isFieldOfAllowedType && isFieldOfSufficientQueryOperators;
};

export const isCollectionSuitableForDP = (schema: Schema) =>
  Object.values(schema.fields).some(fieldSuitableForDPUrl);

export const isSingleItemSchema = (schema: Schema) =>
  Boolean(schema.plugins?.singleItem);
