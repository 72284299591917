import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { StoreTabScrollStateParams } from '../../tabs/actions/ideTabsActions';
import { Context } from '@/types/internal-types';
import { fileSystem } from '@wix/wix-code-common';

interface UseScrollSavingPositionParams {
  markdownRef: React.RefObject<HTMLDivElement>;
  storeTabScrollState: (action: StoreTabScrollStateParams) => void;
  focusIde: () => void;
  context: Context;
  scrollTop: number;
}

export const useMarkdownSavingScroll = ({
  markdownRef,
  storeTabScrollState,
  focusIde,
  context,
  scrollTop,
}: UseScrollSavingPositionParams) => {
  const getSaveScrollPositionDebouncedFunc = useCallback(
    (tabId: string) =>
      _.debounce((scroll: number) => {
        if (
          !fileSystem.codePackages.isPackageMarkdown(tabId) ||
          !_.isNumber(scroll)
        ) {
          return;
        }
        storeTabScrollState({
          tabId,
          scroll,
        });
      }, 100),
    [storeTabScrollState],
  );

  const [onScrollDebounceMap] = useState(
    new Map<string, _.DebouncedFunc<(scroll: number) => void>>(),
  );

  const onMarkdownScroll = useCallback(() => {
    const tabId = context.data?.id;
    let onScroll = onScrollDebounceMap.get(tabId);
    if (!onScroll) {
      onScroll = getSaveScrollPositionDebouncedFunc(tabId);
      onScrollDebounceMap.set(tabId, onScroll);
    }

    const markdownScrollTop = markdownRef.current?.scrollTop;
    if (!markdownScrollTop) {
      return;
    }

    onScroll(markdownScrollTop);
  }, [
    context,
    onScrollDebounceMap,
    markdownRef,
    getSaveScrollPositionDebouncedFunc,
  ]);

  useEffect(() => {
    onScrollDebounceMap.clear();
  }, [context, onScrollDebounceMap]);

  const setMarkdownScroll = useCallback(() => {
    focusIde();
    const c = markdownRef.current;
    if (c) {
      c.scrollTop = scrollTop || 0;
    }
  }, [markdownRef, scrollTop, focusIde]);

  return { onMarkdownScroll, setMarkdownScroll };
};
