import debounce from 'lodash/debounce';
import { PackagesAPIService } from './packagesServiceAPIFacade';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import { InstalledCodeReusePkg } from './packagesModalContext';
import { experimentUtils } from '@wix/wix-code-common';

const DEBOUNCE_DELAY = 500;

const createKey = (pkg: any) => `${pkg.id}:${pkg.version}`;
type CreateWatcherServiceArgs = {
  editorAPI: EditorAPI;
  initialPackages: InstalledCodeReusePkg[];
  packagesAPIService: PackagesAPIService;
};

export function createWatcherService({
  editorAPI,
  initialPackages,
  packagesAPIService,
}: CreateWatcherServiceArgs) {
  let lastCodeReusePkgs = new Map<string, any>(
    initialPackages.map((pkg: any) => [
      createKey(pkg),
      { ...pkg, key: createKey(pkg) },
    ]),
  );

  function findAddedAndRemovedPkgs(pkgs: any[]): {
    added: any[];
    removed: any[];
  } {
    if (!pkgs) {
      return { added: [], removed: [...lastCodeReusePkgs.values()] };
    }
    const added = pkgs.filter((pkg) => !lastCodeReusePkgs.has(pkg.key));
    const removed = [...lastCodeReusePkgs.values()].filter(
      (pkg) => !pkgs.find((p) => p.key === pkg.key),
    );
    return { added, removed };
  }

  async function getInstalledCodePackages() {
    if (experimentUtils.isAnyNpmFirstPhase()) {
      const wixCodeDependencies =
        await packagesAPIService.listWixDependencies();
      if (wixCodeDependencies) {
        return wixCodeDependencies.map((pkg) => ({
          ...pkg,
          name: pkg.packageName,
          key: `${pkg.wixOptions?.packageId}:${pkg.installedVersion}`,
        }));
      }
    }
    const { wixCodeDependencies } =
      await editorAPI.wixCode.codePackages.getCodeReusePkgs();
    return [...Object.values(wixCodeDependencies)].flatMap(
      (codePackage: any) => ({
        ...codePackage,
        name: codePackage.frontEndPackage,
        key: createKey(codePackage),
      }),
    );
  }

  async function subscribe(
    callback: (changes: { added: any[]; removed: any[] }) => void,
  ) {
    editorAPI.registerToSiteChanged(
      debounce(async () => {
        const installedCodeReusePkgs = await getInstalledCodePackages();
        const { added, removed } = findAddedAndRemovedPkgs(
          installedCodeReusePkgs,
        );
        lastCodeReusePkgs = new Map(
          installedCodeReusePkgs?.map((pkg) => [pkg.key, pkg]),
        );
        if (added.length !== 0 || removed.length !== 0) {
          callback({ added, removed });
        }
      }, DEBOUNCE_DELAY),
    );
  }

  return {
    subscribe,
  };
}
