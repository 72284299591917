import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

import once_ from 'lodash/once';

export default once_(({ baseUI }) => {
  const ConsoleMessageAction = createReactClass({
    displayName: 'WixCodeConsoleMessageAction',

    propTypes: {
      message: PropTypes.object,
    },

    render() {
      const { message } = this.props;
      const { callback, text, tooltip } = message.action;

      return (
        <div className="origin" key="origin">
          <div className="line">
            {tooltip ? (
              <baseUI.tooltip
                key="deepLinkTooltip"
                value={tooltip}
                styleType="small"
                alignment="left"
                data-aid="deepLinkTooltip"
              >
                <div onClick={() => callback(message)} className="clickable">
                  {text}
                </div>
              </baseUI.tooltip>
            ) : (
              <div onClick={() => callback(message)} className="clickable">
                {text}
              </div>
            )}
          </div>
        </div>
      );
    },
  });
  return ConsoleMessageAction;
});
