import React from 'react';
// we will use this once enable spi is supported
// import UnlinkPropertiesSmall from 'wix-ui-icons-common/classic-editor/UnlinkPropertiesSmall';
import LinkPropertiesSmall from 'wix-ui-icons-common/classic-editor/LinkPropertiesSmall';
import AlertCircleBoldSmall from 'wix-ui-icons-common/classic-editor/AlertCircleBoldSmall';
import s from './SpiLeftTreeSection.scss';
import { TreeNodeTooltip } from '../TreeNodeTooltip/TreeNodeTooltip';
import { dataHooks } from '../../consts/dataHooks';

export const spiStatusesTooltips = {
  publishedNotConnected: (
    <TreeNodeTooltip
      dataHook={dataHooks.SPI_STATUS_PUBLISHED}
      text="Spi_Statuses_Published_Not_Connected"
      ctaText="Spi_Statuses_Published_Not_Connected_Cta_Text"
      ctaLink="Spi_Statuses_Published_Not_Connected_Cta_Link"
      icon={<LinkPropertiesSmall size="18" className={s.spiStatusColor} />}
      biOrigin="custom_integrations-published"
    />
  ),
  connected: (
    <TreeNodeTooltip
      dataHook={dataHooks.SPI_STATUS_CONNECTED}
      text="Spi_Statuses_Connected"
      ctaText="Spi_Statuses_Connected_Cta_Text"
      ctaLink="Spi_Statuses_Connected_Cta_Link"
      icon={<LinkPropertiesSmall size="18" className={s.spiStatusColor} />}
      biOrigin="custom_integrations-connected"
    />
  ),
  error: (
    <TreeNodeTooltip
      dataHook={dataHooks.SPI_STATUS_ERROR}
      text="Spi_Statuses_Error"
      ctaText="Spi_Statuses_Error_Cta_Text"
      ctaLink="Spi_Statuses_Error_Cta_Link"
      icon={
        <AlertCircleBoldSmall size="18" className={s.spiStatusColorError} />
      }
      biOrigin="custom_integrations-error"
    />
  ),
};
