import ideReducer from './ideReducer';
import consoleReducer from './consoleReducer';
import tbfConsoleReducer from '@/toExtract/backendDebugging/reducers/tbfConsoleReducer';
import leftPaneReducer from './leftPaneReducer';
import fileSystemReducer from './fileSystemReducer';
import packageJsonReducer from './packageJsonReducer';
import packagesViewReducer from '@/toExtract/packages/packagesView/packagesViewReducer';
import codeReuseFsReducer from '@/toExtract/packages/codeReuseFs/codeReuseFsReducer';
import appDataReducer from '@/toExtract/packages/appData/appDataReducer';
import packagesModalReducer from '@/toExtract/packages/packagesModal/packagesModalReducer';
import treeSectionReducer from '@/sidebar/filesTree/filesTree/treeSectionReducer';
import filesReducer from './filesReducer';
import filesListenersReducer from './filesListenersReducer';
import pageEditorReducer from './pageEditorReducer';
import filesSelectionReducer from './filesSelectionReducer';
import findReplaceReducer from './findReplaceReducer';
import ideTabsReducer from '@/codeEditor/tabs/reducers/ideTabsReducer';
import searchReducer from '@/sidebar/searchPanel/searchReducer';
import dealerReducer from '../../dealer/dealerReducer';
import modulesReducer from '@/toExtract/packages/packagesModal/packagesModalContent/pkgLists/npmPackagesList/reducers/modulesReducer';
import requestPackageReducer from '@/toExtract/packages/packagesModal/packagesModalContent/pkgLists/npmPackagesList/reducers/requestPackageReducer';
import sideBarReducer from '@/sidebar/state/leftPaneReducer';
import { combineReducers } from 'redux';

const appReducers = {
  ide: ideReducer,
  console: consoleReducer,
  tbfConsole: tbfConsoleReducer,
  leftPane: leftPaneReducer,
  fileSystemStructure: fileSystemReducer,
  packageJson: packageJsonReducer,
  packagesView: packagesViewReducer,
  codeReuseFs: codeReuseFsReducer,
  appData: appDataReducer,
  packagesModal: packagesModalReducer,
  treeSection: treeSectionReducer,
  files: filesReducer,
  filesListeners: filesListenersReducer,
  filesSelection: filesSelectionReducer,
  pageEditor: pageEditorReducer,
  findReplace: findReplaceReducer,
  ideTabs: ideTabsReducer,
  search: searchReducer,
  dealer: dealerReducer,
  npmModules: modulesReducer,
  npmPackageRequest: requestPackageReducer,
  sideBar: sideBarReducer,
};

export const rootReducer = combineReducers(appReducers);
export type AppState = ReturnType<typeof rootReducer>;
