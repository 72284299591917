import { consts } from '@wix/wix-code-consts';
import {
  getFileIdFromPageId,
  getMasterPageFileId,
  getFileIdFromStylesFolder,
  isCssFile,
} from './utils';

export const devContextUtilsCreator = ({ constants }) => {
  const extractFileId = (devContext) => {
    if (devContext.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE) {
      return getFileIdFromPageId(devContext.data.id);
    } else if (
      devContext.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.FILE
    ) {
      return devContext.data.id;
    } else if (
      devContext.type ===
      constants.DEVELOPER_MODE.CONTEXT_TYPES.TRIGGER_BACKEND_FUNCTION
    ) {
      return devContext.data.fileId;
    }
  };
  const getFileNameFromFileId = (fileId) => {
    return fileId && fileId.split('/').pop();
  };
  const setMasterPageContext = (editorAPI) => {
    const mpContext = editorAPI.developerMode.createContext(
      constants.DEVELOPER_MODE.CONTEXT_TYPES.FILE,
      { id: getMasterPageFileId() },
    );

    editorAPI.developerMode.setContext(mpContext);
  };

  const setCssFileContext = (editorAPI) => {
    const cssContext = editorAPI.developerMode.createContext(
      constants.DEVELOPER_MODE.CONTEXT_TYPES.FILE,
      { id: getFileIdFromStylesFolder(consts.GLOBAL_CSS) },
    );

    editorAPI.developerMode.setContext(cssContext);
  };

  const setPageContext = (editorAPI) => {
    const pageContext = editorAPI.developerMode.createContext(
      constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE,
    );

    editorAPI.developerMode.setContext(pageContext);
  };

  const setFileContext = (editorAPI, fileId) => {
    const fileContext = editorAPI.developerMode.createContext(
      constants.DEVELOPER_MODE.CONTEXT_TYPES.FILE,
      { id: fileId },
    );

    editorAPI.developerMode.setContext(fileContext);
  };

  const setEmbeddedTabContext = (editorAPI, { url }) => {
    const whatsNewContext = editorAPI.developerMode.createContext(
      constants.DEVELOPER_MODE.CONTEXT_TYPES.WHATS_NEW,
      { url },
    );

    editorAPI.developerMode.setContext(whatsNewContext);
  };

  const isPageContext = (editorAPI) => {
    const context = editorAPI.developerMode.getContext();
    return context.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE;
  };

  const isFileContext = (editorAPI) => {
    const context = editorAPI.developerMode.getContext();
    return context.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.FILE;
  };

  const isMasterPageContext = (editorAPI) => {
    const context = editorAPI.developerMode.getContext();
    return (
      context.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.FILE &&
      context.data.id === getMasterPageFileId()
    );
  };

  const isCssFileContext = (editorAPI) => {
    const context = editorAPI.developerMode.getContext();
    return (
      context.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.FILE &&
      isCssFile(context.data.id)
    );
  };

  const isEmbeddedTabContext = (editorAPI) => {
    const context = editorAPI.developerMode.getContext();
    return context.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.WHATS_NEW;
  };

  const setTbfContext = (
    editorAPI,
    { id, fileId, functionName, functionSignature, paramNames, lineNumber },
  ) => {
    const tbfContext = editorAPI.developerMode.createContext(
      constants.DEVELOPER_MODE.CONTEXT_TYPES.TRIGGER_BACKEND_FUNCTION,
      {
        id,
        fileId,
        functionName,
        functionSignature,
        paramNames,
        lineNumber,
      },
    );
    editorAPI.developerMode.setContext(tbfContext);
  };

  const isTbfContext = (editorAPI) => {
    const context = editorAPI.developerMode.getContext();
    return (
      context.type ===
      constants.DEVELOPER_MODE.CONTEXT_TYPES.TRIGGER_BACKEND_FUNCTION
    );
  };

  return {
    extractFileId,
    getFileNameFromFileId,
    setPageContext,
    setCssFileContext,
    setFileContext,
    setMasterPageContext,
    setEmbeddedTabContext,
    isPageContext,
    isFileContext,
    isMasterPageContext,
    isCssFileContext,
    isEmbeddedTabContext,
    setTbfContext,
    isTbfContext,
  };
};
