import DeleteIcon from '@/assets/icons/contextMenu/delete.svg';
import VersionsIcon from '@/assets/icons/contextMenu/versions.svg';
import EditIcon from '@/assets/icons/contextMenu/edit.svg';
import SpinningArrowsIcon from '@/assets/icons/contextMenu/spinningArrows.svg';
import FileIcon from '@/assets/icons/contextMenu/file.svg';
import { AppMarket } from '@wix/wix-ui-icons-common/classic-editor';
import { TFunc } from '@/infra/i18n/i18nService';
import { VELO_TREE_QUICK_ACTIONS } from './CodeReusePkgSuffixContextMenu';

type appMode = 'by-velo' | 'private-app' | 'by-others';
const generateSeeAllVersionsAction = (
  actionSelectedHandler: (action: VELO_TREE_QUICK_ACTIONS) => void,
  t: TFunc,
) => {
  return [
    {
      onClick: () => actionSelectedHandler('VERSIONS'),
      icon: VersionsIcon,
      label: t('Package_Manager_Installed_Menu_AllVersions'),
      automationId: 'action-see-all-versions-module',
    },
  ];
};

const generateEditPackageAction = (
  actionSelectedHandler: (action: VELO_TREE_QUICK_ACTIONS) => void,
  t: TFunc,
) => {
  return [
    {
      onClick: () => actionSelectedHandler('EDIT'),
      icon: EditIcon,
      label: t('Packages_Modal_Context_Menu_Edit_Package'),
      automationId: 'action-edit-packagegit-module',
    },
  ];
};

const generateOpenReadmeAction = (
  actionSelectedHandler: (action: VELO_TREE_QUICK_ACTIONS) => void,
  t: TFunc,
) => {
  return [
    {
      onClick: () => actionSelectedHandler('README'),
      icon: FileIcon,
      label: t('Left_Tree_Components_Readme_Open'),
      automationId: 'action-readme-code-reuse-module',
    },
  ];
};
const generateReameUpdatePackageAction = (
  actionSelectedHandler: (action: VELO_TREE_QUICK_ACTIONS) => void,
  canUpdate: boolean,
  t: TFunc,
) => {
  return [
    {
      onClick: () => actionSelectedHandler('UPDATE'),
      disabled: !canUpdate,
      icon: SpinningArrowsIcon,
      label: t('Left_Tree_Components_Readme_Update'),
      automationId: 'action-update-corvid-module',
    },
  ];
};
const generateUninstallAction = (
  actionSelectedHandler: (action: VELO_TREE_QUICK_ACTIONS) => void,
  t: TFunc,
) => {
  return [
    {
      onClick: () => actionSelectedHandler('UNINSTALL'),
      icon: DeleteIcon,
      label: t('Left_Tree_Components_Readme_Uninstall'),
      automationId: 'action-uninstall-corvid-module',
    },
  ];
};
const generateAppDetailsAction = (
  actionSelectedHandler: (action: VELO_TREE_QUICK_ACTIONS) => void,
  t: TFunc,
) => {
  return [
    {
      onClick: () => actionSelectedHandler('APP_DETAILS'),
      icon: AppMarket,
      label: t('Left_Tree_Blocks_Private_InstalledApp_ContextMenu_AppDetails'),
      automationId: 'action-app-details-module',
    },
  ];
};
const generateEditInBlocksAction = (
  actionSelectedHandler: (action: VELO_TREE_QUICK_ACTIONS) => void,
  t: TFunc,
) => {
  return [
    {
      onClick: () => actionSelectedHandler('EDIT_IN_BLOCKS'),
      icon: EditIcon,
      label: t('Left_Tree_Blocks_Private_InstalledApp_ContextMenu_EditApp'),
      automationId: 'action-edit-in-blocks-module',
    },
  ];
};

const generateSeeUpdateAction = (
  actionSelectedHandler: (action: VELO_TREE_QUICK_ACTIONS) => void,
  t: TFunc,
) => [
  {
    onClick: () => actionSelectedHandler('APP_DETAILS'),
    icon: SpinningArrowsIcon,
    label: t('Left_Tree_Blocks_Private_InstalledApp_ContextMenu_GetUpdate'),
    automationId: 'action-update-package',
  },
];

const generateVeloContextMenuStructure = (
  actionSelectedHandler: (action: VELO_TREE_QUICK_ACTIONS) => void,
  canUpdate: boolean,
  t: TFunc,
) => ({
  sections: [
    [
      ...generateOpenReadmeAction(actionSelectedHandler, t),
      ...generateReameUpdatePackageAction(actionSelectedHandler, canUpdate, t),
      ...generateSeeAllVersionsAction(actionSelectedHandler, t),
      ...generateUninstallAction(actionSelectedHandler, t),
    ],
  ],
});

const addSeeUpdateAction = (
  contextMenu: any,
  actionSelectedHandler: any,
  t: any,
): void => {
  contextMenu.sections[0].splice(
    0,
    0,
    generateSeeUpdateAction(actionSelectedHandler, t)[0],
  );
};

const addEditInBlocksAction = (
  contextMenu: any,
  actionSelectedHandler: any,
  t: any,
): void => {
  contextMenu.sections[0].splice(
    contextMenu.sections[0].length - 1,
    0,
    generateEditInBlocksAction(actionSelectedHandler, t)[0],
  );
};

const generatePrivateAppsContextMenuStructure = (
  actionSelectedHandler: (action: VELO_TREE_QUICK_ACTIONS) => void,
  canUpdate: boolean,
  t: TFunc,
  isBlocksCombinedMode: boolean,
) => {
  if (!isBlocksCombinedMode) {
    return {
      sections: [
        [
          ...generateOpenReadmeAction(actionSelectedHandler, t),
          ...generateEditPackageAction(actionSelectedHandler, t),
          ...generateReameUpdatePackageAction(
            actionSelectedHandler,
            canUpdate,
            t,
          ),
          ...generateSeeAllVersionsAction(actionSelectedHandler, t),
          ...generateUninstallAction(actionSelectedHandler, t),
        ],
      ],
    };
  }
  const contextMenu = {
    sections: [
      [
        ...generateAppDetailsAction(actionSelectedHandler, t),
        ...generateEditInBlocksAction(actionSelectedHandler, t),
        ...generateUninstallAction(actionSelectedHandler, t),
      ],
    ],
  };

  canUpdate && addSeeUpdateAction(contextMenu, actionSelectedHandler, t);
  return contextMenu;
};

const generatePrivateAppsByOtherContextMenuStructure = (
  actionSelectedHandler: (action: VELO_TREE_QUICK_ACTIONS) => void,
  canUpdate: boolean,
  t: TFunc,
  isBlocksCombinedMode: boolean,
  canEdit?: boolean,
) => {
  if (!isBlocksCombinedMode) {
    return {
      sections: [
        [
          ...generateOpenReadmeAction(actionSelectedHandler, t),
          ...generateReameUpdatePackageAction(
            actionSelectedHandler,
            canUpdate,
            t,
          ),
          ...generateUninstallAction(actionSelectedHandler, t),
        ],
      ],
    };
  }

  const contextMenu = {
    sections: [
      [
        ...generateAppDetailsAction(actionSelectedHandler, t),
        ...generateUninstallAction(actionSelectedHandler, t),
      ],
    ],
  };

  canUpdate && addSeeUpdateAction(contextMenu, actionSelectedHandler, t);

  canEdit && addEditInBlocksAction(contextMenu, actionSelectedHandler, t);

  return contextMenu;
};

const generateRootContextMenuStructure = (
  appMode: appMode,
  actionSelectedHandler: (action: VELO_TREE_QUICK_ACTIONS) => void,
  canUpdate: boolean,
  t: TFunc,
  isBlocksCombinedMode: boolean,
  canEdit?: boolean,
): AnyFixMe => {
  let contextMenuStructure;
  switch (appMode) {
    case 'by-velo':
      contextMenuStructure = generateVeloContextMenuStructure(
        actionSelectedHandler,
        canUpdate,
        t,
      );
      break;
    case 'private-app':
      contextMenuStructure = generatePrivateAppsContextMenuStructure(
        actionSelectedHandler,
        canUpdate,
        t,
        isBlocksCombinedMode,
      );
      break;
    case 'by-others':
      contextMenuStructure = generatePrivateAppsByOtherContextMenuStructure(
        actionSelectedHandler,
        canUpdate,
        t,
        isBlocksCombinedMode,
        canEdit,
      );
      break;
    default:
  }
  return contextMenuStructure;
};

export { generateRootContextMenuStructure };
