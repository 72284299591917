import * as React from 'react';
import dataHooks from '../../../../dataHooks';
import s from './CodeReusePkgsList.scss';
import { RichText } from '@wix/wix-base-ui';
import SearchNotFound from '../../../../icons/search_not_found.svg';
import { useTranslate } from '@/infra/i18n/useTranslate';

export const BuiltByVeloListEmptyState: React.FC<{ searchKeyword: string }> = ({
  searchKeyword,
}) => {
  const { t } = useTranslate();

  return (
    <div
      className={s.noResultWrapper}
      data-hook={dataHooks.BUILT_BY_VELO_EMPTY_STATE}
    >
      <SearchNotFound />
      <RichText type="T09">
        {t('Package_Manager_Popup_Search_NoResults_Heading')}
      </RichText>
      <RichText type="T07" className="light">
        {t('WixCode_Packages_Modal_Velo_Search_Empty_State', {
          search_input: searchKeyword,
        })}
      </RichText>
      <RichText className={s.noResultsTitle}>
        <a
          rel="noreferrer"
          target="_blank"
          href={t('WixCode_Packages_Modal_Velo_Search_Empty_State_Link_URL')}
        >
          {t('WixCode_Packages_Modal_Velo_Search_Empty_State_Link_Text')}
        </a>
      </RichText>
    </div>
  );
};
