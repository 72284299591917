export const INSTALL_MODULE_DONE = 'INSTALL_MODULE_DONE';
export const INSTALL_MODULE_START = 'INSTALL_MODULE_START';
export const TAB_SELECTED = 'TAB_SELECTED';
export const OPEN_SUBMIT_NPM_REQUEST = 'OPEN_SUBMIT_NPM_REQUEST';
export const CLOSE_SUBMIT_NPM_REQUEST = 'CLOSE_SUBMIT_NPM_REQUEST';
export const SEARCH_KEYWORD_CHANGED = 'SEARCH_KEYWORD_CHANGED';
export const ERROR_INSTALLING_PACKAGES = 'ERROR_INSTALLING_PACKAGES';
export const LOAD_REMOTE_PACKAGES = 'LOAD_REMOTE_PACKAGES';
export const REQUEST_PACKAGE_SUBMITTED = 'REQUEST_PACKAGE_SUBMITTED';
export const REQUEST_PACKAGE_SUBMITTING_ERROR =
  'REQUEST_PACKAGE_SUBMITTING_ERROR';
export const REQUEST_PACKAGE_SUBMITTING = 'REQUEST_PACKAGE_SUBMITTING';
export const REQUEST_PACKAGE_INPUT_CHANGE = 'REQUEST_PACKAGE_INPUT_CHANGE';
export const REQUEST_PACKAGE_SEARCH_CHANGE = 'REQUEST_PACKAGE_SEARCH_CHANGE';
export const REQUEST_PACKAGE_SET_VERSION_VALIDATION_ERROR =
  'REQUEST_PACKAGE_SET_VERSION_VALIDATION_ERROR';
export const INDEX_SEARCH_REQUEST = 'INDEX_SEARCH_REQUEST';
export const INDEX_SEARCH_SUCCESS = 'INDEX_SEARCH_SUCCESS';
export const INDEX_SEARCH_FAILURE = 'INDEX_SEARCH_FAILURE';
export const PACKAGES_INIT_DONE = 'PACKAGES_INIT_DONE';
export const PACKAGES_INIT_START = 'PACKAGES_INIT_START';
export const LOAD_DEFAULT_NPM_PACKAGES = 'LOAD_DEFAULT_NPM_PACKAGES';
export const SET_SELECTED_NPM_PKG_DATA = 'SET_SELECTED_NPM_PKG_DATA';

export type ModuleMetadata = Record<
  string,
  {
    name: string;
    versions: Record<
      string,
      { status: string; changelog: any[]; comment?: any }
    >;
  }
>;
