// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".n5Gt9K{display:flex;align-items:center;margin-bottom:8px}.n5Gt9K .HDxo92{margin-right:8px;color:#868aa5}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"classes-label-container": "n5Gt9K",
	"classesLabelContainer": "n5Gt9K",
	"classes-label": "HDxo92",
	"classesLabel": "HDxo92"
};
export default ___CSS_LOADER_EXPORT___;
