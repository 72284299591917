import _ from 'lodash';

import fileActions from '@/infra/redux-state/actions/fileActions';
import pageActionsCreator from '@/infra/redux-state/actions/pageActions';
import fileSystemActions from '@/infra/redux-state/actions/fileSystemActions';
import codeStateReader from '@/infra/redux-state/reducers/codeStateReader';
import {
  utilsCreator,
  fileSystem as fileSystemCommon,
} from '@wix/wix-code-common';
import { Store } from '@wix/wix-code-common-components';
import {
  DevContextAPI,
  EditorAPI,
  ExperimentsAPI,
  FileDescriptor,
  LegacyEditorDependencies,
  ReadOnlyAPI,
} from '@wix/wix-code-plugin-contracts';
import { consts } from '@wix/wix-code-consts';
import preloadSiteCodeFilesCreator from '@/legacy/core/services/preloadSiteCodeFilesTree';

export default (
  { constants, experiment, platform, util }: LegacyEditorDependencies,
  readOnlyAPI: ReadOnlyAPI,
  devContextAPI: DevContextAPI,
  experimentsAPI: ExperimentsAPI,
) => {
  const pageActions = pageActionsCreator({ experiment, platform, util });
  const utils = utilsCreator({ experiment, platform, util });
  const isUsingIdeServerNG = experimentsAPI.isOpen('dm_ideServerNG');

  const PATH_TO_SCHEMAS = '.schemas/';

  function checkIfPathIsInsideSchemas(path: string) {
    return path.startsWith(PATH_TO_SCHEMAS) && path !== PATH_TO_SCHEMAS;
  }

  function getSchemasSubfolderNameFromPath(pathToSchemasSubfolder: string) {
    return pathToSchemasSubfolder.substring(
      PATH_TO_SCHEMAS.length, // Strip the known parent path.
      pathToSchemasSubfolder.length - 1, // Strip the trailing slash.
    );
  }

  function contextBelongsToDeletedItem(context: any, descriptor: any) {
    if (context.type !== constants.DEVELOPER_MODE.CONTEXT_TYPES.FILE) {
      return false;
    }

    if (descriptor.directory) {
      return _.startsWith(context.data.id, descriptor.location);
    } else {
      return context.data.id === descriptor.location;
    }
  }

  const wrapWithReadOnlyCheck = <TFunc extends (...args: any[]) => any>(
    func: TFunc,
  ): ((...args: Parameters<TFunc>) => ReturnType<TFunc> | undefined) => {
    return (...args) => {
      if (readOnlyAPI.getReadOnlyMode().fileSystem) {
        console.warn(
          `${func.name} is disabled because filesystem is in read-only`,
        );
        return;
      }
      return func(...args);
    };
  };

  return function (store: Store, editorAPI: EditorAPI) {
    function deleteItem(fileDescriptor: any) {
      return store
        .dispatch(fileSystemActions.delete(fileDescriptor))
        .then(() => updateContextPostDelete(fileDescriptor));
    }

    function updateContextPostDelete(descriptor: any) {
      const context = editorAPI.developerMode.getContext();
      if (contextBelongsToDeletedItem(context, descriptor)) {
        editorAPI.developerMode.setContext(
          editorAPI.developerMode.createContext(
            constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE,
          ),
        );
      }
    }

    function move(
      fileDescriptor: FileDescriptor,
      targetFileDescriptor: FileDescriptor,
      newName: string,
    ) {
      return editorAPI.dsActions.wixCode.fileSystem
        .move(fileDescriptor, targetFileDescriptor, newName)
        .then(function (newDescriptor: AnyFixMe) {
          if (fileDescriptor.directory) {
            updateFileModelsForFolderMove(
              store.getState(),
              fileDescriptor,
              fileDescriptor.location,
              newDescriptor.location,
            );
            store.dispatch(
              fileSystemActions.moveFolder(
                fileDescriptor.location,
                newDescriptor.location,
              ),
            );
            updateContextPostFolderMove(fileDescriptor, newDescriptor);

            return newDescriptor.location;
          }

          store.dispatch(
            fileSystemActions.unregisterItem(fileDescriptor.location),
          );
          store.dispatch(
            fileSystemActions._addChild(
              targetFileDescriptor.location,
              newDescriptor,
            ),
          );

          return newDescriptor.location;
        });
    }

    function updateFileModelsForFolderMove(
      state: AnyFixMe,
      currentFileDescriptor: FileDescriptor,
      oldFolderLocation: string,
      newFolderLocation: string,
    ) {
      const oldChildren = codeStateReader.getChildrenFileDescriptors(
        state,
        currentFileDescriptor.location,
      );

      oldChildren.forEach((child: FileDescriptor) => {
        if (child.directory) {
          updateFileModelsForFolderMove(
            state,
            child,
            oldFolderLocation,
            newFolderLocation,
          );
        } else {
          const newChildId = child.location.replace(
            oldFolderLocation,
            newFolderLocation,
          );
          const modelId = codeStateReader.getFileModelId(state, child.location);
          editorAPI.wixCode.models.remove({ modelId });
          editorAPI.wixCode.fileSystem.loadFile(newChildId);
        }
      });
    }

    function updateContextPostFolderMove(
      oldDescriptor: FileDescriptor,
      newDescriptor: FileDescriptor,
    ) {
      const fileContext = editorAPI.developerMode.getContext();
      if (!contextBelongsToDeletedItem(fileContext, oldDescriptor)) {
        return;
      }

      const newContextId = fileContext.data.id.replace(
        oldDescriptor.location,
        newDescriptor.location,
      );
      editorAPI.developerMode.setContext(
        editorAPI.developerMode.createContext(
          constants.DEVELOPER_MODE.CONTEXT_TYPES.FILE,
          { id: newContextId },
        ),
      );
    }

    function writeFile(fileDescriptor: any, content: any) {
      const operationIsInvalidateCacheCommand =
        fileDescriptor.location.includes('$commands');

      if (operationIsInvalidateCacheCommand) {
        return store.dispatch(fileSystemActions.writeEmptyFile(fileDescriptor));
      }

      const parentPath = fileSystemCommon.getParentPath(
        fileDescriptor.location,
      );
      const parentExists = codeStateReader.getFileSystemEntry(
        store.getState(),
        parentPath,
      );
      if (!parentExists) {
        const parentIsSchemasSubfolder = checkIfPathIsInsideSchemas(parentPath);
        if (parentIsSchemasSubfolder) {
          const subfoderName = getSchemasSubfolderNameFromPath(parentPath);
          store.dispatch(
            fileSystemActions.createVirtualFolder(
              PATH_TO_SCHEMAS,
              subfoderName,
            ),
          );
        } else {
        return Promise.reject('Cannot write a file in a non-existing folder') //eslint-disable-line
        }
      }

      const fileExists = codeStateReader.getFileSystemEntry(
        store.getState(),
        fileDescriptor.location,
      );
      return (
        fileExists
          ? store.dispatch(
              fileActions.changeContent(fileDescriptor.location, content),
            )
          : store.dispatch(
              fileSystemActions.createFile(
                parentPath,
                fileDescriptor.name,
                content,
              ),
            )
      ).then(function () {
        return codeStateReader.getFileSystemEntry(
          store.getState(),
          fileDescriptor.location,
        ).descriptor;
      });
    }

    function createFolder(folderName: string, parentFolderDescriptor: any) {
      return store
        .dispatch(
          fileSystemActions.createFolder(
            parentFolderDescriptor.location,
            folderName,
          ),
        )
        .then((location: string) =>
          editorAPI.wixCode.fileSystem.getVirtualDescriptor(location, true),
        );
    }

    function getFileIdFromPageId(pageId: string) {
      return utils.getFileIdFromPageId(pageId);
    }

    function loadFile(fileId: string) {
      store.dispatch(fileActions.loadFile(fileId));
    }

    function loadPageCode(pageId: string, defaultContent: string) {
      store.dispatch(pageActions.loadById({ pageId, defaultContent }));
    }

    function changeContent(fileId: string, content: string) {
      store.dispatch(fileActions.changeContent(fileId, content));
    }

    function setSelection(fileId: string, selection: any) {
      store.dispatch(fileActions.setSelection(fileId, selection));
    }

    const FLUSH_ORIGINS = {
      PLATFORM: 'PLATFORM',
      PREVIEW: 'PREVIEW',
      OPEN_CM: 'OPEN_CM',
    };

    function isFileNotFoundError(e: unknown) {
      return _.get(e, 'xhr.status') === 404;
    }

    function isFileNotFoundErrorServerNG(e: unknown) {
      return _.get(e, 'response.status') === 404;
    }

    async function refreshFolder(path: string) {
      const folderDescriptor =
        editorAPI.wixCode.fileSystem.getVirtualDescriptor(path, true);
      try {
        const updatedChildren = await editorAPI.wixCode.fileSystem.getChildren(
          folderDescriptor,
        );
        updatedChildren.forEach(async (child) => {
          if (child.directory) {
            await refreshFolder(child.location);
          } else {
            await refreshFile(child.location);
          }
        });
        store.dispatch(fileSystemActions.setChildren(path, updatedChildren));
      } catch (e) {
        if (
          (isUsingIdeServerNG && isFileNotFoundErrorServerNG(e)) ||
          isFileNotFoundError(e)
        ) {
          store.dispatch(fileSystemActions.removeFromStore(folderDescriptor));
        }
      }
    }

    async function readFile(fileDescriptor: FileDescriptor) {
      if (
        fileDescriptor.location ===
        `backend/${consts.WIX_CODE_PACKAGE_JSON_FILE_NAME}`
      ) {
        return '';
      }
      return editorAPI.dsActions.wixCode.fileSystem.readFile(fileDescriptor);
    }

    async function refreshFile(path: string) {
      const fileDescriptor =
        editorAPI.wixCode.fileSystem.getVirtualDescriptor(path);
      try {
        const content = await readFile(fileDescriptor);
        store.dispatch(fileActions.cacheContent(fileDescriptor, content));
      } catch (e) {
        if (
          (isUsingIdeServerNG && isFileNotFoundErrorServerNG(e)) ||
          isFileNotFoundError(e)
        ) {
          store.dispatch(fileSystemActions.removeFromStore(fileDescriptor));
          updateContextPostDelete(fileDescriptor);
        } else {
          throw e;
        }
      }
    }

    const isFolder = (path: string) => path.endsWith('/');

    const cacheNonExistingPublicPages = () => {
      const pageIds = editorAPI.pages.getPageIdList();
      pageIds.forEach((pageId) => {
        const fileDescriptor =
          editorAPI.wixCode.fileSystem.getVirtualDescriptor(
            `public/pages/${pageId}.js`,
          );
        const fileExists = codeStateReader.getFileSystemEntry(
          store.getState(),
          fileDescriptor.location,
        );
        if (!fileExists) {
          store.dispatch(
            fileActions.cacheContent(
              fileDescriptor,
              utils.getDefaultPageContent(false),
            ),
          );
        }
      });
    };

    const concurrentChangeSubscriber = async (paths: string[]) => {
      const rootsPaths = Object.values(
        editorAPI.wixCode.fileSystem.getRoots(),
      ).map((root: FileDescriptor) => root.location);

      const shouldLoadAllFiles = rootsPaths.every((root: string) =>
        paths.includes(root),
      );
      if (shouldLoadAllFiles) {
        const preloadSiteCodeFiles = preloadSiteCodeFilesCreator({
          experiment,
          platform,
          util,
          constants,
        });
        const refreshExistingTab = async () => {
          const selectedFileId = devContextAPI.getSelectedFileId();
          if (!selectedFileId) {
            return;
          }
          return refreshFile(selectedFileId);
        };
        await Promise.all([
          preloadSiteCodeFiles(editorAPI, store),
          refreshExistingTab(),
        ]).catch((e) => {
          console.error('Failed to load files', e);
        });
        cacheNonExistingPublicPages();
        return;
      }

      const refreshPaths = paths
        .filter((path) => path !== `backend/${consts.CONFIG_FOLDER_NAME}/`)
        .map((path) =>
          isFolder(path) ? refreshFolder(path) : refreshFile(path),
        );

      await Promise.all(refreshPaths).catch((e) => {
        console.error('Failed to load files', e);
      });

      if (paths.includes('public/pages/')) {
        cacheNonExistingPublicPages();
      }
    };
    // should be replaced with direct API call once santa-editor-testkit is updated with latest DS
    _.invoke(
      editorAPI.wixCode.fileSystem,
      'subscribeToConcurrentChange',
      concurrentChangeSubscriber,
    );

    return {
      writeFile: wrapWithReadOnlyCheck(writeFile),
      createFolder: wrapWithReadOnlyCheck(createFolder),
      deleteItem: wrapWithReadOnlyCheck(deleteItem),
      move: wrapWithReadOnlyCheck(move),
      cacheNonExistingPublicPages,
      getFileIdFromPageId,
      loadFile,
      loadPageCode,
      changeContent: wrapWithReadOnlyCheck(changeContent),
      setSelection,
      FLUSH_ORIGINS,
      refreshFolder,
      refreshFile,
      readFile,
    };
  };
};
