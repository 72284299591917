import { isEmpty } from 'lodash';
import maybe from 'folktale/maybe';
import { adapterInputConstraints } from '../constants';

const { Just, Nothing } = maybe;

const hasInvalidCharacters = (collectionName: string) =>
  !/^[\w-]*$/.test(collectionName);

type t = (key: string, options?: { [key: string]: string }) => string;

const isUnique = (array: any[], element: any) =>
  array.filter((uniqueElement) => uniqueElement === element).length === 0;
export const createValidations = () => {
  const getNamespaceValidationError = (
    namespace: string,
    uniqueNamespaces: string[],
    t: t,
  ) => {
    if (isEmpty(namespace)) {
      return Just(
        t('External_Databases_Modal_Connect_Adapter_Empty_Field_Error'),
      );
    }
    if (namespace.length > adapterInputConstraints.MAX_NAMESPACE_LENGTH) {
      return Just(
        t(
          'External_Databases_Modal_Connect_Adapter_Input_Field_Char_Limit_Exceeded_Error',
          {
            maxLength: adapterInputConstraints.MAX_NAMESPACE_LENGTH.toString(),
          },
        ),
      );
    }
    if (hasInvalidCharacters(namespace)) {
      return Just(
        t('External_Collections_Screen_Namespace_InvalidCharacter_Tooltip'),
      );
    }

    if (!isUnique(uniqueNamespaces, namespace)) {
      return Just(
        t('External_Collections_Screen_Namespace_NameExists_Tooltip'),
      );
    }

    return Nothing();
  };

  const getConfigurationValidationError = (configuration: string, t: t) => {
    if (isEmpty(configuration)) {
      return Just(
        t('External_Databases_Modal_Connect_Adapter_Empty_Field_Error'),
      );
    }
    return Nothing();
  };

  const getEndpointValidationError = (endpoint: string, t: t) => {
    if (isEmpty(endpoint)) {
      return Just(
        t('External_Databases_Modal_Connect_Adapter_Empty_Field_Error'),
      );
    }
    if (endpoint.length > adapterInputConstraints.MAX_ENDPOINT_LENGTH) {
      return Just(
        t(
          'External_Databases_Modal_Connect_Adapter_Input_Field_Char_Limit_Exceeded_Error',
          {
            maxLength: adapterInputConstraints.MAX_ENDPOINT_LENGTH.toString(),
          },
        ),
      );
    }

    const validationMessageKey =
      'External_Collections_Screen_Endpoint_Error_Tooltip';

    return endpoint.startsWith('https://')
      ? Nothing()
      : Just(t(validationMessageKey));
  };
  return {
    getNamespaceValidationError,
    getConfigurationValidationError,
    getEndpointValidationError,
  };
};
