import React, { useState } from 'react';
import dataHooks from '../dataHooks';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { TreeSectionDivider, TreeListItem } from '@wix/wix-base-ui';
import s from '../LeftPaneWithTabs.module.scss';
import { useTranslate } from '@/infra/i18n/useTranslate';
import { experimentUtils } from '@wix/wix-code-common';
import { useBiLogger } from '@/infra/wixCodeContext/BiLoggerAPIContext';
import {
  leftTreeClickOnAnItem,
  leftTreeClickOnASection,
} from '@wix/bi-logger-platform/v2';

export interface HelpTabUpdatesProps {
  helpArticle?: {
    biItemName: string;
    linkName: string;
    symbolName: string;
    onClick: () => void;
  };
  openFeedbackForm: () => void;
}

export const HelpTabUpdates: React.FC<HelpTabUpdatesProps> = ({
  helpArticle,
  openFeedbackForm,
}) => {
  const links = [
    {
      biItemName: 'Forum',
      url: 'WixCode_Exposure_Link_Forum',
      label: 'WixCode_TreeView_FileTree_QuickActions_VeloForum',
    },
    {
      biItemName: 'Discord',
      url: 'WixCode_TreeView_FileTree_QuickActions_Discord_URL',
      label: 'WixCode_TreeView_FileTree_QuickActions_Discord',
    },
    {
      biItemName: 'VeloBlog',
      url: 'WixCode_TreeView_FileTree_QuickActions_VeloBlog_URL',
      label: 'WixCode_TreeView_FileTree_QuickActions_VeloBlog',
    },
  ];
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslate();
  const biLogger = useBiLogger();

  const onClickSectionDividerHandler = () => {
    const newState = !collapsed;
    setCollapsed(newState);
    biLogger.report(
      leftTreeClickOnASection({
        section_name: 'updates',
        action: newState ? 'close' : 'open',
      }),
    );
  };
  const onClickGettingStartedHandler = () => {
    biLogger.report(
      leftTreeClickOnAnItem({
        item_name: helpArticle?.biItemName || helpArticle?.linkName,
        item_type: 'resource',
      }),
    );
    helpArticle?.onClick();
  };
  const onClickLinkHandler = (biItemName: string, url: string) => {
    biLogger.report(
      leftTreeClickOnAnItem({
        item_name: biItemName,
        item_type: 'resource',
      }),
    );
    window.open(t(url), '_blank');
  };

  return (
    <>
      <div data-aid="help-updates-section">
        <TreeSectionDivider
          dataHook={dataHooks.SECTION_DIVIDER}
          label={
            experimentUtils.isRearrangeHelpTabResources()
              ? 'Left_Tree_Components_Category_HelpCommunity'
              : 'Left_Tree_Components_Category_HelpUpdates'
          }
          collapsed={collapsed}
          onClick={onClickSectionDividerHandler}
          size="small"
        />
      </div>
      {!collapsed ? (
        <div data-aid="sub-items" className={s.sectionContainer}>
          {experimentUtils.isRearrangeHelpTabResources() &&
            links.map(({ url, biItemName, label }) => (
              <React.Fragment key={biItemName}>
                <TreeListItem
                  className={s.blueListItem}
                  dataHook={dataHooks.TREE_NODE}
                  label={label}
                  size="small"
                  suffix={<Symbol name="link_icon" />}
                  onClick={() => onClickLinkHandler(biItemName, url)}
                />
              </React.Fragment>
            ))}
          {!experimentUtils.isRearrangeHelpTabResources() && (
            <TreeListItem
              className={s.blueListItem}
              dataHook={dataHooks.TREE_NODE}
              label={t('WixCode_TreeView_FileTree_QuickActions_Give_Feedback')}
              icon={<Symbol name="blog_icon" />}
              size="small"
              onClick={openFeedbackForm}
            />
          )}
          {!experimentUtils.isRearrangeHelpTabResources() && helpArticle && (
            <TreeListItem
              className={s.blueListItem}
              dataHook={dataHooks.TREE_NODE}
              shouldTranslate={false}
              label={t('WixCode_GettingStarted_TabTitle')}
              icon={<Symbol name={helpArticle.symbolName} />}
              size="small"
              onClick={onClickGettingStartedHandler}
            />
          )}
        </div>
      ) : null}
    </>
  );
};
