// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WtnUCh{padding:0 12px 0px 14px !important}.WtnUCh.N_mrXK{cursor:not-allowed}.nWJjF6{display:flex;flex-direction:column;gap:10px;padding:25px;width:700px;height:500px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cta": "WtnUCh",
	"disabled": "N_mrXK",
	"main": "nWJjF6"
};
export default ___CSS_LOADER_EXPORT___;
