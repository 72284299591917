import React from 'react';
import styles from './FloatingNotification.scss';
import { Text, Button, TextButton } from '@wix/wix-base-ui';
import { dataHooks } from './dataHooks';

export interface FloatingNotificationProps {
  prefixIcon?: React.ReactElement;
  text: string;
  buttonLabel?: string;
  buttonOnClick?: () => void;
  buttonType?: 'LINK' | 'BUTTON';
  buttonIcon?: React.ReactElement;
}
export const FloatingNotification = ({
  prefixIcon,
  text,
  buttonLabel,
  buttonOnClick,
  buttonType,
  buttonIcon,
}: FloatingNotificationProps) => {
  const shouldRenderButton =
    buttonType === 'BUTTON' && buttonOnClick && buttonLabel;
  const shouldRenderLinkButton =
    buttonType === 'LINK' && buttonOnClick && buttonLabel;

  return (
    <div data-hook={dataHooks.container} className={styles.notificationWrapper}>
      <div onClick={(e) => e.stopPropagation()} className={styles.notification}>
        {prefixIcon}
        <Text
          dataHook={dataHooks.text}
          className={styles.text}
          enableEllipsis={false}
        >
          {text}
        </Text>
        {shouldRenderLinkButton && (
          <TextButton dataHook={dataHooks.linkButton} onClick={buttonOnClick}>
            {buttonLabel}
          </TextButton>
        )}
        {shouldRenderButton && (
          <Button
            dataHook={dataHooks.button}
            onClick={buttonOnClick}
            {...(buttonIcon ? { suffixIcon: buttonIcon } : {})}
          >
            {buttonLabel}
          </Button>
        )}
      </div>
    </div>
  );
};
