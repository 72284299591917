import { MODAL_TABS } from '@/toExtract/packages/packagesModal/packagesModalConsts';
import { useInternalAPIs } from '../packagesTabEntryPoint/contexts';
import { useEditorLegacyAPIs } from '@wix/wix-code-common-components';

export enum PACKAGE_VIEW_QUICK_ACTIONS {
  NEW_NPM_MODULE = 'NEW_NPM_MODULE',
  MANAGE_INSTALLED_MODULES = 'MANAGE_INSTALLED_MODULES',

  INSTALL_A_PACKAGE = 'INSTALL_A_PACKAGE',
  INSTALL_A_PACKAGE_BY_VELO = 'INSTALL_A_PACKAGE_BY_VELO', // used in empty state cta
  INSTALL_MY_PACKAGE = 'INSTALL_MY_PACKAGE',
  MANAGE_INSTALLED = 'MANAGE_INSTALLED',
  CREATE_A_PACKAGE = 'CREATE_A_PACKAGE',
  SEE_ALL_PRIVATE_APPS = 'SEE_ALL_PRIVATE_APPS',
  CREATE_NEW_APP = 'CREATE_NEW_APP',
}

const openPkgModalActions: {
  [key in PACKAGE_VIEW_QUICK_ACTIONS]?: { tabName: string };
} = {
  [PACKAGE_VIEW_QUICK_ACTIONS.NEW_NPM_MODULE]: {
    tabName: MODAL_TABS.NPM_PACKAGES,
  },
  [PACKAGE_VIEW_QUICK_ACTIONS.MANAGE_INSTALLED_MODULES]: {
    tabName: MODAL_TABS.INSTALLED_PACKAGES,
  },
  [PACKAGE_VIEW_QUICK_ACTIONS.INSTALL_A_PACKAGE]: {
    tabName: MODAL_TABS.BUILT_BY_VELO,
  },
  [PACKAGE_VIEW_QUICK_ACTIONS.INSTALL_A_PACKAGE_BY_VELO]: {
    tabName: MODAL_TABS.BUILT_BY_VELO,
  },
  [PACKAGE_VIEW_QUICK_ACTIONS.INSTALL_MY_PACKAGE]: {
    tabName: MODAL_TABS.MY_PACKAGES,
  },
  [PACKAGE_VIEW_QUICK_ACTIONS.MANAGE_INSTALLED]: {
    tabName: MODAL_TABS.INSTALLED_PACKAGES,
  },
};

export const usePkgTreeQuickActionHandler = ({
  packagesService,
  showCodeReuse,
}: {
  packagesService: AnyFixMe;
  showCodeReuse: AnyFixMe;
}) => {
  const {
    editorAPI,
    legacyDependenciesAPI: { stateManagement },
  } = useEditorLegacyAPIs();
  const {
    wixCodeStoreAPI: { dispatch, packagesActions },
  } = useInternalAPIs();
  function handleOpenPkgModal(tabName: string, packageName?: string) {
    dispatch(packagesActions.selectTab(tabName, packageName));
    editorAPI.panelManager.openPanel('wixCode.panels.PackagesModal', {
      panelName: 'wixCode.panels.PackagesModal',
      packagesService,
      showCodeReuse,
    });
  }

  function handleOpenPrivateAppModal() {
    editorAPI.panelManager.openPanel('tpa.compPanels.appMarketPanel', {
      urlParams: {},
      selectedSection: {
        id: 'private-apps',
        props: {},
      },
    });
  }

  function handleCreateAPackage() {
    editorAPI.store.dispatch(
      stateManagement.editorPlugins.panels.actions.updateOrOpenPanel(
        'wixBlocksConsumer.panels.WixBlocksPanel',
        {},
      ),
    );
  }

  const actionHandler = (
    action: PACKAGE_VIEW_QUICK_ACTIONS,
    packageName?: string,
  ) => {
    if (
      action === PACKAGE_VIEW_QUICK_ACTIONS.CREATE_A_PACKAGE ||
      action === PACKAGE_VIEW_QUICK_ACTIONS.CREATE_NEW_APP
    ) {
      handleCreateAPackage();
    } else if (action === PACKAGE_VIEW_QUICK_ACTIONS.SEE_ALL_PRIVATE_APPS) {
      handleOpenPrivateAppModal();
    } else if (Object.keys(openPkgModalActions).includes(action)) {
      handleOpenPkgModal(openPkgModalActions[action]!.tabName, packageName);
    } else {
      throw new Error(`Unexpected action ${action}`);
    }
  };

  return { actionHandler };
};
