import s from './tbfCodeEditorContainer.scss';
import React from 'react';
import { connect } from 'react-redux';
import createReactClass from 'create-react-class';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import once_ from 'lodash/once';
import noop_ from 'lodash/noop';
import flowRight_ from 'lodash/flowRight';
import PropTypes from 'prop-types';
import { wixCodeReduxContext } from '@wix/wix-code-common-components';
import codeEditorCreator from '@/codeEditor/components/codeEditor/codeEditor';
import { Tooltip, TextLabel } from '@wix/wix-base-ui';
import tbfActions from '../../actions/tbfActions';
import { isTbfSupportHttpFunctionsActive } from '@/infra/experiments/legacyExperiments';
import dataHooks from '../../dataHooks';
import bi from '../../bi';
import { injectWixCodeCodeEditorStyles } from '@/utils/injectWixCodeCodeEditorStyles';

export default once_(({ experiment, core, util, stateManagement }) => {
  injectWixCodeCodeEditorStyles({ util });

  const CodeEditor = codeEditorCreator({
    experiment,
    core,
    util,
    stateManagement,
  });

  const TbfCodeEditorContainer = createReactClass({
    displayName: 'TbfCodeEditorContainer',
    propTypes: {
      modelId: PropTypes.string.isRequired,
      fileId: PropTypes.string.isRequired,
      functionName: PropTypes.string.isRequired,
      paramNames: PropTypes.array.isRequired,
      sendBi: PropTypes.func.isRequired,
    },
    getCodeEditorProps() {
      const props = {
        key: 'tbfCodeEditor',
        modelId: this.props.modelId,
        onFocus: noop_,
        onBlur: noop_,
        onFindReplaceToggle: noop_,
        onFindInAllFiles: noop_,
        onSeeAllShortcuts: noop_,
        readOnly: false,
        getFileContext: () => ({ type: 'public' }),
        allowShortcutsPopover: true,
      };
      return props;
    },

    render() {
      return (
        <div
          className={`${s.tbfCodeEditorContainer} tbf-code-editor-container`}
          data-hook={dataHooks.TBF_CODE_EDITOR_CONTAINER}
        >
          <Tooltip
            key="clearConsoleOutputTooltip"
            direction="TOP"
            alignment="LEFT"
            marginLeft={8}
            marginTop={2}
            content={
              <TextLabel
                type="T04"
                value={util.translate('Trigger_Function_LeftPanel_Reset')}
              />
            }
          >
            <div
              onClick={() => {
                this.props.resetTbfModel();
                this.props.sendBi(
                  bi.events.TBF_TRIGGER_PANEL_ACTION,
                  bi.eventBuilders.resetParameters({
                    parametersCount: this.props.paramNames.length,
                    fileName: this.props.fileId,
                    origin: 'button',
                    functionName: this.props.functionName,
                  }),
                );
              }}
              className={s.tbfEditorClearButton}
              data-hook={dataHooks.TBF_CODE_EDITOR_CLEAR_BUTTON}
            >
              <Symbol
                name="tbf_trash_white"
                className={s.tbfEditorClearButtonHoverSymbol}
              />
              <Symbol
                name="tbf_reset"
                className={s.tbfEditorClearButtonNormalSymbol}
              />
            </div>
          </Tooltip>

          <CodeEditor {...this.getCodeEditorProps()} />
        </div>
      );
    },
  });

  const mapDispatchToProps = (dispatch: AnyFixMe, ownProps: AnyFixMe) => ({
    resetTbfModel: () =>
      dispatch(
        tbfActions.resetTbfModel({
          isHttpFunctionExperimentOn:
            isTbfSupportHttpFunctionsActive(experiment),
          fileId: ownProps.fileId,
          functionName: ownProps.functionName,
          paramNames: ownProps.paramNames,
        }),
      ),
  });

  return flowRight_(
    connect(null, mapDispatchToProps, null, {
      forwardRef: true,
      context: wixCodeReduxContext,
    }),
  )(TbfCodeEditorContainer);
});
