/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { RichText, Button } from '@wix/wix-base-ui';
import { TFunction } from '@wix/wix-i18n-config';

type PageElementsRestrictedGlobalElementInteractionStateProps = {
  t: TFunction;
  openMasterPage: () => void;
  openHelpPanel: (id: string, options?: Record<string, any>) => void;
};

const PageElementsRestrictedGlobalElementInteractionState = ({
  t,
  openMasterPage,
  openHelpPanel,
}: PageElementsRestrictedGlobalElementInteractionStateProps) => {
  const openHelpPanelLinkText = t(
    'WixCode_PropertiesAndEventsPanel_RestrictedGlobalElementInteraction_Link_Text',
  );
  return (
    <div
      data-aid="restricted-global-element-interaction-state"
      className="empty-state"
    >
      <div style={{ marginTop: '12px', width: '100%' }} />
      <RichText className="text-container">
        <p>
          <span data-aid="description">
            {t(
              'WixCode_PropertiesAndEventsPanel_RestrictedGlobalElementInteraction_Body',
            )}
          </span>
          &nbsp;
          <a
            data-aid="help-link"
            onClick={() =>
              openHelpPanel('2ec37118-35d1-4068-a491-7640c1481aa1', {
                linkText: openHelpPanelLinkText,
              })
            }
          >
            {openHelpPanelLinkText}
          </a>
        </p>
      </RichText>
      <br />
      <Button
        className="btn-sm"
        automationId="open-master-page"
        onClick={() => {
          openMasterPage();
        }}
      >
        {t(
          'WixCode_PropertiesAndEventsPanel_RestrictedGlobalElementInteraction_Button_Text',
        )}
      </Button>
    </div>
  );
};

export default PageElementsRestrictedGlobalElementInteractionState;
