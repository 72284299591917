// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ufRuU4{overflow-y:auto;padding:30px 30px 0 30px;flex:1}.dsGwKH{display:flex;height:100%;align-items:center;justify-content:center}.waJXAT{display:flex;height:50%;align-items:center;justify-content:center}.LPuz3N{border-top:1px solid #dfe5eb;height:47px;display:flex;align-items:center}.LPuz3N .gpZi1U{text-align:center}.q4BFRB{height:calc(100% - 47px)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "ufRuU4",
	"preloader": "dsGwKH",
	"halfloader": "waJXAT",
	"footer": "LPuz3N",
	"link-wrapper": "gpZi1U",
	"linkWrapper": "gpZi1U",
	"readme-description": "q4BFRB",
	"readmeDescription": "q4BFRB"
};
export default ___CSS_LOADER_EXPORT___;
