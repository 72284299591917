import React from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { CompRef } from '@wix/document-services-types';
import { ExternalLinkSmall } from '@wix/wix-ui-icons-common';
import { Divider, TextButton, TextLabel } from '@wix/wix-base-ui';
import { ideLinkToElementsApiReferenceClicked } from '@wix/bi-logger-platform/v2';
import { useBiLogger } from '../../../../biLoggerContext';
import { useCssClasses } from '../../../../createCssClasses';
import s from './cssReference.scss';
import styles from '../panelContent.scss';
import dataHooks from '../../../dataHooks';

type CssReferenceProps = { compRef: CompRef };

export const CssReference = ({ compRef }: CssReferenceProps) => {
  const [t] = useTranslation();
  const biLoggerAPI = useBiLogger();
  const { cssReference, elementTypeName } = useCssClasses(compRef);

  const handleOpenCssReferenceLink = async () => {
    if (cssReference) {
      window.open(cssReference, '_blank');
      biLoggerAPI.report(
        ideLinkToElementsApiReferenceClicked({
          is_css: true,
        }),
      );
    }
  };

  return cssReference ? (
    <>
      <div className={s.section} data-hook={dataHooks.cssReference}>
        <TextLabel
          className={s.sectionLabel}
          enableEllipsis={false}
          shouldTranslate={false}
          type="T04"
          value={t('WixCode_CssClassesPanel_Reference_Title') + ' '}
        />
        <span className={s.linkContainer}>
          <TextButton
            dataHook={dataHooks.cssReferenceCta}
            size="tiny"
            weight="normal"
            onClick={handleOpenCssReferenceLink}
          >
            {t('WixCode_CssClassesPanel_Reference_LinkText', {
              element_name: elementTypeName,
            })}
          </TextButton>
          <ExternalLinkSmall className={s.link} />
        </span>
      </div>
      <Divider className={styles.divider} long />
    </>
  ) : null;
};
