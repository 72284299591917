import React from 'react';
import { MessageModal, Text } from '@wix/wix-base-ui';
import { Trans, useTranslation } from '@wix/wix-i18n-config';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { TranslationProvider } from '../../i18n';
import { LocalEditorBIEvents } from '../../utils/biEvents';

import { MODALS_ACTIONS } from '../../utils/biConsts';
import dataHooks from '../dataHooks';

interface ErrorModalProps {
  titleKey: string;
  contentKey: string;
  primaryButtonOnClick: () => void;
  biEvents?: LocalEditorBIEvents;
}

const createShowErrorModal =
  (wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI) =>
  ({
    titleKey,
    contentKey,
    primaryButtonOnClick,
    biEvents,
  }: ErrorModalProps) => {
    const ErrorModal: React.FC<{ closeModal: () => void }> = ({
      closeModal,
    }) => {
      const [t] = useTranslation();
      const reportPopUpBiEvent = (action: string) => {
        biEvents && biEvents.syncChangesFailedPopUpAction({ action });
      };

      const ErrorContent = () => (
        <Text
          size="small"
          skin="secondary"
          enableEllipsis={false}
          shouldTranslate={false}
        >
          <Trans
            i18nKey={contentKey}
            components={[
              <a
                href={`${t(
                  'localEditor.error_modal.general_error.learnMoreBtn.link',
                )}`}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  reportPopUpBiEvent(MODALS_ACTIONS.LEARN_MORE);
                }}
              >
                Learn More
              </a>,
            ]}
          />
        </Text>
      );

      return (
        <MessageModal
          dataHook={dataHooks.errorModal}
          title={t(titleKey)}
          content={<ErrorContent />}
          primaryButtonText={t(
            'localEditor.error_modal.general_error.primary_button_text',
          )}
          primaryButtonOnClick={async () => {
            reportPopUpBiEvent(MODALS_ACTIONS.TRY_AGAIN);
            primaryButtonOnClick();
            closeModal();
          }}
          secondaryButtonText={t(
            'localEditor.error_modal.general_error.secondary_button_text',
          )}
          secondaryButtonOnClick={() => {
            reportPopUpBiEvent(MODALS_ACTIONS.CANCEL);
            closeModal();
          }}
        />
      );
    };

    wixCodeEditorAdapterAPI.modalAPI.showModal(
      (closeModal) => (
        <TranslationProvider>
          <ErrorModal closeModal={closeModal} />
        </TranslationProvider>
      ),
      {
        backdrop: true,
        closeOnClickOutside: true,
      },
    );
  };

export type ShowErrorModal = ReturnType<typeof createShowErrorModal>;
export default createShowErrorModal;
