import React from 'react';
import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { COLLECTIONS_TAB_ID, cloudDbReadyEvent } from './constants';
import { sideBarContent } from './components/CollectionsTab/CollectionsTab';
import {
  InterceptorsAction,
  interceptorsReducer,
  InterceptorsState,
} from './state/interceptorsReducer';
import {
  CollectionsEvent,
  Interceptor,
  PredefinedSidebarTabs,
  TabProps,
} from '@wix/wix-code-plugin-contracts';
import { addInterceptor } from './state/interceptorActions';
import { CloudDbSuccessModal } from './components/CloudDbSuccessModal';
import { createCollectionsMenuTab } from './components/CollectionsTab/CollectionsMenuTab';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';

export type CollectionsStore = {
  interceptors: InterceptorsState;
};
type SideBarContentProps = {
  tabProps?: TabProps[PredefinedSidebarTabs.COLLECTIONS];
  closePanel?: () => void;
};
export const CollectionsEntryPoint: EntryPoint = {
  name: 'Wix Code Collections Plugin',
  getDependencyAPIs() {
    return [
      APIKeys.SidebarAPI,
      APIKeys.ExperimentsAPI,
      APIKeys.PanelsAPI,
      APIKeys.ClassicEditorAPI,
      APIKeys.LegacyEditorDependencies,
      APIKeys.PlatformAppsAPI,
      APIKeys.SiteAPI,
      APIKeys.ReadOnlyAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.WixCodeAppAPI,
      APIKeys.MenuAPI,
      APIKeys.MenuPanelAPI,
      APIKeys.UserPreferencesAPI,
      APIKeys.FilesTreeAPI,
      APIKeys.DuplexerAPI,
      WixCodeEditorAdapterAPI,
    ];
  },
  declareAPIs() {
    return [APIKeys.CollectionsAPI];
  },
  attach(shell: Shell) {
    shell.contributeState<CollectionsStore, InterceptorsAction>(() => ({
      interceptors: interceptorsReducer,
    }));
    const store = shell.getStore<CollectionsStore>();
    shell.contributeAPI(APIKeys.CollectionsAPI, () => ({
      addInterceptor: (
        eventName: CollectionsEvent,
        interceptor: Interceptor,
      ) => {
        store.dispatch(addInterceptor(eventName, interceptor));
      },
    }));
  },
  extend(shell: Shell) {
    const sidebarAPI = shell.getAPI(APIKeys.SidebarAPI);
    const experimentsAPI = shell.getAPI(APIKeys.ExperimentsAPI);
    const panelsAPI = shell.getAPI(APIKeys.PanelsAPI);
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
    const legacyEditorDependencies = shell.getAPI(
      APIKeys.LegacyEditorDependencies,
    );
    const wixCodeEditorAdapterAPI = shell.getAPI(WixCodeEditorAdapterAPI);

    const platformAppsAPI = shell.getAPI(APIKeys.PlatformAppsAPI);
    const siteAPI = shell.getAPI(APIKeys.SiteAPI);
    const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
    const wixCodeAppAPI = shell.getAPI(APIKeys.WixCodeAppAPI);
    const readOnlyAPI = shell.getAPI(APIKeys.ReadOnlyAPI);
    const menuAPI = shell.getAPI(APIKeys.MenuAPI);
    const menuPanelAPI = shell.getAPI(APIKeys.MenuPanelAPI);
    const userPreferencesAPI = shell.getAPI(APIKeys.UserPreferencesAPI);
    const filesTreeAPI = shell.getAPI(APIKeys.FilesTreeAPI);
    const duplexerAPI = shell.getAPI(APIKeys.DuplexerAPI);

    const connection = duplexerAPI.connect(cloudDbReadyEvent.appDefId);

    connection.on('@duplexer:connected', () => {
      const channel = connection.subscribe('cloud-db-provisioning');
      channel.on(cloudDbReadyEvent.eventName, () => {
        panelsAPI.openPanel(
          <CloudDbSuccessModal
            createCollectionEvent={
              legacyEditorDependencies.platform.constants.EVENTS
                .createCollectionButtonClicked
            }
            languageCode={
              legacyEditorDependencies.util.editorModel.languageCode
            }
            panelsAPI={panelsAPI}
            platformAppsAPI={platformAppsAPI}
          />,
          true,
        );
      });
    });

    const getSideBarContent = ({ tabProps }: SideBarContentProps) => ({
      ...sideBarContent(
        {
          panelsAPI,
          editorAPI,
          legacyEditorDependencies,
          platformAppsAPI,
          siteAPI,
          experimentsAPI,
          biLoggerAPI,
          wixCodeAppAPI,
          readOnlyAPI,
          userPreferencesAPI,
          filesTreeAPI,
        },
        shell,
        tabProps,
      ),
    });

    sidebarAPI.contributeTab<PredefinedSidebarTabs.COLLECTIONS>((tabProps) => ({
      id: COLLECTIONS_TAB_ID,
      label: 'Left_Tree_Components_Title_Databases',
      infoLinkText: 'Left_Tree_Components_Tooltip_Databases_Link_Text',
      infoLinkUrl:
        tabProps?.translations?.learnMoreLink ||
        'Left_Tree_Components_Tooltip_Databases_Link_URL',
      infoText:
        tabProps?.translations?.headerTooltip ||
        'Left_Tree_Components_Tooltip_Databases',
      dataHook: 'collections_tab',
      shouldShowTab: () => true,
      ...getSideBarContent({ tabProps }),
    }));

    menuPanelAPI.contributeTab({
      id: 'databases',
      TabContent: ({ closePanel }: { closePanel: () => void }) => {
        const TabContent = getSideBarContent({}).creator();
        return createCollectionsMenuTab({
          closePanel,
          children: TabContent,
          apis: {
            legacyDependenciesAPI: wixCodeEditorAdapterAPI.legacyDependencies,
            panelsAPI,
          },
        });
      },
    });
    menuAPI.registerMenuItemListener('databases', () => {
      menuPanelAPI.selectTab('databases');
    });
  },
};
