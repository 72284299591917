import React, { useEffect, useState } from 'react';
import { CustomModal, Spacer, Button } from '@wix/wix-base-ui';
import dataHooks from '../dataHooks';
import SignInInfo from './SignInInfo/SignInInfo';
import InstallApp from './InstallApp/InstallApp';
import { connectWithShell, Shell } from '@wix/wix-code-repluggable';
import { GithubState } from '../../state';
import { GithubPrivateAPI } from '../../githubPrivateAPI';
import styles from './ConnectInPhasesModal.scss';
import {
  GithubRepository,
  GithubUser,
  OnboardingState,
  RepositoryOwnerType,
} from '../../types/GithubTypes';
import { useTranslation } from '@wix/wix-i18n-config';
import { ClassicEditorAPI } from '@wix/wix-code-plugin-contracts';
import InstalledApp from './InstallApp/InstalledApp';
import Padder from './Padder';
import {
  GITHUB_LOGIN_SESSION_EXPIRED_ERROR,
  GITHUB_REPOSITORY_EXISTS_ERROR,
} from '../../constants';
import CreateRepositoryFlow from './CreateRepositoryFlow/CreateRepositoryFlow';
import { useBiLogger } from '../../context/biLoggerContext';
import { gitHubOnboardingSuccessStatus } from '@wix/bi-logger-platform/v2';
import {
  signInAction,
  installAppAction,
  closeModalAction,
  createRepoModalActions,
  createRepoFailed,
} from '../../utils/biEvents';
import {
  CONNECT_TO_GITHUB_MODAL_STEPS,
  MODALS_ACTIONS,
} from '../../utils/biConsts';
import Steps from '../Steps/Steps';
import { ErrorBanner } from './ErrorBanner';

export interface Step {
  onboardingState: OnboardingState;
  modalContent: () => React.ReactNode;
  value: string;
  cta: () => JSX.Element;
}

export interface ConnectInPhasesModalProps {
  githubAPI: GithubPrivateAPI;
  classicEditorAPI: ClassicEditorAPI;
  getSiteName: () => string;
  closeModal: () => void;
  onPhasesCompleted: () => void;
}

interface ConnectInPhasesModalStateProps {
  onboardingState: OnboardingState;
  githubUser?: GithubUser;
  githubRepository?: GithubRepository;
}

export const ConnectInPhasesModal: React.FC<
  ConnectInPhasesModalProps & ConnectInPhasesModalStateProps
> = ({
  closeModal,
  onPhasesCompleted,
  getSiteName,
  onboardingState,
  githubUser,
  githubAPI,
}) => {
  const [t] = useTranslation();
  const bi = useBiLogger();

  useEffect(() => {
    if (onboardingState === OnboardingState.GITHUB_ENABLED) {
      closeModal();
      onPhasesCompleted();
    }
  }, [onboardingState, closeModal, onPhasesCompleted]);

  useEffect(() => {
    if (githubUser && githubUser.userLogin) {
      setUserAccount(githubUser.userLogin);
    }
  }, [githubUser]);

  const [userAccount, setUserAccount] = useState<string>('');
  const [accountType, setAccountType] = useState<RepositoryOwnerType>(
    RepositoryOwnerType.USER,
  );
  const [repositoryName, setRepositoryName] = useState<string>(getSiteName());

  const [repositoryDescription, setRepositoryDescription] = useState('');
  const [showError, setShowError] = useState(false);

  const [actionInPrgoress, setActionInProgress] = useState(false);
  const [doesRepoNameExist, setDoesRepoNameExist] = useState(false);
  const [loginSessionWasExpired, setLoginSessionWasExpired] = useState(false);

  const onCloseBiReportEvent = (onBoardingState: OnboardingState) => {
    let step: string;
    switch (onBoardingState) {
      case OnboardingState.GITHUB_DISABLED:
        step = CONNECT_TO_GITHUB_MODAL_STEPS.SIGN_IN;
        break;
      case OnboardingState.GITHUB_AUTHORIZED:
        step = CONNECT_TO_GITHUB_MODAL_STEPS.CREATE;
        break;
      case OnboardingState.GITHUB_REPOSITORY_CREATED:
        step = CONNECT_TO_GITHUB_MODAL_STEPS.INSTALL;
        break;
      default:
        step = '';
        break;
    }
    bi.report(closeModalAction({ step }));
  };

  const handleGitAccountOnChange = (accountName: string) => {
    const innerAccountType =
      accountName === githubUser!.userLogin // At the phase of CreateRepository we are positive that we hold a defined GithubUser instance
        ? RepositoryOwnerType.USER
        : RepositoryOwnerType.ORGANIZATION;
    setAccountType(innerAccountType);
    setUserAccount(accountName);
  };
  const handleRepoNameOnChange = (repoName: string) => {
    setDoesRepoNameExist(false);
    setRepositoryName(repoName);
  };
  const handleRepoDescriptionOnChange = (description: string) => {
    setRepositoryDescription(description);
  };
  const handleError = () => setShowError(true);
  const resetErrorBanner = () => {
    if (showError) {
      setShowError(false);
    }
  };
  const handleCreateRepositoryError = (error: any) => {
    const errorCode = error?.response?.status;
    if (errorCode === GITHUB_REPOSITORY_EXISTS_ERROR) {
      setDoesRepoNameExist(true);
      return;
    }
    if (errorCode === GITHUB_LOGIN_SESSION_EXPIRED_ERROR) {
      setLoginSessionWasExpired(true);
    }
    handleError();
  };

  const steps: Step[] = [
    {
      onboardingState: OnboardingState.GITHUB_DISABLED,
      value: 'githubIntegration.connect_modal.steps.sign_in',
      modalContent: () => (
        <SignInInfo
          showCentralLoaderSpinner={actionInPrgoress}
          translate={t}
          biLoggerAPI={bi}
        />
      ),
      cta: () => {
        return (
          <Button
            dataHook={dataHooks.connectModal.primaryButton}
            onClick={async () => {
              bi.report(signInAction({ action: MODALS_ACTIONS.SIGN_IN }));
              resetErrorBanner();
              try {
                await githubAPI.authorizeGithubUser(() =>
                  setActionInProgress(true),
                );
              } catch (error) {
                handleError();
              } finally {
                setActionInProgress(false);
              }
            }}
            disabled={actionInPrgoress}
            className={`btn-md ${styles.primaryButton}`}
          >
            {t('githubIntegration.connect_modal.sign_in.cta')}
          </Button>
        );
      },
    },
    {
      onboardingState: OnboardingState.GITHUB_AUTHORIZED,
      value: 'githubIntegration.connect_modal.steps.create_repository',
      modalContent: () => (
        <CreateRepositoryFlow
          gitHubUserInfo={githubUser!} // At the phase of CreateRepository we are positive that we hold a defined GithubUser instance
          translate={t}
          onAccountChange={handleGitAccountOnChange}
          onRepoNameChange={handleRepoNameOnChange}
          onDescriptionChange={handleRepoDescriptionOnChange}
          userAccount={userAccount}
          repoName={repositoryName}
          repoDescription={repositoryDescription}
          siteName={getSiteName()}
          doesRepoNameExist={doesRepoNameExist}
          showCentralLoaderSpinner={actionInPrgoress}
        />
      ),
      cta: () => {
        return (
          <Button
            dataHook={dataHooks.connectModal.primaryButton}
            onClick={async () => {
              bi.report(
                createRepoModalActions({
                  action: MODALS_ACTIONS.CREATE,
                }),
              );
              resetErrorBanner();
              try {
                setActionInProgress(true);
                await githubAPI.createGitHubRepository(
                  {
                    ownerType: accountType,
                    login: userAccount,
                  },
                  repositoryName,
                  repositoryDescription,
                );
              } catch (error) {
                bi.report(createRepoFailed());
                handleCreateRepositoryError(error);
              } finally {
                setActionInProgress(false);
              }
            }}
            className={`btn-md ${styles.primaryButton}`}
            disabled={actionInPrgoress}
          >
            {t('githubIntegration.connect_modal.create_repository.cta')}
          </Button>
        );
      },
    },
    {
      onboardingState: OnboardingState.GITHUB_REPOSITORY_CREATED,
      value: 'githubIntegration.connect_modal.steps.install_app',
      modalContent: () => {
        if (!githubAPI.isVeloAppInstalled()) {
          return (
            <InstallApp
              showCentralLoaderSpinner={actionInPrgoress}
              translate={t}
            />
          );
        }
        return (
          <InstalledApp
            translate={t}
            showCentralLoaderSpinner={actionInPrgoress}
          />
        );
      },
      cta: () => {
        return (
          <Button
            dataHook={dataHooks.connectModal.primaryButton}
            onClick={async () => {
              bi.report(
                installAppAction({ githubAPI, action: MODALS_ACTIONS.INSTALL }),
              );
              resetErrorBanner();
              try {
                if (githubAPI.isVeloAppInstalled()) {
                  setActionInProgress(true);
                }
                await githubAPI.initGitHubRepository(() =>
                  setActionInProgress(true),
                );
                bi.report(gitHubOnboardingSuccessStatus({ success: true }));
              } catch (error) {
                handleError();
                bi.report(gitHubOnboardingSuccessStatus({ success: false }));
              } finally {
                setActionInProgress(false);
              }
            }}
            disabled={actionInPrgoress}
            className={`btn-md ${styles.primaryButton}`}
          >
            {githubAPI.isVeloAppInstalled()
              ? t('githubIntegration.connect_modal.installed_app_case.cta')
              : t('githubIntegration.connect_modal.install_app.cta')}
          </Button>
        );
      },
    },
  ];
  const currentStep =
    steps.find((step) => step.onboardingState === onboardingState) || steps[0];
  const currentStepIdx = steps.findIndex(
    (step) => step.onboardingState === onboardingState,
  );

  return (
    <CustomModal
      onCloseButtonClick={() => {
        onCloseBiReportEvent(currentStep.onboardingState);
        closeModal();
        if (loginSessionWasExpired) {
          githubAPI.setOnboardingStatus({
            onboardingState: OnboardingState.GITHUB_DISABLED,
          });
        }
      }}
      title={t('githubIntegration.connect_modal.title')}
      dataHook={dataHooks.connectModal.container}
      footerContent={
        <div className={styles.footerContent}>{currentStep.cta()}</div>
      }
      removeContentPadding={true}
    >
      <div className={styles.container}>
        <Padder size="24px">
          <Steps
            steps={steps}
            translate={t}
            currentStepIndex={currentStepIdx}
            onboardingState={onboardingState}
            isError={showError || doesRepoNameExist}
          />
        </Padder>
        {showError && (
          <ErrorBanner
            t={t}
            githubAPI={githubAPI}
            loginSessionWasExpired={loginSessionWasExpired}
            resetErrorBanner={resetErrorBanner}
          />
        )}
        <Spacer type="Spacer06" />
        <Padder size="24px" maxHeight={true}>
          {currentStep.modalContent()}
        </Padder>
      </div>
    </CustomModal>
  );
};

const createConnectInPhasesModal = (
  shell: Shell,
  githubAPI: GithubPrivateAPI,
) => {
  return connectWithShell<
    GithubState,
    ConnectInPhasesModalProps,
    ConnectInPhasesModalStateProps
  >(
    () => ({
      onboardingState: githubAPI.getOnboardingState(),
      githubUser: githubAPI.getGithubUser(),
      githubRepository: githubAPI.getGithubRepository(),
    }),
    undefined,
    shell,
    {
      renderOutsideProvider: true,
    },
  )(ConnectInPhasesModal);
};

export default createConnectInPhasesModal;
