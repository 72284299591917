// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xpNE1C{display:flex;height:100%;width:100%;flex-direction:column;justify-content:center;align-items:center}.xpNE1C .ue1_5P{margin-top:24px;margin-bottom:8px}.xpNE1C .ebQYDP{text-align:center}.xpNE1C .mt9Nmo{margin:24px 0}.xpNE1C .zK7KDh{margin-top:24px;margin-bottom:8px}.xpNE1C .P_aMfg{max-width:300px;display:flex;flex-direction:column;align-items:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "xpNE1C",
	"title": "ue1_5P",
	"subtitle": "ebQYDP",
	"description-list": "mt9Nmo",
	"descriptionList": "mt9Nmo",
	"main-cta": "zK7KDh",
	"mainCta": "zK7KDh",
	"content-wrapper": "P_aMfg",
	"contentWrapper": "P_aMfg"
};
export default ___CSS_LOADER_EXPORT___;
