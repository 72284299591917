import React from 'react';
import { ExternalLink } from 'wix-ui-icons-common';
import { Banner } from '@wix/wix-code-common-components';
import { i18n, I18nextProvider, useTranslation } from '@wix/wix-i18n-config';
import {
  VSCodePublicAPI,
  BiLoggerAPI,
  MenuItemId,
} from '@wix/wix-code-plugin-contracts';
import { CODE_PANEL_ITEMS, codePanelEvent } from '../../bi';
import { wixIdeButton } from '@wix/bi-logger-platform/v2';

interface ExternalBannerProps {
  vsCodeApi: VSCodePublicAPI;
  biLoggerAPI: BiLoggerAPI;
  getSelectedMenuItem: () => MenuItemId;
}

interface ExternalBannerPropsCreator extends ExternalBannerProps {
  i18nInstance: i18n;
}

export const ExternalCodeEditorBannerComp: React.FC<ExternalBannerProps> = ({
  vsCodeApi,
  biLoggerAPI,
  getSelectedMenuItem,
}) => {
  const [t] = useTranslation();
  const onClickHandler = () => {
    vsCodeApi.openVSCodeOnlineTab();
    biLoggerAPI.report(codePanelEvent(CODE_PANEL_ITEMS.CODE_IN_WIX_IDE));
    biLoggerAPI.report(
      wixIdeButton({
        action: 'click',
        input_name: 'code_in_wix_ide',
        origin: 'external_ide_banner',
        environment: `${getSelectedMenuItem()}_tab`,
      }),
    );
  };
  return (
    <Banner
      description={t('lite_panel.ide_entry.description')}
      onClick={onClickHandler}
      buttonName={t('lite_panel.ide_entry.cta')}
      suffixIcon={<ExternalLink />}
    />
  );
};

export const externalCodeEditorBannerCreator =
  ({
    vsCodeApi,
    i18nInstance,
    biLoggerAPI,
    getSelectedMenuItem,
  }: ExternalBannerPropsCreator) =>
  () =>
    (
      <I18nextProvider i18n={i18nInstance}>
        <ExternalCodeEditorBannerComp
          vsCodeApi={vsCodeApi}
          biLoggerAPI={biLoggerAPI}
          getSelectedMenuItem={getSelectedMenuItem}
        />
      </I18nextProvider>
    );
