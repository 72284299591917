import React from 'react';
import { initI18n } from '@wix/wix-i18n-config';
import { MessageModal } from '@wix/wix-base-ui';
import { PanelsAPI, PlatformAppsAPI } from '@wix/wix-code-plugin-contracts';

interface CloudDbSuccessModalProps {
  languageCode: string;
  panelsAPI: PanelsAPI;
  platformAppsAPI: PlatformAppsAPI;
  createCollectionEvent: string;
}

export const CloudDbSuccessModal: React.FC<CloudDbSuccessModalProps> = ({
  languageCode,
  panelsAPI,
  platformAppsAPI,
  createCollectionEvent,
}) => {
  const { t } = initI18n({
    locale: languageCode,
    asyncMessagesLoader: (locale: string) =>
      import(`../assets/locale/messages_${locale}.json`),
  });

  return (
    <MessageModal
      title={t('External_Databases.Created.Modal.DatabaseIsReady.Title')}
      content={t(
        'External_Databases.Created.Modal.DatabaseIsReady.Description',
      )}
      primaryButtonText={t(
        'External_Databases.Created.Modal.DatabaseIsReady.PrimaryCTA',
      )}
      secondaryButtonText={t(
        'External_Databases.Created.Modal.DatabaseIsReady.SecondaryCTA',
      )}
      primaryButtonOnClick={() => {
        platformAppsAPI.notifyDataBinding({
          eventType: createCollectionEvent,
          eventPayload: {
            origin: 'ideTree',
            clickedInSiteStructure: true,
            namespace: 'test',
          },
        });
      }}
      secondaryButtonOnClick={() => panelsAPI.closePanel()}
    />
  );
};
