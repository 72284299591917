import React from 'react';
import { Text, TextButton } from '@wix/wix-base-ui';
import s from './ConsoleEmptyState.scss';
import { useTranslate } from '@/infra/i18n/useTranslate';

export const ConsoleEmptyState: React.FC = () => {
  const { t } = useTranslate();
  return (
    <div className="no-logs-to-show">
      <Text size="medium" shouldTranslate={false}>
        {t('DevConsole_Empty_State_Title')}
      </Text>
      <div className={s.smallMargin} />
      <Text size="small" skin="secondary" shouldTranslate={false}>
        {t('DevConsole_Empty_State_Subtitle')}
      </Text>
      <div className={s.smallMargin} />
      <TextButton
        size="small"
        onClick={() => {
          window.open(t('DevConsole_Empty_State_Link_Url'));
        }}
        shouldTranslate={false}
      >
        {t('DevConsole_Empty_State_Link')}
      </TextButton>
    </div>
  );
};
