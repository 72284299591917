import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import _ from 'lodash';
import userPreferenceUtilCreator from '@/utils/userPreference';

const dealerOfferHideKey = 'wixCode.dealerOffers.hide';
const HIDE_ALL_DEALER_OFFERS = 'HIDE_ALL_DEALER_OFFERS';

type DealerUserPreferencesEntry = {
  [hiddenOfferGuid: string]: boolean;
};

export const dealerUserPreferences = ({
  stateManagement,
}: {
  stateManagement: any;
}) => {
  const { setGlobalPreference, getGlobalPreference, getSitePreference } =
    userPreferenceUtilCreator({
      stateManagement,
    });

  const hideDealerOffer = (editorAPI: EditorAPI, offerGuid: string) => {
    const entry = getGlobalPreference<DealerUserPreferencesEntry>(
      editorAPI,
      dealerOfferHideKey,
    );

    if (!_.has(entry, offerGuid)) {
      return setGlobalPreference(editorAPI, dealerOfferHideKey, {
        ...entry,
        [offerGuid]: true,
      });
    }
  };

  const isDealerOfferHidden = (editorAPI: EditorAPI, offerGuid: string) => {
    const sitePreference = getSitePreference(editorAPI, dealerOfferHideKey);
    const globalPreference = getGlobalPreference(editorAPI, dealerOfferHideKey);

    return (
      _.has(sitePreference, offerGuid) ||
      _.has(globalPreference, offerGuid) ||
      _.has(sitePreference, HIDE_ALL_DEALER_OFFERS) ||
      _.has(globalPreference, HIDE_ALL_DEALER_OFFERS)
    );
  };

  return {
    isDealerOfferHidden,
    hideDealerOffer,
  };
};
