import { AppState } from './rootReducer';

function getLeftPaneViewMode(state: AppState) {
  return state.leftPane.viewMode;
}

function getLeftPaneSelectedTab(state: AppState) {
  return state.leftPane.selectedTab;
}

export default {
  getLeftPaneViewMode,
  getLeftPaneSelectedTab,
};
