// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ptDrTy{height:100%;overflow-y:auto}.luZAnj{padding:20px}.luZAnj .lXl1t9{background-color:#f7f8f8;border-radius:8px;padding:21px 24px 21px 22px}.hxDzWe{display:flex;align-items:center;justify-content:flex-start;border-top:1px solid #dfe5eb;padding:13px 18px;width:100%;height:21px;background-color:#fff;bottom:0px;left:0px}.tgeirO{overflow-y:auto}.YGHcWW{position:relative;height:100%;display:flex;flex-direction:column;justify-content:space-between}.Njgu2D{padding:10px;border-bottom:1px solid #dfe5eb}.fFNAPq{display:flex;flex-direction:column;justify-content:center;align-items:center;height:80%;gap:10px;margin:-1px}.fFNAPq *{text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scroller": "ptDrTy",
	"request-package-wrapper": "luZAnj",
	"requestPackageWrapper": "luZAnj",
	"request-package": "lXl1t9",
	"requestPackage": "lXl1t9",
	"my-packages-footer": "hxDzWe",
	"myPackagesFooter": "hxDzWe",
	"my-pkgs-scroller": "tgeirO",
	"myPkgsScroller": "tgeirO",
	"my-packages-container": "YGHcWW",
	"myPackagesContainer": "YGHcWW",
	"search-input": "Njgu2D",
	"searchInput": "Njgu2D",
	"no-result-wrapper": "fFNAPq",
	"noResultWrapper": "fFNAPq"
};
export default ___CSS_LOADER_EXPORT___;
