import * as React from 'react';
import dataHooks from '../../../dataHooks';
import bi from '@/legacy/bi/bi';
import { FileTreeParagraphEmptyState } from '../../../filesTree/FileTreeParagraphEmptyState';
import { PACKAGE_VIEW_QUICK_ACTIONS } from '../pkgTreeQuickActions';
import { PkgTreeContext } from '../PkgTreeContext';
import {
  ReadOnlyModeContext,
  useTranslation,
  useEditorLegacyAPIs,
} from '@wix/wix-code-common-components';

interface CodeReuseScopeEmptyStateProps {
  isPrivateScope?: boolean;
  actionHandler: (
    action: PACKAGE_VIEW_QUICK_ACTIONS,
    packageName?: string,
  ) => void;
}

export const CodeReuseScopeEmptyState: React.FC<
  CodeReuseScopeEmptyStateProps
> = ({ actionHandler, isPrivateScope = false }) => {
  const { editorAPI } = useEditorLegacyAPIs();
  const [t] = useTranslation();
  const { readOnlyMode } = React.useContext(ReadOnlyModeContext);
  const { isBlocksCombinedMode } = React.useContext(PkgTreeContext);

  const emptyStateCtaClick = () => {
    editorAPI.bi.event(
      bi.events.LEFT_TREE_CLICK_ON_ADD_QUICK_LINK,
      isBlocksCombinedMode
        ? {
            section_name: isPrivateScope ? 'private_apps' : 'velo_packages',
            sub_section: isPrivateScope ? 'private_apps' : 'velo_packages',
          }
        : {
            section_name: 'internal_packages',
            sub_section: isPrivateScope ? 'private_packages' : 'velo_packages',
          },
    );

    return isPrivateScope
      ? actionHandler(
          isBlocksCombinedMode
            ? PACKAGE_VIEW_QUICK_ACTIONS.CREATE_NEW_APP
            : PACKAGE_VIEW_QUICK_ACTIONS.CREATE_A_PACKAGE,
        )
      : actionHandler(PACKAGE_VIEW_QUICK_ACTIONS.INSTALL_A_PACKAGE_BY_VELO);
  };

  const emptyStateText = () => {
    let description = '';
    let ctaText = '';
    if (isPrivateScope) {
      description = isBlocksCombinedMode
        ? 'Left_Tree_Packages_Apps_And_Packages_Description'
        : 'Left_Tree_Packages_My_Packages_Description';
      ctaText = isBlocksCombinedMode
        ? 'Left_Tree_Packages_Apps_And_Packages_Action_Link'
        : 'Left_Tree_Packages_My_Packages_Action_Link';
    } else {
      description = isBlocksCombinedMode
        ? 'Left_Tree_Components_Category_CodeFiles_Velo_Apps_Corvid_EmptyState'
        : 'Left_Tree_Components_Category_CodeFiles_Packages_Corvid_EmptyState';
      ctaText = isBlocksCombinedMode
        ? 'Left_Tree_Components_Category_CodeFiles_Velo_App_Corvid_Button'
        : 'Left_Tree_Components_Category_CodeFiles_Packages_Corvid_Button';
    }
    return {
      description: t(description),
      ctaText: t(ctaText),
    };
  };

  return (
    <FileTreeParagraphEmptyState
      dataHook={dataHooks.EMPTY_STATE}
      onCtaClick={emptyStateCtaClick}
      readonly={!!readOnlyMode.sidePanel?.packages}
      {...emptyStateText()}
    />
  );
};
