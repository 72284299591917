import './fileCodeEditorV2.global.scss';
import _ from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  utilsCreator as wixCodeUtilsCreator,
  fileSystem as fileSystemCommon,
  getMasterPageFileId,
} from '@wix/wix-code-common';
import codePropTypesCreator from '@/legacy/core/components/propTypes';
import wixCodeCodeEditorCreator from './wixCodeCodeEditor';
import { consts } from '@wix/wix-code-consts';
import devContextUtilsCreator from '@/utils/devContext';
import AdditionalSidePane from '../../additionalSidePane/additionalSidePane';
import once_ from 'lodash/once';
import { ReadOnlyModeContext } from '@wix/wix-code-common-components';
import { CodeEditorSidebar } from '../../codeEditorSidebar';

export default once_(
  ({
    baseUI,
    constants,
    core,
    experiment,
    platform,
    util,
    stateManagement,
  }) => {
    const {
      connect: editorConnect,
      STORES: { EDITOR_API: EDITOR_API_STORE },
    } = util.hoc;

    const WixCodeCodeEditor = wixCodeCodeEditorCreator({
      experiment,
      baseUI,
      core,
      util,
      stateManagement,
    });

    const CodePropTypes = codePropTypesCreator({ constants });
    const wixCodeUtils = wixCodeUtilsCreator({ experiment, platform, util });
    const { isPageContext, isMasterPageContext, isCssFileContext } =
      devContextUtilsCreator({
        constants,
      });

    class FileCodeEditor extends React.Component {
      static contextType = ReadOnlyModeContext;

      constructor(props) {
        super(props);
        this.getFileContext = this.getFileContext.bind(this);
        this.getFileId = this.getFileId.bind(this);
        this.getWixCodeCodeEditorProps =
          this.getWixCodeCodeEditorProps.bind(this);
      }

      getFileContext() {
        const contextId = _.get(this.props.context, 'data.id');
        const { dependencies } = this.props;
        if (
          this.props.context.type ===
          constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE
        ) {
          return {
            type: 'page',
            dependencies,
            ...this.props.codeIntelligence.getContentAssistForPage(
              this.props.focusedPageId,
            ),
          };
        }
        if (contextId === getMasterPageFileId()) {
          return {
            type: 'page',
            dependencies,
            ...this.props.codeIntelligence.getContentAssistForPage(
              consts.SITE_JS_PAGE_ID,
            ),
          };
        }
        if (contextId.startsWith('public/')) {
          return {
            type: 'public',
            dependencies,
          };
        }
        if (contextId.startsWith('backend/')) {
          return {
            type: 'backend',
            dependencies,
          };
        }
        if (fileSystemCommon.codePackages.isPackageFile(contextId)) {
          return {
            type: 'public',
            dependencies: [],
          };
        }
      }

      onEditorFocus() {
        util.keyboardShortcuts.disable();
      }

      onEditorBlur() {
        util.keyboardShortcuts.enable();
      }

      getFileId() {
        if (
          this.props.context.type ===
          constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE
        ) {
          return wixCodeUtils.getFileIdFromPageId(this.props.focusedPageId);
        }

        return this.props.context.data.id;
      }

      getWixCodeCodeEditorProps() {
        const fileId = this.getFileId();
        const { readOnlyMode } = this.context;
        const readOnly =
          readOnlyMode.codeIde ||
          fileSystemCommon.codePackages.isPackageFile(fileId);

        return {
          fileId,
          onEditorFocus: this.onEditorFocus,
          onEditorBlur: this.onEditorBlur,
          onSeeAllShortcuts: this.props.onSeeAllShortcuts,
          Footer: this.props.Footer,
          readOnly,
          getFileContext: this.getFileContext,
        };
      }

      render() {
        const { sidebarSlot } = this.props;
        const additionalSidePane = this.props.additionalSidePane;
        const displaySidePane =
          this.props.isPageContext ||
          this.props.isMasterPageContext ||
          this.props.isCssFileContext;

        const maybeAdditionalSidePane =
          additionalSidePane && additionalSidePane.isVisible ? (
            <AdditionalSidePane SidePane={additionalSidePane.SidePane} />
          ) : null;
        const maybePageElementsPanel = this.props.isPageElementsPanelEnabled ? (
          <CodeEditorSidebar sidebarSlot={sidebarSlot} />
        ) : null;

        return (
          <Fragment>
            <WixCodeCodeEditor {...this.getWixCodeCodeEditorProps()} />
            {displaySidePane &&
              (maybeAdditionalSidePane || maybePageElementsPanel)}
          </Fragment>
        );
      }
    }

    FileCodeEditor.displayName = 'FileCodeEditor';

    FileCodeEditor.propTypes = {
      context: CodePropTypes.context.isRequired,
      currentPageId: PropTypes.string.isRequired,
      focusIde: PropTypes.func.isRequired,
      onSeeAllShortcuts: PropTypes.func.isRequired,
      dependencies: PropTypes.object,
      additionalSidePane: PropTypes.shape({
        buttonIconName: PropTypes.string,
        buttonTooltipDataProps: PropTypes.shape({
          text: PropTypes.string.isRequired,
          link: PropTypes.string,
          linkAction: PropTypes.func,
          shouldTranslate: PropTypes.bool,
        }),
        isVisible: PropTypes.bool,
        onVisibilityChange: PropTypes.func,
        SidePane: PropTypes.elementType,
      }),
      codeIntelligence: PropTypes.shape({
        getContentAssistForPage: PropTypes.func,
      }),
    };

    const mapEditorApiToProps = ({ editorAPI }) => ({
      focusedPageId: editorAPI.pages.getFocusedPageId(),
      isPageElementsPanelEnabled: editorAPI.developerToolBar.isEnabled(),
      isPageContext: isPageContext(editorAPI),
      isMasterPageContext: isMasterPageContext(editorAPI),
      isCssFileContext: isCssFileContext(editorAPI),
      editorAPI,
    });

    const withEditorAPI = (component) => {
      const connected = editorConnect(
        EDITOR_API_STORE,
        mapEditorApiToProps,
      )(component);
      connected.pure = component; // for unit tests
      return connected;
    };

    return withEditorAPI(FileCodeEditor);
  },
);
