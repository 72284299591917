import './consoleMessage.global.scss';
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import wixCodeBI from '../../../../bi/bi';
import consoleConstants from '../constants';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import renderWhenMutatedByPropTypes from '@/utils/renderWhenMutatedByPropTypes';
import _ from 'lodash';

const propTypes = {
  message: PropTypes.object,
  sendBiEvent: PropTypes.func.isRequired,
  navigateToFile: PropTypes.func.isRequired,
  togglePreview: PropTypes.func.isRequired,
};

export default _.once(({ baseUI, util }) => {
  const { translate } = util;
  const { connect } = util.hoc;

  function getSectionNameByOriginType(originType) {
    switch (originType) {
      case consoleConstants.ORIGIN_TYPES.PAGE_CODE:
        return 'pages';
      case consoleConstants.ORIGIN_TYPES.SITE_CODE:
        return 'site';
      case consoleConstants.ORIGIN_TYPES.PUBLIC_FILE:
        return 'public';
      case consoleConstants.ORIGIN_TYPES.BACKEND_FILE:
        return 'backend';
      default:
        console.warn(
          `Unknown origin type in ConsoleMessageOrigin: ${originType}`,
        );
    }
  }

  const ConsoleMessageOrigin = createReactClass({
    displayName: 'WixCodeconsoleMessageOrigin',

    propTypes,

    navigateToOrigin() {
      const { message, sendBiEvent, navigateToFile, togglePreview } =
        this.props;
      const { origin, logLevel } = message;
      const { type, sourceFileFullPath, line, column } = origin;

      sendBiEvent(wixCodeBI.events.LOG_CONSOLE_DEEP_LINK_CLICK, {
        section_name: getSectionNameByOriginType(type),
        severity_name: logLevel.toLowerCase(),
      });

      function afterPreviewCb() {
        navigateToFile(sourceFileFullPath, { position: { line, column } });
      }

      togglePreview(afterPreviewCb);
    },

    render() {
      const { origin } = this.props.message;

      return (
        <div className="origin" key="origin">
          <div className="origin-icon">
            {origin.type === consoleConstants.ORIGIN_TYPES.PUBLIC_FILE && (
              <baseUI.tooltip
                key="publicFileIcon"
                value={translate(
                  'WixCode_Console_Logs_FileOrigin_Tooltip_Public',
                )}
                styleType="small"
                data-aid="originIconTooltip"
              >
                <Symbol name="code-console-public-file-icon" />
              </baseUI.tooltip>
            )}
            {origin.type === consoleConstants.ORIGIN_TYPES.BACKEND_FILE && (
              <baseUI.tooltip
                key="backendFileIcon"
                value={translate(
                  'WixCode_Console_Logs_FileOrigin_Tooltip_Backend',
                )}
                styleType="small"
                data-aid="originIconTooltip"
              >
                <Symbol name="code-console-backend-file-icon" />
              </baseUI.tooltip>
            )}
          </div>
          <div className="file">{origin.sourceFileDisplayName}</div>
          <div className="line">
            <baseUI.tooltip
              key="deepLinkTooltip"
              value={translate('WixCode_Console_Logs_DeepLink_Tooltip_Text')}
              styleType="small"
              alignment="left"
              data-aid="deepLinkTooltip"
            >
              <div onClick={this.navigateToOrigin} className="clickable">
                Line {origin.line}
              </div>
            </baseUI.tooltip>
          </div>
        </div>
      );
    },
  });

  const navigateToFile =
    (...args) =>
    (dispatch, getState, { editorAPI }) =>
      editorAPI.wixCode.fileTree.navigateToFile(editorAPI, ...args);
  const togglePreview =
    (...args) =>
    (dispatch, getState, { editorAPI }) =>
      editorAPI.preview.togglePreview(...args);

  const biEvent =
    (eventType, params) =>
    (dispatch, getState, { editorAPI }) => {
      editorAPI.bi.event(eventType, params);
    };

  const mapDispatchToProps = (dispatch) => ({
    sendBiEvent(eventType, params) {
      dispatch(biEvent(eventType, params));
    },
    navigateToFile(...args) {
      return dispatch(navigateToFile(...args));
    },
    togglePreview(...args) {
      dispatch(togglePreview(...args));
    },
  });

  const Connected = connect(
    ['editorAPI'],
    undefined,
    mapDispatchToProps,
  )(renderWhenMutatedByPropTypes(ConsoleMessageOrigin, _.keys(propTypes)));
  Connected.Pure = ConsoleMessageOrigin;

  return Connected;
});
