/* eslint react/prop-types:2 */
import _ from 'lodash';
import { utilsCreator as wixCodeUtilsCreator } from '@wix/wix-code-common';
import bi from '../../../../bi/bi';

import once_ from 'lodash/once';

export default once_(
  ({ constants, experiment, platform, stateManagement, util }) => {
    const wixCodeUtils = wixCodeUtilsCreator({ experiment, platform, util });
    const { findCurrentSlide } =
      stateManagement.editorPlugins.boxSlideShow.selectors;

    const VALIDATION_MSGS = {
      ALREADY_EXISTS: 'WixCode_PropertiesPanel_ID_Validation_NotUnique',
      TOO_SHORT: 'WixCode_PropertiesPanel_ID_Validation_Empty',
      TOO_LONG: 'WixCode_PropertiesPanel_ID_Validation_TooLong',
      INVALID_NAME: 'WixCode_PropertiesPanel_ID_Validation_InvalidName',
    };

    const getEventList = (editorAPI, editedComponent) => {
      if (!editedComponent) {
        return null;
      }
      const sdkOrControllerType = wixCodeUtils.getSdkTypeOrControllerType(
        editorAPI,
        editedComponent,
      );
      return editorAPI.wixCode.events.getEvents(sdkOrControllerType);
    };

    const getInnerComponent = (editorAPI, compRef) => {
      if (!compRef) {
        return null;
      }
      if (editorAPI.components.is.exposesSlideAsInnerComponent(compRef)) {
        return findCurrentSlide(editorAPI.dsRead, compRef);
      }

      return null;
    };

    const mapStateToProps = ({ editorAPI, state }, ownProps) => {
      const { editedComponent } = ownProps || {};
      const innerComponent = getInnerComponent(editorAPI, editedComponent);
      const { openDockedSections } =
        stateManagement.editorPlugins.sectionedPanel.selectors;
      const developerToolBarSelector =
        stateManagement.editorPlugins.developerToolBar.selectors;
      const componentProperties =
        editorAPI.components.properties.get(editedComponent);
      const isComponentVisible = !_.get(componentProperties, 'isHidden');
      const isComponentHiddenable = editedComponent
        ? editorAPI.components.is.hiddenable(editedComponent)
        : false;
      const isComponentCollapsible = editedComponent
        ? editorAPI.components.is.collapsible(editedComponent)
        : false;
      const isComponentCollapsed = Boolean(
        _.get(componentProperties, 'isCollapsed'),
      );
      const isComponentEnabled = !_.get(componentProperties, 'isDisabled');
      const isComponentDisableable = editedComponent
        ? editorAPI.components.is.disableable(editedComponent)
        : false;
      const savedBehaviors = editedComponent
        ? editorAPI.behaviors.get(editedComponent)
        : null;
      const eventList = getEventList(editorAPI, editedComponent);
      const showOnlySavedEvents =
        developerToolBarSelector.showOnlySavedEvents(state);
      return {
        editedComponent,
        innerComponent,
        componentName: editorAPI.components.code.getNickname(editedComponent),
        innerComponentName:
          innerComponent &&
          editorAPI.components.code.getNickname(innerComponent),
        eventList,
        isComponentCollapsed,
        isComponentCollapsible,
        isComponentDisableable,
        isComponentEnabled,
        isComponentHiddenable,
        isComponentVisible,
        showOnlySavedEvents,
        isGroup: editorAPI.components.is.group(editedComponent),
        isReadOnly: false,
        isReadOnlyEvents: developerToolBarSelector.isReadOnlyEvents(state),
        disableLinkToCode: developerToolBarSelector.disableLinkToCode(state),
        shouldShowEvents: developerToolBarSelector.shouldShowEvents(state),
        savedBehaviors,
        wixCodeLoaded: state.wixCodeLoaded,
        isSectionProperties: _.includes(
          openDockedSections(state),
          constants.DOCKED_PANEL_SECTIONS.PROPERTIES,
        ),
      };
    };

    const validateName =
      (value, editedComponent, getter) =>
      (dispatch, getState, { editorAPI }) => {
        editedComponent = getter
          ? getter(editorAPI, editedComponent)
          : editedComponent;
        const codeAPI = editorAPI.components.code;
        return (
          codeAPI.validateNickname(editedComponent, value) ===
          codeAPI.VALIDATIONS.VALID
        );
      };

    const getNameInvalidMessage =
      (invalidName, editedComponent, getter) =>
      (dispatch, getState, { editorAPI }) => {
        editedComponent = getter
          ? getter(editorAPI, editedComponent)
          : editedComponent;
        const codeAPI = editorAPI.components.code;
        const validationResult = codeAPI.validateNickname(
          editedComponent,
          invalidName,
        );
        if (validationResult === codeAPI.VALIDATIONS.VALID) {
          return '';
        }

        return util.translate(VALIDATION_MSGS[validationResult]);
      };

    const onNameChange =
      (value, editedComponent, getter) =>
      (dispatch, getState, { editorAPI }) => {
        editedComponent = getter
          ? getter(editorAPI, editedComponent)
          : editedComponent;
        editorAPI.components.code.setNickname(editedComponent, value);
      };

    const onNameFocus =
      (editedComponent, getter) =>
      (dispatch, getState, { editorAPI, dsRead }) => {
        editedComponent = getter
          ? getter(editorAPI, editedComponent)
          : editedComponent;
        editorAPI.bi.event(bi.events.PROPERTIES_PANEL_NICKNAME_FOCUSED, {
          site_id: dsRead.generalInfo.getSiteId(),
          element_type: wixCodeUtils.getCompTypeOrControllerType(
            editorAPI,
            editedComponent,
          ),
          element_id: editedComponent.id,
        });
      };

    const toggleComponentCollapsiblity =
      (editedComponent) =>
      (dispatch, getState, { editorAPI, dsRead }) => {
        const componentProperties =
          editorAPI.components.properties.get(editedComponent);
        const isComponentCollapsible = editedComponent
          ? editorAPI.components.is.collapsible(editedComponent)
          : false;
        const isComponentCollapsed = Boolean(
          _.get(componentProperties, 'isCollapsed'),
        );

        if (isComponentCollapsible) {
          const willBeCollapsed = !isComponentCollapsed;

          editorAPI.components.properties.update(editedComponent, {
            isCollapsed: willBeCollapsed,
          });

          editorAPI.bi.event(bi.events.PROPERTIES_PANEL_COLLAPSIBLITY_TOGGLE, {
            site_id: dsRead.generalInfo.getSiteId(),
            element_type: wixCodeUtils.getCompTypeOrControllerType(
              editorAPI,
              editedComponent,
            ),
            is_collapsed: willBeCollapsed,
          });
        }
      };

    const toggleComponentEnability =
      (editedComponent) =>
      (dispatch, getState, { editorAPI, dsRead }) => {
        const componentProperties =
          editorAPI.components.properties.get(editedComponent);
        const isComponentEnabled = !_.get(componentProperties, 'isDisabled');
        const isComponentDisableable = editedComponent
          ? editorAPI.components.is.disableable(editedComponent)
          : false;

        if (isComponentDisableable) {
          const willBeDisabled = isComponentEnabled;

          editorAPI.components.properties.update(editedComponent, {
            isDisabled: willBeDisabled,
          });

          editorAPI.bi.event(bi.events.PROPERTIES_PANEL_ENABILITY_TOGGLE, {
            site_id: dsRead.generalInfo.getSiteId(),
            element_type: wixCodeUtils.getCompTypeOrControllerType(
              editorAPI,
              editedComponent,
            ),
            state: willBeDisabled ? 'disable' : 'enable',
          });
        }
      };

    const toggleComponentVisibility =
      (editedComponent) =>
      (dispatch, getState, { editorAPI, dsRead }) => {
        const componentProperties =
          editorAPI.components.properties.get(editedComponent);
        const isComponentHiddenable = editedComponent
          ? editorAPI.components.is.hiddenable(editedComponent)
          : false;

        if (isComponentHiddenable) {
          const willBeHidden = !_.get(componentProperties, 'isHidden');

          editorAPI.components.properties.update(editedComponent, {
            isHidden: willBeHidden,
          });
          editorAPI.bi.event(bi.events.PROPERTIES_PANEL_VISIBILITY_TOGGLE, {
            site_id: dsRead.generalInfo.getSiteId(),
            element_type: wixCodeUtils.getCompTypeOrControllerType(
              editorAPI,
              editedComponent,
            ),
            is_visible: !willBeHidden,
          });
        }
      };

    const mapDispatchToProps = (dispatch, { editedComponent }) => ({
      validateName(value) {
        return dispatch(validateName(value, editedComponent));
      },
      getNameInvalidMessage(invalidName) {
        return dispatch(getNameInvalidMessage(invalidName, editedComponent));
      },
      onNameChange(value) {
        return dispatch(onNameChange(value, editedComponent));
      },
      onNameFocus() {
        return dispatch(onNameFocus(editedComponent));
      },
      validateInnerName(value) {
        return dispatch(
          validateName(value, editedComponent, getInnerComponent),
        );
      },
      getInnerNameInvalidMessage(invalidName) {
        return dispatch(
          getNameInvalidMessage(
            invalidName,
            editedComponent,
            getInnerComponent,
          ),
        );
      },
      onInnerNameChange(value) {
        return dispatch(
          onNameChange(value, editedComponent, getInnerComponent),
        );
      },
      onInnerNameFocus() {
        return dispatch(onNameFocus(editedComponent, getInnerComponent));
      },
      toggleComponentCollapsiblity() {
        return dispatch(toggleComponentCollapsiblity(editedComponent));
      },
      toggleComponentEnability() {
        return dispatch(toggleComponentEnability(editedComponent));
      },
      toggleComponentVisibility() {
        return dispatch(toggleComponentVisibility(editedComponent));
      },
    });

    return {
      mapStateToProps,
      mapDispatchToProps,
    };
  },
);
