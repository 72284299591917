import React from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import style from './CommitsSection.scss';
import { Spacer, TextButton, Divider, TextLabel } from '@wix/wix-base-ui';
import { GitHubCommit } from '../../../../types/GithubTypes';
import { useBiLogger } from '../../../../context/biLoggerContext';
import { GITHUB_TAB_ACTIONS } from '../../../../utils/biConsts';
import { githubLeftTreeTabAction } from '../../../../utils/biEvents';

const Commit: React.FC<{ commit: GitHubCommit }> = ({ commit }) => {
  const [t] = useTranslation();
  const bi = useBiLogger();
  const { author, createdDate, commitHash } = formatCommit(commit, t);

  return (
    <div className={style.commitContainer}>
      <Spacer type="Spacer04" />
      <TextLabel
        type="T19"
        enableEllipsis={true}
        value={commit.message}
        shouldTranslate={false}
      />
      <Spacer type="Spacer02" />
      <TextLabel
        className={style.commitTextsContainer}
        type="T20"
        shouldTranslate={false}
        value={
          t('githubIntegration.github_connected.commits_section.commit_owner') +
          ' ' +
          author
        }
      />
      <TextLabel
        className={style.commitTextsContainer}
        type="T20"
        shouldTranslate={false}
        value={createdDate}
      />
      <Spacer type="Spacer01" />
      <div className={style.linkTextsWrapper}>
        <TextButton
          size="small"
          weight="thin"
          shouldTranslate={false}
          onClick={() => {
            bi.report(
              githubLeftTreeTabAction({
                itemName: GITHUB_TAB_ACTIONS.COMMIT_ID,
              }),
            );
            return window.open(commit.htmlUrl, '_blank');
          }}
        >
          {commitHash}
        </TextButton>
      </div>
      <Spacer type="Spacer04" />
      <Divider long />
      <Spacer type="Spacer04" />
    </div>
  );
};

const dateFormat = new Intl.DateTimeFormat('en', {
  dateStyle: 'short',
  timeStyle: 'medium',
});

const formatCommit = (commit: GitHubCommit, t: Function) => {
  const author =
    commit.author?.userLogin?.replace('velo-app[bot]', 'velo-app') ||
    commit.author?.userName ||
    t('githubIntegration.github_connected.commits_section.unknown_author');
  const createdDate = dateFormat.format(new Date(commit.createdDate));
  const commitHash = commit.sha.substring(0, 7);
  return { author, createdDate, commitHash };
};

export const CommitsSection: React.FC<{
  commitHistoryUrl?: string;
  commits: GitHubCommit[];
}> = ({ commitHistoryUrl, commits = [] }) => {
  const [t] = useTranslation();
  const bi = useBiLogger();

  return (
    <div>
      <ul>
        {commits.map((commit) => (
          <Commit key={commit.sha} commit={commit} />
        ))}
      </ul>
      <div className={style.linkTextsWrapper}>
        <TextButton
          weight="thin"
          className={style.commitsHistoryLink}
          shouldTranslate={false}
          onClick={() => {
            bi.report(
              githubLeftTreeTabAction({
                itemName: GITHUB_TAB_ACTIONS.VIEW_COMMIT_HISTORY,
              }),
            );
            return window.open(commitHistoryUrl, '_blank');
          }}
        >
          {t(
            'githubIntegration.github_connected.commits_section.commits_history_link',
          )}
        </TextButton>
      </div>
    </div>
  );
};

export default CommitsSection;
