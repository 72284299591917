import { PagesTreeReducerAction, PagesTreeState } from '.';
import { ActionType } from './actionTypes';

const initialState: PagesTreeState = {
  contributedSections: [],
};

export const pagesTreeReducer = (
  state = initialState,
  action: PagesTreeReducerAction,
): PagesTreeState => {
  switch (action.type) {
    case ActionType.CONTRIBUTE_SECTION:
      return {
        ...state,
        contributedSections: [...state.contributedSections, action.section],
      };
    default:
      return state;
  }
};
