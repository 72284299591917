import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import userPreferenceUtilCreator from '@/utils/userPreference';

const IDEShortcutsDismissedKey = 'wixCode.ideShortcutsPopover.dismiss';
const MultiTabsDismissedKey = 'wixCode.multiTabsPopover.dismiss';

export interface PopoversApi {
  isIdeShortcutsPopoverDismissed: (editorAPI: EditorAPI) => boolean;
  setIdeShortcutsPopoverDismissed: (editorAPI: EditorAPI) => void;
  isMultiTabsPopoverDismissed: (editorAPI: EditorAPI) => boolean;
  setMultiTabsPopoverDismissed: (editorAPI: EditorAPI) => void;
}

export const popoversApiCreator: ({ stateManagement }: any) => PopoversApi = ({
  stateManagement,
}) => {
  const { setGlobalPreference, getGlobalPreference, getSitePreference } =
    userPreferenceUtilCreator({
      stateManagement,
    });

  const setIdeShortcutsPopoverDismissed = (editorAPI: EditorAPI) =>
    setGlobalPreference(editorAPI, IDEShortcutsDismissedKey, true);

  const isIdeShortcutsPopoverDismissed = (editorAPI: EditorAPI) =>
    !!getSitePreference(editorAPI, IDEShortcutsDismissedKey) ||
    !!getGlobalPreference(editorAPI, IDEShortcutsDismissedKey);

  const setMultiTabsPopoverDismissed = (editorAPI: EditorAPI) =>
    setGlobalPreference(editorAPI, MultiTabsDismissedKey, true);

  const isMultiTabsPopoverDismissed = (editorAPI: EditorAPI) =>
    !!getSitePreference(editorAPI, MultiTabsDismissedKey) ||
    !!getGlobalPreference(editorAPI, MultiTabsDismissedKey);

  return {
    isIdeShortcutsPopoverDismissed,
    setIdeShortcutsPopoverDismissed,
    isMultiTabsPopoverDismissed,
    setMultiTabsPopoverDismissed,
  };
};
