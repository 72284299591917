// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".y_f0gu{color:#000624;padding-bottom:10px;display:flex;justify-content:space-between;align-items:center}.ZzN3ue{float:right}.ZzN3ue .preloader-button{width:75px}.z3HJKs{margin-left:15px}.uhCCSf{display:flex;flex-direction:column;padding:10px 0px;align-items:flex-start}.tn5iVi{margin-right:5px}.mapcvx{margin-top:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "y_f0gu",
	"action": "ZzN3ue",
	"version": "z3HJKs",
	"package-info-container": "uhCCSf",
	"packageInfoContainer": "uhCCSf",
	"package-info-bold": "tn5iVi",
	"packageInfoBold": "tn5iVi",
	"package-info-line": "mapcvx",
	"packageInfoLine": "mapcvx"
};
export default ___CSS_LOADER_EXPORT___;
