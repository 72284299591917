import {
  Tour,
  TourContribution,
  WixCodeEditorAdapterAPI,
} from '@wix/wix-code-editor-adapter';
import { ClassicEditorAPI } from '@wix/wix-code-plugin-contracts';
import dataHooks from '../components/dataHooks';

const contributeSyncLocallyTour = (
  translate: (key: string) => string,
  wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI,
  classicEditorAPI: ClassicEditorAPI,
) => {
  const createSyncLocallyButtonTour = (): TourContribution => {
    const t = translate;
    const tour: Tour = {
      tourKeyName: 'syncLocallyTour',
      actions: [
        {
          title: t('localEditor.sync_locally_tour.title'),
          content: t('localEditor.sync_locally_tour.content'),
          primaryButton: {
            label: t('localEditor.sync_locally_tour.primary_button_label'),
          },
          selectorToAttach: `[data-hook='${dataHooks.syncLocallyButton.popover}']`,
        },
      ],
    };
    return { tour };
  };
  const syncLocallyTour = createSyncLocallyButtonTour();

  const openSyncTour = wixCodeEditorAdapterAPI.contributeTour(syncLocallyTour);
  const triggeringEvents = [
    'transaction',
    'pages.add',
    'pages.remove',
    'pages.duplicate',
    'pages.rename',
    'components.add',
    'components.remove',
    'components.duplicate',
  ];

  const shouldTriggerTour = (events: string[]) => {
    const isTransactionEvent = events.some((event) =>
      triggeringEvents.includes(event),
    );
    return isTransactionEvent && !wixCodeEditorAdapterAPI.isAnyTourRunning();
  };

  classicEditorAPI.editorAPI.registerToSiteChanged(
    (events: string[] | undefined) => {
      if (events && shouldTriggerTour(events)) {
        openSyncTour({ showOnlyOnce: true });
      }
    },
  );
};

export default contributeSyncLocallyTour;
