import { ActionType } from '../actions/types';
import {
  AddFileListener as AddFileListenerInternal,
  RemoveFileListener as RemoveFileListenerInternal,
  FilesListenersState as FilesListenersStateInternal,
  FilesListenersReducerAction as FilesListenersReducerActionInternal,
} from '@wix/wix-code-plugin-contracts';

export type AddFileListener = AddFileListenerInternal;
export type RemoveFileListener = RemoveFileListenerInternal;
export type FilesListenersState = FilesListenersStateInternal;
export type FilesListenersReducerAction = FilesListenersReducerActionInternal;

const initialState: FilesListenersState = {};

export const filesListenersReducer = (
  state = initialState,
  action: FilesListenersReducerAction,
) => {
  const { [action.fileId]: fileListeners, ...rest } = state;

  switch (action.type) {
    case ActionType.ADD_FILE_LISTENER:
      return {
        ...rest,
        [action.fileId]: [...(fileListeners || []), action.listener],
      };
    case ActionType.REMOVE_FILE_LISTENER:
      return {
        ...rest,
        [action.fileId]: [
          ...(fileListeners || []).filter(
            (listener) => listener !== action.listener,
          ),
        ],
      };
    default:
      return state;
  }
};

export default filesListenersReducer;
