import React from 'react';
import { Spacer, Heading } from '@wix/wix-base-ui';
import RepositoryInfo from './RepositoryInfo/RepositoryInfo';
import RepositoryDescription from './RepositoryDescription/RepositoryDescription';
import dataHooks from '../../dataHooks';
import CenterLoaderSpinner from '../CenteralLoaderSpinner/CenteralLoaderSpinner';
import { GithubUser } from '../../../types/GithubTypes';
import styles from './CreateRepositoryFlow.scss';

const CreateRepositoryFlow: React.FC<{
  gitHubUserInfo: GithubUser;
  translate: (key: string) => string;
  onAccountChange: (value: any) => void;
  onRepoNameChange: (value: string, isValid: boolean) => void;
  onDescriptionChange: (value: string, isValid: boolean) => void;
  userAccount: string;
  repoName: string;
  siteName: string;
  doesRepoNameExist: boolean;
  showCentralLoaderSpinner: boolean;
  repoDescription: string;
}> = ({
  gitHubUserInfo,
  translate: t,
  onAccountChange,
  onRepoNameChange,
  onDescriptionChange,
  userAccount,
  repoName,
  siteName,
  doesRepoNameExist,
  showCentralLoaderSpinner,
  repoDescription,
}) => {
  return (
    <div
      className={styles.container}
      data-hook={dataHooks.connectModal.createRepository.container}
    >
      <Heading
        appearance="h3"
        dataHook={dataHooks.connectModal.createRepository.titles.mainTitle}
      >
        {t('githubIntegration.connect_modal.create_repository.description')}
      </Heading>
      <Spacer type="Spacer04"></Spacer>
      {showCentralLoaderSpinner ? (
        <CenterLoaderSpinner
          texts={[
            t('githubIntegration.connect_modal.create_repository.loader.text'),
          ]}
        />
      ) : (
        <div data-hook={dataHooks.connectModal.createRepository.repositoryInfo}>
          <RepositoryInfo
            translate={t}
            onAccountChange={onAccountChange}
            onRepoNameChange={onRepoNameChange}
            gitHubUserInfo={gitHubUserInfo}
            userAccount={userAccount}
            repoName={repoName}
            doesRepoNameExist={doesRepoNameExist}
          />
          <Spacer type="Spacer06" />
          <RepositoryDescription
            translate={t}
            onChange={onDescriptionChange}
            siteName={siteName}
            repoDescription={repoDescription}
          />
        </div>
      )}
    </div>
  );
};

export default CreateRepositoryFlow;
