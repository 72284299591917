// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sisQDL{display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%;height:calc(100% - 56px)}.sisQDL .nWq1G5{margin-bottom:24px}.sisQDL .DIdyAm{margin-bottom:8px}.sisQDL .n_yIC1{margin:24px 0}.sisQDL .NgJO2o{margin-bottom:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "sisQDL",
	"illustration": "nWq1G5",
	"title": "DIdyAm",
	"description-list": "n_yIC1",
	"descriptionList": "n_yIC1",
	"cta": "NgJO2o"
};
export default ___CSS_LOADER_EXPORT___;
