import React from 'react';
import { Button } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import Illustration from '../../../assets/images/PanelEmptyStateIllustration.svg';
import { EmptyStateBase } from '../EmptyStateBase/EmptyStateBase';
import classnames from 'classnames';
import styles from './NoCssFileState.scss';
import { ReadOnlyModeContext } from '@wix/wix-code-common-components';
import dataHooks from '../../dataHooks';
import { CssEditingInternalAPI } from '../../../cssEditingInternalAPI';
import { ideToolbarAction } from '@wix/bi-logger-platform/v2';
import { useBiLogger } from '../../../biLoggerContext';

interface NoCssFileStateProps {
  onAddGlobalCss: CssEditingInternalAPI['createCssFileAndOpen'];
}
export const NoCssFileState = ({ onAddGlobalCss }: NoCssFileStateProps) => {
  const [t] = useTranslation();
  const biLogger = useBiLogger();
  const { readOnlyMode } = React.useContext(ReadOnlyModeContext);

  return (
    <EmptyStateBase
      illustration={<Illustration />}
      text={t('WixCode_CssClassesPanel_AddGlobalCssFile_Description')}
      cta={
        <Button
          dataHook={dataHooks.cssClassesEmptyState.noCssFileCta}
          disabled={readOnlyMode.cssEditing.addGlobalCss}
          className={classnames('btn-sm', styles.addCssButton)}
          onClick={() => {
            onAddGlobalCss(
              `${t(
                'WixCode_CodePanel_Editor_GlobalCss_DefaultText_CssReference',
              )} ${t(
                'WixCode_CodePanel_Editor_GlobalCss_DefaultText_CssIntro',
              )}`,
            );
            biLogger.report(
              ideToolbarAction({
                item_name: 'css_add_global_file',
                action: 'click',
              }),
            );
          }}
        >
          {t('WixCode_CssClassesPanel_AddGlobalCssFile_AddFileCTA_Label')}
        </Button>
      }
    />
  );
};
