// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".U2E0oS{margin-top:36px}.iLK0uY{height:100%;overflow:hidden}.ykMIp6{display:flex;height:522px}.iWqQF9{flex-grow:1;height:100%}.CtAcsH{height:calc(100% - 54px);box-sizing:border-box}.UxZpgP{height:100%;overflow:auto}.QEk5EW{height:460px}.mAQKW6{padding-top:78px}.QJDHxb{width:100%;height:100%}.zO1_iO{overflow-y:auto;overflow-x:hidden;height:calc(100% - 110px)}.z2p8Kf{overflow-y:auto;overflow-x:hidden;height:calc(100% - 68px)}.z2p8Kf mark{background-color:#d6e6fe}.Fua7Dh{height:54px;padding:14px 21px 18px 21px;box-sizing:border-box}.ALzQkv{text-align:center;text-overflow:ellipsis}.AwoL_T{height:75px;box-sizing:border-box;padding-left:21px;padding-right:21px;padding-top:19px;padding-bottom:0px;margin-bottom:0px;display:flex;flex-direction:column;align-items:baseline}.AwoL_T>a{margin-top:2px}.ZErzyt{padding:15px 19px}.xk2FWU{position:absolute;bottom:0;right:0;width:100%}.MepI49{display:flex;flex-direction:column;justify-content:center;align-items:center;height:100%;gap:10px;margin:-1px}.MepI49 *{text-align:center}.GrcoZn{padding:10px 13px 10px 20px;width:auto;color:#868aa5;border-bottom:1px solid #dfe5eb}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptySearchPanelIllustration": "U2E0oS",
	"panelWrapper": "iLK0uY",
	"panelContentWrapper": "ykMIp6",
	"tabWrapper": "iWqQF9",
	"tabContent": "CtAcsH",
	"installedTab": "UxZpgP",
	"installedTabMainContent": "QEk5EW",
	"installedTabEmptyStateContainer": "mAQKW6",
	"availableTab": "QJDHxb",
	"availableModulesList": "zO1_iO",
	"availableModulesListNpmSearch": "z2p8Kf",
	"licenseStatement": "Fua7Dh",
	"moduleNotFound": "ALzQkv",
	"cantFindModule": "AwoL_T",
	"searchInput": "ZErzyt",
	"panelFooter": "xk2FWU",
	"noResultWrapper": "MepI49",
	"section-sub-title": "GrcoZn",
	"sectionSubTitle": "GrcoZn"
};
export default ___CSS_LOADER_EXPORT___;
