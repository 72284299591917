// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bsFTIt{min-width:515px}.bsFTIt .GVscA1{padding:5px 10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "bsFTIt",
	"item-list": "GVscA1",
	"itemList": "GVscA1"
};
export default ___CSS_LOADER_EXPORT___;
