import { useState } from 'react';

export const useConnectorDetails = () => {
  const [namespace, setNamespace] = useState('');
  const [endpoint, setEndpoint] = useState('');
  const [configuration, setConfiguration] = useState('');

  return {
    namespace,
    setNamespace,
    endpoint,
    setEndpoint,
    configuration,
    setConfiguration,
  };
};
