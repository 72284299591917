import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import { componentsDataService } from '@wix/wix-code-properties-panel';

export const createCodeIntelligenceService = ({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) => {
  editorAPI.dsActions.platform.registerToManifestAdded((manifest) => {
    if (!manifest?.exports) {
      return;
    }
    componentsDataService.registerEventHandlersFromManifests(manifest.exports);
  });

  return {
    getContentAssistForPage: (pageId: string) => {
      const pagesNicknamesMaps =
        editorAPI.wixCode.codeIntelligence.getNicknameToTypeMap([pageId]);
      return {
        elementsMap: pagesNicknamesMaps[pageId],
      };
    },
  };
};
