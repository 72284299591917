import React from 'react';
import {
  TextButton,
  Spacer,
  ContextMenu,
  ContextMenuAction,
  TreeListItem,
  TextLabel,
} from '@wix/wix-base-ui';
import Repository from '../../../../assets/Repository.svg';
import Branch from '../../../../assets/Branch.svg';
import Terminal from '../../../../assets/Terminal.svg';
import Disconnect from '../../../../assets/Disconnect.svg';
import OpenExternal from '../../../../assets/OpenExternal.svg';
import { useTranslation } from '@wix/wix-i18n-config';
import { GithubRepository } from '../../../../types/GithubTypes';
import style from './OverviewSection.scss';
import { GithubModalsAPI } from '../../../../githubModalsAPI';
import ContextMenuSmall from 'wix-ui-icons-common/classic-editor/ContextMenuSmall';
import dataHooks from '../../../dataHooks';
import { useBiLogger } from '../../../../context/biLoggerContext';
import {
  GITHUB_OVERVIEW_CONTEXTMENU,
  GITHUB_TAB_ACTIONS,
} from '../../../../utils/biConsts';
import {
  githubLeftTreeTabAction,
  githubTabContextMenuActions,
} from '../../../../utils/biEvents';

type IconComponent = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
type ContextMenuItem = {
  onClick: () => void;
  icon: IconComponent;
  text: string;
  dataHook: string;
};

export const OverviewSection: React.FC<{
  githubRepository?: GithubRepository;
  githubModalsAPI: GithubModalsAPI;
}> = ({ githubRepository, githubModalsAPI }) => {
  const [t] = useTranslation();
  const bi = useBiLogger();

  const RepositoryContextMenu = () => {
    const contextMenuItems: ContextMenuItem[] = [
      {
        onClick: () => {
          bi.report(
            githubTabContextMenuActions({
              menuEntryPoint: GITHUB_OVERVIEW_CONTEXTMENU.VIEW,
            }),
          );
          return window.open(githubRepository?.htmlUrl, '_blank');
        },
        icon: OpenExternal,
        text: 'githubIntegration.github_connected.overview_section.context_menu.view_repository',
        dataHook: dataHooks.connectedGithubTab.menuItemsActions.viewRepository,
      },
      {
        onClick: () => {
          bi.report(
            githubTabContextMenuActions({
              menuEntryPoint: GITHUB_OVERVIEW_CONTEXTMENU.CLONE,
            }),
          );
          return githubModalsAPI.showInstructionsModal();
        },
        icon: Terminal,
        text: 'githubIntegration.github_connected.overview_section.context_menu.clone',
        dataHook: dataHooks.connectedGithubTab.menuItemsActions.cloneRepository,
      },
      {
        onClick: () => {
          bi.report(
            githubTabContextMenuActions({
              menuEntryPoint: GITHUB_OVERVIEW_CONTEXTMENU.DISCONNECT,
            }),
          );
          return githubModalsAPI.openDisconnectModal();
        },
        icon: Disconnect,
        text: 'githubIntegration.github_connected.overview_section.context_menu.disconnect',
        dataHook: dataHooks.connectedGithubTab.menuItemsActions.disconnect,
      },
    ];

    return (
      <ContextMenu
        dataHook={dataHooks.connectedGithubTab.menuItems}
        customButton={<ContextMenuSmall />}
      >
        {contextMenuItems.map((menuItem) => (
          <ContextMenuAction
            dataHook={menuItem.dataHook}
            onClick={menuItem.onClick}
          >
            <menuItem.icon className={style.contextMenuIcon} />
            {t(menuItem.text)}
          </ContextMenuAction>
        ))}
      </ContextMenu>
    );
  };

  return (
    <div className={style.container}>
      <div className={style.overviewContainer}>
        <TextLabel
          type="T19"
          enableEllipsis={false}
          shouldTranslate={false}
          value={t(
            'githubIntegration.github_connected.overview_section.repository_title',
          )}
        />
      </div>
      <Spacer type="Spacer01" />
      <TreeListItem
        onClick={() => {
          bi.report(
            githubLeftTreeTabAction({ itemName: GITHUB_TAB_ACTIONS.OVERVIEW }),
          );
        }} // base-ui bug -  expect an onClick function on TreeListItem when we click on the suffix ContextMenu
        label={githubRepository?.repositoryFullName}
        shouldTranslate={false}
        icon={<Repository />}
        size="medium"
        suffix={<RepositoryContextMenu />}
      />
      <Spacer type="Spacer05" />
      <div className={style.overviewContainer}>
        <TextLabel
          type="T19"
          className={style.item}
          shouldTranslate={false}
          value={t(
            'githubIntegration.github_connected.overview_section.branch_title',
          )}
        />
        <Spacer type="Spacer03" />
        <div className={style.branchContainer}>
          <Branch />
          <TextLabel
            type="T20"
            shouldTranslate={false}
            value={
              t(
                'githubIntegration.github_connected.overview_section.branch_prefix',
              ) + githubRepository?.defaultBranch
            }
          />
        </div>
        <Spacer type="Spacer03" />
        <div className={style.textWrapper}>
          <TextLabel
            className={style.overviewTextsContainer}
            shouldTranslate={false}
            type="T20"
            value={
              t(
                'githubIntegration.github_connected.overview_section.description',
                { defaultBranch: githubRepository?.defaultBranch },
              ) + ' '
            }
            enableEllipsis={false}
          />
          <TextButton
            className={style.learnMoreLink}
            underline="none"
            size="small"
            skin="dark"
            shouldTranslate={false}
            onClick={() => {
              bi.report(
                githubLeftTreeTabAction({
                  itemName: GITHUB_TAB_ACTIONS.LEARN_MORE,
                }),
              );
              window.open(
                t(
                  'githubIntegration.github_connected.overview_section.learn_more_link',
                ),
                '_blank',
              );
            }}
          >
            {t(
              'githubIntegration.github_connected.overview_section.learn_more',
            )}
          </TextButton>
        </div>
      </div>
    </div>
  );
};

export default OverviewSection;
