import React from 'react';
import { CodeReusePkgVersion } from '../../../codeReuseServerAPI';
import s from './PackageMarkDownHeader.scss';
import { PackageMarkdownHeaderFirstRow } from './PackageMarkdownHeaderFirstRow';
import { PackageMarkdownPkgDetails } from './PackageMarkdownHeaderPkgDetails';

interface PackageMarkDownHeaderV2Props {
  allVersions: CodeReusePkgVersion[];
}

export const PackageMarkDownHeaderV2: React.FC<
  PackageMarkDownHeaderV2Props
> = ({ allVersions }) => (
  <div className={s.header}>
    <PackageMarkdownHeaderFirstRow allVersions={allVersions} />
    <PackageMarkdownPkgDetails />
  </div>
);
