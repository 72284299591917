import React from 'react';

interface PageTreeContextProps {
  selectedIdeTabId: string;
  selectedPageId: string;
  onPageSelected: (pageData: any, type?: any) => void;
  setPendingRename: (id: string, isPendingRename?: boolean) => void;
  pageIdPendingRename: string;
  isCollapsed: (id: string) => boolean;
  setCollapsed: (id: string, collapsed: boolean) => void;
}

export const PageTreeContext = React.createContext<PageTreeContextProps>({
  selectedIdeTabId: null,
  selectedPageId: null,
  onPageSelected: null,
  setPendingRename: null,
  pageIdPendingRename: null,
  isCollapsed: null,
  setCollapsed: null,
} as any);
