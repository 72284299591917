import React from 'react';
import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { BackendCodeTab } from '../components/backendTab/backendTab';
import { BiLoggerContext } from '@wix/wix-code-common-components';

export const BackendEntryPoint: EntryPoint = {
  name: 'Backend Entry Point',
  getDependencyAPIs() {
    return [
      APIKeys.MenuAPI,
      APIKeys.MenuPanelAPI,
      APIKeys.VSCodePublicAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.ReadOnlyAPI,
      APIKeys.PanelsAPI,
    ];
  },
  extend(shell: Shell) {
    const menuAPI = shell.getAPI(APIKeys.MenuAPI);
    const menuPanelAPI = shell.getAPI(APIKeys.MenuPanelAPI);
    const vscodePublicAPI = shell.getAPI(APIKeys.VSCodePublicAPI);
    const readOnlyAPI = shell.getAPI(APIKeys.ReadOnlyAPI);
    const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
    const panelsAPI = shell.getAPI(APIKeys.PanelsAPI);

    menuPanelAPI.contributeTab({
      id: 'backend',
      TabContent: ({ closePanel }) => (
        <BiLoggerContext.Provider value={biLoggerAPI}>
          <BackendCodeTab
            readOnly={readOnlyAPI.getReadOnlyMode().externalIde}
            vscodePublicAPI={vscodePublicAPI}
            panelsAPI={panelsAPI}
            closePanel={closePanel}
          />
        </BiLoggerContext.Provider>
      ),
    });
    menuAPI.registerMenuItemListener('backend', () => {
      menuPanelAPI.selectTab('backend');
    });
  },
};
