export type SidebarTabDefinition =
  | SideTabContribution
  | SideTabContributionWithProps;

export enum CollectionsAction {
  ADD_EXTERNAL_COLLECTION = 'add_external_collection',
}

export enum PredefinedSidebarTabs {
  PAGE_CODE = 'page_code',
  SITE_CODE = 'site_code',
  BLOCKS_CODE = 'blocks_code',
  SEARCH = 'search',
  TOOLS = 'tools',
  APP_BUILDER = 'app_builder',
  HELP = 'help',
  BLOCKS_HELP = 'blocks_help',
  PACKAGES = 'code_packages',
  GITHUB = 'github_integration',
  COLLECTIONS = 'collections',
}

type valueOf<T> = T[keyof T];

export interface SideTabContributionWithProps {
  tabId: PredefinedSidebarTabs;
  props?: valueOf<TabProps>;
}

export interface SideTabContribution {
  creator: () => React.ReactElement;
  id: string;
  dataHook?: string;
  label: string;
  infoLinkText?: string;
  infoLinkUrl?: string;
  infoText?: string;
  symbol: React.ReactElement;
  className?: string;
  shouldShowTab: (editorAPI?: any) => boolean;
  isBottomTab?: boolean;
}

export type TabProps = {
  [PredefinedSidebarTabs.COLLECTIONS]: Partial<{
    hiddenActions: CollectionsAction[];
    translations: {
      [key: string]: string;
    };
  }>;
  [PredefinedSidebarTabs.BLOCKS_CODE]: Partial<{
    translations: {
      [key: string]: string;
    };
  }>;
  [PredefinedSidebarTabs.BLOCKS_HELP]: Partial<{
    translations: {
      [key: string]: string;
    };
  }>;
};

export interface SidebarAPI {
  contributeTab<T extends keyof TabProps>(
    tab: (props?: TabProps[T]) => SideTabContribution,
  ): void;
}
