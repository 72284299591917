import { ideToolbarAction } from '@wix/bi-logger-platform/v2';

export const CODE_PANEL_ITEMS = {
  VELO_PRO: 'velo_pro_on',
  CODE_IN_WIX_IDE: 'code_in_wix_ide',
} as const;

type KeysOfCodePanelItems = keyof typeof CODE_PANEL_ITEMS;
export type CodePanelItem = (typeof CODE_PANEL_ITEMS)[KeysOfCodePanelItems];

export const codePanelEvent = (item: CodePanelItem) => {
  return ideToolbarAction({
    item_name: item,
  });
};
