import React from 'react';
import { PanelHeader } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';
import { TranslationProvider } from '@wix/wix-code-common-components';
import {
  LegacyEditorDependencies,
  PanelsAPI,
} from '@wix/wix-code-plugin-contracts';

type PackagesTabWrapperProps = {
  closePanel: () => void;
  apis: {
    legacyDependenciesAPI: LegacyEditorDependencies;
    panelsAPI: PanelsAPI;
  };
};

type PackagesTabPanelHeaderProps = {
  closePanel: () => void;
  panelsAPI: PanelsAPI;
};

const PackagesTabPanelHeader: React.FC<PackagesTabPanelHeaderProps> = ({
  closePanel,
  panelsAPI,
}) => {
  const [t] = useTranslation();

  const onOpenHelp = () => {
    panelsAPI.openHelpCenter('6ab6c4a6-667d-4ddc-912d-a2b4d6378fdc');
  };

  return (
    <PanelHeader
      onHelp={onOpenHelp}
      onClose={closePanel}
      className="dragger dark"
      defaultCursor
    >
      {t('Left_Tree_Components_Title_Apps_And_Packages')}
    </PanelHeader>
  );
};

export const createPackagesTabWrapper: React.FC<PackagesTabWrapperProps> = ({
  closePanel,
  children,
  apis: { panelsAPI, legacyDependenciesAPI },
}) => (
  <TranslationProvider
    options={{
      locale: legacyDependenciesAPI.util.editorModel.languageCode,
      disableAutoInit: true,
      asyncMessagesLoader: (locale: string) =>
        import(`../../../assets/locale/messages_${locale}.json`),
    }}
  >
    <PackagesTabPanelHeader closePanel={closePanel} panelsAPI={panelsAPI} />
    {children}
  </TranslationProvider>
);
