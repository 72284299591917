export interface ExperimentsObj {
  isOpen(spec: string): boolean;
  getValue(spec: string): string;
  isMultiValueExperimentOpen(spec: string): boolean;
  getRunningExperiments(): { [spec: string]: string };
}

// Dealr config
export enum RealEstateIds {
  PUSH_NOTIFICATION_REAL_ESTATE_ID = 'e19e52c8-187a-4fb6-8573-8963c15c93d4',
  HELP_ARTICLE_TAB_REAL_ESTATE_ID = '6814159a-31ee-4445-9891-7279e7df4bea',
}

export enum ActionType {
  TREE_SECTION_SET_LOADED = 'wix.code.TREE_SECTION_SET_LOADED',
  TREE_SECTION_SET_EXPANDED = 'wix.code.TREE_SECTION_SET_EXPANDED',
  REGISTER_SINGLE_FILE_SECTION = 'wix.code.REGISTER_SINGLE_FILE_SECTION',
  PACKAGES_MODAL_SELECT_TAB = 'wix.code.PACKAGES_MODAL_SELECT_TAB',
  PACKAGES_MODAL_SELECT_PACKAGE = 'wix.code.PACKAGES_MODAL_SELECT_PACKAGE',
  PACKAGE_JSON_MODIFIED = 'wix.code.PACKAGE_JSON_MODIFIED',
  PACKAGES_MODAL_SELECT_VERSION = 'wix.code.PACKAGES_MODAL_SELECT_VERSION',
  PACKAGES_MODAL_SEARCH_VELO_PKG = 'wix.code.PACKAGES_MODAL_SEARCH_VELO_PKG',
  SET_INSTALLED_CODE_REUSE_PKGS = 'wix.code.SET_INSTALLED_CODE_REUSE_PKGS',
  ADD_INSTALLED_CODE_REUSE_PKG = 'wix.code.ADD_INSTALLED_CODE_REUSE_PKG',
  UPDATE_INSTALLED_CODE_REUSE_PKG = 'wix.code.UPDATE_INSTALLED_CODE_REUSE_PKG',
  REMOVE_INSTALLED_CODE_REUSE_PKG = 'wix.code.REMOVE_INSTALLED_CODE_REUSE_PKG',
  SET_AVAILABLE_CODE_REUSE_PKGS = 'wix.code.SET_AVAILABLE_CODE_REUSE_PKGS',
  SET_CODE_REUSE_PKG_STATUS = 'wix.code.SET_CODE_REUSE_PKG_STATUS',
  SET_APPS_DATA = 'wix.code.SET_APPS_DATA',
  SET_APPS_DATA_LOADED = 'wix.code.SET_APPS_DATA_LOADED',
  SET_APPS_DATA_LOADING = 'wix.code.SET_APPS_DATA_LOADING',
  CODE_REUSE_FS_ADD_ROOT = 'wix.code.CODE_REUSE_FS_ADD_ROOT',
  CODE_REUSE_FS_REMOVE_ROOT = 'wix.code.CODE_REUSE_FS_REMOVE_ROOT',
  CODE_REUSE_FS_SET_EXPANDED = 'wix.code.CODE_REUSE_FS_SET_EXPANDED',
  CODE_REUSE_FS_EXPAND_PARENTS = 'wix.code.CODE_REUSE_FS_EXPAND_PARENTS',
  SET_SELECTION = 'wix.code.SET_SELECTION',
  CHANGE_CONTENT = 'wix.code.CHANGE_CONTENT',
  RESET_CONTENT_CHANGED_BI_REPORTS = 'wix.code.RESET_CONTENT_CHANGED_BI_REPORTS',
  SET_CONTENT_CHANGED_BI_REPORTED = 'wix.code.SET_CONTENT_CHANGED_BI_REPORTED',
  LOAD_FILE_BEGIN = 'wix.code.LOAD_FILE_BEGIN',
  LOAD_FILE_END = 'wix.code.LOAD_FILE_END',
  LOAD_FILE_FAILURE = 'wix.code.LOAD_FILE_FAILURE',
  LOAD_FILE_CHECK_STATE = 'wix.code.LOAD_FILE_CHECK_STATE',
  SET_FILES_LOADED = 'wix.code.SET_FILES_LOADED',
  SAVE_FILE_BEGIN = 'wix.code.SAVE_FILE_BEGIN',
  SAVE_FILE = 'wix.code.SAVE_FILE',
  SAVE_FILE_END = 'wix.code.SAVE_FILE_END',
  SAVE_FILE_FAILURE = 'wix.code.SAVE_FILE_FAILURE',
  SET_FILE = 'wix.code.SET_FILE',
  SET_DIRS = 'wix.code.SET_DIRS',
  REMOVE_FILE = 'wix.code.REMOVE_FILE',
  REMOVE_FILES_UNDER = 'wix.code.REMOVE_FILES_UNDER',
  SET_EXPANDED = 'wix.code.SET_EXPANDED',
  SET_EXPANDED_MANY = 'wix.code.SET_EXPANDED_MANY',
  SET_LOADING = 'wix.code.SET_LOADING',
  SET_LOADED = 'wix.code.SET_LOADED',
  SET_CHILDREN = 'wix.code.SET_CHILDREN',
  SET_NPM_PACKAGES = 'wix.code.SET_NPM_PACKAGES',
  SET_CODE_REUSE_PKGS = 'wix.code.SET_CODE_REUSE_PKGS',
  GET_CHILDREN = 'wix.code.GET_CHILDREN',
  REMOVE_CHILD = 'wix.code.REMOVE_CHILD',
  ADD_CHILD = 'wix.code.ADD_CHILD',
  SET_PENDING_RENAME = 'wix.code.SET_PENDING_RENAME',
  MOVE_FOLDER = 'wix.code.MOVE_FOLDER',
  SET_PENDING_DELETE = 'wix.code.SET_PENDING_DELETE',
  PAGE_EDITOR_SET_CURRENT_TAB = 'wix.code.PAGE_EDITOR_SET_CURRENT_TAB',
  PAGE_EDITOR_SET_COLLAPSED = 'wix.code.PAGE_EDITOR_SET_COLLAPSED',
  PAGE_EDITOR_SET_RENAMING = 'wix.code.PAGE_EDITOR_SET_RENAMING',
  CONSOLE_ADD_MESSAGE = 'wix.code.CONSOLE_ADD_MESSAGE',
  CONSOLE_REMOVE_MESSAGE = 'wix.code.CONSOLE_REMOVE_MESSAGE',
  CONSOLE_ADD_MESSAGES = 'wix.code.CONSOLE_ADD_MESSAGES',
  CONSOLE_ADD_MESSAGE_ORIGIN = 'wix.code.CONSOLE_ADD_MESSAGE_ORIGIN',
  CONSOLE_CLEAR_DISPLAYED_MESSAGES = 'wix.code.CONSOLE_CLEAR_DISPLAYED_MESSAGES',
  CONSOLE_SET_LEVEL_VISIBILITY = 'wix.code.CONSOLE_SET_LEVEL_VISIBILITY',
  FIND_REPLACE_TOGGLE_VISIBLE = 'wix.code.FIND_REPLACE_TOGGLE_VISIBLE',
  SET_LEFT_PANE_VIEW_MODE = 'wix.code.SET_LEFT_PANE_VIEW_MODE',
  SELECT_LEFT_PANE_TAB = 'wix.code.SELECT_LEFT_PANE_TAB',
  SUGGEST_LEFT_PANE_TAB = 'wix.code.SUGGEST_LEFT_PANE_TAB',
  TABS_CLOSE_TAB = 'wix.code.TABS_CLOSE_TAB',
  TABS_PIN_TAB = 'wix.code.TABS_PIN_TAB',
  TABS_CLOSE_EMBEDDED_TAB = 'wix.code.TABS_CLOSE_EMBEDDED_TAB',
  TABS_OPEN_OR_CHANGE_UNPINNED_TAB = 'wix.code.TABS_OPEN_OR_CHANGE_UNPINNED_TAB',
  TABS_ADD_OR_CHANGES_TBF_TAB = 'wix.code.TABS_ADD_OR_CHANGES_TBF_TAB',
  TABS_CLOSE_TBF_TAB = 'wix.code.TABS_CLOSE_TBF_TAB',
  TABS_STORE_SCROLL_STATE = 'wix.code.TABS_STORE_SCROLL_STATE',
  FILE_TREE_NODE_DOUBLE_CLICK = 'wix.code.FILE_TREE_NODE_DOUBLE_CLICK',
  SEARCH_IN_ALL_FILES_DONE = 'wix.code.SEARCH_IN_ALL_FILES_DONE',
  HELP_LINK_CLICK = 'wix.code.HELP_LINK_CLICK',
  DEALER_RESPONSE_DONE = 'wix.code.DEALER_RESPONSE_DONE',
  IDE_SET_THEME = 'wix.code.IDE_SET_THEME',
  CONTRIBUTE_TREE_SECTION = 'wix.code.CONTRIBUTE_TREE_SECTION',
  CONTRIBUTE_VALIDATION_RULE = 'wix.code.CONTRIBUTE_VALIDATION_RULE',
  SET_NEW_FILE_RENAME_INPUT = 'wix.code.SET_NEW_FILE_RENAME_INPUT',
  EXPAND_TREE_SECTION = 'wix.code.EXPAND_TREE_SECTION',
  COLLAPSE_TREE_SECTION = 'wix.code.COLLAPSE_TREE_SECTION',
  SET_TREE_SECTION_LOADED = 'wix.code.SET_TREE_SECTION_LOADED',
  SET_TREE_SECTION_LOADING = 'wix.code.SET_TREE_SECTION_LOADING',
  CONTRIBUTE_FILE_VALIDATION_RULE = 'wix.code.CONTRIBUTE_FILE_VALIDATION_RULE',
  PACKAGES_MODAL_ADD_JUST_INSTALLED_PKG = 'wix.code.PACKAGES_MODAL_ADD_JUST_INSTALLED_PKG',
  PACKAGES_MODAL_CLEAR_INSTALLED_PKGS = 'wix.code.PACKAGES_MODAL_CLEAR_INSTALLED_PKGS',
  PACKAGES_MODAL_ADD_JUST_REQUESTED_PKG = 'wix.code.PACKAGES_MODAL_ADD_JUST_REQUESTED_PKG',
  PACKAGES_MODAL_OPEN_VERSION_PICKER = 'wix.code.PACKAGES_MODAL_OPEN_VERSION_PICKER',
  PACKAGES_MODAL_CLOSE_VERSION_PICKER = 'wix.code.PACKAGES_MODAL_CLOSE_VERSION_PICKER',
  SEARCH_IN_ALL_FILES_QUERY_CHANGED = 'wix.code.SEARCH_IN_ALL_FILES_QUERY_CHANGED',
  SET_LAST_PROCESSED_PLATFORM_APP_MESSAGE_INDEX = 'wix.code.SET_LAST_PROCESSED_PLATFORM_APP_MESSAGE_INDEX',
  TBF_CONSOLE_ADD_MESSAGE = 'wix.code.TBF_CONSOLE_ADD_MESSAGE',
  TBF_CONSOLE_ADD_MESSAGES = 'wix.code.TBF_CONSOLE_ADD_MESSAGES',
  TBF_CONSOLE_CLEAR = 'wix.code.TBF_CONSOLE_CLEAR',
  ADD_FILE_LISTENER = 'wix.code.ADD_FILE_LISTENER',
  REMOVE_FILE_LISTENER = 'wix.code.REMOVE_FILE_LISTENER',

  INSTALL_MODULE_DONE = 'INSTALL_MODULE_DONE',
  INSTALL_MODULE_START = 'INSTALL_MODULE_START',
  TAB_SELECTED = 'TAB_SELECTED',
  OPEN_SUBMIT_NPM_REQUEST = 'OPEN_SUBMIT_NPM_REQUEST',
  CLOSE_SUBMIT_NPM_REQUEST = 'CLOSE_SUBMIT_NPM_REQUEST',
  SEARCH_KEYWORD_CHANGED = 'SEARCH_KEYWORD_CHANGED',
  ERROR_INSTALLING_PACKAGES = 'ERROR_INSTALLING_PACKAGES',
  LOAD_REMOTE_PACKAGES = 'LOAD_REMOTE_PACKAGES',
  REQUEST_PACKAGE_SUBMITTED = 'REQUEST_PACKAGE_SUBMITTED',
  REQUEST_PACKAGE_SUBMITTING_ERROR = 'REQUEST_PACKAGE_SUBMITTING_ERROR',
  REQUEST_PACKAGE_SUBMITTING = 'REQUEST_PACKAGE_SUBMITTING',
  REQUEST_PACKAGE_INPUT_CHANGE = 'REQUEST_PACKAGE_INPUT_CHANGE',
  REQUEST_PACKAGE_SEARCH_CHANGE = 'REQUEST_PACKAGE_SEARCH_CHANGE',
  REQUEST_PACKAGE_SET_VERSION_VALIDATION_ERROR = 'REQUEST_PACKAGE_SET_VERSION_VALIDATION_ERROR',
  INDEX_SEARCH_REQUEST = 'INDEX_SEARCH_REQUEST',
  INDEX_SEARCH_SUCCESS = 'INDEX_SEARCH_SUCCESS',
  INDEX_SEARCH_FAILURE = 'INDEX_SEARCH_FAILURE',
  PACKAGES_INIT_DONE = 'PACKAGES_INIT_DONE',
  PACKAGES_INIT_START = 'PACKAGES_INIT_START',
  LOAD_DEFAULT_NPM_PACKAGES = 'LOAD_DEFAULT_NPM_PACKAGES',
  SET_SELECTED_NPM_PKG_DATA = 'SET_SELECTED_NPM_PKG_DATA',
}
