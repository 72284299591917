import { Tooltip } from '@wix/wix-base-ui';
import { Copy } from '@wix/wix-ui-icons-common/classic-editor';
import React, { MouseEvent, useContext, useState } from 'react';
import s from './CopyButton.scss';
import { dataHooks } from '../../dataHooks';
import { TabContext } from '../tabContext';
import { leftTreeCopyId } from '@wix/bi-logger-platform/v2';
import { bi } from '../../bi';
import { experimentUtils } from '@wix/wix-code-common';

export interface CopyButtonProps {
  textToCopy: string;
  description: string;
  displayName: string;
  sectionName: string;
  itemType: string;
}

export const CopyButton: React.FC<CopyButtonProps> = ({
  description,
  textToCopy,
  displayName,
  sectionName,
  itemType,
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const { biLoggerAPI } = useContext(TabContext);

  const getCopyButtonClass = () =>
    isClicked
      ? experimentUtils.isLiteEditor()
        ? s.copyButtonClickedStudio
        : s.copyButtonClicked
      : experimentUtils.isLiteEditor()
      ? s.copyButtonStudio
      : s.copyButton;

  return (
    <div
      onClick={(e: MouseEvent) => {
        e.stopPropagation();
      }}
    >
      <Tooltip
        content={description}
        closeOnMouseClick={true}
        shouldTranslate={false}
      >
        <div
          className={getCopyButtonClass()}
          data-hook={dataHooks.COPY_BUTTON}
          onMouseLeave={() => setIsClicked(false)}
          onMouseEnter={() => {
            biLoggerAPI.report(
              leftTreeCopyId({
                action: bi.actions.HOVER,
                item_name: displayName,
                section_name: sectionName,
                item_type: itemType,
              }),
            );
          }}
          onClick={() => {
            biLoggerAPI.report(
              leftTreeCopyId({
                action: bi.actions.CLICK,
                item_name: displayName,
                section_name: sectionName,
                item_type: itemType,
              }),
            );
            navigator.clipboard.writeText(textToCopy);
            setIsClicked(true);
          }}
        >
          <Copy />
        </div>
      </Tooltip>
    </div>
  );
};
