import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { Experiments } from '@wix/wix-code-common';
import * as constants from './constants';
import {
  createExternalComponentsAPI,
  ExternalComponentsInternalAPIKey,
} from './externalComponentsInternalAPI';
import createSidebarSection from './components/createSideBarSection';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';

export const ExternalComponentsEntryPoint: EntryPoint = {
  name: 'external components entry point',
  getDependencyAPIs() {
    return [
      APIKeys.WixCodeDuplexerAPI,
      APIKeys.EditorContextAPI,
      APIKeys.ClassicEditorAPI,
      WixCodeEditorAdapterAPI,
      APIKeys.ExperimentsAPI,
      APIKeys.FilesTreeAPI,
    ];
  },
  declareAPIs() {
    return [ExternalComponentsInternalAPIKey];
  },
  attach(shell: Shell) {
    shell.contributeAPI(ExternalComponentsInternalAPIKey, () => {
      const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
      const wixCodeDuplexerAPI = shell.getAPI(APIKeys.WixCodeDuplexerAPI);
      return createExternalComponentsAPI({
        editorAPI,
        wixCodeDuplexerAPI,
      });
    });
  },
  async extend(shell: Shell) {
    const shouldContributeExternalComponents = () => {
      const experimentsAPI = shell.getAPI(APIKeys.ExperimentsAPI);
      const editorContextAPI = shell.getAPI(APIKeys.EditorContextAPI);
      const editorType = editorContextAPI.getEditorType();
      if (editorType === 'Studio' || editorType === 'EditorX') {
        return experimentsAPI.isOpen(Experiments.BYOC);
      }
      return false;
    };
    if (!shouldContributeExternalComponents()) {
      return;
    }

    const filesTreeAPI = shell.getAPI(APIKeys.FilesTreeAPI);
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
    const internalAPI = shell.getAPI(ExternalComponentsInternalAPIKey);
    const { legacyDependencies } = shell.getAPI(WixCodeEditorAdapterAPI);

    internalAPI.updateViewerOnChanges();
    filesTreeAPI.contributeSection({
      id: constants.EXTERANL_COMPONENTS_SECTION,
      order: 2,
      createSection: createSidebarSection({
        filesTreeAPI,
        editorAPI,
        legacyDependenciesAPI: legacyDependencies,
      }),
    });
  },
};
