import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import { createEditorComponentsAPI } from './api';

export const EditorComponentsEntryPoint: EntryPoint = {
  name: 'Components Data Entry Point',
  getDependencyAPIs() {
    return [APIKeys.ClassicEditorAPI, APIKeys.LegacyEditorDependencies];
  },
  declareAPIs() {
    return [APIKeys.EditorComponentsAPI];
  },
  async attach(shell: Shell) {
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
    const legacyDependencies = shell.getAPI(APIKeys.LegacyEditorDependencies);

    createEditorComponentsAPI({
      editorAPI,
      legacyDependencies,
    }).then((editorComponentAPI) =>
      shell.contributeAPI(
        APIKeys.EditorComponentsAPI,
        () => editorComponentAPI,
      ),
    );
  },
};
