import React from 'react';
import { DYNAMIC_PANEL_FULL_PATH } from '@/toExtract/panels/PanelsAPI';
import { useEditorLegacyAPIs } from '@wix/wix-code-common-components';

export interface DynamicPanelProps {
  withFocusPanelFrame: boolean;
  element: React.ReactElement;
  modalOptions?: {
    closeOnClickOutside?: boolean;
    onClose?: () => void;
  };
}

export const DynamicPanel: React.FC<DynamicPanelProps> = ({
  withFocusPanelFrame,
  children,
  modalOptions: { closeOnClickOutside, onClose } = {
    closeOnClickOutside: true,
    onClose: () => {},
  },
}) => {
  const {
    editorAPI,
    legacyDependenciesAPI: { panels },
  } = useEditorLegacyAPIs();
  const FocusPanelFrame = panels.frames.focusPanelFrame;
  return withFocusPanelFrame ? (
    <FocusPanelFrame
      shouldHideHeader={true}
      onClose={() => {
        onClose && onClose();
        editorAPI.panelManager.closePanelByName(DYNAMIC_PANEL_FULL_PATH);
        return true; // This return value is required by the FocusPanelFrame
      }}
      shouldNotCloseOnBlur={!closeOnClickOutside}
    >
      {children}
    </FocusPanelFrame>
  ) : (
    <>{children}</>
  );
};
