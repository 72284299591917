import React from 'react';
import { TextInput, Composites, TextLabel } from '@wix/wix-base-ui';
import s from '../SpiWizard.scss';
import { dataHooks } from '../../../consts/dataHooks';
import { useTranslation } from '@wix/wix-i18n-config';

interface WizardStep3NamePickerProps {
  spiName: string;
  isValid: boolean;
  onChange: (spiName: string) => void;
  errorMessage: string;
}

export const WizardStep3NamePicker: React.FC<WizardStep3NamePickerProps> = ({
  spiName,
  isValid,
  onChange,
  errorMessage,
}) => {
  const [t] = useTranslation();
  return (
    <div className={s.namePickerContainer}>
      <Composites.TextInputLabeled>
        <TextLabel
          type="T02"
          shouldTranslate={false}
          value={t('Wizard_Phase_3_Name_SPI')}
        />
        <div className={s.smallerMargin} />
        <TextInput
          value={spiName}
          shouldTranslate={false}
          placeholder={t('Wizard_Phase_3_Add_Name_Input')}
          isValid={isValid}
          onChange={(input) => onChange(input)}
          className={s.inputContainer}
          invalidMessage={errorMessage}
          dataHook={dataHooks.SPI_WIZARD_STEP_3_TEXT_INPUT}
          focus={true}
        />
      </Composites.TextInputLabeled>
    </div>
  );
};
