import React from 'react';
import { SingleFile } from './SingleFile';

export const ReadmeFileTree: React.FC<{
  translations?: { [key: string]: string };
}> = ({ translations }) => (
  <SingleFile
    parentId="public/"
    fileName="README.md"
    id="ReadmeFile"
    fileIsMandatory={true}
    initialContent="# README"
    translations={translations}
  />
);
