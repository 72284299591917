// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sRCLPl{position:fixed;width:100%;height:100%;top:0;left:0;z-index:4000}.TDRZBD{height:100%;width:100%;display:flex;justify-content:center;align-items:center}.D6WLx4{background-color:rgba(0,0,0,.5)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel-frame-container": "sRCLPl",
	"panelFrameContainer": "sRCLPl",
	"screen-centered-frame": "TDRZBD",
	"screenCenteredFrame": "TDRZBD",
	"dark-frame-overlay": "D6WLx4",
	"darkFrameOverlay": "D6WLx4"
};
export default ___CSS_LOADER_EXPORT___;
