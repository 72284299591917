import * as codeEditor from '@wix/wix-code-code-editor';
import bi from '@/legacy/bi/bi';
import { consts } from '@wix/wix-code-consts';

export const initEditorShortcuts = ({ api, editorAPI }: AnyFixMe) => {
  const { KeyMod, KeyCode } = codeEditor.KeyBindings;
  const { PREVIEW, PUBLISH, KEYBOARD_SHORTCUT } = consts;

  const commands = {
    publish: {
      keyBindings: KeyMod.CtrlCmd | KeyMod.Shift | KeyCode.KEY_P,
      handler: () => {
        editorAPI.publishManager.saveAndPublish();
        editorAPI.bi.event(bi.events.IDE_TOOLBAR_BUTTON_CLICK, {
          item_name: PUBLISH,
          origin: KEYBOARD_SHORTCUT,
        });
      },
    },
    preview: {
      keyBindings: KeyMod.CtrlCmd | KeyCode.KEY_P,
      handler: () => {
        editorAPI.preview.togglePreview();
        editorAPI.bi.event(bi.events.IDE_TOOLBAR_BUTTON_CLICK, {
          item_name: PREVIEW,
          origin: KEYBOARD_SHORTCUT,
        });
      },
    },
  };
  Object.values(commands).forEach(({ keyBindings, handler }) => {
    api.addCommand(keyBindings, handler);
  });
};
