// TEMPORARY FILE > IF YOU MAKE CHANGES HERE - THEY NEED TO BE MADE ALSO IN SearchPanelContainer.
// This file is only used by deprecatedAppBuilderTree and should be deleted once
// deprecatedAppBuilderTree is moved to the new Context flow

import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { wixCodeReduxContext } from '@wix/wix-code-common-components';
import bi from '@/legacy/bi/bi';
import { leftTreeTabPin as leftTreeTabPinEvent } from '@/legacy/bi/biEvents';
import searchPanelCreator from './searchPanel/searchPanel';
import codeStateReader from '@/infra/redux-state/reducers/codeStateReader';
import { utilsCreator as wixCodeUtilsCreator } from '@wix/wix-code-common';
import { consts } from '@wix/wix-code-consts';
import ideTabsActions from '@/codeEditor/tabs/actions/ideTabsActions';
import searchStateReader from './searchStateReader';
import searchActions from './searchActions';
import { fileIconCreator } from '@/utils/icons';
import constants from './constants';

const { MINIMUM_SEARCH_LETTERS } = constants;

export default ({ experiment, platform, util }) => {
  const wixCodeUtils = wixCodeUtilsCreator({ experiment, platform, util });
  const SearchPanel = searchPanelCreator({ util });

  const SearchPanelContainer = ({
    editorAPI,
    getFileDescriptorFromModelId,
    pinTab,
    results,
    setResults,
  }) => {
    const [error, setError] = useState('');

    const search = ({ query, options }) => {
      const validationResult = validate(query);
      if (validationResult === VALIDATION_RESULT.EMPTY_QUERY) {
        setError('');
        setResults(null);
        return;
      }

      if (validationResult === VALIDATION_RESULT.TOO_SHORT) {
        setError(util.translate('Corvid_Search_All_Files_Type_More'));
        setResults(null);
        return;
      }

      if (validationResult === VALIDATION_RESULT.VALID) {
        setError('');
      }

      const searchResults = editorAPI.wixCode.models.search({ query, options });
      const hasSearchOption = (option) => options.includes(option);
      editorAPI.bi.event(bi.events.SEARCH_ALL_FILES_SEARCH, {
        origin: 'left_tree',
        input_name: query,
        num_of_results: searchResults.length,
        is_case: hasSearchOption('case_sensitive'),
        is_whole_word: hasSearchOption('match_word'),
        is_reg_exp: hasSearchOption('regex_active'),
      });
      const resultsGroup = _.groupBy(searchResults, 'modelId');
      const FileIcon = fileIconCreator(editorAPI);

      // eslint-disable-next-line no-shadow
      const results = _.reduce(
        resultsGroup,
        (accumulator, matches, modelId) => {
          const { location: fileId, name: title } =
            getFileDescriptorFromModelId(modelId);
          const fileResult = {
            fileId,
            title,
            matches,
            subTitle: '',
            icon: <FileIcon path={fileId} key="icon-file" />,
          };

          if (wixCodeUtils.isPageFile(editorAPI, fileId)) {
            const pageId = wixCodeUtils.getPageIdFromFileId(fileId);
            accumulator[fileId] = _.assign(fileResult, {
              subTitle: 'Pages',
              title:
                pageId === consts.SITE_JS_PAGE_ID
                  ? consts.SITE_JS
                  : editorAPI.pages.getPageTitle(trimFileExtension(title)),
            });
          } else if (
            wixCodeUtils.isBackendFile(editorAPI, fileId) &&
            !wixCodeUtils.isReservedBackendFile(editorAPI, fileId)
          ) {
            accumulator[fileId] = _.assign(fileResult, {
              subTitle: `Backend${wixCodeUtils.getBackendUserFolder(
                editorAPI,
                fileId,
              )}`,
            });
          } else if (wixCodeUtils.isPublicFile(editorAPI, fileId)) {
            accumulator[fileId] = _.assign(fileResult, {
              subTitle: `Public${wixCodeUtils.getPublicUserFolder(
                editorAPI,
                fileId,
              )}`,
            });
          }
          return accumulator;
        },
        {},
      );

      setResults(results);
    };

    const selectSearchResult = ({ modelId, range }) => {
      const filePath = getFileDescriptorFromModelId(modelId).location;

      editorAPI.bi.event(bi.events.SEARCH_ALL_FILES_SELECT_RESULT, {
        file_name: filePath,
      });

      editorAPI.wixCode.fileTree.navigateToFile(editorAPI, filePath, {
        selection: rangeToSelection(range),
      });
    };

    const doubleClickResult = ({ modelId, range }) => {
      selectSearchResult({ modelId, range });

      const filePath = getFileDescriptorFromModelId(modelId).location;

      const isPinned = pinTab(filePath);

      if (isPinned) {
        editorAPI.bi.event(
          bi.events.IDE_TAB_ACTION,
          leftTreeTabPinEvent('search_result_double_click', filePath),
        );
      }
    };

    const validate = (query) => {
      if (query.length === 0) {
        return VALIDATION_RESULT.EMPTY_QUERY;
      }
      if (query.length <= MINIMUM_SEARCH_LETTERS) {
        return VALIDATION_RESULT.TOO_SHORT;
      }

      return VALIDATION_RESULT.VALID;
    };

    const VALIDATION_RESULT = {
      EMPTY_QUERY: 'EMPTY_QUERY',
      TOO_SHORT: 'TOO_SHORT',
      VALID: 'VALID',
    };

    return (
      <div className="search-panel">
        <SearchPanel
          onResultClick={selectSearchResult}
          onResultDoubleClick={doubleClickResult}
          onSearch={search}
          results={results}
          error={error}
        />
      </div>
    );
  };

  const trimFileExtension = (filePath) =>
    filePath.split('.').slice(0, -1).join('.');

  const rangeToSelection = (range) => ({
    start: {
      line: range.startLineNumber,
      column: range.startColumn,
    },
    end: {
      line: range.endLineNumber,
      column: range.endColumn,
    },
  });

  const mapStateToProps = (state) => ({
    getFileDescriptorFromModelId: (modelId) =>
      codeStateReader.getFileDescriptorFromModelId(state, modelId),
    results: searchStateReader.getSearchResults(state),
  });

  const mapDispatchToProps = (dispatch) => ({
    pinTab: (filePath) => dispatch(ideTabsActions.pinTab({ tabId: filePath })),
    setResults: (results) => dispatch(searchActions.searchDone({ results })),
  });

  const Connected = connect(mapStateToProps, mapDispatchToProps, null, {
    context: wixCodeReduxContext,
  })(SearchPanelContainer);

  return Connected;
};
