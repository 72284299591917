// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xjAuaW{display:flex;padding:18px 24px;min-height:90px;border-bottom:1px solid #dfe5eb;box-sizing:border-box}.xjAuaW:hover{background-color:#e7f0ff}.xjAuaW:hover,.xjAuaW:hover *{cursor:pointer}.xjAuaW .bclTl9{flex:1 1 auto;display:flex;flex-direction:column;justify-content:center;overflow:auto;word-wrap:break-word}.xjAuaW .mVlhty{flex:0 0 auto;display:flex;align-items:center;margin-left:12px}.xjAuaW .mVlhty svg circle{fill:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tool-item": "xjAuaW",
	"toolItem": "xjAuaW",
	"text-wrapper": "bclTl9",
	"textWrapper": "bclTl9",
	"cta-wrapper": "mVlhty",
	"ctaWrapper": "mVlhty"
};
export default ___CSS_LOADER_EXPORT___;
