export interface DefaultNpmPackageDescriptor {
  description: string;
  importSnippet: string;
  usageDescription: string;
  functionalitySnippet: string;
  whatDoes: string;
}

export const defaultNpmPackagesDescriptors: Record<
  string,
  DefaultNpmPackageDescriptor
> = {
  lodash: {
    description:
      'Lodash makes JavaScript easier by taking the hassle out of working with arrays, numbers, objects, strings, etc.',
    importSnippet: `import _ from 'lodash';`,
    usageDescription: 'Use the following code to union two arrays',
    functionalitySnippet: `let unionArray = _.union([1, 2, 3], [3, 4, 5]);
// unionArray = [1,2,3,4,5]`,
    whatDoes: ``,
  },
  '@sendgrid/mail': {
    description:
      'A dedicated service for interaction with the mail endpoint of the SendGrid v3 API.',
    importSnippet: `import sgMail from '@sendgrid/mail';`,
    usageDescription: 'Use the following code to send an email',
    functionalitySnippet: `const msg = {
    'to': 'to@example.com',
    'from': 'from@example.com',
    'subject': 'The subject on the email'
};
async () => {
    try {
        await sgMail.send(msg);
    } catch (error) {
        console.error(error);
    }
}`,
    whatDoes: ``,
  },
  moment: {
    description:
      'A JavaScript date library for parsing, validating, manipulating, and formatting dates.',
    importSnippet: `import moment from 'moment';`,
    usageDescription:
      'Use the following code to get the relative time that has passed since the year 2021 has started',
    functionalitySnippet: `moment("20210101", "YYYYMMDD").fromNow();`,
    whatDoes: ``,
  },
  uuid: {
    description: 'This packge is used for the creation of RFC4122 UUIDs.',
    importSnippet: `const uuid = require('uuid');`,
    usageDescription: 'Use the following code to create a uuid',
    functionalitySnippet: `let id = uuid.v4();`,
    whatDoes: ``,
  },
  'pdf-generator-api': {
    description: 'This pakcage is a JavaScript module for pdfgeneratorapi.com.',
    importSnippet: `import PDFGeneratorAPI from 'pdf-generator-api';`,
    usageDescription: 'Use the following code to get all available templates.',
    functionalitySnippet: `let Client = new PDFGeneratorAPI(
    '{your_api_key}',
    '{your_api_secret}'
);
Client.setBaseUrl('https://us1.pdfgeneratorapi.com/api/v3/');
Client.setWorkspace('{unique_workspace_identifier}');

Client.getAll().then(function (response) {
    // response.response => list of templates available for users
});`,
    whatDoes: ``,
  },
  stripe: {
    description: 'This package is a helper for Stripe API.',
    importSnippet: `import Stripe from 'stripe';`,
    usageDescription: 'Use the following code to log the customer.id property',
    functionalitySnippet: `const stripe = new Stripe('secret key');

async () => {
    const customer = await stripe.customers.create({
        email: 'customer@example.com',
    });
    console.log(customer.id);
};`,
    whatDoes: ``,
  },
  axios: {
    description:
      'This package provides Promise based HTTP client for the browser and node.js',
    importSnippet: `const axios = require('axios');`,
    usageDescription: 'Use the following code to perform a GET request',
    functionalitySnippet: `async () => {
    try {
        const response = await axios.get('/user?ID=12345');
        console.log(response);
    } catch (error) {
        console.error(error);
    }
}`,
    whatDoes: ``,
  },
  'crypto-js': {
    description: 'This package provides crypto standards.',
    importSnippet: `import CryptoJS from 'crypto-js';`,
    usageDescription: 'Use the following code encypt/decrypt text messages',
    functionalitySnippet: `// Encrypt
const text = CryptoJS.AES.encrypt('my message', 'secret key').toString();

// Decrypt
const bytes = CryptoJS.AES.decrypt(text, 'secret key');
const originalText = bytes.toString(CryptoJS.enc.Utf8);

console.log(originalText); // 'my message'`,
    whatDoes: ``,
  },
  nodemailer: {
    description: 'This package allows e-mail sending.',
    importSnippet: `import nodemailer from 'nodemailer';`,
    usageDescription: 'Use the following code to send an e-mail',
    functionalitySnippet: `let transport = {};
let defaults;
let data = {};
let callback;

let transporter = nodemailer.createTransport(transport[defaults])
transporter.sendMail(data[callback])
        `,
    whatDoes: ``,
  },
  twilio: {
    description: 'This package is a helper for the Twilio library.',
    importSnippet: `import twilio from 'twilio';`,
    usageDescription: 'Use the following code to send a message using callback',
    functionalitySnippet: `
        // Use this function to send message using callback.
let from = 'FROM_NUMBER'
let to = 'TO_NUMBER'
twilio.messages.create({
    "from": from,
    "to": to,
    "body": 'create using callback'
}, function (err, result) {
    console.log(result.sid);
});`,
    whatDoes: ``,
  },
};
