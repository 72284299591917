import s from './ContextMenuItem.scss';
import * as React from 'react';
import cx from 'classnames';

export interface ContextMenuItemProps {
  icon:
    | React.FC<{ className: string }>
    | React.SFC<React.SVGProps<SVGSVGElement>>;
  label: string;
}

export class ContextMenuItem extends React.Component<ContextMenuItemProps> {
  render() {
    const { icon: Icon, label } = this.props;

    return (
      <div className={s.container}>
        <Icon
          className={cx(
            'symbol', // className symbol is here to get the default styling from <ContextMenuAction /> of wix-base-ui
            s.currentColor,
          )}
        />
        <span className={s.label}>{label}</span>
      </div>
    );
  }
}
