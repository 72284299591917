import React from 'react';

export type PropertiesPanelTab = {
  label: string;
  id: string;
  TabContent: React.ComponentType;
};

export interface PropertiesPanelAPI {
  contributeTab: (tab: PropertiesPanelTab) => void;
  selectTab: (tabId: string) => void;
}

export const propertiesPanelTabIds = {
  propertiesAndEvents: 'propertiesAndEvents',
  cssClasses: 'cssClasses',
};

export const tabsOrder = [
  propertiesPanelTabIds.propertiesAndEvents,
  propertiesPanelTabIds.cssClasses,
];
