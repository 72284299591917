import React, { useState } from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import {
  Text,
  DropDown,
  DropDownOption,
  TextInputMultiline,
  CustomModal,
  Preloader,
} from '@wix/wix-base-ui';
import cx from 'classnames';
import s from './GenerateCodeForm.scss';
import { dataHooks } from '../dataHooks';
import { AIModalFooter } from '../AIModalFooter/AIModalFooter';

export interface GenerateCodeFormProps {
  closePanel: () => void;
  onGenerateCodeClick: () => Promise<void>;
  selectedEvent: string;
  setSelectedEvent: (event: string) => void;
  events: { name: string; description: string }[];
  userInput: string;
  setUserInput: (userInput: string) => void;
}

const GenerateCodeForm = ({
  closePanel,
  onGenerateCodeClick,
  selectedEvent,
  setSelectedEvent,
  events,
  userInput,
  setUserInput,
}: GenerateCodeFormProps) => {
  const [loading, setLoading] = useState(false);
  const primaryButtonProps = loading
    ? {
        className: cx(s.cta, { [s.disabled]: loading }),
        prefixIcon: <Preloader className="tiny light" />,
      }
    : {};

  const [t] = useTranslation();
  return (
    <CustomModal
      onCloseButtonClick={closePanel}
      title={t('aiCodeAssistant.AddCodeModal.Title')}
      // base ui won't render the button when button text is empty, passing space as a workaround
      primaryButtonText={
        loading ? ' ' : t('aiCodeAssistant.AddCodeModal.PrimaryCta')
      }
      primaryButtonOnClick={async () => {
        if (loading) {
          return;
        }
        setLoading(true);
        await onGenerateCodeClick();
        setLoading(false);
      }}
      primaryButtonProps={{
        disabled: !userInput,
        ...primaryButtonProps,
      }}
      secondaryButtonText={t('aiCodeAssistant.AddCodeModal.SecondaryCta')}
      secondaryButtonOnClick={closePanel}
      onHelpButtonClick={() => {
        debugger;
        window.open(t('aiCodeAssistant.AddCodeModal.LearnMore.Link'), '_blank');
      }}
      dataHook={dataHooks.ai_panel.modal}
      footnote={<AIModalFooter />}
    >
      <div className={s.main}>
        <Text size="medium" dataHook={dataHooks.ai_panel.event_label}>
          {t('aiCodeAssistant.AddCodeModal.EventPrompt')}
        </Text>
        <DropDown
          value={selectedEvent}
          onChange={(value: string) => setSelectedEvent(value)}
          dataHook={dataHooks.ai_panel.event_dropdown}
        >
          {events.map(({ name, description }) => (
            <DropDownOption value={name} label={description} key={name} />
          ))}
        </DropDown>
        <Text size="medium" dataHook={dataHooks.ai_panel.user_input_label}>
          {t('aiCodeAssistant.AddCodeModal.ActionPrompt')}
        </Text>
        <TextInputMultiline
          placeholder={t(
            'aiCodeAssistant.AddCodeModal.ActionPrompt.Placeholder',
          )}
          onChange={(value) => setUserInput(value)}
          value={userInput}
          dataHook={dataHooks.ai_panel.userInput}
        />
      </div>
    </CustomModal>
  );
};

export default GenerateCodeForm;
