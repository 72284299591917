import React from 'react';
import _ from 'lodash';
import { DATA_BINDING } from '@wix/app-definition-ids';
import { utilsCreator, getMasterPageFileId } from '@wix/wix-code-common';
import { leftTreeClickOnAnItem } from '@wix/bi-logger-platform/v2';
import { StaticPagesTree } from './StaticPagesTree';
import { DynamicPagesTree } from './DynamicPagesTree';
import { PageTreeContext } from './PageTreeContext';
import { PagesTabContext } from './PagesTabContext';
import { RouterPagesTree } from './RouterPagesTree';
import { LightboxTree } from './LightboxTree';

import {
  BiLoggerAPI,
  DevContextAPI,
  EditorAPI,
  FilesViewAPI,
  LegacyEditorDependencies,
  WixCodeStoreAPI,
} from '@wix/wix-code-plugin-contracts';
import { Divider } from '@wix/wix-base-ui';
import dataHooks from '../dataHooks';
import MasterPage from './MasterPage';
import { ContributedSection } from '../state';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import {
  getAppPagesData,
  getDataSources,
  getDynamicRoutersData,
  getRoutersData,
  getSiteMenuData,
} from '../dataParser';
import { PageMetaData, RouterPageData } from './types';

type TypeRaw = 'PageLink' | 'DynamicPageLink';

type Type = {
  raw: TypeRaw;
  isDropdown: boolean;
  isLink: boolean;
  isPage: boolean;
  isPopupLink: boolean;
};

type MenuItem = {
  id: string;
  type: Type;
  label: string;
  isVisible: boolean;
  isVisibleMobile: boolean;
  pageData: RouterPageData | null;
  link: {
    type: TypeRaw;
    id: string;
    metaData: PageMetaData;
    pageId: string;
    target: string;
  };
  position: number;
  appDefinitionId: null;
  isCustomErrorPage: boolean | null;
  isHomePage: boolean;
  isOldBlog: boolean;
  items: MenuItem[];
};

type Menu = {
  id: string;
  actions: null;
  menuItems: MenuItem[];
  multiPageMenu: boolean;
  title: string;
};

type PageType = { isPage: true; isDynamicPage: true; routerType: string };

type Router = {
  id: string;
  title: string;
  pagesData: RouterPageData[];
  prefix: string;
  applicationId: number;
  isSpecial: boolean;
  biCategory: string;
  routerType: string;
  actions: {
    title: string;
    symbolName: string;
    event: string;
    type?: string;
  }[];
};

type Popup = {
  id: string;
  title: string;
  biCategory: string;
};

type Datasources = {
  appPages: AppPage[];
  menus: Menu[];
  routers: Router[];
  popups: Popup[];
};

type PageDescriptor = {
  orderIndex: number;
  icon: string;
  tooltip?: string;
};

type Permission = {
  granted: boolean;
};

type Action = {
  title: string;
  event: string;
  symbolName: string;
  type?: string;
  permissions: Permission;
};

type PageGroup = {
  title: string;
  pages: string[];
  id: string;
  groupActions: Action[];
};

type AppPage = {
  id: string;
  title: string;
  pagesData: {
    id: string;
    tpaPageId: string;
    title: string;
    managingAppDefId: string;
    permissionSymbol: string | null;
    pageDescriptor: PageDescriptor;
  }[];
  biCategory: string;
  helpId: string;
  pagesGroups: PageGroup[];
  actions: Action[];
};

interface PagesTabCompEditorReduxProps {
  editorAPI: EditorAPI;
  selectedPageId: string;
  dataSources: Datasources;
}

interface PagesTabReduxProps {
  pageIdPendingRename: string;
}

type PagesTabCompProps = PagesTabReduxProps &
  PagesTabCompEditorReduxProps & {
    legacyDependenciesAPI: LegacyEditorDependencies;
    wixCodeStoreAPI: WixCodeStoreAPI;
    biLoggerAPI: BiLoggerAPI;
    devContextAPI: DevContextAPI;
    filesViewAPI: FilesViewAPI;
    wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI;
    contributedSections: ContributedSection[];
    selectedIdeTabId: string;
  };

const PagesTabComp: React.FC<PagesTabCompProps> = ({
  editorAPI,
  selectedPageId,
  dataSources,
  selectedIdeTabId,
  pageIdPendingRename,
  legacyDependenciesAPI,
  wixCodeStoreAPI,
  biLoggerAPI,
  devContextAPI,
  filesViewAPI,
  wixCodeEditorAdapterAPI,
  contributedSections,
}: PagesTabCompProps) => {
  const { constants, experiment, platform, util } = legacyDependenciesAPI;

  const {
    getStore,
    selectors: {
      page: { isPageCollapsed: isPageCollapsedRaw, pageActionsCreator },
    },
  } = wixCodeStoreAPI;
  const pageActions = pageActionsCreator({ experiment, platform, util });
  const utils = utilsCreator({ experiment, platform, util });
  const store = getStore();
  /*
  We need to use this translate func so the translations will come from wix-code-classic-editor artfaict. 
  We should move them in here after coordinating it with our Tech Writer.
  */
  const { translate } = util;

  const refreshAppAfterDataChange = () => {
    const context = editorAPI.developerMode.getContext();
    if (
      experiment.isOpen('sv_livePreview') &&
      context.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.COLLECTION
    ) {
      editorAPI.dsActions.platform.livePreview.refresh({
        apps: [DATA_BINDING],
        source: 'AFTER_DB_CHANGE',
      });
    }
  };

  const setPageCollapsed = (id: AnyFixMe, collapsed: AnyFixMe) => {
    store.dispatch(pageActions.setCollapsed([id], collapsed));
  };

  const focusOnLoad = (fileId: AnyFixMe) => {
    editorAPI.wixCode.codeEditorApi.performActionOnMount(
      fileId,
      (api: AnyFixMe) => api && api.focus(),
    );
  };

  const isPageCollapsed = (id: AnyFixMe) => {
    return isPageCollapsedRaw(store.getState(), id);
  };

  const onPageSelected = (pageId: string, type: PageType = {} as any) => {
    biLoggerAPI.report(
      leftTreeClickOnAnItem({
        item_name: pageId,
        item_type: type.isDynamicPage ? 'dynamic_page' : 'page',
      }),
    );
    refreshAppAfterDataChange();
    editorAPI.panelManager.closeAllPanelsOfType(
      constants.PANEL_TYPES.FULL_STAGE,
    );

    focusOnLoad(utils.getFileIdFromPageId(pageId));
    editorAPI.pages.navigateTo(pageId);
  };

  const setPendingRename = (pageId: AnyFixMe, isPendingRename = false) => {
    store.dispatch(pageActions.setRenaming(pageId, isPendingRename));
  };

  const pagesStructure = dataSources;

  const masterPageFileId = getMasterPageFileId();

  const sitePagesMenuItems = getSiteMenuData(pagesStructure);

  const dynamicRouters = getDynamicRoutersData(editorAPI, dataSources);

  const routers = getRoutersData(dataSources);
  return (
    <PagesTabContext.Provider
      value={{
        legacyDependenciesAPI,
        wixCodeStoreAPI,
        editorAPI,
        bi: biLoggerAPI,
        devContextAPI,
        filesViewAPI,
        wixCodeEditorAdapterAPI,
      }}
    >
      <PageTreeContext.Provider
        value={{
          selectedPageId,
          selectedIdeTabId,
          onPageSelected,
          setPendingRename,
          pageIdPendingRename,
          isCollapsed: isPageCollapsed,
          setCollapsed: setPageCollapsed,
        }}
      >
        <StaticPagesTree
          title={translate('Left_Tree_Components_Category_SitePages')}
          items={sitePagesMenuItems}
          showGroupOptions
        />
        <div data-hook={dataHooks.MASTER_PAGE_CONTAINER}>
          <Divider dataHook={dataHooks.MASTER_PAGE_DIVIDER} />
          <MasterPage selected={selectedIdeTabId === masterPageFileId} />
        </div>
        {contributedSections.map(({ section: Section }: any) => (
          <Section />
        ))}
        {getAppPagesData(pagesStructure).map((appPage: AppPage) => {
          return (
            <StaticPagesTree title={appPage.title} items={appPage.pagesData} />
          );
        })}
        {dynamicRouters.length > 0 && (
          <DynamicPagesTree
            data={dynamicRouters}
            label={translate('Left_Tree_Components_Category_DynamicPages')}
          />
        )}
        {routers.length > 0 && (
          <RouterPagesTree
            label={translate('Left_Tree_Components_Category_RouterPages')}
            items={routers}
          />
        )}
        {dataSources.popups.length > 0 && (
          <LightboxTree
            items={dataSources.popups}
            label={translate('Left_Tree_Components_Category_Lightboxes')}
          />
        )}
      </PageTreeContext.Provider>
    </PagesTabContext.Provider>
  );
};

const mapEditorStateToProps = ({
  editorAPI,
}: AnyFixMe): PagesTabCompEditorReduxProps => {
  const selectedPageId = editorAPI.pages.getFocusedPageId();
  const dataSources = getDataSources(editorAPI);

  return {
    editorAPI,
    selectedPageId,
    dataSources,
  };
};

let ConnectedComponent = null as any;
export const PagesTab = (origProps: AnyFixMe) => {
  const mapCodeStateToProps = (state: any): PagesTabReduxProps => {
    const pageIdPendingRename = getRenamingPageId(state);
    return {
      pageIdPendingRename,
    };
  };

  const legacyDependenciesAPI: LegacyEditorDependencies =
    origProps.legacyDependenciesAPI;
  const wixCodeStoreAPI: WixCodeStoreAPI = origProps.wixCodeStoreAPI;
  const {
    connectToStores,
    selectors: {
      page: { getRenamingPageId },
    },
  } = wixCodeStoreAPI;

  const { util } = legacyDependenciesAPI;
  if (ConnectedComponent === null) {
    ConnectedComponent = connectToStores({
      util,
      mapEditorStateToProps,
      mapCodeStateToProps,
      comp: PagesTabComp,
    });
  }

  return <ConnectedComponent {...origProps} />;
};
