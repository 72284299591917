import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useTranslate } from '@/infra/i18n/useTranslate';
import { useEditorLegacyAPIs } from '@wix/wix-code-common-components';

export type DevModeLoaderProps = {
  panelName: string;
  isDone: boolean;
  appName: string;
  isError?: boolean;
};

const tasksDisplayNameArray = [
  'DeveloperMode_EnableDevMode_Loader_Text1',
  'DeveloperMode_EnableDevMode_Loader_Text2',
  'DeveloperMode_EnableDevMode_Loader_Text3',
  'DeveloperMode_EnableDevMode_Loader_Text4',
  'DeveloperMode_EnableDevMode_Loader_Text5',
];

const DevModeLoader = (props: DevModeLoaderProps) => {
  const [step, setStep] = useState(0);
  const { t } = useTranslate();
  const updateProgresssBarInterval: any = useRef();
  const {
    legacyDependenciesAPI: { panels },
  } = useEditorLegacyAPIs();
  const defaultProps = {
    isError: props.isError || false,
  };
  const totalSteps = tasksDisplayNameArray.length;
  const ProgressBarPanel = panels.focusPanels.progressBarPanel;

  const onSuccess = () => {
    setStep(tasksDisplayNameArray.length);
  };

  const getTaskDisplayName = () =>
    step === 0 ? '' : t(tasksDisplayNameArray[step - 1]);

  const getTitle = () => t(props.appName);

  const updateProgressState = () => {
    if (step < totalSteps - 1) {
      setStep((prevState) => prevState + 1);
    }
    if (props.isDone && !defaultProps.isError) {
      clearInterval(updateProgresssBarInterval.current);
      onSuccess();
    }
  };

  useEffect(() => {
    updateProgresssBarInterval.current = setInterval(updateProgressState, 1000);

    return () => {
      clearInterval(updateProgresssBarInterval.current);
    };
  });

  return (
    <ProgressBarPanel
      panelName={props.panelName}
      progressMax={totalSteps}
      step={step}
      taskDisplayName={getTaskDisplayName()}
      isError={defaultProps.isError}
      isDone={props.isDone}
      title={getTitle()}
      panelClosed={_.noop}
    />
  );
};

export default DevModeLoader;
