import React from 'react';
import { HelpTabLinks, HelpTabLinkProps } from './HelpTabLinks';
import { HelpTabUpdates, HelpTabUpdatesProps } from './HelpTabUpdates';

type HelpTabRootProps = HelpTabLinkProps & HelpTabUpdatesProps;

export const HelpTabRoot: React.FC<HelpTabRootProps> = ({
  helpArticle,
  links,
  openFeedbackForm,
}) => {
  return (
    <>
      <HelpTabLinks links={links} helpArticle={helpArticle} />
      <HelpTabUpdates
        helpArticle={helpArticle}
        openFeedbackForm={openFeedbackForm}
      />
    </>
  );
};
