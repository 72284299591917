import s from './runCodeButton.scss';
import React, { FC, useCallback } from 'react';
import PlayCircle from 'wix-ui-icons-common/classic-editor/PlayCircle';
import { Button, Tooltip } from '@wix/wix-base-ui';
import bi from '../../../../bi/bi';
import { previewClick } from '../../../../bi/biEvents';
import {
  useEditorLegacyAPIs,
  connectToStores,
} from '@wix/wix-code-common-components';
import { useTranslate } from '@/infra/i18n/useTranslate';
import { codeEditorConsts } from '@wix/wix-code-common';

enum PreviewingStatus {
  IDLE = 'idle',
  IN_PROGRESS = 'inProgress',
}

interface RunCodeButtonReduxStateProps {
  isLocked: boolean;
  previewingStatus: PreviewingStatus;
}

type RunCodeButtonProps = RunCodeButtonReduxStateProps;

const RunCodeButtonComp = ({
  isLocked,
  previewingStatus,
}: RunCodeButtonProps): JSX.Element => {
  const { editorAPI } = useEditorLegacyAPIs();
  const { t } = useTranslate();

  const handleRunCodeBtnClick = useCallback(() => {
    editorAPI.preview.togglePreview();
    editorAPI.bi.event(bi.events.PREVIEW_CLICK, previewClick());
  }, [editorAPI.bi, editorAPI.preview]);

  return (
    <Tooltip
      content={t('WixCode_Run_Code_Button_Tooltip_Label')}
      marginTop={4}
      maxWidth={200}
      dataHook={codeEditorConsts.DATA_HOOKS.RUN_CODE_BUTTON_TOOLTIP}
    >
      <Button
        disabled={isLocked || previewingStatus === PreviewingStatus.IN_PROGRESS}
        className={s.runCodeButton}
        onClick={handleRunCodeBtnClick}
        dataHook={codeEditorConsts.DATA_HOOKS.RUN_CODE_BUTTON}
      >
        <PlayCircle />
        {t('WixCode_Run_Code_Button_Label')}
      </Button>
    </Tooltip>
  );
};

const mapEditorStateToPropsFunc =
  (stateManagement: any) =>
  ({ state }: AnyFixMe): RunCodeButtonReduxStateProps => {
    return {
      isLocked: stateManagement.editorPlugins.savePublish?.getIsLocked(state),
      previewingStatus:
        stateManagement.editorPlugins.preview?.getPreviewingStatus(state),
    };
  };

let ConnectedComponent: FC | null = null;
export const RunCodeButton = (): JSX.Element => {
  const {
    legacyDependenciesAPI: { util, stateManagement },
  } = useEditorLegacyAPIs();
  if (ConnectedComponent === null) {
    ConnectedComponent = connectToStores({
      util,
      mapEditorStateToProps: mapEditorStateToPropsFunc(stateManagement),
      comp: RunCodeButtonComp,
    });
  }
  return <ConnectedComponent />;
};
