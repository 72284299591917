import './searchBox.global.scss';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { xor, noop, debounce } from 'lodash';

import { wixCodeReduxContext } from '@wix/wix-code-common-components';
import searchStateReader from '../searchStateReader';
import searchActions from '../searchActions';
import constants from '../constants';
import { useTranslate } from '@/infra/i18n/useTranslate';
import { AppState } from '@/infra/redux-state/reducers/rootReducer';

const SEARCH_OPTIONS = [
  {
    name: 'case_sensitive',
    title: 'Left_Tree_Components_MatchCase',
  },
  {
    name: 'match_word',
    title: 'Left_Tree_Components_MatchWord',
  },
  {
    name: 'regex_active',
    title: 'Left_Tree_Components_Search',
  },
];

interface SearchBoxProps {
  query: string;
  autoFocus?: boolean;
  onSearch?: (args: { query: string; options: string[] }) => void;
  searchQueryChanged: (s: string) => void;
  placeholder: string;
  error?: string;
}

const SearchBox = ({
  autoFocus = true,
  onSearch = noop,
  placeholder,
  error,
  searchQueryChanged,
  query,
}: SearchBoxProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchOptions, setSearchOptions] = useState<string[]>([]);
  const { t } = useTranslate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryChanged = useCallback(
    debounce(
      (_query) => onSearch({ query: _query, options: searchOptions }),
      constants.DEBOUNCED_SEARCH_TIME,
    ),
    [],
  );

  useEffect(() => {
    if (inputRef && autoFocus) {
      inputRef.current?.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    queryChanged(query);
    return queryChanged.cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const toggleSearchOption = (option: string) => {
    setSearchOptions(xor(searchOptions, [option]));
    inputRef.current?.focus();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // cancel debounced query if exists
    queryChanged.cancel();
    onSearch({ query, options: searchOptions });
  };

  return (
    <div data-aid="search-box">
      <form onSubmit={handleSubmit}>
        <div className="search-box">
          <input
            data-aid="search-box-input"
            key="search-box-input"
            type="text"
            placeholder={placeholder}
            className={classNames('search-box-input', {
              'search-box-input--error': !!error,
            })}
            onChange={(e) => searchQueryChanged(e.target.value)}
            value={query}
            ref={inputRef}
          />
          <div className="search-options">
            {SEARCH_OPTIONS.map(({ name, title }) => (
              <div
                key={name}
                onClick={() => toggleSearchOption(name)}
                data-aid={name}
                title={t(title)}
                className={classNames('svg-container', name, {
                  [`${name}--active`]: searchOptions.includes(name),
                  active: searchOptions.includes(name),
                })}
              />
            ))}
          </div>
        </div>
      </form>
    </div>
  );
};

SearchBox.contextTypes = {
  captureException: PropTypes.func,
};

const mapStateToProps = (state: AppState) => {
  const query = searchStateReader.getSearchQuery(state);

  return {
    query,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  const searchQueryChanged = (query: string) =>
    dispatch(searchActions.searchQueryChanged({ query }));

  return {
    searchQueryChanged,
  };
};

const Connected = connect(mapStateToProps, mapDispatchToProps, null, {
  context: wixCodeReduxContext,
})(SearchBox);

export default Connected;
