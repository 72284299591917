import * as React from 'react';
import { CodeReusePkgTreeContext } from '../CodeReusePkgTreeContext';

import bi from '@/legacy/bi/bi';
import { SuffixPimple } from './SuffixPimple';
import { PkgTreeContext } from '../../PkgTreeContext';
import { useEditorLegacyAPIs } from '@wix/wix-code-common-components';

interface UpdatePimpleProps {
  pkgName: string;
}

export const UpdatePimple: React.FC<UpdatePimpleProps> = ({ pkgName }) => {
  const { latestVersion, manageInstalledModules } = React.useContext(
    CodeReusePkgTreeContext,
  );
  const { isBlocksCombinedMode } = React.useContext(PkgTreeContext);
  const {
    editorAPI,
    legacyDependenciesAPI: {
      util: { translate },
    },
  } = useEditorLegacyAPIs();

  const seeReleaseNotes = (event: React.MouseEvent) => {
    editorAPI.bi.event(bi.events.CODE_REUSE_ADD_DEPENDENCY_CLICK, {
      related_link: 'MANAGE_INSTALLED_MODULES',
      link_name: 'see_release_notes',
      link_type: 'update',
      origin: pkgName,
    });
    manageInstalledModules();
    event.stopPropagation();
  };

  const updatePimpleProps = {
    tooltipText: translate('WixCode_PackageManager_Update_Text', {
      versionNumber: latestVersion!.version.substring(0, 3),
    }),
    ctaAction: seeReleaseNotes,
    ctaText: isBlocksCombinedMode
      ? ''
      : translate('WixCode_PackageManager_Update_Link'),
  };

  return <SuffixPimple {...updatePimpleProps} />;
};
