import React from 'react';
import { MessageModal, Text, Spacer } from '@wix/wix-base-ui';
import dataHooks from '../dataHooks';
import { useTranslation } from '@wix/wix-i18n-config';
import { useBiLogger } from '../../context/biLoggerContext';
import { beforeConnectModalAction } from '../../utils/biEvents';
import { MODALS_ACTIONS } from '../../utils/biConsts';

const ModalContent: React.FC<{
  translate: Function;
}> = ({ translate: t }) => {
  return (
    <>
      <Text
        dataHook={dataHooks.beforeConnectModal.contentLine1}
        enableEllipsis={false}
      >
        {t('githubIntegration.before_connect_modal.content_line_1')}
      </Text>
      <Spacer type="Spacer06" />
      <Text
        dataHook={dataHooks.beforeConnectModal.contentLine2}
        enableEllipsis={false}
      >
        {t('githubIntegration.before_connect_modal.content_line_2')}
      </Text>
    </>
  );
};

const BeforeConnectModal: React.FC<{
  closeModal: Function;
  onContinue: React.MouseEventHandler;
}> = ({ closeModal, onContinue }) => {
  const [t] = useTranslation();
  const bi = useBiLogger();

  return (
    <MessageModal
      dataHook={dataHooks.beforeConnectModal.container}
      onCloseButtonClick={() => {
        bi.report(beforeConnectModalAction({ action: MODALS_ACTIONS.X }));
        closeModal();
      }}
      title={t('githubIntegration.before_connect_modal.title')}
      content={<ModalContent translate={t} />}
      primaryButtonText={t(
        'githubIntegration.before_connect_modal.continue_button',
      )}
      primaryButtonOnClick={(e) => {
        bi.report(
          beforeConnectModalAction({ action: MODALS_ACTIONS.CONTINUE }),
        );
        onContinue(e);
      }}
      secondaryButtonText={t(
        'githubIntegration.before_connect_modal.cancel_button',
      )}
      secondaryButtonOnClick={() => {
        bi.report(beforeConnectModalAction({ action: MODALS_ACTIONS.CANCEL }));
        closeModal();
      }}
    />
  );
};

export default BeforeConnectModal;
