import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';

import { createWixCodeStoreAPI } from './WixCodeStoreAPI';

export const WixCodeStoreEntryPoint: EntryPoint = {
  name: 'WixCode Store Entry Point',
  declareAPIs() {
    return [APIKeys.WixCodeStoreAPI];
  },
  attach(shell: Shell) {
    shell.contributeAPI(APIKeys.WixCodeStoreAPI, () => createWixCodeStoreAPI());
  },
};
