import React from 'react';
import { ToolsTab } from './ToolsTab';
import ToolsIcon from '../icons/tools.svg';
import { PredefinedSidebarTabs } from '@wix/wix-code-plugin-contracts';

export const panelHeaderData = {
  label: 'Left_Tree_Components_Title_Tools_Developer',
  infoText: 'Left_Tree_Components_Tooltip_Tools',
};

export const createDeveloperToolsTab = () => ({
  symbol: <ToolsIcon />,
  id: PredefinedSidebarTabs.TOOLS,
  dataHook: `${PredefinedSidebarTabs.TOOLS}_tab`,
  creator: () => <ToolsTab />,
  shouldShowTab: () => true,
  ...panelHeaderData,
});
