// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hz15G4{color:#de9c03}.IkhbC9{color:#dfe6ef}.sLms5x{color:#3178c6}._bcNsu{color:#06a2ad}.tiF84j{color:#0049db}.p2Yipd path{fill:currentColor !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"js": "hz15G4",
	"jsx": "IkhbC9",
	"ts": "sLms5x",
	"jsw": "_bcNsu",
	"css": "tiF84j",
	"currentColor": "p2Yipd"
};
export default ___CSS_LOADER_EXPORT___;
