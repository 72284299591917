import {
  EditorAPI,
  PanelsAPI,
  WixCodeDuplexerAPI,
  wixCodeLifecycleDuplexer,
  WixCodeStoreAPI,
} from '@wix/wix-code-plugin-contracts';
import { openErrorModal } from './errorModal/errorModal';
import { installDone } from './packagesModal/packagesModalContent/pkgLists/npmPackagesList/actions/modulesActions';
import { GetResolveNpmDependenciesResultRequest } from '@wix/ambassador-velo-npm-v1-npm-package-info/types';
import { getResolveNpmDependenciesResult } from '@wix/ambassador-velo-npm-v1-npm-package-info/http';
import { HttpClient } from '@wix/http-client';

export type DependenciesChangedListener = (
  payload?: wixCodeLifecycleDuplexer.DependenciesChangedEvent,
) => void;

export type PackagesAPI = {
  internalSubscribeToDependenciesChanged: (
    pkgName: string,
    jobid: string,
    listener: DependenciesChangedListener,
  ) => void;
  subscribeToDependenciesChanged: () => void;
};
const jobsMap = new Map<
  string,
  wixCodeLifecycleDuplexer.DependenciesChangedEvent
>();
const listenersMap = new Map<string, DependenciesChangedListener>();

export const createPackagesAPI = (
  wixCodeDuplexerAPI: WixCodeDuplexerAPI,
  panelsAPI: PanelsAPI,
  wixCodeStoreAPI: WixCodeStoreAPI,
  translate: (key: string) => string,
  editorAPI: EditorAPI,
): PackagesAPI => {
  const subscribeToDependenciesChanged = () => {
    wixCodeDuplexerAPI.subscribeTo.dependenciesChanged(
      (payload: wixCodeLifecycleDuplexer.DependenciesChangedEvent) => {
        const { jobId } = payload;
        if (jobId) {
          const listener = listenersMap.get(jobId);
          if (listener) {
            listener(payload);
            listenersMap.delete(jobId);
          } else {
            jobsMap.set(jobId, payload);
          }
        }
      },
    );
  };

  const internalSubscribeToDependenciesChanged = (
    pkgName: string,
    jobId: string,
    listener: DependenciesChangedListener,
  ) => {
    const payload = jobsMap.get(jobId);
    if (payload) {
      listener(payload);
      jobsMap.delete(jobId);
    } else {
      listenersMap.set(jobId, listener);
      setTimeout(async () => {
        if (listenersMap.has(jobId)) {
          await handleDuplexerTimeOut(jobId, pkgName);
          listenersMap.delete(jobId);
        }
      }, 70000);
    }
  };
  const handleDuplexerTimeOut = async (jobId: string, pkgName: string) => {
    const getResolveNpmDependenciesResultRequest: GetResolveNpmDependenciesResultRequest =
      {
        jobId,
      };
    try {
      const httpClient = new HttpClient({
        getAppToken: () => editorAPI.wixCode.getClientSpec().instance,
      });

      await httpClient.request(
        getResolveNpmDependenciesResult(getResolveNpmDependenciesResultRequest),
      );
      const listener = listenersMap.get(jobId);
      listener && listener();
    } catch (error) {
      wixCodeStoreAPI.dispatch(installDone(pkgName));
      openErrorModal(panelsAPI, translate);
    }
  };
  return {
    subscribeToDependenciesChanged,
    internalSubscribeToDependenciesChanged,
  };
};
