import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TFunc } from './i18nService';

interface TranslateBaseUiIfNeededProps {
  tFunc: TFunc;
}

export class TranslateBaseUiIfNeeded extends React.Component<TranslateBaseUiIfNeededProps> {
  static childContextTypes = {
    translateFromContext: PropTypes.func,
  };

  getChildContext() {
    const { tFunc } = this.props;
    return {
      translateFromContext: (key: string) => {
        const translation = tFunc(key);
        return translation;
      },
    };
  }

  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}
