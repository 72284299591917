import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { DATA_BINDING } from '@wix/app-definition-ids';
import collectionsTreeNodeCreator from './collectionsTreeNode';
import bi from '../../../bi/bi';
import {
  isDbDriverSchema,
  isExternalSchema,
  isUserSchema,
} from './schemaHelpers';
import { QUICK_ACTIONS } from './constants';
import { withErrorHandling } from '@/infra/monitoring';

import once_ from 'lodash/once';
import get_ from 'lodash/get';
import sortBy_ from 'lodash/sortBy';
import orderBy_ from 'lodash/orderBy';
import groupBy_ from 'lodash/groupBy';

export default once_(
  ({
    baseUI,
    constants,
    core,
    experiment,
    platform,
    util,
    stateManagement,
  }) => {
    const { getAppStore } = stateManagement.editorPlugins.appsStore.selectors;

    const CollectionsTreeNode = collectionsTreeNodeCreator({
      baseUI,
      core,
      experiment,
      platform,
      util,
      stateManagement,
    });

    const CollectionsTree = createReactClass({
      displayName: 'CollectionsTree',
      propTypes: {
        onCollectionSelected: PropTypes.func.isRequired,
        onCreateCollection: PropTypes.func.isRequired,
        onAddExternalCollections: PropTypes.func,
        onEditExternalCollections: PropTypes.func,
        onRemoveExternalCollections: PropTypes.func,
        // redux state props
        loading: PropTypes.bool.isRequired,
        schemas: PropTypes.arrayOf(PropTypes.object),
        schemaActions: PropTypes.object,
      },

      mixins: [core.mixins.editorAPIMixin],

      getInitialState() {
        return {
          rootExpanded: true,
          namespaceExpandedMap: {},
          prevSelectedSchemaId: getSelectedSchemaId(this.props.devModeContext),
        };
      },

      statics: {
        getDerivedStateFromProps(props, state) {
          const selectedSchemaId = getSelectedSchemaId(props.devModeContext);
          if (selectedSchemaId !== state.prevSelectedSchemaId) {
            const stateUpdate = {
              prevSelectedSchemaId: selectedSchemaId,
              rootExpanded: state.rootExpanded || Boolean(selectedSchemaId),
            };

            const selectedSchema = props.schemas.find(
              ({ id }) => id === selectedSchemaId,
            );
            const namespace = get_(selectedSchema, 'namespace');
            if (!namespace) {
              return stateUpdate;
            }

            const namespaceExpandedMap = {
              ...state.namespaceExpandedMap,
              [namespace]: true,
            };

            return { namespaceExpandedMap, ...stateUpdate };
          }

          return null;
        },
      },

      componentDidMount() {
        this.onContextMenuActionSelected = withErrorHandling(
          this.onContextMenuActionSelected,
        );
      },

      onFolderToggle({ isRoot, namespace, isExpandedFolder }) {
        this.setState((state) =>
          isRoot
            ? { rootExpanded: !isExpandedFolder }
            : {
                namespaceExpandedMap: {
                  ...state.namespaceExpandedMap,
                  [namespace]: !isExpandedFolder,
                },
              },
        );
      },

      onContextMenuActionSelected({ action, nodeData }) {
        const { isRoot, name, isFolder, schema } = nodeData;
        const {
          onCreateCollection,
          onAddExternalCollections,
          onEditExternalCollections,
          onRemoveExternalCollections,
        } = this.props;
        const editorAPI = this.getEditorAPI();
        if (isRoot) {
          editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_PLUS_ITEM, {
            section_name: 'database',
            menu_entry_name: action,
          });
        } else if (isFolder) {
          editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_SETTINGS_ITEM, {
            item_name: name,
            item_type: 'external_collection',
            menu_entry_name: action,
          });
        } else {
          editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_SETTINGS_ITEM, {
            item_name: schema.id,
            item_type: 'collection',
            menu_entry_name: action,
          });
        }

        switch (action) {
          case QUICK_ACTIONS.NEW_COLLECTION:
            return onCreateCollection();
          case QUICK_ACTIONS.ADD_EXTERNAL_COLLECTIONS:
            return onAddExternalCollections();
          case QUICK_ACTIONS.EDIT_EXTERNAL_COLLECTIONS:
            return onEditExternalCollections(name);
          case QUICK_ACTIONS.REMOVE_EXTERNAL_COLLECTIONS:
            return onRemoveExternalCollections(name);
          default:
            const { applicationId } =
              editorAPI.dsRead.platform.getAppDataByAppDefId(DATA_BINDING);

            editorAPI.dsActions.platform.notifyApplication(applicationId, {
              eventType: action,
              eventPayload: {
                collectionId: schema.id,
                origin: 'ideTree',
              },
            });
        }
      },

      render() {
        const {
          labelOverride,
          devModeContext,
          onCollectionSelected,
          onCreateCollection,
          loading,
          schemas,
          schemaActions,
        } = this.props;

        const { rootExpanded, namespaceExpandedMap } = this.state;

        const nodeData = computeTreeData({
          schemas,
          rootName: labelOverride,
          selectedSchemaId: getSelectedSchemaId(devModeContext),
          rootExpanded,
          isNamespaceExpanded: (namespace) =>
            Boolean(namespaceExpandedMap[namespace]),
        });

        return (
          <CollectionsTreeNode
            loading={loading}
            nodeData={nodeData}
            onCollectionSelected={onCollectionSelected}
            onCreateCollection={onCreateCollection}
            onFolderToggle={this.onFolderToggle}
            onContextMenuActionSelected={this.onContextMenuActionSelected}
            schemaActions={schemaActions}
          />
        );
      },
    });

    const getSelectedSchemaId = (devModeContext) =>
      devModeContext.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.COLLECTION
        ? devModeContext.data.id
        : null;

    const createSchemaNodeData = ({ schema, selectedSchemaId }) => ({
      isFolder: false,
      name: schema.displayName,
      isSelectedSchema: schema.id === selectedSchemaId,
      schema,
    });

    const createFolderNodeData = ({
      name,
      namespace = null,
      isRoot = false,
      isExternalFolder = false,
      isExpandedFolder,
      childItems,
    }) => ({
      isFolder: true,
      name,
      namespace,
      isRoot,
      isExternalFolder,
      isExpandedFolder,
      childItems,
    });

    const schemasToChildItems = ({ schemas, selectedSchemaId }) =>
      sortBy_(schemas, 'displayName').map((schema) =>
        createSchemaNodeData({ schema, selectedSchemaId }),
      );

    const getChildItems = ({
      schemas,
      selectedSchemaId,
      isNamespaceExpanded,
    }) => {
      const getFolderName = (schema) =>
        schema.displayNamespace || schema.namespace;

      if (util.appStudioUtils.isAppStudio()) {
        return schemasToChildItems({ schemas, selectedSchemaId });
      }

      const groupsByNamespace = Object.values(
        groupBy_(
          schemas.filter((schema) => !isUserSchema(schema)),
          'namespace',
        ),
      );

      const sortedGroupsByNamespace = orderBy_(
        groupsByNamespace,
        [isExternalSchema, isDbDriverSchema, getFolderName].map(
          (sortFn) => (schemasGroup) => sortFn(schemasGroup[0]),
        ),
        ['desc', 'desc', 'asc'],
      );

      const folders = sortedGroupsByNamespace.map((schemasGroup) => {
        const firstSchema = schemasGroup[0];

        return createFolderNodeData({
          name: getFolderName(firstSchema),
          namespace: firstSchema.namespace,
          isExternalFolder: isExternalSchema(firstSchema),
          isExpandedFolder: isNamespaceExpanded(firstSchema.namespace),
          childItems: schemasToChildItems({
            schemas: schemasGroup,
            selectedSchemaId,
          }),
        });
      });

      const rootSchemas = schemas.filter(isUserSchema);

      return [
        ...folders,
        ...schemasToChildItems({ schemas: rootSchemas, selectedSchemaId }),
      ];
    };

    const computeTreeData = ({
      schemas,
      rootName,
      selectedSchemaId,
      rootExpanded,
      isNamespaceExpanded,
    }) =>
      createFolderNodeData({
        name: rootName,
        isRoot: true,
        isExpandedFolder: rootExpanded,
        childItems: getChildItems({
          schemas,
          selectedSchemaId,
          isNamespaceExpanded,
        }),
      });

    const mapStateToProps = ({ state, dsRead }) => {
      const dataBindingStore = getAppStore(
        state,
        dsRead.platform.editorApps.DATA_BINDING.appDefId,
      );

      const schemas = get_(dataBindingStore, 'schemas', []);

      const actions = get_(dataBindingStore, 'actions', {});

      const actionsBySchema = get_(dataBindingStore, 'actionsBySchema', []);

      const schemaActions = Object.keys(actionsBySchema).reduce(
        (updatedSchemaActions, schemaId) => {
          updatedSchemaActions[schemaId] = actionsBySchema[schemaId].map(
            (action) => actions[action],
          );
          return updatedSchemaActions;
        },
        {},
      );

      return {
        loading: !dataBindingStore,
        schemas,
        schemaActions,
      };
    };

    return util.hoc.connect(
      util.hoc.STORES.STATE_AND_DS,
      mapStateToProps,
    )(CollectionsTree);
  },
);
