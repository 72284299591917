import _ from 'lodash';
import { ActionType } from '../actions/types';
import { consts } from '@wix/wix-code-consts';
import {
  FileDescriptor as FileDescriptorInternal,
  FilesReducerState as FilesReducerStateInternal,
  LoadFileBegin as LoadFileBeginInternal,
  LoadFileEnd as LoadFileEndInternal,
  LoadFileFailure as LoadFileFailureInternal,
  LoadFileCheckState as LoadFileCheckStateInternal,
  SetFilesLoaded as SetFilesLoadedInternal,
  SetContentChangeBiReported as SetContentChangeBiReportedInternal,
} from '@wix/wix-code-plugin-contracts';

export type FileDescriptor = FileDescriptorInternal;
export type FilesReducerState = FilesReducerStateInternal;
export type LoadFileBegin = LoadFileBeginInternal;
export type LoadFileEnd = LoadFileEndInternal;
export type LoadFileFailure = LoadFileFailureInternal;
export type LoadFileCheckState = LoadFileCheckStateInternal;
export type SetFilesLoaded = SetFilesLoadedInternal;
export type SetContentChangeBiReported = SetContentChangeBiReportedInternal;

type RemoveFile = {
  fileId: string;
  type: ActionType.REMOVE_FILE;
};
type RemoveFilesUnder = {
  fileId: string;
  type: ActionType.REMOVE_FILES_UNDER;
};

type MoveFolder = {
  oldLocation: string;
  type: ActionType.MOVE_FOLDER;
};

type FilesReducerAction =
  | LoadFileBegin
  | LoadFileEnd
  | LoadFileFailure
  | LoadFileCheckState
  | RemoveFile
  | RemoveFilesUnder
  | SetFilesLoaded
  | SetContentChangeBiReported
  | MoveFolder;

const initialState: FilesReducerState = {};

const moveFolder = (state: FilesReducerState, action: MoveFolder) => {
  let newState = { ...state };
  Object.keys(state).forEach((fileId) => {
    if (fileId.startsWith(action.oldLocation)) {
      const { [fileId]: fileToRemove, ...rest } = state;
      newState = { ...rest };
    }
  });
  return newState;
};

const filesReducer = (state = initialState, action: FilesReducerAction) => {
  switch (action.type) {
    case ActionType.LOAD_FILE_BEGIN:
      const newFile = {
        descriptor: null,
        state: consts.FILE_STATES.LOADING,
      };
      return {
        ...state,
        [action.fileId]: newFile,
      };
    case ActionType.LOAD_FILE_END:
      if (state[action.fileId]?.state === consts.FILE_STATES.IDLE) {
        return { ...state };
      }
      return {
        ...state,
        [action.fileId]: {
          ...state[action.fileId],
          modelId: action.modelId,
          descriptor: action.descriptor,
          state: consts.FILE_STATES.IDLE,
        },
      };
    case ActionType.LOAD_FILE_FAILURE:
      if (state[action.fileId]?.state === consts.FILE_STATES.IDLE) {
        return { ...state };
      }
      return {
        ...state,
        [action.fileId]: {
          ...state[action.fileId],
          state: consts.FILE_STATES.LOAD_FAILED,
        },
      };
    case ActionType.LOAD_FILE_CHECK_STATE:
      if (state[action.fileId]?.state === consts.FILE_STATES.LOADING) {
        return {
          ...state,
          [action.fileId]: {
            ...state[action.fileId],
            state: consts.FILE_STATES.LOAD_TAKING_LONGER_THAN_USUAL,
          },
        };
      }
      return { ...state };
    case ActionType.REMOVE_FILE:
      const { [action.fileId]: fileIdToRemove, ...rest } = state;
      return { ...rest };
    case ActionType.REMOVE_FILES_UNDER:
      return {
        ...Object.keys(state).filter((file) => {
          return !_.startsWith(file, action.fileId);
        }),
      };
    case ActionType.SET_FILES_LOADED:
      const updates = _.fromPairs(
        action.filesWithModels
          .map(({ fileId, modelId, descriptor }) => {
            if (state[action.fileId!]?.state !== consts.FILE_STATES.IDLE) {
              return [
                fileId,
                {
                  modelId,
                  descriptor,
                  state: consts.FILE_STATES.IDLE,
                },
              ];
            }
            return [];
          })
          .filter((entry) => entry.length === 2),
      );

      return {
        ...state,
        ...updates,
      };

    case ActionType.SET_CONTENT_CHANGED_BI_REPORTED:
      return {
        ...state,
        [action.fileId]: {
          ...state[action.fileId],
          contentChangedBiReported: true,
        },
      };

    case ActionType.MOVE_FOLDER:
      return moveFolder(state, action);

    default:
      return state;
  }
};

export default filesReducer;
