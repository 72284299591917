import { NewChild } from '@wix/wix-code-plugin-contracts';
import { NodeDoubleClick } from '@/codeEditor/tabs/reducers/ideTabsReducer';
import { ThunkExtraArg } from '@wix/wix-code-common-components';
import { ActionType } from './types';

function nodeDoubleClick(fileId: NodeDoubleClick['fileId']): Function {
  return (dispatch: AnyFixMe, _: AnyFixMe, { editorAPI }: ThunkExtraArg) => {
    editorAPI.developerMode.ui.idePane.unMinimize();
    return dispatch({
      type: ActionType.FILE_TREE_NODE_DOUBLE_CLICK,
      fileId,
    });
  };
}

function setNewFileRenameInput(newChild: NewChild) {
  return {
    type: ActionType.SET_NEW_FILE_RENAME_INPUT,
    newChild,
  };
}

export { nodeDoubleClick, setNewFileRenameInput };
