import React from 'react';
import { DisconnectModalBase } from '../DisconnectModal/DisconnectModalBase';
import { Trans, useTranslation } from '@wix/wix-i18n-config';
import dataHooks from '../dataHooks';
import { DisconnectModalProps } from '../DisconnectModal/DisconnectModal';
import { ON_BOARDING_SUPPORTED_ERRORS } from '../../constants';

interface GithubUninstalledModalProps extends DisconnectModalProps {
  refreshEditor: () => void;
}

export const GithubUninstalledModal = ({
  closeModal,
  githubAPI,
  refreshEditor,
}: GithubUninstalledModalProps) => {
  const [t] = useTranslation();

  return (
    <DisconnectModalBase
      errorType={ON_BOARDING_SUPPORTED_ERRORS.GITHUB_APP_INSTALLATION_NOT_FOUND}
      dataHook={dataHooks.githubConnectionIssuesModal.githubUninstalledModal}
      closeModal={closeModal}
      githubAPI={githubAPI}
      title={t('githubIntegration.modal.app_uninstalled.title')}
      helpLink={t('githubIntegration.modal.app_uninstalled.learn_more.link')}
      secondaryButtonOnClick={refreshEditor}
      primaryButtonText={t(
        'githubIntegration.modal.app_uninstalled.primary_cta',
      )}
      secondaryButtonText={t(
        'githubIntegration.modal.app_uninstalled.secondary_cta',
      )}
      content={
        <Trans
          i18nKey={t('githubIntegration.modal.app_uninstalled.description')}
          components={[
            <a
              href={`${t(
                'githubIntegration.modal.app_uninstalled.velo_app.link',
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </a>,
          ]}
        />
      }
    />
  );
};
