import React from 'react';
import cx from 'classnames';
import { TextLabel, Checkbox, InfoIcon } from '@wix/wix-base-ui';
import * as biEvents from '../bi/biEvents';
import { TFunction } from '@wix/wix-i18n-config';

type ElementPropsSectionProps = {
  t: TFunction;
  disabled: boolean;
  isCompHiddenable: boolean;
  toggleCompVisibility: () => void;
  isCompVisible: boolean;
  isCompCollapsible: boolean;
  toggleCompCollapsiblity: () => void;
  isCompCollapsed: boolean;
  isCompDisableable: boolean;
  toggleCompEnability: () => void;
  isCompEnabled: boolean;
  sendBi: (event: Record<string, any>) => void;
  siteId: string;
  compId: string;
  compType: string;
};

const ElementPropsSection = ({
  t,
  disabled,
  isCompHiddenable,
  toggleCompVisibility,
  isCompVisible,
  isCompCollapsible,
  toggleCompCollapsiblity,
  isCompCollapsed,
  isCompDisableable,
  toggleCompEnability,
  isCompEnabled,
  sendBi,
  siteId,
  compId,
  compType,
}: ElementPropsSectionProps) => (
  <div data-aid="props-container" data-is-read-only={(!!disabled).toString()}>
    <span className={cx({ disabled }, 'section-label-container')}>
      <TextLabel
        automationId="props-label"
        className="section-label"
        type="T04"
        value={t('WixCode_PropertiesAndEventsPanel_DefaultValues_Title')}
      />
      <InfoIcon
        automationId="props-label-tooltip"
        text={t('WixCode_PropertiesAndEventsPanel_DefaultValues_Tooltip')}
      />
    </span>
    <div data-aid="props-checkboxes" className="props-checkboxes">
      {isCompHiddenable && (
        <Checkbox
          automationId="visibility"
          dataHook="visibility"
          disabled={disabled}
          className="prop-label"
          label={t('WixCode_PropertiesAndEventsPanel_DefaultValues_Hidden')}
          labelAfterSymbol={true}
          value={!isCompVisible}
          type="T04"
          onChange={() => {
            sendBi(
              biEvents.toggleVisibility({
                siteId,
                compId,
                compType,
                isVisible: !isCompVisible,
              }),
            );
            toggleCompVisibility();
          }}
        />
      )}
      {isCompCollapsible && (
        <Checkbox
          automationId="collapsiblity"
          dataHook="collapsiblity"
          disabled={disabled}
          className="prop-label"
          label={t('WixCode_PropertiesAndEventsPanel_DefaultValues_Collapsed')}
          labelAfterSymbol={true}
          value={isCompCollapsed}
          type="T04"
          onChange={() => {
            sendBi(
              biEvents.toggleCollapsibility({
                siteId,
                compId,
                compType,
                isCollapsed: !isCompCollapsed,
              }),
            );
            toggleCompCollapsiblity();
          }}
        />
      )}
      {isCompDisableable && (
        <Checkbox
          automationId="enability"
          dataHook="enability"
          disabled={disabled}
          className={cx({ disabled }, 'prop-label')}
          label={t('WixCode_PropertiesAndEventsPanel_DefaultValues_Enabled')}
          labelAfterSymbol={true}
          value={isCompEnabled}
          type="T04"
          onChange={() => {
            sendBi(
              biEvents.toggleEnability({
                siteId,
                compId,
                compType,
                isEnabled: !isCompEnabled,
              }),
            );
            toggleCompEnability();
          }}
        />
      )}
    </div>
  </div>
);

export default ElementPropsSection;
