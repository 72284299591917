import * as React from 'react';
import { PkgTreeContextWrapper } from './pkgTree/PkgTreeContextWrapper';
import { SectionsSlot } from './packagesTabEntryPoint/contributedSections';
import { SlotRenderer } from '@wix/wix-code-repluggable';
import { sectionsOrder } from '@wix/wix-code-plugin-contracts';

export const PkgTreeRoot: React.FC<{
  slot: SectionsSlot;
}> = ({ slot }) => (
  <PkgTreeContextWrapper showCodeReuse={true}>
    <div data-aid="combined-packages-section">
      <SlotRenderer
        slot={slot}
        sortFunc={(sectionA, sectionB) =>
          sectionsOrder.indexOf(sectionA.contribution.id) -
          sectionsOrder.indexOf(sectionB.contribution.id)
        }
        mapFunc={({ Component }, idx) =>
          () =>
            <Component isLast={idx === slot.getItems().length - 1} />}
      />
    </div>
  </PkgTreeContextWrapper>
);
