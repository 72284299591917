import { SpiFolder } from '../../../SpiService';

export type SpiNameValidator = {
  isValid: (spiName: string) => { isValid: boolean; errors: ErrorType[] };
};

export enum ErrorType {
  TOO_LONG = 'Wizard_Phase_3_Spi_Name_Error_Too_Long',
  INVALID_CHARS = 'Wizard_Phase_3_Spi_Name_Error_Special_Chars',
  ALREADY_EXISTS = 'Wizard_Phase_3_Spi_Name_Error_Spi_Exists',
}

export const spiNameValidator = (spisList: SpiFolder[]): SpiNameValidator => {
  const isTooLong = (spiName: string): boolean => spiName.length > 20;

  const containsSpecialChars = (spiName: string): boolean => {
    return !spiName.match(/^[a-zA-Z0-9-_]+$/);
  };

  const alreadyExists = (spiName: string): boolean => {
    const lowerCaseSpiName = spiName.toLowerCase();
    return spisList.some(
      (spi: SpiFolder) => spi.name.toLowerCase() === lowerCaseSpiName,
    );
  };

  return {
    isValid: (spiName: string) => {
      const errors = [];
      let isValid = true;
      if (spiName === '') {
        return { isValid: true, errors: [] };
      }
      if (isTooLong(spiName)) {
        isValid = false;
        errors.push(ErrorType.TOO_LONG);
      }
      if (containsSpecialChars(spiName)) {
        isValid = false;
        errors.push(ErrorType.INVALID_CHARS);
      }
      if (alreadyExists(spiName)) {
        isValid = false;
        errors.push(ErrorType.ALREADY_EXISTS);
      }
      return { isValid, errors };
    },
  };
};
