const getParentPath = (filePath: string = '') =>
  filePath.replace(/\/[^/]*\/?$/, '/');
const isMarkdownFile = (filePath: string = '') => filePath.endsWith('.md');
const isPackageFile = (filePath: string = '') => filePath.startsWith('@');
const isPackageMarkdown = (filePath: string = '') =>
  isPackageFile(filePath) && isMarkdownFile(filePath);
const isScheduledJobsFile = (filePath: string = '') =>
  filePath === 'backend/jobs.config';
const isCustomElementsFile = (filePath: string = '') =>
  filePath.startsWith('public/custom-elements/');
const convertModelIdToPath = (modelId: string) =>
  modelId.replace('file:///', '');

export const fileSystem = {
  getParentPath,
  isMarkdownFile,
  isScheduledJobsFile,
  isCustomElementsFile,
  convertModelIdToPath,
  codePackages: {
    isPackageFile,
    isPackageMarkdown,
  },
};
