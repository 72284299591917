import React from 'react';
import { ON_BOARDING_SUPPORTED_ERRORS } from '../../constants';
import { GithubSuspendedModal } from './GithubSuspendedModal';
import { DisconnectModalProps } from '../DisconnectModal/DisconnectModal';
import { GithubUninstalledModal } from './GithubUninstalledModal';
import { RepositoryNotFoundModal } from './RepositoryNotFoundModal';
import { useBiLogger } from '../../context/biLoggerContext';
import { createDisconnectFromGithubActions } from '../../utils/biEvents';
import { MODALS_ACTIONS } from '../../utils/biConsts';

interface GithubConnectionIssuesModalProps extends DisconnectModalProps {
  errorType: ON_BOARDING_SUPPORTED_ERRORS;
}

export const GithubConnectionIssuesModal = ({
  errorType,
  githubAPI,
  closeModal,
}: GithubConnectionIssuesModalProps) => {
  const bi = useBiLogger();
  const disconnectBiEvents = createDisconnectFromGithubActions(githubAPI);

  const refreshEditor = () => {
    bi.report(
      disconnectBiEvents.disconnectFromGithubModalAction({
        action: MODALS_ACTIONS.REFRESH_EDITOR,
        errorType,
      }),
    );
    window.location.reload();
  };

  switch (errorType) {
    case ON_BOARDING_SUPPORTED_ERRORS.GITHUB_APP_INSTALLATION_SUSPENDED: {
      return (
        <GithubSuspendedModal
          refreshEditor={refreshEditor}
          closeModal={closeModal}
          githubAPI={githubAPI}
        />
      );
    }
    case ON_BOARDING_SUPPORTED_ERRORS.GITHUB_APP_INSTALLATION_NOT_FOUND: {
      return (
        <GithubUninstalledModal
          refreshEditor={refreshEditor}
          closeModal={closeModal}
          githubAPI={githubAPI}
        />
      );
    }
    case ON_BOARDING_SUPPORTED_ERRORS.REPOSITORY_NOT_FOUND: {
      return (
        <RepositoryNotFoundModal
          closeModal={closeModal}
          githubAPI={githubAPI}
        />
      );
    }
  }
  return null;
};
