import React from 'react';
import { Preloader, Text } from '@wix/wix-base-ui';
import styles from './ConnectionLoader.scss';
import { dataHooks } from '../dataHooks';
import { useTranslation } from '@wix/wix-i18n-config';

export const ConnectionLoader: React.FC = () => {
  const [t] = useTranslation();
  return (
    <div
      className={styles.connectionLoader}
      data-hook={dataHooks.externalDbPanels.connectingLoader.container}
    >
      <div style={{ marginBottom: '32px' }}>
        <Preloader className="large" />
      </div>
      <Text
        dataHook={dataHooks.externalDbPanels.connectingLoader.loadingMsg}
        shouldTranslate={false}
      >
        {t('External_Collections_Screen_Add_Loading_Message')}
      </Text>
    </div>
  );
};
