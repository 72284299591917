import React from 'react';
import { dataHooks } from '../dataHooks';
import styles from './ConnectAdapter.scss';
import { NotificationBanner } from '@wix/wix-base-ui';
import { useTranslation } from '@wix/wix-i18n-config';

export const WarningBanner: React.FC = () => {
  const [t] = useTranslation();
  return (
    <div className={styles.warningContainer}>
      <NotificationBanner
        skin="warning"
        type="floating"
        text={t(
          'WixCode_WarningModal_EditExternalDatabaseConnection_Description',
        )}
        multiline
        dataHook={dataHooks.externalDbPanels.editProvider.warningBanner}
        shouldTranslate={false}
      />
    </div>
  );
};
