import React from 'react';
import { i18n, I18nextProvider } from '@wix/wix-i18n-config';
import { APIKeys, connectWithShell, Shell } from '@wix/wix-code-repluggable';
import { ContributedTabsSlot, TabsSlot } from './contributedTabs';
import EventsPanel, {
  ConnectEventsPanelWithShellProps,
} from './components/pageElementsPanel/EventsPanel';
import { PageElementsPanelContext } from './SidebarContexts';
import { PropertiesPanel } from './components/pageElementsPanel/PageElementsPanel';
import { getContributedTabs, getSelectedTab } from './state/selectors';
import { setContributedTabs, setSelectedTab, TabsStore } from './state';
import { propertiesPanelTabIds } from '@wix/wix-code-plugin-contracts';
import { BiLoggerContext } from '@wix/wix-code-common-components';

type PropertiesPanelProps = {
  tabsSlot: TabsSlot;
  contributedTabs: string[];
  selectedTab: string;
};
type PropertiesPanelDispatchProps = {
  selectTab: (selectedTab: string) => void;
};

const contributePropertiesAndEvents = (
  tabsSlot: TabsSlot,
  shell: Shell,
  i18nInstance: i18n,
) => {
  const ConnectedEventsPanel = connectWithShell<
    {},
    {},
    ConnectEventsPanelWithShellProps
  >(
    () => {
      const devContextAPI = shell.getAPI(APIKeys.DevContextAPI);
      return {
        selectedFileId: devContextAPI.getSelectedFileId(),
        openMasterPage: devContextAPI.setMasterPageContext,
      };
    },
    undefined,
    shell,
    {
      renderOutsideProvider: true,
      allowOutOfEntryPoint: true,
    },
  )(EventsPanel);

  tabsSlot.contribute(
    shell,
    {
      label: i18nInstance.t('WixCode_PropertiesAndEventsPanel_VeloTab_Title'),
      id: propertiesPanelTabIds.propertiesAndEvents,
      TabContent: (origProps: any) => (
        <BiLoggerContext.Provider value={shell.getAPI(APIKeys.BiLoggerAPI)}>
          <ConnectedEventsPanel {...origProps} />
        </BiLoggerContext.Provider>
      ),
    },
    () => true,
  );
  shell
    .getStore()
    .dispatch(setContributedTabs(propertiesPanelTabIds.propertiesAndEvents));
};

export const createCodeEditorSidebar = ({
  shell,
  i18nInstance,
}: {
  shell: Shell;
  i18nInstance: i18n;
}) => {
  const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
  const devContextAPI = shell.getAPI(APIKeys.DevContextAPI);
  const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
  const wixCodeStoreAPI = shell.getAPI(APIKeys.WixCodeStoreAPI);
  const legacyDependenciesAPI = shell.getAPI(APIKeys.LegacyEditorDependencies);
  const aiAssistancePublicAPI = shell.getAPI(APIKeys.AiAssistancePublicAPI);
  const tabsSlot = shell.getSlot(ContributedTabsSlot);
  const store = shell.getStore<TabsStore>();

  contributePropertiesAndEvents(tabsSlot, shell, i18nInstance);

  const ConnectedPropertiesPanel = connectWithShell<
    {},
    {},
    PropertiesPanelProps,
    PropertiesPanelDispatchProps
  >(
    () => {
      const state = store.getState();
      return {
        tabsSlot,
        selectedTab: getSelectedTab(state),
        contributedTabs: getContributedTabs(state),
      };
    },
    () => ({
      selectTab: (selectedTab: string) => {
        store.dispatch(setSelectedTab(selectedTab));
      },
    }),
    shell,
    {
      renderOutsideProvider: true,
      allowOutOfEntryPoint: true,
    },
  )(PropertiesPanel);

  return () => (
    <I18nextProvider i18n={i18nInstance}>
      <BiLoggerContext.Provider value={biLoggerAPI}>
        <PageElementsPanelContext.Provider
          value={{
            editorAPI,
            devContextAPI,
            wixCodeStore: wixCodeStoreAPI,
            legacyDependencies: legacyDependenciesAPI,
            aiAssistancePublicAPI,
          }}
        >
          <ConnectedPropertiesPanel />
        </PageElementsPanelContext.Provider>
      </BiLoggerContext.Provider>
    </I18nextProvider>
  );
};
