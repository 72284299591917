import { EntryPoint, APIKeys, Shell } from '@wix/wix-code-repluggable';
import { createSiteCodeTab } from './createSiteCodeTab';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { createSiteCodeMenuTab } from './siteCodeTabMenuTab';

export const SiteCodeTabEntryPoint: EntryPoint = {
  name: 'Site Code Tab Entry Point',
  getDependencyAPIs() {
    return [
      APIKeys.MenuAPI,
      APIKeys.PanelsAPI,
      APIKeys.SidebarAPI,
      APIKeys.ReadOnlyAPI,
      APIKeys.BiLoggerAPI,
      APIKeys.MenuPanelAPI,
      APIKeys.FilesTreeAPI,
      APIKeys.WixCodeStoreAPI,
      APIKeys.PackagesAndAppsAPI,
      WixCodeEditorAdapterAPI,
    ];
  },
  extend(shell: Shell) {
    const menuAPI = shell.getAPI(APIKeys.MenuAPI);
    const panelsAPI = shell.getAPI(APIKeys.PanelsAPI);
    const sidebarAPI = shell.getAPI(APIKeys.SidebarAPI);
    const readOnlyAPI = shell.getAPI(APIKeys.ReadOnlyAPI);
    const biLoggerAPI = shell.getAPI(APIKeys.BiLoggerAPI);
    const menuPanelAPI = shell.getAPI(APIKeys.MenuPanelAPI);
    const filesTreeAPI = shell.getAPI(APIKeys.FilesTreeAPI);
    const wixCodeStoreAPI = shell.getAPI(APIKeys.WixCodeStoreAPI);
    const packagesAndAppsAPI = shell.getAPI(APIKeys.PackagesAndAppsAPI);
    const { legacyDependencies } = shell.getAPI(WixCodeEditorAdapterAPI);

    const siteCodeTab = createSiteCodeTab();

    sidebarAPI.contributeTab(() => siteCodeTab);

    menuPanelAPI.contributeTab({
      id: 'site_code',
      TabContent: ({ closePanel }) => {
        const TabContent = siteCodeTab.creator();
        return createSiteCodeMenuTab({
          shell,
          closePanel,
          children: TabContent,
          apis: {
            wixCodeStoreAPI,
            biLoggerAPI,
            packagesAndAppsAPI,
            legacyDependenciesAPI: legacyDependencies,
            filesTreeAPI,
            panelsAPI,
            readOnlyAPI,
          },
        });
      },
    });

    menuAPI.registerMenuItemListener('site_code', () => {
      menuPanelAPI.selectTab('site_code');
    });
  },
};
