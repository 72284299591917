import { configureStore, Reducer } from '@reduxjs/toolkit';
import reduxThunk from 'redux-thunk';
import { EditorAPI } from '@wix/wix-code-plugin-contracts';
import batchedUpdates from '../middlewares/reactBatchUpdates';
import { LegacyEditorExperimentsObj } from '../../experiments/legacyExperiments';
import { AppState } from '../reducers/rootReducer';
import { ThunkExtraArg } from '@wix/wix-code-common-components';

export default function createStore(
  reducer: Reducer<AppState>,
  isDebug: any,
  editorAPI: EditorAPI,
  experiment: LegacyEditorExperimentsObj,
) {
  const middleware = [
    reduxThunk.withExtraArgument<ThunkExtraArg>({
      ...editorAPI,
      experiment,
      editorAPI,
    }),
    batchedUpdates,
  ];

  return configureStore({
    reducer,
    middleware,
    devTools: isDebug && process.env.NODE_ENV !== 'production',
  });
}
