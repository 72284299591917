import { ActionType } from '@/infra/redux-state/actions/types';
import { isPageFile, isMasterPageFile } from '@wix/wix-code-common';
import {
  TabsAddOrChangeTbfTab,
  TabsCloseEmbededTab,
  TabsCloseTab,
  TabsCloseTbfTab,
  TabsOpenOrChangeUnpinnedTab,
  TabsPinTab,
  TabsStoreScrollState,
} from '../reducers/ideTabsReducer';
import { IdeTabsActions } from '@wix/wix-code-plugin-contracts';
import { Dispatch } from '@wix/wix-code-common-components';

function pinTab({ tabId }: { tabId: string }) {
  return (
    dispatch: Dispatch,
    _: AnyFixMe,
    editorApi: AnyFixMe,
  ): boolean | TabsPinTab => {
    // Page file are automatically pinned and should not be opened via the pinning mechanism
    if (isPageFile(editorApi, tabId) && !isMasterPageFile(tabId)) {
      return false;
    }

    return !!dispatch({
      type: ActionType.TABS_PIN_TAB,
      tabId,
    });
  };
}

function closeTab({ tabId }: { tabId: string }): TabsCloseTab {
  return {
    type: ActionType.TABS_CLOSE_TAB,
    tabId,
  };
}

function closeEmbeddedTab({ tabId }: { tabId: string }): TabsCloseEmbededTab {
  // TODO tabId is embeddedTab's URL
  return {
    type: ActionType.TABS_CLOSE_EMBEDDED_TAB,
    tabId,
  };
}

function closeTbfTab({ tabId }: { tabId: string }): TabsCloseTbfTab {
  return {
    type: ActionType.TABS_CLOSE_TBF_TAB,
    tabId,
  };
}

function openOrChangeUnpinnedTab({
  tabId,
}: {
  tabId: string | null;
}): TabsOpenOrChangeUnpinnedTab {
  return {
    type: ActionType.TABS_OPEN_OR_CHANGE_UNPINNED_TAB,
    tabId,
  };
}

function openOrAddTbfTab({
  tabId,
  fileId,
  functionName,
  functionSignature,
  paramNames,
  lineNumber,
}: Omit<TabsAddOrChangeTbfTab, 'type'>): TabsAddOrChangeTbfTab {
  return {
    type: ActionType.TABS_ADD_OR_CHANGES_TBF_TAB,
    tabId,
    fileId,
    functionName,
    functionSignature,
    paramNames,
    lineNumber,
  };
}

export type StoreTabScrollStateParams = Omit<TabsStoreScrollState, 'type'>;
function storeTabScrollState({
  tabId,
  scroll,
}: StoreTabScrollStateParams): TabsStoreScrollState {
  return {
    type: ActionType.TABS_STORE_SCROLL_STATE,
    tabId,
    scroll,
  };
}

const ideTabsActions: IdeTabsActions = {
  closeTab,
  closeEmbeddedTab,
  closeTbfTab,
  pinTab,
  openOrChangeUnpinnedTab,
  openOrAddTbfTab,
  storeTabScrollState,
};

export default ideTabsActions;
