export default {
  backend_tab: {
    title: 'backend-title',
    subtitle: 'backend-subtitle',
    features_list: 'backend-features_list',
    cta: 'backend-cta',
    link: 'backend-link',
    panel_header: 'panel-header',
  },
  empty_state: {
    header: 'empty-state-header',
    title: 'empty-state-title',
    subtitle: 'empty-state-subtitle',
    features_list: 'empty-state-features-list',
    cta: 'empty-state-features-cta',
    link: 'empty-state-features-link',
  },
  resources: {
    resources_section: {
      sectionHeader: 'resources-section-title',
      item: (label: string) => `resource-section-item-${label}`,
    },
  },
  menu: 'menu',
  code_panel: 'code-panel',
  dev_mode_off_button: 'dev_mode_off_button',
};
