import React from 'react';
import styles from './ExternalDBPanels.scss';
import { Badge, InfoIcon } from '@wix/wix-base-ui';
import { ExternalDbFlow } from './AddExternalDbMainScreen/AddExternalDbMainScreen';

export const ADD_FLOW = 'ADD_FLOW';
export const EDIT_FLOW = 'EDIT_FLOW';
export const SELECT_PROVIDER_FLOW = 'SELECT_FLOW';

export const ADD_PANEL_NAME_FOR_BI = 'add external collections';
export const EDIT_PANEL_NAME_FOR_BI = 'edit external collections';

export const LOADING_PANEL_LABELS = {
  [ADD_FLOW]: {
    panelTitle: 'External_Collections_Screen_Add_Title',
    panelSubtitle: 'External_Collections_Screen_Add_Subtitle',
    loadingMessage: 'External_Collections_Screen_Add_Loading_Message',
  },
  [EDIT_FLOW]: {
    panelTitle: 'External_Collections_Screen_Edit_Title',
    panelSubtitle: 'External_Collections_Screen_Edit_Subtitle',
    loadingMessage: 'External_Collections_Screen_Edit_Loading_Message',
  },
};

export const HELP_ID = '3aac240b-5a82-4a4b-ae81-fc04f0fcfbb1';

export enum Providers {
  GOOGLE_OPTION_VALUE = 'External_Databases_Modal_Select_Provider_Google_Cloud',
  AZURE_OPTION_VALUE = 'External_Databases_Modal_Select_Provider_Azure',
  AWS_OPTION_VALUE = 'External_Databases_Modal_Select_Provider_AWS',
  CUSTOM_OPTION_VALUE = 'External_Databases_Modal_Select_Provider_Custom',
}

type ConfigurationLink = {
  [key: string]: string;
};
export const CONFIGURATION_LINK: ConfigurationLink = {
  [Providers.GOOGLE_OPTION_VALUE]:
    'External_Databases_Modal_Connect_Adapter_Google_Link',
  [Providers.AZURE_OPTION_VALUE]:
    'External_Databases_Modal_Connect_Adapter_Azure_Link',
  [Providers.AWS_OPTION_VALUE]:
    'External_Databases_Modal_Connect_Adapter_AWS_Link',
  [Providers.CUSTOM_OPTION_VALUE]:
    'External_Databases_Modal_Connect_Adapter_Custom_Link',
};

export const BI = {
  panels: {
    selectProvider: 'wixCode.panels.externalDB.select_provider',
    connectAdapter: 'wixCode.panels.externalDB.connect_adapter',
    editAdapter: 'wixCode.panels.externalDB.edit_adapter',
    successPage: 'wixCode.panels.externalDB.success_page',
    successNoCollections: 'wixCode.panels.externalDB.success_add_tables',
    disconnect: 'wixCode.panels.externalDB.disconnect_external_db',
    connectionValue:
      'wixCode.panels.externalDB.change_external_db_conenction_value',
    errorPanel: 'wixCode.panels.externalDB.connection_issue',
    mainModal: 'wixCode.panels.externalDB.Select_flow_modal',
    promotionalModal: 'wixCode.panels.externalDB.promotional_modal',
    createManagedDb: 'wixCode.panels.externalDB.create_managed_external_db',
    dbCreationPending: 'wixCode.panels.externalDB.db_pending_creation',
    deleteCloudDb: 'wixCode.panels.externalDB.cloud_db_delete_db',
    dbCreationError: 'wixCode.panels.externalDB.could_db_creation_failed',
  },
  actions: {
    click: 'click',
    hover: 'hover',
  },
  buttons: {
    next: 'next',
    configureAdapter: 'configure_adapter',
    connect: 'connect',
    back: 'back',
    createNamespace: 'create_a_namespace',
    addEndpoint: 'add_an_endpoint',
    enterSecretKey: 'enter_a_secret_key',
    cancel: 'cancel',
    x: 'x',
    help: '?',
    disconnect: 'disconnect',
    primaryCTA: 'primaryCTA',
    secondaryCTA: 'secondaryCTA',
    editSettings: 'edit_settings',
    delete: 'delete',
    tryAgain: 'try_again',
  },
  flows: {
    createNew: 'create_new_database',
    addExisting: 'connect_existing_databse',
  },
  badges: {
    inProgress: 'in_progress',
    learnMore: 'learn_more',
  },
  contextMenuActions: {
    newCollection: 'new_collection',
    metrics: 'metrics',
    deleteDatabase: 'delete_database',
    learnMore: 'learn_more',
  },
};

export const adapterInputConstraints = {
  MAX_NAMESPACE_LENGTH: 32,
  MAX_ENDPOINT_LENGTH: 255,
};

export const getExternalDbsMainScreenOptions = (
  isCloudDbLimitReached: boolean,
  t: (key: string) => string,
) => [
  {
    title: (
      <div className={styles.titleContainer}>
        {t('External_Databases.Modal.ChooseCreateOrConnect.CreateNew.Title')}
        {isCloudDbLimitReached ? null : (
          <Badge
            skin="standard"
            value={t(
              'External_Databases.Modal.ChooseCreateOrConnect.CreateNew.Badge',
            )}
            className={styles.marginLeft}
          />
        )}
      </div>
    ),
    text: t(
      'External_Databases.Modal.ChooseCreateOrConnect.CreateNew.Subtitle',
    ),
    value: ExternalDbFlow.CreateNewDb,
    disabled: isCloudDbLimitReached,
    suffix: isCloudDbLimitReached ? (
      <InfoIcon
        text={t(
          'External_Databases.Modal.ChooseCreateOrConnect.CreateNew.LimitReached.Tooltip',
        )}
        className={styles.marginLeft}
      />
    ) : undefined,
  },
  {
    title: t(
      'External_Databases.Modal.ChooseCreateOrConnect.ConnectExisting.Title',
    ),
    text: t(
      'External_Databases.Modal.ChooseCreateOrConnect.ConnectExisting.Subtitle',
    ),
    value: ExternalDbFlow.ConnectExistingDb,
  },
];
