import { SpiConnection } from '../ServerAPI';

export const types = {
  UPDATE_SPI_CONNECTIONS: 'UPDATE_SPI_CONNECTIONS',
  DELETE_SPI_CONNECTION: 'DELETE_SPI_CONNECTION',
};

export const updateConnections = (connections: SpiConnection[]) => ({
  type: types.UPDATE_SPI_CONNECTIONS,
  payload: connections,
});

export const deleteConnection = (spiPath: string) => ({
  type: types.DELETE_SPI_CONNECTION,
  payload: spiPath,
});
