import React, { Fragment } from 'react';
import { openToolDashboard as _openToolDashboard } from '@/utils/openToolDashboard';

import {
  WIX_CODE_SECRETS_MANAGER,
  WIX_CODE_SITE_MONITORING,
  WIX_CODE_PLATFORM_VISIBILITY,
} from '@wix/app-definition-ids';
import bi from '@/legacy/bi/bi';
import dataHooks from '../dataHooks';
import { ToolsTree } from './toolsTree/ToolsTree';
import { useTranslate } from '@/infra/i18n/useTranslate';
import {
  useEditorLegacyAPIs,
  connectToStores,
} from '@wix/wix-code-common-components';

interface ToolsTabEditorReduxProps {
  openToolDashboard: (options: { appDefId?: string; path?: string }) => void;
  sendItemClickBi: (item_name: string) => void;
  sendSectionClickBi: (sectionName: string, collapsed: boolean) => void;
}
const ToolsTabComp: React.FC<ToolsTabEditorReduxProps> = ({
  openToolDashboard,
  sendItemClickBi,
  sendSectionClickBi,
}: ToolsTabEditorReduxProps) => {
  const { t } = useTranslate();
  const cachingPagesEntry = {
    id: 'caching-pages',
    dataHook: dataHooks.LARGE_ACTION_ITEM,
    title: t('Left_Tree_Components_Title_Caching_Pages'),
    description: t('Left_Tree_Components_Text_Caching_Pages'),
    onClick: () => {
      sendItemClickBi('caching pages');
      window.open(t('Caching_Pages_Link'));
    },
  };
  const monitoringEntry = {
    id: 'monitoring',
    dataHook: dataHooks.LARGE_ACTION_ITEM,
    title: t('Left_Tree_Components_Title_Monitoring_New'),
    description: t('Left_Tree_Components_Text_Monitoring'),
    onClick: () => {
      sendItemClickBi('monitoring');
      openToolDashboard({ appDefId: WIX_CODE_PLATFORM_VISIBILITY });
    },
  };
  const tools = {
    logging: [
      {
        id: 'real time logs',
        dataHook: dataHooks.LARGE_ACTION_ITEM,
        title: t('Left_Tree_Components_Title_Logs_RealTime'),
        description: t('Left_Tree_Components_Subtitle_Logs_RealTime'),
        onClick: () => {
          sendItemClickBi('site events');
          openToolDashboard({
            appDefId: WIX_CODE_SITE_MONITORING,
            path: '/site-events',
          });
        },
      },
      {
        id: 'site-monitoring-google-operations',
        dataHook: dataHooks.LARGE_ACTION_ITEM,
        title: t('Left_Tree_Components_Title_Operations'),
        description: t('Left_Tree_Components_Subtitle_Operations'),
        onClick: () => {
          sendItemClickBi('google operations');
          openToolDashboard({
            appDefId: WIX_CODE_SITE_MONITORING,
            path: '/operations',
          });
        },
      },
    ],
    monitoring: [monitoringEntry],
    cachingPages: [cachingPagesEntry],
    security: [
      {
        id: 'secrets-manager',
        dataHook: dataHooks.LARGE_ACTION_ITEM,
        title: t('Left_Tree_Components_Title_SecretsManager'),
        description: t('Left_Tree_Components_Text_SecretsManager'),
        onClick: () => {
          sendItemClickBi('secrets manager');
          openToolDashboard({ appDefId: WIX_CODE_SECRETS_MANAGER });
        },
      },
    ],
    automation: [
      {
        id: 'triggered-emails',
        dataHook: dataHooks.LARGE_ACTION_ITEM,
        title: t('Left_Tree_Components_Title_Triggered_Emails'),
        description: t('Left_Tree_Components_Subtitle_Triggered_Emails'),
        onClick: () => {
          sendItemClickBi('triggered emails');
          openToolDashboard({ path: 'transactional-emails' });
        },
      },
    ],
  };
  return (
    <Fragment>
      <ToolsTree
        dataHook={dataHooks.VELO_TOOLS_MONITORING_SECTION}
        onSectionClick={(collapsed) => {
          sendSectionClickBi('monitoring', collapsed);
        }}
        toolSectionDataHook={dataHooks.SECTION_DIVIDER}
        toolSectionLabel={t('Left_Tree_Components_Title_Monitoring_New')}
        tools={tools.monitoring}
      />

      <ToolsTree
        dataHook={dataHooks.VELO_TOOLS_LOGS_SECTION}
        onSectionClick={(collapsed) => {
          sendSectionClickBi('logs', collapsed);
        }}
        toolSectionDataHook={dataHooks.SECTION_DIVIDER}
        toolSectionLabel={t('Left_Tree_Components_Title_Logs')}
        tools={tools.logging}
      />
      <ToolsTree
        dataHook={dataHooks.VELO_TOOLS_SECURITY_SECTION}
        onSectionClick={(collapsed) => {
          sendSectionClickBi('security', collapsed);
        }}
        toolSectionDataHook={dataHooks.SECTION_DIVIDER}
        toolSectionLabel={t('Left_Tree_Components_Title_Security')}
        tools={tools.security}
      />
      <ToolsTree
        dataHook={dataHooks.VELO_TOOLS_AUTOMATION_SECTION}
        onSectionClick={(collapsed) => {
          sendSectionClickBi('automation', collapsed);
        }}
        toolSectionDataHook={dataHooks.SECTION_DIVIDER}
        toolSectionLabel={t('Left_Tree_Components_Title_Automation')}
        tools={tools.automation}
      />
    </Fragment>
  );
};

const mapStateToProps =
  (util: AnyFixMe, experiment: AnyFixMe) =>
  ({ editorAPI }: AnyFixMe): ToolsTabEditorReduxProps => {
    const sendItemClickBi = (item_name: string) =>
      editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_ITEM, {
        item_name,
        item_type: 'tool',
      });
    const sendSectionClickBi = (sectionName: string, collapsed: boolean) =>
      editorAPI.bi.event(bi.events.LEFT_TREE_CLICK_ON_SECTION, {
        section_name: sectionName,
        action: collapsed ? 'close' : 'open',
      });

    const openToolDashboard = ({ appDefId = '', path = '' }) =>
      _openToolDashboard({ editorAPI, util, appDefId, path });

    return {
      openToolDashboard,
      sendItemClickBi,
      sendSectionClickBi,
    };
  };

let ConnectedComponent = null as any;
export const ToolsTab = (origProps: AnyFixMe) => {
  const {
    legacyDependenciesAPI: { util, experiment },
  } = useEditorLegacyAPIs();
  if (ConnectedComponent === null) {
    ConnectedComponent = connectToStores({
      util,
      mapEditorStateToProps: mapStateToProps(util, experiment),
      comp: ToolsTabComp,
    });
  }
  return <ConnectedComponent {...origProps} />;
};
