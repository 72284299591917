import React, { ReactElement } from 'react';
import { Icons } from './index';

export const SchemaFieldsIcons: { [key: string]: ReactElement } = {
  text: <Icons.Text />,
  image: <Icons.Image />,
  boolean: <Icons.Boolean />,
  number: <Icons.Number />,
  date: <Icons.Calendar />,
  datetime: <Icons.Calendar />,
  time: <Icons.Time />,
  'rich-content': <Icons.RichContent />,
  richtext: <Icons.RichText />,
  url: <Icons.Url />,
  document: <Icons.Document />,
  address: <Icons.Address />,
  array: <Icons.Array />,
  audio: <Icons.Audio />,
  color: <Icons.Color />,
  composedstatus: <Icons.ComposedStatus />,
  'array<document>': <Icons.DocumentArray />,
  'media-gallery': <Icons.MediaGallery />,
  object: <Icons.Object />,
  reference: <Icons.Reference />,
  video: <Icons.Video />,
  'array<string>': <Icons.Array />,
  'multi-reference': <Icons.MultiReference />,
};
