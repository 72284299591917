import { EntryPoint, Shell, APIKeys } from '@wix/wix-code-repluggable';
import {
  FilesTreeState,
  fileTreeReducer,
  FileTreeReducerAction,
} from '@/infra/redux-state/reducers/fileTreeReducer';

import { createFilesTreeAPI } from './FilesTreeAPI';

export const FilesTreeEntryPoint: EntryPoint = {
  name: 'Files Tree',
  getDependencyAPIs() {
    return [
      APIKeys.ClassicEditorAPI,
      APIKeys.LegacyEditorDependencies,
      APIKeys.WixCodeStoreAPI,
    ];
  },
  declareAPIs() {
    return [APIKeys.FilesTreeAPI];
  },
  attach(shell: Shell) {
    shell.contributeState<{ filesTree: FilesTreeState }, FileTreeReducerAction>(
      () => ({
        filesTree: fileTreeReducer,
      }),
    );
    const { editorAPI } = shell.getAPI(APIKeys.ClassicEditorAPI);
    const wixCodeStoreAPI = shell.getAPI(APIKeys.WixCodeStoreAPI);
    const { stateManagement } = shell.getAPI(APIKeys.LegacyEditorDependencies);
    const filesTreeStore = shell.getStore<FilesTreeState>();
    shell.contributeAPI(APIKeys.FilesTreeAPI, () =>
      createFilesTreeAPI({
        wixCodeStoreAPI,
        editorAPI,
        stateManagement,
        filesTreeStore,
      }),
    );
  },
};
