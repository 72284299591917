import * as React from 'react';
import { CodeReusePkgTreeContext } from '../CodeReusePkgTreeContext';
import { packageViewActions } from '@/toExtract/packages/packagesView/packagesViewReducer';
import { SuffixPimple } from './SuffixPimple';
import { useInternalAPIs } from '../../../packagesTabEntryPoint/contexts';
import { useEditorLegacyAPIs } from '@wix/wix-code-common-components';

export const WasAutoUpdatedPimple = () => {
  const { pkg } = React.useContext(CodeReusePkgTreeContext);
  const {
    legacyDependenciesAPI: {
      util: { translate },
    },
  } = useEditorLegacyAPIs();
  const {
    wixCodeStoreAPI: { dispatch },
  } = useInternalAPIs();

  const dismiss = () =>
    dispatch(
      packageViewActions.updateInstalledCodeReusePkg({
        oldPkg: pkg,
        newPkg: { ...pkg, fromAutoUpdate: false },
      }),
    );

  const wasUpdatedProps = {
    tooltipText: translate('WixCode_Package_Was_Updated_Text'),
    ctaAction: dismiss,
    ctaText: translate('WixCode_Package_Was_Updated_Dismiss'),
  };

  return <SuffixPimple {...wasUpdatedProps} />;
};
