export const COLLECTIONS_TAB_ID = 'collections';

export const QUICK_ACTIONS = {
  NEW_COLLECTION: 'NEW_COLLECTION',
  ADD_EXTERNAL_COLLECTIONS: 'ADD_EXTERNAL_COLLECTIONS',
  EDIT_EXTERNAL_COLLECTIONS: 'EDIT_EXTERNAL_COLLECTIONS',
  REMOVE_EXTERNAL_COLLECTIONS: 'REMOVE_EXTERNAL_COLLECTIONS',
};
export const COLLECTIONS_QUICK_ACTIONS = {
  ADD_REMOVE_HOOKS: 'ADD_REMOVE_HOOKS',
};

export const FORMS_BANNER_USER_PREFERENCES_KEY =
  'wixCode.moveFormsToNewNamespace';

export const cloudDbReadyEvent = {
  appDefId: 'bbcd7031-4f4e-46de-9ff0-dfa722335c49',
  eventName: 'cloud-db-provisioning-success',
};
