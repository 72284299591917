import isPlainObject_ from 'lodash/isPlainObject';
import constants from './consts';

const HTTP_FUNCTIONS_GRID_APP_ID_HEADER = 'X-Wix-Grid-App-Id';
const WIX_AUTHORIZATION_HEADER = 'X-Wix-Si';
const AUTHORIZATION_HEADER = 'Authorization';
const { HTTP_FUNCTIONS_REQUEST_OBJECT_WARNINGS } = constants;

export interface RequestOptions {
  method: string;
  body?: string;
  path: string;
  headers: Record<string, string>;
}

const allStrings = (array: unknown[]): array is string[] =>
  array.every((segment) => typeof segment === 'string');

const buildBaseUri = (path: string[], basePath: string): string =>
  path.length > 0 ? `${basePath}/${path.join('/')}` : basePath;

const resolveBaseUri = (
  basePath: string,
  path: unknown,
  warnings: string[],
): string => {
  if (Array.isArray(path)) {
    if (allStrings(path)) {
      return buildBaseUri(path, basePath);
    } else {
      warnings.push(
        HTTP_FUNCTIONS_REQUEST_OBJECT_WARNINGS.PATH_ITEMS_ARE_NOT_STRINGS,
      );
    }
  } else if (path !== undefined) {
    warnings.push(HTTP_FUNCTIONS_REQUEST_OBJECT_WARNINGS.PATH_IS_NOT_ARRAY);
  }

  return basePath;
};

const buildUrl = (
  basePath: string,
  queryParameters: Record<string, string>,
): string => {
  const query = Object.keys(queryParameters)
    .map((key) => `${key}=${encodeURIComponent(queryParameters[key])}`)
    .join('&');

  return `${basePath}?${query}`;
};

const resolveHttpFunctionPath = (
  origin: string,
  uri: string,
  path: unknown[],
  queryParameters: Record<string, string>,
  warnings: string[],
  useNewHttpFunctionsDevService: boolean,
) => {
  const devFunctionsDispatcherPath = useNewHttpFunctionsDevService
    ? 'wix-code-http-functions-dev'
    : 'wix-code-functions-dev-dispatcher';
  const basePath = `${origin}/_api/${devFunctionsDispatcherPath}/_functions-test/${uri}`;

  const pathWithoutParameters = resolveBaseUri(basePath, path, warnings);

  if (isPlainObject_(queryParameters)) {
    if (allStrings(Object.values(queryParameters))) {
      if (Object.keys(queryParameters).length > 0) {
        return buildUrl(pathWithoutParameters, queryParameters);
      }
    } else {
      warnings.push(
        HTTP_FUNCTIONS_REQUEST_OBJECT_WARNINGS.QUERY_VALUES_ARE_NOT_STRINGS,
      );
    }
  } else if (queryParameters !== undefined) {
    warnings.push(HTTP_FUNCTIONS_REQUEST_OBJECT_WARNINGS.QUERY_IS_NOT_OBJECT);
  }

  return pathWithoutParameters;
};

const isValidHttpMethod = (method: string): boolean =>
  ['GET', 'POST', 'PUT', 'DELETE', 'OPTIONS'].includes(method.toUpperCase());

const resolveHttpMethod = (method: string, warnings: string[]): string => {
  if (typeof method === 'string') {
    if (isValidHttpMethod(method)) {
      return method.toUpperCase();
    } else {
      warnings.push(HTTP_FUNCTIONS_REQUEST_OBJECT_WARNINGS.METHOD_IS_INVALID);
    }
  } else if (method !== undefined) {
    warnings.push(HTTP_FUNCTIONS_REQUEST_OBJECT_WARNINGS.METHOD_IS_NOT_STRINGS);
  }

  return 'GET';
};

const resolveHeaders = (
  headers: Record<string, string>,
  warnings: string[],
): Record<string, string> => {
  if (isPlainObject_(headers)) {
    if (allStrings(Object.values(headers))) {
      return headers;
    } else {
      warnings.push(
        HTTP_FUNCTIONS_REQUEST_OBJECT_WARNINGS.HEADERS_VALUES_IS_NOT_STRING,
      );
    }
  } else if (headers !== undefined) {
    warnings.push(HTTP_FUNCTIONS_REQUEST_OBJECT_WARNINGS.HEADERS_IS_NOT_OBJECT);
  }

  return {};
};

const resolveBody = (
  body: Record<string, unknown> | undefined,
  headers: Record<string, string>,
  warnings: string[],
): string | undefined => {
  if (body?.json) {
    if (typeof body.json === 'object') {
      headers['Content-Type'] = 'application/json';

      return JSON.stringify(body.json);
    } else {
      warnings.push(
        HTTP_FUNCTIONS_REQUEST_OBJECT_WARNINGS.BODY_JSON_IS_INVALID,
      );
    }
  } else if (body?.text) {
    if (typeof body.text === 'string') {
      headers['Content-Type'] = 'text/plain';

      return body.text;
    } else {
      warnings.push(
        HTTP_FUNCTIONS_REQUEST_OBJECT_WARNINGS.BODY_TEXT_IS_INVALID,
      );
    }
  } else if (body !== undefined) {
    warnings.push(HTTP_FUNCTIONS_REQUEST_OBJECT_WARNINGS.BODY_IS_INVALID);
  }
};

export const extractHttpFunctionsRequestOptions = ({
  functionName,
  origin,
  gridAppId,
  instance,
  param,
  useNewHttpFunctionsDevService,
}: AnyFixMe): { options: RequestOptions; warnings: string[] } => {
  const warnings: string[] = [];

  const [methodFromFunctionName, uri] = functionName.split('_');
  const defaultHeaders = {
    [HTTP_FUNCTIONS_GRID_APP_ID_HEADER]: gridAppId,
    [WIX_AUTHORIZATION_HEADER]: instance,
  };
  const headers = useNewHttpFunctionsDevService
    ? { ...defaultHeaders, [AUTHORIZATION_HEADER]: instance }
    : defaultHeaders;
  const options: RequestOptions = {
    method: methodFromFunctionName.toUpperCase(),
    headers,
    body: undefined,
    path: resolveHttpFunctionPath(
      origin,
      uri,
      param?.path,
      param?.query,
      warnings,
      useNewHttpFunctionsDevService,
    ),
  };

  if (options.method === 'USE') {
    options.method = resolveHttpMethod(param?.method, warnings);
  }

  options.headers = {
    ...resolveHeaders(param?.headers, warnings),
    ...options.headers,
  };

  options.body = resolveBody(param?.body, options.headers, warnings);

  return { options, warnings };
};
