// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DKPA0P{background-color:#f7fbff}.DKPA0P .Fkeh26{font-weight:700;color:#868aa5;margin:17px 0 0 22px}.DKPA0P .GjvPo0{margin:0 20px}.AbEF_m{background-color:#f7fbff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"packages-modal-menu": "DKPA0P",
	"packagesModalMenu": "DKPA0P",
	"label": "Fkeh26",
	"divider-container": "GjvPo0",
	"dividerContainer": "GjvPo0",
	"tabs": "AbEF_m"
};
export default ___CSS_LOADER_EXPORT___;
