import { useTranslation } from '@wix/wix-i18n-config';
import React, { useContext } from 'react';
import { BI } from './constants';
import { MessageModal } from '@wix/wix-base-ui';
import { ExternalDbsModalContext } from './ExternalDbsModalContext';
import { panelHeaderClose } from '@wix/bi-logger-editor/v2';
import { connectExternalDbPanelAction } from '@wix/bi-logger-platform/v2';
import SuccessImage from '../../assets/icons/success.svg';
import { dataHooks } from './dataHooks';

interface SuccessModalProps {
  title: string;
  description: string;
}

export const SuccessModal: React.FC<SuccessModalProps> = ({
  title,
  description,
}) => {
  const [t] = useTranslation();
  const { panelsAPI, biLoggerAPI, platformAppsAPI } = useContext(
    ExternalDbsModalContext,
  );
  return (
    <MessageModal
      dataHook={dataHooks.externalDbPanels.messageModal.container}
      onCloseButtonClick={() => {
        biLoggerAPI.report(
          panelHeaderClose({
            panel_name: BI.panels.successPage,
          }),
        );
        panelsAPI.closePanel();
      }}
      title={title}
      content={description}
      illustration={<SuccessImage />}
      primaryButtonText={t(
        'WixCode_SuccessModal_NewExternalDatabaseConnection_PrimaryCTA',
      )}
      primaryButtonOnClick={async () => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: BI.panels.successPage,
            action_type: BI.actions.click,
            button_name: BI.buttons.primaryCTA,
          }),
        );
        platformAppsAPI.openContentManager({
          origin: 'ADD_EXTERNAL_DB_DRIVER',
        });
        panelsAPI.closePanel();
      }}
      secondaryButtonText={t(
        'WixCode_SuccessModal_NewExternalDatabaseConnection_SecondaryCTA',
      )}
      secondaryButtonOnClick={() => {
        biLoggerAPI.report(
          connectExternalDbPanelAction({
            input_name: BI.panels.successPage,
            action_type: BI.actions.click,
            button_name: BI.buttons.secondaryCTA,
          }),
        );
        panelsAPI.closePanel();
      }}
    />
  );
};
