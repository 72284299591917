import React, { useState, useEffect, useContext, useRef } from 'react';
import _ from 'lodash';
import { TreeListItem } from '@wix/wix-base-ui';
import { monitoring, resolveRouterRef } from '@wix/wix-code-common';
import { PagesTabContext } from './PagesTabContext';
import dataHooks from '../dataHooks';
import { DynamicPage } from './DynamicPage';
import { PageTreeContext } from './PageTreeContext';
import {
  ContextMenuStructure,
  ReadOnlyModeContext,
  TreeContextMenu,
} from '@wix/wix-code-common-components';
import {
  leftTreeClickOnASection,
  leftTreeClickOnMenuSettingIconItem,
  leftTreeClickInMenuSettingOptionItem,
} from '@wix/bi-logger-platform/v2';
import { DynamicPageItemType, DyanmicPageItemTypeWithPage } from './types';

interface DynamicPagesGroupProps {
  data: DynamicPageItemType;
}

type ContextMenuAction = {
  id: string;
  title: string;
  icon: string;
};

const { withErrorHandling } = monitoring;
export const DynamicPagesGroup = ({ data }: DynamicPagesGroupProps) => {
  const { isCollapsed, setCollapsed } = useContext(PageTreeContext);
  const { readOnlyMode } = useContext(ReadOnlyModeContext);
  const {
    editorAPI,
    bi,
    legacyDependenciesAPI: { util },
  } = useContext(PagesTabContext);

  const getId = () => 'page_' + _.get(data, 'id');

  const [isContextMenuOpen, setContextMenuOpen] = useState(false);
  const [routerContextMenuItems, setRouterContextMenuItems] = useState<
    ContextMenuAction[]
  >([]);
  const [collapsed, setCollapsedState] = React.useState(isCollapsed(getId()));
  const [contextMenuSections, setContextMenuSections] =
    useState<ContextMenuStructure>({
      sections: [],
    });
  const stateRef = useRef<any[]>();
  stateRef.current = routerContextMenuItems;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => updateContextMenuItems(), []);

  const updateContextMenuItems = () => {
    const contextMenuItems = shouldShowRouterActions()
      ? editorAPI.pages.dynamicPages.getRouterActionByPrefix(data.prefix)
      : [];

    setRouterContextMenuItems(contextMenuItems);
    setContextMenuSections({
      sections: [
        [
          ...contextMenuItems.map((item: any, index: number) => ({
            onClick: () => actionSelectedHandler(item.id),
            icon: item.icon || 'file',
            label: item.title,
            automationId: `router-context-item-${item.title}-${index}`,
          })),
        ],
      ],
    });
  };

  const onNodeClick = () =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickOnASection({
          section_name: 'dynamic_pages_group',
          sub_section: data.title,
          action: collapsed ? 'open' : 'close',
        }),
      );
      setCollapsed(getId(), !collapsed);
      setCollapsedState(!collapsed);
    })();

  const onContextMenuClick = (event: AnyFixMe) =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickOnMenuSettingIconItem({
          item_name: _.get(data, 'id'),
          item_type: 'dynamic_page',
        }),
      );
      event.stopPropagation();
    })();

  const shouldShowContextMenu = () => {
    if (isMobileViewMode() || stateRef.current!.length === 0) {
      return false;
    }
    return true;
  };

  const handleContextMenuToggle = setContextMenuOpen;

  const shouldShowRouterActions = () => !isMobileViewMode();

  const isMobileViewMode = () => {
    const viewMode = editorAPI.dsRead.viewMode;
    return viewMode.get() === viewMode.VIEW_MODES.MOBILE;
  };

  const actionSelectedHandler = (action: AnyFixMe) =>
    withErrorHandling(() => {
      bi.report(
        leftTreeClickInMenuSettingOptionItem({
          item_name: _.get(data, 'id'),
          item_type: 'dynamic_page',
          menu_entry_name: action,
        }),
      );
      const selectedOption = _.find(stateRef.current, {
        id: action,
      });

      if (_.get(selectedOption, 'callback')) {
        return selectedOption.callback();
      }
      const { routerRef, pageRef } = resolveRouterRef(editorAPI, data);
      const routerData = editorAPI.dsRead.routers.get.byRef(routerRef);
      const payload = {
        routerRef,
        publicUrl: editorAPI.dsRead.generalInfo.getPublicUrl(),
        origin: 'idePagesTree',
      };
      if (pageRef) {
        (payload as any).pageRef = pageRef;
      }
      const applicationId = _.get(
        editorAPI.dsRead.platform.getAppDataByAppDefId(
          routerData.appDefinitionId,
        ),
        'applicationId',
      );
      editorAPI.dsActions.platform.notifyApplication(applicationId, {
        eventType: selectedOption && selectedOption.event,
        eventPayload: payload,
      });
    })();

  const contextMenu = (
    <div
      className="stop-propagation-bg"
      key="contextMenuContainer"
      onClick={onContextMenuClick}
    >
      <TreeContextMenu
        contextMenuStructure={contextMenuSections}
        contextMenuButton="corvid_tree__context_menu_more"
        handleContextMenuToggle={handleContextMenuToggle}
        isContextMenuOpen={isContextMenuOpen}
        menuClassName="context-menu-icon"
        className="wix-code-pages-tree-dd"
        tooltipContent={util.translate(
          'Velo_Concurrent_Editing_Sidebar_ReadOnly_Text',
        )}
        readonly={!!readOnlyMode.sidePanel?.pages}
      />
    </div>
  );

  return (
    <div onMouseEnter={() => updateContextMenuItems()}>
      <TreeListItem
        dataHook={dataHooks.TREE_NODE}
        label={data.title}
        shouldTranslate={false}
        collapseState={collapsed ? 'collapsed' : 'expanded'}
        size="small"
        onClick={onNodeClick}
        alwaysShowSuffix={isContextMenuOpen}
        highlight={isContextMenuOpen}
        suffix={shouldShowContextMenu() && contextMenu}
      />
      {!collapsed && (
        <ul data-aid="dynamic-pages-group-items" key="subtree">
          {data.items &&
            data.items.map((child: DyanmicPageItemTypeWithPage) => (
              <li key={child.id}>
                <DynamicPage dataSource={child} depth={0} />
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
