import createReactClass from 'create-react-class';
import React from 'react';
import wrapDisplayName from 'recompose/wrapDisplayName';

export default (Component) => {
  const WixCodeErrorBoundaryComponent = createReactClass({
    displayName: wrapDisplayName(Component, 'wixCodeErrorBoundaryComponent'),
    componentDidCatch(error, { componentStack }) {
      if (window.wixCodeSentry) {
        let boundaryProps;
        try {
          boundaryProps = JSON.stringify(this.props, null, 2);
        } catch (e) {
          // No props for you :(
        }
        window.wixCodeSentry.captureError(error, {
          componentStack,
          boundaryProps,
        });
      }
    },

    render() {
      return React.createElement(Component, this.props);
    },
  });

  return WixCodeErrorBoundaryComponent;
};
