import React from 'react';
import { ContextMenu, ContextMenuAction } from '@wix/wix-base-ui';
import { ContextMenuItem, IconColor } from '../ContextMenuItem/ContextMenuItem';
import { dataHooks } from '../../dataHooks';
import s from './TreeContextMenu.scss';
import { TooltipSymbol } from '../TooltipSymbol/TooltipSymbol';
import { useTranslation } from '@wix/wix-i18n-config';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

export type MenuItemSection = TreeContextMenuItemProps[];

export interface TreeContextMenuItemProps {
  automationId?: string;
  disabled?: boolean;
  icon: React.FunctionComponent;
  iconColor?: IconColor;
  label: string;
  onClick: () => void;
}

export interface ContextMenuStructure {
  sections: MenuItemSection[];
  separator?: React.ReactElement;
}

export interface TreeContextMenuProps {
  menuClassName: string;
  contextMenuStructure: ContextMenuStructure;
  contextMenuButton: string;
  className?: string;
  disabled?: boolean;
}

interface DisabledTooltipSymbolProps {
  iconName: string;
  key: string;
}

export const DisabledTooltipSymbol = ({
  iconName,
  key,
}: DisabledTooltipSymbolProps) => {
  const [t] = useTranslation();

  return (
    <TooltipSymbol
      automationId={dataHooks.FILE_CONTEXT_DISABLED_TOOLTIP}
      tooltipContent={t('Velo_Concurrent_Editing_Sidebar_ReadOnly_Text')}
      className={s.disabled}
      key={key}
      symbolName={iconName}
    />
  );
};

const renderMenuActions = (
  sections: MenuItemSection[],
  separator?: React.ReactElement,
) => {
  const actions: (React.ReactElement[] | React.ReactElement)[] = [] as any;
  sections.forEach((section: MenuItemSection, index: Number) => {
    actions.push(
      section.map((item: TreeContextMenuItemProps) => (
        <ContextMenuAction
          className={s.contextMenuAction}
          automationId={item.automationId}
          dataHook={item.automationId}
          disabled={item.disabled}
          key={`menuItem_${item.automationId}`}
          onClick={item.onClick}
          shouldTranslate={false}
        >
          <ContextMenuItem
            icon={item.icon}
            iconColor={item.iconColor}
            label={item.label}
          />
        </ContextMenuAction>
      )),
    );

    if (separator && index !== sections.length - 1) {
      const separatorWithKey = React.cloneElement(separator, {
        key: `separator_${index}`,
      });
      actions.push(separatorWithKey);
    }
  });
  return actions;
};

export const TreeContextMenu: React.FC<TreeContextMenuProps> = ({
  contextMenuStructure: { sections, separator },
  contextMenuButton,
  menuClassName,
  className,
}) => {
  const flattened = renderMenuActions(sections, separator);

  return (
    <ContextMenu
      dataHook={dataHooks.CONTEXT_MENU_ICON}
      customButton={
        <div data-hook={dataHooks.CONTEXT_MENU_CUSTOM_BUTTON}>
          <Symbol name={contextMenuButton} key={contextMenuButton} />
        </div>
      }
      optionsContainerClassName={menuClassName}
      className={className}
      shouldTranslate={false}
    >
      {flattened.map((item) => item)}
    </ContextMenu>
  );
};
