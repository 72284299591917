import { EditorAPI } from '@wix/wix-code-plugin-contracts';

export const resolveRouterRef = (
  editorAPI: EditorAPI,
  dataSource: AnyFixMe,
) => {
  let routerRef;
  let pageRef;
  if (dataSource.prefix) {
    routerRef = editorAPI.dsRead.routers.getRouterRef.byPrefix(
      dataSource.prefix,
    );
  } else {
    pageRef = editorAPI.dsRead.pages.getReference(dataSource.pageData.id);
    routerRef = editorAPI.dsRead.routers.getRouterRef.byPage(pageRef);
  }
  if (!routerRef) {
    throw new Error('Could not resolve router ref');
  }
  return { routerRef, pageRef };
};
