import * as React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { TextLabel, Illustration, RichText, Button } from '@wix/wix-base-ui';
import { PkgListEmptyStateContainer } from '../../pkgListEmptyStateContainer/PkgListEmptyStateContainer';
import { PackagesModalContext } from '../../../../../packagesModalContext';
import s from './MyPackagesEmptyState.scss';

import dataHooks from '../../../../../dataHooks';
import biEvents from '@/legacy/bi/events';
import { useEditorLegacyAPIs } from '@wix/wix-code-common-components';

export const MyPackagesEmptyState: React.FC = () => {
  const { t } = React.useContext(PackagesModalContext);
  const {
    editorAPI,
    legacyDependenciesAPI: { stateManagement },
  } = useEditorLegacyAPIs();

  const sendClickBI = () => {
    editorAPI.bi.event(biEvents.PKG_QUICK_ACTION_CLICKED, {
      menu_entry_name: 'my_packages',
      item_type: 'my_packages',
      category: 'learn_how_to_build_a_package',
    });
  };

  const handleClickCreateAPackage = () => {
    editorAPI.store.dispatch(
      stateManagement.editorPlugins.panels.actions.updateOrOpenPanel(
        'wixBlocksConsumer.panels.WixBlocksPanel',
        {},
      ),
    );
    editorAPI.bi.event(biEvents.PKG_QUICK_ACTION_CLICKED, {
      menu_entry_name: 'my_packages',
      item_type: 'my_packages',
      category: 'request_new_package',
    });
  };

  return (
    <PkgListEmptyStateContainer dataHook={dataHooks.MY_PACKAGE_EMPTY_STATE}>
      <Illustration>
        <Symbol name="corvid_npm__installed_empty_state" />
      </Illustration>
      <TextLabel
        dataHook="title"
        type="T03"
        value={t('Package_Manager_My_Packages_EmptyState_Title')}
      />
      <RichText dataHook="subtitle" type="T01" className={s.subtitle}>
        {t('Package_Manager_My_Packages_EmptyState_SubTitle')}
      </RichText>
      <span className={s.learnMoreLink}>
        <RichText type="T02">
          <a
            target="_blank"
            rel="noreferrer"
            href={t('Package_Manager_My_Packages_EmptyState_Learn_More_Url')}
            data-hook={dataHooks.MY_PACKAGE_EMPTY_LEARN_MORE}
            onClick={sendClickBI}
          >
            {t('Package_Manager_My_Packages_EmptyState_Learn_More')}
          </a>
        </RichText>
      </span>
      <Button
        className={s.ctaButton}
        dataHook={dataHooks.MY_PACKAGE_EMPTY_CREATE_PKG}
        onClick={handleClickCreateAPackage}
      >
        {t('Package_Manager_My_Packages_EmptyState_Create_Package')}
      </Button>
    </PkgListEmptyStateContainer>
  );
};
