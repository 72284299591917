// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lzIRzW{vertical-align:middle;border-left:1px solid #dfe5eb;height:36px;display:inline-flex;flex-direction:column;justify-content:center;align-items:center;padding:0 12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wix-code-ide-toolbar-text-button": "lzIRzW",
	"wixCodeIdeToolbarTextButton": "lzIRzW"
};
export default ___CSS_LOADER_EXPORT___;
