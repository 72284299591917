import * as React from 'react';
import { Divider } from '@wix/wix-base-ui';
import {
  PackagesModalContext,
  PkgInfo,
} from '../../../../../packagesModalContext';
import Preloader from '../../../../../preloader/preloader';
import { MODAL_TABS } from '../../../../packagesModalConsts';
import { ListSectionTitle } from '../../listSectionTitle/ListSectionTitle';
import { InstalledPackagesListItem } from '../InstalledPackagesListItem/InstalledPackagesListItem';
import dataHooks from '../../../../../dataHooks';
import { PackageItemContextProvider } from '../../PackageItemContextProvider';

interface InstalledPackageListSectionProps {
  dataHook: string;
  sectionTitle: string;
  installedPkgs: PkgInfo[];
  isLoading?: boolean;
}

export const InstalledPackagesListSection: React.FC<
  InstalledPackageListSectionProps
> = ({ dataHook, sectionTitle, installedPkgs, isLoading = false }) => {
  const { selectPackage, selectedPackage } =
    React.useContext(PackagesModalContext);

  return (
    <div data-hook={dataHook}>
      <ListSectionTitle title={sectionTitle} />
      <Divider long={true} />
      {isLoading ? (
        <Preloader dataHook={dataHooks.INSTALLED_PKGS_LOADER} />
      ) : (
        installedPkgs.map((pkg) => (
          <PackageItemContextProvider pkgName={pkg.name} key={pkg.name}>
            <InstalledPackagesListItem
              isSelected={pkg.name === selectedPackage}
              onClick={() =>
                selectPackage(pkg.name, MODAL_TABS.INSTALLED_PACKAGES)
              }
            />
          </PackageItemContextProvider>
        ))
      )}
    </div>
  );
};
