import {
  WixCodeDuplexerAPI,
  wixCodeLifecycleDuplexer,
} from '@wix/wix-code-plugin-contracts';
import { WixCodeEditorAdapterAPI } from '@wix/wix-code-editor-adapter';
import { GithubPrivateAPI } from './githubPrivateAPI';
import { i18n } from '@wix/wix-i18n-config';

interface registerToExternalOnboardingCompletedEventParams {
  githubAPI: GithubPrivateAPI;
  wixCodeEditorAdapterAPI: WixCodeEditorAdapterAPI;
  i18nInstance: i18n;
}

export const listenToExternalCompletedOnboardingEvent = ({
  wixCodeDuplexerAPI,
  githubAPI,
  wixCodeEditorAdapterAPI,
  i18nInstance,
}: registerToExternalOnboardingCompletedEventParams & {
  wixCodeDuplexerAPI: WixCodeDuplexerAPI;
}) => {
  return wixCodeDuplexerAPI.subscribeTo.githubStatusChanged(
    handleExternalOnboardingCompletedEvent,
  );

  function handleExternalOnboardingCompletedEvent(
    payload: wixCodeLifecycleDuplexer.TeamworkGithubIntegrationEvent,
  ) {
    wixCodeEditorAdapterAPI.showSnackbar(
      i18nInstance.t(
        payload.isEnabled
          ? 'githubIntegration.connected_to_github_by_other_user.snackbar'
          : 'githubIntegration.disconnected_from_github_by_other_user.snackbar',
      ),
    );
    githubAPI.syncWithSavedState();
  }
};
