import React from 'react';
import _ from 'lodash';
import EventControl from '../eventControl';
import { TextLabel, InfoIcon, TextButton, Tooltip } from '@wix/wix-base-ui';
import { TFunction } from '@wix/wix-i18n-config';
import { experimentUtils } from '@wix/wix-code-common';
import { SparklesSmall } from '@wix/wix-ui-icons-common';
import { Event } from '../types';
import { usePageElementsPanel } from '../../../../SidebarContexts';
import dataHooks from '../../dataHooks';

import styles from './ElementEventsSectionComp.scss';

export type ElementEventsSectionCompProps = {
  disabled: boolean;
  events: Event[];
  t: TFunction;
};

const ElementEventsSectionComp: React.FC<ElementEventsSectionCompProps> = ({
  disabled,
  events,
  t,
}) => {
  const { aiAssistancePublicAPI } = usePageElementsPanel();
  const isAiAssistanceFeatureOpen = experimentUtils.isAiAssistance();
  const renderPageElementsEvents = () => {
    return (
      <div
        className="page-elements-events-binding"
        data-aid="events-binding"
        data-is-read-only={(!!disabled).toString()}
      >
        <span className="section-label-container">
          <TextLabel
            automationId="events-label"
            className="section-label"
            type="T04"
            value={t('WixCode_PropertiesAndEventsPanel_EventHandlers_Title')}
          />
          <InfoIcon
            automationId="events-label-tooltip"
            text={t('WixCode_PropertiesAndEventsPanel_EventHandlers_Tooltip')}
          />
        </span>
        {events.map((event, eventIndex) => {
          const {
            eventLabel,
            eventType,
            handlerInputState,
            handlerName,
            onHandlerNameClicked,
            onCancelEditHandlerName,
            onAddEventHandlerClicked,
            onHandlerNameChanged,
            onRemoveHandlerClicked,
            asyncHandlerNameValidator,
            onErrorTooltipClosed,
          } = event;
          return (
            <EventControl
              key={eventIndex}
              disabled={disabled}
              t={t}
              eventLabel={eventLabel}
              eventType={eventType}
              handlerInputState={handlerInputState}
              handlerName={handlerName}
              onHandlerNameClicked={onHandlerNameClicked}
              onCancelEditHandlerName={onCancelEditHandlerName}
              onAddEventHandlerClicked={onAddEventHandlerClicked}
              onHandlerNameChanged={onHandlerNameChanged}
              onRemoveHandlerClicked={onRemoveHandlerClicked}
              asyncHandlerNameValidator={asyncHandlerNameValidator}
              onErrorTooltipClosed={onErrorTooltipClosed}
              stretchHandlerName={!isAiAssistanceFeatureOpen}
            />
          );
        })}
      </div>
    );
  };

  const renderAiAssitanceLink = () => {
    const AiAssitanceLink = () => {
      return (
        <TextButton
          onClick={aiAssistancePublicAPI.openAIPanel}
          prefixIcon={<SparklesSmall />}
          dataHook={dataHooks.aiAssistancePanelLink}
          disabled={disabled}
        >
          AI Code Assistant
        </TextButton>
      );
    };

    if (disabled) {
      return (
        <Tooltip content="You're in read-only mode because another user is editing code">
          <AiAssitanceLink />
        </Tooltip>
      );
    } else {
      return (
        <div>
          <AiAssitanceLink />
        </div>
      );
    }
  };

  if (isAiAssistanceFeatureOpen) {
    return (
      <div className={styles.container}>
        {renderPageElementsEvents()}
        {renderAiAssitanceLink()}
      </div>
    );
  } else {
    return renderPageElementsEvents();
  }
};

export default ElementEventsSectionComp;
